import { action, actionOn, thunk, thunkOn } from 'easy-peasy';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';
import { utcDateTimeStringToTz, timestampToTz } from '../helpers/date';
import { PARAMETER_DATE_ONLY_FORMAT, PARAMETER_TIME_FORMAT } from '../api/eventApi';

class TimelineModelBuilder extends BaseModelBuilder {
  constructor(timelineApiKey, noteApiKey, settingsKey = 'settings') {
    super(timelineApiKey);
    this.noteApiKey = noteApiKey;
    this.settingsKey = settingsKey;
    this.generators.push(this.timelineGenerator);
  }

  /**
   * @private
   */
  timelineGenerator() {
    return {
      last: false,
      noteApiKey: this.noteApiKey,
      fetchList: thunk(
        async (actions, { page = 0, limit = 10, userMemberId, leadId, sort, nodeType }, { getState, injections }) => {
          const tz = injections.globalStore.getState()[this.settingsKey].timezone;
          const { data } = await getApi(injections, getState()).find({
            page,
            limit,
            sort,
            extraParams: { userMemberId, leadId, nodeType },
          });
          const payload = createPayloadFromFindResponse(data);
          payload.rows = payload.rows.map((r) => convertDatesToTz(r, tz));

          actions.fetchedList({ ...payload, userMemberId, leadId, nodeType });
          return payload;
        }
      ),

      fetchedList: action((state, { rows, pageCount, page, userMemberId, leadId, nodeType, last }) => {
        if (page === 0) {
          state.rows = rows;
        } else {
          rows.forEach((r) => {
            if (!state.rows.some((sr) => sr.id === r.id && sr.nodeType === r.nodeType)) {
              state.rows.push(r);
            }
          });
        }
        state.last = last;
        state.pageCount = pageCount;
        state.page = page;
        state.userMemberId = userMemberId;
        state.leadId = leadId;
        state.nodeType = nodeType;
      }),

      refreshList: thunk((actions, _, { getState }) => {
        const { userMemberId, leadId, nodeType, rows } = getState();
        if (userMemberId) {
          return actions.fetchList({ page: 0, limit: rows.length + 1, userMemberId, nodeType });
        }
        if (leadId) {
          return actions.fetchList({ page: 0, limit: rows.length + 1, leadId, nodeType });
        }
      }),

      onFetchListStart: actionOn(
        (actions) => actions.fetchList.startType,
        (state) => {
          state.loadingList = true;
        }
      ),
      onFetchListDone: actionOn(
        (actions) => [actions.fetchList.successType, actions.fetchList.failType],
        (state) => {
          state.loadingList = false;
        }
      ),

      onChangeDone: thunkOn(
        (actions) => [
          actions.removeNote.successType,
          actions.removeNote.failType,
          actions.removeAttachment.successType,
          actions.removeAttachment.failType,
          actions.createNote.successType,
          actions.createNote.failType,
          actions.updateNote.successType,
          actions.updateNote.failType,
          actions.createLeadNote.successType,
          actions.createLeadNote.failType,
        ],
        (actions) => actions.refreshList()
      ),

      createNote: thunk((_, { newNote }, { injections, getState }) => {
        const { file } = newNote;
        delete newNote.file;
        return getApi(injections, { apiKey: getState().noteApiKey }).create(newNote, file);
      }),

      updateNote: thunk((_, { id, patchedFields }, { injections, getState }) => {
        const updatedService = { ...patchedFields };
        delete updatedService.file;
        return getApi(injections, { apiKey: getState().noteApiKey }).update(id, {
          patchedFields: updatedService,
          file: patchedFields?.file,
        });
      }),

      removeNote: thunk((_, id, { injections, getState }) => {
        return getApi(injections, { apiKey: getState().noteApiKey }).remove(id);
      }),

      removeAttachment: thunk((_, id, { injections, getState }) => {
        return getApi(injections, { apiKey: getState().noteApiKey }).removeAttachment(id);
      }),

      createLeadNote: thunk((_, { newNote }, { injections, getState }) => {
        const { file } = newNote;
        delete newNote.file;
        return getApi(injections, { apiKey: getState().noteApiKey }).createLeadNote(newNote, file);
      }),
    };
  }
}

const convertDatesToTz = ({
  sendTimestamp,
  createdDate,
  eventStartDate,
  deadlineDate,
  deadlineTime,
  transactionDate,
  ...rest
}, tz) => {
  return {
    ...rest,
    sendTimestamp,
    sendEmailDate: timestampToTz(sendTimestamp, tz),
    createdDate: utcDateTimeStringToTz(createdDate, tz),
    eventStartDate: utcDateTimeStringToTz(eventStartDate, tz),
    deadlineDate: utcDateTimeStringToTz(`${deadlineDate} ${deadlineTime}`, tz, PARAMETER_DATE_ONLY_FORMAT),
    deadlineTime: utcDateTimeStringToTz(`${deadlineDate} ${deadlineTime}`, tz, PARAMETER_TIME_FORMAT),
    transactionDate: utcDateTimeStringToTz(transactionDate, tz),
  }
}

export default TimelineModelBuilder;
