import { cn } from "@gymflow/helpers";
import { useState } from "react";

import { Badge } from "../Badge";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";

type SideType = "top" | "right" | "bottom" | "left";

type AlignType = "start" | "center" | "end";

type TagType<T> = {
  label: string;
  item?: T;
};

type TagsPopoverProps<T> = {
  tags: TagType<T>[];
  side?: SideType;
  align?: AlignType;
  onClickItem?: (item: TagType<T>) => void;
};

export const TagsPopover = <T,>({
  tags,
  side = "bottom",
  align = "start",
  onClickItem,
}: TagsPopoverProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const badgeSize = tags.length - 1;

  return (
    <div className="flex items-center gap-3">
      <div
        className={cn(
          "line-clamp-1 break-all text-sm font-medium text-gray-900",
          { "hover:text-secondary-500 cursor-pointer ": !!onClickItem },
        )}
        onClick={() => onClickItem?.(tags[0])}
      >
        {!tags.length ? "-" : tags[0].label}
      </div>
      {badgeSize > 0 && (
        <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
          <PopoverTrigger asChild>
            <div>
              <Badge
                className={cn("rounded-full py-0.5 ring-gray-200", {
                  "bg-secondary-25 text-secondary-700 ring-secondary-200 ring-2":
                    isOpen,
                })}
              >
                +{badgeSize}
              </Badge>
            </div>
          </PopoverTrigger>
          <PopoverContent
            side={side}
            className="flex max-h-40 w-auto max-w-xs flex-wrap gap-2 overflow-y-auto rounded-[0.625rem] border-gray-200 p-3"
            align={align}
          >
            {tags.slice(1).map((item, index) => (
              <Badge
                key={`${item.label}-${index}`}
                className={cn(
                  "px-2.5 py-1 text-sm font-medium text-gray-950 ring-gray-200",
                  { "hover:text-secondary-500 cursor-pointer ": !!onClickItem },
                )}
                onClick={() => onClickItem?.(item)}
              >
                {item.label}
              </Badge>
            ))}
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};
