import { PromotionDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { promotionQueryKeys } from "./promotionQueryKeys";

export function usePromotion(
  {
    api,
    promotionId,
  }: {
    api: {
      promotionApi: { findById: (arg0: number) => Promise<any> };
    };
    promotionId?: number;
  },
  queryOpts?: UseQueryOptions<PromotionDTO | null>,
) {
  const result = useQuery({
    queryKey: promotionQueryKeys.details(promotionId),
    queryFn: async () => {
      const result = await api.promotionApi.findById(promotionId as number);
      return result.data as PromotionDTO;
    },
    enabled: !!promotionId,
    initialData: null,
    ...queryOpts,
  });

  return result;
}
