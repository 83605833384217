import {
  AlertContext,
  PaymentConfirmationStatus,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import qs from "qs";
import { useContext, useEffect } from "react";
import Alert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router";

import { usePortalRoutes, useRedirectUrl } from "../../hooks";
import { useSaleResult } from "../../hooks/useSaleResult";
import { RouteFeature } from "../../routes/feature";
import useGymflowModels from "../../store";

export function SaleCompleteSca() {
  const { api } = useGymflowModels();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const history = useHistory();
  const { saleResult, paymentIntentId } = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });
  const { setAlert, hide } = useContext(AlertContext);
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();

  const { getFromMessageResult, getRedirectUrlAfterPurchaseLinkForSaleResult } =
    useSaleResult();

  const showError = (status) => {
    let alertType;
    let message;
    if (status === PaymentConfirmationStatus.Failed) {
      alertType = "error";
      message =
        "Your payment authorization was not successfull. However an account was created with your details. Check your email to finish the sign up progress and try to pay this invoice in the Customer Portal.";
    }

    setAlert(
      <Alert
        title="Oh no!"
        type={alertType}
        closeOnClickOutside={false}
        style={{
          width: "39em",
        }}
        showCloseButton
        onConfirm={() => {
          hide();
          history.push(
            getRedirectUrlAfterPurchaseLinkForSaleResult(saleResult),
          );
          history.go();
        }}
      >
        {message}
      </Alert>,
    );
  };

  useEffect(() => {
    const showConfirmationAlert = async () => {
      const paymentConfirmationResult = await showPaymentConfirmationAlert({
        paymentIntentIdForAuthorization: paymentIntentId,
        confirmPayment:
          api.public.strongCustomerAuthorizationApi.confirmPayment,
        messageText: (
          <>
            Your bank has requested additional authorization to make this
            payment.
            <br />
            Please check your email and click the authorization link to complete
            this purchase.
          </>
        ),
        hideCancel: true,
      });

      if (
        paymentConfirmationResult.status === PaymentConfirmationStatus.Success
      ) {
        history.push({
          pathname: createSiteOrEmbedLink(RouteFeature.SiteSaleComplete),
          search: qs.stringify({ saleResult }),
        });
      } else {
        showError(paymentConfirmationResult.status);
      }
    };

    if (!paymentIntentId) {
      return;
    }
    showConfirmationAlert();
  }, []);

  useEffect(() => {
    if (!saleResult || paymentIntentId) {
      return;
    }

    const { type, message, title } = getFromMessageResult(saleResult);
    setAlert(
      <Alert
        title={title}
        type={type}
        closeOnClickOutside={false}
        style={{
          width: "39em",
        }}
        showCloseButton
        onConfirm={() => {
          hide();
          redirectToCustomizedWebsiteOr(
            getRedirectUrlAfterPurchaseLinkForSaleResult(saleResult),
          );
        }}
      >
        {message}
      </Alert>,
    );
  }, [saleResult, redirectToCustomizedWebsiteOr]);
  return <div className="track-height" style={{ minHeight: "400px" }} />;
}
