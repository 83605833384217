import "react-day-picker/style.css";
import "./DatePicker.css";

import { faChevronDown, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { PopoverClose } from "@radix-ui/react-popover";
import { cva, VariantProps } from "class-variance-authority";
import { omit } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { DateRange, DayPicker, DayPickerProps } from "react-day-picker";

import { useClubSettings } from "../../providers";
import {
  Button,
  buttonVariants,
  CalendarWithNumberIcon,
  TextInputClassNames,
} from "../atoms";
import { Popover, PopoverContent, PopoverTrigger } from "../atoms/Popover";

const datePickerVariants = cva("", {
  variants: {
    inputType: {
      dropdown: cn(
        buttonVariants({ size: "small", intent: "default" }),
        "justify-between bg-[#fff] text-gray-700",
      ),
      field: cn(
        TextInputClassNames,
        "flex justify-between bg-[#fff] text-gray-700",
      ),
    },
  },
  defaultVariants: {
    inputType: "dropdown",
  },
});

export type DatePickerProps<T> = DayPickerProps & {
  showQuickNavigation?: boolean;
  label?: string;
  isClearable?: T;
  isDisabled?: boolean;
  inputClassnames?: string;
} & VariantProps<typeof datePickerVariants> &
  (
    | {
        mode: "single";
        handleDateSave?: (
          date: T extends true ? Date | undefined : Date,
        ) => void;
      }
    | {
        mode: "multiple";
        handleDateSave?: (
          date: T extends true ? Date[] | undefined : Date[],
        ) => void;
      }
    | {
        mode: "range";
        handleDateSave?: (
          date: T extends true ? DateRange | undefined : DateRange,
        ) => void;
      }
    | { mode: undefined; handleDateSave?: (date: undefined) => void }
    | undefined
  );

/*** All dates are in local time  */
export const DatePicker = <T,>(props: DatePickerProps<T>) => {
  const settings = useClubSettings();
  const [month, setMonth] = useState(
    DateTime.now().setZone(settings.timezone).toJSDate(),
  );

  const selectedProp = (props as any).selected;
  const [currentSelected, setCurrentSelected] = useState<any>(selectedProp);

  useEffect(() => {
    if (selectedProp) {
      switch (props.mode) {
        case "single":
          setMonth(selectedProp);
          break;
        case "range":
          if (selectedProp?.start) {
            setMonth(selectedProp.start);
          }
          break;
        case "multiple":
          if (selectedProp?.[0]) {
            setMonth(selectedProp[0]);
          }
          break;
      }
    }
  }, [selectedProp, props.mode]);

  const dayPickerProps = useMemo(() => {
    let result = omit(props, [
      "showQuickNavigation",
      "isClearable",
      "label",
      "handleDateSave",
    ]) as DayPickerProps;
    if (props.handleDateSave) {
      (result as any).selected = currentSelected;
      (result as any).onSelect = setCurrentSelected as any;
    }
    return result;
  }, [currentSelected, props]);
  return (
    <Popover>
      <PopoverTrigger
        disabled={props.isDisabled}
        className={cn(
          datePickerVariants({ inputType: props.inputType }),
          { "bg-gray-100": props.isDisabled },
          props.inputClassnames,
        )}
      >
        <div>{props.label ?? "Select Date..."}</div>
        <div className="flex flex-row items-center gap-x-2">
          {props.isClearable && (dayPickerProps as any).selected && (
            <FontAwesomeIcon
              onClick={(e) => {
                props.handleDateSave?.(undefined as any);
                setCurrentSelected(undefined as any);
                e.stopPropagation();
              }}
              className="h-3 w-3"
              icon={faClose}
            />
          )}
          {(props.inputType === "dropdown" || !props.inputType) && (
            <FontAwesomeIcon className="h-3 w-3" icon={faChevronDown} />
          )}
          {props.inputType === "field" && (
            <CalendarWithNumberIcon pathClassName="stroke-gray-500" />
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent>
        {props.showQuickNavigation && (
          <>
            <div className="flex flex-col p-4">
              {[
                {
                  label: "Today",
                  onClick: () => setMonth(DateTime.now().toJSDate()),
                },
                {
                  label: "Yesterday",
                  onClick: () =>
                    setMonth(DateTime.now().minus({ days: 1 }).toJSDate()),
                },
                {
                  label: "This week",
                  onClick: () =>
                    setMonth(
                      DateTime.now()
                        .setZone(settings.timezone)
                        .startOf("week")
                        .toJSDate(),
                    ),
                },
                {
                  label: "Last week",
                  onClick: () =>
                    setMonth(
                      DateTime.now()
                        .setZone(settings.timezone)
                        .minus({ weeks: 1 })
                        .startOf("week")
                        .toJSDate(),
                    ),
                },
                {
                  label: "This month",
                  onClick: () =>
                    setMonth(
                      DateTime.now()
                        .setZone(settings.timezone)
                        .startOf("month")
                        .toJSDate(),
                    ),
                },
                {
                  label: "Last month",
                  onClick: () =>
                    setMonth(
                      DateTime.now()
                        .setZone(settings.timezone)
                        .minus({ months: 1 })
                        .startOf("month")
                        .toJSDate(),
                    ),
                },
                {
                  label: "This year",
                  onClick: () =>
                    setMonth(
                      DateTime.now()
                        .setZone(settings.timezone)
                        .startOf("year")
                        .toJSDate(),
                    ),
                },
                {
                  label: "Last year",
                  onClick: () =>
                    setMonth(
                      DateTime.now()
                        .setZone(settings.timezone)
                        .minus({ years: 1 })
                        .startOf("year")
                        .toJSDate(),
                    ),
                },
              ].map((e, i) => {
                return (
                  <div
                    key={i}
                    className="flex w-full min-w-fit cursor-pointer whitespace-nowrap rounded p-2 font-medium hover:bg-gray-50"
                    onClick={() => {
                      e.onClick();
                    }}
                  >
                    {e.label}
                  </div>
                );
              })}
            </div>
            <div className="flex border-r border-gray-200">&nbsp;</div>
          </>
        )}
        <div className="flex flex-col">
          <div className="flex p-4 dark:text-[#ffffff]">
            <DayPicker
              month={month}
              onMonthChange={(e) => setMonth(e)}
              {...dayPickerProps}
            />
          </div>
          {props.handleDateSave && (
            <>
              <div className="flex w-full border-t border-gray-200"></div>
              <div className="flex w-full flex-row items-center justify-end gap-2 p-4">
                <PopoverClose asChild>
                  <Button
                    onClick={() => {
                      setCurrentSelected((props as any).selected);
                    }}
                  >
                    Cancel
                  </Button>
                </PopoverClose>
                <PopoverClose asChild>
                  <Button
                    onClick={() => {
                      (props.handleDateSave as any)?.(currentSelected);
                    }}
                    intent="secondary"
                  >
                    Apply
                  </Button>
                </PopoverClose>
              </div>
            </>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
