import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { CalendarEventOccurrence } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Optional } from "utility-types";

import { calendarAsPublicQueryKeys } from "./calendarAsPublicQueryKeys";

export function useInfiniteQueryCalendarEventOccurrencesAsPublic({
  api,
  tz,
  filters,
}: {
  api: CalendarEventOccurrencesAsPublicApi;
  tz: string;
  filters: Optional<
    CalendarEventOccurrenceAsPublicFilter,
    "dateFrom" | "dateTo"
  >;
}) {
  return useInfiniteQuery({
    queryKey: calendarAsPublicQueryKeys.eventOccurrences({ tz, filters }),
    queryFn: ({ pageParam }) => {
      return calendarEventOccurrencesAsPublicQueryFn({
        tz,
        api,
        filters: {
          ...(filters as CalendarEventOccurrenceAsPublicFilter),
          nextPageToken: pageParam,
        },
      });
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    select: (data) => calendarEventOccurrencesAsPublicSelectFn(data, tz),
    enabled: !!tz && (!!filters?.["dateFrom"] || !!filters?.["dateTo"]),
  });
}

export async function calendarEventOccurrencesAsPublicQueryFn({
  api,
  tz,
  filters,
}: {
  api: CalendarEventOccurrencesAsPublicApi;
  tz: string;
  filters: CalendarEventOccurrenceAsPublicFilter;
}) {
  const result = await api.public.eventApi.calendarEventOccurrences({
    filters: createApiParams({
      filters,
      tz,
    }),
  });
  return result.data;
}

const createApiParams = ({
  filters,
  tz,
}: {
  filters: CalendarEventOccurrenceAsPublicFilter;
  tz: string;
}) => {
  const parsed: { dateFrom?: string; dateTo?: string } = {};

  if (filters?.["dateFrom"]) {
    parsed["dateFrom"] = zonedTimeToUtc(filters["dateFrom"], tz);
  }

  if (filters?.["dateTo"]) {
    parsed["dateTo"] = zonedTimeToUtc(filters["dateTo"], tz);
  }

  return {
    ...filters,
    ...parsed,
  };
};

export function calendarEventOccurrencesAsPublicSelectFn(
  data: {
    pages: CalendarEventOccurrenceAsPublicResult[];
    pageParams: unknown[];
  },
  tz: string,
) {
  return {
    ...data,
    pages: data.pages.map((page) =>
      page.content.map((eventOccurrence) => ({
        ...eventOccurrence,
        startDate: utcToZonedTime(eventOccurrence.startDate, tz),
        endDate: utcToZonedTime(eventOccurrence.endDate, tz),
      })),
    ),
  };
}

interface CalendarEventOccurrenceAsPublicFilter {
  dateFrom: string;
  dateTo: string;
  limit: number;
  nextPageToken?: string;
  includeBookedCounts?: boolean;
  includeWaitingCounts?: boolean;
  eventHostId?: string[];
  facilityId?: number[];
  activityId?: number[];
  isPublicEvent?: boolean;
  activityCategoryId?: number[];
}

interface CalendarEventOccurrenceAsPublicResult {
  content: CalendarEventOccurrence[];
  nextPageToken?: string;
}

interface CalendarEventOccurrencesAsPublicApi {
  public: {
    eventApi: {
      calendarEventOccurrences: ({
        filters,
      }: {
        filters: CalendarEventOccurrenceAsPublicFilter;
      }) => Promise<{ data: CalendarEventOccurrenceAsPublicResult }>;
    };
  };
}
