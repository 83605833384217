import { SessionPackSummaryDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { sessionPackQueryKeys } from "./sessionPackQueryKeys";

export function useQueryCreditPackSummary(
  {
    api,
    opts,
    enabled,
  }: {
    api: {
      creditPackApi: {
        summary: (args: {
          creditPackId: number;
          promotionCode?: string;
          userMemberId: string;
        }) => Promise<{ data: SessionPackSummaryDTO }>;
      };
    };
    enabled?: boolean;
    opts?: {
      creditPackId: number;
      promotionCode?: string;
      userMemberId: string;
    };
  },
  queryOptions?: UseQueryOptions<SessionPackSummaryDTO>,
) {
  const result = useQuery({
    queryKey: sessionPackQueryKeys.summary(opts),
    queryFn: async () => {
      return (await api.creditPackApi.summary(opts!)).data;
    },
    enabled: !!opts && enabled,
    ...queryOptions,
  });
  return result;
}
