export const CreditCardDownloadIcon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={pathClassName}
      d="M7.49996 14.1668L9.99996 16.6668M9.99996 16.6668L12.5 14.1668M9.99996 16.6668V10.8335M18.3333 7.50016H1.66663M4.58329 15.0002H4.33329C3.39987 15.0002 2.93316 15.0002 2.57664 14.8185C2.26304 14.6587 2.00807 14.4037 1.84828 14.0901C1.66663 13.7336 1.66663 13.2669 1.66663 12.3335V6.00016C1.66663 5.06674 1.66663 4.60003 1.84828 4.24351C2.00807 3.92991 2.26304 3.67494 2.57664 3.51515C2.93316 3.3335 3.39987 3.3335 4.33329 3.3335H15.6666C16.6 3.3335 17.0668 3.3335 17.4233 3.51515C17.7369 3.67494 17.9918 3.92991 18.1516 4.24351C18.3333 4.60003 18.3333 5.06674 18.3333 6.00016V12.3335C18.3333 13.2669 18.3333 13.7336 18.1516 14.0901C17.9918 14.4038 17.7369 14.6587 17.4233 14.8185C17.0668 15.0002 16.6 15.0002 15.6666 15.0002H15.4166"
      stroke="#0C111D"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
