import {
  AvailabilityOverrideDTO,
  AvailabilityOverridePostDTO,
} from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityOverrideCreate({
  api,
}: {
  api: {
    availabilityApi: {
      createFacilityOverride: (
        facilityId: number,
        isUnavailableAllDay: boolean,
        startTime: string,
        endTime: string,
        overrideDate: string,
      ) => Promise<{ data: AvailabilityOverrideDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      newOverride,
    }: {
      facilityId: number;
      newOverride: AvailabilityOverridePostDTO;
    }) => {
      const response = await api.availabilityApi.createFacilityOverride(
        facilityId,
        newOverride.isUnavailableAllDay,
        newOverride.startTime,
        newOverride.endTime,
        newOverride.overrideDate,
      );
      return response.data;
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allFacilityOverrides(facilityId),
      });
    },
  });

  return mutation;
}
