import { subject } from "@casl/ability";
import { useAbility } from "@casl/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useInfiniteQueryCalendarAppointments,
  useInfiniteQueryCalendarEventOccurrences,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_SECONDS,
  DateTimeSelect,
  humanizeTimeInterval,
  SmallEventCard,
} from "@gymflow/common";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { SidebarToggleContext } from "apps/portal/src/providers/SidebarToggleContext";
import classNames from "classnames";
import { format } from "date-fns";
import { DateTime } from "luxon";
import moment, { Moment } from "moment-timezone";
import QueryString from "qs";
import { useContext, useState } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { AbilityContext, anyRecord, Subject, Verb } from "../../../permissions";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import { SlideSideBar } from "../../atoms";

export interface CalendarSideBarProps {
  isCalendarOpened: boolean;
  hide: () => void;
}
export const CalendarSideBar: React.FC<CalendarSideBarProps> = ({
  isCalendarOpened,
  hide,
}) => {
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  const [eventSelectedDate, setEventSelectedDate] = useState<string | null>(
    null,
  );
  const { api } = useGymflowModels();

  const { timezone } = useClubSettings();
  const { id: loggedInId } = useAuthenticatedUser();

  const ability = useAbility(AbilityContext);
  const canViewAllEvents = ability.can(
    Verb.View,
    subject(Subject.StaffCalendar, anyRecord()),
  );
  const { data: eventOccurrences } = useInfiniteQueryCalendarEventOccurrences({
    api,
    tz: timezone,
    filters: {
      dateFrom: (eventSelectedDate ? moment(eventSelectedDate) : moment())
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format(DATE_FORMAT_WITH_SECONDS),
      dateTo: (eventSelectedDate ? moment(eventSelectedDate) : moment())
        .endOf("day")
        .format(DATE_FORMAT_WITH_SECONDS),
      eventHostId: canViewAllEvents && loggedInId ? [] : [loggedInId as string],
      includeBookedCounts: true,
      includeWaitingCounts: true,
      limit: 100,
    },
  });
  const { data: appointments } = useInfiniteQueryCalendarAppointments({
    api,
    tz: timezone,
    filters: {
      dateFrom: (eventSelectedDate ? moment(eventSelectedDate) : moment())
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .format(DATE_FORMAT_WITH_SECONDS),
      dateTo: (eventSelectedDate ? moment(eventSelectedDate) : moment())
        .endOf("day")
        .format(DATE_FORMAT_WITH_SECONDS),
      appointmentHostId:
        canViewAllEvents && loggedInId ? [] : [loggedInId as string],
      limit: 100,
    },
  });
  const { hideSidebar } = useContext(SidebarToggleContext);
  const eventCards = [
    ...(eventOccurrences?.pages.flatMap((e) => e) ?? []),
    ...(appointments?.pages.flatMap((e) => e) ?? []),
  ]
    .sort((a, b) =>
      DateTime.fromISO(a.startDate) > DateTime.fromISO(b.startDate) ? 1 : -1,
    )
    .map((occurrence) => {
      const occurrenceOccupancyStatus =
        "eventOccurrenceId" in occurrence
          ? occurrence.bookedCount! < occurrence.capacity!
            ? "FREE"
            : occurrence.waitingCount! < occurrence.waitListCapacity!
            ? "WAITLIST"
            : "FULL"
          : undefined;

      return (
        <SmallEventCard
          key={
            "appointmentId" in occurrence
              ? occurrence.appointmentId
              : occurrence.eventOccurrenceId
          }
          status={occurrenceOccupancyStatus}
          eventName={
            "appointmentId" in occurrence
              ? occurrence?.appointableName
              : occurrence.activityName
          }
          startTime={format(new Date(occurrence.startDate), "h:mm a")}
          duration={humanizeTimeInterval(
            occurrence.startDate,
            occurrence.endDate,
          )}
          capacity={
            "appointmentId" in occurrence ? undefined : occurrence?.capacity
          }
          bookedCount={
            "appointmentId" in occurrence ? undefined : occurrence?.bookedCount
          }
          hostName={occurrence?.hostName ?? occurrence.facilityName ?? ""}
          onClick={() => {
            hideSidebar();
            if ("eventOccurrenceId" in occurrence) {
              history.push({
                pathname: createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.EventOccurrence.replace(
                    ":eventId",
                    occurrence.eventOccurrenceId.toString(),
                  ),
                ),
              });
            } else {
              history.push({
                pathname: createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.Calendar,
                ),
                search: QueryString.stringify({
                  date: occurrence.startDate,
                  appointmentId: occurrence.appointmentId,
                }),
              });
            }
            setTimeout(() => hide(), 300);
          }}
        />
      );
    });
  return (
    <SlideSideBar isOpen={isCalendarOpened} hide={hide}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="my-4 flex flex-row items-center justify-between px-6">
          <div className="text-xl font-semibold text-gray-900">Calendar</div>
          <FontAwesomeIcon
            onClick={() => {
              hide();
            }}
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </div>
        <div className="flex px-6">
          <DateTimeSelect
            value={
              eventSelectedDate === null
                ? undefined
                : moment(eventSelectedDate).format(DATE_FORMAT)
            }
            onChange={(v: any) =>
              setEventSelectedDate(moment(v).format(DATE_FORMAT_WITH_SECONDS))
            }
            placeholder="Today"
            isValidDate={(date: Moment) =>
              date.isBefore(moment().add(1, "year"))
            }
          />
        </div>
        <div className="mt-4 flex flex-col overflow-y-auto px-6">
          <div
            className={classNames("text-muted", {
              "d-none": eventCards ? eventCards.length > 0 : true,
            })}
          >
            No events.
          </div>
          {eventCards}
        </div>
      </div>
    </SlideSideBar>
  );
};
