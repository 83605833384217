import { ExpiryType, SubscriptionStatus } from "@gymflow/common";
import classNames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import { Card, CardBody } from "reactstrap";

import { useClubSettings } from "../../providers";
import styles from "./UserMemberCredits.module.css";

const responsive = {
  wider: { breakpoint: { max: 4000, min: 2000 }, items: 5 },
  desktop: { breakpoint: { max: 2000, min: 1024 }, items: 3 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1.2 },
};

function UserMemberCredits({ creditPacks }) {
  const settings = useClubSettings();
  const dateFormat = settings.date_format;

  return (
    <Carousel partialVisible={false} responsive={responsive}>
      {creditPacks.map((item) => {
        return (
          <div key={item.id} style={{ maxWidth: 350, marginRight: 16 }}>
            <Card>
              <CardBody>
                <h2 className="font-weight-bold mb-2">{item.name}</h2>
                <div>
                  <div
                    className={classNames(
                      styles["credit-pack-remaining"],
                      "mb-2",
                      {
                        invisible:
                          !item.unlimited && item.remaining === undefined,
                      },
                    )}
                  >
                    {item.unlimited
                      ? "∞"
                      : `${item.remaining}/${item.totalCredits}`}{" "}
                    credits remaining
                  </div>
                </div>
                {item.status === SubscriptionStatus.Active && (
                  <div
                    className={classNames("text-muted mb-2", {
                      invisible: item.expiryType === ExpiryType.NA,
                    })}
                  >
                    Credits Expire:
                    {" " + moment(item.expiry).format(dateFormat)}
                  </div>
                )}
                {item.status === SubscriptionStatus.Pending && (
                  <div className="text-muted mb-2">
                    Active from:
                    {" " + moment(item.startDate).format(dateFormat)}
                  </div>
                )}
                <div
                  style={{
                    fontSize: "0.75rem",
                    marginBottom: 0,
                    textDecoration: "underline",
                  }}
                >
                  <a
                    href={item.termsConditions}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & Conditions
                  </a>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      })}
    </Carousel>
  );
}

UserMemberCredits.propTypes = {
  creditPacks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      remaining: PropTypes.number,
      unlimited: PropTypes.bool.isRequired,
      expiry: PropTypes.string.isRequired,
      expiryType: PropTypes.oneOf(Object.values(ExpiryType)),
      status: PropTypes.oneOf(Object.values(SubscriptionStatus)),
      categoryNames: PropTypes.arrayOf(PropTypes.string),
      termsConditions: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default UserMemberCredits;
