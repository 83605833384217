import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation } from "@tanstack/react-query";


export function useMutationMemberInvoice({
  api,
  memberId,
  clubId,
}: {
  api: ApiType;
  memberId: string;
  clubId: number;
}) {
  const result = useMutation({
    mutationFn: async ({ invoiceNumber }: { invoiceNumber: string }) => {
      return await api.memberApi.invoiceFile(memberId, clubId, invoiceNumber);
    },
  });

  return result;
}
