import { utcToZonedTime } from "@gymflow/helpers";
import merge from "lodash/merge";

export function eventOccurrenceToZonedTime(occurrence: any) {
  const tz = occurrence.event.club.zoneId;
  const eventStart = utcToZonedTime(
    `${occurrence.event.startDate}T${occurrence.event.startTime}Z`,
    tz,
  );
  const [eventStartDate, eventStartTime] = eventStart.split("T");
  const eventEnd = utcToZonedTime(
    `${occurrence.event.endDate}T${occurrence.event.endTime}Z`,
    tz,
  );
  const [eventEndDate, eventEndTime] = eventEnd.split("T");
  const eventInUtc = {
    startDate: eventStartDate,
    startTime: eventStartTime,
    endDate: eventEndDate,
    endTime: eventEndTime,
  } as any;

  if (occurrence.event.startRecurringDate) {
    const recurringStart = utcToZonedTime(
      `${occurrence.event.startRecurringDate}T${occurrence.event.startTime}Z`,
      tz,
    );
    const [recurringStartDate] = recurringStart.split("T");
    eventInUtc.startRecurringDate = recurringStartDate;
  }

  if (occurrence.event.endRecurringDate) {
    const recurringEnd = utcToZonedTime(
      `${occurrence.event.endRecurringDate}T${occurrence.event.endTime}Z`,
      tz,
    );
    const [recurringEndDate] = recurringEnd.split("T");
    eventInUtc.endRecurringDate = recurringEndDate;
  }

  return merge({}, occurrence, {
    event: eventInUtc,
    startDate: utcToZonedTime(occurrence.startDate, tz),
    endDate: utcToZonedTime(occurrence.endDate, tz),
  });
}
