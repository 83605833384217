import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useClubFeatureFlags } from "@gymflow/api";
import { zonedTime } from '@gymflow/helpers';
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import classNames from 'classnames';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import { useContext, useMemo, useState } from 'react';
import Select from 'react-select';
import { Button, ButtonGroup } from 'reactstrap';

import { CreateEditTaskSidebarProviderContext } from "../components/organisms";
import StaffSelect from '../components/StaffSelect';
import { CompleteTasksTable } from '../components/Task/CompleteTasksTable';
import { UncompleteTasksTable } from '../components/Task/UncompleteTasksTable';
import { useEditOrCreateTask } from '../hooks/useEditOrCreateTask';

const filterButtonGroup = [
  { label: 'All Tasks', key: 0, color: 'primary' },
  { label: 'Overdue', key: 1, color: 'danger' },
  { label: 'Today', key: 2, color: 'warning' },
];

const STATUS = {
  IN_COMPLETED: 'Incomplete',
  COMPLETED: 'Completed',
};

const statusOptions = Object.keys(STATUS).map((k) => ({ label: STATUS[k], value: k }));

export function Tasks() {
  const { openCreateTaskSidebar } = useContext(CreateEditTaskSidebarProviderContext);
  const [activeBtnFilter, setActiveBtnFilter] = useState(0);
  const [owners, setOwners] = useState();
  const [status, setStatus] = useState('IN_COMPLETED');
  const { api } = useGymflowModels();
  const { clubId, timezone } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  const { setEditingTaskId, showAlert } = useEditOrCreateTask();

  const filters = useMemo(() => {
    const options = {
      'taskOwners.id': owners?.map(({ value }) => value.id),
      complete: status === 'COMPLETED',
    };
    if (activeBtnFilter === 1) {
      options.complete = false;
      options.dateTo = zonedTime(timezone);
    } else if (activeBtnFilter === 2) {
      options.dateFrom = zonedTime(timezone, startOfDay(new Date()));
      options.dateTo = zonedTime(timezone, endOfDay(new Date()));
    }

    return options;
  }, [activeBtnFilter, owners, status, timezone]);

  return (
    <div className="flex flex-col overflow-y-auto p-8">
      <div className="flex w-full">
        <ButtonGroup className="mb-sm-0 d-block d-sm-inline-block mb-1">
          {filterButtonGroup.map((button) => (
            <Button
              key={button.key}
              style={{ width: 100 }}
              size="sm"
              className="mr-2 p-2"
              color={button.color}
              outline={activeBtnFilter !== button.key}
              onClick={() => setActiveBtnFilter(button.key)}
            >
              {button.label}
            </Button>
          ))}
        </ButtonGroup>
        <div className="d-flex align-items-center mb-1">
          <h2 className="d-none d-sm-block mb-0 mr-2">Filters:</h2>
          <StaffSelect isMulti={true} placeholder="Owner" className="react-select-btn mr-2" onChange={setOwners} />
          <Select
            components={{
              DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
                if (menuIsOpen) {
                  return <FontAwesomeIcon icon={faAngleUp} className="ml-2 mr-4 text-gray-500" />;
                }
                return <FontAwesomeIcon icon={faAngleDown} className="ml-2 mr-4 text-gray-500" />;
              },
              IndicatorSeparator: () => {
                return null;
              },
              ValueContainer: ({ children }) => {
                return (
                  <div className="ml-4 flex max-w-[70%] items-center overflow-x-hidden font-semibold [&>div]:!text-gray-500">
                    {children}
                  </div>
                );
              },
            }}
            classNames={{
              control: () =>
                '!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap',
              menu: () => '!z-[9999] !rounded-lg',
              option: () => classNames(`!cursor-pointer hover:!bg-primary-50 !bg-white !text-gray-800`),
              valueContainer: () => 'border-none rounded-xl shadow-none h-[calc(2.75rem-2px)] cursor-pointer',
            }}
            className="bg-gray-25 flex h-11 min-w-[10rem] cursor-pointer items-center rounded-lg border border-gray-300 text-center hover:bg-gray-100"
            options={statusOptions}
            onChange={({ value }) => {
              setStatus(value);
            }}
            value={statusOptions.find((op) => op.value === status)}
          />
        </div>
        <Button 
          onClick={() => 
            featureFlags?.featureFlags.PORTAL_REDESIGN_TASK ? openCreateTaskSidebar() : showAlert()
          }
        >
          Create Task
        </Button>
      </div>
      {filters.complete ? (
        <CompleteTasksTable filters={filters} setEditingTaskId={setEditingTaskId} />
      ) : (
        <UncompleteTasksTable filters={filters} setEditingTaskId={setEditingTaskId} />
      )}
    </div>
  );
}
