import { useContext, useEffect } from 'react';
import qs from 'qs';
import { AlertContext } from '@gymflow/common';
import { formatCurrency } from '@gymflow/helpers';
import { AlertProvider } from '@gymflow/common';

function GenericSecurePayment({ match, history }) {
  const search = qs.parse(history?.location?.search, { ignoreQueryPrefix: true });
  const { show, hide } = useContext(AlertContext);

  const renderSuccess = () => {
    const invoiceAmount = formatCurrency(parseFloat(search.invoiceAmount), search.currency);

    show(
      <>
        Completed payment of {invoiceAmount}.

      </>,
      {
        title: 'Authorization Successfull',
        type: 'success',
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          hide();
          history.push('/');
        },
      }
    );
  };

  const renderError = () => {
    show(
      <>
        Authorization failed, please contact your club to complete purchase.
      </>,
      {
        title: 'Payment failed',
        type: 'error',
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          hide();
          history.push('/');
        },
      }
    );
  };

  const renderAuthorizationNotRequired = () => {
    show(
      <>
        This payment has already been authorised or does not require authorization.
      </>,
      {
        title: 'Authorization not needed',
        type: 'success',
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          hide();
          history.push('/');
        },
      }
    );
  };

  useEffect(() => {
    if (search.result === 'SUCCEEDED') {
      renderSuccess();
    } else if (search.result === 'PAYMENT_AUTHORIZATION_NOT_REQUIRED') {
      renderAuthorizationNotRequired();
    } else {
      renderError();
    }
  }, [search.result]);

  return <div />;
}

function GenericSecurePaymentLayout(props) {
  return (
    <AlertProvider>
      <GenericSecurePayment {...props} />
    </AlertProvider>
  );
}

export { GenericSecurePaymentLayout as GenericSecurePayment };
