import classNames from "classnames";
import { useContext, useEffect, useState } from "react";

import { Button, TextInput } from "../../../components/atoms";
import environment from "../../../environment";
import { useApiResolverContext } from "../../ApiResolverProvider";
import { useAuthenticatedUser } from "../AuthenticatedProvider";
import { LoginOverlayContext } from "./context";

export function LoginForm({ defaultEmail }: { defaultEmail: string }) {
  const { closeOverlay } = useContext(LoginOverlayContext);
  const {
    urls: { auth: authUrl },
    authRealm,
  } = useApiResolverContext();

  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState("");
  const auth = useAuthenticatedUser();
  const [errorMessage, setErrorMessage] = useState("");
  const [delayedInputShow, setDelayedInputShow] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDelayedInputShow(true);
    }, 200);
  }, []);
  return (
    <form
      className="flex h-screen w-full flex-col gap-8 overflow-y-auto md:h-auto "
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="flex flex-col items-center gap-6">
        <div className="dark:text-darkGray-50 text-2xl font-bold text-gray-900">
          Sign In
        </div>
        <div className="dark:text-darkGray-400 text-center text-sm font-medium text-gray-600">
          Log in to complete your booking or purchase.
        </div>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600 dark:text-gray-400">Email:</div>
          {delayedInputShow && (
            <TextInput
              autoFocus
              value={email}
              onChange={({ currentTarget: { value } }) => {
                setEmail(value);
              }}
            />
          )}
        </div>
        <div className="flex flex-col gap-2">
          <div className="text-sm text-gray-600 dark:text-gray-400">
            Password:
          </div>
          <TextInput
            type="password"
            value={password}
            onChange={({ currentTarget: { value } }) => {
              setPassword(value);
            }}
          />
        </div>
        <div
          className={classNames("text-error-500", { hidden: !errorMessage })}
        >
          {errorMessage}
        </div>
        <div>
          <Button
            intent="primary"
            type="submit"
            className="mt-2 w-full"
            onClick={async () => {
              const response = await auth.login(email, password);

              if (response.isLoggedIn) {
                if (response.roles?.includes("MEMBER")) {
                  setErrorMessage("");
                  closeOverlay({ isLoggedIn: true });
                } else {
                  setErrorMessage(
                    "Cannot login as a staff member in this page.",
                  );
                  auth.logout();
                }
              } else {
                setErrorMessage("Invalid email and password.");
              }
            }}
          >
            Continue
          </Button>
        </div>
        <div className="flex justify-center">
          <a
            className="text-primary-600 text-sm font-semibold"
            href={`${authUrl}/realms/${authRealm}/login-actions/reset-credentials?client_id=${environment.get(
              "KEYCLOAK_CLIENT_ID",
            )}`}
          >
            Forgot Password
          </a>
        </div>
      </div>
    </form>
  );
}
