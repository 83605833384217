import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody } from 'reactstrap';

const OverdueBanner = ({ amountDue, onPayClick }) => {
  return (
    <Card style={{ maxWidth: '1000px' }}>
      <CardBody>
        <div>
          Your membership is temporarily inactive due to an overdue payment. Please pay your outstanding balance and
          your membership will be re-activated instantly.
        </div>
        <div className="d-flex justify-content-between align-content-between">
          <div className="font-weight-bold align-self-end">Amount due: {amountDue}</div>
          <div>
            <Button color="primary" className="btn-sm btn-round" onClick={onPayClick}>
              Pay Now
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

OverdueBanner.propTypes = {
  amountDue: PropTypes.string.isRequired,
  onPayClick: PropTypes.func.isRequired,
};

export default OverdueBanner;
