import {
  useInfiniteQueryCreditsHistorySpent,
  useInfiniteQueryCreditsHistoryTopUps,
} from "@gymflow/api";
import { CreditSpentItem, CreditTopUpItem } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { usePageSize } from "apps/portal/src/hooks";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";

import { Tabs, TabType } from "../../atoms";
import { PaginatedTable } from "../../organisms";
import { CreditsBalanceBlock } from "./components/CreditsBalanceBlock";
import { formatDate } from "./utils/formatDate";

type TabsType = "SMS_USAGE" | "TOP_UPS";

const TABS: TabType<TabsType>[] = [
  {
    id: "SMS_USAGE",
    label: <Trans i18nKey="page.settings.tab.sms.tab.smsUsage" />,
  },
  {
    id: "TOP_UPS",
    label: <Trans i18nKey="page.settings.tab.sms.tab.topUps" />,
  },
];

export const SMSTab = () => {
  const [activeTabId, _setActiveTabId] = useState<TabsType>(TABS[0].id);
  const [currentPage, setCurrentPage] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const columnHelperTopUp = createColumnHelper<CreditTopUpItem>();
  const columnHelperSpent = createColumnHelper<CreditSpentItem>();
  const pageSize = usePageSize({ tableContainerRef, rowHeight: 56 });

  const topUpsQuery = useInfiniteQueryCreditsHistoryTopUps({
    api,
    params: { limit: pageSize },
    refetchOnWindowFocus: true,
  });

  const spentQuery = useInfiniteQueryCreditsHistorySpent({
    api,
    params: { limit: pageSize },
    refetchOnWindowFocus: true,
  });

  const columnsSMSUsage = useMemo(
    () => [
      columnHelperSpent.accessor(
        ({ messageBody, id }) => ({
          messageBody,
          id,
        }),
        {
          id: "message",
          header: () =>
            t(
              "page.settings.tab.sms.tableSection.smsUsage.table.header.message",
            ),
          enableSorting: false,
          cell: (cell) => {
            const { messageBody, id } = cell.getValue();

            return (
              <div>
                <Tooltip
                  className="!z-50 flex max-w-xs flex-col items-center rounded-lg !bg-black text-center text-sm font-medium"
                  id={`tooltip-${id}`}
                  openOnClick
                  place="top-start"
                />
                <div
                  data-tooltip-id={`tooltip-${id}`}
                  data-tooltip-content={messageBody}
                  className="line-clamp-1 text-sm font-medium text-gray-950"
                >
                  {messageBody}
                </div>
              </div>
            );
          },
        },
      ),
      columnHelperSpent.accessor("creditsAmount", {
        id: "smsCreditsUsed",
        header: () =>
          t(
            "page.settings.tab.sms.tableSection.smsUsage.table.header.smsCreditsUsed",
          ),
        enableSorting: false,
        cell: (cell) => (
          <div className="w-32 text-sm font-medium text-gray-950 lg:w-48">
            {cell.getValue()}
          </div>
        ),
      }),
      columnHelperSpent.accessor("createdDate", {
        id: "date",
        header: () =>
          t("page.settings.tab.sms.tableSection.smsUsage.table.header.date"),
        enableSorting: false,
        cell: (cell) => (
          <div className="w-24 text-sm font-medium text-gray-950 lg:w-48">
            {formatDate(cell.getValue(), timezone, true)}
          </div>
        ),
      }),
    ],
    [columnHelperSpent, t, timezone],
  );

  const columnsTopUps = useMemo(
    () => [
      columnHelperTopUp.accessor("creditsAmount", {
        id: "smsPackage",
        header: () =>
          t(
            "page.settings.tab.sms.tableSection.topUps.table.header.smsPackage",
          ),
        enableSorting: false,
        cell: (cell) => (
          <div className="text-sm font-medium text-gray-950">
            {t(
              "page.settings.tab.sms.tableSection.topUps.table.cell.smsPackage",
              { creditsAmount: cell.getValue() },
            )}
          </div>
        ),
      }),

      columnHelperTopUp.accessor("createdDate", {
        id: "date",
        header: () =>
          t("page.settings.tab.sms.tableSection.smsUsage.table.header.date"),
        enableSorting: false,
        cell: (cell) => (
          <div className="text-sm font-medium text-gray-950">
            {formatDate(cell.getValue(), timezone, true)}
          </div>
        ),
      }),
    ],
    [columnHelperTopUp, t, timezone],
  );

  const {
    columns,
    title,
    currentPageData,
    isLoading,
    hasPreviousPage,
    hasNextPage,
    goToNextPage,
  } = useMemo(() => {
    const { data, columns, title, isLoading, fetchNextPage } =
      activeTabId === "TOP_UPS"
        ? {
            data: topUpsQuery.data,
            columns: columnsTopUps,
            title: t("page.settings.tab.sms.tableSection.topUps.title"),
            isLoading: topUpsQuery.isLoading,
            fetchNextPage: topUpsQuery.fetchNextPage,
          }
        : {
            data: spentQuery.data,
            columns: columnsSMSUsage,
            title: t("page.settings.tab.sms.tableSection.smsUsage.title"),
            isLoading: spentQuery.isLoading,
            fetchNextPage: spentQuery.fetchNextPage,
          };

    return {
      columns,
      title,
      currentPageData: data?.pages[currentPage]?.content ?? [],
      isLoading,
      hasPreviousPage: !!data && currentPage > 0,
      hasNextPage: !!data && !!data.pages[currentPage]?.nextPageToken,
      goToNextPage: () => {
        setCurrentPage((prev) => prev + 1);
        if (!data?.pages[currentPage + 1]) {
          fetchNextPage();
        }
      },
    };
  }, [
    activeTabId,
    columnsSMSUsage,
    columnsTopUps,
    currentPage,
    spentQuery.data,
    spentQuery.fetchNextPage,
    spentQuery.isLoading,
    t,
    topUpsQuery.data,
    topUpsQuery.fetchNextPage,
    topUpsQuery.isLoading,
  ]);

  const setActiveTabId = (id: TabsType) => {
    _setActiveTabId(id);
    setCurrentPage(0);
  };

  return (
    <div className="flex h-full max-h-full w-full flex-col gap-6 overflow-y-auto">
      <CreditsBalanceBlock
        lastTopUpItem={topUpsQuery.data?.pages[0]?.content[0]}
      />
      <Tabs
        tabs={TABS}
        activeTabId={activeTabId}
        setActiveTabId={setActiveTabId}
      />
      <div className="flex h-full min-h-[24rem] flex-col gap-4 pb-8">
        <div className="text-lg font-semibold text-gray-950">{title}</div>
        <PaginatedTable
          tableProps={{
            data: currentPageData,
            columns,
            isFetching: isLoading,
            pageSize,
            tableContainerRef,
          }}
          hasPreviousPage={hasPreviousPage}
          hasNextPage={hasNextPage}
          goToNextPage={goToNextPage}
          goToPreviousPage={() => setCurrentPage((prev) => prev - 1)}
        />
      </div>
    </div>
  );
};
