import { ServiceType } from "@gymflow/common";
import { Redirect, Route, Switch } from "react-router-dom";

import SalesRoute from "../components/SalesWizard/SalesRoute";
import SellMembership from "../components/SalesWizard/SellMembership";
import SellProducts from "../components/SalesWizard/SellProducts";
import SellSessionPack from "../components/SalesWizard/SellSessionPack";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";

function Sales() {
  const { createClubLink } = usePortalRoutes();
  return (
    <Switch>
      <Route
        path={createClubLink(
          RouteLayout.Staff,
          RouteFeature.Sales,
          SalesRoute.RecurringMembership,
        )}
      >
        <SellMembership membershipType={ServiceType.Recurring} />
      </Route>
      <Route
        path={createClubLink(
          RouteLayout.Staff,
          RouteFeature.Sales,
          SalesRoute.PrepaidMembership,
        )}
      >
        <SellMembership membershipType={ServiceType.Prepaid} />
      </Route>
      <Route
        path={createClubLink(
          RouteLayout.Staff,
          RouteFeature.Sales,
          SalesRoute.SessionsPack,
        )}
      >
        <SellSessionPack />
      </Route>
      <Route
        path={createClubLink(
          RouteLayout.Staff,
          RouteFeature.Sales,
          SalesRoute.Product,
        )}
      >
        <SellProducts />
      </Route>
      <Route>
        <Redirect
          to={createClubLink(
            RouteLayout.Staff,
            RouteFeature.Sales,
            SalesRoute.RecurringMembership,
          )}
        />
      </Route>
    </Switch>
  );
}

export default Sales;
