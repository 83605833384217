import { useMutation } from "@tanstack/react-query";

export function useMutationStripeOnboardAccount({
  api,
}: {
  api: {
    clubApi: {
      stripeOnboardAccount: (clubId: number) => Promise<{ data: string }>;
    };
  };
}) {
  const mutation = useMutation({
    mutationFn: async ({ clubId }: { clubId: number }) => {
      const response = await api.clubApi.stripeOnboardAccount(clubId);
      return response.data;
    },
  });
  return mutation;
}
