import "react-multi-carousel/lib/styles.css";

import classNames from "classnames";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";

import { MembershipBillingType } from "../../constants/MembershipBillingType";
import MembershipCard from "../molecules/MembershipCard";

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1440, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function MembershipCarousel({ memberships, onSelectClick }) {
  return (
    <Carousel responsive={responsive} partialVisible={false}>
      {memberships.map(
        ({ id, name, description, price, isRecurring, billingType, billingPeriod, includes, plus }, idx) => (
          <div key={id} style={{ minHeight: "320px" }} className={classNames("h-100", "pr-2", { "pl-2": idx !== 0 })}>
            <MembershipCard
              name={name}
              description={description}
              price={price}
              isRecurring={isRecurring}
              billingType={billingType}
              billingPeriod={billingPeriod}
              includes={includes}
              onButtonClick={() => onSelectClick(id)}
              plus={plus}
            />
          </div>
        ),
      )}
    </Carousel>
  );
}

MembershipCarousel.defaultProps = {
  memberships: [],
};

MembershipCarousel.propTypes = {
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.string.isRequired,
      isRecurring: PropTypes.bool,
      billingType: PropTypes.oneOf(Object.values(MembershipBillingType)),
      billingPeriod: PropTypes.number,
      includes: PropTypes.arrayOf(PropTypes.string),
      plus: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
        }),
      ),
    }),
  ),
  onSelectClick: PropTypes.func.isRequired,
};

export default MembershipCarousel;
