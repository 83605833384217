import { AlertContext } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import qs from "qs";
import { useContext, useEffect } from "react";
import { AlertTriangle, Check } from "react-feather";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { RouteFeature } from "../../routes/feature";

export function SecurePayment() {
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const history = useHistory();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });
  const { show, hide } = useContext(AlertContext);

  const renderSuccess = () => {
    const invoiceAmount = formatCurrency(
      parseFloat(search.invoiceAmount),
      search.currency,
    );

    show(
      <>
        <Check size={80} color="rgb(92, 184, 92)" />
        <br />
        Completed payment of {invoiceAmount}.
      </>,
      {
        title: "Authorization Successfull",
        intent: "success",
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          hide();
          history.push(createSiteOrEmbedLink(RouteFeature.SiteTimetable));
        },
      },
    );
  };

  const renderError = () => {
    show(
      <>
        <AlertTriangle size={80} color="#ec250d" />
        <br />
        Authorization failed, please contact your club to complete purchase.
      </>,
      {
        title: "Payment failed",
        intent: "error",
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          hide();
          history.push(createSiteOrEmbedLink(RouteFeature.SiteTimetable));
        },
      },
    );
  };

  const renderAuthorizationNotRequired = () => {
    show(
      <>
        <AlertTriangle size={80} color="rgb(255, 193, 7)" />
        <br />
        This payment has already been authorised or does not require
        authorization.
      </>,
      {
        title: "Authorization not needed",
        intent: "success",
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          hide();
          history.push(createSiteOrEmbedLink(RouteFeature.SiteTimetable));
        },
      },
    );
  };

  useEffect(() => {
    if (search.result === "SUCCEEDED") {
      renderSuccess();
    } else if (search.result === "PAYMENT_AUTHORIZATION_NOT_REQUIRED") {
      renderAuthorizationNotRequired();
    } else {
      renderError();
    }
  }, [search.result]);

  return <div className="track-height" style={{ minHeight: "400px" }} />;
}
