import { action, computed, thunk, thunkOn } from "easy-peasy";

import ServiceType from "../api/ServiceType";
import { tzDateTimeStringToUtc, utcDateTimeStringToTz } from "../helpers/date";
import BaseModelBuilder, {
  createPayloadFromFindResponse,
  getApi,
} from "./BaseModelBuilder";

class MemberModelBuilder extends BaseModelBuilder {
  constructor(apiKey, settingsKey = "settings") {
    super(apiKey);
    this.settingsKey = settingsKey;
    this.generators.push(this.memberGenerator);
    this.generators.push(this.pictureGenerator);
    this.generators.push(this.subscriptionGenerator);
    this.generators.push(this.paymentMethodGenerator);
  }

  memberGenerator() {
    return {
      total: 0,

      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        const { data } = await getApi(injections, getState()).findById(
          recordId,
        );
        const payload = {
          ...data,
        };
        actions.fetchedRecord({
          ...payload,
          balanceDate: utcDateTimeStringToTz(payload.balanceDate, tz),
          subscriptions: payload.subscriptions.map(
            adjustTzSubscriptionMapGenerator(tz),
          ),
        });
        return payload;
      }),

      fetchOverdueMembers: thunk(
        async (
          actions,
          { page = 0, limit = 10, sort },
          { getState, injections },
        ) => {
          const userApi = getApi(injections, getState());
          const { data } = await userApi.overdueMembers({ page, limit, sort });

          const payload = createPayloadFromFindResponse(data);

          actions.fetchedList(payload);
        },
      ),

      fetchMembershipsEnding: thunk(
        async (
          actions,
          { startDate, endDate, page = 0, limit = 10, sort },
          { getState, injections },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          const userApi = getApi(injections, getState());
          const { data } = await userApi.membershipsEnding({
            startDate: tzDateTimeStringToUtc(startDate, tz),
            endDate: tzDateTimeStringToUtc(endDate, tz),
            page,
            limit,
            sort,
          });

          const payload = createPayloadFromFindResponse(data);

          actions.fetchedList({
            ...payload,
            rows: payload.rows.map((row) => ({
              ...row,
              expiryDate: utcDateTimeStringToTz(row.expiryDate, tz),
              cancellationDate: utcDateTimeStringToTz(row.cancellationDate, tz),
            })),
          });
        },
      ),

      fetchMembershipsPending: thunk(
        async (
          actions,
          { page = 0, limit = 10, sort },
          { getState, injections },
        ) => {
          const userApi = getApi(injections, getState());
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          const { data } = await userApi.pendingMemberships({
            page,
            limit,
            sort,
          });

          const payload = {
            ...createPayloadFromFindResponse(data),
            rows: data.content.map((item) => ({
              ...item,
              ...(item.startDate && {
                startDate: utcDateTimeStringToTz(item.startDate, tz),
              }),
            })),
          };

          actions.fetchedList(payload);
        },
      ),

      fetchEventAttendance: thunk(
        async (
          actions,
          { startDate, endDate, page = 0, limit = 10, sort },
          { getState, injections },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          const userApi = getApi(injections, getState());
          const utcStartDate = tzDateTimeStringToUtc(startDate, tz);
          const utcEndDate = tzDateTimeStringToUtc(endDate, tz);
          const { data } = await userApi.eventAttendance({
            startDate: utcStartDate,
            endDate: utcEndDate,
            page,
            limit,
            sort,
          });

          const payload = createPayloadFromFindResponse(data);

          const {
            data: { total },
          } = await userApi.eventAttendanceTotal(utcStartDate, utcEndDate);

          actions.fetchedList({ ...payload, total });
        },
      ),

      fetchServicePaused: thunk(
        async (
          actions,
          { page = 0, limit = 10, sort },
          { getState, injections },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          const userApi = getApi(injections, getState());
          const { data } = await userApi.servicePaused({ page, limit, sort });

          const payload = {
            ...createPayloadFromFindResponse(data),
            rows: data.content.map((item) => ({
              ...item,
              ...(item.resumeDate && {
                resumeDate: utcDateTimeStringToTz(item.resumeDate, tz),
              }),
              ...(item.pausedDate && {
                pausedDate: utcDateTimeStringToTz(item.pausedDate, tz),
              }),
            })),
          };

          actions.fetchedList(payload);
        },
      ),

      fetchMemberStatusList: thunk(
        async (
          actions,
          { page = 0, limit = 10, sort, extraParams },
          { getState, injections },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;

          const utcDateFrom =
            extraParams?.dateFrom &&
            tzDateTimeStringToUtc(extraParams?.dateFrom, tz);
          const utcDateTo =
            extraParams?.dateTo &&
            tzDateTimeStringToUtc(extraParams?.dateTo, tz);

          const userApi = getApi(injections, getState());
          const { data } = await userApi.memberStatusList({
            page,
            limit,
            sort,
            extraParams: {
              ...extraParams,
              dateFrom: utcDateFrom,
              dateTo: utcDateTo,
            },
          });

          const payload = createPayloadFromFindResponse(data);

          actions.fetchedList(payload);
        },
      ),

      fetchedList: action(
        (state, { rows, pageCount, page, total, rowsLimit, totalRecords }) => {
          state.rows = rows;
          state.pageCount = pageCount;
          state.page = page;
          state.total = total;
          state.rowsLimit = rowsLimit;
          state.totalRecords = totalRecords;
        },
      ),

      update: thunk(
        (_, { id, clubId, patchedFields }, { injections, getState }) =>
          getApi(injections, getState()).update(id, clubId, patchedFields),
      ),

      onUpdate: thunkOn(
        (actions) => [actions.update],
        async (actions, { payload }, { getState }) => {
          await actions.fetchById(getState().editing.id);
        },
      ),

      activeCreditPacks: computed(
        (state) =>
          state.editing &&
          state.editing.subscriptions
            .filter((sub) => sub.sessionPackBean && sub.active)
            .map(mappingSubscriptionCreditPack)
            .sort((a, b) => new Date(a.expiry) - new Date(b.expiry)),
      ),
      creditPacks: computed(
        (state) =>
          state.editing &&
          state.editing.subscriptions
            .filter((sub) => sub.sessionPackBean)
            .map(mappingSubscriptionCreditPack)
            .sort((a, b) => {
              const priority = {
                ACTIVE: 1,
                EXPIRED: 2,
                OVERDUE: 3,
                PENDING: 4,
                PAUSED: 5,
                CANCELLED: 6,
              };
              const max = 1000;
              const minus =
                (priority[a.status] || max) - (priority[b.status] || max);
              if (minus === 0) {
                return new Date(a.expiry) - new Date(b.expiry);
              }
              return minus;
            }),
      ),
      memberships: computed(
        (state) =>
          state.editing &&
          state.editing.subscriptions
            .filter((sub) => sub.membershipBean)
            .map((sub) => ({
              name: sub.name,
              type: sub.membershipBean.type,
              id: sub.id,
              status: sub.status,
              active: sub.active,
              startDate: sub.startDate,
              endDate: sub.endDate,
              endContractDate: sub.endContractDate,
              cancellationDate: sub.cancellationDate,
              price:
                sub.membershipBean.type === ServiceType.Recurring
                  ? sub.membershipMonthlyPayment
                  : sub.price,
              termsConditions: sub.membershipBean.termsConditions,
              duration: sub.membershipBean.duration,
              durationType: sub.membershipBean.durationType,
              pauseStartDate: sub.pauseStartDate,
              pauseEndDate: sub.pauseEndDate,
              mainSubscription: sub.mainSubscription,
              membershipId: sub.membershipBean.id,
              staffCancellable: sub.staffCancellable,
              monthlyBillingDay: sub?.monthlyBillingDay,
              billingType: sub?.billingType,
              billingPeriod: sub?.billingPeriod,
              weeklyBillingDay: sub?.weeklyBillingDay,
            })),
      ),
    };
  }

  /**
   * @private
   */
  pictureGenerator() {
    return {
      postPicture: thunk(async (actions, image, { injections, getState }) => {
        const response = await getApi(injections, getState()).updatePicture(
          getState().editing.id,
          image,
        );
        actions.updatePicture(response.data);
      }),
      updatePicture: action((state, imageUrl) => {
        state.editing.picture = imageUrl;
      }),
    };
  }

  /**
   * @private
   */
  paymentMethodGenerator() {
    return {
      loadingPaymentMethods: false,
      paymentMethods: [],

      fetchPaymentMethods: thunk(
        async (actions, { userMemberId, clubId }, { injections, getState }) => {
          actions.startLoadingPaymentMethods();
          const { data } = await getApi(
            injections,
            getState(),
          ).fetchPaymentMethods(userMemberId, clubId);
          actions.fetchedPaymentMethods(data);
          return data;
        },
      ),

      fetchedPaymentMethods: action((state, paymentMethods) => {
        state.paymentMethods = paymentMethods;
        state.loadingPaymentMethods = false;
      }),

      startLoadingPaymentMethods: action((state) => {
        state.loadingPaymentMethods = true;
      }),
      addPaymentMethod: thunk(
        (
          _,
          { userMemberId, clubId, paymentMethod },
          { injections, getState },
        ) => {
          return getApi(injections, getState()).attachPaymentMethod(
            userMemberId,
            clubId,
            paymentMethod,
          );
        },
      ),

      removePaymentMethod: thunk(
        (
          _,
          { userMemberId, clubId, paymentMethod },
          { injections, getState },
        ) => {
          return getApi(injections, getState()).removePaymentMethod(
            userMemberId,
            clubId,
            paymentMethod,
          );
        },
      ),

      assignDefaultPaymentMethod: thunk(
        (
          _,
          { userMemberId, clubId, paymentMethod },
          { injections, getState },
        ) => {
          return getApi(injections, getState()).assignDefaultPaymentMethod(
            userMemberId,
            clubId,
            paymentMethod,
          );
        },
      ),

      onUpdatePaymentMethod: thunkOn(
        (actions) => [
          actions.addPaymentMethod,
          actions.removePaymentMethod,
          actions.assignDefaultPaymentMethod,
        ],
        async (actions, { payload: { clubId } }, { getState }) => {
          await actions.fetchPaymentMethods({
            userMemberId: getState().editing.id,
            clubId,
          });
        },
      ),
    };
  }

  /**
   * @private
   */
  subscriptionGenerator() {
    return {
      resumeSubscription: thunk(
        (
          _,
          { memberId, subscriptionId, isWaived, paymentMethodId, resumeDate },
          { injections, getState },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          return getApi(injections, getState()).resumeSubscriptionEarly(
            memberId,
            subscriptionId,
            tzDateTimeStringToUtc(resumeDate, tz),
            isWaived,
            paymentMethodId,
          );
        },
      ),

      expireSubscription: thunk(
        (_, { memberId, subscriptionId }, { injections, getState }) => {
          return getApi(injections, getState()).expireSubscription(
            memberId,
            subscriptionId,
          );
        },
      ),

      editExpireSubscription: thunk(
        (
          _,
          { memberId, subscriptionId, expiredDate },
          { injections, getState },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          return getApi(injections, getState()).expireSubscription(
            memberId,
            subscriptionId,
            tzDateTimeStringToUtc(expiredDate, tz),
          );
        },
      ),

      pauseSubscription: thunk(
        (
          _,
          { memberId, subscriptionId, immediately, pauseDate, resumeDate },
          { injections, getState },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          return getApi(injections, getState()).pauseSubscription(
            memberId,
            subscriptionId,
            immediately,
            tzDateTimeStringToUtc(pauseDate, tz),
            tzDateTimeStringToUtc(resumeDate, tz),
          );
        },
      ),

      cancelPauseSubscription: thunk(
        (_, { memberId, subscriptionId }, { injections, getState }) =>
          getApi(injections, getState()).cancelPauseSubscription(
            memberId,
            subscriptionId,
          ),
      ),

      cancelSubscription: thunk(
        (
          _,
          { memberId, subscriptionId, immediately, when },
          { injections, getState },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          return getApi(injections, getState()).cancelSubscription(
            memberId,
            subscriptionId,
            immediately,
            tzDateTimeStringToUtc(when, tz),
          );
        },
      ),

      revokeCancellation: thunk(
        (_, { memberId, subscriptionId }, { injections, getState }) =>
          getApi(injections, getState()).revokeCancellation(
            memberId,
            subscriptionId,
          ),
      ),

      changeSubscription: thunk(
        (
          _,
          { clubId, memberId, newMembershipId, paymentMethod, isWaived },
          { injections, getState },
        ) =>
          getApi(injections, getState()).changeSubscription(
            clubId,
            memberId,
            newMembershipId,
            paymentMethod,
            isWaived,
          ),
      ),

      adjustSessionPack: thunk(
        (
          _,
          { memberId, subscriptionId, newAmount },
          { injections, getState },
        ) => {
          return getApi(injections, getState()).adjustSessionPack(
            memberId,
            subscriptionId,
            newAmount,
          );
        },
      ),

      changeSubscriptionPrice: thunk(
        (
          _,
          { clubId, memberId, subscriptionId, newSubscriptionPrice },
          { injections, getState },
        ) => {
          return getApi(injections, getState()).changeSubscriptionPrice(
            clubId,
            memberId,
            subscriptionId,
            newSubscriptionPrice,
          );
        },
      ),

      updatePendingDate: thunk(
        (
          _,
          { memberId, subscriptionId, immediately, when },
          { injections, getState },
        ) => {
          const tz =
            injections.globalStore.getState()[this.settingsKey].timezone;
          const utcWhen = immediately
            ? undefined
            : tzDateTimeStringToUtc(when, tz);
          return getApi(injections, getState()).updatePendingDate(
            memberId,
            subscriptionId,
            immediately,
            utcWhen,
          );
        },
      ),

      onUpdateSubscription: thunkOn(
        (actions) => [
          actions.expireSubscription,
          actions.editExpireSubscription,
          actions.pauseSubscription,
          actions.resumeSubscription,
          actions.cancelSubscription,
          actions.revokeCancellation,
          actions.cancelPauseSubscription,
          actions.changeSubscription,
          actions.updatePendingDate,
        ],
        async (actions, _, { getState }) => {
          await actions.fetchById(getState().editing.id);
        },
      ),
    };
  }
}

const adjustTzSubscriptionMapGenerator = (tz) => {
  return (sub) => {
    const newSub = {
      ...sub,
      startDate: utcDateTimeStringToTz(sub.startDate, tz),
    };

    if (sub.endDate) {
      newSub.endDate = utcDateTimeStringToTz(sub.endDate, tz);
    }

    if (sub.pauseStartDate) {
      newSub.pauseStartDate = utcDateTimeStringToTz(sub.pauseStartDate, tz);
    }

    if (sub.pauseEndDate) {
      newSub.pauseEndDate = utcDateTimeStringToTz(sub.pauseEndDate, tz);
    }

    if (sub.cancellationDate) {
      newSub.cancellationDate = utcDateTimeStringToTz(sub.cancellationDate, tz);
    }

    if (sub.endContractDate) {
      newSub.endContractDate = utcDateTimeStringToTz(sub.endContractDate, tz);
    }

    return newSub;
  };
};

const mappingSubscriptionCreditPack = (sub) => {
  return {
    id: sub.id,
    name: sub.sessionPackBean.name,
    remaining: sub.sessionsLeft,
    expiry: sub.endDate,
    addon: sub.addon,
    totalCredits: sub.sessionPackBean.sessionsIncluded,
    expiryType: sub.sessionPackBean.expiryType,
    unlimited: sub.sessionPackBean.sessionsUnlimited,
    price: sub.sessionPackBean.price,
    status: sub.status,
    startDate: sub.startDate,
    categoryIdList: sub.sessionPackBean.activityCategoryIdList,
    appointableCategoryIdList: sub.sessionPackBean.appointableCategoryIdList,
    termsConditions: sub.sessionPackBean.termsConditions,
    staffCancellable: sub.staffCancellable,
  };
};

export default MemberModelBuilder;
