import {
  clubStaleTime,
  useClubAsPublic,
  useMemberPaymentMethod,
  useMemberPaymentMethodAsMember,
} from "@gymflow/api";
import {
  UserMemberBean,
  UserMemberPublicDTO,
  UserMemberSearchByFullNameResult,
} from "@gymflow/types";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useState } from "react";

import { ModalContext, ModalWrapper, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, ErrorMessage } from "../atoms";
import { ToastContext } from "../../providers/ToastProvider/context";

export type AddPaymentMethodModalProps = {
  userMember:
    | UserMemberBean
    | UserMemberSearchByFullNameResult
    | UserMemberPublicDTO;
  onConfirm?: (newPaymentMethodId?: string) => Promise<void>;
  asMember?: boolean;
};

export const AddPaymentMethodModal = (props: AddPaymentMethodModalProps) => {
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClubAsPublic(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );
  const { popModal } = useContext(ModalContext);
  if (!club) return null;
  return (
    <ModalWrapper onCancel={popModal}>
      <Elements
        stripe={loadStripe(settings.stripe_api_key, {
          stripeAccount: club.stripeApplicationId,
        })}
      >
        <AddPaymentMethodModalContents {...props} />
      </Elements>
    </ModalWrapper>
  );
};

const AddPaymentMethodModalContents = ({
  userMember,
  onConfirm,
  asMember,
}: AddPaymentMethodModalProps) => {
  const { popModal } = useContext(ModalContext);
  const stripe = useStripe();
  const elements = useElements();
  const { api } = useGymflowModels();
  const { attachPaymentMethodMutation: attachPaymentMethodMutationAsStaff } =
    useMemberPaymentMethod({
      api,
    });
  const { attachPaymentMethodMutation: attachPaymentMethodMutationAsMember } =
    useMemberPaymentMethodAsMember({ api });
  const attachPaymentMethodMutation = asMember
    ? attachPaymentMethodMutationAsMember
    : attachPaymentMethodMutationAsStaff;
  const settings = useClubSettings();
  const { notifyDanger, toast } = useContext(ToastContext);
  const [stripeError, setStripeError] = useState<string>();
  const onSubmit = async () => {
    try {
      if (!stripe || !elements) return;
      const paymentMethodResult = await stripe.createPaymentMethod({
        elements: elements,
        params: {
          billing_details: {
            name: `${userMember.firstName} ${userMember.lastName}`,
          },
        },
      });
      if (paymentMethodResult.error) {
        setStripeError(paymentMethodResult.error.message);
        return;
      }
      await attachPaymentMethodMutation.mutateAsync({
        paymentMethodId: paymentMethodResult.paymentMethod.id,
        memberId: userMember.id,
        clubId: settings.clubId,
      });
      await onConfirm?.(paymentMethodResult.paymentMethod.id);
      toast({ message: "Payment method added." });
    } catch (e) {
      notifyDanger(e as any);
    }
  };
  const isDarkMode = document.querySelector("html")!.classList.contains("dark");
  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-semibold text-gray-950 dark:text-[#ffffff]">
        Add Payment Method
      </div>
      <div className="flex flex-col gap-1">
        <div className="rounded-xl border border-gray-300">
          <CardElement
            onChange={(card) => {
              if (card.error) {
                setStripeError(card.error.message);
              } else {
                setStripeError(undefined);
              }
            }}
            options={{
              classes: {
                base: "p-3",
              },
              style: isDarkMode
                ? {
                    base: {
                      color: "#f5f5f6",
                      "::placeholder": {
                        color: "#85888e",
                      },
                    },
                  }
                : {
                    base: {
                      color: "#101828",
                      "::placeholder": {
                        color: "#98a2b3",
                      },
                    },
                  },
            }}
          />
        </div>
        {stripeError && <ErrorMessage text={stripeError} />}
      </div>
      <div className="flex gap-4">
        <Button
          onClick={() => {
            popModal();
          }}
          className="flex-1"
        >
          Cancel
        </Button>

        <Button
          intent="secondary"
          className="mt-0 flex-1"
          disabled={!!stripeError}
          onClick={async () => {
            await onSubmit();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
