import { Bar } from 'react-chartjs-2';
const colors = ['#D8DBFF', '#B6BCFC', '#A5F3E6', '#333A78', '#1A2168'];
export interface MultiBarChartProps {
  xAxisLabels: string[];
  xLabel: string;
  yLabel: string;
  data: {
    labels: string[];
    values: number[][];
  };
  height?: number;
}
const MultiBarChart = ({
  xAxisLabels,
  data,
  xLabel,
  yLabel,
  height,
}: MultiBarChartProps) => {
  const cumulativeSubtraction: number[][] = [];

  for (let i = 0; i < data.values.length; i++) {
    cumulativeSubtraction[i] = [];
    for (let j = 0; j < data.values[0].length; j++) {
      cumulativeSubtraction[i][j] = data.values[i][j];
      for (let k = 1; k <= i; k++) {
        cumulativeSubtraction[i][j] -= cumulativeSubtraction?.[i - k]?.[j] ?? 0;
      }
      if (cumulativeSubtraction[i][j] < 0) cumulativeSubtraction[i][j] = 0;
    }
  }
  const dataConfig = {
    labels: xAxisLabels,
    datasets: cumulativeSubtraction.map((e, i) => ({
      label: data.labels[i],
      data: e,
      barPercentage: 0.36,
      backgroundColor: colors[i],
      hoverBackgroundColor: colors[i],
      stack: 'stack1',
    })),
  };
  const plugins = [
    {
      afterDatasetsDraw: function(chart: any) {
        const ctx = chart.ctx;
        const datasets = chart.data.datasets;
        const roundedBars = chart.config.options.roundedBars;
        if (!roundedBars) return;

        ctx.save();
        ctx.beginPath();
        datasets.forEach(function(dataset: any, datasetIndex: any) {
          const meta = chart.getDatasetMeta(datasetIndex);
          if (!meta.hidden) {
            meta.data.forEach(function(bar: any, index: any) {
              if (dataset.data[index] === 0) return;
              const radius = roundedBars;
              const x = bar._model.x - bar._model.width / 2 + 0.05;
              const y = bar._model.y - radius + 1;
              const width = bar._model.width - 0.1;
              const height = bar._model.height;

              ctx.beginPath();
              ctx.fillStyle = dataset.backgroundColor;
              ctx.strokeWidth = 0;
              ctx.moveTo(x + radius, y);
              ctx.lineTo(x + width - radius, y);
              ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
              ctx.lineTo(x + width, y + height - radius);
              ctx.quadraticCurveTo(
                x + width,
                y + height,
                x + width - radius,
                y + height
              );
              ctx.lineTo(x + radius, y + height);
              ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
              ctx.lineTo(x, y + radius);
              ctx.quadraticCurveTo(x, y, x + radius, y);
              ctx.closePath();
              ctx.fill();
            });
          }
        });
        ctx.restore();
      },
    },
  ];
  const options = {
    maintainAspectRatio: false,
    roundedBars: 8,
    animation: false,
    tooltips: {
      backgroundColor: 'white',
      titleFontColor: '#101828',
      titleFontFamily: 'Manrope',
      titleFontStyle: 'bold',
      bodyFontColor: '#667085',
      bodyFontFamily: 'Manrope',
      borderColor: '#eaecf0',
      borderWidth: 1,
      displayColors: false,
      xPadding: 12,
      yPadding: 12,
      callbacks: {
        title: function(tooltipItem: any, labelData: any) {
          return `Total: ${data.values[data.values.length - 1][tooltipItem[0].index]
            }`;
        },
        label: function(tooltipItem: any, labelData: any) {
          return data.values
            .map((e, i) => `${data.labels[i]}: ${e[tooltipItem.index]}`)
            .reverse()
            .splice(1);
        },
      },
      fontFamily: 'Manrope',
    },
    legend: {
      onClick: () => undefined,
      position: 'top',
      align: 'end',
      labels: {
        fontSize: 14,
        pointStyle: 'circle',
        usePointStyle: true,
        boxWidth: 4,
        fontFamily: 'Manrope',
        fontStyle: 'bold',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: '#fff',
            zeroLineColor: '#f0ebeb',
          },
          scaleLabel: {
            display: true,
            labelString: xLabel,
            fontFamily: 'Manrope',
            fontStyle: 'bold',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#f0ebeb',
            zeroLineColor: '#f0ebeb',
          },
          scaleLabel: {
            display: true,
            labelString: yLabel,
            fontFamily: 'Manrope',
            fontStyle: 'bold',
          },
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6,
            color: '#f0ebeb',
            max: Math.round(
              data.values[data.values.length - 1]?.reduce(
                (acc, next) => Math.max(acc, next),
                0
              ) * 1.10
            ),
          },
        },
      ],
    },
  };

  return (
    <Bar
      data={dataConfig}
      options={options}
      plugins={plugins}
      height={height ?? 300}
    />
  );
};

export default MultiBarChart;
