import { ApiType } from "@gymflow/common/lib/api/ApiType";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberPaymentMethod(
  {
    api,
  }: {
    api: ApiType;
  },
  mutationOpts?: UseMutationOptions<
    { data: unknown },
    unknown,
    unknown,
    unknown
  >,
) {
  const queryClient = useQueryClient();

  const attachPaymentMethodMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      paymentMethodId,
    }: MemberPaymentMethodMutationParams) =>
      api.memberApi.attachPaymentMethod(memberId, clubId, paymentMethodId),
    onSuccess: async (
      _,
      { memberId, clubId }: MemberPaymentMethodMutationParams,
    ) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethodsNew(clubId, memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
    ...(mutationOpts as any),
  });

  const removePaymentMethodMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      paymentMethodId,
    }: MemberPaymentMethodMutationParams) =>
      api.memberApi.removePaymentMethod(memberId, clubId, paymentMethodId),
    onSuccess: async (
      _,
      { memberId, clubId }: MemberPaymentMethodMutationParams,
    ) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethodsNew(clubId, memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
    ...(mutationOpts as any),
  });

  const assignDefaultPaymentMethodMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      paymentMethodId,
    }: MemberPaymentMethodMutationParams) =>
      api.memberApi.assignDefaultPaymentMethod(
        memberId,
        clubId,
        paymentMethodId,
      ),
    onSuccess: async (
      _New,
      { memberId, clubId }: MemberPaymentMethodMutationParams,
    ) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethodsNew(clubId, memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
    ...(mutationOpts as any),
  });

  return {
    attachPaymentMethodMutation,
    removePaymentMethodMutation,
    assignDefaultPaymentMethodMutation,
  };
}

type MemberPaymentMethodMutationParams = {
  memberId: string;
  clubId: number;
  paymentMethodId: string;
};
