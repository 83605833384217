import { SMSPurpose } from "@gymflow/types";
import { DateTime } from "luxon";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { RadioButtonOption } from "../../atoms";
import { SendSMSFormValues } from "./types";

export const SMS_TEXT_INPUT_ROWS: number = 5;

export const MARKETING_OPTIONS: RadioButtonOption<SMSPurpose>[] = [
  { label: "Yes", value: "MARKETING" },
  { label: "No", value: "ACCOUNT_RELATED" },
];

export const SCHEDULE_TIME_FORMAT = "h:mm a";

export const sendSMSSchema = (timezone: string) =>
  toFormikValidationSchema<SendSMSFormValues>(
    z
      .object({
        marketing: z.enum(["MARKETING", "ACCOUNT_RELATED"]),
        message: z.string({ required_error: "Message is a required field" }),
        isSchedule: z.boolean(),
        date: z.date().optional(),
        time: z.string().optional(),
      })
      .superRefine(({ isSchedule, date, time }, ctx) => {
        if (!isSchedule) return;

        const now = DateTime.now().setZone(timezone);
        const today = now.startOf("day");
        const minDate = today.minus({ days: 1 });
        const maxDate = today.plus({ days: 33 });

        const selectedDate = date
          ? DateTime.fromJSDate(date).setZone(timezone)
          : null;

        if (!selectedDate) {
          ctx.addIssue({
            code: "custom",
            message: "Date is required when scheduling",
            path: ["date"],
          });
        } else if (selectedDate < minDate) {
          ctx.addIssue({
            code: "custom",
            message: "Selected date cannot be earlier than today",
            path: ["date"],
          });
        } else if (selectedDate > maxDate) {
          ctx.addIssue({
            code: "custom",
            message: "You cannot schedule SMS more than 34 days in advance",
            path: ["date"],
          });
        }

        if (!time) {
          ctx.addIssue({
            code: "custom",
            message: "Time is required when scheduling",
            path: ["time"],
          });
        } else {
          const selectedTime = DateTime.fromFormat(time, SCHEDULE_TIME_FORMAT, {
            zone: timezone,
          });

          if (!selectedDate?.hasSame(minDate, "day")) {
            return;
          }

          if (selectedTime < now) {
            ctx.addIssue({
              code: "custom",
              message: "Selected time cannot be in the past",
              path: ["time"],
            });
          }

          const thirtyMinutesFromNow = now.plus({ minutes: 30 });

          if (selectedTime < thirtyMinutesFromNow) {
            ctx.addIssue({
              code: "custom",
              message:
                "You cannot schedule SMS to be sent within 30 minutes of now",
              path: ["time"],
            });
          }
        }
      }),
  );

export const INITIAL_VALUES_SEND_SMS: SendSMSFormValues = {
  marketing: MARKETING_OPTIONS[0].value,
  message: "",
  isSchedule: false,
  date: undefined,
  time: undefined,
};
