import { useEffect, useState } from "react";
import useGymflowModels from "../store";

/**
 * @deprecated
 */
export const useLoadLeadSources = () => {
  const { api } = useGymflowModels();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    api.public.leadApi.findLeadSources().then(({ data }) => {
      const sources = data.filter((item) => item.active).map((item) => ({ label: item.name, value: item.id }));
      setOptions(sources);
    });
  }, []);

  return { options };
};
