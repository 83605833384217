import { zonedTimeToUtc } from "@gymflow/helpers";
import {
  MembershipStatus,
  MembershipType,
  SMSPayload,
  SMSSendingResponse,
} from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export type SendSMSToMembersFilterArgs = {
  smsPayload: SMSPayload;
  userMemberIdsToExclude?: string[];
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
  membershipIdList?: number[];
  membershipStatusList?: MembershipStatus[];
  membershipTypeList?: MembershipType[];
  membershipStartFrom?: string;
  membershipStartTo?: string;
  membershipCancellationFrom?: string;
  membershipCancellationTo?: string;
  membershipExpireFrom?: string;
  membershipExpireTo?: string;
  assignedStaffIdList?: string[];
  creditsRemainingFrom?: string;
  creditsRemainingTo?: string;
};

export const useMutationSendSMSToMembersFilter = ({
  api,
  tz,
}: {
  api: {
    smsApi: {
      sendSMSToMembersFilter: (
        args: SendSMSToMembersFilterArgs,
      ) => Promise<{ data: SMSSendingResponse }>;
    };
  };
  tz: string;
}) => {
  return useMutation({
    mutationFn: async (payload: SendSMSToMembersFilterArgs) => {
      const parsedOpts = { ...payload };
      if (payload?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(payload.createdFrom, tz);
      }
      if (payload?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(payload.createdTo, tz);
      }
      if (payload?.membershipStartFrom) {
        parsedOpts.membershipStartFrom = zonedTimeToUtc(
          payload.membershipStartFrom,
          tz,
        );
      }
      if (payload?.membershipStartTo) {
        parsedOpts.membershipStartTo = zonedTimeToUtc(
          payload.membershipStartTo,
          tz,
        );
      }
      if (payload?.membershipCancellationFrom) {
        parsedOpts.membershipCancellationFrom = zonedTimeToUtc(
          payload.membershipCancellationFrom,
          tz,
        );
      }
      if (payload?.membershipCancellationTo) {
        parsedOpts.membershipCancellationTo = zonedTimeToUtc(
          payload.membershipCancellationTo,
          tz,
        );
      }
      if (payload?.membershipExpireFrom) {
        parsedOpts.membershipExpireFrom = zonedTimeToUtc(
          payload.membershipExpireFrom,
          tz,
        );
      }
      if (payload?.membershipExpireTo) {
        parsedOpts.membershipExpireTo = zonedTimeToUtc(
          payload.membershipExpireTo,
          tz,
        );
      }

      const { data } = await api.smsApi.sendSMSToMembersFilter(parsedOpts);
      return data;
    },
  });
};
