import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";

const customerActivityApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.customerActivity}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, canFind(state), {
    findCategories({ page = 0, limit = 10, status }) {
      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");

      const config = {
        page,
        size: limit,
        statusList: status,
      };

      return state.http.put(`${state.baseUrl}/category/search`, config);
    },

    findCategoryById(id) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );
      return state.http.get(`${state.baseUrl}/category/${id}`);
    },
  });
};

export default customerActivityApi;
