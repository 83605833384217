import { action, computed, thunk, thunkOn } from 'easy-peasy';
import { hasStaffRole } from '@gymflow/api';

const canBuild = (state, authController) => ({
  build() {
    return {
      isLoggedIn: false,
      id: null,
      avatar: '',
      firstName: '',
      lastName: '',
      roles: [],
      email: '',
      error: null,
      authController,

      init: thunk(async (actions, { placeholder }) => {
        actions.savePlaceholder({ placeholder });
        const id = await authController.getUserId();
        const email = await authController.getEmail();
        const roles = await authController.getRoles();
        actions.saveDetails({ roles, id, email });
        actions.loggedIn();
      }),

      onInit: thunkOn(
        (actions) => actions.init,
        (actions) => {
          return actions.updateUserDetails();
        }
      ),

      updateUserDetails: thunk(async (actions, _, { injections, getState }) => {
        const storeState = getState();
        const storeKey = hasStaffRole(storeState.roles) ? state.staffKey : state.userMemberKey;
        const api = injections.api[storeKey];
        actions.saveError({ error: null });
        try {
          const result = await api.findById(storeState.id);
          if (result) {
            actions.saveName(result.data);
          }
        } catch (e) {
          actions.saveError({ error: 'User has no permission to access this website.' });
        }
      }),

      logout: thunk(async (actions) => {
        actions.loggedOut();
        await authController.logout();
      }),

      saveDetails: action((state, { roles, id, email }) => {
        state.roles = roles;
        state.id = id;
        state.email = email;
      }),

      saveName: action((state, { firstName, lastName, picture }) => {
        state.firstName = firstName;
        state.lastName = lastName;
        if (picture) {
          state.avatar = picture;
        }
      }),

      saveError: action((state, { error }) => {
        state.error = error;
      }),

      loggedIn: action((state) => {
        state.isLoggedIn = true;
      }),

      loggedOut: action((state) => {
        state.roles = [];
        state.isLoggedIn = false;
      }),

      savePlaceholder: action((state, { placeholder }) => {
        if (!state.avatar) {
          state.avatar = placeholder;
        }
      }),
    };
  },
});

const canExportController = (authController) => {
  const controller = {
    refreshSession() {
      return authController.refreshAuthentication();
    },

    logout() {
      return authController.logout();
    },
  };

  return {
    controller,
    getController: () => authController,
  };
};

const auth = ({ staffKey = 'staff', userMemberKey = 'user-member', authController }) => {
  const state = {
    controller: null,
    staffKey,
    userMemberKey,
  };

  return Object.assign(state, canBuild(state, authController), canExportController(authController));
};

export default auth;
