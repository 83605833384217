import { QueryKey } from "@tanstack/react-query";

export const productQueryKeys = {
  all: () => ["product"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...productQueryKeys.all(), { filters }] as QueryKey,
  summary: (params?: Readonly<{ [k: string]: any }>) =>
    [...productQueryKeys.all(), "summary", params] as QueryKey,
  details: (productId?: number) =>
    [...productQueryKeys.all(), productId] as QueryKey,
  categorySearch: (filters: Readonly<{ [k: string]: any }>) =>
    ["product-category-search", { filters }] as QueryKey,
};
