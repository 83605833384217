import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";

import { DynamicReportWidgetContext } from "./DynamicReportWidgetCard";
import { ReportWidgetCardDropdown } from "./ReportWidgetCardDropdown";

function EmptyReportWidgetCard() {
  const dynamicWidgetContext = useContext(DynamicReportWidgetContext);
  const [showOptions, setShowOptions] = useState(false);
  return (
    <div className="relative mx-3 mt-6 min-w-[250px] flex-1 rounded-xl border border-gray-200 bg-white shadow">
      {dynamicWidgetContext && showOptions && (
        <ReportWidgetCardDropdown hide={() => setShowOptions(false)} />
      )}
      <div className="flex flex-row items-center justify-between px-6 pt-6">
        <div className="text-sm font-medium text-gray-600"></div>
        {dynamicWidgetContext && (
          <div
            className="flex w-4 cursor-pointer flex-row justify-end"
            onClick={() => {
              setShowOptions(true);
            }}
          >
            <FontAwesomeIcon
              className="cursor-pointer text-gray-400"
              icon={faEllipsisVertical}
            />
          </div>
        )}
      </div>
      <div className="mt-2 flex flex-row items-center justify-between px-6">
        <div>
          <div className="h-7 w-12 rounded"></div>
        </div>
        <div className="text-3xl font-semibold"></div>
      </div>
      <div className="mt-6 flex flex-row items-center justify-end border-t border-gray-200 px-6 py-4">
        <div></div>
      </div>
    </div>
  );
}

export default EmptyReportWidgetCard;
