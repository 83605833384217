export function getFilterAppliedCount(filters: Record<string, any>) {
  return Object.keys(filters).reduce((acc, key) => {
    if (Array.isArray(filters[key]) && filters[key].length === 0) {
      return acc;
    }
    if (filters[key] === undefined || filters[key] === "") {
      return acc;
    }
    return acc + 1;
  }, 0);
}
