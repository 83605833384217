import { Spinner as ReactStrapSpinner } from 'reactstrap';

function Spinner(props) {
  return (
    <div>
      <div
        style={{
          position: 'absolute',
          display: props.hidden ? 'none' : 'flex',
          justifyContent: 'center',
          width: '100%',
          paddingRight: '30px',
          top: '40%',
          zIndex: 100,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="ml-auto mr-auto" data-testid="spinner">
            <ReactStrapSpinner color="primary" size="lg" />
          </div>
          <div>{props.text}</div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
