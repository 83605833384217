import { ActivityCategoryDTO, ActivityCategoryStatus } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useActivityCategoryEditStatus({
  api,
}: {
  api: {
    activityApi: {
      updateCategoryStatus: (
        activityCategoryId: number,
        status: ActivityCategoryStatus,
      ) => Promise<{ data: ActivityCategoryDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      activityCategoryId,
      newStatus,
    }: {
      activityCategoryId: number;
      newStatus: ActivityCategoryStatus;
    }) => {
      const response = await api.activityApi.updateCategoryStatus(
        activityCategoryId,
        newStatus,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: activityQueryKeys.categories(),
      });
    },
  });
  return mutation;
}
