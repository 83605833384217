import { z } from "zod";

export const PresetLeadStatusType = {
  NewLead: "NEW_LEAD",
  DealClosed: "DEAL_CLOSED",
  DealLost: "DEAL_LOST",
} as const;

export const PresetLeadStatusTypeZodSchema = z.enum([
  PresetLeadStatusType.NewLead,
  PresetLeadStatusType.DealClosed,
  PresetLeadStatusType.DealLost,
]);
