import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { DATE_FORMAT_WITH_SECONDS } from '@gymflow/common';
import Item from './Item';

function LeadStatusItem({ leadStatus, createdDate, dateFormat }) {
  return (
    <Item
      icon={faFilter}
      firstLine={
        <>
          Lead Status changed to <b>{leadStatus.name}</b>
        </>
      }
      secondLine={<>{moment(createdDate, DATE_FORMAT_WITH_SECONDS).format(`${dateFormat} [at] h:mm a`)}</>}
    />
  );
}

LeadStatusItem.propTypes = {
  leadStatus: PropTypes.object.isRequired,
  createdDate: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default LeadStatusItem;
