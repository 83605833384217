import classNames from "classnames";
import { useContext } from "react";

import { CheckCircleIcon } from "../../atoms";
import { WizardContext } from "../StepWizard";

export function KisiIntegrationStep3({ submitError }: { submitError: string }) {
  const { wizardState } = useContext(WizardContext);

  return (
    <div>
      <div className="font-semibold">Sync Members</div>
      <div className="mt-2 text-gray-600">
        Gymflow will now sync access for all existing members with an ACTIVE
        membership for your selected memberships. This process may take several
        minutes and cannot be interrupted until completed.
      </div>

      <div
        className={classNames(
          { hidden: wizardState.isWizardComplete !== true },
          "rounded border border-success-700 text-success-700 p-5 bg-success-50 flex",
        )}
      >
        <div className="mt-0.5">
          <CheckCircleIcon pathClassName="stroke-success-700" />
        </div>
        <div className="ml-2">
          <div className="font-semibold">Setup Successful</div>
          <div>
            Your members access has been setup successfully, you can now exit
            setup.
          </div>
        </div>
      </div>

      {submitError && (
        <div className="flex rounded border border-error-700 bg-error-50 p-5 text-error-700">
          <div className="mt-0.5">
            <CheckCircleIcon pathClassName="stroke-error-700" />
          </div>
          <div className="ml-2">
            <div className="font-semibold">Error</div>
            <div>{submitError}</div>
          </div>
        </div>
      )}
    </div>
  );
}
