import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import format from "date-fns/format";

import { Badge, RefreshIcon } from "../../atoms";
import { StatusCircle } from "./StatusCircle";
import { CalendarAppointmentExtendedProps, CalendarEventImpl } from "./types";

export function EventContentAppointment({
  event,
  mini,
}: {
  event: CalendarEventImpl<CalendarAppointmentExtendedProps>;
  mini: boolean;
}) {
  const { participantName, appointmentStatus, paymentStatus } =
    event.extendedProps;

  const statusMap = {
    PAID: "available",
    REFUNDED: "maybe",
    UNPAID: "unavailable",
  } as const;

  const getBadgeType = () => {
    switch (appointmentStatus) {
      case "BOOKED":
        return "primary";
      case "CANCELLED":
      case "LATE_CANCELLED":
        return "error";
    }
    return "default";
  };
  return (
    <>
      {!!event.start && !mini && (
        <div className="flex items-center justify-between text-xs font-medium text-gray-500">
          <div>{format(new Date(event.start), "h:mm a")}</div>
          <div className="flex items-center gap-2">
            <StatusCircle status={statusMap[paymentStatus]} />
            {event.extendedProps.recurringAppointmentId !== undefined && (
              <RefreshIcon
                className="h-3.5 w-3.5"
                pathClassName="stroke-gray-500"
              />
            )}
          </div>
        </div>
      )}
      <div className="flex items-center justify-between text-sm font-semibold text-gray-900 ">
        <div>{participantName}</div>
        <div
          className={cn("flex flex-row items-center gap-1", { hidden: !mini })}
        >
          <StatusCircle status={statusMap[paymentStatus]} />
          {event.extendedProps.recurringAppointmentId !== undefined && (
            <RefreshIcon
              className="h-3.5 w-3.5"
              pathClassName="stroke-gray-500"
            />
          )}
        </div>
      </div>
      <div className="text-xs font-medium text-gray-500">{event.title}</div>
      <div className={classNames({ hidden: mini })}>
        <Badge intent={getBadgeType()} className="capitalize">
          {appointmentStatus.toLowerCase()}
        </Badge>
      </div>
    </>
  );
}
