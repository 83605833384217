import { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Form,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from "reactstrap";
import { useFormik } from "formik";
import ReactBSAlert from "react-bootstrap-sweetalert";
import * as Yup from "yup";
import {
  AsyncButton,
  FormikInput,
  FormMapper,
  renderErrorRowOnTouch,
  formikHelpers,
  useRecordForm,
} from "@gymflow/common";
import { currencies, SYMBOL_PLACEMENT } from "@gymflow/helpers";
import noop from "lodash/noop";
import isEmpty from "lodash/isEmpty";
import styled from "styled-components";

const mapper = new FormMapper();

const AMOUNT = "amount";

/**
 * @deprecated rewrite in tailwind
 */
const Button = styled.button`
  margin-right: 8px;
`;

/**
 * @deprecated rewrite in tailwind
 */
const SubmitButton = styled(AsyncButton)`
  color: white;
  borderColor: rgb(40, 96, 144),
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgb(165, 202, 234) 0px 0px 8px
`;

function PayInvoiceAlert({
  onCancel,
  onConfirm,
  maxAmount,
  title,
  text,
  confirmButtonText,
  currency,
}) {
  const schema = useMemo(
    () =>
      Yup.object().shape({
        [AMOUNT]: Yup.number()
          .required()
          .min(0.01)
          .max(maxAmount)
          .default(maxAmount),
      }),
    [maxAmount],
  );

  const { initialValues } = useRecordForm({
    fields: schema.default(),
    record: null,
    mapper,
  });

  const formikProps = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: noop,
  });
  const { errorClass } = formikHelpers(formikProps);
  const { validateForm, errors, touched, values } = formikProps;

  const renderButtons = () => (
    <>
      <Button
        type="button"
        onClick={onCancel}
        className="btn btn-sm btn-outline-primary"
      >
        Cancel
      </Button>
      <SubmitButton
        type="button"
        className="btn btn-sm btn-primary"
        onClick={async () => {
          const fieldErrors = await validateForm(values);
          const isValidated = isEmpty(fieldErrors);
          if (isValidated) {
            await onConfirm(values);
          }
        }}
      >
        {confirmButtonText}
      </SubmitButton>
    </>
  );

  return (
    <ReactBSAlert
      customButtons={renderButtons()}
      title={title}
      showCancel
      confirmBtnText={confirmButtonText}
      onConfirm={noop}
      onCancel={noop}
      closeOnClickOutside={false}
    >
      <Form className="form-horizontal" role="form">
        <Row className="mt-3">
          <Col>{text}</Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
}

PayInvoiceAlert.defaultProps = {
  confirmButtonText: "Pay",
  text: "Do you want to pay this invoice?",
  title: "Pay Invoice",
};

PayInvoiceAlert.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  maxAmount: PropTypes.number.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  confirmButtonText: PropTypes.string,
  currency: PropTypes.string.isRequired,
};

export default PayInvoiceAlert;
