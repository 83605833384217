import { z } from "zod";

export const NotificationType = {
  Email: "EMAIL",
  Sms: "SMS",
} as const;

export const NotificationTypeZodSchema = z.enum([
  NotificationType.Email,
  NotificationType.Sms,
]);

Object.setPrototypeOf(NotificationType, {
  humanize: (value: (typeof NotificationType)[keyof typeof NotificationType]) =>
    value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
});
