import {
  DurationType,
  FormMapper,
  MembershipBillingType,
  MembershipProrataChoice,
} from "@gymflow/common";

class MembershipFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "termsConditions",
          transform: (url, { termsConditionsFilename }) => ({
            name: termsConditionsFilename,
            url,
          }),
        },
      ],
    });
  }

  to(values) {
    const transformed = super.to(values);

    const upfrontAddons = (values.membershipAddonList || []).filter(
      ({ recurring }) => !recurring,
    );
    transformed["upfront-addons"] =
      MembershipFormMapper.mapAddonFromRecord(upfrontAddons);

    const recurringAddons = (values.membershipAddonList || []).filter(
      ({ recurring }) => recurring,
    );
    transformed["recurring-addons"] =
      MembershipFormMapper.mapAddonFromRecord(recurringAddons);

    delete transformed["service-addon-list"];
    delete transformed["club-bean-list"];

    if (transformed["duration-type"] === DurationType.NoContract) {
      transformed.duration = 0;
    }

    return transformed;
  }

  from(formValues) {
    const transformed = super.from(formValues);

    if (transformed.upfrontAddons) {
      const products = transformed.upfrontAddons.filter(
        ({ type }) => type === "product",
      );
      if (transformed.upfrontAddons.length > 0) {
        transformed.productIdOnceOffList =
          MembershipFormMapper.mapProductFromForm(products);
      }

      const sessionPacks = transformed.upfrontAddons.filter(
        ({ type }) => type === "creditPack",
      );
      if (transformed.upfrontAddons.length > 0) {
        transformed.sessionPackIdOnceOffList =
          MembershipFormMapper.mapCreditPacksFromForm(sessionPacks);
      }
      if (transformed.upfrontAddons.length === 0) {
        transformed.productIdOnceOffList = [];
        transformed.sessionPackIdOnceOffList = [];
      }
      delete transformed.upfrontAddons;
    }

    if (transformed.recurringAddons) {
      const products = transformed.recurringAddons.filter(
        ({ type }) => type === "product",
      );
      if (transformed.recurringAddons.length > 0) {
        transformed.productIdRecurringList =
          MembershipFormMapper.mapProductFromForm(products);
      }

      const sessionPacks = transformed.recurringAddons.filter(
        ({ type }) => type === "creditPack",
      );
      if (transformed.recurringAddons.length > 0) {
        transformed.sessionPackIdRecurringList =
          MembershipFormMapper.mapCreditPacksFromForm(sessionPacks);
      }
      if (transformed.recurringAddons.length === 0) {
        transformed.productIdRecurringList = [];
        transformed.sessionPackIdRecurringList = [];
      }

      delete transformed.recurringAddons;
    }

    if (transformed.billingType === MembershipBillingType.Monthly) {
      delete transformed.weeklyBillingDay;
    } else if (transformed.billingType === MembershipBillingType.Weekly) {
      delete transformed.monthlyBillingDay;
    }

    if (transformed.calculateProrata === MembershipProrataChoice.No) {
      delete transformed.monthlyBillingDay;
      delete transformed.weeklyBillingDay;
    }

    return transformed;
  }

  /**
   * @private
   */
  static mapAddonFromRecord(addons) {
    return addons.flatMap((addon) => {
      const type = MembershipFormMapper.getAddonTypeFromObject(addon);
      if (type === "product")
        return new Array(addon.quantity).fill(0).map((e) => ({
          type,
          id: addon[idNameMap[type]],
          name: addon.name,
          quantity: 1,
          price: addon.price,
          recurring: addon.recurring,
        }));
      return [
        {
          type,
          id: addon[idNameMap[type]],
          name: addon.name,
          quantity: 1,
          price: addon.price,
          recurring: addon.recurring,
        },
      ];
    });
  }

  /**
   * @private
   */
  static getAddonTypeFromObject(addon) {
    if (typeof addon.productId !== "undefined") {
      return "product";
    } else if (typeof addon.sessionPackId !== "undefined") {
      return "creditPack";
    }
    throw new Error("Addon type not supported");
  }

  /**
   * @private
   * @param {Array} products
   */
  static mapProductFromForm(products) {
    const productIds = [...new Set(products.map((product) => product.id))];
    const quantities = productIds.reduce((acc, next) => {
      return {
        ...acc,
        [next]: products.filter((product) => product.id === next).length,
      };
    }, {});
    return productIds.map((id) => ({
      id: id,
      quantity: quantities[id],
    }));
  }

  /**
   * @private
   */
  static mapCreditPacksFromForm(creditPacks) {
    return creditPacks.map((creditPack) => creditPack.id);
  }
}

const idNameMap = {
  product: "productId",
  creditPack: "sessionPackId",
};

export default MembershipFormMapper;
