export function UploadCloudIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathClassName}
        d="M5.3335 10.6667L8.00016 8M8.00016 8L10.6668 10.6667M8.00016 8V14M13.3335 11.1619C14.1478 10.4894 14.6668 9.47196 14.6668 8.33333C14.6668 6.30829 13.0252 4.66667 11.0002 4.66667C10.8545 4.66667 10.7182 4.59066 10.6442 4.46516C9.77487 2.98989 8.16978 2 6.3335 2C3.57207 2 1.3335 4.23858 1.3335 7C1.3335 8.3774 1.89046 9.62472 2.79146 10.529"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
