import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../../hooks/usePortalRoutes";
import { RouteFeature } from "../../../../routes/feature";
import { RouteLayout } from "../../../../routes/layout";
import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportEventAttendanceWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const data = useContext(ReportContext);

  return (
    <ReportWidgetCard
      title="Attendance"
      value={data.events?.attendance?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Sum of users that were checked into an event during the selected period."
      variation={data.events?.attendance?.variation}
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.EventAttendance,
          ),
          search: QueryString.stringify({
            startDate: data.startDate,
            endDate: data.endDate,
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
