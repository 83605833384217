import { GetUserMemberAccessLogRequestParams } from "@gymflow/common/lib/api/accessApi";
import { QueryKey } from "@tanstack/react-query";

export const accessQueryKeys = {
  all: () => ["access"] as QueryKey,
  member: (memberId?: string) =>
    [...accessQueryKeys.all(), memberId] as QueryKey,
  getUserMemberAccessLog: (params?: GetUserMemberAccessLogRequestParams) => {
    const result = [...accessQueryKeys.all(), "getUserMemberAccessLog"];
    if (params) result.push(params);
    return result as QueryKey;
  },
};
