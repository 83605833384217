import invert from "lodash/invert";

import { CalendarIntervalMode } from "./CalendarIntervalMode";

export type SupportedCalendarView =
  | "timeGridDay"
  | "timeGridWeek"
  | "dayGridMonth";

export const calendarViewToIntervalMap: {
  [k in CalendarIntervalMode]: SupportedCalendarView;
} = {
  DAY: "timeGridDay",
  WEEK: "timeGridWeek",
  MONTH: "dayGridMonth",
};

export const intervalToCalendarViewMap = invert(
  calendarViewToIntervalMap,
) as unknown as {
  [k in SupportedCalendarView]: CalendarIntervalMode;
};

export function getTimeslotOnViewport() {
  const timegridSlotsEls = document.querySelectorAll(
    ".fc-timegrid-slot>div>div>div>div",
  );
  const inView = [];
  for (let i = 0; i < timegridSlotsEls.length; i++) {
    const el = timegridSlotsEls[i];
    const rect = el.getBoundingClientRect();
    if (
      rect.bottom > 0 &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight)
    ) {
      inView.push(el);
    }
  }
  if (inView.length > 0) {
    const middle = inView[Math.ceil(inView.length / 2)];
    return middle.textContent;
  }
  return null;
}
