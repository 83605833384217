import { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canCreate,
  canFindById,
  canTrimFields,
  canUpdate,
} from "./features/apiResource";

const leadEndpoints = {
  status: `${endpoints.lead}/status`,
  singleStatus: `${endpoints.lead}/status/:id`,
  singleStatusName: `${endpoints.lead}/status/:id/name`,
  singleStatusOrder: `${endpoints.lead}/status/:id/order`,
  report: `${endpoints.lead}/report/preset-status`,
  reportCsv: `${endpoints.lead}/report/preset-status/csv`,
  openReport: `${endpoints.lead}/report/open-status`,
  openReportCsv: `${endpoints.lead}/report/open-status/csv`,
};

const leadApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["firstName", "lastName", "phoneNumber", "email", "source"],
    baseUrl: `${clubPrefix}${endpoints.lead}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    {
      find({ statusIds, source, dateFrom, dateTo }) {
        const params = {};
        if (statusIds) {
          params["leadStatus.id"] = statusIds;
        }
        if (source) {
          params["source.id"] = source;
        }
        if (dateFrom) {
          params.dateFrom = dateFrom;
        }
        if (dateTo) {
          params.dateTo = dateTo;
        }
        return state.http.get(state.baseUrl, { params });
      },
      list({
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        leadStatusId,
        leadSourceId,
        includeNotCompleteTasks,
        nextPageToken,
        limit,
      }) {
        return state.http.get(`${clubPrefix}lead-list`, {
          params: {
            createdFrom,
            createdTo,
            smsCommunication,
            emailCommunication,
            leadStatusId,
            leadSourceId,
            includeNotCompleteTasks,
            nextPageToken,
            limit,
          },
        });
      },
      listCount(params) {
        return state.http.get(`${clubPrefix}lead-list/count`, {
          params,
        });
      },
      listCsv(params) {
        return state.http.get(`${clubPrefix}lead-list/csv`, {
          params,
          headers: {
            "Content-Disposition": "attachment; filename=leads.csv",
          },
        });
      },
      delete(leadId) {
        return state.http.delete(`${state.baseUrl}/${leadId}`);
      },
      findStatus() {
        return state.http.get(`${clubPrefix}${leadEndpoints.status}`);
      },
      changeLeadStatusColumn(leadId, columnId) {
        assert.number(leadId, "leadId must be a number");
        assert.number(columnId, "columnId must be a number");

        return state.http.patch(state.baseUrl, {
          leadId,
          newStatusId: columnId,
        });
      },
      createStatusColumn(name) {
        assert.string(name, "name must be string");

        return state.http.post(`${clubPrefix}${leadEndpoints.status}`, {
          name,
        });
      },
      editStatusColumnName(columnId, name) {
        assert.number(columnId, "columnId must be a number");
        assert.string(name, "name must be string");

        const url = `${clubPrefix}${leadEndpoints.singleStatusName}`.replace(
          ":id",
          columnId,
        );
        return state.http.patch(url, { name });
      },

      editStatusColumnOrder(columnId, statusOrder) {
        assert.number(columnId, "columnId must be a number");
        assert.number(statusOrder, "statusOrder must be a number");

        const url = `${clubPrefix}${leadEndpoints.singleStatusOrder}`.replace(
          ":id",
          columnId,
        );
        return state.http.patch(url, { statusOrder });
      },
      deleteStatusColumn(columnId) {
        assert.number(columnId, "columnId must be a number");
        const url = `${clubPrefix}${leadEndpoints.singleStatus}`.replace(
          ":id",
          columnId,
        );

        return state.http.delete(url);
      },
      report({
        startDate,
        endDate,
        presetLeadStatus,
        leadSourceList,
        page = 0,
        limit = 10,
        sort,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        const config = {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
            presetLeadStatus,
            leadSourceList:
              leadSourceList.length > 0 ? leadSourceList : undefined,
            page,
            size: limit,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(`${clubPrefix}${leadEndpoints.report}`, config);
      },
      reportCsv(filters) {
        return state.http.get(`${clubPrefix}${leadEndpoints.reportCsv}`, {
          params: filters,
        });
      },
      openReport({
        leadStatusList,
        leadSourceList,
        page = 0,
        limit = 10,
        sort,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");
        const config = {
          params: {
            leadStatusList:
              leadStatusList.length > 0 ? leadStatusList : undefined,
            leadSourceList:
              leadSourceList.length > 0 ? leadSourceList : undefined,
            page,
            size: limit,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${leadEndpoints.openReport}`,
          config,
        );
      },
      openReportCsv(filters) {
        return state.http.get(`${clubPrefix}${leadEndpoints.openReportCsv}`, {
          params: filters,
        });
      },
      delete(leadId) {
        return state.http.delete(`${clubPrefix}lead/${leadId}`);
      },
      bulkStatusUpdate(newLeadStatusId, leadIds) {
        return state.http.put(
          `${clubPrefix}lead-list/bulk-status-update/no-filter`,
          {
            newLeadStatusId,
            leadIds,
          },
        );
      },
      bulkStatusUpdateFilter({
        newLeadStatusId,
        leadIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        leadStatusIdList,
        leadSourceIdList,
      }) {
        return state.http.put(
          `${clubPrefix}lead-list/bulk-status-update/filter`,
          {
            newLeadStatusId,
            leadIdsToExclude,
            createdFrom,
            createdTo,
            smsCommunication,
            emailCommunication,
            leadStatusIdList,
            leadSourceIdList,
          },
        );
      },
    },
  );
};

export default leadApi;
