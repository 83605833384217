import { FacilityDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export async function facilityQueryFn({
  api,
  facilityId,
}: {
  api: FacilityApi;
  facilityId: number;
}) {
  const result = await api.facilityApi.findById(facilityId);
  return result.data as FacilityDTO;
}

export function useFacility(
  {
    api,
    facilityId,
  }: {
    api: FacilityApi;
    facilityId?: number;
  },
  opts?: UseQueryOptions<FacilityDTO | null>,
) {
  const result = useQuery({
    queryKey: facilityQueryKeys.details(facilityId as number),
    queryFn: () => facilityQueryFn({ api, facilityId: facilityId as number }),
    enabled: !!facilityId,
    initialData: null,
    ...opts,
  });

  return result;
}

interface FacilityApi {
  facilityApi: { findById: (arg0: number) => Promise<any> };
}
