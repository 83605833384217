import { zonedTimeToUtc } from "@gymflow/helpers";
import { ApiListResponse, TaskDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { deadlineDatesToZonedTime } from "./deadlineDatesToZonedTime";
import { taskQueryKeys } from "./taskQueryKeys";

export function useTaskList(
  {
    api,
    opts,
    tz,
  }: {
    api: {
      taskApi: { find: (arg0: { [k: string]: any }) => Promise<any> };
    };
    tz: string;
    opts: TaskListFilter;
  },
  queryOpts: UseQueryOptions<ApiListResponse<TaskDTO>>,
) {
  const result = useQuery({
    queryKey: taskQueryKeys.list(opts),
    queryFn: async () => {
      let parsedOpts = merge({}, opts);
      if (opts?.extraParams?.dateFrom) {
        parsedOpts = merge(parsedOpts, {
          extraParams: {
            dateFrom: zonedTimeToUtc(opts.extraParams.dateFrom, tz),
          },
        });
      }
      if (opts?.extraParams?.dateTo) {
        parsedOpts = merge(parsedOpts, {
          extraParams: {
            dateTo: zonedTimeToUtc(opts.extraParams.dateTo, tz),
          },
        });
      }
      return (await api.taskApi.find(parsedOpts))
        .data as ApiListResponse<TaskDTO>;
    },
    initialData: defaultPage<TaskDTO>,
    select: (data: ApiListResponse<TaskDTO>) => {
      return merge({}, data, {
        content: data.content.map((task) => ({
          ...task,
          ...deadlineDatesToZonedTime(task, tz),
        })),
      });
    },
    ...queryOpts,
  });
  return result;
}

export interface TaskListFilter {
  readonly extraParams?: {
    readonly dateFrom?: string;
    readonly dateTo?: string;
    readonly complete?: boolean;
    readonly ["taskOwners.id"]?: string | string[];
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
