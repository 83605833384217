import { GenericValidatorParser } from "@gymflow/common";
import { startCase } from "lodash";

export function requestErrorParser(e: any) {
  if (e?.response?.data?.error_message) {
    return e.response.data.error_message;
  }
  if (!e?.response?.data?.errors) {
    return "";
  }
  const parser = new GenericValidatorParser(e.response.data.errors);
  const fieldErrors = parser.getFieldErrors() as any;
  const fieldErrorString = Object.keys(fieldErrors)
    .map((field) => `${startCase(field)}: ${fieldErrors[field]}`)
    .join("; ");
  return fieldErrorString;
}
