import classNames from "classnames";
import { twMerge } from "tailwind-merge";

export interface TabButtonConfig {
  title: string;
  onClick: (selectedIdx: number) => void;
}

interface TabButtonGroupProps {
  tabs: TabButtonConfig[];
  selectedIdx?: number;
  className?: string;
}

export function TabButtonGroup({
  tabs,
  selectedIdx,
  className,
}: TabButtonGroupProps) {
  return (
    <div className={twMerge("flex w-full", className)}>
      {tabs.map(({ title, onClick }, idx) => {
        const isSelected = idx === selectedIdx;
        return (
          <div
            className={classNames(
              "flex-1 border-b border-l border-t py-2 text-center",
              { "bg-gray-50": isSelected },
              { "cursor-pointer hover:bg-gray-100": !isSelected },
              { "rounded-l-md": idx === 0 },
              { "rounded-r-md border-r": idx === tabs.length - 1 },
            )}
            onClick={() => {
              onClick(idx);
            }}
            key={idx}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
}
