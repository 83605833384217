import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { CalendarEventOccurrenceAsMember } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Optional } from "utility-types";

import { calendarAsMemberQueryKeys } from "./calendarAsMemberQueryKeys";

export function useInfiniteQueryCalendarEventOccurrencesAsMember({
  api,
  tz,
  filters,
}: {
  api: {
    customerOccurrenceApi: {
      calendarEventOccurrences: ({
        filters,
      }: {
        filters: CalendarEventOccurrenceAsMemberFilter;
      }) => Promise<{ data: CalendarEventOccurrenceAsMemberResult }>;
    };
  };
  tz: string;
  filters: Optional<
    CalendarEventOccurrenceAsMemberFilter,
    "dateFrom" | "dateTo"
  >;
}) {
  return useInfiniteQuery({
    queryKey: calendarAsMemberQueryKeys.eventOccurrences({ tz, filters }),
    queryFn: async ({ pageParam }) => {
      const result = await api.customerOccurrenceApi.calendarEventOccurrences({
        filters: createApiParams({
          filters: {
            ...(filters as CalendarEventOccurrenceAsMemberFilter),
            nextPageToken: pageParam,
          },
          tz,
        }),
      });
      return result.data;
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    select: (data) => calendarEventOccurrencesAsMemberSelectFn(data, tz),
    enabled: !!tz && (!!filters?.["dateFrom"] || !!filters?.["dateTo"]),
  });
}

const createApiParams = ({
  filters,
  tz,
}: {
  filters: CalendarEventOccurrenceAsMemberFilter;
  tz: string;
}) => {
  const parsed: { dateFrom?: string; dateTo?: string } = {};

  if (filters?.["dateFrom"]) {
    parsed["dateFrom"] = zonedTimeToUtc(filters["dateFrom"], tz);
  }

  if (filters?.["dateTo"]) {
    parsed["dateTo"] = zonedTimeToUtc(filters["dateTo"], tz);
  }

  return {
    ...filters,
    ...parsed,
  };
};

export function calendarEventOccurrencesAsMemberSelectFn(
  data: {
    pages: CalendarEventOccurrenceAsMemberResult[];
    pageParams: unknown[];
  },
  tz: string,
) {
  return {
    ...data,
    pages: data.pages.map((page) =>
      page.content.map((eventOccurrence) => ({
        ...eventOccurrence,
        startDate: utcToZonedTime(eventOccurrence.startDate, tz),
        endDate: utcToZonedTime(eventOccurrence.endDate, tz),
      })),
    ),
  };
}

interface CalendarEventOccurrenceAsMemberFilter {
  dateFrom: string;
  dateTo: string;
  limit: number;
  nextPageToken?: string;
  includeBookedCounts?: boolean;
  includeWaitingCounts?: boolean;
  eventHostId?: string[];
  facilityId?: number[];
  activityId?: number[];
  isPublicEvent?: boolean;
  activityCategoryId?: number[];
}

interface CalendarEventOccurrenceAsMemberResult {
  content: CalendarEventOccurrenceAsMember[];
  nextPageToken?: string;
}
