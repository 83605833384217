import * as Yup from "yup";

export const LOGO = "logo";
export const FONT = "font";
export const FONT_COLOR = "font-color";
export const NAVBAR_COLOR = "navbar-color";
export const ACCENT_COLOR = "accent-color";
export const PRIMARY_COLOR = "primary-color";
export const FOOTER_COLOR = "footer-color";
export const BACKGROUND_COLOR = "background-color";
export const CARD_COLOR = "card-color";
export const SHADOW_COLOR = "shadow-color";

export const createCustomizeSchema = () =>
  Yup.object().shape({
    [LOGO]: Yup.mixed()
      .test("is-file", "File is required.", (value) => value && value.name)
      .required()
      .default(null),
    [FONT]: Yup.string().trim().required().default("Manrope"),
    [FONT_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#3A3A3F"),
    [NAVBAR_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#FFFFFF"),
    [ACCENT_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#EEBA0B"),
    [PRIMARY_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#F80040"),
    [FOOTER_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#FFFFFF"),
    [BACKGROUND_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#F7F7F7"),
    [CARD_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#FFFFFF"),
    [SHADOW_COLOR]: (Yup.string() as any)
      .cssColor()
      .trim()
      .required()
      .default("#F1F1F2"),
  });
