import { AxiosError, isAxiosError } from "axios";
import { ReactNode, useCallback } from "react";
import { toast } from "sonner";

import { GenericValidatorParser } from "../../../../../libs/common/src/lib/helpers/GenericValidatorParser";
import { ToastContext } from "./context";
import { Toast, ToastProps } from "./Toast";

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const customToast = (toastProps: Omit<ToastProps, "toastId">) => {
    return toast.custom(
      (t) => {
        return <Toast toastId={t} {...toastProps} />;
      },
      {
        position: "top-center",
      },
    );
  };

  const notifyDanger = useCallback((error: string | AxiosError<any>) => {
    if (typeof error === "string") {
      customToast({ message: error, intent: "error" });
    } else if (isAxiosError(error)) {
      const { response } = error;
      if (response?.data?.errors) {
        const parser = new GenericValidatorParser(response.data.errors);
        customToast({
          message: "An error occurred.",
          description: parser.toString(),
          intent: "error",
        });
      } else if (response?.data?.error_message) {
        customToast({
          message: "An error occurred.",
          description: response.data.error_message,
          intent: "error",
        });
      } else {
        customToast({
          message: "Something unexpected happened.",
          intent: "error",
        });
      }
    } else {
      console.error(error);
    }
  }, []);

  return (
    <ToastContext.Provider
      value={{
        notifyDanger,
        toast: customToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
