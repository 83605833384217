// @ts-ignore
import { tzDateTimeStringToUtc } from "@gymflow/common";
import { RevenueType } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useRevenueByTypeReportCsv({
  api,
  tz,
}: {
  api: {
    revenueApi: {
      csv: (
        params: {
          dateFrom?: string;
          dateTo?: string;
        },
        revenueType: RevenueType,
      ) => Promise<any>;
    };
  };
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      revenueType,
      dateFrom,
      dateTo,
    }: {
      revenueType: RevenueType;
      dateFrom?: string;
      dateTo?: string;
    }) => {
      const result = await api.revenueApi.csv(
        {
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
        },
        revenueType,
      );
      return result.data as string;
    },
  });
  return csvMutation;
}
