import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryMembershipListAsPublic } from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { useApiResolverContext } from "apps/portal/src/providers";
import React, { useEffect, useMemo, useState } from "react";

import useGymflowModels from "../../../store";
import { Button } from "../../atoms";
import { EmptyPage } from "../../atoms/EmptyPage";
import { SortPopover, SortType } from "../SortPopover";
import {
  membershipFilters,
  MembershipTabType,
  membershipTypeToString,
} from "./constants";
import { HostedPagesMembershipCard } from "./HostedPagesMembershipCard";
import {
  HostedPagesMembershipFilterPopover,
  HostedPagesMembershipFilterPopoverProps,
} from "./HostedPagesMembershipFilterPopover";
import { useMembershipAvailableTabs } from "./useMembershipAvailableTabs";

export type HostedPagesMembershipListProps = {};

export const HostedPagesMembershipList: React.FC<
  HostedPagesMembershipListProps
> = ({}) => {
  const { reinitializeWithAuth } = useApiResolverContext();
  useEffect(() => {
    reinitializeWithAuth();
  }, [reinitializeWithAuth]);
  const { api } = useGymflowModels();

  const [sort, setSort] = useState<SortType | undefined>("ASC");
  const [currentTab, setCurrentTab] = useState<MembershipTabType>("recurring");
  const [uiFilter, setUiFilter] = useState<
    HostedPagesMembershipFilterPopoverProps["filter"]
  >({
    durationType: [],
  });

  const { data } = useQueryMembershipListAsPublic({
    api,
    opts: {
      status: "ACTIVE",
      size: 200,
      page: 0,
      ...membershipFilters[currentTab],
      ...uiFilter,
    },
  });
  const sortedMemberships = useMemo(() => {
    return data?.content.sort((a, b) => {
      if (!sort) return 0;
      if (sort === "ASC") {
        return a.defaultPrice - b.defaultPrice;
      } else {
        return b.defaultPrice - a.defaultPrice;
      }
    });
  }, [data?.content, sort]);
  const tabs = useMembershipAvailableTabs();
  const [parent] = useAutoAnimate();
  return (
    <div className="flex h-full flex-col bg-gray-50 dark:bg-gray-800">
      <div className="track-height flex flex-col gap-4 border-b border-gray-300 bg-[#ffffff] p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
        <div className="flex flex-row flex-wrap items-center justify-between gap-2">
          <div className="flex flex-row items-center gap-4">
            <div className="text-xl font-medium text-gray-950 dark:text-white">
              Memberships
            </div>
            <div className="hidden flex-row items-center gap-2 lg:flex">
              {tabs.map((e) => {
                return (
                  <Button
                    key={e}
                    size="small"
                    className={cn("text-sm transition-all", {
                      "!border-secondary-500 !text-secondary-500":
                        currentTab === e,
                    })}
                    onClick={() => setCurrentTab(e)}
                  >
                    {membershipTypeToString[e]}
                  </Button>
                );
              })}
            </div>
          </div>
          <div className="flex flex-row gap-2">
            <SortPopover onChange={setSort} value={sort} />
            <HostedPagesMembershipFilterPopover
              filter={uiFilter}
              onChange={setUiFilter}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 lg:hidden">
          {tabs.map((e) => {
            return (
              <Button
                key={e}
                size="small"
                className={cn("text-sm transition-all flex-1", {
                  "!border-secondary-500 !text-secondary-500": currentTab === e,
                })}
                onClick={() => setCurrentTab(e)}
              >
                {membershipTypeToString[e]}
              </Button>
            );
          })}
        </div>
      </div>

      <div
        className="track-height relative grid min-h-[16rem] content-start gap-4 overflow-y-auto overflow-x-hidden bg-gray-50 p-4 dark:bg-gray-800 lg:grid-cols-3 lg:gap-x-6 lg:gap-y-8 lg:px-28 lg:pt-6"
        ref={parent}
      >
        {sortedMemberships?.length === 0 && (
          <div className="absolute inset-0 flex items-center justify-center">
            <EmptyPage />
          </div>
        )}
        {sortedMemberships?.map((e) => (
          <HostedPagesMembershipCard key={e.id} membership={e} />
        ))}
      </div>
    </div>
  );
};
