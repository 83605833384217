import { KisiGroupMapping } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useQueryKisiGroupMappings(
  {
    api,
    clubId,
  }: {
    api: {
      kisiApi: {
        fetchGroupMappings: (
          clubId: number,
        ) => Promise<{ data: KisiGroupMapping[] }>;
      };
    };
    clubId: number;
  },
  queryOpts?: UseQueryOptions<KisiGroupMapping[]>,
) {
  const result = useQuery({
    queryKey: kisiQueryKeys.groupMappings(clubId),
    queryFn: async () => {
      const result = await api.kisiApi.fetchGroupMappings(clubId);
      return result.data;
    },
    enabled: !!clubId,
    ...queryOpts,
  });
  return result;
}
