export function CopyIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2751_11640)">
        <path
          className={pathClassName}
          d="M4.16666 12.5C3.39009 12.5 3.0018 12.5 2.69552 12.3732C2.28714 12.204 1.96268 11.8795 1.79352 11.4712C1.66666 11.1649 1.66666 10.7766 1.66666 10V4.33335C1.66666 3.39993 1.66666 2.93322 1.84831 2.5767C2.0081 2.2631 2.26307 2.00813 2.57667 1.84834C2.93319 1.66669 3.3999 1.66669 4.33332 1.66669H9.99999C10.7766 1.66669 11.1648 1.66669 11.4711 1.79355C11.8795 1.96271 12.204 2.28717 12.3731 2.69555C12.5 3.00183 12.5 3.39012 12.5 4.16669M10.1667 18.3334H15.6667C16.6001 18.3334 17.0668 18.3334 17.4233 18.1517C17.7369 17.9919 17.9919 17.7369 18.1517 17.4233C18.3333 17.0668 18.3333 16.6001 18.3333 15.6667V10.1667C18.3333 9.23327 18.3333 8.76656 18.1517 8.41004C17.9919 8.09643 17.7369 7.84147 17.4233 7.68168C17.0668 7.50002 16.6001 7.50002 15.6667 7.50002H10.1667C9.23324 7.50002 8.76653 7.50002 8.41001 7.68168C8.0964 7.84147 7.84143 8.09643 7.68165 8.41004C7.49999 8.76656 7.49999 9.23327 7.49999 10.1667V15.6667C7.49999 16.6001 7.49999 17.0668 7.68165 17.4233C7.84143 17.7369 8.0964 17.9919 8.41001 18.1517C8.76653 18.3334 9.23324 18.3334 10.1667 18.3334Z"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2751_11640">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
