import PropTypes from 'prop-types';
import classNames from 'classnames';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import SessionPackCard from '../molecules/SessionPackCard';

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 1440 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1440, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function SessionPackCarousel({ sessionPacks, onSelectClick }) {
  return (
    <Carousel responsive={responsive} partialVisible={false}>
      {sessionPacks.map(({ id, name, description, price, sessionsIncluded, expiry }, idx) => (
        <div key={id} style={{ minHeight: '320px' }} className={classNames('h-100', 'pr-2', { 'pl-2': idx !== 0 })}>
          <SessionPackCard
            name={name}
            description={description}
            price={price}
            sessionsIncluded={sessionsIncluded}
            expiry={expiry}
            onButtonClick={() => onSelectClick(id)}
          />
        </div>
      ))}
    </Carousel>
  );
}

SessionPackCarousel.defaultProps = {
  sessionPacks: [],
};

SessionPackCarousel.propTypes = {
  sessionPacks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.string.isRequired,
      sessionsIncluded: PropTypes.string.isRequired,
      expiry: PropTypes.string,
    })
  ),
  onSelectClick: PropTypes.func.isRequired,
};

export default SessionPackCarousel;
