import { useLocation } from "react-router-dom";

import { usePortalRoutes } from "./usePortalRoutes";
import { RouteLayout } from "../routes/layout";
import { RouteFeature } from "../routes/feature";
import { BuyMembershipSubRoute } from "../routes/site/buyMembershipSubRoute";

export function useSaleResult() {
  const location = useLocation();
  const { createClubLink, isPathInLayout } = usePortalRoutes();
  const isEmbed = isPathInLayout(location.pathname, RouteLayout.Embed);

  const getRedirectUrlAfterPurchaseLinkForSaleResult = (saleResult) => {
    switch (saleResult) {
      case SaleResult.MembershipSold:
      case SaleResult.MembershipSoldButNotBooked:
        return createClubLink(
          isEmbed ? RouteLayout.Embed : RouteLayout.Site,
          RouteFeature.SiteBuyMembership,
          BuyMembershipSubRoute.RecurringMembership,
        );
      case SaleResult.SessionPackSold:
      case SaleResult.SessionPackSoldButNotBooked:
        return createClubLink(isEmbed ? RouteLayout.Embed : RouteLayout.Site, RouteFeature.SiteBuySessionPack);
      default:
        throw new Error("Unknown SaleResult");
    }
  };

  return {
    getFromMessageResult: (result) => messageMap[result],
    getRedirectUrlAfterPurchaseLinkForSaleResult,
  };
}

export const SaleResult = {
  MembershipSold: "MEMBERSHIP_SOLD",
  MembershipSoldButNotBooked: "MEMBERSHIP_SOLD_NOT_BOOKED",
  SessionPackSold: "SESSION_PACK_SOLD",
  SessionPackSoldButNotBooked: "SESSION_PACK_SOLD_NOT_BOOKED",
};

const messageMap = {
  [SaleResult.MembershipSold]: {
    intent: "success",
    message: "Membership purchased. Please check your email for login details.",
    title: "Welcome!",
  },
  [SaleResult.MembershipSoldButNotBooked]: {
    intent: "warning",
    message:
      "We could not book you into the event. However an account was created with your details. Check your email to finish the sign up progress and then try to book again in the Customer Portal.",
    title: "Oh no!",
  },
  [SaleResult.SessionPackSold]: {
    intent: "success",
    message: "Credit pack purchased. Please check your email for login details.",
    title: "Welcome!",
  },
  [SaleResult.SessionPackSoldButNotBooked]: {
    intent: "warning",
    message:
      "We could not book you into the event. However an account was created with your details. Check your email to finish the sign up progress and then try to book again in the Customer Portal.",
    title: "Oh no!",
  },
};

export default useSaleResult;
