import classNames from "classnames";

export function Avatar({
  url,
  className,
}: {
  url?: string;
  className?: string;
}) {
  const style: React.CSSProperties = {};
  if (url) {
    style.backgroundImage = `url(${url})`;
  }
  return (
    <div
      className={classNames(
        "h-[35px] w-[35px] bg-contain bg-center bg-no-repeat rounded-full",
        { "bg-avatar-placeholder": !url },
        className,
      )}
      style={style}
    />
  );
}
