import { z } from "zod";

export const AutomationPurpose = {
  Marketing: "MARKETING",
  AccountRelated: "ACCOUNT_RELATED",
} as const;

export const AutomationPurposeZodSchema = z.enum([
  AutomationPurpose.Marketing,
  AutomationPurpose.AccountRelated,
]);

Object.setPrototypeOf(AutomationPurpose, {
  humanize: (
    value: (typeof AutomationPurpose)[keyof typeof AutomationPurpose],
  ) => {
    switch (value) {
      case AutomationPurpose.Marketing:
        return "Marketing";
      case AutomationPurpose.AccountRelated:
        return "Account Related";
      default:
        throw new Error("Unknown AutomationPurpose.");
    }
  },
});
