import { DATE_FORMAT, DateTimeSelect } from "@gymflow/common";
import classNames from "classnames";
import moment from "moment";
import { Col, Row } from "reactstrap";

export function Step6Timetable({
  value,
  onChange,
}: {
  value: { date?: string };
  onChange: (newValue: any) => void;
}) {
  return (
    <div className="w-100 text-center">
      <b className="h2">Date (optional)</b>
      <Row className="d-flex justify-content-center mt-4 text-left">
        <Col md={8}>
          <p className="text-muted">
            Choose the default date the calendar will open on, if no date is
            specified the page will always open in the current day.
          </p>

          <div className="mt-3 flex flex-col items-center">
            <DateTimeSelect
              className="w-52"
              dateTimeClassName="max-w-[10rem]"
              value={value?.date || ""}
              onChange={(v: any) => {
                onChange({ ...value, date: moment(v).format(DATE_FORMAT) });
              }}
            />
            <div className={classNames({ hidden: !value?.date })}>
              <span
                className="cursor-pointer text-sm text-[#31436e] hover:underline"
                onClick={() => {
                  onChange({ ...value, date: undefined });
                }}
              >
                Clear date
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
