import {
  useRevenueByTypeReport,
  useRevenueByTypeReportCsv,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  RevenueType,
} from "@gymflow/common";
import { downloadCsv, formatCurrency } from "@gymflow/helpers";
import { InvoiceRevenueReportItemDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { Badge, LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import useQueryParam from "../../hooks/useQueryParam";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { UserMemberPage } from "../UserMember";

export function RevenueSchedule() {
  const history = useHistory();
  const { api } = useGymflowModels();
  const { timezone, date_format, defaultCurrency } = useClubSettings();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);
  const { createMemberLink } = usePortalRoutes();

  const [dates, setDates] = useQueryParam("fromDates", "string") as any;
  const defaultDates = useMemo(
    () => ({
      startDate: moment().format(PARAMETER_DATE_FORMAT),
      endDate: moment().endOf("month").format(PARAMETER_DATE_FORMAT),
    }),
    [],
  );
  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, []);

  const { data, isFetching } = useRevenueByTypeReport({
    api,
    dateFrom: dates?.startDate,
    dateTo: dates?.endDate,
    tz: timezone,
    limit: pageSize,
    page: currentPage,
    revenueType: RevenueType.ScheduledRevenue,
  });

  const downloadCsvMutation = useRevenueByTypeReportCsv({
    api,
    tz: timezone,
  });

  const columnHelper = createColumnHelper<InvoiceRevenueReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor("invoiceDate", {
      cell: (info) => (
        <div className="text-gray-600">
          {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            date_format,
          )}
        </div>
      ),
      header: () => <div >Date</div>,
      enableSorting: false,
    }),
    columnHelper.accessor("name", {
      cell: (info) => {
        return (
          <LinkButton
            to={createMemberLink(
              info.row.original.userMemberId,
              UserMemberPage.Account,
            )}
          >
            {info.getValue()}
          </LinkButton>
        );
      },
      header: "User Name",
      enableSorting: false,
    }),
    columnHelper.accessor("subscriptionName", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Membership",
      enableSorting: false,
    }),
    columnHelper.accessor("subscriptionPrice", {
      cell: (info) => (
        <div className="text-gray-600">
          {formatCurrency(info.getValue(), defaultCurrency)}
        </div>
      ),
      header: "Standard Price",
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => (
        <div className="text-gray-600">
          {formatCurrency(info.getValue(), defaultCurrency)}
        </div>
      ),
      header: "Actual Price",
      footer: () =>
        `Total: ${
          data !== undefined ? formatCurrency(data.total, defaultCurrency) : ""
        }`,
      enableSorting: false,
    }),
    columnHelper.accessor("promoCode", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue() || "-"}</div>;
      },
      header: "Promo Code",
      enableSorting: false,
    }),
    columnHelper.accessor("promoDiscount", {
      cell: (info) => {
        const value = info.getValue();
        if (!value) {
          return <div className="text-gray-600">-</div>;
        }
        const discountDuration = info.row.original?.recurringDiscountDuration;
        let badgeText = "";
        if (discountDuration) {
          badgeText = `${info.row.original?.cyclesLeft} left`;
        } else {
          badgeText = "Forever";
        }
        const promoType = info.row.original?.promoType;
        switch (promoType) {
          case "CURRENCY":
            return (
              <div className="flex gap-2 text-gray-600">
                <div>{formatCurrency(+value!, defaultCurrency)}</div>
                <Badge intent="primary">{badgeText}</Badge>
              </div>
            );
          case "PERCENTAGE":
            return (
              <div className="flex gap-2 text-gray-600">
                <div> {`${Math.floor(+value)}% `}</div>
                <Badge intent="primary">{badgeText}</Badge>
              </div>
            );
          default:
            return <div className="text-gray-600">-</div>;
        }
      },
      header: "Discount",
      enableSorting: false,
    }),
    columnHelper.accessor("endContractDate", {
      cell: (info) => {
        return (
          <div className="text-gray-600">
            {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              date_format,
            )}
          </div>
        );
      },
      header: "Contract End Date",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        setDates({
          startDate: moment(newDate.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          ),
          endDate: moment(newDate.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        });
      }
    },
    [setDates],
  );

  return (
    <Report
      title="Scheduled Billing"
      description="List of all scheduled invoices awaiting collection within the specified date range. This report excludes any memberships due to be paused or cancelled before the billing date and factors in any credits or promo codes used."
      onDownloadClick={async () => {
        const data = await downloadCsvMutation.mutateAsync({
          revenueType: RevenueType.ScheduledRevenue,
          dateFrom: dates?.startDate,
          dateTo: dates?.endDate,
        });
        downloadCsv(data, `ScheduledBilling.csv`);
      }}
      handleDateRangeChange={handleDateRangeChange}
      dateRange={
        dates?.startDate && dates?.endDate
          ? {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(dates.endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                .endOf("day")
                .format(DATE_FORMAT),
            }
          : undefined
      }
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(
                dates.endDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.data.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.data.totalPages}
            pageIndex={data?.data.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
          />
          <div className="border-t-[0.063rem] border-gray-300">
            <ReportPagination
              pageCount={data?.data.totalPages as number}
              currentPage={data?.data.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}
