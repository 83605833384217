import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import classNames from "classnames";
import { ReactNode, useEffect, useRef, useState } from "react";
import { components } from "react-select";
import { ActionMeta } from "react-select/dist/declarations/src/types";
import { AsyncPaginate } from "react-select-async-paginate";
import { twMerge } from "tailwind-merge";

export interface PaginatedSelectOption {
  value: any;
  label: ReactNode;
  isDisabled?: boolean;
}

export interface PaginatedSelectAdditional {
  page: number;
}

const paginatedSelectVariants = cva("", {
  variants: {
    size: {
      small: "h-[2.375rem] text-sm",
      medium: "min-h-[2.5rem]",
    },
    color: {
      default: "border-gray-300",
      accent: "bg-secondary-500 hover:bg-secondary-500 border-secondary-500",
    },
  },
  defaultVariants: {
    size: "medium",
    color: "default",
  },
});

export type PaginatedSelectVariantProps = VariantProps<
  typeof paginatedSelectVariants
>;

export interface PaginatedSelectProps extends PaginatedSelectVariantProps {
  value: any;
  onChange: (newValue: any, action: ActionMeta<any>) => void;
  className?: string;
  loadOptions: (
    inputValue: string,
    loadedOptions: readonly PaginatedSelectOption[],
    additional: PaginatedSelectAdditional,
  ) => Promise<{
    options: PaginatedSelectOption[];
    hasMore?: boolean;
    additional?: PaginatedSelectAdditional;
  }>;
  isSearchable?: boolean;
  isMulti?: boolean;
  placeholder?: string;
  cacheUniqs?: ReadonlyArray<any>;
  isDisabled?: boolean;
  isClearable?: boolean;
  icon?: ReactNode;
  controlShouldRenderValue?: boolean;
  compact?: boolean;
  showMultiCountInsteadOfValues?: boolean;
  menuWidthShouldMatchText?: boolean;
  closeMenuOnSelect?: boolean;
}
/*** @deprecated Use Paginated Select instead ***/
export function CommonPaginatedSelect({
  value,
  onChange,
  className,
  loadOptions,
  isSearchable = false,
  isMulti = false,
  placeholder,
  cacheUniqs,
  isDisabled = false,
  isClearable,
  icon,
  controlShouldRenderValue,
  size,
  color,
  compact,
  showMultiCountInsteadOfValues,
  menuWidthShouldMatchText,
  closeMenuOnSelect,
}: PaginatedSelectProps) {
  const optionRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const hasAlreadyFocusedValue = useRef(false);
  useEffect(() => {
    if (!isMenuOpen) {
      hasAlreadyFocusedValue.current = false;
    }
  }, [isMenuOpen]);
  return (
    <AsyncPaginate
      loadOptions={async (inputValue, options, additional) => {
        const result = await loadOptions(
          inputValue,
          options,
          additional || { page: 0 },
        );

        return {
          additional: { page: 0 },
          ...result,
        };
      }}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }: any) => {
          if (compact) {
            return null;
          }
          if (menuIsOpen) {
            return (
              <FontAwesomeIcon
                icon={faAngleUp}
                className={cn(dropdownIndicatorVariants({ color, size }))}
              />
            );
          }
          return (
            <FontAwesomeIcon
              icon={faAngleDown}
              className={cn(dropdownIndicatorVariants({ color, size }))}
            />
          );
        },
        IndicatorSeparator: () => {
          return null;
        },
        Option: (props) => {
          if (!hasAlreadyFocusedValue.current) {
            hasAlreadyFocusedValue.current = true;
            setTimeout(() => {
              if (optionRef.current) {
                optionRef.current?.parentElement?.scroll({
                  top: optionRef.current.offsetTop,
                });
              }
            }, 10);
          }
          return (
            <div
              ref={
                (props as any).value === value?.value ? optionRef : undefined
              }
            >
              <components.Option {...props} />
            </div>
          );
        },
        ValueContainer: ({ children, className, ...props }) => {
          const values = props.getValue();

          let content = children;
          if (showMultiCountInsteadOfValues && values && values.length > 0) {
            content = `${values.length} selected`;
          }

          return (
            <>
              <div className={classNames("pl-2", { hidden: !icon })}>
                {icon}
              </div>
              <components.ValueContainer
                className={twMerge(
                  classNames(
                    "!py-0 !pl-0 text-left font-semibold",
                    valueContainerVariants({ color }),
                    { "!pr-4": !compact },
                    { "ml-4": !icon },
                    className,
                  ),
                )}
                {...props}
              >
                {content}
              </components.ValueContainer>
            </>
          );
        },
        MultiValue: ({ children, ...props }) => {
          const values = props.getValue();
          if (compact) {
            if (values[0]) {
              return values[0].label;
            }
          }

          return (
            <components.MultiValue {...props}>{children}</components.MultiValue>
          );
        },
      }}
      classNames={{
        control: () =>
          "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap min-h-0",
        menu: () =>
          classNames("!z-[9999] !rounded-lg", {
            "-ml-[4.2rem]": compact,
            "w-auto": compact || menuWidthShouldMatchText,
          }),
        option: ({ isSelected }: { isSelected: boolean }) => {
          return classNames(optionVariants({ size }), {
            "font-semibold": isSelected,
          });
        },
        valueContainer: () =>
          classNames("border-none shadow-none cursor-pointer", {
            "!ml-2": compact,
          }),
      }}
      className={cn(
        "dark:border-darkGray-700 dark:hover:bg-darkGray-900 flex min-w-fit cursor-pointer items-center !rounded-lg border border-gray-300 text-center hover:bg-gray-100",
        paginatedSelectVariants({ size, color }),
        { "bg-gray-100": isDisabled },
        className,
      )}
      value={value}
      onChange={onChange}
      isSearchable={isSearchable}
      isMulti={isMulti}
      placeholder={placeholder}
      additional={{ page: 0 }}
      cacheUniqs={cacheUniqs}
      isDisabled={isDisabled}
      isClearable={isClearable}
      controlShouldRenderValue={controlShouldRenderValue}
      menuPlacement="auto"
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => {
        // Fix for menu instantly closing with touch events
        setTimeout(() => {
          setIsMenuOpen(true);
        }, 1);
      }}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      closeMenuOnSelect={closeMenuOnSelect}
    />
  );
}

const dropdownIndicatorVariants = cva("mr-4 cursor-pointer", {
  variants: {
    color: {
      default: "text-gray-500",
      accent: "text-white",
    },
    size: {
      small: "mt-px",
      medium: "",
    },
  },
  defaultVariants: {
    color: "default",
    size: "medium",
  },
});

const valueContainerVariants = cva("", {
  variants: {
    color: {
      default: "text-gray-500 [&>div]:text-gray-500",
      accent: "text-white [&>div]:text-white",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

const optionVariants = cva(
  "dark:hover:!bg-darkGray-900 hover:!bg-primary-50 dark:!bg-gray-950 dark:!text-darkGray-50 !cursor-pointer !bg-white text-left !text-gray-800",
  {
    variants: {
      size: {
        small: "text-sm",
        medium: "text-base",
      },
    },
    defaultVariants: {
      size: "medium",
    },
  },
);
