export function CalendarBlockedIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      width="50"
      height="57"
      viewBox="0 0 50 57"
      fill="none"
      version="1.1"
      className={className}
    >
      <defs id="defs1" />
      <path
        className={pathClassName}
        d="M48 23.2747H2M35.2222 2.66675V12.9707M14.7778 2.66675V12.9707M14.2667 54.1867H35.7333C40.0271 54.1867 42.1739 54.1867 43.8139 53.3444C45.2565 52.6035 46.4294 51.4213 47.1644 49.9672C48 48.3141 48 46.15 48 41.8219V20.1835C48 15.8555 48 13.6914 47.1644 12.0383C46.4294 10.5842 45.2565 9.40196 43.8139 8.66105C42.1739 7.81875 40.0271 7.81875 35.7333 7.81875H14.2667C9.97293 7.81875 7.82606 7.81875 6.18607 8.66105C4.7435 9.40196 3.57065 10.5842 2.83562 12.0383C2 13.6914 2 15.8555 2 20.1835V41.8219C2 46.15 2 48.3141 2.83562 49.9672C3.57065 51.4213 4.7435 52.6035 6.18607 53.3444C7.82606 54.1867 9.97293 54.1867 14.2667 54.1867Z"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="path1"
      />
      <g
        style={{ fill: "none" }}
        id="g1"
        transform="translate(18.0001,29.158121)"
      >
        <path
          className={pathClassName}
          d="m 11.5999,2.94678 -9.2,9.20002 m 0,-9.20002 9.2,9.20002"
          stroke="#000000"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="path1-9"
        />
      </g>
    </svg>
  );
}
