import { QueryKey } from "@tanstack/react-query";

export const creditPackAsPublicQueryKeys = {
  all: () => ["credit-pack-as-public"] as const,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...creditPackAsPublicQueryKeys.all(), { filters }] as QueryKey,
  summary: (params?: Readonly<{ [k: string]: any }>) =>
    [...creditPackAsPublicQueryKeys.all(), "summary", params] as QueryKey,
  details: (sessionPackId?: number) =>
    [...creditPackAsPublicQueryKeys.all(), sessionPackId] as QueryKey,
};
