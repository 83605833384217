import { VerboseFormattingArg } from "@fullcalendar/core/internal";
import moment from "moment-timezone";

export function TimeGridWeekDayTitleFormat(date: VerboseFormattingArg) {
  const isInsideSameMonth =
    date.start.marker.getMonth() === date.end!.marker.getMonth();
  const formattedStart = moment(date.start.marker).format("MMM Do");
  const formattedEnd = moment(date.end!.marker).format(
    isInsideSameMonth ? "Do" : "MMM Do",
  );
  return `${formattedStart} - ${formattedEnd}`;
}
