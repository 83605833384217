import { useClubFeatureFlags } from "@gymflow/api";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { Automations } from "../components/Marketing/Automations";
import { Promotions } from "../components/Marketing/Promotions";
import { Templates } from "../components/Marketing/Templates";
import PillTabsLayout from "../components/molecules/PillTabsLayout";
import { Promotions as PromotionsNew } from "../components/pages";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export const marketingRoute = {
  Automations: `/automations`,
  Promotions: `/promotions`,
  Templates: `/templates`,
};

export function Marketing() {
  const { url } = useRouteMatch();
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });
  if (featureFlags?.featureFlags.PROMOTION_PAGE_REDESIGN) {
    return (
      <div className="flex h-full max-h-full w-full flex-col gap-y-4 p-4 lg:p-8">
        <Switch>
          <Route path={`${url}${marketingRoute.Templates}`}>
            <Templates />
          </Route>
          <Route path={`${url}${marketingRoute.Promotions}`}>
            <PromotionsNew />
          </Route>
          <Route>
            <Redirect to={`${url}${marketingRoute.Templates}`} />
          </Route>
        </Switch>
      </div>
    );
  }

  return <MarketingOld />;
}

export function MarketingOld() {
  const { url } = useRouteMatch();

  const layoutConfig = [
    {
      text: "Automations",
      path: `${url}${marketingRoute.Automations}`,
      component: Automations,
    },
    {
      text: "Promotions",
      path: `${url}${marketingRoute.Promotions}`,
      component: Promotions,
    },
    {
      text: "Templates",
      path: `${url}${marketingRoute.Templates}`,
      component: Templates,
    },
  ];
  return <PillTabsLayout className="p-8" tabs={layoutConfig} />;
}
