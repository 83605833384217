import { useEffect, useRef, useState } from "react";

import { StrongAuthorizationPaymentStatus } from "../../constants/StrongAuthorizationPaymentStatus";

const RETRY_SECONDS = 60;
const successStatuses = [
  StrongAuthorizationPaymentStatus.AuthorizationNotRequired,
  StrongAuthorizationPaymentStatus.Succeeded,
];
const usePaymentAuthorization = ({ onSuccess, onFailed, paymentIntentIdForAuthorization, confirmPayment }) => {
  const intervalRef = useRef(null);
  const [seconds, setSeconds] = useState(RETRY_SECONDS);

  const stopInterval = () => clearInterval(intervalRef.current);
  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      if (seconds === 0) {
        const {
          data: { result },
        } = await confirmPayment(paymentIntentIdForAuthorization);
        if (successStatuses.includes(result)) {
          stopInterval();
          onSuccess();
        } else if (result === StrongAuthorizationPaymentStatus.Failed) {
          stopInterval();
          onFailed();
        } else {
          setSeconds(RETRY_SECONDS);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return stopInterval;
  }, [paymentIntentIdForAuthorization, seconds]);

  return { cancel: stopInterval, secondsUntilNextCheck: seconds, check: () => setSeconds(0) };
};

export default usePaymentAuthorization;
