import {
  DATE_FORMAT,
  FormikDateTime,
  formikHelpers,
  FormikInput,
} from "@gymflow/common";
import { useClubSettings } from "apps/portal/src/providers";
import moment from "moment-timezone";
import { Col, FormGroup, Label, Row } from "reactstrap";

import { EXPIRY } from "./PromotionSchema";

function Step6({ formikProps }) {
  const { values } = formikProps;
  const { errorClass } = formikHelpers(formikProps);
  const settings = useClubSettings();
  const dateFormat = settings.date_format;

  return (
    <div className="w-100 text-center">
      <b className="h2">Do you want your code to expire?</b>
      <div className="text-muted mx-lg-5 mt-3">
        <p className="lh-130">
          You can also set an expiry date so you dont have to remember to come
          back and switch it off.
        </p>
      </div>

      <Row className="d-flex justify-content-center mt-4 text-left">
        <Col md={6}>
          <Row className="text-uppercase text-left">
            <Col>
              <Label tag="h3">Expiry date</Label>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormGroup className={errorClass(EXPIRY)}>
                <FormikInput
                  name={EXPIRY}
                  component={FormikDateTime}
                  inputProps={{
                    className: "form-control",
                    name: EXPIRY,
                    autoComplete: "off",
                    id: EXPIRY,
                    placeholder: "Doesn't Expire",
                    value: moment(values[EXPIRY], DATE_FORMAT).isValid()
                      ? moment(values[EXPIRY], DATE_FORMAT).format(dateFormat)
                      : undefined,
                  }}
                  onOpen={() =>
                    values[EXPIRY] ||
                    formikProps.setFieldValue(
                      EXPIRY,
                      moment().format(DATE_FORMAT),
                    )
                  }
                  timeFormat={false}
                  dateFormat={DATE_FORMAT}
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step6;
