import { cn } from "@gymflow/helpers";
import React, { ReactNode, useState } from "react";
import { Tooltip } from "react-tooltip";

export type HostedPagesCardProps = {
  children: React.ReactNode;
};

export const HostedPagesCard = {
  Container: ({ children }: HostedPagesCardProps) => {
    return (
      <div
        className={cn("flex flex-col gap-3 rounded-2xl border p-4 shadow-sm", [
          "border-gray-200 bg-white",
          "dark:border-gray-700 dark:bg-gray-950",
        ])}
      >
        {children}
      </div>
    );
  },
  Separator: () => (
    <div
      className={cn("mt-auto border-b", [
        "border-gray-300",
        "dark:bg-gray-950",
      ])}
    />
  ),
  Header: ({ children }: HostedPagesCardProps) => {
    return <div className="flex flex-col gap-2">{children}</div>;
  },
  Footer: ({ children }: HostedPagesCardProps) => {
    return (
      <div className="flex flex-row flex-wrap items-center gap-2">
        {children}
      </div>
    );
  },
  Price: ({
    price,
    description,
  }: {
    price: ReactNode;
    description: ReactNode;
  }) => {
    return (
      <div className="flex flex-1 flex-col flex-wrap">
        <div className="text-lg font-bold text-gray-950 dark:text-white">
          {price}
        </div>
        <div className="flex flex-row items-center gap-1 whitespace-nowrap text-sm text-gray-500">
          {description}
        </div>
      </div>
    );
  },
  Description: ({
    description,
    hideTooltip,
  }: {
    description: string;
    hideTooltip?: boolean;
  }) => {
    const [id] = useState(Math.random().toString(36).substring(2, 15));
    return (
      <div
        className="line-clamp-2 cursor-default text-ellipsis text-sm text-gray-500"
        data-tooltip-id={`tooltip-${id}`}
        data-tooltip-content={description}
      >
        {description}
        {!hideTooltip && (
          <Tooltip
            className="!max-w-[min(24rem,calc(100vw-2rem))] !rounded-xl !bg-black !px-3 !py-2 !text-center !text-sm !font-semibold !text-[#ffffff] !opacity-100"
            id={`tooltip-${id}`}
          />
        )}
      </div>
    );
  },
};
