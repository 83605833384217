import { z } from "zod";

export const AutomationAudience = {
  Staff: "STAFF",
  Customer: "CUSTOMER",
} as const;

export const AutomationAudienceZodSchema = z.enum([
  AutomationAudience.Staff,
  AutomationAudience.Customer,
]);

Object.setPrototypeOf(AutomationAudience, {
  humanize: (
    value: (typeof AutomationAudience)[keyof typeof AutomationAudience],
  ) => {
    switch (value) {
      case AutomationAudience.Staff:
        return "Staff";
      case AutomationAudience.Customer:
        return "Customer";
      default:
        throw new Error("Unknown AutomationAudience.");
    }
  },
});
