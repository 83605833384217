import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { reportHomeRoutes } from "../../views/reports/reportHomeRoutes";
import { ReportContext } from "./ReportContext";
import { ReportRevenueOtherAccountReceivableWidget } from "./Widgets/Revenue/Other/AccountsReceivable";
import { ReportRevenueOtherScheduleBillingWidget } from "./Widgets/Revenue/Other/ScheduleBilling";
import { ReportRevenueSalesSummaryMembershipWidget } from "./Widgets/Revenue/SalesSummary/Membership";
import { ReportRevenueSalesSummaryProductWidget } from "./Widgets/Revenue/SalesSummary/Product";
import { ReportRevenueSalesSummarySessionWidget } from "./Widgets/Revenue/SalesSummary/Sessions";
import { ReportRevenueSalesSummaryTotalWidget } from "./Widgets/Revenue/SalesSummary/Total";
import { ReportRevenueTransactionDetailsAwaitingAuthWidget } from "./Widgets/Revenue/TransactionDetails/AwaitingAuth";
import { ReportRevenueTransactionDetailsCreditAppliedWidget } from "./Widgets/Revenue/TransactionDetails/CreditApplied";
import { ReportRevenueTransactionDetailsRefundsWidget } from "./Widgets/Revenue/TransactionDetails/Refunds";
import { ReportRevenueTransactionDetailsWriteOffWidget } from "./Widgets/Revenue/TransactionDetails/WriteOff";

export const TransactionType = {
  MANUAL_CREDIT: "MANUAL_CREDIT",
  REFUND_DEBIT: "REFUND_DEBIT",
  WRITE_OFF: "WRITE_OFF",
  PENDING_AUTHORIZATION: "PENDING_AUTHORIZATION",
};

export function Revenue() {
  const { createClubLink } = usePortalRoutes();
  const revenueRoute = createClubLink(
    RouteLayout.Staff,
    RouteFeature.Reports,
    reportHomeRoutes.Revenue,
  );
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();

  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);

  const { data, isFetching } = useStatistic({
    api,
    clubId: settings.clubId,
    start:
      dates?.startDate &&
      moment(dates.startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end:
      dates?.endDate &&
      moment(dates.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    trendPeriod: "MONTH",
    categories: ["revenue"],
    revenueFields: [
      "total",
      "membership",
      "sessionPack",
      "product",
      "credits",
      "writeOff",
      "refunds",
      "awaitingAuth",
    ],
  });
  return (
    <ReportContext.Provider
      value={{
        isLoading: isFetching,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        revenue: data?.revenue && {
          total: {
            total: data.revenue.total!.total!,
            variation: data.revenue.total!.var,
          },
          membership: {
            total: data.revenue.membership!.total!,
            variation: data.revenue.membership!.var,
          },
          sessionPack: {
            total: data.revenue.sessionPack!.total!,
            variation: data.revenue.sessionPack!.var,
          },
          product: {
            total: data.revenue.product!.total!,
            variation: data.revenue.product!.var,
          },
          creditsApplied: {
            total: data.revenue.credits!.total!,
            variation: data.revenue.credits!.var,
          },
          writeOff: {
            total: data.revenue.writeOff!.total!,
            variation: data.revenue.writeOff!.var,
          },
          refunds: {
            total: data.revenue.refunds!.total!,
            variation: data.revenue.refunds!.var,
          },
          awaitingAuth: {
            total: data.revenue.awaitingAuth!.total!,
            variation: data.revenue.awaitingAuth!.var,
          },
        },
      }}
    >
      <div>
        <div className="mt-6 text-lg font-semibold">Sales Summary</div>
        <div className="mx-[-.75rem] flex flex-wrap">
          <ReportRevenueSalesSummaryTotalWidget parentRoute={revenueRoute} />
          <ReportRevenueSalesSummaryMembershipWidget
            parentRoute={revenueRoute}
          />
          <ReportRevenueSalesSummarySessionWidget parentRoute={revenueRoute} />
          <ReportRevenueSalesSummaryProductWidget parentRoute={revenueRoute} />
        </div>
        <div className="mt-6 text-lg font-semibold">Transaction details</div>
        <div className="mx-[-.75rem] flex flex-wrap">
          <ReportRevenueTransactionDetailsCreditAppliedWidget
            parentRoute={revenueRoute}
          />
          <ReportRevenueTransactionDetailsWriteOffWidget
            parentRoute={revenueRoute}
          />
          <ReportRevenueTransactionDetailsRefundsWidget
            parentRoute={revenueRoute}
          />
          <ReportRevenueTransactionDetailsAwaitingAuthWidget
            parentRoute={revenueRoute}
          />
        </div>
        <div className="mt-6 text-lg font-semibold">Other</div>
        <div className="mx-[-.75rem] flex flex-wrap">
          <ReportRevenueOtherScheduleBillingWidget parentRoute={revenueRoute} />
          <ReportRevenueOtherAccountReceivableWidget
            parentRoute={revenueRoute}
          />
        </div>
      </div>
    </ReportContext.Provider>
  );
}
