import { leadSourceListQueryFn, useLeadCreate } from "@gymflow/api";
import {
  formikHelpers,
  FormikInput,
  MobileNumberInput,
  renderErrorRowOnTouch,
  useRecordForm,
} from "@gymflow/common";
import classNames from "classnames";
import { useFormik } from "formik";
import omit from "lodash/omit";
import { useContext, useEffect, useMemo, useState } from "react";
import { Form, FormGroup } from "reactstrap";

import { ModalContext, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import { Button, PaginatedSelect } from "../atoms";
import createLeadMapper from "../Leads/mapper";
import createLeadSchema, {
  EMAIL,
  EMAIL_COMMUNICATION,
  FIRST_NAME,
  LAST_NAME,
  PHONE_NUMBER,
  SMS_COMMUNICATION,
  SOURCE_ID,
} from "../Leads/schema";
import { LeadSourceModal } from "../organisms";

export type SideBarLeadFormProps = {
  hide: () => void;
  onLeadCreated: (newLeadId: number) => void;
  onChange: (newValue: any) => void;
  defaultValues: any;
};

export function SideBarLeadForm({
  hide,
  onLeadCreated,
  onChange,
  defaultValues,
}: SideBarLeadFormProps) {
  const { notifyDanger } = useContext(ToastContext);
  const settings = useClubSettings();

  const mapper = useMemo(() => createLeadMapper(), []);
  const schema = useMemo(() => createLeadSchema(), []);
  const [leadSourceName, setLeadSourceName] = useState(
    defaultValues?.["source"]?.["name"] ?? "",
  );
  const { initialValues, getValues } = useRecordForm({
    record: {
      ...omit(defaultValues, "source"),
      [SOURCE_ID]: defaultValues?.source?.id,
    },
    fields: (schema as any).default(),
    mapper,
  });

  const formikProps = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: () => undefined,
  });
  const { values } = formikProps;
  useEffect(() => {
    onChange(
      omit(
        {
          ...values,
          source: { name: leadSourceName, id: values[SOURCE_ID] },
        },
        [SOURCE_ID],
      ),
    );
  }, [leadSourceName, onChange, values]);
  const { errorClass } = formikHelpers(formikProps);
  const { api } = useGymflowModels();
  const createLeadMutation = useLeadCreate({ api });
  const { setModal, hide: hideModal } = useContext(ModalContext);
  return (
    <>
      <div className="mt-4 flex h-full overflow-y-auto px-6">
        <Form className="flex w-full flex-col" role="form">
          <div className="mb-1">Email</div>
          <FormGroup className={classNames("after:!hidden", errorClass(EMAIL))}>
            <FormikInput
              name={EMAIL}
              placeholder="Enter Email"
              type="email"
              data-testid={EMAIL}
              autoComplete="off"
              maxLength={128}
              formikProps={formikProps}
              className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
            />
          </FormGroup>
          <div className="mb-1">First Name</div>
          <FormGroup
            className={classNames("after:!hidden", errorClass(FIRST_NAME))}
          >
            <FormikInput
              name={FIRST_NAME}
              placeholder="Enter First Name"
              id={FIRST_NAME}
              type="text"
              maxLength={128}
              formikProps={formikProps}
              className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
            />
          </FormGroup>
          <div className="mb-1">Last Name</div>
          <FormGroup
            className={classNames("after:!hidden", errorClass(LAST_NAME))}
          >
            <FormikInput
              placeholder="Enter Last Name"
              name={LAST_NAME}
              id={LAST_NAME}
              type="text"
              formikProps={formikProps}
              className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
            />
          </FormGroup>
          <>
            <div className="mb-1">Phone</div>
            <FormGroup
              className={classNames("after:!hidden", errorClass(PHONE_NUMBER))}
            >
              <FormikInput
                name={PHONE_NUMBER}
                placeholder="Enter Mobile Number"
                component={MobileNumberInput}
                type="number"
                id={PHONE_NUMBER}
                maxLength={32}
                formikProps={formikProps}
                phone_number_country={settings.phone_number_country}
                className="!h-11 !rounded-lg !border !border-gray-300 !px-3 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
              />
            </FormGroup>
          </>
          <div className="mb-1">How did you hear about us?</div>

          <FormGroup
            className={classNames("dropdown-group", errorClass(SOURCE_ID))}
            data-testid={SOURCE_ID}
          >
            <PaginatedSelect
              showMenuFooterButton
              menuFooterClick={async () => {
                setModal(<LeadSourceModal onCancel={hideModal} />);
              }}
              menuFooterText="Create Lead Source"
              refetchOnMenuOpen
              placeholder="Select Source"
              loadOptions={async (_, __, { page }) => {
                const data = await leadSourceListQueryFn({
                  api,
                  opts: {
                    page,
                    extraParams: { active: true },
                  },
                });

                return {
                  options: data.content.map((source) => ({
                    label: source.name,
                    value: source,
                  })),
                  hasMore: !data.last,
                  additional: {
                    page: page + 1,
                  },
                };
              }}
              onChange={(value: any) => {
                setLeadSourceName(value.label);
                formikProps.setFieldValue(SOURCE_ID, value.value.id);
              }}
              value={
                formikProps.values[SOURCE_ID]
                  ? {
                      value: formikProps.values[SOURCE_ID],
                      label: leadSourceName,
                    }
                  : undefined
              }
            />
            {renderErrorRowOnTouch(
              SOURCE_ID,
              formikProps.touched,
              formikProps.errors,
            )}
          </FormGroup>

          <FormGroup check className={errorClass(EMAIL_COMMUNICATION)}>
            <div className="flex flex-row items-center">
              <input
                name={EMAIL_COMMUNICATION}
                type="checkbox"
                id={EMAIL_COMMUNICATION}
                data-testid="email-communication"
                checked={formikProps.values[EMAIL_COMMUNICATION]}
                onChange={({ target: { checked } }) => {
                  formikProps.setFieldValue(EMAIL_COMMUNICATION, checked);
                }}
                className="!visible !relative mr-2 h-4 w-4 rounded-md border-gray-200 bg-gray-100 !opacity-100"
              />
              Keep in touch via Email
            </div>
          </FormGroup>
          <FormGroup check className={errorClass(SMS_COMMUNICATION)}>
            <div className="flex flex-row items-center">
              <input
                name={SMS_COMMUNICATION}
                type="checkbox"
                data-testid="sms-communication"
                checked={formikProps.values[SMS_COMMUNICATION]}
                onChange={({ target: { checked } }) => {
                  formikProps.setFieldValue(SMS_COMMUNICATION, checked);
                }}
                className="!visible !relative mr-2 h-4 w-4 rounded-md border-gray-200 bg-gray-100 !opacity-100"
              />
              Keep in touch via SMS
            </div>
          </FormGroup>
        </Form>
      </div>
      <div className="flex h-20 flex-row items-center justify-end gap-2 border-t border-gray-200 px-6 py-4">
        <Button
          className="w-full"
          onClick={() => {
            formikProps.resetForm();
            hide();
          }}
        >
          Cancel
        </Button>
        <Button
          intent="primary"
          className="w-full"
          onClick={async () => {
            try {
              const errors = await formikProps.validateForm();
              if (Object.keys(errors).length > 0) {
                Object.keys(errors).forEach((key) => {
                  formikProps.setFieldTouched(key);
                });
                return;
              }

              const result = await createLeadMutation.mutateAsync(
                getValues(values),
              );
              formikProps.resetForm();
              hide();
              onLeadCreated(result.id);
            } catch (err) {
              notifyDanger(err as any);
            }
          }}
        >
          Create
        </Button>
      </div>
    </>
  );
}
