import { useContext } from "react";

import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportEventBookingPercentageWidget = () => {
  const data = useContext(ReportContext);

  return (
    <ReportWidgetCard
      title="Bookings Vs Capacity"
      value={`${(data.events?.bookingPercentage?.total ?? 0).toFixed(0)}%`}
      isLoading={data.isLoading}
      explanation="Total number of bookings divided by the total event capacity during the selected period."
      variation={data.events?.bookingPercentage?.variation}
    />
  );
};
