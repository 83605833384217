import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityOverrideDelete({
  api,
}: {
  api: {
    availabilityApi: {
      deleteHostOverride: (
        staffId: string,
        overrideId: number,
      ) => Promise<{ data: void }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      availabilityOverrideId,
    }: {
      staffId: string;
      availabilityOverrideId: number;
    }) => {
      await api.availabilityApi.deleteHostOverride(
        staffId,
        availabilityOverrideId,
      );
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allHostOverrides(staffId),
      });
    },
  });

  return mutation;
}
