import { useMemo } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { BootstrapServerRepository } from "@gymflow/api";

import environment from "../../environment";
import { AsyncSelect } from "./AsyncSelect";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { Badge } from "./Badge";

export function ClubMultiSelect({ onChange, value, className }: any) {
  const serverUrl = environment.get("API_RESOLVER_URL");
  const repository = useMemo(
    () => new BootstrapServerRepository(serverUrl),
    [serverUrl],
  );
  const { brand } = usePortalRoutes();
  const loadOptions = async (
    _: string,
    __: unknown,
    { page }: { page: number },
  ) => {
    const { results, hasMore } = await repository.getRoutesByBrand({
      brand,
      page,
      limit: 5,
    });
    return {
      options: results.map((r) => {
        return {
          label: r.displayName,
          value: {
            name: r.displayName,
            id: r.clubId,
          },
          isDisabled: value.some(
            ({ value: { id } }: { value: { id: number } }) => id === r.clubId,
          ),
        };
      }),
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncSelect
      additional={{ page: 0 }}
      loadOptions={loadOptions}
      value={value}
      onChange={onChange}
      isMulti
      // this rerenders every time
      cacheUniqs={[Math.random()]}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }: any) => {
          if (menuIsOpen) {
            return (
              <FontAwesomeIcon
                icon={faAngleUp}
                className="ml-2 mr-4 text-gray-500"
              />
            );
          }
          return (
            <FontAwesomeIcon
              icon={faAngleDown}
              className="ml-2 mr-4 text-gray-500"
            />
          );
        },
        IndicatorSeparator: () => {
          return null;
        },
        ValueContainer: ({ children }: any) => {
          return (
            <div className="ml-4 flex max-w-[70%] overflow-x-hidden font-semibold [&>div]:!text-gray-500">
              {children}
            </div>
          );
        },
        MultiValueContainer: ({ children }: any) => {
          return <Badge className="mr-1">{children}</Badge>;
        },
        MultiValueRemove: ({
          innerProps: {
            onClick,
            onMouseDown,
            onTouchEnd,
            "aria-label": ariaLabel,
            className,
          },
        }: any) => {
          return (
            <div
              className={classNames(
                "-mt-0.5 ml-1 cursor-pointer hover:text-gray-500",
                className,
              )}
              onClick={onClick}
              onMouseDown={onMouseDown}
              onTouchEnd={onTouchEnd}
              aria-label={ariaLabel}
            >
              {" "}
              x
            </div>
          );
        },
      }}
      classNames={{
        control: () =>
          "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap",
        menu: () => "!z-[9999] !rounded-lg",
        option: ({ isDisabled }: { isDisabled: boolean }) => {
          return classNames(
            {
              "!cursor-pointer !text-gray-800 hover:!bg-primary-50":
                !isDisabled,
            },
            `!bg-white`,
          );
        },
        valueContainer: () =>
          "border-none rounded-xl shadow-none h-[calc(2.75rem-2px)] cursor-pointer",
      }}
      className={classNames(
        "bg-gray-25 flex h-11 min-w-fit cursor-pointer items-center rounded-lg border border-gray-200 text-center hover:bg-gray-100",
        className,
      )}
    />
  );
}
