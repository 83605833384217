/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React from "react";

import gender from "../../../../../libs/common/src/lib/constants/gender";
import { PaginatedSelect } from "./PaginatedSelect";

export interface GenderSelectProps {
  value: string;
  onChange: (newValue: any) => void;
  isDisabled?: boolean;
}
const options = [
  {
    label: "Prefer not to say",
    value: gender.PreferNotToSay,
  },
  {
    label: "Female",
    value: gender.Female,
  },
  {
    label: "Male",
    value: gender.Male,
  },
];
const GenderSelect: React.FC<GenderSelectProps> = ({
  value,
  onChange,
  isDisabled,
}) => {
  const selectValue = options.find((option) => option.value === value);
  return (
    <PaginatedSelect
      className="h-10 min-h-[2.5rem]"
      loadOptions={async () => ({ options: options })}
      isSearchable={false}
      isDisabled={isDisabled}
      onChange={(newValue: any) => {
        if (newValue.value?.custom) {
          onChange("");
        } else {
          onChange(newValue.value);
        }
      }}
      value={selectValue}
    />
  );
};

export default GenderSelect;
