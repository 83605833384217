import * as Yup from "yup";

export const EMAIL = "email";
export const FIRST_NAME = "first-name";
export const LAST_NAME = "last-name";
export const MOBILE_NUMBER = "mobile-number";
export const PICTURE = "picture";
export const LOCATIONS = "club-accessible-list";
export const ROLE = "role";

const createStaffSchema = (
  defaultClub?: {
    label?: string;
    value?: { name?: string; id?: number };
  },
) =>
  Yup.object().shape({
    [EMAIL]: Yup.string().required().email(),
    [FIRST_NAME]: Yup.string().trim().required().min(2),
    [LAST_NAME]: Yup.string().trim().required().min(2),
    [MOBILE_NUMBER]: (Yup.string().required() as any).phoneNumber(),
    [PICTURE]: Yup.mixed().default({ blob: null }),
    [LOCATIONS]: Yup.array()
      .default([defaultClub])
      .min(1, "Must have access to at least one location."),
    [ROLE]: Yup.object().required(),
  });

export default createStaffSchema;
