import { useQuery,UseQueryOptions } from '@tanstack/react-query';

import { memberQueryKeys } from './memberQueryKeys';

export function useMemberCalculateProRataForChangingSubscription(
  { api, memberId, membershipId }: {
    api: {
      memberApi:
      {
        calculateChangeSubscriptionPrice: (
          memberId: string,
          membershipId: number,
        ) => Promise<any>
      }
    },
    memberId: string,
    membershipId?: number,
  }, opts?: UseQueryOptions<number | null>
) {
  const result = useQuery({
    queryKey: memberQueryKeys.calculateChangeSubscription(memberId, membershipId!),
    queryFn: async () => {
      const result = await api.memberApi.calculateChangeSubscriptionPrice(memberId, membershipId!);
      return result.data as number;
    },
    enabled: !!memberId && !!membershipId,
    initialData: null,
    ...opts
  });

  return result;
}
