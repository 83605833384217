import { action, thunk } from 'easy-peasy';
import { BaseModelBuilder, getApi, STATISTICS_CATEGORY, REVENUE_FIELD, MEMBERSHIP_FIELD, EVENT_FIELD } from '@gymflow/common';

class DashboardModelBuilder {
  constructor(apiKey, clubId) {
    this.generators = [];
    this.apiKey = apiKey;
    this.clubId = clubId;
    this.generators.push(this.dashboardGenerator);
  }

  dashboardGenerator() {
    return {
      apiKey: this.apiKey,
      live: {
        totalRevenue: {
          value: 0,
          lastYear: 0,
        },
        newMembers: {
          value: 0,
          lastYear: 0,
        },
        cancellations: {
          value: 0,
          lastYear: 0,
        },
        eventAttendance: {
          value: 0,
          lastYear: 0,
        },
        bookings: {
          value: 0,
          lastYear: 0,
        },
      },

      fetchDataForDay: thunk(async (actions, { day }, { injections, getState }) => {
        const api = getApi(injections, getState());

        const revenueFields = [REVENUE_FIELD.Total];
        const membershipFields = [MEMBERSHIP_FIELD.SignUps, MEMBERSHIP_FIELD.Cancelled];
        const eventFields = [EVENT_FIELD.Attendance, EVENT_FIELD.BookingPercentage];

        const { data: liveData } = await api.getData(
          this.clubId,
          day,
          day,
          'DAY',
          [STATISTICS_CATEGORY.Revenue, STATISTICS_CATEGORY.Membership, STATISTICS_CATEGORY.Event],
          revenueFields,
          membershipFields,
          eventFields
        );
        actions.updateData({ liveData });
      }),

      updateData: action((state, { liveData }) => {
        state.live = {
          ...state.live,

          totalRevenue: {
            value: liveData.revenue.total.values[0].amount,
            lastYear: liveData.revenue.total.var,
          },

          newMembers: {
            value: liveData.membership.signUps.values[0].amount,
            lastYear: liveData.membership.signUps.var,
          },

          cancellations: {
            value: liveData.membership.cancelled.values[0].amount,
            lastYear: liveData.membership.cancelled.var,
          },

          eventAttendance: {
            value: liveData.event.attendance.values[0].amount,
            lastYear: liveData.event.attendance.var,
          },

          bookings: {
            value: liveData.event.bookingPercentage.values[0].amount,
            lastYear: liveData.event.bookingPercentage.var,
          },
        };
      }),
    };
  }
}

DashboardModelBuilder.prototype.build = BaseModelBuilder.prototype.build;

export default DashboardModelBuilder;
