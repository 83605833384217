import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClubFeatureFlags } from "@gymflow/api";
import { formatCurrency, membershipHelper, ordinal } from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { DateTime } from "luxon";
import React, { useContext } from "react";

import { Badge, PlusIcon, TrashIcon } from "../../atoms";
import { AddPromotionCodeModal } from "./MembershipModal/AddPromotionCodeModal";
import { ExpireMembershipModal } from "./MembershipModal/ExpireMembershipModal";
import { RemovePromotionCodeModal } from "./MembershipModal/RemovePromotionCodeModal";
import { UpdateBillingMembershipModal } from "./MembershipModal/UpdateBillingMembershipModal";
import { UpdateContractEndDateMembershipModal } from "./MembershipModal/UpdateContractEndDateMembershipModal";
import { UpdateStartDateMembershipModal } from "./MembershipModal/UpdateStartDateMembershipModal";

const billingTexts = ({
  price,
  billingDay,
  billingPeriod,
}: {
  price: string;
  billingDay: string;
  billingPeriod: string;
}) => ({
  WEEKLY: {
    singular: `${price} every ${billingPeriod} week on ${billingDay}`,
    plural: `${price} every ${billingPeriod} weeks on ${billingDay}`,
  },
  MONTHLY: {
    singular: `${price} every ${billingPeriod} month on the ${ordinal(
      Number(billingDay),
    )}`,
    plural: `${price} every ${billingPeriod} months on the ${ordinal(
      Number(billingDay),
    )}`,
  },
});

export type MembershipInformationLinesProps = {
  membership: UserMemberSubscriptionBeanWithMembership;
  memberId: string;
};

export const MembershipInformationLines: React.FC<
  MembershipInformationLinesProps
> = ({ membership, memberId }) => {
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const { setModal } = useContext(ModalContext);

  const { clubId } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  const billingLine = (
    <MembershipInformationLine
      title="Billing"
      description={
        membership.billingType
          ? billingTexts({
              billingDay: membershipHelper.getBillingDay(membership),
              price: formatCurrency(
                membership.price,
                clubSettings.defaultCurrency,
              ),
              billingPeriod: `${membership.billingPeriod}`,
            })[membership.billingType][
              membership.billingPeriod === 1 ? "singular" : "plural"
            ]
          : ""
      }
      onClick={
        "ACTIVE" === membership.status
          ? () => {
              setModal(
                <UpdateBillingMembershipModal
                  membership={membership}
                  memberId={memberId}
                />,
              );
            }
          : undefined
      }
    />
  );
  const startDateLine = (
    <MembershipInformationLine
      title="Start Date"
      description={
        membership.startDate &&
        DateTime.fromISO(membership.startDate).toLocaleString(DateTime.DATE_MED)
      }
      onClick={
        "PENDING" === membership.status
          ? () => {
              setModal(
                <UpdateStartDateMembershipModal
                  memberId={memberId}
                  membership={membership}
                />,
              );
            }
          : undefined
      }
    />
  );
  const contractEndDateLine = (
    <MembershipInformationLine
      title="Contract End Date"
      description={
        membership.membershipBean.durationType === "NO_CONTRACT"
          ? "No Contract"
          : membership.endContractDate &&
            DateTime.fromISO(membership.endContractDate).toLocaleString(
              DateTime.DATE_MED,
            )
      }
      onClick={
        membership.membershipBean.durationType === "NO_CONTRACT"
          ? undefined
          : () =>
              setModal(
                <UpdateContractEndDateMembershipModal
                  membership={membership}
                />,
              )
      }
    />
  );

  const expiryDateLine = (
    <MembershipInformationLine
      title="Expiry Date"
      description={
        membership.endContractDate &&
        DateTime.fromISO(membership.endContractDate).toLocaleString(
          DateTime.DATE_MED,
        )
      }
      onClick={
        membership.status === "ACTIVE"
          ? () =>
              setModal(
                <ExpireMembershipModal
                  memberId={memberId}
                  membership={membership}
                />,
              )
          : undefined
      }
    />
  );

  const trialEndsLine = (
    <MembershipInformationLine
      title="Trial Ends"
      description={
        membership.endDate &&
        DateTime.fromISO(membership.endDate).toLocaleString(DateTime.DATE_MED)
      }
      onClick={() =>
        setModal(
          <ExpireMembershipModal memberId={memberId} membership={membership} />,
        )
      }
    />
  );

  const promoCodeLine =
    featureFlags?.featureFlags.ADD_REMOVE_PROMOTION_CODE_TO_SUBSCRIPTION &&
    membership.membershipBean.type === "RECURRING" ? (
      <div className="flex w-full">
        <div className="flex w-full items-center justify-between text-sm font-normal text-gray-600">
          <div className="min-w-fit font-semibold">Promotion Code:&nbsp;</div>
          {"promotionCode" in membership ? (
            membership.promotionIsExpired ? (
              <div className="flex w-full flex-row items-center justify-between">
                <div
                  className="text-secondary-500 flex flex-1 cursor-pointer items-center justify-start gap-1 text-xs font-semibold hover:font-bold sm:flex-[4]"
                  onClick={() => {
                    setModal(<AddPromotionCodeModal membership={membership} />);
                  }}
                >
                  <PlusIcon
                    className="h-4 w-4"
                    pathClassName="stroke-secondary-500"
                  />
                  <div>Add Code</div>
                </div>
                <div className="flex flex-row items-center gap-1 font-medium">
                  <Badge className="line-through">
                    {`"${membership.promotionCode}"`}
                  </Badge>
                  Expired
                </div>
              </div>
            ) : (
              <div className="flex flex-1 items-center justify-between sm:flex-[4]">
                <Badge>{`"${membership.promotionCode}"`}</Badge>
                <div
                  className="flex cursor-pointer items-center gap-1"
                  onClick={() => {
                    setModal(
                      <RemovePromotionCodeModal membership={membership} />,
                    );
                  }}
                >
                  <TrashIcon
                    className="h-3.5 w-3.5"
                    pathClassName="stroke-error-600"
                  />
                  <div className="text-error-600 text-xs font-semibold">
                    Remove Code
                  </div>
                </div>
              </div>
            )
          ) : (
            <div
              className="text-secondary-500 flex flex-1 cursor-pointer items-center justify-start gap-1 text-xs font-semibold hover:font-bold sm:flex-[4]"
              onClick={() => {
                setModal(<AddPromotionCodeModal membership={membership} />);
              }}
            >
              <PlusIcon
                className="h-4 w-4"
                pathClassName="stroke-secondary-500"
              />
              <div>Add Code</div>
            </div>
          )}
        </div>
      </div>
    ) : undefined;

  let lines = [];
  if (membership.membershipBean.type === "RECURRING") {
    lines.push(billingLine);
    lines.push(promoCodeLine);
  }
  lines.push(startDateLine);
  if (membership.membershipBean.type === "RECURRING") {
    lines.push(contractEndDateLine);
  }
  if (
    membership.membershipBean.type === "PREPAID" &&
    !membership.membershipBean.isTrial
  ) {
    lines.push(expiryDateLine);
  }
  if (
    membership.membershipBean.type === "PREPAID" &&
    membership.membershipBean.isTrial
  ) {
    lines.push(trialEndsLine);
  }
  return lines.map((e, i) => <React.Fragment key={i}>{e}</React.Fragment>);
};

export interface MembershipInformationLineProps {
  title: string;
  description: string;
  onClick?: () => void;
}

const MembershipInformationLine: React.FC<MembershipInformationLineProps> = ({
  title,
  description,
  onClick,
}) => {
  return (
    <div
      key={title}
      className="flex w-full flex-row items-center justify-between"
    >
      <div className="inline-flex flex-row text-sm font-normal text-gray-600">
        <div className="font-semibold">{title}:&nbsp;</div>
        {description}
      </div>
      {onClick && (
        <div onClick={onClick} className="flex w-3 cursor-pointer justify-end">
          <FontAwesomeIcon
            className="cursor-pointer text-gray-600"
            icon={faEllipsisV}
          />
        </div>
      )}
    </div>
  );
};
