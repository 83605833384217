import { Field, FieldProps, FormikProps } from "formik";

import { ErrorMessage } from "../ErrorMessage";
import { TimeSelect, TimeSelectProps } from "../TimeSelect";

type FormTimeSelectProps<T> = {
  name: keyof T;
} & Pick<TimeSelectProps, "label" | "fullWidth">;

export const FormTimeSelect = <T,>({
  name,
  ...rest
}: FormTimeSelectProps<T>) => (
  <Field name={name}>
    {({
      field,
      meta: { touched, error },
    }: FieldProps<string, FormikProps<T>>) => (
      <div className="flex flex-col gap-1.5">
        <TimeSelect
          {...rest}
          value={field.value}
          onChange={(value) =>
            field.onChange({
              target: {
                name,
                value,
              },
            })
          }
        />
        {touched && error && <ErrorMessage text={error} />}
      </div>
    )}
  </Field>
);
