import {
  canFind,
} from './features/apiResource';
import endpoints from './endpoints';

const customerFacilityApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.customerFacility}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canFind(state)
  );
};

export default customerFacilityApi;
