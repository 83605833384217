import { ActivityDTO, ActivityStatus, ApiListResponse } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityQueryKeys } from "./activityQueryKeys";

export async function activityListQueryFn({ api, opts }: { api: ActivityListApi; opts?: ActivityListFilter }) {
  const result = await api.activityApi.find({ ...opts });
  return result.data as ApiListResponse<ActivityDTO>;
}

export function useActivityList({ api, opts }: { api: ActivityListApi; opts?: ActivityListFilter }) {
  const result = useQuery({
    queryKey: activityQueryKeys.list(opts),
    queryFn: () => activityListQueryFn({ api, opts }),
    initialData: defaultPage<ActivityDTO>,
  });
  return result;
}

export interface ActivityListFilter {
  readonly extraParams?: {
    readonly status?: ActivityStatus;
    readonly id?: number[];
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}

interface ActivityListApi {
  activityApi: {
    find: (arg0: { [k: string]: any }) => Promise<any>;
  };
}
