import { SearchIcon } from "./icons/SearchIcon";

export type EmptyPageProps = {
  textOverride?: string;
  action?: React.ReactNode;
};

export const EmptyPage = ({ action, textOverride }: EmptyPageProps) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 dark:bg-gray-700">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200 dark:bg-gray-600">
          <SearchIcon
            pathClassName="dark:stroke-gray-200"
            className="h-6 w-6"
          />
        </div>
      </div>
      <div className="font-semibold text-gray-950 dark:text-white">
        {textOverride ?? "No Matches"}
      </div>
      {action}
    </div>
  );
};
