import { createContext, ReactNode, useState } from "react";

interface PageTitleProviderContextType {
  setPageTitle: (newTitle: { title: string; iconUrl?: string }) => void;
  pageTitle: {
    title: string;
    iconUrl?: string;
  };
}

export const PageTitleProviderContext =
  createContext<PageTitleProviderContextType>({
    setPageTitle: () => {
      throw new Error("PageTitleProviderContext not initialized");
    },
    pageTitle: { title: "Gymflow" },
  });

export function PageTitleProvider({
  children,
}: {
  children: ((context: PageTitleProviderContextType) => ReactNode) | ReactNode;
}) {
  const [pageTitle, setPageTitle] = useState<
    PageTitleProviderContextType["pageTitle"]
  >({ title: "Gymflow" });
  const contextProperties = { pageTitle, setPageTitle };
  return (
    <PageTitleProviderContext.Provider value={contextProperties}>
      {typeof children === "function" ? children(contextProperties) : children}
    </PageTitleProviderContext.Provider>
  );
}
