import { useClubSettings } from "apps/portal/src/providers";
import { useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Card, Col, Row } from "reactstrap";

import { Spinner } from "../../atoms";
import useGymflowModels from "./../../../store";
import EmailItem from "./EmailItem";
import LeadStatusItem from "./LeadStatusItem";
import MembershipStatusItem from "./MembershipStatusItem";
import TaskItem from "./TaskItem";
import TransactionItem from "./TransactionItem";

const limit = 20;

export const transactionTypeMap = {
  CREDIT: "Successful Payment",
  MANUAL_CREDIT: "Credit Applied",
  REFUND_DEBIT: "Refund",
  WRITE_OFF: "Debt Written off",
};

const Filter = ({ defaultValues, options = [], onChange }) => {
  const [checked, setChecked] = useState(Array(options.length).fill(false));

  useEffect(() => {
    const defaultChecked = options.map((op) =>
      defaultValues.includes(op.value),
    );
    setChecked(defaultChecked);
  }, []);

  const handleOnClick = useCallback(
    (idx) => {
      return () => {
        let newValues = [];
        setChecked((s) => {
          let newState;
          const hasAllOption = options.some((op) => op.label === "ALL");
          if (hasAllOption && idx === 0) {
            newState = s[idx]
              ? Array(s.length).fill(false)
              : Array(s.length).fill(true);
          } else {
            s[idx] = !s[idx];
            if (hasAllOption) {
              s[0] = !!s.slice(1).every((i) => i);
            }
            newState = [...s];
          }

          newValues = newState
            .flatMap((bool, index) => (bool ? index : []))
            .map((i) => options[i].value);
          if (newValues[0] === null) {
            newValues = newValues.slice(1);
          }
          return newState;
        });

        onChange && onChange(newValues);
      };
    },
    [checked, onChange, options],
  );

  return (
    <div>
      {options.map((item, idx) => (
        <span
          key={idx}
          className="h3 mr-3"
          style={{
            cursor: "pointer",
            borderBottom: checked[idx] ? "1px solid #6186db" : "none",
            color: checked[idx] ? "#6186db" : "#9d9ea2",
          }}
          onClick={handleOnClick(idx)}
        >
          {item.label}
        </span>
      ))}
    </div>
  );
};

const timelineNodeMap = {
  EMAIL: EmailItem,
  LEADSTATUS: LeadStatusItem,
  SUBSCRIPTION: MembershipStatusItem,
  TASK: TaskItem,
  TRANSACTION: TransactionItem,
};

const nodeTypeOptions = [
  { label: "ALL", value: null },
  { label: "EMAILS", value: "EMAIL" },
  { label: "LEAD STATUS", value: "LEADSTATUS" },
  { label: "MEMBERSHIP STATUS", value: "SUBSCRIPTION" },
  { label: "TASKS", value: "TASK" },
  { label: "TRANSACTIONS", value: "TRANSACTION" },
];

const Timeline = ({
  userMemberId,
  leadId,
  fetchNodes,
  refreshNodes,
  value,
  isLast,
  isLoading,
}) => {
  const { settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { defaultCurrency } = useStoreState(settingsStore);
  const [loadedPages, setLoadedPages] = useState(0);
  const defaultFilter = nodeTypeOptions.map((op) => op.value);
  const [filteredNodeTypes, setFilteredNodeTypes] = useState(
    defaultFilter.filter(Boolean),
  );

  useEffect(() => {
    if (userMemberId || leadId) {
      setLoadedPages(0);
      fetchNodes({
        page: 0,
        userMemberId,
        leadId,
        limit,
        nodeType: filteredNodeTypes,
      });
    }
  }, [fetchNodes, userMemberId, leadId, filteredNodeTypes]);

  const renderNodes = () => {
    const nodes = value
      .filter(
        (node) =>
          !(node.nodeType === "TRANSACTION" && !transactionTypeMap[node.type]),
      )
      .map((node, idx) => {
        const Component = timelineNodeMap[node.nodeType];
        if (Component) {
          return (
            <li key={idx}>
              <Component
                {...node}
                dateFormat={dateFormat}
                currency={defaultCurrency}
                refreshNodes={refreshNodes}
              />
            </li>
          );
        }

        return null;
      });

    return (
      <InfiniteScroll
        dataLength={value.length}
        hasMore={!isLast}
        next={() => {
          fetchNodes({
            page: loadedPages + 1,
            userMemberId,
            leadId,
            limit,
            nodeType: filteredNodeTypes,
          });
          setLoadedPages(loadedPages + 1);
        }}
        scrollableTarget={
          navigator.platform.indexOf("Win") > -1
            ? document.querySelector(".main-panel")
            : undefined
        }
      >
        <ul className="timeline">{nodes}</ul>
      </InfiniteScroll>
    );
  };

  if (!userMemberId && !leadId) {
    return null;
  }

  return (
    <div className="relative flex h-full w-full">
      <div className="absolute inset-0 overflow-y-auto overflow-x-hidden">
        <Row>
          <Col>
            <Filter
              defaultValues={defaultFilter}
              options={nodeTypeOptions}
              onChange={setFilteredNodeTypes}
            />
          </Col>
        </Row>
        <Row className="mt-lg-20 mt-3">
          <Col>
            <Card className="card-timeline card-plain">
              {isLoading && <Spinner className="text-gray-300" />}
              {!isLoading && (
                <>
                  {value.length === 0 && (
                    <p className="text-muted">No activity yet.</p>
                  )}
                  {value.length > 0 && renderNodes()}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

Timeline.defaultProps = {
  fetchNodes: () => Promise.resolve(),
};

Timeline.propTypes = {
  userMemberId: PropTypes.string,
  leadId: PropTypes.string,
  fetchNodes: PropTypes.func,
  refreshNodes: PropTypes.func.isRequired,
  value: PropTypes.array,
  isLast: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};

export default Timeline;
