import {
  ApiTokenListResponse,
  CreditSpentItem,
  TokenPageableRequestParams,
} from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { smsQueryKeys } from "./smsQueryKeys";

type Api = {
  smsApi: {
    getCreditsHistorySpent: (
      params: TokenPageableRequestParams,
    ) => Promise<{ data: ApiTokenListResponse<CreditSpentItem> }>;
  };
};

export const useInfiniteQueryCreditsHistorySpent = ({
  api,
  params,
  refetchOnWindowFocus = false,
}: {
  api: Api;
  params: TokenPageableRequestParams;
  refetchOnWindowFocus?: boolean;
}) => {
  return useInfiniteQuery({
    queryKey: smsQueryKeys.getCreditsHistorySpent(params),
    queryFn: async ({ pageParam }: { pageParam?: string }) =>
      (
        await api.smsApi.getCreditsHistorySpent({
          ...params,
          nextPageToken: pageParam,
        })
      ).data,
    getNextPageParam: (lastPage) => lastPage?.nextPageToken,
    refetchOnWindowFocus,
  });
};
