import React from "react";
import { Button as RButton, Spinner } from "reactstrap";
import * as classnames from "classnames";

/**
 * @deprecated replaced by atoms/Button
 */
const Button = ({ loading, children, ...rest }) => {
  return (
    <RButton {...rest}>
      {loading ? (
        <Spinner
          className={classnames({
            visible: loading,
            invisible: !loading,
          })}
          size="sm"
        />
      ) : (
        children
      )}
    </RButton>
  );
};

export default Button;
