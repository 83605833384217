import { FormMapper } from "@gymflow/common";
import { ActivityCategoryDTO } from "@gymflow/types";

export default class ActivityFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "activityCategory",
          transform: (v: ActivityCategoryDTO, others: { activityCategoryId: { label: string; value: number } }) => {
            others["activityCategoryId"] = { label: v.name, value: v.id };
            return v;
          },
        },
      ],
      outValue: [
        {
          key: "activity-category-id",
          transform: (v: { label: string; value: number }) => {
            return v.value;
          },
        },
      ],
    });
  }
}
