import {
  useLateCancellationsReport,
  useLateCancellationsReportCsv,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv, formatCurrency } from "@gymflow/helpers";
import { LateCancellationItemDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function LateCancellations() {
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([
    {
      id: "startTime",
      desc: true,
    },
  ]);

  const createSort = function () {
    const result = [];
    if (currentSort?.[0]) {
      result.push({ field: currentSort[0]?.id, desc: currentSort[0]?.desc });
    }
    return result;
  };

  const { data, isFetching } = useLateCancellationsReport({
    api,
    startDate,
    endDate,
    sort: createSort(),
    limit: pageSize,
    page: currentPage,
    tz: settings.timezone,
  });
  const downloadCsvMutation = useLateCancellationsReportCsv({
    api,
    tz: settings.timezone,
  });

  useEffect(() => {
    if (searchParams?.["startDate"]) {
      setStartDate(
        moment(searchParams["startDate"] as string, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
      );
    }
    if (searchParams?.["endDate"]) {
      setEndDate(
        moment(searchParams["endDate"] as string, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      );
    }
  }, [searchParams?.["startDate"], searchParams?.["endDate"]]);

  const columnHelper = createColumnHelper<LateCancellationItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor("startTime", {
      cell: (info) => {
        const startTime = info.getValue();
        return startTime ? (
          <div className="text-gray-600">
            {moment(startTime, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              `${settings.date_format} h:mm a`,
            )}
          </div>
        ) : null;
      },
      header: "Date/Time",
      footer: () => `Total: ${data?.totalCount}`,
    }),
    columnHelper.accessor("className", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Class Name",
      enableSorting: false,
    }),
    columnHelper.accessor("memberFirstName", {
      cell: (info) => {
        const fullName = `${info.row.original.memberFirstName} ${info.row.original.memberLastName}`;
        return <div className="text-gray-600">{fullName}</div>;
      },
      header: "User Name",
      enableSorting: false,
    }),
    columnHelper.accessor("cancellationTime", {
      cell: (info) => {
        return (
          <div className="text-gray-600">
            {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              `${settings.date_format} h:mm a`,
            )}
          </div>
        );
      },
      header: "Cancellation Date/Time",
      enableSorting: false,
    }),
    columnHelper.accessor("feeCollected", {
      cell: (info) => {
        return (
          <div className="text-gray-600">
            {formatCurrency(info.getValue() || 0, settings.defaultCurrency)}
          </div>
        );
      },
      header: "Fee Collected",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback((newDate: any) => {
    if (newDate) {
      history.push({
        search: qs.stringify({
          startDate: newDate.startDate,
          endDate: newDate.endDate,
          backRoute: searchParams["backRoute"],
        }),
      });
    }
  }, []);

  return (
    <Report
      title="Late Cancellations"
      description="List of all classes that had late cancellations, who they were and how much was charged."
      onDownloadClick={async () => {
        const data = await downloadCsvMutation.mutateAsync({
          startDate: startDate!,
          endDate: endDate!,
        });
        downloadCsv(data, `LateCancellations.csv`);
      }}
      handleDateRangeChange={handleDateRangeChange}
      dateRange={
        startDate && endDate
          ? {
              startDate: moment(
                startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
                DATE_FORMAT,
              ),
            }
          : undefined
      }
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: searchParams["startDate"],
              endDate: searchParams["endDate"],
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.items.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.items.totalPages}
            pageIndex={data?.items.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
            manualSorting
          />
          <div className="border-t-[1px] border-gray-300">
            <ReportPagination
              pageCount={data?.items.totalPages as number}
              currentPage={data?.items.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}
