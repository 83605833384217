import { useTranslation } from "react-i18next";

import { RadioButtonGroup } from "../../atoms";

export type YesNoRadioButtonsProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const YesNoRadioButtons = ({
  value,
  onChange,
}: YesNoRadioButtonsProps) => {
  const { t } = useTranslation();
  return (
    <RadioButtonGroup
      options={[
        {
          label: t("Common.yes"),
          value: "YES",
        },
        {
          label: t("Common.no"),
          value: "NO",
        },
      ]}
      value={value ? "YES" : "NO"}
      onChange={({ target: { value } }) => {
        onChange(value === "YES");
      }}
    />
  );
};
