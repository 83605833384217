import PropTypes from 'prop-types';
import { useRouteMatch, useHistory, Redirect } from 'react-router-dom';

export function RedirectBase({ to }) {
  const { url: base } = useRouteMatch();
  const { location: { pathname, search } } = useHistory();
  const remainingPath = pathname.replace(base, '')
  return (<Redirect to={to + remainingPath + search} />);
}

RedirectBase.propTypes = {
  to: PropTypes.string
}
