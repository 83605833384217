import { AppointmentPaymentStatus } from "@gymflow/types";

import { Badge } from "../../atoms";

export function PaymentStatusBadge({
  paymentStatus,
}: {
  paymentStatus: AppointmentPaymentStatus;
}) {
  return (
    <>
      {paymentStatus === "UNPAID" && (
        <Badge intent="error" className="uppercase">
          Unpaid
        </Badge>
      )}
      {paymentStatus === "PAID" && (
        <Badge intent="success" className="uppercase">
          Paid
        </Badge>
      )}
      {paymentStatus === "REFUNDED" && (
        <Badge className="uppercase">Refunded</Badge>
      )}
    </>
  );
}
