export type PromoIconProps = {
  pathClassName?: string;
  className?: string;
};

export const PromoIcon = ({ pathClassName, className }: PromoIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      className={className}
    >
      <path
        d="M6.6665 6.6665H6.67484M1.6665 4.33317L1.6665 8.06193C1.6665 8.46958 1.6665 8.67341 1.71255 8.86522C1.75338 9.03528 1.82072 9.19786 1.9121 9.34698C2.01517 9.51517 2.1593 9.6593 2.44755 9.94755L8.83808 16.3381C9.82812 17.3281 10.3231 17.8231 10.894 18.0086C11.3961 18.1718 11.9369 18.1718 12.439 18.0086C13.0099 17.8231 13.5049 17.3281 14.4949 16.3381L16.3381 14.4949C17.3281 13.5049 17.8231 13.0099 18.0086 12.439C18.1718 11.9369 18.1718 11.3961 18.0086 10.894C17.8231 10.3231 17.3281 9.82812 16.3381 8.83808L9.94755 2.44755C9.6593 2.1593 9.51517 2.01517 9.34698 1.9121C9.19786 1.82072 9.03528 1.75338 8.86522 1.71255C8.67341 1.6665 8.46958 1.6665 8.06193 1.6665L4.33317 1.6665C3.39975 1.6665 2.93304 1.6665 2.57652 1.84816C2.26292 2.00795 2.00795 2.26292 1.84816 2.57652C1.6665 2.93304 1.6665 3.39975 1.6665 4.33317ZM7.08317 6.6665C7.08317 6.89662 6.89662 7.08317 6.6665 7.08317C6.43639 7.08317 6.24984 6.89662 6.24984 6.6665C6.24984 6.43639 6.43639 6.24984 6.6665 6.24984C6.89662 6.24984 7.08317 6.43639 7.08317 6.6665Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};
