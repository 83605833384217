import { useAbility } from "@casl/react";
import {
  useClub,
  useClubFeatureFlags,
  useLead,
  useMember,
  useTaskCreate,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { LeadDTO, UserMemberBean } from "@gymflow/types";
import React, { useCallback, useContext } from "react";

import addNoteIcon from "../../../assets/img/add-note.svg";
import createTaskIcon from "../../../assets/img/create-task.svg";
import sendEmailIcon from "../../../assets/img/send-email.svg";
import salesIcon from "../../../assets/img/shopping-bag-icon-grey.svg";
import { useSendEmailsNew } from "../../hooks";
import { AbilityContext, Subject, Verb } from "../../permissions";
import { ModalWrapper, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import { Button, PrimaryButton, Spinner } from "../atoms";
import { CheckInButton } from "../atoms/CheckInButton";
import { CreateEditTaskSidebarProviderContext } from "../organisms";
import TaskAlert from "../Task/TaskAlert";
import { NoteFormSideBarProviderContext } from "../UserMember/Notes/NoteFormSideBarProvider";
import SendEmailAlertWithProvider from "../UserMember/SendEmails/SendEmailAlert";

export interface ActionMenuModalProps {
  onCancel: () => void;
  userMemberId?: string;
  leadId?: number;
  showShop: (user?: UserMemberBean | LeadDTO) => Promise<void>;
}

export const ActionMenuModal: React.FC<ActionMenuModalProps> = ({
  onCancel,
  userMemberId,
  leadId,
  showShop,
}) => {
  const { api } = useGymflowModels();
  const { clubId, timezone: tz } = useClubSettings();
  const { data: club } = useClub({ api, clubId });
  const ability = useAbility(AbilityContext);
  const { setAlert, hide } = useContext(AlertContext);
  const createTaskMutation = useTaskCreate({ api, tz });
  const { sendEmailToLeads, sendEmailToMembers } = useSendEmailsNew();
  const { toast } = useContext(ToastContext);
  const { triggerNewNote } = useContext(NoteFormSideBarProviderContext);
  const { data: memberContainer } = useMember({
    api,
    memberId: userMemberId,
    tz,
  });
  const { openCreateTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const { data: lead } = useLead({ api, leadId: leadId });

  const user = memberContainer?.user || lead;

  const onClickCreateTask = useCallback(() => {
    if (!user) {
      return;
    }

    const { id, firstName, lastName } = user;

    if (featureFlags?.featureFlags.PORTAL_REDESIGN_TASK) {
      const imageUrl = "picture" in user ? user.picture : undefined;
      openCreateTaskSidebar({
        initialValues: {
          relatedUsers: [
            { id, label: `${firstName} ${lastName}`, value: user, imageUrl },
          ],
        },
      });
      onCancel();
      return;
    }

    setAlert(
      <TaskAlert
        defaultRelatedUsers={
          user.profileType !== "LEAD"
            ? [
                {
                  id,
                  firstName,
                  lastName,
                },
              ]
            : []
        }
        defaultRelatedLeads={
          user.profileType === "LEAD"
            ? [
                {
                  id,
                  firstName,
                  lastName,
                },
              ]
            : []
        }
        onCancel={hide}
        onSubmit={async (values) => {
          await createTaskMutation.mutateAsync(values);
          toast({ message: "Task Created" });
          hide();
        }}
      />,
    );
    onCancel();
  }, [
    createTaskMutation,
    featureFlags?.featureFlags.PORTAL_REDESIGN_TASK,
    hide,
    toast,
    onCancel,
    openCreateTaskSidebar,
    setAlert,
    user,
  ]);

  return (
    <ModalWrapper className="flex flex-col gap-y-4 !p-6 " onCancel={onCancel}>
      {!user && <Spinner />}
      {user && (
        <>
          <div className="flex w-full flex-row items-start justify-between">
            <div className="flex flex-col">
              <div className="flex text-lg font-semibold text-gray-900">
                Action Menu
              </div>
              <div className="flex text-sm text-gray-600">
                Choose an action for&nbsp;
                <b>
                  {user.firstName} {user.lastName}
                </b>
              </div>
            </div>
          </div>
          <Button
            onClick={() => {
              showShop(user);
              onCancel();
            }}
          >
            <div className="flex flex-row items-center gap-x-2">
              <img src={salesIcon} alt="sell-something" />
              Sell Something
            </div>
          </Button>
          {user.profileType === "USER" && (
            <CheckInButton
              onCheckInOutFinished={onCancel}
              userMemberId={user.id as any}
            />
          )}
          {ability.can(Verb.Create, Subject.Email) && (
            <Button
              onClick={async () => {
                setAlert(
                  <SendEmailAlertWithProvider
                    allowMarketing={user.emailCommunication}
                    from={club?.email!}
                    to={`${user.firstName} ${user.lastName}`}
                    onSubmit={async (values: any) => {
                      const bcc = values.bcc ? values.bcc.split(",") : [];
                      if (user.profileType === "LEAD") {
                        await sendEmailToLeads({
                          emailPayload: {
                            marketing: values.marketing,
                            body: values.body,
                            subject: values.subject,
                            bccList: bcc,
                          },
                          leadIds: [leadId!],
                        });
                      } else {
                        await sendEmailToMembers({
                          emailPayload: {
                            marketing: values.marketing,
                            body: values.body,
                            subject: values.subject,
                            bccList: bcc,
                          },
                          userMemberIds: [userMemberId!],
                        });
                      }
                    }}
                    onCancel={hide}
                  />,
                );
                onCancel();
              }}
              className="flex"
            >
              <div className="flex flex-row items-center gap-x-2">
                <img src={sendEmailIcon} alt="quick-actions" />
                Send Email
              </div>
            </Button>
          )}
          <Button
            onClick={() => {
              triggerNewNote({
                ...(typeof user.id === "number"
                  ? {
                      leadId: user.id,
                    }
                  : {
                      userMemberId: user.id,
                    }),
                name: `${user.firstName} ${user.lastName}`,
              });
              onCancel();
            }}
            className="flex"
          >
            <div className="flex flex-row items-center gap-x-2">
              <img src={addNoteIcon} alt="quick-actions" />
              Add Note
            </div>
          </Button>
          <Button onClick={onClickCreateTask} className="flex">
            <div className="flex flex-row items-center gap-x-2">
              <img src={createTaskIcon} alt="quick-actions" />
              Create Task
            </div>
          </Button>

          <PrimaryButton onClick={onCancel} className="mt-0 ">
            Close
          </PrimaryButton>
        </>
      )}
    </ModalWrapper>
  );
};
