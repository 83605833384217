import moment from 'moment-timezone';

import SubscriptionStatus from '../SubscriptionStatus';
import ServiceType from '../ServiceType';
import { PARAMETER_DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from '../eventApi';

const isPendingCancellation = (type, cancellationDate) =>
  type === ServiceType.Recurring && moment(cancellationDate, PARAMETER_DATE_FORMAT).isAfter(moment());

const isPendingPause = (status, pauseStartDate) => status === SubscriptionStatus.Active && pauseStartDate;

const isPausable = (type, cancellationDate, status, pauseStartDate) =>
  type === ServiceType.Recurring &&
  status === SubscriptionStatus.Active &&
  !isPendingPause(status, pauseStartDate) &&
  !isPendingCancellation(type, cancellationDate);

const validateMemberBook = (startDate) => {
  const isBefore = moment().isBefore(moment(startDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ));
  if (isBefore) {
    return null;
  }
  return 'You cannot book into events that have already started.';
};

const membershipLimits = {
  isPendingCancellation,
  isPendingPause,
  isPausable,
  validateMemberBook,
};

export default membershipLimits;
