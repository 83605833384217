import { hasStaffRole } from "@gymflow/api";
import { useContext } from "react";

import {
  PageTitleProviderContext,
  useAuthenticatedUser,
} from "../../providers";
import menuIcon from "./../../../assets/img/menu.svg";
import { TopNavbarContents } from "./TopNavbarContents";

export function TopNavbar({ toggleSidebar }: { toggleSidebar: () => void }) {
  const { pageTitle } = useContext(PageTitleProviderContext);

  const { roles } = useAuthenticatedUser();
  const isStaff = hasStaffRole(roles);

  return (
    <>
      <div className="hidden min-h-[3.5rem] flex-row items-center border-b border-gray-300 bg-white lg:inline-flex">
        <div className="text-primary-700 flex h-full min-w-fit items-center justify-center truncate border-r border-gray-300 px-8 font-semibold">
          {pageTitle.title}
        </div>
        {isStaff && <TopNavbarContents />}
      </div>
      <div className="bg-primary-700 inline-flex min-h-[3.5rem] flex-row items-center justify-between px-4 lg:hidden">
        <div className="flex h-full min-w-fit items-center justify-center gap-x-2 truncate font-semibold text-white">
          <img src={pageTitle.iconUrl} alt="Page Icon" className="h-5 w-5" />
          {pageTitle.title}
        </div>
        <div onClick={toggleSidebar} className="flex cursor-pointer text-white">
          <img src={menuIcon} alt="Menu Icon" className="h-5 w-5" />
        </div>
      </div>
    </>
  );
}
