import { useMember, useMemberDelete } from "@gymflow/api";
import { useParseErrors } from "@gymflow/common";
import { UserMemberSubscriptionBean } from "@gymflow/types";
import { useStoreState } from "easy-peasy";
import { useMemo } from "react";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { ConfirmModal } from "./ConfirmModal";

export interface DeleteMemberModalProps {
  memberId: string;
  onConfirm: () => void;
  onClose: () => void;
}
export function DeleteMemberModal({
  memberId,
  onClose,
  onConfirm,
}: DeleteMemberModalProps) {
  const { api, settingsStore } = useGymflowModels();
  const { timezone } = useStoreState(settingsStore);
  const settings = useClubSettings();
  const clubId = settings.clubId as number;

  const parseError = useParseErrors();
  const mutationOpts = useMemo(
    () => ({
      onError: (error: any) => {
        parseError(error.response);
      },
    }),
    [parseError],
  );
  const deleteMutation = useMemberDelete({ api }, mutationOpts);
  const { data: userData } = useMember({
    api,
    memberId: memberId,
    tz: timezone,
  });
  const hasActiveMembership = !!userData?.user?.subscriptions?.find(
    (e: UserMemberSubscriptionBean) => e.active,
  );
  return !hasActiveMembership ? (
    <ConfirmModal
      onConfirm={async () => {
        try {
          await deleteMutation.mutateAsync({ memberId, clubId });
          onConfirm();
        } catch (e) {
          onClose();
        }
      }}
      onCancel={onClose}
      title="Delete User"
      type="danger"
    >
      This action is permanent and cannot be reversed. Any bookings will be
      automatically cancelled at the same time.
    </ConfirmModal>
  ) : (
    <ConfirmModal onCancel={onClose} title="Can't Delete User" type="warning">
      Please cancel any memberships or credit packs before deleting this user.
    </ConfirmModal>
  );
}
