import { ConfirmModal } from "../../templates";

export function HostNotAvailableModal({
  onConfirm,
  onCancel,
}: {
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}) {
  return (
    <ConfirmModal
      onConfirm={onConfirm}
      onCancel={onCancel}
      title="Host is not available"
    >
      This host has no availability at the time of this appointment. Are you
      sure you want create it?
    </ConfirmModal>
  );
}
