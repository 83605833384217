import {
  AlertContext,
  AsyncButton,
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import moment from "moment-timezone";
import { useContext, useState } from "react";
import ReactBsAlert from "react-bootstrap-sweetalert";
import Datetime from "react-datetime";
import { Button } from "reactstrap";

import { useClubSettings } from "../../providers";

function EditSessionPackExpiryAlert({ expiryDate, onConfirm }) {
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const { hide } = useContext(AlertContext);
  const [expire, setExpire] = useState(moment(expiryDate));

  const renderButtons = () => (
    <>
      <Button
        size="sm"
        outline
        color="primary"
        type="button"
        onClick={hide}
        style={{ marginRight: "8px" }}
      >
        Cancel
      </Button>
      <AsyncButton
        size="sm"
        color="primary"
        type="button"
        onClick={async () => {
          const parameters = {
            expiredDate: expire
              .endOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
          };
          await onConfirm(parameters);
          hide();
        }}
      >
        Save
      </AsyncButton>
    </>
  );
  return (
    <ReactBsAlert
      title="Edit Credit Pack Expiry"
      closeOnClickOutside={false}
      customButtons={renderButtons()}
      onCancel={hide}
    >
      <div className="d-flex flex-column align-items-center">
        <div className="font-weight-bold">Choose a new expiry date</div>
        <div className="mt-2">
          <Datetime
            dateFormat={DATE_FORMAT}
            timeFormat={false}
            onChange={(v) => setExpire(v)}
            value={expire}
            inputProps={{
              style: {
                background: "transparent",
                cursor: "pointer",
                width: "152px",
                border: "0.5px solid #b3b3b9",
                borderRadius: "4px",
                padding: "6px 8px",
              },
              readOnly: true,
              placeholder: "Select date",
              className: "d-inline-block",
              value: moment(expire, DATE_FORMAT).format(dateFormat),
            }}
            closeOnSelect
            isValidDate={(date) =>
              date.isAfter(moment().subtract(1, "day").endOf("day"))
            }
          />
        </div>
      </div>
    </ReactBsAlert>
  );
}

export default EditSessionPackExpiryAlert;
