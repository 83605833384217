// @ts-ignore
import { tzDateTimeStringToUtc } from "@gymflow/common";
import {
  RevenueCategory,
  RevenueReportDTO,
  RevenueReportItemDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import { utcToZonedTime } from "date-fns-tz";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseRevenueBySaleTypeReportProps {
  api: {
    revenueApi: {
      find: (params: {
        limit: number;
        page: number;
        extraParams: {
          dateFrom?: string;
          dateTo?: string;
          category?: RevenueCategory;
        };
      }) => Promise<any>;
      total: (params: {
        dateFrom?: string;
        dateTo?: string;
        category?: RevenueCategory;
      }) => Promise<any>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  limit: number;
  category?: RevenueCategory;
  page: number;
  tz: string;
}

export function useRevenueBySaleTypeReport({
  api,
  limit,
  dateFrom,
  dateTo,
  page,
  category,
  tz,
}: UseRevenueBySaleTypeReportProps) {
  const result = useQuery({
    queryKey: [
      reportQueryKeys.revenueAccountsReceivable({
        page,
        limit,
        dateFrom,
        category,
        dateTo,
      }),
    ],
    queryFn: async () => {
      const resultRequest = api.revenueApi.find({
        page,
        limit,
        extraParams: {
          category,
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
        },
      });
      const totalRequest = api.revenueApi.total({
        category,
        ...(dateFrom !== undefined
          ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
          : {}),
        ...(dateTo !== undefined
          ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
          : {}),
      });
      const [result, total] = await Promise.all([resultRequest, totalRequest]);
      return {
        data: result.data,
        total: total.data.total,
      } as RevenueReportDTO;
    },
    placeholderData: () => {
      return {
        data: defaultPage<RevenueReportItemDTO>(),
        total: 0,
      };
    },
    select: (data) => {
      const dto = merge({}, data.data, {
        content: data.data.content.map(({ date, ...rest }) => {
          return {
            date: date && utcToZonedTime(date, tz),
            ...rest,
          };
        }),
      });
      return {
        ...data,
        data: dto,
      };
    },
    enabled: !!tz,
  });
  return result;
}
