import PropTypes from 'prop-types';

function FieldError({ visible, error }) {
  if (visible && error) {
    return <label className="error">{error}</label>;
  }
  return null;
}

FieldError.defaultProps = {
  error: null,
  visible: true,
};

FieldError.propTypes = {
  error: PropTypes.string,
  visible: PropTypes.bool,
};

export default FieldError;
