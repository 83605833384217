/* global Cypress */
const realm =
  // @ts-ignore
  "REACT_APP_AUTH_KEYCLOAK_REALM" ||
  // @ts-ignore
  Cypress.env("REACT_APP_AUTH_KEYCLOAK_REALM");

if (!realm) {
  throw new Error("Missing keycloak realm config");
}

const endpoints = {
  clubs: "club",
  memberships: "memberships",
  userMembers: "user/member",
  userStaff: "user/staff",
  product: "product",
  customerProduct: "customer/product",
  service: "membership",
  creditPack: "sessionpack",
  facility: "facility",
  activity: "activity",
  leadSource: "lead/source",
  event: "event",
  task: "task",
  eventOccurrence: "event/occurrence",
  statistics: "statistic",
  customerOccurrence: "customer/event/occurrence",
  customerUserMembers: "customer/user/member",
  customerClub: "customer/club",
  customerActivity: "customer/activity",
  customerFacility: "customer/facility",
  hostedClub: "hosted/settings",
  note: "note",
  timeline: "timeline",
  rule: "rules",
  lead: "lead",
  revenue: "revenue",
  access: "access",
  integrationApiKey: "integration-api-key",
  emailTemplate: "template",
  automation: "automation",
  sendEmail: "send-email",
  promotion: "promotion",
  strongCustomerAuthorization: "sca",
  publicUserMember: "hosted/user/member",
  publicService: "hosted/membership",
  publicCreditPack: "hosted/sessionpack",
  publicProduct: "hosted/product",
  publicClub: "",
  publicEvent: "hosted/event/occurrence",
  publicHostedClub: "hosted",
  publicRule: "hosted/rules",
  publicLead: "hosted/lead",
  publicFacility: "hosted/facility",
  publicActivity: "hosted/activity",
  kisi: "kisi",
  appointable: "appointable",
  appointment: "appointment",
  availability: "availability",
  customerAvailability: "customer/availability",
  customerAppointment: "customer/appointment",
} as const;

export default endpoints;
