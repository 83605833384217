import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { reportHomeRoutes } from "../../views/reports/reportHomeRoutes";
import {
  MembershipWidgetsFilters,
  MembershipWidgetsFilterSidebar,
} from "../templates";
import { buildMemberReportFilterKey } from "./memberReportFilterKey";
import { ReportContext } from "./ReportContext";
import { ReportMembersActiveWidget } from "./Widgets/Members/Active";
import { ReportMembersEndingWidget } from "./Widgets/Members/Ending";
import { ReportMembersGrowthWidget } from "./Widgets/Members/Growth";
import { ReportMembersSoldWidget } from "./Widgets/Members/MembershipSold";
import { ReportMembersOverdueWidget } from "./Widgets/Members/Overdue";
import { ReportMembersPausedWidget } from "./Widgets/Members/Paused";
import { ReportMembersPausingWidget } from "./Widgets/Members/Pausing";
import { ReportMembersPendingWidget } from "./Widgets/Members/Pending";
import { ReportMembersResumingWidget } from "./Widgets/Members/Resuming";
import { ReportMembersTotalWidget } from "./Widgets/Members/Total";

export function Members({
  isFilterOpen,
  onCloseFilterSidebar,
}: {
  isFilterOpen: boolean;
  onCloseFilterSidebar: () => {};
}) {
  const { createClubLink } = usePortalRoutes();
  const membersRoute = createClubLink(
    RouteLayout.Staff,
    RouteFeature.Reports,
    reportHomeRoutes.Members,
  );
  const { api, authStore } = useGymflowModels();
  const settings = useClubSettings();
  const location = useLocation();
  const { routeId } = usePortalRoutes();
  const { id: loggedInId } = useStoreState(authStore);

  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);
  const [filter, setFilter] = useState<MembershipWidgetsFilters>({});
  const [areFiltersLoaded, setAreFiltersLoaded] = useState(false);

  const setFiltersWithStorage = useCallback(
    (newFilters: MembershipWidgetsFilters) => {
      setFilter(() => {
        localStorage.setItem(
          buildMemberReportFilterKey(loggedInId, routeId),
          JSON.stringify(newFilters),
        );
        return newFilters;
      });
    },
    [loggedInId, routeId],
  );

  useEffect(() => {
    const savedFilters = localStorage.getItem(
      buildMemberReportFilterKey(loggedInId, routeId),
    );
    if (savedFilters) {
      setFilter({
        ...JSON.parse(savedFilters),
      });
    }
    setAreFiltersLoaded(true);
  }, []);

  const { data, isFetching } = useStatistic(
    {
      api,
      clubId: settings.clubId,
      start:
        dates?.startDate &&
        moment(dates.startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
      end:
        dates?.endDate &&
        moment(dates.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
      trendPeriod: "MONTH",
      categories: ["membership"],
      membershipFields: [
        "signUps",
        "ending",
        "growth",
        "total",
        "active",
        "overdue",
        "paused",
        "pending",
        "pausing",
        "resuming",
      ],
      membershipIncluded:
        filter?.membershipIncluded?.map(({ id }) => id) || undefined,
      membershipExcluded:
        filter?.membershipExcluded?.map(({ id }) => id) || undefined,
    },
    { enabled: areFiltersLoaded },
  );

  return (
    <ReportContext.Provider
      value={{
        isLoading: isFetching,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        members: data?.membership && {
          membershipsSold: {
            total: data.membership.signUps!.total!,
            variation: data.membership.signUps!.var,
          },
          ending: {
            total: data.membership.ending!.total!,
            variation: data.membership.ending!.var,
          },
          growth: {
            total: data.membership.growth!.total!,
            variation: data.membership.growth!.var,
          },
          total: {
            total: data.membership.total!.total!,
            variation: data.membership.total!.var,
          },
          active: {
            total: data.membership.active!.total!,
            variation: data.membership.active!.var,
          },
          overdue: {
            total: data.membership.overdue!.total!,
            variation: data.membership.overdue!.var,
          },
          paused: {
            total: data.membership.paused!.total!,
            variation: data.membership.paused!.var,
          },
          pending: {
            total: data.membership.pending!.total!,
            variation: data.membership.pending!.var,
          },
          pausing: {
            total: data.membership.pausing!.total!,
            variation: data.membership.pausing!.var,
          },
          resuming: {
            total: data.membership.resuming!.total!,
            variation: data.membership.resuming!.var,
          },
        },
      }}
    >
      <MembershipWidgetsFilterSidebar
        value={filter}
        isVisible={isFilterOpen}
        onChange={(newFilters) => {
          setFiltersWithStorage({
            membershipIncluded: newFilters?.membershipIncluded?.length
              ? newFilters.membershipIncluded
              : undefined,
            membershipExcluded: newFilters?.membershipExcluded?.length
              ? newFilters.membershipExcluded
              : undefined,
          });
          onCloseFilterSidebar();
        }}
        onClose={() => {
          onCloseFilterSidebar();
        }}
      />
      <div>
        <div className="mx-1 mt-6 text-lg font-semibold">
          Membership Activity
        </div>
        <div className="mx-1 font-normal text-gray-600">
          All membership activity that contributes to changing membership counts
          and statuses.
        </div>
        <div className="mx-[-.75rem] mb-4 flex flex-wrap">
          <ReportMembersSoldWidget parentRoute={membersRoute} />
          <ReportMembersEndingWidget parentRoute={membersRoute} />
          <ReportMembersGrowthWidget />
          <ReportMembersPausingWidget parentRoute={membersRoute} />
          <ReportMembersResumingWidget parentRoute={membersRoute} />
        </div>
        <div className="mx-1 mt-6 text-lg font-semibold">
          Membership Summary
        </div>
        <div className="mx-1 font-normal text-gray-600">
          Total number of memberships in each status as of the end of the date
          range selected.
        </div>

        <div className="mx-[-.75rem] mb-4 flex flex-wrap">
          <ReportMembersTotalWidget />
          <ReportMembersActiveWidget />
          <ReportMembersPausedWidget parentRoute={membersRoute} />
          <ReportMembersPendingWidget parentRoute={membersRoute} />
          <ReportMembersOverdueWidget parentRoute={membersRoute} />
        </div>
      </div>
    </ReportContext.Provider>
  );
}
