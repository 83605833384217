import {
  CalendarEventOccurrenceFilter,
  calendarEventOccurrencesQueryFn,
  calendarEventOccurrencesSelectFn,
} from "@gymflow/api";
import { useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import { ChevronDown, ChevronUp } from "react-feather";
import { ActionMeta } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

import useGymflowModels from "../../store";
import EventCard from "./EventCard";

function EventSelect({
  value,
  onChange,
  filters,
}: {
  value: any;
  onChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
  filters: Omit<CalendarEventOccurrenceFilter, "limit">;
}) {
  const { api, settingsStore } = useGymflowModels();
  const { timezone } = useStoreState(settingsStore);
  const loadOptions = async (
    _: never,
    __: never,
    { pageParam }: { pageParam?: string },
  ) => {
    const data = await calendarEventOccurrencesQueryFn({
      filters: {
        ...filters,
        includeBookedCounts: true,
        limit: 10,
      },
      pageParam,
      api,
      tz: timezone,
    });
    const options = calendarEventOccurrencesSelectFn(
      { pages: [data], pageParams: [] },
      timezone,
    ).pages[0].map((event) => {
      return {
        value: event,
        label: (
          <EventCard
            name={event.activityName}
            startDate={event.startDate}
            endDate={event.endDate}
            isFullDayEvent={false}
            hostName={event.hostName}
            bookedCount={event.bookedCount as number}
            capacity={event.capacity as number}
          />
        ),
        isDisabled: value?.value?.id === event.eventOccurrenceId,
      };
    });
    return {
      hasMore: !!data.nextPageToken && data.content.length > 0,
      options,
      additional: {
        pageParam: data.nextPageToken,
      },
    };
  };

  return (
    <AsyncPaginate
      additional={{
        pageParam: undefined,
      }}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
          if (menuIsOpen) {
            return <ChevronUp color="#31436e" />;
          }
          return <ChevronDown color="#31436e" />;
        },
      }}
      styles={{
        // @ts-ignore
        control: (provided) => ({
          ...provided,
          height: "57px",
          fontSize: "12px",
          border: "1px solid #f0f0f0",
          boxShadow: "0 5px 10px 0px #f5f6f6",
        }),
        // @ts-ignore
        option: (provided) => ({
          ...provided,
          fontSize: "12px",
          height: "57px",
          padding: "0px",
        }),
        // @ts-ignore
        valueContainer: (provided) => ({
          ...provided,
          fontSize: "12px",
          height: "55px",
          width: "100%",
          padding: "0px",
        }),
        // @ts-ignore
        singleValue: (provided) => ({
          ...provided,
          width: "100%",
        }),
        // @ts-ignore
        indicatorSeparator: (provided) => ({
          ...provided,
          display: "none",
        }),
        // @ts-ignore
        indicatorsContainer: (provided) => ({
          ...provided,
          marginRight: "8px",
        }),
      }}
      isSearchable={false}
      // @ts-ignore
      loadOptions={loadOptions}
      placeholder="Select Event"
      value={value}
      onChange={onChange}
      isOptionSelected={({ value: { isDisabled } }) => isDisabled}
      cacheUniqs={[value, filters]}
    />
  );
}

EventSelect.defaultProps = {
  value: null,
};

EventSelect.propTypes = {
  // @ts-ignore
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    "event.activity.activityCategory.id": PropTypes.number,
    "event.isBookable": PropTypes.bool,
  }),
};

export default EventSelect;
