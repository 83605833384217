import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Item = ({ icon, firstLine, secondLine }) => {
  return (
    <div className="d-flex align-items-center w-100">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: 30, width: 30, background: '#F0F0F0', borderRadius: '50%', marginRight: 20 }}
      >
        <FontAwesomeIcon icon={icon} fontSize={14} color="#9D9EA2" />
      </div>
      <div>
        <div>{firstLine}</div>
        <div className="font-size-sm text-muted">{secondLine}</div>
      </div>
    </div>
  );
};

Item.propTypes = {
  icon: PropTypes.object.isRequired,
  firstLine: PropTypes.node.isRequired,
  secondLine: PropTypes.node.isRequired,
}

export default Item;

