import { useAutoAnimate } from "@formkit/auto-animate/react";
import {
  useMutationRuleGroupEdit,
  useMutationRuleGroupNew,
} from "@gymflow/api";
import { pluralize, range } from "@gymflow/helpers";
import { RuleGroup, RuleType } from "@gymflow/types";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import { PaginatedSelect } from "../../atoms";
import {
  MembershipRuleFormType,
  MembershipRuleMapper,
} from "./MembershipRuleMapper";
import { RuleFormWrapper } from "./RuleFormWrapper";
import { RuleMapper } from "./RuleMapper";
import { RulesComponents } from "./RulesComponents";
import { YesNoRadioButtons } from "./YesNoRadioButtons";

export function MembershipRuleForm() {
  const { t } = useTranslation();
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const isEditing = ruleGroupId !== undefined && ruleGroupId !== "";
  return (
    <RuleFormWrapper
      title={
        isEditing
          ? t("page.rules.membershipForm.headerEdit")
          : t("page.rules.membershipForm.headerNew")
      }
      description={
        isEditing
          ? t("page.rules.membershipForm.explanationEdit")
          : t("page.rules.membershipForm.explanationNew")
      }
      Form={Form}
    />
  );
}

const Form = forwardRef<
  { submitForm: () => Promise<any> },
  | {
      isEditing: true;
      ruleTypeToIdMap: Record<RuleType, number>;
      initialValues: RuleGroup;
    }
  | {
      isEditing: false;
    }
>(function Form(props, ref) {
  const initialValues = useMemo(() => {
    return "initialValues" in props
      ? MembershipRuleMapper.mapBEtoFE(props.initialValues)
      : MembershipRuleMapper.defaultValues;
  }, [props]);
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { mutateAsync: newGroup } = useMutationRuleGroupNew({ api });
  const { mutateAsync: editGroup } = useMutationRuleGroupEdit({ api });

  const formik = useFormik<MembershipRuleFormType>({
    initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(MembershipRuleMapper.schema),
    onSubmit: async (values) => {
      if (props.isEditing) {
        await editGroup({
          ruleGroupId: +ruleGroupId,
          patchedFields: RuleMapper.mapPostToPatch(
            MembershipRuleMapper.mapFEtoBE(values),
            props.ruleTypeToIdMap,
          ),
        });
      } else {
        await newGroup({
          fields: MembershipRuleMapper.mapFEtoBE(values),
        });
      }
      history.goBack();
    },
  });
  const { values, setFieldValue, submitForm } = formik;
  useImperativeHandle(ref, () => ({
    submitForm,
  }));
  const billingOptions = range(1, 64).map((i) => ({
    label: `${i} ${pluralize("Billing Period", "Billing Periods", i)}`,
    value: i,
  }));
  const [parent] = useAutoAnimate();
  return (
    <RulesComponents.FormRow
      label={t("page.rules.membershipForm.cancellationLabel")}
    >
      <div className="flex w-full flex-col gap-2" ref={parent}>
        <RulesComponents.CardLabel
          label={t("page.rules.membershipForm.cancellationExplanation")}
        >
          <YesNoRadioButtons
            value={values.cancelMembership.enabled}
            onChange={(newValue) => {
              setFieldValue("cancelMembership.enabled", newValue);
              setFieldValue("cancelMembership.minimumPeriodNotice", 1);
            }}
          />
        </RulesComponents.CardLabel>
        {values.cancelMembership.enabled && (
          <RulesComponents.CardLabel
            label={t("page.rules.membershipForm.cancellationPeriod")}
          >
            <PaginatedSelect
              className="w-48"
              value={billingOptions.find((e) =>
                "minimumPeriodNotice" in values.cancelMembership
                  ? e.value === values.cancelMembership.minimumPeriodNotice
                  : true,
              )}
              onChange={(newValue) => {
                setFieldValue("cancelMembership.minimumPeriodNotice", newValue.value);
              }}
              loadOptions={async () => {
                return {
                  options: billingOptions,
                };
              }}
            />
          </RulesComponents.CardLabel>
        )}
      </div>
    </RulesComponents.FormRow>
  );
});
