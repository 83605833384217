import { action, thunk } from 'easy-peasy';

import UserRole from '../api/UserRole';
import RuleName from '../constants/RuleName';
import BaseModelBuilder from './BaseModelBuilder';
import { hasStaffRole } from '@gymflow/api';

class SettingsModelBuilder {
  constructor(
    apiKey,
    clubId,
    authKey = 'auth',
    clubKey = 'club',
    customerClubKey = 'customer-club'
  ) {
    this.generators = [];
    this.apiKey = apiKey;
    this.clubId = clubId;
    this.authKey = authKey;
    this.clubKey = clubKey;
    this.customerClubKey = customerClubKey;
    this.generators.push(this.settingsGenerator);
  }

  settingsGenerator() {
    return {
      apiKey: this.apiKey,
      fetchValues: thunk(async (actions, _, { injections, getStoreState }) => {
        const authState = getStoreState()[this.authKey];
        const isStaff = hasStaffRole(authState.roles);
        const isMember = !isStaff;
        const result = await injections.api[isMember ? this.customerClubKey : this.clubKey].findById(this.clubId);
        actions.setValues({
          ...result.data
        });
        if (window.dataLayer) {
          setTimeout(() => {
            const authState = getStoreState()[this.authKey];
            window.dataLayer.push({
              event: 'GymflowSettingsReady',
              gymflowIsStaff: isStaff,
              gymflowUserId: authState.id,
              gymflowHostname: window.location.hostname,
              gymflowUserName: `${authState.firstName} ${authState.lastName}`,
              gymflowUserEmail: authState.email,
            });
          }, 1000);
        }
      }),
      setValues: action(
        (
          state,
          {
            addressLine1,
            city,
            defaultCurrency,
            defaultTaxRate,
            timezone,
            stripeApplicationId,
            email,
            termsConditions,
            googleTagContainerId,
          }
        ) => {
          state.defaultCurrency = defaultCurrency;
          state.defaultTaxRate = defaultTaxRate;
          state.city = city;
          state.addressLine1 = addressLine1;
          state.timezone = timezone;
          state.stripeApplicationId = stripeApplicationId;
          state.email = email;
          state.termsConditions = termsConditions;
          state.googleTagContainerId = googleTagContainerId;
        }
      ),
    };
  }
}

SettingsModelBuilder.prototype.build = BaseModelBuilder.prototype.build;

export default SettingsModelBuilder;
