import {
  AlertContext,
  AsyncButton,
  formikHelpers,
  FormikInput,
  FormMapper,
  MobileNumberInput,
  renderErrorRowOnTouch,
  useRecordForm,
  useUserFormFieldConfiguration,
} from "@gymflow/common";
import classNames from "classnames";
import { useFormik } from "formik";
import noop from "lodash/noop";
import qs from "qs";
import { useCallback, useContext, useMemo } from "react";
import Alert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

import { useRedirectUrl } from "../../hooks";
import { useLoadLeadSources } from "../../hooks/useLoadLeadSources";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function Enquiry() {
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();
  const mapper = new FormMapper({
    outValue: [{ key: "email", transform: (v) => v.toLowerCase() }],
  });
  const { setAlert, hide } = useContext(AlertContext);
  const { options: sourceOptions } = useLoadLeadSources();

  const location = useLocation();
  const { source } = qs.parse(location.search.slice(1));

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId: settings.clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const schema = useMemo(() => {
    const baseSchema = {
      [EMAIL]: Yup.string().email().required(),
      [FIRST_NAME]: Yup.string().trim().required().min(2),
      [LAST_NAME]: Yup.string().trim().required().min(2),
      [EMAIL_COMMUNICATION]: Yup.boolean().default(false),
      [SMS_COMMUNICATION]: Yup.boolean().default(false),
      [SOURCE_ID]: Yup.string().required(),
    };

    if (requiredFields?.mobileNumber) {
      baseSchema[MOBILE_NUMBER] = Yup.string().required().phoneNumber();
    }

    if (typeof source === "undefined") {
      return Yup.object().shape(baseSchema);
    }
    return Yup.object().shape({
      ...baseSchema,
      [SOURCE_ID]: Yup.string().required().default(source),
    });
  }, [source, requiredFields]);

  const { getValues, initialValues } = useRecordForm({
    record: null,
    fields: schema.default(),
    mapper,
  });

  const formikProps = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: noop,
  });

  const {
    values,
    errors,
    setFieldValue,
    setValues,
    submitForm,
    resetForm,
    validateForm,
    touched,
  } = formikProps;

  const onSubmit = useCallback(async () => {
    await submitForm();
    const fieldErrors = await validateForm();
    const validated = Object.keys(fieldErrors).length === 0;
    if (validated) {
      const parsedValues = getValues(values);

      try {
        await api.public.leadApi.create(parsedValues);

        setAlert(
          <Alert
            title="Thanks for your interest"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            showCloseButton
            onConfirm={() => {
              hide();
              redirectToCustomizedWebsiteOr();
            }}
          >
            We will be in touch shortly.
          </Alert>,
        );

        resetForm();
        setValues(mapper.to(schema.default()));
      } catch (e) {
        let text = "Opps. Couldn&apos;t process your request. Try again later.";
        if (e.response.status === 409) {
          text = e.response.data.error_message;
        }
        setAlert(
          <Alert
            title="Error"
            type="error"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            showCloseButton
            onConfirm={hide}
          >
            {text}
          </Alert>,
        );
      }
    }
  }, [getValues, hide, resetForm, setAlert, submitForm, validateForm, values]);

  const { errorClass } = formikHelpers(formikProps);

  return (
    <div className="track-height">
      <Row>
        <Col className="mx-auto mt-5" lg="4">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Form className="form-horizontal" role="form">
                    <Row className="text-uppercase">
                      <Col>
                        <Label htmlFor={EMAIL}>Email *</Label>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <FormGroup
                          className={classNames(
                            "dropdown-group",
                            errorClass(EMAIL),
                          )}
                        >
                          <FormikInput
                            name={EMAIL}
                            id={EMAIL}
                            type="text"
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-uppercase">
                      <Col>
                        <Label htmlFor={FIRST_NAME}>First Name *</Label>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <FormGroup className={errorClass(FIRST_NAME)}>
                          <FormikInput
                            name={FIRST_NAME}
                            id={FIRST_NAME}
                            type="text"
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-uppercase">
                      <Col>
                        <Label htmlFor={LAST_NAME}>Last Name *</Label>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <FormGroup className={errorClass(LAST_NAME)}>
                          <FormikInput
                            name={LAST_NAME}
                            type="text"
                            id={LAST_NAME}
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      className={classNames("text-uppercase", {
                        hidden: !requiredFields?.mobileNumber,
                      })}
                    >
                      <Col>
                        <Label htmlFor={MOBILE_NUMBER}>Phone Number *</Label>
                      </Col>
                    </Row>
                    <Row
                      className={classNames("mt-2", {
                        hidden: !requiredFields?.mobileNumber,
                      })}
                    >
                      <Col>
                        <FormGroup className={errorClass(MOBILE_NUMBER)}>
                          <FormikInput
                            name={MOBILE_NUMBER}
                            component={MobileNumberInput}
                            type="text"
                            id={MOBILE_NUMBER}
                            maxLength="32"
                            formikProps={formikProps}
                            phone_number_country={settings.phone_number_country}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-uppercase mt-2">
                      <Col>
                        <Label>Let&apos;s keep in touch?</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="checkbox-radios">
                        <FormGroup
                          check
                          className={errorClass(EMAIL_COMMUNICATION)}
                        >
                          <Label check>
                            <Input
                              name={EMAIL_COMMUNICATION}
                              type="checkbox"
                              id={EMAIL_COMMUNICATION}
                              data-testid="email-communication"
                              checked={values[EMAIL_COMMUNICATION]}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(EMAIL_COMMUNICATION, checked);
                              }}
                            />
                            <span className="form-check-sign" />
                            Email
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className={errorClass(SMS_COMMUNICATION)}
                        >
                          <Label check>
                            <Input
                              name={SMS_COMMUNICATION}
                              type="checkbox"
                              data-testid="sms-communication"
                              checked={values[SMS_COMMUNICATION]}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(SMS_COMMUNICATION, checked);
                              }}
                            />
                            <span className="form-check-sign" />
                            SMS
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      className={classNames("text-uppercase", "text-left", {
                        hidden: source,
                      })}
                    >
                      <Col>
                        <Label htmlFor={SOURCE_ID}>
                          How did you hear about us? *
                        </Label>
                      </Col>
                    </Row>
                    <Row className={classNames("mt-2", { hidden: source })}>
                      <Col>
                        <FormGroup
                          className={classNames(
                            "dropdown-group",
                            errorClass(SOURCE_ID),
                          )}
                          data-testid={SOURCE_ID}
                        >
                          <ReactSelect
                            classNamePrefix="react-select"
                            name={SOURCE_ID}
                            inputId={SOURCE_ID}
                            onChange={({ value }) => {
                              setFieldValue(SOURCE_ID, value);
                            }}
                            options={sourceOptions}
                            value={
                              values[SOURCE_ID] &&
                              sourceOptions.length && {
                                value: values[SOURCE_ID],
                                label: sourceOptions.find(
                                  (c) => c.value == values[SOURCE_ID],
                                ).label,
                              }
                            }
                          />
                          {renderErrorRowOnTouch(SOURCE_ID, touched, errors)}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col className="d-flex">
                      <AsyncButton className="mx-auto" onClick={onSubmit}>
                        Submit
                      </AsyncButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const EMAIL = "email";
const FIRST_NAME = "first-name";
const LAST_NAME = "last-name";
const MOBILE_NUMBER = "mobile-number";
const EMAIL_COMMUNICATION = "email-communication";
const SMS_COMMUNICATION = "sms-communication";
const SOURCE_ID = "source-id";
