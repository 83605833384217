import { useMutation, useQueryClient } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMutationMembershipTermsConditions({
  api,
}: {
  api: {
    serviceApi: {
      updateTermsConditions: (
        id: number,
        arg1: { termsAndConditionsBlob: unknown },
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      membershipId,
      patchedFields,
    }: {
      membershipId: number;
      patchedFields: { [k: string]: any };
    }) => {
      return api.serviceApi.updateTermsConditions(membershipId, {
        termsAndConditionsBlob: patchedFields["termsConditions"],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });
  return mutation;
}
