import {
  InvoiceDTO,
  ProductOrderDetailDTO,
  ProductOrderSummaryDTO,
  ProductStatus,
} from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerProductApiType = ReturnType<typeof customerProductApi>;

const customerProductApi = (axiosInstance: AxiosInstance, clubId: number) => {
  const baseUrl = `club/${clubId}/product`;

  return {
    getList(params: {
      page: number;
      size: number;
      status?: ProductStatus;
      id?: number[];
      sort: string;
    }) {
      return axiosInstance.get(baseUrl, {
        params,
      });
    },
    findCategories(params: { page: number; size: number }) {
      return axiosInstance.put(`${baseUrl}/category/search`, params);
    },
    order(params: {
      paymentMethodId?: string;
      productOrderList: ProductOrderDetailDTO[];
      promotionCode?: string;
    }) {
      return axiosInstance.post<{ invoice: InvoiceDTO }>(
        `${baseUrl}/order`,
        params,
      );
    },

    summary(params: {
      productOrderList: ProductOrderDetailDTO[];
      promotionCode?: string;
    }) {
      return axiosInstance.post<ProductOrderSummaryDTO>(
        `${baseUrl}/order/summary`,
        params,
      );
    },
  };
};

export default customerProductApi;
