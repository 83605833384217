import { Col, FormGroup, Row } from 'reactstrap';
import { FormikInput, formikHelpers } from '@gymflow/common';

import { PROMOTION_CODE } from './PromotionSchema';

function Step2({ formikProps, lockSomeFields }) {
  const { errorClass } = formikHelpers(formikProps);

  return (
    <div className="w-100 text-center">
      <b className="h2">Create a promotional code</b>
      <div className="text-muted mt-3">
        <p className="lh-130">
          This is customer facing and must be unique. We recommend short and easy to remember codes to improve usage.
          Once a code has been used it cannot be changed so if its an annual or seasonal code be sure to reference that
          in the code e.g 50%OFF21
        </p>
      </div>
      <Row className="mt-4 text-left d-flex justify-content-center">
        <Col md={8}>
          <FormGroup className={errorClass(PROMOTION_CODE)}>
            <FormikInput
              autoComplete="off"
              maxLength="128"
              name={PROMOTION_CODE}
              type="text"
              readOnly={lockSomeFields}
              formikProps={formikProps}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Step2;
