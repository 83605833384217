import { useMutation } from "@tanstack/react-query";

export function useOpenLeadReportCsv({
  api,
}: {
  api: {
    leadApi: {
      openReportCsv: (params: {
        leadStatusList: string[];
        leadSourceList?: number[];
        page?: number;
        limit?: number;
        sort?: any;
      }) => Promise<any>;
    };
  };
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      leadSourceList,
      leadStatusList,
    }: {
      leadSourceList?: number[];
      leadStatusList: string[];
    }) => {
      const result = await api.leadApi.openReportCsv({
        leadSourceList,
        leadStatusList,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
