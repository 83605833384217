import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import {
  MembershipEndingReportDTO,
  MembershipType,
  ReportMembershipEndingLineDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export function useMembershipEndingReport({
  api,
  startDate,
  endDate,
  page,
  limit,
  sort,
  membershipIncluded,
  membershipExcluded,
  membershipType,
  tz,
}: {
  api: ApiType;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  sort?: any;
  membershipIncluded?: number[];
  membershipExcluded?: number[];
  membershipType?: MembershipType;
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.membershipEnding({
      startDate,
      endDate,
      page,
      limit,
      sort,
      membershipIncluded,
      membershipExcluded,
      membershipType,
    }),
    queryFn: async () => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.membershipEnding({
        dateFrom: utcStart,
        dateTo: utcEnd,
        page,
        limit,
        sort,
        membershipIncluded,
        membershipExcluded,
        membershipType,
      });
      return result.data as MembershipEndingReportDTO;
    },
    placeholderData: () => {
      return defaultPage<ReportMembershipEndingLineDTO>();
    },
    select: (data) => {
      const dto = merge({}, data, {
        content: data.content.map(({ endDate, ...rest }) => {
          return {
            endDate: endDate && utcToZonedTime(endDate, tz),
            ...rest,
          };
        }),
      });
      return dto;
    },
    enabled: !!startDate && !!endDate && !!tz,
  });
  return result;
}
