import { useMutation, useQueryClient } from '@tanstack/react-query';

import { automationQueryKeys } from './automationQueryKeys';

export function useAutomationEnable({
  api,
}: {
  api: {
    automationApi: {
      active: (automationId: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (automationId: number) => {
      await api.automationApi.active(automationId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationQueryKeys.all() });
    },
  });

  return mutation;
}
