import { ClubBean } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { companyQueryKeys } from "./companyQueryKeys";

export function useQueryCompany({
  api,
  clubId,
}: {
  api: {
    companyApi: {
      details: (clubId: number) => Promise<{ data: ClubBean }>;
    };
  };
  clubId: number;
}) {
  const result = useQuery({
    queryKey: companyQueryKeys.all(),
    queryFn: async () => {
      const result = await api.companyApi.details(clubId);
      return result.data;
    },
    enabled: !!clubId,
  });

  return result;
}
