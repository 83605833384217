import { FeatureFlags } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { clubQueryKeys } from "./clubQueryKeys";
import { clubStaleTime } from "./useClub";

export function useClubFeatureFlags(
  {
    api,
    clubId,
  }: {
    api: {
      clubApi: {
        featureFlags: (clubId: number) => Promise<{ data: FeatureFlags }>;
      };
    };
    clubId: number;
  },
  opts?: UseQueryOptions<FeatureFlags | null>,
) {
  const result = useQuery({
    queryKey: clubQueryKeys.featureFlags(clubId),
    queryFn: async () => {
      const result = await api.clubApi.featureFlags(clubId);
      return result.data as FeatureFlags;
    },
    enabled: !!clubId,
    placeholderData: null,
    staleTime: clubStaleTime,
    ...opts,
  });

  return result;
}
