import { useContext } from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { AlertContext } from '@gymflow/common';

import LinkGeneratorWizard from './LinkGenerator/LinkGeneratorWizard';

function GenerateLink() {
  const { setAlert, hide } = useContext(AlertContext);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">
          <div>Create Custom Links & Embeds</div>
          <div className="text-muted">
            Choose exactly what you want to display on any web link or embed page use the custom link builder.
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Button
          className="mt-2 font-weight-bold"
          color="primary"
          size="sm"
          onClick={() => {
            setAlert(<LinkGeneratorWizard onClose={hide} />);
          }}
        >
          Create Custom Web Links
        </Button>
      </CardBody>
    </Card>
  );
}

export default GenerateLink;
