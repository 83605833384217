import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { MembershipReportDTO, ReportMemberDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export function useMembershipOverdueReport({
  api,
  page,
  limit,
  sort,
}: {
  api: ApiType;
  page?: number;
  limit?: number;
  sort?: any;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.membershipOverdue({
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const request = await api.memberApi.membershipOverdue({
        page,
        limit,
        sort,
      });

      return request.data as MembershipReportDTO;
    },
    placeholderData: () => {
      return defaultPage<ReportMemberDTO>();
    },
    select: (data) => {
      const dto = merge({}, data, {
        content: data.content.map(({ ...rest }) => {
          return {
            ...rest,
          };
        }),
      });
      return dto;
    },
  });
  return result;
}
