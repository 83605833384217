import { SessionPackDTO } from '@gymflow/types';
import { useQuery,UseQueryOptions } from '@tanstack/react-query';

import { sessionPackQueryKeys } from './sessionPackQueryKeys';

export function useSessionPack(
  { api, sessionPackId }: {
    api: {
      creditPackApi:
      { findById: (arg0: number) => Promise<any> }
    },
    sessionPackId?: number
  }, opts?: UseQueryOptions<SessionPackDTO | null>
) {
  const result = useQuery({
    queryKey: sessionPackQueryKeys.details(sessionPackId),
    queryFn: async () => {
      const result = await api.creditPackApi.findById(sessionPackId!);
      return result.data as SessionPackDTO;
    },
    enabled: !!sessionPackId,
    initialData: null,
    ...opts
  });

  return result;
}
