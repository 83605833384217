import { ApiListResponse, LeadSourceDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { leadSourceQueryKeys } from "./leadSourceQueryKeys";

export async function leadSourceListQueryFn({
  api,
  opts,
}: {
  api: LeadSourceListApi;
  opts: LeadSourceListFilter;
}) {
  const result = await api.leadSourceApi.find(opts);
  return result.data;
}

export function useLeadSourceList({
  api,
  opts,
}: {
  api: LeadSourceListApi;
  opts: LeadSourceListFilter;
}) {
  const result = useQuery({
    queryKey: leadSourceQueryKeys.list(opts),
    queryFn: () => leadSourceListQueryFn({ api, opts }),
    initialData: defaultPage<LeadSourceDTO>,
  });
  return result;
}

interface LeadSourceListApi {
  leadSourceApi: {
    find: (arg0: {
      [k: string]: any;
    }) => Promise<{ data: ApiListResponse<LeadSourceDTO> }>;
  };
}

interface LeadSourceListFilter {
  readonly extraParams?: {
    readonly active?: boolean;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
