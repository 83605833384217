import { EventOccurrenceDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import merge from "lodash/merge";

import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { eventOccurrenceToZonedTime } from "./eventOccurrenceToZonedTime";

export async function eventOccurrenceQueryFn({
  eventId,
  api,
}: {
  eventId: number;
  api: EventOccurrenceApi;
}) {
  const result = await api.eventApi.findById(eventId);
  return result.data as EventOccurrenceDTO;
}

export function useEventOccurrence(
  {
    api,
    eventId,
  }: {
    api: EventOccurrenceApi;
    eventId: number | null;
  },
  opts?: UseQueryOptions<EventOccurrenceDTO | null>,
) {
  const result = useQuery({
    queryKey: eventOccurrenceQueryKeys.details(eventId),
    queryFn: () => {
      return eventOccurrenceQueryFn({ api, eventId: eventId as number });
    },
    enabled: !!eventId,
    initialData: null,
    select: (data: any) => {
      if (!data) {
        return null;
      }
      return merge(
        {},
        data,
        eventOccurrenceToZonedTime(data),
      ) as EventOccurrenceDTO;
    },
    ...opts,
  });

  return result;
}

interface EventOccurrenceApi {
  eventApi: { findById: (arg0: number) => Promise<any> };
}
