import { cn } from "@gymflow/helpers";
import React, { FocusEventHandler, forwardRef, ReactNode } from "react";

export type InputProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  label?: string;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  readOnly?: boolean;
  renderOverInput?: ReactNode;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { className, label, value, startIcon, endIcon, renderOverInput, ...rest },
    ref,
  ) => (
    <div className="relative flex flex-col gap-1.5">
      {label && (
        <div className="text-sm font-medium text-gray-950">{label}</div>
      )}
      <div className="relative">
        {startIcon && (
          <div className="absolute left-3.5 top-1/2 z-10 -translate-y-1/2">
            {startIcon}
          </div>
        )}
        <input
          {...rest}
          ref={ref}
          value={value}
          className={cn(
            "shadow-xs outline-secondary-200 w-full rounded-xl border border-gray-200 px-3.5 py-2.5 text-base font-medium",
            value ? "text-gray-950" : "text-gray-500",
            { "pl-10": startIcon, "pr-10": endIcon },
            className,
          )}
        />
        {endIcon && (
          <div className="absolute right-3.5 top-1/2 z-10 -translate-y-1/2">
            {endIcon}
          </div>
        )}
        {renderOverInput}
      </div>
    </div>
  ),
);

Input.displayName = "Input";
