import { QueryKey } from '@tanstack/react-query';

export const facilityQueryKeys = {
  all: () => ['facility'] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }> = {}) =>
    [...facilityQueryKeys.all(), { filters }] as QueryKey,
  details: (facilityId: number) =>
    [...facilityQueryKeys.all(), facilityId] as QueryKey,
  categories: () => ['facility-categories'] as QueryKey,
};
