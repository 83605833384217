import {
  ApiListResponse,
  AppointableDTO,
  AppointableDtoStatus,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { appointableAsPublicQueryKeys } from "./appointableAsPublicQueryKeys";

export async function appointableListAsPublicQueryFn({
  api,
  filter,
}: {
  api: AppointableListAsPublicApi;
  filter: AppointableListAsPublicFilter;
}) {
  const result = await api.public.appointableApi.find(filter);
  return result.data;
}

export function useAppointableListAsPublic({
  api,
  opts,
}: {
  api: AppointableListAsPublicApi;
  opts: AppointableListAsPublicFilter;
}) {
  const result = useQuery({
    queryKey: appointableAsPublicQueryKeys.list(opts),
    initialData: defaultPage<AppointableDTO>,
    queryFn: () => appointableListAsPublicQueryFn({ api, filter: opts }),
  });
  return result;
}

interface AppointableListAsPublicApi {
  public: {
    appointableApi: {
      find: (
        params: Record<string, any>,
      ) => Promise<{ data: ApiListResponse<AppointableDTO> }>;
    };
  };
}

interface AppointableListAsPublicFilter {
  readonly extraParams?: {
    readonly id?: number[];
    readonly status?: AppointableDtoStatus;
    readonly "appointableCategory.id"?: number[];
    readonly allowOnlineBookings?: boolean;
    readonly allowAppBookings?: boolean;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
