import { z } from "zod";

export const PromotionCodeValidity = {
  Yes: "YES",
  No: "NO",
  NotForThisPurchase: "NOT_FOR_THIS_PURCHASE",
} as const;

export const PromotionCodeValidityZodSchema = z.enum([
  PromotionCodeValidity.Yes,
  PromotionCodeValidity.No,
  PromotionCodeValidity.NotForThisPurchase,
]);
