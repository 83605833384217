import { action, thunk, thunkOn } from 'easy-peasy';
import { ModelBuilder, getApi, utcDateTimeStringToTz, tzDateTimeStringToUtc, PresetLeadStatusType, PARAMETER_TIME_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from '@gymflow/common';
import sortBy from 'lodash/sortBy';
import moment from 'moment-timezone';

class LeadModelBuilder extends ModelBuilder {
  constructor(apiKey, settingsKey = 'settings') {
    super();
    this.apiKey = apiKey;
    this.settingsKey = settingsKey;
    this.generators.push(this.leadGenerator);
  }

  leadGenerator() {
    return {
      apiKey: this.apiKey,
      settingsKey: this.settingsKey,
      columns: [],
      lostColumnId: null,
      leads: [],
      leadFilters: {},
      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).findById(recordId);
        const payload = {
          ...data,
        };
        actions.fetchedRecord(payload);
        return payload;
      }),
      fetchedRecord: action((state, payload) => {
        state.editing = payload;
      }),
      fetchColumns: thunk(async (actions, _, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).findStatus();
        actions.fetchedColumns(data);
        return data;
      }),
      fetchedColumns: action((state, data) => {
        state.lostColumnId = data.find((c) => c.presetType === PresetLeadStatusType.DealLost).id;
        state.columns = sortBy(data, ['statusOrder']).filter(
          (c) => ![PresetLeadStatusType.DealClosed, PresetLeadStatusType.DealLost].includes(c.presetType)
        );
      }),
      fetchLeads: thunk(async (actions, { statusIds, source, dateFrom, dateTo } = {}, { getState, injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;

        const utcDateFrom = dateFrom && tzDateTimeStringToUtc(dateFrom, tz);
        const utcDateTo = dateTo && tzDateTimeStringToUtc(dateTo, tz);

        const { data } = await getApi(injections, getState()).find({
          statusIds,
          source,
          dateFrom: utcDateFrom,
          dateTo: utcDateTo,
        });

        const timezonedData = data.map((lead) => {
          const utcLead = {
            ...lead,
            createdDate: utcDateTimeStringToTz(lead.createdDate, tz),
          };

          if (lead.nextTask) {
            utcLead.nextTask = convertDeadlineDatesToTz(lead.nextTask, tz);
          }

          return utcLead;
        });
        actions.fetchedLeads({ leads: timezonedData, statusIds, source });
        return timezonedData;
      }),
      fetchedLeads: action((state, { leads, statusIds, source }) => {
        state.leads = leads;
        state.leadFilters = { statusIds, source };
      }),
      refreshLeads: thunk((actions, _, { getState }) => actions.fetchLeads(getState().leadFilters)),

      changeLeadColumn: thunk((_, { id, newColumn }, { injections, getState }) =>
        getApi(injections, getState()).changeLeadStatusColumn(id, newColumn)
      ),
      addColumn: thunk((_, { name }, { injections, getState }) =>
        getApi(injections, getState()).createStatusColumn(name)
      ),

      editColumnName: thunk((_, { id, name }, { injections, getState }) =>
        getApi(injections, getState()).editStatusColumnName(id, name)
      ),

      editColumnOrder: thunk((_, { id, statusOrder }, { injections, getState }) =>
        getApi(injections, getState()).editStatusColumnOrder(id, statusOrder)
      ),

      removeColumn: thunk((_, { id }, { injections, getState }) =>
        getApi(injections, getState()).deleteStatusColumn(id)
      ),

      create: thunk((_, fields, { injections, getState }) => getApi(injections, getState()).create(fields)),

      update: thunk((_, { id, patchedFields }, { injections, getState }) => {
        return getApi(injections, getState()).update(id, patchedFields);
      }),

      clearEditingRecord: action((state) => {
        state.editing = null;
      }),

      onUpdateLead: thunkOn(
        (actions) => [actions.create.successType, actions.changeLeadColumn.successType],
        (_, __, { getStoreActions }) => {
          getStoreActions().refreshLeads();
        }
      ),

      onUpdateColumn: thunkOn(
        (actions) => [
          actions.addColumn.successType,
          actions.changeLeadColumn.successType,
          actions.editColumnName.successType,
          actions.editColumnOrder.successType,
          actions.removeColumn.successType,
        ],
        (_, __, { getStoreActions }) => {
          getStoreActions().fetchColumns();
        }
      ),
    };
  }
}

const convertDeadlineDatesToTz = ({ deadlineDate, deadlineTime, ...rest }, tz) => {
  const deadline = moment(
    utcDateTimeStringToTz(
      `${deadlineDate} ${deadlineTime}`,
      tz,
      `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`
    )
  );
  const result = {
    ...rest,
    deadlineDate: deadline.format(PARAMETER_DATE_ONLY_FORMAT),
    deadlineTime: deadline.format(PARAMETER_TIME_FORMAT),
  };

  return result;
};

export default LeadModelBuilder;
