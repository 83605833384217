import {
  ActivityStatus,
  ApiListResponse,
  AppointableCategoryDTO,
  PaginationOptions,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { appointableAsPublicQueryKeys } from "./appointableAsPublicQueryKeys";

export const appointableCategoriesAsPublicQueryFn = async ({
  api,
  filter,
  paginationOption,
}: {
  api: AppointableCategoriesAsPublicApi;
  paginationOption: PaginationOptions;
  filter?: {
    statusList?: ActivityStatus[];
  };
}) => {
  const result = await api.public.appointableApi.fetchCategories({
    ...paginationOption,
    ...filter,
  });
  return result.data;
};

export function useAppointableCategoriesAsPublic({
  api,
  paginationOption,
  filter,
}: {
  api: AppointableCategoriesAsPublicApi;
  paginationOption: PaginationOptions;
  filter?: {
    statusList?: ActivityStatus[];
  };
}) {
  const result = useQuery({
    queryKey: appointableAsPublicQueryKeys.categories({
      ...paginationOption,
      ...filter,
    }),
    initialData: defaultPage<AppointableCategoryDTO>(),
    queryFn: () =>
      appointableCategoriesAsPublicQueryFn({ api, paginationOption, filter }),
  });

  return result;
}

interface AppointableCategoriesAsPublicApi {
  public: {
    appointableApi: {
      fetchCategories: (
        params: any,
      ) => Promise<{ data: ApiListResponse<AppointableCategoryDTO> }>;
    };
  };
}
