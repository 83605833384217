import { EmailSendingResult } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMutationSendEmailToLeads({
  api,
}: {
  api: {
    emailApi: {
      sendEmailToLeads: (args: {
        emailPayload: {
          marketing: boolean;
          body: string;
          subject: string;
          fromEmail: string;
          bccList: string[];
        };
        leadIds: number[];
      }) => Promise<{ data: EmailSendingResult }>;
    };
  };
}) {
  return useMutation({
    mutationFn: async (payload: {
      emailPayload: {
        marketing: boolean;
        body: string;
        subject: string;
        fromEmail: string;
        bccList: string[];
      };
      leadIds: number[];
    }) => {
      const response = await api.emailApi.sendEmailToLeads(payload);
      return response.data;
    },
  });
}
