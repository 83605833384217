import {
  useEventAttendanceReport,
  useEventAttendanceReportCsv,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv } from "@gymflow/helpers";
import { UserMemberEventAttendanceDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import {
  EventAttendanceFilters,
  EventAttendanceFilterSidebar,
} from "../../components/templates";
import { getFilterAppliedCount } from "../../helpers";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function EventAttendance() {
  const { createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);
  const [filters, setFilters] = useState<EventAttendanceFilters>({});

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };

  const { data, isFetching } = useEventAttendanceReport({
    api,
    startDate,
    endDate,
    sort: createSort(),
    limit: pageSize,
    page: currentPage,
    tz: settings.timezone,
    gt: filters.gt,
    lt: filters.lt,
    eq: filters.eq,
    membership: filters?.memberships && filters.memberships.map(({ id }) => id),
  });
  const downloadCsvMutation = useEventAttendanceReportCsv({
    api,
    tz: settings.timezone,
  });

  useEffect(() => {
    if (searchParams?.["startDate"]) {
      setStartDate(
        moment(searchParams["startDate"] as string, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
      );
    }
    if (searchParams?.["endDate"]) {
      setEndDate(
        moment(searchParams["endDate"] as string, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      );
    }
  }, [searchParams]);

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);

  const columnHelper = createColumnHelper<UserMemberEventAttendanceDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.userMemberId,
        };
      },
      {
        id: "firstName",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton to={createMemberLink(info.getValue().id)}>
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div>Name</div>;
        },
        enableSorting: false,
      },
    ),
    columnHelper.accessor("email", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Email",
      enableSorting: false,
    }),
    columnHelper.accessor("mobileNumber", {
      cell: (info) => {
        const mobileNumber = info.getValue();
        return mobileNumber;
      },
      header: "Mobile Number",
      enableSorting: false,
    }),
    columnHelper.accessor("attendance", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Attendance",
      footer: () => `Total: ${data?.attendanceTotal}`,
      enableSorting: false,
    }),
    columnHelper.accessor("lastAttended", {
      cell: (info) => {
        const lastAttended = info.getValue();
        return lastAttended ? (
          <div className="text-gray-600">
            {moment(lastAttended, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              settings.date_format,
            )}
          </div>
        ) : null;
      },
      header: "Last Attended",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        history.push({
          search: qs.stringify({
            startDate: newDate.startDate,
            endDate: newDate.endDate,
            backRoute: searchParams["backRoute"],
          }),
        });
      }
    },
    [history, searchParams],
  );

  return (
    <>
      <EventAttendanceFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <Report
        title="Class Attendance"
        description="List of all users and how many times they attended a class, during the specified date period."
        onDownloadClick={async () => {
          const data = await downloadCsvMutation.mutateAsync({
            ...filters,
            startDate,
            endDate,
            membership:
              filters?.memberships && filters.memberships.map(({ id }) => id),
          });
          downloadCsv(data, "EventAttendance.csv");
        }}
        showFilters={() => setFiltersAreVisible(true)}
        filtersAppliedCount={getFilterAppliedCount(filters)}
        handleDateRangeChange={handleDateRangeChange}
        dateRange={
          startDate && endDate
            ? {
                startDate: moment(
                  startDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
                endDate: moment(
                  endDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
              }
            : undefined
        }
        close={() => {
          history.push({
            pathname: searchParams["backRoute"] as string,
            search: qs.stringify({
              dates: {
                startDate: searchParams["startDate"],
                endDate: searchParams["endDate"],
              },
            }),
          });
        }}
        table={
          <>
            <Table
              data={data?.userMemberEventAttendanceDTOPage.content ?? []}
              columns={columnsDefinition}
              pageCount={data?.userMemberEventAttendanceDTOPage.totalPages}
              pageIndex={data?.userMemberEventAttendanceDTOPage.number}
              onSortingChange={setCurrentSort}
              sort={currentSort}
              isFetching={isFetching}
              pageSize={pageSize}
              rowClassName="h-14"
            />
            <div className="border-t-[0.063rem] border-gray-300">
              <ReportPagination
                pageCount={
                  data?.userMemberEventAttendanceDTOPage.totalPages as number
                }
                currentPage={
                  data?.userMemberEventAttendanceDTOPage.number as number
                }
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                pageSize={pageSize}
                setPageSize={(newValue) => {
                  setPageSize(newValue);
                  setCurrentPage(0);
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
}
