import useGymflowModels from "../store";

/**
 * @deprecated
 */
const useTemplateLoadOptions = () => {
  const { api } = useGymflowModels();
  const loadOptions = async (_, __, { page }) => {
    const results = await api.emailTemplateApi.find({
      page,
      extraParams: {
        isAutomationTemplate: false,
      },
    });

    return {
      options: results.data.content.map(({ id, templateName, ...rest }) => {
        return {
          value: { id, templateName, ...rest },
          label: templateName,
        };
      }),
      hasMore: !results.data.last,
      additional: {
        page: page + 1,
      },
    };
  };

  return loadOptions;
};

export default useTemplateLoadOptions;
