import { ApiListResponse, FacilityDTO, FacilityStatus } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { facilityAsPublicQueryKeys } from "./facilityAsPublicQueryKeys";

export async function facilityListAsPublicQueryFn({
  filter,
  api,
}: {
  filter?: FacilityListAsPublicFilter;
  api: FacilityListAsPublicApi;
}) {
  const result = await api.public.facilityApi.find({ ...filter });
  return result.data as ApiListResponse<FacilityDTO>;
}

export function useQueryFacilityListAsPublic({
  api,
  opts,
}: {
  api: FacilityListAsPublicApi;
  opts?: FacilityListAsPublicFilter;
}) {
  const result = useQuery({
    queryKey: facilityAsPublicQueryKeys.list(opts),
    queryFn: () => facilityListAsPublicQueryFn({ api, filter: opts }),
    initialData: defaultPage<FacilityDTO>,
  });
  return result;
}

interface FacilityListAsPublicApi {
  public: {
    facilityApi: {
      find: (arg0: { [k: string]: any }) => Promise<any>;
    };
  };
}

interface FacilityListAsPublicFilter {
  readonly extraParams?: {
    readonly status?: FacilityStatus;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}
