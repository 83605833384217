import { ApiListResponse, Membership } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { MembershipListFilter } from "./MembershipListFilter";
import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMembershipList(
  {
    api,
    opts,
  }: {
    api: ApiWithMembershipList;
    opts?: MembershipListFilter;
  },
  queryOptions?: UseQueryOptions<ApiListResponse<Membership>>,
) {
  const result = useQuery({
    queryKey: membershipQueryKeys.list(opts),
    queryFn: () => {
      return membershipListQueryFn({ api, filter: opts });
    },
    initialData: defaultPage<Membership>,
    ...queryOptions,
  });
  return result;
}

export async function membershipListQueryFn({
  api,
  filter,
}: {
  api: ApiWithMembershipList;
  filter?: MembershipListFilter;
}) {
  const result = await api.serviceApi.find({ ...filter });
  return result.data as ApiListResponse<Membership>;
}

interface ApiWithMembershipList {
  serviceApi: { find: (arg0: Readonly<{ [k: string]: any }>) => Promise<any> };
}
