import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const serviceEndpoints = {
  summary: `${endpoints.publicService}/summary`,
};

const publicServiceApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicService}`,
    http: createPublicAxiosInstance({
      apiUrl,
      paramsSerializer: {
        indexes: null,
      },
    }),
  };

  return Object.assign(state, canFind(state), {
    findById(id, proRataStartDate) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );

      let config;
      if (proRataStartDate) {
        config = {
          params: {
            startDate: proRataStartDate,
          },
        };
      }

      return state.http.get(`${state.baseUrl}/${id}`, config);
    },

    findByClubId(clubId, { page, limit, status, extraParams, sort, type }) {
      assert.number(clubId, "clubId must be a number");
      return state.find({
        page,
        limit,
        sort,
        extraParams: {
          ...extraParams,
          type,
          status,
          "clubList.id": clubId,
        },
      });
    },

    summary({ membershipId, startDate, promotionCode, userMemberId }) {
      assert.number(membershipId, "membershipId must be a number");
      assert.string(startDate, "startDate must be a valid datetime");
      assert.maybe.string(promotionCode, "promotion code must be a string");
      assert.maybe.string(userMemberId, "userMemberId must be a string");

      return state.http.post(`${clubPrefix}${serviceEndpoints.summary}`, {
        membershipId,
        startDate,
        promotionCode,
        userMemberId,
      });
    },
  });
};

export default publicServiceApi;
