import { useStatistic } from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  PARAMETER_DATE_ONLY_FORMAT,
} from "@gymflow/common";
import moment from "moment";
import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../../../hooks";
import { useClubSettings } from "../../../../providers";
import { RouteFeature, RouteLayout } from "../../../../routes";
import useGymflowModels from "../../../../store";
import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportNoVisitsDashboardWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { startDate, endDate } = useContext(ReportContext);
  const { data: statisticsData, isFetching: isLoading } = useStatistic({
    api,
    clubId,
    start: moment(startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end: moment(endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    trendPeriod: "DAY",
    categories: ["visit"],
    visitFields: ["noVisits"],
  });

  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  return (
    <ReportWidgetCard
      title="Members with No Visits"
      value={(statisticsData?.visit?.noVisits?.total || 0).toString()}
      isLoading={isLoading}
      explanation="Number of active subscribers that have not checked in within the date range selected."
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.NoVisitReport,
          ),
          search: QueryString.stringify({
            startDate: moment(startDate, DATE_FORMAT).format(
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ),
            endDate: moment(endDate, DATE_FORMAT)
              .endOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
