import 'moment-timezone';

import { canCreate, canFind, canFindById, canTrimFields, canUpdate } from './features/apiResource';
import endpoints from './endpoints';
import { assert } from 'check-types';

const taskApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ['name', 'notes'],
    baseUrl: `${clubPrefix}${endpoints.task}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    {
      remove(id) {
        assert.number(id, 'taskId must be a number');
        return state.http.delete(`${state.baseUrl}/${id}`);
      },
    }
  );
};

export default taskApi;
