export function PlayStoreButton({ className }: { className?: string }) {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.gymflow.member"
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      <img
        src="/public/img/GetItOnGooglePlay_Badge_Web_color_English.png"
        alt="Link to the Gymflow App"
        className="h-100"
      />
    </a>
  );
}
