import LeadDetailsForm from "./LeadDetailsForm";

const LeadOverview = ({
  user,
  isLoading: loadingRecord,
  updateUser,
  updateLeadStatus,
}) => {
  return (
    <div className="-mx-4 flex max-h-full flex-col overflow-y-auto px-4 lg:-mx-8 lg:px-8">
      <div className="relative flex h-full max-h-full w-full flex-col">
        <LeadDetailsForm
          user={user}
          isLoading={loadingRecord}
          updateUser={updateUser}
          updateLeadStatus={updateLeadStatus}
        />
      </div>
    </div>
  );
};

export default LeadOverview;
