import { useEffect } from "react";
import PropTypes from "prop-types";
import { ProductStockType } from "@gymflow/common";
import { formatCurrency, range } from "@gymflow/helpers";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import Select from "react-select";
import styled from "styled-components";

import useCartStore from "./useCartStore";

/**
 * @deprecated rewrite in tailwind
 */
const Title = styled.b`
  font-size: ${({ theme }) => theme.text.medium};
`;

function ProductCart({ products, onSelectClick, value, currency }) {
  const {
    products: cart,
    addProduct,
    removeProduct,
    changeProductQuantity,
    setProducts,
  } = useCartStore();

  useEffect(() => {
    setProducts(value);
  }, [value]);

  const renderProducts = () =>
    products
      .sort((a, b) => {
        const cartProductA = cart.find(({ id }) => id === a.id)?.quantity || 0;
        const cartProductB = cart.find(({ id }) => id === b.id)?.quantity || 0;

        if (cartProductA === 0 && cartProductB === 0) {
          return a.price - b.price;
        }
        return cartProductB - cartProductA;
      })
      .map(({ id, name, price, stockOnHand, stockType }) => {
        const cartItem = cart.find((productInCart) => productInCart.id === id);
        const selectValue = cartItem
          ? { value: cartItem.quantity, label: cartItem.quantity }
          : { value: 0, label: 0 };
        const stockAvailable =
          stockType === ProductStockType.Unlimited || stockOnHand > 1;
        const stockLimitReached =
          stockType !== ProductStockType.Unlimited &&
          cartItem?.quantity >= stockOnHand;
        const maxStock =
          stockType === ProductStockType.Unlimited || stockOnHand > 100
            ? 100
            : stockOnHand;

        return (
          <Card className="mt-2">
            <CardBody
              key={id}
              style={{
                backgroundColor: cartItem ? "#f0f0f0" : undefined,
                padding: "5px 20px",
              }}
            >
              <Row>
                <Col className="d-flex align-items-center">
                  <Title>{name}</Title>
                </Col>
                <Col className="d-flex align-items-center">
                  <Title>{formatCurrency(price, currency)}</Title>
                </Col>
                <Col className="text-muted d-flex align-items-center">
                  SOH:{" "}
                  {stockType === ProductStockType.Unlimited
                    ? "Unlimited"
                    : stockOnHand}
                </Col>
                <Col className="d-flex align-items-center">
                  <div className="text-muted d-inline-block">In Cart:</div>
                  <div className="d-inline-block ml-2">
                    <Select
                      className="react-select info react-select-btn"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          "&:hover": { borderColor: "transparent" },
                          width: "65px !important",
                          border: "0",
                          background: "transparent",
                          alignItems: "center",
                          boxShadow: null,
                          boxSizing: "border-box",
                          cursor: "default",
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-between",
                          label: "control",
                          minHeight: 38,
                          outline: "0 !important",
                          position: "relative",
                          transition: "all 100ms",
                        }),
                        indicatorSeparator: (provided) => ({
                          ...provided,
                          display: "none",
                        }),
                      }}
                      value={selectValue}
                      options={range(0, maxStock).map((i) => ({
                        label: i,
                        value: i,
                      }))}
                      onChange={({ value }) => {
                        if (value === 0) {
                          removeProduct({ id });
                        } else {
                          changeProductQuantity({
                            product: {
                              id,
                              name,
                              price,
                              stockOnHand,
                              stockType,
                            },
                            quantity: value,
                          });
                        }
                      }}
                    />
                  </div>
                </Col>
                <Col className="d-flex align-items-center">
                  <Button
                    className="font-weight-bold"
                    color="primary"
                    size="sm"
                    style={{ width: "140px" }}
                    onClick={() =>
                      addProduct({
                        product: { id, name, price, stockOnHand, stockType },
                      })
                    }
                    disabled={!stockAvailable || stockLimitReached}
                  >
                    {stockAvailable ? "Add" : "No Stock"}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        );
      });

  return (
    <>
      <Row style={{ maxHeight: "300px", overflow: "auto" }}>
        <Col>{renderProducts()}</Col>
      </Row>
      <Row>
        <Col>
          <Card className="mt-2">
            <CardBody>
              <Button
                className="font-weight-bold"
                color="primary"
                size="sm"
                style={{ width: "140px" }}
                onClick={() => onSelectClick(cart)}
                disabled={cart.length === 0}
              >
                Next
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

ProductCart.defaultProps = {
  products: [],
  value: [],
};

ProductCart.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      stockOnHand: PropTypes.string.isRequired,
      stockType: PropTypes.oneOf(Object.values(ProductStockType)),
    }),
  ),
  onSelectClick: PropTypes.func.isRequired,
  value: PropTypes.array,
};

export default ProductCart;
