import { QueryKey } from "@tanstack/react-query";

export const activityAsMemberQueryKeys = {
  all: () => ["activityAsMember"] as QueryKey,
  list: (filters: Readonly<Record<string, any>> = {}) =>
    [...activityAsMemberQueryKeys.all(), { filters }] as QueryKey,
  categories: (filter?: Readonly<Record<string, any>>) =>
    ["activity-categories", filter] as QueryKey,
  categoryDetails: (id: number) => ["activity-category", id] as QueryKey,
};
