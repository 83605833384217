import {
  useFacilityList,
  useQueryFacilityAvailabilityCalculateMappedByFacility,
  useQueryHostAvailability,
  useQueryHostAvailabilityCalculateMappedByHost,
  useQueryHostAvailabilityCalculateMultipleDays,
  useStaffList,
} from "@gymflow/api";
import { isMobile, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { AvailabilityCalculationResult } from "@gymflow/types";
import { intervalToDuration } from "date-fns";
import moment from "moment-timezone";
import { useMemo } from "react";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { CalendarMode } from "./useCalendarMode";

export function useCalendarAvailability({
  enabled = true,
  modeSelected,
  intervalLoaded,
  filteredStaffIds,
  filteredFacilityIds,
}: {
  intervalLoaded?: { start: Date; end: Date };
  modeSelected: CalendarMode;
  filteredStaffIds?: string[];
  filteredFacilityIds?: number[];
  enabled?: boolean;
}) {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const defaultResourceLimit = isMobile() ? 1 : 10;

  const { data: staffList, isFetching: isFetchingStaffList } = useStaffList(
    {
      api,
      opts: {
        extraParams: {
          id: filteredStaffIds,
          availableForAppointments: filteredStaffIds ? undefined : true,
        },
        limit: filteredStaffIds ? 100 : defaultResourceLimit,
      },
    },
    { enabled },
  );

  const dateFrom =
    intervalLoaded?.start &&
    moment(intervalLoaded.start).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ);

  const {
    data: availabilityCalculationResult,
    isFetching: isFetchingAvailability,
  } = useQueryHostAvailabilityCalculateMappedByHost(
    {
      api,
      dateFrom,
      dateTo:
        intervalLoaded?.start &&
        moment(intervalLoaded.start)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      appointableHostsIdList:
        staffList && staffList.content.map((trainer) => trainer.id),
      tz: timezone,
    },
    {
      enabled:
        enabled &&
        modeSelected === "TRAINER" &&
        !!timezone &&
        !!intervalLoaded?.start,
    },
  );

  const numberOfDaysToCalculateAvailability = useMemo(() => {
    if (!intervalLoaded?.start || !intervalLoaded?.end) {
      return undefined;
    }
    const interval = intervalToDuration({
      start: intervalLoaded.start,
      end: intervalLoaded.end,
    });
    if (interval?.days === undefined) {
      return undefined;
    }
    if (interval.days <= 1) {
      return 1;
    } else if (interval.days <= 7) {
      return 7;
    } else {
      return 31;
    }
  }, [intervalLoaded]);

  const showAvailabilityOnNonTrainerMode =
    modeSelected !== "TRAINER" && filteredStaffIds?.length === 1;
  const { data: hostAvailability, isFetching: isFetchingHostAvailability } =
    useQueryHostAvailability(
      { api, staffId: filteredStaffIds?.[0] || null },
      {
        enabled: enabled && showAvailabilityOnNonTrainerMode,
      },
    );

  const {
    data: availabilityCalculationResultMultipleDays,
    isFetching: isFetchingAvailabilityForMultipleDays,
  } = useQueryHostAvailabilityCalculateMultipleDays(
    {
      api,
      tz: timezone,
      dateFrom,
      appointableHostId: filteredStaffIds?.[0],
      numberOfDays: numberOfDaysToCalculateAvailability,
    },
    {
      enabled:
        enabled &&
        !!timezone &&
        !!dateFrom &&
        !!numberOfDaysToCalculateAvailability &&
        showAvailabilityOnNonTrainerMode,
    },
  );

  const { data: facilityList, isFetching: isFetchingFacilities } =
    useFacilityList(
      {
        api,
        opts: {
          extraParams: {
            id: filteredFacilityIds,
            availableForAppointments: filteredFacilityIds ? undefined : true,
          },
          limit: filteredFacilityIds ? undefined : defaultResourceLimit,
        },
      },
      { enabled },
    );
  const {
    data: facilityAvailability,
    isFetching: isFetchingFacilityAvailability,
  } = useQueryFacilityAvailabilityCalculateMappedByFacility(
    {
      api,
      tz: timezone,
      dateFrom,
      dateTo:
        intervalLoaded?.start &&
        moment(intervalLoaded.start)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      appointableFacilityIdList: filteredFacilityIds,
    },
    {
      enabled:
        enabled && !!intervalLoaded?.start && modeSelected === "FACILITY",
    },
  );

  const unavailabilityEvents = useMemo(() => {
    if (!intervalLoaded || (!staffList && !facilityList)) {
      return [];
    }

    type Gap = {
      readonly start: Date;
      readonly end: Date;
      readonly resourceId: string;
      readonly display: "background";
    };
    switch (modeSelected) {
      case "TRAINER":
        if (!availabilityCalculationResult || !staffList) {
          return [];
        }
        return staffList.content.reduce((acc, { id: staffId }) => {
          let gaps = availabilityCalculationResult?.[staffId] || [];

          return acc.concat(
            calculateGaps({ intervalLoaded, gaps, resourceId: staffId }),
          );
        }, [] as Gap[]);
      case "FACILITY":
        if (!facilityAvailability || !facilityList) {
          return [];
        }
        return facilityList.content.reduce((acc, { id: facilityId }) => {
          let gaps = facilityAvailability?.[facilityId] || [];

          return acc.concat(
            calculateGaps({
              intervalLoaded,
              gaps,
              resourceId: facilityId.toString(),
            }),
          );
        }, [] as Gap[]);
      default:
        if (
          !staffList ||
          filteredStaffIds?.length !== 1 ||
          !availabilityCalculationResultMultipleDays ||
          !hostAvailability?.availableForAppointments
        ) {
          return [];
        }
        return staffList.content.reduce((acc, { id: staffId }) => {
          let gaps = availabilityCalculationResultMultipleDays;

          return acc.concat(
            calculateGaps({ intervalLoaded, gaps, resourceId: staffId }),
          );
        }, [] as Gap[]);
    }
  }, [
    intervalLoaded,
    staffList,
    facilityList,
    modeSelected,
    availabilityCalculationResult,
    facilityAvailability,
    filteredStaffIds?.length,
    availabilityCalculationResultMultipleDays,
    hostAvailability?.availableForAppointments,
  ]);

  const resources = useMemo(() => {
    if (modeSelected === "FACILITY" && facilityList?.content) {
      return facilityList.content.map((facility) => ({
        title: facility.name,
        id: facility.id.toString(),
      }));
    }
    if (staffList?.content) {
      return staffList.content.map((staff) => ({
        title: `${staff.firstName} ${staff.lastName}`,
        id: staff.id,
      }));
    }

    return [];
  }, [facilityList?.content, modeSelected, staffList?.content]);

  return {
    isFetchingAvailability,
    unavailabilityEvents,
    isFetching:
      isFetchingAvailability ||
      isFetchingAvailabilityForMultipleDays ||
      isFetchingStaffList ||
      isFetchingHostAvailability ||
      isFetchingFacilityAvailability ||
      isFetchingFacilities,
    staffList: staffList?.content,
    facilityList: facilityList?.content,
    resources,
  };
}

function calculateGaps({
  intervalLoaded,
  gaps,
  resourceId,
}: {
  intervalLoaded: { start: Date; end: Date };
  gaps: AvailabilityCalculationResult[];
  resourceId: string;
}) {
  const display = "background" as const;
  let resultingIntervals = [
    {
      start: intervalLoaded.start,
      end: moment(intervalLoaded.end).endOf("day").toDate(),
      resourceId,
      display,
    },
  ];
  gaps.forEach((gap) => {
    const gapStart = moment(gap.startTime, PARAMETER_DATE_FORMAT_WITHOUT_TZ);
    const gapEnd = moment(gap.endTime, PARAMETER_DATE_FORMAT_WITHOUT_TZ);

    resultingIntervals = resultingIntervals.flatMap((interval) => {
      if (
        gapStart.isBetween(interval.start, interval.end) &&
        gapEnd.isBetween(interval.start, interval.end)
      ) {
        // Gap is within the interval
        return [
          {
            start: interval.start,
            end: gapStart.toDate(),
            resourceId,
            display,
          },
          {
            start: gapEnd.toDate(),
            end: interval.end,
            resourceId,
            display,
          },
        ];
      } else if (gapStart.isBetween(interval.start, interval.end)) {
        // Gap starts within the interval
        return [
          {
            start: interval.start,
            end: gapStart.toDate(),
            resourceId,
            display,
          },
        ];
      } else if (gapEnd.isBetween(interval.start, interval.end)) {
        // Gap ends within the interval
        return [
          {
            start: gapEnd.toDate(),
            end: interval.end,
            resourceId,
            display,
          },
        ];
      } else {
        // Gap doesn't overlap with the interval
        return [
          {
            start: interval.start,
            end: interval.end,
            resourceId,
            display,
          },
        ];
      }
    });
  });
  return resultingIntervals;
}
