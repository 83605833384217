import { DATE_FORMAT, FormMapper, PARAMETER_DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";

import { DEADLINE_DATE } from "./TaskSchema";

class TaskFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "deadlineDate",
          transform: (date) => moment(date, PARAMETER_DATE_FORMAT).format(DATE_FORMAT),
        },
        {
          key: "deadlineTime",
          transform: (time) => {
            return moment(time, "HH:mm:ss").format("h:mm a");
          },
        },
      ],
      outValue: [
        {
          key: DEADLINE_DATE,
          transform: (date) => moment(date, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
        },
        {
          key: "deadline-time",
          transform: (time) => moment(time, "h:mm a").format("HH:mm:ss"),
        },
      ],
    });
  }

  to(values) {
    const transformed = super.to(values);
    return transformed;
  }

  from(formValues) {
    const transformed = super.from(formValues);
    transformed.relatedUserIdList = transformed.taskRelatedUsers?.map(({ id }) => id);
    transformed.relatedLeadIdList = transformed.taskRelatedLeads?.map(({ id }) => id);
    transformed.ownerIdList = transformed.taskOwners?.map(({ id }) => id);

    delete transformed.taskRelatedUsers;
    delete transformed.taskRelatedLeads;
    delete transformed.taskOwners;

    return transformed;
  }
}

export default TaskFormMapper;
