import { z } from "zod";

export const UserProfileType = {
  Lead: "LEAD",
  User: "USER",
} as const;

export const UserProfileTypeZodSchema = z.enum([
  UserProfileType.Lead,
  UserProfileType.User,
]);
