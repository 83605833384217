import { useEffect, useState } from "react";
import { Form } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

import Wizard from "../../../../components/Wizard";
import Step1 from "./Step1";
import UrlType from "./UrlType";
import Step2GuestRegistration from "./Step2GuestRegistration";
import Step2Memberships from "./Step2Memberships";
import Step2Timetable from "./Step2Timetable";
import Step2SessionPacks from "./Step2SessionPacks";
import Step3Timetable from "./Step3Timetable";
import Step4Timetable from "./Step4Timetable";
import StepLast from "./StepLast";
import Step5Timetable from "./Step5Timetable";
import { Step6Timetable } from "./Step6Timetable";
import LandingPage from "./LandingPage";
import useGymflowModels from "../../../../store";

function LinkGeneratorWizard({ onClose }) {
  const filterMap = {
    [UrlType.Memberships]: { memberships: [] },
    [UrlType.SessionPacks]: { sessionPacks: [] },
    [UrlType.Timetable]: {
      activities: [],
      hosts: [],
      facilities: [],
      landingPage: LandingPage.Memberships,
      memberships: [],
      sessionPacks: [],
    },
    [UrlType.GuestRegistration]: { leadSource: null },
    [UrlType.Enquiry]: { leadSource: null },
  };
  const [urlTypeSelected, setUrlTypeSelected] = useState(UrlType.Memberships);
  const [filters, setFilters] = useState(filterMap[UrlType.Memberships]);

  useEffect(() => {
    setFilters({ ...filterMap[urlTypeSelected] });
  }, [urlTypeSelected]);

  const step1 = {
    name: "Step1",
    component: Step1,
    innerProps: {
      value: urlTypeSelected,
      onChange: setUrlTypeSelected,
    },
  };

  const lastStep = {
    name: "UrlStep",
    component: StepLast,
    innerProps: {
      filters,
      type: urlTypeSelected,
    },
  };

  const stepMap = {
    [UrlType.Memberships]: [
      step1,
      {
        name: "Step2",
        component: Step2Memberships,
        innerProps: {
          onChange: (value) => {
            setFilters({ ...filters, memberships: value });
          },
          value: filters?.memberships,
        },
      },
      lastStep,
    ],

    [UrlType.SessionPacks]: [
      step1,
      {
        name: "Step2",
        component: Step2SessionPacks,
        innerProps: {
          onChange: (value) => {
            setFilters({ ...filters, sessionPacks: value });
          },
          value: filters?.sessionPacks,
        },
      },
      lastStep,
    ],
    [UrlType.Timetable]: [
      step1,
      {
        name: "Step2",
        component: Step2Timetable,
        innerProps: {
          onChange: (value) => {
            setFilters({ ...filters, activities: value });
          },
          value: filters?.activities,
        },
      },
      {
        name: "Step3",
        component: Step3Timetable,
        innerProps: {
          onChange: (value) => {
            setFilters({ ...filters, hosts: value });
          },
          value: filters?.hosts,
        },
      },
      {
        name: "Step4",
        component: Step4Timetable,
        innerProps: {
          onChange: (value) => {
            setFilters({ ...filters, facilities: value });
          },
          value: filters?.facilities,
        },
      },
      {
        name: "Step5",
        component: Step5Timetable,
        innerProps: {
          onChange: ({ landingPage, memberships, sessionPacks }) => {
            setFilters({ ...filters, landingPage, memberships, sessionPacks });
          },
          value: {
            landingPage: filters.landingPage,
            memberships: filters.memberships,
            sessionPacks: filters.sessionPacks,
          },
        },
      },
      {
        name: "Step6",
        component: Step6Timetable,
        innerProps: {
          onChange: ({ date }) => {
            setFilters({ ...filters, date });
          },
          value: {
            date: filters?.date,
          },
        },
      },
      lastStep,
    ],
    [UrlType.GuestRegistration]: [
      step1,
      {
        name: "Step2",
        component: Step2GuestRegistration,
        innerProps: {
          onChange: (value) => {
            setFilters({ leadSource: value });
          },
          value: filters?.leadSource,
        },
      },
      lastStep,
    ],
    [UrlType.Enquiry]: [
      step1,
      {
        name: "Step2",
        component: Step2GuestRegistration,
        innerProps: {
          onChange: (value) => {
            setFilters({ leadSource: value });
          },
          value: filters?.leadSource,
        },
      },
      lastStep,
    ],
  };

  const steps = stepMap[urlTypeSelected];

  return (
    <ReactBSAlert
      showCancel
      onCancel={onClose}
      closeOnClickOutside={false}
      onConfirm={onClose}
      customButtons={<></>}
      customClass="alert-wrapper"
      style={{
        width: 600,
      }}
    >
      <Form
        className="form-horizontal text-left"
        role="form"
        style={{ height: 440 }}
      >
        <Wizard
          steps={steps}
          onCancel={onClose}
          onSubmit={onClose}
          submitBtnProps={{ text: "Close" }}
        />
      </Form>
    </ReactBSAlert>
  );
}

function LinkGeneratorWizardWithProviders(props) {
  const { ServiceStore, CreditPackStore } = useGymflowModels();
  return (
    <ServiceStore.Provider>
      <CreditPackStore.Provider>
        <LinkGeneratorWizard {...props} />
      </CreditPackStore.Provider>
    </ServiceStore.Provider>
  );
}

export default LinkGeneratorWizardWithProviders;
