import { ModalWrapper } from "../../../providers";
import { Button, DangerIcon, PrimaryButton } from "../../atoms";

export function RecurrenceEditModal({
  onCancel,
  onConfirm,
}: {
  onCancel: () => void;
  onConfirm: (updateAll: boolean) => Promise<void>;
}) {
  return (
    <ModalWrapper className="sm:!max-w-2xl" onCancel={onCancel}>
      <div className="bg-primary-100 mb-3 flex h-11 w-11 self-center rounded-full p-3">
        <DangerIcon className="h-5 w-5" pathClassName="stroke-primary-600" />
      </div>
      <div className="text-lg font-semibold text-gray-900">
        Select which occurrences to change
      </div>
      <div className="mt-2 text-sm text-gray-600">
        This event is recurring. Do you want your edits to apply to all
        following occurrences or just this specific one?
      </div>
      <div className="mt-5 flex space-x-2">
        <PrimaryButton
          onClick={() => {
            return onConfirm(false);
          }}
          className="flex-1"
        >
          Only this date
        </PrimaryButton>
        <PrimaryButton
          onClick={() => {
            return onConfirm(true);
          }}
          className="flex-1"
        >
          All following dates
        </PrimaryButton>
        <Button onClick={onCancel} className="mt-2 flex-1">
          Cancel
        </Button>
      </div>
    </ModalWrapper>
  );
}
