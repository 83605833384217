import { z } from "zod";

export const UserStatus = {
  Lead: "LEAD",
  Customer: "CUSTOMER",
  Member: "MEMBER",
  Deleted: "DELETED",
} as const;

export const UserStatusZodSchema = z.enum([
  UserStatus.Lead,
  UserStatus.Customer,
  UserStatus.Member,
  UserStatus.Deleted,
]);

export function humanizeUserStatus(
  value: (typeof UserStatus)[keyof typeof UserStatus],
) {
  return Object.keys(UserStatus).find(
    (key) => UserStatus[key as keyof typeof UserStatus] === value,
  );
}
