import {
  BootstrapServerRepository,
  clubStaleTime,
  useClub,
  useStaff,
} from "@gymflow/api";
import { useMutation } from "@tanstack/react-query";
import { useStoreState } from "easy-peasy";
import { useEffect, useMemo, useState } from "react";

import environment from "../../environment";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useRememberMe } from "../../hooks/useRememberMe";
import { useClubSettings } from "../../providers";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { PaginatedSelect } from "./PaginatedSelect";

export interface NavigateToClubSelectProps {}
export function NavigateToClubSelect() {
  const settings = useClubSettings();
  const { brand, routeId, createNewClubLink } = usePortalRoutes();
  const { api, authStore } = useGymflowModels();
  const clubId = settings.clubId;
  const { data: currentClub } = useClub(
    { clubId, api },
    { staleTime: clubStaleTime },
  );

  const serverUrl = environment.get("API_RESOLVER_URL");
  const repository = useMemo(
    () => new BootstrapServerRepository(serverUrl),
    [serverUrl],
  );
  const getClubs = useMutation({
    mutationFn: ({ searchTerm, page, limit, clubIds }: any) =>
      repository.getRoutesByBrand({
        search: searchTerm,
        page,
        limit,
        brand,
        clubIds,
      }),
  });

  const { id: loggedInId } = useStoreState(authStore);
  const { data: currentStaff }: any = useStaff({ api, staffId: loggedInId });

  const [currentOption, setCurrentOption] = useState({ label: "---" });
  useEffect(() => {
    if (!currentClub) {
      return;
    }
    const fetchCurrentClub = async () => {
      const { results } = await getClubs.mutateAsync({
        page: 0,
        limit: 1,
        clubIds: [currentClub.id],
      });
      if (results.length > 0) {
        setCurrentOption({ label: results[0].displayName });
      }
    };
    fetchCurrentClub();
  }, [currentClub]);

  const { rememberRoute } = useRememberMe();

  return (
    <PaginatedSelect
      className="[&_div]:text-primary-700 h-full !rounded-none border-y-0 border-l border-r-0 border-gray-300 p-0"
      value={{
        ...currentOption,
        label: currentOption.label,
      }}
      onChange={({ value }: any) => {
        rememberRoute(value.routeId);
        window.location.href = createNewClubLink(
          value.routeId,
          RouteLayout.Staff,
        );
      }}
      cacheUniqs={[currentStaff?.clubAccessibleList]}
      loadOptions={async (searchTerm: string, _: any, { page }: any) => {
        const clubIds = currentStaff?.clubAccessibleList.map(
          ({ id }: { id: number }) => id,
        );
        const data = await getClubs.mutateAsync({
          searchTerm,
          page,
          limit: 10,
          clubIds,
        });

        return {
          options: data.results.map((route) => ({
            label: route.displayName,
            value: route,
            isDisabled:
              route.status === "DISABLED" || route.routeId === routeId,
          })),
          hasMore: data.hasMore,
          additional: {
            page: page + 1,
          },
        };
      }}
    />
  );
}
