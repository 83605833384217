import { useMutation } from '@tanstack/react-query';
import { zonedTimeToUtc } from '@gymflow/helpers';

export function useMemberCalculateProRataForResumingSubscription(
  { api, tz }: {
    api: {
      memberApi:
      {
        calculateProRata: (
          memberId: string,
          subscriptionId: number,
          resumeDate: string
        ) => Promise<any>
      }
    },
    tz: string
  }
) {
  const calculateProRataMutation = useMutation({
    mutationFn: async ({ memberId, subscriptionId, resumeDate }: {
      memberId: string,
      subscriptionId: number,
      resumeDate: string
    }) => {
      const result = await api.memberApi.calculateProRata(memberId, subscriptionId, zonedTimeToUtc(resumeDate, tz));
      return result.data as number;
    },
  });

  return calculateProRataMutation;
}
