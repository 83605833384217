import { useTaskDelete, useTaskEdit } from "@gymflow/api";
import { useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { useCallback, useContext } from "react";

import { normalizeOutgoingData } from "../utils/dataNormalizer";
import { TaskForm } from "./TaskForm/TaskForm";
import { TaskFormProps, TaskFormValues } from "./TaskForm/types";

type EditTaskSidebarProps = {
  taskId?: number;
  onUpdate?: () => void;
} & Pick<TaskFormProps, "onClose" | "initialValues">;

export const EditTaskForm = ({
  onClose,
  initialValues,
  taskId,
  onUpdate,
}: EditTaskSidebarProps) => {
  const { toast, notifyDanger } = useContext(ToastContext);
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const { mutateAsync: mutateAsyncUpdateTask } = useTaskEdit({ api, tz });
  const { mutateAsync: mutateAsyncRemoveTask } = useTaskDelete({ api });

  const onSubmit = useCallback(
    async (values: TaskFormValues) => {
      if (!taskId) return;

      try {
        await mutateAsyncUpdateTask({
          taskId,
          patchedFields: normalizeOutgoingData(values),
        });

        toast({ message: "Task Updated" });
        onUpdate && onUpdate();
        onClose();
      } catch (e) {
        notifyDanger(e as any);
      }
    },
    [mutateAsyncUpdateTask, toast, notifyDanger, onClose, onUpdate, taskId],
  );

  const onDelete = useCallback(async () => {
    if (!taskId) return;

    try {
      await mutateAsyncRemoveTask(taskId);

      toast({ message: "Task Deleted" });
      onUpdate && onUpdate();
      onClose();
    } catch (e) {
      notifyDanger(e as any);
    }
  }, [mutateAsyncRemoveTask, toast, notifyDanger, onClose, onUpdate, taskId]);

  return (
    <TaskForm
      initialValues={initialValues}
      title="Edit Task"
      description="Edit tasks by updating the assigned owners and linking them to any user or lead."
      submitButtonText="Save"
      onClose={onClose}
      onDelete={onDelete}
      onSubmit={onSubmit}
    />
  );
};
