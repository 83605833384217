import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityOverrideDelete({
  api,
}: {
  api: {
    availabilityApi: {
      deleteFacilityOverride: (
        facilityId: number,
        overrideId: number,
      ) => Promise<{ data: void }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      availabilityOverrideId,
    }: {
      facilityId: number;
      availabilityOverrideId: number;
    }) => {
      await api.availabilityApi.deleteFacilityOverride(
        facilityId,
        availabilityOverrideId,
      );
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allFacilityOverrides(facilityId),
      });
    },
  });

  return mutation;
}
