import { useRef, useEffect } from 'react';

const useFocusOnError = ({ fieldRef, name, formikProps: { submitCount, errors, isValid } }) => {
  const prevSubmitCountRef = useRef(submitCount);
  const firstErrorKey = Object.keys(errors)[0];
  useEffect(() => {
    if (prevSubmitCountRef.current !== submitCount && !isValid && fieldRef.current && firstErrorKey === name) {
      try {
        fieldRef.current.focus();
      } catch {
        const element = document.getElementById(firstErrorKey);
        if (element !== null) {
          element.focus();
        }
      }
    }
  }, [submitCount]);
};

export default useFocusOnError;
