import { useClubHostedPagesSettingsAsPublic } from "@gymflow/api";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Branding, Links, PageMenu } from "../organisms";

export function WebsiteSettings() {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: websiteSettings } = useClubHostedPagesSettingsAsPublic({
    api,
    clubId,
  });

  return (
    <div className="flex h-full flex-col gap-6 overflow-y-auto pb-24">
      <Links />
      <div className="border-t border-t-gray-300"></div>
      <PageMenu
        value={
          websiteSettings
            ? {
                membershipsNavEnabled:
                  websiteSettings.settings.membershipsNavEnabled,
                creditPacksNavEnabled:
                  websiteSettings.settings.creditPacksNavEnabled,
                productNavEnabled: websiteSettings.settings.productNavEnabled,
                appointmentsNavEnabled:
                  websiteSettings.settings.appointmentsNavEnabled,
                timetableNavEnabled:
                  websiteSettings.settings.timetableNavEnabled,
                leadCaptureNavEnabled:
                  websiteSettings.settings.leadCaptureNavEnabled,
                yourWebsiteUrl: websiteSettings.settings.yourWebsiteUrl,
                redirectUrl: websiteSettings.settings.redirectUrl,
              }
            : undefined
        }
      />
      <div className="border-t border-t-gray-300"></div>
      <Branding
        value={
          websiteSettings
            ? {
                darkMode: websiteSettings.settings.darkMode,
                font: websiteSettings.settings.font,
                primaryColor: websiteSettings.settings.primaryColor,
                logo: websiteSettings.settings.logo,
              }
            : undefined
        }
      />
    </div>
  );
}
