class ModelBuilder {
  constructor() {
    this.generators = [];
  }

  build() {
    return this.generators.reduce((acc, generator) => {
      return {
        ...acc,
        ...generator.bind(this)(),
      };
    }, {});
  }
}

export default ModelBuilder;
