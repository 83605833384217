import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation } from "@tanstack/react-query";

export function useNoShowsReportCsv({
  api,
  tz,
}: {
  api: ApiType;
  startDate?: string;
  endDate?: string;
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
    }: {
      startDate?: string;
      endDate?: string;
    }) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.noShowsCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
      });
      return result.data;
    },
  });
  return csvMutation;
}
