import { utcToZonedTime } from "@gymflow/helpers";
import { ApiListResponse, NoteDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { noteQueryKeys } from "./noteQueryKeys";

export function useNoteSearch({
  api,
  opts,
  tz,
}: {
  api: {
    noteApi: {
      search: (
        filter: NoteSearchFilter,
      ) => Promise<{ data: ApiListResponse<NoteDTO> }>;
    };
  };
  opts: NoteSearchFilter;
  tz: string;
}) {
  const result = useQuery({
    queryKey: noteQueryKeys.search(opts),
    queryFn: async () => {
      const result = await api.noteApi.search({ ...opts });
      return result.data;
    },
    initialData: defaultPage<NoteDTO>,
    select: (data) => {
      const notes = data.content.slice(0);
      const utcNotes = notes.map((note) => {
        return merge({}, note, {
          createdDate: utcToZonedTime(note.createdDate, tz),
        });
      });

      return merge({}, data, { content: utcNotes });
    },
  });
  return result;
}

interface NoteSearchFilter {
  readonly page: number;
  readonly limit: number;
  readonly userMemberId?: string;
  readonly leadId?: number;
  readonly appointmentId?: number;
  readonly dateFrom?: string;
  readonly dateTo?: string;
}
