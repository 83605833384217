import { z } from "zod";

export const TemplateType = {
  SMS: "SMS",
  Email: "EMAIL",
} as const;

export const TemplateTypeZodSchema = z.enum([
  TemplateType.SMS,
  TemplateType.Email,
]);

Object.setPrototypeOf(TemplateType, {
  humanize: (value: (typeof TemplateType)[keyof typeof TemplateType]) => {
    switch (value) {
      case TemplateType.SMS:
        return "SMS";
      case TemplateType.Email:
        return "Email";
      default:
        throw new Error("Unknown TemplateType.");
    }
  },
});
