import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { RouteLayout } from "../routes/layout";
import { usePortalRoutes } from "./usePortalRoutes";

const nodeClassName = "embed-show-transparency-loading";
function removeStyleNodes() {
  const previousElements = document.querySelectorAll(`.${nodeClassName}`);
  previousElements.forEach((el) => el.remove());
}

export function useEmbedLoadingStyleStart() {
  const location = useLocation();
  const { isPathInLayout } = usePortalRoutes();

  useEffect(() => {
    const isEmbed = isPathInLayout(location.pathname, RouteLayout.Embed);

    removeStyleNodes();

    let styleElement: HTMLStyleElement | null = null;
    if (isEmbed) {
      styleElement = document.createElement("style");
      styleElement.className = nodeClassName;
      styleElement.textContent = `
        body {
          background-color: transparent !important;
        }
        #root {
          background-color: transparent !important;
          background: transparent !important;
        }
        .white-content {
          background-color: transparent !important;
        }
        .white-content .main-panel {
          background-color: transparent !important;
          background: transparent !important;
        }
      `;
      document.head.append(styleElement);
    } else {
      (document.querySelector(":root") as HTMLElement).style.setProperty(
        "--background-color",
        "#f7f8ff",
      );
    }
    return () => {
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);
}

export function useEmbedLoadingStyleEnd({ condition = true }) {
  useEffect(() => {
    if (condition) {
      removeStyleNodes();
      (document.querySelector(":root") as HTMLElement).style.setProperty(
        "--background-color",
        "#f7f8ff",
      );
    }
  }, [condition]);
}
