import { MembershipType } from "@gymflow/types";

export const membershipAllTabs = ["recurring", "prepaid", "trial"] as const;
export type MembershipTabType = (typeof membershipAllTabs)[number];
export const membershipTypeToString: { [key in MembershipTabType]: string } = {
  recurring: "Recurring",
  prepaid: "Pre-Paid",
  trial: "Trial",
};
export const membershipFilters: {
  [key in MembershipTabType]: {
    isTrial?: boolean;
    type: MembershipType;
  };
} = {
  recurring: { type: "RECURRING" },
  prepaid: { isTrial: false, type: "PREPAID" },
  trial: { isTrial: true, type: "PREPAID" },
};
