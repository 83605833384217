import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { reportQueryKeys } from "../report";
import { accessQueryKeys } from "./accessQueryKeys";

export function useCheckOutMutation({
  api,
}: {
  api: ApiType;
}, mutationOptions?: {onSuccess?: () => void}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userMemberId }: { userMemberId: string }) =>
      api.accessApi.checkOut(userMemberId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accessQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.allAccess() });
      mutationOptions?.onSuccess?.();
    },
  });
}
