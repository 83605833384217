import {
  useMembershipList,
  useProductList,
  usePromotion,
  usePromotionCreate,
  usePromotionEdit,
  usePromotionList,
  useSessionPackList,
} from "@gymflow/api";
import {
  AlertContext,
  AsyncButton,
  isMobile,
  ProductStatus,
  ServiceStatus,
} from "@gymflow/common";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import {
  CREDIT_PACK_STORE_KEY,
  PRODUCT_STORE_KEY,
  SERVICE_STORE_KEY,
} from "../../store/keys";
import { ResourceTableCard } from "../ResourceTableCard";
import Switch from "../Switch";
import { PromotionAlert } from "./PromotionAlert/PromotionAlert";

export function Promotions() {
  const { api } = useGymflowModels();

  const { setAlert, hide } = useContext(AlertContext);
  const { toast, notifyDanger } = useContext(ToastContext);
  const [editingPromotionId, setEditingPromotionId] = useState(null);

  const { data: editing } = usePromotion({
    api,
    promotionId: editingPromotionId,
  });
  const editPromotion = usePromotionEdit({ api });
  const createPromotion = usePromotionCreate({ api });

  const { data: memberships, isFetching: isFetchingMemberships } =
    useMembershipList({
      api,
      opts: { extraParams: { unpaged: true } },
    });
  const { data: products, isFetching: isFetchingProducts } = useProductList({
    api,
    opts: { extraParams: { unpaged: true } },
  });
  const { data: sessionPacks, isFetching: isFetchingSessionPacks } =
    useSessionPackList({
      api,
      opts: { extraParams: { unpaged: true } },
    });

  const availableItems = useMemo(() => {
    let items = [];
    items = items.concat(
      memberships.content.map(({ id, name, status }) => ({
        label: name,
        value: { id, type: SERVICE_STORE_KEY },
        isActive: status === ServiceStatus.Active,
      })),
    );
    items = items.concat(
      products.content.map(({ id, name, status }) => ({
        label: name,
        value: { id, type: PRODUCT_STORE_KEY },
        isActive: status === ProductStatus.Available,
      })),
    );
    items = items.concat(
      sessionPacks.content.map(({ id, name, status }) => ({
        label: name,
        value: { id, type: CREDIT_PACK_STORE_KEY },
        isActive: status === ServiceStatus.Active,
      })),
    );
    return items;
  }, [memberships, products, sessionPacks]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (editing) {
          await editPromotion.mutateAsync({
            promotionId: values.id,
            patchedFields: values.patchedFields,
          });
        } else {
          await createPromotion.mutateAsync(values);
        }
        setEditingPromotionId(null);
        hide();
      } catch (e) {
        notifyDanger(e);
      }
    },
    [editing, hide, editPromotion, createPromotion, notifyDanger],
  );

  const showAlert = useCallback(() => {
    setAlert(
      <PromotionAlert
        editing={editing}
        onCancel={() => {
          setEditingPromotionId(null);
          hide();
        }}
        specificItemsOptions={availableItems}
        onSubmit={handleSubmit}
      />,
    );
  }, [editing, hide, availableItems]);

  useEffect(() => {
    if (editingPromotionId) {
      showAlert();
    }
  }, [editing, editingPromotionId, showAlert]);

  return (
    <div className="flex h-full w-full">
      <ResourceTableCard
        columns={[
          {
            Header: <h3>Name</h3>,
            accessor: "name",
            show: !isMobile(),
          },
          {
            Header: <h3>Code</h3>,
            accessor: "code",
          },
          {
            Header: <h3>Times Used</h3>,
            accessor: "timesUsed",
          },
          {
            id: "actions",
            Header: (
              <div style={{ textAlign: "center", width: "100%" }}>
                <h3>Action</h3>
              </div>
            ),
            accessor: (row) => row,
            width: 150,
            Cell: ({ value }) => (
              <div className="d-flex justify-content-center">
                <AsyncButton
                  className="btn-link"
                  onClick={() => setEditingPromotionId(value.id)}
                  disabled={
                    editingPromotionId ||
                    isFetchingMemberships ||
                    isFetchingProducts ||
                    isFetchingSessionPacks
                  }
                >
                  Edit
                </AsyncButton>
              </div>
            ),
          },
          {
            id: "isActive",
            Header: <h3>Active</h3>,
            width: 100,
            accessor: (row) => row,
            Cell: ({ value: { id, active } }) => {
              return (
                <Switch
                  size="sm"
                  checked={active}
                  onChange={async (checked) => {
                    if (checked) {
                      await editPromotion.mutateAsync({
                        promotionId: id,
                        patchedFields: { active: true },
                      });
                      toast({ message: "Promotion enabled successfully." });
                    } else {
                      await editPromotion.mutateAsync({
                        promotionId: id,
                        patchedFields: { active: false },
                      });
                      toast({ message: "Promotion disabled successfully." });
                    }
                  }}
                />
              );
            },
          },
        ]}
        title="Promotion List"
        addBtnText="Add Promotion"
        onAddClick={showAlert}
        showEditButtons
        statusField="active"
        enabledValue
        showDisableCheckbox
        useQueryHook={usePromotionList}
        onEditClick={setEditingPromotionId}
        selectedId={editingPromotionId}
      />
    </div>
  );
}
