import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { RecurringAppointmentDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { appointmentQueryKeys } from "./appointmentQueryKeys";

export async function recurringAppointmentQueryFn({
  recurringAppointmentId,
  api,
}: {
  recurringAppointmentId: number;
  api: ApiType;
}) {
  const result = await api.appointmentApi.findRecurringById(
    recurringAppointmentId,
  );
  return result.data;
}

export function useQueryRecurringAppointment(
  {
    api,
    recurringAppointmentId,
  }: {
    api: ApiType;
    recurringAppointmentId?: number;
  },
  opts?: UseQueryOptions<RecurringAppointmentDTO>,
) {
  const result = useQuery({
    queryKey: appointmentQueryKeys.recurringAppointmentDetails(
      recurringAppointmentId,
    ),
    queryFn: () => {
      return recurringAppointmentQueryFn({
        recurringAppointmentId: recurringAppointmentId as number,
        api,
      });
    },
    enabled: !!recurringAppointmentId,
    ...opts,
  });

  return result;
}
