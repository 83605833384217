import { cn } from "@gymflow/helpers";
import { Field, FieldProps } from "formik";
import React from "react";

import { SMSTextInput, SMSTextInputProps } from "../base";
import { ErrorMessage } from "../ErrorMessage";

type FormSMSTextInputProps<T> = {
  name: keyof T;
  innerRef?: React.Ref<HTMLTextAreaElement>;
} & Omit<SMSTextInputProps, "onChange" | "value">;

export const FormSMSTextInput = <T,>({
  name,
  innerRef,
  className,
  ...props
}: FormSMSTextInputProps<T>) => (
  <Field name={name}>
    {({ field, meta: { touched, error } }: FieldProps) => {
      const isError = touched && error;
      return (
        <div className="flex flex-col gap-1.5">
          <SMSTextInput
            {...props}
            {...field}
            ref={innerRef}
            className={cn(
              className,
              isError && "outline-error-300 border-error-200",
            )}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);
