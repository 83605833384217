import { useQueryStripeProducts } from "@gymflow/api";
import { ModalWrapper } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useTranslation } from "react-i18next";

import { CloseIcon, Spinner } from "../../../../atoms";
import { SMSPackageItem } from "./components/SMSPackageItem";

type ChooseSMSPackageModalProps = {
  onClose: () => void;
};

export const ChooseSMSPackageModal = ({
  onClose,
}: ChooseSMSPackageModalProps) => {
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { data, isLoading } = useQueryStripeProducts({ api });

  return (
    <ModalWrapper
      className="flex flex-col gap-6 md:max-w-[54.75rem]"
      onCancel={() => onClose()}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="text-xl font-semibold text-gray-950">
          {t(
            "page.settings.tab.sms.creditsBalanceBlock.chooseSMSPackageModal.title",
          )}
        </div>
        <div className="cursor-pointer" onClick={() => onClose()}>
          <CloseIcon className="h-6 w-6" />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {!isLoading &&
          data?.map((product) => (
            <SMSPackageItem
              key={product.id}
              {...product}
              onClose={() => onClose()}
            />
          ))}
      </div>
      {isLoading && (
        <div className="flex pb-10">
          <Spinner />
        </div>
      )}
    </ModalWrapper>
  );
};
