export const Subject = {
  StaffCalendar: "StaffCalendar",
  UserCalendar: "UserCalendar",
  MemberList: "MemberList",
  MemberProfile: "MemberProfile",
  MyProfile: "MyProfile",
  Reports: "Reports",
  Settings: "Settings",
  Sales: "Sales",
  Dashboard: "Dashboard",
  GlobalSearch: "GlobalSearch",
  CheckoutPrice: "CheckoutPrice",
  /** @deprecated */
  Notifications: "Notifications",
  CustomerSales: "CustomerSales",
  Invoices: "Invoices",
  Leads: "Leads",
  Marketing: "Marketing",
  Task: "Task",
  PaymentStrongAuthorization: "PaymentStrongAuthorization",
  StaffAccount: "StaffAccount",
  Email: "Email",
  SMS: "SMS",
  Appointments: "Appointments",
  AppointmentAvailability: "AppointmentAvailability",
  FacilityAvailability: "FacilityAvailability",
  Access: "Access",
  StripeConnect: "StripeConnect",
} as const;

export type SubjectType = keyof typeof Subject;
