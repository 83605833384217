import { DayHeaderContentArg } from "@fullcalendar/core";
import { isMobile } from "@gymflow/common";
import classNames from "classnames";
import format from "date-fns/format";
import moment from "moment-timezone";

export function TimeGridWeekDayContent(date: DayHeaderContentArg) {
  if (isMobile()) return "";
  return (
    <div
      className={classNames("mb-1 flex flex-col rounded px-4 py-1", {
        "bg-primary-100": moment(date.date).isSame(new Date(), "day"),
      })}
    >
      <div className="text-xl font-bold text-gray-900">
        {format(moment(date.date).toDate(), "d")}
      </div>
      <div className="-mt-1 text-sm font-normal text-gray-900">
        {format(moment(date.date).toDate(), "E")}
      </div>
    </div>
  );
}
