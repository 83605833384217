import classNames from 'classnames';
import { Button, Spinner } from 'reactstrap';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import noop from 'lodash/noop';

import usePaymentAuthorization from './usePaymentAuthorization';

const PaymentAuthorizationAlert = ({
  onCancel,
  paymentIntentIdForAuthorization,
  onSuccess,
  onFailed,
  confirmPayment,
  text,
  hideCancel,
}) => {
  const { cancel, secondsUntilNextCheck, check } = usePaymentAuthorization({
    onSuccess,
    onFailed,
    paymentIntentIdForAuthorization,
    confirmPayment,
  });

  const renderButtons = () => {
    return (
      <>
        <Button
          size="sm"
          color="primary"
          type="button"
          onClick={() => {
            check();
          }}
          style={{ marginRight: '8px' }}
        >
          Check Status
        </Button>
        <Button
          size="sm"
          outline
          color="primary"
          type="button"
          className={classNames({ 'd-none': hideCancel })}
          onClick={() => {
            cancel();
            return onCancel();
          }}
        >
          Confirm later
        </Button>
      </>
    );
  };

  const renderProgress = () => {
    if (secondsUntilNextCheck === 0) {
      return <div className="text-muted">Checking...</div>;
    }
    return <div className="text-muted">Checking in {secondsUntilNextCheck} seconds.</div>;
  };

  return (
    <ReactBSAlert
      title="Authorization Required"
      closeOnClickOutside={false}
      customButtons={renderButtons()}
      onConfirm={noop}
      style={{
        width: '60em',
      }}
    >
      <Spinner />
      <br />
      <div className="mt-2">{renderProgress()}</div>
      <br />
      {text}
    </ReactBSAlert>
  );
};

PaymentAuthorizationAlert.defaultProps = {
  hideCancel: false,
  text: '',
};

export default PaymentAuthorizationAlert;
