import { useMutation, useQueryClient } from '@tanstack/react-query';

import { emailTemplateQueryKeys } from './emailTemplateQueryKeys';

export function useEmailTemplateEdit({
  api,
}: {
  api: {
    emailTemplateApi: {
      update: (arg0: number, arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      emailTemplateId,
      patchedFields,
    }: {
      emailTemplateId: number;
      patchedFields: { [k: string]: any };
    }) => api.emailTemplateApi.update(emailTemplateId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: emailTemplateQueryKeys.all() });
    },
  });
  return mutation;
}
