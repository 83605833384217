import {
  useMembershipEndingReport,
  useMembershipEndingReportCsv,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv } from "@gymflow/helpers";
import { ReportMembershipEndingLineDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import {
  MembershipEndingFilters,
  MembershipEndingFilterSidebar,
} from "../../components/templates";
import { getFilterAppliedCount } from "../../helpers";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { UserMemberPage } from "../UserMember";

export function MembershipEnding() {
  const { createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([
    { id: "membershipType", desc: true },
  ]);
  const [filters, setFilters] = useState<MembershipEndingFilters>({});

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };

  const { data, isFetching } = useMembershipEndingReport({
    api,
    startDate,
    endDate,
    sort: createSort(),
    limit: pageSize,
    page: currentPage,
    membershipIncluded:
      filters?.membershipIncluded?.map(({ id }) => id) || undefined,
    membershipExcluded:
      filters?.membershipExcluded?.map(({ id }) => id) || undefined,
    membershipType: filters.membershipType?.value ?? undefined,
    tz: settings.timezone,
  });
  const downloadCsvMutation = useMembershipEndingReportCsv({
    api,
    tz: settings.timezone,
  });

  useEffect(() => {
    if (searchParams?.["startDate"]) {
      setStartDate(
        moment(searchParams["startDate"] as string, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
      );
    }
    if (searchParams?.["endDate"]) {
      setEndDate(
        moment(searchParams["endDate"] as string, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      );
    }
  }, [searchParams]);

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);
  const columnHelper = createColumnHelper<ReportMembershipEndingLineDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.userMemberId,
        };
      },
      {
        id: "firstName",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton
                to={createMemberLink(
                  info.getValue().id,
                  UserMemberPage.Account,
                )}
              >
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div>Name</div>;
        },
        enableSorting: false,
        footer: () => <div>Total: {data?.totalElements}</div>,
      },
    ),
    columnHelper.accessor("membershipName", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Membership Name",
      enableSorting: false,
    }),
    columnHelper.accessor("membershipType", {
      cell: (info) => (
        <div className="capitalize text-gray-600">
          {info.getValue()?.toLowerCase().replace("_", " ")}
        </div>
      ),
      header: "Membership Type",
      enableSorting: false,
    }),
    columnHelper.accessor("endDate", {
      cell: (info) => {
        const endDate = info.getValue();
        return endDate ? (
          <div className="text-gray-600">
            {moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              settings.date_format,
            )}
          </div>
        ) : null;
      },
      header: "End Date",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        history.push({
          search: qs.stringify({
            startDate: newDate.startDate,
            endDate: newDate.endDate,
            backRoute: searchParams["backRoute"],
          }),
        });
      }
    },
    [history, searchParams],
  );

  return (
    <>
      <MembershipEndingFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={setFilters}
      />
      <Report
        title="Memberships Ending"
        description="List of all recurring and pre-paid memberships that have either a cancellation date or expiry date within the date range specified."
        onDownloadClick={async () => {
          const data = await downloadCsvMutation.mutateAsync({
            startDate: startDate!,
            endDate: endDate!,

            membershipIncluded:
              filters?.membershipIncluded?.map(({ id }) => id) || undefined,
            membershipExcluded:
              filters?.membershipExcluded?.map(({ id }) => id) || undefined,
            membershipType: filters.membershipType?.value ?? undefined,
          });
          downloadCsv(data, `MembershipsEnding.csv`);
        }}
        showFilters={() => setFiltersAreVisible(true)}
        filtersAppliedCount={getFilterAppliedCount(filters)}
        handleDateRangeChange={handleDateRangeChange}
        dateRange={
          startDate && endDate
            ? {
                startDate: moment(
                  startDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
                endDate: moment(
                  endDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
              }
            : undefined
        }
        close={() => {
          history.push({
            pathname: searchParams["backRoute"] as string,
            search: qs.stringify({
              dates: {
                startDate: searchParams["startDate"],
                endDate: searchParams["endDate"],
              },
            }),
          });
        }}
        table={
          <>
            <Table
              data={data?.content ?? []}
              columns={columnsDefinition}
              pageCount={data?.totalPages}
              pageIndex={data?.number}
              onSortingChange={setCurrentSort}
              sort={currentSort}
              isFetching={isFetching}
              pageSize={pageSize}
              rowClassName="h-14"
            />
            <div className="border-t-[0.063rem] border-gray-300">
              <ReportPagination
                pageCount={data?.totalPages as number}
                currentPage={data?.number as number}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                pageSize={pageSize}
                setPageSize={(newValue) => {
                  setPageSize(newValue);
                  setCurrentPage(0);
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
}
