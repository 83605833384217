import styled from "styled-components";

/**
 * @deprecated
 */
const MediumText = styled.span`
  font-size: ${({ theme }) => theme.text.medium};
`;

export default MediumText;
