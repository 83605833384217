import { Iso2Country } from "@gymflow/types";

const postalCodeCountryPattern: { [key in Iso2Country]: RegExp } = {
  GB: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i,
  US: /^[0-9]{5}(?:-[0-9]{4})?$/i,
  AU: /^\d{4}$/,
  NO: /^\d{4}$/,
  NZ: /^\d{4}$/,
  ES: /^(\d{5})$/,
  GI: /^([0-9a-zA-Z\- ]{1,23})$/i,
  CA: /^[A-Z0-9]{3}([ -]{0,1}[A-Z0-9]{3})?$/i,
  IE: /^([A-Z][0-9]{2}|D6W)([ -][0-9A-Z]{4})?$/i,
  CY: /^\d{4}$/,
  FR: /^\d{5}$/,
  NL: /^[1-9][0-9]{3}\s?[A-Z]{2}$/,
};

export const validatePostCode = (postCode: string, country: Iso2Country) => {
  const pattern = postalCodeCountryPattern[country];
  if (pattern) {
    return postCode && new RegExp(pattern).test(postCode.trim());
  }
  throw new Error("Invalid post code country.");
};
