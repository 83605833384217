import { useMutation, useQueryClient } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export function useFacilityEnable({ api }: {
  api: {
    facilityApi:
    {
      activate: (arg0: number) => Promise<any>
    }
  }
}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (facilityId: number) => api.facilityApi.activate(facilityId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: facilityQueryKeys.all() })
    }
  });

  return mutation
}
