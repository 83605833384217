export const Verb = {
  View: "View",
  Credit: "Credit",
  Pay: "Pay",
  Collect: "Collect",
  Refund: "Refund",
  WriteOff: "WriteOff",
  Download: "Download",
  Create: "Create",
  Edit: "Edit"
} as const;

export type VerbType = keyof typeof Verb;
