import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";

import { Button } from "../atoms";

export type HostedPagesBackHeaderProps = {
  label: string;
};

export const HostedPagesBackHeader = ({
  label,
}: HostedPagesBackHeaderProps) => {
  const navigation = useHistory();
  return (
    <div className="flex h-fit w-full flex-row items-center gap-4 border-b border-gray-300 bg-[#ffffff] p-4 dark:border-gray-700 dark:bg-gray-950 lg:px-28">
      <Button
        intent="transparent"
        className="min-h-0 min-w-0 p-1"
        onClick={() => {
          navigation.goBack();
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} className="h-5 w-5 text-gray-500" />
      </Button>
      <div className="text-xl font-medium text-gray-950 dark:text-white">
        {label}
      </div>
    </div>
  );
};
