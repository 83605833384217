import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export function useMutationMigrationCardPaymentMethod(
  {
    api,
  }: {
    api: {
      public: {
        hostedClubApi: {
          invitationCardPaymentMethod: ({
            invitationToken,
            paymentMethodId,
          }: {
            invitationToken: string;
            paymentMethodId: string;
          }) => Promise<{ data: void }>;
        };
      };
    };
  },

  mutationOpts?: UseMutationOptions<
    void,
    unknown,
    { invitationToken: string; paymentMethodId: string }
  >,
) {
  return useMutation({
    mutationFn: async ({
      invitationToken,
      paymentMethodId,
    }: {
      invitationToken: string;
      paymentMethodId: string;
    }) => {
      await api.public.hostedClubApi.invitationCardPaymentMethod({
        invitationToken,
        paymentMethodId,
      });
    },

    ...mutationOpts,
  });
}
