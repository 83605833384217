import { useQueryClient, useMutation } from '@tanstack/react-query';
import { UserStaffBean } from '@gymflow/types';

import { staffQueryKeys } from './staffQueryKeys';

export function useStaffDisable({
  api,
}: {
  api: {
    staffApi: {
      deactivate: (arg0: string) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (staffId: string) => {
      const response = await api.staffApi.deactivate(staffId);
      return response.data as UserStaffBean;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: staffQueryKeys.all() });
    },
  });

  return mutation;
}
