import useGymflowModels from "../store";

/**
 * @deprecated useCreditPackList instead
 */
const useSessionPacks = () => {
  const { CreditPackStore } = useGymflowModels();
  const { fetchList, fetchCategories } = CreditPackStore.useStoreActions(
    (actions) => actions,
  );
  const {
    page,
    pageCount,
    editing,
    rows,
    categories,
    loadingList,
    loadingRecord,
  } = CreditPackStore.useStoreState((state) => state);

  return {
    fetchList,
    fetchCategories,
    page,
    pageCount,
    editing,
    rows,
    loadingRecord,
    loadingList,
    categories,
  };
};

export default useSessionPacks;
