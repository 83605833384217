import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { downloadCsv } from "@gymflow/helpers";
import { useMutation } from "@tanstack/react-query";

import {
  generateMemberListFilter,
  UserMemberListFilter,
} from "./useInfiniteQueryMemberList";

export function useMutationMemberListCsv({
  api,
  tz,
}: {
  api: ApiType;
  tz: string;
}) {
  return useMutation({
    mutationFn: async (filters?: UserMemberListFilter) => {
      return downloadCsv(
        (
          await api.memberApi.listNewCsv({
            ...generateMemberListFilter(tz, filters),
          })
        ).data,
        "users.csv",
      );
    },
  });
}
