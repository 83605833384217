import {
  useCheckInMutation,
  useCheckInQuery,
  useCheckOutMutation,
  useMember,
} from "@gymflow/api";
import capitalize from "lodash/capitalize";
import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmModal } from "../components/templates";
import { ModalContext, useClubSettings } from "../providers";
import useGymflowModels from "../store";
import { usePortalRoutes } from "./usePortalRoutes";

export function useAccessToggleButton({
  userMemberId,
  onCheckInOutFinished,
}: {
  userMemberId?: string;
  onCheckInOutFinished?: (isCheckedIn: boolean) => void;
}) {
  const { api } = useGymflowModels();
  const { data: checkInContainer } = useCheckInQuery({ api, userMemberId });
  const { setModal, hide } = useContext(ModalContext);
  const history = useHistory();
  const { createMemberLink } = usePortalRoutes();

  const {
    mutateAsync: checkIn,
    isSuccess: didCheckIn,
    isLoading: isLoadingCheckIn,
  } = useCheckInMutation(
    {
      api,
    },
    {
      onSuccess: () => onCheckInOutFinished?.(true),
    },
  );
  const {
    mutateAsync: checkOut,
    isSuccess: didCheckOut,
    isLoading: isLoadingCheckOut,
  } = useCheckOutMutation(
    { api },
    {
      onSuccess: () => onCheckInOutFinished?.(false),
    },
  );
  const checkedInOrOut = didCheckIn || didCheckOut;

  const { timezone } = useClubSettings();
  const { data: userContainer } = useMember({
    api,
    memberId: userMemberId,
    tz: timezone,
  });

  const userMember = userContainer?.user;
  const userActiveSubscription = userMember?.subscriptions?.find(
    (e) => e.active && e.membershipBean,
  );

  const toggle = useCallback(async () => {
    if (isLoadingCheckIn || isLoadingCheckOut || !userMember) return;
    if (!checkInContainer?.isCheckedIn) {
      if (!userActiveSubscription) {
        setModal(
          <ConfirmModal
            title="No Membership"
            cancelText="View"
            confirmText="Yes"
            onCancel={() => {
              hide();
              history.push(createMemberLink(userMember.id));
            }}
            onConfirm={async () => {
              await checkIn({
                userMemberId: userMember.id,
                payload: { guest: true },
              });
              hide();
            }}
          >
            {userMember.firstName} {userMember.lastName} doesn’t have a
            membership, do you want to check -in as a guest ?
          </ConfirmModal>,
        );
        return;
      }

      if (userActiveSubscription.status !== "ACTIVE") {
        setModal(
          <ConfirmModal
            title="No Membership"
            cancelText="View"
            confirmText="Yes"
            onCancel={() => {
              hide();
              history.push(createMemberLink(userMember.id));
            }}
            onConfirm={async () => {
              await checkIn({
                userMemberId: userMember.id,
                payload: { guest: true },
              });
              hide();
            }}
            type="danger"
          >
            {userMember.firstName} {userMember.lastName} subscription is{" "}
            <b>{capitalize(userActiveSubscription.status)} </b> do you still
            want to check in?
          </ConfirmModal>,
        );
        return;
      }
    }
    if (checkInContainer?.isCheckedIn) {
      await checkOut({ userMemberId: userMember.id });
    } else {
      await checkIn({
        userMemberId: userMember.id,
        payload: { guest: false },
      });
    }
  }, [
    checkIn,
    checkInContainer?.isCheckedIn,
    checkOut,
    createMemberLink,
    hide,
    history,
    isLoadingCheckIn,
    isLoadingCheckOut,
    setModal,
    userActiveSubscription,
    userMember,
  ]);

  return {
    toggle,
    isReady: !!userMember && !!checkInContainer,
    checkedInOrOut,
    isCheckedIn: checkInContainer?.isCheckedIn,
    didCheckIn,
    didCheckOut,
  };
}
