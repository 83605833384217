import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import { ReactNode } from "react";

import { ModalWrapper } from "../../providers";
import { Button, DangerIcon } from "../atoms";

export type ConfirmModalType = "danger" | "warning" | "default";

export function ConfirmModal({
  title,
  onHide,
  onCancel,
  onConfirm,
  children,
  type,
  cancelText,
  confirmText,
  isConfirmDisabled,
}: {
  title: string;
  onHide?: () => void;
  onCancel: () => void;
  onConfirm?: () => void;
  children: ReactNode;
  type?: ConfirmModalType;
  cancelText?: ReactNode;
  confirmText: ReactNode;
  isConfirmDisabled?: boolean;
}) {
  const renderIcon = () => {
    return (
      <div
        className={cn(
          "flex h-11 w-11 items-center justify-center  self-center rounded-full",
          (
            {
              default: "bg-secondary-50",
              danger: "bg-error-50",
              warning: "bg-warning-50",
            } as { [key in ConfirmModalType]: string }
          )[type ?? "default"],
        )}
      >
        <div
          className={cn(
            "flex h-8 w-8 items-center justify-center self-center rounded-full",
            (
              {
                default: "bg-secondary-100",
                danger: "bg-error-100",
                warning: "bg-warning-100",
              } as { [key in ConfirmModalType]: string }
            )[type ?? "default"],
          )}
        >
          <DangerIcon
            className="h-5 w-5"
            pathClassName={
              (
                {
                  default: "stroke-secondary-600",
                  danger: "stroke-error-600",
                  warning: "stroke-warning-600",
                } as { [key in ConfirmModalType]: string }
              )[type ?? "default"]
            }
          />
        </div>
      </div>
    );
  };

  const renderConfirmButton = () => {
    switch (type) {
      case "danger":
        return (
          <Button
            intent="danger"
            disabled={isConfirmDisabled}
            className={classNames("ml-4 flex-1 mt-2", {
              hidden: !onConfirm,
            })}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        );
      case "warning":
        return (
          <Button
            intent="warning"
            disabled={isConfirmDisabled}
            className={classNames("ml-4 flex-1 mt-2", {
              hidden: !onConfirm,
            })}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        );
      case "default":
      default:
        return (
          <Button
            intent="secondary"
            disabled={isConfirmDisabled}
            className={classNames("ml-4 flex-1 mt-2", {
              hidden: !onConfirm,
            })}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        );
    }
  };

  return (
    <ModalWrapper onCancel={onHide ?? onCancel}>
      {renderIcon()}
      <div className="text-lg font-semibold text-gray-900">{title}</div>
      <div className="mt-2 text-sm text-gray-600">{children}</div>
      <div className="mt-5 flex">
        <Button
          onClick={onCancel}
          className={classNames("flex-1 mt-2", {
            hidden: !onCancel,
          })}
        >
          {cancelText ?? "Cancel"}
        </Button>
        {renderConfirmButton()}
      </div>
    </ModalWrapper>
  );
}

ConfirmModal.defaultProps = {
  type: "default",
  confirmText: "Confirm",
};
