import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { SubscriptionPromotionSummary } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useQueryAddPromotionCodeToSubscriptionSummary(
  {
    api,
    subscriptionId,
    promotionCode,
  }: {
    api: ApiType;
    subscriptionId?: number;
    promotionCode?: string;
  },
  queryOpts?: UseQueryOptions<unknown, unknown, SubscriptionPromotionSummary>,
) {
  return useQuery({
    queryKey: memberQueryKeys.addPromotionCodeToSubscription({
      subscriptionId,
      promotionCode,
    }),
    queryFn: async () => {
      const response =
        await api.memberApi.addPromotionCodeToSubscriptionSummary({
          subscriptionId: subscriptionId as number,
          promotionCode: promotionCode as string,
        });
      return response.data;
    },
    enabled: !!subscriptionId && !!promotionCode,
    ...queryOpts,
  });
}

