const formik = ({ errors, touched, dirty, isValid, submitCount }) => {
  const submitDisabled = !dirty || (submitCount > 0 && !isValid);

  return {
    errorClass: getErrorClass({ errors, touched }),
    submitDisabled,
  };
};

export default formik;

export const getErrorClass = ({ errors, touched }) => {
  return (...fieldNames) => {
    return fieldNames.some((f) => touched[f] && errors[f]) ? 'has-danger' : '';
  };
};
