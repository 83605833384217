import { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { Card, CardHeader, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import MediumText from '../atoms/MediumText';

function AccordionWizard({ steps, activeStep, onStepTitleClick }) {
  return (
    <Container fluid className="pr-0 pl-0">
      {steps.map(({ icon, title, component: StepComponent, props, contentInsideTitleCard }, idx) => {
        const canRenderStep = idx === activeStep;
        const isPreviousStep = idx < activeStep;
        return (
          <Fragment key={idx}>
            <Row className={classNames({ 'mt-3': idx !== 0 })}>
              <Col>
                <Card>
                  <CardHeader
                    style={{
                      minHeight: '60px',
                      cursor: isPreviousStep && onStepTitleClick ? 'pointer' : 'default',
                    }}
                    className="d-flex justify-content-between pb-3 pb-sm-0"
                    onClick={isPreviousStep ? () => onStepTitleClick(idx) : noop}
                  >
                    <div>
                      {icon}
                      <MediumText className="ml-2">{title}</MediumText>
                    </div>
                    <div className={classNames({ 'd-none': idx >= activeStep })}>
                      <FontAwesomeIcon style={{ fontSize: '20px' }} color="green" icon={faCheckCircle} />
                    </div>
                  </CardHeader>
                  {StepComponent && contentInsideTitleCard && canRenderStep && (
                    <div className={classNames({ hidden: activeStep !== idx })}>
                      <StepComponent {...props} />
                    </div>
                  )}
                </Card>
              </Col>
            </Row>

            {StepComponent && !contentInsideTitleCard && canRenderStep && (
              <div className={classNames('mt-3', { hidden: activeStep !== idx })}>
                <StepComponent {...props} />
              </div>
            )}
          </Fragment>
        );
      })}
    </Container>
  );
}

AccordionWizard.defaultProps = {
  activeStep: 0,
  onStepTitleClick: noop,
};

AccordionWizard.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      icon: PropTypes.node.isRequired,
      component: PropTypes.elementType,
      props: PropTypes.object,
      contentInsideTitleCard: PropTypes.bool,
    }),
  ).isRequired,
  activeStep: PropTypes.number,
  onStepTitleClick: PropTypes.func,
};

export default AccordionWizard;
