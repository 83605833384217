import {
  clubStaleTime,
  useClubHostedPagesSettingsAsPublic,
} from "@gymflow/api";
import { useCallback } from "react";
import { useHistory } from "react-router";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export function useRedirectUrl() {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );
  const history = useHistory();

  const redirectToCustomizedWebsiteOr = useCallback(
    (alternativeInternalUrl?: string) => {
      if (hostedPagesSettings?.settings?.redirectUrl) {
        window.location.assign(hostedPagesSettings?.settings?.redirectUrl);
        return { result: "REDIRECTED_TO_CUSTOMIZED_REDIRECT_URL" } as const;
      } else if (hostedPagesSettings?.settings?.yourWebsiteUrl) {
        window.location.assign(hostedPagesSettings?.settings?.yourWebsiteUrl);
        return { result: "REDIRECTED_TO_YOUR_WEBSITE_URL" } as const;
      } else if (alternativeInternalUrl) {
        history.push(alternativeInternalUrl);
        return { result: "REDIRECTED_TO_ALTERNATIVE_INTERNAL_URL" } as const;
      }
      return { result: "NOT_REDIRECT" } as const;
    },
    [history, hostedPagesSettings],
  );
  return { redirectToCustomizedWebsiteOr };
}
