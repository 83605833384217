const fieldNames = {
  CARD_HOLDERS_NAME: 'card-holders-name',
  ACCEPT_TOC: 'accept-toc',
  NEW_PAYMENT_METHOD: 'new-payment-method',
  PAYMENT_METHOD: 'payment-method',
  START_DATE: 'start-date',
  PAYMENT_METHOD_TYPE: 'payment-method-type',
  PROMO_CODE: 'promo-code',
};

export default fieldNames;
