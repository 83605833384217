/* eslint-disable react/jsx-props-no-spreading */
import { ServiceType, useUserFormFieldConfiguration } from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import { useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { useClubSettings } from "../../providers";
import { BuyMembershipSubRoute } from "../../routes/site/buyMembershipSubRoute";
import useGymflowModels from "../../store";
import BuyMembershipAccordion from "./BuyMembershipAccordion";

function BuyMembership() {
  const settings = useClubSettings();
  const { api, settingsStore } = useGymflowModels();

  const dateFormat = settings.date_format;
  const { defaultCurrency: currency, timezone } = useStoreState(settingsStore);
  const history = useHistory();

  const fetchLeadSources = useCallback(async () => {
    const { data: result } = await api.public.leadApi.findLeadSources({
      extraParams: { active: true },
    });
    return { data: { content: result, last: true } };
  }, [api.public.leadApi]);

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId: settings.clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const match = useRouteMatch();

  return (
    <div className="track-height mx-5">
      <Switch>
        <Route path={match.path + BuyMembershipSubRoute.RecurringMembership}>
          <BuyMembershipAccordion
            membershipType={ServiceType.Recurring}
            currency={currency}
            fetchLeadSources={fetchLeadSources}
            dateFormat={dateFormat}
            summary={api.public.serviceApi.summary}
            timezone={timezone}
            requiredFields={requiredFields}
          />
        </Route>
        <Route path={match.path + BuyMembershipSubRoute.PrepaidMembership}>
          <BuyMembershipAccordion
            membershipType={ServiceType.Prepaid}
            currency={currency}
            fetchLeadSources={fetchLeadSources}
            dateFormat={dateFormat}
            summary={api.public.serviceApi.summary}
            timezone={timezone}
            requiredFields={requiredFields}
          />
        </Route>
        <Route path={match.path + BuyMembershipSubRoute.Any}>
          <BuyMembershipAccordion
            currency={currency}
            fetchLeadSources={fetchLeadSources}
            dateFormat={dateFormat}
            summary={api.public.serviceApi.summary}
            timezone={timezone}
            requiredFields={requiredFields}
          />
        </Route>
        <Route path={`${match.path}/:id`}>
          {({ match: innerMatch }) => {
            const membershipId = innerMatch.params.id;
            api.public.serviceApi
              .findById(membershipId)
              .then(({ data: { type } }) => {
                if (type === ServiceType.Recurring) {
                  history.push({
                    pathname: `${match.path}${BuyMembershipSubRoute.RecurringMembership}`,
                    search: `?membershipId=${membershipId}`,
                  });
                } else if (type === ServiceType.Prepaid) {
                  history.push({
                    pathname: `${match.path}${BuyMembershipSubRoute.PrepaidMembership}`,
                    search: `?membershipId=${membershipId}`,
                  });
                }
              });

            return null;
          }}
        </Route>
        <Route>
          <Redirect
            to={match.url + BuyMembershipSubRoute.RecurringMembership}
          />
        </Route>
      </Switch>
    </div>
  );
}

BuyMembership.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
  }).isRequired,
};

function BuyMembershipWithProvider() {
  const { EventStore, ServiceStore } = useGymflowModels();
  return (
    <ServiceStore.Provider>
      <EventStore.Provider>
        <BuyMembership />
      </EventStore.Provider>
    </ServiceStore.Provider>
  );
}

export { BuyMembershipWithProvider as BuyMembership };
