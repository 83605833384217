export function BarChartIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="bar-chart-10">
        <path
          className={pathClassName}
          d="M12.5 14.1667H16.1667C16.6334 14.1667 16.8667 14.1667 17.045 14.0758C17.2018 13.9959 17.3293 13.8685 17.4092 13.7117C17.5 13.5334 17.5 13.3 17.5 12.8333V3.83333C17.5 3.36662 17.5 3.13327 17.4092 2.95501C17.3293 2.79821 17.2018 2.67072 17.045 2.59083C16.8667 2.5 16.6334 2.5 16.1667 2.5H12.5M12.5 2.5L7.5 2.5M12.5 2.5L12.5 16.1667C12.5 16.6334 12.5 16.8667 12.4092 17.045C12.3293 17.2018 12.2018 17.3293 12.045 17.4092C11.8667 17.5 11.6334 17.5 11.1667 17.5L8.83333 17.5C8.36662 17.5 8.13327 17.5 7.95501 17.4092C7.79821 17.3293 7.67072 17.2018 7.59083 17.045C7.5 16.8667 7.5 16.6334 7.5 16.1667L7.5 2.5M7.5 10.8333L3.83333 10.8333C3.36662 10.8333 3.13327 10.8333 2.95501 10.7425C2.79821 10.6626 2.67072 10.5351 2.59083 10.3783C2.5 10.2001 2.5 9.96671 2.5 9.5V3.83333C2.5 3.36662 2.5 3.13327 2.59083 2.95501C2.67072 2.79821 2.79821 2.67072 2.95501 2.59083C3.13327 2.5 3.36662 2.5 3.83333 2.5H7.5"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
