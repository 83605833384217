import endpoints from "./endpoints";
import { canCreate, canFind, canFindById, canTrimFields, canUpdate } from "./features/apiResource";

const appointableApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["name", "notes"],
    baseUrl: `${clubPrefix}${endpoints.appointable}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    {
      fetchCategories(params) {
        return state.http.put(`${clubPrefix}${endpoints.appointable}/category/search`, params);
      },

      createCategory(category) {
        const url = `${clubPrefix}${endpoints.appointable}/category`;

        return state.http.post(url, category);
      },
      disableCategory(id) {
        const url = `${clubPrefix}${endpoints.appointable}/category/:id/status`.replace(":id", id);

        return state.http.patch(url, { status: "DISABLED" });
      },
      active(id) {
        const url = `${clubPrefix}${endpoints.appointable}/:id/active`.replace(":id", id);

        return state.http.patch(url);
      },
      disable(id) {
        const url = `${clubPrefix}${endpoints.appointable}/:id/inactive`.replace(":id", id);

        return state.http.patch(url);
      },
    },
  );
};

export default appointableApi;
