import React from 'react';
import { Button } from 'reactstrap';

const LinkBox = ({ name, actionText, link, onCopy }) => {
  return (
    <div
      className="w-100 d-inline-flex p-2 justify-content-between"
      style={{ borderRadius: '4px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)' }}
    >
      <div name={name} className="d-inline-flex align-items-center text-truncate">
        {link}
      </div>
      <span className="ml-1" style={{ borderLeft: '2px solid #9d9ea2', minWidth: 80 }}>
        <Button className="btn-link px-2" onClick={onCopy}>
          {actionText}
        </Button>
      </span>
    </div>
  );
};

export default LinkBox;
