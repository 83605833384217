import { ApiListResponse, RuleDTO, RuleType } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryRuleList({
  api,
  filter,
}: {
  api: {
    ruleApi: {
      find: (arg0: { [k: string]: any }) => Promise<any>;
    };
  };
  filter?: RuleListFilter;
}) {
  const result = useQuery({
    queryKey: ruleQueryKeys.list(filter ?? {}),
    queryFn: async () => {
      const result = await api.ruleApi.find({ ...filter });
      return result.data as ApiListResponse<RuleDTO>;
    },
    initialData: defaultPage<RuleDTO>,
  });
  return result;
}

interface RuleListFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
  readonly description?: string;
  readonly id?: number[];
  readonly type?: RuleType;
}
