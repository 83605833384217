import { UserStaffBean } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { staffQueryKeys } from "./staffQueryKeys";

export async function staffQueryFn({
  api,
  staffId,
}: {
  api: StaffApi;
  staffId: string;
}) {
  const result = await api.staffApi.findById(staffId);
  return result.data as UserStaffBean;
}

export function useStaff(
  {
    api,
    staffId,
  }: {
    api: StaffApi;
    staffId?: string;
  },
  opts?: UseQueryOptions<UserStaffBean | null>,
) {
  const result = useQuery({
    queryKey: staffQueryKeys.details(staffId),
    queryFn: () => staffQueryFn({ api, staffId: staffId! }),
    enabled: !!staffId,
    initialData: null,
    ...opts,
  });

  return result;
}

interface StaffApi {
  staffApi: { findById: (arg0: string) => Promise<any> };
}
