import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationGrowMetricsApiKeysCreate,
  useMutationGrowMetricsApiKeysDelete,
  useQueryGrowMetricsApiKeys,
} from "@gymflow/api";
import { useContext, useState } from "react";
import { useHistory } from "react-router";

import { copyToClipboard } from "../../helpers";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { ModalContext } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { Button, TextInputWithButton } from "../atoms";
import { SettingsRoute } from "../Settings/SettingsRoute";
import { ConfirmModal } from "../templates";

export function GrowMetrics() {
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const { data: apiKey } = useQueryGrowMetricsApiKeys({ api });

  const { mutateAsync: createApiKey } = useMutationGrowMetricsApiKeysCreate({
    api,
  });
  const { mutateAsync: deleteApiKey } = useMutationGrowMetricsApiKeysDelete({
    api,
  });
  const [currentApiKey, setCurrentApiKey] = useState<string>();
  const { toast } = useContext(ToastContext);
  const { setModal } = useContext(ModalContext);

  return (
    <div className="flex h-full max-h-full flex-col bg-white p-5">
      <div className="flex max-h-full flex-col">
        <div className="flex justify-between">
          <div className="flex">
            <div className="bg-grow-metrics-logo h-12 w-12 rounded-sm bg-contain bg-center bg-no-repeat"></div>
            <div className="ml-4 flex items-center font-semibold">
              <div className="text-2xl">GrowMetrics</div>
            </div>
          </div>
          <div className="mr-5 flex items-center">
            <FontAwesomeIcon
              onClick={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.Settings,
                    SettingsRoute.Integrations,
                  ),
                );
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
        </div>
        <div className="py-5 text-gray-600">
          Automate Lead creation and track conversion of leads generated by Grow
          Metrics Automated Advertising platform.
        </div>
      </div>
      <div className="flex flex-col rounded-xl border border-gray-200">
        <div className="flex flex-col gap-2 p-6">
          <div className="text-sm font-semibold">API Key</div>
          <div className="flex w-full">
            <TextInputWithButton
              className="w-full"
              onButtonClick={async () => {
                if (currentApiKey) {
                  copyToClipboard(currentApiKey);
                  toast({ message: "API Key copied to clipboard." });
                } else if (!apiKey) {
                  const { apiToken } = await createApiKey();
                  setCurrentApiKey(apiToken);
                } else {
                  setModal(
                    <ConfirmModal
                      title="Regenerate API Key"
                      onCancel={() => {
                        setModal(null);
                      }}
                      onConfirm={async () => {
                        const { apiToken } = await createApiKey();
                        setCurrentApiKey(apiToken);
                        setModal(null);
                      }}
                      confirmText="Regenerate"
                      type="warning"
                    >
                      Regenerating your API key will invalidate the current key.
                      Are you sure you want to regenerate?
                    </ConfirmModal>,
                  );
                }
              }}
              buttonText={
                currentApiKey
                  ? "Copy Key"
                  : apiKey
                  ? "Regenerate API Key"
                  : "Create API Key"
              }
              value={
                apiKey
                  ? currentApiKey ?? "***************************************"
                  : ""
              }
            />
          </div>
          <div className="text-sm text-gray-600">
            You can only view your API once so ensure to copy it below closing
            this window.
          </div>
        </div>
        <div className="flex w-full border-b border-gray-200" />
        <div className="flex items-center justify-end gap-x-4 p-6">
          {apiKey && (
            <Button
              intent="danger"
              onClick={() => {
                setModal(
                  <ConfirmModal
                    title="Disable Integration"
                    onCancel={() => {
                      setModal(null);
                    }}
                    onConfirm={async () => {
                      await deleteApiKey();
                      setModal(null);
                      history.push(
                        createClubLink(
                          RouteLayout.Staff,
                          RouteFeature.Settings,
                          SettingsRoute.Integrations,
                        ),
                      );
                    }}
                    confirmText="Disable"
                    type="danger"
                  >
                    Are you sure you want to disable the GrowMetrics
                    integration?
                  </ConfirmModal>,
                );
              }}
            >
              Disable
            </Button>
          )}
          <Button
            intent="primary"
            onClick={() => {
              history.push(
                createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.Settings,
                  SettingsRoute.Integrations,
                ),
              );
            }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}
