import { assert } from 'check-types';

import endpoints from './endpoints';
import { canTrimFields, canCreate, canFind, canFindById, canUpdate } from './features/apiResource';

const emailTemplateEndpoints = {
  delete: `${endpoints.emailTemplate}/:id`,
};

const emailTemplateApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ['templateName', 'templateType', 'subject', 'body'],
    baseUrl: `${clubPrefix}${endpoints.emailTemplate}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    {
      delete(id) {
        assert.number(id, 'id must be a number');

        const url = `${clubPrefix}${emailTemplateEndpoints.delete}`.replace(':id', id);
        return state.http.delete(url);
      },
    }
  );
};

export default emailTemplateApi;
