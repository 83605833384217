import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

type RadioButtonProps<T extends string | number> = {
  value: T;
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  name?: string;
  label?: ReactNode;
  isDisabled?: boolean;
  containerClassName?: string;
};

export const RadioButton = <T extends string | number>({
  checked,
  label,
  isDisabled,
  containerClassName,
  ...rest
}: RadioButtonProps<T>) => (
  <div className={cn("flex items-center gap-3", containerClassName)}>
    <div className="relative h-5 w-5">
      <input
        type="radio"
        checked={checked}
        className="h-5 w-5 cursor-pointer"
        readOnly={isDisabled}
        disabled={isDisabled}
        {...rest}
      />
      <div
        className={cn(
          "pointer-events-none absolute left-0 top-0 flex h-5 w-5 items-center justify-center rounded-full",
          checked ? "bg-secondary-500" : "bg-gray-300",
        )}
      >
        <div
          className={cn(
            "rounded-full bg-white",
            checked ? "h-2 w-2" : "h-[1.125rem] w-[1.125rem]",
          )}
        />
      </div>
    </div>
    {label && (
      <div className="m-0 block select-none text-base font-medium text-gray-950">
        {label}
      </div>
    )}
  </div>
);
