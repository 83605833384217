import React from "react";

export interface UserCheckInProps extends React.SVGProps<SVGSVGElement> {}

export const UserCheckInIcon: React.FC<UserCheckInProps> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 16.5L18.3333 14M18.3333 14L15.8333 11.5M18.3333 14H13.3333M10 11.9167H6.25C5.08703 11.9167 4.50554 11.9167 4.03238 12.0602C2.96704 12.3834 2.13336 13.217 1.8102 14.2824C1.66666 14.7555 1.66666 15.337 1.66666 16.5M12.0833 5.25C12.0833 7.32107 10.4044 9 8.33333 9C6.26226 9 4.58333 7.32107 4.58333 5.25C4.58333 3.17893 6.26226 1.5 8.33333 1.5C10.4044 1.5 12.0833 3.17893 12.0833 5.25Z"
        stroke="#667085"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
