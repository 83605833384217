import {TokenStorage} from './TokenStorage';

export class LocalTokenStorage implements TokenStorage {
  async set(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }
  async get(key: string): Promise<string | null> {
    return localStorage.getItem(key);
  }
  async remove(key: string): Promise<void> {
    localStorage.removeItem(key);
  }
  async log(): Promise<void> {
    console.info(localStorage);
  }
}

export const LocalTokenStorageInstance = new LocalTokenStorage()