import check, { assert } from 'check-types';

import endpoints from './endpoints';
import { canActivateAndDeactivate, canFind, canFindById,canTrimFields } from './features/apiResource';

const creditPackEndpoints = {
  categoryCatalog: 'catalog/sessionpack/category',
  summary: `${endpoints.creditPack}/summary`,
};

const creditPackApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ['name'],
    baseUrl: `${clubPrefix}${endpoints.creditPack}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canActivateAndDeactivate(state),
    canFind(state),
    canFindById(state),
    {
      create(newCreditPack, termsAndConditionsBlob) {
        const formData = state.transformIntoFormData({ file: termsAndConditionsBlob, creditPack: { ...newCreditPack, clubIdList: [clubId] } });

        return state.http.post(state.baseUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },

      update(id, { patchedFields, termsAndConditionsBlob }) {
        assert(check.any([check.string(id), check.number(id)]), 'id must be number or string');
        const formData = state.transformIntoFormData({ file: termsAndConditionsBlob, creditPack: patchedFields });

        return state.http.patch(`${state.baseUrl}/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      },

      transformIntoFormData({ file, creditPack }) {
        const formData = new FormData();
        const trimmedData = state.trimFields(creditPack);
        if (file) {
          formData.append('file', file, file.name);
        }

        if (creditPack) {
          formData.append(
            'session_pack',
            new Blob([JSON.stringify(trimmedData)], {
              type: 'application/json',
            })
          );
        }

        return formData;
      },

      findByClubId(clubId, { page, limit, extraParams, sort, status }) {
        assert.number(clubId, 'clubId must be a number');
        const findExtraParams = {
          ...extraParams,
          'clubList.id': clubId,
        };

        if (status) {
          findExtraParams.status = status;
        }

        return state.find({
          page,
          limit,
          sort,
          extraParams: findExtraParams,
        });
      },

      fetchCategories() {
        return state.http.get(`${clubPrefix}${creditPackEndpoints.categoryCatalog}`);
      },

      summary({ creditPackId, promotionCode, userMemberId }) {
        assert.number(creditPackId, 'creditPackId must be a number');
        assert.maybe.string(promotionCode, 'promotion code must be a string');
        assert.maybe.string(userMemberId, 'userMemberId must be a string');

        return state.http.post(`${clubPrefix}${creditPackEndpoints.summary}`, {
          sessionPackId: creditPackId,
          promotionCode,
          userMemberId,
        });
      },
    }
  );
};

export default creditPackApi;
