import { useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { Spinner } from '@gymflow/common';

import FilterableList from '../../../forms/FilterableList';
import useStaff from '../../../../hooks/useStaff';

function Step3Timetable({ value, onChange }) {
  const { fetchList: fetchStaff, rows: staffMembers, loadingList: isLoading } = useStaff();

  useEffect(() => {
    fetchStaff({
      limit: 1000,
    });
  }, []);

  const options = useMemo(
    () => staffMembers.map(({ firstName, lastName, id }) => ({ label: `${firstName} ${lastName}`, value: id })),
    [staffMembers]
  );

  return (
    <>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div className="w-100 text-center" style={{ opacity: isLoading ? '0.2' : '1' }}>
        <b className="h2">Select which events to display by hosts</b>
        <Row className="mt-4 text-left d-flex justify-content-center">
          <Col md={8}>
            <p className="text-uppercase text-muted">Choose Hosts</p>
            <p style={{ color: '#ec250d' }}>Selecting no-one will display all previously selected events</p>
            <FilterableList options={options} value={value} onChange={onChange} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Step3Timetable;
