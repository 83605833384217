import { cn } from "@gymflow/helpers";
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

import { ChevronDownIcon, CloseIcon } from "../icons";
import { OptionType } from "./components/OptionsList/components/OptionItem";
import { OptionsList } from "./components/OptionsList/OptionsList";
import { OptionsListProps, OptionsType } from "./components/OptionsList/types";
import { SelectedOptionBadge } from "./components/SelectedOptionBadge";
import { SelectedOptionItem } from "./components/SelectedOptionItem";
import { Input, InputProps } from "./Input";

export type SelectInputOptionType<T> = OptionType<T>;

export type SelectInputOptionsType<T> = OptionsType<T>;

export type SelectInputProps<T> = ({
  value: SelectInputOptionsType<T>;
  onChange: (selected: SelectInputOptionsType<T>) => void;
  isMulti?: boolean;
  withImageByDefault?: boolean;
} & (
  | { options: SelectInputOptionsType<T> }
  | {
      dataFetchingQuery: () => UseInfiniteQueryOptions<
        unknown,
        unknown,
        SelectInputOptionsType<T>
      >;
    }
)) &
  Pick<InputProps, "label" | "placeholder" | "className"> &
  Pick<OptionsListProps<T>, "notFound" | "notFoundClassName" | "dropPosition">;

export const SelectInput = <T,>({
  isMulti = false,
  value,
  onChange,
  dropPosition = "bottom",
  placeholder = "Select",
  withImageByDefault = false,
  notFoundClassName,
  notFound,
  label,
  className,
  ...rest
}: SelectInputProps<T>) => {
  const dataFetchingQuery =
    "dataFetchingQuery" in rest ? rest.dataFetchingQuery : undefined;
  const options = "options" in rest ? rest.options : undefined;

  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const queryOptions = dataFetchingQuery
    ? dataFetchingQuery()
    : { queryKey: [], enabled: false };
  const { data, hasNextPage, isFetching, fetchNextPage } = useInfiniteQuery({
    ...queryOptions,
  });

  useOnClickOutside(containerRef, () => setIsOpen(false));

  const paginatedOptions = useMemo(() => {
    const fetchedOptions = data?.pages.flatMap((page) => page) || [];
    return [...(options || []), ...fetchedOptions];
  }, [data, options]);

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    if (isFetching || !hasNextPage || !isOpen) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollTop + clientHeight >= scrollHeight - 20) {
      fetchNextPage();
    }
  };

  const handleSelectOption = useCallback(
    (option: SelectInputOptionType<T>) => {
      if (isMulti) {
        inputRef.current?.focus();
        const isAlreadySelected = value.some((v) => v.id === option.id);
        const updatedValue = isAlreadySelected
          ? value.filter((v) => v.id !== option.id)
          : [...value, option];

        return onChange(updatedValue);
      }

      onChange([option]);
      setIsOpen(false);
    },
    [isMulti, onChange, value],
  );

  const onClear = useCallback(() => {
    isOpen && inputRef.current?.focus();
    onChange([]);
  }, [isOpen, onChange]);

  const isSelectedAndMulti = isMulti && value.length > 0;
  const isSelectedSingleValue = Boolean(!isMulti && value[0]);

  return (
    <div className="relative">
      <div ref={containerRef}>
        <Input
          ref={inputRef}
          value="" // it's ok, we don't need it
          onChange={() => {}} // it's ok, we don't need it
          onFocus={() => setIsOpen(true)}
          label={label}
          className={className}
          placeholder={isSelectedSingleValue ? "" : placeholder}
          readOnly
          renderOverInput={
            <>
              <div className="absolute inset-y-0 right-3.5 flex items-center gap-1.5">
                {isSelectedAndMulti && (
                  <SelectedOptionBadge value={value.length} onClick={onClear} />
                )}
                {isSelectedSingleValue && (
                  <div onClick={onClear}>
                    <CloseIcon className="h-4 w-4 cursor-pointer" />
                  </div>
                )}
                <div
                  onClick={() => {
                    !isOpen && inputRef.current?.focus();
                    setIsOpen(!isOpen);
                  }}
                >
                  <ChevronDownIcon
                    className={cn("cursor-pointer", { "rotate-180": isOpen })}
                  />
                </div>
              </div>
              <SelectedOptionItem
                show={isSelectedSingleValue}
                label={value[0]?.label}
                subLabel={value[0]?.subLabel}
                imageUrl={value[0]?.imageUrl}
                withImageByDefault={withImageByDefault}
              />
            </>
          }
        />
        <OptionsList
          show={isOpen}
          options={paginatedOptions}
          value={value}
          handleSelectOption={handleSelectOption}
          dropPosition={dropPosition}
          withImageByDefault={withImageByDefault}
          notFoundClassName={notFoundClassName}
          notFound={notFound}
          onScroll={handleScroll}
          loading={isFetching}
        />
      </div>
      {isSelectedAndMulti && (
        <div className="mt-2 flex flex-wrap items-center gap-2">
          {value.map(({ id, label }) => (
            <SelectedOptionBadge
              key={id}
              value={label}
              onClick={() => onChange(value.filter((v) => v.id !== id))}
            />
          ))}
        </div>
      )}
    </div>
  );
};
