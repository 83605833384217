import { useContext } from "react";

import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportMembersGrowthWidget = () => {
  const data = useContext(ReportContext);

  return (
    <ReportWidgetCard
      title="Membership Growth"
      value={data.members?.growth?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Memberships sold minus memberships ending within the period selected."
      variation={data.members?.growth?.variation}
    />
  );
};
