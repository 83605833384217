export type SearchIconProps = {
  className?: string;
  pathClassName?: string;
};

export const SearchIcon = ({ className, pathClassName }: SearchIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      className={className}
    >
      <path
        d="M21.5 21L18.0001 17.5M20.5 11.5C20.5 16.1944 16.6944 20 12 20C7.30558 20 3.5 16.1944 3.5 11.5C3.5 6.80558 7.30558 3 12 3C16.6944 3 20.5 6.80558 20.5 11.5Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};
