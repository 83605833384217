import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../../hooks/usePortalRoutes";
import { RouteFeature } from "../../../../routes/feature";
import { RouteLayout } from "../../../../routes/layout";
import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportLeadsClosedWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const data = useContext(ReportContext);
  const history = useHistory();

  return (
    <ReportWidgetCard
      title="Deals Closed"
      value={data.leads?.closed?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Sum of all leads that were created in the selected period that are in a Deal Closed status."
      variation={data.leads?.closed?.variation}
      onClick={() => {
        history.push({
          pathname: createClubLink(RouteLayout.Staff, RouteFeature.ClosedLeads),
          search: QueryString.stringify({
            dates: { startDate: data.startDate, endDate: data.endDate },
            backRoute: parentRoute,
          }),
        });
      }}
    />
  );
};
