import { formatCurrency, pluralize } from "@gymflow/helpers";
import { SessionPackDTO } from "@gymflow/types";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { RouteFeature } from "apps/portal/src/routes";
import React, { useContext } from "react";
import { useHistory } from "react-router";

import { Button, ClockIcon, CreditsIcon, InfinityIcon } from "../../atoms";
import { HostedPagesCard } from "../HostedPagesCard";

export type HostedPagesCreditPackCardProps = {
  creditPack: SessionPackDTO;
};

export const HostedPagesCreditPackCard: React.FC<
  HostedPagesCreditPackCardProps
> = ({ creditPack }) => {
  const history = useHistory();
  const auth = useAuthenticatedUser();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const settings = useClubSettings();
  const { createSiteOrEmbedLink } = usePortalRoutes();
  return (
    <HostedPagesCard.Container>
      <HostedPagesCard.Header>
        <div className="flex flex-row items-start justify-between gap-4">
          <div className="flex-wrap font-bold text-gray-950 dark:text-white">
            {creditPack.name}
          </div>
          <div className="flex flex-row gap-2">
            {creditPack.sessionsUnlimited ? (
              <InfinityIcon className="dark:stroke-darkGray-50 stroke-gray-950" />
            ) : (
              <div className="font-bold text-gray-950 dark:text-white">
                {creditPack.sessionsIncluded}
              </div>
            )}
            <CreditsIcon pathClassName="stroke-secondary-500" />
          </div>
        </div>
        <HostedPagesCard.Description description={creditPack.description} />
      </HostedPagesCard.Header>
      <HostedPagesCard.Separator />
      <HostedPagesCard.Footer>
        <HostedPagesCard.Price
          price={formatCurrency(creditPack.price, settings.defaultCurrency)}
          description={
            <>
              <ClockIcon />
              {creditPack.expiryType === "NA"
                ? "No expiry"
                : creditPack.expiryType === "WEEKLY"
                ? `${creditPack.expiryValue} ${pluralize(
                    "Week",
                    "Weeks",
                    1,
                  )} Expiry`
                : `${creditPack.expiryValue} ${pluralize(
                    "Month",
                    "Months",
                    1,
                  )} Expiry`}
            </>
          }
        />

        {auth.id ? (
          <Button
            link={createSiteOrEmbedLink(
              RouteFeature.SiteBuySessionPackIdCheckout.replace(
                ":id",
                creditPack.id.toString(),
              ),
            )}
            intent="secondary"
            className="w-20"
          >
            Buy
          </Button>
        ) : (
          <Button
            onClick={async () => {
              const { isLoggedIn } = await showSignUpOverlay();

              if (isLoggedIn) {
                history.push(
                  createSiteOrEmbedLink(
                    RouteFeature.SiteBuySessionPackIdCheckout.replace(
                      ":id",
                      creditPack.id.toString(),
                    ),
                  ),
                );
              }
            }}
            intent="secondary"
            className="w-20"
          >
            Buy
          </Button>
        )}
      </HostedPagesCard.Footer>
    </HostedPagesCard.Container>
  );
};
