export function MailIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={pathClassName}
          d="M1.66663 5.83333L8.47073 10.5962C9.0217 10.9819 9.29719 11.1747 9.59685 11.2494C9.86154 11.3154 10.1384 11.3154 10.4031 11.2494C10.7027 11.1747 10.9782 10.9819 11.5292 10.5962L18.3333 5.83333M5.66663 16.6667H14.3333C15.7334 16.6667 16.4335 16.6667 16.9683 16.3942C17.4387 16.1545 17.8211 15.772 18.0608 15.3016C18.3333 14.7669 18.3333 14.0668 18.3333 12.6667V7.33333C18.3333 5.9332 18.3333 5.23313 18.0608 4.69835C17.8211 4.22795 17.4387 3.8455 16.9683 3.60581C16.4335 3.33333 15.7334 3.33333 14.3333 3.33333H5.66663C4.26649 3.33333 3.56643 3.33333 3.03165 3.60581C2.56124 3.8455 2.17879 4.22795 1.93911 4.69835C1.66663 5.23313 1.66663 5.9332 1.66663 7.33333V12.6667C1.66663 14.0668 1.66663 14.7669 1.93911 15.3016C2.17879 15.772 2.56124 16.1545 3.03165 16.3942C3.56643 16.6667 4.26649 16.6667 5.66663 16.6667Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
