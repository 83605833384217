import {
  useQueryFacilityAvailabilityCalculateAsMember,
  useQueryHostAvailabilityCalculateAsMember,
} from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { cn, range } from "@gymflow/helpers";
import {
  AppointableDtoAvailabilityType,
  AvailabilityCalculationResult,
  FacilityDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import addDays from "date-fns/addDays";
import addMinutes from "date-fns/addMinutes";
import endOfDay from "date-fns/endOfDay";
import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import isSameDay from "date-fns/isSameDay";
import startOfDay from "date-fns/startOfDay";
import subDays from "date-fns/subDays";
import moment from "moment-timezone";
import { useEffect, useMemo, useState } from "react";
import { useWindowSize } from "usehooks-ts";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarBlockedIcon,
} from "../../atoms";

export function SelectTime({
  serviceId,
  slotSizeInMinutes,
  serviceDuration,
  staffHost,
  facilityHost,
  availabilityType,
  onTimeSelect,
  onBack,
}: {
  serviceId: number;
  serviceDuration: number;
  slotSizeInMinutes: number;
  staffHost?: UserStaffPublicDTO | null;
  facilityHost?: FacilityDTO | null;
  availabilityType: AppointableDtoAvailabilityType;
  onTimeSelect: (slot: { startTime: Date; hosts: string[] }) => void;
  onBack: () => void;
}) {
  const [dayIndex, setDayIndex] = useState(0);
  const [date, setDate] = useState<Date>(new Date());
  const { timezone } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: dataForStaff } = useQueryHostAvailabilityCalculateAsMember(
    {
      api,
      tz: timezone,
      appointableId: serviceId,
      appointableHostsIdList: staffHost ? [staffHost.id] : undefined,
      dateFrom: moment(startOfDay(date)).format(
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      ),
      dateTo: moment(endOfDay(date)).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
    },
    { enabled: availabilityType === "STAFF" },
  );
  const { data: dataForFacility } =
    useQueryFacilityAvailabilityCalculateAsMember(
      {
        api,
        tz: timezone,
        appointableId: serviceId,
        appointableFacilityIdList: facilityHost ? [facilityHost.id] : undefined,
        dateFrom: moment(startOfDay(date)).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
        dateTo: moment(endOfDay(date)).format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      },
      {
        enabled: availabilityType === "FACILITY",
      },
    );
  const slots = useMemo(() => {
    if (
      (availabilityType === "STAFF" && !dataForStaff) ||
      (availabilityType === "FACILITY" && !dataForFacility)
    ) {
      return { morning: [], afternoon: [] };
    }
    let baseResult: AvailabilityCalculationResult[] = [];

    if (availabilityType === "STAFF") {
      baseResult = dataForStaff as AvailabilityCalculationResult[];
    } else if (availabilityType === "FACILITY") {
      baseResult = dataForFacility as AvailabilityCalculationResult[];
    }

    const result = baseResult
      .map((slot) => {
        const start = moment(
          slot.startTime,
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ).toDate();
        const end = moment(slot.endTime, PARAMETER_DATE_FORMAT_WITHOUT_TZ)
          .subtract(serviceDuration, "minutes")
          .toDate();
        let current = new Date(start.getTime());
        const newSlots = [{ start: current, hosts: slot.availableHostsIdList }];
        while (true) {
          const tentativeSlot = addMinutes(current, slotSizeInMinutes);
          if (isBefore(tentativeSlot, end)) {
            newSlots.push({
              start: tentativeSlot,
              hosts: slot.availableHostsIdList,
            });
            current = tentativeSlot;
          } else {
            break;
          }
        }
        return newSlots;
      })
      .flatMap((s) => s)
      .filter((s) => isAfter(s.start, new Date()));
    return {
      morning: result.filter((s) => s.start.getHours() < 12),
      afternoon: result.filter((s) => s.start.getHours() >= 12),
    };
  }, [dataForStaff, availabilityType, dataForFacility]);

  const window = useWindowSize({ debounceDelay: 100 });
  const [numberDaysToShow, setNumberDaysToShow] = useState(7);
  useEffect(() => {
    if (window.width < 250) {
      setNumberDaysToShow(1);
    } else if (window.width < 450) {
      setNumberDaysToShow(3);
    } else if (window.width < 650) {
      setNumberDaysToShow(5);
    } else {
      setNumberDaysToShow(7);
    }
  }, [window.width]);

  const showBackArrow = dayIndex > 0;
  const TWO_MONTHS_IN_DAYS = 62;
  const showForwardArrow = dayIndex < TWO_MONTHS_IN_DAYS - numberDaysToShow;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div
          className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 cursor-pointer rounded-lg border border-gray-300 px-3 py-2 hover:bg-gray-50"
          onClick={onBack}
        >
          <ArrowLeftIcon pathClassName="stroke-gray-500 dark:stroke-darkGray-400" />
        </div>
        <div className="text-2xl font-bold dark:text-white">Select Time</div>
      </div>
      <div className="flex justify-between">
        <div className="text-lg font-semibold dark:text-white">
          {format(date, "EEEE, do MMMM")}
        </div>
        <div className="flex gap-3">
          <div
            className={cn({
              "cursor-pointer": showBackArrow,
              invisible: !showBackArrow,
            })}
            onClick={() => {
              if (dayIndex - numberDaysToShow <= 0) {
                setDayIndex(0);
                setDate(new Date());
              } else {
                setDayIndex(dayIndex - numberDaysToShow);
                setDate(subDays(date, numberDaysToShow));
              }
            }}
          >
            <ArrowLeftIcon pathClassName="stroke-gray-500 dark:stroke-darkGray-400" />
          </div>
          <div
            className={cn({
              "cursor-pointer": showForwardArrow,
              invisible: !showForwardArrow,
            })}
            onClick={() => {
              setDayIndex(dayIndex + numberDaysToShow);
              setDate(addDays(date, numberDaysToShow));
            }}
          >
            <ArrowRightIcon pathClassName="stroke-gray-500 dark:stroke-darkGray-400" />
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        {range(dayIndex, dayIndex + numberDaysToShow - 1).map((i) => {
          const selectableDate = addDays(new Date(), i);
          const isSelectedDay = isSameDay(date, selectableDate);
          return (
            <div
              key={i}
              className={cn(
                "flex h-16 w-16 flex-col items-center justify-center rounded-lg border",
                {
                  "dark:text-darkGray-400 dark:hover:bg-darkGray-900 dark:border-darkGray-800 cursor-pointer border-gray-300 hover:bg-gray-50":
                    !isSelectedDay,
                  "bg-primary-600 border-primary-600 cursor-pointer text-white":
                    isSelectedDay,
                },
              )}
              onClick={() => {
                setDate(selectableDate);
              }}
            >
              <div className="text-sm font-semibold uppercase">
                {format(selectableDate, "E")}
              </div>
              <div className="text-2xl font-bold">
                {format(selectableDate, "d")}
              </div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-4">
        {!slots.morning.length && !slots.afternoon.length && (
          <div className="flex flex-col items-center justify-center gap-4 p-8">
            <div>
              <CalendarBlockedIcon pathClassName="dark:stroke-darkGray-400" />
            </div>
            <div className="dark:text-darkGray-400 text-lg font-semibold">
              We're Fully Booked
            </div>
          </div>
        )}
        {!!slots.morning.length && (
          <>
            <div className="dark:bg-darkGray-800 dark:text-darkGray-200 rounded-lg border border-gray-200 bg-gray-50 px-4 py-2 text-lg font-semibold dark:border-0">
              Morning
            </div>
            {slots.morning.map((morningSlot) => {
              return (
                <div
                  key={morningSlot.start.getTime()}
                  className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 dark:text-darkGray-400 flex cursor-pointer items-center justify-between gap-4 rounded-lg border border-gray-300 p-4 hover:bg-gray-50"
                  onClick={() => {
                    onTimeSelect({
                      startTime: morningSlot.start,
                      hosts: morningSlot.hosts,
                    });
                  }}
                >
                  <div>{format(morningSlot.start, "h:mm a")}</div>
                  <div>
                    <ArrowRightIcon pathClassName="stroke-gray-500 dark:stroke-darkGray-400" />
                  </div>
                </div>
              );
            })}
          </>
        )}
        {!!slots.afternoon.length && (
          <>
            <div className="dark:bg-darkGray-800 dark:text-darkGray-200 rounded-lg border border-gray-200 bg-gray-50 px-4 py-2 text-lg font-semibold dark:border-0">
              Afternoon
            </div>
            {slots.afternoon.map((afternoonSlot) => {
              return (
                <div
                  key={afternoonSlot.start.getTime()}
                  className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 dark:text-darkGray-400 flex cursor-pointer items-center justify-between gap-4 rounded-lg border border-gray-300 p-4 hover:bg-gray-50"
                  onClick={() => {
                    onTimeSelect({
                      startTime: afternoonSlot.start,
                      hosts: afternoonSlot.hosts,
                    });
                  }}
                >
                  <div>{format(afternoonSlot.start, "h:mm a")}</div>
                  <div>
                    <ArrowRightIcon pathClassName="stroke-gray-500 dark:stroke-darkGray-400" />
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
