import {
  EventStatisticField,
  GlobalStatisticsCategories,
  LeadStatisticField,
  MembershipStatisticField,
  RevenueStatisticField,
  VisitStatisticField,
} from "@gymflow/types";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import mergeWith from "lodash/mergeWith";
import { useEffect, useMemo, useState } from "react";

import { routeToFieldsMap } from "../components/Reports/Widgets";

function mergeArraysCustomizer(objValue: unknown, srcValue: unknown) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  return undefined;
}

export const useDashboardDynamicWidgets = () => {
  const [widgets, setWidgets] = useState<string[][]>([]);

  useEffect(() => {
    const storedWidgets = localStorage.getItem("dashboard-dynamic-widgets");
    if (storedWidgets) {
      setWidgets(JSON.parse(storedWidgets));
    } else {
      setWidgets([
        ["revenue", "salesSummary", "total"],
        ["members", "membershipsSold"],
        ["members", "overdueMemberships"],
        ["events", "attendance"],
      ]);
    }
  }, []);

  const { fields, sections } = useMemo(() => {
    const sectionsToFetch: GlobalStatisticsCategories[] = [];
    const fieldsToFetch: {
      revenueFields?: RevenueStatisticField[];
      membershipFields?: MembershipStatisticField[];
      leadFields?: LeadStatisticField[];
      eventFields?: EventStatisticField[];
      visitFields?: VisitStatisticField[];
    } = routeToFieldsMap.reduce((acc, routeToField) => {
      const widgetDef = widgets.find((widget) => {
        return isEqual(routeToField.route, widget);
      });
      if (widgetDef && routeToField.fields) {
        acc = mergeWith(acc, routeToField.fields, mergeArraysCustomizer);
      }
      return acc;
    }, {});
    if (fieldsToFetch["revenueFields"]) {
      sectionsToFetch.push("revenue");
    }
    if (fieldsToFetch["membershipFields"]) {
      sectionsToFetch.push("membership");
    }
    if (fieldsToFetch["leadFields"]) {
      sectionsToFetch.push("lead");
    }
    if (fieldsToFetch["eventFields"]) {
      sectionsToFetch.push("event");
    }
    if (fieldsToFetch["visitFields"]) {
      sectionsToFetch.push("visit");
    }
    return { fields: fieldsToFetch, sections: sectionsToFetch };
  }, [widgets]);
  return {
    widgets,
    fields,
    sections,
    setWidgets: (newWidgets: string[][]) => {
      localStorage.setItem(
        "dashboard-dynamic-widgets",
        JSON.stringify(newWidgets),
      );
      setWidgets(newWidgets);
    },
  };
};
