import { LeadStatusDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export async function leadStatusListQueryFn({
  api,
}: {
  api: LeadStatusListApi;
}) {
  const result = await api.leadApi.findStatus();
  return result.data;
}

export function useQueryLeadStatus({ api }: { api: LeadStatusListApi }) {
  const result = useQuery({
    queryKey: leadQueryKeys.leadStatus({}),
    queryFn: () => leadStatusListQueryFn({ api }),
  });
  return result;
}

interface LeadStatusListApi {
  leadApi: {
    findStatus: () => Promise<{ data: LeadStatusDTO[] }>;
  };
}
