import { useMemo } from "react";
import omit from "lodash/omit";
import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { calendarQueryKeys } from "../calendar";

export function useEventOccurrenceDelete(
  {
    api,
  }: {
    api: {
      eventApi: {
        delete: (occurrenceId: number, deleteAll: boolean) => Promise<any>;
      };
    };
  },
  mutationOptsParams?: UseMutationOptions<
    unknown,
    unknown,
    { occurrenceId: number; isDeleteAll: boolean }
  >,
) {
  const queryClient = useQueryClient();

  const mutationOpts = useMemo(
    () => omit(mutationOptsParams, "onSuccess"),
    [mutationOptsParams],
  );

  const mutation = useMutation({
    mutationFn: ({
      occurrenceId,
      isDeleteAll,
    }: {
      occurrenceId: number;
      isDeleteAll: boolean;
    }) => api.eventApi.delete(occurrenceId, isDeleteAll),
    onSuccess: (data, variables, context) => {
      if (mutationOptsParams?.onSuccess) {
        mutationOptsParams.onSuccess(data, variables, context);
      }
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
    ...mutationOpts,
  });
  return mutation;
}
