import { useContext } from "react";

import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportLeadsClosedRatioWidget = () => {
  const data = useContext(ReportContext);

  return (
    <ReportWidgetCard
      title="Close Ratio"
      value={`${(data.leads?.closeRatio?.total ?? 0).toFixed(0)}%`}
      isLoading={data.isLoading}
      explanation="Number of deals closed divided by the number of New Leads within the selected period."
      variation={data.leads?.closeRatio?.variation}
    />
  );
};
