import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import Select from "react-select";

import UrlType from "./UrlType";

const Step1 = ({ value, onChange }) => {
  const options = [
    {
      label: "Memberships",
      value: UrlType.Memberships,
    },

    {
      label: "Credit Packs",
      value: UrlType.SessionPacks,
    },

    {
      label: "Enquiry",
      value: UrlType.Enquiry,
    },

    {
      label: "Guest Registration",
      value: UrlType.GuestRegistration,
    },

    {
      label: "Timetable",
      value: UrlType.Timetable,
    },
  ];
  return (
    <div className="w-100 text-center">
      <b className="h2">Which page do you want to customize?</b>
      <Row className="d-flex justify-content-center mt-4 text-left">
        <Col md={8}>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            options={options}
            value={options.find((option) => option.value === value)}
            onChange={({ value }) => {
              onChange(value);
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

Step1.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Step1;
