import endpoints from "./endpoints";

const statisticsApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = { http: axiosInstance, apiUrl };

  return Object.assign(state, {
    getData(
      clubId,
      start,
      end,
      trendPeriod = "MONTH",
      categories = Object.values(STATISTICS_CATEGORY),
      revenueFields,
      membershipFields,
      eventFields,
      leadFields,
      visitFields,
      membershipIncluded,
      membershipExcluded,
    ) {
      const body = {};

      if (categories.includes(STATISTICS_CATEGORY.Revenue)) {
        body.revenue = {
          filter: {
            fields: revenueFields,
            initialDate: start,
            finalDate: end,
            trendPeriod,
          },
        };
      }

      if (categories.includes(STATISTICS_CATEGORY.Membership)) {
        body.membership = {
          filter: {
            fields: membershipFields,
            initialDate: start,
            finalDate: end,
            trendPeriod,
            membershipIncluded,
            membershipExcluded,
          },
        };
      }

      if (categories.includes(STATISTICS_CATEGORY.Event)) {
        body.event = {
          filter: {
            fields: eventFields,
            initialDate: start,
            finalDate: end,
            trendPeriod,
          },
        };
      }

      if (categories.includes(STATISTICS_CATEGORY.Lead)) {
        body.lead = {
          filter: {
            fields: leadFields,
            initialDate: start,
            finalDate: end,
            trendPeriod,
          },
        };
      }

      if (categories.includes(STATISTICS_CATEGORY.Visit)) {
        body.visit = {
          filter: {
            fields: visitFields,
            initialDate: start,
            finalDate: end,
            trendPeriod,
          },
        };
      }

      return this.http.post(`${clubPrefix}${endpoints.statistics}`, body);
    },
  });
};

export const STATISTICS_CATEGORY = {
  Revenue: "revenue",
  Membership: "membership",
  Event: "event",
  Lead: "lead",
  Visit: "visit",
};

export const REVENUE_FIELD = {
  Total: "total",
  Membership: "membership",
  CreditPack: "sessionPack",
  Product: "product",
};

export const MEMBERSHIP_FIELD = {
  Active: "active",
  Paused: "paused",
  Cancelled: "cancelled",
  SignUps: "signUps",
  Overdue: "overdue",
};

export const EVENT_FIELD = {
  Attendance: "attendance",
  BookingPercentage: "bookingPercentage",
};

export default statisticsApi;
