import { tzDateTimeStringToUtc } from "@gymflow/common";
import {
  RevenueType,
  TransactionReportDTO,
  TransactionReportItemDTO,
  TransactionType,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import { utcToZonedTime } from "date-fns-tz";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseRevenueAccountsReceivableProps {
  api: {
    revenueApi: {
      fetchRevenueByType: (
        params: {
          limit: number;
          page: number;
          dateFrom?: string;
          dateTo?: string;
          transactionType: TransactionType;
        },
        revenueType: RevenueType,
      ) => Promise<any>;
      total: (
        params: {
          dateFrom?: string;
          dateTo?: string;
          transactionType: TransactionType;
        },
        revenueType: RevenueType,
      ) => Promise<any>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  limit: number;
  page: number;
  transactionType: TransactionType;
  tz: string;
}

export function useRevenueByTransactionTypeReport({
  api,
  limit,
  dateFrom,
  dateTo,
  page,
  transactionType,
  tz,
}: UseRevenueAccountsReceivableProps) {
  const result = useQuery({
    queryKey: [
      reportQueryKeys.revenueAccountsReceivable({
        page,
        limit,
        dateFrom,
        dateTo,
        transactionType,
      }),
    ],
    queryFn: async () => {
      const resultRequest = api.revenueApi.fetchRevenueByType(
        {
          page,
          limit,
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
          transactionType,
        },
        "transaction-type",
      );
      const totalRequest = api.revenueApi.total(
        {
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
          transactionType,
        },
        "transaction-type",
      );
      const [result, total] = await Promise.all([resultRequest, totalRequest]);
      return {
        data: result.data,
        total: total.data.total,
      } as TransactionReportDTO;
    },
    placeholderData: () => {
      return {
        data: defaultPage<TransactionReportItemDTO>(),
        total: 0,
      };
    },
    select: (data) => {
      const dto = merge({}, data.data, {
        content: data.data.content.map(({ date, ...rest }) => {
          return {
            date: date && utcToZonedTime(date, tz),
            ...rest,
          };
        }),
      });
      return {
        ...data,
        data: dto,
      };
    },
    enabled: !!tz,
  });
  return result;
}
