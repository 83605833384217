import { AvailabilityOverrideDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryFacilityAvailabilityOverride(
  {
    api,
    availabilityOverrideId,
    facilityId,
  }: {
    api: {
      availabilityApi: {
        facilityOverride: (
          facilityId: number,
          overrideId: number,
        ) => Promise<any>;
      };
    };
    availabilityOverrideId: number | null;
    facilityId?: number;
  },
  opts?: UseQueryOptions<AvailabilityOverrideDTO | null>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.facilityOverride(
      facilityId as number,
      availabilityOverrideId as number,
    ),
    queryFn: async () => {
      const result = await api.availabilityApi.facilityOverride(
        facilityId as number,
        availabilityOverrideId as number,
      );
      return result.data as AvailabilityOverrideDTO;
    },
    enabled: !!availabilityOverrideId && !!facilityId,
    initialData: null,
    ...opts,
  });

  return result;
}
