import check, { assert } from "check-types";
/*** @deprecated */
export const canFind = (state) => ({
  /**
   * @param {SortType | undefined} sort
   */
  find({ page = 0, limit = 10, extraParams = {}, sort }) {
    assert.maybe.number(page, "page must be number or undefined");
    assert.maybe.number(limit, "limit must be number or undefined");

    const config = {
      params: {
        page,
        size: limit,
        ...extraParams,
      },
    };
    if (sort) {
      assert.string(
        sort.field,
        "sort.field must be a string if sort is specified.",
      );
      config.params.sort = state.createSortParam(sort.field, sort.desc);
    }

    return state.http.get(state.baseUrl, config);
  },
  createSortParam(field, desc = false) {
    return `${field},${desc ? "desc" : "asc"}`;
  },
});

/*** @deprecated */
export const canFindById = (state) => ({
  findById(id) {
    assert(
      check.any([check.string(id), check.number(id)]),
      "id must be number or string",
    );
    return state.http.get(`${state.baseUrl}/${id}`);
  },
});

/*** @deprecated */
export const canCreate = (state) => ({
  create(newResource) {
    const trimmedData = state.trimFields(newResource);

    return state.http.post(state.baseUrl, trimmedData);
  },
});

/*** @deprecated */
export const canUpdate = (state) => ({
  update(id, patchedFields) {
    assert(
      check.any([check.string(id), check.number(id)]),
      "id must be number or string",
    );
    return state.http.patch(
      `${state.baseUrl}/${id}`,
      state.trimFields(patchedFields),
    );
  },
});

/*** @deprecated */
export const canTrimFields = (state) => ({
  trimFields(record) {
    const trimmedRecord = {
      ...record,
    };
    state.fieldsToTrim.forEach((field) => {
      const fieldValue = trimmedRecord[field];
      if (typeof fieldValue === "string") {
        trimmedRecord[field] = fieldValue.trim();
      }
    });
    return trimmedRecord;
  },
});

/*** @deprecated */
export const canActivateAndDeactivate = (
  state,
  urls = { inactive: "inactive", active: "active" },
) => ({
  deactivate(id) {
    assert.number(id, "id must be a number");
    const url = `${state.baseUrl}/${id}/${urls.inactive}`;

    return state.http.patch(url);
  },

  activate(id) {
    assert.number(id, "id must be a number");
    const url = `${state.baseUrl}/${id}/${urls.active}`;
    return state.http.patch(url);
  },
});

/*** @deprecated */
export const canCheckIfEmailExists = (state, clubId) => ({
  async emailExists(email) {
    const clubPrefix = `club/${clubId}/`;
    const response = await state.http.get(`${clubPrefix}catalog/user/email`, {
      params: {
        q: email.toLowerCase(),
      },
    });
    return response.data;
  },
});
