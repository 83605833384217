import React, { createRef, useEffect, useReducer } from 'react';
import ReactCrop from 'react-image-crop';
import { Button } from 'reactstrap';
import * as PropTypes from 'prop-types';

const ImageUploader = (props) => {
  const { onCancel, onSave, src, maxHeight, minHeight, maxWidth, minWidth, buttonsClass, hideHeaders } = props;
  const previewRef = createRef();
  useEffect(() => {
    if (previewRef.current) {
      previewRef.current.scrollIntoView();
    }
  }, [previewRef]);

  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case SET_IMAGE_REF_ACTION:
          return { ...state, imageRef: action.imageRef, crop: { ...state.crop, width: action.imageRef.width } };
        case SET_CROP_ACTION:
          return { ...state, crop: { ...state.crop, ...action.crop } };
        case PREVIEW_CROP_ACTION: {
          const fileUrl = window.URL.createObjectURL(action.blob);
          return { ...state, cropped: fileUrl };
        }
        case BACK_TO_CROP_ACTION:
          window.URL.revokeObjectURL(state.cropped);
          return { ...state, cropped: null };
        default:
          throw new Error();
      }
    },
    {
      original: src,
      imageRef: null,
      cropped: null,
      crop: {
        width: minWidth,
        x: 0,
        y: 0,
        aspect: 1,
        locked: true,
      },
      fileUrl: null,
    }
  );

  const getCroppedImgBlob = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const renderCropper = () => {
    if (state.cropped) {
      return null;
    }
    return (
      <>
        {hideHeaders ? null : <h3>Crop your picture</h3>}
        <div style={{ padding: '20px', marginBottom: '8px', border: '1px solid #f5f6fa' }}>
          <ReactCrop
            src={src.blob}
            onChange={(newCrop) => {
              dispatch({
                type: SET_CROP_ACTION,
                crop: newCrop,
              });
            }}
            onImageLoaded={(image) => {
              dispatch({ type: SET_IMAGE_REF_ACTION, imageRef: image });
              return false;
            }}
            crop={state.crop}
            keepSelection
            circularCrop
            maxHeight={maxHeight}
            maxWidth={maxWidth}
            minHeight={minHeight}
            minWidth={minWidth}
          />
        </div>
        <div className="row d-flex w-100 justify-content-center">
          <div>
            <Button
              color="primary"
              outline
              size="sm"
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={async () => {
                const blob = await getCroppedImgBlob(state.imageRef, state.crop, 'crop.jpg');
                dispatch({ type: PREVIEW_CROP_ACTION, blob });
              }}
            >
              Preview
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderPreview = () => {
    if (state.cropped) {
      return (
        <>
          {hideHeaders ? null : <h3 ref={previewRef}>Preview</h3>}

          <div className="text-center" style={{ padding: '20px', marginBottom: '8px', border: '1px solid #f5f6fa' }}>
            <div className="fileinput">
              <div className="thumbnail img-circle">
                <img alt="Crop" style={{ maxWidth: '100%' }} src={state.cropped} />
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center">
            <Button
              className={buttonsClass}
              style={{ height: 60 }}
              onClick={() => {
                dispatch({ type: BACK_TO_CROP_ACTION });
              }}
            >
              Return to Crop
            </Button>
            <Button
              className={buttonsClass}
              color="primary"
              style={{ height: 60 }}
              onClick={() => {
                onSave({ name: src.name, blob: state.cropped });
              }}
            >
              Submit
            </Button>
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <>
      {renderCropper()}
      {renderPreview()}
    </>
  );
};

const SET_CROP_ACTION = 'SET_CROP';
const SET_IMAGE_REF_ACTION = 'SET_IMAGE_REF';
const PREVIEW_CROP_ACTION = 'PREVIEW_CROP';
const BACK_TO_CROP_ACTION = 'BACK_TO_CROP';

ImageUploader.defaultProps = {
  buttonsClass: 'col-12 col-xl-6',
  hideHeaders: false,
  maxHeight: 500,
  maxWidth: 500,
  minHeight: 100,
  minWidth: 100,
};

ImageUploader.propTypes = {
  src: PropTypes.exact({
    name: PropTypes.string,
    blob: PropTypes.string,
  }).isRequired,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  buttonsClass: PropTypes.string,
  hideHeaders: PropTypes.bool,
};

export default ImageUploader;
