import { assert } from "check-types";

import endpoints from "./endpoints";

const kisiEndpoints = {
  saveApiKey: `${endpoints.kisi}/save-api-key/:apiKey`,
  apiKeyExists: `${endpoints.kisi}/check/api-key`,
  signatureKeyExists: `${endpoints.kisi}/check/api-signature-key`,
  generateSignatureKey: `${endpoints.kisi}/api-signature-key`,
  catalogPlaces: `catalog/kisi/places`,
  catalogGroups: `catalog/kisi/groups`,
  initialize: `${endpoints.kisi}/initialize`,
  mappings: `${endpoints.kisi}/mappings`,
  groupMappings: `${endpoints.kisi}/group-mappings`,
  modify: `${endpoints.kisi}/modify`,
  resetAccess: `${endpoints.kisi}/reset-access/:memberId`,
};

const kisiApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.kisi}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    saveApiKey(clubId, apiKey) {
      assert.number(clubId, "clubId must be a number");
      assert.string(apiKey, "apiKey must be string");

      const url = kisiEndpoints.saveApiKey.replace(":apiKey", apiKey);

      return state.http.put(`${clubPrefix}${url}`);
    },
    apiKeyExists(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(`${clubPrefix}${kisiEndpoints.apiKeyExists}`);
    },
    generateSignatureKey(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(
        `${clubPrefix}${kisiEndpoints.generateSignatureKey}`,
      );
    },
    signatureKeyExists(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(`${clubPrefix}${kisiEndpoints.signatureKeyExists}`);
    },
    fetchPlaces(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(`${clubPrefix}${kisiEndpoints.catalogPlaces}`);
    },
    fetchGroups(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(`${clubPrefix}${kisiEndpoints.catalogGroups}`);
    },
    initialize(clubId, kisiPlaceId, membershipToGroupMap, facilityToGroupMap) {
      assert.number(clubId, "clubId must be a number");
      assert.string(kisiPlaceId, "kisiPlaceId must be string");
      assert.object(
        membershipToGroupMap,
        "membershipToGroupMap must be an object",
      );
      assert.object(facilityToGroupMap, "facilityToGroupMap must be an object");

      return state.http.post(`${clubPrefix}${kisiEndpoints.initialize}`, {
        placeId: kisiPlaceId,
        membershipsToGroups: membershipToGroupMap,
        facilitiesToGroups: facilityToGroupMap,
      });
    },
    /** @deprecated */
    fetchMappings(clubId) {
      return state.http.get(`${clubPrefix}${kisiEndpoints.mappings}`);
    },
    fetchGroupMappings() {
      return state.http.get(`${clubPrefix}${kisiEndpoints.groupMappings}`);
    },
    modify({ clubId, membershipId, facilityId, kisiGroupId }) {
      return state.http.patch(`${clubPrefix}${kisiEndpoints.modify}`, {
        membershipId,
        kisiGroupId,
        facilityId,
      });
    },
    resetAccess(clubId, memberId) {
      assert.number(clubId, "clubId must be a number");
      assert.string(memberId, "memberId must be string");

      const url = `${clubPrefix}${kisiEndpoints.resetAccess}`.replace(
        ":memberId",
        memberId,
      );

      return state.http.put(url);
    },
  });
};

export default kisiApi;
