import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { QueryClientError } from "../QueryClientError";
import { BootstrapServerRepository } from "../repositories/BootstrapServerRepository";

export const useBootstrapServers = ({
  serverUrl,
  routeId,
}: {
  serverUrl: string;
  routeId?: string;
}) => {
  const repository = useMemo(
    () => new BootstrapServerRepository(serverUrl),
    [serverUrl],
  );

  return useQuery({
    queryKey: ["bootstrapServer", routeId],
    queryFn: async () => repository.getServerByRouteId(routeId!),
    enabled: !!routeId,

    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,

    staleTime: Infinity,
    cacheTime: Infinity,
    meta: { errCode: "BOOTSTRAP" as QueryClientError },
  });
};
