import { RevenueType } from "../constants/RevenueType";
import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";

const revenueEndpoints = {
  csv: `${endpoints.revenue}/csv`,
  total: `${endpoints.revenue}/total`,
};

const revenueApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    http: axiosInstance,
    baseUrl: `${clubPrefix}${endpoints.revenue}`,
    apiUrl,
  };

  return Object.assign(state, canFind(state), {
    csv(filters, revenueType) {
      const url = clubPrefix + (revenueType ? `${endpoints.revenue}/${revenueType}/csv` : revenueEndpoints.csv);
      return state.http.get(url, {
        params: filters,
      });
    },
    total(filters, revenueType) {
      const url = clubPrefix + (revenueType ? `${endpoints.revenue}/${revenueType}/total` : revenueEndpoints.total);
      return state.http.get(url, {
        params: filters,
      });
    },
    fetchRevenueByType({ limit, ...otherFilters }, revenueType = RevenueType.TransactionType) {
      return state.http.get(`${clubPrefix}${endpoints.revenue}/${revenueType}`, {
        params: {
          size: limit,
          ...otherFilters,
        },
      });
    },
  });
};

export default revenueApi;
