import { ClubBean } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { clubAsPublicQueryKeys } from "./clubAsPublicQueryKeys";

export function useClubAsPublic(
  {
    api,
    clubId,
  }: {
    api: {
      public: {
        clubApi: { findById: (clubId: number) => Promise<{ data: ClubBean }> };
      };
    };
    clubId: number;
  },
  opts?: UseQueryOptions<ClubBean | null>,
) {
  const result = useQuery({
    queryKey: clubAsPublicQueryKeys.details(clubId),
    queryFn: async () => {
      const result = await api.public.clubApi.findById(clubId);
      return result.data;
    },
    enabled: !!clubId,
    placeholderData: null,
    ...opts,
  });

  return result;
}
