import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";

import { Button } from "../atoms";

export function Pagination({
  className,
  goToNextPage,
  goToPreviousPage,
  hasNextPage,
  hasPreviousPage,
}: {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  goToNextPage?: () => void;
  goToPreviousPage?: () => void;
  className?: string;
}) {
  return (
    <div className={cn("flex w-full flex-row justify-between", className)}>
      <Button
        onClick={goToPreviousPage}
        className={cn({
          invisible: !hasPreviousPage,
        })}
      >
        <div className="flex items-center justify-between gap-2">
          <FontAwesomeIcon icon={faArrowLeft} />
          Previous
        </div>
      </Button>
      <Button
        onClick={goToNextPage}
        className={cn({
          invisible: !hasNextPage,
        })}
      >
        <div className="flex items-center justify-between gap-2">
          Next
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      </Button>
    </div>
  );
}
