import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointmentQueryKeys } from "../appointment";
import { noteQueryKeys } from "./noteQueryKeys";

export function useNoteEdit({
  api,
}: {
  api: {
    noteApi: {
      update: (
        noteId: number,
        data: { patchedFields: any; file: unknown },
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      noteId,
      patchedFields,
      file,
    }: {
      noteId: number;
      patchedFields?: { [k: string]: any };
      file?: unknown;
    }) => {
      await api.noteApi.update(noteId, { patchedFields, file });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
    },
  });
  return mutation;
}
