import { ApiListResponse, RuleGroup, RuleGroupType } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryRuleGroupSearch({
  api,
  filter,
}: {
  api: {
    ruleApi: {
      ruleGroupSearch: (params: RuleGroupSearchParams) => Promise<any>;
    };
  };
  filter: RuleGroupSearchParams;
}) {
  const result = useQuery({
    queryKey: ruleQueryKeys.ruleGroupSearch(filter ?? {}),
    queryFn: async () => {
      const result = await api.ruleApi.ruleGroupSearch({ ...filter });
      return result.data as ApiListResponse<RuleGroup>;
    },
    initialData: defaultPage<RuleGroup>,
  });
  return result;
}

interface RuleGroupSearchParams {
  readonly isActive?: boolean;
  readonly isDefault?: boolean;
  readonly groupType?: RuleGroupType;
  readonly page: number;
  readonly size: number;
}
