import { useClubFeatureFlags, useEmailTemplateList } from "@gymflow/api";
import { AsyncButton, isMobile } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import classNames from "classnames";
import { Button } from "reactstrap";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { ResourceTableCard } from "../ResourceTableCard";
import useTemplateAlert from "./useTemplateAlert";

export function Templates() {
  const { showAlert, editingTemplateId, setEditingTemplateId } =
    useTemplateAlert();

  const renderButtons = ({ resource: { id, isEditable }, beingEdited }) => (
    <AsyncButton
      className={classNames("btn-link", { "d-none": !isEditable })}
      data-testid="btn-edit"
      onClick={() => setEditingTemplateId(id)}
      size="sm"
      disabled={beingEdited}
    >
      Edit
    </AsyncButton>
  );

  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });

  return (
    <div
      className={cn("relative flex h-full max-h-full w-full flex-col", {
        "mt-12": featureFlags.featureFlags.PROMOTION_PAGE_REDESIGN,
      })}
    >
      <Button
        color="primary"
        size="sm"
        className="absolute right-0 top-[-60px] w-fit"
        onClick={() => {
          showAlert();
        }}
      >
        Create Template
      </Button>
      <ResourceTableCard
        columns={[
          {
            accessor: "templateName",
            Header: "Name",
          },
          {
            accessor: "templateType",
            Header: "Type",
            show: !isMobile(),
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
            minWidth: isMobile() ? 110 : 160,
          },
        ]}
        renderButtons={renderButtons}
        showEditButtons
        useQueryHook={useEmailTemplateList}
        onEditClick={setEditingTemplateId}
        selectedId={editingTemplateId}
        findParams={{ extraParams: { isAutomationTemplate: false } }}
      />
    </div>
  );
}
