import { UserMemberCreated, UserMemberPost } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { memberAsPublicQueryKeys } from "./memberAsPublicQueryKeys";

export function useMemberCreateAsPublic(
  {
    api,
  }: {
    api: {
      public: {
        userMemberApi: {
          create: (
            newUser: UserMemberPost,
          ) => Promise<{ data: { userMember: UserMemberCreated } }>;
        };
      };
    };
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, UserMemberPost>,
) {
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: (newMember: UserMemberPost) =>
      api.public.userMemberApi.create(newMember),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsPublicQueryKeys.all(),
      });
    },
    ...mutationOpts,
  });

  return createMutation;
}
