import { useQuery } from '@tanstack/react-query';

import { automationQueryKeys } from './automationQueryKeys';
import { AutomationDTO } from '@gymflow/types';

export function useAutomationList({ api }: {
  api: {
    automationApi:
    {
      find: () => Promise<any>,
    }
  }
}) {
  const result = useQuery({
    queryKey: automationQueryKeys.list(),
    queryFn: async () => {
      const result = await api.automationApi.find();
      return result.data as AutomationDTO[];
    },
    initialData: [],
  });
  return result;
}
