import { z } from "zod";

export const Weekday = {
  Sunday: "SUNDAY",
  Monday: "MONDAY",
  Tuesday: "TUESDAY",
  Wednesday: "WEDNESDAY",
  Thursday: "THURSDAY",
  Friday: "FRIDAY",
  Saturday: "SATURDAY",
} as const;

export const WeekdayZodSchema = z.enum([
  Weekday.Sunday,
  Weekday.Monday,
  Weekday.Tuesday,
  Weekday.Wednesday,
  Weekday.Thursday,
  Weekday.Friday,
  Weekday.Saturday,
]).default(Weekday.Sunday);

Object.setPrototypeOf(Weekday, {
  humanize: (value: keyof typeof Weekday) =>
    Weekday[value].charAt(0).toUpperCase() +
    Weekday[value].slice(1).toLowerCase(),
  toNumber: (value: (typeof Weekday)[keyof typeof Weekday]) => {
    switch (value) {
      case Weekday.Sunday:
        return 0;
      case Weekday.Monday:
        return 1;
      case Weekday.Tuesday:
        return 2;
      case Weekday.Wednesday:
        return 3;
      case Weekday.Thursday:
        return 4;
      case Weekday.Friday:
        return 5;
      case Weekday.Saturday:
        return 6;
      default:
        throw new Error("Invalid Weekday.");
    }
  },
});
