import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import type { Transform } from "@dnd-kit/utilities";
import { cn } from "@gymflow/helpers";
import React, { forwardRef } from "react";

import styles from "./Draggable.module.css";

export enum Axis {
  All,
  Vertical,
  Horizontal,
}

interface Props {
  axis?: Axis;
  dragOverlay?: boolean;
  dragging?: boolean;
  handle?: boolean;
  label?: string;
  listeners?: DraggableSyntheticListeners;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  transform?: Transform | null;
}

export const Draggable = forwardRef<HTMLDivElement, Props>(function Draggable(
  {
    axis,
    dragOverlay,
    dragging,
    handle,
    label,
    listeners,
    transform,
    style,
    buttonStyle,
    ...props
  },
  ref,
) {
  return (
    <div
      className={cn(
        styles["Draggable"],
        dragOverlay && styles["dragOverlay"],
        dragging && styles["dragging"],
        handle && styles["handle"],
        "[&>*]:!opacity-100"
      )}
      style={
        {
          ...style,
          "--translate-x": `${transform?.x ?? 0}px`,
          "--translate-y": `${transform?.y ?? 0}px`,
        } as React.CSSProperties
      }
      ref={ref}
    ></div>
  );
});
