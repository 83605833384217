import endpoints from "./endpoints";

const emailEndpoints = {
  noFilter: `${endpoints.sendEmail}/no-filter`,
  filter: `${endpoints.sendEmail}/filter`,
};

const emailApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    http: axiosInstance,
    baseUrl: endpoints.sendEmail,
    apiUrl,
  };

  return Object.assign(state, {
    /** @deprecated */
    sendEmail(
      clubId,
      from,
      subject,
      body,
      emailRecipientList,
      isMarketing,
      bccList = [],
    ) {
      return state.http.post(`${clubPrefix}${emailEndpoints.noFilter}`, {
        emailRecipientList,
        body,
        subject,
        fromEmail: from,
        bccList,
        isMarketing,
        clubId,
      });
    },
    /** @deprecated */
    sendEmailFilter(
      clubId,
      from,
      subject,
      body,
      filters,
      isMarketing,
      leadIdsToExclude = [],
      userMemberIdsToExclude = [],
      bccList = [],
    ) {
      return state.http.post(`${clubPrefix}${emailEndpoints.filter}`, {
        body,
        subject,
        fromEmail: from,
        emailSendingFilters: filters,
        emailsToExclude: {
          leadIdList: leadIdsToExclude,
          userMemberIdList: userMemberIdsToExclude,
        },
        bccList,
        isMarketing,
        clubId,
      });
    },

    sendEmailToLeads({ emailPayload, leadIds }) {
      return state.http.post(`${clubPrefix}lead-send-email/no-filter`, {
        emailPayload,
        leadIds,
      });
    },
    sendEmailToLeadFilter({
      emailPayload,
      leadIdsToExclude,
      createdFrom,
      createdTo,
      smsCommunication,
      emailCommunication,
      leadStatusIdList,
      leadSourceIdList,
    }) {
      return state.http.post(`${clubPrefix}lead-send-email/filter`, {
        emailPayload,
        leadIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        leadStatusIdList,
        leadSourceIdList,
      });
    },
    sendEmailToMembers({ emailPayload, userMemberIds }) {
      return state.http.post(`${clubPrefix}user-member-send-email/no-filter`, {
        emailPayload,
        userMemberIds,
      });
    },
    sendEmailToMemberFilter({
      emailPayload,
      userMemberIdsToExclude,
      createdFrom,
      createdTo,
      smsCommunication,
      emailCommunication,
      membershipIdList,
      membershipTypeList,
      membershipStartFrom,
      membershipStartTo,
      membershipCancellationFrom,
      membershipCancellationTo,
      membershipExpireFrom,
      membershipExpireTo,
      assignedStaffIdList,
      creditsRemainingFrom,
      creditsRemainingTo,
      membershipStatusList,
    }) {
      return state.http.post(`${clubPrefix}user-member-send-email/filter`, {
        emailPayload,
        userMemberIdsToExclude,
        createdFrom,
        createdTo,
        smsCommunication,
        emailCommunication,
        membershipIdList,
        membershipTypeList,
        membershipStartFrom,
        membershipStartTo,
        membershipCancellationFrom,
        membershipCancellationTo,
        membershipExpireFrom,
        membershipExpireTo,
        assignedStaffIdList,
        creditsRemainingFrom,
        creditsRemainingTo,
        membershipStatusList,
      });
    },
  });
};

export default emailApi;
