import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMutationPayInvoiceAsMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const payInvoiceMutation = useMutation({
    mutationFn: async ({
      invoiceNumber,
      amount,
      paymentMethodId,
    }: {
      invoiceNumber: string;
      amount: number;
      paymentMethodId?: string;
    }) => {
      if (paymentMethodId) {
        await api.profileApi.assignDefaultPaymentMethod(
          undefined,
          undefined,
          paymentMethodId,
        );
      }
      return (await api.profileApi.payInvoice(invoiceNumber, amount)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
    },
  });

  return payInvoiceMutation;
}
