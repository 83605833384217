import {
  activityCategoryListAsPublicQueryFn,
  activityListAsPublicQueryFn,
  calendarEventOccurrencesAsPublicQueryFn,
  facilityListAsPublicQueryFn,
  rulesAsPublicFn,
  useQueryUserFormRulesAsPublic,
  useRuleValuesAsPublic,
} from "@gymflow/api";
import {
  AlertContext,
  DATE_FORMAT,
  DATE_FORMAT_WITH_SECONDS,
  permissions,
  RuleName,
} from "@gymflow/common";
import { utcToZonedTime } from "@gymflow/helpers";
import noop from "lodash/noop";
import omit from "lodash/omit";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import qs from "qs";
import { useCallback, useContext, useEffect, useState } from "react";
import Alert from "react-bootstrap-sweetalert";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { Button } from "reactstrap";

import { useFilterParams } from "../../hooks/useFilterParams";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import { BuyMembershipSubRoute } from "../../routes/site/buyMembershipSubRoute";
import useGymflowModels from "../../store";
import { CallToActionLink } from "../CallToActionLink";
import { EventGrid } from "../molecules/EventGrid";

function Timetable() {
  const history = useHistory();
  const { api } = useGymflowModels();

  const { date } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const initialDate = moment(date, DATE_FORMAT).isValid()
    ? moment(date, DATE_FORMAT).format(DATE_FORMAT_WITH_SECONDS)
    : moment().format(DATE_FORMAT_WITH_SECONDS);
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: rules } = useQueryUserFormRulesAsPublic({ api, clubId });
  const { setAlert, hide } = useContext(AlertContext);
  const { filters } = useFilterParams();
  const [events, setEvents] = useState([]);
  const { createClubLink, createSiteOrEmbedLink, isPathInLayout } =
    usePortalRoutes();

  const match = useRouteMatch();
  const embedPath = isPathInLayout(match.path, RouteLayout.Embed);
  const customerLogin = createClubLink(RouteLayout.Member);

  const [localFilter, setLocalFilter] = useState({});

  const validateMemberBook = async (activityId, startDate) => {
    const { ruleClubList: rules } = await rulesAsPublicFn(api, { activityId });
    const rule = rules?.find(
      ({ ruleType }) => ruleType === RuleName.BookingWindow,
    );
    if (!rule) {
      return null;
    }
    const ruleValue = rule.bookingRule;

    if (ruleValue && ruleValue.windowType && ruleValue.windowValue) {
      const isValid = moment()
        .add(ruleValue.windowValue, ruleValue.windowType)
        .isAfter(moment(startDate));
      if (!isValid) {
        let interval = ruleValue.windowType.toLowerCase();
        if (ruleValue.windowValue === "1") {
          interval = interval.slice(0, -1);
        }

        return `You can only book ${ruleValue.windowValue} ${interval} in advance.`;
      }
    }

    const error = permissions.membership.validateMemberBook(startDate);
    if (error) {
      return error;
    }

    return null;
  };

  const refreshEvents = useCallback(async () => {
    const aggregator = new RequestAggregator(async (filters) => {
      const events = await calendarEventOccurrencesAsPublicQueryFn({
        api,
        tz: settings.timezone,
        filters: {
          eventHostId: filters?.["event.userEventHost.id"],
          activityCategoryId: filters?.["event.activity.activityCategory.id"],
          activityId: filters?.["event.activity.id"],
          facilityId: filters?.["event.facility.id"],
          dateFrom: filters?.["dateFrom"],
          dateTo: filters?.["dateTo"],
          limit: 100,
          includeBookedCounts: true,
          includeWaitingCounts: true,
        },
      });
      return {
        ...events,
        content: events.content.map((event) => {
          return {
            ...event,
            startDate: utcToZonedTime(event.startDate, settings.timezone),
            endDate: utcToZonedTime(event.endDate, settings.timezone),
          };
        }),
      };
    });
    const dateToFetch = selectedDate;
    const bookFiltersToIgnore = ["id", "type"];
    let hasActivityOrActivityCategoryFetchRan = false;

    if (filters["event.activity.activityCategory.id"]) {
      aggregator.queue({
        dateFrom: moment(dateToFetch)
          .startOf("day")
          .format(DATE_FORMAT_WITH_SECONDS),
        dateTo: moment(dateToFetch)
          .add(6, "days")
          .endOf("day")
          .format(DATE_FORMAT_WITH_SECONDS),
        ...omit(filters, ["event.activity.id", ...bookFiltersToIgnore]),
      });
      hasActivityOrActivityCategoryFetchRan = true;
    }
    if (filters["event.activity.id"]) {
      aggregator.queue({
        dateFrom: moment(dateToFetch)
          .startOf("day")
          .format(DATE_FORMAT_WITH_SECONDS),
        dateTo: moment(dateToFetch)
          .add(6, "days")
          .endOf("day")
          .format(DATE_FORMAT_WITH_SECONDS),
        ...omit(filters, [
          "event.activity.activityCategory.id",
          ...bookFiltersToIgnore,
        ]),
      });
      hasActivityOrActivityCategoryFetchRan = true;
    }

    if (!hasActivityOrActivityCategoryFetchRan) {
      aggregator.queue({
        dateFrom: moment(dateToFetch)
          .startOf("day")
          .format(DATE_FORMAT_WITH_SECONDS),
        dateTo: moment(dateToFetch)
          .add(6, "days")
          .endOf("day")
          .format(DATE_FORMAT_WITH_SECONDS),
        ...omit(filters, [...bookFiltersToIgnore]),
        ...{
          "event.activity.id": localFilter?.activity?.value
            ? localFilter.activity.value
            : undefined,
          "event.activity.activityCategory.id": localFilter?.category
            ? localFilter?.category.value
            : undefined,
          "event.facility.id": localFilter?.facility
            ? localFilter.facility.value
            : undefined,
        },
      });
    }

    const aggregatorResults = await aggregator.run();
    setEvents(
      aggregatorResults.sort((a, b) => {
        const aDate = new Date(a.startDate);
        const bDate = new Date(b.startDate);
        return aDate - bDate;
      }),
    );
  }, [
    filters,
    selectedDate,
    localFilter?.activity,
    localFilter?.category,
    localFilter?.facility,
  ]);

  useEffect(() => {
    refreshEvents();
  }, [refreshEvents]);

  const location = useLocation();
  const { landingPage, memberships, sessionPacks, type } = qs.parse(
    location.search,
    { ignoreQueryPrefix: true },
  );

  const renderRsvpButton = ({
    startDate,
    bookedCount,
    bookable: isBookable,
    capacity,
    waitListCapacity,
    waitingCount,
    eventOccurrenceId: id,
    activityId,
  }) => {
    const isEventFull = bookedCount >= capacity;
    const isWaitListFull = waitingCount >= waitListCapacity;
    const showBookButton = isBookable;

    if (!showBookButton) {
      return null;
    }

    let text;
    if (!isEventFull) {
      text = "Book";
    } else if (!isWaitListFull) {
      text = "Join waitlist";
    } else {
      text = "Class Full";
    }

    const onClick = async () => {
      const bookErrors = await validateMemberBook(activityId, startDate);
      if (isEventFull && isWaitListFull) {
        setAlert(
          <Alert
            title="Event is full"
            type="danger"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            onConfirm={hide}
          >
            You cannot book this event, it&apos;s full.
          </Alert>,
        );
      } else if (bookErrors !== null) {
        setAlert(
          <Alert
            title="Cannot book"
            type="danger"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            onConfirm={hide}
          >
            {bookErrors}
          </Alert>,
        );
      } else {
        let landingSection = RouteFeature.SiteBuySessionPack;
        if (landingPage) {
          if (landingPage === "memberships") {
            landingSection =
              RouteFeature.SiteBuyMembership + BuyMembershipSubRoute.Any;
          } else if (landingPage === "session-packs") {
            landingSection = RouteFeature.SiteBuySessionPack;
          }
        }
        const bookLink = createSiteOrEmbedLink(landingSection);
        const params = {
          eventId: id,
          memberships,
          sessionPacks,
          type,
        };
        history.push({ pathname: bookLink, search: qs.stringify(params) });
      }
    };

    return (
      <Button
        color="primary"
        onClick={onClick}
        className="w-100 p-0"
        style={{ fontWeight: 600 }}
      >
        {text}
      </Button>
    );
  };

  const renderTitleText = () => {
    let text = (
      <>
        Existing customers please{" "}
        <CallToActionLink
          to={embedPath ? { pathname: customerLogin } : "#"}
          target={embedPath ? "_blank" : "_self"}
          onClick={
            embedPath
              ? noop
              : () => {
                  window.location = customerLogin;
                }
          }
        >
          Login
        </CallToActionLink>{" "}
        to book classes.
      </>
    );

    return text;
  };

  return (
    <div className="track-height flex flex-col px-3">
      <div className="my-3 text-center">{renderTitleText()}</div>
      <EventGrid
        initialDate={selectedDate}
        events={events}
        renderRsvpButton={renderRsvpButton}
        onSelectedDateChange={setSelectedDate}
        allowFilter={Object.keys(filters).length === 0}
        filter={localFilter}
        fetchCategories={(filter) => {
          const categories = activityCategoryListAsPublicQueryFn({
            api,
            filter: { ...filter, status: ["ACTIVE"] },
          });
          return categories;
        }}
        fetchFacilities={async ({ page }) => {
          const facilities = await facilityListAsPublicQueryFn({
            api,
            filter: { extraParams: { status: "ACTIVE" }, page },
          });
          return facilities;
        }}
        fetchActivities={async ({ page }) => {
          const activities = await activityListAsPublicQueryFn({
            api,
            filter: { extraParams: { status: "ACTIVE" }, page },
          });
          return activities;
        }}
        onCategoryFilterChange={(category) => {
          setLocalFilter((oldValue) => ({
            ...oldValue,
            category: category.value === null ? null : category,
          }));
        }}
        onFacilityFilterChange={(facility) => {
          setLocalFilter((oldValue) => ({ ...oldValue, facility }));
        }}
        onActivityFilterChange={(activity) => {
          setLocalFilter((oldValue) => ({ ...oldValue, activity }));
        }}
      />
    </div>
  );
}

function TimetableProvider() {
  const { EventStore } = useGymflowModels();

  return (
    <EventStore.Provider>
      <Timetable />
    </EventStore.Provider>
  );
}

export { TimetableProvider as Timetable };

Timetable.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  match: PropTypes.shape({ path: PropTypes.string.isRequired }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.shape({
      landingPage: PropTypes.string,
      memberships: PropTypes.string,
      sessionPacks: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

/**
 * @deprecated use another solution based on Promise.all
 */
class RequestAggregator {
  constructor(apiCall) {
    this.apiCall = apiCall;
    this.queuedParams = [];
  }

  queue(params) {
    this.queuedParams.push(params);
  }

  async run() {
    let results = [];
    for (const params of this.queuedParams) {
      // TODO: rewrite with Promise.all https://stackoverflow.com/a/52152930
      const { content: rows } = await this.apiCall(params);
      results = results.concat(rows);
    }
    const ids = results.map(({ eventOccurrenceId }) => eventOccurrenceId);
    const uniques = [...new Set(ids)];
    return uniques.map((id) =>
      results.find(({ eventOccurrenceId: resultId }) => id === resultId),
    );
  }
}
