// @ts-ignore
import { ServiceType } from "@gymflow/common";
import { MembershipBean } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMembershipCreate({
  api,
}: {
  api: {
    serviceApi: {
      createRecurring: (
        newMembership: unknown,
        termsAndConditionsBlob: unknown,
      ) => Promise<any>;
      createPrePaid: (
        newMembership: unknown,
        termsAndConditionsBlob: unknown,
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (newMembership: {
      [k: string]: any;
    }): Promise<{ data: MembershipBean }> => {
      switch (newMembership["serviceType"]) {
        case ServiceType.Recurring:
          return api.serviceApi.createRecurring(
            omit(newMembership, ["termsConditions", "serviceType"]),
            newMembership["termsConditions"],
          );
        case ServiceType.Prepaid:
          return api.serviceApi.createPrePaid(
            omit(newMembership, ["termsConditions", "serviceType"]),
            newMembership["termsConditions"],
          );
        default:
          throw new Error("Unknown service type");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });

  return mutation;
}
