import { PromotionPostDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { promotionQueryKeys } from "./promotionQueryKeys";

export function usePromotionEdit({
  api,
}: {
  api: {
    promotionApi: {
      update: (arg0: number, arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      promotionId,
      patchedFields,
    }: {
      promotionId: number;
      patchedFields: Partial<PromotionPostDTO>;
    }) => {
      await api.promotionApi.update(promotionId, patchedFields);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: promotionQueryKeys.all() });
    },
  });
  return mutation;
}
