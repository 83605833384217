import { NoteDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { appointmentQueryKeys } from "../appointment";

export function useNoteCreateForAppointment(
  {
    api,
  }: {
    api: {
      noteApi: {
        createAppointmentNote: (
          fields: { content: string; appointmentId?: number },
          file?: File,
        ) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    NoteDTO,
    unknown,
    NoteCreateForAppointmentParams
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ fields, file }: NoteCreateForAppointmentParams) => {
      const response = await api.noteApi.createAppointmentNote(fields, file);
      return response.data as NoteDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return mutation;
}

interface NoteCreateForAppointmentParams {
  fields: { content: string; appointmentId?: number };
  file?: File;
}
