import { onlyPhoneNumbersProps } from "@gymflow/common";
import { parsePhoneNumber, phoneUtil } from "@gymflow/validation";
import { useFormikContext } from "formik";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useState } from "react";

import { useClubSettings } from "../../providers";
import { FormikTextInput } from "./FormikTextInput";
import { PaginatedSelect } from "./PaginatedSelect";

const utilInstance = PhoneNumberUtil.getInstance();
const phoneNumberOptions = utilInstance.getSupportedRegions().map((region) => ({
  value: `+${utilInstance.getCountryCodeForRegion(region)}`,
  label: `${region}(+${utilInstance.getCountryCodeForRegion(region)})`,
}));

export function FormikPhoneInput({ id, name }: { name: string; id: string }) {
  const { values, setFieldValue, touched, errors } = useFormikContext();
  const { phone_number_country } = useClubSettings();
  const phoneNumber = parsePhoneNumber((values as any)[id] ?? "");

  const [prefix, setPrefix] = useState(
    `+${
      phoneNumber?.getCountryCode() ??
      phoneUtil.getCountryCodeForRegion(
        JSON.parse(phone_number_country)[0] ?? "",
      )
    }`,
  );
  const [number, setNumber] = useState(
    `${phoneNumber?.getNationalNumber() ?? ""}`,
  );

  const hasErrors = (touched as any)[name] && (errors as any)[name];
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <PaginatedSelect
          isSearchable
          className="-mr-px w-16 !rounded-lg !rounded-r-none !border !border-gray-300 px-0 text-sm !text-gray-500 placeholder:!text-base placeholder:!font-normal placeholder:!text-gray-500"
          loadOptions={async (term) => {
            return {
              options: phoneNumberOptions.filter((option) =>
                option.label.toLowerCase().includes(term.toLowerCase()),
              ),
            };
          }}
          value={{
            value: prefix,
            label: prefix,
          }}
          onChange={({ value }) => {
            setPrefix(value);
            if (number) {
              setFieldValue(id, value + number);
            } else {
              setFieldValue(id, undefined);
            }
          }}
        />
        <FormikTextInput
          name={name}
          id={id}
          type="tel"
          className="w-full rounded-l-none"
          value={number}
          onChange={(e) => {
            const newText = e.currentTarget.value;
            setNumber(newText);
            if (newText) {
              setFieldValue(id, prefix + newText);
            } else {
              setFieldValue(id, undefined);
            }
          }}
          hideErrors
          {...onlyPhoneNumbersProps}
        />
      </div>
      {hasErrors && (
        <div className="flex w-full">
          <div className="text-error-600 ml-3.5 mt-2">
            {(errors as any)[name]}
          </div>
        </div>
      )}
    </div>
  );
}
