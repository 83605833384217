import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { reportQueryKeys } from "../report";
import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationDeleteLead(
  {
    api,
  }: {
    api: {
      leadApi: {
        delete: (leadId: number) => Promise<{ data: void }>;
      };
    };
  },

  mutationOpts?: UseMutationOptions<void, unknown, { leadId: number }>,
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ leadId }: { leadId: number }) => {
      await api.leadApi.delete(leadId);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.lead() });
    },

    ...mutationOpts,
  });
}
