import { AppointableCategoryDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointableQueryKeys } from "./appointableQueryKeys";

export function useAppointableCategoryCreate({
  api,
}: {
  api: {
    appointableApi: {
      createCategory: (
        fields: Omit<AppointableCategoryDTO, "id">,
      ) => Promise<{ data: AppointableCategoryDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      newAppointableCategory: Omit<AppointableCategoryDTO, "id">,
    ) => {
      const response = await api.appointableApi.createCategory(
        newAppointableCategory,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointableQueryKeys.categoriesAll() });
    },
  });

  return mutation;
}
