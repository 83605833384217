import { UserMemberSubscriptionBean } from "@gymflow/types";
import React from "react";

export interface NotPrimaryMembershipWarningProps {
  membership: UserMemberSubscriptionBean;
}

export const NotPrimaryMembershipWarning: React.FC<
  NotPrimaryMembershipWarningProps
> = ({ membership }) => {
  if (membership.id === membership.primarySubscriptionId) {
    return null;
  }
  return (
    <div className="text-warning-500">
      You&apos;re editing a shared secondary membership. Changes are not applied
      to the primary membership.
    </div>
  );
};
