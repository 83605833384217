import { useMutationKisiInitialize } from "@gymflow/api";
import { HttpStatusCode } from "axios";
import { useContext, useState } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import {
  KisiGroupToFacilityPair,
  KisiGroupToMembershipPair,
  KisiIntegrationStep1,
  KisiIntegrationStep2,
  KisiIntegrationStep3,
} from "../organisms";
import { StepWizard } from "../organisms/StepWizard";
import { SettingsRoute } from "../Settings/SettingsRoute";

export function KisiIntegrationWizard() {
  const { push } = useHistory();
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const kisiInitializeMutation = useMutationKisiInitialize({ api });
  const [submitError, setSubmitError] = useState("");
  const toast = useContext(ToastContext);

  return (
    <StepWizard
      submitButtonText="Sync"
      onSubmit={async ({ wizardState }) => {
        const membershipToGroupMap = (
          wizardState["membershipToGroupPairs"] as KisiGroupToMembershipPair[]
        ).reduce(
          (prev, pair) => {
            pair.memberships.forEach((m) => {
              const key = (m.membershipId as number).toString();
              prev[key] = pair.kisiGroupId as string;
            });
            return prev;
          },
          {} as { [k: string]: string },
        );
        const facilityToGroupMap = (
          wizardState["facilityToGroupPairs"] as KisiGroupToFacilityPair[]
        ).reduce(
          (prev, pair) => {
            pair.facilities.forEach((m) => {
              const key = (m.facilityId as number).toString();
              prev[key] = pair.kisiGroupId as string;
            });
            return prev;
          },
          {} as { [k: string]: string },
        );
        try {
          await kisiInitializeMutation.mutateAsync({
            clubId,
            kisiPlaceId: wizardState["kisiPlace"].id,
            membershipToGroupMap,
            facilityToGroupMap,
          });
        } catch (e: any) {
          if (e.status === HttpStatusCode.RequestTimeout) {
            toast.toast({
              message:
                "The integration setup is taking longer than expected. Check the status of it later by refreshing this page.",
              intent: "warning",
            });

            push(
              createClubLink(
                RouteLayout.Staff,
                RouteFeature.Settings,
                SettingsRoute.Integrations,
              ),
            );
            return false;
          } else {
            setSubmitError(
              e?.response?.data?.error_message || "Integration error.",
            );
            return false;
          }
        }
        return true;
      }}
      steps={[
        {
          title: "Activate Integration",
          component: KisiIntegrationStep1,
          onValidate: (params) => {
            return !!params.wizardState?.["kisiPlace"]?.id;
          },
        },
        {
          title: "Associate Groups",
          explanation: `Associate access groups with Gymflow memberships.
        If you have more than one access group click "Add Group" to create
        a second or more group associations.`,
          component: KisiIntegrationStep2,
          onValidate: (params) => {
            const isMembershipMapValid = params.wizardState?.[
              "membershipToGroupPairs"
            ]?.every((pair: KisiGroupToMembershipPair) => {
              return (
                pair.kisiGroupId &&
                pair.memberships.every((m) => m.membershipId)
              );
            });
            const isFacilityMapValid = params.wizardState?.[
              "facilityToGroupPairs"
            ]?.every((pair: KisiGroupToFacilityPair) => {
              return (
                pair.kisiGroupId && pair.facilities.every((m) => m.facilityId)
              );
            });
            return !!isMembershipMapValid && !!isFacilityMapValid;
          },
        },
        {
          title: "Sync Members",
          component: KisiIntegrationStep3,
          props: {
            submitError,
          },
        },
      ]}
    />
  );
}
