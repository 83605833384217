import { range } from "@gymflow/helpers";
import { Transition } from "@headlessui/react";
import { get } from "lodash";
import isPlainObject from "lodash/isPlainObject";
import toPairs from "lodash/toPairs";
import { useEffect, useState } from "react";

export * from "./constants";

function RenderErrorRow({ fieldErrors, wasTouched }) {
  const [currentError, setCurrentError] = useState(fieldErrors);
  useEffect(() => {
    if (fieldErrors) {
      setCurrentError(fieldErrors);
    }
  }, [fieldErrors]);
  return (
    <Transition
      className="transition-all duration-200 ease-in-out"
      show={!!fieldErrors && !!wasTouched}
      enter="transition-all ease-in duration-300"
      enterFrom="opacity-0 translate-y-2"
      enterTo="opacity-100 translate-y-0"
      leave="transition-all ease-out duration-300"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-2"
      afterLeave={() => setCurrentError(undefined)}
    >
      <label className="error text-error-500 mb-0">{currentError}</label>
    </Transition>
  );
}

export const fieldHasErrors = (fieldName, touched, errors) =>
  get(touched, fieldName) && get(errors, fieldName);

export function renderErrorRowOnTouch(fieldName, touched, errors) {
  const fieldErrors = get(errors, fieldName);

  return (
    <RenderErrorRow
      wasTouched={fieldHasErrors(fieldName, touched, errors)}
      fieldErrors={fieldErrors}
    />
  );
}

export const whitelistOnKeyPress = (evt, whitelist = []) => {
  if (!whitelist.includes(evt.key)) {
    evt.preventDefault();
  }
};

export const whitelistOnPaste = (evt, whitelist = []) => {
  evt.preventDefault();
  evt.stopPropagation();
  const paste = (evt.clipboardData || window.clipboardData).getData("text");

  const valid = [...paste].reduce((previous, current) => {
    if (whitelist.includes(current)) {
      return previous + current;
    }
    return previous;
  }, "");

  const newValue =
    evt.target.value.slice(0, evt.target.selectionStart) +
    valid +
    evt.target.value.slice(evt.target.selectionEnd);

  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    "value",
  ).set;
  nativeInputValueSetter.call(evt.target, newValue);

  const ev2 = new Event("input", { bubbles: true });
  evt.target.dispatchEvent(ev2);
};

const numberChars = [...range(0, 9).map((k) => k.toString()), "."];
export const onlyNumbersProps = {
  onKeyPress: (evt) => whitelistOnKeyPress(evt, numberChars),
  onPaste: (evt) => whitelistOnPaste(evt, numberChars),
};

const integerChars = [...range(0, 9).map((k) => k.toString())];
export const onlyIntegersProps = {
  onKeyPress: (evt) => whitelistOnKeyPress(evt, integerChars),
  onPaste: (evt) => whitelistOnPaste(evt, integerChars),
};

const phoneNumbersChars = [...range(0, 9).map((k) => k.toString())];
export const onlyPhoneNumbersProps = {
  onKeyPress: (evt) => whitelistOnKeyPress(evt, phoneNumbersChars),
  onPaste: (evt) => whitelistOnPaste(evt, phoneNumbersChars),
};

const ukBankSortCodeChars = [...range(0, 9).map((k) => k.toString()), "-"];
export const onlyBankSortCodeProps = {
  onKeyPress: (evt) => whitelistOnKeyPress(evt, ukBankSortCodeChars),
  onPaste: (evt) => whitelistOnPaste(evt, ukBankSortCodeChars),
};

export const getChangedFields = (initial, changed) =>
  toPairs(changed).reduce((acc, [key, value]) => {
    if (Array.isArray(initial[key]) || isPlainObject(initial[key])) {
      if (JSON.stringify(initial[key]) !== JSON.stringify(value)) {
        acc[key] = value;
      }
    } else if (initial[key] !== value) {
      acc[key] = value;
    }
    return acc;
  }, {});

export { default as UserMemberFormMapper } from "../../formMappers/UserMemberFormMapper";
export { default as FormMapper } from "./FormMapper/FormMapper";
export { default as TwoWayObjectMapper } from "./FormMapper/TwoWayObjectMapper";
