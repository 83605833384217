import { SubscriptionStatus } from "@gymflow/common";
import { UserMemberBean } from "@gymflow/types";

export const hasActiveMembershipSubscription = (
  subscriptions: UserMemberBean["subscriptions"],
) => {
  if (!subscriptions) {
    return false;
  }

  return subscriptions.some((sub) => {
    if (
      sub.membershipBean &&
      [
        SubscriptionStatus.Pending,
        SubscriptionStatus.Overdue,
        SubscriptionStatus.Active,
        SubscriptionStatus.Paused,
      ].includes(sub.status)
    ) {
      return true;
    }

    return false;
  });
};
