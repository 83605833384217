import {
  DATE_FORMAT,
  FormMapper,
  isTimeBefore,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_ONLY_FORMAT,
  PARAMETER_TIME_FORMAT,
  RecurringType,
} from "@gymflow/common";
import moment from "moment-timezone";

import {
  ACTIVITY_ID,
  CAPACITY,
  CREDIT_COST,
  END_DATE,
  END_TIME,
  FACILITY_ID,
  HOST_MEMBER_ID,
  IS_BOOKABLE,
  IS_FULL_DAY_EVENT,
  IS_PUBLIC,
  IS_RECURRING,
  RECURRING_END_DATE,
  RECURRING_INTERVAL,
  RECURRING_TYPE,
  RECURRING_WEEKDAYS,
  START_DATE,
  START_TIME,
  WAITING_LIST_CAPACITY,
} from "./OccurrenceSchema";

export class EventFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "startDate",
          transform: (date: string) =>
            moment(date, PARAMETER_DATE_FORMAT).format(DATE_FORMAT),
        },
        {
          key: "endRecurringDate",
          transform: (date: string) =>
            moment(date, PARAMETER_DATE_ONLY_FORMAT).format(DATE_FORMAT),
        },
      ],
      outValue: [
        {
          key: START_DATE,
          transform: (date: string) =>
            moment(date, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
        },
        {
          key: START_TIME,
          transform: (time: string) =>
            moment(time, "h:mm a").format("HH:mm:ss"),
        },
        {
          key: END_DATE,
          transform: (_: never, otherValues: Record<string, any>) => {
            if (isTimeBefore(otherValues[START_TIME], otherValues[END_TIME])) {
              return moment(otherValues[START_DATE], DATE_FORMAT).format(
                PARAMETER_DATE_ONLY_FORMAT,
              );
            }
            return moment(otherValues[START_DATE], DATE_FORMAT)
              .add(1, "day")
              .format(PARAMETER_DATE_ONLY_FORMAT);
          },
        },
        {
          key: END_TIME,
          transform: (time: string) =>
            moment(time, "h:mm a").format("HH:mm:ss"),
        },
        {
          key: RECURRING_END_DATE,
          transform: (value: string) =>
            value
              ? moment(value, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT)
              : undefined,
        },
      ],
      outKey: {
        [HOST_MEMBER_ID]: "userEventHostId",
      },
    });
  }

  override to(values: Record<string, any>) {
    const transformed = super.to(values) as Record<string, any>;

    transformed[ACTIVITY_ID] = values["event"].activity.id;
    transformed[FACILITY_ID] = values["event"].facility.id;
    transformed[HOST_MEMBER_ID] = values["event"].userEventHost.id;
    transformed[IS_FULL_DAY_EVENT] = values["event"].isFullDayEvent;
    transformed[START_TIME] = moment(
      values["startDate"],
      PARAMETER_DATE_FORMAT,
    ).format("h:mm a");
    transformed[END_TIME] = moment(
      values["endDate"],
      PARAMETER_DATE_FORMAT,
    ).format("h:mm a");
    transformed[IS_BOOKABLE] = values["event"].isBookable;
    transformed[CREDIT_COST] = values["event"].sessionCost;
    transformed[CAPACITY] = values["event"].capacity;
    transformed[WAITING_LIST_CAPACITY] = values["event"].waitListCapacity;
    transformed[IS_RECURRING] = values["event"].isRecurring;
    transformed[RECURRING_INTERVAL] = values["event"].recurrencePeriod;
    transformed[RECURRING_END_DATE] = values["event"].endRecurringDate
      ? moment(
          values["event"].endRecurringDate,
          PARAMETER_DATE_ONLY_FORMAT,
        ).format(DATE_FORMAT)
      : undefined;
    transformed[RECURRING_TYPE] = values["event"].recurrenceType;
    transformed[RECURRING_WEEKDAYS] = values["event"].weekDays;
    transformed[IS_PUBLIC] = values["event"].isPublic;
    delete transformed["booked-count"];
    delete transformed["waiting-count"];

    return transformed;
  }

  override from(formValues: Record<string, any>) {
    const dependentFields = {
      [IS_RECURRING]: [
        RECURRING_INTERVAL,
        RECURRING_END_DATE,
        RECURRING_TYPE,
        RECURRING_WEEKDAYS,
      ],
      [IS_BOOKABLE]: [CREDIT_COST, CAPACITY, WAITING_LIST_CAPACITY],
    } as Record<string, string[]>;

    const filteredValues = Object.keys(formValues).reduce(
      (acc, key) => {
        const fieldsWithDependencies = Object.keys(dependentFields);
        if (fieldsWithDependencies.includes(key) && !acc[key]) {
          dependentFields[key].forEach((d) => delete acc[d]);
        }
        return acc;
      },
      { ...formValues },
    );

    if (filteredValues[RECURRING_TYPE] !== RecurringType.Weekly) {
      delete filteredValues[RECURRING_WEEKDAYS];
    }

    if (filteredValues[IS_FULL_DAY_EVENT]) {
      filteredValues[START_TIME] = moment(
        filteredValues[START_TIME],
        PARAMETER_TIME_FORMAT,
      )
        .startOf("day")
        .format(PARAMETER_TIME_FORMAT);
      filteredValues[END_TIME] = moment(
        filteredValues[START_TIME],
        PARAMETER_TIME_FORMAT,
      )
        .endOf("day")
        .format(PARAMETER_TIME_FORMAT);
    }

    const transformed = super.from(filteredValues) as Record<string, any>;

    if (filteredValues[IS_RECURRING]) {
      transformed["startRecurringDate"] = transformed["startDate"];
    }

    delete transformed["event"];
    delete transformed["eventRsvpList"];
    delete transformed["id"];
    delete transformed["status"];
    return transformed;
  }
}
