import { LeadSourceCreateDTO, LeadSourceDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { leadSourceQueryKeys } from "./leadSourceQueryKeys";

export function useLeadSourceCreate({
  api,
}: {
  api: {
    leadSourceApi: {
      create: (arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newLeadSource: LeadSourceCreateDTO) => {
      const response = await api.leadSourceApi.create(newLeadSource);
      return response.data as LeadSourceDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadSourceQueryKeys.all() });
    },
  });

  return mutation;
}
