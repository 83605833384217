import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicClubApi = (apiUrl, clubId) => {
  const clubPrefix = `club`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicClub}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, {
    findById(id) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );
      return state.http.get(`${state.baseUrl}/${id}/hosted`);
    },
    settings() {
      return state.http.get(`${state.baseUrl}/${clubId}/hosted/settings`);
    },
    properties() {
      return state.http.get(`${state.baseUrl}/${clubId}/hosted/properties`);
    },
    propertiesSettings() {
      return state.http.get(
        `${state.baseUrl}/${clubId}/hosted/properties-settings`,
      );
    },
    featureFlags(clubId) {
      return state.http.get(`${state.baseUrl}/${clubId}/hosted/feature-flags`);
    },
  });
};

export default publicClubApi;
