import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

export type TabType<T extends string> = {
  id: T;
  label: ReactNode;
  labelClassName?: string;
};

type TabsProps<T extends string> = {
  tabs: TabType<T>[];
  activeTabId: T;
  setActiveTabId: (id: T) => void;
};

export const Tabs = <T extends string>({
  tabs,
  activeTabId,
  setActiveTabId,
}: TabsProps<T>) => (
  <div className="flex min-h-[2.75rem] flex-row gap-x-2 overflow-x-auto overflow-y-hidden border-b border-gray-200">
    {tabs.map(({ id, label, labelClassName }) => (
      <div
        key={id}
        onClick={() => setActiveTabId(id)}
        className={cn(
          "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 flex pt-3",
          {
            "border-secondary-500 border-b-2 ": activeTabId === id,
          },
        )}
      >
        <div
          className={cn("text-sm font-semibold text-gray-500", labelClassName, {
            "text-secondary-500": activeTabId === id,
          })}
        >
          {label}
        </div>
      </div>
    ))}
  </div>
);
