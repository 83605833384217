import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { reportHomeRoutes } from "../../views/reports/reportHomeRoutes";
import { ReportContext } from "./ReportContext";
import { ReportEventAttendanceWidget } from "./Widgets/Events/Attendance";
import { ReportEventBookingPercentageWidget } from "./Widgets/Events/BookingPercentage";
import { ReportClassesRunWidget } from "./Widgets/Events/ClassesRun";
import { ReportLateCancellationsWidget } from "./Widgets/Events/LateCancellations";
import { ReportNoShowsWidget } from "./Widgets/Events/NoShows";

export function Events() {
  const { createClubLink } = usePortalRoutes();
  const eventsRoute = createClubLink(
    RouteLayout.Staff,
    RouteFeature.Reports,
    reportHomeRoutes.Events,
  );
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const location = useLocation();

  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);

  const { data, isFetching } = useStatistic({
    api,
    clubId: settings.clubId,
    start:
      dates?.startDate &&
      moment(dates.startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end:
      dates?.endDate &&
      moment(dates.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),

    trendPeriod: "MONTH",
    categories: ["event"],
    eventFields: [
      "attendance",
      "bookingPercentage",
      "classesRun",
      "noShows",
      "lateCancellations",
    ],
  });

  return (
    <ReportContext.Provider
      value={{
        isLoading: isFetching,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        events: data?.event && {
          attendance: {
            total: data.event.attendance!.total!,
            variation: data.event.attendance!.var,
          },
          bookingPercentage: {
            total: data.event.bookingPercentage!.total!,
            variation: data.event.bookingPercentage!.var,
          },
          classesRun: {
            total: data.event.classesRun!.total!,
            variation: data.event.classesRun!.var,
          },
          noShows: {
            total: data.event.noShows!.total!,
            variation: data.event.noShows!.var,
          },
          lateCancellations: {
            total: data.event.lateCancellations!.total!,
            variation: data.event.lateCancellations!.var,
          },
        },
      }}
    >
      <div className="mx-[-.75rem] mb-4 flex flex-wrap">
        <ReportEventAttendanceWidget parentRoute={eventsRoute} />
        <ReportEventBookingPercentageWidget />
        <ReportClassesRunWidget parentRoute={eventsRoute} />
        <ReportNoShowsWidget parentRoute={eventsRoute} />
        <ReportLateCancellationsWidget parentRoute={eventsRoute} />
      </div>
    </ReportContext.Provider>
  );
}
