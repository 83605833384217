import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner, useUserFormFieldConfiguration } from "@gymflow/common";
import classNames from "classnames";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { SlideSideBar, Switch } from "../atoms";
import { SideBarLeadForm, SideBarUserForm } from "../molecules";

type MemberAndLeadSharedFields = {
  email?: string;
  "first-name"?: string;
  "last-name"?: string;
  "mobile-number"?: string;
  source?: { name: string; id: number };
};

type Tab = "MEMBER" | "LEAD";
type CreationMode = "BOTH" | Tab;

type OnCloseArg =
  | { userType: "MEMBER"; userMemberId: string }
  | { userType: "LEAD"; leadId: number };

export interface NewUserSideBarProps {
  isOpened: boolean;
  onClose?: (arg?: OnCloseArg) => Promise<void>;
  hide: () => void;
  creationMode: CreationMode;
  defaultFormValues?: MemberAndLeadSharedFields;
  selectedTab: Tab;
  setSelectedTab: (tab: Tab) => void;
}

interface NewUserSideBarProviderContextType {
  open: (options?: {
    onClose?: (arg?: OnCloseArg) => Promise<void>;
    creationMode?: CreationMode;
    defaultFormValues?: MemberAndLeadSharedFields;
  }) => void;
}

export const NewUserSideBarProviderContext =
  createContext<NewUserSideBarProviderContextType>({} as any);

export function NewUserSidebarProvider({ children }: { children: ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const onCloseRef = useRef((arg?: OnCloseArg) => Promise.resolve());
  const [availableForms, setAvailableForms] = useState<CreationMode>("BOTH");
  const [defaultFormValues, setDefaultDefault] =
    useState<MemberAndLeadSharedFields>({});
  const [selectedTab, setSelectedTab] = useState<Tab>("MEMBER");
  return (
    <NewUserSideBarProviderContext.Provider
      value={{
        open: ({ onClose, creationMode, defaultFormValues } = {}) => {
          if (defaultFormValues) {
            setDefaultDefault(defaultFormValues);
          }
          setIsOpen(true);
          setAvailableForms(creationMode || "BOTH");
          if (!!creationMode && creationMode !== "BOTH") {
            setSelectedTab(creationMode);
          }
          if (onClose) {
            onCloseRef.current = onClose;
          }
        },
      }}
    >
      <NewUserSideBar
        isOpened={isOpen}
        hide={() => setIsOpen(false)}
        defaultFormValues={defaultFormValues}
        onClose={async (arg) => {
          setDefaultDefault({});
          return await onCloseRef.current(arg);
        }}
        creationMode={availableForms}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {children}
    </NewUserSideBarProviderContext.Provider>
  );
}

export const NewUserSideBar: React.FC<NewUserSideBarProps> = ({
  isOpened,
  hide,
  onClose = (arg?: OnCloseArg) => {},
  creationMode,
  defaultFormValues,
  selectedTab,
  setSelectedTab,
}) => {
  const [commonValues, setCommonValues] = useState<MemberAndLeadSharedFields>(
    defaultFormValues ?? {},
  );
  useEffect(() => {
    if (isOpened) {
      setCommonValues(defaultFormValues ?? {});
    }
  }, [isOpened, defaultFormValues]);

  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { data: requiredFields, isLoading: isLoadingRequiredFields }: any =
    useUserFormFieldConfiguration(
      {
        api,
        clubId,
      },
      { enabled: isOpened && selectedTab === "MEMBER" },
    );

  const isRequiredFieldsLoaded = Object.keys(requiredFields).length > 0;

  const onChangeCommonValues = useCallback((newValues: any) => {
    setCommonValues({
      email: newValues?.email,
      "first-name": newValues["first-name"],
      "last-name": newValues["last-name"],
      "mobile-number": newValues["mobile-number"],
      source: newValues.source,
    });
  }, []);

  return (
    <SlideSideBar
      isOpen={isOpened}
      hide={() => {
        hide();
        setCommonValues({});
        onClose();
      }}
    >
      <div className="flex h-full flex-col overflow-hidden">
        <div className="mt-4 flex flex-row items-center justify-between px-6">
          <div className="text-xl font-semibold text-gray-900">
            Create New {creationMode === "LEAD" ? "Lead" : "User"}
          </div>
          <FontAwesomeIcon
            onClick={() => {
              hide();
              setCommonValues({});
              onClose();
            }}
            className="cursor-pointer text-xl text-gray-600"
            icon={faClose}
          />
        </div>
        <div className="mt-4 flex px-6 text-sm font-normal text-gray-600">
          {creationMode === "BOTH" || creationMode === "MEMBER"
            ? `Creates a new user, all new users are created as Leads with a lead
          status of "New Lead". Optionally send the user an account setup email
          which enables them to sign into the app and make purchases and
          bookings.`
            : `Creates a new lead with a status of "New Lead".`}
        </div>

        <div
          className={classNames("mt-4 flex flex-row gap-2 px-6", {
            hidden: creationMode !== "BOTH",
          })}
        >
          <div>
            <Switch
              label={""}
              value={selectedTab === "MEMBER"}
              onChange={function (checked) {
                if (checked) {
                  setSelectedTab("MEMBER");
                } else {
                  setSelectedTab("LEAD");
                }
              }}
            />
          </div>

          <div>Send account setup email</div>
        </div>
        <div className="mt-4 flex px-6 text-sm font-normal text-gray-600">
          All fields are required
        </div>
        {selectedTab === "MEMBER" && isOpened && isLoadingRequiredFields && (
          <Spinner />
        )}
        {selectedTab === "MEMBER" && isOpened && isRequiredFieldsLoaded && (
          <SideBarUserForm
            hide={() => {
              hide();
              setCommonValues({});
              onClose();
            }}
            onMemberCreated={(newMemberId) => {
              onClose({ userType: "MEMBER", userMemberId: newMemberId });
            }}
            onChange={onChangeCommonValues}
            defaultValues={commonValues}
            requiredFields={requiredFields}
          />
        )}
        {selectedTab === "LEAD" && isOpened && (
          <SideBarLeadForm
            onChange={onChangeCommonValues}
            defaultValues={commonValues}
            hide={() => {
              hide();
              setCommonValues({});
              onClose();
            }}
            onLeadCreated={(newLeadId) => {
              onClose({ userType: "LEAD", leadId: newLeadId });
            }}
          />
        )}
      </div>
    </SlideSideBar>
  );
};
