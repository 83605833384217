import { LeadDTOItem, LeadPostDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { reportQueryKeys } from "../report";
import { leadQueryKeys } from "./leadQueryKeys";

export function useLeadCreate(
  {
    api,
  }: {
    api: {
      leadApi: {
        create: (newLead: LeadPostDTO) => Promise<any>;
      };
    };
  },

  mutationOpts?: UseMutationOptions<LeadDTOItem, unknown, LeadPostDTO>,
) {
  const queryClient = useQueryClient();

  const createMutation = useMutation({
    mutationFn: async (newLead) => {
      const result = await api.leadApi.create(newLead);

      return result.data as LeadDTOItem;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.lead() });
    },

    ...mutationOpts,
  });

  return createMutation;
}
