import {
  FacilityAvailabilityDTO,
  FacilityAvailabilitySlot,
} from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityEdit({
  api,
}: {
  api: {
    availabilityApi: {
      saveFacilityAvailability: (
        facilityId: number,
        availableForAppointments: boolean,
        availabilitySlotList: FacilityAvailabilitySlot[],
      ) => Promise<{ data: FacilityAvailabilityDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      availableForAppointments,
      availabilitySlotList,
    }: {
      facilityId: number;
      availableForAppointments: boolean;
      availabilitySlotList: FacilityAvailabilitySlot[];
    }) => {
      const response = await api.availabilityApi.saveFacilityAvailability(
        facilityId,
        availableForAppointments,
        availabilitySlotList,
      );
      return response.data;
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.facilityAvailability(facilityId),
      });
    },
  });

  return mutation;
}
