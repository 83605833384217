import { z } from "zod";

export const MembershipProrataChoice = {
  Charge: "YES",
  Waive: "WAIVE",
  No: "NO",
} as const;

export const MembershipProrataChoiceZodSchema = z
  .enum([
    MembershipProrataChoice.Charge,
    MembershipProrataChoice.Waive,
    MembershipProrataChoice.No,
  ])
  .default(MembershipProrataChoice.No);
