import { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Form, Label, Row } from "reactstrap";

import DropdownFilter from "../../DropdownFilter";
import LinkBox from "../../forms/LinkBox";
import useClipboard from "../../../hooks/useClipboard";
import { useIframeEmbed } from "../../../hooks/useIframeEmbed";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { RouteLayout } from "../../../routes/layout";
import { RouteFeature } from "../../../routes/feature";

const MEMBERSHIP_SALES = "membership-sales-link";
const CREDIT_PACK_SALES = "credit-pack-sales-link";
const TIMETABLE = "timetable";
const ENQUIRE = "enquire";
const CUSTOMER_LOGIN = "customer-login";
const GUEST_REGISTRATION = "guest-registration-link";
const KIOSK = "kiosk";

const MEMBERSHIP_SALES_EMBED = "membership-sales-embed";
const CREDIT_PACK_SALES_EMBED = "credit-pack-sales-embed";
const TIMETABLE_EMBED = "timetable-embed";
const ENQUIRE_EMBED = "enquire-embed";
const GUEST_REGISTRATION_EMBED = "guest-registration-embed";

const WEB_LINKS = {
  MEMBERSHIPS: "Memberships",
  SESSION_PACKS: "Credit Packs",
  TIMETABLE: "Timetable",
  ENQUIRY: "Enquiry",
  CUSTOMER_LOGIN: "Customer Login",
  GUEST_REGISTRATION: "Guest Registration",
  KIOSK: "Check In Kiosk",
};

const WEB_LINKS_OPTIONS = Object.keys(WEB_LINKS).map((key) => ({ label: WEB_LINKS[key], value: key }));

export function Links() {
  const { createAbsoluteClubLink } = usePortalRoutes();
  const [linkFor, setLinkFor] = useState(WEB_LINKS_OPTIONS[0]);

  const iframeEmbed = useIframeEmbed();

  const { copyField: copyToClipboard } = useClipboard();

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">
          <div>Website Integration</div>
          <div className="text-muted">Use web links or embedded code to add functionality to your website.</div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form className="form-horizontal" role="form">
          <Row>
            <Col>
              <Label tag="h3" className="text-muted text-uppercase">
                Get Link For
              </Label>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={6}>
              <DropdownFilter options={WEB_LINKS_OPTIONS} value={linkFor} onChange={(value) => setLinkFor(value)} />
            </Col>
          </Row>
          <div className="mt-lg-20 mt-3">
            {linkFor.label === WEB_LINKS.MEMBERSHIPS && (
              <>
                <Row>
                  <Col>
                    <LinkBox
                      name={MEMBERSHIP_SALES}
                      actionText="Copy Link"
                      link={createAbsoluteClubLink(RouteLayout.Link, RouteFeature.LinkBuyMembership)}
                      onCopy={() => copyToClipboard(MEMBERSHIP_SALES)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <LinkBox
                      name={MEMBERSHIP_SALES_EMBED}
                      actionText="Copy Code"
                      link={iframeEmbed(RouteFeature.SiteBuyMembership)}
                      onCopy={() => copyToClipboard(MEMBERSHIP_SALES_EMBED)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {linkFor.label === WEB_LINKS.SESSION_PACKS && (
              <>
                <Row>
                  <Col>
                    <LinkBox
                      name={CREDIT_PACK_SALES}
                      actionText="Copy Link"
                      link={createAbsoluteClubLink(RouteLayout.Link, RouteFeature.LinkBuySessionPack)}
                      onCopy={() => copyToClipboard(CREDIT_PACK_SALES)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <LinkBox
                      name={CREDIT_PACK_SALES_EMBED}
                      actionText="Copy Code"
                      link={iframeEmbed(RouteFeature.SiteBuySessionPack)}
                      onCopy={() => copyToClipboard(CREDIT_PACK_SALES_EMBED)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {linkFor.label === WEB_LINKS.TIMETABLE && (
              <>
                <Row>
                  <Col>
                    <LinkBox
                      name={TIMETABLE}
                      actionText="Copy Link"
                      link={createAbsoluteClubLink(RouteLayout.Link, RouteFeature.LinkTimetable)}
                      onCopy={() => copyToClipboard(TIMETABLE)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <LinkBox
                      name={TIMETABLE_EMBED}
                      actionText="Copy Code"
                      link={iframeEmbed(RouteFeature.SiteTimetable)}
                      onCopy={() => copyToClipboard(TIMETABLE_EMBED)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {linkFor.label === WEB_LINKS.ENQUIRY && (
              <>
                <Row>
                  <Col>
                    <LinkBox
                      name={ENQUIRE}
                      actionText="Copy Link"
                      link={createAbsoluteClubLink(RouteLayout.Link, RouteFeature.LinkEnquiry)}
                      onCopy={() => copyToClipboard(ENQUIRE)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <LinkBox
                      name={ENQUIRE_EMBED}
                      actionText="Copy Code"
                      link={iframeEmbed(RouteFeature.SiteEnquiry)}
                      onCopy={() => copyToClipboard(ENQUIRE_EMBED)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {linkFor.label === WEB_LINKS.CUSTOMER_LOGIN && (
              <Row>
                <Col>
                  <LinkBox
                    name={CUSTOMER_LOGIN}
                    actionText="Copy Link"
                    link={createAbsoluteClubLink(RouteLayout.Member)}
                    onCopy={() => copyToClipboard(CUSTOMER_LOGIN)}
                  />
                </Col>
              </Row>
            )}
            {linkFor.label === WEB_LINKS.GUEST_REGISTRATION && (
              <>
                <Row>
                  <Col>
                    <LinkBox
                      name={GUEST_REGISTRATION}
                      actionText="Copy Link"
                      link={createAbsoluteClubLink(RouteLayout.Link, RouteFeature.LinkGuestRegistration)}
                      onCopy={() => copyToClipboard(GUEST_REGISTRATION)}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <LinkBox
                      name={GUEST_REGISTRATION_EMBED}
                      actionText="Copy Code"
                      link={iframeEmbed(RouteFeature.SiteGuestRegistration)}
                      onCopy={() => copyToClipboard(GUEST_REGISTRATION_EMBED)}
                    />
                  </Col>
                </Row>
              </>
            )}
            {linkFor.label === WEB_LINKS.KIOSK && (
              <Row>
                <Col>
                  <LinkBox
                    name={KIOSK}
                    actionText="Copy Link"
                    link={createAbsoluteClubLink(RouteLayout.Kiosk, RouteFeature.KioskCheckIn)}
                    onCopy={() => copyToClipboard(KIOSK)}
                  />
                </Col>
              </Row>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
}
