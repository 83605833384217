import { LeadDTO, UserMemberBean } from "@gymflow/types";
import { createContext } from "react";

export type ShopSidebarContextType = {
  showShop: (user?: UserMemberBean | LeadDTO) => Promise<void>;
};

export const ShopSidebarContext = createContext<ShopSidebarContextType>({
  showShop: () => {
    throw new Error("ShopSidebarContext is not initialized");
  },
});
