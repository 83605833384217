import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import moment from "moment";
import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../../../hooks";
import { RouteFeature, RouteLayout } from "../../../../routes";
import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportNoVisitsWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const data = useContext(ReportContext);

  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  return (
    <ReportWidgetCard
      title="Members with No Visits"
      value={(data.visit?.noVisits?.total || 0).toString()}
      isLoading={data.isLoading}
      explanation="Number of active subscribers that have not checked in within the date range selected."
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.NoVisitReport,
          ),
          search: QueryString.stringify({
            startDate: moment(data.startDate, DATE_FORMAT).format(
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ),
            endDate: moment(data.endDate, DATE_FORMAT)
              .endOf("day")
              .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
