import { FormMapper, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import { UserFormRule, UserMemberPost } from "@gymflow/types";
import omit from "lodash/omit";
import moment from "moment-timezone";

export class SignUpFormMapper extends FormMapper {
  rules?: UserFormRule;

  constructor({
    dateFormat,
    rules,
  }: {
    dateFormat: string;
    rules?: UserFormRule;
  }) {
    super({
      outValue: [
        {
          key: "date-birth",
          transform: (v: string) =>
            v && moment(v, dateFormat, true).format(PARAMETER_DATE_ONLY_FORMAT),
        },
        {
          key: "post-code",
          transform: (v: string) => v && v.trim().toUpperCase(),
        },
        {
          key: "source-id",
          transform: ({ value }: { value: number }) => value,
        },
        { key: "email", transform: (email: string) => email.toLowerCase() },
      ],
    });
    this.rules = rules;
  }

  override from(formValues: Record<string, any>) {
    const transformed = super.from(formValues) as UserMemberPost;
    return this.filterFieldsByRules(transformed);
  }

  private filterFieldsByRules(userFields: UserMemberPost) {
    let filteredFields = { ...userFields };
    if (!this.rules?.mobileNumber.isRequired) {
      filteredFields = omit(filteredFields, ["mobileNumber"]);
    }
    if (!this.rules?.addressLine.isRequired) {
      filteredFields = omit(filteredFields, ["addressLine1", "addressLine2"]);
    }
    if (!this.rules?.city.isRequired) {
      filteredFields = omit(filteredFields, ["city"]);
    }
    if (!this.rules?.postCode.isRequired) {
      filteredFields = omit(filteredFields, ["postCode"]);
    }
    if (!this.rules?.dateOfBirth.isRequired) {
      filteredFields = omit(filteredFields, ["dateBirth"]);
    }
    if (!this.rules?.gender.isRequired) {
      filteredFields = omit(filteredFields, ["gender"]);
    }
    if (!this.rules?.emergencyContact.isRequired) {
      filteredFields = omit(filteredFields, [
        "emergencyContact",
        "emergencyContactName",
      ]);
    }
    return omit(filteredFields, ["confirmPassword"]);
  }
}
