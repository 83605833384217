import { assert } from "check-types";

import endpoints from "./endpoints";

function createSortParam(field, desc = false) {
  return `${field},${desc ? "desc" : "asc"}`;
}
const availabilityApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.availability}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    hostAvailability(staffId) {
      assert.string(staffId, "staffId must be a string");
      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId`.replace(
          ":staffId",
          staffId,
        );
      return state.http.get(url);
    },
    saveHostAvailability(
      staffId,
      availableForAppointments,
      availabilitySlotList,
    ) {
      assert.string(staffId, "staffId must be a string");
      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId`.replace(
          ":staffId",
          staffId,
        );
      return state.http.post(url, {
        availableForAppointments,
        availabilitySlotList,
      });
    },
    listHostOverride(
      staffId,
      {
        page,
        limit,
        sort,
        overrideDate,
        unpaged,
        isUnavailableAllDay,
        startTime,
        endTime,
        id,
      },
    ) {
      assert.string(staffId, "staffId must be a string");
      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");
      assert.maybe.string(
        overrideDate,
        "overrideDate must be string or undefined",
      );
      assert.maybe.boolean(unpaged, "unpaged must be boolean or undefined");
      assert.maybe.boolean(
        isUnavailableAllDay,
        "isUnavailableAllDay must be boolean or undefined",
      );
      assert.maybe.string(startTime, "startTime must be string or undefined");
      assert.maybe.string(endTime, "endTime must be string or undefined");
      assert.maybe.array(id, "id must be array or undefined");

      const config = {
        params: {
          page,
          size: limit,
          overrideDate,
          unpaged,
          isUnavailableAllDay,
          startTime,
          endTime,
          id,
        },
      };
      if (sort) {
        assert.string(
          sort.field,
          "sort.field must be a string if sort is specified.",
        );
        config.params.sort = createSortParam(sort.field, sort.desc);
      }

      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId/override`.replace(
          ":staffId",
          staffId,
        );
      return state.http.get(url, config);
    },
    createHostOverride(
      staffId,
      isUnavailableAllDay,
      startTime,
      endTime,
      overrideDate,
    ) {
      assert.string(staffId, "staffId must be a string");
      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId/override`.replace(
          ":staffId",
          staffId,
        );
      return state.http.post(url, {
        isUnavailableAllDay,
        startTime,
        endTime,
        overrideDate,
      });
    },
    hostOverride(staffId, overrideId) {
      assert.string(staffId, "staffId must be a string");
      assert.number(overrideId, "overrideId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId/override/:overrideId`
          .replace(":staffId", staffId)
          .replace(":overrideId", overrideId);
      return state.http.get(url);
    },
    deleteHostOverride(staffId, overrideId) {
      assert.string(staffId, "staffId must be a string");
      assert.number(overrideId, "overrideId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId/override/:overrideId`
          .replace(":staffId", staffId)
          .replace(":overrideId", overrideId);
      return state.http.delete(url);
    },
    updateHostOverride(staffId, overrideId, patchedFields) {
      assert.string(staffId, "staffId must be a string");
      assert.number(overrideId, "overrideId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/host/:staffId/override/:overrideId`
          .replace(":staffId", staffId)
          .replace(":overrideId", overrideId);
      return state.http.patch(url, patchedFields);
    },
    hostCalculateAvailability({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
    }) {
      assert.number(appointableId, "appointableId must be a number");
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(dateTo, "dateTo must be a string");
      assert.maybe.array(
        appointableHostsIdList,
        "appointableHostsIdList must be an array of strings",
      );
      const url = `${clubPrefix}${endpoints.availability}/host/calculate`;
      return state.http.post(url, {
        appointableId,
        dateFrom,
        dateTo,
        appointableHostsIdList,
      });
    },
    hostCalculateAvailabilityMultipleDays({
      appointableId,
      dateFrom,
      appointableHostId,
      numberOfDays,
      ignoreAppointments,
    }) {
      assert.maybe.number(appointableId, "appointableId must be a number");
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(appointableHostId, "appointableHostId must be a string");
      assert.number(numberOfDays, "numberOfDays must be a number");
      const url = `${clubPrefix}${endpoints.availability}/host/calculate-multiple-days`;
      return state.http.post(url, {
        appointableId,
        dateFrom,
        appointableHostId,
        numberOfDays,
        ignoreAppointments,
      });
    },
    hostCalculateAvailabilityMappedByHost({
      dateFrom,
      dateTo,
      appointableHostsIdList,
    }) {
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(dateTo, "dateTo must be a string");
      assert.maybe.array(
        appointableHostsIdList,
        "appointableHostsIdList must be an array of strings",
      );
      const url = `${clubPrefix}${endpoints.availability}/host/calculate-by-host`;
      return state.http.post(url, {
        dateFrom,
        dateTo,
        appointableHostsIdList,
      });
    },
    facilityAvailability(facilityId) {
      assert.number(facilityId, "facilityId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId`.replace(
          ":facilityId",
          facilityId,
        );
      return state.http.get(url);
    },
    saveFacilityAvailability(
      facilityId,
      availableForAppointments,
      availabilitySlotList,
    ) {
      assert.number(facilityId, "facilityId must be a string");
      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId`.replace(
          ":facilityId",
          facilityId,
        );
      return state.http.post(url, {
        availableForAppointments,
        availabilitySlotList,
      });
    },
    facilityOverride(facilityId, overrideId) {
      assert.number(facilityId, "facilityId must be a number");
      assert.number(overrideId, "overrideId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId/override/:overrideId`
          .replace(":facilityId", facilityId)
          .replace(":overrideId", overrideId);
      return state.http.get(url);
    },
    createFacilityOverride(
      facilityId,
      isUnavailableAllDay,
      startTime,
      endTime,
      overrideDate,
    ) {
      assert.number(facilityId, "facilityId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId/override`.replace(
          ":facilityId",
          facilityId,
        );
      return state.http.post(url, {
        isUnavailableAllDay,
        startTime,
        endTime,
        overrideDate,
      });
    },
    deleteFacilityOverride(facilityId, overrideId) {
      assert.number(facilityId, "facilityId must be a number");
      assert.number(overrideId, "overrideId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId/override/:overrideId`
          .replace(":facilityId", facilityId)
          .replace(":overrideId", overrideId);
      return state.http.delete(url);
    },
    updateFacilityOverride(facilityId, overrideId, patchedFields) {
      assert.number(facilityId, "facilityId must be a number");
      assert.number(overrideId, "overrideId must be a number");
      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId/override/:overrideId`
          .replace(":facilityId", facilityId)
          .replace(":overrideId", overrideId);
      return state.http.patch(url, patchedFields);
    },
    listFacilityOverride(
      facilityId,
      {
        page,
        limit,
        sort,
        overrideDate,
        unpaged,
        isUnavailableAllDay,
        startTime,
        endTime,
        id,
      },
    ) {
      assert.number(facilityId, "facilityId must be a number");
      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");
      assert.maybe.string(
        overrideDate,
        "overrideDate must be string or undefined",
      );
      assert.maybe.boolean(unpaged, "unpaged must be boolean or undefined");
      assert.maybe.boolean(
        isUnavailableAllDay,
        "isUnavailableAllDay must be boolean or undefined",
      );
      assert.maybe.string(startTime, "startTime must be string or undefined");
      assert.maybe.string(endTime, "endTime must be string or undefined");
      assert.maybe.array(id, "id must be array or undefined");

      const config = {
        params: {
          page,
          size: limit,
          overrideDate,
          unpaged,
          isUnavailableAllDay,
          startTime,
          endTime,
          id,
        },
      };
      if (sort) {
        assert.string(
          sort.field,
          "sort.field must be a string if sort is specified.",
        );
        config.params.sort = createSortParam(sort.field, sort.desc);
      }

      const url =
        `${clubPrefix}${endpoints.availability}/facility/:facilityId/override`.replace(
          ":facilityId",
          facilityId,
        );
      return state.http.get(url, config);
    },
    facilityCalculateAvailability({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
      appointableFacilityIdList,
    }) {
      assert.number(appointableId, "appointableId must be a number");
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(dateTo, "dateTo must be a string");
      assert.maybe.array(
        appointableHostsIdList,
        "appointableHostsIdList must be an array of strings",
      );
      assert.maybe.array(
        appointableFacilityIdList,
        "appointableFacilityIdList must be an array of numbers",
      );
      const url = `${clubPrefix}${endpoints.availability}/facility/calculate`;
      return state.http.post(url, {
        appointableId,
        dateFrom,
        dateTo,
        appointableHostsIdList,
        appointableFacilityIdList,
      });
    },
    facilityCalculateAvailabilityMappedByFacility({
      dateFrom,
      dateTo,
      appointableHostsIdList,
      appointableFacilityIdList,
    }) {
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(dateTo, "dateTo must be a string");
      assert.maybe.array(
        appointableHostsIdList,
        "appointableHostsIdList must be an array of strings",
      );

      const url = `${clubPrefix}${endpoints.availability}/facility/calculate-by-facility`;
      return state.http.post(url, {
        dateFrom,
        dateTo,
        appointableHostsIdList,
        appointableFacilityIdList,
      });
    },
  });
};

export default availabilityApi;
