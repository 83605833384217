import { action, thunk } from 'easy-peasy';
import { BaseModelBuilder, createPayloadFromFindResponse, getApi, utcDateTimeStringToTz, tzDateTimeStringToUtc } from '@gymflow/common';

class RevenueModelBuilder {
  constructor(apiKey, settingsKey = 'settings') {
    this.generators = [];
    this.apiKey = apiKey;
    this.settingsKey = settingsKey;
    this.generators.push(this.revenueGenerator);
  }

  revenueGenerator() {
    return {
      apiKey: this.apiKey,
      rows: [],
      pageCount: 0,
      totalRecords: 0,
      pageSize: 0,
      page: 0,
      totalRevenue: 0,

      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, filters }, { getState, injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;

        const parsedFilters = { ...filters };
        if (filters.dateFrom) {
          parsedFilters.dateFrom = tzDateTimeStringToUtc(filters.dateFrom, tz);
        }
        if (filters.dateTo) {
          parsedFilters.dateTo = tzDateTimeStringToUtc(filters.dateTo, tz);
        }

        const api = getApi(injections, getState());
        const { data } = await api.find({
          page,
          limit,
          sort,
          extraParams: parsedFilters,
        });
        const payload = createPayloadFromFindResponse(data);
        const { data: totalData } = await api.total(parsedFilters);
        actions.fetchedList({
          ...payload,
          totalRevenue: totalData.total,
          rows: payload.rows.map((row) => ({
            ...row,
            date: utcDateTimeStringToTz(row.date, tz),
          })),
        });
      }),

      fetchedList: action((state, { rows, pageCount, page, last, totalRevenue, totalRecords }) => {
        state.rows = rows;
        state.last = last;
        state.pageCount = pageCount;
        state.page = page;
        state.totalRecords = totalRecords;
        state.totalRevenue = totalRevenue;
      }),

      fetchRevenueByType: thunk(async (actions, { page = 0, limit = 10, sort, revenueType, filters }, { getState, injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;

        const parsedFilters = { ...filters };
        if (filters.dateFrom) {
          parsedFilters.dateFrom = tzDateTimeStringToUtc(filters.dateFrom, tz);
        }
        if (filters.dateTo) {
          parsedFilters.dateTo = tzDateTimeStringToUtc(filters.dateTo, tz);
        }

        const api = getApi(injections, getState());

        const { data } = await api.fetchRevenueByType({
          page,
          limit,
          sort,
          ...parsedFilters
        }, revenueType);
        const payload = createPayloadFromFindResponse(data);

        const { data: totalData } = await api.total(parsedFilters, revenueType);
        actions.fetchedRevenueByType({
          ...payload,
          totalRevenue: totalData.total,
          revenueType,
          rows: payload.rows.map((row) => ({
            ...row,
            date: utcDateTimeStringToTz(row.date, tz),
          })),
        });
      }),

      fetchedRevenueByType: action((state, { rows, pageCount, page, last, revenueType, totalRevenue }) => {
        state.rows = rows;
        state.last = last;
        state.pageCount = pageCount;
        state.page = page;
        state.revenueType = revenueType;
        state.totalRevenue = totalRevenue;
      }),
    };
  }
}

RevenueModelBuilder.prototype.build = BaseModelBuilder.prototype.build;

export default RevenueModelBuilder;
