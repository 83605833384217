import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  usePromotion,
  usePromotionCreate,
  usePromotionEdit,
} from "@gymflow/api";
import { PromotionPostDTO } from "@gymflow/types";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { AxiosError } from "axios";
import { useContext } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import { usePortalRoutes } from "../../../hooks";
import { RouteFeature, RouteLayout } from "../../../routes";
import useGymflowModels from "../../../store";
import { marketingRoute } from "../../../views/Marketing";
import { Spinner } from "../../atoms";
import { StepWizard, WizardState } from "../../organisms/StepWizard";
import { PromotionWizardStep1 } from "./PromotionWizardStep1";
import { PromotionWizardStep2 } from "./PromotionWizardStep2";
import { useAllRestrictableItems } from "./useAllRestrictableItems";

function PromotionWizardRoutes() {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:promotionId`}>
        <PromotionWizard />
      </Route>
      <Route path={`${match.path}`}>
        <PromotionWizard />
      </Route>
    </Switch>
  );
}

function PromotionWizard() {
  const routeParams = useParams<{ promotionId?: string }>();
  const history = useHistory();

  const { api } = useGymflowModels();
  const editingPromotionId = routeParams?.promotionId
    ? +routeParams?.promotionId
    : undefined;
  const { data: promotion } = usePromotion({
    api,
    promotionId: editingPromotionId,
  });
  const { createClubLink } = usePortalRoutes();
  const toast = useContext(ToastContext);

  const { mutateAsync: createPromotion } = usePromotionCreate({ api });
  const { mutateAsync: editPromotion } = usePromotionEdit({ api });

  useAllRestrictableItems({
    staleTime: 60000,
    selectedProductIdList: [],
    selectedMembershipIdList: [],
    selectedCreditPackIdList: [],
    selectedAppointableIdList: [],
  });

  if (routeParams.promotionId && promotion === null) {
    return <Spinner />;
  }
  return (
    <div className="flex h-full flex-col gap-6 bg-white p-5">
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <div className="text-3xl font-semibold text-gray-950">
            {editingPromotionId ? "Edit" : "Add"} Promotion
          </div>
          <div className="mr-5 flex items-center">
            <FontAwesomeIcon
              onClick={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.Marketing,
                    marketingRoute.Promotions,
                  ),
                );
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
        </div>
        <div className="text-base font-normal text-gray-500">
          Create a discount code that can be used across all checkouts.
        </div>
      </div>
      <StepWizard
        submitButtonText="Save"
        initialState={
          promotion
            ? {
                hasPromotionBeenUsed: promotion.timesUsed,
                details: {
                  name: promotion.name,
                  code: promotion.code,
                  trial: promotion.trial,
                  upfrontDiscount: promotion.upfrontDiscount,
                  upfrontDiscountAmount: promotion.upfrontDiscountAmount,
                  upfrontDiscountType: promotion.upfrontDiscountType,
                  recurringDiscount: promotion.recurringDiscount,
                  recurringDiscountAmount: promotion.recurringDiscountAmount,
                  recurringDiscountType: promotion.recurringDiscountType,
                  recurringDiscountDuration:
                    promotion.recurringDiscountDuration,
                },
                limits: {
                  limitUsages: promotion.limitUsages,
                  limitUsagesPerMember: promotion.limitUsagesPerMember,
                  limitMembershipList: promotion.limitMembershipList,
                  limitProductList: promotion.limitProductList,
                  limitSessionPackList: promotion.limitSessionPackList,
                  limitAppointableList: promotion.limitAppointableList,
                  limitCategories: promotion.limitCategories,
                  expiry: promotion.expiry,
                },
              }
            : undefined
        }
        hideStepDetails
        steps={[
          {
            title: "Details",
            component: PromotionWizardStep1,
          },
          {
            title: "Limits",
            component: PromotionWizardStep2,
          },
        ]}
        onSubmit={async ({ wizardState }) => {
          try {
            if (editingPromotionId) {
              const patchedFields = getMutationParametersFromWizardState({
                wizardState,
                existingUpfrontDiscountProrata:
                  promotion?.upfrontDiscountProrata,
                isEditing: true,
                timesUsed: promotion?.timesUsed,
                previousExpiry: promotion?.expiry,
              });

              await editPromotion({
                promotionId: editingPromotionId,
                patchedFields,
              });
            } else {
              await createPromotion(
                getMutationParametersFromWizardState({
                  wizardState,
                  isEditing: false,
                }) as PromotionPostDTO,
              );
            }

            history.push(
              createClubLink(
                RouteLayout.Staff,
                RouteFeature.Marketing,
                marketingRoute.Promotions,
              ),
            );
          } catch (error: unknown) {
            if (error instanceof AxiosError) {
              const errorMessage = error.response?.data?.error_message;

              if (errorMessage) {
                toast.toast({
                  message: errorMessage,
                  intent: "error",
                });
                return false;
              }
            }

            toast.toast({
              message: "Error with saving promotion.",
              intent: "error",
            });

            return false;
          }
          return true;
        }}
      />
    </div>
  );
}

function getMutationParametersFromWizardState({
  wizardState,
  isEditing,
  timesUsed,
  existingUpfrontDiscountProrata,
  previousExpiry,
}: {
  wizardState: WizardState;
  isEditing: boolean;
  timesUsed?: number;
  existingUpfrontDiscountProrata?: boolean;
  previousExpiry?: string;
}) {
  const details = wizardState["details"];
  const limits = wizardState["limits"];
  if (isEditing && timesUsed && timesUsed > 0) {
    const expiry =
      previousExpiry === limits["expiry"] ? undefined : limits["expiry"];
    return {
      trial: details.trial,
      limitUsagesPerMember: limits.limitUsagesPerMember,
      limitUsages: limits.limitUsages,
      expiry,
    } as Partial<PromotionPostDTO>;
  }
  return {
    name: details.name,
    code: details.code,
    trial: details.trial,
    upfrontDiscount: details.upfrontDiscount,
    upfrontDiscountProrata:
      existingUpfrontDiscountProrata !== undefined && details.upfrontDiscount
        ? details.upfrontDiscountProrata
        : false,
    upfrontDiscountAmount: details.upfrontDiscountAmount,
    upfrontDiscountType: details.upfrontDiscountType,
    recurringDiscount: details.recurringDiscount,
    recurringDiscountAmount: details.recurringDiscountAmount,
    recurringDiscountType: details.recurringDiscountType,
    recurringDiscountDuration: details.recurringDiscountDuration,
    limitProductIdList: limits?.limitProductList?.map(
      ({ id }: { id: number }) => id,
    ),
    limitMembershipIdList: limits?.limitMembershipList?.map(
      ({ id }: { id: number }) => id,
    ),
    limitSessionPackIdList: limits?.limitSessionPackList?.map(
      ({ id }: { id: number }) => id,
    ),
    limitAppointableIdList: limits?.limitAppointableList?.map(
      ({ id }: { id: number }) => id,
    ),
    limitUsagesPerMember: limits.limitUsagesPerMember,
    limitUsages: limits.limitUsages,
    limitCategories: limits.limitCategories,
    expiry: limits.expiry,
  } as PromotionPostDTO;
}

export { PromotionWizardRoutes as PromotionWizard };
