import { StaffUserRole } from "@gymflow/types";

export const staffRoles: StaffUserRole[] = [
  'SUPPORT',
  'TRAINER',
  'RECEPTION',
  'MANAGER',
  'OWNER'
];

export function hasStaffRole(roles: readonly string[]) {
  return roles.some((role) => {
    return staffRoles.includes(role as StaffUserRole);
  });
}
