import { useMutation, useQueryClient } from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useMutationKisiSaveApiKey({
  api,
}: {
  api: {
    kisiApi: {
      saveApiKey: (clubId: number, apiKey: string) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ clubId, apiKey }: { clubId: number; apiKey: string }) =>
      api.kisiApi.saveApiKey(clubId, apiKey),
    onSuccess: (_, { clubId }) => {
      queryClient.invalidateQueries({
        queryKey: kisiQueryKeys.apiKeyExists(clubId),
      });
      queryClient.invalidateQueries({
        queryKey: kisiQueryKeys.places(),
      });
      queryClient.invalidateQueries({
        queryKey: kisiQueryKeys.groups(),
      });
    },
  });
  return mutation;
}
