import { ClubSettings } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { clubAsPublicQueryKeys } from "./clubAsPublicQueryKeys";

export function useClubHostedPagesSettingsAsPublic(
  {
    api,
    clubId,
  }: {
    api: ClubHostedPagesSettingsAsPublicApi;
    clubId: number;
  },
  opts?: UseQueryOptions<{ settings: ClubSettings } | null>,
) {
  const result = useQuery({
    queryKey: clubAsPublicQueryKeys.hostedPagesSettings(clubId),
    queryFn: async () => {
      const result = await api.public.clubApi.settings();
      return result.data;
    },
    enabled: !!clubId,
    placeholderData: null,
    ...opts,
  });

  return result;
}

export interface ClubHostedPagesSettingsAsPublicApi {
  public: {
    clubApi: {
      settings: () => Promise<{
        data: { settings: ClubSettings };
      }>;
    };
  };
}
