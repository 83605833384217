const RecurringType = {
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  Yearly: 'YEARLY',
};

Object.setPrototypeOf(RecurringType, {
  toTimeUnit(recurringType) {
    switch (recurringType) {
      case RecurringType.Daily:
        return 'Day';
      case RecurringType.Weekly:
        return 'Week';
      case RecurringType.Monthly:
        return 'Month';
      case RecurringType.Yearly:
        return 'Year';
      default:
        throw new Error();
    }
  },
});

export default RecurringType;
