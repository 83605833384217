import z from "zod";

const ProductStockType = {
  Unlimited: "UNLIMITED",
  Limited: "LIMITED",
} as const;
export const ProductStockTypeSchema = z.enum([
  ProductStockType.Unlimited,
  ProductStockType.Limited,
]);
export default ProductStockType;
