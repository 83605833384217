import { clubStaleTime, useAppointment, useClub } from "@gymflow/api";
import { useClubSettings } from "apps/portal/src/providers";
import classNames from "classnames";
import qs from "qs";
import { createContext, ReactNode, useCallback, useState } from "react";
import { useHistory } from "react-router";

import useGymflowModels from "../../../store";
import { SideBarAppointment } from "./SideBarAppointment";

interface SideBarAppointmentProviderProps {
  children: ReactNode;
}

interface SideBarAppointmentProviderContextType {
  open: (occurrenceId: number) => void;
}

export const SideBarAppointmentProviderContext =
  createContext<SideBarAppointmentProviderContextType>({} as any);

export function SideBarAppointmentProvider({
  children,
}: SideBarAppointmentProviderProps) {
  const settings = useClubSettings();
  const clubId = settings.clubId;

  const [isVisible, setIsVisible] = useState(false);
  const [appointmentId, setAppointmentId] = useState<number>();

  const { api } = useGymflowModels();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  const { data } = useAppointment({ api, appointmentId, tz: club?.timezone });
  const history = useHistory();

  const open = useCallback(
    (appointmentId: number) => {
      setIsVisible(true);
      setAppointmentId(appointmentId);
      history.push({
        search: qs.stringify({
          ...qs.parse(history.location.search, {
            ignoreQueryPrefix: true,
          }),
          appointmentId,
        }),
      });
    },
    [setAppointmentId, setIsVisible, history],
  );

  return (
    <SideBarAppointmentProviderContext.Provider
      value={{
        open,
      }}
    >
      <div className={classNames({ hidden: false })}>
        {appointmentId && data && (
          <SideBarAppointment
            onClose={() => {
              setIsVisible(false);
              const search = qs.parse(history.location.search, {
                ignoreQueryPrefix: true,
              });
              delete search["appointmentId"];
              history.push({
                search: qs.stringify(search),
              });
              setAppointmentId(undefined);
            }}
            isOpen={isVisible}
            value={data}
            isLoading={false}
          />
        )}
      </div>
      {children}
    </SideBarAppointmentProviderContext.Provider>
  );
}
