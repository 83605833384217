import { staffListQueryFn } from "@gymflow/api";
import isEmpty from "lodash/isEmpty";

import useGymflowModels from "../../store";
import {
  eagerSelectAll,
  PaginatedSelect,
  PaginatedSelectVariantProps,
} from ".";

export interface StaffSelectOption {
  label: string;
  value: string;
}

interface StaffSelectProps extends PaginatedSelectVariantProps {
  value: StaffSelectOption[];
  onChange: (newValue: StaffSelectOption[]) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  isDisabled?: boolean;
  isClearable?: boolean;
  className?: string;
  filter?: {
    availableForAppointments?: boolean;
  };
  compact?: boolean;
}

/**
 * @deprecated use atoms/base/StaffSelectInput instead
 */
export function StaffSelect({
  value,
  onChange,
  allowMultipleSelection,
  placeholder,
  isDisabled,
  isClearable,
  className,
  filter = {},
  size,
  color,
  compact,
}: StaffSelectProps) {
  const { api } = useGymflowModels();

  return (
    <PaginatedSelect
      className={className}
      isMulti={allowMultipleSelection}
      placeholder={placeholder}
      loadOptions={async (_, __, additional) => {
        const staffList = await staffListQueryFn({
          api,
          opts: {
            extraParams: { activeUser: true, ...filter },
            page: additional.page,
          },
        });
        return {
          options: staffList.content.map((staff) => ({
            label: `${staff.firstName} ${staff.lastName}`,
            value: staff.id,
          })),
          hasMore: !staffList.last,
          additional: {
            page: additional.page + 1,
          },
        };
      }}
      value={value}
      onChange={(newValue) => {
        onChange(allowMultipleSelection ? newValue : [newValue]);
      }}
      isDisabled={isDisabled}
      isClearable={isClearable}
      size={size}
      color={color}
      compact={compact}
      showSelectAll
      selectAllClick={eagerSelectAll}
      refetchOnMenuOpen={!isEmpty(filter)}
    />
  );
}
