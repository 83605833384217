export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, k) => k + start);

export const truncIfBiggerOrEqualThen = (value: number, threshold = 10000) =>
  value >= threshold ? Math.trunc(value) : value;

export const ordinal = (value: number) => {
  const englishOrdinalRules = new Intl.PluralRules("en", { type: "ordinal" });
  const suffixes: { [key in Intl.LDMLPluralRule]: string } = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
    many: "",
    zero: "",
  };
  const category = englishOrdinalRules.select(value);
  const suffix = suffixes[category];
  return value + suffix;
};
