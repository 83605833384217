import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export function CallToActionLink({
  to, onClick, children, target,
}) {
  return (
    <Link className="text-uppercase" style={{ fontWeight: 600 }} target={target} onClick={onClick} to={to}>
      {children}
    </Link>
  )
}

CallToActionLink.defaultProps = {

}

CallToActionLink.propTypes = {
  to: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  target: PropTypes.string
}
