import qs from "qs";
import { useCallback } from "react";
import { useHistory } from "react-router";

const useQueryParam = (param: string, type?: "number" | "string") => {
  const history = useHistory();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });
  const setParamValue = useCallback(
    (value: any) => {
      history.push({
        search: qs.stringify(
          {
            ...search,
            [param]: type === "number" ? +value : value,
          },
          { skipNulls: true },
        ),
      });
    },
    [history, search, param, type],
  );

  //@ts-expect-error
  return [type === "number" ? +search[param] : search[param], setParamValue];
};

export default useQueryParam;
