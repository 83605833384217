import { useContext } from "react";

import { ModalContext, ModalWrapper } from "../../../providers";
import { Button, DangerIcon } from "../../atoms";

export function AppointmentCannotRescheduleDueToAccessModal() {
  const { hide: hideModal } = useContext(ModalContext);
  return (
    <ModalWrapper onCancel={hideModal}>
      <div className="bg-warning-50 flex h-11 w-11 items-center  justify-center self-center rounded-full">
        <div className="bg-warning-100 flex h-8 w-8 items-center justify-center self-center rounded-full">
          <DangerIcon className="h-5 w-5" pathClassName="stroke-warning-600" />
        </div>
      </div>
      <div className="text-lg font-semibold text-gray-900">
        Cannot reschedule
      </div>
      <div className="mt-2 text-sm text-gray-600">
        You cannot reschedule this appointment as access has already been
        granted, please cancel and re-create instead.
      </div>
      <div className="mt-5 flex">
        <Button intent="primary" onClick={hideModal} className="flex-1">
          Ok
        </Button>
      </div>
    </ModalWrapper>
  );
}
