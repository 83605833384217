const SubscriptionStatus = {
  Active: 'ACTIVE',
  Expired: 'EXPIRED',
  Cancelled: 'CANCELLED',
  Paused: 'PAUSED',
  Pending: 'PENDING',
  Overdue: 'OVERDUE',
};

Object.setPrototypeOf(SubscriptionStatus, {
  humanize: (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
});

export default SubscriptionStatus;
