import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { AvailabilityByHostCalculationResponse } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityCalculateMappedByHost(
  {
    api,
    appointableId,
    dateFrom,
    dateTo,
    appointableHostsIdList,
    tz,
  }: {
    api: {
      availabilityApi: {
        hostCalculateAvailabilityMappedByHost: (filter: {
          dateFrom: string;
          dateTo: string;
          appointableHostsIdList?: string[];
        }) => Promise<{ data: AvailabilityByHostCalculationResponse }>;
      };
    };
    tz?: string;
    appointableId?: number;
    dateFrom?: string;
    dateTo?: string;
    appointableHostsIdList?: string[];
    ignoreAppointments?: boolean;
  },
  opts?: UseQueryOptions<
    AvailabilityByHostCalculationResponse | undefined | null
  >,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
    }),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(dateFrom as string, tz as string);
      const utcDateTo = zonedTimeToUtc(dateTo as string, tz as string);

      const result =
        await api.availabilityApi.hostCalculateAvailabilityMappedByHost({
          dateFrom: utcDateFrom,
          dateTo: utcDateTo,
          appointableHostsIdList,
        });
      return result.data;
    },
    select: (data) =>
      hostCalculateAvailabilityMappedByHostSelectFn({ data, tz: tz as string }),
    enabled: !!tz && !!dateFrom && !!dateTo,
    initialData: null,
    ...opts,
  });

  return result;
}

export function hostCalculateAvailabilityMappedByHostSelectFn({
  data,
  tz,
}: {
  data?: AvailabilityByHostCalculationResponse | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return Object.keys(data).reduce((acc, hostId) => {
    const timezoneParsedStaffAvailability = data[hostId]
      .slice(0)
      .map((availabilityLine) => ({
        ...availabilityLine,
        startTime: utcToZonedTime(availabilityLine.startTime, tz),
        endTime: utcToZonedTime(availabilityLine.endTime, tz),
      }));
    acc[hostId] = timezoneParsedStaffAvailability;
    return acc;
  }, {} as AvailabilityByHostCalculationResponse);
}
