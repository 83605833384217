import {
  useMutationRuleGroupEdit,
  useMutationRuleGroupNew,
} from "@gymflow/api";
import { RuleGroup, RuleType } from "@gymflow/types";
import { useFormik } from "formik";
import { forwardRef, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import { AccessRuleFormType, AccessRuleMapper } from "./AccessRuleMapper";
import { RuleFormWrapper } from "./RuleFormWrapper";
import { RuleMapper } from "./RuleMapper";
import { RulesComponents } from "./RulesComponents";

export function AccessRuleForm() {
  const { t } = useTranslation();
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const isEditing = ruleGroupId !== undefined && ruleGroupId !== "";
  return (
    <RuleFormWrapper
      title={
        isEditing
          ? t("page.rules.accessForm.headerEdit")
          : t("page.rules.accessForm.headerNew")
      }
      description={
        isEditing
          ? t("page.rules.accessForm.explanationEdit")
          : t("page.rules.accessForm.explanationNew")
      }
      Form={Form}
    />
  );
}

const Form = forwardRef<
  { submitForm: () => Promise<any> },
  | {
      isEditing: true;
      ruleTypeToIdMap: Record<RuleType, number>;
      initialValues: RuleGroup;
    }
  | {
      isEditing: false;
    }
>(function Form(props, ref) {
  const initialValues = useMemo(() => {
    return "initialValues" in props
      ? AccessRuleMapper.mapBEtoFE(props.initialValues)
      : AccessRuleMapper.defaultValues;
  }, [props]);
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { mutateAsync: newGroup } = useMutationRuleGroupNew({ api });
  const { mutateAsync: editGroup } = useMutationRuleGroupEdit({ api });

  const formik = useFormik<AccessRuleFormType>({
    initialValues,
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: toFormikValidationSchema(AccessRuleMapper.schema),
    onSubmit: async (values) => {
      if (props.isEditing) {
        await editGroup({
          ruleGroupId: +ruleGroupId,
          patchedFields: RuleMapper.mapPostToPatch(
            AccessRuleMapper.mapFEtoBE(values),
            props.ruleTypeToIdMap,
          ),
        });
      } else {
        await newGroup({
          fields: AccessRuleMapper.mapFEtoBE(values),
        });
      }
      history.goBack();
    },
  });
  const { values, setFieldValue, submitForm } = formik;
  useImperativeHandle(ref, () => ({
    submitForm,
  }));
  return (
    <RulesComponents.FormRow
      label={t("page.rules.accessForm.automaticCheckoutLabel")}
      onChange={(newValue) => {
        setFieldValue("automaticCheckout.enabled", newValue);
        setFieldValue("automaticCheckout.checkoutAfterAmount", 1);
      }}
      isDisabled={!values.automaticCheckout.enabled}
    >
      {values.automaticCheckout.enabled && (
        <RulesComponents.WindowPicker
          label={t("page.rules.accessForm.automaticCheckoutExplanation")}
          onChange={(newValue) => {
            setFieldValue(
              "automaticCheckout.checkoutAfterAmount",
              newValue.amount,
            );
          }}
          value={{
            amount: values.automaticCheckout.checkoutAfterAmount,
            type: "HOURS",
          }}
          availableUnits={["HOURS"]}
        />
      )}
    </RulesComponents.FormRow>
  );
});
