import { faChevronDown, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  membershipListQueryFn,
  staffListQueryFn,
  UserMemberListFilter,
} from "@gymflow/api";
import { onlyNumbersProps } from "@gymflow/common";
import { cn, LUXON_DATE_FORMAT } from "@gymflow/helpers";
import { MembershipType, UserMemberSubscriptionStatus } from "@gymflow/types";
import { PopoverClose } from "@radix-ui/react-popover";
import { useFormik } from "formik";
import { LabeledFormikInput } from "libs/common/src/lib/components/molecules/FormikInput";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import useGymflowModels from "../../../store";
import {
  Button,
  buttonVariants,
  PaginatedSelect,
  SlideSideBar,
} from "../../atoms";
import { Popover, PopoverContent, PopoverTrigger } from "../../atoms/Popover";
import { DatePicker } from "../../molecules";

export interface UserMemberListFilterSidebarProps {
  isVisible: boolean;
  onClose: () => void;
  value: UserMemberListFilter;
  onChange: (newFilters: UserMemberListFilter) => void;
}

const enabledDisabledOptions = [
  { label: "Enabled", value: true },
  { label: "Disabled", value: false },
] satisfies { label: string; value: boolean }[];

const membershipTypeOptions = [
  { label: "Recurring", value: "RECURRING" },
  { label: "Prepaid", value: "PREPAID" },
] satisfies { label: string; value: MembershipType }[];

const membershipStatusOptions = [
  { label: "Active", value: "ACTIVE" },
  { label: "Canceled", value: "CANCELLED" },
  { label: "Expired", value: "EXPIRED" },
  { label: "Overdue", value: "OVERDUE" },
  { label: "Paused", value: "PAUSED" },
  { label: "Pending", value: "PENDING" },
] satisfies { label: string; value: UserMemberSubscriptionStatus }[];

const dateStringRangeToDateRange = (from?: string, to?: string) => {
  if (!from) return;
  return {
    from: DateTime.fromFormat(from, LUXON_DATE_FORMAT)
      .setZone("local", { keepLocalTime: true })
      .toJSDate(),
    ...(to
      ? {
          to: DateTime.fromFormat(to, LUXON_DATE_FORMAT)
            .setZone("local", { keepLocalTime: true })
            .toJSDate(),
        }
      : {}),
  };
};

export const UserMemberListFilterSidebar: React.FC<
  UserMemberListFilterSidebarProps
> = ({ value, onChange, isVisible, onClose }) => {
  const { api } = useGymflowModels();
  const [newFilters, setNewFilters] = useState<UserMemberListFilter>(value);
  useEffect(() => {
    setNewFilters(value);
  }, [value]);

  return (
    <SlideSideBar isOpen={isVisible} hide={onClose}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">
                Apply Filters
              </div>
              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              Apply one or more filters.
            </div>
          </div>

          <div className="flex flex-col gap-4 p-4">
            <DatePicker
              label="User Created Date"
              mode="range"
              isClearable
              selected={dateStringRangeToDateRange(
                newFilters.createdFrom,
                newFilters.createdTo,
              )}
              handleDateSave={(newRange) => {
                if (!newRange) {
                  setNewFilters((previous) => ({
                    ...previous,
                    createdFrom: undefined,
                    createdTo: undefined,
                  }));
                  return;
                }
                if (!newRange.from) return;
                const createdFrom = DateTime.fromJSDate(
                  newRange.from ?? newRange.to,
                ).toFormat(LUXON_DATE_FORMAT);
                const createdTo = DateTime.fromJSDate(
                  newRange.to ?? newRange.from,
                ).toFormat(LUXON_DATE_FORMAT);
                setNewFilters((previous) => ({
                  ...previous,
                  createdFrom,
                  createdTo,
                }));
              }}
            />
            <PaginatedSelect
              placeholder="Trainer Assigned"
              isMulti
              isSearchable
              isClearable
              value={
                newFilters?.assignedStaffId
                  ? newFilters?.assignedStaffId.map((id) => ({ value: id }))
                  : undefined
              }
              onChange={(newValue) => {
                setNewFilters((previous) => ({
                  ...previous,
                  assignedStaffId: newValue?.map((e: any) => e.value),
                }));
              }}
              loadOptions={async (query, _, { page }) => {
                const result = await staffListQueryFn({
                  api,
                  opts: {
                    extraParams: { activeUser: true, firstName: query },
                    page,
                  },
                });
                return {
                  options:
                    result.content.map((e) => ({
                      label: `${e.firstName} ${e.lastName}`,
                      value: e.id,
                    })) ?? [],
                  hasMore: !result.last,
                  additional: {
                    page: page + 1,
                  },
                };
              }}
            />
            <PaginatedSelect
              placeholder="Email Communication"
              isClearable
              value={enabledDisabledOptions.find(
                (e) => e.value === newFilters?.emailCommunication,
              )}
              onChange={(newValue) => {
                setNewFilters((previous) => ({
                  ...previous,
                  emailCommunication: newValue?.value,
                }));
              }}
              loadOptions={async () => {
                return {
                  options: enabledDisabledOptions,
                };
              }}
            />
            <PaginatedSelect
              placeholder="SMS Communication"
              isClearable
              value={enabledDisabledOptions.find(
                (e) => e.value === newFilters?.smsCommunication,
              )}
              onChange={(newValue) => {
                setNewFilters((previous) => ({
                  ...previous,
                  smsCommunication: newValue?.value,
                }));
              }}
              loadOptions={async () => {
                return {
                  options: enabledDisabledOptions,
                };
              }}
            />
            <CreditsRemaining filters={newFilters} setFilters={setNewFilters} />
            <PaginatedSelect
              isMulti
              placeholder="Membership Type"
              isClearable
              value={membershipTypeOptions.filter(
                (e) => newFilters?.membershipType?.some((m) => e.value === m),
              )}
              onChange={(newValue) => {
                setNewFilters((previous) => ({
                  ...previous,
                  membershipType: newValue?.map((e: any) => e.value),
                }));
              }}
              loadOptions={async () => {
                return {
                  options: membershipTypeOptions,
                };
              }}
            />
            <PaginatedSelect
              isMulti
              isSearchable
              isClearable
              placeholder="Current Membership"
              value={
                newFilters?.membershipId
                  ? newFilters.membershipId.map((mId) => ({
                      value: mId,
                    }))
                  : undefined
              }
              onChange={(newValue) => {
                setNewFilters((previous) => ({
                  ...previous,
                  membershipId: newValue?.map((e: any) => e.value),
                }));
              }}
              loadOptions={async (query, _, { page }) => {
                const results = await membershipListQueryFn({
                  api,
                  filter: {
                    extraParams: {
                      status: "ACTIVE",
                      name: query,
                    },
                    page,
                  },
                });

                return {
                  options: results.content.map((m) => {
                    return { label: m.name, value: m.id };
                  }),
                  hasMore: !results.last,
                  additional: {
                    page: page + 1,
                  },
                };
              }}
            />
            <DatePicker
              label="Membership Start Date"
              mode="range"
              isClearable
              selected={dateStringRangeToDateRange(
                newFilters.membershipStartFrom,
                newFilters.membershipStartTo,
              )}
              handleDateSave={(newRange) => {
                if (!newRange) {
                  setNewFilters((previous) => ({
                    ...previous,
                    membershipStartFrom: undefined,
                    membershipStartTo: undefined,
                  }));
                  return;
                }
                if (!newRange.from) return;
                const membershipStartFrom = DateTime.fromJSDate(
                  newRange.from ?? newRange.to,
                ).toFormat(LUXON_DATE_FORMAT);
                const membershipStartTo = DateTime.fromJSDate(
                  newRange.to ?? newRange.from,
                ).toFormat(LUXON_DATE_FORMAT);
                setNewFilters((previous) => ({
                  ...previous,
                  membershipStartFrom,
                  membershipStartTo,
                }));
              }}
            />
            <PaginatedSelect
              isMulti
              isClearable
              placeholder="Membership Status"
              value={membershipStatusOptions.filter(
                (e) => newFilters?.membershipStatus?.some((m) => e.value === m),
              )}
              onChange={(newValue) => {
                setNewFilters((previous) => ({
                  ...previous,
                  membershipStatus: newValue?.map((e: any) => e.value),
                }));
              }}
              loadOptions={async () => {
                return {
                  options: membershipStatusOptions,
                };
              }}
            />

            <DatePicker
              label="Cancellation Date"
              mode="range"
              isClearable
              selected={dateStringRangeToDateRange(
                newFilters.membershipCancellationFrom,
                newFilters.membershipCancellationTo,
              )}
              handleDateSave={(newRange) => {
                if (!newRange) {
                  setNewFilters((previous) => ({
                    ...previous,
                    membershipCancellationFrom: undefined,
                    membershipCancellationTo: undefined,
                  }));
                  return;
                }
                if (!newRange.from) return;
                const membershipCancellationFrom = DateTime.fromJSDate(
                  newRange.from ?? newRange.to,
                ).toFormat(LUXON_DATE_FORMAT);
                const membershipCancellationTo = DateTime.fromJSDate(
                  newRange.to ?? newRange.from,
                ).toFormat(LUXON_DATE_FORMAT);
                setNewFilters((previous) => ({
                  ...previous,
                  membershipCancellationFrom,
                  membershipCancellationTo,
                }));
              }}
            />
            <DatePicker
              label="Expiry Date"
              mode="range"
              isClearable
              selected={dateStringRangeToDateRange(
                newFilters.membershipExpireFrom,
                newFilters.membershipExpireTo,
              )}
              handleDateSave={(newRange) => {
                if (!newRange) {
                  setNewFilters((previous) => ({
                    ...previous,
                    membershipExpireFrom: undefined,
                    membershipExpireTo: undefined,
                  }));
                  return;
                }
                if (!newRange.from) return;
                const membershipExpireFrom = DateTime.fromJSDate(
                  newRange.from ?? newRange.to,
                ).toFormat(LUXON_DATE_FORMAT);
                const membershipExpireTo = DateTime.fromJSDate(
                  newRange.to ?? newRange.from,
                ).toFormat(LUXON_DATE_FORMAT);
                setNewFilters((previous) => ({
                  ...previous,
                  membershipExpireFrom,
                  membershipExpireTo,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button
            intent="default"
            className="mt-2 w-full"
            onClick={() => {
              if (newFilters) {
                onChange(newFilters);
              }
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
};

const CreditsRemaining: React.FC<{
  filters: UserMemberListFilter;
  setFilters: Dispatch<SetStateAction<UserMemberListFilter>>;
}> = ({ filters, setFilters }) => {
  const formik = useFormik<UserMemberListFilter>({
    enableReinitialize: true,
    initialValues: filters,
    onSubmit: (values) => {
      setFilters((e) => ({
        ...e,
        creditsRemainingFrom: values.creditsRemainingFrom,
        creditsRemainingTo: values.creditsRemainingTo,
      }));
    },
    validationSchema: toFormikValidationSchema(
      z.object({
        creditsRemainingFrom: z.coerce.number().optional(),
        creditsRemainingTo: z.coerce.number().optional(),
      }),
    ),
  });
  return (
    <Popover>
      <PopoverTrigger
        className={cn(
          buttonVariants({ size: "small", intent: "default" }),
          "justify-between bg-[#fff] text-gray-700",
        )}
      >
        Credits Remaining
        <div className="flex gap-2">
          <FontAwesomeIcon
            onClick={(e) => {
              e.stopPropagation();
              setFilters((previous) => ({
                ...previous,
                creditsRemainingFrom: undefined,
                creditsRemainingTo: undefined,
              }));
            }}
            className={cn(
              "h-4 w-4 cursor-pointer !text-[#ccc] hover:!text-[#999]",
              {
                hidden:
                  !filters.creditsRemainingFrom && !filters.creditsRemainingTo,
              },
            )}
            icon={faClose}
          />
          <FontAwesomeIcon className="h-3 w-3" icon={faChevronDown} />
        </div>
      </PopoverTrigger>
      <PopoverContent className="flex flex-col">
        <div className="flex flex-col gap-y-2 p-4">
          <LabeledFormikInput
            formikProps={formik}
            name="creditsRemainingTo"
            label="Less Than or Equal To"
            placeholder="Enter amount"
            {...onlyNumbersProps}
          />
          <LabeledFormikInput
            formikProps={formik}
            name="creditsRemainingFrom"
            label="Greater Than or Equal To"
            placeholder="Enter amount"
            {...onlyNumbersProps}
          />
        </div>
        <div className="flex w-full border-t border-gray-200"></div>
        <div className="flex items-center justify-end gap-x-2 p-4">
          <PopoverClose asChild>
            <Button>Cancel</Button>
          </PopoverClose>
          <PopoverClose asChild>
            <Button onClick={() => formik.handleSubmit()} intent="secondary">
              Apply
            </Button>
          </PopoverClose>
        </div>
      </PopoverContent>
    </Popover>
  );
};
