import { rulesHelper } from "@gymflow/helpers";
import { RuleGroup, RuleGroupPost } from "@gymflow/types";
import { z } from "zod";

const MembershipRuleSchema = z.object({
  name: z.string(),
  cancelMembership: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      minimumPeriodNotice: z.number(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  membershipList: z
    .array(z.object({ value: z.number(), label: z.any() }))
    .optional(),
});
export type MembershipRuleFormType = z.infer<typeof MembershipRuleSchema>;
export const MembershipRuleMapper = {
  schema: MembershipRuleSchema,
  defaultValues: {
    name: "",
    cancelMembership: {
      enabled: false,
    },
  } as const,
  mapBEtoFE: (ruleGroup: RuleGroup) => {
    const membershipRules =
      rulesHelper.mapRuleGroupToMembershipRules(ruleGroup);
    const result: MembershipRuleFormType = {
      name: ruleGroup.name,
      cancelMembership: membershipRules.subscriptionCancellationRule!
        .allowCustomerCancelMemberships
        ? {
            enabled: true,
            minimumPeriodNotice:
              membershipRules.subscriptionCancellationRule.minimumPeriodNotice,
          }
        : {
            enabled: false,
          },
    };
    return result;
  },
  mapFEtoBE: (membershipRule: MembershipRuleFormType) => {
    const result: RuleGroupPost = {
      name: membershipRule.name,
      groupType: "ACCESS_GROUP",
      ruleClubList: [
        {
          ruleType: "SUBSCRIPTION_CANCELLATION",
          subscriptionCancellationRule: {
            allowCustomerCancelMemberships:
              membershipRule.cancelMembership.enabled,
            minimumPeriodNotice: membershipRule.cancelMembership.enabled
              ? membershipRule.cancelMembership.minimumPeriodNotice
              : 0,
          },
        },
      ],
    };
    return result;
  },
};
