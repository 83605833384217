import { rulesHelper } from "@gymflow/helpers";
import { RuleGroup, RuleGroupPost, WindowTypeValues } from "@gymflow/types";
import { z } from "zod";

const ClassRuleSchema = z.object({
  name: z.string(),
  isDefault: z.boolean(),
  advancedBookings: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  bookingNoticePeriod: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  concurrentBookings: z.boolean(),
  bookingLimits: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  cancelation: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
      cancellationFee: z.discriminatedUnion("enabled", [
        z.object({
          enabled: z.literal(true),
          amount: z.coerce
            .number({
              invalid_type_error: "Include the fee amount",
              required_error: "Include the fee amount",
            })
            .min(0),
        }),
        z.object({ enabled: z.literal(false) }),
      ]),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  noShowFee: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.coerce
        .number({
          invalid_type_error: "Include the fee amount",
          required_error: "Include the fee amount",
        })
        .min(0),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  classList: z
    .array(z.object({ value: z.number(), label: z.any() }))
    .optional(),
});
export type ClassRuleFormType = z.infer<typeof ClassRuleSchema>;
export const ClassRuleMapper = {
  schema: ClassRuleSchema,
  defaultValues: {
    name: "",
    isDefault: false,
    advancedBookings: {
      enabled: false,
    },
    bookingNoticePeriod: {
      enabled: false,
    },
    rescheduleNoticePeriod: {
      enabled: false,
    },
    concurrentBookings: false,
    bookingLimits: {
      enabled: false,
    },
    cancelation: {
      enabled: false,
    },
    noShowFee: {
      enabled: false,
    },
    classList: [] as { value: number; label: string }[],
  } as const,
  mapBEtoFE: (ruleGroup: RuleGroup) => {
    const classRules = rulesHelper.mapRuleGroupToClassRules(ruleGroup);
    const result: ClassRuleFormType = {
      name: ruleGroup.name,
      isDefault: ruleGroup.isDefault,
      advancedBookings:
        !!classRules.advancedBookingsRule &&
        !!classRules.advancedBookingsRule.windowValue &&
        !!classRules.advancedBookingsRule.windowType
          ? {
              enabled: true,
              amount: Number(classRules.advancedBookingsRule.windowValue),
              type: classRules.advancedBookingsRule.windowType,
            }
          : {
              enabled: false,
            },
      bookingNoticePeriod:
        classRules.bookingNoticeRule &&
        !!Number(classRules.bookingNoticeRule.windowValue)
          ? {
              enabled: true,
              amount: Number(classRules.bookingNoticeRule.windowValue),
              type: classRules.bookingNoticeRule.windowType,
            }
          : {
              enabled: false,
            },
      concurrentBookings:
        !!classRules.concurrentBookingRule &&
        !!classRules.concurrentBookingRule.restrictConcurrentBookings,
      bookingLimits:
        classRules.bookingLimitRule && !!classRules.bookingLimitRule.limitValue
          ? {
              enabled: true,
              amount: Number(classRules.bookingLimitRule.limitValue),
            }
          : {
              enabled: false,
            },
      cancelation:
        classRules.bookingCancellationRule &&
        !!classRules.bookingCancellationRule.windowValue &&
        !!classRules.bookingCancellationRule.windowType
          ? {
              enabled: true,
              amount: Number(classRules.bookingCancellationRule.windowValue),
              type: classRules.bookingCancellationRule.windowType,
              cancellationFee:
                classRules.bookingCancellationRule &&
                !!classRules.bookingCancellationRule.cancellationFee
                  ? {
                      enabled: true,
                      amount: Number(
                        classRules.bookingCancellationRule.cancellationFee,
                      ),
                    }
                  : {
                      enabled: false,
                    },
            }
          : {
              enabled: false,
            },
      noShowFee:
        classRules.noShowRule && !!classRules.noShowRule.noShowFeeValue
          ? {
              enabled: true,
              amount: Number(classRules.noShowRule.noShowFeeValue),
            }
          : {
              enabled: false,
            },
      classList:
        ruleGroup.classList?.map((e) => ({
          label: e.name,
          value: e.id,
        })) ?? [],
    };
    return result;
  },
  mapFEtoBE: (classRule: ClassRuleFormType) => {
    const result: RuleGroupPost = {
      name: classRule.name,
      groupType: "CLASS_GROUP",
      ruleClubList: [
        {
          ruleType: "BOOKING_LIMIT_WINDOW",
          bookingRule: classRule.advancedBookings.enabled
            ? {
                windowType: classRule.advancedBookings.type,
                windowValue: `${classRule.advancedBookings.amount}`,
              }
            : ({
                windowType: "",
                windowValue: "",
              } as any),
        },
        {
          ruleType: "BOOKING_NOTICE_PERIOD",
          bookingNoticePeriodRule: classRule.bookingNoticePeriod.enabled
            ? {
                windowType: classRule.bookingNoticePeriod.type,
                windowValue: `${classRule.bookingNoticePeriod.amount}`,
              }
            : {
                windowType: "DAYS",
                windowValue: "0",
              },
        },
        {
          ruleType: "CONCURRENT_BOOKING",
          concurrentBookingRule: {
            restrictConcurrentBookings: classRule.concurrentBookings,
          },
        },
        {
          ruleType: "BOOKING_CANCELLATION_WINDOW",
          bookingCancellationRule: classRule.cancelation.enabled
            ? {
                windowType: classRule.cancelation.type,
                windowValue: `${classRule.cancelation.amount}`,
                cancellationFee: classRule.cancelation.cancellationFee.enabled
                  ? classRule.cancelation.cancellationFee.amount
                  : 0,
              }
            : ({
                windowType: "",
                windowValue: "",
                cancellationFee: 0,
              } as any),
        },
        {
          ruleType: "BOOKING_LIMIT_PER_CUSTOMER",
          bookingLimitRule: classRule.bookingLimits.enabled
            ? {
                limitValue: classRule.bookingLimits.amount,
              }
            : {
                limitValue: 0,
              },
        },
        {
          ruleType: "NO_SHOW_FEE",
          noShowFeeRule: classRule.noShowFee.enabled
            ? {
                noShowFeeValue: classRule.noShowFee.amount,
              }
            : {
                noShowFeeValue: 0,
              },
        },
      ],
      classIdList: classRule.isDefault
        ? undefined
        : classRule.classList?.map((e) => e.value),
    };
    return result;
  },
};
