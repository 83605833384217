import { cn } from "@gymflow/helpers";

import { BarChartIcon, DotPointsIcon } from "../../atoms";

export function ToggleModeButton({
  className,
  value,
  onChange,
}: {
  className?: string;
  value: "LEFT" | "RIGHT";
  onChange: (v: "LEFT" | "RIGHT") => void;
}) {
  return (
    <div
      className={cn(
        "relative flex h-11 cursor-pointer items-center !rounded-lg border border-gray-300 bg-gray-100 hover:bg-gray-100",
        className,
      )}
      onClick={() => {
        onChange(value === "LEFT" ? "RIGHT" : "LEFT");
      }}
    >
      <div
        className={cn(
          "bg-gray-25 absolute bottom-1 top-1 flex w-9 !rounded-lg shadow-sm transition-all duration-500 ease-in-out",
          {
            "left-1": value === "LEFT",
            "left-[calc(50%+.25rem)]": value === "RIGHT",
          },
        )}
      />
      <div className="z-10 flex flex-row">
        <div className="flex h-11 w-11 items-center justify-center">
          <BarChartIcon pathClassName="stroke-gray-700" />
        </div>
        <div className="flex h-11 w-11 items-center justify-center">
          <DotPointsIcon pathClassName="stroke-gray-700" />
        </div>
      </div>
    </div>
  );
}
