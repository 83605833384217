import { useAutoAnimate } from "@formkit/auto-animate/react";
import { cn } from "@gymflow/helpers";
import React, { ReactNode } from "react";

export interface SettingsContainerProps {
  className?: string;
  title: ReactNode;
  subTitle?: string;
  actions?: ReactNode;
  children: ReactNode;
}

export const SettingsContainer: React.FC<SettingsContainerProps> = ({
  className,
  title,
  actions,
  children,
  subTitle,
}) => {
  const [parent] = useAutoAnimate();
  return (
    <div
      className={cn("flex h-full max-h-full w-full flex-col gap-4", className)}
    >
      <div
        ref={parent}
        className="flex flex-row flex-wrap items-center justify-between gap-4"
      >
        <div className="flex flex-col items-start">
          <div className="flex text-lg font-semibold">{title}</div>
          {subTitle && (
            <div className="flex text-sm text-gray-600">{subTitle}</div>
          )}
        </div>
        {actions && (
          <div className="flex flex-row flex-wrap items-center justify-end gap-2">
            {actions}
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
