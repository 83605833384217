import {
  automationPlaceholderLabelsQueryFn,
  useEmailTemplate,
  useEmailTemplateCreate,
  useEmailTemplateEdit,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { useCallback, useContext, useEffect, useState } from "react";

import useGymflowModels from "../../store";
import AddTemplateAlert from "./AddTemplateAlert";

function useTemplateAlert({ readOnlyTitle = false } = {}) {
  const { api } = useGymflowModels();
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const alert = useContext(AlertContext);

  const { data: editing } = useEmailTemplate({
    api,
    emailTemplateId: editingTemplateId,
  });
  const editEmailTemplate = useEmailTemplateEdit({ api });
  const createEmailTemplate = useEmailTemplateCreate({ api });

  const closeAlert = useCallback(() => {
    setEditingTemplateId(null);
    alert.hide();
  }, [alert]);

  const showAlert = useCallback(() => {
    alert.setAlert(
      <AddTemplateAlert
        onSubmit={async (values) => {
          if (editingTemplateId) {
            await editEmailTemplate.mutateAsync({
              emailTemplateId: editing.id,
              patchedFields: values,
            });
          } else {
            await createEmailTemplate.mutateAsync(values);
          }
          closeAlert();
        }}
        onCancel={closeAlert}
        template={editing}
        fetchPlaceholders={() => {
          return automationPlaceholderLabelsQueryFn({
            api,
            placeholderType: editing?.templatePlaceholderType || "GENERIC",
          });
        }}
        readOnlyTitle={readOnlyTitle}
      />,
    );
  }, [
    alert,
    readOnlyTitle,
    editing,
    automationPlaceholderLabelsQueryFn,
    closeAlert,
  ]);

  useEffect(() => {
    if (editingTemplateId) {
      showAlert();
    } else {
      alert.hide();
    }
  }, [editing, readOnlyTitle]);

  return { showAlert, editingTemplateId, setEditingTemplateId };
}

export default useTemplateAlert;
