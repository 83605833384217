import { useClubFeatureFlags } from "@gymflow/api";
import { useContext } from "react";

import {
  AddPaymentMethodModal,
  AddPaymentMethodModalProps,
} from "../components/molecules";
import { AddPaymentMethodModalNew } from "../components/molecules/AddPaymentMethodModalNew";
import { ModalContext, useClubSettings } from "../providers";
import useGymflowModels from "../store";

export const useAddPaymentMethodAlert = ({
  userMember,
  onConfirm,
  asMember,
}: Partial<AddPaymentMethodModalProps>) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({
    api,
    clubId: settings.clubId,
  });
  const { stackModal, popModal } = useContext(ModalContext);
  const showAddPaymentMethodAlert = () => {
    if (!userMember) return;
    const Component = featureFlags?.featureFlags.PAYMENT_SETUP_INTENT
      ? AddPaymentMethodModalNew
      : AddPaymentMethodModal;
    return stackModal(
      <Component
        userMember={userMember}
        onConfirm={async (newPaymentMethodId) => {
          onConfirm?.(newPaymentMethodId);
          popModal();
        }}
        asMember={asMember}
      />,
    );
  };
  return showAddPaymentMethodAlert;
};
