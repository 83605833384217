import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { memberQueryKeys } from "../member";
import { reportQueryKeys } from "../report";
import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationLeadStatus(
  {
    api,
  }: {
    api: {
      leadApi: {
        changeLeadStatusColumn: (
          id: number,
          newColumn: number,
        ) => Promise<{
          data: {
            leadId: number;
            newStatusId: number;
          };
        }>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    { leadId: number; newStatusId: number },
    unknown,
    { leadId: number; newColumn: number }
  >,
) {
  const queryClient = useQueryClient();

  const changeLeadStatusMutation = useMutation({
    mutationFn: async ({
      leadId,
      newColumn,
    }: {
      leadId: number;
      newColumn: number;
    }) => {
      const response = await api.leadApi.changeLeadStatusColumn(
        leadId,
        newColumn,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.lead() });
    },
    ...mutationOpts,
  });

  return {
    changeLeadStatusMutation,
  };
}
