import { cva, VariantProps } from "class-variance-authority";
import { DATE_FORMAT } from "libs/common/src/lib/helpers/form/constants";
import { Moment } from "moment-timezone";
import Datetime from "react-datetime";
import { twMerge } from "tailwind-merge";

const datePickerButtonVariants = cva(
  "bg-gray-25 mt-2 flex cursor-pointer items-center justify-center !rounded-lg border-gray-300 px-4 text-center font-semibold capitalize text-gray-500 ring-1 ring-gray-300 hover:bg-gray-100",
  {
    variants: {
      size: {
        small: "h-8 text-sm",
        medium: "h-11",
      },
    },
    defaultVariants: {
      size: "medium",
    },
  },
);

interface DatePickerButtonProps
  extends VariantProps<typeof datePickerButtonVariants> {
  value: string;
  onChange: (newValue: string) => void;
  displayValue?: string;
  className?: string;
  isValidDate?:
    | ((currentDate: Date, selectedDate: Date) => boolean)
    | undefined;
}

export function DatePickerButton({
  value,
  onChange,
  displayValue,
  className,
  isValidDate,
  size,
}: DatePickerButtonProps) {
  return (
    <Datetime
      dateFormat={DATE_FORMAT}
      timeFormat={false}
      onChange={(newValue) => {
        onChange((newValue as Moment).format(DATE_FORMAT));
      }}
      value={value}
      closeOnSelect
      inputProps={{
        readOnly: true,
        value: displayValue || value,
        className: twMerge(datePickerButtonVariants({ size }), className),
      }}
      isValidDate={isValidDate}
    />
  );
}
