import { useTaskCreate } from "@gymflow/api";
import { useClubSettings } from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { useCallback, useContext } from "react";

import { normalizeOutgoingData } from "../utils/dataNormalizer";
import { TaskForm } from "./TaskForm/TaskForm";
import { TaskFormProps, TaskFormValues } from "./TaskForm/types";

type CreateTaskSidebarProps = Pick<TaskFormProps, "onClose" | "initialValues">;

export const CreateTaskForm = ({
  onClose,
  initialValues,
}: CreateTaskSidebarProps) => {
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const { toast, notifyDanger } = useContext(ToastContext);
  const { mutateAsync: mutateTaskCreate } = useTaskCreate({ api, tz });

  const onSubmit = useCallback(
    async (values: TaskFormValues) => {
      try {
        await mutateTaskCreate(normalizeOutgoingData(values));
        toast({ message: "Task Created" });
        onClose();
      } catch (e) {
        notifyDanger(e as any);
      }
    },
    [mutateTaskCreate, toast, notifyDanger, onClose],
  );

  return (
    <TaskForm
      initialValues={initialValues}
      title="Create Task"
      description="Assign tasks to owners and relate them to any user or lead."
      submitButtonText="Create Task"
      onClose={onClose}
      onSubmit={onSubmit}
    />
  );
};
