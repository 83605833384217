import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import { emailTemplateQueryKeys } from './emailTemplateQueryKeys';
import { TemplateDTO } from '@gymflow/types';

export function useEmailTemplate(
  { api, emailTemplateId }: {
    api: {
      emailTemplateApi:
      { findById: (arg0: number) => Promise<any> }
    },
    emailTemplateId: number
  }, opts?: UseQueryOptions<TemplateDTO | null>
) {
  const result = useQuery({
    queryKey: emailTemplateQueryKeys.details(emailTemplateId),
    queryFn: async () => {
      const result = await api.emailTemplateApi.findById(emailTemplateId);
      return result.data as TemplateDTO;
    },
    enabled: !!emailTemplateId,
    initialData: null,
    ...opts
  });

  return result;
}
