import {
  canTrimFields,
  canFindById,
  canFind,
  canCreate,
  canUpdate,
} from './features/apiResource';
import endpoints from './endpoints';

const promotionApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ['name', 'code'],
    baseUrl: `${clubPrefix}${endpoints.promotion}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state)
  );
};

export default promotionApi;
