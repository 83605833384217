import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

function LinkWithQuery({ children, to, ...props }) {
  const history = useHistory();
  const search = history?.location?.search;
  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
}

export default LinkWithQuery;
