import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { FormikInput, useRecordForm, FormMapper } from '@gymflow/common';

const BCC = 'bcc';

const AddBCCSchema = Yup.object().shape({
  [BCC]: Yup.array()
    .transform(function(value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
});

function AddBCCAlert({ bcc = '', onCancel, onSubmit }) {
  const { initialValues, getValues } = useRecordForm({
    record: { bcc },
    fields: AddBCCSchema.default(),
    mapper: new FormMapper(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async () => {
      await onSubmit(getValues(formik.values));
    },
    validationSchema: AddBCCSchema,
  });

  const renderButtons = () => (
    <>
      <Button size="sm" outline color="primary" type="button" onClick={onCancel} style={{ marginRight: '8px' }}>
        Cancel
      </Button>
      <Button size="sm" color="primary" type="button" onClick={formik.handleSubmit}>
        Save
      </Button>
    </>
  );

  return (
    <ReactBSAlert
      title={
        <div className="head-title d-flex justify-content-between">
          <h2>Add BCC</h2>
        </div>
      }
      showCancel
      closeOnClickOutside={false}
      customButtons={renderButtons()}
      customClass="alert-wrapper"
      style={{
        width: 580,
      }}
    >
      <Form className="form-horizontal text-left" role="form" style={{ height: 350 }}>
        <Row>
          <Col className="text-left">
            <b>
              Get included on the automation every time it fires by including your email address below. Include multiple
              emails by separating them with a comma. Emails are not shown when automation is sent.
            </b>
          </Col>
        </Row>
        <Row className="mt-3 mt-lg-20">
          <Col>
            <Label tag="h3" htmlFor={BCC}>
              BCC
            </Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup>
              <FormikInput
                name={BCC}
                type="textarea"
                data-testid={BCC}
                autoComplete="off"
                maxLength="1024"
                formikProps={formik}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
};

AddBCCAlert.propTypes = {
  bcc: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddBCCAlert;
