export function ThumbsDownIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="thumbs-down" clipPath="url(#clip0_2855_1364)">
        <path
          className={pathClassName}
          d="M11.3333 1.33331V8.66665M14.6667 6.53331V3.46665C14.6667 2.71991 14.6667 2.34654 14.5214 2.06133C14.3935 1.81044 14.1896 1.60647 13.9387 1.47864C13.6535 1.33331 13.2801 1.33331 12.5333 1.33331H5.41202C4.4377 1.33331 3.95054 1.33331 3.55707 1.5116C3.21028 1.66874 2.91555 1.92159 2.70751 2.24046C2.47146 2.60224 2.39739 3.08374 2.24923 4.04673L1.90051 6.3134C1.70511 7.58352 1.60741 8.21858 1.79588 8.71273C1.96131 9.14644 2.27245 9.50911 2.67596 9.73856C3.1357 9.99998 3.77824 9.99998 5.0633 9.99998H5.60002C5.97338 9.99998 6.16007 9.99998 6.30268 10.0726C6.42812 10.1366 6.5301 10.2385 6.59402 10.364C6.66668 10.5066 6.66668 10.6933 6.66668 11.0666V13.0228C6.66668 13.9307 7.40268 14.6666 8.31057 14.6666C8.52712 14.6666 8.72336 14.5391 8.81131 14.3412L11.0518 9.3001C11.1537 9.07081 11.2047 8.95617 11.2852 8.8721C11.3564 8.7978 11.4438 8.74098 11.5406 8.7061C11.6502 8.66665 11.7756 8.66665 12.0265 8.66665H12.5333C13.2801 8.66665 13.6535 8.66665 13.9387 8.52132C14.1896 8.39349 14.3935 8.18952 14.5214 7.93863C14.6667 7.65342 14.6667 7.28005 14.6667 6.53331Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2855_1364">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
