import { range } from "lodash";

import { pluralize } from "./string";

export function generateDurationOptions({
  intervalsInMinutes,
  hours,
}: {
  intervalsInMinutes: number;
  hours: number;
}) {
  const options: { label: string; value: number }[] = [];
  range(hours + 1).forEach((hour) => {
    if (hour === hours) {
      options.push({
        label: `${hour} ${pluralize("Hour", "Hours", hour)}`,
        value: 60 * hour,
      });
    } else {
      const intervalsPerHour = 60 / intervalsInMinutes;
      for (let m = 0; m < intervalsPerHour; m++) {
        if (hour === 0 && m === 0) {
          continue;
        }
        const minuteString = (m * intervalsInMinutes)
          .toString()
          .padStart(2, "0");
        let label;
        if (minuteString === "00") {
          label = `${hour} ${pluralize("Hour", "Hours", hour)}`;
        } else if (hour === 0) {
          label = `${minuteString} ${pluralize(
            "Minute",
            "Minutes",
            +minuteString,
          )}`;
        } else {
          label = `${hour} ${pluralize(
            "Hour",
            "Hours",
            hour,
          )} ${minuteString} ${pluralize("Minute", "Minutes", +minuteString)}`;
        }
        options.push({
          label,
          value: 60 * hour + m * intervalsInMinutes,
        });
      }
    }
  });
  return options;
}
