import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useLeadSourceCreate,
  useLeadSourceDisable,
  useLeadSourceList,
} from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { useContext, useState } from "react";

import { ModalWrapper } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { Button, TextInput } from "../atoms";

export interface LeadSourceModalProps {
  onCancel: () => void;
}

export function LeadSourceModal({ onCancel }: LeadSourceModalProps) {
  const { api } = useGymflowModels();
  const { data: leadSourceList } = useLeadSourceList({
    api,
    opts: {
      extraParams: { active: true },
      limit: 100,
      page: 0,
    },
  });
  const createSource = useLeadSourceCreate({ api });
  const disableSource = useLeadSourceDisable({ api });
  const [newSourceName, setNewSourceName] = useState("");
  const { notifyDanger } = useContext(ToastContext);
  return (
    <ModalWrapper
      className="flex flex-col gap-y-4 sm:!max-w-xl"
      onCancel={onCancel}
    >
      <div className="flex w-full flex-row items-start justify-between">
        <div className="flex flex-col">
          <div className="flex text-lg font-semibold text-gray-900">
            Lead Sources
          </div>
          <div className="flex text-sm text-gray-600">
            Add or remove lead sources
          </div>
        </div>
        <FontAwesomeIcon
          icon={faClose}
          onClick={onCancel}
          className="h-5 w-5 cursor-pointer text-gray-500"
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <div className="text-sm font-medium text-gray-700">Source Name</div>
        <div className="flex flex-row items-center gap-x-4">
          <TextInput
            placeholder="Enter new lead source"
            type="text"
            value={newSourceName}
            onChange={(e) => {
              setNewSourceName(e.currentTarget.value);
            }}
          />
          <Button
            onClick={async () => {
              if (newSourceName.length > 0) {
                await createSource
                  .mutateAsync({
                    name: newSourceName,
                  })
                  .catch((e) => {
                    notifyDanger(e);
                  });
                setNewSourceName("");
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>

      {leadSourceList.content.map((e) => {
        return (
          <div key={e.id} className="flex flex-row justify-between">
            <div className="text-sm text-gray-700">{e.name}</div>
            <FontAwesomeIcon
              icon={faClose}
              onClick={async () => {
                await disableSource.mutateAsync(e.id);
              }}
              className="h-5 w-5 cursor-pointer text-gray-500"
            />
          </div>
        );
      })}

      <div className="flex space-x-2">
        <Button onClick={onCancel} className="flex-1">
          Close
        </Button>
      </div>
    </ModalWrapper>
  );
}
