import {
  AvailabilityOverrideDTO,
  AvailabilityOverridePostDTO,
} from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityOverrideEdit({
  api,
}: {
  api: {
    availabilityApi: {
      updateHostOverride: (
        staffId: string,
        overrideId: number,
        patchedFields: unknown,
      ) => Promise<{ data: AvailabilityOverrideDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      patchedFields,
      availabilityOverrideId,
      staffId,
    }: {
      patchedFields: Partial<AvailabilityOverridePostDTO>;
      availabilityOverrideId: number;
      staffId: string;
    }) => {
      const response = await api.availabilityApi.updateHostOverride(
        staffId,
        availabilityOverrideId,
        patchedFields,
      );
      return response.data;
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allHostOverrides(staffId),
      });
    },
  });

  return mutation;
}
