import { useSessionPackList } from "@gymflow/api";
import { cn, formatCurrency } from "@gymflow/helpers";
import React, { useContext, useState } from "react";

import searchIcon from "../../../assets/img/search-lg.svg";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button } from "../atoms";
import { CreditPackFormSidebarProviderContext } from "../Settings/Sessions/CreditPackFormSidebarProvider";
import { ShopItemPickerProps } from "./ShopItemPicker";
import { ShopCartType } from "./ShopTypes";

export type ShopCreditPackSelectProps = {
  cart?: ShopCartType;
  setCart: ShopItemPickerProps["setCart"];
  hideShopSidebar: () => void;
  showShopSidebar: () => void;
};

export const ShopCreditPackSelect: React.FC<ShopCreditPackSelectProps> = ({
  cart,
  setCart,
  hideShopSidebar,
  showShopSidebar,
}) => {
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const { data } = useSessionPackList({
    api,
    opts: {
      sort: { field: "createdDate", desc: true },
      extraParams: {
        status: "ACTIVE",
        unpaged: true,
      },
    },
  });
  const { showCreditPackForm } = useContext(
    CreditPackFormSidebarProviderContext,
  );
  const [query, setQuery] = useState("");
  return (
    <div className="pt-[2px]">
      <div className="flex flex-row items-center justify-between px-6 py-4">
        <img src={searchIcon} alt="search-icon" className="h-5" />
        <input
          placeholder="Search..."
          className="flex h-11 w-full pl-2 placeholder:text-base placeholder:text-gray-900 focus:outline-none"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          className="min-w-fit"
          onClick={() => {
            hideShopSidebar();
            showCreditPackForm({
              onClose: (creditPack) => {
                if (!creditPack) {
                  showShopSidebar();
                  return;
                }
                setCart({
                  type: "CREDIT_PACK",
                  payload: creditPack,
                });
                showShopSidebar();
              },
            });
          }}
        >
          Create New
        </Button>
      </div>
      {data?.content
        .filter(
          (e) =>
            e.name.toLowerCase().includes(query.toLowerCase()) ||
            e.price.toString().includes(query),
        )
        ?.map((creditPack) => (
          <div
            key={creditPack.id}
            className={cn(
              "hover:bg-primary-50 -z-10 flex w-full cursor-pointer items-center justify-between border-t border-gray-100 px-6 py-4",
              {
                "bg-primary-50":
                  cart?.type === "CREDIT_PACK" &&
                  cart?.payload?.id === creditPack.id,
              },
            )}
            onClick={() => {
              setCart({
                type: "CREDIT_PACK",
                payload: creditPack,
              });
            }}
          >
            <div className="text-sm text-gray-900"> {creditPack.name}</div>
            <div className="flex flex-col items-end">
              <div className="text-sm font-semibold text-gray-900">
                {formatCurrency(creditPack.price, clubSettings.defaultCurrency)}
              </div>
              <div className="text-sm text-gray-600">
                {creditPack.sessionsUnlimited
                  ? "Unlimited Credits"
                  : `${creditPack.sessionsIncluded} ${
                      creditPack.sessionsIncluded > 1 ? "Credits" : "Credit"
                    }`}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
