export function resetTailwindColors() {
  const root = document.querySelector(":root");
  if (root) {
    const setProperty = (root as HTMLElement).style.setProperty.bind(
      (root as HTMLElement).style,
    );
    // Colors should match tailwind-base.css
    setProperty("--ring-color", "rgb(59 130 246 / 0.5)");
    setProperty("--primary-color-25", "249 250 255");
    setProperty("--primary-color-50", "247 248 255");
    setProperty("--primary-color-100", "216 219 255");
    setProperty("--primary-color-200", "182 188 252");
    setProperty("--primary-color-300", "64 70 129");
    setProperty("--primary-color-400", "51 58 120");
    setProperty("--primary-color-500", "38 46 112");
    setProperty("--primary-color-600", "26 33 104");
    setProperty("--primary-color-700", "0 9 87");
    setProperty("--primary-color-800", "0 9 87");
    setProperty("--primary-color-900", "0 8 76");

    setProperty("--secondary-color-900", "34 8 74");
    setProperty("--secondary-color-800", "57 13 125");
    setProperty("--secondary-color-700", "80 18 176");
    setProperty("--secondary-color-600", "103 23 227");
    setProperty("--secondary-color-500", "135 70 236");
    setProperty("--secondary-color-400", "159 107 240");
    setProperty("--secondary-color-300", "183 144 244");
    setProperty("--secondary-color-200", "207 181 247");
    setProperty("--secondary-color-100", "231 218 251");
    setProperty("--secondary-color-50", "243 236 253");
    setProperty("--secondary-color-25", "243 236 253");
  }
}
