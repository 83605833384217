import { ApiListResponse, AvailabilityOverrideDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryFacilityAvailabilityOverrideList(
  {
    api,
    facilityId,
    opts,
  }: {
    api: {
      availabilityApi: {
        listFacilityOverride: (
          facilityId: number,
          opts?: Readonly<Record<string, any>>,
        ) => Promise<any>;
      };
    };
    facilityId?: number;
    opts?: AvailabilityOverrideListFilter;
  },
  queryOpts?: UseQueryOptions<ApiListResponse<AvailabilityOverrideDTO>>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.listFacilityOverrides(
      facilityId as number,
      opts,
    ),
    queryFn: async () => {
      const result = await api.availabilityApi.listFacilityOverride(
        facilityId as number,
        opts,
      );
      return result.data as ApiListResponse<AvailabilityOverrideDTO>;
    },
    enabled: !!facilityId,
    initialData: defaultPage<AvailabilityOverrideDTO>,
    ...queryOpts,
  });

  return result;
}

interface AvailabilityOverrideListFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
  readonly overrideDate?: string;
  readonly unpaged?: boolean;
  readonly isUnavailableAllDay?: boolean;
  readonly startTime?: string;
  readonly endTime?: string;
  readonly id?: number[];
}
