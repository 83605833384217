import { cva, VariantProps } from "class-variance-authority";
import classNames from "classnames";

const statusCircleVariants = cva("", {
  variants: {
    status: {
      available: "bg-success-500",
      maybe: "bg-warning-500",
      unavailable: "bg-error-500",
    },
  },
});
type StatusCircleVariantProps = VariantProps<typeof statusCircleVariants>;

export function StatusCircle(props: StatusCircleVariantProps) {
  return (
    <div
      className={classNames("h-2 w-2 rounded-sm", statusCircleVariants(props))}
    />
  );
}
