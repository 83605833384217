import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canTrimFields } from "./features/apiResource";

const hostedClubApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.hostedClub}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, canTrimFields(state), {
    findById(id) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );
      return state.http.get(state.baseUrl);
    },
    create(id, { fields, logo }) {
      const formData = state.transformIntoFormData({ file: logo, fields });

      return state.http.post(`${clubPrefix}settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },

    update(id, { fields, logo }) {
      assert(check.any([check.number(id)]), "id must be number or string");
      const formData = state.transformIntoFormData({ file: logo, fields });

      return state.http.put(`${clubPrefix}settings`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    /**
     * @private
     */
    transformIntoFormData({ file, fields }) {
      const formData = new FormData();
      const trimmedData = state.trimFields(fields);
      if (file) {
        formData.append("file", file.blob, file.name);
      }

      if (fields) {
        formData.append(
          "club_hosted_page",
          new Blob([JSON.stringify({ settings: trimmedData })], {
            type: "application/json",
          }),
        );
      }

      return formData;
    },
  });
};

export default hostedClubApi;
