import { useState } from "react";
import { Col, Row } from "reactstrap";
import { Spinner } from "@gymflow/common";
import Select from "react-select";

import LandingPage from "./LandingPage";
import MembershipFilterableList from "./MembershipFilterableList";
import SessionPackFilterableList from "./SessionPackFilterableList";

function Step5Timetable({ value, onChange }) {
  const [isLoading, setIsLoading] = useState(false);
  const landingOptions = [
    {
      label: "Memberships",
      value: LandingPage.Memberships,
    },
    {
      label: "Credit Packs",
      value: LandingPage.SessionPacks,
    },
  ];

  return (
    <>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div className="w-100 text-center" style={{ opacity: isLoading ? "0.2" : "1" }}>
        <b className="h2">Purchase options for first time bookings</b>
        <Row className="d-flex justify-content-center mt-4 text-left">
          <Col md={8}>
            <p className="text-muted">
              Choose the default landing page as well as the memberships or credit packs you would like to offer when a
              user books.
            </p>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              options={landingOptions}
              value={landingOptions.find((option) => option.value === value.landingPage)}
              onChange={({ value: newValue }) => {
                onChange({ ...value, landingPage: newValue });
              }}
            />
            <div className="mt-2">
              {value.landingPage === LandingPage.Memberships ? (
                <MembershipFilterableList
                  value={value?.memberships}
                  onChangeLoading={setIsLoading}
                  onChange={(newValue) => {
                    onChange({ ...value, memberships: newValue, sessionPacks: [] });
                  }}
                />
              ) : (
                <SessionPackFilterableList
                  value={value?.sessionPacks}
                  onChangeLoading={setIsLoading}
                  onChange={(newValue) => {
                    onChange({ ...value, sessionPacks: newValue, memberships: [] });
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Step5Timetable;
