const DurationType = {
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  NoContract: 'NO_CONTRACT',
};

Object.setPrototypeOf(DurationType, {
  format(durationType, isPlural = false) {
    let text = durationTypeToInterval[durationType];
    if (!isPlural && text[text.length - 1] === 's') {
      text = text.substring(0, text.length - 1);
    }

    return text;
  },
});

const durationTypeToInterval = {
  [DurationType.Daily]: 'days',
  [DurationType.Weekly]: 'weeks',
  [DurationType.Monthly]: 'months',
};

export default DurationType;
