import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationMemberUpdateHomeClub({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      memberId,
      clubId,
    }: {
      memberId: string;
      clubId: string;
    }) => {
      await api.memberApi.updateHomeClub(memberId, clubId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
}
