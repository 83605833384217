import { SMSPayload, SMSSendingResponse } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export type SendSMSToMembersArgs = {
  smsPayload: SMSPayload;
  userMemberIds: string[];
};

type Api = {
  smsApi: {
    sendSMSToMembers: (
      args: SendSMSToMembersArgs,
    ) => Promise<{ data: SMSSendingResponse }>;
  };
};

export const useMutationSendSMSToMembers = ({ api }: { api: Api }) => {
  return useMutation({
    mutationFn: async (payload: SendSMSToMembersArgs) => {
      const { data } = await api.smsApi.sendSMSToMembers(payload);
      return data;
    },
  });
};
