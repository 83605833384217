import { useEffect } from "react";

import { TextButton } from "../components/atoms";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { useRememberMe } from "../hooks/useRememberMe";

export function Unauthorized() {
  const { createLoginLink } = usePortalRoutes();
  const { forgetRoute } = useRememberMe();
  useEffect(() => {
    forgetRoute();
  }, []);

  return (
    <div className="bg-primary-50 flex h-[100vh] w-full justify-center">
      <div className="mt-24 w-[420px]">
        <div className="bg-gymflow-logo m-8 h-12 bg-contain bg-center bg-no-repeat" />
        <div className="mt-5">
          You are not authorized to access this club. Make sure you are
          selecting the right club at login.
        </div>
        <div className="mt-5 flex justify-center">
          <TextButton
            onClick={() => {
              window.location.href = createLoginLink();
            }}
          >
            Back to login
          </TextButton>
        </div>
      </div>
    </div>
  );
}
