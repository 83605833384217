import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ServiceStatus } from '@gymflow/common';

import FilterableList from '../../../forms/FilterableList';
import useSessionPacks from '../../../../hooks/useSessionPacks';
import useFilterableListWithGroups from '../../../../hooks/useFilterableListWithGroups';

function SessionPackFilterableList({ value, onChange, onChangeLoading }) {
  const {
    fetchList: fetchSessionPacks,
    fetchCategories,
    rows: sessionPacks,
    categories,
    loadingList: isLoading,
  } = useSessionPacks();
  const { groupedOptions, selection, setSelection } = useFilterableListWithGroups({
    groups: categories,
    options: sessionPacks,
    groupIdField: 'id',
    optionGroupField: 'activityCategoryIdList',
    onChange,
    initialSelection: value,
  });

  useEffect(() => {
    onChangeLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const fetchSessionPackAndCategories = async () => {
      await fetchCategories();
      await fetchSessionPacks({
        status: ServiceStatus.Active,
        extraParams: { unpaged: true, isPublic: true },
      });
    };

    fetchSessionPackAndCategories();
  }, []);

  return <FilterableList options={groupedOptions} value={selection} onChange={setSelection} />;
}

SessionPackFilterableList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLoading: PropTypes.func.isRequired,
};

export default SessionPackFilterableList;
