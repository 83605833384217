import { action, actionOn, thunk } from 'easy-peasy';

import BaseModelBuilder, { getApi } from './BaseModelBuilder';

class RuleModelBuilder {
  constructor(apiKey, clubId) {
    this.generators = [];
    this.apiKey = apiKey;
    this.clubId = clubId;
    this.generators.push(this.ruleGenerator);
  }

  ruleGenerator() {
    return {
      apiKey: this.apiKey,
      clubId: this.clubId,
      loading: false,
      schema: [],
      values: {},
      fetchSchema: thunk(async (actions, _, { injections, getState }) => {
        const result = await getApi(injections, getState()).find({ page: 0 });
        actions.setSchema(result.data);
      }),
      setSchema: action((state, { content }) => {
        state.schema = content.map((field) => ({ ...field, rulesValues: JSON.parse(field.ruleDefaultValue) }));
      }),
      fetchValues: thunk(async (actions, _, { injections, getState, getStoreState }) => {
        const result = await getApi(injections, getState()).getValuesByClubId(getStoreState().clubId);
        actions.setValues(result.data);
      }),
      setValues: action((state, payload) => {
        state.values = payload.reduce((acc, v) => {
          return {
            ...acc,
            [v.ruleName]: JSON.parse(v.value),
          };
        }, {});
      }),
      updateValues: thunk((_, payload, { injections, getState, getStoreState }) => {
        const { schema } = getState();
        const newValues = Object.keys(payload).map((k) => {
          const ruleId = schema.find((f) => f.ruleName === k).id;

          return { ruleId, value: JSON.stringify(payload[k]) };
        });
        return getApi(injections, getState()).updateValues(getStoreState().clubId, newValues);
      }),

      onFetchStart: actionOn(
        (actions) => [actions.fetchSchema.startType, actions.fetchValues.startType],
        (state) => {
          state.loading = true;
        }
      ),

      onFetchDone: actionOn(
        (actions) => [
          actions.fetchSchema.successType,
          actions.fetchSchema.failType,
          actions.fetchValues.successType,
          actions.fetchValues.failType,
        ],
        (state) => {
          state.loading = false;
        }
      ),
    };
  }
}

RuleModelBuilder.prototype.build = BaseModelBuilder.prototype.build;

export default RuleModelBuilder;
