import {
  ApiListResponse,
  MembershipStatus,
  SessionPackDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { sessionPackQueryKeys } from "./sessionPackQueryKeys";

export async function sessionPackListQueryFn({
  filter,
  api,
}: {
  filter: SessionPackListFilter;
  api: SessionPackListApi;
}) {
  const result = await api.creditPackApi.find({ ...filter });
  return result.data as ApiListResponse<SessionPackDTO>;
}

export function useSessionPackList({
  api,
  opts,
}: {
  api: SessionPackListApi;
  opts: SessionPackListFilter;
}) {
  const result = useQuery({
    queryKey: sessionPackQueryKeys.list(opts),
    queryFn: () => sessionPackListQueryFn({ api, filter: opts }),
    initialData: defaultPage<SessionPackDTO>,
  });
  return result;
}

interface SessionPackListApi {
  creditPackApi: { find: (arg0: { [k: string]: any }) => Promise<any> };
}

interface SessionPackListFilter {
  readonly extraParams?: {
    readonly unpaged?: boolean;
    readonly status?: MembershipStatus;
    readonly id?: number[];
    readonly hasActivityCategory?: boolean;
    readonly hasAppointmentCategory?: boolean;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
