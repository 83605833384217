import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LeadSourceDTO } from "@gymflow/types";
import { useCallback, useEffect, useState } from "react";

import useLeadStatusLoadOptions from "../../hooks/useLeadStatusLoadOptions";
import useGymflowModels from "../../store";
import { AsyncSelect, Button, PrimaryButton, SlideSideBar } from "../atoms";

export interface OpenLeadFilter {
  sources: { label: string; value: number }[];
  status: { label: string; value: { id: string } }[];
}

export interface OpenLeadFilterSideBarProps {
  isVisible: boolean;
  onClose: () => void;
  value: OpenLeadFilter;
  onChange: (newFilters: OpenLeadFilter) => void;
}

export const OpenLeadFilterSideBar: React.FC<OpenLeadFilterSideBarProps> = ({
  value,
  onChange,
  isVisible,
  onClose,
}) => {
  const { api } = useGymflowModels();

  const [newFilters, setNewFilters] = useState<OpenLeadFilter>(value);
  useEffect(() => {
    setNewFilters(value);
  }, [value]);

  const loadSourceOptions = useCallback(async () => {
    const { data: result } = await api.public.leadApi.findLeadSources({
      page: 0,
      limit: 50,
      extraParams: { active: true },
    });
    return {
      options: result.map((r: LeadSourceDTO) => {
        return {
          label: r.name,
          value: r.id,
        };
      }),
    };
  }, [api.public.leadApi]);
  const leadStatusLoadOptions = useLeadStatusLoadOptions();

  return (
    <SlideSideBar isOpen={isVisible} hide={onClose}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">Filters</div>
              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              Apply filters to table data
            </div>
          </div>
          <div className="mx-6 mb-2 mt-6 flex text-sm font-medium text-gray-700">
            Lead Source
          </div>
          <AsyncSelect
            className="mx-6 min-w-0 max-w-full"
            loadOptions={loadSourceOptions}
            value={newFilters.sources}
            isMulti
            onChange={(value) => {
              setNewFilters((e) => ({ ...e, sources: value }));
            }}
          />
          <div className="mx-6 mb-2 mt-6 flex text-sm font-medium text-gray-700">
            Lead status
          </div>
          <AsyncSelect
            className="mx-6 min-w-0 max-w-full"
            loadOptions={leadStatusLoadOptions}
            value={newFilters.status}
            isMulti
            additional={{
              includeAll: false,
              excludePreset: ["DEAL_LOST", "DEAL_CLOSED"],
            }}
            onChange={(value) => {
              setNewFilters((e) => ({ ...e, status: value }));
            }}
          />
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button onClick={() => onClose()} className="mr-2 mt-2 w-full">
            Cancel
          </Button>
          <PrimaryButton
            className="w-full"
            onClick={() => {
              if (newFilters) {
                onChange(newFilters);
              }
              onClose();
            }}
          >
            Apply
          </PrimaryButton>
        </div>
      </div>
    </SlideSideBar>
  );
};
