import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import moment from "moment-timezone";
import qs from "qs";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import { getTimeslotOnViewport } from "./helpers";

export function AvailabilityDropdown({
  resourceId,
  resourceType,
}: {
  resourceId: string;
  resourceType: "FACILITY" | "STAFF";
}) {
  const history = useHistory();
  const { createClubLink } = usePortalRoutes();

  return (
    <Menu as="div" className="relative z-[9999] inline-block text-left">
      <div>
        <Menu.Button
          as="div"
          className="flex w-full cursor-pointer items-center justify-center"
        >
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="min-w-32 absolute right-0 z-[9999] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="p-2">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames("cursor-pointer p-2", {
                    "font-semibold text-gray-600": !active,
                    "font-bold text-gray-600": active,
                  })}
                  onClick={() => {
                    const marker = getTimeslotOnViewport();
                    if (marker) {
                      history.push({
                        search: qs.stringify({
                          ...qs.parse(history.location.search, {
                            ignoreQueryPrefix: true,
                          }),
                          marker: moment(marker, "h:mm A").format("HH:mm"),
                        }),
                      });
                    }
                    switch (resourceType) {
                      case "STAFF":
                        history.push(
                          createClubLink(
                            RouteLayout.Staff,
                            RouteFeature.StaffAvailability.replace(
                              ":staffId",
                              resourceId,
                            ),
                          ),
                        );
                        break;
                      case "FACILITY":
                        history.push(
                          createClubLink(
                            RouteLayout.Staff,
                            RouteFeature.FacilityAvailability.replace(
                              ":facilityId",
                              resourceId,
                            ),
                          ),
                        );
                        break;
                    }
                  }}
                >
                  Availability
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames("cursor-pointer p-2", {
                    "font-semibold text-gray-600": !active,
                    "font-bold text-gray-600": active,
                  })}
                  onClick={() => {
                    const marker = getTimeslotOnViewport();
                    if (marker) {
                      history.push({
                        search: qs.stringify({
                          ...qs.parse(history.location.search, {
                            ignoreQueryPrefix: true,
                          }),
                          marker: moment(marker, "h:mm A").format("HH:mm"),
                        }),
                      });
                    }
                    switch (resourceType) {
                      case "STAFF":
                        history.push({
                          pathname: createClubLink(
                            RouteLayout.Staff,
                            RouteFeature.StaffAvailability.replace(
                              ":staffId",
                              resourceId,
                            ),
                          ),
                          search: "?section=blocked",
                        });
                        break;
                      case "FACILITY":
                        history.push({
                          pathname: createClubLink(
                            RouteLayout.Staff,
                            RouteFeature.FacilityAvailability.replace(
                              ":facilityId",
                              resourceId,
                            ),
                          ),
                          search: "?section=blocked",
                        });
                        break;
                    }
                  }}
                >
                  Blocked Time
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
