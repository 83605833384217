import { z } from "zod";

export const MembershipBillingType = {
  Weekly: "WEEKLY",
  Monthly: "MONTHLY",
} as const;

export const MembershipBillingTypeSchema = z.enum([
  MembershipBillingType.Weekly,
  MembershipBillingType.Monthly,
]).default(MembershipBillingType.Weekly);
