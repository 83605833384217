import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useQueryPaymentMethodSetupIntentAsMember({
  api,
  memberId,
  enabled,
}: {
  api: ApiType;
  memberId?: string;
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: memberAsMemberQueryKeys.paymentMethodSetupIntent(memberId),
    queryFn: async () => {
      const result =
        await api.profileApi.getPaymentMethodSetupIntent();
      return result.data;
    },
    enabled: !!memberId && !!enabled,
  });
}
