/*!

=========================================================
* Black Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import classNames from "classnames";
import { isMobile } from "@gymflow/common";

class Footer extends React.Component {
  render() {
    return (
      <footer
        className={classNames("footer" + (this.props.default ? " footer-default" : ""), { "!pb-52": isMobile() })}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <div className="copyright">
            Powered by{" "}
            <a target="_blank" href="https://gymflow.io">
              gymflow.io
            </a>
          </div>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
