import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { creditPackAsMemberQueryKeys } from "./creditPackAsMemberQueryKeys";

export function useQueryCreditPackSummaryAsMember({
  api,
  opts,
  enabled,
}: {
  api: ApiType;
  enabled?: boolean;
  opts?: Parameters<ApiType["customerCreditPackApi"]["summary"]>[0];
}) {
  const result = useQuery({
    queryKey: creditPackAsMemberQueryKeys.summary(opts),
    queryFn: async () => {
      return (await api.customerCreditPackApi.summary(opts!)).data;
    },
    enabled: !!opts && enabled,
  });
  return result;
}
