import { UncontrolledTooltip } from 'reactstrap';

export interface MultipleImageStackProps {
  data: {
    id: string;
    source: string;
    label: string;
  }[];
}
export const MultipleImageStack = ({ data }: MultipleImageStackProps) => {
  data = data.sort((a, b) => a.label.localeCompare(b.label));
  return (
    <div className="flex flex-row">
      {data.slice(0, 2)?.map(({ source, label, id }) => (
        <div key={id} className="mr-[-15px]">
          <img
            id={`owner-tooltip-${id}`}
            className="h-8 w-8 rounded-2xl"
            src={source}
            alt="owner"
          />
          <UncontrolledTooltip placement="top" target={`owner-tooltip-${id}`}>
            {label}
          </UncontrolledTooltip>
        </div>
      ))}
      {data.length > 2 && (
        <div className="mr-[-15px]">
          <div
            id={`owner-tooltip-rest`}
            className="flex h-8 w-8 cursor-default items-center justify-center rounded-2xl border border-gray-200 bg-gray-50 font-semibold"
          >
            +{data.length - 2}
          </div>
          <UncontrolledTooltip placement="top" target={`owner-tooltip-rest`}>
            {data.slice(2).map((e) => (
              <div>{e.label}</div>
            ))}
          </UncontrolledTooltip>
        </div>
      )}
    </div>
  );
};
