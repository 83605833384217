import { useMutation, useQueryClient } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export function useFacilityUpdatePicture({
  api,
}: {
  api: {
    facilityApi: {
      updatePicture: (
        id: number,
        file: { blob: string; name: string },
      ) => Promise<{ data: string }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      file,
    }: {
      facilityId: number;
      file: { blob: string; name: string };
    }) => {
      await api.facilityApi.updatePicture(facilityId, file);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: facilityQueryKeys.all() });
    },
  });

  return mutation;
}
