import { useContext, useState } from "react";
import { Button } from "reactstrap";
import { AsyncButton, AlertContext } from "@gymflow/common";
import ReactBsAlert from "react-bootstrap-sweetalert";
import styled from "styled-components";

/**
 * @deprecated rewrite in tailwind
 */
const AdjustButton = styled(Button)`
  width: 24px;
  height: 24px;
  min-height: unset;
  border-radius: 50%;
  padding: 0;
  background: white;
  color: #31436e;
  font-size: 18px;
  border: 1px solid #31436e;
`;

function AdjustSessionPackAlert({ remaining, totalCredits, onSubmit }) {
  const { hide } = useContext(AlertContext);
  const [sessionRemaining, setSessionRemaining] = useState(remaining);

  const renderButtons = () => (
    <>
      <Button
        size="sm"
        outline
        color="primary"
        type="button"
        onClick={hide}
        style={{ marginRight: "8px" }}
      >
        Cancel
      </Button>
      <AsyncButton
        size="sm"
        color="primary"
        type="button"
        onClick={async () => {
          await onSubmit(sessionRemaining);
          hide();
        }}
      >
        Save
      </AsyncButton>
    </>
  );
  return (
    <ReactBsAlert
      title="Adjust Credit Pack"
      closeOnClickOutside={false}
      customButtons={renderButtons()}
      onCancel={hide}
    >
      <div className="d-flex flex-column align-items-center">
        <div className="font-weight-bold">
          Add or deduct creits on this pack
        </div>
        <div className="text-muted text-uppercase mt-4">Credits remaining</div>
        <div className="mt-2">
          <AdjustButton
            onClick={() => setSessionRemaining((r) => r - 1)}
            disabled={sessionRemaining === 0}
          >
            -
          </AdjustButton>
          <div
            className="d-inline-block font-weight-bold px-2"
            style={{ width: 50 }}
          >
            {sessionRemaining}
          </div>
          <AdjustButton
            onClick={() => setSessionRemaining((r) => r + 1)}
            disabled={sessionRemaining === totalCredits}
          >
            +
          </AdjustButton>
        </div>
      </div>
    </ReactBsAlert>
  );
}

export default AdjustSessionPackAlert;
