import { useMutation, useQueryClient } from "@tanstack/react-query";

import { calendarAsMemberQueryKeys } from "../calendarAsMember";
import { eventOccurrenceAsMemberQueryKeys } from "./eventOccurrenceAsMemberQueryKeys";

export function useEventOccurrenceRsvpAsMember({
  api,
}: {
  api: {
    customerOccurrenceApi: {
      updateRsvp: (rsvpId: number, status: string) => Promise<any>;
      addAttendeeToRsvp: (
        userMemberId: string,
        occurrenceId: number,
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const updateRsvpMutation = useMutation({
    mutationFn: ({ rsvpId, status }: { rsvpId: number; status: string }) =>
      api.customerOccurrenceApi.updateRsvp(rsvpId, status),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.eventOccurrences(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.userMemberBookingAgenda(),
      });
    },
  });

  const addAttendeeToRsvpMutation = useMutation({
    mutationFn: ({
      userMemberId,
      occurrenceId,
    }: {
      userMemberId: string;
      occurrenceId: number;
    }) =>
      api.customerOccurrenceApi.addAttendeeToRsvp(userMemberId, occurrenceId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceAsMemberQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.eventOccurrences(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarAsMemberQueryKeys.userMemberBookingAgenda(),
      });
    },
  });

  return {
    updateRsvp: updateRsvpMutation,
    addAttendeeToRsvp: addAttendeeToRsvpMutation,
  };
}
