import { tzDateTimeStringToUtc } from "@gymflow/common";
import { RevenueType, TransactionType } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useRevenueByTransactionTypeReportCsv({
  api,
  tz,
}: {
  api: {
    revenueApi: {
      csv: (
        params: {
          dateFrom: string;
          dateTo: string;
          transactionType: TransactionType;
        },
        revenueType: RevenueType,
      ) => Promise<any>;
    };
  };
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      transactionType,
      dateFrom,
      dateTo,
    }: {
      dateFrom: string;
      dateTo: string;
      transactionType: TransactionType;
    }) => {
      const result = await api.revenueApi.csv(
        {
          transactionType,
          dateFrom: tzDateTimeStringToUtc(dateFrom, tz),
          dateTo: tzDateTimeStringToUtc(dateTo, tz),
        },
        "transaction-type",
      );
      return result.data as string;
    },
  });
  return csvMutation;
}
