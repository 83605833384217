import { AppointableDTO, AppointablePostDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointableQueryKeys } from "./appointableQueryKeys";

export function useAppointableCreate({
  api,
}: {
  api: {
    appointableApi: {
      create: (fields: AppointablePostDTO) => Promise<{ data: AppointableDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newAppointable: AppointablePostDTO) => {
      const response = await api.appointableApi.create(newAppointable);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointableQueryKeys.all() });
    },
  });

  return mutation;
}
