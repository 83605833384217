import { ApiListResponse, AvailabilityOverrideDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityOverrideList(
  {
    api,
    staffId,
    opts,
  }: {
    api: {
      availabilityApi: {
        listHostOverride: (
          staffId: string,
          opts?: Readonly<Record<string, any>>,
        ) => Promise<{ data: ApiListResponse<AvailabilityOverrideDTO> }>;
      };
    };
    staffId: string | null;
    opts?: AvailabilityOverrideListFilter;
  },
  queryOpts?: UseQueryOptions<ApiListResponse<AvailabilityOverrideDTO>>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.listHostOverrides(staffId as string, opts),
    queryFn: async () => {
      const result = await api.availabilityApi.listHostOverride(
        staffId as string,
        opts,
      );
      return result.data;
    },
    enabled: !!staffId,
    initialData: defaultPage<AvailabilityOverrideDTO>,
    ...queryOpts,
  });

  return result;
}

interface AvailabilityOverrideListFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
  readonly overrideDate?: string;
  readonly unpaged?: boolean;
  readonly isUnavailableAllDay?: boolean;
  readonly startTime?: string;
  readonly endTime?: string;
  readonly id?: number[];
}
