import { InvitationProcessStatusDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { migrationQueryKeys } from "./migrationQueryKeys";

export function useQueryMigrationStatus(
  {
    api,
    invitationToken,
  }: {
    api: {
      public: {
        hostedClubApi: {
          invitationStatus: ({
            invitationToken,
          }: {
            invitationToken: string;
          }) => Promise<{ data: InvitationProcessStatusDTO }>;
        };
      };
    };
    invitationToken?: string;
  },
  opts?: UseQueryOptions<InvitationProcessStatusDTO>,
) {
  const result = useQuery({
    queryKey: migrationQueryKeys.status({ invitationToken }),
    queryFn: async () => {
      const result = await api.public.hostedClubApi.invitationStatus({
        invitationToken: invitationToken as string,
      });
      return result.data;
    },
    enabled: !!invitationToken,
    ...opts,
  });

  return result;
}
