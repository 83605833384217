import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationAddPromotionCodeToSubscription({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      subscriptionId,
      promotionCode,
    }: {
      subscriptionId: number;
      promotionCode: string;
    }) => {
      const response = await api.memberApi.addPromotionCodeToSubscription({
        subscriptionId,
        promotionCode,
      });
      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
}
