import { zonedTimeToUtc } from "@gymflow/helpers";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";
import { LeadListFilter } from "./useInfiniteQueryLeadList";

export function useQueryLeadListCount(
  {
    api,
    filters,
    tz,
  }: {
    api: MemberApi;
    filters?: LeadListFilter;
    tz: string;
  },
  queryOpts?: UseQueryOptions<unknown, unknown, { count: number }>,
) {
  return useQuery({
    queryKey: leadQueryKeys.listCount(filters),
    queryFn: async () => {
      const parsedOpts = { ...filters };
      if (filters?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(filters.createdFrom, tz);
      }
      if (filters?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(filters.createdTo, tz);
      }
      return (await api.leadApi.listCount(parsedOpts)).data;
    },
    ...queryOpts,
  });
}

interface MemberApi {
  leadApi: {
    listCount: (queryParams: any) => Promise<{ data: { count: number } }>;
  };
}
