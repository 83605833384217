export type XIconProps = {
  className?: string;
  pathClassName?: string;
};

export const XIcon = ({ className, pathClassName }: XIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M15 5L5 15M5 5L15 15"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};
