import { Membership } from '@gymflow/types';
import { useQuery,UseQueryOptions } from '@tanstack/react-query';

import { membershipQueryKeys } from './membershipQueryKeys';

export function useMembership(
  { api, membershipId }: {
    api: {
      serviceApi:
      { findById: (arg0: number) => Promise<any> }
    },
    membershipId?: number
  }, opts?: UseQueryOptions<Membership | null>
) {
  const result = useQuery({
    queryKey: membershipQueryKeys.details(membershipId),
    queryFn: async () => {
      const result = await api.serviceApi.findById(membershipId!);
      return result.data as Membership;
    },
    enabled: !!membershipId,
    initialData: null,
    ...opts
  });

  return result;
}
