import { assert } from "check-types";
import defaults from "lodash/defaults";
import toPairs from "lodash/toPairs";
import { useCallback, useMemo } from "react";

import { getChangedFields } from "../helpers/form";
import TwoWayObjectMapper from "../helpers/form/FormMapper/TwoWayObjectMapper";

const useRecordForm = ({ record, fields, mapper }) => {
  assert.not.undefined(record, 'record parameter cannot be undefined, if no record data is available pass "null".');

  mapper = typeof mapper === "undefined" ? new TwoWayObjectMapper() : mapper;

  const mapFormFieldsToRecord = useCallback((formValues) => mapper.from(formValues), [mapper]);

  const mapRecordToFormFields = useCallback((record) => mapper.to(record), [mapper]);

  const initial = useMemo(() => {
    const fieldDefaults = toPairs(fields).reduce((acc, [fieldName, fieldValue]) => {
      acc[fieldName] = typeof fieldValue === "undefined" ? "" : fieldValue;
      return acc;
    }, {});

    return defaults(record === null ? {} : mapRecordToFormFields(record), fieldDefaults);
  }, [record, fields, mapRecordToFormFields]);

  const getPatchedValues = useCallback(
    (formValues) => {
      const patched = getChangedFields(initial, formValues);
      return mapFormFieldsToRecord(patched);
    },
    [initial, mapFormFieldsToRecord],
  );

  return {
    initialValues: initial,
    getPatchedValues,
    getValues: mapFormFieldsToRecord,
  };
};

export default useRecordForm;
