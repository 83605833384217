import useGymflowModels from "../store";

/**
 * @deprecated useMembershipListAsPublic instead
 */
const usePublicMemberships = () => {
  const { PublicServiceStore } = useGymflowModels();
  const fetchList = PublicServiceStore.useStoreActions(
    (actions: any) => actions.fetchList,
  );
  const { page, pageCount, editing, rows, loadingList, loadingRecord } =
    PublicServiceStore.useStoreState((state: any) => state);

  return {
    fetchList,
    page,
    pageCount,
    editing,
    rows,
    loadingRecord,
    loadingList,
  };
};

export default usePublicMemberships;
