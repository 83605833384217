import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecordForm } from "@gymflow/common";
import { useFormik } from "formik";
import { LabeledFormikInput } from "libs/common/src/lib/components/molecules/FormikInput";
import { createContext, ReactNode, useCallback, useState } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import {
  useActivity,
  useActivityCreate,
  useActivityEdit,
} from "../../../../../../libs/api/src";
import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import useGymflowModels from "../../../store";
import {
  ActivityCategorySelect,
  Button,
  LabeledForm,
  SlideSideBar,
} from "../../atoms";
import ActivityFormMapper from "./ActivityFormMapper";
import { ActivitySchema } from "./ActivitySchema";

interface ActivitySideBarContextType {
  showActivityForm: (params: {
    activityId?: number;
    onClose?: () => void;
  }) => void;
}

export const ActivitySideBarContext = createContext<ActivitySideBarContextType>(
  {
    showActivityForm: () => {
      throw new Error("function called outside of context");
    },
  },
);

export function ActivitySideBarFormProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { api } = useGymflowModels();

  const [sidebarState, setSidebarState] = useState<{
    isOpen: boolean;
    editingId?: number;
    onClose?: () => void;
  }>({
    isOpen: false,
  });

  const { data: editing } = useActivity({
    api,
    activityId: sidebarState.editingId,
  });
  const newActivityMutation = useActivityCreate({ api });
  const editActivityMutation = useActivityEdit({ api });

  const showActivityForm = useCallback(
    (params?: { activityId?: number; onClose?: () => void }) => {
      setSidebarState({
        isOpen: true,
        editingId: params?.activityId,
        onClose: params?.onClose,
      });
    },
    [],
  );

  const onClose = useCallback(() => {
    if (sidebarState?.onClose) {
      sidebarState.onClose();
    }
    setSidebarState({ isOpen: false });
  }, [sidebarState]);

  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: !!sidebarState.editingId && editing ? editing : null,
    fields: getDefaultsFromZodSchema(ActivitySchema),
    mapper: new ActivityFormMapper(),
  });
  const formik = useFormik<z.infer<typeof ActivitySchema>>({
    enableReinitialize: true,
    initialValues,
    validationSchema: toFormikValidationSchema(ActivitySchema),
    onSubmit: async (values) => {
      if (sidebarState.editingId) {
        await editActivityMutation.mutateAsync({
          activityId: sidebarState.editingId,
          patchedFields: getPatchedValues(values),
        });
      } else {
        await newActivityMutation.mutateAsync(getValues(values));
      }
      onClose();
      formik.resetForm();
    },
  });

  return (
    <ActivitySideBarContext.Provider value={{ showActivityForm }}>
      <SlideSideBar
        isOpen={sidebarState.isOpen}
        hide={onClose}
        isLoading={false}
      >
        <div className="flex h-full max-h-full flex-col overflow-hidden">
          <div className="flex h-full max-h-full flex-col overflow-hidden">
            <div className="flex flex-col justify-between border-b border-gray-200 p-8">
              <div className="mb-1 flex flex-row items-center justify-between">
                <div className="text-xl font-semibold text-gray-900">
                  {sidebarState.editingId ? "Edit" : "Create"} Activity
                </div>

                <FontAwesomeIcon
                  onClick={() => {
                    onClose();
                  }}
                  className="cursor-pointer text-xl text-gray-600"
                  icon={faClose}
                />
              </div>
              <div className="text-sm font-medium text-gray-600">
                {sidebarState.editingId ? "Edit" : "Create"} a class to schedule
                it on your calendar.
              </div>
            </div>

            <div className="flex h-full max-h-full flex-col gap-4 overflow-y-auto p-8">
              <LabeledFormikInput
                label="Name"
                placeholder="Enter a class name."
                name="name"
                type="text"
                formikProps={formik}
              />
              <LabeledForm
                label="Category"
                tooltip="Users will need to own credits with these categories to be able to book the class."
              >
                <ActivityCategorySelect
                  onChange={([newValue]) => {
                    formik.setFieldValue("activity-category-id", newValue);
                  }}
                  value={[formik.values["activity-category-id"]]}
                  showManageOption
                  isSearchable
                />

                <label className="text-error-500">
                  {formik.touched["activity-category-id"] &&
                    (formik.errors["activity-category-id"] as any)}
                </label>
              </LabeledForm>

              <LabeledFormikInput
                label="Description"
                placeholder="Enter a facility description."
                name="description"
                type="textarea"
                formikProps={formik}
                className="!p-3 text-gray-700"
              />
            </div>
          </div>
          <div className="flex h-20 flex-row items-center gap-2 border-t border-gray-200 px-6">
            <Button className="mt-2 flex-1" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="mt-2 flex-1"
              intent="primary"
              onClick={() => {
                formik.submitForm();
              }}
            >
              {sidebarState.editingId ? "Edit" : "Create"}
            </Button>
          </div>
        </div>
      </SlideSideBar>
      {children}
    </ActivitySideBarContext.Provider>
  );
}
