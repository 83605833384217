import endpoints from "./endpoints";

const integrationApiKeyApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    http: axiosInstance,
    baseUrl: `${clubPrefix}${endpoints.access}`,
    apiUrl,
  };

  return Object.assign(state, {
    getGrowMetricsApiKeys() {
      const url = `${clubPrefix}${endpoints.integrationApiKey}/grow-metrics`;
      return state.http.get(url);
    },
    deleteGrowMetricsApiKeys() {
      const url = `${clubPrefix}${endpoints.integrationApiKey}/grow-metrics`;
      return state.http.delete(url);
    },
    postGrowMetricsApiKeys() {
      const url = `${clubPrefix}${endpoints.integrationApiKey}/grow-metrics`;
      return state.http.post(url);
    },
  });
};

export default integrationApiKeyApi;

