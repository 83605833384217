import { QueryKey } from "@tanstack/react-query";

export const ruleQueryKeys = {
  all: () => ["rule"] as const,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...ruleQueryKeys.all(), { filters }] as QueryKey,
  values: (clubId: number) =>
    [...ruleQueryKeys.all(), "values", { clubId }] as QueryKey,
  ruleGroup: (ruleGroupId: number) =>
    [...ruleQueryKeys.all(), "group", ruleGroupId] as QueryKey,
  ruleGroupSearch: (filters: Readonly<{ [k: string]: any }>) =>
    [...ruleQueryKeys.all(), "group-search", { filters }] as QueryKey,
};
