import { ApiListResponse, FacilityDTO, FacilityStatus } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { facilityAsMemberQueryKeys } from "./facilityAsMemberQueryKeys";

export async function facilityListAsMemberQueryFn({
  filter,
  api,
}: {
  filter?: FacilityListAsMemberFilter;
  api: FacilityListAsMemberApi;
}) {
  const result = await api.customerFacilityApi.find({ ...filter });
  return result.data as ApiListResponse<FacilityDTO>;
}

export function useFacilityListAsMember({
  api,
  opts,
}: {
  api: FacilityListAsMemberApi;
  opts?: FacilityListAsMemberFilter;
}) {
  const result = useQuery({
    queryKey: facilityAsMemberQueryKeys.list(opts),
    queryFn: () => facilityListAsMemberQueryFn({ api, filter: opts }),
    initialData: defaultPage<FacilityDTO>,
  });
  return result;
}

interface FacilityListAsMemberApi {
  customerFacilityApi: {
    find: (arg0: { [k: string]: any }) => Promise<any>;
  };
}

interface FacilityListAsMemberFilter {
  readonly extraParams?: {
    readonly status?: FacilityStatus;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}
