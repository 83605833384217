import { useActivityCategoryList, useActivityList } from "@gymflow/api";
import { Spinner } from "@gymflow/common";
import useGymflowModels from "apps/portal/src/store";
import { useEffect } from "react";
import { Col, Row } from "reactstrap";

import useFilterableListWithGroups from "../../../../hooks/useFilterableListWithGroups";
import FilterableList from "../../../forms/FilterableList";

function Step2Timetable({ value, onChange }) {
  const { api } = useGymflowModels();
  const {
    data: { content: activities },
    isFetching: isFetchingActivities,
  } = useActivityList({ api, opts: { page: 0, limit: 100 } });
  const {
    data: { content: categories },
    isFetching: isFetchingCategories,
  } = useActivityCategoryList({ api, filter: { page: 0, limit: 100 } });
  const { groupedOptions, selection, setSelection } = useFilterableListWithGroups({
    groups: categories,
    options: activities,
    groupIdField: "id",
    optionGroupField: "activityCategory.id",
    onChange,
    initialSelection: value,
  });

  const isLoading = isFetchingActivities || isFetchingCategories;

  return (
    <>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div className="w-100 text-center" style={{ opacity: isLoading ? "0.2" : "1" }}>
        <b className="h2">Select specific activities to display</b>
        <Row className="d-flex justify-content-center mt-4 text-left">
          <Col md={8}>
            <p className="text-uppercase text-muted">Choose Activities</p>
            <FilterableList options={groupedOptions} value={selection} onChange={setSelection} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Step2Timetable;
