import { TokenPageableRequestParams } from "@gymflow/types";
import { QueryKey } from "@tanstack/react-query";

export const smsQueryKeys = {
  all: () => ["sms"] as QueryKey,
  getCreditsHistoryTopUps: (params?: TokenPageableRequestParams) => {
    const result = [...smsQueryKeys.all(), "getCreditsHistoryTopUps"];
    if (params) result.push(params);
    return result as QueryKey;
  },
  getCreditsHistorySpent: (params?: TokenPageableRequestParams) => {
    const result = [...smsQueryKeys.all(), "getCreditsHistorySpent"];
    if (params) result.push(params);
    return result as QueryKey;
  },
  getStripeProducts: () =>
    [...smsQueryKeys.all(), "getStripeProducts"] as QueryKey,
};
