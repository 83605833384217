import { useEffect, useRef } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useOutsideAlerter = (
  onClickOutside: (event: MouseEvent) => void
) => {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, ref]);
  return { ref };
};
