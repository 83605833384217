import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { appointmentQueryKeys } from "../appointment";
import { noteQueryKeys } from "./noteQueryKeys";

export function useNoteDelete(
  {
    api,
  }: {
    api: {
      noteApi: {
        remove: (id: number) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<void, unknown, { noteId: number }>,
) {
  const queryClient = useQueryClient();
  const removeNoteMutation = useMutation({
    mutationFn: async ({ noteId }: { noteId: number }) => {
      await api.noteApi.remove(noteId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return removeNoteMutation;
}
