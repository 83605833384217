import { z } from "zod";

export const LeadSource = {
  Instagram: "INSTAGRAM",
  Facebook: "FACEBOOK",
  GoogleSearch: "GOOGLE_SEARCH",
  Website: "WEBSITE",
  WalkIn: "WALK_IN",
  PhoneIn: "PHONE_IN",
  Referral: "REFERRAL",
  Other: "OTHER",
} as const;

export const LeadSourceZodSchema = z.enum([
  LeadSource.Instagram,
  LeadSource.Facebook,
  LeadSource.GoogleSearch,
  LeadSource.Website,
  LeadSource.WalkIn,
  LeadSource.PhoneIn,
  LeadSource.Referral,
  LeadSource.Other,
]);

Object.setPrototypeOf(LeadSource, {
  humanize: (value: (typeof LeadSource)[keyof typeof LeadSource]) => {
    switch (value) {
      case LeadSource.Instagram:
        return "Instagram";
      case LeadSource.Facebook:
        return "Facebook";
      case LeadSource.GoogleSearch:
        return "Google Search";
      case LeadSource.Website:
        return "Website";
      case LeadSource.WalkIn:
        return "Walk In";
      case LeadSource.PhoneIn:
        return "Phone In";
      case LeadSource.Referral:
        return "Referral";
      case LeadSource.Other:
        return "Other";
      default:
        throw new Error("Unknow LeadSource.");
    }
  },
});
