// @ts-ignore
import { tzDateTimeStringToUtc } from "@gymflow/common";
import { RevenueCategory } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useRevenueBySaleTypeReportCsv({
  api,
  tz,
}: {
  api: {
    revenueApi: {
      csv: (params: {
        category?: RevenueCategory;
        dateFrom: string;
        dateTo: string;
      }) => Promise<any>;
    };
  };
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      category,
      dateFrom,
      dateTo,
    }: {
      category?: RevenueCategory;
      dateFrom: string;
      dateTo: string;
    }) => {
      const result = await api.revenueApi.csv({
        category,
        dateFrom: tzDateTimeStringToUtc(dateFrom, tz),
        dateTo: tzDateTimeStringToUtc(dateTo, tz),
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
