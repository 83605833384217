import { DateTime } from "luxon";

const DATE_FORMAT = "dd LLL yyyy";

const DATE_FORMAT_WITH_COMMA = "dd LLL, yyyy";

export const formatDate = (
  date: string | undefined,
  timezone: string,
  withComma = false,
): string =>
  date
    ? DateTime.fromISO(date)
        .setZone(timezone)
        .toFormat(withComma ? DATE_FORMAT_WITH_COMMA : DATE_FORMAT)
    : "-";
