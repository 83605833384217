import {
  BootstrapServerRepository,
  hasStaffRole,
  useMemberChangePicture,
  useMemberCreate,
  useMemberEdit,
  useMemberTrainer,
  useMutationKisiResetAccess,
  useMutationLeadStatus,
  useMutationMemberChangePictureAsMember,
  useMutationMemberEditAsMember,
  useMutationMemberUpdateEmailAndPassword,
  useMutationMemberUpdateEmailAndPasswordAsMember,
  useMutationMemberUpdateHomeClub,
} from "@gymflow/api";
import {
  createUserMemberFormSchemaWithCustomInvoiceField,
  CUSTOM_INVOICE_FIELD,
  ERROR_MESSAGES,
  MobileNumberInput,
  useRecordForm,
  UserMemberFormConstants,
  UserMemberFormMapper,
} from "@gymflow/common";
import { KisiStatus, UserMemberBean, UserStaffBean } from "@gymflow/types";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useStoreState } from "easy-peasy";
import { setNestedObjectValues, useFormik } from "formik";
import { LabeledFormikInput } from "libs/common/src/lib/components/molecules/FormikInput";
import debounce from "lodash/debounce";
import noop from "lodash/noop";
import memoizeOne from "memoize-one";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import environment from "../../environment";
import useLeadStatusLoadOptions from "../../hooks/useLeadStatusLoadOptions";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { ModalContext, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { Button, PaginatedSelect, TextButton } from "../atoms";
import { ClubPicker } from "../atoms/ClubPicker";
import GenderSelect from "../atoms/GenderSelect";
import Switch from "../Switch";
import { ConfirmModal } from "../templates";
import { DeleteMemberModal } from "../templates/DeleteMemberModal";
import createRecordFormDefaultParams from "./recordFormDefaultParams";
import { SettingsSection } from "./SettingsSection";
import { UserMemberPicture } from "./UserMemberPicture";

const {
  ADDRESS1,
  ADDRESS2,
  CITY,
  DATE_OF_BIRTH,
  EMAIL,
  EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_NAME,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  MOBILE_NUMBER,
  POST_CODE,
  EMAIL_COMMUNICATION,
  SMS_COMMUNICATION,
  PUSH_COMMUNICATION,
  PASSWORD,
  PASSWORD_CONFIRM,
  PERSONAL_NUMBER,
} = UserMemberFormConstants;

export function UserMemberProfile({
  user,
  requiredFields,
  kisiStatus,
  asMember,
}: {
  user: UserMemberBean;
  requiredFields: string[];
  kisiStatus?: KisiStatus;
  asMember?: boolean;
}) {
  const { api, authStore } = useGymflowModels();
  const { roles } = useStoreState(authStore);
  const isStaff = hasStaffRole(roles);
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  const settings = useClubSettings();
  useEffect(() => {
    if (user?.assignedStaffMembers.length > 0) {
      const assignedStaff = user.assignedStaffMembers[0];
      setTrainerOption({
        value: assignedStaff.id,
        label: `${assignedStaff.firstName} ${assignedStaff.lastName}`,
      });
    } else {
      setTrainerOption({ value: null, label: "None" });
    }
  }, [user]);
  const updateEmailAndPasswordMutationAsStaff =
    useMutationMemberUpdateEmailAndPassword({ api });
  const updateEmailAndPasswordMutationAsMember =
    useMutationMemberUpdateEmailAndPasswordAsMember({
      api,
    });
  const updateEmailAndPasswordMutation = asMember
    ? updateEmailAndPasswordMutationAsMember
    : updateEmailAndPasswordMutationAsStaff;
  const { assignMutation, unassignMutation } = useMemberTrainer({ api });
  const updateAssignTrainer = useCallback(
    async ({ staffId }: { staffId: string }) => {
      if (staffId) {
        await assignMutation.mutateAsync({
          memberId: user.id,
          clubId: settings.clubId,
          staffId,
        });
      } else {
        const assignedStaff = user?.assignedStaffMembers[0];
        if (assignedStaff) {
          await unassignMutation.mutateAsync({
            memberId: user.id,
            clubId: settings.clubId,
            staffId: assignedStaff.id,
          });
        }
      }
    },
    [assignMutation, settings, unassignMutation, user],
  );

  const { toast, notifyDanger } = useContext(ToastContext);
  const editMemberAsStaff = useMemberEdit({ api });
  const editMemberAsMember = useMutationMemberEditAsMember({ api });
  const updateUser = useCallback(
    (updatedUser: any) => {
      return (asMember ? editMemberAsMember : editMemberAsStaff)
        .mutateAsync({
          memberId: user.id,
          clubId: settings.clubId,
          patchedFields: updatedUser.patchedFields,
        })
        .catch(notifyDanger);
    },
    [
      asMember,
      editMemberAsMember,
      editMemberAsStaff,
      user.id,
      settings.clubId,
      notifyDanger,
    ],
  );
  const { createMutation: createMember } = useMemberCreate({ api });
  const upgradeToFullUser = useCallback(
    async (newUser: any) => {
      return await createMember
        .mutateAsync({
          clubId: settings.clubId,
          ...newUser,
        })
        .catch(notifyDanger);
    },
    [createMember, notifyDanger, settings.clubId],
  );

  const { changeLeadStatusMutation } = useMutationLeadStatus({ api });

  const changePictureMutationAsStaff = useMemberChangePicture({ api });
  const changePictureMutationAsMember = useMutationMemberChangePictureAsMember({
    api,
  });
  const changePictureMutation = asMember
    ? changePictureMutationAsMember
    : changePictureMutationAsStaff;
  const DEFAULT_NATIONALITY = settings.default_nationality;
  const POSTAL_CODE_COUNTRY = settings.postal_code_country;

  const dateFormat = settings.date_format;
  const resetAccessMutation = useMutationKisiResetAccess({ api });

  const { setModal, hide } = useContext(ModalContext);

  const recordFormDefaultParams = useMemo(
    () =>
      // TODO: Investigate type not matching
      // @ts-ignore
      createRecordFormDefaultParams({
        dateFormat: settings.date_format,
        postalCodeCountry: settings.postal_code_country,
        defaultNationality: settings.default_nationality,
        requiredFields,
      }),
    [
      // TODO: Investigate type not matching
      // @ts-ignore
      settings.date_format,
      settings.postal_code_country,
      settings.default_nationality,
      requiredFields,
    ],
  );
  const { initialValues, getPatchedValues } = useRecordForm({
    ...recordFormDefaultParams,
    mapper: new UserMemberFormMapper({
      dateFormat,
    }),
    record: user,
  });

  useEffect(() => {
    setSchema(
      createUserMemberFormSchemaWithCustomInvoiceField({
        postCodeFormat: POSTAL_CODE_COUNTRY,
        defaultNationality: DEFAULT_NATIONALITY,
        dateFormat,
        requiredFields,
      }),
    );
  }, [requiredFields]);
  const [schema, setSchema] = useState(
    createUserMemberFormSchemaWithCustomInvoiceField({
      postCodeFormat: POSTAL_CODE_COUNTRY,
      defaultNationality: DEFAULT_NATIONALITY,
      dateFormat,
      requiredFields,
    }),
  );

  const formik = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: schema,
    onSubmit: noop,
  });
  // TODO: Investigate why values is of type any (try refactoring this destructuring to use formik.values)
  const { values } = formik;
  const [trainerOption, setTrainerOption] = useState<{
    label: string;
    value: string | null;
  }>({
    label: "None",
    value: null,
  });

  const updateNotificationPreferences = useMutation({
    mutationFn: async ({
      key,
      value,
    }: {
      key: "emailCommunication" | "pushCommunication" | "smsCommunication";
      value: boolean;
    }) => {
      const payload = { id: user.id, patchedFields: { [key]: value } };
      if (user.isFullUser) {
        await updateUser(payload);
      } else {
        await upgradeToFullUser(payload);
      }
    },
  });
  const isFormValid = useCallback(async () => {
    const errors = await formik.validateForm();
    formik.setTouched(setNestedObjectValues(errors, true));
    return Object.keys(errors).length === 0;
  }, [formik]);

  const updateUserMutation = useMutation({
    mutationFn: async ({ user }: { user: UserMemberBean }) => {
      if (Object.keys(getPatchedValues(values)).length > 0) {
        if (user.isFullUser) {
          await updateUser({
            id: user.id,
            patchedFields: getPatchedValues(values),
          });
        } else {
          await upgradeToFullUser({ id: user.id, ...getPatchedValues(values) });
        }
      }
    },
  });
  const handleSubmit = async () => {
    try {
      if (!(await isFormValid())) return;

      await updateUserMutation.mutateAsync({ user });
    } catch (e) {
      notifyDanger(e as any);
    }
  };

  const checkEmailExistOnServer = useCallback(
    memoizeOne(api.memberApi.emailExists),
    [],
  );
  const validateEmail = useMemo(
    () =>
      debounce(async (value) => {
        if (value === initialValues.email) {
          return null;
        }
        try {
          await Yup.reach(schema, "email").validate(value);
          const existingEmails = await checkEmailExistOnServer(value);

          const exists = existingEmails.includes(value);

          if (exists) {
            return ERROR_MESSAGES.emailExists;
          }
          return null;
        } catch (e) {
          return null;
        }
      }, 500),
    [initialValues, checkEmailExistOnServer, schema],
  );

  const areFieldsDisabled = user && !user.isFullUser;
  const leadStatusLoadOptions = useLeadStatusLoadOptions();

  const trainerLoadOptions = useCallback(
    async (_: any, __: any, { page }: any) => {
      const {
        data: { content: data, last },
      } = await api.staffApi.find({ page });
      const options = data.map((host: UserStaffBean) => ({
        value: host.id,
        label: `${host.firstName} ${host.lastName}`,
      }));
      options.unshift({ value: null, label: "None" });
      return {
        hasMore: !last,
        options,
        additional: {
          page: page + 1,
        },
      };
    },
    [],
  );

  const [currentEditingSectionIndex, setCurrentEditingSectionIndex] =
    useState<number>();
  const { mutateAsync: updateMemberHomeClub } = useMutationMemberUpdateHomeClub(
    { api },
  );

  const { brand } = usePortalRoutes();
  const serverUrl = environment.get("API_RESOLVER_URL");
  const repository = useMemo(
    () => new BootstrapServerRepository(serverUrl),
    [serverUrl],
  );
  const { data: homeClubHasMultipleOptions } = useQuery({
    queryKey: ["change-home-club-options"],
    queryFn: async () => {
      return (
        (
          await repository.getRoutesByBrand({
            page: 0,
            limit: 2,
            brand,
          })
        )?.length > 1
      );
    },
  });
  return (
    <div className="-mt-4 flex flex-col pb-48">
      {[
        {
          title: (
            <div className="flex flex-col">
              <div className="text-base font-semibold">Account</div>
              <div className="text-sm text-gray-400">
                Setup or update the users account
              </div>
            </div>
          ),
          content: (index: number) => (
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-y-2 p-6">
                <div className="flex flex-col gap-y-2">
                  <LabeledFormikInput
                    name={EMAIL}
                    type="text"
                    data-testid={EMAIL}
                    autoComplete="off"
                    maxLength={128}
                    placeholder="Email"
                    validate={validateEmail}
                    formikProps={formik}
                    disabled={
                      areFieldsDisabled || currentEditingSectionIndex !== index
                    }
                  />

                  <div className="text-xs text-gray-600">
                    Change the email associated with this account.
                  </div>
                </div>
                <LabeledFormikInput
                  name={PASSWORD}
                  type={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                      ? "password"
                      : "text"
                  }
                  data-testid={PASSWORD}
                  autoComplete="off"
                  maxLength={128}
                  label="New Password"
                  placeholder="Password"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />

                <LabeledFormikInput
                  name={PASSWORD_CONFIRM}
                  type={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                      ? "password"
                      : "text"
                  }
                  data-testid={PASSWORD_CONFIRM}
                  autoComplete="off"
                  maxLength={128}
                  label="Confirm New Password"
                  placeholder="Confirm Password"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />
              </div>
              <div className="border-b border-gray-200" />
              <div className="flex w-full flex-row justify-end gap-4 p-6">
                {!asMember && (
                  <TextButton
                    onClick={() => {
                      setModal(
                        <DeleteMemberModal
                          memberId={user.id}
                          onConfirm={() => {
                            hide();
                            toast({ message: `User successfully deleted.` });
                            history.push(
                              createClubLink(
                                RouteLayout.Staff,
                                RouteFeature.UserMemberList,
                              ),
                            );
                          }}
                          onClose={() => {
                            hide();
                          }}
                        />,
                      );
                    }}
                    className="text-error-500 p-0"
                  >
                    Delete
                  </TextButton>
                )}
                {currentEditingSectionIndex !== index && (
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setCurrentEditingSectionIndex(index);
                    }}
                  >
                    <div className="text-sm">Edit</div>
                  </Button>
                )}
                {currentEditingSectionIndex === index && (
                  <Button
                    intent="primary"
                    onClick={async () => {
                      if (!isFormValid()) {
                        return;
                      }
                      try {
                        await updateEmailAndPasswordMutation.mutateAsync({
                          memberId: user.id,
                          payload: {
                            email: values.email,
                            password: values.password,
                          },
                        });
                      } catch (e) {
                        notifyDanger(e as any);
                      }
                      setCurrentEditingSectionIndex(undefined);
                    }}
                  >
                    <div className="text-sm">Save</div>
                  </Button>
                )}
              </div>
            </div>
          ),
        },

        ...(isStaff
          ? [
              {
                title: (
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">
                      Other Settings
                    </div>
                    <div className="text-sm text-gray-400">
                      Update club or other settings.
                    </div>
                  </div>
                ),
                content: (index: number) => (
                  <div className="flex w-full flex-col">
                    <div className="flex w-full flex-col gap-y-2 p-6">
                      <div className="flex flex-col gap-y-1">
                        <div className="text-sm font-semibold">Trainer</div>
                        <PaginatedSelect
                          isSearchable={false}
                          isDisabled={
                            updateUserMutation.isLoading ||
                            currentEditingSectionIndex !== index
                          }
                          loadOptions={trainerLoadOptions}
                          // @ts-ignore
                          onChange={setTrainerOption}
                          value={trainerOption}
                        />
                      </div>

                      {!asMember && !!user?.lead && (
                        <div className="flex flex-col gap-y-1">
                          <div className="text-sm font-semibold">
                            Lead Status
                          </div>
                          <PaginatedSelect
                            loadOptions={leadStatusLoadOptions as any}
                            onChange={async ({ value }) => {
                              formik.setFieldValue("lead.leadStatus", value);
                            }}
                            value={
                              values?.lead?.leadStatus && {
                                value: values.lead.leadStatus.id,
                                label: values.lead.leadStatus.name,
                              }
                            }
                            isDisabled={
                              values.lead.leadStatus.name === "Deal Closed" ||
                              currentEditingSectionIndex !== index
                            }
                          />
                        </div>
                      )}
                      {homeClubHasMultipleOptions && (
                        <div className="flex flex-col gap-y-1">
                          <div className="text-sm font-semibold">Home Club</div>
                          {user.homeClub && (
                            <ClubPicker
                              isDisabled={currentEditingSectionIndex !== index}
                              initialValue={{
                                label: user.homeClub.name,
                                value: user.homeClub.id,
                              }}
                              onChange={async (value) => {
                                formik.setFieldValue("homeClub", value);
                              }}
                            />
                          )}
                        </div>
                      )}
                      <div className="text-xs text-gray-600">
                        To change home clubs the user must not have any active
                        memberships or credit packs.
                      </div>
                    </div>
                    <div className="border-b border-gray-200" />
                    <div className="flex w-full flex-row justify-end gap-4 p-6">
                      {isStaff &&
                        kisiStatus === "ENABLED" &&
                        user?.kisiUserId && (
                          <TextButton
                            onClick={() => {
                              setModal(
                                <ConfirmModal
                                  onHide={() => {
                                    hide();
                                  }}
                                  onCancel={async () => {
                                    hide();
                                  }}
                                  onConfirm={async () => {
                                    await resetAccessMutation.mutateAsync({
                                      clubId: settings.clubId,
                                      memberId: user.id,
                                    });
                                    hide();
                                  }}
                                  cancelText="Cancel"
                                  confirmText="Yes"
                                  type="default"
                                  title="Reset Access"
                                >
                                  Are you sure you want to reset this
                                  member&apos;s access?
                                </ConfirmModal>,
                              );
                            }}
                            className="text-error-500 p-0"
                          >
                            Reset Access
                          </TextButton>
                        )}

                      {currentEditingSectionIndex !== index && (
                        <Button
                          onClick={() => {
                            formik.resetForm();
                            setCurrentEditingSectionIndex(index);
                          }}
                        >
                          <div className="text-sm">Edit</div>
                        </Button>
                      )}
                      {currentEditingSectionIndex === index && (
                        <Button
                          intent="primary"
                          onClick={async () => {
                            if (trainerOption.value) {
                              await updateAssignTrainer({
                                staffId: trainerOption.value,
                              });
                            }
                            try {
                              if (
                                values.lead.leadStatus.id !==
                                formik.initialValues.lead.leadStatus.id
                              ) {
                                await changeLeadStatusMutation
                                  .mutateAsync({
                                    leadId: values.lead.id,
                                    newColumn: values.lead.leadStatus.id,
                                  })
                                  .catch(notifyDanger);

                                formik.resetForm();
                                setCurrentEditingSectionIndex(undefined);
                              }
                            } catch (e) {
                              notifyDanger(e as any);
                            }
                            if (
                              values.homeClub !== formik.initialValues.homeClub
                            ) {
                              setModal(
                                <ConfirmModal
                                  onHide={hide}
                                  onCancel={hide}
                                  onConfirm={async () => {
                                    try {
                                      await updateMemberHomeClub({
                                        memberId: user.id,
                                        clubId: values.homeClub,
                                      });
                                      history.push(
                                        createClubLink(
                                          RouteLayout.Staff,
                                          RouteFeature.UserMemberList,
                                        ),
                                      );
                                    } catch (e) {
                                      notifyDanger(e as any);
                                    }
                                    hide();
                                    formik.resetForm();
                                  }}
                                  cancelText="Cancel"
                                  confirmText="Yes"
                                  type="warning"
                                  title="Change Member Home Club"
                                >
                                  After changing the user’s home club you will
                                  lose access to view this account in the
                                  current club.
                                </ConfirmModal>,
                              );
                            }
                            setCurrentEditingSectionIndex(undefined);
                          }}
                        >
                          <div className="text-sm">Save</div>
                        </Button>
                      )}
                    </div>
                  </div>
                ),
              },
            ]
          : []),

        {
          title: (
            <div className="flex flex-col">
              <div className="text-base font-semibold">Profile Information</div>
              <div className="text-sm text-gray-400">
                Update the users basic information.
              </div>
            </div>
          ),
          content: (index: number) => (
            <div className="flex w-full flex-col">
              <div className="grid w-full grid-cols-1 gap-4 p-6 lg:grid-cols-2">
                <div className="text-sm font-semibold">Profile Image</div>

                {user && (
                  <UserMemberPicture
                    isLoading={false}
                    avatar={user.picture}
                    initials={`${user.firstName[0]}${user.lastName[0]}`}
                    loading={() => updateUserMutation.isLoading}
                    notLoading={() => !updateUserMutation.isLoading}
                    onChange={async (params) => {
                      await changePictureMutation
                        .mutateAsync({
                          ...params,
                          memberId: user.id,
                        })
                        .catch(notifyDanger);
                    }}
                    disabled={
                      areFieldsDisabled || currentEditingSectionIndex !== index
                    }
                  />
                )}

                <LabeledFormikInput
                  name={FIRST_NAME}
                  type="text"
                  data-testid={FIRST_NAME}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="First Name"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />
                <LabeledFormikInput
                  name={LAST_NAME}
                  type="text"
                  data-testid={LAST_NAME}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="Last Name"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />
                <LabeledFormikInput
                  name={MOBILE_NUMBER}
                  component={MobileNumberInput}
                  type="tel"
                  data-testid={MOBILE_NUMBER}
                  autoComplete="off"
                  placeholder="Mobile Number"
                  maxLength={32}
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                  phone_number_country={settings.phone_number_country}
                />

                <LabeledFormikInput
                  label="Date of Birth"
                  type="text"
                  maxLength={dateFormat.length}
                  placeholder={dateFormat}
                  name={DATE_OF_BIRTH}
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />
                <div className="flex flex-col gap-y-1">
                  <div className="text-sm font-semibold">Gender</div>
                  <GenderSelect
                    onChange={(newGender: any) =>
                      formik.setFieldValue(GENDER, newGender)
                    }
                    value={values[GENDER]}
                    isDisabled={
                      areFieldsDisabled || currentEditingSectionIndex !== index
                    }
                  />
                </div>
                <LabeledFormikInput
                  type="text"
                  maxLength={240}
                  placeholder="Extra invoice info (ie. VAT number)"
                  name={CUSTOM_INVOICE_FIELD}
                  formikProps={formik}
                  disabled={currentEditingSectionIndex !== index}
                />

                <LabeledFormikInput
                  name={ADDRESS1}
                  type="text"
                  data-testid={ADDRESS1}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="Address Line 1"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />

                <LabeledFormikInput
                  name={ADDRESS2}
                  type="text"
                  data-testid={ADDRESS2}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="Address Line 2"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />

                <LabeledFormikInput
                  name={CITY}
                  type="text"
                  data-testid={CITY}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="City"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />

                <LabeledFormikInput
                  name={POST_CODE}
                  type="text"
                  data-testid={POST_CODE}
                  autoComplete="off"
                  maxLength={16}
                  placeholder="Postcode"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                  style={{ textTransform: "uppercase" }}
                />
                <LabeledFormikInput
                  name={EMERGENCY_CONTACT_NAME}
                  type="text"
                  data-testid={EMERGENCY_CONTACT_NAME}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="Emergency Contact Name"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />

                <LabeledFormikInput
                  name={EMERGENCY_CONTACT}
                  component={MobileNumberInput}
                  type="text"
                  data-testid={EMERGENCY_CONTACT}
                  autoComplete="off"
                  maxLength={32}
                  placeholder="Emergency Contact Number"
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                  phone_number_country={settings.phone_number_country}
                />

                <LabeledFormikInput
                  name={PERSONAL_NUMBER}
                  type="text"
                  data-testid={PERSONAL_NUMBER}
                  autoComplete="off"
                  maxLength={25}
                  placeholder={
                    settings.default_nationality === "ESP"
                      ? "DNI/Passport"
                      : "ID Number"
                  }
                  formikProps={formik}
                  disabled={
                    areFieldsDisabled || currentEditingSectionIndex !== index
                  }
                />
              </div>

              <div className="border-b border-gray-200" />
              <div className="flex w-full flex-row justify-end gap-4 p-6">
                {!areFieldsDisabled && currentEditingSectionIndex !== index && (
                  <Button
                    onClick={() => {
                      formik.resetForm();
                      setCurrentEditingSectionIndex(index);
                    }}
                  >
                    <div className="text-sm">Edit</div>
                  </Button>
                )}
                {currentEditingSectionIndex === index && (
                  <Button
                    intent="primary"
                    onClick={async () => {
                      if (!(await isFormValid())) return;
                      await handleSubmit();
                      setCurrentEditingSectionIndex(undefined);
                    }}
                  >
                    <div className="text-sm">Save</div>
                  </Button>
                )}
              </div>
            </div>
          ),
        },
        {
          title: (
            <div className="flex flex-col">
              <div className="text-base font-semibold">Notifications</div>
              <div className="text-sm text-gray-400">
                Set the users notification preferences.
              </div>
            </div>
          ),
          content: () => (
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-y-2 p-6">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-y-2">
                    <div className="inline-flex text-sm font-semibold">
                      Email Marketing
                    </div>
                    <div className="inline-flex text-sm text-gray-400">
                      Allows you to send promotional emails to the user.
                    </div>
                  </div>
                  <Switch
                    data-testid="emailCommunication"
                    checked={values[EMAIL_COMMUNICATION]}
                    onChange={async (checked) => {
                      await updateNotificationPreferences.mutateAsync({
                        key: "emailCommunication",
                        value: checked,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-y-2">
                    <div className="inline-flex text-sm font-semibold">
                      SMS Marketing
                    </div>
                    <div className="inline-flex text-sm text-gray-400">
                      Allows you to send promotional SMS to the user.
                    </div>
                  </div>
                  <Switch
                    data-testid="smsCommunication"
                    checked={values[SMS_COMMUNICATION]}
                    onChange={async (checked) => {
                      await updateNotificationPreferences.mutateAsync({
                        key: "smsCommunication",
                        value: checked,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-y-2">
                    <div className="inline-flex text-sm font-semibold">
                      Push Notifications
                    </div>
                    <div className="inline-flex text-sm text-gray-400">
                      Allows you to send promotional push notifications to the
                      user.
                    </div>
                  </div>
                  <Switch
                    data-testid="pushCommunication"
                    checked={values[PUSH_COMMUNICATION]}
                    onChange={async (checked) => {
                      await updateNotificationPreferences.mutateAsync({
                        key: "pushCommunication",
                        value: checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ),
        },
      ].map((e, i) => {
        return (
          <SettingsSection key={i} title={e.title} content={e.content(i)} />
        );
      })}
    </div>
  );
}
