import { FormMapper } from "@gymflow/common";

export class FacilityMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "picture",
          transform: (value: string) => {
            return { blob: value };
          },
        },
      ],
    });
  }
}
