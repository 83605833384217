import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useAppointmentRefundPayment({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      clubId,
      appointmentId,
    }: {
      clubId: number;
      appointmentId: number;
    }) => {
      const response = await api.appointmentApi.refundPayment(
        clubId,
        appointmentId,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
    },
  });

  return mutation;
}
