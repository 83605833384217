import React, { useState } from "react";
import ReactSwitch from "react-switch";

import { Spinner } from "./atoms";

export type SwitchProps = {
  checked: boolean;
  checkedIcon?: boolean;
  onColor?: string;
  offColor?: string;
  size?: string;
  uncheckedIcon?: boolean;
  onChange: (checked: boolean) => void | Promise<void>;
};

/**
 * @deprecated use atoms/Switch
 */
const Switch: React.FC<SwitchProps> = ({
  size = "md",
  onColor,
  offColor,
  checkedIcon,
  uncheckedIcon,
  checked,
  onChange,
}) => {
  let sizeProps = {};
  if (size === "sm") {
    sizeProps = { width: 24, height: 14, handleDiameter: 10 };
  } else if (size === "md") {
    sizeProps = { width: 40, height: 22, handleDiameter: 18 };
  }
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="relative flex items-center justify-center">
      <ReactSwitch
        {...sizeProps}
        onColor={onColor ?? "#31436e"}
        offColor={offColor}
        checkedIcon={checkedIcon ?? false}
        uncheckedIcon={uncheckedIcon ?? false}
        checked={checked}
        disabled={isLoading}
        onChange={async (isChecked) => {
          setIsLoading(true);
          await onChange(isChecked);
          setIsLoading(false);
        }}
      />
      {isLoading && (
        <div className="absolute  inset-0 flex items-center justify-center">
          <Spinner className="h-4" />
        </div>
      )}
    </div>
  );
};

export default Switch;
