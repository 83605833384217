import {
  clubStaleTime,
  hasStaffRole,
  useClub,
  useInfiniteQueryUserMemberAccessLog,
  useMutationKisiResetAccess,
} from "@gymflow/api";
import { UserMemberAccessLogItem, UserMemberBean } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { usePageSize } from "apps/portal/src/hooks";
import {
  AuthenticatedUserContext,
  ModalContext,
  useClubSettings,
} from "apps/portal/src/providers";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import { useCallback, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "../../atoms";
import { PaginatedTable } from "../../organisms";
import { SettingsContainer } from "../../Settings/SettingsContainer";
import { ConfirmModal } from "../../templates";
import { formatDate } from "./utils/formatDate";

type UserMemberVisitsProps = {
  userMemberId: string;
  user: UserMemberBean;
};

export const UserMemberVisits = ({
  userMemberId,
  user,
}: UserMemberVisitsProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();
  const { toast, notifyDanger } = useContext(ToastContext);
  const { setModal, hide } = useContext(ModalContext);
  const { roles } = useContext(AuthenticatedUserContext);
  const isStaff = hasStaffRole(roles);
  const { api } = useGymflowModels();
  const { timezone, clubId } = useClubSettings();
  const columnHelper = createColumnHelper<UserMemberAccessLogItem>();
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });

  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  const {
    data: accessLog,
    isFetching,
    fetchNextPage,
  } = useInfiniteQueryUserMemberAccessLog({
    api,
    params: { userMemberId, limit: pageSize },
  });

  const resetAccessMutation = useMutationKisiResetAccess({ api });

  const columns = [
    columnHelper.accessor("checkInDate", {
      id: "checkInDate",
      header: () =>
        t("page.userMember.tab.userMemberVisits.table.header.checkInDate"),
      enableSorting: false,
      cell: (cell) => (
        <div className="text-sm font-medium text-gray-950">
          {formatDate(cell.getValue(), timezone)}
        </div>
      ),
    }),
    columnHelper.accessor("checkOutDate", {
      id: "checkOutDate",
      header: () =>
        t("page.userMember.tab.userMemberVisits.table.header.checkOutDate"),
      enableSorting: false,
      cell: (cell) => (
        <div className="text-sm font-medium text-gray-950">
          {formatDate(cell.getValue(), timezone)}
        </div>
      ),
    }),
  ];

  const resetAccess = useCallback(async () => {
    try {
      await resetAccessMutation.mutateAsync({
        clubId,
        memberId: userMemberId,
      });
      toast({
        message: t(
          "page.userMember.tab.userMemberVisits.modal.resetAccess.toast.success",
        ),
      });
      hide();
    } catch (e) {
      if (isAxiosError(e)) {
        notifyDanger(e);
      }
    }
  }, [clubId, hide, notifyDanger, resetAccessMutation, t, toast, userMemberId]);

  const showResetAccessModal = useCallback(
    () =>
      setModal(
        <ConfirmModal
          onHide={() => hide()}
          onCancel={() => hide()}
          onConfirm={async () => await resetAccess()}
          cancelText={t(
            "page.userMember.tab.userMemberVisits.modal.resetAccess.cancelText",
          )}
          confirmText={t(
            "page.userMember.tab.userMemberVisits.modal.resetAccess.confirmText",
          )}
          type="default"
          title={t(
            "page.userMember.tab.userMemberVisits.modal.resetAccess.title",
          )}
        >
          {t(
            "page.userMember.tab.userMemberVisits.modal.resetAccess.description",
          )}
        </ConfirmModal>,
      ),
    [hide, resetAccess, setModal, t],
  );

  const currentPageData = accessLog?.pages[currentPage]?.content ?? [];

  return (
    <SettingsContainer
      title={t("page.userMember.tab.userMemberVisits.title")}
      actions={
        isStaff &&
        club?.kisiStatus === "ENABLED" &&
        user?.kisiUserId && (
          <Button intent="error-outline" onClick={showResetAccessModal}>
            {t("page.userMember.tab.userMemberVisits.button.resetAccess")}
          </Button>
        )
      }
    >
      <PaginatedTable
        tableProps={{
          data: currentPageData,
          columns,
          isFetching,
          pageSize,
          tableContainerRef,
        }}
        hasPreviousPage={!!accessLog && currentPage > 0}
        hasNextPage={
          !!accessLog && !!accessLog.pages[currentPage]?.nextPageToken
        }
        goToNextPage={() => {
          setCurrentPage((e) => e + 1);
          if (!accessLog?.pages[currentPage + 1]) {
            fetchNextPage();
          }
        }}
        goToPreviousPage={() => {
          setCurrentPage((e) => e - 1);
        }}
      />
    </SettingsContainer>
  );
};
