const InvoiceStatus = {
  Scheduled: 'SCHEDULED',
  Reschedule: 'RE_SCHEDULE',
  Paid: 'PAID',
  PastDue: 'PAST_DUE',
  Processing: 'PROCESSING',
  Void: 'VOID',
  Refunded: 'REFUNDED',
  WaitingConfirmation: 'WAITING_CONFIRMATION',
  PaymentAuthorization: 'AWAITING_AUTHORIZATION',
  WrittenOff: 'WRITTEN_OFF'
};

export default InvoiceStatus;
