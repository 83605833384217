import * as Yup from "yup";

import gender from "../../../constants/gender";
import {
  ERROR_MESSAGES,
  yupEmergencyContactTest,
} from "../../../helpers/validations";
import fieldNames, { CUSTOM_INVOICE_FIELD } from "./constants";

export { CUSTOM_INVOICE_FIELD } from "./constants";

const {
  ADDRESS1,
  ADDRESS2,
  CITY,
  DATE_OF_BIRTH,
  EMAIL,
  EMAIL_COMMUNICATION,
  EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_NAME,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  MOBILE_NUMBER,
  NATIONALITY,
  POST_CODE,
  SMS_COMMUNICATION,
  PUSH_COMMUNICATION,
  PASSWORD,
  PASSWORD_CONFIRM,
  PERSONAL_NUMBER,
} = fieldNames;

export const createWithoutEmail = ({
  postCodeFormat,
  defaultNationality,
  dateFormat,
  requiredFields,
}) => {
  const schema = {
    [FIRST_NAME]: Yup.string().trim().required().min(2),
    [LAST_NAME]: Yup.string().trim().required().min(2),
    [NATIONALITY]: Yup.string()
      .trim()
      .required()
      .min(3)
      .max(3)
      .default(defaultNationality),
    [EMAIL_COMMUNICATION]: Yup.boolean().default(false),
    [SMS_COMMUNICATION]: Yup.boolean().default(false),
    [PUSH_COMMUNICATION]: Yup.boolean().default(false),
    [EMERGENCY_CONTACT_NAME]: Yup.string().min(3),
    [EMERGENCY_CONTACT]: Yup.string()
      .phoneNumber()
      .test(yupEmergencyContactTest),
    [PASSWORD]: Yup.string().trim().min(4),
    [PASSWORD_CONFIRM]: Yup.string()
      .trim()
      .min(4)
      .test({
        test: (value, context) => value === context.parent[PASSWORD],
        message: "Passwords do not match",
      }),
  };

  if (requiredFields?.mobileNumber) {
    schema[MOBILE_NUMBER] = Yup.string().required().phoneNumber();
  } else {
    schema[MOBILE_NUMBER] = Yup.string().phoneNumber();
  }

  if (requiredFields?.dateOfBirth) {
    schema[DATE_OF_BIRTH] = Yup.date().format(dateFormat, true).required();
  } else {
    schema[DATE_OF_BIRTH] = Yup.date()
      .format(dateFormat, true)
      .max(new Date(), ERROR_MESSAGES.dateTooBig);
  }

  if (requiredFields?.addressLine) {
    schema[ADDRESS1] = Yup.string().trim().required().min(3);
  } else {
    schema[ADDRESS1] = Yup.string().trim().min(3);
  }
  schema[ADDRESS2] = Yup.string().trim();

  if (requiredFields?.postCode) {
    schema[POST_CODE] = Yup.string().required().postCode(postCodeFormat);
  } else {
    schema[POST_CODE] = Yup.string().postCode(postCodeFormat);
  }

  if (requiredFields?.city) {
    schema[CITY] = Yup.string().trim().required().min(3);
  } else {
    schema[CITY] = Yup.string().trim().min(3);
  }

  if (requiredFields?.gender) {
    schema[GENDER] = Yup.string()
      .required()
      .oneOf([gender.Male, gender.Female, gender.PreferNotToSay]);
  } else {
    schema[GENDER] = Yup.string().oneOf([
      gender.Male,
      gender.Female,
      gender.PreferNotToSay,
    ]);
  }

  if (requiredFields?.personalNumber) {
    schema[PERSONAL_NUMBER] = Yup.string()
      .trim()
      .required()
      .max(25)
      .default("");
  } else {
    schema[PERSONAL_NUMBER] = Yup.string().trim().max(25).default("");
  }

  return Yup.object().shape(schema);
};

export const createSchema = ({
  postCodeFormat,
  defaultNationality,
  dateFormat,
  requiredFields,
}) => {
  const baseSchema = createWithoutEmail({
    postCodeFormat,
    defaultNationality,
    dateFormat,
    requiredFields,
  });
  return baseSchema.shape({
    [EMAIL]: Yup.string().email().required(),
  });
};

export const createSchemaWithCustomInvoiceField = ({
  postCodeFormat,
  defaultNationality,
  dateFormat,
  requiredFields,
}) => {
  const baseSchema = createSchema({
    postCodeFormat,
    defaultNationality,
    dateFormat,
    requiredFields,
  });
  return baseSchema.shape({
    [CUSTOM_INVOICE_FIELD]: Yup.string().max(240),
  });
};
