import { parse } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

import { cn } from "../../helpers/cn";
import { isBirthday } from "../../helpers/date";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { PlaceholderAvatar, SquarishAvatar } from "../atoms";

export interface UserMemberAvatarProps {
  firstName: string;
  lastName: string;
  picture?: string;
  dateBirth?: string;
  email?: string;
  id: string | number;
  className?: string;
  disableLink?: boolean;
}

export const UserMemberAvatar: React.FC<UserMemberAvatarProps> = ({
  firstName,
  lastName,
  picture,
  dateBirth,
  email,
  id,
  className,
  disableLink,
}) => {
  const { createMemberLink, createLeadLink } = usePortalRoutes();
  let celebrationIcon = "";
  if (dateBirth) {
    const birthdayDate = parse(dateBirth, "yyyy-MM-dd", new Date());
    if (isBirthday(birthdayDate)) {
      celebrationIcon = "🎂";
    }
  }
  const name = `${firstName} ${lastName}`;
  const content = (
    <>
      <div>
        {picture ? (
          <SquarishAvatar pictureUrl={picture} />
        ) : (
          <PlaceholderAvatar name={name} />
        )}
      </div>
      <div className="flex flex-col justify-center">
        <div className="text-primary-600 text-sm">
          {`${name} ${celebrationIcon}`}
        </div>
        <div className="line-clamp-1 max-w-[8rem] text-xs font-medium text-gray-500 sm:max-w-none">
          {email ?? ""}
        </div>
      </div>
    </>
  );
  if (disableLink) {
    return (
      <div className={cn("flex gap-2 font-semibold", className)}>{content}</div>
    );
  } else {
    return (
      <Link
        to={typeof id === "number" ? createLeadLink(id) : createMemberLink(id)}
        className={cn("flex gap-2", className)}
      >
        {content}
      </Link>
    );
  }
};
