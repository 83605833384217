import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryClubFeatureFlagsAsPublic } from "@gymflow/api";
import { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { PublicFooter } from "../../components/atoms";
import {
  useHtmlNodeDarkMode,
  useInjectWebsiteCustomizations,
} from "../../hooks";
import useGoogleTagManager from "../../hooks/useGoogleTagManager";
import { LoginOverlayProvider, useClubSettings } from "../../providers";
import { generateSiteWithAuthRoutes, RouteDefinition } from "../../routes";
import useGymflowModels from "../../store";

export function EmbedWithAuth() {
  const match = useRouteMatch();

  const getRoutes = (routes: RouteDefinition[]) => {
    const nodes = routes.map((prop) => {
      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    return nodes;
  };

  useGoogleTagManager();

  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: settings.clubId,
  });
  const routesWithAuth = generateSiteWithAuthRoutes(featureFlags);

  useHtmlNodeDarkMode({ supportDarkMode: true });
  useInjectWebsiteCustomizations();
  const [parent] = useAutoAnimate();
  useEffect(() => {
    const resizeCallback = () => {
      const entries = Array.from(document.querySelectorAll(".track-height"));
      const message = {
        height: entries.reduce((acc, entry) => acc + entry.scrollHeight, 56),
      };

      window.top?.postMessage(message, "*");
    };
    const resizeObserver = new ResizeObserver(resizeCallback);

    const mutationObserver = new MutationObserver((mutations) => {
      const changes = {
        added: [] as any[],
        removed: [] as any[],
      };
      for (const mutation of mutations) {
        for (const _added of Array.from(mutation?.addedNodes)) {
          const added = _added as HTMLElement;
          if (added.classList?.contains("track-height")) {
            changes.added.push(added);
            resizeObserver.observe(added);
          }
        }
        for (const _removed of Array.from(mutation?.removedNodes)) {
          const removed = _removed as HTMLElement;
          if (removed.classList?.contains("track-height")) {
            changes.removed.push(removed);
            resizeObserver.unobserve(removed);
          }
        }
      }
      resizeCallback();
    });
    mutationObserver.observe(document.getElementById("embed-root")!, {
      childList: true,
      subtree: true,
    });
  }, []);
  return (
    <LoginOverlayProvider>
      <div
        id="embed-root"
        className="flex w-full flex-col justify-between overflow-hidden bg-white"
      >
        <div
          ref={parent}
          className="flex h-full w-full flex-col overflow-hidden bg-gray-50 dark:bg-gray-800"
        >
          <Switch>{getRoutes(routesWithAuth)}</Switch>
        </div>
        <PublicFooter />
      </div>
    </LoginOverlayProvider>
  );
}
