import { Iso2Country } from "@gymflow/types";
import { validatePostCode } from "@gymflow/validation";
import { z } from "zod";

export const createClubSchema = (postalCodeCountry: Iso2Country) =>
  z.object({
    name: z.string().trim().min(3, "Must be at least 3 characters").default(""),
    "address-line-1": z
      .string()
      .trim()
      .min(3, "Must be at least 3 characters")
      .default(""),
    "address-line-2": z.string().trim().default(""),
    "post-code": z
      .string()
      .refine((e) => validatePostCode(e, postalCodeCountry), {
        message: "Invalid Postcode",
      }),
    city: z.string().trim().min(3, "Must be at least 3 characters").default(""),
    "default-tax-rate": z.coerce.number().min(0).max(100).default(0),
  });

export type ClubSchema = z.infer<ReturnType<typeof createClubSchema>>;
