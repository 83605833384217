import { createContext, useCallback, useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import noop from "lodash/noop";

import { CANCEL_MESSAGE } from "../helpers/form";

/**
 * @deprecated use ModalContext instead
 */
export const AlertContext = createContext();

/**
 * @deprecated use ModalProvider instead
 */
export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const hide = useCallback(() => {
    setAlert(null);
  }, []);

  const show = useCallback(
    (text, props) => {
      return new Promise((resolve) => {
        const childProps = {
          confirmBtnBsStyle: "success",
          ...props,
        };

        const defaultClose = () => {
          hide();
          resolve();
        };

        const { onConfirm, onCancel } = childProps;
        childProps.onConfirm = childProps.onConfirm
          ? () => {
              const result = onConfirm();
              defaultClose();
              return result;
            }
          : defaultClose;

        childProps.onCancel = childProps.onCancel
          ? () => {
              const result = onCancel();
              defaultClose();
              return result;
            }
          : undefined;

        setAlert(
          <ReactBSAlert
            warning={props.warning}
            danger={props.danger}
            style={{ display: "block" }}
            cancelBtnText={CANCEL_MESSAGE}
            showCancel
            btnSize=""
            {...childProps}
          >
            {text}
          </ReactBSAlert>,
        );
      });
    },
    [hide],
  );

  const showWarning = useCallback(
    (text, props) => {
      return show(text, {
        warning: true,
        onCancel: noop,
        ...props,
      });
    },
    [show],
  );

  const showError = useCallback(
    (text, props) => {
      return show(text, {
        danger: true,
        showCancel: false,
        title: "Error",
        ...props,
      });
    },
    [show],
  );

  return (
    <AlertContext.Provider
      value={{
        setAlert,
        show,
        showWarning,
        showError,
        hide,
        alert,
      }}
    >
      {alert}

      {children}
    </AlertContext.Provider>
  );
};
