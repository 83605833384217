import { formatCurrency } from '@gymflow/helpers';
import { useStoreState } from 'easy-peasy';
import { Line } from 'react-chartjs-2';
import useGymflowModels from '../../store';
const colors = ['#D8DBFF', '#B6BCFC', '#A5F3E6', '#333A78', '#1A2168'];
export interface MultiLineChartProps {
  xAxisLabels: string[];
  xLabel: string;
  yLabel: string;
  data: {
    labels: string[];
    values: number[][];
  };
  height?: number;
}
export const MultiLineChart = ({
  xAxisLabels,
  data,
  xLabel,
  yLabel,
  height,
}: MultiLineChartProps) => {
  const dataConfig = {
    labels: xAxisLabels,
    datasets: data.values.map((e, i) => ({
      label: data.labels[i],
      data: e,
      barPercentage: 0.36,
      backgroundColor: (ctx: any) => {
        const gradient = ctx.chart.ctx.createLinearGradient(
          0,
          0,
          0,
          ctx.chart.height
        );
        gradient.addColorStop(0, `${colors[i]}33`);
        gradient.addColorStop(1, `${colors[i]}00`);
        return gradient;
      },
      hoverBackgroundColor: colors[i],
      borderColor: colors[i],

      pointRadius: 0,
      pointHitRadius: 15,
      fill: `origin`,
    })),
  };
  const { settingsStore } = useGymflowModels();
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const options = {
    maintainAspectRatio: false,
    roundedBars: 8,
    animation: false,
    tooltips: {
      backgroundColor: 'white',
      titleFontColor: '#101828',
      titleFontFamily: 'Manrope',
      titleFontStyle: 'bold',
      footerFontColor: '#667085',
      footerFontFamily: 'Manrope',
      footerFontStyle: 'normal',
      borderColor: '#eaecf0',
      borderWidth: 1,
      displayColors: false,
      xPadding: 12,
      yPadding: 12,
      callbacks: {
        title: function (tooltipItem: any, labelData: any) {
          return `Total: ${formatCurrency(
            data.values[data.values.length - 1][tooltipItem[0].index],
            currency
          )}`;
        },
        label: function (tooltipItem: any, labelData: any) {
          return;
        },
        footer: function (tooltipItem: any, labelData: any) {
          return data.values
            .map(
              (e, i) =>
                `${data.labels[i]}: ${formatCurrency(
                  e[tooltipItem[0].index],
                  currency
                )}`
            )
            .reverse()
            .splice(1);
        },
      },
      fontFamily: 'Manrope',
    },
    legend: {
      onClick: () => undefined,
      position: 'top',
      align: 'end',
      labels: {
        fontSize: 14,
        usePointStyle: true,
        generateLabels: (chart: any) => {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.datasets.map((dataset: any, i: number) => {
              return {
                text: dataset.label,
                fillStyle: colors[i], // set fillStyle to dataset backgroundColor
                strokeStyle: '#0000', // set fillStyle to dataset backgroundColor
                index: i,
              };
            });
          }
          return [];
        },
        pointStyle: 'circle',
        boxWidth: 4,
        fontFamily: 'Manrope',
        fontStyle: 'bold',
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            color: '#fff',
            zeroLineColor: '#f0ebeb',
          },
          scaleLabel: {
            display: true,
            labelString: xLabel,
            fontFamily: 'Manrope',
            fontStyle: 'bold',
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#f0ebeb',
            zeroLineColor: '#f0ebeb',
          },
          scaleLabel: {
            display: true,
            labelString: yLabel,
            fontFamily: 'Manrope',
            fontStyle: 'bold',
          },
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 6,
            color: '#f0ebeb',
          },
        },
      ],
    },
  };

  return <Line data={dataConfig} options={options} height={height ?? 310} />;
};
