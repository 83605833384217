import { DateTime } from "luxon";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { RadioButtonOption } from "../../../../atoms";
import { TaskFormValues, TaskStatus } from "./types";

export const TASK_DETAILS_ROWS: number = 3;

export const STATUS_OPTIONS: RadioButtonOption<TaskStatus>[] = [
  { label: "Completed", value: "COMPLETED" },
  { label: "Incomplete", value: "INCOMPLETE" },
];

export const taskFormSchema = toFormikValidationSchema<TaskFormValues>(
  z.object({
    taskName: z
      .string({ required_error: "Task name is required" })
      .min(1, "Task name is required")
      .max(50, "Maximum 50 characters"),
    taskDetails: z.string().max(200, "Maximum 200 characters").optional(),
    dueDate: z.date({ required_error: "Due Date is a required field" }),
    dueTime: z.string({ required_error: "Due Time is a required field" }),
    // it's not necessary to check array (we don't have "edit" operation with it) ideally this would be UserMemberBean & LeadDTO
    relatedUsers: z.array(z.any()).optional(),
    // it's not necessary to check array (we don't have "edit" operation with it), ideally this would be UserStaffBean
    taskOwners: z.array(z.any()).optional(),
    status: z.enum(["COMPLETED", "INCOMPLETE"]),
  }),
);

export const INITIAL_VALUES_TASK_FORM: TaskFormValues = {
  taskName: "",
  taskDetails: "",
  dueDate: new Date(),
  dueTime: DateTime.now().plus({ hours: 1 }).startOf("hour").toFormat("h:mm a"),
  taskOwners: [],
  relatedUsers: [],
  status: STATUS_OPTIONS[1].value,
};
