import { GLOBAL_NOTIFICATIONS_STORE_KEY } from "../store/keys";
import {
  ACTIVITY_STORE_KEY,
  AUTH_STORE_KEY,
  CLUB_STORE_KEY,
  FACILITY_STORE_KEY,
  HOSTED_CLUB_STORE_KEY,
  LEAD_SOURCE_STORE_KEY,
  PRODUCT_STORE_KEY,
  SETTINGS_STORE_KEY,
  STAFF_STORE_KEY,
} from "./keys";

export const authStore = (store) => store[AUTH_STORE_KEY];
/**
 * @deprecated useClub instead
 */
export const clubStore = (store) => store[CLUB_STORE_KEY];
/**
 * @deprecated useStaff instead
 */
export const staffStore = (store) => store[STAFF_STORE_KEY];
/**
 * @deprecated useProduct instead
 */
export const productStore = (store) => store[PRODUCT_STORE_KEY];
/**
 * @deprecated useFacility instead
 */
export const facilityStore = (store) => store[FACILITY_STORE_KEY];
/**
 * @deprecated useActivity instead
 */
export const activityStore = (store) => store[ACTIVITY_STORE_KEY];
/**
 * @deprecated not used in org model
 */
export const globalNotificationsStore = (store) =>
  store[GLOBAL_NOTIFICATIONS_STORE_KEY];
/**
 * @deprecated useClub / ClubSettingsContext instead
 */
export const settingsStore = (store) => store[SETTINGS_STORE_KEY];
/**
 * @deprecated
 */
export const leadSourceStore = (store) => store[LEAD_SOURCE_STORE_KEY];
/**
 * @deprecated
 */
export const hostedClubStore = (store) => store[HOSTED_CLUB_STORE_KEY];
