import { ClubPropertiesSettings } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { clubAsPublicQueryKeys } from "./clubAsPublicQueryKeys";

export function useClubPropertiesSettingsAsPublic(
  {
    api,
    clubId,
  }: {
    api: ClubPropertiesSettingsAsPublicApi;
    clubId: number;
  },
  opts?: UseQueryOptions<ClubPropertiesSettings | null>,
) {
  const result = useQuery({
    queryKey: clubAsPublicQueryKeys.propertiesSettings(clubId),
    queryFn: async () => {
      const result = await api.public.clubApi.propertiesSettings();
      return result.data;
    },
    enabled: !!clubId,
    placeholderData: null,
    ...opts,
  });

  return result;
}

export interface ClubPropertiesSettingsAsPublicApi {
  public: {
    clubApi: {
      propertiesSettings: () => Promise<{ data: ClubPropertiesSettings }>;
    };
  };
}
