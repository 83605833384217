import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useQueryMembershipAsPublic } from "@gymflow/api";
import { formatCurrency, membershipHelper } from "@gymflow/helpers";
import { usePortalRoutes } from "apps/portal/src/hooks";
import {
  useApiResolverContext,
  useAuthenticatedUser,
  useClubSettings,
} from "apps/portal/src/providers";
import { LoginOverlayContext } from "apps/portal/src/providers/authentication/loginOverlay/context";
import { RouteFeature } from "apps/portal/src/routes";
import React, { useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router";

import useGymflowModels from "../../../store";
import { Button, CheckIcon, CreditsIcon, Spinner } from "../../atoms";
import { HostedPagesBackHeader } from "../HostedPagesBackHeader";
import { HostedPagesCard } from "../HostedPagesCard";

export type HostedPagesMembershipProps = {};

export const HostedPagesMembership: React.FC<
  HostedPagesMembershipProps
> = ({}) => {
  const history = useHistory();
  const { reinitializeWithAuth } = useApiResolverContext();
  useEffect(() => {
    reinitializeWithAuth();
  }, [reinitializeWithAuth]);
  const auth = useAuthenticatedUser();
  const { showSignUpOverlay } = useContext(LoginOverlayContext);
  const { createSiteOrEmbedLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { membershipId } = useParams<{ membershipId: string }>();
  const { data, isLoading } = useQueryMembershipAsPublic({
    api,
    membershipId: parseInt(membershipId),
  });
  const recurringAddons = data?.membershipAddonList?.filter((e) => e.recurring);
  const upfrontAddons = data?.membershipAddonList?.filter((e) => !e.recurring);
  const [parent] = useAutoAnimate();
  return (
    <div className="track-height flex max-h-fit w-full flex-col overflow-y-auto">
      <HostedPagesBackHeader label="Memberships" />
      <div
        ref={parent}
        className="flex h-fit flex-col items-center bg-gray-50 dark:bg-gray-800"
      >
        {isLoading && <Spinner />}
        {!isLoading && data && (
          <div className="flex h-fit w-[32rem] max-w-full flex-col items-center gap-8 overflow-y-auto overflow-x-hidden p-6 max-lg:justify-between ">
            <div className="flex w-full flex-col items-center gap-4">
              <div className="flex w-full flex-col gap-1">
                <div className="flex w-full text-xl font-bold text-gray-950 dark:text-[#ffffff]">
                  {data.name}
                </div>
                {data.description && (
                  <div className="flex w-full text-sm text-gray-600 dark:text-gray-400">
                    {data.description}
                  </div>
                )}
              </div>
              {recurringAddons?.length !== undefined &&
                recurringAddons?.length > 0 && (
                  <div className="flex w-full flex-col gap-1 rounded-xl border border-gray-500 p-3">
                    {recurringAddons?.map((addon) => (
                      <div
                        className="flex flex-row items-center gap-2 font-medium text-gray-950 dark:text-[#ffffff]"
                        key={addon.id}
                      >
                        {(addon.credits !== undefined ||
                          addon.sessionsUnlimited) && (
                          <div className="flex items-center gap-1 font-bold text-gray-950 dark:text-[#ffffff]">
                            {addon.credits !== undefined && (
                              <div>{addon.credits}</div>
                            )}
                            {addon.sessionsUnlimited && (
                              <CheckIcon
                                className="h-4 w-4"
                                pathClassName="stroke-secondary-500"
                              />
                            )}
                            <CreditsIcon pathClassName="stroke-secondary-500" />
                          </div>
                        )}
                        {addon.credits === undefined &&
                          !addon.sessionsUnlimited && (
                            <CheckIcon
                              className="h-4 w-4"
                              pathClassName="stroke-secondary-500"
                            />
                          )}
                        {addon.name}
                      </div>
                    ))}
                  </div>
                )}
              <div className="flex w-full flex-col gap-1">
                <HostedPagesCard.Price
                  price={formatCurrency(
                    data.defaultPrice,
                    settings.defaultCurrency,
                  )}
                  description={membershipHelper.getBillingRecurrence(data)}
                />
                {upfrontAddons?.map((addon) => (
                  <div
                    className="flex flex-row items-center gap-1"
                    key={addon.id}
                  >
                    <div className="font-semibold text-gray-950 dark:text-[#ffffff]">
                      +{formatCurrency(addon.price, settings.defaultCurrency)}
                    </div>
                    <div className="text-xs font-medium text-gray-500">
                      {addon.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {auth.id ? (
              <Button
                link={createSiteOrEmbedLink(
                  RouteFeature.SiteBuyMembershipIdCheckout.replace(
                    ":id",
                    data.id.toString(),
                  ),
                )}
                intent="secondary"
                className="w-fit px-16"
              >
                Go To Checkout
              </Button>
            ) : (
              <Button
                onClick={async () => {
                  const { isLoggedIn } = await showSignUpOverlay();
                  if (isLoggedIn) {
                    history.push(
                      createSiteOrEmbedLink(
                        RouteFeature.SiteBuyMembershipIdCheckout.replace(
                          ":id",
                          data.id.toString(),
                        ),
                      ),
                    );
                  }
                }}
                intent="secondary"
                className="w-fit px-16"
              >
                Go To Checkout
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
