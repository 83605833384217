import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { range } from "@gymflow/helpers";
import { WindowType } from "@gymflow/types";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Tooltip } from "react-tooltip";

import { Button, PaginatedSelect } from "../../atoms";
import { EnabledDisabledRadioButtons } from "./EnabledDisabledRadioButtons";

export const RulesComponents = {
  Container: ({
    title,
    description,
    onSubmit,
    children,
  }: {
    title: string;
    description: string;
    onSubmit?: () => Promise<void>;
    children: ReactNode;
  }) => {
    const history = useHistory();
    return (
      <div className="flex h-full max-h-full flex-col gap-2 bg-white p-8">
        <div className="flex flex-col gap-2 pb-6">
          <div className="flex items-center justify-between">
            <div className="text-3xl font-semibold">{title}</div>
            <Button
              onClick={() => {
                history.goBack();
              }}
              intent="transparent"
              className="h-11 w-11 min-w-0"
            >
              <FontAwesomeIcon
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </Button>
          </div>
          <div className="text-gray-500">{description}</div>
        </div>

        <div className="-mx-8 flex flex-1 flex-col gap-6 overflow-auto px-8">
          {children}
        </div>
        {onSubmit && (
          <div className="flex flex-row items-center justify-end pr-12">
            <Button
              intent="secondary"
              onClick={async () => {
                await onSubmit();
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    );
  },
  FormRow: ({
    label,
    tooltip,
    children,
    isDisabled,
    onChange,
  }: {
    label: string;
    tooltip?: string;
    children: ReactNode;
    isDisabled?: boolean;
    onChange?: (newValue: boolean) => void;
  }) => {
    const [tooltipId] = useState(Math.random().toString(36).substring(2, 15));
    const [parent] = useAutoAnimate();
    return (
      <div
        ref={parent}
        className="flex grid-cols-3 flex-col gap-2 lg:grid lg:gap-4"
      >
        <div className="col-span-1 flex flex-wrap gap-2 max-lg:flex-row max-lg:items-center lg:flex-col lg:justify-center">
          <div className=" flex flex-row items-center gap-1">
            <div className="text-lg font-semibold text-gray-950">{label}</div>
            {tooltip && (
              <>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  data-tooltip-id={tooltipId}
                  data-tooltip-content={tooltip}
                />
                <Tooltip
                  className="z-10 !max-w-[min(24rem,calc(100vw-2rem))]  !rounded-xl !bg-black !px-3 !py-2 !text-center !text-sm !font-semibold !text-[#ffffff] !opacity-100"
                  id={tooltipId}
                />
              </>
            )}
          </div>
          {onChange && (
            <EnabledDisabledRadioButtons
              value={!isDisabled}
              onChange={onChange}
            />
          )}
        </div>
        {!isDisabled && (
          <div className="col-span-2 flex flex-wrap items-center rounded-xl border border-gray-200 p-4">
            {children}
          </div>
        )}
      </div>
    );
  },
  WindowPicker: ({
    label,
    onChange,
    value,
    availableUnits,
  }: {
    label: string;
    onChange: (params: { amount: number; type: WindowType }) => void;
    value: { amount: number; type: WindowType };
    availableUnits: WindowType[];
  }) => {
    const { t } = useTranslation();
    const windowOptions = (
      [
        {
          value: "MINUTES",
          label: value.amount === 1 ? t("Common.minute") : t("Common.minutes"),
        },
        {
          value: "HOURS",
          label: value.amount === 1 ? t("Common.hour") : t("Common.hours"),
        },
        {
          value: "DAYS",
          label: value.amount === 1 ? t("Common.day") : t("Common.days"),
        },
        {
          value: "MONTHS",
          label: value.amount === 1 ? t("Common.month") : t("Common.months"),
        },
      ] as const
    ).filter((e) => availableUnits.includes(e.value as WindowType));
    const type = value.type || "HOURS";
    return (
      <RulesComponents.CardLabel label={label}>
        <div className="flex min-w-fit flex-row flex-wrap items-center gap-2">
          <PaginatedSelect
            className="w-[5.5rem]"
            value={options[type].find((e) => e.value === value.amount)}
            onChange={(newValue) => {
              onChange({
                amount: newValue.value,
                type: type,
              });
            }}
            loadOptions={async () => {
              return {
                options: options[type],
              };
            }}
            cacheUniqs={[type]}
          />
          <PaginatedSelect
            className="w-32"
            isDisabled={windowOptions.length <= 1}
            value={windowOptions.find((e) => e.value === type)}
            onChange={(newValue) => {
              onChange({
                amount: 1,
                type: newValue.value,
              });
            }}
            loadOptions={async () => {
              return {
                options: windowOptions,
              };
            }}
          />
        </div>
      </RulesComponents.CardLabel>
    );
  },
  CardLabel: ({ label, children }: { label: string; children: ReactNode }) => {
    return (
      <div className="flex w-full flex-row flex-wrap items-center justify-between gap-2">
        <div className="flex flex-wrap">{label}</div>
        <div className="flex min-w-fit flex-row flex-wrap items-center gap-4">
          {children}
        </div>
      </div>
    );
  },
};

const options = {
  MINUTES: range(1, 60).map((i) => ({ label: `${i}`, value: i })),
  HOURS: range(1, 24).map((i) => ({ label: `${i}`, value: i })),
  DAYS: range(1, 31).map((i) => ({ label: `${i}`, value: i })),
  MONTHS: range(1, 12).map((i) => ({ label: `${i}`, value: i })),
} as const;
