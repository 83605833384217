import { HostAvailabilityDTO, HostAvailabilitySlot } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityEdit({
  api,
}: {
  api: {
    availabilityApi: {
      saveHostAvailability: (
        staffId: string,
        availableForAppointments: boolean,
        availabilitySlotList: HostAvailabilitySlot[],
      ) => Promise<{ data: HostAvailabilityDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      availableForAppointments,
      availabilitySlotList,
    }: {
      staffId: string;
      availableForAppointments: boolean;
      availabilitySlotList: HostAvailabilitySlot[];
    }) => {
      const response = await api.availabilityApi.saveHostAvailability(
        staffId,
        availableForAppointments,
        availabilitySlotList,
      );
      return response.data;
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostAvailability(staffId),
      });
    },
  });

  return mutation;
}
