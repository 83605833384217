import { LeadSourceDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { leadSourceAsPublicQueryKeys } from "./leadSourceAsPublicQueryKeys";

export async function leadSourceListAsPublicQueryFn({
  api,
}: {
  api: LeadSourceListAsPublicApi;
}) {
  const result = await api.public.leadApi.findLeadSources();
  return result.data;
}

export function useLeadSourceListAsPublic({
  api,
}: {
  api: LeadSourceListAsPublicApi;
}) {
  const result = useQuery({
    queryKey: leadSourceAsPublicQueryKeys.list(),
    queryFn: () => leadSourceListAsPublicQueryFn({ api }),
    initialData: [],
  });
  return result;
}

interface LeadSourceListAsPublicApi {
  public: {
    leadApi: {
      findLeadSources: () => Promise<{ data: LeadSourceDTO[] }>;
    };
  };
}
