import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { noteQueryKeys } from "./noteQueryKeys";

export function useNoteDeleteAttachment(
  {
    api,
  }: {
    api: {
      noteApi: {
        removeAttachment: (id: number) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, unknown, unknown>,
) {
  const queryClient = useQueryClient();

  const removeAttachmentMutation = useMutation({
    mutationFn: ({ noteId }: { noteId: number }) =>
      api.noteApi.removeAttachment(noteId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return removeAttachmentMutation;
}
