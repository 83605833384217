import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutationClubEditSettings } from "@gymflow/api";
import { FormMapper, useRecordForm } from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { useFormik } from "formik";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers/zod";
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Avatar,
  Button,
  ColorInput,
  PaginatedSelect,
  Switch,
  UploadArea,
} from "../../atoms";

interface BrandingProps {
  readonly value?: {
    readonly darkMode: boolean;
    readonly font: string;
    readonly primaryColor: string;
    readonly logo: string;
  };
}

export function Branding({
  value = {
    darkMode: false,
    font: "",
    primaryColor: "",
    logo: "",
  },
}: BrandingProps) {
  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const editSettingsMutation = useMutationClubEditSettings({ api });
  const [isInEditMode, setIsInEditMode] = useState(false);
  const { initialValues, getValues } = useRecordForm({
    record: value ? value : null,
    fields: getDefaultsFromZodSchema(BrandingZodSchema),
    mapper: brandingFormMapper,
  });
  const formik = useFormik<{
    logo: { name: string; blob: string; file: any };
    "primary-color": string;
    font: string;
    "dark-mode": boolean;
  }>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const { logo, ...fields } = getValues(values);
      await editSettingsMutation.mutateAsync({
        clubId,
        patchedFields: {
          fields: fields,
          logo: logo.file ? { name: logo.name, blob: logo.file } : undefined,
        },
      });
    },
    validationSchema: toFormikValidationSchema(BrandingZodSchema),
  });

  return (
    <div className="grid grid-cols-1 md:grid-cols-8">
      <div className="md:grid-cols-subgrid md:col-span-3 md:grid">
        <div className="md:col-start-1 md:col-end-3">
          <div className="text-base font-semibold">Branding</div>
          <div className="font-normal text-gray-600">
            Customise the branding showing on your website pages and links.
          </div>
        </div>
      </div>
      <div
        className="flex flex-col gap-4 rounded-xl border border-gray-200 bg-white p-6 shadow-sm md:col-span-5
        "
      >
        <div className="flex flex-col gap-2">
          <div className="text-sm font-semibold">Your Logo</div>
          <div className="flex gap-2">
            <div className={cn({ hidden: !formik.values.logo.name })}>
              <Avatar
                url={formik.values.logo.blob || formik.values.logo.name}
                className="!h-[64px] !w-[64px]"
              />
            </div>
            <UploadArea
              disabled={!isInEditMode}
              className="col-span-5 flex-1"
              onChange={(file: any) => {
                if (file.blob) {
                  formik.setFieldValue("logo", {
                    name: file.name,
                    blob: file.blob,
                    file: file.file,
                  });
                }
              }}
              description="PNG or JPG (max. 1500x700)"
              maxHeight={700}
              maxWidth={1500}
            />
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <div className="text-sm font-semibold">Primary Colour</div>
            <div>
              <ColorInput
                disabled={!isInEditMode}
                onChange={(newColor) => {
                  formik.setFieldValue("primary-color", newColor);
                }}
                value={formik.values["primary-color"]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div className="text-sm font-semibold">Font</div>
            <div>
              <PaginatedSelect
                isDisabled={!isInEditMode}
                value={{ label: formik.values.font, value: formik.values.font }}
                onChange={(newValue): void => {
                  formik.setFieldValue("font", newValue.value);
                }}
                loadOptions={() =>
                  Promise.resolve({
                    options: [
                      {
                        value: "Manrope",
                        label: "Manrope",
                      },
                      {
                        value: "LeagueSpartan",
                        label: "LeagueSpartan",
                      },
                      { value: "Poppins", label: "Poppins" },
                      { value: "Potta One", label: "Potta One" },
                      { value: "Raleway", label: "Raleway" },
                      {
                        value: "Circular Std Bold",
                        label: "Circular Std Bold",
                      },
                      { value: "Montserrat", label: "Montserrat" },
                      {
                        value: "Futura Regular",
                        label: "Futura Regular",
                      },
                      { value: "Futura Hv Bt", label: "Futura Hv Bt" },
                      {
                        value: "Gotham Condensed",
                        label: "Gotham Condensed",
                      },
                      { value: "Nunito Sans", label: "Nunito Sans" },
                      { value: "Barlow", label: "Barlow" },
                      { value: "NTR", label: "NTR" },
                    ],
                  })
                }
              />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="text-sm font-semibold">
              <div className="inline-block">Dark Mode</div>
              <FontAwesomeIcon
                data-tooltip-id="dark-mode"
                data-tooltip-content="We'll automatically apply a dark theme to your pages using your primary colour."
                className="ml-2 cursor-pointer"
                icon={faQuestionCircle}
              />
              <Tooltip
                className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
                id="dark-mode"
              />
            </div>
            <div>
              <Switch
                disabled={!isInEditMode}
                value={formik.values["dark-mode"]}
                onChange={(checked) => {
                  formik.setFieldValue("dark-mode", checked);
                }}
                label="Dark Mode"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse border-t border-t-gray-200 pt-4">
          <Button
            intent={isInEditMode ? "primary" : "default"}
            className="mt-2"
            onClick={async () => {
              if (isInEditMode) {
                await formik.submitForm();
                setIsInEditMode(false);
              } else {
                setIsInEditMode(true);
              }
            }}
          >
            {isInEditMode ? "Save" : "Edit"}
          </Button>
        </div>
      </div>
    </div>
  );
}

const BrandingZodSchema = z.object({
  logo: z.object({
    name: z.string(),
    blob: z.string().optional(),
    file: z.any().optional(),
  }),
  "primary-color": z.string().default("#1A2168"),
  font: z.string().default("LeagueSpartan"),
  "dark-mode": z.boolean().default(false),
});

const brandingFormMapper = new FormMapper({
  inValue: [
    {
      key: "logo",
      transform: (logo: string) => ({ name: logo }),
    },
  ],
});
