import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../../../hooks/usePortalRoutes";
import { RouteFeature } from "../../../../routes/feature";
import { RouteLayout } from "../../../../routes/layout";
import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportMembersPausingWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const data = useContext(ReportContext);
  const history = useHistory();

  return (
    <ReportWidgetCard
      title="Pausing Memberships "
      value={data.members?.pausing?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Sum of all recurring and pre-paid membership that are pausing."
      variation={data.members?.pausing?.variation}
      positiveIsBad
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.PausingMemberships,
          ),
          search: QueryString.stringify({
            startDate: data.startDate,
            endDate: data.endDate,
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
