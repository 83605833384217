import { z } from "zod";

export const AddonSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("creditPack"),
    id: z.number(),
    name: z.string(),
    price: z.number(),
  }),
  z.object({
    type: z.literal("product"),
    id: z.number(),
    name: z.string(),
    stockQuantity: z.number().optional(),
    price: z.number(),
  }),
]);
