import { Currency } from "@gymflow/types";

import { truncIfBiggerOrEqualThen } from "./number";

export const SYMBOL_PLACEMENT: {
  Before: SymbolPlacement;
  After: SymbolPlacement;
} = {
  Before: "BEFORE",
  After: "AFTER",
};
type SymbolPlacement = "BEFORE" | "AFTER";

export const currencies: {
  [key in Currency]: { symbol: string; symbolPlacement: SymbolPlacement };
} = {
  GBP: {
    symbol: "£",
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
  USD: {
    symbol: "$",
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
  CAD: {
    symbol: "$",
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
  AUD: {
    symbol: "$",
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
  NZD: {
    symbol: "$",
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
  NOK: {
    symbol: "Kr",
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
  EUR: {
    symbol: "€",
    // Symbol placement varies by language, in english it should be before
    // according to EU guidance
    // https://publications.europa.eu/code/en/en-370303.htm
    symbolPlacement: SYMBOL_PLACEMENT.Before,
  },
};

export const formatCurrency = (
  value: number,
  currencyName: keyof typeof currencies,
  truncateLargeNumbers = false,
) => {
  let sign = "";
  if (value < 0) {
    value = -value;
    sign = "- ";
  }
  const symbol = currencies[currencyName];
  const parsedValue = truncateLargeNumbers
    ? truncIfBiggerOrEqualThen(value)
    : value.toFixed(2);
  if (symbol) {
    if (symbol.symbolPlacement === SYMBOL_PLACEMENT.Before) {
      return `${sign}${symbol.symbol}${parsedValue}`;
    } else if (symbol.symbolPlacement === SYMBOL_PLACEMENT.After) {
      return `${sign}${parsedValue}${symbol.symbol}`;
    }
  } else if (typeof symbol === "undefined") {
    return parsedValue.toString() + currencyName;
  }
  return `${parsedValue} ${currencyName}`;
};
