import { PublicCreditPackApiType } from "@gymflow/common/lib/api/publicCreditPackApi";
import { useQuery } from "@tanstack/react-query";

import { creditPackAsPublicQueryKeys } from "./creditPackAsPublicQueryKeys";

export function useQueryCreditPackListAsPublic({
  api,
  opts,
}: {
  api: {
    public: {
      creditPackApi: PublicCreditPackApiType;
    };
  };
  opts: Parameters<PublicCreditPackApiType["getList"]>[0];
}) {
  const result = useQuery({
    queryKey: creditPackAsPublicQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.public.creditPackApi.getList({ ...opts });
      return result.data;
    },
  });
  return result;
}
