import * as Yup from "yup";

const urlRegex =
  "^(?:([a-z0-9+.-]+)://)(?:S+(?::S*)?@)?(?:(?:[1-9]d?|1dd|2[01]d|22[0-3])(?:.(?:1?d{1,2}|2[0-4]d|25[0-5])){2}(?:.(?:[1-9]d?|1dd|2[0-4]d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*.?)(?::d{2,5})?(?:[/?#]S*)?$";

export default Yup.object().shape({
  API_RESOLVER_URL: Yup.string().matches(urlRegex).required(),
  BASE_PATH: Yup.string().default(""),
  KEYCLOAK_CLIENT_ID: Yup.string().required(),
  SENTRY_ENABLED: Yup.boolean().default(false),
  SENTRY_ENV: Yup.string().default(""),
  SENTRY_DSN: Yup.string(),
  FULLCALENDAR_LICENSE: Yup.string(),
});
