import {
  createUserFormSchema,
  UserDetailsFormMapper,
  UserProfileType,
  useUserFormFieldConfiguration,
} from "@gymflow/common";
import { UserMemberBean } from "@gymflow/types";
import memoizeOne from "memoize-one";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export function useValidateMemberDetails({
  sellerUserType,
}: {
  sellerUserType: "STAFF" | "MEMBER";
}) {
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const defaultNationality = settings.default_nationality;
  const postCodeFormat = settings.postal_code_country;
  const dateFormat = settings.date_format;
  const clubId = settings.clubId;

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const findByEmail = memoizeOne(
    sellerUserType === "STAFF"
      ? api.memberApi.findByEmail
      : api.profileApi.findByEmail,
  );

  return {
    validateMemberDetails: async function (memberDetails: UserMemberBean) {
      const validationSchema = createUserFormSchema({
        postCodeFormat,
        defaultNationality,
        dateFormat,
        validEmails: [memberDetails.email] as any,
        findByEmail,
        requiredFields,
        mustAgreeToClubWaiver: sellerUserType === "MEMBER",
      });
      const mapper = new UserDetailsFormMapper({
        dateFormat,
      });
      let isValid;
      try {
        const formValues = mapper.to(memberDetails) as any;
        let existingFormValuesAreValid = false;
        try {
          await validationSchema.validate({
            ...formValues,
            source: formValues.lead.source,
          });
          existingFormValuesAreValid = true;
        } catch (e) {
          existingFormValuesAreValid = false;
        }
        const isLead = memberDetails?.profileType === UserProfileType.Lead;
        isValid =
          memberDetails.isFullUser && !isLead && existingFormValuesAreValid;
      } catch {
        return false;
      }

      return isValid;
    },
  };
}
