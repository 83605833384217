import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const activityEndpoints = {
  categoryCatalog: "hosted/catalog/activity/category",
};

const publicActivityApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicActivity}`,
    apiUrl,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, canFind(state), {
    async fetchCategories() {
      const result = await state.http.put(state.baseUrl + "/category/search", {
        page: 0,
        size: 1000,
      });
      result.data = result.data.content;
      return result;
    },
    findCategories({ page = 0, limit = 10, status }) {
      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");

      const config = {
        page,
        size: limit,
        statusList: status,
      };

      return state.http.put(`${state.baseUrl}/category/search`, config);
    },

    findCategoryById(id) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );
      return state.http.get(`${state.baseUrl}/category/${id}`);
    },
  });
};

export default publicActivityApi;
