import { tzDateTimeStringToUtc } from "@gymflow/common";
import { RevenueType } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { reportQueryKeys } from "./reportQueryKeys";

export interface UseRevenueByTypeTotalProps {
  api: {
    revenueApi: {
      total: (
        params: {
          dateFrom?: string;
          dateTo?: string;
        },
        revenueType: RevenueType,
      ) => Promise<{ data: { total: number } }>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  revenueType: RevenueType;
  tz: string;
}

export function useRevenueByTypeTotal({
  api,
  dateFrom,
  dateTo,
  revenueType,
  tz,
}: UseRevenueByTypeTotalProps) {
  const result = useQuery({
    queryKey: [
      reportQueryKeys.revenueAccountsReceivableTotal({
        dateFrom,
        dateTo,
        revenueType,
      }),
    ],
    queryFn: async () => {
      const totalRequest = await api.revenueApi.total(
        {
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
        },
        revenueType,
      );
      return totalRequest.data;
    },
    enabled: !!dateFrom && !!dateTo && !!tz,
  });
  return result;
}
