import { ActivityCreateDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useActivityEdit({
  api,
}: {
  api: {
    activityApi: {
      update: (arg0: number, arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      activityId,
      patchedFields,
    }: {
      activityId: number;
      patchedFields: Partial<ActivityCreateDTO>;
    }) => {
      await api.activityApi.update(activityId, patchedFields);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.all() });
    },
  });
  return mutation;
}
