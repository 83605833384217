import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { NoShowItemDTO, ReportDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { Sort } from "../Sort";
import { reportQueryKeys } from "./reportQueryKeys";

export function useNoShowsReport({
  api,
  startDate,
  endDate,
  page,
  limit,
  sort,
  tz,
}: {
  api: ApiType;
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  sort?: Sort[];
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.noShows({
      startDate,
      endDate,
      page,
      limit,
      sort,
      tz,
    }),
    queryFn: async () => {
      const utcStart = startDate && zonedTimeToUtc(startDate as string, tz);
      const utcEnd = endDate && zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.noShows({
        dateFrom: utcStart,
        dateTo: utcEnd,
        page,
        limit,
        sort,
      });
      return result.data;
    },
    placeholderData: () => {
      return {
        items: defaultPage<NoShowItemDTO>(),
        totalCount: 0,
      };
    },
    select: (data: ReportDTO<NoShowItemDTO>) => {
      const dto = merge({}, data.items, {
        content: data.items.content.map(
          ({ startTime, cancellationTime, ...rest }) => {
            return {
              startTime: startTime && utcToZonedTime(startTime, tz),
              noShowTime:
                cancellationTime && utcToZonedTime(cancellationTime, tz),
              ...rest,
            };
          },
        ),
      });
      return {
        ...data,
        items: dto,
      };
    },
    enabled: !!startDate && !!endDate,
  });
  return result;
}
