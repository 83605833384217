import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "../availability";
import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useMutationRecurringAppointmentCancel({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      recurringAppointmentId,
      fromAppointmentId,
    }: {
      recurringAppointmentId: number;
      fromAppointmentId: number;
    }) =>
      api.appointmentApi.cancelRecurring(
        recurringAppointmentId,
        fromAppointmentId,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailability(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(),
      });
    },
  });

  return mutation;
}
