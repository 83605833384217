import React from "react";

import { Spinner } from "../../atoms";

export const UserMemberPaymentsCard = {
  Container: ({
    children,
    isLoading,
  }: {
    children: React.ReactNode;
    isLoading?: boolean;
  }) => (
    <div className="flex min-h-[9.5rem] flex-1 flex-col items-center justify-center gap-3 rounded-2xl border border-gray-200 bg-white shadow-sm">
      {isLoading && <Spinner className="h-12 w-12" />}
      {!isLoading && children}
    </div>
  ),
  Header: ({ children }: { children: React.ReactNode }) => (
    <div className="flex h-14 w-full flex-row items-center justify-between p-4 pb-0">
      {children}
    </div>
  ),
  Footer: ({ children }: { children: React.ReactNode }) => (
    <div className="flex h-20 w-full flex-row items-center justify-between p-4 pt-0">
      {children}
    </div>
  ),
};
