import { useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import {
  ApiResolverProvider,
  ClubSettingsAsPublicProvider,
} from "../../providers";
import { authentication as routes } from "../../routes";
import { defaultAuthenticationRoute as defaultRoute } from "../../routes/default";

function AuthenticationLayout({ match }) {
  const getRoutes = useCallback((routes) => {
    const nodes = routes.map((prop) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {<prop.component />}
        </Route>
      );
    });

    nodes.push(
      <Route>
        <Redirect key={nodes.length} to={match.url + defaultRoute} />
      </Route>,
    );

    return nodes;
  }, []);

  return <Switch>{getRoutes(routes)}</Switch>;
}

function AuthenticationWithClub(props) {
  return (
    <ApiResolverProvider userType="PUBLIC">
      {({ clubId }) => (
        <ClubSettingsAsPublicProvider
          clubId={clubId}
          disableClubColorCustomization
        >
          <AuthenticationLayout {...props} />
        </ClubSettingsAsPublicProvider>
      )}
    </ApiResolverProvider>
  );
}

export { AuthenticationWithClub as AuthenticationLayout };
