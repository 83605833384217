import { thunk, thunkOn } from "easy-peasy";

import BaseModelBuilder, { getApi } from "./BaseModelBuilder";

class HostedClubModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId) {
    super(apiKey);
    this.clubId = clubId;
    this.generators.push(this.hostedClubGenerator);
  }

  hostedClubGenerator() {
    return {
      apiKey: this.apiKey,
      clubId: this.clubId,

      create: thunk((_, fields, { injections, getState }) => {
        const updatedFields = { ...fields, logo: "" };
        const updatedLogo = fields.logo.blob && fields.logo;

        return getApi(injections, getState()).create(getState().clubId, {
          fields: updatedFields,
          logo: updatedLogo,
        });
      }),

      update: thunk((_, fields, { injections, getState }) => {
        const updatedFields = { ...fields, logo: fields.logo.name };
        const updatedLogo = fields.logo.blob && fields.logo;
        return getApi(injections, getState()).update(getState().clubId, {
          fields: updatedFields,
          logo: updatedLogo,
        });
      }),

      onCreateOrUpdate: thunkOn(
        (actions) => [actions.update.successType, actions.create.successType],
        async (actions, _, { getState }) => {
          await actions.fetchById(getState().clubId);
        },
      ),
    };
  }
}

export default HostedClubModelBuilder;
