import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { DATE_FORMAT_WITH_SECONDS } from '@gymflow/common';
import { formatCurrency } from '@gymflow/helpers';

import { transactionTypeMap } from './Timeline';
import Item from './Item';

function TransactionItem({ amount, type, transactionDate, currency, dateFormat }) {
  return (
    <Item
      icon={faCreditCard}
      firstLine={
        <div className="d-inline-block">
          {transactionTypeMap[type]}: {['REFUND_DEBIT', 'WRITE_OFF'].includes(type) && '-'}{' '}
          {formatCurrency(amount, currency)}
        </div>
      }
      secondLine={<>Made {moment(transactionDate, DATE_FORMAT_WITH_SECONDS).format(`[at] h:mm a, ${dateFormat}`)}</>}
    />
  );
}

TransactionItem.propTypes = {
  amount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  transactionDate: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default TransactionItem;
