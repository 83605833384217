import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { PaymentMethodDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberPaymentMethodListAsMember(
  {
    api,
    memberId,
    clubId,
    enabled,
  }: {
    api: ApiType;
    memberId?: string;
    clubId: number;
    enabled?: boolean;
  },
  queryOpts?: UseQueryOptions<PaymentMethodDTO[]>,
) {
  const result = useQuery({
    queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
    queryFn: async () => {
      const result = await api.profileApi.fetchPaymentMethods(
        memberId!,
        clubId,
      );
      return result.data as PaymentMethodDTO[];
    },
    enabled:
      memberId !== undefined &&
      !!api?.profileApi?.fetchPaymentMethods &&
      enabled,
    ...queryOpts,
  });
  return result;
}
