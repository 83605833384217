import { cn } from "@gymflow/helpers";
import classNames from "classnames";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  PaginatedSelect,
} from "../atoms";

const pageSizeOptions = [
  {
    value: 5,
    label: "5",
  },

  {
    value: 10,
    label: "10",
  },

  {
    value: 20,
    label: "20",
  },

  {
    value: 25,
    label: "25",
  },

  {
    value: 50,
    label: "50",
  },

  {
    value: 100,
    label: "100",
  },
];

export function ReportPagination({
  pageCount,
  currentPage,
  onPageChange,
  maxOptionCount = 5,
  pageSize = 25,
  setPageSize = () => {},
}: {
  pageCount: number;
  currentPage: number;
  onPageChange: (newPage: number) => void;
  maxOptionCount?: number;
  pageSize: number;
  setPageSize: (newSize: number) => void;
}) {
  const renderPageNumbers = () => {
    const halfOptions = Math.floor(maxOptionCount / 2);
    const nodes = [];
    if (pageCount <= maxOptionCount) {
      for (let i = 0; i < pageCount; i++) {
        nodes.push(
          <ReportPage
            key={i}
            number={i + 1}
            isCurrent={i === currentPage}
            onClick={() => onPageChange(i)}
          />,
        );
      }
    } else {
      for (let i = 0; i < pageCount; i++) {
        const isFirst = i === 0;
        const isLast = i === pageCount - 1;
        const isCurrentOrArround =
          i > currentPage - halfOptions && i < currentPage + halfOptions;
        if (isFirst || isLast || isCurrentOrArround) {
          nodes.push(
            <ReportPage
              key={i}
              number={i + 1}
              isCurrent={i === currentPage}
              onClick={() => onPageChange(i)}
            />,
          );
        } else if (
          i === currentPage - halfOptions ||
          i === currentPage + halfOptions
        ) {
          nodes.push(
            <span
              key="ellipis"
              className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-600"
            >
              ...
            </span>,
          );
        }
      }
    }
    return (
      <nav className="isolate inline-flex -space-x-px" aria-label="Pagination">
        {nodes}
      </nav>
    );
  };

  return (
    <div className="flex items-center justify-between rounded-xl bg-white px-4 py-3 sm:px-6">
      <Button
        className={cn("mt-2", { invisible: currentPage === 0 })}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <div className="flex items-center">
          <ArrowLeftIcon className="mr-2" pathClassName="stroke-gray-500" />
          <div>Previous</div>
        </div>
      </Button>
      {renderPageNumbers()}
      <div className="flex">
        <PaginatedSelect
          className="!mt-2 !h-11"
          value={{ value: pageSize, label: `${pageSize} rows` }}
          onChange={function (newValue): void {
            setPageSize(newValue.value);
          }}
          loadOptions={() => {
            return Promise.resolve({
              options: pageSizeOptions,
              hasMore: false,
            });
          }}
        />

        <Button
          className={classNames("ml-2 mt-2", {
            invisible: currentPage + 1 >= pageCount,
          })}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <div className="flex items-center">
            <div>Next</div>
            <ArrowRightIcon className="ml-2" pathClassName="stroke-gray-500" />
          </div>
        </Button>
      </div>
    </div>
  );
}

export function ReportPage({
  number,
  isCurrent,
  onClick,
}: {
  number: number;
  isCurrent: boolean;
  onClick: () => void;
}) {
  const activeClasses =
    "relative z-10 inline-flex items-center text-gray-800 bg-gray-50 rounded-full px-4 py-2 text-sm font-semibold text-gray-300  cursor-not-allowed";
  const nonActiveClasses =
    "relative inline-flex items-center text-gray-600 px-4 py-2 text-sm font-semibold cursor-pointer";
  return (
    <div
      aria-current={isCurrent ? "page" : false}
      className={isCurrent ? activeClasses : nonActiveClasses}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {number}
    </div>
  );
}
