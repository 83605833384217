import { useStoreActions, useStoreState } from "easy-peasy";
import useGymflowModels from "../store";

/**
 * @deprecated useStaffList instead
 */
const useStaff = () => {
  const { staffStore } = useGymflowModels();
  const { fetchList } = useStoreActions(staffStore);
  const { page, pageCount, editing, rows, loadingList, loadingRecord } = useStoreState(staffStore);

  return { fetchList, page, pageCount, editing, rows, loadingRecord, loadingList };
};

export default useStaff;
