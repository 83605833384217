import { FacilityCategoryDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export function useFacilityCategories(
  {
    api,
  }: {
    api: {
      facilityApi: {
        fetchCategories: () => Promise<{ data: FacilityCategoryDTO[] }>;
      };
    };
  },
  queryOpts?: UseQueryOptions<FacilityCategoryDTO[]>,
) {
  const result = useQuery({
    queryKey: facilityQueryKeys.categories(),
    queryFn: async () => {
      const result = await api.facilityApi.fetchCategories();
      return result.data;
    },
    initialData: [],
    ...queryOpts,
  });
  return result;
}
