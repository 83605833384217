import { ApiListResponse } from "@gymflow/types";

export function defaultPage<T = any>() {
  return {
    content: [],
    number: 0,
    totalPages: 0,
    last: true,
    totalElements: 0,
    size: 0,
    first: true,
    numberOfElements: 0,
    empty: true,
    sort: {
      empty: true,
      sorted: false,
      unsorted: true
    },
    pageable: {
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: false,
      sort: {
        empty: true,
        sorted: false,
        unsorted: true
      }
    }
  } as ApiListResponse<T>;
}

