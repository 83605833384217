import { QueryKey } from "@tanstack/react-query";

export const appointableAsPublicQueryKeys = {
  all: () => ["appointableAsPublic"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...appointableAsPublicQueryKeys.all(), { filters }] as QueryKey,
  details: (appointableId: number | null) =>
    [...appointableAsPublicQueryKeys.all(), appointableId] as QueryKey,
  categoriesAll: () =>
    [...appointableAsPublicQueryKeys.all(), "categories"] as QueryKey,
  categories: (filters: Readonly<{ [k: string]: any }>) =>
    [
      ...appointableAsPublicQueryKeys.all(),
      "categories",
      { filters },
    ] as QueryKey,
};
