import { DateTime } from "luxon";

const getDaySuffix = (day: number) => {
  if (day > 3 && day < 21) return "th";
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const formattedDate = (value: Date) => {
  if (!value) return undefined;

  const date = DateTime.fromJSDate(value);
  const day = date.day;
  const suffix = getDaySuffix(day);
  return `${day}${suffix} ${date.toFormat("MMMM yyyy")}`;
};
