import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "../member";

export function useLeadNote({
  api,
}: {
  api: {
    noteApi: {
      createLeadNote: (newNote: unknown, file: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const createLeadNoteMutation = useMutation({
    mutationFn: ({
      newNote,
      file,
    }: {
      newNote: { [k: string]: any };
      file?: unknown;
    }) => api.noteApi.createLeadNote(newNote, file),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });

  return {
    createLeadNoteMutation,
  };
}
