import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { PopoverClose } from "@radix-ui/react-popover";
import { ReactNode } from "react";

import {
  Button,
  FilterIcon,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../atoms";
import { RefreshCcwIcon } from "../atoms/icons/RefreshCcwIcon";

export const HostedPagesFilterPopover = {
  Container: ({ children }: { children: ReactNode }) => {
    return <Popover>{children}</Popover>;
  },
  Content: ({ children }: { children: ReactNode }) => {
    return (
      <PopoverContent
        className="flex w-fit max-w-[90vw] flex-col lg:max-w-lg"
        align="end"
      >
        {children}
      </PopoverContent>
    );
  },
  Trigger: ({ isFilterEmpty }: { isFilterEmpty: boolean }) => {
    return (
      <PopoverTrigger asChild>
        <Button className="relative">
          <div className="flex flex-row items-center gap-1">
            <FilterIcon pathClassName="stroke-gray-950 dark:stroke-white" />
            <div className="hidden font-medium text-gray-950 dark:text-white lg:flex">
              Filter
            </div>
          </div>
          <div
            className={cn(
              "bg-secondary-500 absolute right-1 top-1 h-2 w-2 rounded-full transition-all duration-200",
              {
                "opacity-0": isFilterEmpty,
              },
            )}
          />
        </Button>
      </PopoverTrigger>
    );
  },
  ContentHeader: ({
    isFilterEmpty,
    onReset,
  }: {
    isFilterEmpty: boolean;
    onReset: () => void;
  }) => {
    return (
      <div className="flex h-12 flex-row items-center justify-between px-4 pt-4">
        <div className="text-lg font-medium text-gray-950 dark:text-white">
          Filters
        </div>
        <div className="flex items-center gap-3">
          {!isFilterEmpty && (
            <Button
              intent="transparent"
              className="flex h-8 items-center gap-1"
              onClick={() => {
                onReset();
              }}
            >
              <RefreshCcwIcon className="h-5 w-5" />
              <div className="text-sm font-semibold">Reset</div>
            </Button>
          )}
          <PopoverClose className="h-5 w-5" asChild>
            <FontAwesomeIcon icon={faClose} className="h-5 w-5 text-gray-500" />
          </PopoverClose>
        </div>
      </div>
    );
  },
};
