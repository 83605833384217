import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { reportHomeRoutes } from "../../views/reports/reportHomeRoutes";
import { ReportContext } from "./ReportContext";
import { ReportLeadsClosedWidget } from "./Widgets/Leads/Closed";
import { ReportLeadsClosedRatioWidget } from "./Widgets/Leads/CloseRatio";
import { ReportLeadsCreatedWidget } from "./Widgets/Leads/Created";
import { ReportLeadsLostWidget } from "./Widgets/Leads/Lost";
import { ReportLeadsOpenedWidget } from "./Widgets/Leads/Opened";

export function Leads() {
  const { createClubLink } = usePortalRoutes();
  const leadsRoute = createClubLink(
    RouteLayout.Staff,
    RouteFeature.Reports,
    reportHomeRoutes.Leads,
  );
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const location = useLocation();

  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);

  const { data, isFetching } = useStatistic({
    api,
    clubId: settings.clubId,
    start:
      dates?.startDate &&
      moment(dates.startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end:
      dates?.endDate &&
      moment(dates.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    trendPeriod: "MONTH",
    categories: ["lead"],
    leadFields: ["created", "opened", "closed", "lost", "closeRatio"],
  });

  return (
    <ReportContext.Provider
      value={{
        isLoading: isFetching,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        leads: data?.lead && {
          created: {
            total: data.lead.created!.total!,
            variation: data.lead.created!.var,
          },
          opened: {
            total: data.lead.opened!.total!,
            variation: data.lead.opened!.var,
          },
          closed: {
            total: data.lead.closed!.total!,
            variation: data.lead.closed!.var,
          },
          lost: {
            total: data.lead.lost!.total!,
            variation: data.lead.lost!.var,
          },
          closeRatio: {
            total: data.lead.closeRatio!.total!,
            variation: data.lead.closeRatio!.var,
          },
        },
      }}
    >
      <div className="mx-[-.75rem] mb-4 flex flex-wrap">
        <ReportLeadsCreatedWidget parentRoute={leadsRoute} />
        <ReportLeadsOpenedWidget parentRoute={leadsRoute} />
        <ReportLeadsClosedWidget parentRoute={leadsRoute} />
        <ReportLeadsLostWidget parentRoute={leadsRoute} />
        <ReportLeadsClosedRatioWidget />
      </div>
    </ReportContext.Provider>
  );
}
