import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export const STATE = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  NOTHING: '',
};

/*** @deprecated use Button instead */
class ProgressButton extends React.Component {
  static defaultProps = {
    errorText: 'Error!',
    loadingText: 'Loading...',
    successText: 'Success!',
    timeout: 2000,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentState: this.props.state || STATE.NOTHING,
    };
    this.timeout = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.state === this.props.state) {
      return;
    }
    switch (this.props.state) {
      case STATE.SUCCESS:
        this.success();
        return;
      case STATE.ERROR:
        this.error();
        return;
      case STATE.LOADING:
        this.loading();
        return;
      default:
        this.notLoading();
        
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  success() {
    this.setState({
      currentState: STATE.SUCCESS,
    });
    this.timeout = this.setTimeout(() => {
      this.notLoading();
    });
  }

  error() {
    this.setState({
      currentState: STATE.ERROR,
    });
    this.timeout = this.setTimeout(() => {
      this.notLoading();
    });
  }

  notLoading() {
    this.setState({
      currentState: STATE.NOTHING,
    });
  }

  setTimeout(callback) {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    return setTimeout(() => {
      if (this.timeout !== null && typeof this.timeout !== 'undefined') {
        callback();
      }
    }, this.props.timeout);
  }

  loading() {
    this.setState({
      currentState: STATE.LOADING,
    });
  }

  renderButtonText() {
    switch (this.state.currentState) {
      case STATE.SUCCESS:
        return this.props.successText;
      case STATE.ERROR:
        return this.props.errorText;
      case STATE.LOADING:
        return this.props.loadingText;
      default:
        return this.props.children;
    }
  }

  render() {
    const childProps = {};
    const { timeout, loadingText, successText, errorText, ...attributes } = this.props;
    if (this.state.currentState === STATE.SUCCESS) {
      childProps.color = 'success';
    } else if (this.state.currentState === STATE.ERROR) {
      childProps.color = 'danger';
    }

    return (
      <Button {...attributes} {...childProps}>
        {this.renderButtonText()}
      </Button>
    );
  }
}

ProgressButton.propTypes = {
  errorText: PropTypes.string,
  state: PropTypes.oneOf(Object.values(STATE)),
  successText: PropTypes.string,
  timeout: PropTypes.number,
};

export default ProgressButton;
