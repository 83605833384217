import { assert } from 'check-types';

import {
  canTrimFields,
  canFindById,
  canFind,
  canCreate,
  canUpdate,
  canActivateAndDeactivate,
} from './features/apiResource';
import endpoints from './endpoints';

const leadSourceEndpoints = {
  findActive: `${endpoints.leadSource}/active`,
  active: `${endpoints.leadSource}/:id/active`,
  inactive: `${endpoints.leadSource}/:id/inactive`,
};

const leadSourceApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ['name'],
    baseUrl: `${clubPrefix}${endpoints.leadSource}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    canActivateAndDeactivate(state),
    {
      findActive({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, 'page must be number or undefined');
        assert.maybe.number(limit, 'limit must be number or undefined');

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(sort.field, 'sort.field must be a string if sort is specified.');
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(`${clubPrefix}${leadSourceEndpoints.findActive}`, config);
      },
      active(id) {
        assert.number(id, 'id must be a number');

        const url = clubPrefix + leadSourceEndpoints.active.replace(':id', id);
        return state.http.patch(url);
      },
      inactive(id) {
        assert.number(id, 'id must be a number');
        const url = clubPrefix + leadSourceEndpoints.inactive.replace(':id', id);

        return state.http.patch(url);
      },
    }
  );
};

export default leadSourceApi;
