import { useParseErrors, UserProfileType } from "@gymflow/common";
import { useCallback } from "react";
/***
 * @deprecated use useMemberCreate.ts
 */
const useStaffOnUserSubmit = ({ create, update, clubId }) => {
  const parseError = useParseErrors();

  const onUserSubmit = useCallback(
    async ({ user, wasEdited }) => {
      const userArgs = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        mobileNumber: user.mobileNumber,
        dateBirth: user.dateBirth,
        postCode: user.postCode,
        city: user.city,
        addressLine1: user.addressLine1,
        addressLine2: user.addressLine2,
        country: user.country,
        gender: user.gender,
        emailCommunication: user.emailCommunication,
        smsCommunication: user.smsCommunication,
        sourceId: user.sourceId,
        emergencyContact: user.emergencyContact,
        emergencyContactName: user.emergencyContactName,
        nationality: user.nationality,
        isClubWaiverAccepted: user.isClubWaiverAccepted,
        personalNumber: user.personalNumber,
      };

      try {
        if (user.isMiniUser) {
          const { data } = await create({
            ...userArgs,
            id: user.id,
            pushCommunication: true,
          });

          return { email: user.email, ...data.userMember };
        }

        if (user.profileType === UserProfileType.User) {
          if (wasEdited) {
            const { data } = await update(user.id, clubId, {
              ...userArgs,
            });
            return {
              email: user.email,
              ...data.userMember,
            };
          }
          return user;
        }

        const { data } = await create({
          ...userArgs,
          pushCommunication: true,
        });

        return { email: user.email, ...data.userMember };
      } catch (e) {
        parseError(e.response);
        return false;
      }
    },
    [create, update, clubId],
  );

  return onUserSubmit;
};

export default useStaffOnUserSubmit;
