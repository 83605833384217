import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { membershipAsMemberQueryKeys } from "./membershipAsMemberQueryKeys";

export function useQueryMembershipAsMember({
  api,
  id,
  enabled,
}: {
  api: ApiType;
  id?: Parameters<ApiType["customerMembershipApi"]["getById"]>[0];
  enabled?: boolean;
}) {
  const result = useQuery({
    queryKey: membershipAsMemberQueryKeys.getById(id),
    queryFn: async () => {
      return (await api.customerMembershipApi.getById(id!)).data;
    },
    enabled: id !== undefined && enabled,
  });
  return result;
}
