import { rulesHelper } from "@gymflow/helpers";
import { RuleGroup, RuleGroupPost } from "@gymflow/types";
import { z } from "zod";

const AccessRuleSchema = z.object({
  name: z.string(),
  automaticCheckout: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      checkoutAfterAmount: z.number(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
});
export type AccessRuleFormType = z.infer<typeof AccessRuleSchema>;
export const AccessRuleMapper = {
  schema: AccessRuleSchema,
  defaultValues: {
    name: "",
    automaticCheckout: {
      enabled: false,
    },
  } as const,
  mapBEtoFE: (ruleGroup: RuleGroup) => {
    const accessRules = rulesHelper.mapRuleGroupToAccessRules(ruleGroup);
    const result: AccessRuleFormType = {
      name: ruleGroup.name,
      automaticCheckout:
        accessRules.checkoutRule!.windowValue !== "0"
          ? {
              enabled: true,
              checkoutAfterAmount: Number(
                accessRules.checkoutRule!.windowValue,
              ),
            }
          : {
              enabled: false,
            },
    };
    return result;
  },
  mapFEtoBE: (accessRule: AccessRuleFormType) => {
    const result: RuleGroupPost = {
      name: accessRule.name,
      groupType: "ACCESS_GROUP",
      ruleClubList: [
        {
          ruleType: "AUTOMATIC_CHECK_OUT",
          checkOutRule: {
            windowValue: `${
              accessRule.automaticCheckout.enabled
                ? accessRule.automaticCheckout.checkoutAfterAmount
                : 0
            }`,
          },
        },
      ],
    };
    return result;
  },
};
