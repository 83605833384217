import noop from "lodash";
import { useCallback } from "react";

const externalFonts = {
  Manrope: {
    css: "",
  },
  "Potta One": {
    css: "@import url('https://fonts.googleapis.com/css2?family=Potta+One&display=swap');",
  },
  Raleway: {
    css: "@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');",
  },
  "Circular Std Bold": {
    css: "@font-face{font-family: 'Circular Std Bold';src: url('/fonts/CircularStdBold.woff') format('woff'),url('/fonts/CircularStdBold.ttf') format('truetype')}",
  },
  "Gotham Condensed": {
    css: "@font-face{font-family: 'Gotham Condensed';src: url('/fonts/gotham-condensed/GothamCondensed-Book.otf') format('opentype')}",
  },
  "Futura Regular": {
    css: "@font-face{font-family: 'Futura Regular';src: url('/fonts/futura/FuturaPTW01-Book.woff') format('woff'), url('/fonts/futura/FuturaPTW01-Book.woff2') format('woff2')}",
  },
  "Futura Hv Bt": {
    css: "@font-face{font-family: 'Futura Hv Bt';src: url('/fonts/futura-hv-bt/FutuHv.ttf') format('truetype')}",
  },
  "Nunito Sans": {
    css: "@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&display=swap');",
  },
  Montserrat: {
    css: "@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');",
  },
  Poppins: {
    css: "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');",
  },
  Barlow: {
    css: "@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');",
  },
  LeagueSpartan: {
    css: "@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');",
  },
  NTR: {
    css: "@import url('https://fonts.googleapis.com/css2?family=NTR&display=swap')",
  },
};

const injectCss = (styleString) => {
  const styleElement = document.createElement("style");
  styleElement.textContent = styleString;
  document.head.append(styleElement);
  return function () {
    styleElement.remove();
  };
};

const useFont = () => {
  const inject = useCallback((fontName) => {
    if (externalFonts[fontName]) {
      return injectCss(externalFonts[fontName].css);
    }
    return noop;
  }, []);

  return inject;
};

export default useFont;
