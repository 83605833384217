import { useMutation, useQueryClient } from "@tanstack/react-query";
import { staffQueryKeys } from "./staffQueryKeys";

export function useStaffSetClubAccessible({ api }: {
  api: {
    staffApi:
    {
      setClubAccessible: (staffId: string, accessibleClubs: number[]) => Promise<any>
    }
  }
}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: ({ staffId, accessibleClubs }: { staffId: string, accessibleClubs: number[] }) =>
      api.staffApi.setClubAccessible(staffId, accessibleClubs),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: staffQueryKeys.all() })
    }
  }
  );


  return mutation
}
