export const theme = {
  text: {
    big: '25px',
    medium: '14px',
    small: '10px',
  },
  textColor: {
    black: '#3A3A3F',
  },
};

