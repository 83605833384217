import { appointableCategoriesQueryFn } from "@gymflow/api";
import { FormMapper, useRecordForm } from "@gymflow/common";
import { generateDurationOptions } from "@gymflow/helpers";
import useGymflowModels from "apps/portal/src/store";
import { Formik, useFormikContext } from "formik";
import noop from "lodash/noop";
import { useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";

import { ModalContext } from "../../../providers";
import {
  FormikTextAreaInput,
  FormikTextInput,
  PaginatedSelect,
  Select,
} from "../../atoms";
import { WizardContext, WizardState } from "../../organisms/StepWizard";
import { NewAppointableCategoryModal } from "./NewAppointableCategoryModal";

export function AppointableWizardStep1() {
  const { wizardState } = useContext(WizardContext);
  const { initialValues, getValues } = useRecordForm({
    record: wizardState["details"] || null,
    fields: schema.getDefault(),
    mapper,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={noop}
      validationSchema={schema}
    >
      <Step1Form getValues={getValues} />
    </Formik>
  );
}

function Step1Form({ getValues }: { getValues: (formValues: any) => any }) {
  const formikProps = useFormikContext();
  const values = formikProps.values as { [k: string]: any };
  const errors = formikProps.errors as Record<string, string>;
  const touched = formikProps.touched as Record<string, string>;
  const { setFieldValue } = formikProps;
  const { api } = useGymflowModels();
  const durationOptions = useMemo(() => {
    return generateDurationOptions({
      intervalsInMinutes: 15,
      hours: 4,
    });
  }, []);

  const { setWizardState, wizardState } = useContext(WizardContext);
  useEffect(() => {
    function updateWizardState() {
      const isValid = Object.keys(errors).length === 0;
      setWizardState((prev: WizardState) => ({
        ...prev,
        details: getValues(values),
        isValid,
      }));
    }
    updateWizardState();
  }, [values, errors]);

  useEffect(() => {
    formikProps.validateForm();
  }, [formikProps.validateForm]);

  useEffect(() => {
    if (wizardState.isWizardStepValid !== undefined) {
      formikProps.submitForm();
    }
  }, [wizardState.isWizardStepValid]);
  const { setModal } = useContext(ModalContext);
  return (
    <div className="p-2">
      <div>
        <div>Name</div>
        <div className="mt-2">
          <FormikTextInput name={NAME} placeholder="Enter a appointment name" />
        </div>
      </div>

      <div className="mt-4">
        <div>Description</div>
        <div className="mt-2">
          <FormikTextAreaInput
            name={DESCRIPTION}
            placeholder="Write a nice description for customer to read before purchasing"
          />
        </div>
      </div>

      <div className="mt-4">
        <div>Appointment Terms</div>
        <div className="mt-2">
          <FormikTextAreaInput
            name={TERMS_AND_CONDITIONS}
            placeholder="Write in your appointment terms e.g. cancellation policy"
          />
        </div>
      </div>

      <div className="mt-4">
        <div>Category</div>
        <div className="mt-2">
          <div>
            <PaginatedSelect
              isMulti={false}
              loadOptions={async (_, __, { page }) => {
                const result = await appointableCategoriesQueryFn({
                  api,
                  paginationOption: { size: 100, page },
                  filter: {
                    statusList: ["ACTIVE"],
                  },
                });

                return {
                  options: result.content.map(({ name, id }) => ({
                    value: id,
                    label: name,
                  })),
                  hasMore: !result.last,
                  additional: { page: page + 1 },
                };
              }}
              value={
                values?.[CATEGORY]
                  ? {
                      label: values[CATEGORY].name,
                      value: values[CATEGORY].id,
                    }
                  : undefined
              }
              onChange={({ value, label }) => {
                setFieldValue(CATEGORY, { id: value, name: label });
              }}
              placeholder="Select appointment category"
              showMenuFooterButton
              menuFooterClick={async () => {
                setModal(
                  <NewAppointableCategoryModal
                    onCancel={() => setModal(null)}
                    api={api}
                  />,
                );
              }}
              refetchOnMenuOpen
            />
          </div>
          <div className="flex w-full">
            {touched[CATEGORY] && errors[CATEGORY] && (
              <div className="text-error-600 ml-3.5 mt-2">
                {errors[CATEGORY]}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="mt-4 hidden">
        <div>Capacity</div>
        <div className="mt-2">
          <FormikTextInput
            name={CAPACITY}
            placeholder="How many people are allowed per appointment?"
          />
        </div>
      </div>

      <div className="mt-4">
        <div>Duration</div>
        <div className="mt-2">
          <div>
            <Select
              options={durationOptions}
              value={
                values?.[DURATION] &&
                durationOptions.find(
                  (duration) => duration.value === values[DURATION],
                )
              }
              onChange={({ value }: { value: number }) => {
                setFieldValue(DURATION, value);
              }}
            />
          </div>

          <div className="flex w-full">
            {touched[DURATION] && errors[DURATION] && (
              <div className="text-error-600 ml-3.5 mt-2">
                {errors[DURATION]}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const NAME = "name";
const DESCRIPTION = "description";
const TERMS_AND_CONDITIONS = "terms-and-conditions";
const CATEGORY = "category";
const CAPACITY = "capacity";
const DURATION = "duration";

const schema = Yup.object().shape({
  [NAME]: Yup.string().required(),
  [DESCRIPTION]: Yup.string().max(2000),
  [TERMS_AND_CONDITIONS]: Yup.string(),
  [CATEGORY]: Yup.object()
    .shape({
      id: Yup.number(),
      name: Yup.string(),
    })
    .required()
    .default(undefined),
  [CAPACITY]: Yup.number().required().min(1).default(1),
  [DURATION]: Yup.number().required().min(0),
});

const mapper = new FormMapper();
