import { useMutationRemovePromotionCodeFromSubscription } from "@gymflow/api";
import { formatCurrency } from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import useGymflowModels from "apps/portal/src/store";
import { useContext } from "react";

import {
  ModalContext,
  ModalWrapper,
  useClubSettings,
} from "../../../../providers";
import { AlarmClockIcon, Button } from "../../../atoms";
import { ExclamationTriangleIcon } from "../../../atoms/icons/ExclamationTriangleIcon";

export function RemovePromotionCodeModal({
  membership,
}: {
  membership: UserMemberSubscriptionBeanWithMembership;
}) {
  const { api } = useGymflowModels();
  const { defaultCurrency } = useClubSettings();
  const { hide: hideModal } = useContext(ModalContext);
  const removePromotionCodeFromSubscription =
    useMutationRemovePromotionCodeFromSubscription({ api });
  if (!("promotionCode" in membership)) return undefined;
  if (membership.promotionIsExpired) return undefined;
  return (
    <ModalWrapper onCancel={hideModal}>
      <div className="bg-error-50 flex h-11 w-11 items-center justify-center self-center rounded-full">
        <div className="bg-error-100 flex h-8 w-8 items-center justify-center self-center rounded-full">
          <ExclamationTriangleIcon
            className="h-5 w-5"
            pathClassName="stroke-error-600"
          />
        </div>
      </div>
      <div className="mt-4 text-lg font-semibold text-gray-900">
        Remove Promotional Code
      </div>
      <div className="mt-1 text-sm font-normal text-gray-600">
        Are you sure you want to remove the promotion code? Discount will be
        removed from all future payments.
      </div>
      <div className="my-5 border-t-2 border-dashed border-gray-300"></div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between text-base font-medium text-gray-500">
          <div>{membership.name}</div>
          <div>{formatCurrency(membership.price, defaultCurrency)}</div>
        </div>
        <div className="flex justify-between text-base font-medium text-gray-500">
          <div>Promotional Code</div>
          <div>
            -{formatCurrency(membership.discountedAmount ?? 0, defaultCurrency)}
          </div>
        </div>
        <div className="mt-[-1.31rem] border-t border-gray-950" />
        <div className="mt-2 flex justify-between text-xl font-bold text-gray-950">
          <div>Amount</div>
          <div>{formatCurrency(membership.price, defaultCurrency)}</div>
        </div>
      </div>
      <div className="mt-4 flex items-center justify-between rounded-xl border border-gray-200 px-4 py-3">
        <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
          <AlarmClockIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
          Duration
        </div>
        <div className="text-base font-semibold text-gray-950">
          All Future Payments
        </div>
      </div>
      <div className="mt-5 flex">
        <Button onClick={hideModal} className="flex-1">
          Cancel
        </Button>
        <Button
          intent="danger"
          className="ml-4 flex-1"
          onClick={async () => {
            await removePromotionCodeFromSubscription.mutateAsync({
              subscriptionId: membership.id,
            });
            hideModal();
          }}
        >
          Remove
        </Button>
      </div>
    </ModalWrapper>
  );
}
