import "react-image-crop/dist/ReactCrop.css";

import { ChangeEventHandler, createRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import { useClubSettings } from "../../providers";
import { TextButton } from "../atoms";
import ImageUploader from "./ImageUploader";

const SUPPORTED_EXTENSIONS = ["jpg", "jpeg", "png", "webp"];

export type UserMemberPictureProps = {
  initials: string;
  avatar?: string;
  loading: () => void;
  notLoading: () => void;
  isLoading: boolean;
  onChange?: (image: { blob: string; name: string }) => Promise<void>;
  disabled: boolean;
};
export const UserMemberPicture: React.FC<UserMemberPictureProps> = ({
  initials,
  loading,
  notLoading,
  isLoading,
  onChange,
  avatar,
  disabled,
}) => {
  const settings = useClubSettings();
  const maxWidth = settings.max_image_upload_width;
  const maxHeight = settings.max_image_upload_height;

  const [newImage, setNewImage] = useState<{
    name: string;
    blob: string | ArrayBuffer | null;
  }>();
  const [error, setError] = useState<string>();
  const fileRef = createRef<HTMLInputElement>();

  const readFile = (file: File) => {
    const validExtension = SUPPORTED_EXTENSIONS.some((extension) =>
      file.name.endsWith(extension),
    );
    if (!validExtension) {
      setError(
        `File format is not supported. Try one of the following extensions: (${SUPPORTED_EXTENSIONS.join(
          ", ",
        )}).`,
      );
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const img = new Image();
      img.addEventListener("load", () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          setError(
            `Image should not be bigger than ${maxWidth} by ${maxHeight} pixels.`,
          );
          return;
        } else if (img.width < 100 || img.height < 100) {
          setError(
            "Image cannot have less than 100 pixels of width or height.",
          );
          return;
        }
        setError(undefined);
        setNewImage({ name: file.name, blob: reader.result });
      });
      img.addEventListener("error", () => {
        setError(
          "Image could not be loaded. Make sure you selected a valid picture.",
        );
      });
      img.src = reader.result?.toString() ?? "";
    });
    reader.readAsDataURL(file);
  };
  const onDrop = (acceptedFiles: File[]) => {
    const fileToUpload = acceptedFiles[0];
    readFile(fileToUpload);
  };
  const { getRootProps, isDragActive } = useDropzone({ onDrop });
  const onSelectFile: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      readFile(e.target.files[0]);
    }
  };
  const isDragging = !disabled && onChange && isDragActive;

  const renderProfile = () => {
    if (newImage) {
      return false;
    }
    return (
      <div
        className="text-center"
        style={{
          background: isDragging ? "#f5f6fa" : "white",
          border: isDragging ? "1px dashed grey" : 0,
        }}
        {...getRootProps()}
      >
        <div className="fileinput flex flex-row items-start gap-2">
          <input
            type="file"
            onChange={onSelectFile}
            accept="image/*"
            ref={fileRef}
          />
          <div className="flex h-20 w-20 min-w-[5rem] items-center justify-center overflow-hidden rounded-full border border-gray-200 text-2xl font-bold text-gray-500">
            {avatar && <img src={avatar} alt="..." />}
            {!avatar && initials}
          </div>
          <div className="flex max-w-full flex-col">
            {isDragging ? (
              "Drop your picture here."
            ) : (
              <TextButton
                onClick={() => fileRef.current?.click()}
                disabled={disabled || isLoading}
              >
                Update
              </TextButton>
            )}
            <div className="text-error-500 max-w-full text-sm">{error}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderImageUploader = () => {
    if (newImage) {
      return (
        <ImageUploader
          src={newImage as any}
          onSave={async (image) => {
            loading();
            setNewImage(undefined);
            await onChange?.(image);
            notLoading();
          }}
          onCancel={() => {
            setNewImage(undefined);
          }}
        />
      );
    }
    return null;
  };

  return (
    <>
      {renderProfile()}
      {renderImageUploader()}
    </>
  );
};
