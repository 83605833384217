import {
  AlertContext,
  AsyncButton,
  fieldHasErrors,
  formikHelpers,
  FormikInput,
  FormMapper,
  gender,
  GenderSelect,
  GenericValidatorParser,
  MobileNumberInput,
  PARAMETER_DATE_ONLY_FORMAT,
  renderErrorRowOnTouch,
  useRecordForm,
  useUserFormFieldConfiguration,
  yupEmergencyContactTest,
} from "@gymflow/common";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import { useFormik } from "formik";
import noop from "lodash/noop";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useContext, useEffect, useMemo } from "react";
import Alert from "react-bootstrap-sweetalert";
import { useLocation } from "react-router-dom";
import ReactSelect from "react-select";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

import { useLoadLeadSources } from "../../hooks/useLoadLeadSources";
import { useClubSettings } from "../../providers";
import useGymflowModels, { settingsStore } from "../../store";
import { useRedirectUrl } from "../../hooks";

class GuestRegistrationFormMapper extends FormMapper {
  constructor(dateFormat) {
    super({
      outKey: {
        "date-of-birth": "dateBirth",
      },
      outValue: [
        { key: "email", transform: (v) => v.toLowerCase() },
        {
          key: "date-of-birth",
          transform: (v) =>
            moment(v, dateFormat, true).format(PARAMETER_DATE_ONLY_FORMAT),
        },
        { key: "post-code", transform: (v) => v.trim().toUpperCase() },
      ],
    });
  }

  from(formValues) {
    const transformed = super.from(formValues);

    delete transformed.acceptToc;

    if (!formValues["emergency-contact"]) {
      delete transformed.emergencyContact;
    }

    if (!formValues["emergency-contact-name"]) {
      delete transformed.emergencyContactName;
    }
    return transformed;
  }
}

export function GuestRegistration() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { redirectToCustomizedWebsiteOr } = useRedirectUrl();

  const DEFAULT_COUNTRY = settings.default_nationality;
  const POST_CODE_FORMAT = settings.postal_code_country;
  const LOCAL_DATE_FORMAT = settings.date_format;

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId: settings.clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const mapper = new GuestRegistrationFormMapper(LOCAL_DATE_FORMAT);
  const { setAlert, hide } = useContext(AlertContext);
  const { termsConditions } = useStoreState(settingsStore);
  const { options: sourceOptions } = useLoadLeadSources();

  const location = useLocation();
  const { source } = qs.parse(location.search.slice(1));

  const schema = useMemo(() => {
    const baseSchema = {
      [EMAIL]: Yup.string().email().required(),
      [FIRST_NAME]: Yup.string().trim().required().min(2),
      [LAST_NAME]: Yup.string().trim().required().min(2),
      [EMAIL_COMMUNICATION]: Yup.boolean().default(false),
      [SMS_COMMUNICATION]: Yup.boolean().default(false),
      [SOURCE_ID]: Yup.string().required(),
      [NATIONALITY]: Yup.string()
        .trim()
        .required()
        .min(3)
        .max(3)
        .default(DEFAULT_COUNTRY),
      [ACCEPT_TOC]: Yup.bool()
        .default(false)
        .oneOf([true], "You must accept the terms and conditions."),
      [IS_CLUB_WAIVER_ACCEPTED]: Yup.bool()
        .default(false)
        .oneOf([true], "You must accept the club's waiver."),
    };

    if (requiredFields?.mobileNumber) {
      baseSchema[MOBILE_NUMBER] = Yup.string().required().phoneNumber();
    }

    if (requiredFields?.dateOfBirth) {
      baseSchema[DATE_OF_BIRTH] = Yup.date()
        .format(LOCAL_DATE_FORMAT, true)
        .required();
    }

    if (requiredFields?.addressLine) {
      baseSchema[ADDRESS1] = Yup.string().trim().required().min(3);
      baseSchema[ADDRESS2] = Yup.string().trim();
    }

    if (requiredFields?.postCode) {
      baseSchema[POST_CODE] = Yup.string()
        .required()
        .postCode(POST_CODE_FORMAT);
    }

    if (requiredFields?.city) {
      baseSchema[CITY] = Yup.string().trim().required().min(3);
    }

    if (requiredFields?.gender) {
      baseSchema[GENDER] = Yup.string()
        .required()
        .default(gender.PreferNotToSay);
    }

    if (requiredFields?.emergencyContact) {
      baseSchema[EMERGENCY_CONTACT_NAME] = Yup.string().required().min(3);
      baseSchema[EMERGENCY_CONTACT] = Yup.string()
        .required()
        .phoneNumber()
        .test(yupEmergencyContactTest);
    }

    if (requiredFields?.personalNumber) {
      baseSchema[PERSONAL_NUMBER] = Yup.string()
        .trim()
        .required()
        .max(25)
        .default("");
    }

    if (typeof source === "undefined") {
      return Yup.object().shape(baseSchema);
    }
    return Yup.object().shape({
      ...baseSchema,
      [SOURCE_ID]: Yup.string().required().default(source),
    });
  }, [source, requiredFields]);

  const { getValues, initialValues } = useRecordForm({
    record: null,
    fields: schema.default(),
    mapper,
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: noop,
  });

  const {
    values,
    validateForm,
    setFieldValue,
    setValues,
    touched,
    errors,
    resetForm,
    submitForm,
  } = formikProps;
  useEffect(() => {
    if (sourceOptions.length === 0 || typeof source === "undefined") {
      return;
    }
    setFieldValue(SOURCE_ID, +source);
  }, [source, sourceOptions]);

  const onSubmit = useCallback(async () => {
    await submitForm();
    const fieldErrors = await validateForm();
    const validated = Object.keys(fieldErrors).length === 0;
    if (validated) {
      const parsedValues = getValues(values);

      try {
        await api.public.userMemberApi.create({
          ...parsedValues,
          pushCommunication: true,
        });

        setAlert(
          <Alert
            title="Thanks for your interest"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            showCloseButton
            onConfirm={() => {
              hide();
              redirectToCustomizedWebsiteOr();
            }}
          >
            We will be in touch shortly.
          </Alert>,
        );

        resetForm();
        setValues(mapper.to(schema.default()));
      } catch (e) {
        let text = "Oops. Couldn&apos;t process your request. Try again later.";
        if (e.response.status === 409) {
          text = e.response.data.error_message;
        } else if (e.response.status === 400) {
          const parser = new GenericValidatorParser(e.response.data.errors);
          text = parser.toString();
        }
        setAlert(
          <Alert
            title="Error"
            type="error"
            closeOnClickOutside={false}
            style={{
              width: "39em",
            }}
            showCloseButton
            onConfirm={hide}
          >
            {text}
          </Alert>,
        );
      }
    }
  }, [getValues, values, hide, setAlert, resetForm, validateForm, submitForm]);

  const { errorClass } = formikHelpers(formikProps);

  return (
    <div className="track-height">
      <Row>
        <Col className="text-center">
          <h3 style={{ fontSize: "1.4375rem", fontWeight: "600" }}>
            Guest Registration
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="mx-auto mt-4" lg="5">
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Form className="form-horizontal" role="form">
                    <Row className="text-uppercase">
                      <Col>
                        <Label htmlFor={EMAIL}>Email *</Label>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col>
                        <FormGroup
                          className={classNames(
                            "dropdown-group",
                            errorClass(EMAIL),
                          )}
                        >
                          <FormikInput
                            name={EMAIL}
                            id={EMAIL}
                            type="text"
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-uppercase">
                      <Col xs="12" sm="6">
                        <Label htmlFor={FIRST_NAME}>First Name *</Label>
                        <FormGroup className={errorClass(FIRST_NAME)}>
                          <FormikInput
                            name={FIRST_NAME}
                            id={FIRST_NAME}
                            type="text"
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <Label htmlFor={LAST_NAME}>Last Name *</Label>
                        <FormGroup className={errorClass(LAST_NAME)}>
                          <FormikInput
                            name={LAST_NAME}
                            type="text"
                            id={LAST_NAME}
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-uppercase">
                      <Col
                        xs="12"
                        sm="6"
                        className={classNames({
                          hidden: !requiredFields?.mobileNumber,
                        })}
                      >
                        <Label htmlFor={MOBILE_NUMBER}>Phone Number *</Label>
                        <FormGroup className={errorClass(MOBILE_NUMBER)}>
                          <FormikInput
                            name={MOBILE_NUMBER}
                            component={MobileNumberInput}
                            type="text"
                            id={MOBILE_NUMBER}
                            maxLength="32"
                            formikProps={formikProps}
                            phone_number_country={settings.phone_number_country}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs="12"
                        sm="6"
                        className={classNames({
                          hidden: !requiredFields?.dateOfBirth,
                        })}
                      >
                        <Label htmlFor={DATE_OF_BIRTH}>Date of Birth *</Label>
                        <FormGroup className={errorClass(DATE_OF_BIRTH)}>
                          <FormikInput
                            name={DATE_OF_BIRTH}
                            type="text"
                            id={DATE_OF_BIRTH}
                            maxLength={LOCAL_DATE_FORMAT.length}
                            formikProps={formikProps}
                            value={formikProps.values[DATE_OF_BIRTH]}
                            placeholder={LOCAL_DATE_FORMAT}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="text-uppercase mt-2">
                      <Col
                        xs="12"
                        sm="6"
                        className={classNames({
                          hidden: !requiredFields?.postCode,
                        })}
                      >
                        <Label htmlFor={POST_CODE}>Postcode/Zip *</Label>
                        <FormGroup className={errorClass(POST_CODE)}>
                          <FormikInput
                            name={POST_CODE}
                            type="text"
                            id={POST_CODE}
                            maxLength="16"
                            formikProps={formikProps}
                            style={{ textTransform: "uppercase" }}
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs="12"
                        sm="6"
                        className={classNames({
                          hidden: !requiredFields?.city,
                        })}
                      >
                        <Label htmlFor={CITY}>City *</Label>
                        <FormGroup className={errorClass(CITY)}>
                          <FormikInput
                            name={CITY}
                            type="text"
                            id={CITY}
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row
                      className={classNames("text-uppercase", {
                        hidden: !requiredFields?.addressLine,
                      })}
                    >
                      <Col xs="12" sm="6">
                        <Label htmlFor={ADDRESS1}>Address *</Label>
                        <FormGroup className={errorClass(ADDRESS1)}>
                          <FormikInput
                            name={ADDRESS1}
                            type="text"
                            id={ADDRESS1}
                            maxLength="128"
                            formikProps={formikProps}
                            data-testid={ADDRESS1}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <Label htmlFor={ADDRESS2} className="invisible">
                          Address 2*
                        </Label>
                        <FormGroup className={errorClass(ADDRESS2)}>
                          <FormikInput
                            name={ADDRESS2}
                            data-testid={ADDRESS2}
                            type="text"
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row
                      className={classNames("text-uppercase", {
                        hidden: !requiredFields?.personalNumber,
                      })}
                    >
                      <Col xs="12" sm="6">
                        <Label htmlFor={PERSONAL_NUMBER}>
                          {settings.default_nationality === "ESP"
                            ? "DNI Passport"
                            : "ID Number"}
                        </Label>
                        <FormGroup className={errorClass(PERSONAL_NUMBER)}>
                          <FormikInput
                            name={PERSONAL_NUMBER}
                            type="text"
                            id={PERSONAL_NUMBER}
                            maxLength="25"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="text-uppercase mt-2">
                      <Col
                        xs="12"
                        sm="6"
                        className={classNames({
                          hidden: !requiredFields?.gender,
                        })}
                      >
                        <Label htmlFor={GENDER}>Gender *</Label>
                        <br />
                        <FormGroup className={errorClass(GENDER)}>
                          <GenderSelect
                            onChange={(newGender) =>
                              formikProps.setFieldValue(GENDER, newGender)
                            }
                            value={formikProps.values[GENDER]}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6" className="mt-sm-0 mt-2">
                        <Label>Let&apos;s keep in touch?</Label>
                        <br />
                        <FormGroup
                          check
                          inline
                          className={errorClass(EMAIL_COMMUNICATION)}
                        >
                          <Label check>
                            <Input
                              name={EMAIL_COMMUNICATION}
                              type="checkbox"
                              id={EMAIL_COMMUNICATION}
                              data-testid="email-communication"
                              checked={values[EMAIL_COMMUNICATION]}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(EMAIL_COMMUNICATION, checked);
                              }}
                            />
                            <span className="form-check-sign" />
                            Email
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          inline
                          className={errorClass(SMS_COMMUNICATION)}
                        >
                          <Label check>
                            <Input
                              name={SMS_COMMUNICATION}
                              type="checkbox"
                              data-testid="sms-communication"
                              checked={values[SMS_COMMUNICATION]}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(SMS_COMMUNICATION, checked);
                              }}
                            />
                            <span className="form-check-sign" />
                            SMS
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row
                      className={classNames(
                        "mt-2",
                        "text-uppercase",
                        "text-left",
                        { hidden: source },
                      )}
                    >
                      <Col>
                        <Label htmlFor={SOURCE_ID}>
                          How did you hear about us? *
                        </Label>
                      </Col>
                    </Row>
                    <Row className={classNames("mt-2", { "d-none": source })}>
                      <Col>
                        <FormGroup
                          className={classNames(
                            "dropdown-group",
                            errorClass(SOURCE_ID),
                          )}
                          data-testid={SOURCE_ID}
                        >
                          <ReactSelect
                            classNamePrefix="react-select"
                            name={SOURCE_ID}
                            inputId={SOURCE_ID}
                            onChange={({ value }) => {
                              setFieldValue(SOURCE_ID, value);
                            }}
                            options={sourceOptions}
                            value={
                              values[SOURCE_ID] &&
                              sourceOptions.length && {
                                value: values[SOURCE_ID],
                                label: sourceOptions.find(
                                  (c) => c.value == values[SOURCE_ID],
                                ).label,
                              }
                            }
                          />
                          {renderErrorRowOnTouch(SOURCE_ID, touched, errors)}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row
                      className={classNames("text-uppercase", {
                        hidden: !requiredFields?.emergencyContact,
                      })}
                    >
                      <Col xs="12" sm="6">
                        <Label htmlFor={EMERGENCY_CONTACT_NAME}>
                          Emergency Contact Name
                        </Label>
                        <FormGroup
                          className={errorClass(EMERGENCY_CONTACT_NAME)}
                        >
                          <FormikInput
                            name={EMERGENCY_CONTACT_NAME}
                            type="text"
                            id={EMERGENCY_CONTACT_NAME}
                            maxLength="128"
                            formikProps={formikProps}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <Label htmlFor={EMERGENCY_CONTACT}>
                          Emergency Contact
                        </Label>
                        <FormGroup className={errorClass(EMERGENCY_CONTACT)}>
                          <FormikInput
                            name={EMERGENCY_CONTACT}
                            component={MobileNumberInput}
                            id={EMERGENCY_CONTACT}
                            type="text"
                            maxLength="32"
                            formikProps={formikProps}
                            phone_number_country={settings.phone_number_country}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col className="checkbox-radios">
                        <FormGroup check className={errorClass(ACCEPT_TOC)}>
                          <Label check>
                            <Input
                              name={ACCEPT_TOC}
                              type="checkbox"
                              data-testid={ACCEPT_TOC}
                              checked={values[ACCEPT_TOC]}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(ACCEPT_TOC, checked);
                              }}
                            />
                            <span className="form-check-sign" />
                            <div style={{ marginTop: "-3px" }}>
                              I Agree to the&nbsp;
                              <a
                                href={termsConditions}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Terms & Conditions
                              </a>
                              .
                            </div>
                          </Label>
                          {fieldHasErrors(ACCEPT_TOC, touched, errors) && (
                            <>
                              <br />
                              {renderErrorRowOnTouch(
                                ACCEPT_TOC,
                                touched,
                                errors,
                              )}
                            </>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="checkbox-radios">
                        <FormGroup
                          check
                          className={errorClass(IS_CLUB_WAIVER_ACCEPTED)}
                        >
                          <Label check>
                            <Input
                              name={IS_CLUB_WAIVER_ACCEPTED}
                              type="checkbox"
                              data-testid={IS_CLUB_WAIVER_ACCEPTED}
                              checked={values[IS_CLUB_WAIVER_ACCEPTED]}
                              onChange={({ target: { checked } }) => {
                                setFieldValue(IS_CLUB_WAIVER_ACCEPTED, checked);
                              }}
                            />
                            <span className="form-check-sign" />
                            <div style={{ marginTop: "-3px" }}>
                              I Agree to the&nbsp;
                              <a
                                href={termsConditions}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                club&apos;s waiver
                              </a>
                              .
                            </div>
                          </Label>
                          {fieldHasErrors(
                            IS_CLUB_WAIVER_ACCEPTED,
                            touched,
                            errors,
                          ) && (
                            <>
                              <br />
                              {renderErrorRowOnTouch(
                                IS_CLUB_WAIVER_ACCEPTED,
                                touched,
                                errors,
                              )}
                            </>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <Row>
                    <Col className="d-flex">
                      <AsyncButton className="mx-auto" onClick={onSubmit}>
                        Register
                      </AsyncButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

const EMAIL = "email";
const FIRST_NAME = "first-name";
const LAST_NAME = "last-name";
const MOBILE_NUMBER = "mobile-number";
const DATE_OF_BIRTH = "date-of-birth";
const POST_CODE = "post-code";
const ADDRESS1 = "address-line-1";
const ADDRESS2 = "address-line-2";
const CITY = "city";
const GENDER = "gender";
const EMAIL_COMMUNICATION = "email-communication";
const SMS_COMMUNICATION = "sms-communication";
const SOURCE_ID = "source-id";
const EMERGENCY_CONTACT_NAME = "emergency-contact-name";
const EMERGENCY_CONTACT = "emergency-contact";
const PERSONAL_NUMBER = "personal-number";
const NATIONALITY = "country";
const ACCEPT_TOC = "accept-toc";
const IS_CLUB_WAIVER_ACCEPTED = "is-club-waiver-accepted";
