import { useMutation, useQueryClient } from "@tanstack/react-query";

import { leadQueryKeys } from "../lead";
import { taskQueryKeys } from "./taskQueryKeys";

export function useTaskDelete({
  api,
}: {
  api: {
    taskApi: {
      remove: (arg0: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (taskId: number) => {
      await api.taskApi.remove(taskId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: taskQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.list() });
    },
  });

  return mutation;
}
