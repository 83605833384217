import { TaskDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { leadQueryKeys } from "../lead";
import { memberQueryKeys } from "../member";
import { deadlineDatesToUtc } from "./deadlineDatesToUtc";
import { taskQueryKeys } from "./taskQueryKeys";

export function useTaskCreate(
  {
    api,
    tz,
  }: {
    api: {
      taskApi: {
        create: (arg1: unknown) => Promise<any>;
      };
    };
    tz: string;
  },
  mutationOpts?: UseMutationOptions<TaskDTO, unknown, { [k: string]: any }>,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newTask: { [k: string]: any }) => {
      const newFields = {
        ...newTask,
        ...deadlineDatesToUtc(newTask, tz),
      };

      const result = await api.taskApi.create(newFields);

      return result as TaskDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: taskQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.list() });
    },
    ...mutationOpts,
  });

  return mutation;
}
