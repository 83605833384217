import { ApiListResponse, FacilityDTO, FacilityStatus } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { facilityQueryKeys } from "./facilityQueryKeys";
import { useFacilityCategories } from "./useFacilityCategories";

export async function facilityListQueryFn({
  api,
  opts,
}: {
  api: FacilityListApi;
  opts?: FacilityListFilter;
}) {
  const result = await api.facilityApi.find({ ...opts });
  return result.data;
}

export function useFacilityList(
  {
    api,
    opts,
  }: {
    api: FacilityListApi;
    opts?: FacilityListFilter;
  },
  queryOpts?: UseQueryOptions<
    ApiListResponse<FacilityDTO>,
    unknown,
    ApiListResponse<FacilityWithCategoryNameDTO>
  >,
) {
  const categories = useFacilityCategories({ api });

  const result = useQuery({
    queryKey: facilityQueryKeys.list(opts),
    queryFn: () => facilityListQueryFn({ api, opts }),
    initialData: defaultPage<FacilityDTO>,
    enabled: categories.isSuccess,
    select: (facilities: ApiListResponse<FacilityDTO>) => {
      return {
        ...facilities,
        content: facilities.content.map((facility) => {
          const facilityCategory = categories.data!.find(
            (category) => category.id === facility.facilityCategoryId,
          )?.name;
          const facilityWithCategoryName: FacilityWithCategoryNameDTO = {
            ...facility,
            categoryName: facilityCategory || "",
          };
          return facilityWithCategoryName;
        }),
      } as ApiListResponse<FacilityWithCategoryNameDTO>;
    },
    ...queryOpts,
  });
  return result;
}

export interface FacilityWithCategoryNameDTO extends FacilityDTO {
  categoryName: string;
}

interface FacilityListFilter {
  readonly extraParams?: {
    readonly status?: FacilityStatus;
    readonly id?: number[];
    readonly availableForAppointments?: boolean;
    readonly name?: string;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}

interface FacilityListApi {
  facilityApi: {
    find: (arg0: {
      [k: string]: any;
    }) => Promise<{ data: ApiListResponse<FacilityDTO> }>;
    fetchCategories: () => Promise<any>;
  };
}
