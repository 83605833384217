import { UserMemberListFilter } from "@gymflow/api";
import {
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  PARAMETER_DATE_ONLY_FORMAT,
} from "@gymflow/common";
import moment from "moment";

const formatDate = (date?: string) => {
  return date
    ? moment(date, PARAMETER_DATE_ONLY_FORMAT)
        .startOf("day")
        .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
    : undefined;
};

const formatEndDate = (date?: string) => {
  return date
    ? moment(date, PARAMETER_DATE_ONLY_FORMAT)
        .endOf("day")
        .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
    : undefined;
};

export const dataFiltersPreparator = (
  filters: UserMemberListFilter,
  excluded: string[],
) => ({
  userMemberIdsToExclude: excluded,
  createdFrom: formatDate(filters?.createdFrom),
  createdTo: formatEndDate(filters?.createdTo),
  smsCommunication: filters?.smsCommunication,
  emailCommunication: filters?.emailCommunication,
  membershipIdList: filters?.membershipId,
  membershipStatusList: filters?.membershipStatus,
  membershipTypeList: filters?.membershipType,
  membershipStartFrom: formatDate(filters?.membershipStartFrom),
  membershipStartTo: formatEndDate(filters?.membershipStartTo),
  membershipCancellationFrom: formatDate(filters?.membershipCancellationFrom),
  membershipCancellationTo: formatEndDate(filters?.membershipCancellationTo),
  membershipExpireFrom: formatDate(filters?.membershipExpireFrom),
  membershipExpireTo: formatEndDate(filters?.membershipExpireTo),
  assignedStaffIdList: filters?.assignedStaffId,
  creditsRemainingFrom: filters?.creditsRemainingFrom,
  creditsRemainingTo: filters?.creditsRemainingTo,
});
