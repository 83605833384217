import { useTask } from "@gymflow/api";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { ReactNode, useCallback, useState } from "react";

import { SlideSideBar, Spinner } from "../../atoms";
import { CreateTaskForm } from "./components/CreateTaskForm";
import { EditTaskForm } from "./components/EditTaskForm";
import { TaskFormValues } from "./components/TaskForm/types";
import {
  CreateEditTaskSidebarProviderContext,
  OpenCreateSidebarOptions,
  OpenEditSidebarOptions,
} from "./CreateEditTaskSidebarProviderContext";
import { normalizeIncomingData } from "./utils/dataNormalizer";

export type CreateEditTaskSidebarProviderProps = {
  children: ReactNode;
};

export const CreateEditTaskSidebarProvider = ({
  children,
}: CreateEditTaskSidebarProviderProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [taskId, setTaskId] = useState<number>();
  const [onUpdate, setOnUpdate] = useState<(() => void) | undefined>();
  const [initialValues, setInitialValues] = useState<Partial<TaskFormValues>>();

  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  const {
    data: task,
    refetch,
    isFetching,
    isError,
  } = useTask({ api, tz, taskId });

  const closeSidebar = useCallback(() => {
    setIsVisible(false);
    setInitialValues(undefined);
    setTaskId(undefined);
    setOnUpdate(undefined);
  }, []);

  const openCreateTaskSidebar = useCallback(
    ({ initialValues: initialValuesLocal }: OpenCreateSidebarOptions = {}) => {
      setInitialValues(initialValuesLocal);
      setTaskId(undefined);
      setOnUpdate(undefined);
      setIsVisible(true);
    },
    [],
  );

  const openEditTaskSidebar = useCallback(
    ({
      taskId: taskIdLocal,
      onUpdate: onUpdateLocal,
    }: OpenEditSidebarOptions) => {
      setTaskId(taskIdLocal);
      setOnUpdate(() => onUpdateLocal);
      setIsVisible(true);
      refetch();
    },
    [refetch],
  );

  return (
    <CreateEditTaskSidebarProviderContext.Provider
      value={{ openCreateTaskSidebar, openEditTaskSidebar }}
    >
      <SlideSideBar
        isOpen={isVisible}
        hide={closeSidebar}
        className="w-[28rem]"
      >
        {taskId === undefined ? (
          <CreateTaskForm
            initialValues={initialValues}
            onClose={closeSidebar}
          />
        ) : isFetching ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <Spinner className="h-12 w-12" />
          </div>
        ) : isError ? (
          <div className="text-error-500 absolute inset-0 flex items-center justify-center font-medium">
            Task not found
          </div>
        ) : (
          task && (
            <EditTaskForm
              taskId={taskId}
              initialValues={normalizeIncomingData(task, tz)}
              onClose={closeSidebar}
              onUpdate={onUpdate}
            />
          )
        )}
      </SlideSideBar>
      {children}
    </CreateEditTaskSidebarProviderContext.Provider>
  );
};
