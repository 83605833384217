import { useStoreActions, useStoreState } from "easy-peasy";

import useGymflowModels from "../store";

/**
 * @deprecated
 */
const useFacilities = () => {
  const { facilityStore } = useGymflowModels();
  const { fetchList, fetchCategories } = useStoreActions(facilityStore);
  const { page, pageCount, editing, rows, loadingList, loadingRecord } =
    useStoreState(facilityStore);

  return {
    fetchList,
    fetchCategories,
    page,
    pageCount,
    editing,
    rows,
    loadingRecord,
    loadingList,
  };
};

export default useFacilities;
