import { useCallback } from "react";
import { ServiceStatus } from "@gymflow/common";

import useGymflowModels from "../store";

/**
 * @deprecated useCreditPackAsPublic instead
 */
export const useSiteCreditPacks = () => {
  const { CreditPackStore } = useGymflowModels();
  const fetchList = CreditPackStore.useStoreActions((actions) => actions.fetchList);

  const creditPackMap = ({
    id,
    name,
    description,
    sessionsIncluded,
    sessionsUnlimited,
    price,
    currency,
    expiryType,
    expiryValue,
    activityCategoryIdList,
    termsConditions,
  }) => ({
    id,
    name,
    description,
    sessionsIncluded,
    sessionsUnlimited,
    price,
    currency,
    activityCategoryIdList,
    expiryAmount: expiryValue,
    expiryInterval: expiryType,
    termsConditions,
  });

  const creditPacks = CreditPackStore.useStoreState((state) => state.rows.map(creditPackMap));

  const fetchActiveCreditPacks = useCallback(
    (params) => fetchList({ status: ServiceStatus.Active, sort: { field: "price", asc: true }, ...params }),
    [fetchList],
  );

  return { fetchList: fetchActiveCreditPacks, creditPacks, creditPackMap };
};
