import { QueryKey } from "@tanstack/react-query";

export const leadQueryKeys = {
  all: () => ["lead"] as QueryKey,
  list: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [...leadQueryKeys.all(), "list", { filters }] as QueryKey;
    }
    return [...leadQueryKeys.all(), "list"] as QueryKey;
  },
  listCount: (filters?: Readonly<{ [k: string]: any }>) => {
    return [...leadQueryKeys.list(), "count", { filters }] as QueryKey;
  },
  completeList: (filters: Readonly<{ [k: string]: any }>) =>
    [...leadQueryKeys.all(), "completeList", { filters }] as QueryKey,
  details: (leadId?: number) => [...leadQueryKeys.all(), leadId] as QueryKey,
  leadStatus: (filters: Readonly<{ [k: string]: any }>) =>
    [...leadQueryKeys.all(), "status", { filters }] as QueryKey,
};
