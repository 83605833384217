import "react-multi-carousel/lib/styles.css";

import { useQueryClubFeatureFlagsAsPublic } from "@gymflow/api";
import { AlertProvider, theme } from "@gymflow/common";
import { useCallback, useContext, useRef } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import CustomStyleContext from "../../components/CustomStyle";
import { useEmbedLoadingStyleEnd } from "../../hooks/useEmbedLoadingStyle";
import useGoogleTagManager from "../../hooks/useGoogleTagManager";
import useHostedPagesStyle from "../../hooks/useHostedPagesStyle";
import useLoadClubSettings from "../../hooks/useLoadClubSettings";
import { useSiteInjectCss } from "../../hooks/useSiteInjectCss";
import {
  ApiResolverProvider,
  AuthenticationSupportedProvider,
  ClubSettingsAsPublicProvider,
  ClubSettingsContext,
  ModalProvider,
  useApiResolverContext,
  YupExtensions,
} from "../../providers";
import { generateSiteRoutes, generateSiteWithAuthRoutes } from "../../routes";
import useGymflowModels from "../../store";
import { EmbedWithAuth } from "./EmbedWithAuth";

function Embed() {
  const mainPanelRef = useRef(null);
  const settings = useContext(ClubSettingsContext);
  const match = useRouteMatch();

  const setRef = useCallback((node) => {
    mainPanelRef.current = node;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const message = {
          height: entry.contentRect.height,
        };
        if (document.contains(entry.target)) {
          window.top.postMessage(message, "*");
        } else {
          resizeObserver.unobserve(entry.target);
        }
      }
    });

    let trackHeightElement = document.querySelector(".track-height");
    if (trackHeightElement) {
      resizeObserver.observe(trackHeightElement);
    }

    const mutationObserver = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        for (const added of mutation.addedNodes) {
          if (added.classList.contains("track-height")) {
            resizeObserver.observe(added);
          }
        }
      }
    });

    const mainPanelElement = document.querySelector(".main-panel");
    mutationObserver.observe(mainPanelElement, { childList: true });
  }, []);

  const clubId = settings.clubId;
  const { customStyle, hostedStylesLoaded } = useHostedPagesStyle({
    clubId,
    useInjectCss: useSiteInjectCss,
  });

  const clubSettingsLoaded = useLoadClubSettings({ clubId });
  const { api } = useGymflowModels();
  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId,
  });

  const getRoutes = (routes) => {
    const nodes = routes.map((prop) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      return (
        <Route path={match.path + prop.path} key={prop.path}>
          {(props) => <prop.component {...props} />}
        </Route>
      );
    });
    return nodes;
  };

  useGoogleTagManager();
  useEmbedLoadingStyleEnd({
    condition: hostedStylesLoaded && clubSettingsLoaded,
  });
  if (!hostedStylesLoaded || !clubSettingsLoaded) {
    return null;
  }

  return (
    <div className="wrapper site-content w-full">
      <ThemeProvider theme={theme}>
        <CustomStyleContext.Provider value={customStyle}>
          <AlertProvider>
            <div
              className="main-panel flex h-full w-full flex-col overflow-y-auto overflow-x-hidden"
              ref={setRef}
            >
              <Switch>{getRoutes(generateSiteRoutes(featureFlags))}</Switch>
            </div>
          </AlertProvider>
        </CustomStyleContext.Provider>
      </ThemeProvider>
    </div>
  );
}

function EmbedWithStore({ ...props }) {
  const { clubId } = useApiResolverContext();
  const { PublicHostedClubStore } = useGymflowModels();

  return (
    <ClubSettingsAsPublicProvider clubId={clubId}>
      <PublicHostedClubStore.Provider>
        <YupExtensions>
          <Embed {...props} />
        </YupExtensions>
      </PublicHostedClubStore.Provider>
    </ClubSettingsAsPublicProvider>
  );
}

function EmbedWithApiResolver(props) {
  const { clubId, authController } = useApiResolverContext();
  const match = useRouteMatch();
  const location = useLocation();
  const { api } = useGymflowModels();
  const { data: featureFlags } = useQueryClubFeatureFlagsAsPublic({
    api,
    clubId: clubId,
  });
  const routesWithAuth = generateSiteWithAuthRoutes(featureFlags);
  const isRouteWithAuth = routesWithAuth.some((r) =>
    location.pathname.startsWith(match.url + r.path),
  );
  return isRouteWithAuth ? (
    <AuthenticationSupportedProvider authController={authController}>
      <ClubSettingsAsPublicProvider clubId={clubId}>
        <ModalProvider>
          <EmbedWithAuth />
        </ModalProvider>
      </ClubSettingsAsPublicProvider>
    </AuthenticationSupportedProvider>
  ) : (
    <EmbedWithStore {...props} />
  );
}

function EmbedWithClub(props) {
  return (
    <ApiResolverProvider userType="PUBLIC">
      <EmbedWithApiResolver {...props} />
    </ApiResolverProvider>
  );
}

export default EmbedWithClub;
