import { RuleGroup } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { ruleAsPublicQueryKeys } from "./ruleAsPublicQueryKeys";

type RulesApiType = {
  public: {
    ruleApi: {
      getRulesFor: (params: RulesPayloadType) => Promise<any>;
    };
  };
};
type RulesPayloadType =
  | {
      activityId: number;
    }
  | {
      appointableId: number;
    }
  | {
      membershipId: number;
    };
  
export const rulesAsPublicFn = async (
  api: RulesApiType,
  params: RulesPayloadType,
) => {
  return (await api.public.ruleApi.getRulesFor(params)).data as RuleGroup;
};

export function useQueryRulesAsPublic({
  api,
  params,
}: {
  api: RulesApiType;
  params: RulesPayloadType;
}) {
  const result = useQuery({
    queryKey: ruleAsPublicQueryKeys.rules(params),
    queryFn: async () => {
      return (await api.public.ruleApi.getRulesFor(params)).data as RuleGroup;
    },
  });

  return result;
}
