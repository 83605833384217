import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";

const badgeVariants = cva(
  "inline-flex w-fit items-center rounded-md px-2 py-1 text-xs font-bold ring-1 ring-inset",
  {
    variants: {
      intent: {
        success: "bg-success-50 text-success-500 ring-success-500",
        warning: "bg-warning-50 text-warning-500 ring-warning-500",
        error: "bg-error-50 text-error-500 ring-error-500",
        primary: "bg-primary-50 text-primary-500 ring-primary-500",
        secondary: "bg-secondary-50 text-secondary-500 ring-secondary-500",
        default:
          "dark:bg-darkGray-700 dark:text-gray-400 dark:ring-darkGray-700 bg-gray-50 text-gray-950 ring-gray-300",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  },
);

export interface BadgeProps
  extends Omit<React.HTMLProps<HTMLSpanElement>, "type">,
    VariantProps<typeof badgeVariants> {}

export function Badge({ children, className, intent, ...props }: BadgeProps) {
  return (
    <span className={cn(badgeVariants({ intent }), className)} {...props}>
      {children}
    </span>
  );
}
