import { twMerge } from "tailwind-merge";

export function SquarishAvatar({
  pictureUrl,
  className,
}: {
  pictureUrl: string;
  className?: string;
}) {
  return (
    <div
      className={twMerge(
        "flex h-11 w-11 items-center justify-center overflow-hidden rounded",
        className,
      )}
    >
      <img src={pictureUrl} alt="avatar" />
    </div>
  );
}
