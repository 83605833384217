import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicHostedClubApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicHostedClub}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, {
    findById(id) {
      assert(
        check.any([check.string(id), check.number(id)]),
        "id must be number or string",
      );
      return state.http.get(`${state.baseUrl}/settings`);
    },
    invitationRequest({ email, invitationLinkPath }) {
      return state.http.put(
        `${state.baseUrl}/invitation-request/${email}`,
        undefined,
        {
          params: {
            invitationLinkPath,
          },
        },
      );
    },
    invitationStatus({ invitationToken }) {
      return state.http.get(`${state.baseUrl}/invitation-process/status`, {
        params: {
          invitationToken,
        },
      });
    },
    invitationNewPassword({ invitationToken, newPassword }) {
      return state.http.put(
        `${state.baseUrl}/invitation-process/new-password`,
        {
          invitationToken,
          newPassword,
        },
      );
    },
    invitationCardPaymentMethod({ invitationToken, paymentMethodId }) {
      return state.http.put(
        `${state.baseUrl}/invitation-process/card-payment-method`,
        {
          invitationToken,
          paymentMethodId,
        },
      );
    },
  });
};

export default publicHostedClubApi;
