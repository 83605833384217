import { z } from "zod";

export const FacilityZodSchema = z.object({
  name: z
    .string()
    .min(3, "Must be at least 3 characters")
    .max(100, "Must be shorter than 100 characters")
    .default(""),
  description: z
    .string()
    .max(500, "Must be shorter than 500  characters")
    .optional(),
  "facility-category-id": z.number(),
  picture: z.object({ blob: z.string() }).optional(),
});
