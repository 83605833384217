import { QueryKey } from "@tanstack/react-query";

export const membershipAsPublicQueryKeys = {
  all: () => ["membership-pack-as-public"] as const,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...membershipAsPublicQueryKeys.all(), { filters }] as QueryKey,
  summary: (params?: Readonly<{ [k: string]: any }>) =>
    [...membershipAsPublicQueryKeys.all(), "summary", params] as QueryKey,
  details: (id?: number) =>
    [...membershipAsPublicQueryKeys.all(), id] as QueryKey,
};
