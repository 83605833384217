import * as Yup from 'yup';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT } from '@gymflow/common';

export const NAME = 'name';
export const NOTES = 'notes';
export const TASK_RELATED_USERS = 'task-related-users';
export const TASK_RELATED_LEADS = 'task-related-leads';
export const DEADLINE_DATE = 'deadline-date';
export const END_DATE = 'end-date';
export const DEADLINE_TIME = 'deadline-time';
export const TASK_OWNERS = 'task-owners';
export const COMPLETE = 'complete';

export const defaultStart = moment().add(1, 'hour').startOf('hour');

export const TaskSchema = Yup.object().shape({
  [NAME]: Yup.string().required().max(50),
  [NOTES]: Yup.string(),
  [DEADLINE_DATE]: Yup.string()
    .required()
    .default(defaultStart.format(DATE_FORMAT))
    .test('is-not-in-the-past', 'Date too small.', (value) =>
      moment(value, DATE_FORMAT).isSameOrAfter(moment().subtract(1, 'year').startOf('day'))
    )
    .strict(true),
  [DEADLINE_TIME]: Yup.string()
    .required()
    .test('is-not-valid', 'Time is invalid', (value, ctx) => {
      return !isEmpty(ctx.parent[DEADLINE_DATE]) && moment(value, 'h:mm a', true).isValid();
    })
    .default(defaultStart.format('h:mm a'))
    .strict(true),
  [TASK_RELATED_USERS]: Yup.array().default([]),
  [TASK_RELATED_LEADS]: Yup.array().default([]),
  [TASK_OWNERS]: Yup.array().default([]),
  [COMPLETE]: Yup.boolean().default(false),
});

export default TaskSchema;
