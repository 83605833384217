import { QueryClientError } from "@gymflow/api";
import { Query, QueryCache, QueryClient } from "@tanstack/react-query";

import { forgetRouteFn } from "../hooks/useRememberMe";

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
    queryCache: new QueryCache({
      onError: (err: unknown, query: Query) => {
        const errorCode = query.meta?.["errCode"] as QueryClientError;
        if (errorCode === "BOOTSTRAP") {
          forgetRouteFn();
        }
      },
    }),
  });
}
