import {
  CardBodyWithSpinner,
  formikHelpers,
  FormMapper,
  renderErrorRowOnTouch,
  RequiredFieldsLabel,
  useProgress,
  useRecordForm,
} from "@gymflow/common";
import { useClubSettings } from "apps/portal/src/providers";
import classNames from "classnames";
import { Formik } from "formik";
import { useCallback, useContext, useEffect } from "react";
import Select from "react-select";
import {
  Card,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";

import { ToastContext } from "../../../providers/ToastProvider/context";
import useGymflowModels from "../../../store";
import ColorInput from "../../forms/ColorInput";
import ImageUploadButton from "../../forms/ImageUploadButton";
import ProgressButton from "../../ProgressButton/ProgressButton";
import {
  ACCENT_COLOR,
  BACKGROUND_COLOR,
  CARD_COLOR,
  createCustomizeSchema,
  FONT,
  FONT_COLOR,
  LOGO,
  NAVBAR_COLOR,
  PRIMARY_COLOR,
  SHADOW_COLOR,
} from "./CustomizeSchema";

const mapper = new FormMapper({
  inValue: [
    {
      key: "logo",
      transform: (logo) => ({ name: logo }),
    },
  ],
});

function Customize() {
  const { HostedStore } = useGymflowModels();
  const { notifyDanger } = useContext(ToastContext);
  const { loadingRecord: isLoadingClub, editing } = HostedStore.useStoreState(
    (state) => state,
  );
  const {
    fetchById: fetchRecord,
    create: createRecord,
    update: updateRecord,
  } = HostedStore.useStoreActions((actions) => actions);
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const {
    notLoading,
    isDisabled,
    transitionTimeout,
    currentState,
    run,
    isLoading,
  } = useProgress({
    isLoading: isLoadingClub,
  });
  const fetchSettings = useCallback(async () => {
    try {
      await fetchRecord(clubId);
      // eslint-disable-next-line no-empty
    } catch {}
    notLoading();
  }, [fetchRecord, notLoading, clubId]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const { initialValues, getValues } = useRecordForm({
    record: editing && editing.settings,
    fields: createCustomizeSchema().default(),
    mapper,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h2">
          <div>Branding</div>
          <div className="text-muted">
            Customise the branding of the customer portal, web links and
            embedded forms.
          </div>
        </CardTitle>
      </CardHeader>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={createCustomizeSchema()}
        onSubmit={async (values) => {
          try {
            await run(() => {
              if (editing) {
                updateRecord(getValues(values));
              } else {
                createRecord(getValues(values));
              }
            });
          } catch (e) {
            return notifyDanger(e);
          }
        }}
      >
        {(formikProps) => {
          const { handleSubmit, values, setFieldValue, errors, touched } =
            formikProps;
          const { errorClass, submitDisabled } = formikHelpers(formikProps);

          return (
            <>
              <CardBodyWithSpinner isLoading={isLoading}>
                <Form className="form-horizontal" role="form">
                  <Row>
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={LOGO}
                      >
                        Logo *
                      </Label>
                      <div className="font-size-sm">
                        Click to upload or change logo, must not be bigger than
                        1500x700 pixels and 2mb in size
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <ImageUploadButton
                        className="w-100"
                        previewImage={values[LOGO]?.blob || values[LOGO]?.name}
                        onChange={(v) => setFieldValue(LOGO, v)}
                        disabled={isLoading}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={FONT}
                      >
                        Font *
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup
                        className={classNames("w-100", errorClass(FONT))}
                      >
                        <div style={{ padding: "5px" }}>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            value={{ value: values[FONT], label: values[FONT] }}
                            onChange={(v) => setFieldValue(FONT, v.value)}
                            options={[
                              {
                                value: "Manrope",
                                label: "Manrope",
                              },
                              {
                                value: "LeagueSpartan",
                                label: "LeagueSpartan",
                              },
                              { value: "Poppins", label: "Poppins" },
                              { value: "Potta One", label: "Potta One" },
                              { value: "Raleway", label: "Raleway" },
                              {
                                value: "Circular Std Bold",
                                label: "Circular Std Bold",
                              },
                              { value: "Montserrat", label: "Montserrat" },
                              {
                                value: "Futura Regular",
                                label: "Futura Regular",
                              },
                              { value: "Futura Hv Bt", label: "Futura Hv Bt" },
                              {
                                value: "Gotham Condensed",
                                label: "Gotham Condensed",
                              },
                              { value: "Nunito Sans", label: "Nunito Sans" },
                              { value: "Barlow", label: "Barlow" },
                              { value: "NTR", label: "NTR" },
                            ]}
                            isDisabled={isLoading}
                          />
                        </div>

                        {renderErrorRowOnTouch(FONT, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={PRIMARY_COLOR}
                      >
                        Primary color *
                      </Label>
                      <div className="font-size-sm">
                        Used for links and buttons
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(PRIMARY_COLOR)}>
                        <ColorInput
                          value={values[PRIMARY_COLOR]}
                          onChange={(v) => setFieldValue(PRIMARY_COLOR, v)}
                          disabled={isLoading}
                        />

                        {renderErrorRowOnTouch(PRIMARY_COLOR, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={ACCENT_COLOR}
                      >
                        Secondary color *
                      </Label>
                      <div className="font-size-sm">
                        Used for buttons hover and accents
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(ACCENT_COLOR)}>
                        <ColorInput
                          value={values[ACCENT_COLOR]}
                          onChange={(v) => setFieldValue(ACCENT_COLOR, v)}
                          disabled={isLoading}
                        />

                        {renderErrorRowOnTouch(ACCENT_COLOR, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={BACKGROUND_COLOR}
                      >
                        Background color *
                      </Label>
                      <div className="font-size-sm">
                        For backgrounds of all pages and embedded forms
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(BACKGROUND_COLOR)}>
                        <ColorInput
                          value={values[BACKGROUND_COLOR]}
                          onChange={(v) => setFieldValue(BACKGROUND_COLOR, v)}
                          disabled={isLoading}
                        />
                        {renderErrorRowOnTouch(
                          BACKGROUND_COLOR,
                          touched,
                          errors,
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={CARD_COLOR}
                      >
                        Card color *
                      </Label>
                      <div className="font-size-sm">
                        All elements that sit on top of backgrounds
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(CARD_COLOR)}>
                        <ColorInput
                          value={values[CARD_COLOR]}
                          onChange={(v) => setFieldValue(CARD_COLOR, v)}
                          disabled={isLoading}
                        />

                        {renderErrorRowOnTouch(CARD_COLOR, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={FONT_COLOR}
                      >
                        Font color *
                      </Label>
                      <div className="font-size-sm">
                        Must have high contrast against card colour
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(FONT_COLOR)}>
                        <ColorInput
                          value={values[FONT_COLOR]}
                          onChange={(v) => setFieldValue(FONT_COLOR, v)}
                          disabled={isLoading}
                        />
                        {renderErrorRowOnTouch(FONT_COLOR, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={SHADOW_COLOR}
                      >
                        Shadow color *
                      </Label>
                      <div className="font-size-sm">
                        Gives depth of field to card elements
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(SHADOW_COLOR)}>
                        <ColorInput
                          value={values[SHADOW_COLOR]}
                          onChange={(v) => setFieldValue(SHADOW_COLOR, v)}
                          disabled={isLoading}
                        />

                        {renderErrorRowOnTouch(SHADOW_COLOR, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Label
                        tag="h3"
                        className="text-muted text-uppercase"
                        htmlFor={NAVBAR_COLOR}
                      >
                        Menu background color *
                      </Label>
                      <div className="font-size-sm">
                        Background color of menu in the customer portal
                      </div>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <FormGroup className={errorClass(NAVBAR_COLOR)}>
                        <ColorInput
                          value={values[NAVBAR_COLOR]}
                          onChange={(v) => setFieldValue(NAVBAR_COLOR, v)}
                          disabled={isLoading}
                        />
                        {renderErrorRowOnTouch(NAVBAR_COLOR, touched, errors)}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBodyWithSpinner>
              <CardFooter>
                <Form className="form-horizontal">
                  <RequiredFieldsLabel />
                  <ProgressButton
                    onClick={handleSubmit}
                    state={currentState}
                    successText="Saved"
                    className="btn-fill pull-right"
                    color="primary"
                    size="sm"
                    disabled={submitDisabled || isDisabled || isLoading}
                    timeout={transitionTimeout}
                    data-testid="save-club"
                  >
                    Save
                  </ProgressButton>
                </Form>
              </CardFooter>
            </>
          );
        }}
      </Formik>
    </Card>
  );
}

function CustomizeWithProvider(props) {
  const { HostedStore } = useGymflowModels();
  return (
    <HostedStore.Provider>
      <Customize {...props} />
    </HostedStore.Provider>
  );
}

export default CustomizeWithProvider;
