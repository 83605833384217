import {
  EventStatisticField,
  GlobalStatisticDTO,
  GlobalStatisticsCategories,
  GlobalStatisticTrendPeriod,
  LeadStatisticField,
  MembershipStatisticField,
  RevenueStatisticField,
  VisitStatisticField,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { statisticQueryKeys } from "./statisticQueryKeys";

export function useStatistic(
  {
    api,
    clubId,
    start,
    end,
    trendPeriod,
    categories,
    revenueFields,
    membershipFields,
    eventFields,
    leadFields,
    visitFields,
    membershipIncluded,
    membershipExcluded,
  }: {
    api: StatisticApi;
    clubId?: number;
    start?: string;
    end?: string;
    trendPeriod?: GlobalStatisticTrendPeriod;
    categories: GlobalStatisticsCategories[];
    revenueFields?: RevenueStatisticField[];
    membershipFields?: MembershipStatisticField[];
    eventFields?: EventStatisticField[];
    leadFields?: LeadStatisticField[];
    visitFields?: VisitStatisticField[];
    membershipIncluded?: number[];
    membershipExcluded?: number[];
  },
  queryOpts?: UseQueryOptions<GlobalStatisticDTO | null>,
) {
  const result = useQuery({
    queryKey: statisticQueryKeys.statistic({
      clubId,
      start,
      end,
      trendPeriod,
      categories,
      revenueFields,
      membershipFields,
      eventFields,
      leadFields,
      visitFields,
      membershipIncluded,
      membershipExcluded,
    }),
    queryFn: async () => {
      const result = await api.statisticsApi.getData(
        clubId!,
        start!,
        end!,
        trendPeriod,
        categories,
        revenueFields,
        membershipFields,
        eventFields,
        leadFields,
        visitFields,
        membershipIncluded,
        membershipExcluded,
      );
      return result.data;
    },
    enabled:
      !!clubId && !!start && !!end && !!categories && categories.length > 0,
    initialData: null,
    ...queryOpts,
  });

  return result;
}

interface StatisticApi {
  statisticsApi: {
    getData: (
      clubId: number,
      start: string,
      end: string,
      trendPeriod?: GlobalStatisticTrendPeriod,
      categories?: GlobalStatisticsCategories[],
      revenueFields?: RevenueStatisticField[],
      membershipFields?: MembershipStatisticField[],
      eventFields?: EventStatisticField[],
      leadFields?: LeadStatisticField[],
      visitFields?: VisitStatisticField[],
      membershipIncluded?: number[],
      membershipExcluded?: number[],
    ) => Promise<{ data: GlobalStatisticDTO }>;
  };
}
