export async function copyToClipboard(textToCopy: string) {
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    console.error(
      "Copy to clipboard only works with HTTPS! Failed to copy: ",
      textToCopy,
    );
  }
}
