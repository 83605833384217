import { createUserMemberFormSchema as createMemberSchema } from "@gymflow/common";
import { Iso2Country, Iso3Country } from "@gymflow/types";

export const createUserMemberFormSchema = ({
  postalCodeCountry,
  defaultNationality,
  dateFormat,
}: {
  postalCodeCountry: Iso2Country;
  defaultNationality: Iso3Country;
  dateFormat: string;
  requiredFields?: string[];
}) =>
  // TODO: double check why requiredField is not passed through
  // @ts-expect-error
  createMemberSchema({
    postCodeFormat: postalCodeCountry,
    defaultNationality: defaultNationality,
    dateFormat: dateFormat,
  });
