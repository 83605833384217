import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormikInput,
  PARAMETER_DATE_ONLY_FORMAT,
  useRecordForm,
} from "@gymflow/common";
import { cn } from "@gymflow/helpers";
import { PromotionLimitCategory } from "@gymflow/types";
import { useFormik } from "formik";
import moment from "moment-timezone";
import { useContext, useEffect, useMemo } from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { getDefaultsFromZodSchema } from "../../../helpers";
import { Badge, Checkbox, PaginatedSelect, Spinner, Switch } from "../../atoms";
import { DatePicker } from "../../molecules";
import { WizardContext, WizardState } from "../../organisms/StepWizard";
import { PromotionFormMapper } from "./mapper";
import { promotionStep2Schema } from "./schema";
import { useAllRestrictableItems } from "./useAllRestrictableItems";

const mapper = new PromotionFormMapper();

interface Item {
  readonly name: string;
  readonly id: number;
}

export function PromotionWizardStep2() {
  const { setWizardState, setOnValidate, wizardState } =
    useContext(WizardContext);
  const { initialValues, getValues } = useRecordForm({
    mapper,
    fields: getDefaultsFromZodSchema(promotionStep2Schema),
    record: wizardState["limits"] || null,
  });

  const formik = useFormik<{
    limitMembershipList?: Item[];
    limitProductList?: Item[];
    limitSessionPackList?: Item[];
    limitAppointableList?: Item[];
    limitUsagesPerMember?: number;
    limitUsages?: number;
    limitToProductCategory: boolean;
    limitType: "CATEGORIES" | "SPECIFIC_ITEMS";
    limitCategories: PromotionLimitCategory[];
    expiry?: string;
  }>({
    enableReinitialize: false,
    validateOnBlur: true,
    initialValues,
    validationSchema: toFormikValidationSchema(promotionStep2Schema),
    onSubmit: () => {},
  });
  const { values, setFieldValue, submitForm, validateForm } = formik;

  useEffect(() => {
    function updateWizardState() {
      setWizardState((prev: WizardState) => ({
        ...prev,
        limits: getValues(values),
      }));
    }
    updateWizardState();
  }, [getValues, setWizardState, values]);
  useEffect(() => {
    setOnValidate(async () => {
      submitForm();
      const result = await validateForm();
      return Object.keys(result).length === 0;
    });
  }, [setOnValidate, submitForm, validateForm]);

  const { itemOptions, isLoading: isLoadingRestrictableItems } =
    useAllRestrictableItems({
      staleTime: 60000,
      selectedMembershipIdList:
        values["limitMembershipList"]?.map(({ id }) => id) ?? [],
      selectedCreditPackIdList:
        values["limitSessionPackList"]?.map(({ id }) => id) ?? [],
      selectedAppointableIdList:
        values["limitAppointableList"]?.map(({ id }) => id) ?? [],
      selectedProductIdList:
        values["limitProductList"]?.map(({ id }) => id) ?? [],
    });

  const hasPromotionBeenUsed = !!wizardState["hasPromotionBeenUsed"];
  const [parent] = useAutoAnimate();

  const selectedItems = useMemo(() => {
    let selected: any[] = [];
    if (values.limitMembershipList) {
      selected = selected.concat(values.limitMembershipList);
    }

    if (values.limitSessionPackList) {
      selected = selected.concat(values.limitSessionPackList);
    }

    if (values.limitProductList) {
      selected = selected.concat(values.limitProductList);
    }

    if (values.limitProductList) {
      selected = selected.concat(values.limitAppointableList);
    }

    return selected;
  }, [
    values.limitAppointableList,
    values.limitMembershipList,
    values.limitProductList,
    values.limitSessionPackList,
  ]);

  return (
    <div className="flex flex-col gap-8 p-1">
      <div className="flex flex-col gap-6">
        <div className="flex flex-wrap gap-8">
          <div className="w-full sm:w-[calc(50%-2rem)]">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium text-gray-950">
                Maximum Redemptions Allowed
              </div>
              <div className="flex flex-col gap-2">
                <FormikInput
                  name="limitUsages"
                  placeholder="Enter Amount"
                  formikProps={formik}
                />
              </div>
            </div>
          </div>
          <div className="w-full sm:w-[calc(50%-2rem)]">
            <div className="flex flex-col gap-2">
              <div className="text-sm font-medium text-gray-950">
                Maximum Redemptions Per Person
              </div>
              <div className="flex flex-col gap-2">
                <FormikInput
                  name="limitUsagesPerMember"
                  placeholder="Enter Amount"
                  formikProps={formik}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex gap-3">
            <div>
              <Switch
                value={!!values["expiry"]}
                onChange={(checked) => {
                  if (checked) {
                    setFieldValue(
                      "expiry",
                      moment().format(PARAMETER_DATE_ONLY_FORMAT),
                    );
                  } else {
                    setFieldValue("expiry", undefined);
                  }
                }}
              />
            </div>
            <div className="text-sm font-medium text-gray-950">Expiry Date</div>
          </div>
          <div
            className={cn("flex gap-8", {
              invisible: !values["expiry"],
            })}
          >
            <div className="flex-1 sm:max-w-[15rem]">
              <DatePicker
                inputType="field"
                label={
                  values["expiry"] &&
                  moment(values["expiry"], PARAMETER_DATE_ONLY_FORMAT).format(
                    "Do MMM YYYY",
                  )
                }
                mode="single"
                selected={
                  values["expiry"]
                    ? moment(
                        values["expiry"],
                        PARAMETER_DATE_ONLY_FORMAT,
                      ).toDate()
                    : undefined
                }
                handleDateSave={(newDate) => {
                  if (!newDate) {
                    return;
                  }
                  setFieldValue(
                    "expiry",
                    moment(newDate).format(PARAMETER_DATE_ONLY_FORMAT),
                  );
                }}
              />
            </div>
            <div className="hidden flex-1 sm:block " />
          </div>
        </div>
      </div>
      <div className="border-t border-t-gray-300"></div>
      <div className="flex flex-col gap-6" ref={parent}>
        <div className="flex flex-col gap-4">
          <div className="text-base font-medium text-gray-950">
            Limit promotional pricing to selected products and services or
            <br /> categories. Leave this blank to apply a storewide promotion.
          </div>
          <div>
            <ToggleModeButton
              value={
                values["limitType"] === "SPECIFIC_ITEMS" ? "LEFT" : "RIGHT"
              }
              onChange={(newValue) => {
                setFieldValue(
                  "limitType",
                  newValue === "LEFT" ? "SPECIFIC_ITEMS" : "CATEGORIES",
                );
              }}
              isDisabled={hasPromotionBeenUsed}
            />
          </div>
        </div>

        {values["limitType"] === "SPECIFIC_ITEMS" && (
          <div className="flex flex-col gap-4" ref={parent}>
            {isLoadingRestrictableItems && (
              <div>
                <Spinner />
              </div>
            )}
            {!isLoadingRestrictableItems && (
              <div>
                <PaginatedSelect
                  isSearchable
                  value={selectedItems}
                  isMulti
                  closeMenuOnSelect
                  blurInputOnSelect
                  placeholder="Select..."
                  onChange={(newOption) => {
                    const last = newOption[newOption.length - 1];
                    if (!last) {
                      setFieldValue("limitMembershipList", []);
                      setFieldValue("limitSessionPackList", []);
                      setFieldValue("limitProductList", []);
                      setFieldValue("limitAppointableList", []);
                      return;
                    }
                    switch (last.value.type) {
                      case "MEMBERSHIP":
                        {
                          const newValue = [
                            ...(values?.["limitMembershipList"] ?? []),
                            { name: last.label, id: last.value.id },
                          ];
                          setFieldValue("limitMembershipList", newValue);
                        }
                        break;
                      case "CREDIT_PACK":
                        {
                          const newValue = [
                            ...(values?.["limitSessionPackList"] ?? []),
                            { name: last.label, id: last.value.id },
                          ];
                          setFieldValue("limitSessionPackList", newValue);
                        }
                        break;
                      case "PRODUCT":
                        {
                          const newValue = [
                            ...(values?.["limitProductList"] ?? []),
                            { name: last.label, id: last.value.id },
                          ];
                          setFieldValue("limitProductList", newValue);
                        }
                        break;
                      case "APPOINTABLE":
                        {
                          const newValue = [
                            ...(values?.["limitAppointableList"] ?? []),
                            { name: last.label, id: last.value.id },
                          ];
                          setFieldValue("limitAppointableList", newValue);
                        }
                        break;
                    }
                  }}
                  loadOptions={(term) => {
                    const results = itemOptions.filter((item) =>
                      item.label.includes(term),
                    );
                    return Promise.resolve({ options: results });
                  }}
                  cacheUniqs={[itemOptions]}
                  isDisabled={hasPromotionBeenUsed}
                  badgeClearIndicator
                />
              </div>
            )}
            <div className="flex flex-wrap gap-4">
              {values["limitMembershipList"]?.map((membership) => {
                return (
                  <Badge className="flex gap-1" key={membership.id}>
                    {membership.name}
                    <FontAwesomeIcon
                      className={cn("cursor-pointer text-gray-600", {
                        hidden: hasPromotionBeenUsed,
                      })}
                      onClick={() => {
                        const newValue = values["limitMembershipList"] ?? [];
                        setFieldValue(
                          "limitMembershipList",
                          newValue.filter(({ id }) => id !== membership.id),
                        );
                      }}
                      icon={faClose}
                    />
                  </Badge>
                );
              })}
              {values["limitSessionPackList"]?.map((sessionPack) => {
                return (
                  <Badge className="flex gap-1" key={sessionPack.id}>
                    {sessionPack.name}
                    <FontAwesomeIcon
                      className={cn("cursor-pointer text-gray-600", {
                        hidden: hasPromotionBeenUsed,
                      })}
                      onClick={() => {
                        const newValue = values["limitSessionPackList"] ?? [];
                        setFieldValue(
                          "limitSessionPackList",
                          newValue.filter(({ id }) => id !== sessionPack.id),
                        );
                      }}
                      icon={faClose}
                    />
                  </Badge>
                );
              })}
              {values["limitProductList"]?.map((product) => {
                return (
                  <Badge className="flex gap-1" key={product.id}>
                    {product.name}
                    <FontAwesomeIcon
                      className={cn("cursor-pointer text-gray-600", {
                        hidden: hasPromotionBeenUsed,
                      })}
                      onClick={() => {
                        const newValue = values["limitProductList"] ?? [];
                        setFieldValue(
                          "limitProductList",
                          newValue.filter(({ id }) => id !== product.id),
                        );
                      }}
                      icon={faClose}
                    />
                  </Badge>
                );
              })}
              {values["limitAppointableList"]?.map((appointable) => {
                return (
                  <Badge
                    className="cursor-pointer text-gray-600"
                    key={appointable.id}
                  >
                    {appointable.name}
                    <FontAwesomeIcon
                      className={cn("cursor-pointer text-gray-600", {
                        hidden: hasPromotionBeenUsed,
                      })}
                      onClick={() => {
                        const newValue = values["limitAppointableList"] ?? [];
                        setFieldValue(
                          "limitAppointableList",
                          newValue.filter(({ id }) => id !== appointable.id),
                        );
                      }}
                      icon={faClose}
                    />
                  </Badge>
                );
              })}
            </div>
          </div>
        )}
        {values["limitType"] === "CATEGORIES" && (
          <div>
            <div className="flex gap-3">
              <Checkbox
                value={
                  values["limitCategories"].includes("APPOINTABLE") &&
                  values["limitCategories"].includes("MEMBERSHIP") &&
                  values["limitCategories"].includes("PRODUCT") &&
                  values["limitCategories"].includes("SESSION_PACK")
                }
                onChange={(checked) => {
                  if (checked) {
                    setFieldValue("limitCategories", [
                      "APPOINTABLE",
                      "MEMBERSHIP",
                      "PRODUCT",
                      "SESSION_PACK",
                    ]);
                  } else {
                    setFieldValue("limitCategories", []);
                  }
                }}
                isDisabled={hasPromotionBeenUsed}
              />
              <div className="text-base font-medium text-gray-950">
                Select All
              </div>
            </div>
            <div className="my-3 w-[31.25rem] border-t border-t-gray-300"></div>
            <div className="flex flex-col gap-2">
              <div className="flex gap-3">
                <Checkbox
                  value={values["limitCategories"].includes("MEMBERSHIP")}
                  onChange={(checked) => {
                    let newValue = values["limitCategories"].slice(0);
                    if (checked) {
                      newValue.push("MEMBERSHIP");
                    } else {
                      newValue = newValue.filter((v) => v !== "MEMBERSHIP");
                    }
                    setFieldValue("limitCategories", newValue);
                  }}
                  isDisabled={hasPromotionBeenUsed}
                />
                <div className="text-base font-medium text-gray-950">
                  Memberships
                </div>
              </div>
              <div className="flex gap-3">
                <Checkbox
                  value={values["limitCategories"].includes("SESSION_PACK")}
                  onChange={(checked) => {
                    let newValue = values["limitCategories"].slice(0);
                    if (checked) {
                      newValue.push("SESSION_PACK");
                    } else {
                      newValue = newValue.filter((v) => v !== "SESSION_PACK");
                    }
                    setFieldValue("limitCategories", newValue);
                  }}
                  isDisabled={hasPromotionBeenUsed}
                />
                <div className="text-base font-medium text-gray-950">
                  Credit Packs
                </div>
              </div>
              <div className="flex gap-3">
                <Checkbox
                  value={values["limitCategories"].includes("APPOINTABLE")}
                  onChange={(checked) => {
                    let newValue = values["limitCategories"].slice(0);
                    if (checked) {
                      newValue.push("APPOINTABLE");
                    } else {
                      newValue = newValue.filter((v) => v !== "APPOINTABLE");
                    }
                    setFieldValue("limitCategories", newValue);
                  }}
                  isDisabled={hasPromotionBeenUsed}
                />
                <div className="text-base font-medium text-gray-950">
                  Appointments
                </div>
              </div>
              <div className="flex gap-3">
                <Checkbox
                  value={values["limitCategories"].includes("PRODUCT")}
                  onChange={(checked) => {
                    let newValue = values["limitCategories"].slice(0);
                    if (checked) {
                      newValue.push("PRODUCT");
                    } else {
                      newValue = newValue.filter((v) => v !== "PRODUCT");
                    }
                    setFieldValue("limitCategories", newValue);
                  }}
                  isDisabled={hasPromotionBeenUsed}
                />
                <div className="text-base font-medium text-gray-950">
                  Products
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function ToggleModeButton({
  className,
  value,
  onChange,
  isDisabled,
}: {
  className?: string;
  value: "LEFT" | "RIGHT";
  onChange: (v: "LEFT" | "RIGHT") => void;
  isDisabled?: boolean;
}) {
  return (
    <div
      className={cn(
        "relative flex h-11 items-center !rounded-lg border border-gray-300 bg-gray-100 hover:bg-gray-100 max-w-[31.25rem]",
        { "cursor-pointer": !isDisabled },
        { "opacity-50": isDisabled },
        className,
      )}
      onClick={() => {
        if (isDisabled) {
          return;
        }
        onChange(value === "LEFT" ? "RIGHT" : "LEFT");
      }}
    >
      <div
        className={cn(
          "bg-gray-25 absolute bottom-1 top-1 flex w-[calc(50%-0.5rem)] !rounded-lg shadow-sm transition-all duration-500 ease-in-out",
          {
            "left-1": value === "LEFT",
            "left-[calc(50%+.25rem)]": value === "RIGHT",
          },
        )}
      />
      <div className="z-10 flex w-[31.25rem] justify-between">
        <div className="flex h-11 flex-1 items-center justify-center">
          <div className="text-sm font-semibold text-gray-950">
            Products & Services
          </div>
        </div>
        <div className="flex h-11 flex-1 items-center justify-center">
          <div className="text-sm font-semibold text-gray-950">Categories</div>
        </div>
      </div>
    </div>
  );
}
