import { useMutation, useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { sessionPackQueryKeys } from './sessionPackQueryKeys';
import { SessionPackDTO } from '@gymflow/types';

export function useSessionPackCreate({
  api,
}: {
  api: {
    creditPackApi: {
      create: (
        newCreditPack: unknown,
        termsAndConditionsBlob: unknown,
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newSessionPack: { [k: string]: any }) => {
      const response = await api.creditPackApi.create(
        omit(newSessionPack, 'termsConditions'),
        newSessionPack['termsConditions'],
      );
      return response.data as SessionPackDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: sessionPackQueryKeys.all() });
    },
  });

  return mutation;
}
