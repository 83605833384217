import { useMemo } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

export const useFilterParams = () => {
  const location = useLocation();

  const filters = useMemo(() => {
    const result = {};
    const queryParams = qs.parse(location.search.slice(1));
    if (queryParams.memberships) {
      result.id = queryParams.memberships;
    }

    if (queryParams.sessionPacks) {
      result.id = queryParams.sessionPacks;
    }

    if (queryParams.type) {
      result.type = queryParams.type;
    }

    if (queryParams.trial) {
      result.isTrial = queryParams.trial;
    }

    if (queryParams.facility) {
      result['event.facility.id'] = queryParams.facility;
    }

    if (queryParams.host) {
      result['event.userEventHost.id'] = queryParams.host;
    }

    if (queryParams.activity) {
      result['event.activity.id'] = queryParams.activity;
    }

    if (queryParams.activityCategory) {
      result['event.activity.activityCategory.id'] = queryParams.activityCategory;
    }

    return result;
  }, [location.search]);

  return { filters: filters || {} };
};
