import { AvailabilityOverrideDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityOverride(
  {
    api,
    availabilityOverrideId,
    staffId,
  }: {
    api: {
      availabilityApi: {
        hostOverride: (
          staffId: string,
          overrideId: number,
        ) => Promise<{ data: AvailabilityOverrideDTO }>;
      };
    };
    availabilityOverrideId: number | null;
    staffId: string | null;
  },
  opts?: UseQueryOptions<AvailabilityOverrideDTO | null>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.hostOverride(
      staffId as string,
      availabilityOverrideId as number,
    ),
    queryFn: async () => {
      const result = await api.availabilityApi.hostOverride(
        staffId as string,
        availabilityOverrideId as number,
      );
      return result.data;
    },
    enabled: !!availabilityOverrideId && !!staffId,
    initialData: null,
    ...opts,
  });

  return result;
}
