import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import { getMembershipPrice, ServiceStatus, ServiceType } from '@gymflow/common';
import { formatCurrency } from '@gymflow/helpers';

import FilterableList from '../../../forms/FilterableList';
import useMemberships from '../../../../hooks/useMemberships';
import useFilterableListWithGroups from '../../../../hooks/useFilterableListWithGroups';
import useGymflowModels from '../../../../store';

function MembershipFilterableList({ value, onChange, onChangeLoading }) {
  const { settingsStore } = useGymflowModels();
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const { fetchList: fetchMemberships, rows: memberships, loadingList: isLoading } = useMemberships();

  useEffect(() => {
    onChangeLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    fetchMemberships({
      status: ServiceStatus.Active,
      extraParams: { unpaged: true, isPublic: true },
    });
  }, []);
  const groups = useMemo(
    () => [
      {
        id: ServiceType.Recurring,
        name: 'Recurring Memberships',
      },
      {
        id: ServiceType.Prepaid,
        name: 'Prepaid Memberships',
      },
    ],
    []
  );

  const options = useMemo(
    () =>
      memberships.map((membership) => ({
        ...membership,
        name: `${membership.name} ${formatCurrency(getMembershipPrice(membership), currency)}`,
      })),
    [memberships]
  );

  const { groupedOptions, selection, setSelection } = useFilterableListWithGroups({
    groups,
    options,
    groupIdField: 'id',
    optionGroupField: 'type',
    onChange,
    initialSelection: value,
  });

  return <FilterableList options={groupedOptions} value={selection} onChange={setSelection} />;
}

MembershipFilterableList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeLoading: PropTypes.func.isRequired,
};

export default MembershipFilterableList;
