import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { zonedTimeToUtc } from "@gymflow/helpers";
import { MembershipType } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMembershipPausingReportCsv({
  api,
  tz,
}: {
  api: ApiType;
  membership?: number[];
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
      membershipIncluded,
      membershipExcluded,
      membershipType,
    }: {
      startDate: string;
      endDate: string;
      membershipIncluded?: number[];
      membershipExcluded?: number[];
      membershipType?: MembershipType;
    }) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.membershipPausingCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
        membershipIncluded,
        membershipExcluded,
        membershipType,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
