import endpoints from "./endpoints";
import { canTrimFields } from "./features/apiResource";
import canSignUp from "./features/canSignUp";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const userMemberEndpoints = {
  emailExists: "hosted/catalog/user/email/check",
  createWithRsvp: `${endpoints.publicUserMember}/signwithrsvp`,
};

const publicUserMemberApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [
      "firstName",
      "lastName",
      "mobileNumber",
      "dateBirth",
      "postCode",
      "addressLine1",
      "addressLine2",
      "city",
      "email",
      "emergencyContact",
      "emergencyContactName",
      "username",
    ],
    baseUrl: endpoints.publicUserMember,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, canTrimFields(state), canSignUp(state), {
    create(newResource) {
      const trimmedData = state.trimFields(newResource);

      return state.http.post(
        `${clubPrefix}${endpoints.publicUserMember}`,
        state.createRequestBody(trimmedData),
      );
    },

    createAndRsvp(newResource, occurrenceId) {
      const trimmedData = state.trimFields(newResource);

      return state.http.post(
        `${clubPrefix}${userMemberEndpoints.createWithRsvp}`,
        {
          userMemberPostBean: state.createRequestBody(trimmedData),
          occurrenceId,
        },
      );
    },

    emailExists(email) {
      return state.http.get(`${clubPrefix}${userMemberEndpoints.emailExists}`, {
        params: { q: email.toLowerCase() },
      });
    },
  });
};

export default publicUserMemberApi;
