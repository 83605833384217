import { QueryKey } from "@tanstack/react-query";

export const appointableQueryKeys = {
  all: () => ["appointable"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...appointableQueryKeys.all(), { filters }] as QueryKey,
  details: (appointableId: number | null) =>
    [...appointableQueryKeys.all(), appointableId] as QueryKey,
  categoriesAll: () =>
    [...appointableQueryKeys.all(), "categories"] as QueryKey,
  categories: (filters: Readonly<{ [k: string]: any }>) =>
    [...appointableQueryKeys.all(), "categories", { filters }] as QueryKey,
};
