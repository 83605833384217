import memoize from 'lodash/memoize';
import { useMemo } from 'react';
import { assert } from 'check-types';
import { generateId as generateStringId } from '@gymflow/helpers';

/**
 * @typedef {function} generateElementId
 * @param {Object} [opts]
 * @param {string|number} opts.key
 * @param {string} [opts.prefix=div-]
 * @return {string}
 */

/**
 * Returns a memoized function that generates unique string ids. The function
 * takes in an object as a parameter that contains a key and prefix. If the
 * key is the same on subsequent calls the returned id will be
 * the same (the prefix might still be different).
 *
 * @returns {generateElementId}
 */
const useGenerateElementId = () => {
  return useMemo(() => {
    const generateId = memoize(() => {
      return generateStringId();
    });
    return (opts) => {
      assert.maybe.object(opts);
      const options = {
        ...opts,
      };
      options.prefix = options.prefix ?? 'div-';
      return options.prefix + generateId(options.key);
    };
  }, []);
};

export default useGenerateElementId;
