import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImagePreview from '../../components/forms/ImagePreview';

const maxWidth = 2500;
const maxHeight = 700;
const SUPPORTED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'webp'];

const ImageUploadButton = ({ previewImage, onChange, disabled, className }) => {
  const [error, setError] = useState(null);

  const fileRef = createRef();

  const readFile = (file) => {
    const validExtension = SUPPORTED_EXTENSIONS.some((extension) => file.name.endsWith(extension));
    if (!validExtension) {
      setError(
        `File format is not supported. Try one of the following extensions: (${SUPPORTED_EXTENSIONS.join(', ')}).`
      );
      return;
    }
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const img = new Image();
      img.addEventListener('load', () => {
        if (img.width > maxWidth || img.height > maxHeight) {
          setError(`Image should not be bigger than ${maxWidth} by ${maxHeight} pixels.`);
          return;
        } else if (img.width < 100 || img.height < 100) {
          setError('Image cannot have less than 100 pixels of width or height.');
          return;
        }
        setError(null);
        onChange({ name: file.name, blob: new Blob([file]) });
      });
      img.addEventListener('error', (e) => {
        setError('Image could not be loaded. Make sure you selected a valid picture.');
      });
      img.src = reader.result;
    });
    reader.readAsDataURL(file);
  };

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      readFile(e.target.files[0]);
    }
  };

  return (
    <div className={className}>
      <div
        className="w-100 d-flex justify-content-center align-items-center cursor-pointer"
        style={{ minHeight: 42, background: '#fff', borderRadius: '5px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)' }}
        onClick={() => fileRef.current.click()}
        disabled={disabled}
      >
        {previewImage ? <ImagePreview image={previewImage} /> : 'Upload Image!'}
      </div>
      <input type="file" className="d-none" onChange={onSelectFile} accept="image/*" ref={fileRef} />
      {error}
    </div>
  );
};

ImageUploadButton.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default ImageUploadButton;
