import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNoteCreateForAppointment, useNoteDelete } from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { NoteAppointmentDTO } from "@gymflow/types";
import classNames from "classnames";
import compareDesc from "date-fns/compareDesc";
import moment from "moment-timezone";
import { useCallback, useContext, useState } from "react";

import { ToastContext } from "../../../providers/ToastProvider/context";
import useGymflowModels from "../../../store";
import {
  Badge,
  Button,
  TextAreaInput,
  TextButton,
  UploadArea,
} from "../../atoms";
import { uploadAreaDocumentLoadHandler } from "../../UserMember/Notes/NoteFormSideBar";
import { NoteFormSideBarProviderContext } from "../../UserMember/Notes/NoteFormSideBarProvider";

export function AppointmentNotes({
  appointmentId,
  noteList,
  setIsTabOpen,
}: {
  appointmentId: number;
  noteList: NoteAppointmentDTO[];
  setIsTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { api } = useGymflowModels();
  const sortedNoteList = noteList.sort((a, b) =>
    compareDesc(new Date(a.createdDate), new Date(b.createdDate)),
  );

  const deleteNote = useNoteDelete({ api });
  const [isNewNoteOpen, setIsNewNoteOpen] = useState(noteList.length === 0);

  const { triggerEditNote } = useContext(NoteFormSideBarProviderContext);
  return (
    <div className="mx-8 flex flex-col gap-2">
      {isNewNoteOpen ? (
        <NewNoteForm
          appointmentId={appointmentId}
          onSubmit={() => {
            setIsNewNoteOpen(false);
          }}
        />
      ) : (
        <div className="flex justify-end">
          <Button onClick={() => setIsNewNoteOpen(true)}>Add Note</Button>
        </div>
      )}
      <div className="flex-column flex gap-2">
        {sortedNoteList.map((note) => {
          return (
            <div className="flex-column my-2 flex gap-2" key={note.createdDate}>
              <div className="text-sm font-normal text-gray-400">
                Created{" "}
                {moment(
                  note.createdDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format("MMMM Do")}{" "}
                @
                {moment(
                  note.createdDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format("h:mm a")}{" "}
                by {note.createdByFirstName} {note.createdByLastName}
              </div>
              <div className="whitespace-pre-wrap rounded border border-gray-200 p-4 text-sm font-normal text-gray-900">
                {note.content}
              </div>
              <div className={classNames({ hidden: !note.attachmentFilename })}>
                {note.attachment && (
                  <Badge>
                    <a href={note.attachment} target="_blank" rel="noreferrer">
                      {note.attachmentFilename}
                    </a>
                  </Badge>
                )}
              </div>
              <div className="flex flex-row-reverse">
                <TextButton
                  className="!py-0"
                  onClick={() => {
                    setIsTabOpen(false);
                    triggerEditNote(
                      {
                        name: `${note.createdByFirstName} ${note.createdByLastName}`,
                        appointmentId,
                      },
                      note.noteId,
                      async () => {
                        setIsTabOpen(true);
                      },
                    );
                  }}
                >
                  Edit
                </TextButton>
                <TextButton
                  className="!py-0"
                  onClick={async () => {
                    await deleteNote.mutateAsync({ noteId: note.noteId });
                  }}
                >
                  Delete
                </TextButton>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function NewNoteForm({
  appointmentId,
  onSubmit,
}: {
  appointmentId: number;
  onSubmit: () => void;
}) {
  const { api } = useGymflowModels();
  const [noteText, setNoteText] = useState("");
  const [noteFile, setNoteFile] = useState<{
    name: string;
    blob: string | ArrayBuffer;
    file: File;
  } | null>(null);

  const { notifyDanger, toast } = useContext(ToastContext);

  const errorHandler = useCallback(
    (e: any) => {
      notifyDanger(e);
    },
    [notifyDanger],
  );

  const createNote = useNoteCreateForAppointment(
    { api },
    {
      onError: errorHandler,
    },
  );

  return (
    <>
      <div>
        <TextAreaInput
          placeholder="Write note"
          value={noteText}
          onChange={({ currentTarget: { value } }) => {
            setNoteText(value);
          }}
        />
      </div>
      <div>
        {noteFile ? (
          <Badge>
            {noteFile.name}
            <FontAwesomeIcon
              onClick={() => {
                setNoteFile(null);
              }}
              className="ml-3 cursor-pointer"
              icon={faClose}
            />
          </Badge>
        ) : (
          <UploadArea
            onChange={(file) => {
              if (file.blob) {
                setNoteFile({
                  name: file.name,
                  blob: file.blob,
                  file: file.file,
                });
              }
            }}
            allowedExtensions={[
              "pdf",
              "doc",
              "docx",
              "png",
              "jpeg",
              "jpg",
              "svg",
            ]}
            loadHandler={uploadAreaDocumentLoadHandler}
            description="Documents, PDFs and images only (max 5mb)"
            accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,image/*"
          />
        )}
      </div>
      <div>
        <Button
          onClick={async () => {
            await createNote.mutateAsync({
              fields: { appointmentId, content: noteText },
              file: noteFile?.file || undefined,
            });
            toast({ message: "Note added to appointment." });
            setNoteText("");
            onSubmit();
          }}
          disabled={noteText.length === 0}
        >
          Save
        </Button>
      </div>
    </>
  );
}
