import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cn } from "@gymflow/helpers";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import React, { ReactNode } from "react";

import { Button } from "../../atoms";
import { usePrevNextButtons } from "./EmblaCarouselArrowButtons";

type EmblaCarouselProps = {
  slides: ReactNode[];
  options?: EmblaOptionsType;
};

export const EmblaCarousel: React.FC<EmblaCarouselProps> = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <section className="w-full">
      <div className="overflow-hidden" ref={emblaRef}>
        <div
          style={{
            backfaceVisibility: "hidden",
          }}
          className="-ml-4 flex touch-pan-y touch-pinch-zoom"
        >
          {slides.map((node, index) => (
            <div
              className="flex min-w-0 flex-[0_0_100%] overflow-hidden  pl-4"
              key={index}
            >
              {node}
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-full flex-row items-center justify-between">
        <Button
          className={cn("mt-2", {
            invisible: prevBtnDisabled,
          })}
          onClick={onPrevButtonClick}
          disabled={prevBtnDisabled}
        >
          <FontAwesomeIcon
            className="h-[1.125rem] w-[1.125rem]"
            icon={faArrowLeft}
          />
        </Button>
        <Button
          className={cn("mt-2", {
            invisible: nextBtnDisabled,
          })}
          onClick={onNextButtonClick}
          disabled={nextBtnDisabled}
        >
          <FontAwesomeIcon
            className="h-[1.125rem] w-[1.125rem]"
            icon={faArrowRight}
          />
        </Button>
      </div>
    </section>
  );
};
