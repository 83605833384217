import { UserMemberBean, UserMemberSubscriptionBean } from "@gymflow/types";

import { UserMemberMembership } from "./UserMemberMembership/UserMemberMembership";

export function UserMemberAccount({
  user,
  subscriptions,
}: {
  user: UserMemberBean;
  subscriptions: UserMemberSubscriptionBean[];
}) {
  if (!user) {
    return null;
  }
  return (
    <div className="relative -mx-4 flex h-full overflow-y-auto px-4 lg:-mx-8 lg:px-8">
      <UserMemberMembership memberId={user.id} subscriptions={subscriptions} />
    </div>
  );
}
