import { cn } from "@gymflow/helpers";

import { Badge } from "../../Badge";
import { CloseIcon } from "../../icons";

type SelectedOptionBadgeProps = {
  className?: string;
  value: string | number;
  onClick: () => void;
};

export const SelectedOptionBadge = ({
  value,
  onClick,
  className,
}: SelectedOptionBadgeProps) => (
  <Badge
    className={cn(
      "flex gap-0.5 pr-1.5 text-sm font-medium text-gray-950 ring-gray-200",
      className,
    )}
  >
    {value}
    <div className="cursor-pointer" onClick={() => onClick()}>
      <CloseIcon className="h-3 w-3" strokeWidth={3} />
    </div>
  </Badge>
);
