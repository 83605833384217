import { cn } from "@gymflow/helpers";
import { cva, VariantProps } from "class-variance-authority";
import React, { ReactNode } from "react";
import { toast } from "sonner";

import { Button, CheckCircleIcon, CloseIcon } from "../../components/atoms";
import { ExclamationCircleIcon } from "../../components/atoms/icons/ExclamationCircleIcon";

const toastVariants = cva(
  "flex flex-row items-start gap-4 rounded-2xl border border-gray-300 bg-[#ffffff] p-4 shadow-sm shadow-gray-400 dark:border-gray-700 dark:bg-gray-800",
  {
    variants: {
      intent: {
        error: "",
        warning: "",
        success: "",
        info: "",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);

const toastInnerCircle = cva(
  "flex h-7 w-7 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        error: "bg-error-100",
        warning: "bg-warning-100",
        success: "bg-success-100",
        info: "hidden",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);
const toastCircle = cva(
  "flex h-9 w-9 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        error: "bg-error-50",
        warning: "bg-warning-50",
        success: "bg-success-50",
        info: "hidden",
      },
    },
    defaultVariants: {
      intent: "success",
    },
  },
);

export type ToastProps = VariantProps<typeof toastVariants> & {
  toastId: Parameters<Parameters<typeof toast.custom>[0]>[0];
  message: string;
  description?: string;
  actions?: (closeToast: () => void) => ReactNode;
};
export const Toast: React.FC<ToastProps> = (toastProps: ToastProps) => {
  return (
    <div>
      <div
        className={cn(
          toastVariants({ intent: toastProps.intent }),

          {
            "items-center": !toastProps.description,
          },
        )}
      >
        <div className={toastCircle({ intent: toastProps.intent })}>
          <div className={toastInnerCircle({ intent: toastProps.intent })}>
            {
              (
                {
                  error: (
                    <ExclamationCircleIcon pathClassName="stroke-error-600" />
                  ),
                  warning: (
                    <ExclamationCircleIcon pathClassName="stroke-warning-600" />
                  ),
                  success: (
                    <CheckCircleIcon pathClassName="stroke-success-600" />
                  ),
                  info: undefined,
                } as const
              )[toastProps.intent ?? "success"]
            }
          </div>
        </div>
        <div className="flex-1 flex-col items-start" style={{ rowGap: 8 }}>
          <div>
            <div className="text-sm font-semibold text-gray-950 dark:text-[#ffffff]">
              {toastProps.message}
            </div>
            {toastProps.description && (
              <div className="whitespace-pre-line text-sm text-gray-500">
                {toastProps.description}
              </div>
            )}
          </div>
          {toastProps.actions?.(() => toast.dismiss(toastProps.toastId))}
        </div>
        <Button
          intent="transparent"
          className="flex h-fit min-h-0 w-fit min-w-0 items-center justify-center p-1"
          onClick={() => {
            toast.dismiss(toastProps.toastId);
          }}
        >
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
};
