import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { TokenPageableRequestParams } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useInfiniteQueryMemberInvoiceListNewAsMember(
  {
    api,
    memberId,
    params,
  }: {
    api: ApiType;
    memberId: string;
    params: TokenPageableRequestParams;
  },
  { enabled }: { enabled: boolean },
) {
  return useInfiniteQuery({
    queryKey: memberAsMemberQueryKeys.invoicesNew(memberId, params),
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      return (
        await api.profileApi.findInvoicesNew({
          ...params,
          nextPageToken: pageParam,
        })
      ).data;
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageToken,
    enabled,
  });
}
