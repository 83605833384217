import { useTranslation } from "react-i18next";

import { RadioButtonGroup } from "../../atoms";

export type EnabledDisabledRadioButtonsProps = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const EnabledDisabledRadioButtons = ({
  value,
  onChange,
}: EnabledDisabledRadioButtonsProps) => {
  const { t } = useTranslation();
  return (
    <RadioButtonGroup
      options={[
        {
          label: t("Common.enabled"),
          value: "YES",
        },
        {
          label: t("Common.disabled"),
          value: "NO",
        },
      ]}
      value={value ? "YES" : "NO"}
      onChange={({ target: { value } }) => {
        onChange(value === "YES");
      }}
    />
  );
};
