import {
  ApiListResponse,
  MembershipBean,
  MembershipStatus,
  MembershipSummaryDTO,
  MembershipType,
} from "@gymflow/types";

import { createPublicAxiosInstance } from "./publicAxiosInstance";

export type PublicMembershipApiType = ReturnType<typeof publicMembershipApi>;

const publicMembershipApi = (apiUrl: string, clubId: number) => {
  const baseUrl = `club/${clubId}/hosted/membership`;
  const axiosInstance = createPublicAxiosInstance({
    apiUrl,
    paramsSerializer: {
      indexes: null,
    },
  });

  return {
    getById(id: number) {
      return axiosInstance.get<MembershipBean>(`${baseUrl}/${id}`);
    },
    getList(params: {
      status?: MembershipStatus;
      id?: number[];
      page: number;
      size: number;
      sort?: string;
      type?: MembershipType;
      isTrial?: boolean;
    }) {
      return axiosInstance.get<ApiListResponse<MembershipBean>>(`${baseUrl}`, {
        params,
      });
    },
    summary(params: {
      membershipId: number;
      userMemberId: string;
      promotionCode?: string;
    }) {
      return axiosInstance.post<MembershipSummaryDTO>(`${baseUrl}/summary`, {
        membershipId: params.membershipId,
        userMemberId: params.userMemberId,
        promotionCode: params.promotionCode,
      });
    },
  };
};

export default publicMembershipApi;
