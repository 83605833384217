import ServiceType from '../api/ServiceType';

export const getSalesTotalForType = (type, toSell) => {
  if (type === SalesType.Membership) {
    return toSell.totalPayableToday;
  } else if (type === SalesType.CreditPack) {
    return toSell.price;
  }
};

export const canSkipPaymentForType = (type, toSell) => {
  if (type === SalesType.Membership && toSell.type === ServiceType.Recurring) {
    return false;
  }
  return getSalesTotalForType(type, toSell) === 0;
};

export const hasEnoughCredits = (membership, credits, activityCategoryId) => {
  if (credits === 0) {
    return true;
  } else if (!membership.membershipAddonList) {
    return false;
  }
  const packs = membership.membershipAddonList.filter(
    (m) => m.sessionPackId && m.activityCategoryIdList.includes(activityCategoryId)
  );

  const hasUnlimited = packs.some((m) => m.sessionsUnlimited);
  if (hasUnlimited) {
    return true;
  }
  const totalCredits = packs.reduce((acc, m) => acc + m.credits, 0);

  return totalCredits >= credits;
};

export const isSessionPackSameCategoryAsEvent = (sessionPack, event) => {
  return sessionPack.activityCategoryIdList.includes(event.event.activity.activityCategory.id);
};

export const getMembershipPrice = (membership) =>
  membership.type === ServiceType.Recurring ? membership.nextMonthlyPayment : membership.defaultPrice;

export const SalesType = {
  CreditPack: 'CreditPack',
  Membership: 'Membership',
};
