import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useAppointableCategories,
  useAppointableCategoryCreate,
  useAppointableCategoryDisable,
} from "@gymflow/api";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import React, { useContext, useState } from "react";

import { ModalWrapper } from "../../../providers";
import { Button, TextInput } from "../../atoms";

export interface NewAppointableCategoryModalProps {
  api: any;
  onCancel: () => void;
}

export const NewAppointableCategoryModal: React.FC<
  NewAppointableCategoryModalProps
> = ({ api, onCancel }) => {
  const { data: categories } = useAppointableCategories({
    api,
    paginationOption: {
      size: 100,
      page: 0,
    },
    filter: {
      statusList: ["ACTIVE"],
    },
  });
  const { mutateAsync: createCategory } = useAppointableCategoryCreate({ api });
  const { mutateAsync: disableCategory } = useAppointableCategoryDisable({
    api,
  });
  const [newCategoryName, setNewCategoryName] = useState("");
  const { notifyDanger } = useContext(ToastContext);
  return (
    <ModalWrapper
      className="flex flex-col gap-y-4 sm:!max-w-xl"
      onCancel={onCancel}
    >
      <div className="flex w-full flex-row items-start justify-between">
        <div className="flex flex-col">
          <div className="flex text-lg font-semibold text-gray-900">
            Appointment Categories
          </div>
          <div className="flex text-sm text-gray-600">
            Add or remove appointment categories
          </div>
        </div>
        <FontAwesomeIcon
          icon={faClose}
          onClick={onCancel}
          className="h-5 w-5 cursor-pointer text-gray-500"
        />
      </div>

      <div className="flex flex-col gap-y-2">
        <div className="text-sm font-medium text-gray-700">Category</div>
        <div className="flex flex-row items-center gap-x-4">
          <TextInput
            placeholder="Enter new category"
            type="text"
            value={newCategoryName}
            onChange={(e) => {
              setNewCategoryName(e.currentTarget.value);
            }}
          />
          <Button
            onClick={async () => {
              if (newCategoryName.length > 0) {
                await createCategory({
                  name: newCategoryName,
                  color: "#ffffff",
                }).catch((e) => {
                  notifyDanger(e);
                });
                setNewCategoryName("");
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>

      {categories.content.map((e) => {
        return (
          <div key={e.id} className="flex flex-row justify-between">
            <div className="text-sm text-gray-700">{e.name}</div>
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => {
                disableCategory(e.id);
              }}
              className="h-5 w-5 cursor-pointer text-gray-500"
            />
          </div>
        );
      })}

      <div className="flex space-x-2">
        <Button onClick={onCancel} className="flex-1">
          Close
        </Button>
      </div>
    </ModalWrapper>
  );
};
