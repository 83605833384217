import { thunk } from 'easy-peasy';

import ServiceType from '../api/ServiceType';
import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';

class ServiceModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId, settingsKey = 'settings') {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;
    this.withActiveInactive();
    this.generators.push(this.serviceGenerator);
  }

  /**
   * @private
   */
  serviceGenerator() {
    return {
      create: thunk(
        (
          _,
          {
            serviceType,
            defaultPrice,
            name,
            description,
            durationType,
            duration,
            termsConditions,
            productIdOnceOffList,
            creditPackIdOnceOffList,
            productIdRecurringList,
            sessionPackIdRecurringList,
            isPublic,
            calculateProrata,
            isTrial,
            monthlyBillingDay,
            weeklyBillingDay,
            billingType,
            billingPeriod,
          },
          { injections, getState }
        ) => {
          const { defaultTaxRate } = injections.globalStore.getState()[this.settingsKey];

          switch (serviceType) {
            case ServiceType.Recurring:
              return getApi(injections, getState()).createRecurring(
                {
                  name,
                  description,
                  durationType,
                  duration,
                  defaultPrice,
                  productIdOnceOffList,
                  creditPackIdOnceOffList,
                  productIdRecurringList,
                  sessionPackIdRecurringList,
                  clubIdList: [this.clubId],
                  taxRate: defaultTaxRate,
                  isPublic,
                  calculateProrata,
                  isTrial,
                  monthlyBillingDay,
                  weeklyBillingDay,
                  billingType,
                  billingPeriod,
                },
                termsConditions
              );
            case ServiceType.Prepaid:
              return getApi(injections, getState()).createPrePaid(
                {
                  name,
                  description,
                  durationType,
                  duration,
                  defaultPrice,
                  productIdOnceOffList,
                  creditPackIdOnceOffList,
                  clubIdList: [this.clubId],
                  taxRate: defaultTaxRate,
                  isPublic,
                  isTrial,
                },
                termsConditions
              );
            default:
              throw new Error('unknown service type');
          }
        }
      ),

      update: thunk((_, { id, patchedFields }, { injections, getState }) => {
        const updatedService = { ...patchedFields };
        delete updatedService.termsConditions;
        return getApi(injections, getState()).update(id, {
          patchedFields: updatedService,
          termsAndConditionsBlob: patchedFields.termsConditions,
        });
      }),

      fetchList: thunk(
        async (actions, { page = 0, limit = 10, sort, status, extraParams, type } = {}, { injections, getState }) => {
          const { data } = await getApi(injections, getState()).findByClubId(this.clubId, {
            page,
            limit,
            sort,
            status,
            type,
            extraParams,
          });
          const payload = createPayloadFromFindResponse(data);
          actions.fetchedList({ ...payload, filter: { sort, status, ...extraParams } });
          return payload;
        }
      ),
    };
  }
}

export default ServiceModelBuilder;
