import { PromotionDTO, PromotionPostDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { promotionQueryKeys } from "./promotionQueryKeys";

export function usePromotionCreate({
  api,
}: {
  api: {
    promotionApi: {
      create: (arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newPromotion: PromotionPostDTO) => {
      const response = await api.promotionApi.create(newPromotion);
      return response.data as PromotionDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: promotionQueryKeys.all() });
    },
  });

  return mutation;
}
