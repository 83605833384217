import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "../member";

export function useMutationKisiResetAccess({
  api,
}: {
  api: {
    kisiApi: {
      resetAccess: (clubId: number, memberId: string) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      clubId,
      memberId,
    }: {
      clubId: number;
      memberId: string;
    }) => {
      await api.kisiApi.resetAccess(clubId, memberId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
  return mutation;
}
