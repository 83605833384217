import { Col, FormGroup, Row } from 'reactstrap';
import { FormikInput, formikHelpers } from '@gymflow/common';

import { PROMOTION_NAME } from './PromotionSchema';

function Step1({ formikProps, lockSomeFields }) {
  const { errorClass } = formikHelpers(formikProps);

  return (
    <div className="w-100 text-center">
      <b className="h2">Give your promotion a name</b>
      <div className="text-muted mt-3">
        <p>This is for internal reference only for you to view and report on later.</p>
        <p>We recommend you reference the time that the promotion ran for ease of reference.</p>
      </div>
      <Row className="mt-4 text-left d-flex justify-content-center">
        <Col md={8}>
          <FormGroup className={errorClass(PROMOTION_NAME)}>
            <FormikInput
              autoComplete="off"
              maxLength="128"
              name={PROMOTION_NAME}
              type="text"
              formikProps={formikProps}
              readOnly={lockSomeFields}
            />
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Step1;
