import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { formatCurrency, pluralize } from "@gymflow/helpers";
import {
  AppointableDtoAvailabilityType,
  AppointableDtoPricingModel,
  AppointmentPaymentStatus,
  AppointmentStatus,
  Currency,
} from "@gymflow/types";
import classNames from "classnames";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { Badge } from "../../atoms";
import { PaymentStatusBadge } from "./PaymentStatusBadge";

export function AppointmentDetails({
  status,
  appointableName,
  appointablePrice,
  appointableSessionCost,
  hostName,
  memberName,
  memberId,
  facilityName,
  availabilityType,
  paymentStatus,
  currency,
  startDate,
  endDate,
  appointablePricingModel,
}: {
  status: AppointmentStatus;
  appointableName: string;
  appointablePrice?: number;
  appointableSessionCost?: number;
  hostName?: string;
  memberName: string;
  memberId: string;
  facilityName?: string;
  availabilityType: AppointableDtoAvailabilityType;
  paymentStatus: AppointmentPaymentStatus;
  currency: Currency;
  startDate: string;
  endDate: string;
  appointablePricingModel: AppointableDtoPricingModel;
}) {
  const { createMemberLink } = usePortalRoutes();

  const renderPrice = () => {
    if (appointablePricingModel === "FREE") {
      return "Free";
    }
    if (appointablePrice !== undefined) {
      return formatCurrency(appointablePrice, currency);
    }
    return "";
  };

  const renderSessionCost = () => {
    if (appointableSessionCost !== undefined) {
      return `${appointableSessionCost} ${pluralize(
        "Credit",
        "Credits",
        appointableSessionCost,
      )}`;
    }
    return "";
  };

  return (
    <div className="flex-column flex gap-4 px-8">
      <div className="flex items-center justify-between">
        <div className="text-lg font-bold">
          {moment(startDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            "dddd, Do MMMM",
          )}
        </div>
        <div className="font-normal text-gray-500">
          {`${moment(startDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            "h:mm a",
          )} - ${moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            "h:mm a",
          )}`}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="font-normal text-gray-900">{appointableName}</div>
          <div className="text-sm font-normal text-gray-500">
            {availabilityType === "STAFF"
              ? `With ${hostName}`
              : `At ${facilityName}`}
          </div>
        </div>
        <div>
          <div className="text-end font-normal text-gray-900">
            {renderPrice()}
          </div>
          <div
            className={classNames(
              "text-end text-sm font-normal text-gray-500",
              {
                invisible: !appointableSessionCost,
              },
            )}
          >
            {renderSessionCost()}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <Link
            className="text-primary-600 font-bold"
            to={createMemberLink(memberId)}
          >
            {memberName}
          </Link>
        </div>
        <div>
          {status === "CANCELLED" && (
            <Badge intent="error" className="uppercase">
              Cancelled
            </Badge>
          )}
          {status === "LATE_CANCELLED" && (
            <Badge intent="error" className="uppercase">
              Late Cancelled
            </Badge>
          )}
          {status === "BOOKED" && (
            <Badge intent="primary" className="uppercase">
              Booked
            </Badge>
          )}
          {status === "NO_SHOW" && <Badge className="uppercase">No Show</Badge>}
          {status === "ATTENDED" && (
            <Badge className="uppercase">Attended</Badge>
          )}
          {status === "CONFIRMED" && (
            <Badge className="uppercase">Confirmed</Badge>
          )}
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>Payment Status</div>
        <div>
          <PaymentStatusBadge paymentStatus={paymentStatus} />
        </div>
      </div>
    </div>
  );
}
