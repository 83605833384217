import { z } from "zod";

export const RevenueType = {
  TransactionType: "transaction-type",
  ScheduledRevenue: "scheduled-revenue",
  AccountsReceivable: "accounts-receivable",
} as const;

export const RevenueTypeZodSchema = z.enum([
  RevenueType.TransactionType,
  RevenueType.ScheduledRevenue,
  RevenueType.AccountsReceivable,
]);
