import { useMutation, useQueryClient } from '@tanstack/react-query';

import { activityQueryKeys } from './activityQueryKeys';

export function useActivityEnable({
  api,
}: {
  api: {
    activityApi: {
      activate: (arg0: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (activityId: number) => {
      await api.activityApi.activate(activityId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.all() });
    },
  });

  return mutation;
}
