import { appointableListQueryFn } from "@gymflow/api";

import useGymflowModels from "../../store";
import { PaginatedSelect } from ".";

export interface AppointableSelectOption {
  label: string;
  value: number;
}

interface AppointableSelectProps {
  value: AppointableSelectOption[];
  onChange: (newValue: AppointableSelectOption[]) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  isClearable?: boolean;
}

export function AppointableSelect({
  value,
  onChange,
  allowMultipleSelection,
  placeholder,
  isClearable,
}: AppointableSelectProps) {
  const { api } = useGymflowModels();

  return (
    <PaginatedSelect
      isMulti={allowMultipleSelection}
      placeholder={placeholder}
      loadOptions={async (_, __, additional) => {
        const appointableList = await appointableListQueryFn({
          api,
          filter: {
            extraParams: { status: "ACTIVE" },
            page: additional.page,
          },
        });
        return {
          options: appointableList.content.map((appointable) => ({
            label: appointable.name,
            value: appointable.id,
          })),
          hasMore: !appointableList.last,
          additional: {
            page: additional.page + 1,
          },
        };
      }}
      value={value}
      onChange={(newValue) => {
        onChange(allowMultipleSelection ? newValue : [newValue]);
      }}
      isClearable={isClearable}
    />
  );
}
