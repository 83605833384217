import { action, actionOn, thunk, thunkOn } from 'easy-peasy';
import _get from 'lodash/get';

import ModelBuilder from './ModelBuilder';

class BaseModelBuilder extends ModelBuilder {
  constructor(apiKey) {
    super();
    this.apiKey = apiKey;
    this.generators.push(this.baseGenerator);
  }

  withCategories(foreignField = 'categoryId') {
    this.categoryForeignField = foreignField;
    this.generators.push(this.categoryGenerator);
    return this;
  }

  withActiveInactive() {
    this.generators.push(this.activeInactiveGenerator);
    return this;
  }

  /**
   * @private
   */
  baseGenerator() {
    return {
      apiKey: this.apiKey,
      rows: [],
      filter: {},
      pageCount: 0,
      totalRecords: 0,
      pageSize: 0, // TODO: this is the same field as rowsLimit that gets used instead when fetchList runs
      page: 0,
      editing: null,
      loadingRecord: false,
      loadingList: false,

      onFetchListStart: actionOn(
        (actions) => actions.fetchList.startType,
        (state) => {
          state.loadingList = true;
        }
      ),

      onFetchListDone: actionOn(
        (actions) => [actions.fetchList.successType, actions.fetchList.failType],
        (state) => {
          state.loadingList = false;
        }
      ),

      onFetchRecordStart: actionOn(
        (actions) => actions.fetchById.startType,
        (state) => {
          state.loadingRecord = true;
        }
      ),

      onFetchRecordDone: actionOn(
        (actions) => [actions.fetchById.successType, actions.fetchById.failType],
        (state) => {
          state.loadingRecord = false;
        }
      ),

      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, extraParams } = {}, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).find({ page, limit, sort, extraParams });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList({ ...payload, filter: extraParams });
        return payload;
      }),

      refreshList: thunk((actions, cumulativeFilter, { getState }) => {
        const { page, rowsLimit, filter } = getState();
        return actions.fetchList({ page, limit: rowsLimit, extraParams: { ...filter, ...cumulativeFilter } });
      }),

      fetchedList: action((state, { rows, pageCount, page, rowsLimit, totalRecords, filter = {} }) => {
        state.rows = rows;
        state.pageCount = pageCount;
        state.page = page;
        state.rowsLimit = rowsLimit;
        state.filter = filter;
        state.totalRecords = totalRecords;
      }),

      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).findById(recordId);
        const payload = {
          ...data,
        };
        actions.fetchedRecord(payload);
        return payload;
      }),

      fetchedRecord: action((state, payload) => {
        state.editing = payload;
      }),

      clearEditingRecord: action((state) => {
        state.editing = null;
      }),

      clearRows: action((state) => {
        state.rows = [];
        state.pageCount = 0;
        state.page = 0;
        state.rowsLimit = 0;
      }),

      create: thunk((_, fields, { injections, getState }) => getApi(injections, getState()).create(fields)),

      update: thunk((_, { id, patchedFields }, { injections, getState }) =>
        getApi(injections, getState()).update(id, patchedFields)
      ),
    };
  }

  /**
   * @private
   */
  categoryGenerator() {
    return {
      categories: [],

      fetchCategories: thunk(async (actions, _, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).fetchCategories();
        actions.fetchedCategories(data);
        return data;
      }),

      fetchedCategories: action((state, categories) => {
        state.categories = categories;
      }),

      onFetchListAndRecord: thunkOn(
        (actions) => [actions.fetchList.startType, actions.fetchById.startType],
        async (actions, _, { getState }) => {
          if (getState().categories.length === 0) {
            await actions.fetchCategories();
          }
        }
      ),

      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, extraParams } = {}, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).find({ page, limit, sort, extraParams });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList({ ...payload, filter: extraParams });
        return payload;
      }),

      fetchedList: action((state, { rows, pageCount, page, rowsLimit, totalRecords, filter = {} }) => {
        rows.forEach((r) => {
          r.categoryName = state.categories.find((c) => c.id === _get(r, this.categoryForeignField)).name;
        });
        state.rows = rows;
        state.pageCount = pageCount;
        state.page = page;
        state.rowsLimit = rowsLimit;
        state.filter = filter;
        state.totalRecords = totalRecords;
      }),

      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).findById(recordId);
        const payload = {
          ...data,
        };
        payload.categoryName = getState().categories.find(
          (c) => c.id === _get(payload, this.categoryForeignField)
        ).name;
        actions.fetchedRecord(payload);
        return payload;
      }),
    };
  }

  /**
   * @private
   */
  activeInactiveGenerator() {
    return {
      deactivate: thunk((_, id, { injections, getState }) => {
        return getApi(injections, getState()).deactivate(id);
      }),

      activate: thunk((_, id, { injections, getState }) => {
        return getApi(injections, getState()).activate(id);
      }),

      onActivateDeactivate: thunkOn(
        (actions) => [actions.deactivate, actions.activate],
        (actions) => actions.refreshList()
      ),
    };
  }
}

export function getApi(injections, { apiKey }) {
  return injections.api[apiKey];
}

export function createPayloadFromFindResponse({
  content,
  totalPages,
  pageable: { pageNumber },
  size,
  last,
  totalElements,
}) {
  return {
    rows: content,
    pageCount: totalPages,
    page: pageNumber,
    rowsLimit: size,
    totalRecords: totalElements,
    last,
  };
}

export default BaseModelBuilder;
