import {
  memberInvoiceListQueryFn,
  useEventOccurrenceRsvp,
  useMemberInvoice,
} from "@gymflow/api";
import {
  AlertContext,
  InvoiceStatus,
  PaymentConfirmationStatus,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { ordinal, toLowerCaseExceptFirstChar } from "@gymflow/helpers";
import {
  EventOccurrenceDTO,
  EventRsvpDTO,
  EventRsvpStatus,
  UserMemberBean,
} from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import classNames from "classnames";
import { useCallback, useContext, useMemo } from "react";

import useMemberRules from "../../../hooks/useMemberRules";
import { ModalContext, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Badge, Button } from "../../atoms";
import { Table } from "../../organisms";
import { ConfirmModal } from "../../templates";
import { UserMemberAvatar } from "../UserMemberAvatar";

type NonRecursiveRsvpType = {
  [rsvpKey in keyof EventRsvpDTO]: rsvpKey extends "userMember"
    ? {
        [memberKey in keyof UserMemberBean]: memberKey extends
          | "assignedStaffMembers"
          | "subscriptions"
          | "homeClub"
          | "lead"
          ? never
          : UserMemberBean[memberKey];
      }
    : EventRsvpDTO[rsvpKey];
};

export function RsvpTable({
  rsvpList = [],
  startDate,
  isLoading,
  occurrence,
}: {
  occurrence: EventOccurrenceDTO;
  rsvpList: EventRsvpDTO[];
  startDate?: string;
  isLoading: boolean;
}) {
  const sortedRsvpList = useMemo(() => {
    const statusOrder: EventRsvpStatus[] = [
      "ATTENDED",
      "BOOKED",
      "WAITING",
      "CANCELLED",
      "LATE_CANCELLED",
      "NO_SHOW",
    ];

    return [...rsvpList].sort((a, b) => {
      const aIdx = statusOrder.indexOf(a.status);
      const bIdx = statusOrder.indexOf(b.status);
      if (aIdx === -1) {
        return 1;
      }
      if (bIdx === -1) {
        return -1;
      }

      return aIdx - bIdx;
    });
  }, [rsvpList]);

  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { validateMemberCancel, validateNoShow } = useMemberRules({
    activityId: occurrence.event.activity.id,
  });

  const { updateRsvp } = useEventOccurrenceRsvp({
    api,
  });
  const { collectInvoiceMutation } = useMemberInvoice({ api });

  const { setModal, hide: hideModal } = useContext(ModalContext);

  const { show } = useContext(AlertContext);
  const toast = useContext(ToastContext);

  const { show: showPaymentAuthorizationAlert } =
    usePaymentAuthorizationAlert();

  const cancelRsvp = useCallback(
    async ({ isLate, rsvpId }: { isLate: boolean; rsvpId: number }) => {
      try {
        const {
          invoiceNumber,
          eventRsvpDTO: {
            userMember: { id: memberId },
          },
        } = await updateRsvp.mutateAsync({
          rsvpId,
          status: isLate ? "LATE_CANCELLED" : "CANCELLED",
        });
        if (!invoiceNumber) {
          hideModal();
          return;
        }
        const invoice = (
          await memberInvoiceListQueryFn({
            api,
            memberId,
            filter: {
              page: 0,
              limit: 1,
              extraParams: {
                number: invoiceNumber,
              },
            },
          })
        ).content[0];
        const collectResponse = await collectInvoiceMutation.mutateAsync({
          memberId,
          clubId,
          invoiceNumber,
          amount: invoice.chargeableAmount,
        });
        if (
          collectResponse.data.status === InvoiceStatus.PaymentAuthorization
        ) {
          hideModal();
          const paymentConfirmationResult = await showPaymentAuthorizationAlert(
            {
              paymentIntentIdForAuthorization:
                collectResponse.data.paymentIntentIdForAuthorization,
              confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
              messageText: (
                <>
                  The user must authorize this payment before it will be
                  processed.
                  <br />
                  Please ask the user to authorize the payment by clicking the
                  link sent to their email.
                </>
              ),
            },
          );
          if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Success
          ) {
            await show("The payment was confirmed.", {
              title: "Success!",
              showCancel: false,
            });
          } else if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Waiting
          ) {
            toast.toast({
              message: "Awaiting payment, check later.",
              intent: "warning",
            });
          } else if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Failed
          ) {
            toast.toast({
              message: "Payment Failed.",
              intent: "error",
            });
          }
        } else {
          hideModal();
          await show("The payment was confirmed.", {
            title: "Success!",
            showCancel: false,
          });
        }
      } catch (e: any) {
        if (e?.response?.data?.error_message) {
          toast.notifyDanger(
            `${e.response.data.error_message}. An invoice has been added to their account for collection later.`,
          );
        }
      }
    },
    [
      api,
      clubId,
      collectInvoiceMutation,
      hideModal,
      show,
      showPaymentAuthorizationAlert,
      toast,
      updateRsvp,
    ],
  );

  const markNoShow = useCallback(
    async ({ rsvpId }: { rsvpId: number }) => {
      try {
        const {
          invoiceNumber,
          eventRsvpDTO: {
            userMember: { id: memberId },
          },
        } = await updateRsvp.mutateAsync({
          rsvpId,
          status: "NO_SHOW",
        });
        if (!invoiceNumber) {
          hideModal();
          return;
        }
        const invoice = (
          await memberInvoiceListQueryFn({
            api,
            memberId,
            filter: {
              page: 0,
              limit: 1,
              extraParams: {
                number: invoiceNumber,
              },
            },
          })
        ).content[0];
        const collectResponse = await collectInvoiceMutation.mutateAsync({
          memberId,
          clubId,
          invoiceNumber,
          amount: invoice.chargeableAmount,
        });
        if (
          collectResponse.data.status === InvoiceStatus.PaymentAuthorization
        ) {
          hideModal();
          const paymentConfirmationResult = await showPaymentAuthorizationAlert(
            {
              paymentIntentIdForAuthorization:
                collectResponse.data.paymentIntentIdForAuthorization,
              confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
              messageText: (
                <>
                  The user must authorize this payment before it will be
                  processed.
                  <br />
                  Please ask the user to authorize the payment by clicking the
                  link sent to their email.
                </>
              ),
            },
          );
          if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Success
          ) {
            await show("The payment was confirmed.", {
              title: "Success!",
              showCancel: false,
            });
          } else if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Waiting
          ) {
            toast.toast({
              message: "Awaiting payment, check later.",
              intent: "warning",
            });
          } else if (
            paymentConfirmationResult.status ===
            PaymentConfirmationStatus.Failed
          ) {
            toast.toast({
              message: "Payment Failed.",
              intent: "error",
            });
          }
        } else {
          hideModal();
          await show("The payment was confirmed.", {
            title: "Success!",
            showCancel: false,
          });
        }
      } catch (e: any) {
        if (e?.response?.data?.error_message) {
          toast.notifyDanger(
            `${e.response.data.error_message}. An invoice has been added to their account for collection later.`,
          );
        }
      }
    },
    [
      api,
      clubId,
      collectInvoiceMutation,
      hideModal,
      show,
      showPaymentAuthorizationAlert,
      toast,
      updateRsvp,
    ],
  );

  const columnHelper = createColumnHelper<NonRecursiveRsvpType>();
  const columns = [
    columnHelper.accessor("userMember", {
      header: () => <div className="pl-3">Name</div>,
      cell: (cell) => {
        return (
          <div className="pl-6">
            <UserMemberAvatar {...cell.getValue()} />
          </div>
        );
      },
    }),
    columnHelper.accessor("userMember.eventsAttended", {
      header: "Class Count",
      cell: (column) => {
        const attendedCount = column.row.original.userMember.eventsAttended;
        let badgeText = "";
        let celebrationIcon = "";

        if (attendedCount === 0) {
          badgeText = "1st booking";
          celebrationIcon = "💪";
        } else if (attendedCount === 1) {
          badgeText = "1st class";
          celebrationIcon = "🥇";
        } else if (attendedCount % 25 === 0) {
          badgeText = ordinal(attendedCount) + " class";
          celebrationIcon = "🔥";
        } else {
          badgeText = ordinal(attendedCount) + " class";
        }
        return (
          <div>
            <Badge>{`${badgeText} ${celebrationIcon}`}</Badge>
          </div>
        );
      },
    }),
    columnHelper.accessor("userMember.userType", {
      header: "User Type",
      cell: (column) => {
        return (
          <div>
            <Badge>
              {toLowerCaseExceptFirstChar(
                column.row.original.userMember.userType,
              )}
            </Badge>
          </div>
        );
      },
    }),
    columnHelper.accessor("status", {
      header: "Booking Status",
      cell: (column) => {
        switch (column.row.original.status) {
          case "ATTENDED":
            return (
              <div>
                <Badge intent="success">Attended</Badge>
              </div>
            );
          case "BOOKED":
            return (
              <div>
                <Badge intent="primary">Booked</Badge>
              </div>
            );
          case "CANCELLED":
            return (
              <div>
                <Badge intent="warning">Cancelled</Badge>
              </div>
            );
          case "LATE_CANCELLED":
            return (
              <div>
                <Badge intent="warning">Late Cancellation</Badge>
              </div>
            );
          case "WAITING":
            return (
              <div>
                <Badge intent="primary">Waitlist</Badge>
              </div>
            );
          case "NO_SHOW":
            return (
              <div>
                <Badge intent="error">No Show</Badge>
              </div>
            );
        }
        return <div></div>;
      },
    }),
    columnHelper.display({
      header: "Actions",
      cell: (column) => {
        const eventRow = column.row.original;
        return (
          <div className="flex gap-4">
            <Button
              intent="link"
              className={classNames({ hidden: eventRow.status !== "BOOKED" })}
              onClick={async () => {
                try {
                  await updateRsvp.mutateAsync({
                    rsvpId: column.row.original.id,
                    status: "ATTENDED",
                  });
                } catch (e) {
                  toast.notifyDanger(e as any);
                }
              }}
            >
              Check In
            </Button>
            <Button
              intent="link"
              className={classNames({ hidden: eventRow.status !== "ATTENDED" })}
              onClick={async () => {
                try {
                  await updateRsvp.mutateAsync({
                    rsvpId: column.row.original.id,
                    status: "BOOKED",
                  });
                } catch (e) {
                  toast.notifyDanger(e as any);
                }
              }}
            >
              Un-Check
            </Button>
            <Button
              intent="link"
              className={classNames({ hidden: eventRow.status !== "BOOKED" })}
              onClick={async () => {
                const noShowMessage = validateNoShow();
                if (noShowMessage === null) {
                  try {
                    await updateRsvp.mutateAsync({
                      rsvpId: column.row.original.id,
                      status: "NO_SHOW",
                    });
                  } catch (e) {
                    toast.notifyDanger(e as any);
                  }
                } else {
                  setModal(
                    <ConfirmModal
                      onHide={() => {
                        hideModal();
                      }}
                      onCancel={async () => {
                        hideModal();
                      }}
                      onConfirm={async () => {
                        await markNoShow({
                          rsvpId: column.row.original.id,
                        });
                        hideModal();
                      }}
                      cancelText="Cancel"
                      confirmText="Yes"
                      type="default"
                      title="No Show Fee"
                    >
                      {noShowMessage}
                    </ConfirmModal>,
                  );
                }
              }}
            >
              No Show
            </Button>
            <Button
              intent="link"
              className={classNames({
                hidden:
                  eventRow.status !== "CANCELLED" &&
                  eventRow.status !== "WAITING" &&
                  eventRow.status !== "NO_SHOW" &&
                  eventRow.status !== "LATE_CANCELLED",
              })}
              onClick={async () => {
                try {
                  await updateRsvp.mutateAsync({
                    rsvpId: column.row.original.id,
                    status: "BOOKED",
                  });
                } catch (e) {
                  toast.notifyDanger(e as any);
                }
              }}
            >
              Book In
            </Button>
            <Button
              intent="link"
              className={classNames({ hidden: eventRow.status !== "BOOKED" })}
              onClick={async () => {
                const lateCancelMessage =
                  eventRow.status === "BOOKED"
                    ? validateMemberCancel(startDate!, true)
                    : null;
                if (lateCancelMessage === null) {
                  try {
                    await updateRsvp.mutateAsync({
                      rsvpId: column.row.original.id,
                      status: "CANCELLED",
                    });
                  } catch (e) {
                    toast.notifyDanger(e as any);
                  }
                } else {
                  setModal(
                    <ConfirmModal
                      onHide={() => {
                        hideModal();
                      }}
                      onCancel={async () => {
                        await cancelRsvp({
                          isLate: false,
                          rsvpId: column.row.original.id,
                        });
                        hideModal();
                      }}
                      onConfirm={async () => {
                        await cancelRsvp({
                          isLate: true,
                          rsvpId: column.row.original.id,
                        });
                        hideModal();
                      }}
                      cancelText="No"
                      confirmText="Yes"
                      type="default"
                      title="Late Cancellation Fee"
                    >
                      {lateCancelMessage}
                    </ConfirmModal>,
                  );
                }
              }}
            >
              Cancel
            </Button>
            <Button
              intent="link"
              className={classNames({
                hidden: eventRow.status !== "WAITING",
              })}
              onClick={async () => {
                try {
                  await updateRsvp.mutateAsync({
                    rsvpId: column.row.original.id,
                    status: "CANCELLED",
                  });
                } catch (e) {
                  toast.notifyDanger(e as any);
                }
              }}
            >
              Remove
            </Button>
          </div>
        );
      },
    }),
  ];

  return (
    <Table
      rowClassName="h-14"
      data={sortedRsvpList}
      columns={columns}
      pageCount={1}
      pageIndex={0}
      isFetching={isLoading}
      pageSize={sortedRsvpList.length ?? 10}
    />
  );
}
