import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { Spinner } from '@gymflow/common';

import FilterableList from '../../../forms/FilterableList';
import useFacilities from '../../../../hooks/useFacilities';

function Step4Timetable({ onChange, value }) {
  const { fetchList: fetchFacilities, rows: facilities, loadingList: isLoading, fetchCategories } = useFacilities();

  useEffect(() => {
    const fetchFacilitiesAndCategories = async () => {
      await fetchCategories();
      await fetchFacilities({
        limit: 1000,
      });
    };
    fetchFacilitiesAndCategories();
  }, []);

  const options = useMemo(() => facilities.map(({ name, id }) => ({ label: name, value: id })), [facilities]);

  return (
    <>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div className="w-100 text-center" style={{ opacity: isLoading ? '0.2' : '1' }}>
        <b className="h2">Select which facilities to display the events of</b>
        <Row className="mt-4 text-left d-flex justify-content-center">
          <Col md={8}>
            <p className="text-uppercase text-muted">Choose Facilities</p>
            <p style={{ color: '#ec250d' }}>Selecting none will display events from all facilities</p>
            <FilterableList options={options} value={value} onChange={onChange} />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Step4Timetable;
