import { useEffect, useState } from 'react';

import Button from "./Button";

function Wizard({
  steps,
  onCancel,
  onSubmit,
  onStepChange,
  cancelBtnProps,
  prevBtnProps,
  nextBtnProps,
  submitBtnProps,
}) {
  const [currentStep, setCurrentStep] = useState(0);

  const { component: Component, innerProps = {}, validate } = steps[currentStep];

  useEffect(() => {
    onStepChange && onStepChange(currentStep);
  }, [currentStep, onStepChange]);

  const renderButtons = () => (
    <div className="w-100 d-flex justify-content-between">
      {currentStep === 0 ? (
        <Button size="sm" outline color="primary" type="button" onClick={onCancel} {...cancelBtnProps}>
          {cancelBtnProps?.text || 'Cancel'}
        </Button>
      ) : (
        <Button
          size="sm"
          outline
          color="primary"
          type="button"
          onClick={() => setCurrentStep((s) => s - 1)}
          {...prevBtnProps}
        >
          {prevBtnProps?.text || 'Back'}
        </Button>
      )}
      {currentStep === steps.length - 1 ? (
        <Button
          size="sm"
          color="primary"
          onClick={async (...params) => {
            if (validate) {
              const isValid = await validate();
              if (!isValid) {
                return;
              }
            }
            onSubmit(params);
          }}
          {...submitBtnProps}
        >
          {submitBtnProps?.text || 'Save'}
        </Button>
      ) : (
        <Button
          size="sm"
          color="primary"
          onClick={async () => {
            if (validate) {
              const isValid = await validate();
              if (isValid) {
                setCurrentStep((s) => s + 1);
              }
            } else {
              setCurrentStep((s) => s + 1);
            }
          }}
          {...nextBtnProps}
        >
          {nextBtnProps?.text || 'Next'}
        </Button>
      )}
    </div>
  );

  return (
    <div className="d-flex flex-column h-100" style={{ minHeight: 380 }}>
      <div className="d-flex justify-content-center">
        <div style={{ position: 'relative', width: '80%' }}>
          <div style={{ position: 'relative', top: 8, height: 4, backgroundColor: '#31436e', borderRadius: 0.5 }} />
          <ul className="d-flex justify-content-between p-0">
            {steps?.map((_, idx) => (
              <i
                key={idx}
                style={{
                  position: 'relative',
                  borderRadius: '50%',
                  height: 12,
                  width: 12,
                  border: '1px solid #31436e',
                  borderColor: currentStep === idx ? 'transparent' : '#31436e',
                  backgroundColor: currentStep > idx ? '#31436e' : currentStep === idx ? '#6186db' : 'white',
                }}
              />
            ))}
          </ul>
        </div>
      </div>
      <div className="d-flex flex-grow-1 my-2">{<Component {...innerProps} />}</div>
      {renderButtons()}
    </div>
  );
}

export default Wizard;
