import {
  SendSMSToLeadsArgs,
  SendSMSToLeadsFilterArgs,
  SendSMSToMembersArgs,
  SendSMSToMembersFilterArgs,
  useMutationSendSMSToLeads,
  useMutationSendSMSToLeadsFilter,
  useMutationSendSMSToMembers,
  useMutationSendSMSToMembersFilter,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { SMSSendingResponse } from "@gymflow/types";
import { AxiosError } from "axios";
import { useCallback, useContext } from "react";

import { useClubSettings } from "../providers";
import { ToastContext } from "../providers/ToastProvider/context";
import useGymflowModels from "../store";

export const useSendSMS = () => {
  const { api } = useGymflowModels();
  const { timezone: tz } = useClubSettings();
  // change to a new design (just quick copy-paste solution because waiting for designer)
  const { show, showError } = useContext(AlertContext);
  const toast = useContext(ToastContext);
  const { mutateAsync: sendSMSToMembersMutate } = useMutationSendSMSToMembers({
    api,
  });
  const { mutateAsync: sendSMSToMembersFilterMutate } =
    useMutationSendSMSToMembersFilter({ api, tz });
  const { mutateAsync: sendSMSToLeadsMutate } = useMutationSendSMSToLeads({
    api,
  });
  const { mutateAsync: sendSMSToLeadsFilterMutate } =
    useMutationSendSMSToLeadsFilter({ api, tz });

  // change to a new design (just quick copy-paste solution because waiting for designer)
  const showWarning = useCallback(
    ({
      failedMobileNumbers,
      sentSms,
      sentAttempts,
      failedAttemptsWithNoMobileNumber,
    }: SMSSendingResponse) => {
      const failedSMSCount = failedMobileNumbers
        ? failedMobileNumbers.length
        : 0;
      const noSMSCount = failedAttemptsWithNoMobileNumber
        ? failedAttemptsWithNoMobileNumber.length
        : 0;
      const marketingErrors =
        sentAttempts - sentSms - failedSMSCount - noSMSCount;
      let isDanger = false;

      const nodes = [
        <>
          Sent {sentSms} out of {sentAttempts} SMS.
        </>,
      ];

      if (marketingErrors > 0) {
        nodes.push(
          <>
            <br />
            {marketingErrors} SMS not sent due to marketing permissions.
            <br />
          </>,
        );
      }

      if (failedSMSCount > 0) {
        isDanger = true;
        nodes.push(<>Failed SMS: {failedMobileNumbers.join(", ")}</>);
      }

      if (
        failedAttemptsWithNoMobileNumber &&
        failedAttemptsWithNoMobileNumber.length > 0
      ) {
        isDanger = true;
        nodes.push(
          <>
            <br />
            Users with no SMS:{" "}
            {failedAttemptsWithNoMobileNumber
              .map(({ firstName, lastName }) => firstName + " " + lastName)
              .join(", ")}
          </>,
        );
      }

      showError(nodes, {
        warning: !isDanger,
        danger: isDanger,
        title: isDanger ? "Error" : "Warning",
      });
    },
    [showError],
  );

  // change to a new design (just quick copy-paste solution because waiting for designer)
  const showSuccessful = useCallback(
    (response: SMSSendingResponse) =>
      show(`${response.sentSms} SMS successfully sent.`, { showCancel: false }),
    [show],
  );

  const showToastError = useCallback(
    (error: Error | AxiosError) => {
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.error_message;

        if (errorMessage) {
          toast.toast({
            message: errorMessage,
            intent: "error",
          });
          return;
        }
      }

      toast.toast({
        message: "Error with SMS Sending",
        intent: "error",
      });
    },
    [toast],
  );

  const sendSMSToMembers = useCallback(
    async (arg: SendSMSToMembersArgs) => {
      try {
        const response = await sendSMSToMembersMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToMembersMutate, showSuccessful, showToastError, showWarning],
  );

  const sendSMSToMembersFilter = useCallback(
    async (arg: SendSMSToMembersFilterArgs) => {
      try {
        const response = await sendSMSToMembersFilterMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToMembersFilterMutate, showSuccessful, showToastError, showWarning],
  );

  const sendSMSToLeads = useCallback(
    async (arg: SendSMSToLeadsArgs) => {
      try {
        const response = await sendSMSToLeadsMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToLeadsMutate, showSuccessful, showToastError, showWarning],
  );

  const sendSMSToLeadsFilter = useCallback(
    async (arg: SendSMSToLeadsFilterArgs) => {
      try {
        const response = await sendSMSToLeadsFilterMutate(arg);

        if (response.sentSms === response.sentAttempts) {
          showSuccessful(response);
        } else {
          showWarning(response);
        }
      } catch (error) {
        showToastError(error as Error | AxiosError);
      }
    },
    [sendSMSToLeadsFilterMutate, showSuccessful, showToastError, showWarning],
  );

  return {
    sendSMSToMembers,
    sendSMSToMembersFilter,
    sendSMSToLeads,
    sendSMSToLeadsFilter,
  };
};
