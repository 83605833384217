export function ChevronRightIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M7.5 15L12.5 10L7.5 5"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
      </g>
    </svg>
  );
}
