const constants = {
  EMAIL: "email",
  FIRST_NAME: "first-name",
  PASSWORD: "password",
  PASSWORD_CONFIRM: "password-confirm",
  LAST_NAME: "last-name",
  MOBILE_NUMBER: "mobile-number",
  DATE_OF_BIRTH: "date-of-birth",
  POST_CODE: "post-code",
  ADDRESS1: "address1",
  ADDRESS2: "address2",
  CITY: "city",
  NATIONALITY: "country",
  GENDER: "gender",
  EMAIL_COMMUNICATION: "email-communication",
  SMS_COMMUNICATION: "sms-communication",
  PUSH_COMMUNICATION: "push-communication",
  EMERGENCY_CONTACT_NAME: "emergency-contact-name",
  EMERGENCY_CONTACT: "emergency-contact",
  SOURCE: "source",
  PERSONAL_NUMBER: "personal-number",
};

export const CUSTOM_INVOICE_FIELD = "custom-invoice-field";

export default constants;
