import { RuleGroupPatch, RuleGroupPost, RuleType } from "@gymflow/types";

export const RuleMapper = {
  mapPostToPatch: (
    accessRule: RuleGroupPost,
    ruleTypeToIdMap: Record<RuleType, number>,
  ) => {
    const result: RuleGroupPatch = {
      name: accessRule.name,
      classIdList: accessRule.classIdList,
      appointableIdList: accessRule.appointableIdList,
      membershipIdList: accessRule.membershipIdList,
      ruleClubList: accessRule.ruleClubList.map((e) => {
        const result = {
          ...e,
          ruleType: undefined,
          ruleClubId: ruleTypeToIdMap[e.ruleType],
        };
        return result;
      }),
    };
    return result;
  },
};
