import { useMutation } from "@tanstack/react-query";

export function useMutationLeadDelete({
  api,
}: {
  api: {
    leadApi: {
      delete: (leadId: number) => Promise<any>;
    };
  };
}) {
  const csvMutation = useMutation({
    mutationFn: async ({ leadId }: { leadId: number }) => {
      const result = await api.leadApi.delete(leadId);
      return result.data as string;
    },
  });
  return csvMutation;
}
