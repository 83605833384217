const localStorageKey = "remember-me-route-id";

export function forgetRouteFn() {
  localStorage.removeItem(localStorageKey);
}

export function rememberRouteFn(routeId: string) {
  localStorage.setItem(localStorageKey, routeId);
}

export function useRememberMe() {
  const routeId = localStorage.getItem(localStorageKey);

  return {
    routeId,
    rememberRoute: rememberRouteFn,
    forgetRoute: forgetRouteFn,
  };
}
