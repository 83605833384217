import {
  InvoiceStatus,
  PaymentConfirmationStatus,
  usePaymentAuthorizationAlert,
} from "@gymflow/common";
import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router";

import { ToastContext } from "../providers/ToastProvider/context";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";
import { usePortalRoutes } from "./usePortalRoutes";

function useProcessSaleCustomer({
  event,
  userMemberId,
}: {
  event: { id: number };
  userMemberId: string;
}) {
  const { createClubLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const history = useHistory();
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const toast = useContext(ToastContext);

  const createProcessSalenFn = useCallback(
    (apiCall: any, successMessage: string) =>
      async (...props: any[]) => {
        const response = await apiCall(...props);
        if (response.data.checkoutSessionId) {
          return response;
        }

        let pendingPayment = null;
        if (
          response.data?.invoice?.status === InvoiceStatus.PaymentAuthorization
        ) {
          const paymentConfirmationResult = await showPaymentConfirmationAlert({
            paymentIntentIdForAuthorization:
              response.data.invoice.paymentIntentIdForAuthorization,
            confirmPayment: api.strongCustomerAuthorizationApi.confirmPayment,
            messageText: (
              <>
                Your bank has requested additional authorization to make this
                payment.
                <br />
                Please check your email and click the authorization link to
                complete this purchase.
              </>
            ),
            hideCancel: true,
          });
          pendingPayment = paymentConfirmationResult.status;
        }

        let hasErrors = false;
        if (event) {
          try {
            await api.customerOccurrenceApi.addAttendeeToRsvp(
              userMemberId,
              event.id,
            );
          } catch {
            hasErrors = true;
          }
        }

        history.push(createClubLink(RouteLayout.Member, RouteFeature.Profile));

        if (hasErrors) {
          toast.notifyDanger(
            "Could not book the event. Check the event on the Calendar.",
          );
        }

        if (pendingPayment === PaymentConfirmationStatus.Waiting) {
          toast.toast({
            message: successMessage + ", awaiting payment, check later.",
            intent: "warning",
          });
        } else if (pendingPayment === PaymentConfirmationStatus.Failed) {
          toast.toast({
            message: successMessage + ", payment failed.",
            intent: "error",
          });
        } else {
          toast.toast({ message: successMessage + "." });
        }
      },
    [event, history, toast, userMemberId, showPaymentConfirmationAlert],
  );

  const processNewMemberMembership = useMemo(
    () => createProcessSalenFn(api.profileApi.create, "Membership purchased"),
    [createProcessSalenFn],
  );
  const processNewMemberCreditPack = useMemo(
    () =>
      createProcessSalenFn(
        api.profileApi.create,
        "Credit pack added to account",
      ),
    [createProcessSalenFn],
  );
  const processExistingMemberMembership = useMemo(
    () => createProcessSalenFn(api.profileApi.update, "Membership purchased"),
    [createProcessSalenFn],
  );
  const processExistingMemberCreditPack = useMemo(
    () =>
      createProcessSalenFn(
        api.profileApi.update,
        "Credit pack added to account",
      ),
    [createProcessSalenFn],
  );

  return {
    processNewMemberMembership,
    processNewMemberCreditPack,
    processExistingMemberMembership,
    processExistingMemberCreditPack,
  };
}

export default useProcessSaleCustomer;
