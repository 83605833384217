import { BootstrapServerRepository } from "@gymflow/api";
import { useMutation } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import environment from "../../environment";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { PaginatedSelect } from "./PaginatedSelect";

export type ClubPickerProps = {
  initialValue: { label: string; value: number };
  onChange: (newValue: number) => void;
  isDisabled?: boolean;
};

export const ClubPicker: React.FC<ClubPickerProps> = ({
  initialValue,
  onChange,
  isDisabled,
}) => {
  const { brand } = usePortalRoutes();

  const serverUrl = environment.get("API_RESOLVER_URL");
  const repository = useMemo(
    () => new BootstrapServerRepository(serverUrl),
    [serverUrl],
  );
  const getClubs = useMutation({
    mutationFn: ({ searchTerm, page, limit, clubIds }: any) =>
      repository.getRoutesByBrand({
        search: searchTerm,
        page,
        limit,
        brand,
        clubIds,
      }),
  });

  const [currentOption, setCurrentOption] = useState(initialValue);

  return (
    <PaginatedSelect
      value={currentOption}
      onChange={(newOption) => {
        onChange(newOption.value);
        setCurrentOption(newOption);
      }}
      loadOptions={async (searchTerm: string, _: any, { page }: any) => {
        const data = await getClubs.mutateAsync({
          searchTerm,
          page,
          limit: 10,
        });

        return {
          options: data.results.map((route) => ({
            label: route.displayName,
            value: route.clubId,
            isDisabled: route.status === "DISABLED",
          })),
          hasMore: data.hasMore,
          additional: {
            page: page + 1,
          },
        };
      }}
      isDisabled={isDisabled}
    />
  );
};
