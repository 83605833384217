import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { creditPackAsMemberQueryKeys } from "./creditPackAsMemberQueryKeys";

export function useQueryCreditPackAsMember({
  api,
  id,
  enabled,
}: {
  api: ApiType;
  id?: Parameters<ApiType["customerCreditPackApi"]["getById"]>[0];
  enabled?: boolean;
}) {
  const result = useQuery({
    queryKey: creditPackAsMemberQueryKeys.getById(id),
    queryFn: async () => {
      return (await api.customerCreditPackApi.getById(id!)).data;
    },
    enabled: id !== undefined && enabled,
  });
  return result;
}
