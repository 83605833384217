import check, { assert } from 'check-types';

const customerClubApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    findById(id) {
      assert(check.any([check.string(id), check.number(id)]), 'id must be number or string');
      return state.http.get(`${state.baseUrl}customer`);
    },

    properties() {
      return state.http.get(`${state.baseUrl}customer/properties`);
    },
  });
};

export default customerClubApi;
