import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind, canFindById, canTrimFields } from "./features/apiResource";

const noteEndpoints = {
  leadNote: `/lead-note`,
  appointmentNote: `/appointment-note`,
};

const noteApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.note}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    {
      search({
        page = 0,
        limit = 10,
        dateFrom,
        dateTo,
        userMemberId,
        leadId,
        appointmentId,
      }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const body = {
          page,
          size: limit,
          dateFrom,
          dateTo,
          userMemberId,
          leadId,
          appointmentId,
        };

        return state.http.put(`${state.baseUrl}/search`, body);
      },
      create(newNote, file) {
        const formData = state.transformIntoFormData({ file, note: newNote });

        return state.http.post(state.baseUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },

      update(id, { patchedFields, file }) {
        assert(
          check.any([check.string(id), check.number(id)]),
          "id must be number or string",
        );
        const formData = state.transformIntoFormData({
          file,
          note: patchedFields,
        });

        return state.http.patch(`${state.baseUrl}/${id}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },

      remove(id) {
        return state.http.delete(`${state.baseUrl}/${id}`);
      },

      removeAttachment(id) {
        return state.http.delete(`${state.baseUrl}/${id}/attachment`);
      },

      createLeadNote(newNote, file) {
        const formData = state.transformIntoFormData({ file, note: newNote });

        return state.http.post(
          `${state.baseUrl}${noteEndpoints.leadNote}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
      },

      createAppointmentNote(newNote, file) {
        const formData = state.transformIntoFormData({ file, note: newNote });

        return state.http.post(
          `${state.baseUrl}${noteEndpoints.appointmentNote}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        );
      },

      /**
       * @private
       */
      transformIntoFormData({ file, note }) {
        const formData = new FormData();
        const trimmedData = state.trimFields(note);
        if (file) {
          formData.append("file", file, file.name);
        }

        if (note && Object.keys(note).length > 0) {
          formData.append(
            "note",
            new Blob([JSON.stringify(trimmedData)], {
              type: "application/json",
            }),
          );
        }

        return formData;
      },
    },
  );
};

export default noteApi;
