import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useQueryKisiSignatureKeyExists(
  {
    api,
    clubId,
  }: {
    api: {
      kisiApi: {
        signatureKeyExists(clubId: number): Promise<{ data: boolean }>;
      };
    };
    clubId: number;
  },
  queryOpts?: UseQueryOptions<boolean>,
) {
  const result = useQuery({
    queryKey: kisiQueryKeys.signatureKeyExists(clubId),
    queryFn: async () => {
      const result = await api.kisiApi.signatureKeyExists(clubId);
      return result.data;
    },
    ...queryOpts,
  });
  return result;
}
