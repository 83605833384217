/* eslint-disable react/function-component-definition */
import { parsePhoneNumber, phoneUtil } from "@gymflow/validation";
import { useFormik } from "formik";
import { PhoneNumberUtil } from "google-libphonenumber";
import React, { forwardRef, useEffect, useState } from "react";
import { Input } from "reactstrap";

import { cn } from "../../helpers/cn";
import { onlyPhoneNumbersProps } from "../../helpers/form";
import { CommonPaginatedSelect } from "../atoms/CommonPaginatedSelect";

export type MobileNumberInputProps = {
  id?: string;
  name: string;
  formikProps: ReturnType<typeof useFormik>;
} & React.ComponentProps<typeof Input>;
const utilInstance = PhoneNumberUtil.getInstance();
const phoneNumberOptions = utilInstance.getSupportedRegions().map((region) => ({
  value: `+${utilInstance.getCountryCodeForRegion(region)}`,
  label: `${region}(+${utilInstance.getCountryCodeForRegion(region)})`,
}));

const MobileNumberInput: React.FC<MobileNumberInputProps> = (
  {
    id: inId,
    name,
    phone_number_country: out_phone_number_country,
    formikProps,
    ...props
  },
  ref,
) => {
  const phone_number_country = out_phone_number_country ?? '["GB"]';
  const id = inId ?? name;
  const { values, setFieldValue } = formikProps ?? {};
  const phoneNumber = parsePhoneNumber((values as any)[name] ?? "");

  const [prefix, setPrefix] = useState(
    `+${
      phoneNumber?.getCountryCode() ??
      phoneUtil.getCountryCodeForRegion(
        JSON.parse(phone_number_country)[0] ?? "",
      )
    }`,
  );
  const [number, setNumber] = useState(
    `${phoneNumber?.getNationalNumber() ?? ""}`,
  );

  useEffect(() => {
    setPrefix(
      `+${
        phoneNumber?.getCountryCode() ??
        phoneUtil.getCountryCodeForRegion(
          JSON.parse(phone_number_country)[0] ?? "",
        )
      }`,
    );
    setNumber(`${phoneNumber?.getNationalNumber() ?? ""}`);
  }, [phoneNumber, phone_number_country]);

  return (
    <div className="flex flex-row">
      <CommonPaginatedSelect
        isSearchable
        className="!h-10 w-16 !rounded-r-none px-0 text-sm"
        loadOptions={async (term) => {
          return {
            options: phoneNumberOptions.filter((option) =>
              option.label.toLowerCase().includes(term.toLowerCase()),
            ),
          };
        }}
        value={{
          value: prefix,
          label: prefix,
        }}
        onChange={({ value }) => {
          setPrefix(value);
          if (number) {
            setFieldValue(id, value + number);
          } else {
            setFieldValue(id, undefined);
          }
        }}
      />
      <Input
        innerRef={ref}
        name={name}
        //  eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        //  eslint-disable-next-line react/jsx-props-no-spreading
        {...onlyPhoneNumbersProps}
        value={number}
        onChange={(e) => {
          const newText = e.currentTarget.value;
          setNumber(newText);
          if (newText) {
            setFieldValue(id, prefix + newText);
          } else {
            setFieldValue(id, undefined);
          }
        }}
        type="tel"
        className={cn(
          "dark:bg-gray-950 dark:placeholder:text-darkGray-500 dark:!text-darkGray-50 dark:!border-darkGray-700 block !h-10 h-11 w-full flex-1 !rounded-lg !rounded-l-none !border !border-l-0 !border-gray-300 px-3.5 py-2.5 text-gray-900 placeholder:text-gray-400",
          {
            "!bg-gray-100": props.disabled,
          },
        )}
      />
    </div>
  );
};

export default forwardRef(MobileNumberInput as any);
