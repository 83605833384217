import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { PaymentMethodDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

/*** @deprecated - use useMemberPaymentMethodListNew */
export function useMemberPaymentMethodList(
  {
    api,
    memberId,
    clubId,
  }: {
    api: ApiType;
    memberId: string;
    clubId: number;
  },
  queryOpts?: UseQueryOptions<PaymentMethodDTO[]>,
) {
  const result = useQuery({
    queryKey: memberQueryKeys.paymentMethods(clubId, memberId),
    queryFn: async () => {
      const result = await api.memberApi.fetchPaymentMethods(memberId, clubId);
      return result.data as PaymentMethodDTO[];
    },
    initialData: [],
    ...queryOpts,
  });
  return result;
}

export function useQueryMemberPaymentMethodListNew({
  api,
  memberId,
  clubId,
  enabled,
}: {
  api: ApiType;
  memberId: string;
  clubId: number;
  enabled: boolean;
}) {
  const result = useQuery({
    queryKey: memberQueryKeys.paymentMethodsNew(clubId, memberId),
    queryFn: async () => {
      const result = await api.memberApi.fetchPaymentMethods(memberId, clubId);
      return result.data;
    },
    enabled,
  });
  return result;
}
