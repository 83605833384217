import { faCog } from "@fortawesome/free-solid-svg-icons";
import { DATE_FORMAT_WITH_SECONDS } from "@gymflow/common";
import { toLowerCaseExceptFirstChar } from "@gymflow/helpers";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import Item from "./Item";

const colorStatusMap = {
  ACTIVE: "#007E4F",
  PAUSED: "#F05519",
  CANCELLED: "#0A0D16",
  EXPIRED: "#0A0D16",
  PENDING: "#9D9EA2",
  OVERDUE: "#E2003A",
};
function MembershipStatusItem({ status, createdDate, dateFormat }) {
  return (
    <Item
      icon={faCog}
      firstLine={
        <>
          Membership Status changed to{" "}
          <b style={{ color: colorStatusMap[status] }}>
            {toLowerCaseExceptFirstChar(status)}
          </b>
        </>
      }
      secondLine={
        <>
          {moment(createdDate, DATE_FORMAT_WITH_SECONDS).format(
            `${dateFormat} [at] h:mm a`,
          )}
        </>
      }
    />
  );
}

MembershipStatusItem.propTypes = {
  status: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

export default MembershipStatusItem;
