import { QueryKey } from "@tanstack/react-query";

export const clubAsPublicQueryKeys = {
  all: () => ["clubAsPublic"] as QueryKey,
  properties: (clubId: number) =>
    [...clubAsPublicQueryKeys.all(), clubId, "properties"] as QueryKey,
  propertiesSettings: (clubId: number) =>
    [...clubAsPublicQueryKeys.all(), clubId, "propertiesSettings"] as QueryKey,
  details: (clubId: number) =>
    [...clubAsPublicQueryKeys.all(), clubId] as QueryKey,
  featureFlags: (clubId: number) =>
    [...clubAsPublicQueryKeys.all(), clubId, "featureFlags"] as QueryKey,
  hostedPagesSettings: (clubId: number) =>
    [...clubAsPublicQueryKeys.all(), clubId, "hostedPagesSettings"] as QueryKey,
};
