import "./Calendar.css";

import { subject } from "@casl/ability";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventDropArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, {
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  appointmentQueryFn,
  eventOccurrenceQueryFn,
  useAppointmentEdit,
  useEventOccurrenceEdit,
  useMutationRecurringAppointmentEdit,
} from "@gymflow/api";
import {
  DATE_TIME_FORMAT,
  isMobile,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import classNames from "classnames";
import omit from "lodash/omit";
import moment from "moment-timezone";
import qs from "qs";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import environment from "../../../environment";
import { getFilterAppliedCount } from "../../../helpers";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { Can, Subject, Verb } from "../../../permissions";
import {
  ModalContext,
  useAuthenticatedUser,
  useClubSettings,
} from "../../../providers";
import { RouteFeature } from "../../../routes/feature";
import { RouteLayout } from "../../../routes/layout";
import useGymflowModels from "../../../store";
import {
  Button,
  ComputerIcon,
  FilterIcon,
  PaginatedSelect,
  PlaceholderAvatar,
  PlusCircleIcon,
  Spinner,
  SquarishAvatar,
} from "../../atoms";
import {
  AppointmentRescheduleModal,
  EventOccurrenceRescheduleModal,
  HostNotAvailableModal,
  RecurrenceEditModal,
  RecurringAppointmentRescheduleModal,
  SideBarAppointmentFormProvider,
  SideBarAppointmentFormProviderContext,
  SideBarAppointmentProvider,
  SideBarAppointmentProviderContext,
  SideBarOccurrenceFormProvider,
  SideBarOccurrenceFormProviderContext,
} from "../../molecules";
import { ChooseIfRecurringRescheduleModal } from "../../molecules/Appointments/ChooseIfRecurringRescheduleModal";
import {
  AppointmentCannotRescheduleDueToAccessModal,
  CalendarFilterSidebar,
  EventType,
} from "../../organisms";
import { ConfirmModal } from "../../templates";
import { AvailabilityDropdown } from "./AvailabilityDropdown";
import { CalendarIntervalMode } from "./CalendarIntervalMode";
import { ChooseEventTypeModal } from "./ChooseEventTypeModal";
import { DayGridMonthTitleFormat } from "./DayGridMonthTitleFormat";
import { EventContent } from "./EventContent";
import {
  calendarViewToIntervalMap,
  getTimeslotOnViewport,
  intervalToCalendarViewMap,
  SupportedCalendarView,
} from "./helpers";
import { SlotLabelContent } from "./SlotLabelContent";
import { TimeGridDayTitleFormat } from "./TimeGridDayTitleFormat";
import { TimeGridWeekDayContent } from "./TimeGridWeekDayContent";
import { TimeGridWeekDayTitleFormat } from "./TimeGridWeekDayTitleFormat";
import { useCalendarAvailability } from "./useCalendarAvailability";
import { useCalendarEvents } from "./useCalendarEvents";
import { CalendarMode, useCalendarMode } from "./useCalendarMode";
import { useCalendarParams } from "./useCalendarParams";

function Calendar() {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const { id: loggedInId, roles } = useAuthenticatedUser();
  const hasTrainerRole = roles.includes("TRAINER");
  const { createClubLink } = usePortalRoutes();

  const history = useHistory();

  const {
    date,
    getDateForToday,
    interval: intervalParam,
    setQueryParams,
    marker,
    appointmentId: appointmentIdParam,
  } = useCalendarParams();

  const calendarApiRef = useRef<FullCalendar>();

  const modeOptions: { [key in CalendarMode]: string } = {
    EVERYTHING: "Everything",
    APPOINTMENTS: "Appointments",
    CLASSES: "Classes",
    TRAINER: "Trainer",
    FACILITY: "Facility",
  };

  const { modeSelected, setModeSelected, filters, setFilters } =
    useCalendarMode();

  const intervalModeOptions: { [key in CalendarIntervalMode]: string } = {
    DAY: "Day",
    WEEK: "Week",
    MONTH: "Month",
  };
  const [intervalModeSelected, setIntervalModeSelected] =
    useState<CalendarIntervalMode>(intervalParam);
  const [isChangingIntervalMode, setIsChangingIntervalMode] = useState(true);

  const setViewToIntervalMode = useCallback(() => {
    if (calendarApiRef.current) {
      calendarApiRef.current
        .getApi()
        .changeView(calendarViewToIntervalMap[intervalModeSelected]);
    }
  }, [intervalModeSelected]);

  useEffect(() => {
    setViewToIntervalMode();
  }, [setViewToIntervalMode]);

  useEffect(() => {
    if (calendarApiRef.current) {
      switch (modeSelected) {
        case "TRAINER":
        case "FACILITY":
          calendarApiRef.current.getApi().changeView("resourceTimeGridDay");
          break;
        default:
          setViewToIntervalMode();
      }
    }
  }, [modeSelected, setViewToIntervalMode]);

  const [intervalLoaded, setIntervalLoaded] = useState<{
    start: Date;
    end: Date;
  }>();

  const filteredStaffIds = useMemo(() => {
    if (hasTrainerRole) {
      return [loggedInId as string];
    }
    if (filters.hosts && filters.hosts.length > 0) {
      return filters.hosts.map(({ value }) => value);
    }
    if (modeSelected === "TRAINER") {
      return [loggedInId as string];
    }
    return undefined;
  }, [filters.hosts, hasTrainerRole, loggedInId, modeSelected]);

  const {
    calendarEvents,
    isFetching: isFetchingEvents,
    refetchAppointments,
  } = useCalendarEvents({
    enabled: !isChangingIntervalMode,
    intervalLoaded,
    modeSelected,
    filteredStaffIds,
    filters,
  });

  const [isFilterSideBarOpen, setIsFilterSideBarOpen] = useState(false);

  const { openNew: openAppointmentFormSideBar } = useContext(
    SideBarAppointmentFormProviderContext,
  );
  const { open: openAppointmentSideBar } = useContext(
    SideBarAppointmentProviderContext,
  );
  const { openNew: openEventOccurrenceFormSideBar } = useContext(
    SideBarOccurrenceFormProviderContext,
  );

  useEffect(() => {
    if (appointmentIdParam) {
      openAppointmentSideBar(+appointmentIdParam);
    }
  }, [openAppointmentSideBar, appointmentIdParam]);

  const { setModal, hide: hideModal } = useContext(ModalContext);
  const { notifyDanger, toast } = useContext(ToastContext);

  const editAppointmentMutation = useAppointmentEdit({
    api,
    tz: timezone,
  });

  const occurrenceEditMutation = useEventOccurrenceEdit({
    api,
    tz: timezone,
  });

  const rescheduleRecurringAppointment = useMutationRecurringAppointmentEdit({
    api,
  });

  const dragAndDropHandler = useCallback(
    async (eventDropArg: EventDropArg | EventResizeDoneArg) => {
      const eventJson = eventDropArg.event.toJSON();
      if (eventJson["extendedProps"].type === "APPOINTMENT") {
        const appointment = await appointmentQueryFn({
          api,
          appointmentId: eventJson["extendedProps"].appointmentId,
        });

        if (!appointment) {
          eventDropArg.revert();
          return;
        }

        if (appointment.kisiRoleAssignmentId) {
          setModal(<AppointmentCannotRescheduleDueToAccessModal />);
          eventDropArg.revert();
          return;
        }

        async function rescheduleConfirmCallback(newValues: {
          startDate: string;
          appointmentHostId: string;
        }) {
          try {
            await editAppointmentMutation.mutateAsync({
              appointmentId: appointment.id,
              patchedFields: newValues,
            });

            hideModal();
          } catch (e: any) {
            hideModal();
            if (
              e?.response?.data?.error_message ===
              "This staff has no available slots for this booking time."
            ) {
              setModal(
                <HostNotAvailableModal
                  onConfirm={async () => {
                    await editAppointmentMutation.mutateAsync({
                      appointmentId: appointment.id,
                      patchedFields: {
                        ...newValues,
                        availabilityValidationIgnored: true,
                      },
                    });
                    hideModal();

                    toast({
                      message: "Appointment rescheduled.",
                    });
                  }}
                  onCancel={() => {
                    hideModal();
                  }}
                />,
              );
            } else if (
              e?.response?.data?.error_message ===
              "This facility has no available slots for this booking time."
            ) {
              setModal(
                <HostNotAvailableModal
                  onConfirm={async () => {
                    await editAppointmentMutation.mutateAsync({
                      appointmentId: appointment.id,
                      patchedFields: {
                        ...newValues,
                        availabilityValidationIgnored: true,
                      },
                    });
                    hideModal();

                    toast({
                      message: "Appointment rescheduled.",
                    });
                  }}
                  onCancel={() => {
                    hideModal();
                  }}
                />,
              );
            } else {
              notifyDanger(e);
            }
          }
        }

        if (appointment.recurringAppointmentId) {
          setModal(
            <ChooseIfRecurringRescheduleModal
              onConfirm={(selected) => {
                if (selected === "THIS_INSTANCE") {
                  setModal(
                    <AppointmentRescheduleModal
                      appointment={appointment}
                      onConfirm={rescheduleConfirmCallback}
                      onCancel={() => {
                        hideModal();
                        eventDropArg.revert();
                      }}
                      newDate={moment(eventDropArg.event.start).format(
                        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                      )}
                    />,
                  );
                } else if (selected === "ALL_INSTANCES") {
                  setModal(
                    <RecurringAppointmentRescheduleModal
                      appointment={appointment}
                      onConfirm={async (newValues) => {
                        setModal(
                          <ConfirmModal
                            type="warning"
                            onConfirm={async () => {
                              await rescheduleRecurringAppointment.mutateAsync({
                                recurringAppointmentId:
                                  appointment.recurringAppointmentId!,
                                patchedFields: {
                                  ...newValues,
                                  fromAppointmentId: appointment.id,
                                },
                              });
                              hideModal();
                            }}
                            onCancel={() => {
                              hideModal();
                              eventDropArg.revert();
                            }}
                            title="Attention"
                            confirmText="I got it"
                          >
                            If you have changed specific appointments in this
                            series, your changes will be undone and those
                            appointments will be consistent with the series
                            again.
                          </ConfirmModal>,
                        );
                      }}
                      onCancel={() => {
                        hideModal();
                        eventDropArg.revert();
                      }}
                      newDate={moment(eventDropArg.event.start).format(
                        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                      )}
                    />,
                  );
                }
              }}
              onCancel={() => {
                hideModal();
                eventDropArg.revert();
              }}
            />,
          );
        } else {
          setModal(
            <AppointmentRescheduleModal
              appointment={appointment}
              onConfirm={rescheduleConfirmCallback}
              onCancel={() => {
                hideModal();
                eventDropArg.revert();
              }}
              newDate={moment(eventDropArg.event.start).format(
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              )}
            />,
          );
        }
      } else if (eventJson["extendedProps"].type === "OCCURRENCE") {
        const occurrence = await eventOccurrenceQueryFn({
          api,
          eventId: eventJson["extendedProps"].occurrenceId,
        });
        if (!occurrence) {
          eventDropArg.revert();
          return;
        }

        setModal(
          <EventOccurrenceRescheduleModal
            eventOccurrence={occurrence}
            onConfirm={async (newValues) => {
              if (newValues["isRecurring"]) {
                setModal(
                  <RecurrenceEditModal
                    onConfirm={async (updateAll) => {
                      const params = {
                        ...newValues,
                        isUpdateAll: updateAll,
                      };

                      try {
                        await occurrenceEditMutation.mutateAsync({
                          eventId: occurrence.id,
                          patchedFields: params,
                        });
                        hideModal();
                      } catch (e) {
                        notifyDanger(e as any);
                      }
                    }}
                    onCancel={() => {
                      hideModal();
                    }}
                  />,
                );
              } else {
                try {
                  await occurrenceEditMutation.mutateAsync({
                    eventId: occurrence.id,
                    patchedFields: newValues,
                  });
                } catch (e) {
                  notifyDanger(e as any);
                }
                hideModal();
              }
            }}
            onCancel={() => {
              eventDropArg.revert();
              hideModal();
            }}
            newStartDate={moment(eventDropArg.event.start).format(
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            )}
            newEndDate={moment(eventDropArg.event.end).format(
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            )}
          />,
        );
      }
    },
    [
      api,
      editAppointmentMutation,
      hideModal,
      toast,
      notifyDanger,
      occurrenceEditMutation,
      setModal,
    ],
  );

  useEffect(() => {
    if (isMobile()) {
      setIntervalModeSelected("DAY");
      setIsChangingIntervalMode(true);
    }
  }, []);

  const nowInTheGymTimezone = useMemo(
    () => moment().tz(timezone).format("YYYY-MM-DDTHH:mm"),
    [timezone],
  );

  const {
    unavailabilityEvents,
    isFetching: isFetchingAvailability,
    staffList,
    facilityList,
    resources,
  } = useCalendarAvailability({
    enabled: !isChangingIntervalMode,
    modeSelected,
    intervalLoaded,
    filteredStaffIds,
    filteredFacilityIds:
      filters?.facility && filters?.facility?.length > 0
        ? filters.facility.map((f) => {
            return f.value;
          })
        : undefined,
  });

  const showSpinner = isFetchingEvents || isFetchingAvailability;
  return (
    <div className="h-full p-4 sm:p-8">
      <CalendarFilterSidebar
        isVisible={isFilterSideBarOpen}
        onVisibilityChange={(newVisibility) => {
          setIsFilterSideBarOpen(newVisibility);
        }}
        value={filters}
        onChange={(newFilters) => {
          setFilters(newFilters);
        }}
      />
      <div className="flex flex-wrap justify-between pb-2 lg:pb-4">
        <div className="flex items-center gap-2">
          <div
            className={classNames("flex justify-between", {
              hidden: !showSpinner,
            })}
          >
            <div className="overflow-hidden">
              <Spinner />
            </div>
          </div>
        </div>
        <div className="flex grow items-center justify-end gap-2">
          <div className="flex items-center gap-1">
            <Button
              size="small"
              className={classNames({
                "px-2": isMobile(),
              })}
              onClick={() => {
                calendarApiRef.current!.getApi().prev();
              }}
            >
              <FontAwesomeIcon
                className="h-[1.125rem] w-[1.125rem]"
                icon={faArrowLeft}
              />
            </Button>
            <Button
              className={classNames({ hidden: isMobile() })}
              size="small"
              onClick={() => {
                if (calendarApiRef?.current) {
                  calendarApiRef.current.getApi().gotoDate(getDateForToday());
                }
              }}
            >
              Today
            </Button>
            <Button
              size="small"
              className={classNames({
                "px-2": isMobile(),
              })}
              onClick={() => {
                calendarApiRef.current!.getApi().next();
              }}
            >
              <FontAwesomeIcon
                className="h-[1.125rem] w-[1.125rem]"
                icon={faArrowRight}
              />
            </Button>
          </div>
          <PaginatedSelect
            className={classNames("bg-white", {
              hidden:
                modeSelected === "TRAINER" ||
                modeSelected === "FACILITY" ||
                isMobile(),
            })}
            size="small"
            value={{
              label: intervalModeOptions[intervalModeSelected],
              value: intervalModeSelected,
            }}
            onChange={({ value }) => {
              setIntervalModeSelected(value);
              setIsChangingIntervalMode(true);
            }}
            loadOptions={() => {
              return Promise.resolve({
                options: Object.keys(intervalModeOptions).map((k) => ({
                  value: k,
                  label: intervalModeOptions[k as CalendarIntervalMode],
                })),
              });
            }}
          />
          <PaginatedSelect
            className="bg-white"
            size="small"
            value={{
              label: isMobile() ? (
                <ComputerIcon
                  className="h-[1.125rem] w-[1.125rem]"
                  pathClassName="stroke-gray-500"
                />
              ) : (
                modeOptions[modeSelected]
              ),
              value: modeSelected,
            }}
            onChange={({ value }) => {
              setModeSelected(value);
              setIsChangingIntervalMode(true);
            }}
            loadOptions={() => {
              return Promise.resolve({
                options: Object.keys(modeOptions).map((k) => ({
                  value: k,
                  label: modeOptions[k as CalendarMode],
                })),
              });
            }}
            compact={isMobile()}
          />

          <Button
            size="small"
            className={classNames({
              "px-2": isMobile(),
            })}
            onClick={() => {
              setIsFilterSideBarOpen(true);
            }}
            badgeContent={getFilterAppliedCount(
              omit(filters, ["businessHours"]),
            )}
          >
            <div className="flex items-center gap-2">
              <FilterIcon
                className="h-[1.125rem] w-[1.125rem]"
                pathClassName="stroke-gray-500"
              />
              {!isMobile() && <div>Filter</div>}
            </div>
          </Button>
          <PaginatedSelect
            color="accent"
            size="small"
            placeholder="Add"
            value={
              isMobile()
                ? {
                    value: null,
                    label: (
                      <PlusCircleIcon
                        className="h-[1.125rem] w-[1.125rem]"
                        pathClassName="stroke-white"
                      />
                    ),
                  }
                : null
            }
            onChange={({ value }: { value: EventType }) => {
              if (value === "OCCURRENCE") {
                openEventOccurrenceFormSideBar(
                  moment().format(DATE_TIME_FORMAT),
                  true,
                );
              } else if (value === "APPOINTMENT") {
                openAppointmentFormSideBar({
                  referenceDate: moment().format(
                    PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                  ),
                  onClose: async () => {
                    await refetchAppointments();
                  },
                });
              }
            }}
            loadOptions={() => {
              return Promise.resolve({
                options: [
                  { value: "APPOINTMENT", label: "Appointment" },
                  { value: "OCCURRENCE", label: "Class" },
                ] satisfies { value: EventType; label: string }[],
              });
            }}
            compact={isMobile()}
            menuWidthShouldMatchText
          />
        </div>
      </div>
      <div className="unified-calendar h-full overflow-y-scroll">
        <FullCalendar
          height="auto"
          initialDate={date}
          firstDay={1}
          now={nowInTheGymTimezone}
          nowIndicator
          initialView={
            modeSelected === "TRAINER" || modeSelected === "FACILITY"
              ? "resourceTimeGridDay"
              : calendarViewToIntervalMap[intervalModeSelected]
          }
          plugins={[
            timeGridPlugin,
            dayGridPlugin,
            interactionPlugin,
            resourceTimeGridPlugin,
          ]}
          slotDuration="00:15:00"
          eventContent={EventContent}
          slotLabelContent={SlotLabelContent}
          slotMinTime={filters.businessHours.start}
          slotMaxTime={filters.businessHours.end}
          datesSet={({ start, end, view }) => {
            setIntervalLoaded({
              start,
              end: moment(end).subtract(1, "second").toDate(),
            });
            setIsChangingIntervalMode(false);
            setQueryParams({
              date: start,
              interval:
                intervalToCalendarViewMap[view.type as SupportedCalendarView],
              pageTitle: view.title,
            });
          }}
          events={[...calendarEvents, ...unavailabilityEvents]}
          views={{
            dayGridMonth: {
              titleFormat: DayGridMonthTitleFormat,
            },
            timeGridWeek: {
              dayHeaderContent: TimeGridWeekDayContent,
              titleFormat: TimeGridWeekDayTitleFormat,
            },

            timeGridDay: {
              dayHeaderContent: TimeGridWeekDayContent,
              titleFormat: TimeGridDayTitleFormat,
            },
            resourceTimeGridDay: {
              resourceLabelContent: ({ resource }) => {
                let pictureUrl,
                  resourceType: "STAFF" | "FACILITY",
                  permissionSubject;
                if (modeSelected === "TRAINER") {
                  pictureUrl = staffList?.find(({ id }) => id === resource.id)
                    ?.picture;
                  resourceType = "STAFF";
                  permissionSubject = subject(Subject.AppointmentAvailability, {
                    id: resource.id,
                  });
                } else if (modeSelected === "FACILITY") {
                  pictureUrl = facilityList?.find(
                    ({ id }) => id.toString() === resource.id,
                  )?.picture;
                  resourceType = "FACILITY";
                  permissionSubject = Subject.FacilityAvailability;
                } else {
                  return null;
                }

                return (
                  <div className="my-2 flex flex-col items-center text-sm font-normal uppercase">
                    <div className={classNames({ hidden: isMobile() })}>
                      {pictureUrl ? (
                        <SquarishAvatar pictureUrl={pictureUrl} />
                      ) : (
                        <PlaceholderAvatar name={resource.title} />
                      )}
                    </div>
                    <div className="flex items-center">
                      <div> {resource.title}</div>
                      <Can I={Verb.View} a={permissionSubject}>
                        <AvailabilityDropdown
                          resourceId={resource.id}
                          resourceType={resourceType}
                        />
                      </Can>
                    </div>
                  </div>
                );
              },
              titleFormat: TimeGridDayTitleFormat,
            },
          }}
          eventClick={(info) => {
            const eventType = info.event.extendedProps["type"] as EventType;
            if (eventType === "OCCURRENCE") {
              const marker = getTimeslotOnViewport();
              if (marker) {
                history.push({
                  search: qs.stringify({
                    ...qs.parse(history.location.search, {
                      ignoreQueryPrefix: true,
                    }),
                    marker: moment(marker, "h:mm A").format("HH:mm"),
                  }),
                });
              }
              history.push({
                pathname: createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.EventOccurrence.replace(
                    ":eventId",
                    info.event.extendedProps["occurrenceId"],
                  ),
                ),
              });
            } else if (eventType === "APPOINTMENT") {
              openAppointmentSideBar(
                +info.event.extendedProps["appointmentId"],
              );
            }
          }}
          dateClick={({ dateStr, resource }) => {
            if (
              filters.eventType === "OCCURRENCE" ||
              modeSelected === "CLASSES"
            ) {
              openEventOccurrenceFormSideBar(
                moment(dateStr).format(DATE_TIME_FORMAT),
              );
            } else if (
              filters.eventType === "APPOINTMENT" ||
              modeSelected === "APPOINTMENTS"
            ) {
              openAppointmentFormSideBar({
                referenceDate: moment(dateStr).format(
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ),
                host: resource && { name: resource.title, id: resource.id },
              });
            } else {
              let trainerId: string | null | undefined;
              let facilityId: string | null | undefined;
              if (modeSelected === "TRAINER") {
                trainerId = resource?.id;
              } else if (modeSelected === "FACILITY") {
                facilityId = resource?.id;
              }
              setModal(
                <ChooseEventTypeModal
                  onClose={hideModal}
                  onClickButton={(eventType) => {
                    if (eventType === "OCCURRENCE") {
                      openEventOccurrenceFormSideBar(
                        moment(dateStr).format(DATE_TIME_FORMAT),
                        false,
                        trainerId ? trainerId : null,
                        facilityId ? +facilityId : null,
                      );
                    } else if (eventType === "APPOINTMENT") {
                      openAppointmentFormSideBar({
                        referenceDate: moment(dateStr).format(
                          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                        ),
                        host: resource && {
                          name: resource.title,
                          id: resource.id,
                        },
                      });
                    }
                    hideModal();
                  }}
                />,
              );
            }
          }}
          allDaySlot={false}
          headerToolbar={false}
          ref={(ref) => {
            if (!ref) return;
            calendarApiRef.current = ref;

            if (marker && calendarApiRef.current) {
              const markerAsString = moment(marker, "HH:mm").format("h:mm A");
              const timegridSlotsEls = document.querySelectorAll(
                ".fc-timegrid-slot:first-child>div>div>div>div",
              );
              for (let i = 0; i < timegridSlotsEls.length; i++) {
                const el = timegridSlotsEls[i];
                if (el?.textContent?.includes(markerAsString)) {
                  el.scrollIntoView({ behavior: "auto", block: "center" });
                  const root = document.querySelector("#root");
                  if (root) {
                    root.scroll(0, 0);
                  }
                  break;
                }
              }
            }
          }}
          editable
          eventDrop={dragAndDropHandler}
          eventResizableFromStart
          eventResize={(eventArg) => {
            if (eventArg.event.extendedProps["type"] !== "OCCURRENCE") {
              toast({
                message: "Appointments cannot be resized from the Calendar.",
                intent: "warning",
              });
              eventArg.revert();
              return;
            }

            return dragAndDropHandler(eventArg);
          }}
          resources={resources}
          schedulerLicenseKey={environment.get("FULLCALENDAR_LICENSE")}
        />
      </div>
    </div>
  );
}

function CalendarWithProviders() {
  return (
    <SideBarOccurrenceFormProvider>
      <SideBarAppointmentFormProvider>
        <SideBarAppointmentProvider>
          <Calendar />
        </SideBarAppointmentProvider>
      </SideBarAppointmentFormProvider>
    </SideBarOccurrenceFormProvider>
  );
}

export { CalendarWithProviders as Calendar };
