import { action, thunk } from 'easy-peasy';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';

class CreditPackModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId, settingsKey = 'settings') {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;
    this.withActiveInactive();
    this.generators.push(this.creditPackGenerator);
  }

  /**
   * @private
   */
  creditPackGenerator() {
    return {
      categories: [],
      create: thunk(
        (
          _,
          {
            name,
            description,
            activityCategoryIdList,
            price,
            expiryType,
            expiryValue,
            sessionsIncluded,
            sessionsUnlimited,
            termsConditions,
            isPublic,
          },
          { injections, getState }
        ) => {

          const { defaultTaxRate } = injections.globalStore.getState()[this.settingsKey];

          return getApi(injections, getState()).create(
            {
              name,
              description,
              activityCategoryIdList,
              price,
              expiryType,
              expiryValue,
              sessionsIncluded,
              sessionsUnlimited,
              clubIdList: [this.clubId],
              taxRate: defaultTaxRate,
              isPublic,
            },
            termsConditions
          );
        }
      ),

      update: thunk((_, { id, patchedFields }, { injections, getState }) => {
        const updatedCreditPack = { ...patchedFields };
        delete updatedCreditPack.termsConditions;

        return getApi(injections, getState()).update(id, {
          patchedFields: updatedCreditPack,
          termsAndConditionsBlob: patchedFields.termsConditions,
        });
      }),

      fetchList: thunk(
        async (actions, { page = 0, limit = 10, sort, status, extraParams }, { getState, injections }) => {
          const { data } = await getApi(injections, getState()).findByClubId(this.clubId, {
            page,
            limit,
            sort,
            status,
            extraParams,
          });
          const payload = createPayloadFromFindResponse(data);
          actions.fetchedList({ ...payload, filter: { sort, status, ...extraParams } });
          return payload;
        }
      ),

      fetchCategories: thunk(async (actions, _, { injections }) => {
        const { data } = await getApi(injections, { apiKey: 'activity' }).fetchCategories();
        actions.fetchedCategories(data);
      }),

      fetchedCategories: action((state, categories) => {
        state.categories = categories;
      }),
    };
  }
}

export default CreditPackModelBuilder;
