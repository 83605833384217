import { useLocalStorage } from "usehooks-ts";

import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { useAuthenticatedUser } from "../../../providers";
import { CalendarFilters, newDefaultCalendarFilters } from "../../organisms";

export function useCalendarMode() {
  const { id: loggedInId } = useAuthenticatedUser();
  const { routeId } = usePortalRoutes();
  const [modeSelected, setModeSelected] = useLocalStorage<CalendarMode>(
    buildModeLocalStorageKey(loggedInId as string, routeId),
    "EVERYTHING",
  );

  const [standardFilters, setStandardFilters] =
    useLocalStorage<CalendarFilters>(
      buildFilterLocalStorageKey(loggedInId as string, routeId),
      newDefaultCalendarFilters(),
    );
  const [trainerModeFilters, setTrainerModeFilters] =
    useLocalStorage<CalendarFilters>(
      buildTrainerFilterLocalStorageKey(loggedInId as string, routeId),
      newDefaultCalendarFilters(),
    );

  const [facilityModeFilters, setFacilityModeFilters] =
    useLocalStorage<CalendarFilters>(
      buildFacilityFilterLocalStorageKey(loggedInId as string, routeId),
      newDefaultCalendarFilters(),
    );

  let setFilters, filters;
  switch (modeSelected) {
    case "TRAINER":
      setFilters = setTrainerModeFilters;
      filters = trainerModeFilters;
      break;
    case "FACILITY":
      setFilters = setFacilityModeFilters;
      filters = facilityModeFilters;
      break;
    default:
      setFilters = setStandardFilters;
      filters = standardFilters;
  }

  return {
    modeSelected,
    setModeSelected,
    setFilters,
    filters,
  };
}

function buildFilterLocalStorageKey(userId: string, routeId: string) {
  return `calendar-filter-${userId}-${routeId}`;
}

function buildTrainerFilterLocalStorageKey(userId: string, routeId: string) {
  return `calendar-trainer-filter-${userId}-${routeId}`;
}

function buildFacilityFilterLocalStorageKey(userId: string, routeId: string) {
  return `calendar-facility-filter-${userId}-${routeId}`;
}

function buildModeLocalStorageKey(userId: string, routeId: string) {
  return `calendar-mode-${userId}-${routeId}`;
}

export type CalendarMode =
  | "EVERYTHING"
  | "CLASSES"
  | "APPOINTMENTS"
  | "TRAINER"
  | "FACILITY";
