import Customize from "./Customize";
import GenerateLink from "./GenerateLink";
import { Links } from "./Links";

export function HostedPages() {
  return (
    <div className="relative h-full max-h-full overflow-y-auto">
      <div className="absolute inset-0 grid grid-cols-2 gap-4">
        <div className="col-span-2 lg:col-span-1">
          <Customize />
        </div>
        <div className="col-span-2 lg:col-span-1" lg="6">
          <div>
            <Links />
          </div>
          <div className="mt-4">
            <GenerateLink />
          </div>
        </div>
      </div>
    </div>
  );
}
