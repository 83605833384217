import React, { useRef } from "react";

export type FileInputLabelProps = {
  accept: string;
  value: File | null | { name: string };
  onChange: (file: File | null) => void;
};
export const FileInputLabel: React.FC<FileInputLabelProps> = ({
  accept,
  value,
  onChange,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);
  return (
    <div>
      {!value ? (
        <div
          className="text-primary-700 cursor-pointer text-sm font-semibold"
          onClick={() => fileInput.current?.click()}
        >
          Upload
        </div>
      ) : (
        <div className=" flex cursor-pointer flex-row items-center gap-x-1 text-sm font-semibold">
          <div
            className="text-primary-700 cursor-pointer"
            onClick={() => fileInput.current?.click()}
          >
            ({value.name})
          </div>
          <div onClick={() => onChange(null)} className="text-error-600">
            <i className="tim-icons icon-trash-simple" />
          </div>
        </div>
      )}
      <input
        accept={accept}
        type="file"
        className="hidden"
        ref={fileInput}
        onChange={(v) => {
          if (v.target.files?.length === 0) {
            return;
          }
          const file = v.target.files?.[0];
          if (file) {
            onChange(file);
            if (fileInput.current) {
              fileInput.current.value = "";
            }
          }
        }}
      />
    </div>
  );
};
