import {
  ActivityModelBuilder,
  ClubModelBuilder,
  CreditPackModelBuilder,
  CustomerEventModelBuilder,
  EmailTemplateModelBuilder,
  EventModelBuilder,
  FacilityModelBuilder,
  HostedClubModelBuilder,
  InvoiceModelBuilder,
  LeadSourceModelBuilder,
  MemberModelBuilder,
  ProductModelBuilder,
  ProfileModelBuilder,
  PromotionModelBuilder,
  ServiceModelBuilder,
  SettingsModelBuilder,
  StaffModelBuilder,
  TaskModelBuilder,
  TimelineModelBuilder,
} from "@gymflow/common";
import * as Sentry from "@sentry/react";
import { createContextStore, createStore } from "easy-peasy";
import React, { useContext, useMemo } from "react";

import AutomationModelBuilder from "./AutomationModelBuilder";
import DashboardModelBuilder from "./DashboardModelBuilder";
import {
  ACTIVITY_STORE_KEY,
  AUTH_STORE_KEY,
  AUTOMATION_STORE_KEY,
  CLUB_STORE_KEY,
  COMPANY_STORE_KEY,
  CREDIT_PACK_STORE_KEY,
  CUSTOMER_CLUB_API_KEY,
  CUSTOMER_OCCURRENCE_STORE_KEY,
  EMAIL_TEMPLATE_STORE_KEY,
  EVENT_STORE_KEY,
  FACILITY_STORE_KEY,
  GLOBAL_NOTIFICATIONS_STORE_KEY,
  HOSTED_CLUB_STORE_KEY,
  INVOICES_STORE_KEY,
  LEAD_SOURCE_STORE_KEY,
  LEAD_STORE_KEY,
  MEMBER_STORE_KEY,
  NOTE_STORE_KEY,
  PRODUCT_STORE_KEY,
  PROMOTION_STORE_KEY,
  PUBLIC_CREDIT_PACK_STORE_KEY,
  PUBLIC_HOSTED_CLUB_STORE_KEY,
  PUBLIC_RULE_STORE_KEY,
  PUBLIC_SERVICE_STORE_KEY,
  REVENUE_STORE_KEY,
  RULE_STORE_KEY,
  SERVICE_STORE_KEY,
  SETTINGS_STORE_KEY,
  STAFF_STORE_KEY,
  STATISTICS_STORE_KEY,
  TASK_STORE_KEY,
  TIMELINE_STORE_KEY,
  USER_MEMBER_STORE_KEY,
} from "./keys";
import LeadModelBuilder from "./LeadModelBuilder";
import NotificationModelBuilder from "./NotificationModelBuilder";
import RevenueModelBuilder from "./RevenueModelBuilder";

export * from "./callbacks";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();
export const GymflowModelsContext = React.createContext(null);

export const useGymflowModelsInitializer = ({
  api,
  authModelBuilderInstance,
  clubId,
}) => {
  const isAuthEnabled = !!authModelBuilderInstance;

  const gymflowStores = useMemo(() => {
    if (!clubId) {
      return {
        localStores: null,
        globalStore: null,
      };
    }

    const apiInjections = {
      [CLUB_STORE_KEY]: api.clubApi,
      [COMPANY_STORE_KEY]: api.companyApi,
      [STAFF_STORE_KEY]: api.staffApi,
      [MEMBER_STORE_KEY]: api.memberApi,
      [PRODUCT_STORE_KEY]: api.productApi,
      [SERVICE_STORE_KEY]: isAuthEnabled
        ? api.serviceApi
        : api.public.serviceApi,
      [CREDIT_PACK_STORE_KEY]: isAuthEnabled
        ? api.creditPackApi
        : api.public.creditPackApi,
      [FACILITY_STORE_KEY]: api.facilityApi,
      [ACTIVITY_STORE_KEY]: api.activityApi,
      [LEAD_SOURCE_STORE_KEY]: api.leadSourceApi,
      [EVENT_STORE_KEY]: isAuthEnabled ? api.eventApi : api.public.eventApi,
      [TASK_STORE_KEY]: api.taskApi,
      [NOTE_STORE_KEY]: api.noteApi,
      [TIMELINE_STORE_KEY]: api.timelineApi,
      [STATISTICS_STORE_KEY]: api.statisticsApi,
      [USER_MEMBER_STORE_KEY]: api.profileApi,
      [CUSTOMER_OCCURRENCE_STORE_KEY]: api.customerOccurrenceApi,
      [CUSTOMER_CLUB_API_KEY]: api.customerClubApi,
      [PUBLIC_SERVICE_STORE_KEY]: api.public.serviceApi,
      [PUBLIC_CREDIT_PACK_STORE_KEY]: api.public.creditPackApi,
      [HOSTED_CLUB_STORE_KEY]: api.hostedClubApi,
      [RULE_STORE_KEY]: api.ruleApi,
      [PUBLIC_RULE_STORE_KEY]: api.public.ruleApi,
      [PUBLIC_HOSTED_CLUB_STORE_KEY]: api.public.hostedClubApi,
      [LEAD_STORE_KEY]: api.leadApi,
      [AUTOMATION_STORE_KEY]: api.automationApi,
      [EMAIL_TEMPLATE_STORE_KEY]: api.emailTemplateApi,
      [REVENUE_STORE_KEY]: api.revenueApi,
      [PROMOTION_STORE_KEY]: api.promotionApi,
    };

    let stores = {
      [SETTINGS_STORE_KEY]: new SettingsModelBuilder(
        SETTINGS_STORE_KEY,
        clubId,
      ).build(),
    };

    if (isAuthEnabled) {
      stores = {
        ...stores,
        [AUTH_STORE_KEY]: authModelBuilderInstance.build(),
        [CLUB_STORE_KEY]: new ClubModelBuilder(CLUB_STORE_KEY).build(),
        [STAFF_STORE_KEY]: new StaffModelBuilder(STAFF_STORE_KEY).build(),
        [PRODUCT_STORE_KEY]: new ProductModelBuilder(
          PRODUCT_STORE_KEY,
          clubId,
        ).build(),
        [FACILITY_STORE_KEY]: new FacilityModelBuilder(
          FACILITY_STORE_KEY,
          clubId,
        ).build(),
        [ACTIVITY_STORE_KEY]: new ActivityModelBuilder(
          ACTIVITY_STORE_KEY,
          clubId,
        ).build(),
        [LEAD_SOURCE_STORE_KEY]: new LeadSourceModelBuilder(
          LEAD_SOURCE_STORE_KEY,
          clubId,
        ).build(),
        [GLOBAL_NOTIFICATIONS_STORE_KEY]:
          new NotificationModelBuilder().build(),
      };
    }

    const globalStore = createStore(stores, {
      name: "Gymflow Global Store",
      injections: {
        api: apiInjections,
      },
      enhancers: [sentryReduxEnhancer],
    });

    const MemberStore =
      isAuthEnabled &&
      createContextStore(new MemberModelBuilder(MEMBER_STORE_KEY).build(), {
        name: `Gymflow ${MEMBER_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      });

    const ServiceStore = createContextStore(
      new ServiceModelBuilder(SERVICE_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${SERVICE_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const CreditPackStore = createContextStore(
      new CreditPackModelBuilder(CREDIT_PACK_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${CREDIT_PACK_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const InvoiceStore =
      isAuthEnabled &&
      createContextStore(new InvoiceModelBuilder(MEMBER_STORE_KEY).build(), {
        name: `Gymflow ${INVOICES_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      });

    const UserInvoiceStore =
      isAuthEnabled &&
      createContextStore(
        new InvoiceModelBuilder(USER_MEMBER_STORE_KEY).build(),
        {
          name: `Gymflow ${INVOICES_STORE_KEY} store`,
          injections: { api: apiInjections, globalStore },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const UserMemberStore =
      isAuthEnabled &&
      createContextStore(
        new ProfileModelBuilder(USER_MEMBER_STORE_KEY).build(),
        {
          name: `Gymflow ${USER_MEMBER_STORE_KEY} store`,
          injections: { api: apiInjections, globalStore },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const LeadStore =
      isAuthEnabled &&
      createContextStore(new LeadModelBuilder(LEAD_STORE_KEY).build(), {
        name: `Gymflow ${LEAD_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      });

    const EventStore = createContextStore(
      new EventModelBuilder(EVENT_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${EVENT_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const TaskStore =
      isAuthEnabled &&
      createContextStore(new TaskModelBuilder(TASK_STORE_KEY, clubId).build(), {
        name: `Gymflow ${TASK_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      });

    const TimelineStore =
      isAuthEnabled &&
      createContextStore(
        new TimelineModelBuilder(TIMELINE_STORE_KEY, NOTE_STORE_KEY).build(),
        {
          name: `Gymflow ${TIMELINE_STORE_KEY} store`,
          injections: { api: apiInjections, globalStore },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const HostedStore = createContextStore(
      new HostedClubModelBuilder(HOSTED_CLUB_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${HOSTED_CLUB_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const CustomerOccurrenceStore =
      isAuthEnabled &&
      createContextStore(
        new CustomerEventModelBuilder(
          CUSTOMER_OCCURRENCE_STORE_KEY,
          clubId,
        ).build(),
        {
          name: `Gymflow ${CUSTOMER_OCCURRENCE_STORE_KEY} store`,
          injections: { api: apiInjections, globalStore },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const PublicServiceStore = createContextStore(
      new ServiceModelBuilder(PUBLIC_SERVICE_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${PUBLIC_SERVICE_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const PublicCreditPackStore = createContextStore(
      new CreditPackModelBuilder(PUBLIC_CREDIT_PACK_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${PUBLIC_CREDIT_PACK_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const PublicHostedClubStore = createContextStore(
      new HostedClubModelBuilder(PUBLIC_HOSTED_CLUB_STORE_KEY, clubId).build(),
      {
        name: `Gymflow ${PUBLIC_HOSTED_CLUB_STORE_KEY} store`,
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      },
    );

    const DashboardStore =
      isAuthEnabled &&
      createContextStore(
        new DashboardModelBuilder(STATISTICS_STORE_KEY, clubId).build(),
        {
          name: "Gymflow Dashboard store",
          injections: { api: apiInjections },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const AutomationStore =
      isAuthEnabled &&
      createContextStore(
        new AutomationModelBuilder(AUTOMATION_STORE_KEY).build(),
        {
          name: "Gymflow Automations store",
          injections: { api: apiInjections },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const EmailTemplateStore =
      isAuthEnabled &&
      createContextStore(
        new EmailTemplateModelBuilder(
          EMAIL_TEMPLATE_STORE_KEY,
          AUTOMATION_STORE_KEY,
          clubId,
        ).build(),
        {
          name: "Gymflow Email Template store",
          injections: { api: apiInjections },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const RevenueStore =
      isAuthEnabled &&
      createContextStore(new RevenueModelBuilder(REVENUE_STORE_KEY).build(), {
        name: "Gymflow Revenue store",
        injections: { api: apiInjections, globalStore },
        enhancers: [sentryReduxEnhancer],
      });

    const PromotionStore =
      isAuthEnabled &&
      createContextStore(
        new PromotionModelBuilder(
          PROMOTION_STORE_KEY,
          SERVICE_STORE_KEY,
          CREDIT_PACK_STORE_KEY,
          PRODUCT_STORE_KEY,
          SETTINGS_STORE_KEY,
          clubId,
        ).build(),
        {
          name: "Gymflow Promotion store",
          injections: { api: apiInjections, globalStore },
          enhancers: [sentryReduxEnhancer],
        },
      );

    const authStore = (store) => store[AUTH_STORE_KEY];

    const clubStore = (store) => store[CLUB_STORE_KEY];
    const staffStore = (store) => store[STAFF_STORE_KEY];
    const productStore = (store) => store[PRODUCT_STORE_KEY];
    const facilityStore = (store) => store[FACILITY_STORE_KEY];
    const activityStore = (store) => store[ACTIVITY_STORE_KEY];
    const globalNotificationsStore = (store) =>
      store[GLOBAL_NOTIFICATIONS_STORE_KEY];
    const settingsStore = (store) => store[SETTINGS_STORE_KEY];
    const leadSourceStore = (store) => store[LEAD_SOURCE_STORE_KEY];
    const hostedClubStore = (store) => store[HOSTED_CLUB_STORE_KEY];

    return {
      localStores: {
        authStore,
        clubStore,
        staffStore,
        productStore,
        facilityStore,
        activityStore,
        globalNotificationsStore,
        settingsStore,
        leadSourceStore,
        hostedClubStore,
        MemberStore,
        ServiceStore,
        CreditPackStore,
        InvoiceStore,
        UserInvoiceStore,
        UserMemberStore,
        LeadStore,
        EventStore,
        TaskStore,
        TimelineStore,
        HostedStore,
        CustomerOccurrenceStore,
        PublicServiceStore,
        PublicCreditPackStore,
        PublicHostedClubStore,
        DashboardStore,
        AutomationStore,
        EmailTemplateStore,
        RevenueStore,
        PromotionStore,
        authController: authModelBuilderInstance?.getController(),
        api,
      },
      globalStore,
    };
  }, [clubId]);

  return gymflowStores;
};

const useGymflowModels = () => {
  const {
    authStore,
    clubStore,
    staffStore,
    productStore,
    facilityStore,
    activityStore,
    globalNotificationsStore,
    settingsStore,
    leadSourceStore,
    hostedClubStore,
    MemberStore,
    ServiceStore,
    CreditPackStore,
    InvoiceStore,
    UserInvoiceStore,
    UserMemberStore,
    LeadStore,
    EventStore,
    TaskStore,
    TimelineStore,
    HostedStore,
    CustomerOccurrenceStore,
    PublicServiceStore,
    PublicCreditPackStore,
    PublicHostedClubStore,
    DashboardStore,
    AutomationStore,
    EmailTemplateStore,
    RevenueStore,
    PromotionStore,
    authController,
    api,
  } = useContext(GymflowModelsContext);

  return {
    /**
     * @deprecated use AuthenticatedUserContext
     */
    authStore,
    /**
     * @deprecated useClub instead
     */
    clubStore,
    /**
     * @deprecated useStaff instead
     */
    staffStore,
    /**
     * @deprecated useProduct instead
     */
    productStore,
    /**
     * @deprecated useFacility instead
     */
    facilityStore,
    /**
     * @deprecated useActivity instead
     */
    activityStore,
    /**
     * @deprecated not implemented in the org model
     */
    globalNotificationsStore,
    /**
     * @deprecated providers/useClubSettings instead
     */
    settingsStore,
    /**
     * @deprecated useLeadSource instead
     */
    leadSourceStore,
    /**
     * @deprecated create useClubAsPublic
     */
    hostedClubStore,
    /**
     * @deprecated useMember instead
     */
    MemberStore,
    /**
     * @deprecated useMembership instead
     */
    ServiceStore,
    /**
     * @deprecated useCreditPack instead
     */
    CreditPackStore,
    /**
     * @deprecated useMemberInvoice instead
     */
    InvoiceStore,
    /**
     * @deprecated useMemberInvoiceAsMember instead
     */
    UserInvoiceStore,
    /**
     * @deprecated create useMemberAsMember
     */
    UserMemberStore,
    /**
     * @deprecated useLead instead
     */
    LeadStore,
    /**
     * @deprecated useEventOccurrence instead
     */
    EventStore,
    /**
     * @deprecated useTask instead
     */
    TaskStore,
    /**
     * @deprecated useMemberTimeline instead
     */
    TimelineStore,
    /**
     * @deprecated create tanstack query hook to replace
     */
    HostedStore,
    /**
     * @deprecated useEventOccurrenceAsMember instead
     */
    CustomerOccurrenceStore,
    /**
     * @deprecated create useMembershipAsPublic
     */
    PublicServiceStore,
    /**
     * @deprecated create useCreditPackAsPublic
     */
    PublicCreditPackStore,
    /**
     * @deprecated
     */
    PublicHostedClubStore,
    /**
     * @deprecated no longer needed
     */
    DashboardStore,
    /**
     * @deprecated useAutomation instead
     */
    AutomationStore,
    /**
     * @deprecated useEmailTemplate instead
     */
    EmailTemplateStore,
    /**
     * @deprecated no longer needed
     */
    RevenueStore,
    /**
     * @deprecated usePromotion instead
     */
    PromotionStore,
    authController,
    api,
  };
};

export default useGymflowModels;
