import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import {StaffUserRole, UserStaffBean} from '@gymflow/types';

import {staffQueryKeys} from './staffQueryKeys';

export function useStaffChangeRole(
  {
    api,
  }: {
    api: {
      staffApi: {
        setRole: (
          clubId: number,
          staffId: string,
          role: StaffUserRole,
        ) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    UserStaffBean,
    unknown,
    {clubId: number; staffId: string; role: StaffUserRole}
  >,
) {
  const queryClient = useQueryClient();

  const changeRoleMutation = useMutation({
    mutationFn: async ({
      clubId,
      staffId,
      role,
    }: {
      clubId: number;
      staffId: string;
      role: StaffUserRole;
    }) => {
      const response = await api.staffApi.setRole(clubId, staffId, role);
      return response.data as UserStaffBean;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: staffQueryKeys.all(),
      });
    },
    ...mutationOpts,
  });

  return changeRoleMutation;
}
