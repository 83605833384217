import { thunk } from 'easy-peasy';

import BaseModelBuilder, { getApi, createPayloadFromFindResponse } from './BaseModelBuilder';

class LeadSourceModelBuilder extends BaseModelBuilder {
  constructor(apiKey) {
    super(apiKey);
    this.withActiveInactive();
    this.generators.push(this.leadSourceGenerator);
  }

  /**
   * @private
   */
  leadSourceGenerator() {
    return {
      create: thunk((_, { name }, { injections, getState }) => {
        return getApi(injections, getState()).create({
          name
        });
      }),
      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, extraParams }, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).find({
          page,
          limit,
          sort,
          extraParams,
        });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList({ ...payload, filter: { sort, ...extraParams } });
        return payload;
      }),

      activateLeadSource: thunk((_, id, { injections, getState }) => {
        const api = getApi(injections, getState());
        return api.active(id);
      }),
      inactivateLeadSource: thunk((_, id, { injections, getState }) => {
        const api = getApi(injections, getState());
        return api.inactive(id);
      }),
    };
  }
}

export default LeadSourceModelBuilder;
