import { useMutation, useQueryClient } from "@tanstack/react-query";

import { productQueryKeys } from "./productQueryKeys";

export function useMutationProductPicture({
  api,
}: {
  api: {
    productApi: {
      addPicture: (payload: {
        id: number;
        picture: Blob;
      }) => Promise<{ data: string }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (payload: { productId: number; picture: Blob }) => {
      const response = await api.productApi.addPicture({
        id: payload.productId,
        picture: payload.picture,
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productQueryKeys.all() });
    },
  });

  return mutation;
}
