import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import uniqueId from "lodash/uniqueId";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { Button, Popover } from "reactstrap";

import calendarIcon from "./../../../assets/img/calendar.svg";
import style from "./DateRangePicker.module.css";

function DateRangePicker({
  value,
  onChange,
  onSelectionChange,
  dateFormat,
  displayDateFormat,
  placeholder,
  className,
  ...rest
}: {
  [key: string]: any;
}) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const [dates, setDates] = useState(value);
  const [tooltipId] = useState(uniqueId("date-picker-"));

  useEffect(() => {
    if (popoverOpen) {
      setDates(value);
    }
  }, [popoverOpen]);

  const defaultRanges = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  if (dates) {
    defaultRanges.startDate = moment(dates.startDate, dateFormat).toDate();
    defaultRanges.endDate = moment(dates.endDate, dateFormat).toDate();
  }

  const renderLabel = () => {
    if (value) {
      if (value.startDate === value.endDate) {
        return moment(value.startDate, dateFormat).format(displayDateFormat);
      }
      return `${moment(value.startDate, dateFormat).format(
        displayDateFormat,
      )} - ${moment(value.endDate, dateFormat).format(displayDateFormat)}`;
    }

    return placeholder;
  };

  return (
    <div className={className}>
      <div
        id={tooltipId}
        className="bg-gray-25 flex h-11 cursor-pointer flex-row items-center rounded-lg border border-gray-300 px-4 font-semibold text-gray-500 hover:bg-gray-100"
      >
        <img
          src={calendarIcon}
          alt={"Calendar Icon"}
          className="mr-2 text-gray-600"
        />
        <div className="max-w-[150px] truncate">{renderLabel()}</div>
      </div>
      <Popover
        placement="bottom"
        target={tooltipId}
        popperClassName={style["popover"]}
        isOpen={popoverOpen}
        toggle={toggle}
      >
        <DateRange
          firstDayOfWeek={1}
          format={dateFormat}
          ranges={[defaultRanges]}
          onChange={({ selection: { startDate, endDate } }: any) => {
            setDates({
              startDate: moment(startDate).format(dateFormat),
              endDate: moment(endDate).format(dateFormat),
            });
            onSelectionChange &&
              onSelectionChange({
                startDate: moment(startDate).format(dateFormat),
                endDate: moment(endDate).format(dateFormat),
              });
          }}
          {...rest}
          // @ts-ignore
          theme={{
            Calendar: { width: 200 },
            PredefinedRanges: { marginLeft: 10, marginTop: 10 },
          }}
        />
        <div className="d-flex justify-content-between">
          <Button
            className="btn-link"
            onClick={() => {
              onChange(dates);
              toggle();
            }}
          >
            Ok
          </Button>
          <Button
            className="btn-link"
            onClick={() => {
              onChange(null);
              toggle();
            }}
          >
            Clear
          </Button>
        </div>
      </Popover>
    </div>
  );
}

DateRangePicker.defaultProps = {
  dateFormat: "DD-MM-YYYY",
  displayDateFormat: "ll",
  placeholder: "Date Filter",
  className: "",
};

DateRangePicker.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string,
  displayDateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  disabledDay: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

export default DateRangePicker;
