import { useMutationLeadDelete } from "@gymflow/api";
import { FormMapper, MobileNumberInput, useRecordForm } from "@gymflow/common";
import { LeadDTO } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { LabeledFormikInput } from "libs/common/src/lib/components/molecules/FormikInput";
import noop from "lodash/noop";
import { useCallback, useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";

import useLeadSourceLoadOptions from "../../hooks/useLeadSourceLoadOptions";
import useLeadStatusLoadOptions from "../../hooks/useLeadStatusLoadOptions";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { ModalContext, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { Button, PaginatedSelect, PrimaryButton, TextButton } from "../atoms";
import { AccentButton } from "../atoms/AccentButton";
import { FormLabel } from "../atoms/FormLabel";
import createLeadSchema, {
  EMAIL,
  EMAIL_COMMUNICATION,
  FIRST_NAME,
  LAST_NAME,
  LEAD_STATUS,
  PHONE_NUMBER,
  SMS_COMMUNICATION,
  SOURCE,
} from "../Leads/schema";
import { NewUserSideBarProviderContext } from "../molecules";
import Switch from "../Switch";
import { ConfirmModal } from "../templates";
import createRecordFormDefaultParams from "./recordFormDefaultParams";
import { SettingsSection } from "./SettingsSection";

function LeadDetailsForm({
  user,
  updateUser,
  updateLeadStatus,
}: {
  user: LeadDTO;
  updateUser: (...params: any[]) => any;
  updateLeadStatus: (...params: any[]) => any;
}) {
  const settings = useClubSettings();

  const leadStatusLoadOptions = useLeadStatusLoadOptions();
  const leadSourceLoadOptions = useLeadSourceLoadOptions();

  const { toast, notifyDanger: onSubmitError } = useContext(ToastContext);

  const mapper = new FormMapper({
    inValue: [
      {
        key: "source",
        transform: (v: any, others: any) => {
          others["sourceId"] = v.id;
          return v;
        },
      },
    ],
    outValue: [
      {
        key: "email",
        transform: (v: any) => v.toLowerCase(),
      },
    ],
  });

  const recordFormDefaultParams = useMemo(() => {
    // TODO: Investigate type not matching
    // @ts-ignore
    return createRecordFormDefaultParams({
      dateFormat: settings.date_format,
      postalCodeCountry: settings.postal_code_country,
      defaultNationality: settings.default_nationality,
    });
  }, [
    settings.postal_code_country,
    settings.date_format,
    settings.default_nationality,
  ]);
  const { initialValues, getPatchedValues } = useRecordForm({
    ...recordFormDefaultParams,
    mapper,
    record: user,
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    validateOnBlur: true,
    initialValues,
    validationSchema: createLeadSchema(),
    onSubmit: noop,
  });

  const { values, validateForm } = formikProps;

  const handleSubmit = useCallback(async () => {
    try {
      const isValidForm = await validateForm();
      if (Object.keys(isValidForm).length > 0) {
        return;
      }

      await updateUser({
        id: user.id,
        patchedFields: getPatchedValues(values),
      });
    } catch (e) {
      onSubmitError(e as any);
    }
  }, [values, user, validateForm, updateUser, getPatchedValues, onSubmitError]);
  const { api } = useGymflowModels();
  const { setModal, hide } = useContext(ModalContext);
  const { createClubLink } = usePortalRoutes();
  const [currentEditingSectionIndex, setCurrentEditingSectionIndex] =
    useState<number>();
  const history = useHistory();
  const updateNotificationPreferences = useMutation({
    mutationFn: async ({
      key,
      value,
    }: {
      key: "emailCommunication" | "pushCommunication" | "smsCommunication";
      value: boolean;
    }) => {
      const payload = { id: user.id, patchedFields: { [key]: value } };
      await updateUser(payload);
    },
  });
  const { open } = useContext(NewUserSideBarProviderContext);
  const { mutateAsync: deleteLead } = useMutationLeadDelete({ api });
  return (
    <div className="-mt-4 flex flex-col pb-48">
      {[
        {
          title: (
            <div className="flex flex-col">
              <div className="text-base font-semibold">Account</div>
              <div className="text-sm text-gray-400">
                Setup or update the users account
              </div>
            </div>
          ),
          content: (index: number) => (
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-y-2 p-6">
                <div className="flex flex-col gap-y-2">
                  <LabeledFormikInput
                    name={EMAIL}
                    type="text"
                    data-testid={EMAIL}
                    autoComplete="off"
                    maxLength={128}
                    placeholder="Email"
                    formikProps={formikProps}
                    disabled={currentEditingSectionIndex !== index}
                  />
                  <div className="text-xs text-gray-600">
                    This user does not yet have an account, click setup to send
                    them an email to create their account.
                  </div>
                </div>
              </div>
              <div className="border-b border-gray-200" />
              <div className="flex w-full flex-row flex-wrap justify-end gap-4 p-6">
                <TextButton
                  onClick={() => {
                    setModal(
                      <ConfirmModal
                        type="danger"
                        title="Delete Lead"
                        onCancel={hide}
                        cancelText="Cancel"
                        confirmText="Delete"
                        onConfirm={async () => {
                          await deleteLead({ leadId: user.id });
                          toast({ message: `User successfully deleted.` });
                          history.push(
                            createClubLink(
                              RouteLayout.Staff,
                              RouteFeature.UserMemberList,
                            ),
                          );
                          hide();
                        }}
                      >
                        Are you sure you want to delete this lead?
                      </ConfirmModal>,
                    );
                  }}
                  className="text-error-500 p-0"
                >
                  Delete
                </TextButton>
                {currentEditingSectionIndex !== index && (
                  <>
                    <Button
                      onClick={() => {
                        formikProps.resetForm();
                        setCurrentEditingSectionIndex(index);
                      }}
                    >
                      <div className="text-sm">Edit</div>
                    </Button>
                    <AccentButton
                      onClick={() => {
                        open({
                          creationMode: "MEMBER",
                          defaultFormValues: {
                            email: values.email,
                            "mobile-number": values["mobile-number"],
                            "first-name": values["first-name"],
                            "last-name": values["last-name"],
                            source: values.source,
                          },
                          onClose: async (arg) => {
                            if (arg?.userType === "MEMBER") {
                              history.push(
                                createClubLink(
                                  RouteLayout.Staff,
                                  RouteFeature.UserMember.replace(
                                    ":id",
                                    arg.userMemberId,
                                  ),
                                ),
                              );
                            }
                          },
                        });
                      }}
                      className="mt-0"
                    >
                      <div className="text-sm">Setup Account</div>
                    </AccentButton>
                  </>
                )}
                {currentEditingSectionIndex === index && (
                  <PrimaryButton
                    onClick={async () => {
                      await handleSubmit();
                      setCurrentEditingSectionIndex(undefined);
                    }}
                    className="mt-0"
                  >
                    <div className="text-sm">Save</div>
                  </PrimaryButton>
                )}
              </div>
            </div>
          ),
        },
        {
          title: (
            <div className="flex flex-col">
              <div className="text-base font-semibold">Profile Information</div>
              <div className="text-sm text-gray-400">
                Update the users basic information.
              </div>
            </div>
          ),
          content: (index: number) => (
            <div className="flex w-full flex-col">
              <div className="grid w-full grid-cols-1 gap-4 p-6 lg:grid-cols-2">
                <LabeledFormikInput
                  name={FIRST_NAME}
                  type="text"
                  data-testid={FIRST_NAME}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="First Name"
                  formikProps={formikProps}
                  disabled={currentEditingSectionIndex !== index}
                />

                <LabeledFormikInput
                  name={LAST_NAME}
                  type="text"
                  data-testid={LAST_NAME}
                  autoComplete="off"
                  maxLength={128}
                  placeholder="Last Name"
                  formikProps={formikProps}
                  disabled={currentEditingSectionIndex !== index}
                />
                <LabeledFormikInput
                  name={PHONE_NUMBER}
                  component={MobileNumberInput}
                  type="text"
                  data-testid={PHONE_NUMBER}
                  autoComplete="off"
                  placeholder="Mobile Number"
                  maxLength={32}
                  formikProps={formikProps}
                  disabled={currentEditingSectionIndex !== index}
                  phone_number_country={settings.phone_number_country}
                />
                <div className="flex flex-col gap-y-1">
                  <FormLabel label="Lead Status" />
                  <PaginatedSelect
                    loadOptions={leadStatusLoadOptions as any}
                    onChange={async ({ value }) => {
                      formikProps.setFieldValue(LEAD_STATUS, value);
                    }}
                    value={
                      values[LEAD_STATUS] && {
                        value: values[LEAD_STATUS].id,
                        label: values[LEAD_STATUS].name,
                      }
                    }
                    isDisabled={currentEditingSectionIndex !== index}
                  />
                </div>
                <div></div>
                <div className="flex flex-col gap-y-1">
                  <FormLabel label="Lead Source" />
                  <PaginatedSelect
                    placeholder="Select Source"
                    loadOptions={leadSourceLoadOptions}
                    value={{
                      value: values.source.id,
                      label: values.source.name,
                    }}
                    onChange={(value) => {
                      formikProps.setFieldValue(SOURCE, {
                        id: value.value.id,
                        name: value.value.name,
                      });
                    }}
                    isDisabled={currentEditingSectionIndex !== index}
                  />
                </div>
              </div>

              <div className="border-b border-gray-200" />
              <div className="flex w-full flex-row justify-end gap-4 p-6">
                {currentEditingSectionIndex !== index && (
                  <Button
                    onClick={() => {
                      formikProps.resetForm();
                      setCurrentEditingSectionIndex(index);
                    }}
                  >
                    <div className="text-sm">Edit</div>
                  </Button>
                )}
                {currentEditingSectionIndex === index && (
                  <PrimaryButton
                    onClick={async () => {
                      await handleSubmit();
                      setCurrentEditingSectionIndex(undefined);
                    }}
                    className="mt-0"
                  >
                    <div className="text-sm">Save</div>
                  </PrimaryButton>
                )}
              </div>
            </div>
          ),
        },
        {
          title: (
            <div className="flex flex-col">
              <div className="text-base font-semibold">Notifications</div>
              <div className="text-sm text-gray-400">
                Set the users notification preferences.
              </div>
            </div>
          ),
          content: () => (
            <div className="flex w-full flex-col">
              <div className="flex w-full flex-col gap-y-2 p-6">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-y-2">
                    <div className="inline-flex text-sm font-semibold">
                      Email Marketing
                    </div>
                    <div className="inline-flex text-sm text-gray-400">
                      Allows you to send promotional emails to the user.
                    </div>
                  </div>
                  <Switch
                    data-testid="emailCommunication"
                    checked={values[EMAIL_COMMUNICATION]}
                    onChange={async (checked) => {
                      await updateNotificationPreferences.mutateAsync({
                        key: "emailCommunication",
                        value: checked,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col gap-y-2">
                    <div className="inline-flex text-sm font-semibold">
                      SMS Marketing
                    </div>
                    <div className="inline-flex text-sm text-gray-400">
                      Allows you to send promotional SMS to the user.
                    </div>
                  </div>
                  <Switch
                    data-testid="smsCommunication"
                    checked={values[SMS_COMMUNICATION]}
                    onChange={async (checked) => {
                      await updateNotificationPreferences.mutateAsync({
                        key: "smsCommunication",
                        value: checked,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ),
        },
      ].map((e, i) => {
        return (
          <SettingsSection key={i} title={e.title} content={e.content(i)} />
        );
      })}
    </div>
  );
}

export default LeadDetailsForm;
