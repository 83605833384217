import { cn } from "@gymflow/helpers";
import range from "lodash/range";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import { PaginatedSelect } from "./PaginatedSelect";

export interface TimeSelectProps {
  value: string;
  onChange: (newValue: string) => void;
  label?: string;
  fullWidth?: boolean;
}

const TIME_SELECT_FORMAT = "hh:mm a";
const valueAM = { value: "AM", label: "AM" };
const valuePM = { value: "PM", label: "PM" };

export function TimeSelect({
  value,
  onChange,
  label,
  fullWidth = false,
}: TimeSelectProps) {
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const hour = useMemo(() => {
    if (!internalValue) {
      return { value: null, label: "HH" };
    }
    const newHour = moment(internalValue, TIME_SELECT_FORMAT).format("h");
    return { value: +newHour, label: newHour };
  }, [internalValue]);

  const minutes = useMemo(() => {
    if (!internalValue) {
      return { value: null, label: "MM" };
    }
    const newMinutes = moment(internalValue, TIME_SELECT_FORMAT).minutes();
    return {
      value: newMinutes,
      label: newMinutes.toString().padStart(2, "0"),
    };
  }, [internalValue]);

  const amOrPm = useMemo(
    () =>
      moment(internalValue, TIME_SELECT_FORMAT).hour() < 12 ? valueAM : valuePM,
    [internalValue],
  );

  const loadOptionsHour = useCallback(async () => {
    const options = range(1, 13).map((hour) => {
      return { label: hour, value: hour };
    });
    return {
      options,
      hasMore: false,
    };
  }, []);

  const loadOptionsMinutes = useCallback(async () => {
    const options = range(0, 60, 5).map((minutes) => {
      return {
        label: minutes.toString().padStart(2, "0"),
        value: minutes,
      };
    });
    return {
      options,
      hasMore: false,
    };
  }, []);

  const loadOptionsAmOrPm = useCallback(
    async () => ({
      options: [valueAM, valuePM],
      hasMore: false,
    }),
    [],
  );

  const selectClassName = cn({ "flex-1": fullWidth });

  return (
    <div className="flex flex-col gap-1.5">
      {label && (
        <div className="text-sm font-medium text-gray-950">{label}</div>
      )}
      <div className="flex flex-row gap-2">
        <PaginatedSelect
          className={selectClassName}
          value={hour}
          onChange={(newHour) => {
            const minutesString = minutes.label === "MM" ? "00" : minutes.label;
            onChange(`${newHour.label}:${minutesString} ${amOrPm.label}`);
          }}
          loadOptions={loadOptionsHour}
        />
        <PaginatedSelect
          className={selectClassName}
          value={minutes}
          onChange={(newMinutes) => {
            const hourString = hour.label === "HH" ? "08" : hour.label;
            onChange(`${hourString}:${newMinutes.label} ${amOrPm.label}`);
          }}
          loadOptions={loadOptionsMinutes}
        />
        <PaginatedSelect
          className={selectClassName}
          value={amOrPm}
          onChange={(newAmOrPm) => {
            const minutesString = minutes.label === "MM" ? "00" : minutes.label;
            const hourString = hour.label === "HH" ? "08" : hour.label;
            onChange(`${hourString}:${minutesString} ${newAmOrPm.label}`);
          }}
          loadOptions={loadOptionsAmOrPm}
        />
      </div>
    </div>
  );
}
