const GuestStatus = {
  Booked: "BOOKED",
  Cancelled: "CANCELLED",
  LateCanceled: "LATE_CANCELLED",
  Attended: "ATTENDED",
  Waiting: "WAITING",
  NoShow: "NO_SHOW",
} as const;

export default GuestStatus;
