import { FormMapper, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import pick from "lodash/pick";
import moment from "moment-timezone";

import {
  APPOINTABLE_ID,
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
  APPOINTMENT_USER_ID,
} from "./AppointmentSchema";
import { MonthlyRecurringType } from "./MonthlyRadio";

export class AppointmentFormMapper extends FormMapper {
  isRecurring: boolean;
  monthlyRecurringType: MonthlyRecurringType;
  constructor({
    isRecurring,
    monthlyRecurringType,
  }: {
    isRecurring: boolean;
    monthlyRecurringType: MonthlyRecurringType;
  }) {
    super({
      outValue: [
        {
          key: APPOINTMENT_HOST_ID,
          transform: (value: any) => value?.value,
        },
        {
          key: APPOINTMENT_FACILITY_ID,
          transform: (value: any) => value?.value,
        },
        {
          key: APPOINTMENT_USER_ID,
          transform: (value: any) => value.value.id,
        },
        {
          key: APPOINTABLE_ID,
          transform: (value: any) => value.value,
        },
      ],
    });
    this.isRecurring = isRecurring;
    this.monthlyRecurringType = monthlyRecurringType;
  }

  override to(values: any) {
    const parsed: Record<string, any> = super.to(values);

    const user = parsed["appointment-user"];
    if (user) {
      parsed["appointment-user-id"] = {
        value: user,
        label: `${user.firstName} ${user.lastName}`,
      };
    }

    const appointable = parsed["appointable"];
    if (appointable) {
      parsed["appointable-id"] = {
        value: appointable.id,
        label: appointable.name,
      };
    }

    const host = parsed["appointment-host"];
    if (host) {
      parsed["appointment-host-id"] = {
        value: host.id,
        label: `${host.firstName} ${host.lastName}`,
      };
    }

    return pick(parsed, [
      "appointable-id",
      "appointment-host-id",
      "appointment-facility-id",
      "appointment-user-id",
      "start-date",
    ]);
  }

  override from(values: Record<string, any>) {
    const parsed: any = super.from(values);
    if (parsed.availabilityType === "STAFF") {
      delete parsed["appointmentFacilityId"];
    } else if (parsed.availabilityType === "FACILITY") {
      delete parsed["appointmentHostId"];
    }
    delete parsed["availabilityType"];

    if (this.isRecurring) {
      if (parsed["recurringType"] === "DAILY") {
        delete parsed["weeklyDays"];
        delete parsed["monthlyDayOfMonth"];
        delete parsed["monthlyDayPosition"];
        delete parsed["monthlyDayOfWeek"];
      } else if (parsed["recurringType"] === "WEEKLY") {
        delete parsed["monthlyDayOfMonth"];
        delete parsed["monthlyDayPosition"];
        delete parsed["monthlyDayOfWeek"];
      } else if (parsed["recurringType"] === "MONTHLY") {
        delete parsed["weeklyDays"];
        if (this.monthlyRecurringType === "DAY_OF_THE_EVENT") {
          delete parsed["monthlyDayPosition"];
          delete parsed["monthlyDayOfWeek"];
          parsed["monthlyDayOfMonth"] = moment(
            parsed["startDate"],
            "YYYY-MM-DD",
          ).date();
        } else if (this.monthlyRecurringType === "SPECIFIC_DAY") {
          delete parsed["monthlyDayOfMonth"];
        }
      }
      if (parsed["endDate"] === "") {
        delete parsed["endDate"];
      } else if (parsed["endDate"]) {
        parsed["endDate"] = moment(parsed["endDate"], "DD/MM/YYYY").format(
          "YYYY-MM-DD",
        );
      }
      const startDateMoment = moment(
        parsed["startDate"],
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      );
      parsed["startDate"] = startDateMoment.format("YYYY-MM-DD");
      parsed["appointmentTime"] = startDateMoment.format("HH:mm");
    } else {
      delete parsed["recurringType"];
      delete parsed["recurringInterval"];
      delete parsed["weeklyDays"];
      delete parsed["monthlyDayOfMonth"];
      delete parsed["monthlyDayPosition"];
      delete parsed["monthlyDayOfWeek"];
    }

    return parsed;
  }
}
