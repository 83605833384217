import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useClubFeatureFlags } from "@gymflow/api";
import { DATE_FORMAT_WITH_SECONDS } from '@gymflow/common';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { Button } from 'reactstrap';

import { useEditOrCreateTask } from '../../../hooks/useEditOrCreateTask';
import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { CreateEditTaskSidebarProviderContext } from "../../organisms";
import Item from './Item';

function TaskItem({
  id,
  name,
  complete,
  createdBy,
  createdDate,
  deadlineDate,
  deadlineTime,
  dateFormat,
  refreshNodes,
}) {
  const { api } = useGymflowModels();
  const { clubId } = useClubSettings();
  const { openEditTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });

  const { setEditingTaskId } = useEditOrCreateTask({ onRefresh: refreshNodes });

  const formattedDeadline = useMemo(() => {
    const deadlineMoment = moment(`${deadlineDate} ${deadlineTime}`);
    return deadlineMoment.isValid()
      ? deadlineMoment.format(`${dateFormat} [at] h:mm a`)
      : '-';
  }, [dateFormat, deadlineDate, deadlineTime]);

  return (
    <Item
      icon={faPlus}
      firstLine={
        <div className="d-inline-block">
          {name} <span className="font-weight-bold">{complete ? 'Completed' : 'Incomplete'}</span>
          <Button 
            className="btn-link" 
            onClick={() => 
              featureFlags?.featureFlags.PORTAL_REDESIGN_TASK ? openEditTaskSidebar({ taskId: id, onUpdate: refreshNodes }) : setEditingTaskId(id)
            }
          >
            View
          </Button>
        </div>
      }
      secondLine={
        <>
          Created by {createdBy.firstName} {createdBy.lastName} at{' '}
          {moment(createdDate, DATE_FORMAT_WITH_SECONDS).format(`${dateFormat} [at] h:mm a`)}, Due{' '}
          {formattedDeadline}
        </>
      }
    />
  );
}

TaskItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  complete: PropTypes.bool.isRequired,
  createdBy: PropTypes.object.isRequired,
  createdDate: PropTypes.string.isRequired,
  deadlineDate: PropTypes.string.isRequired,
  deadlineTime: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  refreshNodes: PropTypes.func.isRequired,
};

export default TaskItem;
