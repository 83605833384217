import { zonedTimeToUtc } from '@gymflow/helpers';
import { useMutation } from '@tanstack/react-query';


export function useMembershipSoldReportCsv({
  api,
  tz
}: {
  api: {
    memberApi:
    {
      membershipSoldCsv: (params: {
        dateFrom: string,
        dateTo: string,
        membershipIncluded?: number[],
        membershipExcluded?: number[],
      }) => Promise<any>,
    }
  },
  membership?: number[],
  tz: string
}) {
  const csvMutation = useMutation({
    mutationFn: async (
      {
        startDate,
        endDate,
        membershipIncluded,
        membershipExcluded
      }:
        {
          startDate: string,
          endDate: string,
          membershipIncluded?: number[],
          membershipExcluded?: number[],
        }
    ) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.membershipSoldCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
        membershipIncluded,
        membershipExcluded,
      });
      return result.data as string;
    }
  });
  return csvMutation;
}
