import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";

import calendarIcon from "../../../../assets/img/calendar-icon.svg";
import { PageTitleProviderContext, useClubSettings } from "../../../providers";
import { CalendarIntervalMode } from "./CalendarIntervalMode";

export function useCalendarParams() {
  const history = useHistory();
  const { timezone } = useClubSettings();

  const {
    date: dateParam,
    interval: intervalParam,
    marker,
    appointmentId,
  } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });

  const getDateForToday = useCallback(() => {
    const nowStringInClubTimezone = moment()
      .tz(timezone)
      .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ);
    return moment(
      nowStringInClubTimezone,
      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
    ).toDate();
  }, [timezone]);

  const date = useMemo(() => {
    const parsedDate = moment(
      (dateParam as string | undefined) || "",
      DATE_FORMAT,
      true,
    );
    if (!dateParam || !parsedDate.isValid()) {
      return getDateForToday();
    }
    return parsedDate.toDate();
  }, [dateParam, getDateForToday]);

  let interval: CalendarIntervalMode = "WEEK";
  switch (intervalParam) {
    case "DAY":
    case "WEEK":
    case "MONTH":
      interval = intervalParam;
      break;
  }

  const { setPageTitle } = useContext(PageTitleProviderContext);
  const setQueryParams = useCallback(
    (newParams: {
      date?: Date;
      interval?: CalendarIntervalMode;
      pageTitle: string;
    }) => {
      const currentParams = {
        date: moment(date).format(DATE_FORMAT),
        interval,
        marker,
        appointmentId,
      };
      const parsedNewParams: {
        date?: string;
        interval?: CalendarIntervalMode;
      } = {};
      if (newParams.date) {
        parsedNewParams.date = moment(newParams.date).format(DATE_FORMAT);
      }
      if (newParams.interval) {
        parsedNewParams.interval = newParams.interval;
      }
      history.push({
        search: qs.stringify({
          ...currentParams,
          ...parsedNewParams,
        }),
      });
      setPageTitle({ title: newParams.pageTitle, iconUrl: calendarIcon });
    },
    [date, history, interval, marker],
  );

  return {
    date,
    getDateForToday,
    interval,
    setQueryParams,
    marker: marker as string,
    appointmentId: appointmentId as string,
  };
}
