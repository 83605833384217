import {
  clubStaleTime,
  useClubAsPublic,
  useMutationAttachAuthorizedPaymentIntent,
  useMutationAttachAuthorizedPaymentIntentAsMember,
  useQueryPaymentMethodSetupIntent,
  useQueryPaymentMethodSetupIntentAsMember,
} from "@gymflow/api";
import {
  PaymentMethodSetupIntent,
  UserMemberBean,
  UserMemberPublicDTO,
  UserMemberSearchByFullNameResult,
} from "@gymflow/types";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { isAxiosError } from "axios";
import { useContext, useState } from "react";

import { ModalContext, ModalWrapper, useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import { Button, ErrorMessage, Spinner } from "../atoms";

export type AddPaymentMethodModalNewProps = {
  userMember:
    | UserMemberBean
    | UserMemberSearchByFullNameResult
    | UserMemberPublicDTO;
  onConfirm?: (newPaymentMethodId?: string) => Promise<void>;
  asMember?: boolean;
};

export const AddPaymentMethodModalNew = (
  props: AddPaymentMethodModalNewProps,
) => {
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClubAsPublic(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );
  const { popModal } = useContext(ModalContext);
  const pmSetupIntentQueryAsStaff = useQueryPaymentMethodSetupIntent({
    api,
    memberId: props?.userMember.id,
    enabled: !props.asMember,
  });
  const pmSetupIntentQueryAsMember = useQueryPaymentMethodSetupIntentAsMember({
    api,
    memberId: props?.userMember.id,
    enabled: !!props.asMember,
  });
  const { data: paymentMethodSetupIntent } = !props.asMember
    ? pmSetupIntentQueryAsStaff
    : pmSetupIntentQueryAsMember;
  const isDarkMode = document.querySelector("html")!.classList.contains("dark");

  return (
    <ModalWrapper onCancel={popModal}>
      {!club || !paymentMethodSetupIntent ? (
        <Spinner />
      ) : (
        <Elements
          stripe={loadStripe(settings.stripe_api_key, {
            stripeAccount: club.stripeApplicationId,
          })}
          options={{
            clientSecret: paymentMethodSetupIntent.setupIntentClientSecret,
            loader: "auto",
            appearance: {
              variables: {
                colorPrimary: settings.primaryColor ?? "#8746ec",
              },
              theme: isDarkMode ? "night" : "stripe",
            },
          }}
        >
          <AddPaymentMethodModalNewContents
            {...props}
            paymentMethodSetupIntent={paymentMethodSetupIntent}
          />
        </Elements>
      )}
    </ModalWrapper>
  );
};

const AddPaymentMethodModalNewContents = ({
  userMember,
  onConfirm,
  asMember,
  paymentMethodSetupIntent,
}: AddPaymentMethodModalNewProps & {
  paymentMethodSetupIntent: PaymentMethodSetupIntent;
}) => {
  const { popModal } = useContext(ModalContext);
  const stripe = useStripe();
  const elements = useElements();
  const { api } = useGymflowModels();
  const attachAuthorizedPaymentIntentMutationAsStaff =
    useMutationAttachAuthorizedPaymentIntent({
      api,
    });
  const attachAuthorizedPaymentIntentMutationAsMember =
    useMutationAttachAuthorizedPaymentIntentAsMember({
      api,
    });
  const attachAuthorizedPaymentMethodIntentMutation = asMember
    ? attachAuthorizedPaymentIntentMutationAsMember
    : attachAuthorizedPaymentIntentMutationAsStaff;

  const { notifyDanger, toast } = useContext(ToastContext);
  const [stripeError, setStripeError] = useState<string>();
  const onSubmit = async () => {
    try {
      if (!stripe || !elements) return;
      await elements.submit();
      const paymentMethodResult = await stripe.confirmSetup({
        elements,
        clientSecret: paymentMethodSetupIntent.setupIntentClientSecret,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });
      if (paymentMethodResult.error) {
        setStripeError(paymentMethodResult.error.message);
        return;
      }
      await attachAuthorizedPaymentMethodIntentMutation.mutateAsync({
        memberId: userMember.id,
        paymentIntentId: paymentMethodResult.setupIntent.id,
      });
      const paymentMethodContainer =
        paymentMethodResult.setupIntent.payment_method;
      if (!paymentMethodContainer) return;
      const paymentMethod =
        typeof paymentMethodContainer === "string"
          ? paymentMethodContainer
          : paymentMethodContainer.id;
      await onConfirm?.(paymentMethod);
      toast({ message: "Payment method added." });
    } catch (e) {
      if (isAxiosError(e)) {
        notifyDanger(e);
      }
    }
  };
  const [completed, setCompleted] = useState(false);
  return (
    <div className="flex flex-col gap-4">
      <div className="text-lg font-semibold text-gray-950 dark:text-[#ffffff]">
        Add Payment Method
      </div>
      <div className="flex flex-col gap-1">
        <PaymentElement
          onChange={(paymentMethod) => {
            setStripeError(undefined);
            setCompleted(paymentMethod.complete);
          }}
        />
        {stripeError && <ErrorMessage text={stripeError} />}
      </div>
      <div className="flex gap-4">
        <Button
          onClick={() => {
            popModal();
          }}
          className="flex-1"
        >
          Cancel
        </Button>

        <Button
          intent="secondary"
          className="mt-0 flex-1"
          disabled={!!stripeError || !completed}
          onClick={async () => {
            await onSubmit();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
