import {
  faAdd,
  faAngleDown,
  faClose,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMemberPaymentMethod,
  useMemberPaymentMethodAsMember,
  useMemberPaymentMethodListAsMember,
  useQueryMemberPaymentMethodListNew,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import {
  UserMemberBean,
  UserMemberPublicDTO,
  UserMemberSearchByFullNameResult,
} from "@gymflow/types";
import React, { useContext, useEffect, useState } from "react";

import { useAddPaymentMethodAlert } from "../../hooks/useAddPaymentMethodAlert";
import { ModalContext, ModalWrapper, useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button, Spinner, TrashIcon } from "../atoms";
import { StarIcon } from "../atoms/icons/StarIcon";

export type PaymentMethodPickerProps = {
  userMember:
    | UserMemberBean
    | UserMemberSearchByFullNameResult
    | UserMemberPublicDTO;
  currentPaymentMethodId?: string;
  onChange: (newValue?: string) => void;
  disabled?: boolean;
  asMember?: boolean;
};

export const PaymentMethodPicker: React.FC<PaymentMethodPickerProps> = ({
  asMember,
  userMember,
  onChange,
  currentPaymentMethodId,
  disabled,
}) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const staffQuery = useQueryMemberPaymentMethodListNew({
    api,
    memberId: userMember.id,
    clubId: settings.clubId,
    enabled: !asMember,
  });
  const memberQuery = useMemberPaymentMethodListAsMember({
    api,
    memberId: userMember.id,
    clubId: settings.clubId,
    enabled: !!asMember,
  });
  const query = asMember ? memberQuery : staffQuery;
  const paymentMethods = query.data;
  useEffect(() => {
    if (query.isSuccess && !currentPaymentMethodId) {
      onChange(query.data?.find((e) => e.defaultPaymentMethod)?.id);
    }
  }, [currentPaymentMethodId, onChange, query]);
  const showAddPaymentMethodAlert = useAddPaymentMethodAlert({
    userMember,
    asMember,
    onConfirm: async (newPaymentMethodId) => {
      onChange(newPaymentMethodId);
    },
  });
  const {
    removePaymentMethodMutation: removePaymentMutation,
    assignDefaultPaymentMethodMutation:
      assignDefaultPaymentMethodMutationAsStaff,
  } = useMemberPaymentMethod({
    api,
  });
  const {
    removePaymentMethodMutation: removePaymentAsMemberMutation,
    assignDefaultPaymentMethodMutation:
      assignDefaultPaymentMethodMutationAsMember,
  } = useMemberPaymentMethodAsMember({
    api,
  });
  const removePaymentMethodMutation = asMember
    ? removePaymentAsMemberMutation
    : removePaymentMutation;
  const assignDefaultPaymentMethodMutation = asMember
    ? assignDefaultPaymentMethodMutationAsMember
    : assignDefaultPaymentMethodMutationAsStaff;
  const [showPickerModal, setShowPickerModal] = useState(false);
  const { modalStack } = useContext(ModalContext);
  const currentPaymentMethod = paymentMethods?.find(
    (e) => e.id === currentPaymentMethodId,
  );
  return (
    <div className="flex flex-col gap-2">
      <div className="dark:text-darkGray-200 flex flex-col justify-between gap-2 font-medium lg:flex-row lg:items-center">
        {!disabled && "Select card for payment"}
        {!disabled && paymentMethods?.length === 0 && (
          <Button
            className="gap-1 max-lg:w-full"
            intent="secondary-outline"
            onClick={() => {
              showAddPaymentMethodAlert();
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="text-secondary-500" />
            Add Card
          </Button>
        )}
      </div>
      <div
        className={cn(
          "flex flex-row rounded-xl border border-gray-200 dark:border-gray-700 overflow-hidden",
          {
            hidden: paymentMethods?.length === 0,
          },
        )}
      >
        <div className="relative flex flex-1 flex-col bg-[#ffffff] px-4 py-3 dark:bg-gray-950">
          {!currentPaymentMethod && !paymentMethods && (
            <div className="absolute inset-0 flex items-center justify-center dark:text-[#ffffff]">
              <Spinner />
            </div>
          )}
          {!currentPaymentMethod && paymentMethods !== undefined && (
            <div className="absolute inset-0 flex items-center justify-center text-sm font-semibold text-gray-950 dark:text-[#ffffff] ">
              No Payment Method Selected
            </div>
          )}
          <div
            className={cn({
              invisible: !currentPaymentMethod,
            })}
          >
            <div className="text-sm font-bold text-gray-950 dark:text-[#ffffff]">
              Ending in {currentPaymentMethod?.last4Digits}
            </div>
            <div className={cn("text-sm font-medium text-gray-500")}>
              Expires {currentPaymentMethod?.expMonth}/
              {currentPaymentMethod?.expYear}
            </div>
          </div>
        </div>
        {!disabled && (
          <Button
            onClick={() => {
              setShowPickerModal(true);
            }}
            className={cn(
              "flex h-16 min-h-full items-center justify-center !rounded-l-none border-y-0 border-r-0",
            )}
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        )}
      </div>
      <ModalWrapper
        isHidden={!showPickerModal}
        onCancel={() => {
          if (modalStack && modalStack?.length > 0) return;
          setShowPickerModal(false);
        }}
        className="flex flex-col gap-2 overflow-hidden !p-0 sm:!max-w-xl"
      >
        <div className="flex flex-col gap-2 p-6 pb-0">
          <div className="flex flex-row items-center justify-between">
            <div className="text-lg font-bold text-gray-950 dark:text-[#ffffff]">
              Select Card
            </div>
            <Button
              onClick={() => {
                setShowPickerModal(false);
              }}
              size="small"
              intent="transparent"
              className="p-0"
            >
              <FontAwesomeIcon icon={faClose} className="h-5 w-5" />
            </Button>
          </div>
          <Button
            onClick={() => {
              showAddPaymentMethodAlert();
            }}
            intent="secondary-outline"
            className="flex flex-row gap-1"
          >
            <FontAwesomeIcon icon={faAdd} className="text-secondary-500" />
            Add Card
          </Button>
        </div>
        <div className="flex max-h-[50vh] flex-col gap-2 overflow-y-auto px-6 pb-6 pt-2">
          {paymentMethods?.map((paymentMethod) => {
            return (
              <div
                className="flex flex-row items-center gap-2"
                key={paymentMethod.id}
              >
                <Button
                  className={cn("h-fit max-h-fit flex-1 px-4 py-3", {
                    "!border-secondary-500":
                      paymentMethod.id === currentPaymentMethodId,
                  })}
                  onClick={() => {
                    onChange(paymentMethod.id);
                  }}
                  key={paymentMethod.id}
                >
                  <div className="flex w-full flex-col items-start">
                    <div className="text-sm font-bold text-gray-950 dark:text-[#ffffff]">
                      Card ending in {paymentMethod.last4Digits}
                    </div>
                    <div className="text-sm font-normal text-gray-500">
                      Expiry {paymentMethod.expMonth}/{paymentMethod.expYear}
                    </div>
                  </div>
                  <Button
                    className="h-fit min-h-0 min-w-0 !bg-transparent p-2"
                    intent="transparent"
                    onClick={async () => {
                      await assignDefaultPaymentMethodMutation.mutateAsync({
                        memberId: userMember.id,
                        paymentMethodId: paymentMethod.id,
                        clubId: settings.clubId,
                      });
                    }}
                    tooltip="Set as default"
                  >
                    <StarIcon
                      className="h-5 w-5"
                      pathClassName={cn({
                        "fill-secondary-500 stroke-secondary-500":
                          paymentMethod.defaultPaymentMethod,
                        "fill-transparent stroke-gray-500":
                          !paymentMethod.defaultPaymentMethod,
                      })}
                    />
                  </Button>
                </Button>

                <Button
                  className={cn(
                    "flex h-10 max-h-none w-10 items-center justify-center p-0",
                    {
                      invisible: paymentMethod.defaultPaymentMethod,
                    },
                  )}
                  intent="error-outline"
                  onClick={async () => {
                    await removePaymentMethodMutation.mutateAsync({
                      memberId: userMember.id,
                      clubId: settings.clubId,
                      paymentMethodId: paymentMethod.id,
                    });
                  }}
                >
                  <TrashIcon
                    pathClassName="stroke-error-500"
                    className="h-5 w-5"
                  />
                </Button>
              </div>
            );
          })}
        </div>
      </ModalWrapper>
    </div>
  );
};
