import endpoints from './endpoints';
import { canFind } from './features/apiResource';

const timelineApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.timeline}`,
    http: axiosInstance,
    apiUrl,
  };
  return Object.assign(state, canFind(state));
};

export default timelineApi;
