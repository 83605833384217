import { cn } from "@gymflow/helpers";

export function AppStoreButton({ className }: { className?: string }) {
  return (
    <a
      href="https://apps.apple.com/us/app/gymflow-members/id1631161012"
      target="_blank"
      rel="noreferrer"
      className={cn("flex items-center justify-center", className)}
    >
      <img
        src="/public/img/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
        alt="link to the gymflow app"
      />
    </a>
  );
}
