import {
  useActivityCategoryList,
  useAppointableCategories,
  useMemberSubscription,
} from "@gymflow/api";
import {
  AlertContext,
  CardBodyWithSpinner,
  DATE_FORMAT,
  isMobile,
  PARAMETER_DATE_FORMAT,
  SubscriptionStatus,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import moment from "moment-timezone";
import { useContext } from "react";
import ReactTable from "react-table";
import { Button, Card } from "reactstrap";

import { mapCategoriesToCreditPacks } from "../../helpers/creditPack";
import { useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import AdjustSessionPackAlert from "./AdjustSessionPackAlert";
import CancelSessionPackAlert from "./CancelSessionPackAlert";
import EditSessionPackExpiryAlert from "./EditSessionPackExpiryAlert";

const PAGE_SIZE = 20;
const MIN_ROW = 5;

export function UserSessions({
  memberId,
  isLoading,
  creditPacks,
}: {
  memberId: string;
  isLoading: boolean;
  creditPacks: any[];
}) {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { notifyDanger } = useContext(ToastContext);
  const {
    expireSubscriptionMutation,
    cancelSubscriptionMutation,
    adjustSessionPackMutation,
  } = useMemberSubscription({ api, tz: settings.timezone });

  const alert = useContext(AlertContext);
  const dateFormat = settings.date_format;

  const {
    data: { content: categories },
  } = useActivityCategoryList({ api, filter: { page: 0, limit: 100 } });
  const {
    data: { content: appointableCategories },
  } = useAppointableCategories({
    api,
    paginationOption: { page: 0, size: 100 },
  });

  const creditPacksWithCategories =
    creditPacks &&
    mapCategoriesToCreditPacks(
      creditPacks.slice(0),
      categories,
      appointableCategories,
    );

  const sortDate = (a: string, b: string) =>
    moment(a, DATE_FORMAT).valueOf() - moment(b, DATE_FORMAT).valueOf();

  const renderActions = ({
    id,
    staffCancellable,
    remaining,
    totalCredits,
  }: any) => {
    return (
      <>
        {staffCancellable && (
          <Button
            onClick={() =>
              alert.setAlert(
                <CancelSessionPackAlert
                  onConfirm={async () => {
                    await cancelSubscriptionMutation
                      .mutateAsync({
                        subscriptionId: id,
                        memberId: memberId,
                        immediately: true,
                      })
                      .catch(notifyDanger);
                  }}
                />,
              )
            }
            size="sm"
            color="danger"
            className="btn-round"
          >
            Revoke
          </Button>
        )}
        {remaining !== undefined && (
          <Button
            onClick={() =>
              alert.setAlert(
                <AdjustSessionPackAlert
                  remaining={remaining}
                  totalCredits={totalCredits}
                  onSubmit={async (updatedRemaining: number) => {
                    await adjustSessionPackMutation
                      .mutateAsync({
                        subscriptionId: id,
                        newAmount: updatedRemaining,
                        memberId: memberId,
                      })
                      .catch(notifyDanger);
                  }}
                />,
              )
            }
            size="sm"
            color="warning"
            className="btn-round"
          >
            Adjust
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="relative flex h-full w-full">
      <div className="absolute inset-0 overflow-auto">
        <Card className="floating-table">
          {/* @ts-ignore */}
          <CardBodyWithSpinner isLoading={isLoading}>
            <ReactTable
              className="-highlight floating-table"
              columns={[
                {
                  Header: <h3>Name</h3>,
                  accessor: "name",
                  minWidth: 200,
                },
                {
                  Header: <h3>Status</h3>,
                  id: "status",
                  accessor: ({
                    status,
                  }: {
                    status: keyof typeof colorStatusMap;
                  }) => {
                    let color = "#3A3A3F";
                    if (colorStatusMap[status]) {
                      color = colorStatusMap[status];
                    }
                    return (
                      <span
                        className="font-weight-bold"
                        style={{ color, textTransform: "capitalize" }}
                      >
                        {status.toLowerCase()}
                      </span>
                    );
                  },
                  maxWidth: 120,
                  sortable: false,
                },
                {
                  Header: <h3>Actions</h3>,
                  id: "actions",
                  accessor: ({
                    id,
                    staffCancellable,
                    remaining,
                    totalCredits,
                  }: any) => (
                    <div>
                      {renderActions({
                        id,
                        staffCancellable,
                        remaining,
                        totalCredits,
                      })}
                    </div>
                  ),
                  sortable: false,
                  filterable: false,
                  minWidth: 240,
                },
                {
                  Header: <h3>Price</h3>,
                  id: "price",
                  accessor: ({ price }: any) =>
                    formatCurrency(price, settings.defaultCurrency),
                  maxWidth: 120,
                  show: !isMobile(),
                },
                {
                  Header: <h3>Remaining</h3>,
                  id: "remaining",
                  accessor: ({ remaining, unlimited, ...rest }: any) => {
                    if (unlimited) {
                      return "∞";
                    }
                    if (remaining) {
                      return remaining.toString();
                    }
                    return "0";
                  },
                  maxWidth: 120,
                  sortable: true,
                },
                {
                  Header: <h3>Class Category</h3>,
                  id: "categoryNames",
                  accessor: ({ categoryNames }: any) =>
                    categoryNames?.join(", "),
                  minWidth: 180,
                  show: !isMobile(),
                },
                {
                  Header: <h3>Appointment Category</h3>,
                  id: "appointableCategoryNames",
                  accessor: ({ appointableCategoryNames }: any) =>
                    appointableCategoryNames?.join(", "),
                  minWidth: 180,
                  show: !isMobile(),
                },
                {
                  Header: <h3>Expiry Date</h3>,
                  id: "expiry",
                  accessor: ({ id, expiry, status, addon }: any) => (
                    <span>
                      {expiry
                        ? moment(expiry, PARAMETER_DATE_FORMAT)
                            .add(1, "second")
                            .format(dateFormat)
                        : "-"}
                      {status === SubscriptionStatus.Active && !addon && (
                        <Button
                          className="btn-link"
                          onClick={() => {
                            alert.setAlert(
                              <EditSessionPackExpiryAlert
                                expiryDate={expiry}
                                onConfirm={async ({ expiredDate }: any) => {
                                  try {
                                    await expireSubscriptionMutation
                                      .mutateAsync({
                                        subscriptionId: id,
                                        expiredDate,
                                        memberId: memberId,
                                      })
                                      .catch(notifyDanger);
                                  } catch (e) {
                                    notifyDanger(e as any);
                                  }
                                }}
                              />,
                            );
                          }}
                        >
                          Edit
                        </Button>
                      )}
                    </span>
                  ),
                  minWidth: 150,
                  sortMethod: sortDate,
                  show: !isMobile(),
                },
                {
                  Header: (
                    <h3>
                      <abbr title="Terms & Conditions">T&C</abbr>
                    </h3>
                  ),
                  id: "termsConditions",
                  accessor: ({ termsConditions }: any) => (
                    <a
                      href={termsConditions}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button className="btn-link">
                        <span>View</span>
                      </Button>
                    </a>
                  ),
                  sortable: false,
                  show: !isMobile(),
                },
              ]}
              data={creditPacksWithCategories}
              defaultPageSize={PAGE_SIZE}
              minRows={0}
              // @ts-ignore
              minHeight={300}
              getTrGroupProps={() => ({
                style: {
                  maxHeight: "50px",
                },
              })}
              getTbodyProps={() => ({
                style: {
                  paddingBottom: "10px",
                },
              })}
              showPagination={creditPacksWithCategories.length >= MIN_ROW}
            />
          </CardBodyWithSpinner>
        </Card>
      </div>
    </div>
  );
}

export const colorStatusMap = {
  ACTIVE: "#007E4F",
  CANCELLED: "#0A0D16",
  OVERDUE: "#E2003A",
} as const;
