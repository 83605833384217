import {
  useTask,
  useTaskCreate,
  useTaskDelete,
  useTaskEdit,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { useCallback, useContext, useEffect, useState } from "react";

import TaskAlert from "../components/Task/TaskAlert";
import { useClubSettings } from "../providers";
import { ToastContext } from "../providers/ToastProvider/context";
import useGymflowModels from "../store";

export function useEditOrCreateTask({ onRefresh } = {}) {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const { setAlert, hide } = useContext(AlertContext);
  const { notifyDanger } = useContext(ToastContext);
  const [editingTaskId, setEditingTaskId] = useState(null);

  const { data: editing, refetch: refetchTask } = useTask({
    api,
    tz: timezone,
    taskId: editingTaskId,
  });
  const updateTask = useTaskEdit({ api, tz: timezone });
  const createTask = useTaskCreate({ api, tz: timezone });
  const removeTask = useTaskDelete({ api });

  const refresh = useCallback(async () => {
    if (onRefresh) {
      onRefresh();
    }
  }, [onRefresh]);

  const handleRemoveTask = useCallback(
    async (values) => {
      try {
        setEditingTaskId(null);
        await removeTask.mutateAsync(values.id);
        hide();
        refresh();
      } catch (e) {
        notifyDanger(e);
      }
    },
    [hide, refresh, notifyDanger],
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        if (editing) {
          await updateTask.mutateAsync({
            taskId: values.id,
            patchedFields: values.patchedFields,
          });
        } else {
          await createTask.mutateAsync(values);
        }
        setEditingTaskId(null);
        hide();
        refresh();
      } catch (e) {
        notifyDanger(e);
      }
    },
    [editing, hide, notifyDanger, refresh],
  );
  const showAlert = useCallback(() => {
    setAlert(
      <TaskAlert
        editing={editing}
        onSubmit={handleSubmit}
        onCancel={() => {
          setEditingTaskId(null);
          hide();
        }}
        onRemove={handleRemoveTask}
      />,
    );
  }, [setAlert, hide, editing, handleSubmit]);

  useEffect(() => {
    if (editingTaskId) {
      showAlert();
    }
  }, [editingTaskId, showAlert]);

  return { setEditingTaskId, showAlert };
}
