import classNames from "classnames";

import { TextButton } from "./TextButton";

export function MemberAddToClassSearchResult({
  name,
  email,
  onAddToClassClick,
  isFirst,
}: {
  name: string;
  email: string;
  onAddToClassClick?: () => void;
  isFirst: boolean;
}) {
  return (
    <div
      className={classNames(
        "-mx-3 -my-2 flex items-center justify-between px-8 py-4",
        {
          "border-t border-t-gray-200": !isFirst,
        },
      )}
    >
      <div className="overflow-hidden text-left">
        <div className="overflow-hidden text-ellipsis font-medium text-gray-900">
          {name}
        </div>
        <div className="overflow-hidden text-ellipsis text-sm font-normal text-gray-600">
          {email}
        </div>
      </div>
      <div>
        <TextButton onClick={onAddToClassClick}>Add To Class</TextButton>
      </div>
    </div>
  );
}
