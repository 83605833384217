import { useCallback, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { CardBodyWithSpinner } from '@gymflow/common';
import { useTaskList } from '@gymflow/api';

import { useTaskColumnDefs } from '../../hooks/useTaskColumnDefs';
import useGymflowModels from '../../store';

const MIN_ROW = 5;

export function CompleteTasksTable({ filters, setEditingTaskId }) {
  const { api, settingsStore } = useGymflowModels();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(1000);
  const [sorted, setSorted] = useState([{ id: 'deadlineDate,deadlineTime', desc: false }]);
  const { timezone } = useStoreState(settingsStore);

  const createOpts = useCallback(() => {
    const eventOptions = { complete: filters.complete };
    if (filters.dateTo) {
      eventOptions.dateTo = filters?.dateTo;
    }
    if (filters.dateFrom) {
      eventOptions.dateFrom = filters?.dateFrom;
    }
    if (filters['taskOwners.id']) {
      eventOptions['taskOwners.id'] = filters['taskOwners.id'];
    }

    return {
      extraParams: filters,
      page,
      limit,
      sort: { field: sorted[0].id, desc: sorted[0]?.desc },
    };
  }, [filters, sorted, limit, page]);

  const {
    data: { content: tasks, totalPages, totalElements },
    isFetching,
  } = useTaskList({ api, opts: createOpts(), tz: timezone }, { refetchOnWindowFocus: true });

  const handlePageChange = useCallback(
    (pageValue) => {
      setPage(pageValue);
    },
    [setPage]
  );

  const handlePageSizeChange = useCallback(
    (pageSize) => {
      setLimit(pageSize);
    },
    [setLimit]
  );

  const handleSortedChange = useCallback(
    (sort) => {
      setSorted(sort);
    },
    [setSorted]
  );

  const columnDefs = useTaskColumnDefs({ setEditingTaskId });

  return (
    <CardBodyWithSpinner isLoading={isFetching}>
      <ReactTable
        className="-highlight floating-table"
        manual
        columns={columnDefs}
        data={tasks}
        defaultPageSize={limit}
        defaultPage={page || 0}
        page={page}
        pages={totalPages}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortedChange={handleSortedChange}
        minRows={0}
        minHeight={500}
        defaultSorted={[
          {
            id: 'deadlineDate,deadlineTime',
            desc: false,
          },
        ]}
        getTrGroupProps={() => ({
          style: {
            maxHeight: '60px',
          },
        })}
        getTbodyProps={() => ({
          style: {
            paddingBottom: '10px',
          },
        })}
        showPagination={totalElements > MIN_ROW}
      />
    </CardBodyWithSpinner>
  );
}

CompleteTasksTable.defaultProps = {
  filters: {},
};

CompleteTasksTable.propTypes = {
  filters: PropTypes.shape({}),
};

export default CompleteTasksTable;
