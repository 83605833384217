import { AppointableCategoryDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointableQueryKeys } from "./appointableQueryKeys";

export function useAppointableCategoryDisable({
  api,
}: {
  api: {
    appointableApi: {
      disableCategory: (
        id: number,
      ) => Promise<{ data: AppointableCategoryDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (id: number) => {
      const response = await api.appointableApi.disableCategory(id);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appointableQueryKeys.categoriesAll(),
      });
    },
  });

  return mutation;
}
