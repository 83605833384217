import {
  useFacility,
  useQueryRecurringAppointment,
  useStaff,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  PARAMETER_DATE_ONLY_FORMAT,
  useRecordForm,
} from "@gymflow/common";
import {
  AppointableDtoAvailabilityType,
  AppointmentDTO,
  FacilityDTO,
  RecurringAppointmentDTO,
  RecurringAppointmentPostDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import moment from "moment-timezone";
import { useEffect } from "react";

import { ModalWrapper, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Button, DangerIcon, PaginatedSelect, TextInput } from "../../atoms";
import { TimeLimitSelect } from "../../organisms";
import { DatePicker } from "../DatePicker";
import {
  APPOINTABLE_ID,
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
  createRescheduleRecurringSchema,
  RECURRING_END_DATE,
  RECURRING_INTERVAL,
  RECURRING_MONTHLY_DAY_OF_WEEK,
  RECURRING_MONTHLY_DAY_POSITION,
  RECURRING_TIME,
  RECURRING_TYPE,
  RECURRING_WEEKLY_DAYS,
  recurringOptions,
  START_DATE,
} from "./AppointmentSchema";
import { MonthlyRadio } from "./MonthlyRadio";
import { RescheduleRecurringFormMapper } from "./RescheduleRecurringFormMapper";
import { WeekdaySelector } from "./WeekdaySelector";

function RecurringAppointmentRescheduleModalWrapper({
  appointment,
  onConfirm,
  onCancel,
  newDate,
}: {
  appointment: AppointmentDTO;
  onConfirm: (newValues: RecurringAppointmentPostDTO) => Promise<void>;
  onCancel: () => void;
  newDate?: string;
}) {
  const { api } = useGymflowModels();
  const { data: recurringAppointment } = useQueryRecurringAppointment({
    api,
    recurringAppointmentId: appointment.recurringAppointmentId,
  });
  const { data: facility } = useFacility({
    api,
    facilityId:
      recurringAppointment && "appointmentFacilityId" in recurringAppointment
        ? recurringAppointment.appointmentFacilityId
        : undefined,
  });
  const { data: host } = useStaff({
    api,
    staffId:
      recurringAppointment && "appointmentHostId" in recurringAppointment
        ? recurringAppointment.appointmentHostId
        : undefined,
  });

  return (
    recurringAppointment &&
    (host || facility) && (
      <RecurringAppointmentRescheduleModal
        appointment={appointment}
        recurringAppointment={recurringAppointment}
        onConfirm={onConfirm}
        onCancel={onCancel}
        newDate={newDate}
        initialHost={host ? host : undefined}
        initialFacilityHost={facility ? facility : undefined}
      />
    )
  );
}

function RecurringAppointmentRescheduleModal({
  appointment,
  recurringAppointment,
  onConfirm,
  onCancel,
  newDate,
  initialHost,
  initialFacilityHost,
}: {
  appointment: AppointmentDTO;
  recurringAppointment: RecurringAppointmentDTO;
  onConfirm: (newValues: RecurringAppointmentPostDTO) => Promise<void>;
  onCancel: () => void;
  newDate?: string;
  initialHost?: UserStaffPublicDTO;
  initialFacilityHost?: FacilityDTO;
}) {
  const { timezone } = useClubSettings();
  const schema = createRescheduleRecurringSchema(
    recurringAppointment.startDate,
    timezone,
  );
  const { initialValues, getValues } = useRecordForm({
    fields: schema.getDefault(),
    record: recurringAppointment,
    mapper: new RescheduleRecurringFormMapper({
      initialHost,
      initialFacilityHost,
    }),
  });

  return (
    <ModalWrapper onCancel={onCancel}>
      <div className="bg-primary-100 mb-3 flex h-11 w-11 self-center rounded-full p-3">
        <DangerIcon className="h-5 w-5" pathClassName="stroke-primary-600" />
      </div>
      <div>
        <div className="font-semibold">Reschedule Recurring Appointments</div>
        <div className="text-sm text-gray-600">
          Confirm details to reschedule appointment.
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          onConfirm(getValues(values));
        }}
        validationSchema={schema}
      >
        <RescheduleRecurringForm
          onCancel={onCancel}
          newDate={newDate}
          possibleHosts={appointment.appointable.appointableHosts}
          possibleFacilities={appointment.appointable.appointableFacilities}
          availabilityType={appointment.appointable.availabilityType}
        />
      </Formik>
    </ModalWrapper>
  );
}

function RescheduleRecurringForm({
  onCancel,
  newDate,
  possibleHosts,
  possibleFacilities,
  availabilityType,
}: {
  onCancel: () => void;
  newDate?: string;
  possibleHosts: UserStaffPublicDTO[];
  possibleFacilities: FacilityDTO[];
  availabilityType: AppointableDtoAvailabilityType;
}) {
  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const values = formikProps.values as Record<string, any>;
  const errors = formikProps.errors as Record<string, any>;

  useEffect(() => {
    const newDateMoment = moment(newDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ);
    if (newDate) {
      setFieldValue(
        START_DATE,
        newDateMoment.format(PARAMETER_DATE_ONLY_FORMAT),
      );
      setFieldValue(RECURRING_TIME, newDateMoment.format("HH:mm"));
    }
  }, [newDate, setFieldValue]);

  return (
    <div className="mt-5 flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <label
            htmlFor={APPOINTMENT_HOST_ID}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            Select Host
          </label>
          <div className={classNames({ hidden: availabilityType !== "STAFF" })}>
            <PaginatedSelect
              value={
                values[APPOINTMENT_HOST_ID]?.value
                  ? values[APPOINTMENT_HOST_ID]
                  : null
              }
              onChange={function (newValue) {
                setFieldValue(APPOINTMENT_HOST_ID, newValue);
              }}
              loadOptions={function () {
                return Promise.resolve({
                  options: possibleHosts.map(({ id, firstName, lastName }) => ({
                    label: `${firstName} ${lastName}`,
                    value: id,
                  })),
                });
              }}
              cacheUniqs={[values[APPOINTABLE_ID]?.value]}
            />
          </div>
          <div
            className={classNames("text-error-700 text-sm", {
              hidden:
                availabilityType !== "STAFF" || !errors[APPOINTMENT_HOST_ID],
            })}
          >
            {errors[APPOINTMENT_HOST_ID]}
          </div>
          <div
            className={classNames({ hidden: availabilityType !== "FACILITY" })}
          >
            <PaginatedSelect
              value={
                values[APPOINTMENT_FACILITY_ID]?.value
                  ? values[APPOINTMENT_FACILITY_ID]
                  : null
              }
              onChange={function (newValue) {
                setFieldValue(APPOINTMENT_FACILITY_ID, newValue);
              }}
              loadOptions={function () {
                return Promise.resolve({
                  options: possibleFacilities.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })),
                });
              }}
              cacheUniqs={[values[APPOINTABLE_ID]?.value]}
            />
          </div>
          <div
            className={classNames("text-error-700 text-sm", {
              hidden:
                availabilityType !== "FACILITY" ||
                !errors[APPOINTMENT_FACILITY_ID],
            })}
          >
            {errors[APPOINTMENT_FACILITY_ID]}
          </div>
        </div>

        <div className="flex  gap-4">
          <div className="flex flex-1 flex-col gap-2">
            <label
              htmlFor={START_DATE}
              className="mb-0 flex text-sm font-medium text-gray-700"
            >
              Date
            </label>
            <div>
              <DatePicker
                inputType="field"
                inputClassnames="h-[2.5rem] border-gray-300 rounded-lg border ring-0 py-0 flex items-center font-semibold"
                label={
                  values[START_DATE] &&
                  moment(values[START_DATE], PARAMETER_DATE_ONLY_FORMAT).format(
                    DATE_FORMAT,
                  )
                }
                mode="single"
                isClearable
                selected={
                  values[START_DATE] &&
                  moment(
                    values[START_DATE],
                    PARAMETER_DATE_ONLY_FORMAT,
                  ).toDate()
                }
                handleDateSave={(newDate) => {
                  if (!newDate) {
                    setFieldValue(START_DATE, undefined);
                  }
                  setFieldValue(
                    START_DATE,
                    moment(newDate).format(PARAMETER_DATE_ONLY_FORMAT),
                  );
                }}
              />
            </div>
          </div>

          <div className="flex flex-1 flex-col gap-2">
            <label
              htmlFor={START_DATE}
              className="mb-0 flex text-sm font-medium text-gray-700"
            >
              Start Time
            </label>
            <div className="flex">
              <TimeLimitSelect
                className="w-100"
                value={values[RECURRING_TIME] ?? "00:00"}
                minTime={"00:00"}
                onChange={(newTime) => {
                  setFieldValue(RECURRING_TIME, newTime);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={classNames("text-error-700 text-sm", {
            hidden: !errors[START_DATE],
          })}
        >
          {errors[START_DATE]}
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="text-base font-medium">Repeat</div>

        <div className="flex flex-col">
          <label
            htmlFor={START_DATE}
            className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
          >
            Every
          </label>
          <div className="flex gap-4">
            <div className="flex-1">
              <TextInput
                className="h-10 font-semibold"
                value={values[RECURRING_INTERVAL]}
                onChange={({ currentTarget: { value } }) => {
                  setFieldValue(RECURRING_INTERVAL, value);
                }}
              />
            </div>
            <div className="flex-1">
              <PaginatedSelect
                value={recurringOptions.find(
                  (option) => option.value === values[RECURRING_TYPE],
                )}
                onChange={(newValue) => {
                  setFieldValue(RECURRING_TYPE, newValue.value);
                }}
                loadOptions={() =>
                  Promise.resolve({ options: recurringOptions })
                }
              />
            </div>
          </div>
          <div
            className={classNames("text-error-700 pt-2 text-sm", {
              hidden: !errors[RECURRING_INTERVAL],
            })}
          >
            {errors[RECURRING_INTERVAL]}
          </div>
        </div>
        {values[RECURRING_TYPE] === "WEEKLY" && (
          <div className="flex flex-col">
            <WeekdaySelector
              value={values[RECURRING_WEEKLY_DAYS] || []}
              onChange={(weekdays) => {
                setFieldValue(RECURRING_WEEKLY_DAYS, weekdays);
              }}
            />
          </div>
        )}
        {values[RECURRING_TYPE] === "MONTHLY" && (
          <div>
            <MonthlyRadio
              value={{
                option: values["recurrence-monthly-type"],
                specificDay:
                  values[RECURRING_MONTHLY_DAY_POSITION] &&
                  values[RECURRING_MONTHLY_DAY_OF_WEEK]
                    ? {
                        position: values[RECURRING_MONTHLY_DAY_POSITION],
                        weekday: values[RECURRING_MONTHLY_DAY_OF_WEEK],
                      }
                    : undefined,
              }}
              onChange={(newOption) => {
                setFieldValue("recurrence-monthly-type", newOption.option);
                if (newOption.option === "SPECIFIC_DAY") {
                  setFieldValue(
                    RECURRING_MONTHLY_DAY_POSITION,
                    newOption.specificDay?.position,
                  );
                  setFieldValue(
                    RECURRING_MONTHLY_DAY_OF_WEEK,
                    newOption.specificDay?.weekday,
                  );
                } else {
                  setFieldValue(RECURRING_MONTHLY_DAY_POSITION, undefined);
                  setFieldValue(RECURRING_MONTHLY_DAY_OF_WEEK, undefined);
                }
              }}
            />
          </div>
        )}
        <div className="flex flex-col">
          <label
            htmlFor={RECURRING_END_DATE}
            className="mb-0 flex pb-2 text-sm font-medium text-gray-700"
          >
            Until
          </label>
          <div className="flex gap-4">
            <DatePicker
              inputType="field"
              inputClassnames="h-[2.5rem] border-gray-300 rounded-lg border ring-0 py-0 flex items-center font-semibold"
              label={
                values[RECURRING_END_DATE] &&
                moment(values[RECURRING_END_DATE], DATE_FORMAT).format(
                  "Do MMMM YYYY",
                )
              }
              mode="single"
              isClearable
              selected={
                values[RECURRING_END_DATE] &&
                moment(values[RECURRING_END_DATE], DATE_FORMAT).toDate()
              }
              handleDateSave={(newDate) => {
                if (!newDate) {
                  setFieldValue(RECURRING_END_DATE, undefined);
                }
                setFieldValue(
                  RECURRING_END_DATE,
                  moment(newDate).format(DATE_FORMAT),
                );
              }}
            />
          </div>
          <div
            className={classNames("text-error-700 pt-2 text-sm", {
              hidden: !errors[RECURRING_END_DATE],
            })}
          >
            {errors[RECURRING_END_DATE]}
          </div>
        </div>
      </div>
      <div className="flex space-x-2">
        <Button onClick={onCancel} className="flex-1">
          Cancel
        </Button>
        <Button
          intent="primary"
          onClick={() => {
            formikProps.submitForm();
          }}
          className="flex-1"
        >
          Reschedule
        </Button>
      </div>
    </div>
  );
}

export { RecurringAppointmentRescheduleModalWrapper as RecurringAppointmentRescheduleModal };
