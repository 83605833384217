import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import { humanizeTimeInterval } from '@gymflow/common';
import { format } from 'date-fns';

function EventCard({ name, startDate, endDate, isFullDayEvent, hostName, bookedCount, capacity }) {
  const renderStartTime = () => {
    const duration = humanizeTimeInterval(startDate, endDate);
    return `${format(new Date(startDate), 'h:mm a')} ${isFullDayEvent ? 'Full day' : duration}`;
  };

  const renderHost = () => `with ${hostName}`;

  const renderBooked = () => `${bookedCount}/${capacity}`;

  return (
    <Card style={{ border: '0px', cursor: 'pointer' }}>
      <Row className="d-flex justify-content-between align-content-center ml-2 mr-2" style={{ height: '57px' }}>
        <Col className="font-weight-bold">{name}</Col>
        <Col className="font-weight-bold">{renderStartTime()}</Col>
        <Col className="text-muted d-none d-sm-block">{renderHost()}</Col>
        <Col className="text-muted d-none d-sm-block">{renderBooked()}</Col>
      </Row>
    </Card>
  );
}

EventCard.propTypes = {
  name: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  isFullDayEvent: PropTypes.bool.isRequired,
  hostName: PropTypes.string.isRequired,
  bookedCount: PropTypes.number.isRequired,
  capacity: PropTypes.number.isRequired,
};

export default EventCard;
