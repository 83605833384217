import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useMutationRuleGroupDelete,
  useQueryRuleGroupSearch,
} from "@gymflow/api";
import { cn } from "@gymflow/helpers";
import { RuleGroup, RuleGroupType } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { isAxiosError } from "axios";
import qs from "qs";
import { useContext, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { usePageSize, usePortalRoutes } from "../../../hooks";
import { ToastContext } from "../../../providers/ToastProvider/context";
import { RouteFeature, RouteLayout } from "../../../routes";
import useGymflowModels from "../../../store";
import {
  Button,
  EditIcon,
  SettingsIcon,
  TagsPopover,
  TrashIcon,
} from "../../atoms";
import { PaginatedTable } from "../../organisms";
import { SettingsContainer } from "../../Settings/SettingsContainer";
import { ruleFormRoute } from "../RuleFormRouter";

const ruleGroups: { label: string; ruleGroupType: RuleGroupType }[] = [
  { label: "Classes", ruleGroupType: "CLASS_GROUP" },
  {
    label: "Appointments",
    ruleGroupType: "APPOINTABLE_GROUP",
  },
  {
    label: "Memberships",
    ruleGroupType: "MEMBERSHIP_GROUP",
  },
  {
    label: "Access",
    ruleGroupType: "ACCESS_GROUP",
  },
] as const;

export function RulesTable() {
  const { t } = useTranslation();

  const location = useLocation();
  const group = useMemo(() => {
    const parts = qs.parse(location.search.slice(1));
    return (parts?.["group"] ?? "CLASS_GROUP") as RuleGroupType;
  }, [location.search]);

  const { api } = useGymflowModels();
  const [currentPage, setCurrentPage] = useState(0);
  const { data, isLoading } = useQueryRuleGroupSearch({
    api,
    filter: { page: currentPage, size: 10, groupType: group },
  });
  const { mutateAsync: deleteGroup } = useMutationRuleGroupDelete({ api });

  const history = useHistory();
  const { createClubLink } = usePortalRoutes();

  const columnHelper = createColumnHelper<RuleGroup>();

  const listColumn = columnHelper.accessor(
    group === "CLASS_GROUP" ? "classList" : "appointableList",
    {
      header: () => (
        <div className="line-clamp-1 min-w-0">
          {group === "CLASS_GROUP"
            ? t("page.rules.table.connectedClasses")
            : t("page.rules.table.connectedAppointments")}
        </div>
      ),
      cell: (column) => {
        const value = column.getValue();
        if (!value) {
          return "-";
        }
        return (
          <div className="pr-6">
            <TagsPopover tags={value.map(({ name }) => ({ label: name }))} />
          </div>
        );
      },
    },
  );

  const { toast, notifyDanger } = useContext(ToastContext);
  const commonColumns = [
    columnHelper.accessor(
      (e) => ({
        name: e.name,
        isDefault: e.isDefault,
      }),
      {
        id: "name",
        header: () => t("page.rules.table.ruleName"),
        cell: (column) => {
          const value = column.getValue();
          return (
            <div className="flex items-center gap-2">
              {value.isDefault && (
                <SettingsIcon
                  className="h-5 w-5"
                  pathClassName="stroke-gray-500"
                />
              )}
              <div>{value.name}</div>
            </div>
          );
        },
      },
    ),
    ...((["APPOINTABLE_GROUP", "CLASS_GROUP"] as RuleGroupType[]).includes(
      group,
    )
      ? [listColumn]
      : []),
    columnHelper.accessor((e) => ({ id: e.id, isDefault: e.isDefault }), {
      id: "actions",
      header: () => t("page.rules.table.actions"),
      cell: (column) => {
        const value = column.getValue();
        return (
          <div className="flex gap-1 pr-2">
            <Button
              className="p-2"
              link={createClubLink(
                RouteLayout.Staff,
                RouteFeature.RuleForm,
                ruleFormRoute[group].replace(
                  ":ruleGroupId",
                  value.id.toString(),
                ),
              )}
            >
              <EditIcon className="h-5 w-5" pathClassName="stroke-gray-950" />
            </Button>
            <Button
              className={cn("p-2 border-error-300", {
                hidden: value.isDefault,
              })}
              onClick={async () => {
                try {
                  await deleteGroup({ ruleGroupId: value.id });
                  toast({ message: "Rule group deleted." });
                } catch (e) {
                  if (isAxiosError(e)) {
                    notifyDanger(e);
                  }
                }
              }}
            >
              <TrashIcon className="h-5 w-5" pathClassName="stroke-error-700" />
            </Button>
          </div>
        );
      },
    }),
  ];

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });
  return (
    <SettingsContainer
      title={t("page.rules.table.title")}
      subTitle={t("page.rules.table.explanation")}
      actions={
        (["CLASS_GROUP", "APPOINTABLE_GROUP"] as (typeof group)[]).includes(
          group,
        ) ? (
          <Button
            intent="secondary"
            link={createClubLink(
              RouteLayout.Staff,
              RouteFeature.RuleForm,
              ruleFormRoute[group + "_NEW"],
            )}
          >
            <div className="flex flex-row items-center gap-x-2">
              <FontAwesomeIcon icon={faPlus} />
              <div>{t("page.rules.table.addRule")}</div>
            </div>
          </Button>
        ) : undefined
      }
    >
      <div className="flex flex-row gap-x-2 border-b border-gray-200">
        {ruleGroups.map(({ label, ruleGroupType }) => (
          <div
            key={ruleGroupType}
            onClick={() => {
              history.push({
                search: qs.stringify({ group: ruleGroupType }),
              });
            }}
            className={cn(
              "max-w-min cursor-pointer flex-nowrap whitespace-nowrap px-1 pb-3 text-sm font-semibold text-gray-500",
              {
                "text-secondary-500 border-secondary-500 border-b-2":
                  group === ruleGroupType,
              },
            )}
          >
            {label}
          </div>
        ))}
      </div>
      <PaginatedTable
        tableProps={{
          data: data?.content ?? [],
          columns: commonColumns,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasNextPage={!!data && data?.number < data?.totalPages - 1}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setCurrentPage(currentPage + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage(currentPage - 1);
        }}
      />
    </SettingsContainer>
  );
}
