import { z } from "zod";

export const PaymentMethod = {
  BacsDirectDebit: "bacs",
  Card: "card",
} as const;

export const PaymentMethodZodSchema = z.enum([
  PaymentMethod.BacsDirectDebit,
  PaymentMethod.Card,
]);
