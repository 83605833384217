import { utcToZonedTime } from "@gymflow/helpers";
import { ApiTokenListResponse, MemberBookingDTO } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";

export function useInfiniteQueryUpcomingMemberBookingList({
  api,
  tz,
  opts: payload,
  disabled,
}: {
  api: UserMemberUpcomingBookingsList;
  tz?: string;
  opts: Omit<UserMemberBookingsListParams, "nextPageToken">;
  disabled?: boolean;
}) {
  const result = useInfiniteQuery({
    queryKey: eventOccurrenceQueryKeys.memberUpcoming(payload?.memberId, {
      ...payload,
      tz,
    }),
    queryFn: async ({ pageParam }) => {
      return (
        await api.eventApi.upcomingByUserMemberId({
          ...payload,
          nextPageToken: pageParam,
        })
      ).data;
    },
    select: (toSelect) => {
      const newPages = toSelect.pages.map((e) => {
        return merge({}, e, {
          content: e.content.map((e) => memberBookingToZonedTime(e, tz!)),
        });
      });
      return {
        pageParams: toSelect.pageParams,
        pages: newPages,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    enabled: !disabled && !!payload?.memberId && !!tz,
  });
  return result;
}

export function useInfiniteQueryPastMemberBookingList({
  api,
  tz,
  opts: payload,
  disabled,
}: {
  api: UserMemberPastBookingsList;
  tz?: string;
  opts: Omit<UserMemberBookingsListParams, "nextPageToken">;
  disabled?: boolean;
}) {
  const result = useInfiniteQuery({
    queryKey: eventOccurrenceQueryKeys.memberPast(payload?.memberId, {
      ...payload,
      tz,
    }),
    queryFn: async ({ pageParam }) => {
      return (
        await api.eventApi.pastByUserMemberId({
          ...payload,
          nextPageToken: pageParam,
        })
      ).data;
    },
    select: (toSelect) => {
      const newPages = toSelect.pages.map((e) => {
        return merge({}, e, {
          content: e.content.map((e) => memberBookingToZonedTime(e, tz!)),
        });
      });
      return {
        pageParams: toSelect.pageParams,
        pages: newPages,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextPageToken,
    enabled: !disabled && !!payload?.memberId && !!tz,
  });
  return result;
}

interface UserMemberUpcomingBookingsList {
  eventApi: {
    upcomingByUserMemberId: ({
      memberId,
      limit,
      nextPageToken,
    }: UserMemberBookingsListParams) => Promise<{
      data: ApiTokenListResponse<MemberBookingDTO>;
    }>;
  };
}
interface UserMemberPastBookingsList {
  eventApi: {
    pastByUserMemberId: ({
      memberId,
      limit,
      nextPageToken,
    }: UserMemberBookingsListParams) => Promise<{
      data: ApiTokenListResponse<MemberBookingDTO>;
    }>;
  };
}

type UserMemberBookingsListParams = {
  readonly memberId?: string;
  readonly limit: number;
  readonly nextPageToken?: string;
};

export function memberBookingToZonedTime(
  booking: MemberBookingDTO,
  tz: string,
) {
  return merge({}, booking, {
    startDate: utcToZonedTime(booking.startDate, tz),
    endDate: utcToZonedTime(booking.endDate, tz),
  });
}
