import { useAbility } from "@casl/react";
import {
  clubStaleTime,
  useClub,
  useClubFeatureFlags,
  useMutationMemberListCsv,
  UserMemberListFilter,
} from "@gymflow/api";
import { AlertContext } from "@gymflow/common";
import { cn, pluralize } from "@gymflow/helpers";
import {
  Button,
  DownloadIcon,
  FilterIcon,
  MailIcon,
  PlusIcon,
  SMSIcon,
} from "apps/portal/src/components/atoms";
import { NewUserSideBarProviderContext } from "apps/portal/src/components/molecules";
import SendEmailAlert from "apps/portal/src/components/UserMember/SendEmails/SendEmailAlert";
import { useSendEmailsNew } from "apps/portal/src/hooks";
import { AbilityContext, Subject, Verb } from "apps/portal/src/permissions";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import omit from "lodash/omit";
import { useCallback, useContext, useMemo } from "react";

import { getFilterAppliedCount } from "../../../../helpers";
import { dataFiltersPreparator } from "../utils/dataFiltersPreparator";

type ActionsSettingsContainerProps = {
  reset: () => void;
  filters: UserMemberListFilter;
  selected: string[];
  areAllSelected: boolean;
  selectionCount: number;
  excluded: string[];
  setFiltersAreVisible: (filtersAreVisible: boolean) => void;
  setIsSendSMSSidebarVisible: (isSendSMSSidebarVisible: boolean) => void;
};

export const ActionsSettingsContainer = ({
  reset,
  filters,
  selected,
  setFiltersAreVisible,
  setIsSendSMSSidebarVisible,
  areAllSelected,
  selectionCount,
  excluded,
}: ActionsSettingsContainerProps) => {
  const { api } = useGymflowModels();
  const { clubId, timezone: tz } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const ability = useAbility(AbilityContext);
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });
  const { mutateAsync: downloadCsv } = useMutationMemberListCsv({
    api,
    tz,
  });
  const { sendEmailToMembers, sendEmailToMemberFilter } = useSendEmailsNew();
  const alert = useContext(AlertContext);
  const { open } = useContext(NewUserSideBarProviderContext);

  // it needs to be additionally refactored
  const onClickMail = useCallback(
    () =>
      alert.setAlert(
        <SendEmailAlert
          allowMarketing
          from={club?.email ?? ""}
          to={`${selectionCount} ${pluralize("User", "Users", selectionCount)}`}
          onSubmit={async (values) => {
            const bcc = values.bcc ? values.bcc.split(",") : [];
            const emailPayload = {
              marketing: values.marketing,
              body: values.body,
              subject: values.subject,
              bccList: bcc,
            };

            if (areAllSelected) {
              await sendEmailToMemberFilter({
                ...dataFiltersPreparator(filters, excluded),
                emailPayload,
              });
            } else {
              await sendEmailToMembers({
                emailPayload,
                userMemberIds: selected,
              });
            }
            reset();
          }}
          onCancel={alert.hide}
        />,
      ),
    [
      alert,
      areAllSelected,
      club?.email,
      excluded,
      filters,
      reset,
      selected,
      selectionCount,
      sendEmailToMemberFilter,
      sendEmailToMembers,
    ],
  );

  const isNotSelected = useMemo(
    () => selected.length === 0 && !areAllSelected,
    [areAllSelected, selected.length],
  );

  const isHiddenEmail = useMemo(
    () => isNotSelected || !ability.can(Verb.Create, Subject.Email),
    [ability, isNotSelected],
  );

  const isHiddenSMS = useMemo(
    () =>
      !featureFlags?.featureFlags.PORTAL_SEND_SMS ||
      isNotSelected ||
      !ability.can(Verb.Create, Subject.SMS),
    [ability, featureFlags?.featureFlags.PORTAL_SEND_SMS, isNotSelected],
  );

  return (
    <>
      <Button
        onClick={async () => await downloadCsv(filters)}
        className="mt-0 w-11 min-w-[2.75rem] p-0"
      >
        <DownloadIcon pathClassName="stroke-gray-500" />
      </Button>
      <Button
        className={cn("mt-0 w-11 min-w-[2.75rem] p-0", {
          hidden: isHiddenEmail,
        })}
        onClick={onClickMail}
      >
        <MailIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
      </Button>
      <Button
        className={cn("mt-0 w-11 min-w-[2.75rem] p-0", {
          hidden: isHiddenSMS,
        })}
        onClick={() => setIsSendSMSSidebarVisible(true)}
      >
        <SMSIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
      </Button>
      <Button
        className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
        onClick={() => setFiltersAreVisible(true)}
        badgeContent={getFilterAppliedCount(
          omit(filters, [
            "createdTo",
            "membershipStartTo",
            "membershipCancellationTo",
            "membershipExpireTo",
          ]),
        )}
      >
        <div className="inline-flex items-center gap-x-2">
          <FilterIcon
            className="h-[1.125rem] w-[1.125rem]"
            pathClassName="stroke-gray-500"
          />
          <div className={cn("hidden lg:flex")}>Filters</div>
        </div>
      </Button>
      <Button
        className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
        onClick={() => open()}
        intent="secondary"
      >
        <div className="inline-flex items-center gap-x-2">
          <PlusIcon
            className="h-[1.125rem] w-[1.125rem]"
            pathClassName="stroke-white"
          />
          <div className={cn("hidden lg:flex")}>User</div>
        </div>
      </Button>
    </>
  );
};
