import { useClub } from "@gymflow/api";
import { useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";

import { LoadingPortal } from "../components/pages";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export function Roadmap() {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { authStore, api } = useGymflowModels();
  const user = useStoreState(authStore);
  const { data: club } = useClub({ api, clubId });
  const { routeId, brand, company } = usePortalRoutes();

  const [isSdkLoaded, setIsSdkLoaded] = useState(false);

  useEffect(() => {
    if (!club) {
      return;
    }
    const details = {
      organization: "gymflow",

      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      id: user.id,

      customFields: {
        brand,
        company,
        routeId,
      },

      companies: [
        {
          id: club.id.toString(),
          name: club.name,
        },
      ],
    } as any;
    if (user.avatar && user.avatar.startsWith("http")) {
      details.profilePicture = user.avatar;
    }
    //@ts-ignore-next-line
    Featurebase("identify", details, (err: unknown) => {
      if (err) {
        throw err;
      }
      setIsSdkLoaded(true);
    });
  }, [club]);

  if (!isSdkLoaded) {
    return <LoadingPortal message="Loading roadmap..." />;
  }

  return (
    <iframe
      title="Featurebase Roadmap"
      style={{ background: "#fff" }}
      src="https://gymflow.featurebase.app"
      className="w-100 h-100"
      id="featurebase-frame"
    ></iframe>
  );
}
