import classNames from "classnames";
import { useFormik } from "formik";
import noop from "lodash/noop";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";

import { DATE_FORMAT, renderErrorRowOnTouch } from "../../../helpers/form";
import formikHelpers from "../../../helpers/formik";
import useRecordForm from "../../../hooks/useRecordForm";
import AsyncButton from "../../atoms/AsyncButton";
import GenderSelect from "../../atoms/GenderSelect";
import LeadSourceSelect from "../../atoms/LeadSourceSelect";
import RequiredFieldsLabel from "../../atoms/RequiredFieldsLabel";
import FormikInput from "../../molecules/FormikInput";
import MobileNumberInput from "../../molecules/MobileNumberInput";
import UserDetailsFormMapper from "./mapper";
import createSchema, { Field } from "./schema";

function UserForm({
  fetchLeadSources,
  postCodeFormat,
  defaultNationality,
  dateFormat,
  findByEmail,
  onSubmit,
  submitBtnText,
  value,
  validEmails,
  requiredFields,
  clubWaiverLink,
  isStaffPerspective,
  phone_number_country,
}) {
  const validationSchema = useMemo(
    () =>
      createSchema({
        postCodeFormat,
        defaultNationality,
        dateFormat,
        validEmails,
        findByEmail,
        requiredFields,
        mustAgreeToClubWaiver: !isStaffPerspective,
      }),
    [
      postCodeFormat,
      defaultNationality,
      dateFormat,
      validEmails,
      findByEmail,
      requiredFields,
    ],
  );

  useEffect(() => {
    if (!value) {
      formikProps.resetForm();
      return;
    }
    let newValues = { ...value };
    if (value[Field.DATE_OF_BIRTH]) {
      newValues[Field.DATE_OF_BIRTH] = moment(
        value[Field.DATE_OF_BIRTH],
        DATE_FORMAT,
      ).format(dateFormat);
    }

    formikProps.setValues({
      ...newValues,
      [Field.EMAIL_COMMUNICATION]: value[Field.EMAIL_COMMUNICATION] ?? false,
      [Field.SMS_COMMUNICATION]: value[Field.SMS_COMMUNICATION] ?? false,
    });
  }, [value]);

  const { initialValues, getValues } = useRecordForm({
    record: null,
    fields: validationSchema.default(),
    mapper: new UserDetailsFormMapper({
      dateFormat,
    }),
  });
  const formikProps = useFormik({
    initialValues,
    validationSchema,
    onSubmit: noop,
  });

  const { errorClass } = formikHelpers(formikProps);

  return (
    <Form className="form-horizontal" role="form">
      <Row className="mt-3">
        <Col>
          <Row>
            <Col xs="12">
              <Label htmlFor={Field.EMAIL} className="text-uppercase">
                Email *
              </Label>
            </Col>
            <Col xs="12">
              <FormGroup className={errorClass(Field.EMAIL)}>
                <FormikInput
                  name={Field.EMAIL}
                  type="email"
                  data-testid={Field.EMAIL}
                  autoComplete="off"
                  maxLength="128"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs="12" sm="6">
              <Label htmlFor={Field.FIRST_NAME} className="text-uppercase">
                First Name *
              </Label>
              <FormGroup className={errorClass(Field.FIRST_NAME)}>
                <FormikInput
                  name={Field.FIRST_NAME}
                  id={Field.FIRST_NAME}
                  type="text"
                  maxLength="128"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>

            <Col xs="12" sm="6">
              <Label htmlFor={Field.LAST_NAME} className="text-uppercase">
                Last Name *
              </Label>
              <FormGroup className={errorClass(Field.LAST_NAME)}>
                <FormikInput
                  name={Field.LAST_NAME}
                  id={Field.LAST_NAME}
                  type="text"
                  maxLength="128"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col
              className={classNames({ hidden: !requiredFields.mobileNumber })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.MOBILE_NUMBER} className="text-uppercase">
                Phone *
              </Label>
              <FormGroup className={errorClass(Field.MOBILE_NUMBER)}>
                <FormikInput
                  name={Field.MOBILE_NUMBER}
                  component={MobileNumberInput}
                  type="tel"
                  id={Field.MOBILE_NUMBER}
                  maxLength="32"
                  formikProps={formikProps}
                  phone_number_country={phone_number_country}
                />
              </FormGroup>
            </Col>

            <Col
              className={classNames({ hidden: !requiredFields.dateOfBirth })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.DATE_OF_BIRTH} className="text-uppercase">
                Date of Birth *
              </Label>
              <FormGroup className={errorClass(Field.DATE_OF_BIRTH)}>
                <FormikInput
                  name={Field.DATE_OF_BIRTH}
                  type="text"
                  id={Field.DATE_OF_BIRTH}
                  maxLength={dateFormat.length}
                  formikProps={formikProps}
                  placeholder={dateFormat}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col
              className={classNames({ hidden: !requiredFields.addressLine })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.ADDRESS1} className="text-uppercase">
                Address *
              </Label>
              <FormGroup className={errorClass(Field.ADDRESS1)}>
                <FormikInput
                  name={Field.ADDRESS1}
                  type="text"
                  id={Field.ADDRESS1}
                  maxLength="128"
                  formikProps={formikProps}
                  data-testid={Field.ADDRESS1}
                />
              </FormGroup>
            </Col>

            <Col
              className={classNames({ hidden: !requiredFields.addressLine })}
              xs="12"
              sm="6"
            >
              <Label
                htmlFor={Field.ADDRESS2}
                className="text-uppercase invisible"
              >
                Address 2
              </Label>
              <FormGroup className={errorClass(Field.ADDRESS2)}>
                <FormikInput
                  name={Field.ADDRESS2}
                  data-testid={Field.ADDRESS2}
                  type="text"
                  maxLength="128"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col
              className={classNames({ hidden: !requiredFields.postCode })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.POST_CODE} className="text-uppercase">
                Postcode/Zip *
              </Label>
              <FormGroup className={errorClass(Field.POST_CODE)}>
                <FormikInput
                  name={Field.POST_CODE}
                  type="text"
                  id={Field.POST_CODE}
                  maxLength="16"
                  formikProps={formikProps}
                  style={{ textTransform: "uppercase" }}
                />
              </FormGroup>
            </Col>

            <Col
              className={classNames({ hidden: !requiredFields.city })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.CITY} className="text-uppercase">
                City *
              </Label>
              <FormGroup className={errorClass(Field.CITY)}>
                <FormikInput
                  name={Field.CITY}
                  type="text"
                  id={Field.CITY}
                  maxLength="128"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col
              className={classNames({ hidden: !requiredFields.personalNumber })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.CITY} className="text-uppercase">
                {defaultNationality === "ESP" ? "DNI/Passport" : "ID Number"}
              </Label>
              <FormGroup className={errorClass(Field.PERSONAL_NUMBER)}>
                <FormikInput
                  name={Field.PERSONAL_NUMBER}
                  type="text"
                  id={Field.PERSONAL_NUMBER}
                  maxLength="25"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col
              className={classNames({ hidden: !requiredFields.gender })}
              xs="12"
              sm="6"
            >
              <Label htmlFor={Field.GENDER} className="text-uppercase">
                Gender
              </Label>
              <FormGroup className={errorClass(Field.GENDER)}>
                <GenderSelect
                  onChange={(newGender) =>
                    formikProps.setFieldValue(Field.GENDER, newGender)
                  }
                  value={formikProps.values[Field.GENDER]}
                />
              </FormGroup>
            </Col>

            <Col xs="12" sm="6">
              <Label htmlFor={Field.SOURCE} className="text-uppercase">
                How did you hear about us? *
              </Label>

              <FormGroup
                className={classNames(
                  "dropdown-group",
                  errorClass(Field.SOURCE),
                )}
                data-testid={Field.SOURCE}
              >
                <LeadSourceSelect
                  fetchLeadSources={fetchLeadSources}
                  value={formikProps.values[Field.SOURCE]}
                  onChange={({ value: newValue }) =>
                    formikProps.setFieldValue(Field.SOURCE, newValue)
                  }
                />
                {renderErrorRowOnTouch(
                  Field.SOURCE,
                  formikProps.touched,
                  formikProps.errors,
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col
              className={classNames({
                hidden: !requiredFields.emergencyContact,
              })}
              xs="12"
              sm="6"
            >
              <Label
                htmlFor={Field.EMERGENCY_CONTACT_NAME}
                className="text-uppercase"
              >
                Emergency Contact Name
              </Label>
              <FormGroup className={errorClass(Field.EMERGENCY_CONTACT_NAME)}>
                <FormikInput
                  name={Field.EMERGENCY_CONTACT_NAME}
                  type="text"
                  id={Field.EMERGENCY_CONTACT_NAME}
                  maxLength="128"
                  formikProps={formikProps}
                />
              </FormGroup>
            </Col>

            <Col
              className={classNames({
                hidden: !requiredFields.emergencyContact,
              })}
              xs="12"
              sm="6"
            >
              <Label
                htmlFor={Field.EMERGENCY_CONTACT}
                className="text-uppercase"
              >
                Emergency Contact Phone
              </Label>
              <FormGroup className={errorClass(Field.EMERGENCY_CONTACT)}>
                <FormikInput
                  name={Field.EMERGENCY_CONTACT}
                  component={MobileNumberInput}
                  id={Field.EMERGENCY_CONTACT}
                  type="tel"
                  maxLength="32"
                  formikProps={formikProps}
                  phone_number_country={phone_number_country}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="checkbox-radios">
              <FormGroup
                check
                className={errorClass(Field.EMAIL_COMMUNICATION)}
              >
                <Label check>
                  <Input
                    name={Field.EMAIL_COMMUNICATION}
                    type="checkbox"
                    id={Field.EMAIL_COMMUNICATION}
                    data-testid="email-communication"
                    checked={formikProps.values[Field.EMAIL_COMMUNICATION]}
                    onChange={({ target: { checked } }) => {
                      formikProps.setFieldValue(
                        Field.EMAIL_COMMUNICATION,
                        checked,
                      );
                    }}
                  />
                  <span className="form-check-sign" />
                  Keep in touch via Email
                </Label>
              </FormGroup>
              <FormGroup check className={errorClass(Field.SMS_COMMUNICATION)}>
                <Label check>
                  <Input
                    name={Field.SMS_COMMUNICATION}
                    type="checkbox"
                    data-testid="sms-communication"
                    checked={formikProps.values[Field.SMS_COMMUNICATION]}
                    onChange={({ target: { checked } }) => {
                      formikProps.setFieldValue(
                        Field.SMS_COMMUNICATION,
                        checked,
                      );
                    }}
                  />
                  <span className="form-check-sign" />
                  Keep in touch via SMS
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="checkbox-radios">
              <FormGroup
                check
                className={errorClass(Field.IS_CLUB_WAIVER_ACCEPTED)}
              >
                <Label check>
                  <Input
                    name={Field.IS_CLUB_WAIVER_ACCEPTED}
                    type="checkbox"
                    id={Field.IS_CLUB_WAIVER_ACCEPTED}
                    checked={formikProps.values[Field.IS_CLUB_WAIVER_ACCEPTED]}
                    onChange={({ target: { checked } }) => {
                      formikProps.setFieldValue(
                        Field.IS_CLUB_WAIVER_ACCEPTED,
                        checked,
                      );
                    }}
                  />
                  <span className="form-check-sign" />
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {isStaffPerspective ? "The client agrees " : "I agree "}to
                    the{" "}
                    <a
                      href={clubWaiverLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      club's waiver
                    </a>
                    .{!isStaffPerspective && " *"}
                  </div>
                </Label>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <RequiredFieldsLabel />
            </Col>
          </Row>

          <Row>
            <Col>
              <AsyncButton
                className="font-weight-bold"
                color="primary"
                size="sm"
                style={{ minWidth: "140px" }}
                onClick={async () => {
                  await formikProps.submitForm();
                  const errors = await formikProps.validateForm();
                  if (Object.keys(errors).length > 0) {
                    formikProps.setTouched({
                      ...formikProps.touched,
                      ...errors,
                    });
                    await onSubmit({ isValid: false });
                    return;
                  }

                  await onSubmit({
                    values: getValues(formikProps.values),
                    isValid: true,
                  });
                }}
              >
                {submitBtnText}
              </AsyncButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

UserForm.defaultProps = {
  submitBtnText: "Save",
  value: null,
  validEmails: [],
  allowPhoneCountrySelection: false,
  requiredFields: {
    mobileNumber: true,
    dateOfBirth: true,
    addressLine1: true,
    addressLine2: true,
    postCode: true,
    city: true,
    gender: true,
    emergencyContact: true,
  },
  isStaffPerspective: false,
};

UserForm.propTypes = {
  fetchLeadSources: PropTypes.func.isRequired,
  postCodeFormat: PropTypes.string.isRequired,
  telephoneFormat: PropTypes.string.isRequired,
  defaultNationality: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  findByEmail: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitBtnText: PropTypes.string,
  value: PropTypes.object,
  validEmails: PropTypes.arrayOf(PropTypes.string),
  allowPhoneCountrySelection: PropTypes.bool,
  requiredFields: PropTypes.shape({
    mobileNumber: PropTypes.bool,
    dateOfBirth: PropTypes.bool,
    addressLine: PropTypes.bool,
    postCode: PropTypes.bool,
    city: PropTypes.bool,
    gender: PropTypes.bool,
    emergencyContact: PropTypes.bool,
  }),
  clubWaiverLink: PropTypes.string,
  isStaffPerspective: PropTypes.bool,
};

export default UserForm;
