import {
  useMutationAddPromotionCodeToSubscription,
  usePromotionList,
  useQueryAddPromotionCodeToSubscriptionSummary,
} from "@gymflow/api";
import { cn, formatCurrency } from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { useContext, useEffect, useState } from "react";

import {
  ModalContext,
  ModalWrapper,
  useClubSettings,
} from "../../../../providers";
import useGymflowModels from "../../../../store";
import {
  AlarmClockIcon,
  Button,
  DangerIcon,
  InputWithSaveButton,
} from "../../../atoms";

export function AddPromotionCodeModal({
  membership,
}: {
  membership: UserMemberSubscriptionBeanWithMembership;
}) {
  const { api } = useGymflowModels();
  const addPromotionCodeToSubscription =
    useMutationAddPromotionCodeToSubscription({ api });

  const [promoCode, setPromoCode] = useState<string>();
  const [error, setError] = useState<string>();
  const { hide: hideModal } = useContext(ModalContext);

  const {
    data: summary,
    isFetching: isLoadingSummary,
    error: summaryError,
  } = useQueryAddPromotionCodeToSubscriptionSummary(
    {
      api,
      subscriptionId: membership.id,
      promotionCode: promoCode,
    },
    {
      retry: false,
      enabled:
        !!membership.id &&
        !!promoCode &&
        !addPromotionCodeToSubscription.isLoading &&
        !addPromotionCodeToSubscription.isSuccess,
    },
  );
  const { data: promoCodeMatchResults, isFetching: isLoadingPromotion } =
    usePromotionList({
      api,
      opts: {
        extraParams: {
          code: promoCode,
        },
        limit: 100,
      },
    });

  const isLoading = isLoadingSummary || isLoadingPromotion;

  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (summary?.validPromotionCode === "YES") {
      setIsValid(true);
      return;
    } else if (promoCode) {
      setError("Promotion code is not valid for this subscription.");
    }
    if (summaryError) {
      setError((summaryError as any).response?.data?.errors?.subscriptionId);
    }
    setIsValid(false);
  }, [error, promoCode, summary, summaryError]);

  const { defaultCurrency } = useClubSettings();

  const selectedPromoCode = promoCodeMatchResults.content.find(({ code }) => {
    return code === promoCode?.toUpperCase();
  });
  return (
    <ModalWrapper onCancel={hideModal}>
      <div className="bg-secondary-50 flex h-11 w-11 items-center justify-center self-center rounded-full">
        <div className="bg-secondary-100 flex h-8 w-8 items-center justify-center self-center rounded-full">
          <DangerIcon
            className="h-5 w-5"
            pathClassName="stroke-secondary-600"
          />
        </div>
      </div>
      <div className="mt-4 text-lg font-semibold text-gray-900">
        Apply Promotional Code
      </div>
      <div className="mt-4">
        <InputWithSaveButton
          onChange={(newPromoCode) => {
            setIsValid(false);
            setError(undefined);
            setPromoCode(newPromoCode);
          }}
          value={promoCode ?? ""}
          isLoading={isLoading}
        />
      </div>
      {!isValid && error && <div className="text-error-600 mt-2">{error}</div>}
      <div className="my-5 border-t-2 border-dashed border-gray-300"></div>
      <div className="flex flex-col gap-2">
        <div className="flex justify-between text-base font-medium text-gray-500">
          <div>{membership.name}</div>
          <div>{formatCurrency(membership.price, defaultCurrency)}</div>
        </div>
        <div
          className={cn(
            "flex justify-between text-base font-medium text-gray-500",
            { hidden: !isValid },
          )}
        >
          <div>Promotional Code</div>
          <div>
            -
            {formatCurrency(
              summary?.recurringPriceDetails?.totalAmountDifference ?? 0,
              defaultCurrency,
            )}
          </div>
        </div>
        <div className="flex justify-between text-xl font-bold text-gray-950">
          <div>Amount</div>
          <div>
            {formatCurrency(
              promoCode && summary?.validPromotionCode === "YES"
                ? summary.recurringPriceDetails.totalAmountToPay
                : membership.price,
              defaultCurrency,
            )}
          </div>
        </div>
      </div>
      <div
        className={cn(
          "mt-4 flex items-center justify-between rounded-xl border border-gray-200 px-4 py-3",
          {
            hidden: !selectedPromoCode || !isValid,
          },
        )}
      >
        <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-500">
          <AlarmClockIcon className="h-5 w-5" pathClassName="stroke-gray-500" />
          Duration
        </div>
        <div className="text-base font-semibold text-gray-950">
          {selectedPromoCode?.recurringDiscountDuration
            ? `Next ${selectedPromoCode.recurringDiscountDuration} Payments`
            : "All Future Payments"}
        </div>
      </div>
      <div className="mt-5 flex">
        <Button onClick={hideModal} className="flex-1">
          Cancel
        </Button>
        <Button
          intent="secondary"
          className="ml-4 flex-1"
          onClick={async () => {
            if (!promoCode) return;
            await addPromotionCodeToSubscription.mutateAsync({
              promotionCode: promoCode,
              subscriptionId: membership.id,
            });
            hideModal();
          }}
          disabled={!isValid}
        >
          Confirm
        </Button>
      </div>
    </ModalWrapper>
  );
}
