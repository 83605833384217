import { StripeProductItem } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { smsQueryKeys } from "./smsQueryKeys";

type Api = {
  smsApi: {
    getStripeProducts: () => Promise<{ data: StripeProductItem[] }>;
  };
};

export const useQueryStripeProducts = ({ api }: { api: Api }) => {
  return useQuery({
    queryKey: smsQueryKeys.getStripeProducts(),
    queryFn: async () => (await api.smsApi.getStripeProducts()).data,
  });
};
