import { cn } from "@gymflow/helpers";
import { ReactNode, useCallback } from "react";

export type WeekDay =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

export function WeekdaySelector({
  onChange,
  value,
}: {
  onChange: (weekdays: WeekDay[]) => void;
  value: WeekDay[];
}) {
  const dayClickHandler = useCallback(
    (weekday: WeekDay) => {
      if (value.includes(weekday)) {
        onChange(value.filter((w) => w !== weekday));
      } else {
        onChange([...value, weekday]);
      }
    },
    [onChange, value],
  );

  return (
    <div className="flex gap-2">
      <WeekdayButton
        isActive={value.includes("MONDAY")}
        onClick={() => dayClickHandler("MONDAY")}
      >
        Mon
      </WeekdayButton>
      <WeekdayButton
        isActive={value.includes("TUESDAY")}
        onClick={() => dayClickHandler("TUESDAY")}
      >
        Tue
      </WeekdayButton>
      <WeekdayButton
        isActive={value.includes("WEDNESDAY")}
        onClick={() => dayClickHandler("WEDNESDAY")}
      >
        Wed
      </WeekdayButton>
      <WeekdayButton
        isActive={value.includes("THURSDAY")}
        onClick={() => dayClickHandler("THURSDAY")}
      >
        Thu
      </WeekdayButton>
      <WeekdayButton
        isActive={value.includes("FRIDAY")}
        onClick={() => dayClickHandler("FRIDAY")}
      >
        Fri
      </WeekdayButton>
      <WeekdayButton
        isActive={value.includes("SATURDAY")}
        onClick={() => dayClickHandler("SATURDAY")}
      >
        Sat
      </WeekdayButton>
      <WeekdayButton
        isActive={value.includes("SUNDAY")}
        onClick={() => dayClickHandler("SUNDAY")}
      >
        Sun
      </WeekdayButton>
    </div>
  );
}

function WeekdayButton({
  children,
  isActive,
  onClick,
}: {
  children: ReactNode;
  isActive?: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={cn(
        "min-w-[2.5rem] cursor-pointer rounded-[0.625rem] border border-gray-300 px-2 py-2.5 text-center text-sm",
        { "text-primary-600 border-primary-600 bg-primary-25": isActive },
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
