import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export function useMutationSendMigrationEmail(
  {
    api,
  }: {
    api: {
      public: {
        hostedClubApi: {
          invitationRequest: ({
            email,
            invitationLinkPath,
          }: {
            email: string;
            invitationLinkPath: string;
          }) => Promise<{ data: void }>;
        };
      };
    };
  },

  mutationOpts?: UseMutationOptions<
    void,
    unknown,
    { email: string; invitationLinkPath: string }
  >,
) {
  return useMutation({
    mutationFn: async ({
      email,
      invitationLinkPath,
    }: {
      email: string;
      invitationLinkPath: string;
    }) => {
      await api.public.hostedClubApi.invitationRequest({
        email,
        invitationLinkPath,
      });
    },

    ...mutationOpts,
  });
}
