import { useMutation, useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { sessionPackQueryKeys } from './sessionPackQueryKeys';

export function useSessionPackEdit({
  api,
}: {
  api: {
    creditPackApi: {
      update: (
        id: number,
        arg1: { patchedFields: unknown; termsAndConditionsBlob: unknown },
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      sessionPackId,
      patchedFields,
    }: {
      sessionPackId: number;
      patchedFields: { [k: string]: any };
    }) => {
      await api.creditPackApi.update(sessionPackId, {
        patchedFields: omit(patchedFields, 'termsConditions'),
        termsAndConditionsBlob: patchedFields['termsConditions'],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: sessionPackQueryKeys.all() });
    },
  });
  return mutation;
}
