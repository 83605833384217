import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime } from "@gymflow/helpers";
import {
  MembershipBean,
  SessionPackDTO,
  UserMemberBean,
  UserMemberSubscriptionBean,
} from "@gymflow/types";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import merge from "lodash/merge";

import { memberQueryKeys } from "./memberQueryKeys";

export async function memberQueryFn({
  memberId,
  api,
}: {
  memberId: Parameters<ApiType["memberApi"]["findById"]>[0];
  api: ApiType;
}) {
  const result = await api.memberApi.findById(memberId);
  return result.data as UserMemberBean;
}

export function useMember(
  {
    api,
    memberId,
    tz,
  }: {
    api: ApiType;
    memberId?: Parameters<ApiType["memberApi"]["findById"]>[0];
    tz: string;
  },
  opts?: UseQueryResult<MemberDTO | null>,
) {
  const result = useQuery({
    queryKey: memberQueryKeys.details(memberId),
    queryFn: () => memberQueryFn({ memberId: memberId!, api }),
    enabled: !!memberId,
    initialData: null,
    select: (data) => {
      if (!data) {
        return null;
      }
      const user = convertUserToZonedTime(data, tz);
      return {
        user,
        creditPacks: user.subscriptions
          .filter((sub) => sub.sessionPackBean)
          .map(mappingSubscriptionCreditPack)
          .sort((a: SessionPackSubscription, b: SessionPackSubscription) => {
            const max = 1000;
            const minus =
              (sessionPackStatusOrder[a.status] || max) -
              (sessionPackStatusOrder[b.status] || max);
            if (minus === 0) {
              return (
                new Date(a.expiry).getTime() - new Date(b.expiry).getTime()
              );
            }
            return minus;
          }),
      };
    },
    ...opts,
  });

  return result;
}

interface SessionPackSubscription {
  status: keyof typeof sessionPackStatusOrder;
  expiry: string;
}

const convertUserToZonedTime = (user: UserMemberBean, timezone: string) => {
  return merge({}, user, {
    subscriptions: user.subscriptions.map((sub) =>
      merge({}, sub, {
        startDate: utcToZonedTime(sub.startDate, timezone),
        endDate: sub.endDate && utcToZonedTime(sub.endDate, timezone),
        endContractDate:
          sub.endContractDate && utcToZonedTime(sub.endContractDate, timezone),
        cancellationDate:
          sub.cancellationDate &&
          utcToZonedTime(sub.cancellationDate, timezone),
        pauseStartDate:
          sub.pauseStartDate && utcToZonedTime(sub.pauseStartDate, timezone),
        pauseEndDate:
          sub.pauseEndDate && utcToZonedTime(sub.pauseEndDate, timezone),
      }),
    ) as UserMemberSubscriptionBean[],
  });
};

const mappingSubscriptionCreditPack = (sub: any) => {
  return {
    id: sub.id,
    name: sub.sessionPackBean.name,
    remaining: sub.sessionsLeft,
    expiry: sub.endDate,
    addon: sub.addon,
    totalCredits: sub.sessionPackBean.sessionsIncluded,
    expiryType: sub.sessionPackBean.expiryType,
    unlimited: sub.sessionPackBean.sessionsUnlimited,
    price: sub.sessionPackBean.price,
    status: sub.status,
    startDate: sub.startDate,
    categoryIdList: sub.sessionPackBean.activityCategoryIdList,
    appointableCategoryIdList: sub.sessionPackBean.appointableCategoryIdList,
    termsConditions: sub.sessionPackBean.termsConditions,
    staffCancellable: sub.staffCancellable,
  };
};

const sessionPackStatusOrder = {
  ACTIVE: 1,
  EXPIRED: 2,
  OVERDUE: 3,
  PENDING: 4,
  PAUSED: 5,
  CANCELLED: 6,
};

interface MemberDTO {
  user: UserMemberBean;
  memberships: MembershipBean[];
  creditPacks: SessionPackDTO[];
}
