import { forwardRef, useDeferredValue, useMemo } from "react";

import { Textarea, TextareaProps } from "./Textarea";

export type SMSTextInputProps = {
  maxCharactersFor1SMSCredit?: number;
} & TextareaProps;

export const SMSTextInput = forwardRef<HTMLTextAreaElement, SMSTextInputProps>(
  ({ value, maxCharactersFor1SMSCredit = 160, ...rest }, ref) => {
    const deferredValue = useDeferredValue(value);
    const smsCredit = useMemo(() => {
      if (deferredValue.length === 0) {
        return 1;
      }
      return Math.ceil(deferredValue.length / maxCharactersFor1SMSCredit);
    }, [deferredValue.length, maxCharactersFor1SMSCredit]);

    return (
      <div className="flex flex-col gap-1.5">
        <Textarea ref={ref} value={value} {...rest} />
        <div className="flex justify-between">
          <div className="text-sm text-gray-500">{smsCredit} SMS Credit</div>
          <div className="text-sm text-gray-500">
            {value.length}/{maxCharactersFor1SMSCredit}
          </div>
        </div>
      </div>
    );
  },
);

SMSTextInput.displayName = "SMSTextInput";
