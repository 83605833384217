import {
  ApiListResponse,
  AppointableDTO,
  AppointableDtoStatus,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { appointableQueryKeys } from "./appointableQueryKeys";

export async function appointableListQueryFn({
  api,
  filter,
}: {
  api: AppointableListApi;
  filter: AppointableListFilter;
}) {
  const result = await api.appointableApi.find(filter);
  return result.data;
}

export function useAppointableList({
  api,
  opts,
}: {
  api: AppointableListApi;
  opts: AppointableListFilter;
}) {
  const result = useQuery({
    queryKey: appointableQueryKeys.list(opts),
    initialData: defaultPage<AppointableDTO>,
    queryFn: () => appointableListQueryFn({ api, filter: opts }),
  });
  return result;
}

interface AppointableListApi {
  appointableApi: {
    find: (
      params: Record<string, any>,
    ) => Promise<{ data: ApiListResponse<AppointableDTO> }>;
  };
}

interface AppointableListFilter {
  readonly extraParams?: {
    readonly id?: number[];
    readonly status?: AppointableDtoStatus;
    readonly allowOnlineBookings?: boolean;
    readonly allowAppBookings?: boolean;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
