import { hasStaffRole } from "@gymflow/api";
import { ReactNode, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { AbilityContext, create, update } from "../permissions";
import { RouteLayout } from "../routes/layout";
import { useAuthenticatedUser } from "./authentication";
import { useClubSettings } from "./settings";

export function PermissionsProvider({
  allowedRoleCategory,
  children,
}: {
  allowedRoleCategory: "STAFF" | "MEMBER";
  children: ReactNode;
}) {
  const { roles, id: userMemberId } = useAuthenticatedUser();
  const { stripe_account_type } = useClubSettings();

  const [ability, setAbility] = useState(
    create({
      roles,
      userId: userMemberId as string,
      stripeAccountType: stripe_account_type,
    }),
  );
  const isStaff = hasStaffRole(roles);
  const { createClubLink } = usePortalRoutes();

  useEffect(() => {
    update({
      ability,
      roles,
      userId: userMemberId as string,
      stripeAccountType: stripe_account_type,
    });
    setAbility(ability);
  }, [roles, setAbility]);

  if (allowedRoleCategory === "STAFF" && !isStaff) {
    return <Redirect to={createClubLink(RouteLayout.Member)} />;
  }

  if (allowedRoleCategory === "MEMBER" && isStaff) {
    return <Redirect to={createClubLink(RouteLayout.Staff)} />;
  }

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
}
