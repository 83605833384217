import { clubStaleTime, staffRoles, useClub, useStaff } from "@gymflow/api";
import { StaffUserRole } from "@gymflow/types";
import { useEffect, useRef } from "react";

import { useAuthenticatedUser, useClubSettings } from "../providers";
import useGymflowModels from "../store";
import { usePortalRoutes } from "./usePortalRoutes";

export function useIntercomReporting() {
  const { id: loggedInId, roles } = useAuthenticatedUser();
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 10;
  const RETRY_INTERVAL_MS = 500;
  const { routeId } = usePortalRoutes();

  const { api } = useGymflowModels();
  const { data: staff } = useStaff(
    { api, staffId: loggedInId },
    { staleTime: clubStaleTime },
  );
  const { clubId } = useClubSettings();
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  function updateIntercomUserData() {
    const windowAny = window as any;
    if (windowAny.Intercom && windowAny.intercomSettings) {
      const gymflowRole = roles.find((role) =>
        staffRoles.includes(role as StaffUserRole),
      );

      windowAny.Intercom("update", {
        user_id: loggedInId,
        "Home Club": staff!.homeClub.name,
        phone: staff!.mobileNumber,
        Role: gymflowRole,
        Timezone: club!.timezone,
        "Kisi Enabled": club?.kisiStatus === "ENABLED",
        "Route Id": routeId,
        company: {
          id: routeId,
          name: club!.name,
          created_at: Math.floor(Date.now() / 1000),
        },
      });
    } else {
      if (retryCountRef.current < MAX_RETRIES) {
        retryCountRef.current++;
        setTimeout(() => {
          updateIntercomUserData();
        }, RETRY_INTERVAL_MS);
      }
    }
  }

  useEffect(() => {
    if (!staff || !club) {
      return;
    }
    updateIntercomUserData();
  }, [staff, club]);
}
