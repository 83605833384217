import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AsyncPaginate } from 'react-select-async-paginate';
import { components } from 'react-select';
import { UserProfileType } from '@gymflow/common';
import memoizeOne from 'memoize-one';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import noop from 'lodash/noop';

import UserResult from './UserResult';
import useGymflowModels from '../../store';

function Input({ children, ...props }) {
  return (
    <>
      <FontAwesomeIcon className="-mt-8 ml-3" icon={faSearch} style={{ fontSize: 18 }} />
      <components.Input {...props} className="!ml-9" >{children}</components.Input>
    </>
  );
}

function UserSelect({
  value,
  onChange,
  search,
  onUserEditClick,
  beforeUserChange,
  afterUserChange,
  selectRef: propSelectRef,
}) {
  const { api } = useGymflowModels();
  const findByEmail = useMemo(() => memoizeOne(api.memberApi.findByEmail), []);
  const [selectValue, setSelectValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();

  useEffect(() => {
    if (!value) {
      setSelectValue(value);
      return;
    }
    setSelectValue({
      label: (
        <UserResult name={`${value.firstName} ${value.lastName}`} userType={value.userType} picture={value.picture} />
      ),
      value,
    });
  }, [value]);

  const loadOptions = async (term) => {
    if (term === '') {
      return emptyResult;
    }
    const { data } = await search(term);

    const options = data.map((user) => ({
      label: (
        <UserResult
          name={`${user.firstName} ${user.lastName}`}
          email={user.email}
          userType={user.userType}
          picture={user.picture}
          onEditClick={async () => {
            await onUserEditClick(await getUserDetails(user));
            selectRef.current.blur();
            setIsOpen(false);
          }}
        />
      ),
      value: user,
    }));

    return {
      options,
      hasMore: false,
    };
  };

  const emptyResult = {
    options: [
      {
        label: 'Type in to start searching',
        isDisabled: true,
      },
    ],
    hasMore: false,
  };

  const getUserDetails = async (newValue) => {
    await beforeUserChange();
    if (newValue.profileType === UserProfileType.Lead) {
      await afterUserChange();
      delete newValue.id;
      return { value: newValue };
    }
    const { data } = await findByEmail(newValue.email);
    await afterUserChange();
    return { value: { email: newValue.email, ...data } };
  };

  return (
    <AsyncPaginate
      autoComplete="new-password"
      additional={{
        page: 0,
      }}
      selectRef={(ref) => {
        selectRef.current = ref;
        propSelectRef(ref);
      }}
      loadOptions={loadOptions}
      value={selectValue}
      onChange={async ({ value: newValue }) => {
        onChange(await getUserDetails(newValue));
        setIsOpen(false);
      }}
      className="react-select info"
      classNamePrefix="react-select"
      placeholder="Search to select an existing user"
      menuIsOpen={isOpen}
      controlShouldRenderValue={!isOpen}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      styles={{
        control: (provided) => ({ ...provided, borderRadius: '30px', backgroundColor: '#f5f6f6 !important' }),
        indicatorsContainer: (provided) => ({ ...provided, display: 'none' }),
        singleValue: (provided) => ({ ...provided, marginLeft: '40px' }),
        placeholder: (provided) => ({ ...provided, marginLeft: '40px' }),
      }}
      components={{
        Input,
      }}
    />
  );
}

UserSelect.defaultProps = {
  value: null,
  beforeUserChange: Promise.resolve(),
  afterUserChange: Promise.resolve(),
  selectRef: noop,
};

UserSelect.propTypes = {
  value: PropTypes.shape({
    email: PropTypes.string.isRequired,
    userType: PropTypes.oneOf(Object.values(UserProfileType)),
    picture: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  onUserEditClick: PropTypes.func.isRequired,
  beforeUserChange: PropTypes.func,
  afterUserChange: PropTypes.func,
  selectRef: PropTypes.func,
};

export default UserSelect;
