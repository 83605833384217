export function EyeIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <path
            className={pathClassName}
            d="M2.11342 8.47562C2.02262 8.33186 1.97723 8.25998 1.95182 8.14911C1.93273 8.06583 1.93273 7.9345 1.95182 7.85122C1.97723 7.74035 2.02262 7.66847 2.11341 7.52471C2.86369 6.33672 5.09693 3.3335 8.50027 3.3335C11.9036 3.3335 14.1369 6.33672 14.8871 7.52471C14.9779 7.66847 15.0233 7.74035 15.0487 7.85122C15.0678 7.9345 15.0678 8.06583 15.0487 8.14911C15.0233 8.25998 14.9779 8.33186 14.8871 8.47562C14.1369 9.6636 11.9036 12.6668 8.50027 12.6668C5.09693 12.6668 2.86369 9.6636 2.11342 8.47562Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className={pathClassName}
            d="M8.50027 10.0002C9.60484 10.0002 10.5003 9.10473 10.5003 8.00016C10.5003 6.89559 9.60484 6.00016 8.50027 6.00016C7.3957 6.00016 6.50027 6.89559 6.50027 8.00016C6.50027 9.10473 7.3957 10.0002 8.50027 10.0002Z"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
