import { useQueryRuleGroup } from "@gymflow/api";
import { RuleGroup, RuleType } from "@gymflow/types";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import useGymflowModels from "apps/portal/src/store";
import { isAxiosError } from "axios";
import {
  ForwardRefExoticComponent,
  RefAttributes,
  useContext,
  useMemo,
  useRef,
} from "react";
import { useParams } from "react-router";

import { Spinner } from "../../atoms";
import { RulesComponents } from "./RulesComponents";

type RuleFormType = ForwardRefExoticComponent<
  (
    | {
        isEditing: true;
        initialValues: RuleGroup;
        ruleTypeToIdMap: Record<RuleType, number>;
      }
    | {
        isEditing: false;
      }
  ) &
    RefAttributes<{
      submitForm: () => Promise<any>;
    }>
>;
export type RuleFormWrapperProps = {
  initialValues: any;
  isEditing: boolean;
  ruleTypeToIdMap: Record<RuleType, number>;
};
export function RuleFormWrapper({
  title,
  description,
  Form,
}: {
  title: string;
  description: string;
  Form: RuleFormType;
}) {
  const { ruleGroupId } = useParams<{ ruleGroupId: string }>();
  const isEditing = ruleGroupId !== undefined && ruleGroupId !== "";
  const { api } = useGymflowModels();

  const {
    data: rulesList,
    isLoading,
    isSuccess,
    isError,
  } = useQueryRuleGroup({
    api,
    ruleGroupId: ruleGroupId !== undefined ? +ruleGroupId : undefined,
  });
  // This map exists to comply with the endpoint interface, ideally the interface would be changed to receive the type itself...
  const ruleTypeToIdMap = useMemo(() => {
    return rulesList?.ruleClubList?.reduce(
      (acc, ruleGroup) => {
        acc[ruleGroup.ruleType] = ruleGroup.clubRuleId;
        return acc;
      },
      {} as Record<RuleType, number>,
    );
  }, [rulesList]);

  const submitFormRef = useRef<{
    submitForm: () => Promise<any>;
  }>(null);
  const { notifyDanger } = useContext(ToastContext);
  return (
    <RulesComponents.Container
      title={title}
      description={description}
      onSubmit={async () => {
        await submitFormRef.current?.submitForm().catch((e) => {
          if (isAxiosError(e)) {
            notifyDanger(e);
          }
        });
      }}
    >
      {isEditing && (
        <>
          {isLoading && <Spinner />}
          {!isLoading && isSuccess && ruleTypeToIdMap && (
            <Form
              ref={submitFormRef}
              isEditing={isEditing}
              initialValues={rulesList}
              ruleTypeToIdMap={ruleTypeToIdMap}
            />
          )}
          {!isLoading && isError && (
            <div className="flex flex-col self-center">An error occurred</div>
          )}
        </>
      )}
      {!isEditing && <Form ref={submitFormRef} isEditing={isEditing} />}
    </RulesComponents.Container>
  );
}
