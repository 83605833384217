import { downloadCsv, zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation } from "@tanstack/react-query";

import { LeadListFilter } from "./useInfiniteQueryLeadList";

export function useMutationLeadListCsv({
  api,
  tz,
}: {
  api: LeadApi;
  tz: string;
}) {
  return useMutation({
    mutationFn: async (filters?: LeadListFilter) => {
      const parsedOpts = { ...filters };
      if (filters?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(filters.createdFrom, tz);
      }
      if (filters?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(filters.createdTo, tz);
      }
      return downloadCsv(
        (await api.leadApi.listCsv(parsedOpts)).data,
        "leads.csv",
      );
    },
  });
}

interface LeadApi {
  leadApi: {
    listCsv: (queryParams: any) => Promise<{ data: any }>;
  };
}
