import classNames from "classnames";
import format from "date-fns/format";

import { StatusCircle } from "./StatusCircle";
import { CalendarEventImpl, CalendarOccurrenceExtendedProps } from "./types";

export function EventContentOccurrence({
  event,
  mini,
}: {
  event: CalendarEventImpl<CalendarOccurrenceExtendedProps>;
  mini: boolean;
}) {
  const { hostName, capacity, bookedCount } = event.extendedProps;
  const isBookable = typeof capacity !== "undefined";

  const getStatus = () => {
    if (
      typeof event.extendedProps.capacity !== "undefined" &&
      typeof event.extendedProps.bookedCount !== "undefined"
    ) {
      const { capacity, bookedCount } = event.extendedProps;
      return bookedCount / capacity < 0.8
        ? "available"
        : bookedCount / capacity >= 0.8 && bookedCount / capacity < 1
        ? "maybe"
        : "unavailable";
    } else {
      return null;
    }
  };

  return (
    <>
      {!!event.start && !mini && (
        <div className="flex items-center justify-between text-xs font-medium text-gray-500">
          <div>{format(new Date(event.start), "h:mm a")}</div>
          <div>
            <StatusCircle status={getStatus()} />
          </div>
        </div>
      )}
      <div className="flex items-center justify-between text-sm font-semibold text-gray-900 ">
        <div>{event.title}</div>
        <div className={classNames({ hidden: !mini })}>
          <StatusCircle status={getStatus()} />
        </div>
      </div>
      <div
        className={classNames("text-xs font-medium text-gray-500", {
          hidden: mini,
        })}
      >
        {hostName}
      </div>
      <div
        className={classNames("text-xs font-medium text-gray-500", {
          hidden: !isBookable,
        })}
      >
        {bookedCount} / {capacity}
      </div>
    </>
  );
}
