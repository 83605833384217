import { useMembershipList } from "@gymflow/api";
import { cn, formatCurrency } from "@gymflow/helpers";
import { MembershipBean, MembershipType } from "@gymflow/types";
import React, { useContext, useState } from "react";

import searchIcon from "../../../assets/img/search-lg.svg";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Button } from "../atoms";
import { PrepaidMembershipFormSidebarProviderContext } from "../Settings/Membership/PrepaidMembershipFormSidebarProvider";
import { RecurringMembershipFormSidebarProviderContext } from "../Settings/Membership/RecurringMembershipFormSidebarProvider";
import { TrialMembershipFormSidebarProviderContext } from "../Settings/Membership/TrialMembershipFormSidebarProvider";
import { ShopItemPickerProps } from "./ShopItemPicker";
import { ShopCartType } from "./ShopTypes";

export type ShopMembershipSelectProps = {
  type: "recurring" | "prepaid" | "trial";
  cart?: ShopCartType;
  setCart: ShopItemPickerProps["setCart"];
  hideShopSidebar: () => void;
  showShopSidebar: () => void;
  userHasActiveMembershipSubscription: boolean;
};
const filters: {
  [key in ShopMembershipSelectProps["type"]]: {
    isTrial?: boolean;
    type: MembershipType;
  };
} = {
  recurring: { type: "RECURRING" },
  prepaid: { isTrial: false, type: "PREPAID" },
  trial: { isTrial: true, type: "PREPAID" },
};

const BillingTypeMap = {
  MONTHLY: "Month",
  WEEKLY: "Week",
  DAILY: "Day",
  NO_CONTRACT: "",
} as const;

export const ShopMembershipSelect: React.FC<ShopMembershipSelectProps> = ({
  type,
  cart,
  setCart,
  hideShopSidebar,
  showShopSidebar,
  userHasActiveMembershipSubscription,
}) => {
  const { api } = useGymflowModels();
  const clubSettings = useClubSettings();
  const { data } = useMembershipList({
    api,
    opts: {
      sort: { field: "createdDate", desc: true },
      extraParams: {
        status: "ACTIVE",
        unpaged: true,
        ...filters[type],
      },
    },
  });
  const { showTrialMembershipForm } = useContext(
    TrialMembershipFormSidebarProviderContext,
  );
  const { showRecurringMembershipForm } = useContext(
    RecurringMembershipFormSidebarProviderContext,
  );
  const { showPrepaidMembershipForm } = useContext(
    PrepaidMembershipFormSidebarProviderContext,
  );
  const [query, setQuery] = useState("");

  if (userHasActiveMembershipSubscription) {
    return (
      <div className="pt-[2px]">
        <div className="flex flex-row items-center justify-between px-6 py-4">
          This member already has an active or pending membership.
        </div>
      </div>
    );
  }

  return (
    <div className="pt-[2px]">
      <div className="flex flex-row items-center justify-between px-6 py-4">
        <img src={searchIcon} alt="search-icon" className="h-5" />
        <input
          placeholder="Search..."
          className="flex h-11 w-full pl-2 placeholder:text-base placeholder:text-gray-900 focus:outline-none"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Button
          className="min-w-fit"
          onClick={() => {
            hideShopSidebar();
            (
              ({
                recurring: showRecurringMembershipForm,
                prepaid: showPrepaidMembershipForm,
                trial: showTrialMembershipForm,
              }) as const
            )[type]({
              onClose: (membership) => {
                if (!membership) {
                  showShopSidebar();
                  return;
                }
                setCart({
                  type: "MEMBERSHIP",
                  payload: membership,
                });
                showShopSidebar();
              },
            });
          }}
        >
          Create New
        </Button>
      </div>
      {data?.content
        .filter(
          (e) =>
            e.name.toLowerCase().includes(query.toLowerCase()) ||
            e.defaultPrice.toString().includes(query),
        )
        ?.map((membership) => (
          <div
            key={membership.id}
            className={cn(
              "hover:bg-primary-50 -z-10 flex w-full cursor-pointer items-center justify-between border-t border-gray-100 px-6 py-4",
              {
                "bg-primary-50":
                  cart?.type === "MEMBERSHIP" &&
                  cart?.payload?.id === membership.id,
              },
            )}
            onClick={() => {
              setCart({
                type: "MEMBERSHIP",
                payload: membership as any as MembershipBean,
              });
            }}
          >
            <div className="text-sm text-gray-900"> {membership.name}</div>
            <div className="flex flex-col items-end">
              <div className="text-sm font-semibold text-gray-900">
                {formatCurrency(
                  membership.defaultPrice,
                  clubSettings.defaultCurrency,
                )}
              </div>
              <div className="text-sm text-gray-600">
                {membership.type === "RECURRING"
                  ? `Every ${membership.billingPeriod} ${
                      BillingTypeMap[membership.billingType]
                    }${membership.billingPeriod > 1 ? "s" : ""}`
                  : `${membership.duration} ${
                      BillingTypeMap[membership.durationType]
                    }${membership.duration > 1 ? "s" : ""}`}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
