import { zonedTimeToUtc } from "@gymflow/helpers";
import { merge } from "lodash";

export function eventOccurrenceToUtc(occurrence: any, tz: string) {
  const utcFields = {} as any;

  if (occurrence.startDate || occurrence.startTime) {
    const start = zonedTimeToUtc(
      `${occurrence.startDate}T${occurrence.startTime}`,
      tz,
    );
    const [startDate, startTime] = start.split("T");
    utcFields.startDate = startDate;
    utcFields.startTime = startTime.replace("Z", "");
  }

  if (occurrence.endDate || occurrence.endTime) {
    const end = zonedTimeToUtc(
      `${occurrence.endDate}T${occurrence.endTime}`,
      tz,
    );
    const [endDate, endTime] = end.split("T");
    utcFields.endDate = endDate;
    utcFields.endTime = endTime.replace("Z", "");
  }

  if (occurrence.startRecurringDate) {
    const recurringStart = zonedTimeToUtc(
      `${occurrence.startRecurringDate}T${occurrence.startTime}`,
      tz,
    );
    const [recurringStartDate] = recurringStart.split("T");
    utcFields.startRecurringDate = recurringStartDate;
  }

  if (occurrence.endRecurringDate) {
    const recurringEnd = zonedTimeToUtc(
      `${occurrence.endRecurringDate}T${occurrence.endTime}`,
      tz,
    );
    const [recurringEndDate] = recurringEnd.split("T");
    utcFields.endRecurringDate = recurringEndDate;
  }

  return merge({}, occurrence, utcFields);
}
