import { clubStaleTime, useClub } from "@gymflow/api";
import { useUserFormFieldConfiguration } from "@gymflow/common";
import { UserMemberBean } from "@gymflow/types";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { UserMemberProfile } from "./UserMemberProfile";

export function UserMemberOverview({ user }: { user: UserMemberBean }) {
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: requiredFields } = useUserFormFieldConfiguration({
    api,
    clubId: settings.clubId,
  });
  const club = useClub(
    { api, clubId: settings.clubId! },
    { staleTime: clubStaleTime },
  );

  return (
    <div className="-mx-4 flex max-h-full flex-col overflow-y-auto px-4 lg:-mx-8 lg:px-8">
      <div className="relative flex h-full max-h-full w-full flex-col">
        <UserMemberProfile
          user={user}
          // TODO - Review this type
          requiredFields={requiredFields as any}
          kisiStatus={club?.data?.kisiStatus}
        />
      </div>
    </div>
  );
}
