import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductDTO } from '@gymflow/types';

import { productQueryKeys } from './productQueryKeys';

export function useProductCreate({
  api,
}: {
  api: {
    productApi: {
      create: (arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newProduct: { [k: string]: any }) => {
      const response = await api.productApi.create(newProduct);
      return response.data as ProductDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productQueryKeys.all() });
    },
  });

  return mutation;
}
