import { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { Spinner } from "@gymflow/common";

import SessionPackFilterableList from "./SessionPackFilterableList";

function Step2SessionPacks({ value, onChange }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div className="w-100 text-center" style={{ opacity: isLoading ? "0.2" : "1" }}>
        <b className="h2">Select specific credit packs to display</b>
        <Row className="d-flex justify-content-center mt-4 text-left">
          <Col md={8}>
            <p className="text-uppercase text-muted">Choose Credit Packs</p>
            <SessionPackFilterableList onChange={onChange} value={value} onChangeLoading={setIsLoading} />
          </Col>
        </Row>
      </div>
    </>
  );
}

Step2SessionPacks.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Step2SessionPacks;
