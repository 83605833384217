import { ProductDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { productQueryKeys } from "./productQueryKeys";

export function useProductEdit({
  api,
}: {
  api: {
    productApi: {
      update: (arg0: number, arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      productId,
      patchedFields,
    }: {
      productId: number;
      patchedFields: { [k: string]: any };
    }) => {
      return (await api.productApi.update(productId, patchedFields))
        .data as ProductDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: productQueryKeys.all() });
    },
  });
  return mutation;
}
