import { FormMapper, useRecordForm } from "@gymflow/common";
import { AppointableDtoPricingModel } from "@gymflow/types";
import { Formik, useFormikContext } from "formik";
import noop from "lodash/noop";
import { useContext, useEffect } from "react";
import * as Yup from "yup";

import { FormikTextInput, Select, Switch } from "../../atoms";
import { WizardContext, WizardState } from "../../organisms/StepWizard";

export function AppointableWizardStep2() {
  const { wizardState } = useContext(WizardContext);
  const { initialValues, getValues } = useRecordForm({
    record: wizardState["pricing"] || null,
    fields: schema.getDefault(),
    mapper,
  });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={noop}
      validationSchema={schema}
    >
      <Step2Form getValues={getValues} />
    </Formik>
  );
}

function Step2Form({ getValues }: { getValues: (formValues: any) => any }) {
  const formikProps = useFormikContext();
  const values = formikProps.values as { [k: string]: any };
  const { setFieldValue, validateForm } = formikProps;
  const pricingOptions: { label: string; value: AppointableDtoPricingModel }[] =
    [
      { label: "Fixed Price", value: "FIXED" },
      { label: "Free", value: "FREE" },
    ];

  const { setWizardState, wizardState } = useContext(WizardContext);
  useEffect(() => {
    async function updateWizardState() {
      const errors = await validateForm(values);
      const isValid = Object.keys(errors).length === 0;
      setWizardState((prev: WizardState) => ({
        ...prev,
        pricing: getValues(values),
        isValid,
      }));
    }
    updateWizardState();
  }, [values]);

  useEffect(() => {
    if (wizardState.isWizardStepValid !== undefined) {
      formikProps.submitForm();
    }
  }, [wizardState.isWizardStepValid]);

  return (
    <div className="p-2">
      <div>
        <div>Pricing structure</div>
        <div className="mt-2">
          <Select
            options={pricingOptions}
            value={pricingOptions.find(
              (v) => v.value === values[PRICING_STRUCTURE],
            )}
            onChange={({ value }: { value: AppointableDtoPricingModel }) => {
              formikProps.setFieldValue(PRICING_STRUCTURE, value);
            }}
          />
        </div>
      </div>

      <div className="mt-4 grid grid-cols-3 gap-3">
        <div>
          <div>Price</div>
          <div className="mt-2">
            <FormikTextInput name={PRICE} />
          </div>
          <div className="mt-1 text-gray-600">
            Price is inclusive of sales tax.
          </div>
        </div>
        <div>
          <div>Credits</div>
          <div className="mt-2">
            <FormikTextInput name={CREDITS} />
          </div>

          <div className="mt-1 text-gray-600">
            Optional: We&apos;ll take payment in credits if there is an
            available balance or charge currency if not.
          </div>
        </div>
        <div>
          <div>Sales Tax Rate %</div>
          <div className="mt-2">
            <FormikTextInput name={TAX_RATE} />
          </div>
        </div>
      </div>

      <div className="mt-4 flex content-stretch">
        <div className="flex-auto">
          <div>Upfront payment required?</div>
          <div className="mt-1 text-gray-600">
            We&apos;ll collect payment from the user before accepting their
            booking,
          </div>
        </div>
        <div className="flex justify-end">
          <Switch
            value={values[IS_UPFRONT_PAYMENT_REQUIRED]}
            label="test"
            onChange={(value) => {
              setFieldValue(IS_UPFRONT_PAYMENT_REQUIRED, value);
            }}
          />
        </div>
      </div>

      <div className="mt-4 flex !hidden content-stretch">
        <div className="flex-auto">
          <div>Card on file required?</div>
          <div className="mt-1 text-gray-600">
            We&apos;ll make sure the user has an active card on file before
            accepting their booking.
          </div>
        </div>
        <div className="flex justify-end">
          <Switch
            value={values[IS_CARD_REQUIRED]}
            label="test"
            onChange={(value) => {
              setFieldValue(IS_CARD_REQUIRED, value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const PRICING_STRUCTURE = "pricing-model";
const PRICE = "price";
const CREDITS = "session-cost";
const TAX_RATE = "tax-rate";
const IS_UPFRONT_PAYMENT_REQUIRED = "payment-upfront";
const IS_CARD_REQUIRED = "card-upfront";

const schema = Yup.object().shape({
  [PRICING_STRUCTURE]: Yup.string().required().default("FIXED"),
  [PRICE]: Yup.number()
    .test(function (v) {
      if (this.parent[PRICING_STRUCTURE] !== "FREE") {
        if (v !== undefined) {
          return true;
        } else if (this.parent[CREDITS] === undefined) {
          return this.createError({
            message: "Price or Credits needs to be specified.",
            path: PRICE,
          });
        }
      }
      return true;
    })
    .min(0),
  [CREDITS]: Yup.number().min(0).nullable(),
  [TAX_RATE]: Yup.number()
    .test(function (v) {
      if (v === undefined) {
        if (
          this.parent[PRICING_STRUCTURE] === "FREE" ||
          this.parent[PRICE] === undefined ||
          this.parent[PRICE] === ""
        ) {
          return true;
        } else {
          return this.createError({
            message: "Tax rate is required if price is specified.",
            path: TAX_RATE,
          });
        }
      }
      return true;
    })
    .min(0)
    .max(100),
  [IS_UPFRONT_PAYMENT_REQUIRED]: Yup.boolean().required().default(false),
  [IS_CARD_REQUIRED]: Yup.boolean().required().default(false),
});

const mapper = new FormMapper();
