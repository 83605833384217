import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationRemovePromotionCodeFromSubscription({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ subscriptionId }: { subscriptionId: number }) =>
      api.memberApi.removePromotionCodeFromSubscription({
        subscriptionId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
}

