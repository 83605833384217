import range from "lodash/range";
import moment from "moment-timezone";

import { PaginatedSelect } from "./PaginatedSelect";

interface DateSelectProps {
  value: string;
  onChange: (newDate: string) => void;
  dateToStartFrom: string;
  dateFormat: string;
  pageSize?: number;
  pageLimit?: number;
  interval?: { type: "day" | "week" | "month" | "year"; value: number };
}

export function DateSelect({
  value,
  onChange,
  dateToStartFrom,
  dateFormat,
  pageSize = 6,
  pageLimit = 100,
  interval = { type: "day", value: 1 },
}: DateSelectProps) {
  const longDateFormat = "dddd, Do MMMM, YYYY";

  const dateValue = moment(value, dateFormat);
  const hasValidValue = dateValue.isValid();

  return (
    <PaginatedSelect
      value={
        hasValidValue
          ? {
              value: dateValue.format(dateFormat),
              label: dateValue.format(longDateFormat),
            }
          : null
      }
      onChange={(newDate) => {
        onChange(newDate.value);
      }}
      placeholder="Select date"
      loadOptions={async function (_, __, { page }) {
        const options = range(pageSize * page, pageSize * page + pageSize).map(
          (i) => {
            const newDateValue = moment(dateToStartFrom, dateFormat).add(
              interval.value * i,
              interval.type,
            );
            return {
              label: newDateValue.format(longDateFormat),
              value: newDateValue.format(dateFormat),
            };
          },
        );

        return {
          options,
          hasMore: page < pageLimit,
          additional: { page: page + 1 },
        };
      }}
      cacheUniqs={[interval, dateToStartFrom]}
    />
  );
}
