import { cn } from "@gymflow/helpers";
import { CardElement } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";

function StripeCard({ onChange, style, className = "" }) {
  const stripeWrapperStyle = {
    padding: "10px 18px",
    height: "35.15px",
  };

  return (
    <div
      className={cn(
        "stripe-card dark:bg-gray-950 dark:placeholder:text-darkGray-500 dark:text-darkGray-50 dark:ring-darkGray-700 flex flex-col items-center justify-center rounded-lg border-gray-300 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset",
        className,
      )}
      style={stripeWrapperStyle}
    >
      <div className="w-100" style={{ marginTop: "-2px" }}>
        <CardElement
          onChange={onChange}
          options={{ style: { ...stripeStyle, ...style } }}
        />
      </div>
    </div>
  );
}

const stripeStyle = {
  base: {
    "::placeholder": {
      color: "#adb5bd",
    },
    color: "#adb5bd",
  },
};

StripeCard.defaultProps = {
  style: {},
};

StripeCard.propTypes = {
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default StripeCard;
