import { useCallback, useEffect, useLayoutEffect, useState } from 'react';

export const STATE = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
  NOTHING: '',
};
// @deprecated - use useMutation instead
const useProgress = (options) => {
  const opts = {
    transitionTimeout: 2000,
    isLoading: null,
    ...options,
  };

  const [loadingState, setLoadingState] = useState(true);
  const [showSuccessState, setShowSuccessState] = useState(false);
  const [showErrorState, setShowErrorState] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  useLayoutEffect(() => {
    if (opts.isLoading) {
      loading();
    } else {
      notLoading();
    }
  }, [opts.isLoading]);

  const loading = useCallback(() => {
    setLoadingState(true);
  }, []);

  const notLoading = useCallback(() => {
    setLoadingState(false);
  }, []);

  const schedule = useCallback(
    (callback) => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = window.setTimeout(callback, opts.transitionTimeout);
      setTimeoutId(newTimeoutId);
    },
    [opts.transitionTimeout, timeoutId]
  );

  const showSuccess = useCallback(() => {
    setShowSuccessState(true);
    setShowErrorState(false);
    schedule(() => {
      setShowSuccessState(false);
    });
  }, [schedule]);

  const showError = useCallback(() => {
    setShowErrorState(true);
    setShowSuccessState(false);
    schedule(() => {
      setShowErrorState(false);
    });
  }, [schedule]);

  const isDisabled = useCallback(() => {
    return loadingState || showErrorState || showSuccessState;
  }, [loadingState, showErrorState, showSuccessState]);

  const getCurrentState = useCallback(() => {
    if (loadingState) {
      return STATE.LOADING;
    } else if (showSuccessState) {
      return STATE.SUCCESS;
    } else if (showErrorState) {
      return STATE.ERROR;
    }
    return STATE.NOTHING;
  }, [loadingState, showErrorState, showSuccessState]);

  const run = async (callback) => {
    loading();
    let result;
    try {
      result = await callback();
      showSuccess();
      notLoading();
    } catch (e) {
      showError();
      notLoading();
      throw e;
    }
    return result;
  };

  return {
    isLoading: loadingState,
    loading,
    notLoading,
    currentState: getCurrentState(),
    isDisabled: isDisabled(),
    showSuccess,
    showError,
    transitionTimeout: opts.transitionTimeout,
    run,
  };
};

export default useProgress;
