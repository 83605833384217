import { thunk, action, actionOn } from 'easy-peasy';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';

class InvoiceModelBuilder extends BaseModelBuilder {
  constructor(apiKey) {
    super(apiKey);
    this.generators.push(this.invoiceGenerator);
  }

  /**
   * @private
   */
  invoiceGenerator() {
    return {
      fetchList: thunk(
        async (actions, { userId, page = 0, limit = 10, sort, extraParams }, { injections, getState }) => {
          const { data } = await await getApi(injections, getState()).findInvoices(userId, {
            page,
            limit,
            sort,
            extraParams,
          });
          const payload = createPayloadFromFindResponse(data);
          actions.fetchedList({ ...payload, sort, userId });
          return payload;
        }
      ),
      fetchedList: action((state, { rows, pageCount, page, rowsLimit, userId, sort }) => {
        state.rows = rows;
        state.pageCount = pageCount;
        state.page = page;
        state.rowsLimit = rowsLimit;
        state.userId = userId;
        state.sort = sort;
      }),
      refreshList: thunk((actions, _, { getState }) => {
        const { page, rowsLimit, userId, sort } = getState();
        return actions.fetchList({ userId, page, limit: rowsLimit, sort });
      }),
      onFetchListStart: actionOn(
        (actions) => actions.fetchList.startType,
        (state) => {
          state.loadingList = true;
        }
      ),

      onFetchListDone: actionOn(
        (actions) => [actions.fetchList.successType, actions.fetchList.failType],
        (state) => {
          state.loadingList = false;
        }
      ),
    };
  }
}

export default InvoiceModelBuilder;
