import { ServiceType } from "@gymflow/common";
import memoizeOne from "memoize-one";
import qs from "qs";
import { useEffect, useMemo } from "react";
import { useHistory } from "react-router";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

import BuyMembership from "../components/SalesWizard/BuyMembership";
import BuyRoute from "../components/SalesWizard/BuyRoute";
import BuySessionPack from "../components/SalesWizard/BuySessionPack";
import { hasActiveMembershipSubscription } from "../helpers/userMember";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";

function Buy() {
  const { createClubLink } = usePortalRoutes();
  const buyRoute = Object.keys(BuyRoute).reduce((acc, route) => {
    acc[route] = createClubLink(
      RouteLayout.Member,
      RouteFeature.CustomerSale,
      BuyRoute[route],
    );
    return acc;
  }, {});
  const { api, CustomerOccurrenceStore, UserMemberStore } = useGymflowModels();
  const findByEmailBackend = memoizeOne(
    api.public.userMemberApi.emailExists.bind(api.public.userMemberApi),
  );

  const findByEmail = useMemo(
    () => async (email) => {
      const response = await findByEmailBackend(email);
      if (response.data) {
        return "Email already exists.";
      }
      return false;
    },
    [],
  );
  const {
    fetchPaymentMethods,
    fetchById: fetchUserDetails,
    clearEditingRecord,
  } = UserMemberStore.useStoreActions((actions) => actions);
  /**
   * @typedef {import("../../../../libs/types/src").UserMemberBean } UserMemberBean
   * @type UserMemberBean
   */
  const user = UserMemberStore.useStoreState((state) => state.editing);

  useEffect(() => {
    fetchUserDetails();
    return () => clearEditingRecord();
  }, [fetchUserDetails, clearEditingRecord]);

  const history = useHistory();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });

  const { fetchById: fetchEventById, clearEditingRecord: clearEvent } =
    CustomerOccurrenceStore.useStoreActions((actions) => actions);
  const event = CustomerOccurrenceStore.useStoreState((state) => state.editing);

  useEffect(() => {
    if (search.eventId) {
      fetchEventById(search.eventId);
    }
    return () => {
      clearEvent();
    };
  }, [search.eventId]);

  if (!user) {
    return null;
  }

  const hasRunningMembership = hasActiveMembershipSubscription(
    user.subscriptions,
  );

  const renderHasMembershipCard = () => {
    return (
      <div className="content h-full overflow-y-auto p-8">
        <Card>
          <CardBody>
            <div>You already have an active membership.</div>
            <div>
              Buy <Link to={buyRoute.SessionsPack}>Credit Packs</Link>.
            </div>
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <Switch>
      <Route path={buyRoute.RecurringMembership}>
        {hasRunningMembership ? (
          renderHasMembershipCard()
        ) : (
          <BuyMembership
            fetchPaymentMethods={fetchPaymentMethods}
            userMemberId={user.id}
            cardHoldersName={`${user.firstName} ${user.lastName}`}
            membershipType={ServiceType.Recurring}
            subscriptions={user?.subscriptions}
            isMiniUser={!user.isFullUser}
            findByEmail={findByEmail}
            event={event}
            userDetails={{
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              postCode: user?.postCode,
              gender: user?.gender,
              addressLine1: user?.addressLine1,
              addressLine2: user?.addressLine2,
              city: user?.city,
              mobileNumber: user?.mobileNumber,
              dateBirth: user?.dateBirth,
              emergencyContactName: user?.emergencyContactName,
              emergencyContact: user?.emergencyContact,
              emailCommunication: user?.emailCommunication,
              smsCommunication: user?.smsCommunication,
              lead: user?.lead,
              isFullUser: user?.isFullUser,
              profileType: user?.profileType,
              isClubWaiverAccepted: user?.isClubWaiverAccepted,
            }}
          />
        )}
      </Route>
      <Route path={buyRoute.PrepaidMembership}>
        {hasRunningMembership ? (
          renderHasMembershipCard()
        ) : (
          <BuyMembership
            fetchPaymentMethods={fetchPaymentMethods}
            userMemberId={user.id}
            cardHoldersName={`${user.firstName} ${user.lastName}`}
            membershipType={ServiceType.Prepaid}
            subscriptions={user?.subscriptions}
            isMiniUser={!user.isFullUser}
            findByEmail={findByEmail}
            event={event}
            userDetails={{
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              postCode: user?.postCode,
              gender: user?.gender,
              addressLine1: user?.addressLine1,
              addressLine2: user?.addressLine2,
              city: user?.city,
              mobileNumber: user?.mobileNumber,
              dateBirth: user?.dateBirth,
              emergencyContactName: user?.emergencyContactName,
              emergencyContact: user?.emergencyContact,
              emailCommunication: user?.emailCommunication,
              smsCommunication: user?.smsCommunication,
              lead: user?.lead,
              isFullUser: user?.isFullUser,
              profileType: user?.profileType,
              isClubWaiverAccepted: user?.isClubWaiverAccepted,
            }}
          />
        )}
      </Route>
      <Route path={buyRoute.SessionsPack}>
        <BuySessionPack
          fetchPaymentMethods={fetchPaymentMethods}
          userMemberId={user.id}
          cardHoldersName={`${user.firstName} ${user.lastName}`}
          isMiniUser={!user.isFullUser}
          findByEmail={findByEmail}
          event={event}
          userDetails={{
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            postCode: user?.postCode,
            gender: user?.gender,
            addressLine1: user?.addressLine1,
            addressLine2: user?.addressLine2,
            city: user?.city,
            mobileNumber: user?.mobileNumber,
            dateBirth: user?.dateBirth,
            emergencyContactName: user?.emergencyContactName,
            emergencyContact: user?.emergencyContact,
            emailCommunication: user?.emailCommunication,
            smsCommunication: user?.smsCommunication,
            lead: user?.lead,
            isFullUser: user?.isFullUser,
            profileType: user?.profileType,
            isClubWaiverAccepted: user?.isClubWaiverAccepted,
          }}
        />
      </Route>
      <Route path={`${buyRoute.LegacyMembership}/:id`}>
        {({ match: innerMatch }) => {
          const membershipId = innerMatch.params.id;
          api.public.serviceApi
            .findById(membershipId)
            .then(({ data: { type } }) => {
              if (type === ServiceType.Recurring) {
                history.push({
                  pathname: buyRoute.RecurringMembership,
                  search: `?membershipId=${membershipId}`,
                });
              } else if (type === ServiceType.Prepaid) {
                history.push({
                  pathname: buyRoute.PrepaidMembership,
                  search: `?membershipId=${membershipId}`,
                });
              }
            });

          return null;
        }}
      </Route>
      <Route path={`${buyRoute.LegacyMembership}`}>
        {() => {
          history.push({
            pathname: buyRoute.RecurringMembership,
          });

          return null;
        }}
      </Route>

      <Route path={`${buyRoute.LegacyCreditPack}/:id`}>
        {({ match: innerMatch }) => {
          const sessionPackId = innerMatch.params.id;
          history.push({
            pathname: buyRoute.SessionsPack,
            search: `?sessionPackId=${sessionPackId}`,
          });

          return null;
        }}
      </Route>

      <Route path={buyRoute.LegacyCreditPack}>
        {() => {
          history.push({
            pathname: buyRoute.SessionsPack,
          });

          return null;
        }}
      </Route>
      <Route>
        <Redirect
          to={
            hasRunningMembership
              ? buyRoute.SessionsPack
              : buyRoute.RecurringMembership
          }
        />
      </Route>
    </Switch>
  );
}

function BuyWithProviders() {
  const { UserMemberStore, CustomerOccurrenceStore } = useGymflowModels();
  return (
    <UserMemberStore.Provider>
      <CustomerOccurrenceStore.Provider>
        <Buy />
      </CustomerOccurrenceStore.Provider>
    </UserMemberStore.Provider>
  );
}

export default BuyWithProviders;
