export default class Environment {
  constructor(settings, schema, prefix = 'NX_') {
    const parsed = Object.keys(settings).reduce((acc, key) => {
      if (key.startsWith(prefix)) {
        const newKey = key.substring(prefix.length);
        acc[newKey] = settings[key];
      } else {
        acc[key] = settings[key];
      }

      return acc;
    }, {});
    Environment.validate(parsed, schema);
    /** @private */
    this.settings = schema.cast(parsed);
  }

  get(key) {
    const value = this.settings[key];
    if (typeof value === 'undefined') {
      throw new Error('Settings key does not exist.');
    }
    return value;
  }

  /**
   * @private
   */
  static validate(settings, schema) {
    schema.validateSync(settings);
  }
}
