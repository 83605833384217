import { action, actionOn } from 'easy-peasy';
import { ModelBuilder } from '@gymflow/common';

export const TYPE = {
  UNREAD: 'unread',
  READ: 'read',
}

class NotificationModelBuilder extends ModelBuilder {
  constructor() {
    super();
    this.generators.push(this.notificationGenerator);
  }

  notificationGenerator() {
    return {
      unreadMessages: [],
      readMessages: [],
      hasUnreadMsg: false,
      hasMoreUnreadMsg: true,
      hasMoreReadMsg: true,
      add: action((state, message) => {
        const messages = message.read ? state.readMessages : state.unreadMessages;
        const idx = messages.findIndex(({ uuid }) => message.uuid === uuid)
        if (idx < 0) {
          messages.unshift(message);
          messages.sort((a, b) => {
            const [yearA, monthA, dayA, hourA, minuteA] = a.timestamp;
            const [yearB, monthB, dayB, hourB, minuteB] = b.timestamp;
            const dateA = new Date(yearA, monthA - 1, dayA, hourA, minuteA);
            const dateB = new Date(yearB, monthB - 1, dayB, hourB, minuteB);

            return dateB - dateA;
          });
        } else {
          messages[idx] = message;
        }
      }),
      hide: action((state, { uuid, type = TYPE.UNREAD }) => {
        const toEdit = (type === TYPE.UNREAD ? state.unreadMessages : state.readMessages).find(n => n.uuid === uuid);
        if (toEdit) {
          toEdit.visible = false;
        }
      }),
      remove: action((state, { uuid, type = TYPE.UNREAD }) => {
        state.unreadMessages = (type === TYPE.UNREAD ? state.unreadMessages : state.readMessages)
          .filter((n) => n.uuid !== uuid);
      }),
      onUnreadMessagesChange: actionOn(
        (actions) => [actions.add, actions.remove],
        (state) => {
          state.hasUnreadMsg = state.unreadMessages.length > 0;
          state.hasMoreUnreadMsg = !state.unreadMessages.some(msg => msg.paginationDto?.lastPage);
        }
      ),
      onReadMessagesChange: actionOn(
        (actions) => [actions.add, actions.remove],
        (state) => {
          state.hasMoreReadMsg = !state.readMessages.some(msg => msg.paginationDto?.lastPage);
        }
      ),
    };
  }
}

export default NotificationModelBuilder;
