import { useMutation, useQueryClient } from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useMutationKisiGenerateSignatureKey({
  api,
}: {
  api: {
    kisiApi: {
      generateSignatureKey(
        clubId: number,
      ): Promise<{ data: { secretKey: string } }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({ clubId }: { clubId: number }) => {
      const result = await api.kisiApi.generateSignatureKey(clubId);
      return result.data;
    },
    onSuccess: (_, { clubId }) => {
      queryClient.invalidateQueries({
        queryKey: kisiQueryKeys.signatureKeyExists(clubId),
      });
    },
  });
  return mutation;
}
