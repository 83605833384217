import { ClubBean, KisiGroup } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useQueryKisiGroupList(
  {
    api,
    clubId,
  }: {
    api: {
      kisiApi: {
        fetchGroups: (clubId: number) => Promise<any>;
      };
      clubApi: { findById: (clubId: number) => Promise<{ data: ClubBean }> };
    };
    clubId: number;
  },
  queryOpts?: UseQueryOptions<KisiGroup[]>,
) {
  const result = useQuery({
    queryKey: kisiQueryKeys.groups(),
    queryFn: async () => {
      const result = await api.kisiApi.fetchGroups(clubId);
      return result.data as KisiGroup[];
    },
    initialData: [],
    ...queryOpts,
  });
  return result;
}
