export function AlarmClockIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={pathClassName}
          d="M4.16675 2.5L1.66675 5M18.3334 5L15.8334 2.5M5.00008 15.8333L3.33341 17.5M15.0001 15.8333L16.6667 17.5M10.0001 7.5V10.8333L11.6667 12.5M10.0001 17.5C11.7682 17.5 13.4639 16.7976 14.7141 15.5474C15.9644 14.2971 16.6667 12.6014 16.6667 10.8333C16.6667 9.06522 15.9644 7.36953 14.7141 6.11929C13.4639 4.86905 11.7682 4.16667 10.0001 4.16667C8.23197 4.16667 6.53628 4.86905 5.28604 6.11929C4.03579 7.36953 3.33341 9.06522 3.33341 10.8333C3.33341 12.6014 4.03579 14.2971 5.28604 15.5474C6.53628 16.7976 8.23197 17.5 10.0001 17.5Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
