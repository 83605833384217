import { useStoreState } from "easy-peasy";
import { useEffect } from "react";

import { settingsStore } from "../store";

const useGoogleTagManager = () => {
  const { googleTagContainerId } = useStoreState(settingsStore);

  useEffect(() => {
    if (!googleTagContainerId) {
      return;
    }
    const scriptTag = document.createElement("script");
    scriptTag.textContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${googleTagContainerId}');`;
    document.head.append(scriptTag);
  }, [googleTagContainerId]);
};

export default useGoogleTagManager;
