import endpoints from "./endpoints";
import { canFind, canFindById } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicEventEndpoints = {
  calendarEventOccurrences: "hosted/calendar/event-occurrences",
};

const publicEventApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicEvent}`,
    http: createPublicAxiosInstance({
      apiUrl,
      paramsSerializer: {
        indexes: null,
      },
    }),
  };

  return Object.assign(state, canFind(state), canFindById(state), {
    calendarEventOccurrences({ filters }) {
      return state.http.get(
        `${clubPrefix}${publicEventEndpoints.calendarEventOccurrences}`,
        { params: filters },
      );
    },
  });
};

export default publicEventApi;
