import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { membershipAsMemberQueryKeys } from "./membershipAsMemberQueryKeys";

export function useQueryMembershipSummaryAsMember({
  api,
  opts,
  enabled,
}: {
  api: ApiType;
  opts?: Parameters<ApiType["customerMembershipApi"]["summary"]>[0];
  enabled?: boolean;
}) {
  const result = useQuery({
    queryKey: membershipAsMemberQueryKeys.summary(opts),
    queryFn: async () => {
      return (await api.customerMembershipApi.summary(opts!)).data;
    },
    enabled: !!opts && enabled,
  });
  return result;
}
