import { QueryKey } from "@tanstack/react-query";

export const clubQueryKeys = {
  all: () => ["club"] as QueryKey,
  single: (clubId: number) => [...clubQueryKeys.all(), clubId] as QueryKey,
  details: (clubId: number) => [...clubQueryKeys.all(), "details", clubId] as QueryKey,
  properties: (clubId: number) =>
    [...clubQueryKeys.all(), clubId, "properties"] as QueryKey,
  connectStatus: (clubId: number) =>
    [...clubQueryKeys.all(), clubId, "connect"] as QueryKey,
  featureFlags: (clubId: number) =>
    [...clubQueryKeys.all(), clubId, "featureFlags"] as QueryKey,
};
