import { ActivityCategoryDTO, ActivityCategoryPostDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { activityQueryKeys } from "./activityQueryKeys";

export function useActivityCategoryCreate({
  api,
}: {
  api: {
    activityApi: {
      createCategory: (
        newCategory: ActivityCategoryPostDTO,
      ) => Promise<{ data: ActivityCategoryDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newActivity: ActivityCategoryPostDTO) => {
      const response = await api.activityApi.createCategory(newActivity);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: activityQueryKeys.categories(),
      });
    },
  });

  return mutation;
}
