import omit from "lodash/omit";

import { cn } from "../../helpers/cn";
import { useProgressButton } from "../../hooks/useProgressButton";
import { Spinner } from "./Spinner";

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "type"> {
  showSpinner?: boolean;
}

/**
 * @deprecated use LinkButton if you want to use a Button that looks like a
 * Button, but if you just want a clickable that links somewhere use <Button
 * intent="link" />
 */
export function TextButton({
  children,
  className,
  showSpinner,
  ...props
}: ButtonProps) {
  const { disabled, onClick, inProgress } = useProgressButton({
    onClick: props.onClick,
    disabled: props.disabled,
  });
  const buttonProps = omit(props, ["disabled", "onClick"]);

  return (
    <button
      type="button"
      className={cn(
        "text-primary-600 justify-center rounded-md bg-transparent px-3 py-2 text-sm font-semibold focus:border-none focus:outline-none",
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <Spinner
        className={cn(
          { hidden: !inProgress && !showSpinner },
          "text-primary-600 absolute",
        )}
      />
      <div className={cn({ invisible: inProgress || showSpinner })}>
        {children}
      </div>
    </button>
  );
}
