import { useMutation, useQueryClient } from "@tanstack/react-query";
import omit from "lodash/omit";

import { membershipQueryKeys } from "./membershipQueryKeys";

// TODO: cleanup MEMBERSHIP_FIXED_DATES_FIX - delete this file
export function useMembershipEdit({
  api,
}: {
  api: {
    serviceApi: {
      update: (
        id: number,
        arg1: { patchedFields: unknown; termsAndConditionsBlob: unknown },
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      membershipId,
      patchedFields,
    }: {
      membershipId: number;
      patchedFields: { [k: string]: any };
    }) => {
      return api.serviceApi.update(membershipId, {
        patchedFields: omit(patchedFields, ["termsConditions", "serviceType"]),
        termsAndConditionsBlob: patchedFields["termsConditions"],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });
  return mutation;
}
