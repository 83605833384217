import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

import {
  Button,
  MembershipSelect,
  PaginatedSelect,
  SlideSideBar,
} from "../components/atoms";

export interface AccessFilters {
  memberships?: { id: number; name: string }[];
  onSite?: boolean;
  guest?: boolean;
}

export interface AccessFiltersSideBarProps {
  isVisible: boolean;
  onClose: () => void;
  value: AccessFilters;
  onChange: (newFilters: AccessFilters) => void;
}

export const AccessFilterSidebar: React.FC<AccessFiltersSideBarProps> = ({
  value,
  onChange,
  isVisible,
  onClose,
}) => {
  const [newFilters, setNewFilters] = useState<AccessFilters>(value);

  useEffect(() => {
    setNewFilters(value);
  }, [value]);

  return (
    <SlideSideBar isOpen={isVisible} hide={onClose}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex-1 overflow-y-auto">
          <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
            <div className="mb-1 flex flex-row items-center justify-between">
              <div className="text-xl font-semibold text-gray-900">Filters</div>
              <FontAwesomeIcon
                onClick={() => {
                  onClose();
                }}
                className="cursor-pointer text-xl text-gray-600"
                icon={faClose}
              />
            </div>
            <div className="text-sm font-medium text-gray-600">
              Apply filters to table data
            </div>
          </div>

          <div className="flex flex-col px-6">
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              Include Memberships
            </div>
            <div>
              <MembershipSelect
                placeholder={
                  newFilters?.memberships?.length ? "Memberships" : "All"
                }
                isMulti
                isClearable
                onChange={(newMembershipOption) => {
                  if (newMembershipOption) {
                    const newValue = newMembershipOption.map((e) => ({
                      name: e.label,
                      id: e.value,
                    }));
                    setNewFilters((previous) => ({
                      ...previous,
                      memberships: newValue,
                    }));
                  } else {
                    setNewFilters((previous) => ({
                      ...previous,
                      memberships: undefined,
                    }));
                  }
                }}
                value={
                  newFilters.memberships
                    ? newFilters.memberships.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                    : null
                }
              />
            </div>
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              Guest
            </div>
            <div>
              <PaginatedSelect
                loadOptions={async () => {
                  return {
                    options: [
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ],
                  };
                }}
                value={[
                  {
                    label: "Yes",
                    value: true,
                  },
                  {
                    label: "No",
                    value: false,
                  },
                ].find((e) => e.value === newFilters.guest)}
                isClearable={true}
                placeholder="All"
                cacheUniqs={[`${newFilters.guest}`]}
                onChange={(newValue: { value: boolean }) => {
                  setNewFilters((previous) => ({
                    ...previous,
                    guest: newValue?.value,
                  }));
                }}
              />
            </div>
            <div className="mb-2 mt-6 flex text-sm font-medium text-gray-700">
              On site
            </div>
            <div>
              <PaginatedSelect
                loadOptions={async () => {
                  return {
                    options: [
                      {
                        label: "Yes",
                        value: true,
                      },
                      {
                        label: "No",
                        value: false,
                      },
                    ],
                  };
                }}
                value={[
                  {
                    label: "Yes",
                    value: true,
                  },
                  {
                    label: "No",
                    value: false,
                  },
                ].find((e) => e.value === newFilters.onSite)}
                isClearable={true}
                placeholder="All"
                cacheUniqs={[`${newFilters.onSite}`]}
                onChange={(newValue: { value: boolean }) => {
                  setNewFilters((previous) => ({
                    ...previous,
                    onSite: newValue?.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button onClick={() => onClose()} className="mr-2 mt-2 w-full">
            Cancel
          </Button>
          <Button
            intent="primary"
            className="mt-2 w-full"
            onClick={() => {
              if (newFilters) {
                onChange(newFilters);
              }
              onClose();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
};
