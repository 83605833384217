import { useClubFeatureFlags } from "@gymflow/api";
import React, { createContext, useMemo, useState } from "react";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import {
  getReportWidgetsProps,
  ReportWidgetComponent,
  ReportWidgetProps,
} from "../Reports/Widgets";

export interface DynamicReportWidgetCardProps {
  initialCard: string[];
  onCardChange: (newCard: string[]) => void;
  parentRoute: string;
}

export const DynamicReportWidgetContext = createContext<
  | {
      onComponentSelected: (component: string[]) => void;
    }
  | undefined
>(null as any);

export const DynamicReportWidgetCard: React.FC<
  DynamicReportWidgetCardProps
> = ({ initialCard, onCardChange, parentRoute }) => {
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: featureFlags } = useClubFeatureFlags({ api, clubId });
  const reportWidgets = useMemo(() => {
    return getReportWidgetsProps(featureFlags);
  }, [featureFlags]);

  const [currentCardPath, setCurrentCardPath] = useState(initialCard);
  const CurrentCard = currentCardPath.reduce((acc, next) => {
    return acc[next].content as ReportWidgetProps;
  }, reportWidgets) as unknown as ReportWidgetComponent;
  return (
    <DynamicReportWidgetContext.Provider
      value={{
        onComponentSelected: (component) => {
          onCardChange(component);
          setCurrentCardPath(component);
        },
      }}
    >
      <CurrentCard parentRoute={parentRoute} />
    </DynamicReportWidgetContext.Provider>
  );
};
