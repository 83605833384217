import {
  InvoiceStatus,
  PaymentConfirmationStatus,
  ProductStatus,
  useParseErrors,
  usePaymentAuthorizationAlert,
  UserProfileType,
} from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import useProducts from "../../hooks/useProducts";
import { useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import SellProductsAccordion from "./SellProductsAccordion";

const sort = { field: "price,name,id", asc: true };
function SellProducts() {
  const { createClubLink } = usePortalRoutes();
  const { api, settingsStore, MemberStore } = useGymflowModels();
  const history = useHistory();
  const { toast } = useContext(ToastContext);
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const clubId = settings.clubId;
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const { fetchPaymentMethods } = MemberStore.useStoreActions(
    (actions) => actions,
  );
  const { rows: products, fetchAvailableList: fetchProducts } = useProducts();
  const { show: showPaymentConfirmationAlert } = usePaymentAuthorizationAlert();
  const parseError = useParseErrors();

  useEffect(() => {
    fetchProducts({
      sort,
      extraParams: {
        unpaged: true,
        status: ProductStatus.Available,
      },
    });
  }, []);

  return (
    <div className="content h-full overflow-y-auto p-8">
      <SellProductsAccordion
        products={products
          .sort((a, b) => a.price - b.price)
          .map(({ id, name, price, stockQuantity, stockType }) => ({
            id,
            name,
            price,
            stockOnHand: stockQuantity,
            stockType,
          }))}
        searchUsers={api.memberApi.globalSearch}
        currency={currency}
        fetchPaymentMethods={({ userMemberId }) =>
          fetchPaymentMethods({ userMemberId, clubId })
        }
        dateFormat={dateFormat}
        summary={api.productApi.summary}
        onUserSubmit={async ({ user }) => {
          if (user.profileType === UserProfileType.User) {
            return user;
          }

          const { data } = await api.memberApi.createWithMinimumDetails({
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          });

          return {
            email: user.email,
            ...data.userMember,
          };
        }}
        purchase={async (purchaseValues) => {
          let result;
          try {
            result = await api.productApi.order(
              createOrderParams({
                userMemberId: purchaseValues.id,
                paymentMethodId: purchaseValues.paymentMethod,
                products: purchaseValues.cart,
                // skipPayment,
                promotionCode: purchaseValues.promoCode,
              }),
            );
          } catch (e) {
            parseError(e.response);
            return;
          }

          if (
            result.data.invoice.status === InvoiceStatus.PaymentAuthorization
          ) {
            const paymentConfirmationResult =
              await showPaymentConfirmationAlert({
                paymentIntentIdForAuthorization:
                  result.data.invoice.paymentIntentIdForAuthorization,
                confirmPayment:
                  api.strongCustomerAuthorizationApi.confirmPayment,
                messageText: (
                  <>
                    The user must authorize this payment before it will be
                    processed.
                    <br />
                    Please ask the user to authorize the payment by clicking the
                    link sent to their email.
                  </>
                ),
              });
            if (
              paymentConfirmationResult.status ===
              PaymentConfirmationStatus.Success
            ) {
              toast({ message: "Purchase successful." });
            } else if (
              paymentConfirmationResult.status ===
              PaymentConfirmationStatus.Waiting
            ) {
              toast({
                message: "Awaiting payment, check later.",
                intent: "warning",
              });
            } else if (
              paymentConfirmationResult.status ===
              PaymentConfirmationStatus.Failed
            ) {
              toast({ message: "Payment Failed.", intent: "error" });
            }
          } else {
            toast({ message: "Purchase successful." });
          }
          history.push(createClubLink(RouteLayout.Staff, RouteFeature.Sales));
        }}
      />
    </div>
  );
}

const createOrderParams = ({
  userMemberId,
  paymentMethodId,
  products,
  skipPayment,
  promotionCode,
  clubId,
}) => {
  const orderParams = {
    userMemberId,
    products: products.map((product) => ({
      productId: product.id,
      quantity: product.quantity,
    })),
    clubId,
    promotionCode,
  };

  if (skipPayment) {
    return orderParams;
  }

  return {
    ...orderParams,
    paymentMethodId,
  };
};

function SellProductsWithProviders(props) {
  const { MemberStore } = useGymflowModels();
  return (
    <MemberStore.Provider>
      <SellProducts {...props} />
    </MemberStore.Provider>
  );
}

export default SellProductsWithProviders;
