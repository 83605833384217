import { useMemo } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { FormikInput, formikHelpers } from '@gymflow/common';
import { currencies } from '@gymflow/helpers';
import Select from 'react-select';

import {
  DISCOUNT_KIND,
  UPFRONT_DISCOUNT,
  BOTH_DISCOUNT,
  UPFRONT_DISCOUNT_AMOUNT,
  UPFRONT_DISCOUNT_PRORATA,
  UPFRONT_DISCOUNT_TYPE,
  RECURRING_DISCOUNT,
  RECURRING_DISCOUNT_TYPE,
  RECURRING_DISCOUNT_AMOUNT,
  RECURRING_DISCOUNT_DURATION,
  IS_DURATION_MULTI_CYCLES,
  DISCOUNT_TYPE_MAP,
} from './PromotionSchema';

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const typeOptions = [
  { label: 'Upfront', value: UPFRONT_DISCOUNT },
  { label: 'Recurring', value: RECURRING_DISCOUNT },
  { label: 'Both', value: BOTH_DISCOUNT },
];

const durationCyclesOptions = [
  { label: 'Multiple cycles', value: true },
  { label: 'Forever', value: false },
];

function Step3({ formikProps, currency, lockSomeFields }) {
  const { values, setFieldValue } = formikProps;
  const { errorClass } = formikHelpers(formikProps);

  const discountTypeOptions = useMemo(() => {
    return [
      { label: '%', value: DISCOUNT_TYPE_MAP.PERCENTAGE },
      { label: currencies[currency].symbol, value: DISCOUNT_TYPE_MAP.CURRENCY },
    ];
  }, [currency]);

  return (
    <div className="w-100 text-center">
      <b className="h2">What kind of discount would you like to apply?</b>
      <div className="text-muted mt-3">
        <p className="lh-130">
          Once off discounts are applied at checkout to the initial purchase. Ongoing discounts are applied on the first
          recurring invoice and any subsequent ones (if specified)
        </p>
      </div>
      <Row className="mt-4 px-3 text-left">
        <Col>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            options={typeOptions}
            onChange={({ value }) => {
              setFieldValue(DISCOUNT_KIND, value);
              if (DISCOUNT_KIND === UPFRONT_DISCOUNT) {
                setFieldValue(IS_DURATION_MULTI_CYCLES, false);
              } else {
                setFieldValue(IS_DURATION_MULTI_CYCLES, true);
              }
            }}
            value={typeOptions.find((op) => op.value === values[DISCOUNT_KIND])}
            isDisabled={lockSomeFields}
          />
        </Col>
      </Row>
      {[BOTH_DISCOUNT, UPFRONT_DISCOUNT].includes(values[DISCOUNT_KIND]) && (
        <Row className="mt-2 px-3 text-left">
          <Col md={5}>
            <Row className="text-uppercase text-left">
              <Col>
                <Label tag="h3">Once off</Label>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormGroup className={errorClass(UPFRONT_DISCOUNT_AMOUNT)}>
                  <FormikInput
                    autoComplete="off"
                    maxLength="8"
                    name={UPFRONT_DISCOUNT_AMOUNT}
                    type="number"
                    formikProps={formikProps}
                    readOnly={lockSomeFields}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  options={discountTypeOptions}
                  onChange={({ value }) => {
                    setFieldValue(UPFRONT_DISCOUNT_TYPE, value);
                  }}
                  value={discountTypeOptions.find((op) => op.value === values[UPFRONT_DISCOUNT_TYPE])}
                  isDisabled={lockSomeFields}
                />
              </Col>
            </Row>
          </Col>
          <Col md={2}>
            <Row className="d-sm-none d-md-block" style={{ marginTop: 32 }}>
              <Col className="text-center">and</Col>
            </Row>
          </Col>
          <Col md={5}>
            <Row className="text-uppercase text-left">
              <Col>
                <Label tag="h3">Waive Pro-rata?</Label>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  options={yesNoOptions}
                  onChange={({ value }) => {
                    setFieldValue(UPFRONT_DISCOUNT_PRORATA, value);
                  }}
                  value={yesNoOptions.find((op) => op.value === values[UPFRONT_DISCOUNT_PRORATA])}
                  isDisabled={lockSomeFields}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {[BOTH_DISCOUNT, RECURRING_DISCOUNT].includes(values[DISCOUNT_KIND]) && (
        <Row className="mt-2 px-3 text-left">
          <Col md={5}>
            <Row className="text-uppercase text-left">
              <Col>
                <Label tag="h3">OnGoing</Label>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <FormGroup className={errorClass(RECURRING_DISCOUNT_AMOUNT)}>
                  <FormikInput
                    autoComplete="off"
                    maxLength="8"
                    name={RECURRING_DISCOUNT_AMOUNT}
                    type="number"
                    formikProps={formikProps}
                    readOnly={lockSomeFields}
                  />
                </FormGroup>
              </Col>
              <Col>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  options={discountTypeOptions}
                  onChange={({ value }) => {
                    setFieldValue(RECURRING_DISCOUNT_TYPE, value);
                  }}
                  value={discountTypeOptions.find((op) => op.value === values[RECURRING_DISCOUNT_TYPE])}
                  isDisabled={lockSomeFields}
                />
              </Col>
            </Row>
          </Col>
          <Col md={7}>
            <Row className="text-uppercase text-left">
              <Col>
                <Label tag="h3">Duration</Label>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={8}>
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  options={durationCyclesOptions}
                  onChange={({ value }) => {
                    setFieldValue(IS_DURATION_MULTI_CYCLES, value);
                  }}
                  value={durationCyclesOptions.find((op) => op.value === values[IS_DURATION_MULTI_CYCLES])}
                  isDisabled={lockSomeFields}
                />
              </Col>
              {values[IS_DURATION_MULTI_CYCLES] && (
                <Col md={4}>
                  <FormGroup className={errorClass(RECURRING_DISCOUNT_DURATION)}>
                    <FormikInput
                      placeholder="cycles"
                      autoComplete="off"
                      maxLength="8"
                      name={RECURRING_DISCOUNT_DURATION}
                      type="number"
                      formikProps={formikProps}
                      readOnly={lockSomeFields}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Step3;
