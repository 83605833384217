import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";

import { TaskFilterFormValues } from "./types";

export const taskFilterFormSchema =
  toFormikValidationSchema<TaskFilterFormValues>(
    z.object({
      dueDate: z.date().optional(),
      // it's not necessary to check array (we don't have "edit" operation with it), ideally this would be UserStaffBean
      taskOwners: z.array(z.any()).optional(),
    }),
  );

export const INITIAL_VALUES_TASK_FILTER_FORM: TaskFilterFormValues = {
  dueDate: undefined,
  taskOwners: [],
};
