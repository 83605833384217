import { useCallback, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import ReactTable from 'react-table';
import { CardBodyWithSpinner } from '@gymflow/common';
import { useTaskList } from '@gymflow/api';

import { useTaskColumnDefs } from '../../hooks/useTaskColumnDefs';
import useGymflowModels from '../../store';

const LIMIT = 10000;
export function UncompleteTasksTable({ filters, setEditingTaskId }) {
  const { api, settingsStore } = useGymflowModels();
  const [sorted, setSorted] = useState([{ id: 'deadlineDate,deadlineTime', desc: false }]);
  const { timezone } = useStoreState(settingsStore);

  const createOpts = useCallback(() => {
    const eventOptions = { complete: filters.complete };
    if (filters.dateTo) {
      eventOptions.dateTo = filters?.dateTo;
    }
    if (filters.dateFrom) {
      eventOptions.dateFrom = filters?.dateFrom;
    }
    if (filters['taskOwners.id']) {
      eventOptions['taskOwners.id'] = filters['taskOwners.id'];
    }

    return {
      extraParams: filters,
      page: 0,
      limit: LIMIT,
      sort: { field: sorted[0].id, desc: sorted[0]?.desc },
    };
  }, [filters, sorted]);

  const {
    data: { content: tasks },
    isFetching,
  } = useTaskList({ api, opts: createOpts(), tz: timezone }, { refetchOnWindowFocus: true });

  const columnDefs = useTaskColumnDefs({ setEditingTaskId });

  return (
    <ReactTable
      className="-highlight w-full"
      columns={columnDefs}
      data={tasks}
      minRows={0}
      onSortedChange={setSorted}
      defaultSorted={[
        {
          id: 'deadlineDate,deadlineTime',
          desc: false,
        },
      ]}
      getTrGroupProps={() => ({
        style: {
          maxHeight: '60px',
        },
      })}
      getTbodyProps={() => ({
        style: {
          paddingBottom: '10px',
        },
      })}
    />
  );
}

export default UncompleteTasksTable;
