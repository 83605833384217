import * as Yup from "yup";
import Reference from "yup/lib/Reference";
import { AnyObject } from "yup/lib/types";

class YupBuilder<T extends Yup.AnySchema = Yup.AnySchema> {
  protected yupObject: T;
  constructor(yupObjectInstance: T) {
    this.yupObject = yupObjectInstance;
  }

  public isRequiredIf(
    otherFieldReference: Reference,
    errorMessage: string,
    present = true,
  ) {
    this.yupObject = this.yupObject.test(function requiredIf(
      value: any,
      context: Yup.TestContext<AnyObject>,
    ) {
      if (context.resolve(otherFieldReference) === present) {
        if (value !== "" && value !== null && typeof value !== "undefined") {
          return true;
        }
        return context.createError({ message: errorMessage });
      }
      return true;
    });
    return this;
  }

  public isRequiredIfFieldValueIs(
    otherFieldReference: Reference,
    requiredValue: any,
    errorMessage: string,
  ) {
    this.yupObject = this.yupObject.test(function requiredIf(
      value: any,
      context: Yup.TestContext<AnyObject>,
    ) {
      const otherFieldValue = context.parent[otherFieldReference.path];
      if (
        (requiredValue === otherFieldValue && value === null) ||
        typeof value === "undefined"
      ) {
        return context.createError({ message: errorMessage });
      }
      return true;
    });
    return this;
  }

  public build() {
    return this.yupObject;
  }
}

export class YupMixedBuilder extends YupBuilder {
  constructor() {
    super(Yup.mixed());
  }
}

export class YupNumberBuilder extends YupBuilder<Yup.NumberSchema> {
  constructor() {
    super(Yup.number());
  }
}
