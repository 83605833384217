import { z } from "zod";

export const gender = {
  PreferNotToSay: "PREFER_NOT_TO_SAY",
  Male: "MALE",
  Female: "FEMALE",
} as const;

export const genderZodSchema = z.enum([
  gender.PreferNotToSay,
  gender.Male,
  gender.Female,
]);

export default gender;
