import { useQueryMigrationStatus } from "@gymflow/api";
import qs from "qs";
import { useState } from "react";
import { useLocation } from "react-router-dom";

import useGymflowModels from "../../../store";
import { Spinner } from "../../atoms";
import { MigrationOverview } from "./MigrationOverview";
import { MigrationSetPassword } from "./MigrationSetPassword";
import { MigrationSetPaymentMethod } from "./MigrationSetPaymentMethod";
import { MigrationSuccess } from "./MigrationSuccess";

export function MigrationForm() {
  const { api } = useGymflowModels();
  const location = useLocation();
  const params = qs.parse(location.search.slice(1));
  const { data, error, isFetching } = useQueryMigrationStatus({
    api,
    invitationToken: params["invitationToken"] as string | undefined,
  });

  const [step, setStep] = useState<
    "OVERVIEW" | "SET_PASSWORD" | "SET_PAYMENT_METHOD" | "SUCCESS"
  >("OVERVIEW");

  if (isFetching) {
    return (
      <div className="mt-16 flex justify-center">
        <Spinner />
      </div>
    );
  }

  switch (step) {
    case "OVERVIEW": {
      return (
        <MigrationOverview
          isTokenValid={!error && !!params["invitationToken"]}
          needsPasswordSet={!data?.passwordChanged}
          needsPaymentMethod={!data?.paymentMethodCreated}
          onNextButtonClick={() => {
            if (!data?.passwordChanged) {
              setStep("SET_PASSWORD");
            } else if (!data?.paymentMethodCreated) {
              setStep("SET_PAYMENT_METHOD");
            } else {
              setStep("SUCCESS");
            }
          }}
          onProcessAlreadyFinished={() => {
            setStep("SUCCESS");
          }}
        />
      );
    }
    case "SET_PASSWORD": {
      return (
        <MigrationSetPassword
          needsPaymentMethod={!data?.paymentMethodCreated}
          invitationToken={params["invitationToken"] as string}
          onSuccess={() => {
            if (!data?.paymentMethodCreated) {
              setStep("SET_PAYMENT_METHOD");
            } else {
              setStep("SUCCESS");
            }
          }}
        />
      );
    }
    case "SET_PAYMENT_METHOD": {
      return (
        <MigrationSetPaymentMethod
          needsPasswordSet={!data?.passwordChanged}
          invitationToken={params["invitationToken"] as string}
          onSuccess={() => {
            setStep("SUCCESS");
          }}
        />
      );
    }
    case "SUCCESS": {
      return <MigrationSuccess />;
    }
    default:
      return null;
  }
}
