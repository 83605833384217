import { utcToZonedTime } from "@gymflow/helpers";
import { LeadReportItemDTO, OpenLeadReportDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseOpenLeadReportProps {
  api: {
    leadApi: {
      openReport: (params: {
        leadStatusList?: string[];
        leadSourceList?: number[];
        page?: number;
        limit?: number;
        sort: any;
      }) => Promise<any>;
    };
  };
  leadStatusList?: string[];
  leadSourceList?: number[];
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}

export function useOpenLeadReport(
  {
    api,
    leadStatusList,
    leadSourceList,
    page,
    limit,
    sort,
    tz,
  }: UseOpenLeadReportProps,
  queryOpts?: UseQueryOptions<OpenLeadReportDTO>,
) {
  const result = useQuery({
    queryKey: reportQueryKeys.lead({
      leadStatusList,
      leadSourceList,
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const result = await api.leadApi.openReport({
        leadStatusList,
        leadSourceList,
        page,
        limit,
        sort,
      });
      return result.data as OpenLeadReportDTO;
    },
    placeholderData: () => {
      return {
        leadReportByOpenStatusViewDTO: defaultPage<LeadReportItemDTO>(),
        leadReportTotal: 0,
      };
    },
    select: (data) => {
      const dto = merge({}, data.leadReportByOpenStatusViewDTO, {
        content: data.leadReportByOpenStatusViewDTO.content.map(
          ({ createdDate, ...rest }) => {
            return {
              createdDate: createdDate && utcToZonedTime(createdDate, tz),
              ...rest,
            };
          },
        ),
      });
      return {
        ...data,
        leadReportByOpenStatusViewDTO: dto,
      };
    },
    enabled: !!tz,
    ...queryOpts,
  });
  return result;
}
