import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useQueryPaymentMethodSetupIntent({
  api,
  memberId,
  enabled,
}: {
  api: ApiType;
  memberId?: Parameters<ApiType["memberApi"]["getPaymentMethodSetupIntent"]>[0];
  enabled?: boolean;
}) {
  return useQuery({
    queryKey: memberQueryKeys.paymentMethodSetupIntent(memberId),
    queryFn: async () => {
      const result = await api.memberApi.getPaymentMethodSetupIntent(memberId!);
      return result.data;
    },
    enabled: !!memberId && !!enabled,
  });
}
