import { actionOn, thunk, thunkOn } from 'easy-peasy';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';

class ProductModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId, settingsKey = 'settings') {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;
    this.withCategories('productCategoryId');
    this.withActiveInactive();
    this.generators.push(this.productGenerator);
  }

  /**
   * @private
   */
  productGenerator() {
    return {
      create: thunk(
        (
          _,
          { name, productCategoryId, purchasePrice, price, stockQuantity, stockType },
          { injections, getState, getStoreState }
        ) => {
          const { defaultTaxRate } = getStoreState()[this.settingsKey];
          return getApi(injections, getState()).create({
            name,
            productCategoryId,
            purchasePrice,
            price,
            stockQuantity,
            stockType,
            taxRate: defaultTaxRate,
            clubIdList: [this.clubId],
          });
        }
      ),

      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, extraParams } = {}, { getState, injections }) => {
        if (getState().categories.length === 0) {
          await actions.fetchCategories();
        }

        const { data } = await getApi(injections, getState()).findByClubId(this.clubId, {
          page,
          limit,
          sort,
          extraParams,
        });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList({ ...payload, filter: { sort, ...extraParams } });
        return payload;
      }),

      fetchAvailableList: thunk(
        async (actions, { page = 0, limit = 10, sort, extraParams } = {}, { getState, injections }) => {
          if (getState().categories.length === 0) {
            await actions.fetchCategories();
          }

          const { data } = await getApi(injections, getState()).findAvailableByClubId(this.clubId, {
            page,
            limit,
            sort,
            extraParams,
          });
          const payload = createPayloadFromFindResponse(data);
          actions.fetchedList({ ...payload, filter: { sort, ...extraParams } });
          return payload;
        }
      ),

      onFetchListStart: actionOn(
        (actions) => [actions.fetchList.startType, actions.fetchAvailableList.startType],
        (state) => {
          state.loadingList = true;
        }
      ),

      onFetchListDone: actionOn(
        (actions) => [
          actions.fetchList.successType,
          actions.fetchList.failType,
          actions.fetchAvailableList.successType,
          actions.fetchAvailableList.errorType,
        ],
        (state) => {
          state.loadingList = false;
        }
      ),

      onFetchListAndRecord: thunkOn(
        (actions) => [actions.fetchList.startType, actions.fetchById.startType, actions.fetchAvailableList.startType],
        async (actions, _, { getState }) => {
          if (getState().categories.length === 0) {
            await actions.fetchCategories();
          }
        }
      ),
    };
  }
}

export default ProductModelBuilder;
