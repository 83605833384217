import * as Sentry from "@sentry/react";

import environment from "./environment";

// @ts-ignore-next-line
const commitHash = COMMITHASH;
const sentryEnabled = environment.get("SENTRY_ENABLED");
const sentryEnv = environment.get("SENTRY_ENV");
if (sentryEnabled && sentryEnv && commitHash) {
  const dsn = environment.get("SENTRY_DSN");
  Sentry.init({
    dsn,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    release: commitHash,
    environment: sentryEnv,

    beforeSend: (event, hint) => {
      const error: any = hint.originalException;
      if (error?.code === "ECONNABORTED" || error?.code === "ERR_NETWORK") {
        return null;
      }
      return event;
    },
  });
}
