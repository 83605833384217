import { PublicMembershipApiType } from "@gymflow/common/lib/api/publicMembershipApi";
import { useQuery } from "@tanstack/react-query";

import { membershipAsPublicQueryKeys } from "./membershipAsPublicQueryKeys";

export function useQueryMembershipListAsPublic({
  api,
  opts,
}: {
  api: {
    public: {
      membershipApi: PublicMembershipApiType;
    };
  };
  opts: Parameters<PublicMembershipApiType["getList"]>[0];
}) {
  const result = useQuery({
    queryKey: membershipAsPublicQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.public.membershipApi.getList({ ...opts });
      return result.data;
    },
  });
  return result;
}
