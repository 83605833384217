import { useAppointmentSummaryAsMember, useMemberAsMember } from "@gymflow/api";
import { cn, formatCurrency, pluralize } from "@gymflow/helpers";
import { AppointableDTO } from "@gymflow/types";
import { useEffect, useState } from "react";

import { useAuthenticatedUser, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { ArrowLeftIcon, Button, InputWithSaveButton } from "../../atoms";
import { PaymentMethodPicker } from "../../molecules/PaymentMethodPicker";

export function Checkout({
  onBack,
  service,
  onCheckout,
}: {
  onBack: () => void;
  service: AppointableDTO;
  onCheckout: (params: {
    paymentUnit: "CARD" | "CREDITS";
    promoCode?: string;
    paymentMethodId?: string;
  }) => Promise<void>;
}) {
  const [paymentUnit, setPaymentUnit] = useState<"CARD" | "CREDITS">();
  const [promoCode, setPromoCode] = useState("");
  const { api } = useGymflowModels();
  const { defaultCurrency } = useClubSettings();
  const { id: memberId } = useAuthenticatedUser();
  const {
    data: summary,
    isFetching: isLoadingSummary,
    isSuccess,
  } = useAppointmentSummaryAsMember(
    {
      api,
      opts: {
        appointableId: service.id,
        userMemberId: memberId as string,
        appointmentPaymentPostDTO: {
          paidWithSessions: paymentUnit === "CREDITS",
          promotionCode: promoCode,
        },
      },
    },
    { enabled: !!paymentUnit },
  );

  useEffect(() => {
    const getDefaultState = (): "CARD" | "CREDITS" | undefined => {
      if (!isSuccess || paymentUnit !== undefined) {
        return;
      }
      if (service.sessionCost === undefined) {
        return "CARD";
      }

      if (service.price === undefined) {
        return "CREDITS";
      }

      if (
        summary?.paidWithSessions &&
        summary.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS"
      ) {
        return "CREDITS";
      }
      return "CARD";
    };
    let result = getDefaultState();
    if (result) {
      setPaymentUnit(result);
    }
  }, [isSuccess, paymentUnit, service.price, service.sessionCost, summary]);

  const [paymentMethodId, setPaymentMethodId] = useState<string>();

  const renderAmountDue = () => {
    if (summary?.paidWithSessions) {
      if (service.sessionCost !== undefined) {
        return `${service.sessionCost} ${pluralize(
          "Credit",
          "Credits",
          service.sessionCost,
        )}`;
      }
      return "";
    }
    return formatCurrency(
      summary
        ? (summary?.cardUsage.upfrontPriceDetails.totalAmountToPay as number)
        : 0,
      defaultCurrency,
    );
  };
  const settings = useClubSettings();
  const { data: userMember } = useMemberAsMember({
    api,
    memberId,
    tz: settings.timezone,
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-4">
        <div
          className="dark:hover:bg-darkGray-900 dark:border-darkGray-800 cursor-pointer rounded-lg border border-gray-300 px-3 py-2 hover:bg-gray-50"
          onClick={onBack}
        >
          <ArrowLeftIcon pathClassName="stroke-gray-500 dark:stroke-darkGray-400" />
        </div>
        <div className="text-2xl font-bold dark:text-white">Checkout</div>
      </div>
      <div>
        <div className="dark:border-darkGray-900 flex flex-col gap-4 rounded-t-lg border border-gray-300 p-4">
          <div>
            <div className="mt-4 flex">
              <Button
                onClick={() => setPaymentUnit("CARD")}
                className={cn("mt-2 w-full bg-white focus:outline-none", {
                  "dark:bg-darkGray-900 dark:text-gray-400 bg-gray-50":
                    paymentUnit === "CARD",
                  "!rounded-r-none": service.sessionCost !== undefined,
                  hidden: service.price === undefined,
                })}
              >
                Card
              </Button>
              <Button
                onClick={() => setPaymentUnit("CREDITS")}
                className={cn("mt-2  w-full bg-white focus:outline-none", {
                  "dark:bg-darkGray-900 dark:text-gray-400 bg-gray-50":
                    paymentUnit === "CREDITS",
                  "!rounded-l-none": service.price !== undefined,
                  hidden: service.sessionCost === undefined,
                })}
              >
                Credits
              </Button>
            </div>
          </div>
          {paymentUnit === "CARD" && userMember && (
            <PaymentMethodPicker
              userMember={userMember}
              currentPaymentMethodId={paymentMethodId}
              onChange={setPaymentMethodId}
              asMember
            />
          )}
          {paymentUnit === "CREDITS" && <CreditPackDetails />}
        </div>
        <div className="dark:border-darkGray-900 flex flex-col gap-4 rounded-b-lg border-x border-b border-gray-300  p-4">
          <div
            className={cn("flex flex-col gap-2", {
              hidden: paymentUnit === "CREDITS",
            })}
          >
            <div className="dark:text-gray-400 text-sm">
              Promotional Code
            </div>
            <div>
              <InputWithSaveButton
                onChange={(newPromoCode) => {
                  setPromoCode(newPromoCode);
                }}
                value={
                  promoCode &&
                  summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode === "YES"
                    ? promoCode
                    : ""
                }
                isLoading={isLoadingSummary}
              />
            </div>
            <div
              className={cn("text-error-600", {
                hidden: !(
                  promoCode &&
                  summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"
                ),
              })}
            >
              Promotion code is not valid.
            </div>
          </div>
          <div
            className={cn("flex justify-between", {
              hidden: paymentUnit !== "CARD",
            })}
          >
            <div className="text-sm dark:text-white">{service.name}</div>
            <div className="dark:text-darkGray-500 text-sm text-gray-500">
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? (summary?.cardUsage.upfrontPriceDetails
                        .totalAmountBeforeDiscount as number)
                    : 0,
                  defaultCurrency,
                )}
            </div>
          </div>

          <div
            className={cn("flex justify-between", {
              hidden:
                isLoadingSummary ||
                paymentUnit !== "CARD" ||
                !promoCode ||
                (summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"),
            })}
          >
            <div className="text-success-600 text-sm dark:text-white">
              Discount
            </div>
            <div className="dark:text-darkGray-500 text-sm text-gray-500">
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? -(summary?.cardUsage.upfrontPriceDetails
                        .totalAmountDifference as number)
                    : 0,
                  defaultCurrency,
                )}
            </div>
          </div>
          <div
            className={cn("flex flex-col gap-2", {
              hidden: paymentUnit !== "CREDITS",
            })}
          >
            {summary?.paidWithSessions &&
            summary.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS" ? (
              summary.sessionPackUsage.sessionPackUsageCalculations.map(
                (pack) => (
                  <div
                    key={pack.sessionPack.id}
                    className="flex justify-between"
                  >
                    <div className="text-sm dark:text-white">
                      {pack.sessionPack.name}
                    </div>
                    <div className="dark:text-darkGray-500 text-sm text-gray-500">
                      {`${pack.numberOfSessionsUsed} ${pluralize(
                        "Credit",
                        "Credits",
                        pack.numberOfSessionsUsed,
                      )}
                      `}
                    </div>
                  </div>
                ),
              )
            ) : (
              <div className="text-error-600">
                Not enough credits to book session.
              </div>
            )}
          </div>
          <div className="dark:text-darkGray-400 flex justify-between font-bold text-gray-700">
            <div>Amount Due</div>
            <div>{renderAmountDue()}</div>
          </div>
          <div className="flex justify-center">
            <Button
              intent="primary"
              className="mt-2 w-1/2"
              disabled={
                !paymentUnit ||
                isLoadingSummary ||
                (paymentUnit === "CREDITS" &&
                  summary?.paidWithSessions === true &&
                  summary.sessionPackUsage.result === "NOT_ENOUGH_SESSIONS") ||
                (paymentUnit === "CARD" && !paymentMethodId)
              }
              onClick={async () => {
                if (!paymentUnit) {
                  return;
                }
                await onCheckout({
                  paymentUnit,
                  paymentMethodId,
                  promoCode,
                });
              }}
            >
              Pay
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

function CreditPackDetails() {
  return (
    <div>
      <div className="dark:text-darkGray-200 text-sm font-semibold text-gray-700">
        Credits
      </div>
      <div className="dark:text-darkGray-200 text-sm font-normal text-gray-600">
        Pay for this appointment with credits available on your account. Credits
        will be deducted from the pack expiring soonest.
      </div>
    </div>
  );
}
