import { GrowMetricsApiKey } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { integrationApiKeyKeys } from "./integrationApiKeyQueryKeys";

export function useMutationGrowMetricsApiKeysCreate({
  api,
}: {
  api: {
    integrationApiKeyApi: {
      postGrowMetricsApiKeys: () => Promise<{ data: GrowMetricsApiKey }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      return (await api.integrationApiKeyApi.postGrowMetricsApiKeys()).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationApiKeyKeys.growMetrics(),
      });
    },
  });
}

export function useMutationGrowMetricsApiKeysDelete({
  api,
}: {
  api: {
    integrationApiKeyApi: {
      deleteGrowMetricsApiKeys: () => Promise<{ data: any }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      return (await api.integrationApiKeyApi.deleteGrowMetricsApiKeys()).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: integrationApiKeyKeys.growMetrics(),
      });
    },
  });
}
