import {
  PARAMETER_DATE_ONLY_FORMAT,
  PARAMETER_TIME_FORMAT,
} from "@gymflow/common/lib/api/eventApi";
import { DATE_FORMAT, TIME_FORMAT } from "@gymflow/common/lib/helpers/form";
import {
  LeadTaskDTO,
  TaskDTO,
  UserMemberTaskDTO,
  UserStaffTaskDTO,
} from "@gymflow/types";
import moment from "moment";

import { TaskFormValues, TaskStatus } from "../components/TaskForm/types";

const complete: Record<TaskStatus, boolean> = {
  COMPLETED: true,
  INCOMPLETE: false,
};

export const normalizeOutgoingData = ({
  relatedUsers,
  status,
  taskDetails,
  taskName,
  taskOwners,
  dueDate,
  dueTime,
}: TaskFormValues) => {
  const relatedLeadIdList =
    relatedUsers
      ?.filter(({ value: { profileType } }) => profileType === "LEAD")
      .map(({ id }) => id) || [];

  const relatedUserIdList =
    relatedUsers
      ?.filter(({ value: { profileType } }) => profileType === "USER")
      .map(({ id }) => id) || [];

  const deadlineDate = dueDate
    ? moment(dueDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT)
    : undefined;

  const deadlineTime = dueTime
    ? moment(dueTime, TIME_FORMAT).format(PARAMETER_TIME_FORMAT)
    : undefined;

  return {
    complete: complete[status],
    deadlineDate,
    deadlineTime,
    name: taskName,
    notes: taskDetails,
    ownerIdList: taskOwners?.map(({ id }) => id),
    relatedLeadIdList,
    relatedUserIdList,
  };
};

export const normalizeIncomingData = (
  {
    name,
    notes,
    complete,
    deadlineDate,
    deadlineTime,
    taskRelatedLeads = [],
    taskRelatedUsers = [],
    taskOwners = [],
  }: TaskDTO,
  timezone: string,
): TaskFormValues => {
  const status: TaskStatus = complete ? "COMPLETED" : "INCOMPLETE";

  const dueDate = deadlineDate
    ? moment.tz(deadlineDate, PARAMETER_DATE_ONLY_FORMAT, timezone).toDate()
    : undefined;

  const dueTime = deadlineTime
    ? moment
        .tz(deadlineTime, PARAMETER_TIME_FORMAT, timezone)
        .format(TIME_FORMAT)
    : undefined;

  const relatedUsers = [
    ...taskRelatedLeads.map((lead) => {
      const { userMemberId, id: leadId, firstName, lastName } = lead;
      return createUsersOwnersObject(
        lead,
        userMemberId ?? leadId,
        firstName,
        lastName,
      );
    }),
    ...taskRelatedUsers.map((user) => {
      const { id, firstName, lastName, picture } = user;
      return createUsersOwnersObject(user, id, firstName, lastName, picture);
    }),
  ];

  const formattedTaskOwners = taskOwners.map((owner) => {
    const { id, firstName, lastName, picture } = owner;
    return createUsersOwnersObject(owner, id, firstName, lastName, picture);
  });

  return {
    taskName: name,
    taskDetails: notes,
    status,
    relatedUsers,
    taskOwners: formattedTaskOwners,
    dueDate,
    dueTime,
  } as TaskFormValues;
};

const createUsersOwnersObject = (
  user: UserStaffTaskDTO | UserMemberTaskDTO | LeadTaskDTO,
  id: string,
  firstName: string,
  lastName: string,
  imageUrl?: string,
) => ({
  id,
  value: user,
  label: `${firstName} ${lastName}`,
  imageUrl,
});
