import { FeatureFlags } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { clubStaleTime } from "../club/useClub";
import { clubAsPublicQueryKeys } from "./clubAsPublicQueryKeys";

export function useQueryClubFeatureFlagsAsPublic(
  {
    api,
    clubId,
  }: {
    api: {
      public: {
        clubApi: {
          featureFlags: (clubId: number) => Promise<{ data: FeatureFlags }>;
        };
      };
    };
    clubId: number;
  },
  opts?: UseQueryOptions<FeatureFlags | null>,
) {
  const result = useQuery({
    queryKey: clubAsPublicQueryKeys.featureFlags(clubId),
    queryFn: async () => {
      const result = await api.public.clubApi.featureFlags(clubId);
      return result.data as FeatureFlags;
    },
    enabled: !!clubId,
    placeholderData: null,
    staleTime: clubStaleTime,
    ...opts,
  });

  return result;
}
