import { ProductStockType } from "@gymflow/common";
import { ProductStockTypeSchema } from "libs/common/src/lib/api/ProductStockType";
import z from "zod";

export const ProductSchema = z
  .object({
    picture: z
      .object({
        url: z.string().optional(),
        blob: z.any(),
      })
      .optional(),
    name: z.string().min(3, "Must be at least 3 characters").default(""),
    price: z.coerce.number({
      invalid_type_error: "Must include price",
      required_error: "Must include price",
    }),
    "purchase-price": z.coerce.number({
      invalid_type_error: "Must include wholesale price",
      required_error: "Must include wholesale price",
    }),
    "stock-quantity": z.coerce
      .number({
        invalid_type_error: "Must include stock quantity",
        required_error: "Must include stock quantity",
      })
      .optional(),
    "stock-type": ProductStockTypeSchema.default(ProductStockType.Unlimited),
    "product-category-id": z.number(),
    "allow-web-purchases": z.boolean().default(true),
    "allow-app-purchases": z.boolean().default(true),
  })
  .superRefine((data, ctx) => {
    if (data["stock-type"] === ProductStockType.Limited) {
      if (data["stock-quantity"] === undefined) {
        ctx.addIssue({
          code: "custom",
          message: "Stock quantity is required to track stock.",
          path: ["stock-quantity"],
        });
        return false;
      }
    }
    return true;
  });

export type ProductType = z.infer<typeof ProductSchema>;
export type ProductSchemaKeys = keyof z.infer<typeof ProductSchema>;
