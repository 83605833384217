// @ts-ignore
import { tzDateTimeStringToUtc } from "@gymflow/common";
import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation } from "@tanstack/react-query";

export function useAccessReportCsv({ api, tz }: { api: ApiType; tz: string }) {
  const csvMutation = useMutation({
    mutationFn: async ({
      dateFrom,
      dateTo,
      membershipIncluded,
      onSite,
      guest,
    }: Parameters<ApiType["accessApi"]["csv"]>[0]) => {
      const result = await api.accessApi.csv({
        ...(dateFrom !== undefined
          ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
          : {}),
        ...(dateTo !== undefined
          ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
          : {}),
        membershipIncluded,
        onSite,
        guest,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
