import { assert } from "check-types";

import endpoints from "./endpoints";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicStrongCustomerAuthorizationEndpoints = {
  confirmPayment: `${endpoints.strongCustomerAuthorization}/confirm-hosted-pages`,
};

const publicStrongCustomerAuthorizationApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.strongCustomerAuthorization}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, {
    confirmPayment(paymentIntent) {
      assert.string(paymentIntent, "paymentIntent must be a string");

      return state.http.get(
        `${clubPrefix}${publicStrongCustomerAuthorizationEndpoints.confirmPayment}`,
        {
          params: {
            // eslint-disable-next-line camelcase
            payment_intent: paymentIntent,
          },
        },
      );
    },
  });
};

export default publicStrongCustomerAuthorizationApi;
