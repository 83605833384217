export type ExclamationCircleIconProps = {
  className?: string;
  pathClassName?: string;
};

export const ExclamationCircleIcon = ({
  className,
  pathClassName,
}: ExclamationCircleIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_5219_3611)">
        <path
          d="M9.99996 6.66675V10.0001M9.99996 13.3334H10.0083M18.3333 10.0001C18.3333 14.6025 14.6023 18.3334 9.99996 18.3334C5.39759 18.3334 1.66663 14.6025 1.66663 10.0001C1.66663 5.39771 5.39759 1.66675 9.99996 1.66675C14.6023 1.66675 18.3333 5.39771 18.3333 10.0001Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className={pathClassName}
        />
      </g>
      <defs>
        <clipPath id="clip0_5219_3611">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
