import classNames from "classnames";
import { Col, FormGroup, Label, Row } from "reactstrap";
import {
  FormikInput,
  formikHelpers,
  renderErrorRowOnTouch,
} from "@gymflow/common";
import Select from "react-select";

import {
  PRODUCT_STORE_KEY,
  CREDIT_PACK_STORE_KEY,
  SERVICE_STORE_KEY,
} from "../../../store/keys";
import {
  LIMIT_CREDIT_PACK_ID,
  LIMIT_PRODUCT_ID,
  LIMIT_SERVICE_MEMBERSHIP_ID,
  LIMIT_USAGES_PER_USER,
  LIMIT_USAGES,
} from "./PromotionSchema";

function Step4({ formikProps, specificItemsOptions, lockSomeFields }) {
  const { values, setFieldValue, touched, errors } = formikProps;
  const { errorClass } = formikHelpers(formikProps);

  const enabledOptions = specificItemsOptions.filter((item) => item.isActive);
  const itemValue = specificItemsOptions.find((op) => {
    return (
      (op.value.type === PRODUCT_STORE_KEY &&
        op.value.id === values[LIMIT_PRODUCT_ID]) ||
      (op.value.type === SERVICE_STORE_KEY &&
        op.value.id === values[LIMIT_SERVICE_MEMBERSHIP_ID]) ||
      (op.value.type === CREDIT_PACK_STORE_KEY &&
        op.value.id === values[LIMIT_CREDIT_PACK_ID])
    );
  });

  return (
    <div className="w-100 text-center">
      <b className="h2">Set some limitations on usage</b>
      <div className="text-muted mt-3">
        <p className="lh-130">
          We strongly recommend putting in place some limitations on promotional
          code usage. This will ensure you avoid any unwanted discounts being
          applied, as codes typically get shared between friends and online. You
          can use any or all of the below limits to control usage.
        </p>
      </div>
      <Row className="d-flex justify-content-center mt-4 text-left">
        <Col md={9}>
          <Row className="text-uppercase text-left">
            <Col>
              <Label tag="h3">Specific Item Only</Label>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <FormGroup
                className={classNames(
                  errorClass(LIMIT_PRODUCT_ID),
                  errorClass(LIMIT_SERVICE_MEMBERSHIP_ID),
                  errorClass(LIMIT_CREDIT_PACK_ID),
                )}
              >
                <Select
                  className="react-select info"
                  classNamePrefix="react-select"
                  options={enabledOptions}
                  isClearable
                  isDisabled={lockSomeFields}
                  onChange={(newValue) => {
                    setFieldValue(LIMIT_PRODUCT_ID, null);
                    setFieldValue(LIMIT_SERVICE_MEMBERSHIP_ID, null);
                    setFieldValue(LIMIT_CREDIT_PACK_ID, null);
                    if (newValue?.value?.type === PRODUCT_STORE_KEY) {
                      setFieldValue(LIMIT_PRODUCT_ID, newValue?.value?.id);
                    } else if (newValue?.value?.type === SERVICE_STORE_KEY) {
                      setFieldValue(
                        LIMIT_SERVICE_MEMBERSHIP_ID,
                        newValue?.value?.id,
                      );
                    } else if (
                      newValue?.value?.type === CREDIT_PACK_STORE_KEY
                    ) {
                      setFieldValue(LIMIT_CREDIT_PACK_ID, newValue?.value?.id);
                    }
                  }}
                  value={itemValue}
                />
                {renderErrorRowOnTouch(LIMIT_PRODUCT_ID, touched, errors)}
                <div
                  className={classNames("text-error-600", {
                    hidden: !itemValue || itemValue?.isActive,
                  })}
                >
                  This item is currently disabled.
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-2 text-left">
        <Col md={9}>
          <Row>
            <Col>
              <Row className="text-uppercase text-left">
                <Col>
                  <Label tag="h3">Limit Per User</Label>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <FormGroup className={errorClass(LIMIT_USAGES_PER_USER)}>
                    <FormikInput
                      autoComplete="off"
                      maxLength="8"
                      name={LIMIT_USAGES_PER_USER}
                      placeholder="No Limit"
                      type="number"
                      formikProps={formikProps}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="text-uppercase text-left">
                <Col>
                  <Label tag="h3">Total Limit</Label>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <FormGroup className={errorClass(LIMIT_USAGES)}>
                    <FormikInput
                      autoComplete="off"
                      maxLength="8"
                      name={LIMIT_USAGES}
                      placeholder="No Limit"
                      type="number"
                      formikProps={formikProps}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Step4;
