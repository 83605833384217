import {
  useRevenueByTransactionTypeReport,
  useRevenueByTransactionTypeReportCsv,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
} from "@gymflow/common";
import { downloadCsv, formatCurrency } from "@gymflow/helpers";
import { TransactionReportItemDTO, TransactionType } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import useQueryParam from "../../hooks/useQueryParam";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function RevenueTransaction() {
  const [transactionType] = useQueryParam("type", "string") as [
    TransactionType,
  ];
  const history = useHistory();
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);

  const [dates, setDates] = useQueryParam("dates", "string") as any;
  const defaultDates = useMemo(
    () => ({
      startDate: moment().format(PARAMETER_DATE_FORMAT),
      endDate: moment().endOf("month").format(PARAMETER_DATE_FORMAT),
    }),
    [],
  );
  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, []);

  const { data, isFetching } = useRevenueByTransactionTypeReport({
    api,
    dateFrom: dates?.startDate,
    dateTo: dates?.endDate,
    tz: settings.timezone,
    limit: pageSize,
    page: currentPage,
    transactionType: transactionType as any,
  });

  const downloadCsvMutation = useRevenueByTransactionTypeReportCsv({
    api,
    tz: settings.timezone,
  });

  const columnHelper = createColumnHelper<TransactionReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor("date", {
      cell: (info) => (
        <div className="text-gray-600">
          {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            settings.date_format,
          )}
        </div>
      ),
      header: () => <div >Date</div>,
      enableSorting: false,
    }),
    columnHelper.accessor("name", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Name",
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => (
        <div className="text-gray-600">
          {formatCurrency(info.getValue(), settings.defaultCurrency)}
        </div>
      ),
      header: "Amount",
      footer: () =>
        `Total: ${
          data?.total
            ? formatCurrency(data.total, settings.defaultCurrency)
            : ""
        }`,
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        setDates({
          startDate: moment(newDate.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          ),
          endDate: moment(newDate.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        });
      }
    },
    [setDates],
  );

  return (
    <Report
      title={
        (
          {
            PENDING_AUTHORIZATION: "Awaiting Authorizations",
            REFUND_DEBIT: "Refunds",
            WRITE_OFF: "Write-Offs",
            MANUAL_CREDIT: "Credits Applied",
            CREDIT: "", // these reports do no exist
            DEBIT: "",
          } as { [key in TransactionType]: string }
        )[transactionType]
      }
      description={
        (
          {
            PENDING_AUTHORIZATION:
              "List of all payments awaiting authorization within the date range specified. Any payments not authorized by users will not be collected.",
            REFUND_DEBIT:
              "List of all refunds processed by staff back to users within the specified date period.",
            WRITE_OFF:
              "List of all invoices written off by staff due to non-payment within the specified date period.",
            MANUAL_CREDIT:
              "List of all credits applied to invoices by staff within the specified date period.",
            DEBIT: "", // these reports do no exist
            CREDIT: "",
          } as { [key in TransactionType]: string }
        )[transactionType]
      }
      onDownloadClick={async () => {
        const data = await downloadCsvMutation.mutateAsync({
          transactionType: transactionType as any,
          dateFrom: dates?.startDate,
          dateTo: dates?.endDate,
        });
        downloadCsv(
          data,
          `${
            (
              {
                PENDING_AUTHORIZATION: "AwaitingAuthorizations",
                REFUND_DEBIT: "Refunds",
                WRITE_OFF: "WriteOffs",
                MANUAL_CREDIT: "CreditsApplied",
                CREDIT: "", // these reports do no exist
                DEBIT: "",
              } as { [key in TransactionType]: string }
            )[transactionType]
          }.csv`,
        );
      }}
      handleDateRangeChange={handleDateRangeChange}
      dateRange={
        dates?.startDate && dates?.endDate
          ? {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(dates.endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                .endOf("day")
                .format(DATE_FORMAT),
            }
          : undefined
      }
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(
                dates.endDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.data.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.data.totalPages}
            pageIndex={data?.data.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
          />
          <div className="border-t-[1px] border-gray-300">
            <ReportPagination
              pageCount={data?.data.totalPages as number}
              currentPage={data?.data.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}
