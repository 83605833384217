import { useClub } from "@gymflow/api";
import { CreditTopUpItem } from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  CreditCardDownloadIcon,
  SMSIcon,
  Spinner,
} from "../../../atoms";
import { formatDate } from "../utils/formatDate";
import { ChooseSMSPackageModal } from "./ChooseSMSPackageModal/ChooseSMSPackageModal";

type CreditsBalanceBlockProps = {
  lastTopUpItem?: CreditTopUpItem;
};

export const CreditsBalanceBlock = ({
  lastTopUpItem,
}: CreditsBalanceBlockProps) => {
  const { stackModal, hide } = useContext(ModalContext);
  const { t } = useTranslation();
  const { api } = useGymflowModels();
  const { timezone, clubId } = useClubSettings();
  const { data: club, isFetching } = useClub(
    { api, clubId },
    { refetchOnWindowFocus: true },
  );

  const onClickTopUp = useCallback(
    () => stackModal(<ChooseSMSPackageModal onClose={() => hide()} />),
    [hide, stackModal],
  );

  return (
    <div className="relative flex flex-col gap-3 rounded-2xl border border-gray-200 bg-[#ffffff] p-4 shadow-sm lg:w-1/2">
      <div className="flex items-center gap-2">
        <div className="bg-success-50 flex h-9 w-9 items-center justify-center rounded-full">
          <SMSIcon pathClassName="stroke-success-600 stroke-2" />
        </div>
        <div className="text-base font-medium text-gray-950">
          {t("page.settings.tab.sms.creditsBalanceBlock.title")}
        </div>
      </div>
      <div className="flex flex-row items-end justify-between">
        <div className="flex flex-col gap-0.5">
          <div className="text-3xl font-bold text-gray-950">
            {club?.messagingCreditsLeft ?? 0}
          </div>
          <div className="text-sm font-medium text-gray-500">
            {t("page.settings.tab.sms.creditsBalanceBlock.lastTopUp", {
              lastTopUpDate: formatDate(lastTopUpItem?.createdDate, timezone),
            })}
          </div>
        </div>
        <Button className="gap-1.5" onClick={() => onClickTopUp()}>
          <CreditCardDownloadIcon />
          {t("page.settings.tab.sms.creditsBalanceBlock.button.topUp")}
        </Button>
      </div>
      {isFetching && (
        <div className="absolute inset-0 flex items-center justify-center bg-[#ffffff]/50">
          <Spinner className="h-9 w-9" />
        </div>
      )}
    </div>
  );
};
