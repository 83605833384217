import useGymflowModels from "../store";

/**
 * @deprecated
 */
const usePublicSessionPacks = () => {
  const { PublicCreditPackStore } = useGymflowModels();
  const fetchList = PublicCreditPackStore.useStoreActions(
    (actions: any) => actions.fetchList,
  );
  const { page, pageCount, editing, rows, loadingList, loadingRecord } =
    PublicCreditPackStore.useStoreState((state: any) => state);

  return {
    fetchList,
    page,
    pageCount,
    editing,
    rows,
    loadingRecord,
    loadingList,
  };
};

export default usePublicSessionPacks;
