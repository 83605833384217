import { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";

const ruleEndpoints = {
  byClubId: `/templates`,
};

const ruleApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.rule}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, canFind(state), {
    find({
      page = 0,
      limit = 10,
      sort,
      description,
      id,
      type,
      rulesAssociation,
      valueSetup,
    }) {
      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");
      assert.maybe.string(
        description,
        "description must be string or undefined",
      );

      const config = {
        params: {
          page,
          size: limit,
          description,
          id,
          type,
          rulesAssociation,
          valueSetup,
        },
      };
      if (sort) {
        assert.string(
          sort.field,
          "sort.field must be a string if sort is specified.",
        );
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(state.baseUrl + ruleEndpoints.byClubId, config);
    },

    getValuesByClubId(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(state.baseUrl);
    },
    updateValues(clubId, rules) {
      assert.number(clubId, "clubId must be a number");

      return state.http.put(state.baseUrl, rules);
    },
    ruleGroup(ruleGroupId) {
      return state.http.get(`${clubPrefix}rule-group/${ruleGroupId}`);
    },
    newRuleGroup(fields) {
      return state.http.post(`${clubPrefix}rule-group/`, fields);
    },
    editRuleGroup(ruleGroupId, patchedFields) {
      return state.http.put(
        `${clubPrefix}rule-group/${ruleGroupId}`,
        patchedFields,
      );
    },
    ruleGroupSearch({ isActive, isDefault, groupType, page, size }) {
      return state.http.put(`${clubPrefix}rule-group/search`, {
        isActive,
        isDefault,
        groupType,
        page,
        size,
      });
    },
    ruleGroupDelete(ruleGroupId) {
      return state.http.delete(`${clubPrefix}rule-group/${ruleGroupId}`);
    },
  });
};

ruleApi.endpoints = ruleEndpoints;

export default ruleApi;
