import { isMobile } from "@gymflow/common";
import classNames from "classnames";
import { useFormikContext } from "formik";
import omit from "lodash/omit";
import moment from "moment";
import { forwardRef } from "react";
import Datetime from "react-datetime";

const DATE_FORMAT = "DD/MM/YYYY";

function FormikDateTime(props, ref) {
  const { timeFormat } = props;
  const form = props.formikProps;
  const field = form.getFieldProps(props.name);
  // if the date field isn't in a valid date format,
  const onFieldChange = (value) => {
    let dateValue = value;
    // react-datetime's onChange handler returns a string
    // otherwise it returns a moment object
    // this is why we can't override DateTime's onChange
    // prop with Formik's field.onChange
    if (value instanceof moment) {
      if (props.dateFormat !== false) {
        dateValue = moment(value).format(
          (props.dateFormat || DATE_FORMAT) + (props.timeFormat ? ` ${props.timeFormat}` : ""),
        );
      } else if (props.timeFormat) {
        dateValue = moment(value).format(props.timeFormat);
      }
    }
    if (props.onChange) {
      props.onChange(dateValue);
    }

    form.setFieldValue(field.name, dateValue);
  };

  const onFieldBlur = () => {
    form.setFieldTouched(field.name, true);
  };

  const childProps = omit(props, ["onChange", "value", "onBlur", "formikProps"]);

  return (
    <Datetime
      dateFormat={DATE_FORMAT}
      timeFormat={timeFormat}
      onChange={(v) => {
        onFieldChange(v);
      }}
      value={field.value}
      closeOnSelect
      ref={ref}
      {...childProps}
      inputProps={{
        onBlur: onFieldBlur,
        ...childProps.inputProps,
        ...(isMobile()
          ? { readonly: "readonly", className: classNames("!bg-white", childProps?.inputProps?.className) }
          : {}),
      }}
    />
  );
}


export function FormikDateTimeContext(props) {
  const formik = useFormikContext();

  return <FormikDateTime {...props} formikProps={formik} />;
}

export default forwardRef(FormikDateTime);
