import { useContext } from "react";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Button } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { AlertContext, DATE_FORMAT_WITH_SECONDS } from "@gymflow/common";

import Item from "./Item";

function EmailItem({ subject, createdBy, toEmail, body, sendEmailDate, dateFormat }) {
  const { setAlert, hide } = useContext(AlertContext);
  const sendDate = moment(sendEmailDate, DATE_FORMAT_WITH_SECONDS).format(`[at] h:mm a, ${dateFormat}`);

  return (
    <Item
      icon={faEnvelope}
      firstLine={
        <div className="d-inline-block">
          {subject}
          <Button
            className="btn-link"
            onClick={() => {
              setAlert(
                <ReactBSAlert
                  title={
                    <div className="head-title">
                      <h2 className="text-left">
                        <div className="text-left">{subject}</div>
                        <div
                          className="d-inline-block text-left"
                          style={{ fontSize: 10, fontWeight: "normal", color: "#9d9ea2" }}
                        >
                          Sent by {createdBy.firstName} {createdBy.lastName} {sendDate} to {toEmail}
                        </div>
                      </h2>
                    </div>
                  }
                  showCancel={false}
                  confirmBtnText="Close"
                  closeOnClickOutside={false}
                  onConfirm={hide}
                  customClass="alert-wrapper"
                  style={{
                    width: 500,
                  }}
                >
                  <div className="text-left" dangerouslySetInnerHTML={{ __html: body }} />
                </ReactBSAlert>,
              );
            }}
          >
            View
          </Button>
        </div>
      }
      secondLine={
        <>
          Sent by {createdBy.firstName} {createdBy.lastName} {sendDate}
        </>
      }
    />
  );
}

EmailItem.propTypes = {
  subject: PropTypes.string.isRequired,
  createdBy: PropTypes.object.isRequired,
  toEmail: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  sendTimestamp: PropTypes.number.isRequired,
  dateFormat: PropTypes.string.isRequired,
};
export default EmailItem;
