import useGymflowModels from "../store";

/**
 * @deprecated
 */
const useLeadSourceLoadOptions = () => {
  const { api } = useGymflowModels();

  const loadOptions = async (_, __, { hasAll = false, page }) => {
    const { data } = await api.leadSourceApi.find({ page, extraParams: { active: true } });
    const options = data.content.map(({ id, name }) => ({ value: { id, name }, label: name }));

    if (hasAll && page === 0) {
      options.unshift({
        label: "All",
        value: null,
      });
    }

    return {
      options,
      hasMore: !data.last,
      additional: {
        page: page + 1,
      },
    };
  };

  return loadOptions;
};

export default useLeadSourceLoadOptions;
