import { Route, Switch, useRouteMatch } from "react-router-dom";

import { AccessRuleForm } from "./Rules/AccessRuleForm";
import { AppointmentRuleForm } from "./Rules/AppointmentRuleForm";
import { ClassRuleForm } from "./Rules/ClassRuleForm";
import { MembershipRuleForm } from "./Rules/MembershipRuleForm";

export const ruleFormRoute: Record<string, string> = {
  CLASS_GROUP_NEW: "/class/",
  APPOINTABLE_GROUP_NEW: "/appointment/",
  MEMBERSHIP_GROUP_NEW: "/membership/",
  CLASS_GROUP: "/class/:ruleGroupId",
  APPOINTABLE_GROUP: "/appointment/:ruleGroupId",
  MEMBERSHIP_GROUP: "/membership/:ruleGroupId",
  ACCESS_GROUP: "/access/:ruleGroupId",
} as const;

export function RuleFormRouter() {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path + ruleFormRoute["CLASS_GROUP"]}>
        <ClassRuleForm />
      </Route>
      <Route path={match.path + ruleFormRoute["CLASS_GROUP_NEW"]}>
        <ClassRuleForm />
      </Route>
      <Route path={match.path + ruleFormRoute["APPOINTABLE_GROUP"]}>
        <AppointmentRuleForm />
      </Route>
      <Route path={match.path + ruleFormRoute["APPOINTABLE_GROUP_NEW"]}>
        <AppointmentRuleForm />
      </Route>
      <Route path={match.path + ruleFormRoute["MEMBERSHIP_GROUP"]}>
        <MembershipRuleForm />
      </Route>
      <Route path={match.path + ruleFormRoute["ACCESS_GROUP"]}>
        <AccessRuleForm />
      </Route>
    </Switch>
  );
}
