import { assert } from "check-types";

import endpoints from "./endpoints";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const ruleEndpoints = {};

const publicRuleApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicRule}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
    apiUrl,
  };

  return Object.assign(state, {
    getRulesFor(params) {
      return state.http.get(`${clubPrefix}hosted/rule-group`, { params });
    },
    getValuesByClubId(clubId) {
      assert.number(clubId, "clubId must be a number");

      return state.http.get(state.baseUrl);
    },
  });
};

publicRuleApi.endpoints = ruleEndpoints;

export default publicRuleApi;
