import { PaginatedSelect, TextInput } from "../../atoms";

export type RuleSectionProps = {
  title: string;
  subtitle: string;
  rules: {
    description: string;
    fields: {
      error?: string;
      onChange: (newValue: any) => void;
      value: any;
      options?: {
        label: string;
        value: any;
      }[];
    }[];
  }[];
};

export const RuleSection: React.FC<RuleSectionProps> = ({
  title,
  subtitle,
  rules,
}) => {
  return (
    <div className="flex flex-col gap-y-5 pb-5">
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-gray-900">{title}</div>
        <div className="text-sm text-gray-600">{subtitle}</div>
      </div>
      {rules.map((e, i) => {
        return (
          <div key={i} className="flex flex-row flex-wrap items-center gap-x-4">
            <div className="text-sm text-gray-600">{e.description}</div>
            <div className="flex flex-row gap-x-2">
              {e.fields.map((e, i) => {
                return (
                  <div key={i} className="flex flex-row">
                    {e.options && (
                      <PaginatedSelect
                        loadOptions={async () => {
                          return {
                            options: e.options!,
                          };
                        }}
                        value={e.value}
                        onChange={(value: { label: string; value: any }) =>
                          e.onChange(value.value)
                        }
                      />
                    )}
                    {!e.options && (
                      <>
                        <TextInput
                          value={e.value}
                          onChange={({ target: { value: newValue } }: any) =>
                            e.onChange(newValue)
                          }
                        />
                        <div className="text-error-600 ml-3.5 mt-2">
                          {e.error}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="flex w-full border-b border-gray-200 pt-5" />
    </div>
  );
};
