import {
  ExpiryType,
  isSessionPackSameCategoryAsEvent,
  PARAMETER_DATE_FORMAT,
  ServiceStatus,
  tzDateTimeStringToUtc,
  useParseErrors,
  useUserFormFieldConfiguration,
} from "@gymflow/common";
import { formatCurrency, pluralize } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import capitalize from "lodash/capitalize";
import PropTypes from "prop-types";
import qs from "qs";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import useProcessSaleCustomer from "../../hooks/useProcessSaleCustomer";
import useSessionPacks from "../../hooks/usePublicSessionPacks";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import BuySessionPackAccordion from "./BuySessionPackAccordion";

const sort = { field: "price,name,id", asc: true };
function BuySessionPack({
  fetchPaymentMethods,
  userMemberId,
  isMiniUser,
  findByEmail,
  cardHoldersName,
  event,
  userDetails,
}) {
  const { api, settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const clubId = settings.clubId;
  const defaultNationality = settings.default_nationality;
  const postCodeFormat = settings.postal_code_country;
  const telephoneFormat = useMemo(
    () => JSON.parse(settings.phone_number_country),
    [settings.phone_number_country],
  );
  const { defaultCurrency: currency, timezone } = useStoreState(settingsStore);
  const { fetchList: fetchSessionPacks, rows: sessionPacks } =
    useSessionPacks();
  const { processExistingMemberCreditPack, processNewMemberCreditPack } =
    useProcessSaleCustomer({
      userMemberId,
      event,
    });
  const parseError = useParseErrors();
  const history = useHistory();
  const search = qs.parse(history?.location?.search, {
    ignoreQueryPrefix: true,
  });
  const [initialCart, setInitialCart] = useState([]);

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const sessionPackMap = ({
    id,
    name,
    description,
    price,
    sessionsIncluded,
    sessionsUnlimited,
    expiryValue,
    expiryType,
    termsConditions,
  }) => ({
    id,
    name,
    description,
    price: formatCurrency(price, currency),
    sessionsIncluded: sessionsUnlimited
      ? `Unlimited Credits`
      : `${sessionsIncluded} ${pluralize(
          "Credit",
          "Credits",
          sessionsIncluded,
        )}`,
    expiry:
      expiryType !== ExpiryType.NA &&
      `${expiryValue} ${capitalize(
        ExpiryType.format(expiryType, expiryValue > 2),
      )} Expiry`,
    termsAndConditionsLink: termsConditions,
  });

  useEffect(() => {
    fetchSessionPacks({
      status: ServiceStatus.Active,
      sort,
      extraParams: { unpaged: true },
    });
  }, []);

  useEffect(() => {
    const processParams = async () => {
      if (search.sessionPackId) {
        const { data } = await api.public.creditPackApi.findById(
          search.sessionPackId,
        );
        setInitialCart([sessionPackMap(data)]);
      }
    };
    processParams();
  }, [search.sessionPackId]);

  const parsedSessionPacks = sessionPacks
    .filter(
      (pack) =>
        !event ||
        (isSessionPackSameCategoryAsEvent(pack, event) &&
          (pack.sessionsUnlimited ||
            pack.sessionsIncluded >= event.event.sessionCost)),
    )
    .sort((a, b) => a.price - b.price)
    .map(sessionPackMap);

  const purchase = async (purchaseValues) => {
    try {
      await processExistingMemberCreditPack(purchaseValues.id, clubId, {
        paymentMethod: purchaseValues.paymentMethod,
        sessionPackId: purchaseValues.cart[0].id,
        promotionCode: purchaseValues.promoCode,
        startDate: tzDateTimeStringToUtc(
          purchaseValues.startDate,
          timezone,
          PARAMETER_DATE_FORMAT,
        ),
      });
    } catch (e) {
      parseError(e.response);
    }
  };

  const purchaseMiniUser = async ({ user, ...purchaseValues }) => {
    delete user.clubId;
    try {
      await processNewMemberCreditPack({
        paymentMethod: purchaseValues.paymentMethod,
        sessionPackId: purchaseValues.cart[0].id,
        promotionCode: purchaseValues.promoCode,
        startDate: tzDateTimeStringToUtc(
          purchaseValues.startDate,
          timezone,
          PARAMETER_DATE_FORMAT,
        ),
        ...user,
      });
    } catch (e) {
      parseError(e.response);
    }
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      <BuySessionPackAccordion
        sessionPacks={parsedSessionPacks}
        currency={currency}
        userMemberId={userMemberId}
        fetchPaymentMethods={() => fetchPaymentMethods({ clubId })}
        fetchLeadSources={async () => {
          const result = await api.public.leadApi.findLeadSources({
            extraParams: { activeSource: true },
          });
          return { data: { last: true, content: result.data } };
        }}
        dateFormat={dateFormat}
        summary={api.public.creditPackApi.summary}
        purchase={isMiniUser ? purchaseMiniUser : purchase}
        defaultNationality={defaultNationality}
        postCodeFormat={postCodeFormat}
        telephoneFormat={telephoneFormat}
        findByEmail={findByEmail}
        cardHoldersName={cardHoldersName}
        onUserSubmit={async ({ values: user, isValid }) => {
          if (!isValid) {
            return;
          }

          if (!isMiniUser) {
            await api.profileApi.update(userMemberId, clubId, user);
          }

          return { ...user, id: userMemberId, clubId, pushCommunication: true };
        }}
        event={event}
        userDetails={userDetails}
        initialCart={initialCart}
        requiredFields={requiredFields}
      />
    </div>
  );
}

BuySessionPack.defaultProps = {
  isMiniUser: false,
  cardHoldersName: "",
  event: null,
};

BuySessionPack.propTypes = {
  fetchPaymentMethods: PropTypes.func.isRequired,
  userMemberId: PropTypes.string.isRequired,
  cardHoldersName: PropTypes.string,
  isMiniUser: PropTypes.bool,
  findByEmail: PropTypes.func.isRequired,
  event: PropTypes.object,
  userDetails: PropTypes.object.isRequired,
};

function BuySessionPackWithProviders(props) {
  const { PublicCreditPackStore } = useGymflowModels();
  return (
    <PublicCreditPackStore.Provider>
      <BuySessionPack {...props} />
    </PublicCreditPackStore.Provider>
  );
}

export default BuySessionPackWithProviders;
