import { useStoreActions, useStoreState } from "easy-peasy";

import useGymflowModels from "../store";

/**
 * @deprecated useProductList instead
 */
const useProducts = () => {
  const { productStore } = useGymflowModels();
  const fetchAvailableList = useStoreActions(
    (actions) => productStore(actions).fetchList,
  );
  const { page, pageCount, editing, rows, loadingList, loadingRecord } =
    useStoreState(productStore);

  return {
    fetchAvailableList,
    page,
    pageCount,
    editing,
    rows,
    loadingRecord,
    loadingList,
  };
};

export default useProducts;
