import { useEffect, useState } from "react";
import { InputGroup, Input, InputGroupAddon, InputGroupText } from "reactstrap";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import styled from "styled-components";
import { isValidHexColor } from "@gymflow/helpers";

/**
 * @deprecated rewrite in tailwind
 */
const InputGroupStyle = styled(InputGroup)`
  &.input-group {
    width: 150px;
    height: 34px;
    border-radius: 30px;
    border: 1px solid;
    border-color: ${(props) => props.value};
  }

  &.input-group .input-group-text {
    background-color: ${(props) => props.value};
    height: 34px;
    width: 34px;
    margin: -1px 0;
    border-radius: 30px;
    border: 0;
  }

  &.input-group input {
    height: 100%;
    border: 0 !important;
  }
`;

function ColorInputGroup({ value, onClick, onChange, disabled }) {
  return (
    <InputGroupStyle value={value} onClick={onClick}>
      <InputGroupAddon addonType="prepend">
        <InputGroupText />
      </InputGroupAddon>
      <Input
        value={value}
        onChange={(v) => onChange(v.target.value)}
        disabled={disabled}
      />
    </InputGroupStyle>
  );
}

function ColorInput({ value, onChange, className, disabled }) {
  const [color, setColor] = useState(value);
  useEffect(() => {
    if (isValidHexColor) {
      setColor(value);
    }
  }, [value]);
  const [displayPicker, setDisplayPicker] = useState(false);

  const handleClick = () => !disabled && setDisplayPicker(!displayPicker);
  const handleClose = () => setDisplayPicker(false);
  const handleChange = (newColor) => onChange(newColor.hex.toUpperCase());
  const styles = {
    popover: {
      position: "absolute",
      zIndex: "2",
      right: 0,
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div className={className}>
      <ColorInputGroup
        value={value}
        onClick={handleClick}
        onChange={onChange}
        disabled={disabled}
      />
      {displayPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      ) : null}
    </div>
  );
}

ColorInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ColorInput;
