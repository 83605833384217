import { cn } from "@gymflow/helpers";
import { cva, type VariantProps } from "class-variance-authority";
import { useMemo } from "react";

const placeholderAvatarVariants = cva(
  "flex h-11 w-11 items-center justify-center rounded border text-xl font-bold ",
  {
    variants: {
      variant: {
        default:
          "dark:bg-darkGray-800 dark:border-darkGray-800 dark:text-darkGray-400 border-gray-300 bg-gray-100 text-gray-500",
        secondary: "border-secondary-300 bg-secondary-100 text-secondary-500",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export function PlaceholderAvatar({
  name,
  className,
  variant,
}: {
  name: string;
  className?: string;
} & VariantProps<typeof placeholderAvatarVariants>) {
  const text = useMemo(() => {
    const firstLetters = name.split(" ").map((word) => {
      return word.substring(0, 1);
    });
    if (firstLetters.length === 1) {
      return firstLetters[0];
    } else if (firstLetters.length > 1) {
      return `${firstLetters[0]}${firstLetters[firstLetters.length - 1]}`;
    }
    return "";
  }, [name]);
  return (
    <div className={cn(placeholderAvatarVariants({ variant }), className)}>
      <div>{text}</div>
    </div>
  );
}
