import { zonedTimeToUtc } from "@gymflow/helpers";
import { SMSPayload, SMSSendingResponse } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export type SendSMSToLeadsFilterArgs = {
  smsPayload: SMSPayload;
  leadIdsToExclude?: number[];
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
  leadStatusIdList?: number[];
  leadSourceIdList?: number[];
};

export const useMutationSendSMSToLeadsFilter = ({
  api,
  tz,
}: {
  api: {
    smsApi: {
      sendSMSToLeadsFilter: (
        args: SendSMSToLeadsFilterArgs,
      ) => Promise<{ data: SMSSendingResponse }>;
    };
  };
  tz: string;
}) => {
  return useMutation({
    mutationFn: async (payload: SendSMSToLeadsFilterArgs) => {
      const parsedOpts = { ...payload };
      if (payload?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(payload.createdFrom, tz);
      }
      if (payload?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(payload.createdTo, tz);
      }

      const { data } = await api.smsApi.sendSMSToLeadsFilter(parsedOpts);
      return data;
    },
  });
};
