import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationAttachAuthorizedPaymentIntent({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      memberId,
      paymentIntentId,
    }: {
      memberId: string;
      paymentIntentId: string;
    }) => {
      await api.memberApi.attachAuthorizedPaymentIntent(memberId, paymentIntentId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
}
