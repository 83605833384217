import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleGroupDelete({
  api,
}: {
  api: {
    ruleApi: {
      ruleGroupDelete: (ruleGroupId: number) => Promise<void>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ ruleGroupId }: { ruleGroupId: number }) =>
      api.ruleApi.ruleGroupDelete(ruleGroupId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
  return mutation;
}
