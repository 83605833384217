import invert from 'lodash/invert';

const ExpiryType = {
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  NA: 'NA',
};

const intervalToExpiryType = {
  weeks: ExpiryType.Weekly,
  months: ExpiryType.Monthly,
  unlimited: ExpiryType.NA,
};

const expiryTypeToInterval = invert(intervalToExpiryType);

Object.setPrototypeOf(ExpiryType, {
  fromInterval: (interval) => intervalToExpiryType[interval],
  toInterval: (expiryType) => expiryTypeToInterval[expiryType],
  format(expiryType, isPlural = false) {
    let text = expiryTypeToInterval[expiryType];
    if (!isPlural && text[text.length - 1] === 's') {
      text = text.substring(0, text.length - 1);
    }

    return text;
  },
});

export default ExpiryType;
