import * as Sentry from "@sentry/browser";
import { InternalAxiosRequestConfig } from "axios";

const deprecatedEndpoints = [
  {
    method: "GET",
    urlEndsWith: "/event/occurrence",
  },
  {
    method: "GET",
    urlEndsWith: "/customer/event/occurrence",
  },
  {
    method: "GET",
    urlEndsWith: "/customer/event/occurrence/agenda",
  },
  {
    method: "GET",
    urlEndsWith: "/hosted/event/occurrence",
  },
  {
    method: "GET",
    urlEndsWith: "/user/member/member-status",
  },
  {
    method: "GET",
    urlEndsWith: "/user/member/member-status/csv",
  },
];

export function axiosRequestInterceptor(
  requestConfig: InternalAxiosRequestConfig<any>,
) {
  if (requestConfig.url?.includes("//")) {
    Sentry.captureMessage(
      `Detected bad URL string on ${requestConfig.url}`,
      "warning",
    );
  }

  for (const deprecated of deprecatedEndpoints) {
    if (
      requestConfig.url?.endsWith(deprecated.urlEndsWith) &&
      requestConfig.method?.toUpperCase() === deprecated.method
    ) {
      Sentry.captureMessage(
        `Detected deprecated endpoint call to ${requestConfig.method} ${requestConfig.url}`,
        "warning",
      );
    }
  }
  return requestConfig;
}
