import { DayOfWeek, EventRecurrenceType } from "@gymflow/types";
import { add, format, isAfter, isBefore, parse } from "date-fns";
import { z } from "zod";

export const DATE_FORMAT = "y-MM-dd";
export const TIME_FORMAT = "HH:mm:ss";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const EventRecurrenceEnum = [
  "DAILY",
  "WEEKLY",
  "MONTHLY",
  "YEARLY",
] as EventRecurrenceType[];

export const DaysOfWeekEnum = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
] as DayOfWeek[];

export const defaultStart = add(new Date(Date.now()), { hours: 1 });
export const defaultEnd = add(new Date(Date.now()), { hours: 1.5 });
export type UnionToIntersection<U> = (
  U extends any ? (k: U) => void : never
) extends (k: infer I) => void
  ? I
  : never;

export type ValidatedEvent = UnionToIntersection<
  ReturnType<typeof validateEvent>
>;
export type NotValidatedEvent = { [key in keyof ValidatedEvent]: any };

const eventValidators = [
  (data: any) =>
    z.object({
      activityId: z.number(),
      startDate: z.string().default(format(defaultStart, DATE_FORMAT)),
      startTime: z.string().default(format(defaultStart, TIME_FORMAT)),
      endDate: z.string().default(format(defaultEnd, DATE_FORMAT)),
      endTime: z
        .string()
        .default(format(defaultEnd, TIME_FORMAT))
        .refine(
          () =>
            isBefore(
              parse(data.startTime, TIME_FORMAT, new Date(Date.now())),
              parse(data.endTime, TIME_FORMAT, new Date(Date.now())),
            ),
          {
            message: "validation.endTimeAfterStartTime",
            path: ["endTime"],
          },
        ),
    }),
  (data: any) =>
    z.object({
      isFullDayEvent: z.boolean().default(false),
      facilityId: z.number(),
      userEventHostId: z.string(),
      isBookable: z.boolean().default(true),
      sessionCost: z
        .number()
        .min(0)
        .max(100)
        .optional()
        .refine(() => !(data.isBookable && data.sessionCost === undefined), {
          message: "validation.sessionCostRequired",
          path: ["sessionCost"],
        }),
      capacity: z
        .number()
        .min(0)
        .max(100)
        .optional()
        .refine(() => !(data.isBookable && data.capacity === undefined), {
          message: "validation.capacityRequired",
          path: ["capacity"],
        }),
      waitListCapacity: z
        .number()
        .min(0)
        .max(100)
        .optional()
        .refine(
          () => !(data.isBookable && data.waitListCapacity === undefined),
          {
            message: "validation.waitingListCapacityRequired",
            path: ["waitListCapacity"],
          },
        ),
    }),
  (data: any) =>
    z.object({
      isRecurring: z.boolean().default(false),
      recurrenceType: z
        .string()
        .optional()
        .refine(
          (e) =>
            e === undefined ||
            EventRecurrenceEnum.includes(e as EventRecurrenceType),
        )
        .refine(() => !(data.isRecurring && !data.recurrenceType), {
          message: "validation.recurrenceTypeRequired",
          path: ["recurrenceType"],
        }),
      endRecurringDate: z
        .string()
        .optional()
        .refine(
          () => {
            if (!data.isRecurring || !data.endRecurringDate) {
              return true;
            }
            return isAfter(
              parse(data.endRecurringDate, DATE_FORMAT, new Date(Date.now())),
              parse(data.startDate, DATE_FORMAT, new Date(Date.now())),
            );
          },
          {
            message: "validation.mustBeLaterThanStartDate",
            path: ["endRecurringDate"],
          },
        ),
      recurrencePeriod: z
        .number()
        .optional()
        .refine(() => !(data.isRecurring && !data.recurrencePeriod), {
          message: "validation.recurringIntervalRequired",
          path: ["recurrencePeriod"],
        })
        .refine(
          () => {
            if (!data.recurrencePeriod) return true;
            if (data.isRecurring) {
              if (data.recurrencePeriod <= 0) {
                return false;
              }
              switch (data.recurrenceType as EventRecurrenceType) {
                case "DAILY":
                  return data.recurrencePeriod <= 7;
                case "WEEKLY":
                  return data.recurrencePeriod <= 4;
                case "MONTHLY":
                  return data.recurrencePeriod <= 12;
                case "YEARLY":
                  return data.recurrencePeriod <= 2;
                default:
                  return false;
              }
            }
            return true;
          },
          {
            message: "validation.invalidRecurringInterval",
            path: ["recurrencePeriod"],
          },
        ),
      weekDays: z
        .array(z.string())
        .optional()
        .default([])
        .refine(
          () => {
            if (
              data.isRecurring &&
              (data.recurrenceType as EventRecurrenceType) === "WEEKLY"
            ) {
              return data.weekDays.length > 0;
            }
            return true;
          },
          {
            message: "validation.mustSelectOne",
            path: ["weekDays"],
          },
        ),
      isPublic: z.boolean().default(true),
    }),
];

const eventValidator = (data: unknown) => {
  return eventValidators[0](data)
    .merge(eventValidators[1](data))
    .merge(eventValidators[2](data));
};
export const validateEvent = (event: unknown) => {
  return eventValidator(event).parse(event);
};

export const validateEventPart = (event: unknown, part: 0 | 1 | 2) => {
  return eventValidators[part](event).parse(event);
};
