import {
  collapseSelection,
  getLeafNode,
  getPluginType,
  insertNodes,
  isCollapsed,
  select,
  unwrapNodes,
  wrapNodes,
} from "@udecode/plate";

const ELEMENT_LINK = "a";
export const wrapLink = (editor: any, { at, url }: any) => {
  wrapNodes(
    editor,
    {
      type: getPluginType(editor, ELEMENT_LINK),
      url,
      children: [],
    },
    { at, split: true },
  );
};
export const upsertLinkAtSelection = (editor: any, { url, wrap }: any) => {
  if (!editor.selection) return;

  const type = getPluginType(editor, ELEMENT_LINK);

  if (!wrap && isCollapsed(editor.selection)) {
    return insertNodes(editor, {
      type,
      url,
      children: [{ text: url }],
    });
  }

  // if our cursor is inside an existing link, but don't have the text selected, select it now
  if (wrap && isCollapsed(editor.selection)) {
    const linkLeaf = getLeafNode(editor, editor.selection);
    const [, inlinePath] = linkLeaf;
    select(editor, inlinePath);
  }

  unwrapNodes(editor, { at: editor.selection, match: { type } });

  wrapLink(editor, { at: editor.selection, url });

  collapseSelection(editor, { edge: "end" });
};
