import { zonedTimeToUtc } from '@gymflow/helpers';
import { useMutation } from '@tanstack/react-query';


export function useEventAttendanceReportCsv({
  api,
  tz
}: {
  api: {
    memberApi:
    {
      eventAttendanceCsv: (params: {
        dateFrom: string,
        dateTo: string,
        gt?: number,
        lt?: number,
        eq?: number,
        membership?: number[],
      }) => Promise<any>,
    }
  },
  startDate?: string,
  endDate?: string,
  gt?: number,
  lt?: number,
  eq?: number,
  membership?: number[],
  tz: string
}) {
  const csvMutation = useMutation({
    mutationFn: async (
      {
        startDate,
        endDate,
        gt,
        lt,
        eq,
        membership,
      }:
        {
          startDate?: string,
          endDate?: string,
          gt?: number,
          lt?: number,
          eq?: number,
          membership?: number[],
        }
    ) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.eventAttendanceCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
        gt,
        lt,
        eq,
        membership,
      });
      return result.data as string;
    }
  });
  return csvMutation;
}
