import endpoints from "./endpoints";
import { canFind, canFindById } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicAppointableApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/hosted/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.appointable}`,
    http: createPublicAxiosInstance({
      apiUrl,
      paramsSerializer: {
        indexes: null,
      },
    }),
  };

  return Object.assign(state, canFind(state), canFindById(state), {
    fetchCategories(params) {
      return state.http.put(
        `${clubPrefix}${endpoints.appointable}/category/search`,
        params,
      );
    },
  });
};

export default publicAppointableApi;
