import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import moment from 'moment-timezone';

import { humanize } from '../../helpers/date';

const getNodeText = (node) => {
  if (['string', 'number'].includes(typeof node)) {
    return node;
  }
  if (node instanceof Array) {
    return node.map(getNodeText).join('');
  }
  if (typeof node === 'object' && node) {
    return getNodeText(node.props.children);
  }
};

function NotificationCard({ message, date, avatar, onClose, onClick }) {
  const style = {};
  if (onClick) {
    style.cursor = 'pointer';
  }
  return (
    <Card className="text-nowrap">
      <CardBody style={style}>
        <Row>
          <Col xs="9" className="d-flex" onClick={onClick}>
            {/* <img */}
            {/*   src={avatar} */}
            {/*   style={{ width: '19px', height: '19px', borderRadius: '15px', marginTop: '1px' }} */}
            {/*   alt="User photo" */}
            {/* /> */}
            <div className="overflow-hidden text-truncate" title={getNodeText(message)}>
              {message}
            </div>
          </Col>
          <Col
            xs="2"
            className="text-muted text-right overflow-hidden text-truncate"
            onClick={onClick}
            title={moment(date).format('LLLL')}
          >
            {humanize(date)}
          </Col>
          <Col xs="1">
            <button
              type="button"
              aria-hidden="true"
              className="close"
              aria-label="Close"
              onClick={onClose}
              style={{ lineHeight: '0.5' }}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

NotificationCard.propTypes = {
  message: PropTypes.node.isRequired,
  date: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotificationCard;
