import { EventContentArg } from "@fullcalendar/core";
import classNames from "classnames";
import { ReactNode } from "react";

import { EventContentAppointment } from "./EventContentAppointment";
import { EventContentOccurrence } from "./EventContentOccurrence";
import {
  CalendarAppointment,
  CalendarAppointmentExtendedProps,
  CalendarEventImpl,
  CalendarOccurrence,
  CalendarOccurrenceExtendedProps,
} from "./types";

export function EventContent({ event, isDragging }: EventContentArg) {
  if (event.display === "background") {
    return <div className="bg-stripes bg-stripes-gray-600 h-100"></div>;
  }
  const calendarEvent = event as CalendarEventImpl<
    CalendarOccurrenceExtendedProps | CalendarAppointmentExtendedProps
  >;

  const FIFTEEN_MINUTES = 15 * 60 * 1000;
  const hasSpaceForAllDetails =
    event.end!.getTime() - event.start!.getTime() > FIFTEEN_MINUTES;

  return (
    <EventContentWrapper isDragging={isDragging}>
      {event.extendedProps["type"] === "OCCURRENCE" && (
        <EventContentOccurrence
          mini={!hasSpaceForAllDetails}
          event={calendarEvent as CalendarEventImpl<CalendarOccurrence>}
        />
      )}
      {event.extendedProps["type"] === "APPOINTMENT" && (
        <EventContentAppointment
          mini={!hasSpaceForAllDetails}
          event={calendarEvent as CalendarEventImpl<CalendarAppointment>}
        />
      )}
    </EventContentWrapper>
  );
}

function EventContentWrapper({
  children,
  isDragging,
}: {
  children: ReactNode;
  isDragging: boolean;
}) {
  return (
    <div
      className={classNames(
        "bg-gray-25 flex h-full w-full cursor-pointer flex-row overflow-hidden rounded border border-gray-300 p-2 hover:bg-gray-100",
        {
          "cursor-pointer": !isDragging,
          "cursor-grabbing": isDragging,
        },
      )}
    >
      <div className="flex w-full flex-col">{children}</div>
    </div>
  );
}
