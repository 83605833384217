import { AppointableDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { appointableQueryKeys } from "./appointableQueryKeys";

export function useAppointable(
  {
    api,
    appointableId,
  }: {
    api: {
      appointableApi: {
        findById: (arg0: number) => Promise<{ data: AppointableDTO }>;
      };
    };
    appointableId: number | null;
  },
  opts?: UseQueryOptions<AppointableDTO | null>,
) {
  const result = useQuery({
    queryKey: appointableQueryKeys.details(appointableId),
    queryFn: async () => {
      const result = await api.appointableApi.findById(appointableId as number);
      return result.data;
    },
    enabled: !!appointableId,
    initialData: null,
    ...opts,
  });

  return result;
}
