import { zonedTimeToUtc } from "@gymflow/helpers";
import { EmailSendingResult } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMutationSendEmailToLeadFilter({
  api,
  tz,
}: {
  api: {
    emailApi: {
      sendEmailToLeadFilter: (args: {
        emailPayload: {
          marketing: boolean;
          body: string;
          subject: string;
          fromEmail: string;
          bccList: string[];
        };
        leadIdsToExclude?: number[];
        createdFrom?: string;
        createdTo?: string;
        smsCommunication?: boolean;
        emailCommunication?: boolean;
        leadStatusIdList?: number[];
        leadSourceIdList?: number[];
      }) => Promise<{ data: EmailSendingResult }>;
    };
  };
  tz: string;
}) {
  return useMutation({
    mutationFn: async (payload: {
      emailPayload: {
        marketing: boolean;
        body: string;
        subject: string;
        fromEmail: string;
        bccList: string[];
      };
      leadIdsToExclude?: number[];
      createdFrom?: string;
      createdTo?: string;
      smsCommunication?: boolean;
      emailCommunication?: boolean;
      leadStatusIdList?: number[];
      leadSourceIdList?: number[];
    }) => {
      const parsedOpts = { ...payload };
      if (payload?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(payload.createdFrom, tz);
      }
      if (payload?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(payload.createdTo, tz);
      }
      const response = await api.emailApi.sendEmailToLeadFilter(parsedOpts);
      return response.data;
    },
  });
}
