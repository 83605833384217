import { useQueryClient, useMutation } from '@tanstack/react-query';

import { membershipQueryKeys } from './membershipQueryKeys';

export function useMembershipDisable({
  api,
}: {
  api: {
    serviceApi: {
      deactivate: (membershipId: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (membershipId: number) => {
      await api.serviceApi.deactivate(membershipId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });

  return mutation;
}
