export function SwitchHorizontalIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={pathClassName}
          d="M3.33331 14.1667H16.6666M16.6666 14.1667L13.3333 10.8333M16.6666 14.1667L13.3333 17.5M16.6666 5.83333H3.33331M3.33331 5.83333L6.66665 2.5M3.33331 5.83333L6.66665 9.16667"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
