import { UserMemberFormMapper } from "@gymflow/common";
import { Iso2Country, Iso3Country } from "@gymflow/types";

import { createUserMemberFormSchema } from "./MembershipDetailsSchema";

const createRecordFormDefaultParams = ({
  dateFormat,
  postalCodeCountry,
  defaultNationality,
  requiredFields,
}: {
  dateFormat: string;
  postalCodeCountry: Iso2Country;
  defaultNationality: Iso3Country;
  requiredFields: string[];
}) => ({
  record: null,
  fields: (
    createUserMemberFormSchema({
      postalCodeCountry,
      defaultNationality,
      dateFormat,
      requiredFields,
    }) as any
  ).default(),
  mapper: new UserMemberFormMapper({
    dateFormat,
  }),
});

export default createRecordFormDefaultParams;
