import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";

import DateRangePicker from "../components/forms/DateRangePicker";
import { RevenueSummaryChart, UserSummaryChart } from "../components/organisms";
import { ReportContext } from "../components/Reports/ReportContext";
import { DynamicReportWidgetCard } from "../components/ReportWidgetCard/DynamicReportWidgetCard";
import { UpcomingTasks } from "../components/UpcomingTasks/UpcomingTasks";
import { useDashboardDynamicWidgets } from "../hooks/useDashboardDynamicWidgets";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { useClubSettings } from "../providers";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";

export function Dashboard() {
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const longDateFormat = settings.long_date_format;

  const history = useHistory();

  const location = useLocation();
  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);

  const setDates = useCallback(
    ({ startDate, endDate }: { startDate: string; endDate: string }) => {
      history.push({
        search: qs.stringify({
          dates: { startDate, endDate },
        }),
      });
    },
    [],
  );

  const defaultDates = useMemo(
    () => ({
      startDate: moment().startOf("day").format(DATE_FORMAT),
      endDate: moment().endOf("day").format(DATE_FORMAT),
    }),
    [],
  );

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        const { startDate, endDate } = newDate;
        setDates({
          startDate: moment(startDate, dateFormat).format(DATE_FORMAT),
          endDate: moment(endDate, dateFormat).endOf("day").format(DATE_FORMAT),
        });
      }
    },
    [setDates, dateFormat],
  );

  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, [dates, defaultDates, setDates]);

  return (
    <div className="bg-primary-50 flex h-full w-full flex-col overflow-y-auto overflow-x-hidden p-8">
      <div>
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex flex-col">
            <div className="text-lg font-semibold text-gray-900">
              Club Performance
            </div>
            <div className="text-sm font-normal text-gray-600">
              Your club&apos;s KPIs for success.
            </div>
          </div>
          <DateRangePicker
            displayDateFormat={longDateFormat}
            onChange={handleDateRangeChange}
            value={
              dates
                ? {
                    startDate: moment(dates.startDate, DATE_FORMAT).format(
                      dateFormat,
                    ),
                    endDate: moment(dates.endDate, DATE_FORMAT).format(
                      dateFormat,
                    ),
                  }
                : null
            }
            dateFormat={dateFormat}
            onSelectionChange={undefined}
            placeholder={undefined}
          />
        </div>
        <div className="mx-[-.75rem] flex flex-wrap">
          <DynamicWidgetsBar
            dates={{
              startDate: dates?.startDate,
              endDate: dates?.endDate,
            }}
          />
        </div>
      </div>
      <div className="mt-8">
        <UserSummaryChart />
      </div>
      <div className="-mx-4 flex flex-row flex-wrap pb-12 ">
        <div className="mt-8 flex w-full flex-col px-4 xl:w-1/2">
          <RevenueSummaryChart />
        </div>
        <UpcomingTasks />
      </div>
    </div>
  );
}

function DynamicWidgetsBar({
  dates,
}: {
  dates: { startDate?: string; endDate?: string };
}) {
  const { api } = useGymflowModels();
  const { createClubLink } = usePortalRoutes();

  const settings = useClubSettings();

  const { widgets, setWidgets, fields, sections } =
    useDashboardDynamicWidgets();

  const { isFetching, data } = useStatistic({
    api: api,
    start:
      dates?.startDate &&
      moment(dates.startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end:
      dates?.endDate &&
      moment(dates.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    categories: sections,
    revenueFields: fields.revenueFields,
    membershipFields: fields.membershipFields,
    eventFields: fields.eventFields,
    leadFields: fields.leadFields,
    visitFields: fields.visitFields,
    clubId: settings.clubId,
    trendPeriod: "MONTH",
  });

  return (
    <ReportContext.Provider
      value={{
        isLoading: isFetching,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        revenue: data?.revenue && {
          total: data.revenue?.total && {
            total: data.revenue.total.total!,
            variation: data.revenue.total.var,
          },
          membership: data.revenue?.membership && {
            total: data.revenue.membership.total!,
            variation: data.revenue.membership.var,
          },
          sessionPack: data.revenue?.sessionPack && {
            total: data.revenue.sessionPack.total!,
            variation: data.revenue.sessionPack.var,
          },
          product: data.revenue?.product && {
            total: data.revenue.product.total!,
            variation: data.revenue.product.var,
          },
          creditsApplied: data.revenue?.credits && {
            total: data.revenue.credits.total!,
            variation: data.revenue.credits.var,
          },
          writeOff: data.revenue?.writeOff && {
            total: data.revenue.writeOff.total!,
            variation: data.revenue.writeOff.var,
          },
          refunds: data.revenue?.refunds && {
            total: data.revenue.refunds.total!,
            variation: data.revenue.refunds.var,
          },
          awaitingAuth: data.revenue?.awaitingAuth && {
            total: data.revenue.awaitingAuth.total!,
            variation: data.revenue.awaitingAuth.var,
          },
        },
        members: data?.membership && {
          membershipsSold: data.membership?.signUps && {
            total: data.membership.signUps.total!,
            variation: data.membership.signUps.var,
          },
          ending: data.membership?.ending && {
            total: data.membership.ending.total!,
            variation: data.membership.ending.var,
          },
          growth: data.membership?.growth && {
            total: data.membership.growth.total!,
            variation: data.membership.growth.var,
          },
          total: data.membership?.total && {
            total: data.membership.total.total!,
            variation: data.membership.total.var,
          },
          active: data.membership?.active && {
            total: data.membership.active.total!,
            variation: data.membership.active.var,
          },
          overdue: data.membership?.overdue && {
            total: data.membership.overdue.total!,
            variation: data.membership.overdue.var,
          },
          paused: data.membership?.paused && {
            total: data.membership.paused.total!,
            variation: data.membership.paused.var,
          },
          pending: data.membership?.pending && {
            total: data.membership.pending.total!,
            variation: data.membership.pending.var,
          },
          pausing: data.membership?.pausing && {
            total: data.membership.pausing!.total!,
            variation: data.membership.pausing!.var,
          },
          resuming: data.membership?.resuming && {
            total: data.membership.resuming!.total!,
            variation: data.membership.resuming!.var,
          },
        },
        leads: data?.lead && {
          created: data.lead?.created && {
            total: data.lead.created.total!,
            variation: data.lead.created.var,
          },
          opened: data.lead?.opened && {
            total: data.lead.opened!.total!,
            variation: data.lead.opened!.var,
          },
          closed: data.lead?.closed && {
            total: data.lead.closed.total!,
            variation: data.lead.closed.var,
          },
          lost: data.lead?.lost && {
            total: data.lead.lost.total!,
            variation: data.lead.lost.var,
          },
          closeRatio: data.lead?.closeRatio && {
            total: data.lead.closeRatio.total!,
            variation: data.lead.closeRatio.var,
          },
        },
        events: data?.event && {
          attendance: data.event?.attendance && {
            total: data.event.attendance.total!,
            variation: data.event.attendance.var,
          },
          bookingPercentage: data.event?.bookingPercentage && {
            total: data.event.bookingPercentage.total!,
            variation: data.event.bookingPercentage.var,
          },
          classesRun: data.event?.classesRun && {
            total: data.event.classesRun.total!,
            variation: data.event.classesRun.var,
          },
          noShows: data.event?.noShows && {
            total: data.event.noShows.total!,
            variation: data.event.noShows.var,
          },
          lateCancellations: data.event?.lateCancellations && {
            total: data.event.lateCancellations.total!,
            variation: data.event.lateCancellations.var,
          },
        },
        visit: data?.visit && {
          total: data.visit?.total && { total: data.visit.total!.total! },
          noVisits: data.visit?.noVisits && {
            total: data.visit.noVisits!.total,
          },
        },
      }}
    >
      {widgets?.map((e, i) => {
        return (
          <DynamicReportWidgetCard
            key={i}
            initialCard={e}
            onCardChange={(newCard) => {
              const newWidgets = [...widgets];
              newWidgets[i] = newCard;
              setWidgets(newWidgets);
            }}
            parentRoute={createClubLink(
              RouteLayout.Staff,
              RouteFeature.Dashboard,
            )}
          />
        );
      })}
    </ReportContext.Provider>
  );
}
