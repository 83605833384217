// TODO: cleanup WEBSITE_SETTINGS_REDESIGN
/**
 * @deprecated use helpers/copyToClipboard
 */
function useClipboard() {
  const copyField = (fieldName: string) => {
    const range = document.createRange();
    const copyDiv = document.getElementsByName(fieldName)[0];
    range.selectNode(copyDiv);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);

    document.execCommand("copy");
  };

  return { copyField };
}

export default useClipboard;
