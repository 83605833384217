export function PlusCircleIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathClassName}
        d="M9.99996 7.16675V13.8334M6.66663 10.5001H13.3333M18.3333 10.5001C18.3333 15.1025 14.6023 18.8334 9.99996 18.8334C5.39759 18.8334 1.66663 15.1025 1.66663 10.5001C1.66663 5.89771 5.39759 2.16675 9.99996 2.16675C14.6023 2.16675 18.3333 5.89771 18.3333 10.5001Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
