import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { LeadReportDTO, LeadReportItemDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseLeadReportProps {
  api: {
    leadApi: {
      report: (params: {
        startDate: string;
        endDate: string;
        presetLeadStatus: string;
        leadSourceList?: number[];
        page?: number;
        limit?: number;
        sort: any;
      }) => Promise<any>;
    };
  };
  presetLeadStatus: string;
  leadSourceList?: number[];
  startDate?: string;
  endDate?: string;
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}

export function useLeadReport({
  api,
  startDate,
  endDate,
  presetLeadStatus,
  leadSourceList,
  page,
  limit,
  sort,
  tz,
}: UseLeadReportProps) {
  const result = useQuery({
    queryKey: [
      reportQueryKeys.lead({
        startDate,
        endDate,
        presetLeadStatus,
        leadSourceList,
        page,
        limit,
        sort,
      }),
    ],
    queryFn: async () => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = api.leadApi.report({
        startDate: utcStart,
        endDate: utcEnd,
        presetLeadStatus,
        leadSourceList,
        page,
        limit,
        sort,
      });
      return (await result).data as LeadReportDTO;
    },
    placeholderData: () => {
      return {
        leadReportByPresetStatusViewDTO: defaultPage<LeadReportItemDTO>(),
        leadReportTotal: 0,
      };
    },
    select: (data) => {
      const dto = merge({}, data.leadReportByPresetStatusViewDTO, {
        content: data.leadReportByPresetStatusViewDTO.content.map(
          ({ createdDate, ...rest }) => {
            return {
              createdDate: createdDate && utcToZonedTime(createdDate, tz),
              ...rest,
            };
          },
        ),
      });
      return {
        ...data,
        leadReportByPresetStatusViewDTO: dto,
      };
    },
    enabled: !!startDate && !!endDate && !!tz,
  });
  return result;
}
