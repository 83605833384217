import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMutationMemberUpdateEmailAndPasswordAsMember({
  api,
}: {
  api: {
    profileApi: {
      updateEmailAndPassword: (payload: {
        email?: string;
        password?: string;
      }) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      payload,
    }: {
      payload: { email?: string; password?: string };
    }) => {
      await api.profileApi.updateEmailAndPassword(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.all(),
      });
    },
  });
}
