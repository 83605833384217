import { Iso3Country } from "@gymflow/types";
import {
  cy,
  enAU,
  enCA,
  enGB,
  enIE,
  enNZ,
  enUS,
  es,
  fr,
  nb,
  nl,
} from "date-fns/locale";
import { z } from "zod";

export let currentLocale = enGB;

export const iso3CountryCodeToLocale: { [key in Iso3Country]: Locale } = {
  USA: enUS,
  GBR: enGB,
  GIB: es,
  AUS: enAU,
  NOR: nb,
  NZL: enNZ,
  ESP: es,
  CAN: enCA,
  IRL: enIE,
  CYP: cy,
  FRA: fr,
  NLD: nl,
};

export const setValidationLocale = (locale: Locale) => {
  currentLocale = locale;
};

if (navigator?.product === "ReactNative") {
  z.setErrorMap((issue, ctx) => {
    const type: string = (issue as any)?.type;
    if (issue.code === "invalid_type" && type === undefined) {
      return {
        message: "validation.required",
      };
    }
    if (zodLocale?.[type]?.[issue.code])
      return {
        message: zodLocale[type][issue.code],
      };
    if ((issue as any).validation === "email")
      return {
        message: zodLocale["string"]["email"],
      };
    return { message: ctx.defaultError };
  });
}

export const zodLocale: {
  [key: string]: { [key: string]: string };
} = {
  string: {
    email: "validation.email",
    too_small: "validation.minCharacters",
    too_big: "validation.maxCharacters",
  },
  date: {
    too_small: "validation.dateTooSmall",
    too_big: "validation.dateTooBig",
  },
};
export * from "./en";
export * from "./es";
export * from "./fr";
export * from "./nl";
export * from "./pt";
