import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { AvailabilityCalculationResult } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityAsMemberQueryKeys } from "./availabilityAsMemberQueryKeys";

export function useQueryFacilityAvailabilityCalculateAsMember(
  {
    api,
    appointableId,
    dateFrom,
    dateTo,
    appointableHostsIdList,
    appointableFacilityIdList,
    tz,
  }: {
    api: {
      customerAvailabilityApi: {
        facilityCalculateAvailability: (filter: {
          appointableId: number;
          dateFrom: string;
          dateTo: string;
          appointableHostsIdList?: string[];
          appointableFacilityIdList: number[];
        }) => Promise<{ data: AvailabilityCalculationResult[] }>;
      };
    };
    tz?: string;
    appointableId?: number;
    dateFrom?: string;
    dateTo?: string;
    appointableHostsIdList?: string[];
    appointableFacilityIdList?: number[];
  },
  opts?: UseQueryOptions<AvailabilityCalculationResult[] | undefined | null>,
) {
  const result = useQuery({
    queryKey: availabilityAsMemberQueryKeys.facilityCalculateAvailability({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
      appointableFacilityIdList,
    }),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(dateFrom as string, tz as string);
      const utcDateTo = zonedTimeToUtc(dateTo as string, tz as string);

      const result =
        await api.customerAvailabilityApi.facilityCalculateAvailability({
          appointableId: appointableId as number,
          dateFrom: utcDateFrom,
          dateTo: utcDateTo,
          appointableHostsIdList,
          appointableFacilityIdList: appointableFacilityIdList as number[],
        });
      return result.data;
    },
    select: (data) =>
      facilityAvailabilityCalculateAsMemberSelectFn({ data, tz: tz as string }),
    enabled: !!tz && !!appointableId && !!dateFrom && !!dateTo,
    initialData: null,
    ...opts,
  });

  return result;
}

export function facilityAvailabilityCalculateAsMemberSelectFn({
  data,
  tz,
}: {
  data?: AvailabilityCalculationResult[] | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return [...data].map((availabilityLine) => {
    return {
      ...availabilityLine,
      startTime: utcToZonedTime(availabilityLine.startTime, tz),
      endTime: utcToZonedTime(availabilityLine.endTime, tz),
    };
  });
}
