import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { cn } from "../../helpers/cn";

interface LinkButtonProps {
  className?: string;
  to: string | (() => string);
  children: ReactNode;
}

export function LinkButton({ children, className, to }: LinkButtonProps) {
  return (
    <Link
      to={to}
      className={cn("text-primary-600 hover:!text-primary-600", className)}
    >
      {children}
    </Link>
  );
}
