import { ActivityDTO, ActivityStatus, ApiListResponse } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsMemberQueryKeys } from "./activityAsMemberQueryKeys";

export async function activityListAsMemberQueryFn({
  filter,
  api,
}: {
  filter?: ActivityListAsMemberFilter;
  api: ActivityListAsMemberApi;
}) {
  const result = await api.customerActivityApi.find({ ...filter });
  return result.data as ApiListResponse<ActivityDTO>;
}

export function useActivityListAsMember({
  api,
  opts,
}: {
  api: ActivityListAsMemberApi;
  opts?: ActivityListAsMemberFilter;
}) {
  const result = useQuery({
    queryKey: activityAsMemberQueryKeys.list(opts),
    queryFn: () => activityListAsMemberQueryFn({ api, filter: opts }),
    initialData: defaultPage<ActivityDTO>,
  });
  return result;
}

interface ActivityListAsMemberApi {
  customerActivityApi: {
    find: (arg0: { [k: string]: any }) => Promise<any>;
  };
}

interface ActivityListAsMemberFilter {
  readonly extraParams?: {
    readonly status?: ActivityStatus;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
