import {
  appointableListQueryFn,
  appointableQueryKeys,
  membershipListQueryFn,
  membershipQueryKeys,
  productListQueryFn,
  productQueryKeys,
  sessionPackListQueryFn,
  sessionPackQueryKeys,
} from "@gymflow/api";
import {
  AppointableDTO,
  Membership,
  ProductDTO,
  SessionPackDTO,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import useGymflowModels from "../../../store";

export function useAllRestrictableItems(
  {
    staleTime,
    selectedProductIdList,
    selectedMembershipIdList,
    selectedCreditPackIdList,
    selectedAppointableIdList,
  }: {
    staleTime?: number;
    selectedProductIdList: number[];
    selectedMembershipIdList: number[];
    selectedCreditPackIdList: number[];
    selectedAppointableIdList: number[];
  } = {
    staleTime: 60000,
    selectedProductIdList: [],
    selectedMembershipIdList: [],
    selectedCreditPackIdList: [],
    selectedAppointableIdList: [],
  },
) {
  const { api } = useGymflowModels();

  const { data: products, isFetching: isFetchingProducts } = useQuery({
    queryKey: [...productQueryKeys.all(), "all"],
    queryFn: async () => {
      let pages: ProductDTO[][] = [];
      let pageResult;
      do {
        pageResult = await productListQueryFn({
          api,
          filter: { page: pages.length, extraParams: { status: "AVAILABLE" } },
        });
        pages.push(pageResult.content);
      } while (!pageResult.last);
      return pages.flatMap((p) => p);
    },
    staleTime,
  });

  const { data: memberships, isFetching: isFetchingMemberships } = useQuery({
    queryKey: [...membershipQueryKeys.all(), "all"],
    queryFn: async () => {
      let pages: Membership[][] = [];
      let pageResult;
      do {
        pageResult = await membershipListQueryFn({
          api,
          filter: { page: pages.length, extraParams: { status: "ACTIVE" } },
        });
        pages.push(pageResult.content);
      } while (!pageResult.last);
      return pages.flatMap((p) => p);
    },
    staleTime,
  });

  const { data: sessionPacks, isFetching: isFetchingSessionPacks } = useQuery({
    queryKey: [...sessionPackQueryKeys.all(), "all"],
    queryFn: async () => {
      let pages: SessionPackDTO[][] = [];
      let pageResult;
      do {
        pageResult = await sessionPackListQueryFn({
          api,
          filter: { page: pages.length, extraParams: { status: "ACTIVE" } },
        });
        pages.push(pageResult.content);
      } while (!pageResult.last);
      return pages.flatMap((p) => p);
    },
    staleTime,
  });

  const { data: appointables, isFetching: isFetchingAppointables } = useQuery({
    queryKey: [...appointableQueryKeys.all(), "all"],
    queryFn: async () => {
      let pages: AppointableDTO[][] = [];
      let pageResult;
      do {
        pageResult = await appointableListQueryFn({
          api,
          filter: { page: pages.length, extraParams: { status: "ACTIVE" } },
        });
        pages.push(pageResult.content);
      } while (!pageResult.last);
      return pages.flatMap((p) => p);
    },
    staleTime,
  });

  const itemOptions = useMemo(() => {
    let results: {
      value: {
        id: number;
        type: "MEMBERSHIP" | "CREDIT_PACK" | "PRODUCT" | "APPOINTABLE";
      };
      label: string;
    }[] = [];
    if (products) {
      results = results.concat(
        products
          .filter((product) => !selectedProductIdList.includes(product.id))
          .map((product) => ({
            label: product.name,
            value: { id: product.id, type: "PRODUCT" },
          })),
      );
    }

    if (memberships) {
      results = results.concat(
        memberships
          .filter(
            (membership) => !selectedMembershipIdList.includes(membership.id),
          )
          .map((membership) => ({
            label: membership.name,
            value: { id: membership.id, type: "MEMBERSHIP" },
          })),
      );
    }

    if (sessionPacks) {
      results = results.concat(
        sessionPacks
          .filter(
            (sessionPack) => !selectedCreditPackIdList.includes(sessionPack.id),
          )
          .map((sessionPack) => ({
            label: sessionPack.name,
            value: { id: sessionPack.id, type: "CREDIT_PACK" },
          })),
      );
    }

    if (appointables) {
      results = results.concat(
        appointables
          .filter(
            (appointable) =>
              !selectedAppointableIdList.includes(appointable.id),
          )
          .map((appointable) => ({
            label: appointable.name,
            value: { id: appointable.id, type: "APPOINTABLE" },
          })),
      );
    }

    return results;
  }, [
    products,
    memberships,
    sessionPacks,
    appointables,
    selectedProductIdList,
    selectedMembershipIdList,
    selectedCreditPackIdList,
    selectedAppointableIdList,
  ]);

  return {
    itemOptions,
    isLoading:
      isFetchingMemberships ||
      isFetchingProducts ||
      isFetchingSessionPacks ||
      isFetchingAppointables,
  };
}
