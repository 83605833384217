import { useEffect, useState } from 'react';
import useGymflowModels from '../store';
import useInjectCss from './useInjectCss';

const useStyle = (clubId, isStaff) => {
  const { PublicHostedClubStore } = useGymflowModels();
  const { editing: customStyles } = PublicHostedClubStore.useStoreState((state) => state);
  const { fetchById } = PublicHostedClubStore.useStoreActions((actions) => actions);
  const [customStyle, setCustomStyle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const inject = useInjectCss();

  useEffect(() => {
    if (!isStaff) {
      fetchById(clubId)
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [isStaff, fetchById, clubId, setIsLoading]);

  useEffect(() => {
    if (customStyles) {
      const cleanup = inject(customStyles.settings);
      setCustomStyle(customStyles.settings);
      return cleanup;
    }
  }, [customStyles, inject, setCustomStyle]);

  return { customStyle, isLoadingStyle: isLoading };
};

export default useStyle;
