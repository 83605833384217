import { useMutation, useQueryClient } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export function useFacilityDisable({
  api,
}: {
  api: {
    facilityApi: {
      deactivate: (arg0: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (facilityId: number) => api.facilityApi.deactivate(facilityId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: facilityQueryKeys.all() });
    },
  });

  return mutation;
}
