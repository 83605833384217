import { assert } from "check-types";

import endpoints from "./endpoints";

const automationEndpoints = {
  find: `${endpoints.automation}`,
  updateBCC: `${endpoints.automation}/:id/bcc`,
  active: `${endpoints.automation}/:id/active`,
  inactive: `${endpoints.automation}/:id/inactive`,
  placeholders: `${endpoints.automation}/placeholders/:placeholderType`,
  placeholderLabels: `${endpoints.automation}/placeholder-labels/:placeholderType`,
};

const automationApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.automation}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    find() {
      return state.http.get(`${clubPrefix}${automationEndpoints.find}`);
    },
    updateBCC(id, bccList) {
      assert.number(id, "id must be a number");

      const url = `${clubPrefix}${automationEndpoints.updateBCC}`.replace(
        ":id",
        id,
      );
      return state.http.patch(url, { bccList });
    },
    active(id) {
      assert.number(id, "id must be a number");

      const url = `${clubPrefix}${automationEndpoints.active}`.replace(
        ":id",
        id,
      );
      return state.http.patch(url);
    },
    inactive(id) {
      assert.number(id, "id must be a number");
      const url = `${clubPrefix}${automationEndpoints.inactive}`.replace(
        ":id",
        id,
      );

      return state.http.patch(url);
    },
    /** @deprecated */
    placeholders(placeholderType = "GENERIC") {
      assert.maybe.string(placeholderType, "placeholderType must be a string");
      const url = `${clubPrefix}${automationEndpoints.placeholders}`.replace(
        ":placeholderType",
        placeholderType,
      );

      return state.http.get(url);
    },
    placeholderLabels(placeholderType = "GENERIC") {
      assert.maybe.string(placeholderType, "placeholderType must be a string");
      const url =
        `${clubPrefix}${automationEndpoints.placeholderLabels}`.replace(
          ":placeholderType",
          placeholderType,
        );

      return state.http.get(url);
    },
  });
};

export default automationApi;
