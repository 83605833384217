import { cn } from "@gymflow/helpers";
import { MonthlyDayPosition } from "@gymflow/types";
import { ReactNode } from "react";

import { PaginatedSelect } from "../../atoms";
import { WeekDay } from "./WeekdaySelector";

export type MonthlyRecurringType = "DAY_OF_THE_EVENT" | "SPECIFIC_DAY";

interface MonthlyRadioValue {
  option: MonthlyRecurringType;
  specificDay?: {
    position: MonthlyDayPosition;
    weekday: WeekDay;
  };
}

export function MonthlyRadio({
  value,
  onChange,
}: {
  value: MonthlyRadioValue;
  onChange: (newValue: MonthlyRadioValue) => void;
}) {
  const positionOptions = [
    { label: "First", value: "FIRST" },
    { label: "Second", value: "SECOND" },
    { label: "Third", value: "THIRD" },
    { label: "Fourth", value: "FOURTH" },
    { label: "Last", value: "LAST" },
  ];
  const weekdayOptions = [
    { label: "Monday", value: "MONDAY" },
    { label: "Tuesday", value: "TUESDAY" },
    { label: "Wednesday", value: "WEDNESDAY" },
    { label: "Thursday", value: "THURSDAY" },
    { label: "Friday", value: "FRIDAY" },
    { label: "Saturday", value: "SATURDAY" },
    { label: "Sunday", value: "SUNDAY" },
  ];

  return (
    <div className="flex flex-col gap-2">
      <MonthlyRadioOption
        onClick={() => {
          onChange({
            ...value,
            specificDay: undefined,
            option: "DAY_OF_THE_EVENT",
          });
        }}
        isSelected={value.option === "DAY_OF_THE_EVENT"}
      >
        On the date of the event
      </MonthlyRadioOption>
      <MonthlyRadioOption
        onClick={() => {
          onChange({
            specificDay: value.specificDay
              ? value.specificDay
              : { position: "FIRST", weekday: "MONDAY" },
            option: "SPECIFIC_DAY",
          });
        }}
        isSelected={value.option === "SPECIFIC_DAY"}
      >
        <div className="flex gap-3" onClick={(e) => e.stopPropagation()}>
          <PaginatedSelect
            className="w-11"
            isDisabled={value.option === "DAY_OF_THE_EVENT"}
            value={
              value?.specificDay?.position
                ? positionOptions.find(
                    (po) => value?.specificDay?.position === po.value,
                  )
                : positionOptions[0]
            }
            loadOptions={() => Promise.resolve({ options: positionOptions })}
            onChange={(newValue) => {
              onChange({
                option: "SPECIFIC_DAY",
                specificDay: {
                  weekday: value.specificDay!.weekday,
                  position: newValue.value,
                },
              });
            }}
          />
          <PaginatedSelect
            className="min-w-0 flex-1"
            isDisabled={value.option === "DAY_OF_THE_EVENT"}
            value={
              value?.specificDay?.weekday
                ? weekdayOptions.find(
                    (wo) => value?.specificDay?.weekday === wo.value,
                  )
                : weekdayOptions[0]
            }
            loadOptions={() => Promise.resolve({ options: weekdayOptions })}
            onChange={(newValue) => {
              onChange({
                option: "SPECIFIC_DAY",
                specificDay: {
                  position: value.specificDay!.position,
                  weekday: newValue.value,
                },
              });
            }}
          />
        </div>
      </MonthlyRadioOption>
    </div>
  );
}

function MonthlyRadioOption({
  onClick,
  children,
  isSelected,
}: {
  onClick: () => void;
  children: ReactNode;
  isSelected: boolean;
}) {
  return (
    <div
      onClick={onClick}
      className={cn(
        "flex items-center gap-3 rounded-[0.625rem] border border-gray-200 p-4",
        {
          "border-primary-600": isSelected,
        },
      )}
    >
      <div>
        <input
          className="accent-primary-600"
          type="radio"
          checked={isSelected}
          onChange={(e) => {
            e.stopPropagation();
            onClick();
          }}
        />
      </div>
      <div className="flex-1">{children}</div>
    </div>
  );
}
