import {
  ActivityCategoryDTO,
  ActivityCategoryStatus,
  ApiListResponse,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsMemberQueryKeys } from "./activityAsMemberQueryKeys";

export async function activityCategoryListAsMemberQueryFn({
  api,
  filter,
}: {
  api: ActivityCategoryListAsMemberApi;
  filter: ActivityCategoryListAsMemberFilter;
}) {
  const result = await api.customerActivityApi.findCategories({
    ...filter,
    status: filter?.statusList,
  });
  return result.data;
}

export function useQueryActivityCategoryListAsMember({
  api,
  filter,
}: {
  api: ActivityCategoryListAsMemberApi;
  filter: ActivityCategoryListAsMemberFilter;
}) {
  const result = useQuery({
    queryKey: activityAsMemberQueryKeys.categories(filter),
    queryFn: () => activityCategoryListAsMemberQueryFn({ api, filter }),
    initialData: defaultPage<ActivityCategoryDTO>,
  });
  return result;
}

export interface ActivityCategoryListAsMemberFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly statusList?: ActivityCategoryStatus[];
}

interface ActivityCategoryListAsMemberApi {
  customerActivityApi: {
    findCategories: (filter: {
      page?: number;
      limit?: number;
      status?: ActivityCategoryStatus[];
    }) => Promise<{ data: ApiListResponse<ActivityCategoryDTO> }>;
  };
}
