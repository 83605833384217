import { ApiListResponse, UserStaffBean } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { staffQueryKeys } from "./staffQueryKeys";

export async function staffListQueryFn({
  api,
  opts,
}: {
  api: StaffListApi;
  opts?: StaffListFilter;
}) {
  const result = await api.staffApi.find({
    ...opts,
  });
  return result.data as ApiListResponse<UserStaffBean>;
}

export function useStaffList(
  {
    api,
    opts,
  }: {
    api: StaffListApi;
    opts?: StaffListFilter;
  },
  queryOpts?: UseQueryOptions<ApiListResponse<UserStaffBean>>,
) {
  const result = useQuery({
    queryKey: staffQueryKeys.list(opts),
    queryFn: () => {
      return staffListQueryFn({ api, opts });
    },
    initialData: defaultPage<UserStaffBean>,
    ...queryOpts,
  });
  return result;
}

interface StaffListFilter {
  readonly extraParams?: {
    readonly activeUser?: boolean;
    readonly firstName?: string;
    readonly availableForAppointments?: boolean;
    readonly appointableList?: string;
    readonly id?: string[];
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc: boolean;
  };
}

interface StaffListApi {
  staffApi: { find: (arg0: { [k: string]: any }) => Promise<any> };
}
