import { QueryKey } from "@tanstack/react-query";

export const availabilityAsMemberQueryKeys = {
  all: () => ["availabilityAsMember"] as QueryKey,
  hostCalculateAvailability: (
    filter: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      dateTo?: string;
      appointableHostsIdList?: string[];
    }>,
  ) =>
    [
      ...availabilityAsMemberQueryKeys.all(),
      "host",
      "calculate",
      filter,
    ] as QueryKey,
  facilityCalculateAvailability: (
    filter?: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      dateTo?: string;
      appointableHostsIdList?: string[];
      appointableFacilityIdList?: number[];
    }>,
  ) => {
    if (filter) {
      return [
        ...availabilityAsMemberQueryKeys.all(),
        "facility",
        "calculate",
        filter,
      ] as QueryKey;
    }

    return [
      ...availabilityAsMemberQueryKeys.all(),
      "facility",
      "calculate",
    ] as QueryKey;
  },
};
