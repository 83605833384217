import { AlertContext, AsyncButton, CardBodyWithSpinner } from "@gymflow/common";
import merge from "lodash/merge";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import ReactTable from "react-table";
import { Button, Card, CardHeader, CardTitle, CustomInput } from "reactstrap";

import useGymflowModels from "../store";
import styles from "./ResourceTableCard.module.css";

const MIN_ROW = 5;

export function ResourceTableCard({
  addBtnText,
  onAddClick,
  deleteButtons,
  editButtons,
  mapToRow,
  renderButtons,
  columns,
  children,
  title,
  statusField,
  enabledValue,
  showDisableCheckbox,
  useQueryHook,
  onEditClick,
  selectedId,
  findParams,
}) {
  const alert = useContext(AlertContext);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState(null);
  const [disableCheck, setDisableCheck] = useState(false);
  const { api } = useGymflowModels();

  const createOpts = () => {
    let options = {
      page,
      limit,
      sort: sort?.[0],
      ...findParams,
    };
    if (sort?.[0]) {
      options.sort = {
        field: sort[0].id,
        desc: sort[0].desc,
      };
    }
    if (showDisableCheckbox && !disableCheck) {
      options = merge({}, options, {
        extraParams: {
          [statusField]: enabledValue,
        },
      });
    }
    return options;
  };
  const {
    data: { content, totalPages, totalElements },
    isFetching,
  } = useQueryHook({ api, opts: createOpts() });

  const renderEditButton = (id, disabled) => (
    <AsyncButton
      className="btn-link"
      data-testid="btn-edit"
      disabled={disabled}
      onClick={() => onEditClick(id)}
      size="sm"
    >
      Edit
    </AsyncButton>
  );

  const onDeleteClick = (id) => {
    const message = deleteButtons.alertMessage || "";
    const title = deleteButtons.alertTitle || "Are you sure you want to delete it?";
    const confirmButtonText = deleteButtons.alertConfirmText || "Yes, delete it!";

    alert.showWarning(message, {
      title,
      onConfirm: async () => {
        await deleteButtons.callback(id);
        await alert.hide();
      },
      confirmBtnText: confirmButtonText,
      confirmBtnBsStyle: "danger",
    });
  };

  const renderDeleteButton = (id, disabled) => (
    <Button className="btn-link" disabled={disabled} onClick={() => onDeleteClick(id)} size="sm">
      {deleteButtons.tooltip || "Delete"}
    </Button>
  );

  const renderActions = (resource, beingEdited) => (
    <div>
      {editButtons ? renderEditButton(resource.id, beingEdited) : null}
      {deleteButtons ? renderDeleteButton(resource.id, beingEdited) : null}
      {renderButtons({ resource, beingEdited, alert })}
    </div>
  );
  const rows = content.map((r) => {
    const beingEdited = !!selectedId && selectedId === r.id;
    const mappedRow = mapToRow(r);

    mappedRow.actions = renderActions(r, beingEdited);

    return mappedRow;
  });

  const getTrProps = (_, row) => (row && row.original.id === selectedId ? { className: styles["selected-row"] } : {});

  const table = (
    <ReactTable
      className="-highlight"
      columns={columns}
      data={rows}
      defaultPageSize={limit}
      minRows={MIN_ROW}
      getTrProps={getTrProps}
      manual
      pages={totalPages}
      page={page}
      onPageChange={(newPage) => setPage(newPage)}
      onPageSizeChange={(newLimit) => setLimit(newLimit)}
      onSortedChange={(newSort) => setSort(newSort)}
      showPagination={totalElements > MIN_ROW}
    />
  );

  return (
    <div className="relative flex h-full max-h-full w-full flex-col">
      <Card className="absolute inset-0 overflow-y-auto">
        {title && (
          <CardHeader>
            <CardTitle className="d-flex justify-content-between">
              <div className="flex w-full flex-row justify-between">
                <h2 className="mb-sm-2 mb-1">{title}</h2>
                {showDisableCheckbox && (
                  <div className="flex items-center">
                    <h2 className="mb-1">Show disabled:</h2>
                    <CustomInput
                      id="disable-checkbox"
                      type="checkbox"
                      checked={disableCheck}
                      onChange={(e) => setDisableCheck(e.target.checked)}
                    />
                  </div>
                )}
              </div>
              {onAddClick && (
                <Button className="min-w-fit" color="primary" size="sm" onClick={onAddClick}>
                  {addBtnText}
                </Button>
              )}
            </CardTitle>
          </CardHeader>
        )}
        <CardBodyWithSpinner isLoading={isFetching} className="normal-table">
          {typeof children === "function" ? children(table) : table}
        </CardBodyWithSpinner>
      </Card>
    </div>
  );
}

ResourceTableCard.defaultProps = {
  addBtnText: "Add",
  editButtons: false,
  enabledValue: "ACTIVE",
  mapToRow: (r) => r,
  renderButtons: noop,
  showDisableCheckbox: false,
  statusField: "status",
  title: null,
  findParams: {},
};

ResourceTableCard.propTypes = {
  columns: PropTypes.array.isRequired,
  mapToRow: PropTypes.func,
  renderButtons: PropTypes.func,
  editButtons: PropTypes.bool,
  showDisableCheckbox: PropTypes.bool,
  enabledValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  statusField: PropTypes.string,
  deleteButtons: PropTypes.shape({
    tooltip: PropTypes.string,
    alertTitle: PropTypes.string,
    alertMessage: PropTypes.string,
    alertConfirmText: PropTypes.string,
    callback: PropTypes.func.isRequired,
  }),
  title: PropTypes.string,
  addBtnText: PropTypes.string,
  onAddClick: PropTypes.func,
};
