import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { RecurringAppointmentPutDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "../availability";
import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useMutationRecurringAppointmentEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      recurringAppointmentId,
      patchedFields,
    }: {
      recurringAppointmentId: number;
      patchedFields: RecurringAppointmentPutDTO;
    }) => {
      const response = await api.appointmentApi.updateRecurring(
        recurringAppointmentId,
        patchedFields,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailability(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(),
      });
    },
  });

  return mutation;
}
