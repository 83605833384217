// @ts-ignore
import { PARAMETER_TIME_FORMAT } from "@gymflow/common";
import { DATE_ONLY_FORMAT } from "@gymflow/helpers";
import { DateTime } from "luxon";

const INVALID_RESULT = { deadlineDate: undefined, deadlineTime: undefined };

export function deadlineDatesToUtc(
  {
    deadlineDate,
    deadlineTime,
  }: { deadlineDate?: string; deadlineTime?: string },
  timezone: string,
) {
  const timePart = deadlineTime
    ? DateTime.fromFormat(deadlineTime, PARAMETER_TIME_FORMAT)
    : undefined;

  if (timePart && !timePart.isValid) {
    return INVALID_RESULT;
  }

  const datePart = deadlineDate
    ? DateTime.fromFormat(deadlineDate, DATE_ONLY_FORMAT)
    : undefined;

  if (datePart && !datePart.isValid) {
    return INVALID_RESULT;
  }

  const combinedDateTime =
    datePart && timePart
      ? datePart.set({
          hour: timePart.hour,
          minute: timePart.minute,
        })
      : undefined;

  if (!combinedDateTime || !combinedDateTime.isValid) {
    return INVALID_RESULT;
  }

  const dateTimezoneUTC = combinedDateTime
    .setZone(timezone, { keepLocalTime: true })
    .toUTC();

  return {
    deadlineDate: dateTimezoneUTC.toFormat(DATE_ONLY_FORMAT),
    deadlineTime: dateTimezoneUTC.toFormat(PARAMETER_TIME_FORMAT),
  };
}
