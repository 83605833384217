/* eslint-disable react/jsx-props-no-spreading */
import { useUserFormFieldConfiguration } from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import BuySessionPackAccordion from "./BuySessionPackAccordion";

function BuySessionPack() {
  const settings = useClubSettings();
  const { api, settingsStore } = useGymflowModels();
  const { defaultCurrency: currency, timezone } = useStoreState(settingsStore);
  const dateFormat = settings.date_format;
  const history = useHistory();

  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId: settings.clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  const match = useRouteMatch();
  return (
    <div className="track-height mx-5">
      <Switch>
        <Route path={`${match.path}/:id`}>
          {({ match: innerMatch }) => {
            const sessionPackId = innerMatch.params.id;
            history.push({
              pathname: match.path,
              search: `?sessionPackId=${sessionPackId}`,
            });

            return null;
          }}
        </Route>
        <Route>
          <BuySessionPackAccordion
            currency={currency}
            fetchLeadSources={async () => {
              const { data: result } = await api.public.leadApi.findLeadSources(
                {
                  extraParams: { active: true },
                },
              );
              return { data: { content: result, last: true } };
            }}
            dateFormat={dateFormat}
            summary={api.public.creditPackApi.summary}
            timezone={timezone}
            requiredFields={requiredFields}
          />
        </Route>
      </Switch>
    </div>
  );
}

function BuySessionPackWithProvider() {
  const { CreditPackStore, EventStore } = useGymflowModels();
  return (
    <CreditPackStore.Provider>
      <EventStore.Provider>
        <BuySessionPack />
      </EventStore.Provider>
    </CreditPackStore.Provider>
  );
}

export { BuySessionPackWithProvider as BuySessionPack };
