import { thunk, thunkOn } from 'easy-peasy';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';

class StaffModelBuilder extends BaseModelBuilder {
  constructor(apiKey, authKey = 'auth') {
    super(apiKey);
    this.authKey = authKey;
    this.generators.push(this.pictureGenerator);
    this.generators.push(this.staffGenerator);
    this.withActiveInactive();
  }

  /**
   * @private
   */
  pictureGenerator() {
    return {
      postPicture: thunk((_, { id, image }, { injections, getState }) => {
        return getApi(injections, getState()).updatePicture(id, image);
      }),
    };
  }

  /**
   * @private
   */
  staffGenerator() {
    return {
      create: thunk(
        /**
         * @param actions
         * @param fields
         * @returns {Promise<*>}
         */
        async (actions, fields, { injections, getState }) => {
          const newFields = { ...fields };
          if (newFields.picture) {
            delete newFields.picture;
          }
          const result = await getApi(injections, getState()).create(newFields);
          if (fields.picture && fields.picture.blob) {
            await actions.postPicture({ id: result.data.id, image: fields.picture });
          }
          return Promise.resolve();
        }
      ),

      update: thunk(
        /**
         * @param actions
         * @param id
         * @param patchedFields
         * @returns {Promise<*>}
         */
        async (actions, { id, patchedFields }, { injections, getState }) => {
          const newFields = { ...patchedFields };
          if (newFields.picture && newFields.picture.blob) {
            await actions.postPicture({ id: getState().editing.id, image: newFields.picture });
          }
          if (newFields.picture) {
            delete newFields.picture;
          }
          if (Object.keys(newFields).length) {
            return getApi(injections, getState()).update(id, newFields);
          }
          return Promise.resolve();
        }
      ),

      onUpdate: thunkOn(
        (actions) => actions.update,
        (_, { payload }, { getStoreState, getStoreActions }) => {
          if (payload.id === getStoreState()[this.authKey].id) {
            return getStoreActions()[this.authKey].updateUserDetails();
          }
          return Promise.resolve();
        }
      ),

      fetchList: thunk(async (actions, { page = 0, limit = 10, extraParams, sort }, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).find({
          page,
          limit,
          sort,
          extraParams,
        });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList({ ...payload, sort, filter: extraParams });
        return payload;
      }),
    };
  }
}

export default StaffModelBuilder;
