export function RefreshIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={pathClassName}
          d="M9.91669 11.0102C11.1541 10.1086 11.9584 8.64825 11.9584 7.00002C11.9584 4.2616 9.73843 2.04168 7.00002 2.04168H6.70835M7.00002 11.9584C4.26161 11.9584 2.04169 9.73843 2.04169 7.00002C2.04169 5.35179 2.84591 3.89139 4.08335 2.98983M6.41669 13.0667L7.58335 11.9L6.41669 10.7333M7.58335 3.26668L6.41669 2.10002L7.58335 0.93335"
          stroke="#667085"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
