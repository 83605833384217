import {
  ClubBean,
  KisiMembershipDTO,
  KisiMembershipDTOResult,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { kisiQueryKeys } from "./kisiQueryKeys";

/**
 * @deprecated replaced by useQueryKisiGroupMappings
 * */
export function useQueryKisiMemberships(
  {
    api,
    clubId,
  }: {
    api: {
      kisiApi: { fetchMappings: (clubId: number) => Promise<any> };
      clubApi: { findById: (clubId: number) => Promise<{ data: ClubBean }> };
    };
    clubId: number;
  },
  queryOpts?: UseQueryOptions<KisiMembershipDTO>,
) {
  const result = useQuery({
    queryKey: kisiQueryKeys.details(),
    queryFn: async () => {
      const result = await api.kisiApi.fetchMappings(clubId);
      return result.data as KisiMembershipDTO;
    },
    enabled: !!clubId,
    initialData: defaultPage<KisiMembershipDTOResult>,
    ...queryOpts,
  });
  return result;
}
