import { Route, Switch } from "react-router-dom";

import { RedirectBase } from "../../components/RedirectBase";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { RouteFeature, RouteFeatureSite } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";

export function LinkLayout({ match }) {
  const { createClubLink } = usePortalRoutes();

  return (
    <>
      <Switch>
        <Route path={match.path + RouteFeature.LinkBuyMembership}>
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Site,
              RouteFeature.SiteBuyMembership,
            )}`}
          />
        </Route>
        <Route path={match.path + RouteFeature.LinkBuySessionPack}>
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Site,
              RouteFeature.SiteBuySessionPack,
            )}`}
          />
        </Route>
        <Route path={match.path + RouteFeature.LinkGuestRegistration}>
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Site,
              RouteFeature.SiteGuestRegistration,
            )}`}
          />
        </Route>
        <Route path={match.path + RouteFeature.LinkEnquiry}>
          <RedirectBase
            to={`${createClubLink(RouteLayout.Site, RouteFeature.SiteEnquiry)}`}
          />
        </Route>
        <Route path={match.path + RouteFeature.LinkTimetable}>
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Site,
              RouteFeature.SiteTimetable,
            )}`}
          />
        </Route>
        <Route path={match.path + RouteFeatureSite.LinkAppointments}>
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Site,
              RouteFeatureSite.Appointments,
            )}`}
          />
        </Route>

        <Route
          path={match.path + RouteLayout.Embed + RouteFeature.LinkBuyMembership}
        >
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Embed,
              RouteFeature.SiteBuyMembership,
            )}`}
          />
        </Route>
        <Route
          path={
            match.path + RouteLayout.Embed + RouteFeature.LinkBuySessionPack
          }
        >
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Embed,
              RouteFeature.SiteBuySessionPack,
            )}`}
          />
        </Route>
        <Route
          path={
            match.path + RouteLayout.Embed + RouteFeature.LinkGuestRegistration
          }
        >
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Embed,
              RouteFeature.SiteGuestRegistration,
            )}`}
          />
        </Route>
        <Route path={match.path + RouteLayout.Embed + RouteFeature.LinkEnquiry}>
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Embed,
              RouteFeature.SiteEnquiry,
            )}`}
          />
        </Route>
        <Route
          path={match.path + RouteLayout.Embed + RouteFeature.LinkTimetable}
        >
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Embed,
              RouteFeature.SiteTimetable,
            )}`}
          />
        </Route>
        <Route
          path={
            match.path + RouteLayout.Embed + RouteFeatureSite.LinkAppointments
          }
        >
          <RedirectBase
            to={`${createClubLink(
              RouteLayout.Embed,
              RouteFeatureSite.LinkAppointments,
            )}`}
          />
        </Route>
      </Switch>
      Link doesn&apos;t exist.
    </>
  );
}
