import PropTypes from 'prop-types';
import { Badge, Button, Col, Media, Row } from 'reactstrap';
import { UserStatus } from '@gymflow/common';
import classNames from 'classnames';
import placeholder from '../../../assets/img/placeholder.jpg';

const badgeColors = {
  [UserStatus.Member]: '#1d2842',
  [UserStatus.Customer]: '#31436e',
  [UserStatus.Lead]: '#6186db',
};

function UserResult({ name, email, userType, picture, onEditClick }) {
  return (
    <Row className="d-flex justify-content-between w-100">
      <Col>
        <Media className="align-items-center">
          <Media
            object
            src={picture}
            className={classNames('media-object')}
            style={{
              borderRadius: '16px',
              height: '27px',
              width: '27px',
            }}
          />
          <div className="ml-2 d-inline-block">
            <Media>{name}</Media>
            <i className={classNames('text-muted', { 'd-none': !email })}>{email}</i>
          </div>
          &nbsp;&nbsp;
          <Badge
            style={{
              backgroundColor: badgeColors[userType],
              color: 'white',
            }}
          >
            {userType}
          </Badge>
        </Media>
      </Col>
      <Col xs="1" className={classNames({ 'd-none': !onEditClick })}>
        <Button
          className="btn-link"
          onClick={(e) => {
            e.stopPropagation();
            onEditClick();
          }}
        >
          Edit
        </Button>
      </Col>
    </Row>
  );
}

UserResult.defaultProps = {
  picture: placeholder,
  onEditClick: null,
  email: null,
};

UserResult.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string,
  picture: PropTypes.string,
  userType: PropTypes.oneOf(Object.values(UserStatus)).isRequired,
  onEditClick: PropTypes.func,
};

export default UserResult;
