import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { ClassesRunDTO, ClassesRunItemDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { Sort } from "../Sort";
import { reportQueryKeys } from "./reportQueryKeys";

export function useClassesRunReport({
  api,
  startDate,
  endDate,
  activityId,
  eventHostId,
  page,
  limit,
  sort,
  tz,
}: {
  api: ApiType;
  startDate?: string;
  endDate?: string;
  activityId?: number[];
  eventHostId?: string[];
  page?: number;
  limit?: number;
  sort?: Sort[];
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.classesRun({
      startDate,
      endDate,
      activityId,
      eventHostId,
      page,
      limit,
      sort,
      tz,
    }),
    queryFn: async () => {
      const utcStart = startDate && zonedTimeToUtc(startDate as string, tz);
      const utcEnd = endDate && zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.classesRun({
        startDate: utcStart,
        endDate: utcEnd,
        activityId,
        eventHostId,
        page,
        limit,
        sort,
      });
      return result.data as ClassesRunDTO;
    },
    placeholderData: () => {
      return {
        classesRunReportDTO: defaultPage<ClassesRunItemDTO>(),
        classesRunTotal: 0,
      };
    },
    select: (data: ClassesRunDTO) => {
      const dto = merge({}, data.classesRunReportDTO, {
        content: data.classesRunReportDTO.content.map(
          ({ startTime, ...rest }) => {
            return {
              startTime: startTime && utcToZonedTime(startTime, tz),
              ...rest,
            };
          },
        ),
      });
      return {
        ...data,
        classesRunReportDTO: dto,
      };
    },
  });
  return result;
}
