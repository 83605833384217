import { SettingsSection } from "../../UserMember/SettingsSection";
import { ClubForm } from "./ClubForm";
import { CompanyForm } from "./CompanyForm";

export function ClubInfo() {
  return (
    <div className="-mx-4 flex max-h-full flex-col overflow-y-auto px-4 lg:-mx-8 lg:px-8">
      <div className="relative flex h-full max-h-full w-full flex-col">
        <div className="-mt-4 flex flex-col pb-48">
          {[
            {
              title: (
                <div className="flex flex-col">
                  <div className="text-base font-semibold">
                    Club Information
                  </div>
                  <div className="text-sm text-gray-400">
                    Update your club’s information.
                  </div>
                </div>
              ),
              content: <ClubForm />,
            },
            {
              title: (
                <div className="flex flex-col">
                  <div className="text-base font-semibold">
                    Company Information
                  </div>
                  <div className="text-sm text-gray-400">
                    Update company information shown on invoices.
                  </div>
                </div>
              ),
              content: <CompanyForm />,
            },
          ].map((e, i) => {
            return (
              <SettingsSection key={i} title={e.title} content={e.content} />
            );
          })}
        </div>
      </div>
    </div>
  );
}
