import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";
import { usePortalRoutes } from "./../../../../../src/hooks/usePortalRoutes";
import { RouteFeature } from "./../../../../../src/routes/feature";
import { RouteLayout } from "./../../../../../src/routes/layout";

export const ReportMembersEndingWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const data = useContext(ReportContext);
  const history = useHistory();

  return (
    <ReportWidgetCard
      title="Memberships Ending"
      value={data.members?.ending?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Sum of all recurring and pre-paid memberships that have either a cancellation date or expiry date within the period selected."
      variation={data.members?.ending?.variation}
      positiveIsBad
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.MembershipEnding,
          ),
          search: QueryString.stringify({
            startDate: data.startDate,
            endDate: data.endDate,
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
