export function ComputerIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathClassName}
        d="M5.04804 13C5.9379 12.5732 6.94014 12.3333 8.00016 12.3333C9.06018 12.3333 10.0624 12.5732 10.9523 13M4.5335 10.3333H11.4668C12.5869 10.3333 13.147 10.3333 13.5748 10.1153C13.9511 9.9236 14.2571 9.61764 14.4488 9.24131C14.6668 8.81349 14.6668 8.25344 14.6668 7.13333V4.2C14.6668 3.0799 14.6668 2.51984 14.4488 2.09202C14.2571 1.71569 13.9511 1.40973 13.5748 1.21799C13.147 1 12.5869 1 11.4668 1H4.5335C3.41339 1 2.85334 1 2.42552 1.21799C2.04919 1.40973 1.74323 1.71569 1.55148 2.09202C1.3335 2.51984 1.3335 3.0799 1.3335 4.2V7.13333C1.3335 8.25344 1.3335 8.81349 1.55148 9.24131C1.74323 9.61764 2.04919 9.9236 2.42552 10.1153C2.85334 10.3333 3.41339 10.3333 4.5335 10.3333Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
