export type SortIconProps = {
  pathClassName?: string;
};

export const SortIcon = ({ pathClassName }: SortIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        className={pathClassName}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.1665 3.16663C1.61422 3.16663 1.1665 3.61434 1.1665 4.16663C1.1665 4.71891 1.61422 5.16663 2.1665 5.16663H12.1665C12.7188 5.16663 13.1665 4.71891 13.1665 4.16663C13.1665 3.61434 12.7188 3.16663 12.1665 3.16663H2.1665ZM2.1665 8.16663C1.61422 8.16663 1.1665 8.61434 1.1665 9.16663C1.1665 9.71891 1.61422 10.1666 2.1665 10.1666H8.83317C9.38546 10.1666 9.83317 9.71891 9.83317 9.16663C9.83317 8.61434 9.38546 8.16663 8.83317 8.16663H2.1665ZM1.1665 14.1666C1.1665 13.6143 1.61422 13.1666 2.1665 13.1666H6.33317C6.88546 13.1666 7.33317 13.6143 7.33317 14.1666C7.33317 14.7189 6.88546 15.1666 6.33317 15.1666H2.1665C1.61422 15.1666 1.1665 14.7189 1.1665 14.1666ZM16.3332 6.66663C16.3332 6.20639 15.9601 5.83329 15.4998 5.83329C15.0396 5.83329 14.6665 6.20639 14.6665 6.66663V13.8214L12.7558 11.9107C12.4303 11.5853 11.9027 11.5853 11.5772 11.9107C11.2518 12.2361 11.2518 12.7638 11.5772 13.0892L14.9106 16.4225C15.236 16.748 15.7637 16.748 16.0891 16.4225L19.4224 13.0892C19.7479 12.7638 19.7479 12.2361 19.4224 11.9107C19.097 11.5853 18.5694 11.5853 18.2439 11.9107L16.3332 13.8214V6.66663Z"
        fill="black"
      />
    </svg>
  );
};
