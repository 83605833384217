import { FormMapper } from "@gymflow/common";
import {
  FacilityDTO,
  RecurringAppointmentDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import pick from "lodash/pick";
import moment from "moment";

import {
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
} from "./AppointmentSchema";

export class RescheduleRecurringFormMapper extends FormMapper {
  initialHost?: UserStaffPublicDTO;
  initialFacilityHost?: FacilityDTO;

  constructor({
    initialHost,
    initialFacilityHost,
  }: {
    initialHost?: UserStaffPublicDTO;
    initialFacilityHost?: FacilityDTO;
  }) {
    super({
      inValue: [
        {
          key: "appointmentTime",
          transform: (v: [number, number]) => {
            return moment(`${v[0]}:${v[1]}`, "H:m").format("HH:mm");
          },
        },
      ],
      outValue: [
        {
          key: APPOINTMENT_HOST_ID,
          transform: (value: any) => value?.value,
        },
        {
          key: APPOINTMENT_FACILITY_ID,
          transform: (value: any) => value?.value,
        },
      ],
    });
    this.initialHost = initialHost;
    this.initialFacilityHost = initialFacilityHost;
  }

  override to(values: RecurringAppointmentDTO) {
    const parsed: Record<string, any> = super.to(values);

    const hostId = parsed["appointment-host-id"];
    if (hostId && this.initialHost) {
      parsed["appointment-host-id"] = {
        value: this.initialHost.id,
        label: `${this.initialHost.firstName} ${this.initialHost.lastName}`,
      };
    }

    const facilityId = parsed["appointment-facility-id"];
    if (facilityId && this.initialFacilityHost) {
      parsed["appointment-facility-id"] = {
        value: this.initialFacilityHost.id,
        label: this.initialFacilityHost.name,
      };
    }

    parsed["recurrence-monthly-type"] = "DAY_OF_THE_EVENT";
    if (parsed["monthly-day-position"]) {
      parsed["recurrence-monthly-type"] = "SPECIFIC_DAY";
    }

    if (parsed["end-date"]) {
      parsed["end-date"] = moment(parsed["end-date"], "YYYY-MM-DD").format(
        "DD/MM/YYYY",
      );
    }

    return pick(parsed, [
      "appointment-host-id",
      "appointment-facility-id",
      "appointment-time",
      "recurring-type",
      "recurring-interval",
      "start-date",
      "monthly-day-of-month",
      "monthly-day-of-week",
      "monthly-day-position",
      "weekly-days",
      "end-date",
      "recurrence-monthly-type",
    ]);
  }

  override from(values: Record<string, any>) {
    const parsed: any = super.from(values);
    if (parsed.availabilityType === "STAFF") {
      delete parsed["appointmentFacilityId"];
    } else if (parsed.availabilityType === "FACILITY") {
      delete parsed["appointmentHostId"];
    }

    if (parsed["recurringType"] === "DAILY") {
      delete parsed["weeklyDays"];
      delete parsed["monthlyDayOfMonth"];
      delete parsed["monthlyDayPosition"];
      delete parsed["monthlyDayOfWeek"];
    } else if (parsed["recurringType"] === "WEEKLY") {
      delete parsed["monthlyDayOfMonth"];
      delete parsed["monthlyDayPosition"];
      delete parsed["monthlyDayOfWeek"];
    } else if (parsed["recurringType"] === "MONTHLY") {
      delete parsed["weeklyDays"];
      if (parsed["recurrenceMonthlyType"] === "DAY_OF_THE_EVENT") {
        delete parsed["monthlyDayPosition"];
        delete parsed["monthlyDayOfWeek"];
        parsed["monthlyDayOfMonth"] = moment(
          parsed["startDate"],
          "YYYY-MM-DD",
        ).date();
      } else if (parsed["recurrenceMonthlyType"] === "SPECIFIC_DAY") {
        delete parsed["monthlyDayOfMonth"];
      }
    }
    if (parsed["endDate"] === "") {
      delete parsed["endDate"];
    } else {
      parsed["endDate"] = moment(parsed["endDate"], "DD/MM/YYYY").format(
        "YYYY-MM-DD",
      );
    }

    delete parsed["recurrenceMonthlyType"];
    return parsed;
  }
}
