import { QueryKey } from "@tanstack/react-query";

export const calendarQueryKeys = {
  all: () => ["calendar"] as QueryKey,
  eventOccurrences: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarQueryKeys.all(),
        "eventOccurrences",
        { filters },
      ] as QueryKey;
    } else {
      return [...calendarQueryKeys.all(), "eventOccurrences"] as QueryKey;
    }
  },
  appointments: (filters?: Readonly<{ [k: string]: any }>) => {
    if (filters) {
      return [
        ...calendarQueryKeys.all(),
        "appointments",
        { filters },
      ] as QueryKey;
    } else {
      return [...calendarQueryKeys.all(), "appointments"] as QueryKey;
    }
  },
};
