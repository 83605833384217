import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useFormik } from 'formik';
import noop from 'lodash/noop';
import * as Yup from 'yup';
import memoizeOne from 'memoize-one';
import { AsyncButton, CardBodyWithSpinner, FormikInput, RequiredFieldsLabel, UserFormField, formikHelpers, useRecordForm, UserProfileType, FormMapper } from '@gymflow/common';

import useGymflowModels from '../../store';
import UserSelect from './UserSelect';

const { EMAIL, FIRST_NAME, LAST_NAME } = UserFormField;

const createSchema = ({ validEmails, api }) => {
  const findByEmail = memoizeOne(api.memberApi.findByEmail.bind(api.memberApi));
  return Yup.object().shape({
    [EMAIL]: Yup.string()
      .email()
      .required()
      .test(async function(value) {
        if (validEmails.includes(value)) {
          return true;
        }

        const { createError } = this;

        if (!value) {
          return false;
        }

        try {
          await Yup.reach(Yup.object().shape({ email: Yup.string().email() }), 'email').validate(value);
        } catch {
          return false;
        }

        const data = await findByEmail(value);
        return data ? createError({ message: 'Email already exists.' }) : true;
      }),
    [FIRST_NAME]: Yup.string().trim().required().min(2),
    [LAST_NAME]: Yup.string().trim().required().min(2),
  });
};

function ProductPurchaserDetails({ searchUsers, onUserSelectClick, initialValues }) {
  const { api } = useGymflowModels();
  const [showForm, setShowForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const isNew = !selectedUser?.id;

  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = useMemo(
    () =>
      createSchema({
        api,
        validEmails: selectedUser ? [selectedUser.email] : [],
      }),
    [selectedUser]
  );

  const { initialValues: formInitialValues, getValues } = useRecordForm({
    record: null,
    fields: validationSchema.default(),
    mapper: new FormMapper(),
  });
  const formikProps = useFormik({
    initialValues: formInitialValues,
    validationSchema,
    onSubmit: noop,
  });

  const { errorClass } = formikHelpers(formikProps);

  const openFormAndFillWithUser = () => {
    setShowForm(true);
  };

  const openFormAndReset = () => {
    setShowForm(true);
    setSelectedUser(null);
  };

  useEffect(() => {
    if (!selectedUser) {
      formikProps.resetForm();
      return;
    }
    formikProps.setValues({
      [EMAIL]: selectedUser.email,
      [FIRST_NAME]: selectedUser.firstName,
      [LAST_NAME]: selectedUser.lastName,
    });
  }, [selectedUser]);

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    setSelectedUser(initialValues);
    openFormAndFillWithUser();
  }, [initialValues]);

  return (
    <Form className="form-horizontal" role="form">
      <CardBodyWithSpinner isLoading={isLoading}>
        <Row>
          <Col sm="7" md="8" lg="9">
            <UserSelect
              value={selectedUser}
              search={searchUsers}
              onChange={({ value }) => {
                setSelectedUser(value);
                const needsFurtherDetails = value?.profileType === UserProfileType.Lead;
                if (needsFurtherDetails) {
                  openFormAndFillWithUser();
                } else {
                  onUserSelectClick({ user: value, wasEdited: false });
                }
              }}
              onUserEditClick={({ value }) => {
                setSelectedUser(value);
                openFormAndFillWithUser(value);
              }}
              beforeUserChange={() => setIsLoading(true)}
              afterUserChange={() => setIsLoading(false)}
            />
          </Col>
          <Col sm="1" className="font-weight-bold d-flex justify-content-center align-items-center">
            <div className="mt-2 mt-sm-0 mb-2 mb-sm-0">or</div>
          </Col>
          <Col sm="4" md="3" lg="2" className="text-center text-sm-left">
            <Button
              className="font-weight-bold"
              color="primary"
              size="sm"
              style={{ minWidth: '140px' }}
              onClick={openFormAndReset}
            >
              Create New Account
            </Button>
          </Col>
        </Row>
        <Row className={classNames('mt-3', { 'd-none': !showForm })}>
          <Col>
            <Row>
              <Col xs="12">
                <Label htmlFor={EMAIL} className="text-uppercase">
                  Email *
                </Label>
              </Col>
              <Col xs="12">
                <FormGroup className={errorClass(EMAIL)}>
                  <FormikInput
                    name={EMAIL}
                    type="text"
                    data-testid={EMAIL}
                    autoComplete="off"
                    maxLength="128"
                    formikProps={formikProps}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col xs="12" sm="6">
                <Label htmlFor={FIRST_NAME} className="text-uppercase">
                  First Name *
                </Label>
                <FormGroup className={errorClass(FIRST_NAME)}>
                  <FormikInput
                    name={FIRST_NAME}
                    id={FIRST_NAME}
                    type="text"
                    maxLength="128"
                    formikProps={formikProps}
                  />
                </FormGroup>
              </Col>

              <Col xs="12" sm="6">
                <Label htmlFor={LAST_NAME} className="text-uppercase">
                  Last Name *
                </Label>
                <FormGroup className={errorClass(LAST_NAME)}>
                  <FormikInput name={LAST_NAME} id={LAST_NAME} type="text" maxLength="128" formikProps={formikProps} />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <RequiredFieldsLabel />
              </Col>
            </Row>

            <Row>
              <Col>
                <AsyncButton
                  className="font-weight-bold"
                  color="primary"
                  size="sm"
                  style={{ width: '140px' }}
                  onClick={async () => {
                    setIsLoading(true);

                    await formikProps.submitForm();
                    const errors = await formikProps.validateForm();
                    if (Object.keys(errors).length > 0) {
                      setIsLoading(false);
                      return;
                    }

                    await onUserSelectClick({
                      user: {
                        ...getValues(formikProps.values),
                        profileType: selectedUser?.profileType,
                        id: selectedUser?.id,
                      },
                      wasEdited: true,
                    });
                    setIsLoading(false);
                  }}
                >
                  {isNew ? 'Create Account' : 'Next'}
                </AsyncButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBodyWithSpinner>
    </Form>
  );
}

ProductPurchaserDetails.defaultProps = {
  initialValues: null,
};

ProductPurchaserDetails.propTypes = {
  searchUsers: PropTypes.func.isRequired,
  onUserSelectClick: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default ProductPurchaserDetails;
