import { useMutation, useQueryClient } from "@tanstack/react-query";

import { clubAsPublicQueryKeys } from "../clubAsPublic";
import { clubQueryKeys } from "./clubQueryKeys";

export function useMutationClubEditSettings({
  api,
}: {
  api: {
    hostedClubApi: {
      update: (
        id: number,
        patchedFields: { fields: any; logo?: any },
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      clubId,
      patchedFields,
    }: {
      clubId: number;
      patchedFields: { fields: any; logo?: any };
    }) =>
      api.hostedClubApi.update(clubId, {
        fields: patchedFields.fields,
        logo: patchedFields.logo,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: clubAsPublicQueryKeys.all() });
    },
  });
  return mutation;
}
