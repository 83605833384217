import useGymflowModels from "../store";

/**
 * @deprecated
 */
const useLeadStatusLoadOptions = () => {
  const { api } = useGymflowModels();

  return async (_, __, { includeAll = false, disabledStatuses = [], excludePreset = [] } = {}) => {
    const results = await api.leadApi.findStatus();
    const options = results.data
      .filter((e) => !excludePreset.includes(e.presetType))
      .map(({ id, name }) => {
        return { value: { id, name }, label: name, isDisabled: disabledStatuses.includes(name) };
      });

    if (includeAll) {
      options.unshift({
        label: "All",
        value: null,
      });
    }

    return {
      options,
      hasMore: false,
    };
  };
};

export default useLeadStatusLoadOptions;
