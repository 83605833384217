import { QueryKey } from "@tanstack/react-query";

export const kisiQueryKeys = {
  all: () => ["kisi"] as QueryKey,
  details: () => [...kisiQueryKeys.all(), "details"] as QueryKey,
  groupMappings: (clubId: number) =>
    [...kisiQueryKeys.all(), clubId, "group-mappings"] as QueryKey,
  places: () => [...kisiQueryKeys.all(), "places"] as QueryKey,
  groups: () => [...kisiQueryKeys.all(), "groups"] as QueryKey,
  apiKeyExists: (clubId: number) => [
    ...kisiQueryKeys.all(),
    clubId,
    "apiKeyExists",
  ],
  signatureKeyExists: (clubId: number) => [
    ...kisiQueryKeys.all(),
    clubId,
    "signatureKeyExists",
  ],
};
