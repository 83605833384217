import { useEffect, useState } from "react";
import { useStoreActions } from "easy-peasy";

import useGymflowModels from "../store";

/**
 * @deprecated this will be removed once redux is removed
 */
const useLoadClubSettings = ({ clubId }) => {
  const { api, settingsStore } = useGymflowModels();
  const { setValues } = useStoreActions(settingsStore);
  const [clubSettingsLoaded, setClubSettingsLoaded] = useState(false);
  useEffect(() => {
    const fetchClubSettings = async () => {
      const { data } = await api.public.clubApi.findById(clubId);
      const { data: rules } = await api.public.ruleApi.getValuesByClubId(clubId);
      setValues({ ...data, rules });
      setClubSettingsLoaded(true);
    };
    fetchClubSettings();
  }, [setValues, clubId]);

  return clubSettingsLoaded;
};

export default useLoadClubSettings;
