import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberEdit({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      patchedFields,
    }: {
      memberId: string;
      clubId: number;
      patchedFields: { [k: string]: any };
    }) =>
      api.memberApi.update(memberId, clubId, patchedFields) as Promise<any>,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
  });
  return mutation;
}
