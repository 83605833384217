import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { format, parse } from "date-fns";
import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import Datetime from "react-datetime";

import { useAuthenticatedUser } from "../../../providers";
import {
  ActivityCategorySelect,
  ActivityCategorySelectOption,
  ActivitySelect,
  AppointableCategorySelect,
  AppointableCategorySelectOption,
  AppointableSelect,
  Button,
  FacilitySelect,
  PaginatedSelect,
  SlideSideBar,
  StaffSelect,
} from "../../atoms";
import { CalendarFilters } from "./CalendarFilters";
import { EventType } from "./EventType";

export interface CalendarFilterSidebarProps {
  isVisible: boolean;
  onVisibilityChange: (newVisibility: boolean) => void;
  value: CalendarFilters;
  onChange: (newFilters: CalendarFilters) => void;
}

export const CalendarFilterSidebar = ({
  value,
  onChange,
  isVisible,
  onVisibilityChange,
}: CalendarFilterSidebarProps) => {
  const [filters, setFilters] = useState(value);
  const hide = () => {
    onVisibilityChange(false);
  };

  useEffect(() => {
    setFilters(value);
  }, [value]);

  const parsedStartTime = parse(
    filters.businessHours.start,
    "HH:mm",
    new Date(),
  );
  const parsedEndTime = parse(filters.businessHours.end, "HH:mm", new Date());
  const isValid =
    !isNaN(parsedStartTime.valueOf()) && !isNaN(parsedEndTime.valueOf());

  const eventTypeOptions: { [key in EventType]: string } = {
    OCCURRENCE: "Class",
    APPOINTMENT: "Appointment",
  };

  const { roles } = useAuthenticatedUser();
  const hasTrainerRole = roles.includes("TRAINER");
  return (
    <SlideSideBar isOpen={isVisible} hide={hide}>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="flex flex-col justify-between border-b border-gray-200 px-6 py-4">
          <div className="mb-1 flex flex-row items-center justify-between">
            <div className="text-xl font-semibold text-gray-900">Filters</div>
            <FontAwesomeIcon
              onClick={() => {
                hide();
              }}
              className="cursor-pointer text-xl text-gray-600"
              icon={faClose}
            />
          </div>
          <div className="text-sm font-medium text-gray-600">
            Apply filters to calendar
          </div>
        </div>

        <div className="flex h-full flex-col gap-4 overflow-y-auto p-6">
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">Event Type</div>
            <div>
              <PaginatedSelect
                placeholder="All"
                value={
                  filters.eventType
                    ? {
                        label: eventTypeOptions[filters.eventType],
                        value: filters.eventType,
                      }
                    : null
                }
                onChange={(newValue) => {
                  setFilters({ ...filters, eventType: newValue?.value });
                }}
                loadOptions={() => {
                  return Promise.resolve({
                    options: Object.keys(eventTypeOptions).map((k) => ({
                      label: eventTypeOptions[k as EventType],
                      value: k,
                    })),
                  });
                }}
                isClearable
              />
            </div>
          </div>
          <div
            className={classNames("flex flex-col gap-2", {
              hidden: hasTrainerRole,
            })}
          >
            <div className="text-sm font-medium text-gray-700">Trainer</div>
            <div>
              <StaffSelect
                allowMultipleSelection
                placeholder={filters.hosts?.length ? "Trainer" : "All"}
                value={filters.hosts || []}
                onChange={(newHosts) => {
                  setFilters({
                    ...filters,
                    hosts: newHosts,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">Facility</div>
            <div>
              <FacilitySelect
                allowMultipleSelection
                placeholder={filters.facility?.length ? "Facility" : "All"}
                value={filters.facility || []}
                onChange={(newFacilities) => {
                  setFilters({
                    ...filters,
                    facility: newFacilities,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">Class</div>
            <div>
              <ActivitySelect
                allowMultipleSelection
                placeholder={filters.classes?.length ? "Class" : "All"}
                value={filters.classes || []}
                onChange={(newValue) => {
                  setFilters({ ...filters, classes: newValue });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">Appointment</div>
            <div>
              <AppointableSelect
                allowMultipleSelection
                placeholder={
                  filters.appointments?.length ? "Appointment" : "All"
                }
                value={filters.appointments || []}
                onChange={(newValue) => {
                  setFilters({ ...filters, appointments: newValue });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">
              Class Category
            </div>
            <div>
              <ActivityCategorySelect
                allowMultipleSelection
                placeholder={
                  filters.classCategory?.length ? "Class Category" : "All"
                }
                value={filters.classCategory || []}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    classCategory: newValue as ActivityCategorySelectOption[],
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">
              Appointment Category
            </div>
            <div>
              <AppointableCategorySelect
                allowMultipleSelection
                placeholder={
                  filters.appointmentCategory?.length
                    ? "Appointment Category"
                    : "All"
                }
                value={filters.appointmentCategory || []}
                onChange={(newValue) => {
                  setFilters({
                    ...filters,
                    appointmentCategory:
                      newValue as AppointableCategorySelectOption[],
                  });
                }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-sm font-medium text-gray-700">
              Business Hours
            </div>
            <div>
              <Datetime
                dateFormat={false}
                timeFormat={"h:mm a"}
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    businessHours: {
                      ...filters.businessHours,
                      start:
                        v instanceof moment ? (v as Moment).format("HH:mm") : v,
                    } as any,
                  });
                }}
                value={
                  isNaN(parsedStartTime.valueOf())
                    ? (filters?.businessHours as any)?.start
                    : format(parsedStartTime, "h:mm a")
                }
                closeOnSelect
                inputProps={{ inputMode: "none" }}
              />
            </div>
            <div
              className={classNames("text-error-500 pt-2", {
                hidden: !isNaN(parsedStartTime.valueOf()),
              })}
            >
              Start Time is not valid (try something like 08:00 am).
            </div>
            <div>
              <Datetime
                dateFormat={false}
                timeFormat={"h:mm a"}
                onChange={(v) => {
                  setFilters({
                    ...filters,
                    businessHours: {
                      ...filters.businessHours,
                      end:
                        v instanceof moment ? (v as Moment).format("HH:mm") : v,
                    } as any,
                  });
                }}
                value={format(parsedEndTime, "h:mm a")}
                closeOnSelect
                inputProps={{ inputMode: "none" }}
              />
            </div>
            <div
              className={classNames("text-error-500 pt-2", {
                hidden: !isNaN(parsedEndTime.valueOf()),
              })}
            >
              End Time is not valid (try something like 06:00 pm).
            </div>
          </div>
        </div>
        <div className="flex h-20 flex-row items-center justify-end border-t border-gray-200 px-6">
          <Button onClick={() => hide()} className="mr-2 w-full">
            Cancel
          </Button>
          <Button
            intent="primary"
            className="w-full"
            disabled={!isValid}
            onClick={() => {
              if (!isValid) {
                return;
              }
              onChange(filters);
              hide();
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </SlideSideBar>
  );
};
