import { useCallback } from "react";
import { ServiceStatus, ServiceType } from "@gymflow/common";
import useGymflowModels from "../store";

/**
 * @deprecated useMembershipListAsPublic instead. Do be careful because the
 * mapping of the types is not exactly the same
 */
export const useSiteMemberships = () => {
  const { ServiceStore } = useGymflowModels();
  const fetchList = ServiceStore.useStoreActions((actions) => actions.fetchList);

  const memberships = ServiceStore.useStoreState(({ rows }) => {
    const getMembershipPrice = (membership) =>
      membership.type === ServiceType.Recurring ? membership.nextMonthlyPayment : membership.defaultPrice;

    return rows
      .slice(0)
      .sort((a, b) => getMembershipPrice(a) - getMembershipPrice(b))
      .map((service) => {
        const membershipAddonList = service.membershipAddonList ?? [];
        const includes = membershipAddonList.filter((s) => s.recurring);

        const plus = membershipAddonList
          .filter((s) => !s.recurring)
          .map((addon) => ({
            name: addon.name,
            price: (addon.quantity ?? 1) * addon.price,
            quantity: addon.quantity ?? 1,
          }));

        return {
          ...service,
          includes,
          plus,
          intervalType: service.durationType,
          intervalValue: service.duration,
          price: getMembershipPrice(service),
        };
      });
  });

  const fetchActiveMemberships = useCallback(
    (params) => fetchList({ status: ServiceStatus.Active, ...params }),
    [fetchList],
  );

  return { fetchList: fetchActiveMemberships, memberships };
};
