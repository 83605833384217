import React from 'react';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';

import { TRIAL } from './PromotionSchema';

const yesNoOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const Step5 = ({ formikProps }) => {
  const { values, setFieldValue } = formikProps;

  return (
    <div className="w-100 text-center">
      <b className="h2">Is this a trial offer?</b>
      <div className="text-muted mt-3">
        <p className="lh-130">
          Trial offers will ensure that if a lead makes a purchase that they stay in the lead pipeline instead of being
          marked as "Deal Closed"
        </p>
      </div>
      <Row className="mt-4 text-left d-flex justify-content-center">
        <Col md={6}>
          <Select
            className="react-select info"
            classNamePrefix="react-select"
            options={yesNoOptions}
            onChange={({ value }) => {
              setFieldValue(TRIAL, value);
            }}
            value={yesNoOptions.find((op) => op.value === values[TRIAL])}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Step5;
