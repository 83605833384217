import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const ImagePreview = ({ className, image }) => {
  const [imageUrl, setImageUrl] = useState(image);

  const loadBlob = async (blob) => {
    const base64 = await blobToBase64(blob);
    setImageUrl(base64);
  };

  useEffect(() => {
    if (image instanceof Blob) {
      loadBlob(image);
    } else {
      setImageUrl(image);
    }
  }, [image]);

  return (
    <div
      className={className}
      style={{
        display: 'inline-block',
        width: '100%',
        height: '42px',
      }}
    >
      <div
        style={{
          borderRadius: '2px',
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  );
};

ImagePreview.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Blob)]),
  className: PropTypes.string,
};

export default ImagePreview;
