import { cn } from "@gymflow/helpers";
import { forwardRef } from "react";

export type TextareaProps = {
  value: string;
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
  name?: string | undefined;
  rows?: number;
  className?: string;
  placeholder?: string;
  label?: string;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, value, ...rest }, ref) => (
    <div className="flex flex-col gap-1.5">
      {label && (
        <div className="text-sm font-medium text-gray-950 ">{label}</div>
      )}
      <textarea
        ref={ref}
        value={value}
        className={cn(
          "shadow-xs outline-secondary-200 w-full resize-y rounded-xl border border-gray-200 px-3.5 py-3 text-base font-medium",
          value ? "text-gray-950" : "text-gray-500",
          className,
        )}
        {...rest}
      />
    </div>
  ),
);

Textarea.displayName = "Textarea";
