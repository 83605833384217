import axios, { CustomParamsSerializer, ParamsSerializerOptions } from "axios";

import { axiosRequestInterceptor } from "./interceptors";

export function createPublicAxiosInstance({
  apiUrl,
  paramsSerializer,
}: {
  apiUrl: string;
  paramsSerializer?:
    | ParamsSerializerOptions
    | CustomParamsSerializer
    | undefined;
}) {
  const instance = axios.create({
    baseURL: apiUrl,
    paramsSerializer,
  });

  instance.interceptors.request.use(axiosRequestInterceptor);
  return instance;
}
