import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { RouteLayout } from "../../routes/layout";
import { Spinner } from "../atoms";

export function LoadingPortal({ message }: { message: string }) {
  const location = useLocation();
  const { isPathInLayout } = usePortalRoutes();
  const isEmbed = isPathInLayout(location.pathname, RouteLayout.Embed);
  return (
    <div
      className={classNames("container-fluid h-[95vh]", {
        invisible: isEmbed,
      })}
    >
      <div className="justify-content-center pt-md-5 mt-md-5 flex flex-col">
        <div>
          <div className="bg-gymflow-logo m-8 h-12 bg-contain bg-center bg-no-repeat" />
        </div>
        <div>
          <Spinner
            className="!h-[30vh] !max-h-20 !w-[30vh] !max-w-[5rem]"
            circleClassName="stroke-primary-600"
          />
        </div>
        <div className="mt-5 text-center">{message}</div>
      </div>
    </div>
  );
}
