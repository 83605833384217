import { cn } from "@gymflow/helpers";
import { Field, FieldProps, FormikProps } from "formik";

import {
  StaffSelectInput,
  StaffSelectInputOptionsType,
  StaffSelectInputProps,
} from "../base/StaffSelectInput";
import { ErrorMessage } from "../ErrorMessage";

type FormStaffSelectInputProps<T> = {
  name: keyof T;
} & Omit<StaffSelectInputProps, "value" | "onChange">;

export const FormStaffSelectInput = <T,>({
  name,
  className,
  ...props
}: FormStaffSelectInputProps<T>) => (
  <Field name={name}>
    {({
      field,
      form: { setFieldValue },
      meta: { touched, error },
    }: FieldProps<StaffSelectInputOptionsType, FormikProps<T>>) => {
      const isError = touched && error;
      return (
        <div className="flex flex-col gap-1.5">
          <StaffSelectInput
            {...props}
            value={field.value}
            onChange={(selected: StaffSelectInputOptionsType) =>
              setFieldValue(String(name), selected)
            }
            className={cn(
              className,
              isError && "outline-error-300 border-error-200",
            )}
          />
          {isError && <ErrorMessage text={error} />}
        </div>
      );
    }}
  </Field>
);
