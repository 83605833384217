import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationDeleteLeadStatus(
  {
    api,
  }: {
    api: {
      leadApi: {
        deleteStatusColumn: (columnId: number) => Promise<void>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, { columnId: number }>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ columnId }: { columnId: number }) =>
      api.leadApi.deleteStatusColumn(columnId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return mutation;
}
