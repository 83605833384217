import { useQueryMembershipStatusHistory } from "@gymflow/api";
import { UserMemberSubscriptionStatus } from "@gymflow/types";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { capitalize } from "lodash";
import { DateTime } from "luxon";
import React from "react";

import { Badge, BadgeProps } from "../../atoms";

export interface MembershipStatusHistoryProps {
  memberId: string;
}

export const MembershipStatusHistory: React.FC<
  MembershipStatusHistoryProps
> = ({ memberId }) => {
  const { api } = useGymflowModels();
  const { timezone } = useClubSettings();
  const { data: membershipStatusHistory } = useQueryMembershipStatusHistory({
    api,
    memberId,
    tz: timezone,
  });
  return (
    <div className="flex w-full flex-col gap-4 pb-4">
      <div className="flex flex-col">
        <div className="flex h-9 items-center text-lg font-semibold text-gray-900">
          Membership Status History
        </div>
        <div className="text-sm font-normal text-slate-600">
          See all membership status changes for this member.
        </div>
      </div>
      {membershipStatusHistory?.map((e) => (
        <div className="flex flex-col gap-4" key={e.id}>
          <div className="border-b border-gray-200" />
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center gap-1 text-sm font-semibold text-slate-700">
              Membership status changed to
              <Badge
                intent={membershipColorMap[e.status]}
                className="h-5 text-xs"
              >
                {capitalize(e.status)}
              </Badge>
            </div>

            <div className="text-sm font-normal text-slate-600 ">
              {DateTime.fromISO(e.createdDate).toLocaleString(
                DateTime.DATE_MED,
              )}{" "}
              @{" "}
              {DateTime.fromISO(e.createdDate).toLocaleString(
                DateTime.TIME_SIMPLE,
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const membershipColorMap = {
  ACTIVE: "success",
  PAUSED: "warning",
  PENDING: "primary",
  OVERDUE: "error",
  CANCELLED: "default",
  DELETED: "error",
  EXPIRED: "default",
} as {
  [key in UserMemberSubscriptionStatus]: BadgeProps["intent"];
};
