import { FacilityCreateDTO, FacilityDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export function useFacilityCreate({
  api,
}: {
  api: {
    facilityApi: {
      create: (
        newFacility: FacilityCreateDTO,
      ) => Promise<{ data: FacilityDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newFacility: FacilityCreateDTO) => {
      const response = await api.facilityApi.create(newFacility);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: facilityQueryKeys.all() });
    },
  });

  return mutation;
}
