import { useQueryLeadListCount, useQueryLeadStatus } from "@gymflow/api";
import { cn } from "@gymflow/helpers";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function LeadCountBadge() {
  const { timezone } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: leadStatusList } = useQueryLeadStatus({ api });
  const newLeadStatusId = leadStatusList?.find(
    (s) => s.presetType === "NEW_LEAD",
  )?.id;
  const { data } = useQueryLeadListCount(
    {
      api,
      tz: timezone,
      filters: {
        leadStatusId: newLeadStatusId ? [newLeadStatusId] : undefined,
      },
    },
    { enabled: !!newLeadStatusId },
  );
  return (
    <div className="flex justify-between">
      <div>Leads</div>
      <div
        className={cn(
          "text-secondary-700 flex h-6 items-center rounded-2xl bg-[#E5D8FB] px-2.5 py-1 text-sm",
          { hidden: !data?.count },
        )}
      >
        {data?.count}
      </div>
    </div>
  );
}
