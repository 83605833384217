import { Placeholders } from "@gymflow/common";
import {
  createBoldPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createParagraphPlugin,
  createPlateEditor,
  createPlateUI,
  createPlugins,
  createStrikethroughPlugin,
  createUnderlinePlugin,
  deserializeHtml,
  serializeHtml,
} from "@udecode/plate";
import { useCallback, useMemo } from "react";

const useEditorPlugins = ({ placeholders = {} } = {}) => {
  const memoedComponents = useMemo(() => createPlateUI(), []);
  const memoedPlugins = useMemo(() => {
    const plugins = [
      createLinkPlugin(),
      createBoldPlugin(),
      createHeadingPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createListPlugin(),
      createImagePlugin(),
      createParagraphPlugin(),
    ];

    return createPlugins(plugins, { components: memoedComponents });
  }, [memoedComponents]);

  const editor = useMemo(
    () =>
      createPlateEditor({
        plugins: memoedPlugins,
        components: memoedComponents,
      }),
    [memoedComponents, memoedPlugins],
  );

  const convertEditorNodesToHTML = useCallback(
    (editorValue) => {
      let result = serializeHtml(editor, {
        nodes: editorValue,
      });

      const rootEl = document.createElement("body");
      rootEl.innerHTML = result;
      const images = rootEl.getElementsByClassName(
        "slate-ImageElement-resizable",
      );

      [...images].forEach((img) => {
        img.style["max-width"] = null;
        img.children[0].style.width = img.style.width;
      });

      let parsed = rootEl.innerHTML;

      Object.keys(placeholders).forEach((pKey) => {
        parsed = parsed.split(`{${placeholders[pKey]}}`).join(`{${pKey}}`);
      });

      return parsed;
    },
    [placeholders, editor],
  );

  const convertHTMLToEditorNodes = useCallback(
    (html) => {
      Object.keys(placeholders).forEach((pKey) => {
        html = html.split(`{${pKey}}`).join(`{${placeholders[pKey]}}`);
      });
      return deserializeHtml(editor, {
        element: html,
      });
    },
    [placeholders, editor],
  );
  return {
    plugins: memoedPlugins,
    convertEditorNodesToHTML,
    convertHTMLToEditorNodes,
  };
};

export default useEditorPlugins;
