import * as Yup from 'yup';
import moment from 'moment-timezone';
import { DATE_FORMAT, ERROR_MESSAGES } from '@gymflow/common';

export const PROMOTION_NAME = 'name';
export const PROMOTION_CODE = 'code';
export const DISCOUNT_KIND = 'discount-kind';
export const UPFRONT_DISCOUNT = 'upfront-discount';
export const RECURRING_DISCOUNT = 'recurring-discount';
export const BOTH_DISCOUNT = 'both-discount';
export const UPFRONT_DISCOUNT_AMOUNT = 'upfront-discount-amount';
export const UPFRONT_DISCOUNT_TYPE = 'upfront-discount-type';
export const UPFRONT_DISCOUNT_PRORATA = 'upfront-discount-prorata';
export const RECURRING_DISCOUNT_AMOUNT = 'recurring-discount-amount';
export const RECURRING_DISCOUNT_TYPE = 'recurring-discount-type';
export const IS_DURATION_MULTI_CYCLES = 'is-duration-multi-cycles';
export const RECURRING_DISCOUNT_DURATION = 'recurring-discount-duration';
export const LIMIT_PRODUCT_ID = 'limit-product-id';
export const LIMIT_SERVICE_MEMBERSHIP_ID = 'limit-membership-id';
export const LIMIT_CREDIT_PACK_ID = 'limit-session-pack-id';
export const LIMIT_USAGES_PER_USER = 'limit-usages-per-member';
export const LIMIT_USAGES = 'limit-usages';
export const TRIAL = 'trial';
export const ACTIVE = 'active';
export const EXPIRY = 'expiry';

export const DISCOUNT_TYPE_MAP = {
  CURRENCY: 'CURRENCY',
  PERCENTAGE: 'PERCENTAGE',
};

const createSchema = ({ isNew }) => {
  const fields = {
    [PROMOTION_NAME]: Yup.string().required().min(3),
    [PROMOTION_CODE]: Yup.string().required(),
    [DISCOUNT_KIND]: Yup.string().default(UPFRONT_DISCOUNT),
    [UPFRONT_DISCOUNT_AMOUNT]: Yup.string()
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', BOTH_DISCOUNT)
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', UPFRONT_DISCOUNT),
    [UPFRONT_DISCOUNT_TYPE]: Yup.string()
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', BOTH_DISCOUNT)
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', UPFRONT_DISCOUNT)
      .default(DISCOUNT_TYPE_MAP.PERCENTAGE),
    [UPFRONT_DISCOUNT_PRORATA]: Yup.boolean()
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', BOTH_DISCOUNT)
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', UPFRONT_DISCOUNT)
      .default(false),
    [RECURRING_DISCOUNT_AMOUNT]: Yup.string()
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', BOTH_DISCOUNT)
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', RECURRING_DISCOUNT),
    [RECURRING_DISCOUNT_TYPE]: Yup.string()
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', BOTH_DISCOUNT)
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', RECURRING_DISCOUNT)
      .default(DISCOUNT_TYPE_MAP.PERCENTAGE),
    [IS_DURATION_MULTI_CYCLES]: Yup.boolean()
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', BOTH_DISCOUNT)
      .requiredIf(Yup.ref(DISCOUNT_KIND), 'Field is required', RECURRING_DISCOUNT)
      .default(false),
    [RECURRING_DISCOUNT_DURATION]: Yup.string()
      .requiredIf(Yup.ref(IS_DURATION_MULTI_CYCLES), 'Field is required', true)
      .nullable(),
    [LIMIT_PRODUCT_ID]: Yup.number().nullable(),
    [LIMIT_SERVICE_MEMBERSHIP_ID]: Yup.number().nullable(),
    [LIMIT_CREDIT_PACK_ID]: Yup.number().nullable(),
    [LIMIT_USAGES_PER_USER]: Yup.string(),
    [LIMIT_USAGES]: Yup.string(),
    [TRIAL]: Yup.boolean().required().default(false),
    [ACTIVE]: Yup.boolean().required().default(true),
  };
  if (isNew) {
    return Yup.object().shape({
      ...fields,
      [EXPIRY]: Yup.date()
        .format(DATE_FORMAT, true)
        .min(moment().subtract(1, 'day').toDate(), ERROR_MESSAGES.dateTooSmall),
    });
  }

  return Yup.object().shape({
    ...fields,
    [EXPIRY]: Yup.date().format(DATE_FORMAT, true),
  });
};

export default createSchema;
