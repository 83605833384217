import { PhoneNumberUtil } from "google-libphonenumber";

export const phoneUtil = PhoneNumberUtil.getInstance();

export const parsePhoneNumber = (value: string) => {
  try {
    return phoneUtil.parseAndKeepRawInput(value);
  } catch {}
  return;
};

export const validatePhoneNumber = (phoneNumber?: string) => {
  if(!phoneNumber) return false;
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
  } catch {
    return false;
  }
};
