import { RefObject } from "react";
import { useResizeObserver } from "usehooks-ts";

const HEADER_HEIGHT = 44;

export function usePageSize({
  tableContainerRef,
  rowHeight,
}: {
  tableContainerRef: RefObject<HTMLElement>;
  rowHeight: number;
}) {
  const { height } = useResizeObserver({
    ref: tableContainerRef,
    box: "border-box",
  });
  const result = Math.floor((height ?? 0 - HEADER_HEIGHT) / rowHeight - 1) || 1;
  return result > 0 ? result : 1;
}
