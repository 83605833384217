import {
  DayOfWeek,
  MembershipCalculateCancelDatesResponse,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useQueryMembershipCalculateCancelDates(
  {
    api,
    opts,
  }: {
    api: ApiMembershipCalculateCancelDates;
    opts?: MembershipCalculateCancelDatesFilter;
  },
  queryOptions?: UseQueryOptions<
    MembershipCalculateCancelDatesResponse | undefined
  >,
) {
  const result = useQuery({
    queryKey: membershipQueryKeys.calculateCancelDates(opts),
    queryFn: () => {
      if (!opts) {
        return;
      }
      return membershipCalculateCancelDatesQueryFn({ api, filter: opts });
    },
    enabled: !!opts?.startDate && !!opts?.billingType && !!opts?.billingPeriod,
    ...queryOptions,
  });
  return result;
}

export async function membershipCalculateCancelDatesQueryFn({
  api,
  filter,
}: {
  api: ApiMembershipCalculateCancelDates;
  filter: MembershipCalculateCancelDatesFilter;
}) {
  const result = await api.serviceApi.calculateCancelDates(filter);
  return result.data;
}

type MembershipCalculateCancelDatesFilter =
  | {
      billingType: "MONTHLY";
      billingPeriod: number;
      monthlyBillingDay: number;
      startDate: string;
    }
  | {
      billingType: "WEEKLY";
      billingPeriod: number;
      weeklyBillingDay: DayOfWeek;
      startDate: string;
    };

interface ApiMembershipCalculateCancelDates {
  serviceApi: {
    calculateCancelDates: (
      filter: MembershipCalculateCancelDatesFilter,
    ) => Promise<{ data: MembershipCalculateCancelDatesResponse }>;
  };
}
