import { AlertContext } from "@gymflow/common";
import { pluralize } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import { useCallback, useContext } from "react";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

function useSendEmails() {
  const { api, settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { email: clubEmail } = useStoreState(settingsStore);
  const { show, showError } = useContext(AlertContext);

  const showWarnings = useCallback(
    ({ failedEmails, sentEmails, sentAttempts, failedAttemptsWithNoEmail }) => {
      const failedEmailsCount = failedEmails ? failedEmails.length : 0;
      const noEmailCount = failedAttemptsWithNoEmail
        ? failedAttemptsWithNoEmail.length
        : 0;
      const marketingErrors =
        sentAttempts - sentEmails - failedEmailsCount - noEmailCount;
      let isDanger = false;

      const nodes = [
        <>
          Sent {sentEmails} out of {sentAttempts}{" "}
          {pluralize("email", "emails", sentAttempts)}.
        </>,
      ];

      if (marketingErrors > 0) {
        nodes.push(
          <>
            <br />
            {marketingErrors} emails not sent due to marketing permissions.
            <br />
          </>,
        );
      }

      if (failedEmailsCount > 0) {
        isDanger = true;
        nodes.push(<>Failed emails: {failedEmails.join(", ")}</>);
      }

      if (failedAttemptsWithNoEmail && failedAttemptsWithNoEmail.length > 0) {
        isDanger = true;
        nodes.push(
          <>
            Users with no emails:
            {failedAttemptsWithNoEmail
              .map(({ firstName, lastName }) => firstName + " " + lastName)
              .join(", ")}
          </>,
        );
      }

      showError(nodes, {
        warning: !isDanger,
        danger: isDanger,
        title: isDanger ? "Error" : "Warning",
      });
    },
    [showError],
  );

  const sendEmails = useCallback(
    async (subject, body, emailRecipientList, isMarketing, bcc) => {
      const {
        data: {
          sentEmails,
          sentAttempts,
          failedEmails,
          failedAttemptsWithNoEmail,
        },
      } = await api.emailApi.sendEmail(
        clubId,
        clubEmail,
        subject,
        body,
        emailRecipientList,
        isMarketing,
        bcc,
      );

      if (sentEmails === sentAttempts) {
        show(
          `${sentEmails} ${pluralize(
            "email",
            "emails",
            sentEmails,
          )} successfully sent.`,
          { showCancel: false },
        );
      } else {
        showWarnings({
          failedEmails,
          sentEmails,
          sentAttempts,
          failedAttemptsWithNoEmail,
        });
      }
    },
    [clubId, clubEmail, show, showWarnings],
  );

  const sendEmailsByFilter = useCallback(
    async (
      subject,
      body,
      filters,
      isMarketing,
      leadIdsToExclude,
      userMemberIdsToExclude,
      bcc,
    ) => {
      const {
        data: {
          sentEmails,
          sentAttempts,
          failedEmails,
          failedAttemptsWithNoEmail,
        },
      } = await api.emailApi.sendEmailFilter(
        clubId,
        clubEmail,
        subject,
        body,
        filters,
        isMarketing,
        leadIdsToExclude,
        userMemberIdsToExclude,
        bcc,
      );
      if (sentEmails === sentAttempts) {
        show(
          `${sentEmails} ${pluralize(
            "email",
            "emails",
            sentEmails,
          )} successfully sent.`,
          { showCancel: false },
        );
      } else {
        showWarnings({
          failedEmails,
          sentEmails,
          sentAttempts,
          failedAttemptsWithNoEmail,
        });
      }
    },
    [clubEmail, clubId, show, showWarnings],
  );

  return { sendEmails, sendEmailsByFilter };
}

export default useSendEmails;
