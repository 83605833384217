import { useMutation, useQueryClient } from '@tanstack/react-query';

import { automationQueryKeys } from './automationQueryKeys';

export function useAutomationEditBcc({
  api,
}: {
  api: {
    automationApi: {
      updateBCC: (automationId: number, bccList: string[]) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      automationId,
      bccList,
    }: {
      automationId: number;
      bccList: string[];
    }) => {
      await api.automationApi.updateBCC(automationId, bccList);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: automationQueryKeys.all() });
    },
  });
  return mutation;
}
