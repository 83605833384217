import { ApiListResponse, ProductCategoryDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { productQueryKeys } from "./productQueryKeys";

export function useProductCategorySearchList(
  {
    api,
    opts,
  }: {
    api: {
      productApi: {
        findCategories: (
          filter: ProductCategoryFilter,
        ) => Promise<{ data: ApiListResponse<ProductCategoryDTO> }>;
      };
    };
    opts: ProductCategoryFilter;
  },
  queryOpts?: UseQueryOptions<ApiListResponse<ProductCategoryDTO>>,
) {
  const result = useQuery({
    queryKey: productQueryKeys.categorySearch(opts),
    queryFn: async () => {
      const result = await api.productApi.findCategories({ ...opts });
      return result.data;
    },
    initialData: defaultPage<ProductCategoryDTO>,
    enabled: opts.limit !== undefined && opts.page !== undefined,
    ...queryOpts,
  });
  return result;
}

export interface ProductCategoryFilter {
  readonly page?: number;
  readonly limit?: number;
}
