import { CompanyDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { companyQueryKeys } from "./companyQueryKeys";


export function useMutationCompany({
  api,
}: {
  api: {
    companyApi: {
      updateDetails: (
        clubId: number,
        patchedFields: Partial<CompanyDTO>,
      ) => Promise<{ data: CompanyDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      clubId,
      payload,
    }: {
      clubId: number;
      payload: Partial<CompanyDTO>;
    }) => {
      const result = await api.companyApi.updateDetails(clubId, payload);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: companyQueryKeys.all() });
    },
  });
}
