export function ThumbsUpIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="thumbs-up" clipPath="url(#clip0_2855_493)">
        <path
          className={pathClassName}
          d="M4.66665 14.6666V7.33331M1.33331 8.66665V13.3333C1.33331 14.0697 1.93027 14.6666 2.66665 14.6666H11.6175C12.6046 14.6666 13.4441 13.9464 13.5942 12.9708L14.3122 8.30409C14.4986 7.09258 13.5612 5.99998 12.3354 5.99998H9.99998C9.63179 5.99998 9.33331 5.7015 9.33331 5.33331V2.97721C9.33331 2.06931 8.59732 1.33331 7.68942 1.33331C7.47287 1.33331 7.27663 1.46084 7.18868 1.65873L4.84261 6.93741C4.7356 7.17816 4.49686 7.33331 4.2334 7.33331H2.66665C1.93027 7.33331 1.33331 7.93027 1.33331 8.66665Z"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2855_493">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
