import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Tooltip } from "react-tooltip";

export interface FormLabelProps {
  label: string;
  tooltip?: string;
}

export const FormLabel: React.FC<FormLabelProps> = ({ label, tooltip }) => {
  return (
    <div className="inline-flex items-center gap-x-2 text-sm font-semibold dark:text-white">
      {label}
      {tooltip && (
        <>
          <FontAwesomeIcon
            data-tooltip-id="category-tooltip"
            data-tooltip-content={tooltip}
            className="cursor-pointer"
            icon={faQuestionCircle}
          />
          <Tooltip
            className="!bg-primary-700 flex max-w-sm flex-col items-center rounded-lg text-center text-xs"
            id="category-tooltip"
          />
        </>
      )}
    </div>
  );
};
