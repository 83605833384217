export function useSiteInjectCss() {
  return addStyle;
}

const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace("#", "");

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

const addStyle = ({
  logo,
  backgroundColor,
  cardColor,
  font,
  fontColor,
  primaryColor,
  buttonFontColor,
  accentColor,
  shadowColor,
}: {
  logo: string;
  backgroundColor: string;
  cardColor: string;
  font: string;
  fontColor: string;
  primaryColor: string;
  buttonFontColor: string;
  accentColor: string;
  shadowColor: string;
}) => {
  const styleString = `
    .hosted-date-select{
      background-color: ${primaryColor} !important;
      border: none !important;
    }
    .hosted-date-select .hosted-date-select-icon{
      color: ${buttonFontColor ?? "white"} !important;
    }
    .hosted-date-select .hosted-date-select-input{
      height: 44px;
      color: ${buttonFontColor ?? "white"} !important;
      background-color: ${primaryColor} !important;
    }

    body,
    .site-content button,
    .site-content input,
    .site-content optgroup,
    .site-content select,
    .site-content textarea {
      font-family: '${font}';
    }

    body,
    .white-content .site-content h1,
    .white-content .site-content h2,
    .white-content .site-content h3,
    .white-content .site-content h4,
    .white-content .site-content h5,
    .white-content .site-content h6,
    .white-content .site-content ol li,
    .white-content .site-content ul li,
    .white-content .site-content pre {
      font-family: '${font}';
      color: ${fontColor};
    }

    .white-content .site-content .react-select .react-select__control,
    .white-content .site-content .react-select .react-select__control:hover,
    .white-content .site-content .react-select__control,
    .white-content .site-content .react-select__control:hover
    {
      border-color: rgba(58, 58, 63, 0.5) !important;
    }

    .white-content .site-content .react-select.react-select-btn .react-select__control,
    .white-content .site-content .react-select.react-select-btn .react-select__control:hover,
    .white-content .site-content .react-select-btn .react-select__control,
    .white-content .site-content .react-select-btn .react-select__control:hover
    {
      border-color: ${primaryColor} !important;
    }


    .white-content .site-content .react-select.react-select-btn .react-select__option,
    .white-content .site-content .react-select.react-select-btn .react-select__option--is-focused
    {
      color: ${fontColor} !important;
    }

    .react-select .react-select__control .react-select__value-container--has-value .react-select__single-value,
    .react-select .react-select__control .react-select__value-container,
    body.white-content .react-select .react-select__control,
    .react-select__control .react-select__value-container--has-value .react-select__single-value,
    .react-select__control .react-select__value-container,
    body.white-content .react-select__control
    {
      color: ${fontColor} !important;
      background-color: ${cardColor} !important;
    }

    .react-select__menu-list,
    .react-select__option--is-focused
    {
      background-color: ${cardColor} !important;
    }

    .white-content .form-control[readonly] {
      background-color: ${cardColor} !important;
    }

    .react-select__option:hover {
      background-color: rgb(247, 248, 255) !important;
    }

    .react-select.react-select-btn .react-select__control .react-select__value-container--has-value .react-select__single-value,
    .react-select.react-select-btn .react-select__control .react-select__value-container,
    body.white-content .react-select.react-select-btn .react-select__control,
    .react-select-btn .react-select__control .react-select__value-container--has-value .react-select__single-value,
    .react-select-btn .react-select__control .react-select__value-container,
    body.white-content .react-select-btn .react-select__control
    {
      color: ${primaryColor} !important;
    }

    .text-muted {
      color: ${setOpacity(fontColor, 0.6)} !important;
    }


    .white-content .site-content .form-control,
    .white-content .site-content p,
    .white-content .site-content .card:not(.card-white) label:not(.btn):not(.error),
    .white-content .site-content .card:not(.card-white) .tab-content .tab-pane,
    .white-content .site-content .card:not(.card-white) .card-header,
    .sweet-alert h2,
    .sweet-alert h2 *,
    .site-content .sweet-alert {
      color: ${fontColor} !important;
    }

    .gymflow-logo-background {
      background-image: url(${logo});
    }

    .white-content .main-panel {
      background: ${backgroundColor};
    }

    .white-content .site-content .card:not(.card-white)
    {
      border: 0;
      background-color: ${cardColor} !important;
    }

    .site-content .form-check input[type="checkbox"]:checked + .form-check-sign::before,
    .site-content .form-check input[type="checkbox"]:checked + .form-check-sign::before
    {
      background: ${primaryColor};
    }

    .main-panel .site-content .btn:not(.btn-link),
    .main-panel .site-content .btn:not(.btn-link):hover,
    .main-panel .site-content .btn:not(.btn-link):focus {
      background: ${primaryColor} !important;
      color: ${buttonFontColor} !important;
    }

    .white-content .site-content .btn.btn-link,
    .white-content .site-content .btn.btn-link:hover,
    .white-content .site-content .btn.btn-link:focus {
      color: ${primaryColor} !important;
    }

    .site-content a,
    .site-content a:hover,
    .site-content a:focus {
      color: ${primaryColor} !important;
      font-weight: normal;
    }

    .site-content a.regular-link,
    .site-content a.regular-link:hover,
    .site-content a.regular-link:focus {
      color: #000957 !important;
    }

    .site-content a.active {
      font-weight: bold;
    }

    .site-content .btn,
    .site-content .user-calendar-date-select
    {
      padding: 11px 40px;
      background: ${primaryColor};
      background-image: none !important;
      color: ${buttonFontColor};
    }

    .site-content .btn-sm {
      padding: 5px 10px
    }

    .site-content .user-calendar-date-select input,
    .site-content .user-calendar-date-select:hover input {
      color: ${buttonFontColor} !important;
    }

    .site-content .user-calendar-date-select:hover,
    .site-content .user-calendar-date-select:active,
    .site-content .user-calendar-date-select.active,
    .site-content .user-calendar-date-select:focus
    {
      background-color: ${accentColor} !important;
    }

    .site-content .btn-primary,
    .site-content .btn-primary:hover,
    .site-content .btn-primary:focus,
    .site-content .btn-primary:active,
    .site-content .btn-primary.active,
    .site-content .btn-primary:active:focus,
    .site-content .btn-primary:active:hover,
    .site-content .btn-primary.active:focus,
    .site-content .btn-primary.active:hover,
    .site-content .sweet-alert a.btn,
    .site-content .sweet-alert a.btn:hover,
    .site-content .sweet-alert a.btn:focus,
    .site-content .sweet-alert a.btn:active,
    .site-content .sweet-alert a.btn.active,
    .site-content .sweet-alert a.btn:active:focus,
    .site-content .sweet-alert a.btn:active:hover,
    .site-content .sweet-alert a.btn.active:focus,
    .site-content .sweet-alert a.btn.active:hover
    {
      background: ${primaryColor} !important;
      background-image: none !important;
      color: ${buttonFontColor};
      box-shadow: none !important;
      padding: 11px 40px !important;
    }


    .site-content .btn-secondary,
    .site-content .btn-secondary:hover,
    .site-content .btn-secondary:focus,
    .site-content .btn-secondary:active,
    .site-content .btn-secondary.active,
    .site-content .btn-secondary:active:focus,
    .site-content .btn-secondary:active:hover,
    .site-content .btn-secondary.active:focus,
    .site-content .btn-secondary.active:hover {
      box-shadow: none !important;
    }

    .main-panel .site-content .btn.btn-secondary:not(.btn-link)hover {
      background: ${accentColor} !important;
      transform: none;
      -webkit-transform: none;
    }

    .white-content .site-content .card:not(.card-white)
    {
      box-shadow: 0 1px 15px 0 ${convertHexToRGBA(shadowColor, 15)} !important;
    }

    .footer {
      box-shadow: none;
    }

    .rdtPicker {
      background: ${cardColor};
    }

    .rdtPicker::after {
      border-bottom: 0.4em solid ${backgroundColor};
    }

    .rdtDays tr .rdtDay.rdtOld, .rdtDays tr .rdtDay.rdtNew {
      color: ${fontColor} !important;
    }

    .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
      color: ${primaryColor} !important;
    }

    .rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
      background-color: ${primaryColor} !important;
    }

    .site-content .sweet-alert {
      background-color: ${cardColor} !important;
    }

    .site-content .sweet-alert .btn {
      box-shadow: none;
    }

    .sweet-alert > div:first-child {
      display: none;
    }

    .event-circle {
      background-color: ${primaryColor};
    }

    .badge-primary {
      background-color: ${setOpacity(primaryColor, 0.1)};
      color: ${primaryColor};
    }

    .event-icon {
      color: ${primaryColor};
    }
  `;

  return injectCss(styleString);
};

const setOpacity = (hex: string, alpha: number) =>
  `${hex}${Math.floor(alpha * 255)
    .toString(16)
    .padStart(2, "0")}`;

const injectCss = (styleString: string) => {
  const styleElement = document.createElement("style");
  styleElement.textContent = styleString;
  document.head.append(styleElement);

  return function () {
    styleElement.remove();
  };
};
