import { z } from "zod";

export const promotionStep1Schema = z
  .object({
    name: z
      .string({ required_error: "Field is required" })
      .min(1, { message: "Field is required" }),
    code: z
      .string({ required_error: "Field is required" })
      .min(1, { message: "Field is required" }),
    trial: z.boolean().default(false),
    upfrontDiscount: z.boolean().default(true),
    upfrontDiscountAmount: z.number({ coerce: true }).min(1).optional(),
    upfrontDiscountType: z
      .enum(["PERCENTAGE", "CURRENCY"])
      .default("PERCENTAGE"),
    recurringDiscount: z.boolean().default(false),
    recurringDiscountAmount: z.number({ coerce: true }).min(1).optional(),
    recurringDiscountType: z
      .enum(["PERCENTAGE", "CURRENCY"])
      .default("PERCENTAGE"),
    recurringDiscountDurationType: z
      .enum(["FOREVER", "MULTIPLE_CYCLES"])
      .default("FOREVER"),
    recurringDiscountDuration: z.number({ coerce: true }).min(1).optional(),
  })
  .superRefine((input, ctx) => {
    if (input.upfrontDiscount) {
      if (!input.upfrontDiscountAmount) {
        ctx.addIssue({
          code: "custom",
          message: "Field is required",
          path: ["upfrontDiscountAmount"],
        });
        return false;
      }
      if (
        input.upfrontDiscountType === "PERCENTAGE" &&
        input.upfrontDiscountAmount > 100
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Cannot be more than 100%.",
          path: ["upfrontDiscountAmount"],
        });
        return false;
      }
    }
    if (input.recurringDiscount) {
      if (!input.recurringDiscountAmount) {
        ctx.addIssue({
          code: "custom",
          message: "Field is required",
          path: ["recurringDiscountAmount"],
        });
        return false;
      }

      if (
        input.recurringDiscountType === "PERCENTAGE" &&
        input.recurringDiscountAmount > 100
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Cannot be more than 100%.",
          path: ["recurringDiscountAmount"],
        });
        return false;
      }

      if (
        input.recurringDiscountDurationType === "MULTIPLE_CYCLES" &&
        !input.recurringDiscountDuration
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Field is required",
          path: ["recurringDiscountDuration"],
        });
      }
    }
    return true;
  });

const item = z.object({ name: z.string(), id: z.number() });

export const promotionStep2Schema = z.object({
  limitType: z.enum(["SPECIFIC_ITEMS", "CATEGORIES"]).default("SPECIFIC_ITEMS"),
  limitMembershipList: z.array(item).default([]),
  limitProductList: z.array(item).default([]),
  limitSessionPackList: z.array(item).default([]),
  limitAppointableList: z.array(item).default([]),
  limitUsagesPerMember: z.number({ coerce: true }).optional(),
  limitUsages: z.number({ coerce: true }).optional(),
  limitCategories: z.array(z.string()).default([]),
  expiry: z.string().optional(),
});
