import { ClubBean, KisiPlace } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useQueryKisiPlaceList(
  {
    api,
    clubId,
  }: {
    api: {
      kisiApi: {
        fetchPlaces: (clubId: number) => Promise<any>;
      };
      clubApi: { findById: (clubId: number) => Promise<{ data: ClubBean }> };
    };
    clubId: number;
  },
  queryOpts?: UseQueryOptions<KisiPlace[]>,
) {
  const result = useQuery({
    queryKey: kisiQueryKeys.places(),
    queryFn: async () => {
      const result = await api.kisiApi.fetchPlaces(clubId);
      return result.data as KisiPlace[];
    },
    initialData: [],
    ...queryOpts,
  });
  return result;
}
