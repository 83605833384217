import { assert } from "check-types";

import endpoints from "./endpoints";

const customerAvailabilityApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.customerAvailability}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    hostCalculateAvailability({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
    }) {
      assert.number(appointableId, "appointableId must be a number");
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(dateTo, "dateTo must be a string");
      assert.maybe.array(
        appointableHostsIdList,
        "appointableHostsIdList must be an array of strings",
      );
      const url = `${clubPrefix}${endpoints.customerAvailability}/host/calculate`;
      return state.http.post(url, {
        appointableId,
        dateFrom,
        dateTo,
        appointableHostsIdList,
      });
    },
    facilityCalculateAvailability({
      appointableId,
      dateFrom,
      dateTo,
      appointableHostsIdList,
      appointableFacilityIdList,
    }) {
      assert.number(appointableId, "appointableId must be a number");
      assert.string(dateFrom, "dateFrom must be a string");
      assert.string(dateTo, "dateTo must be a string");
      assert.maybe.array(
        appointableHostsIdList,
        "appointableHostsIdList must be an array of strings",
      );
      assert.maybe.array(
        appointableFacilityIdList,
        "appointableFacilityIdList must be an array of numbers",
      );
      const url = `${clubPrefix}${endpoints.customerAvailability}/facility/calculate`;
      return state.http.post(url, {
        appointableId,
        dateFrom,
        dateTo,
        appointableHostsIdList,
        appointableFacilityIdList,
      });
    },
  });
};

export default customerAvailabilityApi;
