export function RefreshCcwIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      className={className}
    >
      <path
        d="M1.66675 7.33333C1.66675 7.33333 3.33757 5.05685 4.69494 3.69854C6.05232 2.34022 7.92808 1.5 10.0001 1.5C14.1422 1.5 17.5001 4.85786 17.5001 9C17.5001 13.1421 14.1422 16.5 10.0001 16.5C6.58084 16.5 3.69601 14.2119 2.79322 11.0833M1.66675 7.33333V2.33333M1.66675 7.33333H6.66675"
        stroke="#667085"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
}
