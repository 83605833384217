import * as Yup from "yup";

export const LEAD_STATUS = "lead-status";
export const FIRST_NAME = "first-name";
export const LAST_NAME = "last-name";
export const EMAIL = "email";
export const PHONE_NUMBER = "mobile-number";
export const SOURCE_ID = "source-id";
export const SOURCE = "source";
export const EMAIL_COMMUNICATION = "email-communication";
export const SMS_COMMUNICATION = "sms-communication";

const createLeadSchema = () =>
  Yup.object().shape({
    [FIRST_NAME]: Yup.string().required().min(2),
    [LAST_NAME]: Yup.string().required().min(2),
    [EMAIL]: Yup.string().email().required(),
    [PHONE_NUMBER]: (Yup.string() as any).phoneNumber().default(""),
    [SOURCE_ID]: Yup.number().required(),
    [EMAIL_COMMUNICATION]: Yup.boolean().default(false),
    [SMS_COMMUNICATION]: Yup.boolean().default(false),
  });

export default createLeadSchema;
