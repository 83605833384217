import { ActivityCategoryListAsMemberFilter } from "@gymflow/api";
import {
  DATE_FORMAT,
  DATE_FORMAT_WITH_SECONDS,
  DateTimeSelect,
  MiniEventCard,
  useDimensions,
} from "@gymflow/common";
import {
  ActivityCategoryDTO,
  ActivityDTO,
  ApiListResponse,
  CalendarEventOccurrenceAsMember,
  FacilityDTO,
} from "@gymflow/types";
import classNames from "classnames";
import groupBy from "lodash/groupBy";
import moment, { Moment } from "moment-timezone";
import { ReactNode, useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { AsyncPaginate } from "react-select-async-paginate";
import { Button, Card, Col, Row } from "reactstrap";

import { useClubSettings } from "../../providers";

const MIN_WIDTH_CARD = 280;

const selectStyles = {
  control: (provided: any) => ({
    ...provided,
    borderRadius: "10px !important",
    width: "120px !important",
    minWidth: "100px !important",
    border: "1px solid #1A2168 !important",
    borderColor: "#1A2168 !important",
    color: "#1A2168 !important",
    height: "44px",
  }),
  container: (provided: any) => ({ ...provided }),
};

export function EventGrid({
  events = [],
  renderRsvpButton,
  onSelectedDateChange,
  allowFilter = false,
  filter,
  fetchCategories,
  onCategoryFilterChange,
  onActivityFilterChange,
  onFacilityFilterChange,
  fetchFacilities,
  fetchActivities,
  initialDate,
}: {
  events: CalendarEventOccurrenceAsMember[];
  renderRsvpButton: (event: CalendarEventOccurrenceAsMember) => ReactNode;
  onSelectedDateChange: (date: string) => void;
  allowFilter?: boolean;
  filter: any;
  fetchCategories: (
    filter: ActivityCategoryListAsMemberFilter,
  ) => Promise<ApiListResponse<ActivityCategoryDTO>>;
  onCategoryFilterChange: () => void;
  onActivityFilterChange: () => void;
  onFacilityFilterChange: () => void;
  fetchFacilities: ({
    page,
  }: {
    page: number;
  }) => Promise<ApiListResponse<FacilityDTO>>;
  fetchActivities: ({
    page,
  }: {
    page: number;
  }) => Promise<ApiListResponse<ActivityDTO>>;
  initialDate: string;
}) {
  //@ts-ignore-next-line
  let [{ width }, ref] = useDimensions();
  const scrollbarWidth = 16;
  width -= scrollbarWidth;
  const [selectedDate, setSelectedDate] = useState(
    initialDate || moment().format(DATE_FORMAT_WITH_SECONDS),
  );
  interface DisplayDate {
    label: string;
    date: Moment;
    formattedDate: string;
  }
  const [displayDates, setDisplayDates] = useState<(DisplayDate | null)[]>(
    Array.from({ length: 7 }).fill(null) as (DisplayDate | null)[],
  );

  const settings = useClubSettings();

  useEffect(() => {
    setDisplayDates(
      displayDates.map((_, idx) => {
        const today = moment();
        const tomorrow = moment().add(1, "days");
        const date = moment(selectedDate, DATE_FORMAT_WITH_SECONDS).add(
          idx,
          "days",
        );
        const dayOfWeek = date.format("dddd");
        const formattedDate = date.format("MMM Do");
        let label;
        if (date.isSame(today, "day")) {
          label = "Today";
        } else if (date.isSame(tomorrow, "day")) {
          label = "Tomorrow";
        } else {
          label = dayOfWeek;
        }
        return { label, date, formattedDate };
      }),
    );
    onSelectedDateChange(selectedDate);
  }, [selectedDate]);

  const eventGroup = groupBy(
    events.map((e) => ({
      ...e,
      formattedDate: moment(e.startDate).format("MMM Do"),
    })),
    "formattedDate",
  );

  const numberOfElements = Math.max(
    Math.floor(Math.floor(width / MIN_WIDTH_CARD)) - 1,
    1,
  );
  const horizontalMargin = 8;
  const eventGrid = displayDates.filter(Boolean).map((nullableDate, idx) => {
    const date = nullableDate as DisplayDate;
    return (
      <Col
        key={date.label}
        className={classNames(`mx-1 w-full p-0`, {
          "d-none": idx >= numberOfElements,
        })}
        style={{
          maxWidth: width / numberOfElements - horizontalMargin,
        }}
      >
        <Row className="mx-2">
          <Card
            className="d-flex justify-content-between align-items-center flex-row p-3"
            style={{ position: "sticky", top: "0px", zIndex: 2 }}
          >
            <div className="font-weight-bold">
              {date.label}
              <div
                className={classNames("event-circle overflow-hidden", {
                  "d-none": !eventGroup[date.formattedDate],
                })}
              />
            </div>
            <div className="text-muted event-grid-date">
              {date.formattedDate}
            </div>
          </Card>
        </Row>
        <Row style={{ zIndex: 1 }}>
          <Col className="p-0" style={{ minHeight: 400 }}>
            {eventGroup[date.formattedDate]?.map((event) => {
              const {
                eventOccurrenceId: id,
                activityName: name,
                activityDescription: description,
                hostName,
                sessionCost,
                bookable: isBookable,
                facilityName,
                startDate,
                endDate,
              } = event;
              
              const [hostFirstName, hostLastName] = hostName.split(" ");
              return (
                <Row className="m-3" key={id}>
                  <MiniEventCard
                    activityName={name}
                    facilityName={facilityName}
                    sessionCost={sessionCost}
                    activityDescription={description}
                    hostFirstName={hostFirstName}
                    hostLastName={hostLastName}
                    isFullDay={false}
                    startDate={startDate}
                    endDate={endDate}
                    showRsvpButton={isBookable}
                    renderRsvpButton={() => renderRsvpButton(event)}
                  />
                </Row>
              );
            })}
          </Col>
        </Row>
      </Col>
    );
  });

  const arrowButtonStyle = {
    width: 44,
    height: 44,
    fontWeight: "600",
    padding: 0,
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center">
        <Button
          className="mr-2 mt-2"
          style={arrowButtonStyle}
          onClick={() =>
            setSelectedDate(
              moment(selectedDate, DATE_FORMAT_WITH_SECONDS)
                .subtract(1, "day")
                .format(DATE_FORMAT_WITH_SECONDS),
            )
          }
        >
          &#60;
        </Button>
        <DateTimeSelect
          value={moment(selectedDate, DATE_FORMAT_WITH_SECONDS).format(
            DATE_FORMAT,
          )}
          displayValue={moment(selectedDate, DATE_FORMAT_WITH_SECONDS).format(
            settings.date_format,
          )}
          onChange={(v: any) =>
            setSelectedDate(moment(v).format(DATE_FORMAT_WITH_SECONDS))
          }
          isValidDate={(date: Moment) => date.isBefore(moment().add(1, "year"))}
        />
        <Button
          className="ml-2 mt-2"
          style={arrowButtonStyle}
          onClick={() =>
            setSelectedDate(
              moment(selectedDate, DATE_FORMAT_WITH_SECONDS)
                .add(1, "day")
                .format(DATE_FORMAT_WITH_SECONDS),
            )
          }
          disabled={moment(selectedDate, DATE_FORMAT_WITH_SECONDS).isAfter(
            moment().add(1, "year").subtract(1, "day"),
          )}
        >
          &#62;
        </Button>
      </div>

      {allowFilter && (
        <div className="d-flex justify-content-center justify-content-xl-end mt-xl-0 mt-3">
          <div className="ml-2">
            <AsyncPaginate
              additional={{
                page: 0,
              }}
              isSearchable={false}
              className="react-select info react-select-btn"
              classNamePrefix="react-select"
              placeholder="Facility"
              components={{
                DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
                  if (menuIsOpen) {
                    return <ChevronUp />;
                  }
                  return <ChevronDown />;
                },
              }}
              loadOptions={async (_, __, additional) => {
                const response = await fetchFacilities({
                  page: additional?.page ?? 0,
                });
                const options: { value: number | null; label: string }[] =
                  response.content.map((facility) => ({
                    value: facility.id,
                    label: facility.name,
                  }));
                if (additional?.page === 0) {
                  options.unshift({ value: null, label: "All" });
                }
                return {
                  hasMore: !response.last,
                  options,
                  additional: { page: (additional?.page ?? 0) + 1 },
                };
              }}
              value={filter?.facility?.value ? filter.facility : null}
              onChange={onFacilityFilterChange}
              styles={selectStyles}
            />
          </div>
          <div className="ml-2">
            <AsyncPaginate
              additional={{
                page: 0,
              }}
              isSearchable={false}
              className="react-select info react-select-btn"
              classNamePrefix="react-select"
              placeholder="Class"
              components={{
                DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
                  if (menuIsOpen) {
                    return <ChevronUp />;
                  }
                  return <ChevronDown />;
                },
              }}
              loadOptions={async (_, __, additional) => {
                const response = await fetchActivities({
                  page: additional?.page ?? 0,
                });
                const options: { value: number | null; label: string }[] =
                  response.content.map((activity) => ({
                    value: activity.id,
                    label: activity.name,
                  }));
                if (additional?.page === 0) {
                  options.unshift({ value: null, label: "All" });
                }
                return {
                  hasMore: !response.last,
                  options,
                  additional: { page: (additional?.page ?? 0) + 1 },
                };
              }}
              value={filter?.activity?.value ? filter.activity : null}
              onChange={onActivityFilterChange}
              styles={selectStyles}
            />
          </div>
          <div className="ml-2">
            <AsyncPaginate
              additional={{
                page: 0,
              }}
              isSearchable={false}
              className="react-select info react-select-btn"
              classNamePrefix="react-select"
              placeholder="Category"
              components={{
                DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
                  if (menuIsOpen) {
                    return <ChevronUp />;
                  }
                  return <ChevronDown />;
                },
              }}
              loadOptions={async (_, __, additional) => {
                const response = await fetchCategories({
                  limit: 10,
                  page: additional?.page ?? 0,
                  statusList: ["ACTIVE"],
                });
                const options: { value: number | null; label: string }[] =
                  response.content.map((category) => ({
                    value: category.id,
                    label: category.name,
                  }));
                if (additional?.page === 0) {
                  options.unshift({ value: null, label: "All" });
                }
                return {
                  hasMore: !response.last,
                  options,
                  additional: { page: (additional?.page ?? 0) + 1 },
                };
              }}
              value={filter?.category?.value ? filter.category : null}
              onChange={onCategoryFilterChange}
              styles={selectStyles}
            />
          </div>
        </div>
      )}
      <div
        //@ts-ignore-next-line
        ref={ref}
        className="relative mt-6 flex overflow-y-auto overflow-x-hidden"
      >
        {eventGrid}
      </div>
    </>
  );
}
