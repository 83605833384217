import { MembershipSummaryDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useQueryMembershipSummary(
  {
    api,
    opts,
    enabled,
  }: {
    api: {
      serviceApi: {
        summary: (args: {
          membershipId: number;
          startDate: string;
          promotionCode?: string;
          userMemberId: string;
        }) => Promise<{ data: MembershipSummaryDTO }>;
      };
    };
    enabled?: boolean;
    opts?: {
      membershipId: number;
      startDate: string;
      promotionCode?: string;
      userMemberId: string;
    };
  },
  queryOptions?: UseQueryOptions<MembershipSummaryDTO>,
) {
  const result = useQuery({
    queryKey: membershipQueryKeys.summary(opts),
    queryFn: async () => {
      return (await api.serviceApi.summary(opts!)).data;
    },
    enabled: !!opts && enabled,
    ...queryOptions,
  });
  return result;
}
