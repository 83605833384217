import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import omit from "lodash/omit";
import { AsyncPaginate } from "react-select-async-paginate";

import PaymentMethodOption from "./PaymentMethodOption";

function PaymentMethodSelect(props) {
  const { allowedType, showNew, fetchPaymentMethods, value, optional } = props;

  useEffect(() => {
    if (value?.new) {
      setCurrentValue({
        label: renderRowForNew(),
        value: "new-payment-method",
      });
    } else if (value) {
      setCurrentValue({
        label: <PaymentMethodOption {...value} />,
        value,
      });
    } else {
      setCurrentValue({
        label: renderRowForNone(),
        value: "none",
      });
    }
  }, [value]);

  const [currentValue, setCurrentValue] = useState(null);

  const loadOptions = async () => {
    let results = await fetchPaymentMethods();

    if (allowedType !== "all") {
      results = results.filter((r) => r.type === allowedType);
    }
    const options = results.map((r) => {
      return {
        label: <PaymentMethodOption {...r} />,
        value: r,
      };
    });

    if (optional) {
      options.unshift({
        label: renderRowForNone(),
        value: null,
      });
    }

    if (showNew) {
      options.push({
        label: renderRowForNew(),
        value: "new-payment-method",
      });
    }

    return {
      options,
      hasMore: false,
      additional: {
        page: 0,
      },
    };
  };

  const renderRowForNew = () => {
    return (
      <div className="align-text-top">
        <i className="far fa-credit-card invisible" />
        &nbsp;<i> New payment method</i>&nbsp;
      </div>
    );
  };

  const renderRowForNone = () => {
    return (
      <div className="align-text-top">
        <i className="far fa-credit-card invisible" />
        &nbsp;<i> None</i>&nbsp;
      </div>
    );
  };

  const onChangeValue = (v) => {
    setCurrentValue(v);
    if (props.onChange) {
      props.onChange(v);
    }
  };

  const selectProps = omit(props, ["allowedType", "showNew", "onChange", "value"]);

  return (
    <AsyncPaginate
      styles={{
        control: (provided) => ({ ...provided, minHeight: "35.15px", borderRadius: "6.856px" }),
      }}
      isSearchable={false}
      loadOptions={loadOptions}
      value={currentValue}
      onChange={onChangeValue}
      {...selectProps}
    />
  );
}

PaymentMethodSelect.defaultProps = {
  allowedType: "all",
  optional: false,
};

PaymentMethodSelect.propTypes = {
  allowedType: PropTypes.oneOf(["all", "card", "bankAccount"]),
  showNew: PropTypes.bool,
  optional: PropTypes.bool,
};

export default PaymentMethodSelect;
