import { useMutation, useQueryClient } from '@tanstack/react-query';

import { sessionPackQueryKeys } from './sessionPackQueryKeys';

export function useSessionPackEnable({
  api,
}: {
  api: {
    creditPackApi: {
      activate: (sessionPackId: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (sessionPackId: number) => {
      await api.creditPackApi.activate(sessionPackId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: sessionPackQueryKeys.all() });
    },
  });

  return mutation;
}
