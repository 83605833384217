import { SlotLabelContentArg } from "@fullcalendar/core";
import format from "date-fns/format";

export function SlotLabelContent({ date }: SlotLabelContentArg) {
  return (
    <div className="text-primary-50 relative">
      {format(date, "h:mm a")}
      <div className="absolute -top-3 text-sm font-medium text-gray-400">
        {format(date, "h:mm a")}
      </div>
    </div>
  );
}
