import { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import noop from 'lodash/noop';
import { Clock, MapPin, UserPlus } from 'react-feather';
import moment from 'moment-timezone';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { pluralize } from '@gymflow/helpers';

import { format } from 'date-fns';
import useGenerateElementId from '../../hooks/useGenerateElementId';
import { humanizeTimeInterval } from '../../helpers/date';
import { AlertContext } from '../../providers/Alert';
import styles from './EventCard.module.css';

function EventCard({
  eventId,
  onCardClick,
  activityName,
  activityDescription,
  facilityName,
  startDate,
  endDate,
  waitListCapacity,
  waitingCount,
  isBookable,
  hostFirstName,
  hostLastName,
  hostPicture,
  showDateLine,
  showRsvpButton,
  placeholder,
  creditCost,
  renderRsvpButton,
  isFullDay,
  style: styleProp,
}) {
  const generateId = useGenerateElementId();
  const { hide, setAlert } = useContext(AlertContext);

  const style = {
    ...styleProp,
  };

  if (onCardClick !== noop) {
    style.cursor = 'pointer';
  }

  const iconSize = '18';
  const iconDivStyle = { width: `${iconSize}px`, marginTop: '-1px' };
  const textDivProps = { className: classNames('ml-1', styles['event-card__text']) };
  const colSizeProps = { md: '4', xl: '2' };

  const renderFacility = () => (
    <div className="d-flex">
      <div className="d-inline-block" style={iconDivStyle}>
        <MapPin size={iconSize} />
      </div>
      <div {...textDivProps}>{renderTextSpan(facilityName)}</div>
    </div>
  );

  const renderTextSpan = (text, threshold = 15) => {
    const spanProps = {};
    if (text && text.length > threshold) {
      spanProps.title = text;
    }
    return <span {...spanProps}>{text}</span>;
  };

  const renderStartTime = () => (
    <div className="d-flex">
      <div className="d-inline-block" style={iconDivStyle}>
        <Clock size={iconSize} />
      </div>
      <div {...textDivProps}>
        {isFullDay ? 'Full Day' : `${format(new Date(startDate), 'h:mm a')} ${humanizeTimeInterval(startDate, endDate)}`}
      </div>
    </div>
  );

  const renderCreditCost = () => {
    if (!isBookable) {
      return null;
    }
    return (
      <div className="d-flex">
        <div {...textDivProps}>{renderTextSpan(`${creditCost} ${pluralize('Session', 'Sessions', creditCost)}`)}</div>
      </div>
    );
  };

  const renderWaitList = () => {
    if (!isBookable) {
      return null;
    }
    return (
      <div className="d-flex">
        <div className="d-inline-block" style={iconDivStyle}>
          <UserPlus size={iconSize} />
        </div>
        <div {...textDivProps}>{renderTextSpan(`${waitingCount}/${waitListCapacity} Waiting`)}</div>
      </div>
    );
  };

  const renderHost = () => {
    const pictureId = generateId({ key: eventId, prefix: 'member-avatar' });
    const hostName = `${hostFirstName} ${hostLastName}`;
    return (
      <div>
        <div className="text-truncate d-flex align-items-center">
          <img
            src={hostPicture || placeholder}
            style={{ width: '30px', height: '30px', borderRadius: '15px', marginTop: '4px' }}
            id={pictureId}
            alt="host"
          />
          <span className="ml-2">{renderTextSpan(hostName)}</span>
        </div>
      </div>
    );
  };

  const renderActivity = () => {
    if (activityDescription) {
      return (
        <u
          role="button"
          style={{ cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setAlert(
              <ReactBSAlert title={activityName} onConfirm={hide}>
                {activityDescription}
              </ReactBSAlert>
            );
          }}
        >
          {activityName}
        </u>
      );
    }
    return <span>{activityName}</span>;
  };

  return (
    <Card className="pr-3 pl-3 text-nowrap" style={style} onClick={() => onCardClick({ id: eventId })}>
      <Row
        className={classNames('pt-2', 'pb-2', 'font-weight-bold', 'text-center', 'text-xl-left', {
          'd-none': !showDateLine,
        })}
      >
        <Col>{moment(startDate).format('LL')}</Col>
      </Row>
      <Row
        className="align-items-center d-flex align-items-center justify-content-center"
        style={{
          minHeight: '64.9px',
        }}
      >
        <Col {...colSizeProps} className={classNames('pt-2', 'pb-2', 'font-weight-bold', styles['event-card__text'])}>
          {renderActivity()}
        </Col>

        <Col {...colSizeProps} className={classNames('pt-2', 'pb-2', styles['event-card__text'])}>
          {renderStartTime()}
        </Col>
        <Col {...colSizeProps} className={classNames('pt-2', 'pb-2', styles['event-card__text'])}>
          {renderFacility()}
        </Col>
        <Col {...colSizeProps} className={classNames('pt-2', 'pb-2', styles['event-card__text'])}>
          {renderCreditCost()}
        </Col>
        <Col
          {...colSizeProps}
          className={classNames('pt-2', 'pb-2', styles['event-card__text'], { 'd-none': showRsvpButton })}
        >
          {renderWaitList()}
        </Col>
        <Col {...colSizeProps} className={classNames('pt-2', 'pb-2', styles['event-card__text'])}>
          {renderHost()}
        </Col>
        <Col
          {...colSizeProps}
          className={classNames('pt-2', 'pb-2', styles['event-card__'], { 'd-none': !showRsvpButton })}
        >
          {showRsvpButton && renderRsvpButton()}
        </Col>
      </Row>
    </Card>
  );
}

EventCard.defaultProps = {
  activityDescription: '',
  creditCost: 0,
  hostPicture: '',
  onCardClick: noop,
  placeholder: '',
  renderRsvpButton: noop,
  showDateLine: false,
  showRsvpButton: false,
  style: {},
};

EventCard.propTypes = {
  eventId: PropTypes.number.isRequired,
  onCardClick: PropTypes.func,
  activityName: PropTypes.string.isRequired,
  activityDescription: PropTypes.string,
  facilityName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  capacity: PropTypes.number.isRequired,
  waitListCapacity: PropTypes.number.isRequired,
  bookedCount: PropTypes.number.isRequired,
  waitingCount: PropTypes.number.isRequired,
  isBookable: PropTypes.bool.isRequired,
  hostFirstName: PropTypes.string.isRequired,
  hostLastName: PropTypes.string.isRequired,
  hostPicture: PropTypes.string,
  showDateLine: PropTypes.bool,
  showRsvpButton: PropTypes.bool,
  renderRsvpButton: PropTypes.func,
  isFullDay: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  creditCost: PropTypes.number,
  style: PropTypes.object,
};

export default EventCard;
