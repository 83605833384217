import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberChangePicture({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      blob,
      name,
    }: {
      memberId: string;
      blob: string;
      name: string;
    }) => {
      return api.memberApi.updatePicture(memberId, { blob, name });
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
    },
  });
  return mutation;
}
