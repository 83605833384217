import { FacilityCreateDTO, FacilityDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { facilityQueryKeys } from "./facilityQueryKeys";

export function useFacilityEdit({
  api,
}: {
  api: {
    facilityApi: {
      update: (arg0: number, arg1: unknown) => Promise<{ data: FacilityDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      facilityId,
      patchedFields,
    }: {
      facilityId: number;
      patchedFields: Partial<FacilityCreateDTO>;
    }) => {
      const result = await api.facilityApi.update(facilityId, patchedFields);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: facilityQueryKeys.all() });
    },
  });
  return mutation;
}
