import { MouseEventHandler, useCallback, useState } from "react";

// @deprecated - use useMutation instead
export function useProgressButton({
  onClick,
  disabled,
}: {
  onClick?: MouseEventHandler;
  disabled?: boolean;
}) {
  const [isOnClickRunning, setOnClickRunning] = useState(false);
  const onClickWrapper = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      setOnClickRunning(true);
      if (onClick) {
        await onClick(e);
      }
      setOnClickRunning(false);
    },
    [onClick],
  );

  return {
    disabled: isOnClickRunning || disabled,
    onClick: onClickWrapper,
    inProgress: isOnClickRunning,
  };
}
