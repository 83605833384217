import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { zonedTimeToUtc } from "@gymflow/helpers";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { membershipQueryKeys } from "../membership";
import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberSubscription(
  {
    api,
    tz,
  }: {
    api: ApiType;
    tz: string;
  },
  mutationOpts?: UseMutationOptions<any, any, any, any>,
) {
  const queryClient = useQueryClient();
  type ResumeSubscriptionMutationParams = {
    memberId: string;
    subscriptionId: number;
    resumeDate: string;
    isWaived: boolean;
    paymentMethodId?: string;
  };
  const resumeSubscriptionEarlyMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      resumeDate,
      isWaived,
      paymentMethodId,
    }: ResumeSubscriptionMutationParams) => {
      const resumeDateUtc = zonedTimeToUtc(resumeDate, tz);

      return api.memberApi.resumeSubscriptionEarly(
        memberId,
        subscriptionId,
        resumeDateUtc,
        isWaived,
        paymentMethodId,
      );
    },
    onSuccess: (_, { memberId }: ResumeSubscriptionMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type ExpireSubscriptionMutationParams = {
    memberId: string;
    subscriptionId: number;
    expiredDate?: string;
  };
  const expireSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      expiredDate,
    }: ExpireSubscriptionMutationParams) => {
      let expiredDateUtc;
      if (expiredDate) {
        expiredDateUtc = zonedTimeToUtc(expiredDate, tz);
      }
      return api.memberApi.expireSubscription(
        memberId,
        subscriptionId,
        expiredDateUtc,
      );
    },
    onSuccess: (_, { memberId }: ExpireSubscriptionMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type ChangeSubscriptionPriceMutationParams = {
    clubId: number;
    memberId: string;
    subscriptionId: number;
    newSubscriptionPrice: number;
  };
  const changeSubscriptionPriceMutation = useMutation({
    mutationFn: ({
      clubId,
      memberId,
      subscriptionId,
      newSubscriptionPrice,
    }: ChangeSubscriptionPriceMutationParams) =>
      api.memberApi.changeSubscriptionPrice(
        clubId,
        memberId,
        subscriptionId,
        newSubscriptionPrice,
      ),
    onSuccess: (_, { memberId }: ChangeSubscriptionPriceMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type PauseSubscriptionMutationParams = {
    memberId: string;
    subscriptionId: number;
    immediately: boolean;
    pauseDate?: string;
    resumeDate: string;
  };
  const pauseSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      immediately,
      pauseDate,
      resumeDate,
    }: PauseSubscriptionMutationParams) => {
      let pauseDateUtc;
      if (!immediately) {
        pauseDateUtc = zonedTimeToUtc(pauseDate!, tz);
      }
      const resumeDateUtc = zonedTimeToUtc(resumeDate, tz);

      return api.memberApi.pauseSubscription(
        memberId,
        subscriptionId,
        immediately,
        pauseDateUtc,
        resumeDateUtc,
      );
    },
    onSuccess: (_, { memberId }: PauseSubscriptionMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type CancelPauseSubscriptionMutationParams = {
    memberId: string;
    subscriptionId: number;
  };
  const cancelPauseSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
    }: CancelPauseSubscriptionMutationParams) =>
      api.memberApi.cancelPauseSubscription(memberId, subscriptionId),
    onSuccess: (_, { memberId }: CancelPauseSubscriptionMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type CancelSubscriptionMutationParams = {
    memberId: string;
    subscriptionId: number;
    immediately: boolean;
    when?: string;
  };
  const cancelSubscriptionMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      immediately,
      when,
    }: CancelSubscriptionMutationParams) => {
      const whenUtc = when && zonedTimeToUtc(when, tz);
      return api.memberApi.cancelSubscription(
        memberId,
        subscriptionId,
        immediately,
        whenUtc,
      );
    },
    onSuccess: (_, { memberId }: CancelSubscriptionMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type UpdateSubscriptionPendingDateMutationParams = {
    memberId: string;
    subscriptionId: number;
    immediately: boolean;
    when?: string;
  };
  const updateSubscriptionPendingDateMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      immediately,
      when,
    }: UpdateSubscriptionPendingDateMutationParams) => {
      const whenUtc = immediately ? undefined : zonedTimeToUtc(when!, tz);
      return api.memberApi.updatePendingDate(
        memberId,
        subscriptionId,
        immediately,
        whenUtc,
      );
    },
    onSuccess: (
      _,
      { memberId }: UpdateSubscriptionPendingDateMutationParams,
    ) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type RevokeCancellationMutationParams = {
    memberId: string;
    subscriptionId: number;
  };
  const revokeCancellationMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
    }: RevokeCancellationMutationParams) =>
      api.memberApi.revokeCancellation(memberId, subscriptionId),
    onSuccess: (_, { memberId }: RevokeCancellationMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type AdjustSessionPackMutationParams = {
    memberId: string;
    subscriptionId: number;
    newAmount: number;
  };
  const adjustSessionPackMutation = useMutation({
    mutationFn: ({
      memberId,
      subscriptionId,
      newAmount,
    }: AdjustSessionPackMutationParams) =>
      api.memberApi.adjustSessionPack(memberId, subscriptionId, newAmount),
    onSuccess: (_, { memberId }: AdjustSessionPackMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type ChangeSubscriptionMutationParams = {
    clubId: number;
    memberId: string;
    newMembershipId: number;
    paymentMethodId?: string;
    isWaived?: boolean;
  };
  const changeSubscriptionMutation = useMutation({
    mutationFn: ({
      clubId,
      memberId,
      newMembershipId,
      paymentMethodId,
      isWaived,
    }: ChangeSubscriptionMutationParams) =>
      api.memberApi.changeSubscription(
        clubId,
        memberId,
        newMembershipId,
        paymentMethodId,
        isWaived,
      ),
    onSuccess: (_, { memberId }: ChangeSubscriptionMutationParams) => {
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: memberQueryKeys.timeline(memberId),
      });
      queryClient.invalidateQueries({
        queryKey: membershipQueryKeys.statusHistory(memberId),
      });
    },
    ...(mutationOpts as any),
  });
  type ChangeBillingDateMutationParams = {
    clubId: number;
    subscriptionId: number;
    newDate: string;
  };
  const changeBillingDateMutation = useMutation({
    mutationFn: ({
      clubId,
      subscriptionId,
      newDate,
    }: ChangeBillingDateMutationParams) =>
      api.memberApi.changeBillingDate(clubId, subscriptionId, newDate),
    onSuccess: (_, __: ChangeBillingDateMutationParams) => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });

      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...(mutationOpts as any),
  });

  type ChangeContractEndDateMutationParams = {
    subscriptionId: number;
    newDate: string;
  };
  const changeContractEndDateMutation = useMutation({
    mutationFn: ({
      subscriptionId,
      newDate,
    }: ChangeContractEndDateMutationParams) => {
      return api.memberApi.changeContractEndDate(subscriptionId, newDate);
    },
    onSuccess: (_, __: ChangeContractEndDateMutationParams) => {
      queryClient.invalidateQueries({ queryKey: memberQueryKeys.all() });
    },
    ...(mutationOpts as any),
  });
  return {
    resumeSubscriptionEarlyMutation,
    expireSubscriptionMutation,
    changeSubscriptionPriceMutation,
    pauseSubscriptionMutation,
    cancelPauseSubscriptionMutation,
    cancelSubscriptionMutation,
    updateSubscriptionPendingDateMutation,
    revokeCancellationMutation,
    adjustSessionPackMutation,
    changeSubscriptionMutation,
    changeBillingDateMutation,
    changeContractEndDateMutation,
  };
}
