import { FormMapper } from "@gymflow/common";

import { EMAIL } from "./schema";

const createLeadMapper = () =>
  new FormMapper({
    outValue: [{ key: EMAIL, transform: (v: string) => v.toLowerCase() }],
  });

export default createLeadMapper;
