import {isAfter, parse, sub} from 'date-fns';
import {z} from 'zod';
import {DATE_FORMAT, UnionToIntersection} from './event';

export const TaskSchema = z.object({
  name: z.string().min(1).max(50),
  notes: z.string().optional(),
  deadlineDate: z
    .string()
    .refine(
      value =>
        isAfter(
          parse(value, DATE_FORMAT, new Date()),
          sub(new Date(), {years: 1}),
        ),
      {message: 'validation.dateIsToSmall'},
    ),
  deadlineTime: z.string(),
  relatedLeadIdList: z.array(z.number()).optional().default([]),
  relatedUserIdList: z.array(z.string()).optional().default([]),
  ownerIdList: z.array(z.string()).optional().default([]),
  complete: z.boolean().optional().default(false),
});

export const validateTask = (task: unknown) => {
  return TaskSchema.parse(task);
};

export type ValidatedTask = UnionToIntersection<
  ReturnType<typeof validateTask>
>;
export type NotValidatedTask = {[key in keyof ValidatedTask]: any};
