import omit from "lodash/omit";
import { CardBody } from "reactstrap";
import PropTypes from "prop-types";

import Spinner from "./Spinner";

/**
 * @deprecated use tailwind components
 */
function CardBodyWithSpinner(props) {
  const cardBodyProps = omit(props, ["isLoading"]);
  const { isLoading, children } = props;
  return (
    <CardBody {...cardBodyProps}>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div style={{ opacity: isLoading ? "0.2" : "1" }}> {children}</div>
    </CardBody>
  );
}

CardBodyWithSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export default CardBodyWithSpinner;
