import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { AsyncPaginate } from "react-select-async-paginate";
import useGymflowModels from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

//TODO: Replace with atoms/StaffSelect
/**
 * @deprecated atoms/StaffSelect
 */
const StaffSelect = ({ value, onChange, placeholder, isMulti, className, ...rest }) => {
  const { api } = useGymflowModels();
  const loadOptions = async (term, _, { page }) => {
    const { data } = await api.staffApi.find({ page, extraParams: { activeUser: true } });
    const rows = data.content.map((user) => {
      return {
        label: `${user.firstName} ${user.lastName}`,
        value: user,
      };
    });

    return {
      options: rows,
      hasMore: !data.last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      additional={{
        page: 0,
      }}
      components={{
        DropdownIndicator: ({ selectProps: { menuIsOpen } }) => {
          if (menuIsOpen) {
            return <FontAwesomeIcon icon={faAngleUp} className="ml-2 mr-4 text-gray-500" />;
          }
          return <FontAwesomeIcon icon={faAngleDown} className="ml-2 mr-4 text-gray-500" />;
        },
        IndicatorSeparator: () => {
          return null;
        },
        ValueContainer: ({ children }) => {
          return (
            <div className="ml-4 flex max-w-[70%] items-center overflow-x-hidden font-semibold [&>div]:!text-gray-500">
              {children}
            </div>
          );
        },
      }}
      classNames={{
        placeholder: () => "!flex !items-center",
        control: () =>
          "!border-none bg-transparent !outline-0 !shadow-none !flex !flex-row justify-between w-full !flex-nowrap",
        menu: () => "!z-[9999] !rounded-lg",
        option: () => classNames(`!cursor-pointer hover:!bg-primary-50 !bg-white !text-gray-800`),
        valueContainer: () => "border-none rounded-xl shadow-none h-[calc(2.75rem-2px)] cursor-pointer",
      }}
      className="bg-gray-25 flex h-11 min-w-[10rem] cursor-pointer items-center rounded-lg border border-gray-300 text-center hover:bg-gray-100"
      isMulti={isMulti}
      loadOptions={loadOptions}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      menuContainerStyle={{ zIndex: "99999 !important" }}
      isSearchable={false}
      {...rest}
    />
  );
};

StaffSelect.defaultProps = {
  isMulti: false,
  placeholder: "Select staff member",
};

StaffSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(null)]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default StaffSelect;
