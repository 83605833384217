import { RuleGroupPost } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleGroupNew({
  api,
}: {
  api: {
    ruleApi: {
      newRuleGroup: (fields: RuleGroupPost) => Promise<void>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ fields }: { fields: RuleGroupPost }) =>
      api.ruleApi.newRuleGroup(fields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
  return mutation;
}
