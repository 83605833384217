import { cn } from "@gymflow/helpers";

import { AppStoreButton, CheckCircleIcon, PlayStoreButton } from "../../atoms";

export function MigrationSuccess() {
  const system = getMobileOperatingSystem();
  const isDesktop = system !== "Android" && system !== "iOS";
  return (
    <div className="mt-16 flex justify-center">
      <div className="flex max-w-[20.5rem] flex-col gap-8">
        <div>
          <div className="flex flex-col items-center gap-4">
            <div className="flex h-14 w-14 items-center justify-center rounded-[32rem] border-8 border-[#ECFDF3] bg-[#DCFAE6] dark:border-[#053321] dark:bg-[#074D31]">
              <CheckCircleIcon
                className="h-7 w-7"
                pathClassName="stroke-success-600 dark:stroke-[#17B26A]"
              />
            </div>
            <div className="flex flex-col items-center gap-2">
              <div className="text-gray-950 dark:text-gray-25 text-center text-xl font-bold">
                Account Transferred Successfully
              </div>
              <div className="text-center text-base font-medium text-gray-500 dark:text-gray-400">
                You can now access your account.
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[4.875rem] flex flex-col gap-4">
          <div className="dark:bg-gray-950 flex flex-col items-center gap-4 rounded-2xl border border-[#E4E7EC] bg-white px-3 py-4 dark:border-gray-600">
            <div>
              <div className="bg-gymflow-logo h-6 w-48 bg-contain bg-center bg-no-repeat"></div>
            </div>
            <div className="text-center text-sm font-medium text-gray-500 dark:text-gray-400">
              We will be in touch to confirm next steps. But you can get started
              by downloading our mobile app.
            </div>
            <div
              className={cn("w-100 flex items-center gap-4", {
                "justify-between": isDesktop,
                "justify-center": !isDesktop,
              })}
            >
              <PlayStoreButton
                className={cn("hidden h-10", {
                  block: system === "Android" || isDesktop,
                })}
              />
              <AppStoreButton
                className={cn("hidden h-10", {
                  block: system === "iOS" || isDesktop,
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getMobileOperatingSystem() {
  // @ts-ignore
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone" as const;
  }

  if (/android/i.test(userAgent)) {
    return "Android" as const;
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  // @ts-ignore
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS" as const;
  }

  return "unknown" as const;
}
