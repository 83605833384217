import {
  CardBodyWithSpinner,
  DATE_FORMAT,
  gender,
  PARAMETER_DATE_ONLY_FORMAT,
  UserForm,
  UserFormField,
} from "@gymflow/common";
import memoizeOne from "memoize-one";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import { useValidateMemberDetails } from "../../hooks/useValidateMemberDetails";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import UserSelect from "./UserSelect";

const {
  ADDRESS1,
  ADDRESS2,
  CITY,
  DATE_OF_BIRTH,
  EMAIL,
  EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_NAME,
  FIRST_NAME,
  GENDER,
  LAST_NAME,
  MOBILE_NUMBER,
  POST_CODE,
  NATIONALITY,
  EMAIL_COMMUNICATION,
  SMS_COMMUNICATION,
  SOURCE,
  IS_CLUB_WAIVER_ACCEPTED,
} = UserFormField;

function UserDetails({
  fetchLeadSources,
  searchUsers,
  onUserSelectClick,
  postCodeFormat,
  telephoneFormat,
  defaultNationality,
  dateFormat,
  onUserPrefil,
  initialValues,
  requiredFields,
  clubWaiverLink,
}) {
  const { api } = useGymflowModels();
  const findByEmail = memoizeOne(api.memberApi.findByEmail);
  const userSelectRef = useRef();
  const [showForm, setShowForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const isNew = !selectedUser?.id;

  const settings = useClubSettings();
  const allowPhoneCountrySelection = settings.allow_phone_country_selection;

  const openFormAndFillWithUser = () => {
    setShowForm(true);
  };

  const openFormAndReset = () => {
    setShowForm(true);
    setSelectedUser(null);
  };

  useEffect(() => {
    if (!initialValues) {
      return;
    }
    setSelectedUser(initialValues);
    openFormAndFillWithUser();
  }, [initialValues]);

  const userFormValue = useMemo(
    () =>
      selectedUser && {
        [EMAIL]: selectedUser.email,
        [FIRST_NAME]: selectedUser.firstName,
        [LAST_NAME]: selectedUser.lastName,
        [MOBILE_NUMBER]: selectedUser.mobileNumber,
        [DATE_OF_BIRTH]: selectedUser.dateBirth
          ? moment(
              selectedUser.dateBirth,
              PARAMETER_DATE_ONLY_FORMAT,
              true,
            ).format(DATE_FORMAT)
          : null,
        [POST_CODE]: selectedUser.postCode,
        [ADDRESS1]: selectedUser.addressLine1,
        [ADDRESS2]: selectedUser.addressLine2,
        [CITY]: selectedUser.city,
        [GENDER]: selectedUser.gender || gender.PreferNotToSay,
        [NATIONALITY]: selectedUser.country || defaultNationality,
        [EMERGENCY_CONTACT_NAME]: selectedUser.emergencyContactName,
        [EMERGENCY_CONTACT]: selectedUser.emergencyContact
          ? selectedUser.emergencyContact
          : "",
        [EMAIL_COMMUNICATION]: !!selectedUser.emailCommunication,
        [SMS_COMMUNICATION]: !!selectedUser.smsCommunication,
        [SOURCE]: selectedUser?.lead?.source,
        [IS_CLUB_WAIVER_ACCEPTED]: selectedUser?.isClubWaiverAccepted,
      },
    [selectedUser],
  );

  const validEmails = useMemo(() => {
    return selectedUser ? [selectedUser.email] : [];
  }, [selectedUser]);
  const { validateMemberDetails } = useValidateMemberDetails({
    sellerUserType: "STAFF",
  });

  return (
    <CardBodyWithSpinner isLoading={isLoading} className="mt-0 pt-0">
      <Row>
        <Col sm="7" md="8" lg="9">
          <UserSelect
            value={
              !!selectedUser
                ? {
                    email: selectedUser.email,
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName,
                    userType: selectedUser.UserProfileType,
                    picture: selectedUser.picture,
                  }
                : null
            }
            search={searchUsers}
            onChange={async ({ value }) => {
              const isValid = await onUserPrefil(value);
              if (!isValid) {
                userSelectRef.current.blur();
                return;
              }

              setSelectedUser(value);
              const needsFurtherDetails = !(await validateMemberDetails(value));

              if (needsFurtherDetails) {
                openFormAndFillWithUser();
              } else {
                onUserSelectClick({ user: value, wasEdited: false });
              }
            }}
            onUserEditClick={async ({ value }) => {
              const isValid = await onUserPrefil(value);
              if (!isValid) {
                userSelectRef.current.blur();
                return;
              }
              setSelectedUser(value);
              openFormAndFillWithUser();
            }}
            beforeUserChange={() => setIsLoading(true)}
            afterUserChange={() => setIsLoading(false)}
            selectRef={(ref) => {
              userSelectRef.current = ref;
            }}
          />
        </Col>
        <Col
          sm="1"
          className="font-weight-bold d-flex justify-content-center align-items-center"
        >
          <div className="mt-sm-0 mb-sm-0 my-2">or</div>
        </Col>
        <Col sm="4" md="3" lg="2" className="text-sm-left text-center">
          <Button
            className="font-weight-bold"
            color="primary"
            size="sm"
            style={{ minWidth: "140px" }}
            onClick={openFormAndReset}
          >
            Create New Account
          </Button>
        </Col>
      </Row>
      {showForm && (
        <UserForm
          submitBtnText={isNew ? "Create Account" : "Next"}
          fetchLeadSources={fetchLeadSources}
          postCodeFormat={postCodeFormat}
          telephoneFormat={telephoneFormat}
          defaultNationality={defaultNationality}
          dateFormat={dateFormat}
          validEmails={validEmails}
          value={userFormValue}
          onSubmit={async ({ values, isValid }) => {
            if (!isValid) {
              return;
            }
            setIsLoading(true);

            await onUserSelectClick({
              user: {
                ...values,
                profileType: selectedUser?.profileType,
                id: selectedUser?.id,
                isMiniUser: !selectedUser?.isFullUser,
              },
              wasEdited: true,
            });
            setIsLoading(false);
          }}
          findByEmail={async (email) => {
            const response = await findByEmail(email);
            if (response) {
              return "Email already exists.";
            }
            return false;
          }}
          allowPhoneCountrySelection={allowPhoneCountrySelection}
          requiredFields={requiredFields}
          clubWaiverLink={clubWaiverLink}
          phone_number_country={settings.phone_number_country}
          isStaffPerspective
        />
      )}
    </CardBodyWithSpinner>
  );
}

UserDetails.defaultProps = {
  onUserPrefil: () => Promise.resolve(true),
  initialValues: null,
  requiredFields: {
    mobileNumber: true,
    dateOfBirth: true,
    addressLine1: true,
    addressLine2: true,
    postCode: true,
    city: true,
    gender: true,
    emergencyContact: true,
  },
};

UserDetails.propTypes = {
  searchUsers: PropTypes.func.isRequired,
  fetchLeadSources: PropTypes.func.isRequired,
  onUserSelectClick: PropTypes.func.isRequired,
  postCodeFormat: PropTypes.string.isRequired,
  telephoneFormat: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultNationality: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  onUserPrefil: PropTypes.func,
  initialValues: PropTypes.object,
  requiredFields: PropTypes.shape({
    mobileNumber: PropTypes.bool,
    dateOfBirth: PropTypes.bool,
    addressLine: PropTypes.bool,
    postCode: PropTypes.bool,
    city: PropTypes.bool,
    gender: PropTypes.bool,
    emergencyContact: PropTypes.bool,
  }),
  clubWaiverLink: PropTypes.string.isRequired,
};

export default UserDetails;
