import { zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation } from "@tanstack/react-query";

export function useLeadReportCsv({
  api,
  tz,
}: {
  api: {
    leadApi: {
      reportCsv: (params: {
        dateFrom: string;
        dateTo: string;
        presetLeadStatus: string;
        leadSourceList?: number[];
        page?: number;
        limit?: number;
        sort?: any;
      }) => Promise<any>;
    };
  };
  tz: string;
}) {
  const csvMutation = useMutation({
    mutationFn: async ({
      startDate,
      endDate,
      leadSourceList,
      presetLeadStatus,
    }: {
      startDate?: string;
      endDate?: string;
      leadSourceList?: number[];
      presetLeadStatus: string;
    }) => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.leadApi.reportCsv({
        dateFrom: utcStart,
        dateTo: utcEnd,
        leadSourceList,
        presetLeadStatus,
      });
      return result.data as string;
    },
  });
  return csvMutation;
}
