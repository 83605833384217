import { action, actionOn, thunk } from 'easy-peasy';

import BaseModelBuilder, { getApi, createPayloadFromFindResponse } from './BaseModelBuilder';

class EmailTemplateModelBuilder extends BaseModelBuilder {
  constructor(apiKey, automationsApiKey, clubId) {
    super(apiKey);
    this.clubId = clubId;
    this.automationsApiKey = automationsApiKey;
    this.generators.push(this.templateGenerator);
  }

  templateGenerator() {
    return {
      automationsApiKey: this.automationsApiKey,
      clubId: this.clubId,
      create: thunk(
        /**
         * @param actions
         * @param fields
         * @returns {Promise<*>}
         */
        (_, fields, { injections, getState }) => {
          return getApi(injections, getState()).create({
            ...fields,
            clubId: this.clubId,
          });
        }
      ),
      placeholders: [],
      fetchList: thunk(async (actions, { page = 0, limit = 10, sort }, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).find({
          page,
          limit,
          sort,
          extraParams: {
            isAutomationTemplate: false,
          },
        });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList(payload);
        return payload;
      }),

      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const { data: template } = await getApi(injections, getState()).findById(recordId);
        await actions.fetchPlaceholders(template.templatePlaceholderType);
        actions.fetchedRecord({ template });
        return { template };
      }),

      fetchedRecord: action((state, { template }) => {
        state.editing = template;
      }),

      fetchPlaceholders: thunk(async (actions, placeholderType, { getState, injections }) => {
        const { data: placeholders } = await injections.api[getState().automationsApiKey].placeholders(placeholderType);
        actions.fetchedPlaceholders(placeholders);
      }),

      fetchedPlaceholders: action((state, placeholders) => {
        state.placeholders = placeholders;
      }),

      onFetchRecordStart: actionOn(
        (actions) => actions.fetchById.startType,
        (state) => {
          state.loadingRecord = true;
        }
      ),

      onFetchRecordDone: actionOn(
        (actions) => [actions.fetchById.successType, actions.fetchById.failType],
        (state) => {
          state.loadingRecord = false;
        }
      ),
    };
  }
}

export default EmailTemplateModelBuilder;
