import {
  faComment,
  faEnvelope,
  faPhoneAlt,
  faPlus,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { ReactNode, useCallback } from "react";
import { Button, ButtonGroup, Media } from "reactstrap";

import placeholder from "../../../assets/img/placeholder.jpg";
import styles from "./SearchModalResult.module.css";

export function SearchModalResult({
  title,
  text,
  picture = placeholder,
  onClick,
  onTaskClick,
  onPhoneClick,
  onEnvelopClick,
  onCommentClick,
  onNoteClick,
}: {
  title: ReactNode;
  text?: string;
  picture: string;
  onClick: () => void;
  onPhoneClick?: () => void;
  onEnvelopClick?: () => Promise<void>;
  onCommentClick?: () => void;
  onNoteClick?: () => void;
  onTaskClick?: () => void;
}) {
  const handleTaskClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onTaskClick && onTaskClick();
    },
    [onTaskClick],
  );

  const handlePhoneClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onPhoneClick && onPhoneClick();
    },
    [onPhoneClick],
  );

  const handleEnvelopClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onEnvelopClick && onEnvelopClick();
    },
    [onEnvelopClick],
  );

  const handleCommentClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onCommentClick && onCommentClick();
    },
    [onCommentClick],
  );

  const handleNoteClick = useCallback(
    (event: any) => {
      event.preventDefault();
      event.stopPropagation();
      onNoteClick && onNoteClick();
    },
    [onNoteClick],
  );

  return (
    <div className="d-flex align-items-center w-100 h-100">
      <Media onClick={onClick} className="w-100" style={{ cursor: "pointer" }}>
        <Media left href="#">
          <Media
            object
            src={picture}
            className={classNames(
              "hidden sm:block",
              "media-object",
              styles["result-icon"],
            )}
          />
        </Media>
        <Media
          body
          className={classNames(
            "d-flex justify-content-between",
            styles["result-text"],
          )}
        >
          <div>
            <Media heading className={styles["result-text-title"]}>
              {title}
            </Media>
            {text && <p className={styles["result-text-info"]}>{text}</p>}
          </div>
          <ButtonGroup
            className={classNames(styles["right-actions"], "hidden sm:flex")}
          >
            {onTaskClick && (
              <Button onClick={handleTaskClick}>
                <FontAwesomeIcon icon={faPlus} style={{ fontSize: 10 }} />
              </Button>
            )}
            {onPhoneClick && (
              <Button onClick={handlePhoneClick}>
                <FontAwesomeIcon icon={faPhoneAlt} style={{ fontSize: 10 }} />
              </Button>
            )}
            {onEnvelopClick && (
              <Button onClick={handleEnvelopClick}>
                <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: 10 }} />
              </Button>
            )}
            {onCommentClick && (
              <Button onClick={handleCommentClick}>
                <FontAwesomeIcon icon={faComment} style={{ fontSize: 10 }} />
              </Button>
            )}
            {onNoteClick && (
              <Button onClick={handleNoteClick}>
                <FontAwesomeIcon icon={faStickyNote} style={{ fontSize: 10 }} />
              </Button>
            )}
          </ButtonGroup>
        </Media>
      </Media>
    </div>
  );
}
