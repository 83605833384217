import check, { assert } from "check-types";

import endpoints from "./endpoints";
import { canFind, canFindById } from "./features/apiResource";
import GuestStatus from "./GuestStatus.ts";

const customerOccurrenceEndpoints = {
  rsvp: `${endpoints.customerOccurrence}/rsvp`,
  attending: `${endpoints.customerOccurrence}/agenda`,
  calendarEventOccurrences: `customer/calendar/event-occurrences`,
  userMemberBookingAgenda: `customer/user-member-booking-agenda`,
};

const customerOccurrence = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.customerOccurrence}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, canFind(state), canFindById(state), {
    findAttending({ page = 0, limit = 10, extraParams = {}, sort }) {
      assert.maybe.number(page, "page must be number or undefined");
      assert.maybe.number(limit, "limit must be number or undefined");

      const config = {
        params: {
          page,
          size: limit,
          ...extraParams,
        },
      };
      if (sort) {
        assert.string(
          sort.field,
          "sort.field must be a string if sort is specified.",
        );
        config.params.sort = state.createSortParam(sort.field, sort.desc);
      }

      return state.http.get(
        `${clubPrefix}${customerOccurrenceEndpoints.attending}`,
        config,
      );
    },

    addAttendeeToRsvp(userMemberId, occurrenceId) {
      assert(check.string(userMemberId), "userMemberId must be a string");
      assert(check.number(occurrenceId), "occurrenceId must be a number");
      return state.http.post(
        `${clubPrefix}${customerOccurrenceEndpoints.rsvp}`,
        { userMemberId, occurrenceId },
      );
    },

    updateRsvp(rsvpId, status) {
      assert(check.number(rsvpId), "rsvpId must be a number");
      assert(
        check.in(status, GuestStatus),
        "status needs to be a valid GuestStatus.",
      );
      return state.http.patch(
        `${clubPrefix}${customerOccurrenceEndpoints.rsvp}/${rsvpId}`,
        { status },
      );
    },

    calendarEventOccurrences({ filters }) {
      return state.http.get(
        `${clubPrefix}${customerOccurrenceEndpoints.calendarEventOccurrences}`,
        { params: filters },
      );
    },

    userMemberBookingAgenda({ filters }) {
      return state.http.get(
        `${clubPrefix}${customerOccurrenceEndpoints.userMemberBookingAgenda}`,
        { params: filters },
      );
    },
  });
};

export default customerOccurrence;
