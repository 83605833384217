import { ProductOrderDetailDTO, ProductOrderSummaryDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { productQueryKeys } from "./productQueryKeys";

export function useQueryProductSummary(
  {
    api,
    opts,
    enabled,
  }: {
    api: {
      productApi: {
        summary: (args: {
          products: ProductOrderDetailDTO[];
          promotionCode?: string;
          userMemberId: string;
        }) => Promise<{ data: ProductOrderSummaryDTO }>;
      };
    };
    enabled?: boolean;
    opts?: {
      products: ProductOrderDetailDTO[];
      promotionCode?: string;
      userMemberId: string;
    };
  },
  queryOptions?: UseQueryOptions<ProductOrderSummaryDTO>,
) {
  const result = useQuery({
    queryKey: productQueryKeys.summary(opts),
    queryFn: async () => {
      return (await api.productApi.summary(opts!)).data;
    },
    enabled: !!opts && enabled,
    ...queryOptions,
  });
  return result;
}
