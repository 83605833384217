import { RuleGroupPatch } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useMutationRuleGroupEdit({
  api,
}: {
  api: {
    ruleApi: {
      editRuleGroup: (
        ruleGroupId: number,
        patchedFields: RuleGroupPatch,
      ) => Promise<void>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      ruleGroupId,
      patchedFields,
    }: {
      ruleGroupId: number;
      patchedFields: RuleGroupPatch;
    }) => api.ruleApi.editRuleGroup(ruleGroupId, patchedFields),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ruleQueryKeys.all() });
    },
  });
  return mutation;
}
