import axios from "axios";

import endpoints from "./endpoints";
import {
  canCheckIfEmailExists,
  canCreate,
  canFind,
  canFindById,
  canTrimFields,
  canUpdate,
} from "./features/apiResource";

const staffEndpoints = {
  updatePicture: `${endpoints.userStaff}/:id/picture`,
  activate: `${endpoints.userStaff}/:id/activate-staff-user`,
  deactivate: `${endpoints.userStaff}/:id/deactivate-staff-user`,
  setClubsAccessible: `${endpoints.userStaff}/set-club-accessible/:id`,
  setRole: `${endpoints.userStaff}/set-role/:id`,
};

const userStaffApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["email", "firstName", "lastName", "mobileNumber", "username"],
    baseUrl: `${clubPrefix}${endpoints.userStaff}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canCreate(state),
    canUpdate(state),
    canFind(state),
    canFindById(state),
    canCheckIfEmailExists(state, clubId),
    {
      async updatePicture(id, { blob, name }) {
        const blobResponse = await axios.get(blob, {
          responseType: "blob",
        });
        const formData = new FormData();
        formData.append("file", blobResponse.data, name);
        const url = clubPrefix + staffEndpoints.updatePicture.replace(":id", id);
        return state.http.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },
      activate(id) {
        const url = clubPrefix + staffEndpoints.activate.replace(":id", id);

        return state.http.post(url);
      },
      deactivate(id) {
        const url = clubPrefix + staffEndpoints.deactivate.replace(":id", id);

        return state.http.post(url);
      },
      setClubAccessible(id, accessibleClubs) {
        const url = clubPrefix + staffEndpoints.setClubsAccessible.replace(":id", id);
        return state.http.patch(url, {
          clubAccessibleIdList: accessibleClubs,
        });
      },
      setRole(clubId, staffId, role) {
        const url = clubPrefix + staffEndpoints.setRole.replace(":id", staffId);
        return state.http.patch(url, {
          role,
        });
      },
    },
  );
};

export default userStaffApi;
