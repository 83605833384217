import { useStatistic } from "@gymflow/api";
import { PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import classNames from "classnames";
import moment from "moment";
import { useMemo } from "react";

import { useGetPreviousElements } from "../../hooks/useGetPreviousElements";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { Spinner } from "../atoms";
import { MultiLineChart } from "../MultiLineChart";

export function RevenueSummaryChart() {
  const { api } = useGymflowModels();
  const settings = useClubSettings();

  const { data, isFetching } = useStatistic({
    api,
    clubId: settings.clubId,
    categories: ["revenue"],
    revenueFields: ["product", "sessionPack", "membership", "total"],
    start: moment()
      .subtract(12, "M")
      .startOf("month")
      .format(PARAMETER_DATE_ONLY_FORMAT),
    end: moment()
      .subtract(1, "M")
      .endOf("month")
      .format(PARAMETER_DATE_ONLY_FORMAT),
  });

  const getPreviousElements = useGetPreviousElements();
  const currentMonth = new Date().getMonth(); // getMonth return [0, 11]
  const revenueLabels = getPreviousElements(
    MonthLabels,
    12,
    currentMonth,
    false,
  );

  const parseDataValues = (data: any = []) => data.map((d: any) => d.amount);
  const revenueData = useMemo(() => {
    if (!data?.revenue) {
      return [];
    }

    return [
      parseDataValues(data.revenue.product!.values),
      parseDataValues(data.revenue.sessionPack!.values),
      parseDataValues(data.revenue.membership!.values),
      parseDataValues(data.revenue.total!.values),
    ];
  }, [data]);

  return (
    <>
      <div className="text-lg font-semibold text-gray-900">Revenue Summary</div>
      <div className="text-sm font-normal text-gray-600">
        Last 12 months&apos; revenue broken down by sales category.
      </div>
      <div className="relative mt-4 flex rounded-xl border border-gray-200 bg-white p-6 !shadow">
        <div
          className={classNames("absolute w-full pt-28", {
            hidden: !isFetching,
          })}
        >
          <Spinner className="!h-12 !w-12" />
        </div>
        <div className={classNames("w-full", { "opacity-25": isFetching })}>
          <MultiLineChart
            xAxisLabels={revenueLabels}
            data={{
              values: revenueData,
              labels: ["Products", "Credit Packs", "Memberships", "Total"],
            }}
            xLabel="Month"
            yLabel="Revenue"
            height={300}
          />
        </div>
      </div>
    </>
  );
}

const MonthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
