import { useRevenueByTypeTotal } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useClubSettings } from "apps/portal/src/providers";
import moment from "moment";
import QueryString from "qs";
import { useContext } from "react";
import { useHistory } from "react-router";

import ReportWidgetCard from "../../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../../ReportContext";
import { usePortalRoutes } from "./../../../../../hooks/usePortalRoutes";
import { RouteFeature } from "./../../../../../routes/feature";
import { RouteLayout } from "./../../../../../routes/layout";
import useGymflowModels from "./../../../../../store";

export const ReportRevenueOtherScheduleBillingWidget = ({
  parentRoute,
}: {
  parentRoute: string;
}) => {
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const { timezone, defaultCurrency } = useClubSettings();

  const data = useContext(ReportContext);

  const { data: dataTotal, isFetching } = useRevenueByTypeTotal({
    api,
    dateFrom: data.startDate
      ? moment(data.startDate, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        )
      : undefined,
    dateTo: data.endDate
      ? moment(data.endDate, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
      : undefined,
    revenueType: "scheduled-revenue",
    tz: timezone,
  });

  return (
    <ReportWidgetCard
      title="Scheduled Billing"
      value={formatCurrency(
        Math.round(dataTotal === undefined ? 0 : dataTotal.total),
        defaultCurrency,
        true,
      )}
      isLoading={isFetching}
      explanation="Sum of invoices scheduled for next billing only, less any manual credits applied. This does not predict failed payments so the actual collected amount will be lower."
      onClick={() =>
        history.push({
          pathname: createClubLink(
            RouteLayout.Staff,
            RouteFeature.RevenueSchedule,
          ),
          search: QueryString.stringify({
            fromDates: {
              startDate: moment(data.startDate, DATE_FORMAT).format(
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ),
              endDate: moment(data.endDate, DATE_FORMAT)
                .endOf("day")
                .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
            },
            backRoute: parentRoute,
          }),
        })
      }
    />
  );
};
