import {
  useRevenueByTypeReport,
  useRevenueByTypeReportCsv,
} from "@gymflow/api";
import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  RevenueType,
} from "@gymflow/common";
import { downloadCsv, formatCurrency } from "@gymflow/helpers";
import { TransactionReportItemDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import useQueryParam from "../../hooks/useQueryParam";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function RevenueAccountsReceivable() {
  const history = useHistory();
  const { api } = useGymflowModels();

  const { date_format, defaultCurrency, timezone } = useClubSettings();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);

  const [dates, setDates] = useQueryParam("dates", "string") as any;
  const defaultDates = useMemo(
    () => ({
      startDate: moment().startOf("month").format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    }),
    [],
  );
  useEffect(() => {
    if (!dates) {
      setDates(defaultDates);
    }
  }, []);

  const { data, isFetching } = useRevenueByTypeReport({
    api,
    dateFrom: dates?.startDate,
    dateTo: dates?.endDate,
    tz: timezone,
    limit: pageSize,
    page: currentPage,
    revenueType: RevenueType.AccountsReceivable,
  });

  const downloadCsvMutation = useRevenueByTypeReportCsv({
    api,
    tz: timezone,
  });

  const columnHelper = createColumnHelper<TransactionReportItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor("invoiceDate", {
      cell: (info) => (
        <div className="text-gray-600">
          {moment(info.getValue(), PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
            date_format,
          )}
        </div>
      ),
      header: () => <div >Invoice Date</div>,
      enableSorting: false,
    }),
    columnHelper.accessor("name", {
      cell: (info) => {
        return <div className="text-gray-600">{info.getValue()}</div>;
      },
      header: "Name",
      enableSorting: false,
    }),
    columnHelper.accessor("amount", {
      cell: (info) => (
        <div className="text-gray-600">
          {formatCurrency(info.getValue(), defaultCurrency)}
        </div>
      ),
      header: "Purchase Date",
      footer: () =>
        `Total: ${
          data?.total !== undefined
            ? formatCurrency(data.total, defaultCurrency)
            : ""
        }`,
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback(
    (newDate: any) => {
      if (newDate) {
        setDates({
          startDate: moment(newDate.startDate, DATE_FORMAT).format(
            PARAMETER_DATE_FORMAT_WITHOUT_TZ,
          ),
          endDate: moment(newDate.endDate, DATE_FORMAT)
            .endOf("day")
            .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
        });
      }
    },
    [setDates],
  );

  return (
    <Report
      title="Accounts Receivable"
      description="List of all invoices that are past due within the specified date range."
      onDownloadClick={async () => {
        const data = await downloadCsvMutation.mutateAsync({
          revenueType: RevenueType.AccountsReceivable,
          dateFrom: dates?.startDate,
          dateTo: dates?.endDate,
        });
        downloadCsv(data, `AccountsReceivable.csv`);
      }}
      handleDateRangeChange={handleDateRangeChange}
      dateRange={
        dates?.startDate && dates?.endDate
          ? {
              startDate: moment(
                dates.startDate,
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT),
              endDate: moment(dates.endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ)
                .endOf("day")
                .format(DATE_FORMAT),
            }
          : undefined
      }
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: searchParams["startDate"],
              endDate: searchParams["endDate"],
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.data.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.data.totalPages}
            pageIndex={data?.data.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
          />
          <div className="border-t-[1px] border-gray-300">
            <ReportPagination
              pageCount={data?.data.totalPages as number}
              currentPage={data?.data.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}
