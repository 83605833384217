import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { stripeQueryKeys } from "./stripeQueryKeys";

export function useQueryStripeAccountSession(
  {
    api,
    clubId,
  }: {
    api: {
      clubApi: {
        stripeSession: (clubId: number) => Promise<{ data: string }>;
      };
    };
    clubId: number;
  },
  queryOpts?: UseQueryOptions<string>,
) {
  const result = useQuery({
    queryKey: stripeQueryKeys.accountSession(),
    queryFn: async () => {
      const result = await api.clubApi.stripeSession(clubId);
      return result.data;
    },
    ...queryOpts,
  });

  return result;
}
