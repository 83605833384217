import { useMutation, useQueryClient } from '@tanstack/react-query';

import { emailTemplateQueryKeys } from './emailTemplateQueryKeys';

export function useEmailTemplateCreate({
  api,
}: {
  api: {
    emailTemplateApi: {
      create: (arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (newEmailTemplate: { [k: string]: any }) =>
      api.emailTemplateApi.create(newEmailTemplate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: emailTemplateQueryKeys.all() });
    },
  });

  return mutation;
}
