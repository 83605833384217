import { action, actionOn, thunk, thunkOn } from 'easy-peasy';
import { getApi, ModelBuilder } from '@gymflow/common';

class AutomationModelBuilder extends ModelBuilder {
  constructor(apiKey) {
    super();
    this.generators = [];
    this.apiKey = apiKey;
    this.generators.push(this.automationGenerator);
  }

  automationGenerator() {
    return {
      apiKey: this.apiKey,
      automations: [],
      isLoading: false,
      fetchAutomations: thunk(async (actions, _, { injections, getState }) => {
        const api = getApi(injections, getState());
        const { data } = await api.find();
        actions.fetchedAutomations(data);
      }),
      fetchedAutomations: action((state, payload) => {
        state.automations = payload;
      }),
      onFetchAutomationsStart: actionOn(
        (actions) => actions.fetchAutomations.startType,
        (state) => {
          state.isLoading = true;
        }
      ),
      onFetchAutomationsDone: actionOn(
        (actions) => [actions.fetchAutomations.successType, actions.fetchAutomations.failType],
        (state) => {
          state.isLoading = false;
        }
      ),
      activateAutomation: thunk((_, id, { injections, getState }) => {
        const api = getApi(injections, getState());
        return api.active(id);
      }),
      inactivateAutomation: thunk((_, id, { injections, getState }) => {
        const api = getApi(injections, getState());
        return api.inactive(id);
      }),
      updateBCC: thunk((_, { id, bccList }, { injections, getState }) => {
        const api = getApi(injections, getState());
        return api.updateBCC(id, bccList);
      }),
      onUpdate: thunkOn(
        (actions) => [actions.activateAutomation, actions.inactivateAutomation, actions.updateBCC],
        (actions) => {
          actions.fetchAutomations();
        }
      ),
    };
  }
}

export default AutomationModelBuilder;
