import { QueryKey } from "@tanstack/react-query";

export const appointmentQueryKeys = {
  all: () => ["appointment"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...appointmentQueryKeys.all(), { filters }] as QueryKey,
  details: (appointmentId?: number | null) =>
    [...appointmentQueryKeys.all(), appointmentId] as QueryKey,
  summary: (parameters: Readonly<{ [k: string]: any }>) =>
    [...appointmentQueryKeys.all(), "summary", { parameters }] as QueryKey,
  recurringAppointmentDetails: (recurringAppointmentId?: number) =>
    [
      ...appointmentQueryKeys.all(),
      "recurring",
      recurringAppointmentId,
    ] as QueryKey,
};
