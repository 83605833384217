import { FormMapper } from "@gymflow/common";
import { toLowerCaseExceptFirstChar } from "@gymflow/helpers";

export default class StaffFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "picture",
          transform: (picture: any) => ({ blob: picture }),
        },
        {
          key: "clubAccessibleList",
          transform: (accessibleList: { id: number; name: string }[]) => {
            return accessibleList.map(({ id, name }) => ({
              label: name,
              value: { id, name },
            }));
          },
        },
        {
          key: "role",
          transform: (v: string) => ({
            value: v,
            label: toLowerCaseExceptFirstChar(v),
          }),
        },
      ],
      outValue: [
        {
          key: "email",
          transform: (v: string) => v.toLowerCase(),
        },
        {
          key: "club-accessible-list",
          transform: (v: { value: { id: number } }[]) => {
            return v.map(({ value: { id } }) => id);
          },
        },
        {
          key: "role",
          transform: (v: { value: string }) => v.value,
        },
      ],
    });
  }
}
