import { cn, isValidHexColor } from "@gymflow/helpers";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

import { TextInputClassNames } from "./TextInput";

export function ColorInput({
  value,
  onChange,
  className,
  disabled,
}: {
  value: string;
  onChange: (newColor: string) => void;
  className?: string;
  disabled?: boolean;
}) {
  const [color, setColor] = useState(value);
  useEffect(() => {
    if (isValidHexColor(value)) {
      setColor(value);
    }
  }, [value]);
  const [displayPicker, setDisplayPicker] = useState(false);
  const handleClick = () => !disabled && setDisplayPicker(!displayPicker);
  const handleClose = () => setDisplayPicker(false);
  const handleChange = (newColor: { hex: string }) => {
    return onChange(newColor.hex.toUpperCase());
  };
  return (
    <div className="flex rounded-md shadow-sm">
      <div className="relative flex grow items-stretch focus-within:z-10">
        <input
          type="text"
          disabled={disabled}
          className={cn(TextInputClassNames, "rounded-none rounded-l-lg", {
            "bg-gray-100": disabled,
          })}
          onClick={() => {
            handleClick();
          }}
          onChange={({ currentTarget: { value } }) => {
            handleChange({ hex: value });
          }}
          value={value}
        />

        {displayPicker && !disabled && (
          <div
            style={{
              position: "absolute",
              zIndex: "2",
              left: "6rem",
            }}
          >
            <div
              style={{
                position: "fixed",
                top: "0px",
                left: "0px",
                bottom: "0px",
                right: "0px",
              }}
              onClick={handleClose}
            />
            <SketchPicker color={color} onChange={handleChange} />
          </div>
        )}
      </div>
      <div
        className="relative -ml-px inline-flex w-32 items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        style={{ backgroundColor: value }}
      ></div>
    </div>
  );
}
