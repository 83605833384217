import moment from "moment-timezone";

// @ts-ignore
import { PARAMETER_DATE_FORMAT } from "../api/eventApi";
import { TIME_FORMAT } from "./form/constants";

export const DATE_FORMAT_WITH_SECONDS = "YYYY-MM-DDTHH:mm:ss";

const yesterday = moment().subtract(1, "day");
const today = moment();
const tomorrow = moment().add(1, "day");
export const humanizeTimeInterval = (
  startTime: string,
  endTime: string,
  timeFormat = PARAMETER_DATE_FORMAT,
) => {
  const duration = getDurationBetween(startTime, endTime, timeFormat);

  const hoursLocale = duration.hours() > 1 ? "hours" : "hour";
  const minutesLocale = duration.minutes() > 1 ? "minutes" : "minute";

  if (duration.days() === 1) {
    return "1 day";
  } else if (duration.hours() > 0) {
    const minutePart =
      duration.minutes() > 0
        ? ` and ${duration.minutes()} ${minutesLocale}`
        : "";

    return `${duration.hours()} ${hoursLocale}${minutePart}`;
  }
  return `${duration.minutes()} ${minutesLocale}`;
};

export const getDurationBetween = (
  startTime: string,
  endTime: string,
  timeFormat = TIME_FORMAT,
) => {
  const start = moment(`${startTime}`, timeFormat);
  const end = moment(`${endTime}`, timeFormat);
  let duration;
  if (start.isBefore(end)) {
    duration = moment.duration(end.diff(start));
  } else {
    duration = moment.duration(end.add(24, "hours").diff(start));
  }

  return duration;
};

export const isTimeBefore = (
  startTime: string,
  endTime: string,
  timeFormat = TIME_FORMAT,
) => {
  const start = moment(`${startTime}`, timeFormat);
  const end = moment(`${endTime}`, timeFormat);
  return start.isBefore(end);
};

export const tzDateTimeStringToUtc = (
  dateTimeString: string,
  tz: string,
  format = PARAMETER_DATE_FORMAT,
) => moment.tz(dateTimeString, tz).utc().format(format);

export const utcDateTimeStringToTz = (
  dateTimeString: string,
  tz: string,
  format = DATE_FORMAT_WITH_SECONDS,
) => moment.utc(dateTimeString).tz(tz).format(format);

export const timestampToTz = (
  timestamp: number,
  tz: string,
  format = DATE_FORMAT_WITH_SECONDS,
) => moment.unix(timestamp).tz(tz).format(format);

export const roundToClosestFutureHalfHour = (momentObject: moment.Moment) => {
  const remainder = 30 - (momentObject.minute() % 30);

  return moment(momentObject).add(remainder, "minutes");
};

export const humanize = (date: string) => {
  const duration = moment.duration(moment().diff(moment(date)));
  return duration.humanize() + " ago";
};

export const humanizeDate = (date: moment.Moment, format?: string) => {
  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(tomorrow, "day")) {
    return "Tomorrow";
  } else if (date.isSame(yesterday, "day")) {
    return "Yesterday";
  } else {
    return format ? date.format(format) : date;
  }
};
