import classNames from "classnames";
import { components } from "react-select";

import { LookingGlassIcon } from "./icons";

export const SearchSelectValueContainer = ({
  children,
  className,
  ...props
}: {
  [k: string]: any;
}) => {
  return (
    <>
      <div className="pl-2">
        <LookingGlassIcon pathClassName="stroke-grey-600" />
      </div>
      {/* TODO: Investigate type not matching */}
      {/* @ts-expect-error */}
      <components.ValueContainer
        className={classNames("text-left", className)}
        {...props}
      >
        {children}
      </components.ValueContainer>
    </>
  );
};
