import { QueryKey } from "@tanstack/react-query";

export const eventOccurrenceAsMemberQueryKeys = {
  all: () => ["eventOccurrenceAsMember"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...eventOccurrenceAsMemberQueryKeys.all(), { filters }] as QueryKey,
  attending: (filters: Readonly<Record<string, any>>) =>
    [
      ...eventOccurrenceAsMemberQueryKeys.all(),
      "attending",
      filters,
    ] as QueryKey,
};
