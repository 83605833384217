import {
  clubStaleTime,
  useClassesRunReport,
  useClassesRunReportCsv,
  useClub,
} from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { downloadCsv } from "@gymflow/helpers";
import { ClassesRunItemDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import moment from "moment-timezone";
import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import {
  ClassesRunFilters,
  ClassesRunFilterSidebar,
} from "../../components/templates";
import { getFilterAppliedCount } from "../../helpers";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";

export function ClassesRun() {
  const { createClubLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const clubId = settings.clubId;
  const { data: club } = useClub({ api, clubId }, { staleTime: clubStaleTime });

  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([
    {
      id: "startTime",
      desc: true,
    },
  ]);
  const [filters, setFilters] = useState<ClassesRunFilters>({});

  const createSort = function () {
    const result = [];
    if (currentSort?.[0]) {
      result.push({ field: currentSort[0]?.id, desc: currentSort[0]?.desc });
    }
    return result;
  };

  const { data, isFetching } = useClassesRunReport({
    api,
    startDate,
    endDate,
    sort: createSort(),
    limit: pageSize,
    page: currentPage,
    tz: club?.timezone!,
    eventHostId: filters?.eventHost && filters.eventHost.map(({ id }) => id),
    activityId: filters?.activity && filters.activity.map(({ id }) => id),
  });
  const downloadCsvMutation = useClassesRunReportCsv({
    api,
    tz: club?.timezone!,
  });

  useEffect(() => {
    if (searchParams?.["startDate"]) {
      setStartDate(
        moment(searchParams["startDate"] as string, DATE_FORMAT).format(
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        ),
      );
    }
    if (searchParams?.["endDate"]) {
      setEndDate(
        moment(searchParams["endDate"] as string, DATE_FORMAT)
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
      );
    }
  }, [searchParams?.["startDate"], searchParams?.["endDate"]]);

  const [filtersAreVisible, setFiltersAreVisible] = useState(false);

  const columnHelper = createColumnHelper<ClassesRunItemDTO>();
  const columnsDefinition = [
    columnHelper.accessor("startTime", {
      cell: (info) => {
        const startTime = info.getValue();
        return startTime ? (
          <div className="text-gray-600">
            {moment(startTime, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
              `${settings.date_format} h:mm a`,
            )}
          </div>
        ) : null;
      },
      header: "Start Time",
    }),
    columnHelper.accessor("className", {
      cell: (info) => (
        <div className="text-gray-600">
          <LinkButton
            to={createClubLink(
              RouteLayout.Staff,
              RouteFeature.EventOccurrence.replace(
                ":eventId",
                info.row.original.occurrenceId.toString(),
              ),
            )}
          >
            {info.getValue()}
          </LinkButton>
        </div>
      ),
      header: "Class Name",
      enableSorting: false,
    }),
    columnHelper.accessor("capacity", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Capacity",
      enableSorting: false,
    }),
    columnHelper.accessor("occupancy", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Occupancy",
      enableSorting: false,
    }),
    columnHelper.accessor("occupancyPercentage", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}%</div>,
      header: "Occupancy %",
      enableSorting: false,
    }),
    columnHelper.accessor("trainer", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Trainer",
      enableSorting: false,
    }),
  ];

  const handleDateRangeChange = useCallback((newDate: any) => {
    if (newDate) {
      history.push({
        search: qs.stringify({
          startDate: newDate.startDate,
          endDate: newDate.endDate,
          backRoute: searchParams["backRoute"],
        }),
      });
    }
  }, []);

  return (
    <>
      <ClassesRunFilterSidebar
        isVisible={filtersAreVisible}
        onClose={() => setFiltersAreVisible(false)}
        value={filters}
        onChange={(newFilters) => {
          setFilters(newFilters);
          setCurrentPage(0);
        }}
      />
      <Report
        title="Classes Run"
        description="List of all classes within the specified date period."
        onDownloadClick={async () => {
          const data = await downloadCsvMutation.mutateAsync({
            startDate: startDate!,
            endDate: endDate!,
            eventHostId:
              filters?.eventHost && filters.eventHost.map(({ id }) => id),
            activityId:
              filters?.activity && filters.activity.map(({ id }) => id),
          });
          downloadCsv(data, `ClassesRun.csv`);
        }}
        showFilters={() => setFiltersAreVisible(true)}
        filtersAppliedCount={getFilterAppliedCount(filters)}
        handleDateRangeChange={handleDateRangeChange}
        dateRange={
          startDate && endDate
            ? {
                startDate: moment(
                  startDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
                endDate: moment(
                  endDate,
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT),
              }
            : undefined
        }
        close={() => {
          history.push({
            pathname: searchParams["backRoute"] as string,
            search: qs.stringify({
              dates: {
                startDate: searchParams["startDate"],
                endDate: searchParams["endDate"],
              },
            }),
          });
        }}
        table={
          <>
            <Table
              data={data?.classesRunReportDTO.content ?? []}
              columns={columnsDefinition}
              pageCount={data?.classesRunReportDTO.totalPages}
              pageIndex={data?.classesRunReportDTO.number}
              onSortingChange={setCurrentSort}
              sort={currentSort}
              isFetching={isFetching}
              pageSize={pageSize}
              rowClassName="h-14"
              manualSorting
            />
            <div className="border-t-[0.063rem] border-gray-300">
              <ReportPagination
                pageCount={data?.classesRunReportDTO.totalPages as number}
                currentPage={data?.classesRunReportDTO.number as number}
                onPageChange={(newPage) => {
                  setCurrentPage(newPage);
                }}
                pageSize={pageSize}
                setPageSize={(newValue) => {
                  setPageSize(newValue);
                  setCurrentPage(0);
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
}
