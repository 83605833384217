export function CheckInIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathClassName}
        d="M4.33333 14.4605C4.33333 14.7739 4.33333 14.9306 4.34813 15.0668C4.47098 16.1977 5.3369 17.132 6.5075 17.3965C6.64849 17.4283 6.81297 17.449 7.14181 17.4904L13.3675 18.2745C15.1397 18.4977 16.0258 18.6093 16.7135 18.351C17.3173 18.1242 17.8203 17.7083 18.1381 17.173C18.5 16.5632 18.5 15.7188 18.5 14.03V5.96995C18.5 4.28118 18.5 3.4368 18.1381 2.82703C17.8203 2.29167 17.3173 1.87575 16.7135 1.64898C16.0258 1.39068 15.1397 1.50228 13.3675 1.72547L7.14181 2.50955C6.81293 2.55097 6.6485 2.57168 6.5075 2.60354C5.3369 2.86804 4.47098 3.80225 4.34813 4.93322C4.33333 5.06945 4.33333 5.22614 4.33333 5.53954M10 6.43163L13.7778 10M13.7778 10L10 13.5684M13.7778 10H1.5"
        strokeWidth="1.50"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
