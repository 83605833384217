import React, { useCallback, useContext } from 'react';
import { AlertContext } from '../../providers/Alert';

import PaymentAuthorizationAlert from './PaymentAuthorizationAlert';

const usePaymentAuthorizationAlert = () => {
  const { setAlert, show, hide } = useContext(AlertContext);

  const showFailed = useCallback(() => {
    return new Promise((resolve) => {
      show('', {
        title: 'Authorization failed',
        type: 'danger',
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          resolve();
        },
      });
    });
  }, [show]);

  const showSuccess = useCallback(() => {
    return new Promise((resolve) => {
      show('Complete', {
        title: 'Authorization Successfull',
        type: 'success',
        closeOnClickOutside: false,
        showCancel: false,
        onConfirm: () => {
          resolve();
        },
      });
    });
  }, [show]);

  const showWaiting = useCallback(
    ({ confirmPayment, paymentIntentIdForAuthorization, messageText, hideCancel = false }) => {
      return new Promise((resolve) => {
        setAlert(
          <PaymentAuthorizationAlert
            text={messageText}
            paymentIntentIdForAuthorization={paymentIntentIdForAuthorization}
            onSuccess={async () => {
              await showSuccess();
              resolve({ status: PaymentConfirmationStatus.Success });
            }}
            onFailed={async () => {
              await showFailed();
              resolve({ status: PaymentConfirmationStatus.Failed });
            }}
            onCancel={() => {
              hide();
              resolve({ status: PaymentConfirmationStatus.Waiting });
            }}
            confirmPayment={confirmPayment}
            hideCancel={hideCancel}
          />
        );
      });
    },
    [hide, setAlert, showSuccess, showFailed]
  );

  return { show: showWaiting };
};

export const PaymentConfirmationStatus = {
  Success: 'SUCCESS',
  Waiting: 'WAITING',
  Failed: 'FAILED',
};

export default usePaymentAuthorizationAlert;
