export const CheckIcon = ({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    className={className}
  >
    <path
      d="M14.6667 1L5.50004 10.1667L1.33337 6"
      stroke="#8746EC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={pathClassName}
    />
  </svg>
);
