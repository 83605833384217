import {
  ActivityCategoryDTO,
  ActivityCategoryStatus,
  ApiListResponse,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsPublicQueryKeys } from "./activityAsPublicQueryKeys";

export async function activityCategoryListAsPublicQueryFn({
  api,
  filter,
}: {
  api: ActivityCategoryListAsPublicApi;
  filter: ActivityCategoryListAsPublicFilter;
}) {
  const result = await api.public.activityApi.findCategories({
    ...filter,
    status: filter.statusList,
  });
  return result.data;
}

export function useActivityCategoryListAsPublic({
  api,
  filter,
}: {
  api: ActivityCategoryListAsPublicApi;
  filter: ActivityCategoryListAsPublicFilter;
}) {
  const result = useQuery({
    queryKey: activityAsPublicQueryKeys.categories(filter),
    queryFn: () => activityCategoryListAsPublicQueryFn({ api, filter }),
    initialData: defaultPage<ActivityCategoryDTO>,
  });
  return result;
}

export interface ActivityCategoryListAsPublicFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly statusList?: ActivityCategoryStatus[];
}

interface ActivityCategoryListAsPublicApi {
  public: {
    activityApi: {
      findCategories: (filter: {
        page?: number;
        limit?: number;
        status?: ActivityCategoryStatus[];
      }) => Promise<{ data: ApiListResponse<ActivityCategoryDTO> }>;
    };
  };
}
