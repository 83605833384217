import {
  clubStaleTime,
  useClubAsPublic,
  useQueryActivityCategoryListAsMember,
} from "@gymflow/api";
import {
  AlertContext,
  MembershipBillingType,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  RecurringType,
  ServiceType,
} from "@gymflow/common";
import {
  formatCurrency,
  pluralize,
  toLowerCaseExceptFirstChar,
} from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

import BuyRoute from "../components/SalesWizard/BuyRoute";
import MembershipWidget from "../components/UserMember/MembershipWidget";
import UserMemberCredits from "../components/UserMember/UserMemberCredits";
import { mapCategoriesToCreditPacks } from "../helpers/creditPack";
import { useAddPaymentMethodAlert } from "../hooks/useAddPaymentMethodAlert";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { useClubSettings } from "../providers";
import { RouteFeature } from "../routes/feature";
import { RouteLayout } from "../routes/layout";
import useGymflowModels from "../store";

function Account() {
  const { api } = useGymflowModels();
  const { createClubLink } = usePortalRoutes();
  const { authStore, UserMemberStore } = useGymflowModels();
  const { id: loggedInId } = useStoreState(authStore);
  const { showWarning, hide } = useContext(AlertContext);
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClubAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );

  const { currentMembership, editing, activeCreditPacks, paymentMethods } =
    UserMemberStore.useStoreState((state) => state);

  const defaultMethod = paymentMethods.find(
    (method) => method.defaultPaymentMethod,
  );

  const {
    fetchById,
    clearEditingRecord,
    pauseSubscription,
    resumeSubscription,
    cancelSubscription,
    fetchPaymentMethods,
    addPaymentMethod,
    assignDefaultPaymentMethod,
  } = UserMemberStore.useStoreActions((actions) => actions);

  const {
    data: { content: categories },
  } = useQueryActivityCategoryListAsMember({
    api,
    filter: { page: 0, limit: 100 },
  });

  const creditPacks =
    activeCreditPacks &&
    mapCategoriesToCreditPacks(activeCreditPacks.slice(0), categories);

  const showAddPaymentAlert = useAddPaymentMethodAlert({
    userMember: editing,
    asMember: true,
    onConfirm: async (paymentMethod) => {
      fetchPaymentMethods({ userMemberId: loggedInId, clubId });
      await assignDefaultPaymentMethod({
        userMemberId: loggedInId,
        clubId,
        paymentMethod,
      });
      fetchById();
    },
  });

  useEffect(() => {
    fetchById();
    fetchPaymentMethods({ userMemberId: loggedInId, clubId });
    return () => clearEditingRecord();
  }, [
    fetchById,
    clearEditingRecord,
    loggedInId,
    fetchPaymentMethods,
    loggedInId,
    clubId,
  ]);
  if (!editing) {
    return null;
  }

  let billingDay =
    currentMembership?.membershipBean.billingType ===
    MembershipBillingType.Weekly
      ? currentMembership?.membershipBean?.weeklyBillingDay
      : currentMembership?.membershipBean?.monthlyBillingDay;
  if (currentMembership?.membershipBean.calculateProrata === "NO") {
    if (
      currentMembership?.membershipBean.billingType ===
      MembershipBillingType.Weekly
    ) {
      billingDay = moment(
        currentMembership?.startDate,
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      ).format("dddd");
    } else {
      billingDay = moment(
        currentMembership?.startDate,
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      ).format("D");
    }
  }

  const renderBillOn = () => {
    if (currentMembership.membershipBean.type !== ServiceType.Recurring) {
      return null;
    }
    return `Bills on
                ${
                  currentMembership.membershipBean.billingType ===
                  MembershipBillingType.Weekly
                    ? toLowerCaseExceptFirstChar(billingDay)
                    : "the " + moment.localeData().ordinal(billingDay)
                } every
                ${
                  currentMembership.membershipBean.billingPeriod > 1
                    ? currentMembership.membershipBean.billingPeriod
                    : ""
                }
                ${pluralize(
                  RecurringType.toTimeUnit(
                    currentMembership.membershipBean.billingType,
                  )?.toLowerCase(),
                  RecurringType.toTimeUnit(
                    currentMembership.membershipBean.billingType,
                  )?.toLowerCase() + "s",
                  currentMembership.membershipBean.billingPeriod,
                )}
                `;
  };

  return (
    <div className="content h-full overflow-y-auto p-8">
      <Row>
        <Col sm="4">
          <h2>Membership</h2>
          {currentMembership ? (
            <MembershipWidget
              membershipName={currentMembership.name}
              status={currentMembership.status}
              isRecurring={
                currentMembership.membershipBean.type === ServiceType.Recurring
              }
              price={formatCurrency(
                currentMembership.membershipBean.type === ServiceType.Recurring
                  ? currentMembership.membershipMonthlyPayment
                  : currentMembership.price,
                club?.defaultCurrency,
              )}
              billOn={renderBillOn()}
              priceInterval={
                currentMembership?.membershipBean.billingPeriod &&
                `Per
                ${
                  currentMembership.membershipBean.billingPeriod > 1
                    ? currentMembership.membershipBean.billingPeriod
                    : ""
                }
                ${pluralize(
                  RecurringType.toTimeUnit(
                    currentMembership.membershipBean.billingType,
                  ),
                  RecurringType.toTimeUnit(
                    currentMembership.membershipBean.billingType,
                  ) + "s",
                  currentMembership.membershipBean.billingPeriod,
                )}
                `
              }
              onPauseClick={() => {
                showWarning(
                  "Pausing this membership will prevent your access to it",
                  {
                    title: "Are you sure you want to pause your membership?",
                    onConfirm: () => {
                      hide();
                      pauseSubscription({
                        memberId: loggedInId,
                        subscriptionId: currentMembership.id,
                      });
                    },
                    confirmBtnText: "Yes, pause it!",
                    confirmBtnBsStyle: "danger",
                  },
                );
              }}
              onCancelClick={() => {
                showWarning(
                  "Cancelling this membership will stop your access to it.",
                  {
                    title: "Are you sure you want to cancel your membership?",
                    onConfirm: () => {
                      hide();
                      cancelSubscription({
                        memberId: loggedInId,
                        subscriptionId: currentMembership.id,
                      });
                    },
                    confirmBtnText: "Yes, cancel it!",
                    confirmBtnBsStyle: "danger",
                  },
                );
              }}
              onResumeClick={() => {
                showWarning("Resuming this membership will restore it.", {
                  title: "Are you sure you want to resume your membership?",
                  onConfirm: () => {
                    hide();
                    resumeSubscription({
                      memberId: loggedInId,
                      subscriptionId: currentMembership.id,
                    });
                  },
                  confirmBtnText: "Yes, resume it!",
                  confirmBtnBsStyle: "success",
                });
              }}
              isActive={currentMembership.active}
              activeFrom={currentMembership.startDate}
              activeUntil={currentMembership.endDate}
              isCancellable={
                settings.allow_customer_cancel_memberships &&
                currentMembership.customerCancellable
              }
              type={currentMembership.membershipBean.type}
              allowPause={settings.allow_customer_pausing_memberships}
              cancellationDate={currentMembership.cancellationDate}
              termsConditions={currentMembership.membershipBean.termsConditions}
              defaultPayment={defaultMethod}
              onUpdateCard={showAddPaymentAlert}
            />
          ) : (
            <div style={{ minHeight: 150 }} className="text-muted">
              Shop memberships&nbsp;
              <Link
                to={createClubLink(
                  RouteLayout.Member,
                  RouteFeature.CustomerSale,
                  BuyRoute.RecurringMembership,
                )}
              >
                here
              </Link>
            </div>
          )}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
          <h2>Credits</h2>
          {!isEmpty(creditPacks) ? (
            <UserMemberCredits creditPacks={creditPacks} />
          ) : (
            <div style={{ minHeight: 150 }} className="text-muted">
              Shop credit packs&nbsp;
              <Link
                to={createClubLink(
                  RouteLayout.Member,
                  RouteFeature.CustomerSale,
                  BuyRoute.SessionsPack,
                )}
              >
                here
              </Link>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}

const AccountWithProvider = () => {
  const { UserMemberStore } = useGymflowModels();
  return (
    <UserMemberStore.Provider>
      <Account />
    </UserMemberStore.Provider>
  );
};

export default AccountWithProvider;
