import {UTCDateMini} from '@date-fns/utc';
import endOfDay from 'date-fns/endOfDay';
import dateFnsFormatDistanceToNow from 'date-fns/formatDistanceToNow';
import dateFnsIsAfter from 'date-fns/isAfter';
import dateFnsIsBefore from 'date-fns/isBefore';
import dateFnsParse from 'date-fns/parse';
import sub from 'date-fns/sub';
import {
  format as dateFnsFormat,
  utcToZonedTime as dateFnsUtcToZonedTime,
} from 'date-fns-tz';

export const iso8601 = "yyyy-MM-dd'T'HH:mm:ss'Z'";

export const iso8601WithoutTz = "yyyy-MM-dd'T'HH:mm:ss";
export const DATE_ONLY_FORMAT = 'yyyy-MM-dd';

export function zonedTimeToUtc(date: string, fromTz: string, format = iso8601) {
  const parsedForReference = dateFnsParse(date, iso8601WithoutTz, new Date());
  const offset = formatInTimeZone(parsedForReference, 'xxx', fromTz);
  const parsed = dateFnsParse(
    `${date}${offset}`,
    "yyyy-MM-dd'T'HH:mm:ssxxx",
    new UTCDateMini(),
  );
  const result = formatInTimeZone(parsed, format, 'UTC');

  return result;
}

export function utcToZonedTime(
  date: string,
  toTz: string,
  format = iso8601WithoutTz,
) {
  const parsed = dateFnsParse(
    date,
    "yyyy-MM-dd'T'HH:mm:ssxxx",
    new UTCDateMini(),
  );
  const zonedDate = dateFnsUtcToZonedTime(parsed, toTz);
  const zonedTimeString = dateFnsFormat(zonedDate, iso8601WithoutTz, {
    timeZone: 'UTC',
  });
  return zonedTimeString;
}

export function humanizeDate(date: string) {
  return dateFnsFormatDistanceToNow(dateFnsParse(date, iso8601, new Date()));
}

export function formatDate(
  date: string,
  formatTo: string,
  readFromFormat = iso8601WithoutTz,
) {
  return dateFnsFormat(
    dateFnsParse(date, readFromFormat, new Date()),
    formatTo,
  );
}

export function isBefore(
  date: string,
  dateToCompare: string,
  format = iso8601,
) {
  return dateFnsIsBefore(
    dateFnsParse(date, format, new Date()),
    dateFnsParse(dateToCompare, format, new Date()),
  );
}

export function isAfter(date: string, dateToCompare: string, format = iso8601) {
  return dateFnsIsAfter(
    dateFnsParse(date, format, new Date()),
    dateFnsParse(dateToCompare, format, new Date()),
  );
}

const formatInTimeZone = (date: Date, fmt: string, tz: string) => {
  return dateFnsFormat(dateFnsUtcToZonedTime(date, tz), fmt, {timeZone: tz});
};

export function zonedTime(timezone: string, utcDate: Date = new UTCDateMini()) {
  const utc = formatInTimeZone(utcDate, iso8601, 'UTC');
  return utcToZonedTime(utc, timezone);
}

export function dateStringToDate(
  dateString: string,
  format = iso8601WithoutTz,
) {
  return dateFnsParse(dateString, format, new Date());
}

export function lastSecondOfYesterday(
  timezone: string,
  format = iso8601WithoutTz,
) {
  const result = dateFnsFormat(
    endOfDay(sub(dateStringToDate(zonedTime(timezone)), {days: 1})),
    format,
  );
  return result;
}
