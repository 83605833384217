import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useQueryMemberHighlightedInvoice({
  api,
  memberId,
}: {
  api: ApiType;
  memberId: string;
}) {
  return useQuery({
    queryKey: memberQueryKeys.highlightedInvoice(memberId),
    queryFn: async () => {
      return (
        await api.memberApi.highlightedInvoice({
          memberId,
        })
      ).data;
    },
  });
}
