import { RuleGroup } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryRuleGroup({
  api,
  ruleGroupId,
}: {
  api: {
    ruleApi: {
      ruleGroup: (ruleGroupId: number) => Promise<{ data: RuleGroup }>;
    };
  };
  ruleGroupId?: number;
}) {
  const result = useQuery({
    queryKey: ruleQueryKeys.ruleGroup(ruleGroupId as number),
    queryFn: async () => {
      const result = await api.ruleApi.ruleGroup(ruleGroupId as number);
      return result.data;
    },
    enabled: !!ruleGroupId,
  });
  return result;
}
