import { cn } from "@gymflow/helpers";
import { useTranslation } from "react-i18next";

import { Button, FilterIcon, PlusIcon } from "../../atoms";

type TaskActionsSettingsContainerProps = {
  setIsFilterVisible: (value: boolean) => void;
  openCreateTaskSidebar: () => void;
  appliedFiltersCount?: number;
};

export const TaskActionsSettingsContainer = ({
  setIsFilterVisible,
  openCreateTaskSidebar,
  appliedFiltersCount,
}: TaskActionsSettingsContainerProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Button
        className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
        onClick={() => setIsFilterVisible(true)}
        badgeContent={appliedFiltersCount}
      >
        <div className="inline-flex items-center gap-x-2">
          <FilterIcon
            className="h-[1.125rem] w-[1.125rem]"
            pathClassName="stroke-gray-500"
          />
          <div className={cn("hidden lg:flex")}>
            {t("page.tasks.taskActionsSettingsContainer.button.filters")}
          </div>
        </div>
      </Button>
      <Button
        className="mt-0 w-11 min-w-[2.75rem] lg:w-auto lg:min-w-fit"
        onClick={() => openCreateTaskSidebar()}
        intent="secondary"
      >
        <div className="inline-flex items-center gap-x-2">
          <PlusIcon
            className="h-[1.125rem] w-[1.125rem]"
            pathClassName="stroke-white"
          />
          <div className="hidden lg:flex">
            {t("page.tasks.taskActionsSettingsContainer.button.newTask")}
          </div>
        </div>
      </Button>
    </>
  );
};
