import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { memberAsPublicQueryKeys } from "./memberAsPublicQueryKeys";

export function useMemberEmailExistsAsPublic(
  {
    api,
    email,
  }: {
    api: {
      public: {
        userMemberApi: {
          emailExists: (email: string) => Promise<{ data: boolean }>;
        };
      };
    };
    email?: string;
  },
  queryOpts?: UseQueryOptions<boolean>,
) {
  const result = useQuery({
    queryKey: memberAsPublicQueryKeys.emailExists(email),
    queryFn: async () => {
      const result = await api.public.userMemberApi.emailExists(
        email as string,
      );
      return result.data;
    },
    enabled: !!email,
    ...queryOpts,
  });
  return result;
}
