import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getTextFromReactElement } from '@gymflow/helpers';

function FilterableList({ value, onChange, options, placeholder }) {
  const [selected, setSelected] = useState(value);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <>
      <InputGroup className="mb-0">
        <InputGroupAddon addonType="prepend">
          <InputGroupText
            style={{
              borderBottom: '0',
              borderBottomLeftRadius: '0',
              paddingLeft: '10px',
            }}
          >
            <FontAwesomeIcon icon={faSearch} color="#9d9ea2" style={{ fontSize: 14 }} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder={placeholder}
          className="pl-2"
          style={{
            borderBottomLeftRadius: '0',
            borderBottomRightRadius: '0',
            borderBottom: '0',
          }}
          value={filter}
          onChange={({ target: { value } }) => {
            setFilter(value);
          }}
        />
      </InputGroup>
      <div
        style={{
          border: '1px solid rgba(58, 58, 63, 0.5)',
          borderRadius: '5px',
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          borderTop: '0',
          padding: '10px',
          maxHeight: '190px',
          overflow: 'auto',
        }}
      >
        {options
          .filter(({ label }) => {
            if (typeof label === 'string') {
              return label.toLowerCase().includes(filter.toLowerCase());
            } else {
              return getTextFromReactElement(label).includes(filter.toLowerCase());
            }
          })
          .map(({ label, value: optionValue, readOnly }) => {
            return (
              <div>
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={selected.some((v) => isEqual(v, optionValue))}
                  onChange={({ target: { checked } }) => {
                    let newValue;
                    if (checked) {
                      newValue = selected.concat([optionValue]);
                    } else {
                      newValue = selected.filter((s) => !isEqual(s, optionValue));
                    }
                    onChange(newValue, optionValue, checked);
                  }}
                  disabled={readOnly}
                />
                {label}
              </div>
            );
          })}
      </div>
    </>
  );
}

FilterableList.defaultProps = {
  options: [],
  placeholder: 'Search...',
};

FilterableList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.node.isRequired,
    })
  ),
  placeholder: PropTypes.string,
};

export default FilterableList;
