import { thunk, thunkOn } from 'easy-peasy';

import EventModelBuilder from './EventModelBuilder';
import { tzDateTimeStringToUtc } from '../helpers/date';
import { getApi, createPayloadFromFindResponse } from './BaseModelBuilder';
import EventStatus from '../api/EventStatus';

class CustomerEventModelBuilder extends EventModelBuilder {
  constructor(apiKey, clubId, settingsKey = 'settings') {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;

    this.generators.push(this.customerEventGenerator);
  }

  /**
   * @private
   */
  customerEventGenerator() {
    return {
      fetchAttending: thunk(
        async (
          actions,
          { dateFrom, dateTo, page = 0, limit = 1000, sort, unpaged = true, ...rest },
          { injections, getState }
        ) => {
          const tz = injections.globalStore.getState()[this.settingsKey].timezone;
          const dateFromUtc = dateFrom ? tzDateTimeStringToUtc(dateFrom, tz) : undefined;
          const dateToUtc = dateTo ? tzDateTimeStringToUtc(dateTo, tz) : undefined;

          const { data } = await getApi(injections, getState()).findAttending({
            page,
            limit,
            sort,
            extraParams: {
              dateFrom: dateFromUtc,
              dateTo: dateToUtc,
              clubId: this.clubId,
              unpaged,
              ...rest,
            },
          });
          const payload = createPayloadFromFindResponse(data);

          payload.rows = payload.rows
            .map((r) => ({
              ...r,
            }))
            .filter((r) => r.eventRsvpList.some((rsvp) => rsvp.status !== EventStatus.Cancelled));

          actions.fetchedList({ ...payload, dateFrom, dateTo });
          return payload;
        }
      ),

      // Overrides parent and blanks it
      onRsvpChange: thunkOn(
        () => [],
        async () => { }
      ),
    };
  }
}

export default CustomerEventModelBuilder;
