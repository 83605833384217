import { GetUserMemberAccessLogRequestParams } from "@gymflow/common/lib/api/accessApi";
import { ApiTokenListResponse, UserMemberAccessLogItem } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";

import { accessQueryKeys } from "./accessQueryKeys";

type Api = {
  accessApi: {
    getUserMemberAccessLog: (
      params: GetUserMemberAccessLogRequestParams,
    ) => Promise<{ data: ApiTokenListResponse<UserMemberAccessLogItem> }>;
  };
};

export const useInfiniteQueryUserMemberAccessLog = ({
  api,
  params,
}: {
  api: Api;
  params: GetUserMemberAccessLogRequestParams;
}) =>
  useInfiniteQuery({
    queryKey: accessQueryKeys.getUserMemberAccessLog(params),
    queryFn: async ({ pageParam }: { pageParam?: string }) =>
      (
        await api.accessApi.getUserMemberAccessLog({
          ...params,
          nextPageToken: pageParam,
        })
      ).data,
    getNextPageParam: (lastPage) => lastPage?.nextPageToken,
  });
