import { ApiListResponse, TaskDTO } from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { deadlineDatesToZonedTime } from "./deadlineDatesToZonedTime";
import { taskQueryKeys } from "./taskQueryKeys";
import { TaskListFilter } from "./useTaskList";

interface TaskApi {
  find: (params: TaskListFilter) => Promise<{ data: ApiListResponse<TaskDTO> }>;
}

export const useInfiniteQueryTaskList = ({
  api,
  opts,
  tz,
}: {
  api: {
    taskApi: TaskApi;
  };
  tz: string;
  opts: TaskListFilter;
}) =>
  useInfiniteQuery({
    queryKey: taskQueryKeys.list(opts),
    queryFn: async ({ pageParam = 0 }: { pageParam?: number }) => {
      let parsedOpts = merge({}, opts, {
        extraParams: {
          ...opts.extraParams,
          page: pageParam,
        },
      });

      const response = await api.taskApi.find(parsedOpts);
      return response.data as ApiListResponse<TaskDTO>;
    },
    getNextPageParam: (lastPage) =>
      lastPage.last ? undefined : lastPage.number + 1,
    select: (data) => ({
      ...data,
      pages: data.pages.map((page) => ({
        ...page,
        content: page.content.map((task) => ({
          ...task,
          ...deadlineDatesToZonedTime(task, tz),
        })),
      })),
    }),
  });
