import { ActivityDTO, ActivityStatus, ApiListResponse } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityAsPublicQueryKeys } from "./activityAsPublicQueryKeys";

export async function activityListAsPublicQueryFn({
  filter,
  api,
}: {
  filter?: ActivityListAsPublicFilter;
  api: ActivityListAsPublicApi;
}) {
  const result = await api.public.activityApi.find({ ...filter });
  return result.data as ApiListResponse<ActivityDTO>;
}

export function useQueryActivityListAsPublic({
  api,
  opts,
}: {
  api: ActivityListAsPublicApi;
  opts?: ActivityListAsPublicFilter;
}) {
  const result = useQuery({
    queryKey: activityAsPublicQueryKeys.list(opts),
    queryFn: () => activityListAsPublicQueryFn({ api, filter: opts }),
    initialData: defaultPage<ActivityDTO>,
  });
  return result;
}

interface ActivityListAsPublicApi {
  public: {
    activityApi: {
      find: (arg0: { [k: string]: any }) => Promise<any>;
    };
  };
}

interface ActivityListAsPublicFilter {
  readonly extraParams?: {
    readonly status?: ActivityStatus;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
