import { useStatistic } from "@gymflow/api";
import { DATE_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from "@gymflow/common";
import moment from "moment-timezone";
import qs from "qs";
import { useMemo } from "react";
import { useLocation } from "react-router";

import { usePortalRoutes } from "../../hooks";
import { useClubSettings } from "../../providers";
import { RouteFeature, RouteLayout } from "../../routes";
import useGymflowModels from "../../store";
import { reportHomeRoutes } from "../../views/reports/reportHomeRoutes";
import { TotalVisitsChart, VisitPercentageChart } from "../organisms";
import { ReportContext } from "./ReportContext";
import { ReportNoVisitsWidget } from "./Widgets/Visit/NoVisits";
import { ReportTotalVisitsWidget } from "./Widgets/Visit/TotalVisits";

export function Visits() {
  const { api } = useGymflowModels();

  const settings = useClubSettings();
  const location = useLocation();

  const dates = useMemo(() => {
    const { dates } = qs.parse(location.search.slice(1));
    return dates as { startDate: string; endDate: string };
  }, [location.search]);

  const { data, isFetching } = useStatistic({
    api,
    clubId: settings.clubId,
    start:
      dates?.startDate &&
      moment(dates.startDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    end:
      dates?.endDate &&
      moment(dates.endDate, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
    trendPeriod: "DAY",
    categories: ["visit"],
    visitFields: ["total", "noVisits"],
  });

  const { createClubLink } = usePortalRoutes();
  const visitRoute = createClubLink(
    RouteLayout.Staff,
    RouteFeature.Reports,
    reportHomeRoutes.Visits,
  );

  return (
    <ReportContext.Provider
      value={{
        isLoading: isFetching,
        startDate: dates?.startDate,
        endDate: dates?.endDate,
        visit: data?.visit && {
          total: { total: data.visit.total!.total! },
          noVisits: { total: data.visit.noVisits!.total },
        },
      }}
    >
      <div>
        <div className="mt-6 text-lg font-semibold">Visits Summary</div>
        <div className="mx-[-.75rem] flex flex-wrap">
          <ReportTotalVisitsWidget parentRoute={visitRoute} />
          <ReportNoVisitsWidget parentRoute={visitRoute} />
          <VisitPercentageChart />
        </div>

        <div className="mt-6 flex flex-wrap">
          <TotalVisitsChart className="flex-1" />
        </div>
      </div>
    </ReportContext.Provider>
  );
}
