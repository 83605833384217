import { AnyAbility } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { Consumer, createContext } from "react";

export const AbilityContext = createContext<AnyAbility>(
  undefined as unknown as AnyAbility,
);
export const Can = createContextualCan(
  AbilityContext.Consumer as Consumer<AnyAbility>,
);

export const anyRecord = () => ({});
