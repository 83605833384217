import endpoints from "./endpoints";
import { canCreate, canTrimFields } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicLeadApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.publicLead}`,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, canTrimFields(state), canCreate(state), {
    findLeadSources() {
      return state.http.get(`${clubPrefix}${endpoints.publicLead}/source`);
    },
  });
};

export default publicLeadApi;
