import { zonedTimeToUtc } from "@gymflow/helpers";
import { AppointmentDTO, AppointmentPostDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { appointmentAsMemberQueryKeys } from "./appointmentAsMemberQueryKeys";

export function useAppointmentCreateAsMember(
  {
    api,
    tz,
  }: {
    api: {
      customerAppointmentApi: {
        create: (
          fields: AppointmentPostDTO,
        ) => Promise<{ data: AppointmentDTO }>;
      };
    };
    tz?: string;
  },
  mutationOpts?: UseMutationOptions<
    AppointmentDTO,
    unknown,
    AppointmentPostDTO
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newAppointment) => {
      const response = await api.customerAppointmentApi.create({
        ...newAppointment,
        startDate: zonedTimeToUtc(newAppointment.startDate, tz!),
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appointmentAsMemberQueryKeys.all(),
      });
    },
    ...mutationOpts,
  });

  return mutation;
}
