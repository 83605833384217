import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberInvoice({
  api,
  memberId,
}: {
  api: ApiType & {
    strongCustomerAuthorizationApi: {
      cancelAuthorization: (invoiceId: string) => Promise<void>;
    };
  };
  memberId?: string;
}) {
  const queryClient = useQueryClient();

  /*** @deprecated adjustInvoiceMutation instead */
  const creditInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
      amount,
    }: {
      memberId: string;
      clubId: number;
      invoiceNumber: string;
      amount: number;
    }) => api.memberApi.creditInvoice(memberId, clubId, invoiceNumber, amount),
    onSuccess: async (_, { memberId }) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoicesNew(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });
  /*** @deprecated adjustInvoiceMutation instead */
  const debitInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
      amount,
    }: {
      memberId: string;
      clubId: number;
      invoiceNumber: string;
      amount: number;
    }) => api.memberApi.debitInvoice(memberId, clubId, invoiceNumber, amount),
    onSuccess: async (_, { memberId }) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoicesNew(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });
  const adjustInvoiceMutation = useMutation({
    mutationFn: ({
      invoiceId,
      amount,
    }: {
      invoiceId: string;
      amount: number;
    }) => api.memberApi.adjustInvoice(invoiceId, amount),
    onSuccess: async () => {
      if (!memberId) return;
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoicesNew(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });
  const collectInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
      amount,
    }: {
      memberId: string;
      clubId: number;
      invoiceNumber: string;
      amount: number;
    }) => api.memberApi.collectInvoice(memberId, clubId, invoiceNumber, amount),
    onSuccess: async (_, { memberId }) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoicesNew(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  const refundInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
    }: {
      memberId: string;
      clubId: number;
      invoiceNumber: string;
    }) => api.memberApi.refundInvoice(memberId, clubId, invoiceNumber),
    onSuccess: async (_, { memberId }) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoicesNew(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  const writeOffInvoiceMutation = useMutation({
    mutationFn: ({
      memberId,
      clubId,
      invoiceNumber,
    }: {
      memberId: string;
      clubId: number;
      invoiceNumber: string;
    }) => api.memberApi.writeOffInvoice(memberId, clubId, invoiceNumber),
    onSuccess: async (_, { memberId }) => {
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoicesNew(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });
  const cancelAuthorizationMutation = useMutation({
    mutationFn: ({
      paymentIntentIdForAuthorization,
    }: {
      paymentIntentIdForAuthorization: string;
    }) =>
      api.strongCustomerAuthorizationApi.cancelAuthorization(
        paymentIntentIdForAuthorization,
      ),
    onSuccess: async () => {
      if (!memberId) return;
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.invoices(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.details(memberId),
      });
      await queryClient.invalidateQueries({
        queryKey: memberQueryKeys.highlightedInvoice(memberId),
      });
    },
  });

  return {
    creditInvoiceMutation,
    debitInvoiceMutation,
    collectInvoiceMutation,
    refundInvoiceMutation,
    writeOffInvoiceMutation,
    cancelAuthorizationMutation,
    adjustInvoiceMutation,
  };
}
