import classNames from "classnames";

type TextAreaInputProps = Omit<React.HTMLProps<HTMLTextAreaElement>, "type">;

export const TextAreaInputClassNames =
  "block w-full rounded-md border-0 py-1.5 px-3.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

/**
 * @deprecated use atoms/base/Textarea instead
 */
export function TextAreaInput({ className, ...props }: TextAreaInputProps) {
  return (
    <textarea
      rows={4}
      className={classNames(TextAreaInputClassNames, className)}
      {...props}
    />
  );
}
