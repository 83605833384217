import { tzDateTimeStringToUtc } from "@gymflow/common";
import { utcToZonedTime } from "@gymflow/helpers";
import {
  InvoiceRevenueReportDTO,
  InvoiceRevenueReportItemDTO,
  RevenueType,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export interface UseRevenueByTypeReportProps {
  api: {
    revenueApi: {
      fetchRevenueByType: (
        params: {
          limit: number;
          page: number;
          dateFrom?: string;
          dateTo?: string;
        },
        revenueType: RevenueType,
      ) => Promise<any>;
      total: (
        params: {
          dateFrom?: string;
          dateTo?: string;
        },
        revenueType: RevenueType,
      ) => Promise<any>;
    };
  };
  dateFrom?: string;
  dateTo?: string;
  limit: number;
  page: number;
  revenueType: RevenueType;
  tz: string;
}

export function useRevenueByTypeReport({
  api,
  limit,
  dateFrom,
  dateTo,
  page,
  revenueType,
  tz,
}: UseRevenueByTypeReportProps) {
  const result = useQuery({
    queryKey: [
      reportQueryKeys.revenueAccountsReceivable({
        page,
        limit,
        dateFrom,
        dateTo,
        revenueType,
      }),
    ],
    queryFn: async () => {
      const resultRequest = api.revenueApi.fetchRevenueByType(
        {
          page,
          limit,
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
        },
        revenueType,
      );
      const totalRequest = api.revenueApi.total(
        {
          ...(dateFrom !== undefined
            ? { dateFrom: tzDateTimeStringToUtc(dateFrom, tz) }
            : {}),
          ...(dateTo !== undefined
            ? { dateTo: tzDateTimeStringToUtc(dateTo, tz) }
            : {}),
        },
        revenueType,
      );
      const [result, total] = await Promise.all([resultRequest, totalRequest]);
      return {
        data: result.data,
        total: total.data.total,
      } as InvoiceRevenueReportDTO;
    },
    placeholderData: () => {
      return {
        data: defaultPage<InvoiceRevenueReportItemDTO>(),
        total: 0,
      };
    },
    select: (data) => {
      let parsed: any = cloneDeep(data);
      parsed.data.content = parsed.data.content.map(
        (row: InvoiceRevenueReportItemDTO) => {
          return {
            ...row,
            endContractDate: row.endContractDate
              ? utcToZonedTime(row.endContractDate, tz)
              : undefined,
          };
        },
      );
      return parsed as InvoiceRevenueReportDTO;
    },
    enabled: !!dateFrom && !!dateTo && !!tz,
  });
  return result;
}
