import { action, thunk, thunkOn } from 'easy-peasy';

import BaseModelBuilder, { getApi } from './BaseModelBuilder';

class ClubModelBuilder extends BaseModelBuilder {
  constructor(apiKey) {
    super(apiKey);
    this.generators.push(this.connectGenerator);
  }

  /**
   * @private
   */
  connectGenerator() {
    return {
      fetchConnectStatus: thunk(async (actions, { clubId }, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).connectStatus(clubId);
        actions.updateStatus({ status: data });
        return data;
      }),

      update: thunk((_, { id, patchedFields }, { injections, getState }) => {
        const updatedClub = { ...patchedFields };
        delete updatedClub.termsConditions;
        return getApi(injections, getState()).update(id, {
          patchedFields: updatedClub,
          termsAndConditionsBlob: patchedFields.termsConditions,
        });
      }),

      updateStatus: action((state, { status }) => {
        state.connectStatus = status;
      }),

      onFetchClubIdFetchConnectStatus: thunkOn(
        (actions) => [actions.fetchById.startType],
        (actions, { payload }) => {
          actions.fetchConnectStatus({ clubId: payload });
        }
      ),
    };
  }
}

export default ClubModelBuilder;
