import { QueryKey } from "@tanstack/react-query";

export const availabilityQueryKeys = {
  all: () => ["availability"] as QueryKey,
  hostAvailability: (staffId: string) =>
    [...availabilityQueryKeys.all(), "host", staffId] as QueryKey,
  allHostOverrides: (staffId: string) =>
    [...availabilityQueryKeys.all(), "host", staffId, "override"] as QueryKey,
  listHostOverrides: (
    staffId: string,
    opts: Readonly<{ [k: string]: any }> = {},
  ) =>
    [
      ...availabilityQueryKeys.allHostOverrides(staffId),
      "list",
      opts,
    ] as QueryKey,
  hostOverride: (staffId: string, overrideId?: number) =>
    [
      ...availabilityQueryKeys.allHostOverrides(staffId),
      overrideId,
    ] as QueryKey,
  hostCalculateAvailability: (
    filter?: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      dateTo?: string;
      appointableHostsIdList?: string[];
    }>,
  ) => {
    if (filter) {
      return [
        ...availabilityQueryKeys.all(),
        "host",
        "calculate",
        filter,
      ] as QueryKey;
    }

    return [...availabilityQueryKeys.all(), "host", "calculate"] as QueryKey;
  },
  hostCalculateAvailabilityMappedByHost: (
    filter?: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      dateTo?: string;
      appointableHostsIdList?: string[];
    }>,
  ) => {
    if (filter) {
      return [
        ...availabilityQueryKeys.all(),
        "host",
        "calculate-mapped-by-host",
        filter,
      ] as QueryKey;
    }
    return [
      ...availabilityQueryKeys.all(),
      "host",
      "calculate-mapped-by-host",
    ] as QueryKey;
  },
  hostCalculateAvailabilityMultipleDays: (
    filter?: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      appointableHostId?: string;
      numberOfDays?: number;
      ignoreAppointments?: boolean;
    }>,
  ) => {
    return [
      ...availabilityQueryKeys.all(),
      "host",
      "calculate-multiple-days",
      filter,
    ] as QueryKey;
  },
  facilityAvailability: (facilityId: number) =>
    [...availabilityQueryKeys.all(), "facility", facilityId] as QueryKey,
  allFacilityOverrides: (facilityId: number) =>
    [
      ...availabilityQueryKeys.all(),
      "facility",
      facilityId,
      "override",
    ] as QueryKey,
  facilityOverride: (facilityId: number, overrideId?: number) =>
    [
      ...availabilityQueryKeys.allFacilityOverrides(facilityId),
      overrideId,
    ] as QueryKey,
  listFacilityOverrides: (
    facilityId: number,
    opts: Readonly<{ [k: string]: any }> = {},
  ) =>
    [
      ...availabilityQueryKeys.allFacilityOverrides(facilityId),
      "list",
      opts,
    ] as QueryKey,
  facilityCalculateAvailability: (
    filter?: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      dateTo?: string;
      appointableHostsIdList?: string[];
      appointableFacilityIdList: number[];
    }>,
  ) => {
    if (filter) {
      return [
        ...availabilityQueryKeys.all(),
        "facility",
        "calculate",
        filter,
      ] as QueryKey;
    }

    return [
      ...availabilityQueryKeys.all(),
      "facility",
      "calculate",
    ] as QueryKey;
  },
  facilityCalculateAvailabilityMappedByFacility: (
    filter?: Readonly<{
      appointableId?: number;
      dateFrom?: string;
      dateTo?: string;
      appointableHostsIdList?: string[];
      appointableFacilityIdList?: number[];
    }>,
  ) => {
    if (filter) {
      return [
        ...availabilityQueryKeys.all(),
        "facility",
        "calculate-mapped-by-facility",
        filter,
      ] as QueryKey;
    }
    return [
      ...availabilityQueryKeys.all(),
      "facility",
      "calculate-mapped-by-facility",
    ] as QueryKey;
  },
};
