export const mapCategoriesToCreditPacks = (
  creditPacks,
  categories,
  appointableCategories = [],
) => {
  return creditPacks.map((pack) => {
    pack.categoryNames = pack.categoryIdList.reduce((acc, categoryId) => {
      const matching = categories.find((c) => c.id === categoryId);
      if (matching) {
        acc.push(matching.name);
      }
      return acc;
    }, []);
    pack.appointableCategoryNames = pack.appointableCategoryIdList.reduce(
      (acc, categoryId) => {
        const matching = appointableCategories.find((c) => c.id === categoryId);
        if (matching) {
          acc.push(matching.name);
        }
        return acc;
      },
      [],
    );
    return pack;
  });
};
