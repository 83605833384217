import { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Spinner } from '@gymflow/common';

import MembershipFilterableList from './MembershipFilterableList';

function Step2Memberships({ value, onChange }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      {isLoading ? <Spinner hidden={!isLoading} /> : null}
      <div className="w-100 text-center" style={{ opacity: isLoading ? '0.2' : '1' }}>
        <b className="h2">Select specific memberships to display</b>
        <Row className="mt-4 text-left d-flex justify-content-center">
          <Col md={8}>
            <p className="text-uppercase text-muted">Choose Memberships</p>
            <MembershipFilterableList value={value} onChange={onChange} onChangeLoading={setIsLoading} />
          </Col>
        </Row>
      </div>
    </>
  );
}

Step2Memberships.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Step2Memberships;
