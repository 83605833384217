import { useStoreState } from "easy-peasy";
import { useCallback, useContext, useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import usersIcon from "../../assets/img/users-icon.svg";
import { QuickActionsButton } from "../components/atoms/QuickActionsButton";
import PillTabsLayout from "../components/molecules/PillTabsLayout";
import { ActionMenuButtons } from "../components/SearchGlobal/ActionMenuButtons";
import { ShopSidebarContext } from "../components/Shop/ShopSidebarContext";
import LeadOverview from "../components/UserMember/LeadOverview";
import { UserMemberNotes } from "../components/UserMember/Notes/UserMemberNotes";
import Timeline from "../components/UserMember/Timeline/Timeline";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { PageTitleProviderContext, useClubSettings } from "../providers";
import useGymflowModels from "../store";

export const LeadProfilePage = {
  Profile: "/profile",
  Account: "/account",
  Sessions: "/sessions",
  Payments: "/payments",
  Notes: "/notes",
  Timeline: "/timeline",
};
function LeadProfile() {
  const { LeadStore, TimelineStore, settingsStore, TaskStore } =
    useGymflowModels();
  const { createLeadLink } = usePortalRoutes();
  const match = useRouteMatch();
  const { id } = match.params;
  const settings = useClubSettings();

  const {
    clearEditingRecord,
    update: updateUser,
    create: createUser,
    changeLeadColumn,
    fetchById,
  } = LeadStore.useStoreActions((actions) => actions);
  const { editing, loadingRecord } = LeadStore.useStoreState((state) => state);
  const { setPageTitle } = useContext(PageTitleProviderContext);

  useEffect(() => {
    if (editing?.firstName && editing?.lastName) {
      setPageTitle({
        title: `${editing.firstName} ${editing.lastName}`,
        iconUrl: usersIcon,
      });
    }
  }, [setPageTitle, editing?.firstName, editing?.lastName]);

  const { fetchList: fetchNodes, refreshList } = TimelineStore.useStoreActions(
    (actions) => actions,
  );
  const {
    rows: nodes,
    last: nodesIsLast,
    loadingList,
  } = TimelineStore.useStoreState((state) => state);
  const { fetchById: fetchTask } = TaskStore.useStoreActions(
    (actions) => actions,
  );

  const clubId = settings.clubId;
  const { defaultCurrency: currency } = useStoreState(settingsStore);

  useEffect(() => {
    fetchById(id);
    return () => clearEditingRecord();
  }, [id, fetchById, clearEditingRecord]);

  const createUserAndRefresh = useCallback(
    async (newUser) => {
      await createUser({ clubId, ...newUser });
      return fetchById(id);
    },
    [createUser, fetchById, clubId, id],
  );

  const updateUserAndRefresh = useCallback(
    async (updatedUser) => {
      await updateUser({ clubId, ...updatedUser });
      return fetchById(id);
    },
    [updateUser, fetchById, clubId, id],
  );

  const updateLeadStatusAndRefresh = useCallback(
    async (updatedLeadStatus) => {
      await changeLeadColumn(updatedLeadStatus);
      return fetchById(id);
    },
    [changeLeadColumn, fetchById, id],
  );

  const layoutConfig = [
    {
      text: "Profile",
      path: createLeadLink(id, LeadProfilePage.Profile),
      component: LeadOverview,
      props: {
        user: editing,
        isLoading: loadingRecord,
        updateUser: updateUserAndRefresh,
        updateLeadStatus: updateLeadStatusAndRefresh,
        upgradeToFullUser: createUserAndRefresh,
      },
    },
    {
      text: "Notes",
      path: createLeadLink(id, LeadProfilePage.Notes),
      component: UserMemberNotes,
      props: {
        leadId: id,
        name: `${editing?.firstName} ${editing?.lastName}`,
      },
    },
    {
      text: "Timeline",
      path: createLeadLink(id, LeadProfilePage.Timeline),
      component: Timeline,
      props: {
        isLoading: loadingList,
        leadId: editing?.id,
        onFetchTask: fetchTask,
        fetchNodes: fetchNodes,
        value: nodes,
        isLast: nodesIsLast,
        refreshNodes: refreshList,
      },
    },
  ];
  const { showShop } = useContext(ShopSidebarContext);
  if (!editing) {
    return null;
  }

  return (
    <Switch>
      <Route path={createLeadLink(id)}>
        <PillTabsLayout
          className="p-4 lg:p-8"
          tabs={layoutConfig}
          moreActions={
            <>
              <ActionMenuButtons
                className="hidden lg:flex"
                leadId={id}
                showShop={showShop}
              />
              <QuickActionsButton
                className="flex lg:hidden"
                variant="secondary"
                leadId={id}
              />
            </>
          }
        />
      </Route>
    </Switch>
  );
}

const LeadProfileWithProvider = () => {
  const { LeadStore, TimelineStore, TaskStore } = useGymflowModels();
  return (
    <LeadStore.Provider>
      <TimelineStore.Provider>
        <TaskStore.Provider>
          <LeadProfile />
        </TaskStore.Provider>
      </TimelineStore.Provider>
    </LeadStore.Provider>
  );
};

export default LeadProfileWithProvider;
