export function ShopBagIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={pathClassName}
        d="M13.333 6.66667C13.333 7.55072 12.9818 8.39857 12.3567 9.02369C11.7315 9.64881 10.8837 10 9.99964 10C9.11558 10 8.26774 9.64881 7.64261 9.02369C7.01749 8.39857 6.6663 7.55072 6.6663 6.66667M3.02732 6.16782L2.44399 13.1678C2.31867 14.6716 2.25601 15.4235 2.51021 16.0035C2.73354 16.5131 3.12049 16.9336 3.60979 17.1985C4.1667 17.5 4.92119 17.5 6.43017 17.5H13.5691C15.0781 17.5 15.8326 17.5 16.3895 17.1985C16.8788 16.9336 17.2657 16.5131 17.4891 16.0035C17.7433 15.4235 17.6806 14.6716 17.5553 13.1678L16.972 6.16782C16.8641 4.87396 16.8102 4.22703 16.5237 3.73738C16.2714 3.3062 15.8957 2.9605 15.445 2.74487C14.9333 2.5 14.2841 2.5 12.9858 2.5L7.0135 2.5C5.71516 2.5 5.06598 2.5 4.55423 2.74487C4.10359 2.9605 3.72788 3.3062 3.47557 3.73738C3.18905 4.22703 3.13514 4.87396 3.02732 6.16782Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
