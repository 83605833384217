import classNames from "classnames";
import omit from "lodash/omit";
import { useMemo } from "react";
import { twMerge } from "tailwind-merge";

import { useProgressButton } from "../../hooks/useProgressButton";
import { Spinner } from "./Spinner";

/**
 * @deprecated Button with primary intent
 */
export function PrimaryButton({
  children,
  type = "button",
  className,
  showSpinner,
  ...props
}: ButtonProps) {
  const { disabled, onClick, inProgress } = useProgressButton({
    onClick: props.onClick,
    disabled: props.disabled,
  });
  const buttonProps = useMemo(
    () => omit(props, ["disabled", "onClick"]),
    [props],
  );

  return (
    <button
      type={type}
      className={twMerge(
        classNames(
          "hover:bg-primary-300 bg-primary-600 text-gray-25 mt-2 flex h-11 cursor-pointer items-center justify-center rounded-lg px-4 font-semibold capitalize",
          { "hover:bg-primary-600 cursor-default opacity-80": disabled },
        ),
        className,
      )}
      onClick={onClick}
      disabled={disabled}
      {...buttonProps}
    >
      <Spinner
        className={classNames(
          { hidden: !inProgress && !showSpinner },
          "absolute text-white",
        )}
      />
      <div className={classNames({ invisible: inProgress || showSpinner })}>
        {children}
      </div>
    </button>
  );
}

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, "type"> {
  readonly showSpinner?: boolean;
  readonly type?: "button" | "submit";
}
