import { ClubBean } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { clubQueryKeys } from "./clubQueryKeys";

export function useClub(
  {
    api,
    clubId,
  }: {
    api: {
      clubApi: { findById: (clubId: number) => Promise<{ data: ClubBean }> };
    };
    clubId: number;
  },
  opts?: UseQueryOptions<ClubBean | null>,
) {
  const result = useQuery({
    queryKey: clubQueryKeys.single(clubId),
    queryFn: async () => {
      const result = await api.clubApi.findById(clubId);
      return result.data;
    },
    enabled: !!clubId,
    placeholderData: null,
    ...opts,
  });

  return result;
}

const FIVE_MINUTES_IN_MS = 5 * (60 * 1000);
/**
 * @description Use this stale time when you just need properties
 * from the club that aren't changing regularly like timezone. **If this is set
 * to more than five minutes, the cache time also has to be adjusted!**
 */
export const clubStaleTime = FIVE_MINUTES_IN_MS;
