import { useMutation, useQueryClient } from "@tanstack/react-query";

import { clubQueryKeys } from "../club";
import { clubAsPublicQueryKeys } from "../clubAsPublic";

export function useMutationStripeCompleteOnboard({
  api,
}: {
  api: {
    clubApi: {
      stripeCompleteOnboard: (clubId: number) => Promise<{ data: void }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ clubId }: { clubId: number }) =>
      api.clubApi.stripeCompleteOnboard(clubId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: clubAsPublicQueryKeys.all() });
    },
  });
  return mutation;
}
