import { TwoWayObjectMapper } from "@gymflow/common";

export class PromotionFormMapper extends TwoWayObjectMapper {
  constructor() {
    super({
      defaultInKey: TwoWayObjectMapper.defaultKeyTransformer,
      inKey: {},
      inValue: [],
      defaultOutKey: TwoWayObjectMapper.defaultKeyTransformer,
      outKey: {},
      outValue: [],
    });
  }

  override to(values: Record<string, any>) {
    const parsed = super.to(values) as Record<string, any>;

    if (parsed["recurringDiscount"]) {
      if (parsed["recurringDiscountDuration"]) {
        parsed["recurringDiscountDurationType"] = "MULTIPLE_CYCLES";
      } else {
        parsed["recurringDiscountDurationType"] = "FOREVER";
      }
      if (parsed["recurringDiscountDuration"] === null) {
        parsed["recurringDiscountDuration"] = "";
      }
    }

    if (parsed["limitCategories"] && parsed["limitCategories"]?.length > 0) {
      parsed["limitType"] = "CATEGORIES";
    } else {
      parsed["limitType"] = "SPECIFIC_ITEMS";
    }

    return parsed;
  }

  override from(values: Record<string, any>) {
    const parsed: any = super.from(values);

    parsed["upfrontDiscountAmount"] = parseNumberIfExists(
      parsed["upfrontDiscountAmount"],
    );
    parsed["recurringDiscountAmount"] = parseNumberIfExists(
      parsed["recurringDiscountAmount"],
    );
    parsed["recurringDiscountDuration"] = parseNumberIfExists(
      parsed["recurringDiscountDuration"],
    );
    parsed["limitUsages"] = parseNumberIfExists(parsed["limitUsages"]) ?? null;
    parsed["limitUsagesPerMember"] =
      parseNumberIfExists(parsed["limitUsagesPerMember"]) ?? null;

    if (!parsed["upfrontDiscount"]) {
      delete parsed["upfrontDiscountAmount"];
      delete parsed["upfrontDiscountType"];
    }

    if (parsed["recurringDiscount"]) {
      if (!parsed["recurringDiscountDuration"]) {
        parsed["recurringDiscountDuration"] = null;
      }
    } else {
      delete parsed["recurringDiscountAmount"];
      delete parsed["recurringDiscountType"];
      delete parsed["recurringDiscountDuration"];
    }

    delete parsed["recurringDiscountDurationType"];
    if (parsed["limitUsages"] === "") {
      parsed["limitUsages"] = null;
    }

    if (parsed["limitUsagesPerMember"] === "") {
      parsed["limitUsagesPerMember"] = null;
    }
    if (!parsed["expiry"] || parsed["expiry"] === "") {
      parsed["expiry"] = null;
    }

    if (parsed["limitType"] === "SPECIFIC_ITEMS") {
      delete parsed["limitCategories"];
    } else if (parsed["limitType"] === "CATEGORIES") {
      delete parsed["limitMembershipList"];
      delete parsed["limitSessionPackList"];
      delete parsed["limitProductList"];
      delete parsed["limitAppointableList"];
    }
    delete parsed["limitType"];

    return parsed;
  }
}

function parseNumberIfExists(numberString: string) {
  if (
    numberString === undefined ||
    numberString === "" ||
    numberString === null
  ) {
    return undefined;
  }
  return +numberString;
}
