import { useContext } from "react";
import { Button } from "reactstrap";
import { AlertContext, AsyncButton } from "@gymflow/common";
import ReactBsAlert from "react-bootstrap-sweetalert";

function CancelSessionPackAlert({ onConfirm }) {
  const { hide } = useContext(AlertContext);
  const renderButtons = () => (
    <>
      <Button size="sm" outline color="primary" type="button" onClick={hide} style={{ marginRight: "8px" }}>
        No
      </Button>
      <AsyncButton
        size="sm"
        color="primary"
        type="button"
        onClick={async () => {
          await onConfirm();
          hide();
        }}
      >
        Yes
      </AsyncButton>
    </>
  );
  return (
    <ReactBsAlert
      title="Are you sure you want to revoke this credit pack?"
      closeOnClickOutside={false}
      customButtons={renderButtons()}
      onConfirm={async () => {
        await onConfirm();
        hide();
      }}
      onCancel={hide}
    >
      <div className="d-flex flex-column align-items-center">The user will lose permanent access to the credits.</div>
    </ReactBsAlert>
  );
}

export default CancelSessionPackAlert;
