import { useContext } from "react";

import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportMembersActiveWidget = () => {
  const data = useContext(ReportContext);

  return (
    <ReportWidgetCard
      title="Active Memberships"
      value={data.members?.active?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Sum of all recurring and pre-paid membership with an Active status."
      variation={data.members?.active?.variation}
    />
  );
};
