import { LeadDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export async function leadQueryFn({
  leadId,
  api,
}: {
  leadId: number;
  api: { leadApi: { findById: (arg0: number) => Promise<any> } };
}) {
  const result = await api.leadApi.findById(leadId);
  return result.data as LeadDTO;
}

export function useLead(
  {
    api,
    leadId,
  }: {
    api: { leadApi: { findById: (arg0: number) => Promise<any> } };
    leadId?: number;
  },
  opts?: UseQueryOptions<LeadDTO | null>,
) {
  const result = useQuery({
    queryKey: leadQueryKeys.details(leadId),
    queryFn: () => leadQueryFn({ leadId: leadId!, api }),
    enabled: !!leadId,
    initialData: null,
    ...opts,
  });

  return result;
}
