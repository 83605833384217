import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime } from "@gymflow/helpers";
import {
  MembershipStatus,
  MembershipType,
  TokenPageableRequestParams,
} from "@gymflow/types";
import { useInfiniteQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

import { memberQueryKeys } from "./memberQueryKeys";

export const generateMemberListFilter = (
  tz: string,
  filters?: UserMemberListFilter,
): UserMemberListFilter => {
  if (!filters) {
    return {};
  }
  const startOfDay = (from?: string) =>
    DateTime.fromFormat(from ?? "", "yyyy-MM-dd", {
      zone: tz,
    })
      .startOf("day")
      .toUTC()
      .toISO() ?? undefined;
  const endOfDay = (to?: string) =>
    DateTime.fromFormat(to ?? "", "yyyy-MM-dd", {
      zone: tz,
    })
      .endOf("day")
      .toUTC()
      .toISO() ?? undefined;
  return {
    ...filters,
    createdFrom: startOfDay(filters?.createdFrom),
    createdTo: endOfDay(filters?.createdTo),
    membershipCancellationFrom: startOfDay(filters?.membershipCancellationFrom),
    membershipCancellationTo: endOfDay(filters?.membershipCancellationTo),
    membershipStartFrom: startOfDay(filters?.membershipStartFrom),
    membershipStartTo: endOfDay(filters?.membershipStartTo),
    membershipExpireFrom: startOfDay(filters?.membershipExpireFrom),
    membershipExpireTo: endOfDay(filters?.membershipExpireTo),
  };
};

export function useInfiniteQueryMemberList({
  api,
  tz,
  filters,
  pagination,
}: {
  api: ApiType;
  filters?: UserMemberListFilter;
  pagination: TokenPageableRequestParams;
  tz: string;
}) {
  return useInfiniteQuery({
    queryKey: memberQueryKeys.listNew(pagination, filters),
    queryFn: async ({ pageParam }) => {
      return (
        await api.memberApi.listNew({
          ...pagination,
          ...generateMemberListFilter(tz, filters),
          nextPageToken: pageParam,
        })
      ).data;
    },
    getNextPageParam: (lastPage) => lastPage?.nextPageToken,
    select: (data) => {
      return {
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          content: page.content.map((item) => {
            return {
              ...item,
              createdDate:
                item.createdDate && utcToZonedTime(item.createdDate, tz),
            };
          }),
        })),
      };
    },
  });
}


export type UserMemberListFilter = {
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
  membershipId?: number[];
  membershipStatus?: MembershipStatus[];
  membershipType?: MembershipType[];
  membershipStartFrom?: string;
  membershipStartTo?: string;
  membershipCancellationFrom?: string;
  membershipCancellationTo?: string;
  membershipExpireFrom?: string;
  membershipExpireTo?: string;
  assignedStaffId?: string[];
  creditsRemainingFrom?: string;
  creditsRemainingTo?: string;
};
