import { SMSPayload, SMSSendingResponse } from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export type SendSMSToLeadsArgs = {
  smsPayload: SMSPayload;
  leadIds: number[];
};

type Api = {
  smsApi: {
    sendSMSToLeads: (
      args: SendSMSToLeadsArgs,
    ) => Promise<{ data: SMSSendingResponse }>;
  };
};

export const useMutationSendSMSToLeads = ({ api }: { api: Api }) => {
  return useMutation({
    mutationFn: async (payload: SendSMSToLeadsArgs) => {
      const { data } = await api.smsApi.sendSMSToLeads(payload);
      return data;
    },
  });
};
