import { useContext } from "react";

import ReportWidgetCard from "../../../ReportWidgetCard/ReportWidgetCard";
import { ReportContext } from "../../ReportContext";

export const ReportMembersTotalWidget = () => {
  const data = useContext(ReportContext);

  return (
    <ReportWidgetCard
      title="Total Memberships"
      value={data.members?.total?.total.toString() || "0"}
      isLoading={data.isLoading}
      explanation="Sum of all recurring and pre-paid membership in either an Active, Pending, Overdue or Paused status."
      variation={data.members?.total?.variation}
    />
  );
};
