import { EventType } from "./EventType";

type SelectOption<T extends number | string = number> = {
  label: string;
  value: T;
};

export type CalendarFilters = {
  eventType?: EventType;
  classes?: SelectOption[];
  classCategory?: SelectOption[];
  appointments?: SelectOption[];
  appointmentCategory?: SelectOption[];
  businessHours: { start: string; end: string };
  hosts?: SelectOption<string>[];
  facility?: SelectOption[];
};

export function newDefaultCalendarFilters(): CalendarFilters {
  return {
    businessHours: {
      start: "00:00",
      end: "23:59",
    },
  };
}
