import { clubStaleTime, useClub, useRoutesByBrand } from "@gymflow/api";
import { useRecordForm } from "@gymflow/common";
import { StaffUserRole } from "@gymflow/types";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import { useMemo } from "react";

import environment from "../../../environment";
import { usePortalRoutes } from "../../../hooks/usePortalRoutes";
import { ModalWrapper, useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import {
  Avatar,
  Button,
  ClubMultiSelect,
  FormikPhoneInput,
  FormikTextInput,
  PrimaryButton,
  Select,
  UploadArea,
} from "../../atoms";
import StaffFormMapper from "../../Settings/Staff/StaffFormMapper";
import createStaffSchema, {
  EMAIL,
  FIRST_NAME,
  LAST_NAME,
  LOCATIONS,
  MOBILE_NUMBER,
  PICTURE,
  ROLE,
} from "../../Settings/Staff/StaffSchema";

export function StaffFormModal({
  existingValues,
  onSubmit,
  onCancel,
  showEditingSelfRoleWarning,
}: any) {
  const { api } = useGymflowModels();
  const { brand } = usePortalRoutes();
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { data: club } = useClub({ clubId, api }, { staleTime: clubStaleTime });

  const serverUrl = environment.get("API_RESOLVER_URL");
  const { data: clubRoutes } = useRoutesByBrand({
    serverUrl,
    clubIds: club?.id ? [club?.id] : [],
    brand,
    page: 0,
    limit: 1,
  });
  const schema = useMemo(() => {
    return createStaffSchema({
      label: clubRoutes?.results[0]?.displayName,
      value: { name: clubRoutes?.results[0]?.displayName, id: club?.id },
    });
  }, [club?.id, clubRoutes?.results]);

  const { initialValues, getPatchedValues, getValues } = useRecordForm({
    record: existingValues,
    fields: schema.getDefault(),
    mapper: new StaffFormMapper(),
  });

  return (
    <ModalWrapper className="md:w-[600px] md:max-w-[600px]" onCancel={onCancel}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={async (values) => {
          if (initialValues?.id) {
            const patchedFields = getPatchedValues(values);
            await onSubmit({
              id: initialValues.id,
              patchedFields,
            });
          } else {
            const newValues = getValues(values);
            await onSubmit(newValues);
          }
        }}
        validationSchema={schema}
      >
        {({ submitForm }) => (
          <>
            <div className="mb-5">
              <div className="text-lg font-semibold">Add Staff Member</div>
              <div className="text-gray-600">
                Setup a new staff member with access to Gymflow.
              </div>
            </div>
            <div>
              <StaffForm
                showEditingSelfRoleWarning={showEditingSelfRoleWarning}
              />
            </div>
            <div className="mt-5 flex">
              <Button className="mt-2 flex-1" onClick={onCancel}>
                Cancel
              </Button>
              <PrimaryButton
                className="ml-4 flex-1"
                onClick={async () => {
                  await submitForm();
                }}
              >
                Save
              </PrimaryButton>
            </div>
          </>
        )}
      </Formik>
    </ModalWrapper>
  );
}

function StaffForm({
  showEditingSelfRoleWarning,
}: {
  showEditingSelfRoleWarning: boolean;
}) {
  const formikProps = useFormikContext();

  const values = formikProps.values as { [k: string]: any };
  const errors = formikProps.errors as { [k: string]: any };
  const touched = formikProps.touched as { [k: string]: any };

  const roleOptions: { label: string; value: StaffUserRole }[] = [
    {
      label: "Owner",
      value: "OWNER",
    },
    {
      label: "Manager",
      value: "MANAGER",
    },
    {
      label: "Reception",
      value: "RECEPTION",
    },
    {
      label: "Trainer",
      value: "TRAINER",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-6 gap-2">
        <div>
          <Avatar url={values[PICTURE].blob} className="!h-[64px] !w-[64px]" />
        </div>
        <UploadArea
          className="col-span-5"
          onChange={(file: any) => {
            formikProps.setFieldValue(PICTURE, file);
          }}
        />
      </div>
      <div className="mt-4">
        <label
          htmlFor={EMAIL}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Email
        </label>
        <div className="mt-2">
          <FormikTextInput
            type="email"
            name={EMAIL}
            id={EMAIL}
            placeholder="Enter your email address"
          />
        </div>
      </div>
      <div className="mt-4">
        <label
          htmlFor={FIRST_NAME}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          First Name
        </label>
        <div className="mt-2">
          <FormikTextInput
            name={FIRST_NAME}
            id={FIRST_NAME}
            placeholder="Enter your first name"
          />
        </div>
      </div>
      <div className="mt-4">
        <label
          htmlFor={LAST_NAME}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Last Name
        </label>
        <div className="mt-2">
          <FormikTextInput
            name={LAST_NAME}
            id={LAST_NAME}
            placeholder="Enter your last name"
          />
        </div>
      </div>
      <div className="mt-4">
        <label
          htmlFor={MOBILE_NUMBER}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Mobile Number
        </label>
        <div className="mt-2">
          <FormikPhoneInput name={MOBILE_NUMBER} id={MOBILE_NUMBER} />
        </div>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Locations
        </label>
        <div className="mt-2">
          <ClubMultiSelect
            value={values[LOCATIONS]}
            onChange={(v: unknown) => {
              formikProps.setFieldValue(LOCATIONS, v);
            }}
          />
          {errors[LOCATIONS] && touched[LOCATIONS] && (
            <div className="text-error-600 mt-2">{errors[LOCATIONS]}</div>
          )}
        </div>
      </div>
      <div className="mt-4">
        <label
          htmlFor=""
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Role
        </label>
        <div className="mt-2">
          <div
            className={classNames("text-warning-600 mb-2", {
              hidden: !showEditingSelfRoleWarning,
            })}
          >
            Changing your own role will cause you to logout to apply the new
            permissions.
          </div>
          <Select
            value={values[ROLE]}
            onChange={(v: unknown) => {
              formikProps.setFieldValue(ROLE, v);
            }}
            options={roleOptions}
          />
          {errors[ROLE] && touched[ROLE] && (
            <div className="text-error-600 mt-2">{errors[ROLE]}</div>
          )}
        </div>
      </div>
    </>
  );
}
