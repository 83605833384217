import { assert } from 'check-types';

import endpoints from './endpoints';

const strongCustomerAuthorizationEndpoints = {
  confirmPayment: `${endpoints.strongCustomerAuthorization}/confirm`,
  sendEmail: `${endpoints.strongCustomerAuthorization}/send-email`,
  cancelAuthorization: `${endpoints.strongCustomerAuthorization}/force-failure`,
};

const strongCustomerAuthorizationApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.strongCustomerAuthorization}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, {
    confirmPayment(paymentIntent) {
      assert.string(paymentIntent, 'paymentIntent must be a string');

      return state.http.get(`${clubPrefix}${strongCustomerAuthorizationEndpoints.confirmPayment}`, {
        params: {
          // eslint-disable-next-line camelcase
          payment_intent: paymentIntent,
        },
      });
    },
    sendEmail(invoiceNumber) {
      assert.string(invoiceNumber, 'invoiceNumber must be a string');

      return state.http.get(`${clubPrefix}${strongCustomerAuthorizationEndpoints.sendEmail}`, {
        params: {
          // eslint-disable-next-line camelcase
          invoice_number: invoiceNumber,
        },
      });
    },
    cancelAuthorization(paymentIntent) {
      assert.string(paymentIntent, 'paymentIntent must be a string');

      return state.http.get(`${clubPrefix}${strongCustomerAuthorizationEndpoints.cancelAuthorization}`, {
        params: {
          // eslint-disable-next-line camelcase
          payment_intent: paymentIntent,
        },
      });
    },
  });
};

export default strongCustomerAuthorizationApi;
