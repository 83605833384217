import { extendYup } from "@gymflow/common";
import { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";

import { LoadingPortal } from "../components/pages";
import { useClubSettings } from "./settings";

export function YupExtensions({ children }: { children: ReactNode }) {
  const clubSettings = useClubSettings();
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (clubSettings.date_format) {
      extendYup(Yup, clubSettings.date_format);
      setIsLoaded(true);
    }
  }, [clubSettings.date_format]);

  if (!isLoaded) {
    return <LoadingPortal message="Processing form settings..." />;
  }
  return children;
}
