import { MembershipDurationType } from "@gymflow/types";
import React from "react";
import { useTranslation } from "react-i18next";

import { LabeledForm } from "../../atoms";
import { BadgePicker } from "../../atoms/BadgePicker";
import { HostedPagesFilterPopover } from "../HostedPagesFilterPopover";

export type HostedPagesMembershipFilterPopoverProps = {
  filter: {
    durationType: MembershipDurationType[];
  };
  onChange: (
    newFilter: HostedPagesMembershipFilterPopoverProps["filter"],
  ) => void;
};

export const HostedPagesMembershipFilterPopover: React.FC<
  HostedPagesMembershipFilterPopoverProps
> = ({ filter, onChange }) => {
  const { t } = useTranslation();
  return (
    <HostedPagesFilterPopover.Container>
      <HostedPagesFilterPopover.Trigger
        isFilterEmpty={filter.durationType.length === 0}
      />
      <HostedPagesFilterPopover.Content>
        <HostedPagesFilterPopover.ContentHeader
          isFilterEmpty={filter.durationType.length === 0}
          onReset={() => {
            onChange({
              durationType: [],
            });
          }}
        />
        <div className="flex max-w-full p-4 pt-0">
          <LabeledForm
            label={t("HostedPagesMembership.Filters.ContractsLabel")}
            className="gap-y-2"
          >
            <BadgePicker
              className="max-w-[80vw] lg:max-w-full"
              options={
                [
                  {
                    label: t("Common.all"),
                    value: "ALL",
                  },
                  {
                    label: t("HostedPagesMembership.Filters.WithContract"),
                    value: "WITH_CONTRACT",
                  },
                  {
                    label: t("HostedPagesMembership.Filters.WithoutContract"),
                    value: "WITHOUT_CONTRACT",
                  },
                ] as const
              }
              onChange={(newValue) => {
                if (newValue === "ALL") {
                  onChange({
                    ...filter,
                    durationType: [],
                  });
                } else if (newValue === "WITH_CONTRACT") {
                  onChange({
                    ...filter,
                    durationType: ["DAILY", "WEEKLY", "MONTHLY"],
                  });
                } else {
                  onChange({
                    ...filter,
                    durationType: ["NO_CONTRACT"],
                  });
                }
              }}
              value={
                filter.durationType.length === 0
                  ? ("ALL" as const)
                  : filter.durationType.includes("NO_CONTRACT")
                  ? ("WITHOUT_CONTRACT" as const)
                  : ("WITH_CONTRACT" as const)
              }
            />
          </LabeledForm>
        </div>
      </HostedPagesFilterPopover.Content>
    </HostedPagesFilterPopover.Container>
  );
};
