import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  RecurringType,
  TIME_FORMAT,
} from "@gymflow/common";
import moment from "moment-timezone";
import * as Yup from "yup";

import {
  YupMixedBuilder,
  YupNumberBuilder,
} from "../../../helpers/yupBuilders";

export const ACTIVITY_ID = "activity-id";
export const START_DATE = "start-date";
export const END_DATE = "end-date";
export const START_TIME = "start-time";
export const END_TIME = "end-time";
export const DURATION = "duration";
export const IS_FULL_DAY_EVENT = "is-full-day-event";
export const FACILITY_ID = "facility-id";
export const HOST_MEMBER_ID = "host-member-id";
export const IS_BOOKABLE = "is-bookable";
export const CREDIT_COST = "session-cost";
export const CAPACITY = "capacity";
export const WAITING_LIST_CAPACITY = "wait-list-capacity";
export const IS_RECURRING = "is-recurring";
export const RECURRING_TYPE = "recurrence-type";
export const RECURRING_INTERVAL = "recurrence-period";
export const RECURRING_END_DATE = "end-recurring-date";
export const RECURRING_WEEKDAYS = "week-days";
export const IS_PUBLIC = "is-public";

export const defaultStart = moment().add(1, "hour").startOf("hour");
export const defaultEnd = defaultStart.clone().add(30, "minutes");

export const createClassDetailsSchemaFields = (
  defaultHostId?: string,
  defaultFacilityId?: number,
) => {
  let hostField = Yup.string().required();
  if (defaultHostId) {
    hostField = hostField.default(defaultHostId);
  }
  let facilityField = Yup.number().required();
  if (defaultFacilityId) {
    facilityField = facilityField.default(defaultFacilityId);
  }
  return {
    [ACTIVITY_ID]: Yup.number().required(),
    [FACILITY_ID]: facilityField,
    [HOST_MEMBER_ID]: hostField,
  };
};

export const createDateAndTimeSchemaFields = () => ({
  [START_DATE]: Yup.string()
    .required()
    .default(defaultStart.format(DATE_FORMAT)),
  [START_TIME]: Yup.string()
    .required()
    .default(defaultStart.format(TIME_FORMAT)),
  [END_DATE]: Yup.string().required().default(defaultEnd.format(DATE_FORMAT)),
  [END_TIME]: Yup.string().required().default(defaultEnd.format(TIME_FORMAT)),
  [IS_FULL_DAY_EVENT]: Yup.boolean().required().default(false),
});

export const createBookingDetailsSchemaFields = () => ({
  [IS_BOOKABLE]: Yup.boolean().required().default(true),
  [IS_PUBLIC]: Yup.boolean().default(true),
  [CREDIT_COST]: new YupNumberBuilder()
    .isRequiredIf(Yup.ref(IS_BOOKABLE), "Credit cost is required.")
    .build()
    .min(0)
    .max(100)
    .default(1),
  [CAPACITY]: new YupNumberBuilder()
    .isRequiredIf(Yup.ref(IS_BOOKABLE), "Capacity is required.")
    .build()
    .min(0)
    .max(100)
    .default(10),
  [WAITING_LIST_CAPACITY]: new YupNumberBuilder()
    .isRequiredIf(Yup.ref(IS_BOOKABLE), "Waiting list is required.")
    .build()
    .min(0)
    .max(100)
    .default(5),
});

export const createRecurrenceSchemaFields = () => ({
  [IS_RECURRING]: Yup.boolean().required().default(false),
  [RECURRING_TYPE]: new YupMixedBuilder()
    .isRequiredIf(Yup.ref(IS_RECURRING), "Recurring Type is required.")
    .build()
    .default("WEEKLY")
    .oneOf(Object.values(RecurringType)),
  [RECURRING_END_DATE]: Yup.string()
    .test(
      "is-later-than-start",
      "Must be later than start date.",
      function (value) {
        if (!this.parent[IS_RECURRING] || !value) {
          return true;
        }
        return moment(this.parent[START_DATE], DATE_FORMAT).isBefore(
          moment(value, DATE_FORMAT),
        );
      },
    )
    .nullable()
    .default(null),
  [RECURRING_INTERVAL]: new YupNumberBuilder()
    .isRequiredIf(Yup.ref(IS_RECURRING), "Recurring interval is required.")
    .build()
    .default(1)
    .test(
      "valid-recurring-interval",
      "Invalid recurring interval.",
      function (value) {
        if (this.parent[IS_RECURRING]) {
          if (value <= 0) {
            return false;
          }
          switch (this.parent[RECURRING_TYPE]) {
            case RecurringType.Daily:
              return value <= 7;
            case RecurringType.Weekly:
              return value <= 4;
            case RecurringType.Monthly:
              return value <= 12;
            case RecurringType.Yearly:
              return value <= 2;
            default:
              return false;
          }
        }
        return true;
      },
    ),
  [RECURRING_WEEKDAYS]: Yup.array()
    .test(
      "has-at-least-one-weekday",
      "Must have at least one selected.",
      function (value) {
        if (
          this.parent[IS_RECURRING] &&
          this.parent[RECURRING_TYPE] === RecurringType.Weekly
        ) {
          return !!(value && value.length > 0);
        }
        return true;
      },
    )
    .default([]),
});

export const createEventSchema = (
  defaultHostId?: string,
  defaultFacilityId?: number,
) =>
  Yup.object()
    .shape(createClassDetailsSchemaFields(defaultHostId, defaultFacilityId))
    .shape(createDateAndTimeSchemaFields())
    .shape(createBookingDetailsSchemaFields())
    .shape(createRecurrenceSchemaFields());

export const createEventSchemaWithDate = (
  date: string,
  blankTime = false,
  defaultHostId?: string,
  defaultFacilityId?: number,
) => {
  return createEventSchema(defaultHostId, defaultFacilityId).shape({
    [START_DATE]: Yup.string()
      .required()
      .default(moment(date, DATE_TIME_FORMAT).format(DATE_FORMAT))
      .test("is-not-in-the-past", "Must be today or later.", function (value) {
        return (
          moment(value, DATE_FORMAT).isSameOrAfter(
            moment().subtract(1, "year").startOf("day"),
          ) ||
          this.createError({
            message: "Must not be more than a year back.",
          })
        );
      }),
    [START_TIME]: Yup.string()
      .required()
      .test("must-not-be-in-the-past", function (value, { parent }) {
        const parsedValue = moment(
          `${this.parent[START_DATE]} ${value}`,
          `${DATE_FORMAT} h:mm a`,
        );
        return (
          parsedValue.isSameOrAfter(moment().subtract(1, "year")) ||
          this.createError({
            message: "Must not be more than a year back.",
          })
        );
      })
      .default(
        blankTime ? null : moment(date, DATE_TIME_FORMAT).format("h:mm a"),
      ),
    [END_TIME]: Yup.string()
      .required()
      .default(
        blankTime
          ? null
          : moment(date, DATE_TIME_FORMAT).add(30, "minutes").format("h:mm a"),
      ),
  });
};

export const createRescheduleSchema = () =>
  Yup.object().shape(createDateAndTimeSchemaFields());
