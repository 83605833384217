import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useClubFeatureFlags, useTaskEdit } from "@gymflow/api";
import { humanizeDate, isMobile, UserStatus } from "@gymflow/common";
import { useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import classNames from "classnames";
import concat from "lodash/concat";
import moment from "moment-timezone";
import { useContext } from 'react';
import { Link } from "react-router-dom";
import { Label, UncontrolledTooltip } from "reactstrap";

import defaultAvatar from "../../assets/img/placeholder.jpg";
import { MultipleImageStack } from "../components/MultipleImageStack";
import { CreateEditTaskSidebarProviderContext } from "../components/organisms";
import { LeadProfilePage } from "../views/LeadProfile";
import { UserMemberPage } from "../views/UserMember";
import { usePortalRoutes } from "./usePortalRoutes";

export function useTaskColumnDefs({ setEditingTaskId }) {
  const { openEditTaskSidebar } = useContext(CreateEditTaskSidebarProviderContext);
  const { createLeadLink, createMemberLink } = usePortalRoutes();
  const { api } = useGymflowModels();
  const { clubId, timezone, date_format: dateFormat } = useClubSettings();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const updateTask = useTaskEdit({ api, tz: timezone });

  return [
    {
      Header: <h3>Done</h3>,
      id: "complete",
      accessor: ({ id, complete, deadlineDate, deadlineTime }) => {
        return (
          <div
            className="d-flex align-items-center cursor-pointer"
            style={{ height: 20 }}
            onClick={async () => {
              await updateTask.mutateAsync({
                taskId: id,
                patchedFields: {
                  complete: !complete,
                  deadlineDate,
                  deadlineTime,
                },
              });
            }}
          >
            {complete ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: 12, color: "green" }}
              />
            ) : (
              <input type="radio" checked={false} readOnly />
            )}
          </div>
        );
      },
      width: 70,
      sortable: false,
    },
    {
      id: "name",
      Header: <h3>Task</h3>,
      accessor: (row) => row,
      Cell: ({
        value: { id, name, taskRelatedUsers, taskRelatedLeads, complete },
      }) => {
        const relatedUsers = concat(taskRelatedUsers, taskRelatedLeads);
        return (
          <div
            className={classNames({ "text-muted": complete })}
            style={{
              textDecoration: complete ? "line-through" : "none",
              cursor: "pointer",
            }}
            onClick={() => 
              featureFlags?.featureFlags.PORTAL_REDESIGN_TASK ? openEditTaskSidebar({ taskId: id }) :  setEditingTaskId(id)
            }
          >
            <div>{name}</div>
            <div className="font-size-sm">
              <span className="text-muted">
                Users: {!relatedUsers?.length && "NA"}
              </span>
              {relatedUsers?.map((user, idx) => (
                <span key={user.id}>
                  {idx > 0 ? ", " : ""}
                  {complete ? (
                    <span>
                      {user.firstName} {user.lastName}
                    </span>
                  ) : (
                    <span>
                      <Link
                        to={
                          user.profileType === UserStatus.Lead
                            ? createLeadLink(user.id, LeadProfilePage.Profile)
                            : createMemberLink(
                                user?.userMemberId || user.id,
                                UserMemberPage.Profile,
                              )
                        }
                        id={`related-user-tooltip-${id}-${idx}`}
                      >
                        {user.firstName} {user.lastName}
                      </Link>
                      <UncontrolledTooltip
                        placement="bottom"
                        target={`related-user-tooltip-${id}-${idx}`}
                      >
                        <div
                          className="text-left"
                          style={{ padding: 10, minWidth: 260 }}
                        >
                          <div>
                            <Label className="text-muted mr-1">Email: </Label>
                            <span className="font-weight-bold">
                              {user.email}
                            </span>
                          </div>
                          <div>
                            <Label className="text-muted mr-1">Phone: </Label>
                            <span className="font-weight-bold">
                              {user.phoneNumber}
                            </span>
                          </div>
                          <div>
                            <Label className="text-muted mr-1">Source: </Label>
                            <span className="font-weight-bold">
                              {user?.source?.name}
                            </span>
                          </div>
                        </div>
                      </UncontrolledTooltip>
                    </span>
                  )}
                </span>
              ))}
            </div>
          </div>
        );
      },
      minWidth: 180,
      sortable: false,
    },
    {
      id: "owner",
      Header: <h3>Owner</h3>,
      accessor: (row) => row,
      Cell: ({ value: { id, taskOwners } }) => (
        <MultipleImageStack
          data={taskOwners?.map((e) => ({
            source: e.picture ?? defaultAvatar,
            label: `${e.firstName} ${e.lastName}`,
            id: e.id,
          }))}
        />
      ),
      show: !isMobile(),
      sortable: false,
    },
    {
      id: "deadlineDate,deadlineTime",
      Header: <h3>Deadline</h3>,
      accessor: (row) => row,
      Cell: ({ value: { deadlineDate, deadlineTime, complete } }) => {
        const date = humanizeDate(
          moment(`${deadlineDate} ${deadlineTime}`),
          `${dateFormat} [at] h:mm a`,
        );
        return (
          <div
            className={classNames({ "text-muted": complete })}
            style={{ textDecoration: complete ? "line-through" : "none" }}
          >
            <span
              style={{
                color: getDeadlineColor(deadlineDate, deadlineTime, timezone),
              }}
            >
              {!deadlineDate || !deadlineTime ? '-' : date}
            </span>
          </div>
        );
      },
      minWidth: 160,
    },
  ];
}

export const getDeadlineColor = (deadlineDate, deadlineTime, timezone) => {
  const deadline = moment.tz(`${deadlineDate} ${deadlineTime}`, timezone);
  const isOverDue = moment().tz(timezone).isAfter(deadline);
  const isToday = moment().tz(timezone).isSame(deadline, "day");
  return isOverDue ? "#E2003A" : isToday ? "#F05519" : "#0A0D16";
};
