import { useQueryMembershipListAsPublic } from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { useMemo } from "react";

import { membershipFilters, MembershipTabType } from "./constants";

export const useMembershipAvailableTabs = () => {
  const { api } = useGymflowModels();
  const { data: prepaidMembership } = useQueryMembershipListAsPublic({
    api,
    opts: {
      status: "ACTIVE",
      size: 1,
      page: 0,
      ...membershipFilters.prepaid,
    },
  });
  const { data: recurringMembership } = useQueryMembershipListAsPublic({
    api,
    opts: {
      status: "ACTIVE",
      size: 1,
      page: 0,
      ...membershipFilters.recurring,
    },
  });
  const { data: trialMembership } = useQueryMembershipListAsPublic({
    api,
    opts: {
      status: "ACTIVE",
      size: 1,
      page: 0,
      ...membershipFilters.trial,
    },
  });
  const tabs = useMemo(() => {
    const result = [] as MembershipTabType[];
    if (recurringMembership && recurringMembership?.content.length > 0)
      result.push("recurring");
    if (prepaidMembership && prepaidMembership?.content.length > 0)
      result.push("prepaid");
    if (trialMembership && trialMembership?.content.length > 0)
      result.push("trial");
    return result;
  }, [prepaidMembership, recurringMembership, trialMembership]);
  return tabs;
};
