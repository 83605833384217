import endpoints from "./endpoints";
import { canTrimFields } from "./features/apiResource";

const companyApi = (axiosInstance, apiUrl, clubId) => {
  const state = {
    baseUrl: endpoints.clubs,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, canTrimFields(state), {
    details() {
      return state.http.get(`${state.baseUrl}/${clubId}/company`);
    },
    updateDetails(clubId, payload) {
      return state.http.patch(`${state.baseUrl}/${clubId}/company`, payload);
    },
  });
};

export default companyApi;
