import { Field, FieldProps } from "formik";

import { RadioButtonGroup, RadioButtonOption } from "../base";

type FormRadioButtonGroupProps<T, V extends string | number> = {
  name: keyof T;
  options: RadioButtonOption<V>[];
  label?: string;
};

export const FormRadioButtonGroup = <T, V extends string | number>({
  name,
  ...rest
}: FormRadioButtonGroupProps<T, V>) => (
  <Field name={name}>
    {({ field }: FieldProps) => <RadioButtonGroup {...field} {...rest} />}
  </Field>
);
