import { AutomationType } from "@gymflow/types";
import { QueryKey } from "@tanstack/react-query";

export const automationQueryKeys = {
  all: () => ["automation"] as QueryKey,
  list: () =>
    [...automationQueryKeys.all(), { noPagination: true }] as QueryKey,
  details: (automationId: number) =>
    [...automationQueryKeys.all(), automationId] as QueryKey,
  placeholders: (placeholderType: string) =>
    ["automation-placeholders", placeholderType] as QueryKey,
  placeholderLabels: (placeholderType: AutomationType) =>
    ["automation-placeholder-labels", placeholderType] as QueryKey,
};
