import { useTaskDelete } from "@gymflow/api";
import useGymflowModels from "apps/portal/src/store";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  DotsVerticalIcon,
  EditIcon,
  MenuPopover,
  TrashIcon,
} from "../../atoms";
import { CreateEditTaskSidebarProviderContext } from "../CreateEditTaskSidebarProvider";

type TaskActionsColumnProps = {
  taskId: number;
};

export const TaskActionsColumn = ({ taskId }: TaskActionsColumnProps) => {
  const { api } = useGymflowModels();
  const { t } = useTranslation();
  const { openEditTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { mutateAsync: mutateAsyncRemoveTask } = useTaskDelete({ api });

  return (
    <div className="flex gap-2">
      <MenuPopover
        classNameTrigger="lg:hidden"
        triggerButton={
          <Button className="ml-1.5 h-9 w-9 min-w-[2.25rem] p-0">
            <DotsVerticalIcon
              className="h-5 w-5"
              pathClassName="stroke-gray-950"
            />
          </Button>
        }
        side="top"
        options={[
          {
            id: "edit",
            label: t("page.tasks.taskActionsColumn.edit"),
            icon: (
              <EditIcon className="h-4 w-4" pathClassName="stroke-gray-500" />
            ),
            onClick: () => openEditTaskSidebar({ taskId }),
          },
          {
            id: "delete",
            label: t("page.tasks.taskActionsColumn.delete"),
            onClick: async () => await mutateAsyncRemoveTask(taskId),
            labelClassName: "text-error-600",
            icon: (
              <TrashIcon className="h-4 w-4" pathClassName="stroke-error-600" />
            ),
          },
        ]}
      />
      <div className="hidden gap-2 lg:flex">
        <Button
          onClick={() => openEditTaskSidebar({ taskId })}
          className="h-9 w-9 min-w-[2.25rem] p-0"
        >
          <EditIcon className="h-5 w-5" pathClassName="stroke-gray-950" />
        </Button>
        <Button
          onClick={async () => await mutateAsyncRemoveTask(taskId)}
          className="border-error-300 h-9 w-9 min-w-[2.25rem] p-0"
          intent="error-outline"
        >
          <TrashIcon className="h-5 w-5" pathClassName="stroke-error-700" />
        </Button>
      </div>
    </div>
  );
};
