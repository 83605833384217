import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';

import TwoWayObjectMapper from './TwoWayObjectMapper';

class FormMapper extends TwoWayObjectMapper {
  constructor(transformers) {
    super({
      defaultInKey: kebabCase,
      defaultOutKey: camelCase,
      ...transformers,
    });
  }
}

export default FormMapper;
