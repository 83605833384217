import { useClubAsPublic } from "@gymflow/api";
import { UserForm } from "@gymflow/common";
import PropTypes from "prop-types";
import { CardBody } from "reactstrap";

import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

export function UserDetails({
  fetchLeadSources,
  postCodeFormat,
  telephoneFormat,
  defaultNationality,
  dateFormat,
  onSubmit,
  findByEmail,
  requiredFields,
}) {
  const settings = useClubSettings();
  const { api } = useGymflowModels();
  const { data: club } = useClubAsPublic({ api, clubId: settings.clubId });
  const allowPhoneCountrySelection = settings.allow_phone_country_selection;
  return (
    <CardBody>
      <UserForm
        fetchLeadSources={fetchLeadSources}
        postCodeFormat={postCodeFormat}
        telephoneFormat={telephoneFormat}
        defaultNationality={defaultNationality}
        dateFormat={dateFormat}
        findByEmail={findByEmail}
        onSubmit={onSubmit}
        submitBtnText="Create Account"
        allowPhoneCountrySelection={allowPhoneCountrySelection}
        requiredFields={requiredFields}
        clubWaiverLink={club?.termsConditions}
        phone_number_country={settings.phone_number_country}
      />
    </CardBody>
  );
}

UserDetails.propTypes = {
  fetchLeadSources: PropTypes.func.isRequired,
  postCodeFormat: PropTypes.string.isRequired,
  telephoneFormat: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultNationality: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  findByEmail: PropTypes.func.isRequired,
  requiredFields: PropTypes.shape({
    mobileNumber: PropTypes.bool,
    dateOfBirth: PropTypes.bool,
    addressLine: PropTypes.bool,
    postCode: PropTypes.bool,
    city: PropTypes.bool,
    gender: PropTypes.bool,
    emergencyContact: PropTypes.bool,
  }),
};
