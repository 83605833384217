import { debounce } from "lodash";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

export function useDimensions() {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  // The following measures the size of the div and listens to changes
  const targetRef = useRef();
  const RESET_TIMEOUT = 100;

  const setDimensions = () => {
    setHeight(targetRef.current.offsetHeight);
    setWidth(targetRef.current.offsetWidth);
  };

  const useEnhancedEffect =
    typeof window !== "undefined" ? useLayoutEffect : useEffect;

  useEnhancedEffect(() => {
    setDimensions();
  }, []);

  const debouncedDimensions = debounce(setDimensions, RESET_TIMEOUT);

  useEffect(() => {
    window.addEventListener("resize", debouncedDimensions);
    return () => {
      window.removeEventListener("resize", debouncedDimensions);
    };
  });

  return [{ height, width }, targetRef];
}
