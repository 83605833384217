import get from "lodash/get";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import { useEffect, useMemo, useState } from "react";

function useFilterableListWithGroups({
  groups,
  options,
  groupIdField,
  optionGroupField,
  onChange = noop,
  initialSelection = [],
}) {
  const [selection, setSelection] = useState(initialSelection);
  const groupNameField = "name";
  const optionNameField = "name";

  const groupedOptions = useMemo(() => {
    const getOptionsOrganizedIntoGroups = () => {
      return groups.reduce((acc, group) => {
        const organizedOptions = options
          .filter((option) => {
            const groupField = get(option, optionGroupField);
            const groupId = get(group, groupIdField);
            if (groupField instanceof Array) {
              return groupField.includes(groupId);
            }
            return groupField === groupId;
          })
          .map((option) => ({ label: get(option, optionNameField), value: option }));
        if (organizedOptions.length) {
          acc.push({ label: <b>{get(group, groupNameField)}</b>, value: { ...group, group: true }, group: true });
          acc = acc.concat(organizedOptions);
        }
        return acc;
      }, []);
    };

    const getOptionsWithSelectedGroupItemsAsReadOnly = (options) => {
      const newOptions = options.slice(0);

      newOptions.forEach((option) => {
        if (option.group) {
          return;
        }
        const group = newOptions.find((innerOption) => {
          const groupField = get(option.value, optionGroupField);
          const groupId = get(innerOption.value, groupIdField);
          return groupField instanceof Array ? groupField.includes(groupId) : groupField === groupId;
        });
        const isGroupSelected = !!selection.find((selected) => isEqual(selected, group.value));
        option.readOnly = isGroupSelected;
      });
      return newOptions;
    };
    return getOptionsWithSelectedGroupItemsAsReadOnly(getOptionsOrganizedIntoGroups());
  }, [groupIdField, groups, optionGroupField, options, selection]);

  useEffect(() => {
    onChange(selection);
  }, [selection]);

  const setSelectionWrapper = (newSelection, changedElement, isChangedElementChecked) => {
    const isGroup = groupedOptions
      .filter((option) => option.group)
      .some((option) => isEqual(option.value, changedElement));
    if (isGroup) {
      let result = newSelection.slice(0);
      groupedOptions.forEach((option) => {
        const groupField = get(option.value, optionGroupField);
        const groupId = get(changedElement, groupIdField);

        const isInSelectedGroup = groupField instanceof Array ? groupField.includes(groupId) : groupField === groupId;
        const isAlreadySelected = newSelection.some((selected) => isEqual(selected, option.value));
        if (isInSelectedGroup) {
          if (isChangedElementChecked && !isAlreadySelected) {
            result.push(option.value);
          } else if (!isChangedElementChecked && isAlreadySelected) {
            result = result.filter((selection) => !isEqual(selection, option.value));
          }
        }
      });
      setSelection(result);
    } else {
      setSelection(newSelection);
    }
  };

  return { groupedOptions, selection, setSelection: setSelectionWrapper };
}

export default useFilterableListWithGroups;
