export interface SmallEventCardProps {
  eventName: string;
  startTime: string;
  duration: string;
  bookedCount?: number;
  capacity?: number;
  hostName: string;
  status?: "FREE" | "WAITLIST" | "FULL";
  onClick?: () => void;
}

const colors = {
  FREE: "bg-success-500",
  WAITLIST: "bg-warning-500",
  FULL: "bg-error-500",
};

function SmallEventCard({
  eventName,
  startTime,
  duration,
  bookedCount,
  capacity,
  hostName,
  status,
  onClick,
}: SmallEventCardProps) {
  const initials = hostName
    .split(/ +/)
    .map((e) => e[0])
    .join("")
    .toUpperCase();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`border-t border-gray-200 py-4 last:border-b ${
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      <div className="flex w-full items-center justify-between">
        <div className="bg-primary-300 mr-3 flex h-12 w-12 min-w-[3rem] items-center justify-center rounded-xl text-lg font-medium text-gray-50">
          {initials}
        </div>
        <div className="flex w-full flex-col">
          <div className="text-sm font-medium text-gray-700">{eventName}</div>
          <div className="text-sm font-normal text-gray-600">
            {startTime}, {duration}
          </div>
          <div className="text-sm font-normal text-gray-700">
            {bookedCount !== undefined && capacity !== undefined
              ? `${bookedCount}/${capacity} Booked`
              : ""}
          </div>
        </div>
        <div className="flex h-12 w-2 items-start justify-start">
          <div className={`${colors[status ?? "FULL"]} h-2 w-2 rounded-sm`} />
        </div>
      </div>
    </div>
  );
}

export default SmallEventCard;
