import { cn } from "@gymflow/helpers";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ReactNode, useEffect, useState } from "react";

interface SectionDefinition {
  title: ReactNode;
  body: ReactNode;
  className?: string;
  disabled?: boolean;
}

interface CollapsibleSectionsProps {
  sections: SectionDefinition[];
  openSectionIndex?: number;
  disableChangeSections?: boolean;
  onChangeSection?: (section: SectionDefinition, sectionIdx: number) => void;
}

export function CollapsibleSections({
  sections = [],
  disableChangeSections = false,
  onChangeSection = () => {},
  openSectionIndex,
}: CollapsibleSectionsProps) {
  const [openId, setOpenId] = useState<number | null>(0);

  useEffect(() => {
    if (openSectionIndex !== undefined) {
      setOpenId(openSectionIndex);
    }
  }, [openSectionIndex]);

  return (
    <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
      {sections.map((section, idx) => {
        const { title, body } = section;
        const isSectionHidden = openId !== idx;
        return (
          <div key={idx}>
            <div
              className={cn(
                "flex flex-row items-center justify-between border-b border-gray-200 bg-gray-50 px-8 py-4 font-medium text-gray-900 opacity-40",
                {
                  "cursor-pointer opacity-100":
                    !disableChangeSections && !section.disabled,
                },
              )}
              onClick={() => {
                if (disableChangeSections || section.disabled) {
                  return;
                }
                if (isSectionHidden) {
                  setOpenId(idx);
                  onChangeSection(section, idx);
                } else {
                  setOpenId(null);
                }
              }}
            >
              <div>{title}</div>
              <div>
                {isSectionHidden ? (
                  <ChevronDownIcon className="h-5 w-5" />
                ) : (
                  <ChevronUpIcon className="h-5 w-5" />
                )}
              </div>
            </div>
            <Transition
              className={cn(
                "flex flex-col gap-y-4 border-b border-gray-200 p-8",
                section.className,
              )}
              show={!isSectionHidden}
              enter="transition-all ease-in duration-500 overflow-hidden"
              enterFrom="opacity-0 translate-y-6 max-h-0 py-0"
              enterTo="opacity-100 translate-y-0 max-h-[700px]"
              leave="transition-all ease-out duration-300 overflow-hidden"
              leaveFrom="opacity-100 max-h-[700px]"
              leaveTo="opacity-0 py-0 max-h-0"
            >
              {body}
            </Transition>
          </div>
        );
      })}
    </div>
  );
}
