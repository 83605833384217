import { Iso2Country } from "@gymflow/types";
import { validatePostCode } from "@gymflow/validation";
import { z } from "zod";

export const createCompanySchema = (postalCodeCountry: Iso2Country) =>
  z.object({
    name: z.string().trim().min(3, "Must be at least 3 characters").default(""),
    "company-number": z.string().trim().default(""),
    "vat-number": z.string().trim().default(""),
    "address-line-1": z
      .string()
      .trim()
      .min(3, "Must be at least 3 characters")
      .default(""),
    "address-line-2": z.string().trim().default(""),
    "post-code": z
      .string()
      .refine((e) => validatePostCode(e, postalCodeCountry), {
        message: "Invalid Postcode",
      }),
    city: z.string().trim().min(3, "Must be at least 3 characters").default(""),
  });

export type CompanySchema = z.infer<ReturnType<typeof createCompanySchema>>;
