import { cn } from "@gymflow/helpers";
import { ReactNode } from "react";

import { Button } from "../Button";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";

type OptionType = {
  id: string;
  label: string;
  labelClassName?: string;
  icon?: ReactNode;
  onClick: () => void;
};

type AlignType = "start" | "end";

type SideType = "top" | "bottom";

type MenuPopoverProps = {
  classNameTrigger?: string;
  triggerButton: ReactNode;
  options: OptionType[];
  side?: SideType;
  contentClassName?: string;
  align?: AlignType;
};

export const MenuPopover = ({
  classNameTrigger,
  triggerButton,
  options,
  side = "bottom",
  align = "end",
  contentClassName,
}: MenuPopoverProps) => (
  <Popover>
    <PopoverTrigger className={classNameTrigger} asChild>
      {triggerButton}
    </PopoverTrigger>
    <PopoverContent
      side={side}
      className={cn(
        "flex w-40 flex-col gap-0.5 rounded-[0.625rem] border-gray-200 p-2",
        contentClassName,
      )}
      align={align}
    >
      {options.map(({ id, icon, label, onClick, labelClassName }) => (
        <Button
          key={id}
          intent="transparent"
          className="h-8 min-h-0 justify-start gap-2 !rounded-[0.625rem] p-2.5 py-0"
          onClick={() => onClick()}
        >
          {icon}
          <div
            className={cn("text-gray-500 text-sm font-medium", labelClassName)}
          >
            {label}
          </div>
        </Button>
      ))}
    </PopoverContent>
  </Popover>
);
