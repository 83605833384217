import { GrowMetricsApiKey } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import { HttpStatusCode, isAxiosError } from "axios";

import { integrationApiKeyKeys } from "./integrationApiKeyQueryKeys";

export function useQueryGrowMetricsApiKeys({
  api,
}: {
  api: {
    integrationApiKeyApi: {
      getGrowMetricsApiKeys: () => Promise<{ data: GrowMetricsApiKey }>;
    };
  };
}) {
  return useQuery({
    queryKey: integrationApiKeyKeys.growMetrics(),
    queryFn: async () => {
      try {
        return (await api.integrationApiKeyApi.getGrowMetricsApiKeys()).data;
      } catch (e) {
        if (isAxiosError(e)) {
          if (e.response?.status === HttpStatusCode.NotFound) {
            return null;
          }
        }
        throw e;
      }
    },
  });
}
