import axios from "axios";
import { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canActivateAndDeactivate,
  canCreate,
  canFind,
  canFindById,
  canTrimFields,
  canUpdate,
} from "./features/apiResource";

const facilityEndpoints = {
  findActive: `${endpoints.facility}/active`,
  categoryCatalog: "catalog/facility/category",
  updatePicture: `${endpoints.facility}/:id/picture`,
};

const facilityApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["name", "description"],
    baseUrl: `${clubPrefix}${endpoints.facility}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canCreate(state),
    canUpdate(state),
    canActivateAndDeactivate(state),

    {
      async updatePicture(id, { blob, name }) {
        const blobResponse = await axios.get(blob, {
          responseType: "blob",
        });
        const formData = new FormData();
        formData.append("file", blobResponse.data, name);
        const url =
          clubPrefix + facilityEndpoints.updatePicture.replace(":id", id);
        return state.http.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      },
      findActive({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${facilityEndpoints.findActive}`,
          config,
        );
      },

      fetchCategories() {
        return state.http.get(
          `${clubPrefix}${facilityEndpoints.categoryCatalog}`,
        );
      },
    },
  );
};

export default facilityApi;
