import { cn } from "@gymflow/helpers";
import { createRef, ReactNode, useState } from "react";

import uploadFileIcon from "../../../assets/img/upload-icon.svg";

export interface UploadAreaProps {
  className?: string;
  allowedExtensions: string[];
  onChange: ({
    name,
    blob,
    file,
  }: {
    name: string;
    blob: string | ArrayBuffer | null;
    file: File;
  }) => void;
  accept?: string;
  description?: ReactNode;
  loadHandler: UploadAreaLoadHandler;
  disabled?: boolean;
  maxWidth?: number;
  maxHeight?: number;
}

export function UploadArea({
  className,
  onChange,
  allowedExtensions,
  accept,
  description,
  loadHandler,
  disabled,
  maxWidth,
  maxHeight,
}: UploadAreaProps) {
  const [error, setError] = useState<string | null>(null);

  const fileRef = createRef<any>();

  const readFile = (file: File) => {
    const validExtension = allowedExtensions.some((extension) =>
      file.name.endsWith(extension),
    );
    if (!validExtension) {
      setError(
        `File format is not supported. Try one of the following extensions: (${allowedExtensions.join(
          ", ",
        )}).`,
      );
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      loadHandler({ setError, onChange, reader, file, maxWidth, maxHeight });
    });
    reader.readAsDataURL(file);
  };

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      readFile(e.target.files[0]);
    }
  };

  return (
    <div
      onClick={() => {
        fileRef.current.click();
      }}
      className={cn(
        "flex cursor-pointer flex-col rounded-lg border border-gray-200 p-3 text-center align-middle text-gray-600",
        { "cursor-default opacity-50": disabled },
        className,
      )}
    >
      <div className="flex h-11 w-11 self-center rounded-full bg-gray-100 p-3">
        <img src={uploadFileIcon} alt="Upload File Icon" />
      </div>
      <div
        className={cn("text-primary-600 text-sm font-semibold", {
          invisible: disabled,
        })}
      >
        Click to upload.
      </div>
      <div className="text-xs">{description}</div>
      <div>{error}</div>
      <input
        disabled={disabled}
        type="file"
        className="d-none"
        onChange={onSelectFile}
        accept={accept}
        ref={fileRef}
      />
    </div>
  );
}

export type UploadAreaLoadHandler = (
  parameters: UploadAreaLoadHandlerParameters,
) => void;

export type UploadAreaLoadHandlerParameters = {
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  onChange: ({
    name,
    blob,
  }: {
    name: string;
    blob: string | ArrayBuffer | null;
    file: File;
  }) => void;
  reader: FileReader;
  file: File;
  maxWidth?: number;
  maxHeight?: number;
};

export function uploadAreaLoadHandlerForImages({
  setError,
  onChange,
  reader,
  file,
  maxWidth = 500,
  maxHeight = 500,
}: UploadAreaLoadHandlerParameters) {
  const img = new Image();
  img.addEventListener("load", () => {
    if (img.width > maxWidth || img.height > maxHeight) {
      setError(
        `Image should not be bigger than ${maxWidth} by ${maxHeight} pixels.`,
      );
      return;
    } else if (img.width < 100 || img.height < 100) {
      setError("Image cannot have less than 100 pixels of width or height.");
      return;
    }
    setError(null);
    onChange({ name: file.name, blob: reader.result, file });
  });
  img.addEventListener("error", (e) => {
    setError(
      "Image could not be loaded. Make sure you selected a valid picture.",
    );
  });
  img.src = reader.result as string;
}

UploadArea.defaultProps = {
  allowedExtensions: ["jpg", "jpeg", "png", "webp"],
  accept: "image/*",
  description: "PNG or JPG (max. 500x500)",
  loadHandler: uploadAreaLoadHandlerForImages,
};
