import { DateTime } from "luxon";

const DATE_TIME_FORMAT = "dd MMM, yyyy - h:mm a";

export const formatDate = (date: string, timezone: string) => {
  const dt = DateTime.fromISO(date, { zone: "utc" }).setZone(timezone);

  if (!dt.isValid) {
    return "-";
  }

  return dt.toFormat(DATE_TIME_FORMAT);
};
