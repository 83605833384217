import { zonedTimeToUtc } from "@gymflow/helpers";
import {
  EmailSendingResult,
  MembershipStatus,
  MembershipType,
} from "@gymflow/types";
import { useMutation } from "@tanstack/react-query";

export function useMutationSendEmailToMemberFilter({
  api,
  tz,
}: {
  api: {
    emailApi: {
      sendEmailToMemberFilter: (args: {
        emailPayload: {
          marketing: boolean;
          body: string;
          subject: string;
          fromEmail: string;
          bccList: string[];
        };
        userMemberIdsToExclude?: string[];
        createdFrom?: string;
        createdTo?: string;
        smsCommunication?: boolean;
        emailCommunication?: boolean;
        membershipIdList?: number[];
        membershipStatusList?: MembershipStatus[];
        membershipTypeList?: MembershipType[];
        membershipStartFrom?: string;
        membershipStartTo?: string;
        membershipCancellationFrom?: string;
        membershipCancellationTo?: string;
        membershipExpireFrom?: string;
        membershipExpireTo?: string;
        assignedStaffIdList?: string[];
        creditsRemainingFrom?: string;
        creditsRemainingTo?: string;
      }) => Promise<{ data: EmailSendingResult }>;
    };
  };
  tz: string;
}) {
  return useMutation({
    mutationFn: async (payload: {
      emailPayload: {
        marketing: boolean;
        body: string;
        subject: string;
        fromEmail: string;
        bccList: string[];
      };
      userMemberIdsToExclude?: string[];
      createdFrom?: string;
      createdTo?: string;
      smsCommunication?: boolean;
      emailCommunication?: boolean;
      membershipIdList?: number[];
      membershipStatusList?: MembershipStatus[];
      membershipTypeList?: MembershipType[];
      membershipStartFrom?: string;
      membershipStartTo?: string;
      membershipCancellationFrom?: string;
      membershipCancellationTo?: string;
      membershipExpireFrom?: string;
      membershipExpireTo?: string;
      assignedStaffIdList?: string[];
      creditsRemainingFrom?: string;
      creditsRemainingTo?: string;
    }) => {
      const parsedOpts = { ...payload };
      if (payload?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(payload.createdFrom, tz);
      }
      if (payload?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(payload.createdTo, tz);
      }
      if (payload?.membershipStartFrom) {
        parsedOpts.membershipStartFrom = zonedTimeToUtc(
          payload.membershipStartFrom,
          tz,
        );
      }
      if (payload?.membershipStartTo) {
        parsedOpts.membershipStartTo = zonedTimeToUtc(
          payload.membershipStartTo,
          tz,
        );
      }
      if (payload?.membershipCancellationFrom) {
        parsedOpts.membershipCancellationFrom = zonedTimeToUtc(
          payload.membershipCancellationFrom,
          tz,
        );
      }
      if (payload?.membershipCancellationTo) {
        parsedOpts.membershipCancellationTo = zonedTimeToUtc(
          payload.membershipCancellationTo,
          tz,
        );
      }
      if (payload?.membershipExpireFrom) {
        parsedOpts.membershipExpireFrom = zonedTimeToUtc(
          payload.membershipExpireFrom,
          tz,
        );
      }
      if (payload?.membershipExpireTo) {
        parsedOpts.membershipExpireTo = zonedTimeToUtc(
          payload.membershipExpireTo,
          tz,
        );
      }
      const response = await api.emailApi.sendEmailToMemberFilter(parsedOpts);
      return response.data;
    },
  });
}
