import { NoteDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { noteQueryKeys } from "./noteQueryKeys";

export function useNoteCreateForLead({
  api,
}: {
  api: {
    noteApi: {
      createLeadNote: (
        fields: { content: string; leadId?: number },
        file?: File,
      ) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      fields,
      file,
    }: {
      fields: { content: string; leadId?: number };
      file?: File;
    }) => {
      const response = await api.noteApi.createLeadNote(fields, file);
      return response.data as NoteDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: noteQueryKeys.all() });
    },
  });

  return mutation;
}
