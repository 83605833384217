import endpoints from "./endpoints";
import { canCreate, canTrimFields } from "./features/apiResource";

const customerAppointmentApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: [],
    baseUrl: `${clubPrefix}${endpoints.customerAppointment}`,
    http: axiosInstance,
    apiUrl,
  };

  return Object.assign(state, canTrimFields(state), canCreate(state), {
    summary(params) {
      const url = `${state.baseUrl}/summary`;
      return state.http.post(url, params);
    },
  });
};

export default customerAppointmentApi;
