import { useAppointmentSummary } from "@gymflow/api";
import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { formatCurrency, pluralize } from "@gymflow/helpers";
import {
  AppointableDtoPricingModel,
  AppointmentPaymentStatus,
  UserMemberPublicDTO,
  UserMemberSubscriptionBean,
} from "@gymflow/types";
import classNames from "classnames";
import moment from "moment-timezone";
import { useEffect } from "react";

import { useClubSettings } from "../../../providers";
import useGymflowModels from "../../../store";
import { Badge, Button, InputWithSaveButton } from "../../atoms";
import { PaymentMethodPicker } from "../PaymentMethodPicker";
import { PaymentStatusBadge } from "./PaymentStatusBadge";

export function AppointmentPayment({
  appointmentId,
  appointableId,
  paymentStatus,
  appointablePrice,
  appointableSessionCost,
  member,
  setIsTabOpen,
  setPaymentMethodId,
  paymentMethodId,
  setPaymentUnit,
  paymentUnit,
  setPromoCode,
  promoCode,
  appointableName,
  pricingModel,
}: {
  paymentStatus: AppointmentPaymentStatus;
  appointablePrice?: number;
  appointableSessionCost?: number;
  paymentUpfront: boolean;
  appointmentId: number;
  appointableId: number;
  member: UserMemberPublicDTO;
  cardRequired: boolean;
  setIsTabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPaymentMethodId: React.Dispatch<React.SetStateAction<string | undefined>>;
  paymentMethodId?: string;
  setPaymentUnit: React.Dispatch<
    React.SetStateAction<"CARD" | "CREDITS" | undefined>
  >;
  paymentUnit?: "CARD" | "CREDITS";
  setPromoCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  promoCode?: string;
  appointableName: string;
  pricingModel: AppointableDtoPricingModel;
}) {
  const { api } = useGymflowModels();
  const {
    data: summary,
    isFetching: isLoadingSummary,
    isSuccess,
  } = useAppointmentSummary(
    {
      api,
      opts: {
        appointableId,
        userMemberId: member.id,
        appointmentPaymentPostDTO: {
          appointmentId,
          paidWithSessions: paymentUnit === "CREDITS",
          promotionCode: !!promoCode ? promoCode : undefined,
          paymentMethodId: paymentUnit === "CARD" ? paymentMethodId : undefined,
        },
      },
    },
    { enabled: !!paymentUnit },
  );

  const settings = useClubSettings();

  useEffect(() => {
    const getDefaultState = (): "CARD" | "CREDITS" | undefined => {
      if (!isSuccess || paymentUnit !== undefined) {
        return;
      }
      if (pricingModel === "FREE") {
        return "CARD";
      }
      if (appointableSessionCost === undefined) {
        return "CARD";
      }

      if (appointablePrice === undefined) {
        return "CREDITS";
      }

      if (
        summary?.paidWithSessions &&
        summary.sessionPackUsage.result !== "NOT_ENOUGH_SESSIONS"
      ) {
        return "CREDITS";
      }
      return "CARD";
    };
    let result = getDefaultState();
    if (result) {
      setPaymentUnit(result);
    }
  }, [
    appointablePrice,
    appointableSessionCost,
    isSuccess,
    paymentUnit,
    pricingModel,
    setPaymentUnit,
    summary,
  ]);

  const renderUnpaid = () => {
    if (paymentStatus !== "UNPAID") {
      return null;
    }
    return (
      <>
        <div className="mx-8 mt-4 flex">
          <Button
            onClick={() => setPaymentUnit("CARD")}
            className={classNames("w-full focus:outline-none", {
              "!bg-gray-100": paymentUnit === "CARD",
              "!rounded-r-none": appointableSessionCost !== undefined,
              hidden: appointablePrice === undefined,
            })}
          >
            Card
          </Button>
          <Button
            onClick={() => setPaymentUnit("CREDITS")}
            className={classNames("w-full focus:outline-none", {
              "bg-gray-100": paymentUnit === "CREDITS",
              "!rounded-l-none": appointablePrice !== undefined,
              hidden: appointableSessionCost === undefined,
            })}
          >
            Credits
          </Button>
        </div>
        {paymentUnit === "CARD" && (
          <div className="px-8">
            <PaymentMethodPicker
              userMember={member}
              currentPaymentMethodId={paymentMethodId}
              onChange={setPaymentMethodId}
            />
          </div>
        )}
        {paymentUnit === "CREDITS" && (
          <CreditPackDetails
            sessionPackCalculations={
              summary?.paidWithSessions === true
                ? summary.sessionPackUsage.sessionPackUsageCalculations
                : undefined
            }
          />
        )}
        <div className="flex flex-col gap-2 border-t border-gray-200 py-4">
          <div className="mx-8 text-sm font-semibold text-gray-700">
            Checkout
          </div>
          {paymentUnit === "CARD" && (
            <>
              <div className="mx-8">Promotional Code</div>
              <div className="mx-8">
                <InputWithSaveButton
                  onChange={(newPromoCode) => {
                    setPromoCode(newPromoCode);
                  }}
                  value={
                    promoCode &&
                    summary?.paidWithSessions === false &&
                    summary.cardUsage.validPromotionCode === "YES"
                      ? promoCode
                      : ""
                  }
                  isLoading={isLoadingSummary}
                />
              </div>
              <div
                className={classNames("text-error-600 mx-8", {
                  hidden: !(
                    promoCode &&
                    summary?.paidWithSessions === false &&
                    summary.cardUsage.validPromotionCode !== "YES"
                  ),
                })}
              >
                Promotion code is not valid.
              </div>
            </>
          )}
          <div className="mx-8 flex justify-between">
            <div>{appointableName}</div>
            <div>
              {paymentUnit === "CARD" &&
                summary?.paidWithSessions === false &&
                formatCurrency(
                  summary.cardUsage.upfrontPriceDetails
                    .totalAmountBeforeDiscount,
                  settings.defaultCurrency,
                )}
              {paymentUnit === "CREDITS" &&
                appointableSessionCost !== undefined &&
                `${appointableSessionCost} ${pluralize(
                  "Credit",
                  "Credits",
                  appointableSessionCost,
                )}`}
            </div>
          </div>
          <div
            className={classNames("mx-8 flex justify-between", {
              hidden:
                isLoadingSummary ||
                paymentUnit !== "CARD" ||
                !promoCode ||
                (summary?.paidWithSessions === false &&
                  summary.cardUsage.validPromotionCode !== "YES"),
            })}
          >
            <div>Discount</div>
            <div>
              {summary?.paidWithSessions === false &&
                formatCurrency(
                  summary
                    ? -(summary?.cardUsage.upfrontPriceDetails
                        .totalAmountDifference as number)
                    : 0,
                  settings.defaultCurrency,
                )}
            </div>
          </div>
          <div className="mx-8 flex justify-between text-base font-bold text-gray-700">
            <div className="text-base font-bold text-gray-700">Amount Due</div>
            <div>
              {paymentUnit === "CARD" &&
                summary?.paidWithSessions === false &&
                formatCurrency(
                  summary.cardUsage.upfrontPriceDetails.totalAmountToPay,
                  settings.defaultCurrency!,
                )}
              {paymentUnit === "CREDITS" &&
                appointableSessionCost !== undefined &&
                `${appointableSessionCost} ${pluralize(
                  "Credit",
                  "Credits",
                  appointableSessionCost,
                )}`}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="mx-8 flex justify-between">
        <div className="font-medium text-gray-900">Payment Status</div>
        <div>
          <PaymentStatusBadge paymentStatus={paymentStatus} />
        </div>
      </div>
      {renderUnpaid()}
    </div>
  );
}

function CreditPackDetails({
  sessionPackCalculations,
}: {
  sessionPackCalculations?: {
    readonly sessionPack: UserMemberSubscriptionBean;
    readonly numberOfSessionsUsed: number;
  }[];
}) {
  const settings = useClubSettings();

  return (
    <div className="mx-8">
      <div className="text-sm font-semibold text-gray-700">Credits</div>
      <div className="text-sm font-normal text-gray-600">
        Charge for this appointment with credits available on the user accounts.
        Credits will be deducted from the pack expiring soonest.
      </div>
      <div className="mt-4 flex max-h-48 flex-col gap-2 overflow-y-auto">
        {sessionPackCalculations ? (
          sessionPackCalculations?.map(
            ({
              sessionPack: { id, name, sessionsLeft, endDate, sessionPackBean },
            }) => {
              return (
                <div
                  key={id}
                  className="cursor-pointer rounded border border-gray-200 p-4"
                >
                  <div className="flex justify-between">
                    <div className="text-sm font-bold text-gray-700">
                      {name}
                    </div>
                    <div>
                      <Badge intent="primary">
                        {sessionPackBean?.sessionsUnlimited
                          ? "∞"
                          : `${sessionsLeft} ${pluralize(
                              "Credit",
                              "Credits",
                              sessionsLeft!,
                            )}`}
                      </Badge>
                    </div>
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    Expiry{" "}
                    {moment(endDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ).format(
                      settings.date_format,
                    )}
                  </div>
                </div>
              );
            },
          )
        ) : (
          <div>Member doesn&apos;t have credit packs.</div>
        )}
      </div>
    </div>
  );
}
