import { AutomationPlaceholderLabels, AutomationType } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { automationQueryKeys } from "./automationQueryKeys";

export async function automationPlaceholderLabelsQueryFn({
  api,
  placeholderType,
}: {
  api: AutomationPlaceholderLabelsApi;
  placeholderType: AutomationType;
}) {
  const result = await api.automationApi.placeholderLabels(placeholderType);
  return result.data as AutomationPlaceholderLabels;
}

export function useAutomationPlaceholderLabels({
  api,
  placeholderType,
}: {
  api: AutomationPlaceholderLabelsApi;
  placeholderType: AutomationType;
}) {
  const result = useQuery({
    queryKey: automationQueryKeys.placeholderLabels(placeholderType),
    queryFn: () => automationPlaceholderLabelsQueryFn({ api, placeholderType }),
    initialData: {} as AutomationPlaceholderLabels,
  });
  return result;
}

type AutomationPlaceholderLabelsApi = {
  automationApi: {
    placeholderLabels: (
      placeholderType: AutomationType,
    ) => Promise<{ data: AutomationPlaceholderLabels }>;
  };
};
