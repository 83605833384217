class TwoWayObjectMapper {
  defaultInKeyTransformer = TwoWayObjectMapper.defaultKeyTransformer;

  inKeyTransformers = {};

  inValueTransformers = [];

  defaultOutKeyTransformer = TwoWayObjectMapper.defaultKeyTransformer;

  outKeyTransformers = {};

  outValueTransformers = [];

  constructor(
    transformers = {
      defaultInKey: TwoWayObjectMapper.defaultKeyTransformer,
      inKey: {},
      inValue: [],
      defaultOutKey: TwoWayObjectMapper.defaultKeyTransformer,
      outKey: {},
      outValue: [],
    },
  ) {
    this.defaultInKeyTransformer =
      transformers.defaultInKey || TwoWayObjectMapper.defaultKeyTransformer;
    this.inKeyTransformers = transformers.inKey || {};
    this.inValueTransformers = transformers.inValue || [];

    this.defaultOutKeyTransformer =
      transformers.defaultOutKey || TwoWayObjectMapper.defaultKeyTransformer;
    this.outKeyTransformers = transformers.outKey || {};
    this.outValueTransformers = transformers.outValue || [];
  }

  to(values) {
    return TwoWayObjectMapper.runTransformers(
      values,
      this.inKeyTransformers,
      this.defaultInKeyTransformer,
      this.inValueTransformers,
    );
  }

  from(formValues) {
    return TwoWayObjectMapper.runTransformers(
      formValues,
      this.outKeyTransformers,
      this.defaultOutKeyTransformer,
      this.outValueTransformers,
    );
  }

  toKey(key) {
    return TwoWayObjectMapper.translateKey(
      key,
      this.inKeyTransformers,
      this.defaultInKeyTransformer,
    );
  }

  fromKey(key) {
    return TwoWayObjectMapper.translateKey(
      key,
      this.outKeyTransformers,
      this.defaultOutKeyTransformer,
    );
  }

  /**
   * @private
   */
  static getValueTransformersForKey(key, transformers) {
    return transformers
      .filter((transformer) => transformer.key === key)
      .map((transformer) => transformer.transform);
  }

  /**
   * @private
   */
  static translateKey(key, dictionary, defaultTransformer) {
    const translated = dictionary[key];
    if (typeof translated === "string") {
      return translated;
    }
    if (typeof translated === "function") {
      return translated(key);
    }
    return defaultTransformer(key);
  }

  /**
   * @private
   */
  static runTransformers(
    object,
    keyTransformers,
    defaultKeyTransformer,
    valueTransformers,
  ) {
    return Object.keys(object).reduce((acc, key) => {
      const translatedKey = TwoWayObjectMapper.translateKey(
        key,
        keyTransformers,
        defaultKeyTransformer,
      );
      const toProcess = TwoWayObjectMapper.getValueTransformersForKey(
        key,
        valueTransformers,
      );
      if (toProcess.length === 0) {
        acc[translatedKey] = object[key];
      } else {
        acc[translatedKey] = toProcess.reduce(
          (toProcessAcc, transform) => transform(toProcessAcc, object),
          object[key],
        );
      }
      return acc;
    }, {});
  }

  /**
   * @protected
   */
  static defaultKeyTransformer(key) {
    return key;
  }
}

export default TwoWayObjectMapper;
