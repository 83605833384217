import { AppointableDTO, AppointablePatchDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { appointableQueryKeys } from "./appointableQueryKeys";

export function useAppointableEdit({
  api,
}: {
  api: {
    appointableApi: {
      update: (
        appointableId: number,
        fields: AppointablePatchDTO,
      ) => Promise<{ data: AppointableDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      patchedFields,
      appointableId,
    }: {
      patchedFields: AppointablePatchDTO;
      appointableId: number;
    }) => {
      const response = await api.appointableApi.update(
        appointableId,
        patchedFields,
      );
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointableQueryKeys.all() });
    },
  });

  return mutation;
}
