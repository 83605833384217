const useInjectCss = () => {
  return addStyle;
};

const addStyle = ({ logo }: { logo: string }) => {
  const styleString = `
    .gymflow-logo-background {
      background-image: url(${logo}) !important;
    }
  `;

  return injectCss(styleString);
};

const injectCss = (styleString: string) => {
  const styleElement = document.createElement("style");
  styleElement.textContent = styleString;
  document.head.append(styleElement);

  return function () {
    styleElement.remove();
  };
};

export default useInjectCss;
