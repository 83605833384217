import { useMutation, useQueryClient } from "@tanstack/react-query";

import { reportQueryKeys } from "../report";
import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationLeadBulkStatusUpdate({
  api,
}: {
  api: {
    leadApi: {
      bulkStatusUpdate: (
        newLeadStatusId: number,
        leadIds: number[],
      ) => Promise<{ data: any }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: {
      newLeadStatusId: number;
      leadIds: number[];
    }) => {
      const response = await api.leadApi.bulkStatusUpdate(
        payload.newLeadStatusId,
        payload.leadIds,
      );
      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.lead() });
    },
  });
}
