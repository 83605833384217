import {
  clubStaleTime,
  useClubHostedPagesSettingsAsPublic,
} from "@gymflow/api";
import { useEffect } from "react";

import { useClubSettings } from "../providers";
import useGymflowModels from "../store";

export function useHtmlNodeDarkMode({
  supportDarkMode,
}: {
  supportDarkMode: boolean;
}) {
  const { clubId } = useClubSettings();
  const { api } = useGymflowModels();
  const { data: hostedPagesSettings } = useClubHostedPagesSettingsAsPublic(
    { api, clubId },
    { staleTime: clubStaleTime },
  );
  useEffect(() => {
    const htmlNode = document.querySelector("html");
    if (!htmlNode) {
      return;
    }
    if (supportDarkMode) {
      if (
        hostedPagesSettings?.settings?.darkMode &&
        !htmlNode.classList.contains("dark")
      ) {
        htmlNode.classList.add("dark");
      } else if (
        !hostedPagesSettings?.settings?.darkMode &&
        htmlNode.classList.contains("dark")
      ) {
        htmlNode.classList.remove("dark");
      }
    } else {
      if (htmlNode.classList.contains("dark")) {
        htmlNode.classList.remove("dark");
      }
    }
  }, [hostedPagesSettings?.settings?.darkMode]);
}
