import {
  AxiosAuthenticationLoader,
  BootstrapServerRepository,
  LocalTokenStorageInstance,
  RouteResult,
  useBootstrapServers,
} from "@gymflow/api";
import { axiosRequestInterceptor } from "@gymflow/common";
import axios from "axios";
import classNames from "classnames";
import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { AsyncPaginate } from "react-select-async-paginate";
import { Form, Label } from "reactstrap";

import { PrimaryButton, TextInput } from "../components/atoms";
import environment from "../environment";
import { useRememberMe } from "../hooks/useRememberMe";

export function Welcome() {
  const history = useHistory();
  const {
    routeId: routeIdParam,
    redirect,
    redirectQuery,
  } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const serverUrl = environment.get("API_RESOLVER_URL");
  const keycloakClientId = environment.get("KEYCLOAK_CLIENT_ID");
  const repository = useMemo(
    () => new BootstrapServerRepository(serverUrl),
    [serverUrl],
  );
  const [selectedClub, setSelectedClub] = useState<RouteResult>();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const bootstrapResponse = useBootstrapServers({
    serverUrl: environment.get("API_RESOLVER_URL"),
    routeId: selectedClub?.routeId,
  });

  const auth = useMemo(() => {
    if (!bootstrapResponse.data) {
      return null;
    }

    const axiosInstance = axios.create({
      baseURL: bootstrapResponse.data.urls.api,
      paramsSerializer: {
        indexes: null,
      },
    });
    axiosInstance.interceptors.request.use(axiosRequestInterceptor);
    return new AxiosAuthenticationLoader(
      axiosInstance,
      {
        apiUrl: bootstrapResponse.data.urls.api,
        keycloakRealm: bootstrapResponse.data.authRealm,
        keycloakUrl: bootstrapResponse.data.urls.auth,
      },
      LocalTokenStorageInstance,
      keycloakClientId,
    );
  }, [bootstrapResponse.data, keycloakClientId]);

  useEffect(() => {
    const fetchClubFromUrl = async () => {
      if (!routeIdParam) {
        return;
      }
      const { results } = await repository.findRoutes({
        routeId: routeIdParam as string,
        page: 0,
        limit: 1,
      });
      if (results) {
        const clubRoute = results.find(
          ({ routeId }) => routeId === routeIdParam,
        );
        if (clubRoute) {
          setSelectedClub(clubRoute);
        }
      }
    };
    fetchClubFromUrl();
  }, [routeIdParam]);

  const { routeId: rememberedRouteId, rememberRoute } = useRememberMe();

  useEffect(() => {
    if (rememberedRouteId) {
      history.push({ pathname: rememberedRouteId });
    }
  }, [rememberedRouteId]);

  const onSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (!auth) {
        return;
      }
      try {
        await auth.login(username, password);
      } catch (e: any) {
        if (e?.response?.status === 401) {
          setError("User and/or password not correct.");
        } else {
          setError("There was a problem logging in.");
        }
      }

      if (auth.isAuthenticated() && selectedClub) {
        rememberRoute(selectedClub.routeId);
        const pushState = { pathname: selectedClub.routeId, search: "" };
        if (redirect) {
          pushState.pathname += `/${redirect}`;
        }
        try {
          if (redirectQuery) {
            pushState.search = `${window.atob(redirectQuery as string)}`;
          }
        } catch {}
        history.push(pushState);
      }
    },
    [auth, password, redirect, redirectQuery, selectedClub?.routeId, username],
  );

  const [forgotPasswordLink, setForgotPasswordLink] = useState<string>();
  useEffect(() => {
    if (!bootstrapResponse.data) {
      return;
    }
    setForgotPasswordLink(
      `${bootstrapResponse.data.urls.auth}/realms/${bootstrapResponse.data.authRealm}/login-actions/reset-credentials?client_id=${keycloakClientId}`,
    );
  }, [bootstrapResponse]);

  return (
    <div className="container-fluid h-[95vh] overflow-y-auto">
      <div className="row justify-content-center pt-md-5 mt-md-5">
        <div className="col-xs-12 col-sm-6 max-w-md">
          <div>
            <div className="bg-gymflow-logo m-8 h-12 bg-contain bg-center bg-no-repeat" />
            <div className="mb-4 text-center">
              <h1 className="mb-4 text-2xl">Log into your account</h1>
              <p className="text-gray-900">
                Welcome back! Please enter your details.
              </p>
            </div>
            <Form onSubmit={onSubmit}>
              <div className="form-group">
                <Label>Select your club</Label>

                <AsyncPaginate
                  placeholder="Search for your club"
                  additional={{
                    page: 0,
                  }}
                  loadOptions={
                    (async (
                      searchTerm: string,
                      __: unknown,
                      { page }: { page: number },
                    ) => {
                      const data = await repository.findRoutes({
                        searchTerm,
                        page,
                        limit: 10,
                      });

                      return {
                        options: (data?.results || [])
                          .slice()
                          .sort(
                            (a, b) =>
                              Number(b.status === "ENABLED") -
                              Number(a.status === "ENABLED"),
                          )
                          .map((route) => ({
                            label: route.displayName,
                            value: route,
                            isDisabled: route.status === "DISABLED",
                          })),
                        hasMore: data.hasMore,
                        additional: {
                          page: page + 1,
                        },
                      };
                    }) as any
                  }
                  isSearchable
                  value={selectedClub && { label: selectedClub.displayName }}
                  onChange={({ value }: any) => {
                    setSelectedClub(value);
                  }}
                  styles={{
                    control: (provided: any) => ({
                      ...provided,
                      background: "#FFFFFF !important",
                      border: "1px solid #D0D5DD !important",
                      boxShadow:
                        "0px 1px 2px rgba(16, 24, 40, 0.05) !important",
                      borderRadius: "8px !important",
                      minHeight: "44px",
                    }),
                    placeholder: (provided: any) => ({
                      ...provided,
                      color: "#667085",
                    }),
                    menu: (provided: any) => ({
                      ...provided,
                      borderRadius: "8px",
                    }),
                    option: (provided: any, state: any) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#f5f5f5" : null,
                    }),
                    indicatorSeparator: (provided: any) => ({
                      ...provided,
                      display: "none",
                    }),
                    indicatorsContainer: (provided: any) => ({
                      ...provided,
                      display: "none",
                    }),
                    valueContainer: (provided: any) => ({
                      ...provided,
                      padding: "0 16px",
                      fontSize: "1rem",
                    }),
                  }}
                />
              </div>

              <div className="form-group">
                <Label>Email</Label>
                <TextInput
                  type="email"
                  className="block h-11 w-full flex-1 rounded-md border-gray-300 px-3.5 py-2.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  onChange={({ currentTarget: { value } }) =>
                    setUsername(value)
                  }
                  autoFocus
                />
              </div>
              <div className="form-group">
                <Label>Password</Label>
                <TextInput
                  type="password"
                  className="block h-11 w-full flex-1 rounded-md border-gray-300 px-3.5 py-2.5 text-gray-900 placeholder:text-gray-400 sm:text-sm sm:leading-6"
                  onChange={({ currentTarget: { value } }) =>
                    setPassword(value)
                  }
                />
              </div>
              <div className="text-error-700">{error}</div>
              <input type="submit" hidden />
            </Form>

            <div className="mt-4 flex">
              <PrimaryButton
                className="flex-1"
                onClick={onSubmit}
                disabled={!auth || !username || !password}
              >
                Sign In
              </PrimaryButton>
            </div>

            <div
              className={classNames("mt-4 text-center text-sm", {
                invisible: !forgotPasswordLink,
              })}
            >
              <a href={forgotPasswordLink} className="font-semibold">
                Forgot password
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
