import { statusColors } from "./statusColors";

export function LeadBadge({ statusName }: { statusName: string }) {
  let badgeStyle: any = {};
  let badgeCircleStyle: any = {};

  if (statusColors[statusName]) {
    badgeStyle["background"] = statusColors[statusName].badgeBackground;
    badgeCircleStyle["borderColor"] = statusColors[statusName].badgeCircle;
  } else {
    badgeStyle["background"] = "#ECE9FE";
    badgeCircleStyle["borderColor"] = "#DDD6FE";
  }
  return (
    <div
      style={badgeStyle}
      className="flex w-max basis-auto items-center gap-2 rounded-3xl px-3 py-1"
    >
      <div style={badgeCircleStyle} className="h-4 w-4 rounded-lg border-4" />
      <div>{statusName}</div>
    </div>
  );
}
