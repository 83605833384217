import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  deadlineDatesToZonedTime,
  useClubFeatureFlags,
  useTaskEdit,
  useTaskList,
} from "@gymflow/api";
import { DATE_ONLY_FORMAT, zonedTime } from "@gymflow/helpers";
import { endOfDay, format, parse, startOfDay } from "date-fns";
import { Fragment, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { useEditOrCreateTask } from "../../hooks/useEditOrCreateTask";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import { PrimaryButton } from "../atoms";
import { CreateEditTaskSidebarProviderContext } from "../organisms";

export const UpcomingTasks: React.FC = () => {
  const { t } = useTranslation();
  const { createMemberLink, createLeadLink } = usePortalRoutes();
  const { api } = useGymflowModels();

  const { clubId, timezone } = useClubSettings();
  const { showAlert, setEditingTaskId } = useEditOrCreateTask();
  const [pageIndex, setPageIndex] = useState(0);
  const { data } = useTaskList(
    {
      api,
      opts: {
        page: pageIndex,
        limit: 4,
        extraParams: {
          complete: false,
          dateFrom: zonedTime(timezone, startOfDay(new Date())),
          dateTo: zonedTime(timezone, endOfDay(new Date())),
        },
        sort: {
          field: "deadlineDate,deadlineTime",
        },
      },
      tz: timezone,
    },
    { refetchOnWindowFocus: true },
  );
  const { openCreateTaskSidebar, openEditTaskSidebar } = useContext(
    CreateEditTaskSidebarProviderContext,
  );
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const updateTask = useTaskEdit({ api, tz: timezone });
  const history = useHistory();

  const onClickEditTask = useCallback(
    (taskId: number) => {
      if (featureFlags?.featureFlags.PORTAL_REDESIGN_TASK) {
        openEditTaskSidebar({ taskId });
        return;
      }

      //@ts-ignore
      setEditingTaskId(taskId);
    },
    [
      featureFlags?.featureFlags.PORTAL_REDESIGN_TASK,
      openEditTaskSidebar,
      setEditingTaskId,
    ],
  );

  return (
    <div className="mt-8 flex w-full flex-col px-4 xl:w-1/2">
      <div className="flex w-full flex-row items-center justify-between">
        <div className="w-full">
          <div className="text-lg font-semibold text-gray-900">
            {t("page.dashboard.upcomingTasks.title")}
          </div>
          <div className="text-sm font-normal text-gray-600">
            {t("page.dashboard.upcomingTasks.subTitle")}
          </div>
        </div>
        <PrimaryButton
          onClick={() =>
            featureFlags?.featureFlags.PORTAL_REDESIGN_TASK
              ? openCreateTaskSidebar()
              : showAlert()
          }
          className="-mt-0.5 h-11 w-11"
        >
          <FontAwesomeIcon className="w-11 text-white" icon={faPlus} />
        </PrimaryButton>
      </div>
      <div className="mt-4 h-full overflow-hidden rounded-xl border border-gray-200 bg-white !shadow">
        <div className="flex h-[calc(4rem-2px)] flex-row items-center justify-between bg-gray-50">
          <div className="w-14 text-base font-medium text-gray-600">&nbsp;</div>
          <div className="text-base font-medium text-gray-600">Task</div>
          <div className="w-36 text-base font-medium text-gray-600">
            Due Date
          </div>
        </div>
        {data?.content.map((item, index) => {
          return (
            <div
              key={item.id}
              className="flex h-14 flex-row items-center justify-between border-t border-gray-200"
            >
              <div className="flex items-center justify-center text-base font-medium text-gray-600">
                <input
                  id={`task-${index}`}
                  type="checkbox"
                  checked={item.complete}
                  onChange={async (e) => {
                    await updateTask.mutateAsync({
                      taskId: item.id,
                      patchedFields: {
                        complete: e.target.checked,
                      },
                    });
                  }}
                  className="mx-4 h-4 w-4 rounded-md border-gray-200 bg-gray-100"
                />
                <div
                  className="mb-0 cursor-pointer align-middle text-sm font-medium text-gray-900"
                  onClick={() => onClickEditTask(item.id)}
                >
                  {item.name}
                  {[...item.taskRelatedLeads, ...item.taskRelatedUsers].length >
                    0 && (
                    <span className="text-sm font-normal text-gray-500">
                      &nbsp;with&nbsp;
                      {[...item.taskRelatedLeads, ...item.taskRelatedUsers].map(
                        (e, i) => (
                          <Fragment key={e.id}>
                            {i !== 0 ? ", " : ""}
                            <span
                              className="hover:underline"
                              onClick={() => {
                                history.push(
                                  e.profileType === "USER"
                                    ? createMemberLink(e.id)
                                    : createLeadLink(+e.id),
                                );
                              }}
                            >
                              {`${e.firstName} ${e.lastName}`}
                            </span>
                          </Fragment>
                        ),
                      )}
                    </span>
                  )}
                </div>
              </div>

              <div className="w-36 min-w-[9rem] text-sm font-normal text-gray-600">
                {item.deadlineDate &&
                  format(
                    parse(
                      deadlineDatesToZonedTime(item, timezone)!.deadlineDate,
                      DATE_ONLY_FORMAT,
                      new Date(Date.now()),
                    ),
                    "PPP",
                  )}
              </div>
            </div>
          );
        })}
        {Array(4 - (data?.content.length ?? 0))
          .fill(0)
          .map((e, i) => {
            return (
              <div
                key={i}
                className={`${
                  i === 0 ? "border-t" : ""
                } flex h-14 w-full items-center justify-between border-gray-200 p-3`}
              />
            );
          })}

        <div className="flex h-[calc(4rem-2px)] w-full items-center justify-between border-t border-gray-200 p-3">
          {data?.numberOfElements === 0 && (
            <div className="flex h-11 w-full items-center justify-center">
              Showing&nbsp;<b>0</b>&nbsp;tasks
            </div>
          )}
          {data?.numberOfElements !== 0 && (
            <>
              <div
                onClick={() => {
                  if (pageIndex > 0) setPageIndex((e) => e - 1);
                }}
                className={`${
                  pageIndex > 0
                    ? "cursor-pointer text-gray-500"
                    : "text-gray-300"
                } flex h-11 items-center rounded-md border border-gray-300 p-2 font-semibold hover:bg-gray-100`}
              >
                Previous
              </div>
              <div className="flex h-11 items-center">
                Showing
                <span className="font-bold">
                  &nbsp;{`${pageIndex * 4 + 1}`}&nbsp;
                </span>
                to
                <span className="font-bold">
                  &nbsp;{`${pageIndex * 4 + (data?.content.length ?? 0)}`}&nbsp;
                </span>
                of
                <span className="font-bold">
                  &nbsp;{`${data?.totalElements}`}&nbsp;
                </span>
                tasks
              </div>
              <div
                onClick={() => {
                  if (pageIndex < (data?.totalPages ?? 0) - 1)
                    setPageIndex((e) => e + 1);
                }}
                className={`${
                  pageIndex < (data?.totalPages ?? 0) - 1
                    ? "cursor-pointer text-gray-500"
                    : "text-gray-300"
                } flex h-11 items-center rounded-md border border-gray-300 p-2 font-semibold hover:bg-gray-100`}
              >
                Next
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
