import {
  AxiosAuthenticationLoader,
  LocalTokenStorageInstance,
  useBootstrapServers,
} from "@gymflow/api";
import {
  authModelBuilder,
  axiosRequestInterceptor,
  gymflowApi,
} from "@gymflow/common";
import * as Sentry from "@sentry/browser";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { StoreProvider } from "easy-peasy";
import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";

import { LoadingPortal } from "../components/pages";
import environment from "../environment";
import { usePortalRoutes } from "../hooks/usePortalRoutes";
import { GymflowModelsContext, useGymflowModelsInitializer } from "../store";

type UserType = "STAFF" | "MEMBER" | "PUBLIC";
type ApiResolverContextType = {
  routeId: string;
  brand: string;
  company: string;
  location: string;
  serverId: string;
  clubId: number;
  authRealm: string;
  urls: {
    api: string;
    auth: string;
  };
  authModelBuilderInstance: any;
  authController?: AxiosAuthenticationLoader;
  reinitializeWithAuth: () => void;
};

const ApiResolverContext = createContext<ApiResolverContextType | null>(null);

export function ApiResolverProvider({
  children,
  userType,
}: {
  children: ReactNode | ((properties: ApiResolverContextType) => ReactNode);
  userType: UserType;
}) {
  const axiosRef = useRef<any>(null);
  const [apiInstance, setApiInstance] = useState<any>(null);
  const { routeId, brand, company, location } = usePortalRoutes();
  const bootstrapResponse = useBootstrapServers({
    serverUrl: environment.get("API_RESOLVER_URL"),
    routeId,
  });

  const { authModelBuilderInstance, api, authController } = useMemo(() => {
    if (!bootstrapResponse.data) {
      setApiInstance(null);
      return { authModelBuilderInstance: null, api: null };
    }

    const axiosInstance = axios.create({
      baseURL: bootstrapResponse.data.urls.api,
      paramsSerializer: {
        indexes: null,
      },
    });
    axiosInstance.interceptors.request.use(axiosRequestInterceptor);
    const authController = new AxiosAuthenticationLoader(
      axiosInstance,
      {
        apiUrl: bootstrapResponse.data.urls.api,
        keycloakRealm: bootstrapResponse.data.authRealm,
        keycloakUrl: bootstrapResponse.data.urls.auth,
      },
      LocalTokenStorageInstance,
      "gymflow-app",
    );
    authController.eventEmitter.on("http-error", (error) => {
      try {
        // ignore backend returning business error messages
        if (error?.responseBody && JSON.parse(error.responseBody).errors) {
          return;
        }
      } catch {}
      try {
        // ignore connection issues
        if (
          !error?.statusCode ||
          error?.code === "ECONNABORTED" ||
          error?.code === "ERR_NETWORK"
        ) {
          return;
        }

        // These are endpoints we know report HTTP error codes
        const whiteListPatterns = ["/user/member/email"];
        for (const pattern of whiteListPatterns) {
          if (error?.url.includes(pattern)) {
            return;
          }
        }
      } catch {}

      Sentry.captureMessage(error, "debug");
    });

    authController.eventEmitter.on("forbidden", (error) => {
      Sentry.captureMessage(error, "debug");
    });

    let authModel = null;

    if (userType !== "PUBLIC") {
      authModel = authModelBuilder({
        authController,
      }) as any;
    }

    const apiInstance = gymflowApi({
      apiUrl: bootstrapResponse.data?.urls.api,
      clubId: bootstrapResponse.data?.clubId,
      publicOnly: userType === "PUBLIC",
      axiosInstance,
    });

    axiosRef.current = axiosInstance;
    setApiInstance(apiInstance);
    return {
      authModelBuilderInstance: authModel,
      api: apiInstance,
      authController,
    };
  }, [JSON.stringify(bootstrapResponse.data), userType]);

  const { data } = useQuery({
    queryKey: ["clubSettings", routeId, userType],
    queryFn: async () => {
      const settings = {
        ...bootstrapResponse.data,

        task_sse_url: "http://localhost:8448/connect/tasks",
        notifications_ws_url: "ws://localhost",
        gymflow_url: "http://localhost",
      };
      return settings;
    },
    enabled: !!bootstrapResponse.data && !!api,

    refetchOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,

    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const { globalStore, localStores } = useGymflowModelsInitializer({
    api,
    authModelBuilderInstance,
    clubId: data?.clubId,
  });

  if (!data?.urls) {
    return <LoadingPortal message="Contacting server..." />;
  }

  const contextProperties = {
    routeId,
    brand,
    company,
    location,
    serverId: data.serverId!,
    clubId: data.clubId!,
    authRealm: data.authRealm!,
    urls: data.urls,
    authController,
    authModelBuilderInstance,
    /*** This function exists to keep compatibility with redux, once that's removed it can be removed*/
    reinitializeWithAuth: () => {
      // This checks for any api being defined on apiInstance so that multiple `reinitializeWithAuth` don't cause an infinite loop
      if (apiInstance.activityApi) return;
      setApiInstance(
        gymflowApi({
          apiUrl: bootstrapResponse.data?.urls.api,
          clubId: bootstrapResponse.data?.clubId,
          publicOnly: false,
          axiosInstance: axiosRef.current,
        }),
      );
    },
  };

  return (
    <ApiResolverContext.Provider value={contextProperties}>
      {/* @ts-ignore */}
      <StoreProvider store={globalStore}>
        <GymflowModelsContext.Provider
          value={{ ...localStores, api: apiInstance } as any}
        >
          {typeof children === "function"
            ? children(contextProperties)
            : children}
        </GymflowModelsContext.Provider>
      </StoreProvider>
    </ApiResolverContext.Provider>
  );
}

export function useApiResolverContext() {
  const data = useContext(ApiResolverContext);
  if (!data) {
    throw new Error("useApiResolverContext called outside of context");
  }
  return data;
}
