import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import { AvailabilityCalculationResult } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useQueryHostAvailabilityCalculateMultipleDays(
  {
    api,
    appointableId,
    dateFrom,
    numberOfDays,
    appointableHostId,
    ignoreAppointments,
    tz,
  }: {
    api: {
      availabilityApi: {
        hostCalculateAvailabilityMultipleDays: (filter: {
          appointableId?: number;
          dateFrom: string;
          numberOfDays: number;
          appointableHostId: string;
          ignoreAppointments?: boolean;
        }) => Promise<{ data: AvailabilityCalculationResult[] }>;
      };
    };
    tz?: string;
    appointableId?: number;
    dateFrom?: string;
    numberOfDays?: number;
    appointableHostId?: string;
    ignoreAppointments?: boolean;
  },
  opts?: UseQueryOptions<AvailabilityCalculationResult[] | undefined | null>,
) {
  const result = useQuery({
    queryKey: availabilityQueryKeys.hostCalculateAvailabilityMultipleDays({
      appointableId,
      dateFrom,
      appointableHostId,
      numberOfDays,
      ignoreAppointments,
    }),
    queryFn: async () => {
      const utcDateFrom = zonedTimeToUtc(dateFrom as string, tz as string);

      const result =
        await api.availabilityApi.hostCalculateAvailabilityMultipleDays({
          appointableId,
          dateFrom: utcDateFrom,
          appointableHostId: appointableHostId as string,
          numberOfDays: numberOfDays as number,
          ignoreAppointments,
        });
      return result.data;
    },
    select: (data) =>
      hostCalculateAvailabilityMultipleDaysSelectFn({ data, tz: tz as string }),
    enabled: !!tz && !!dateFrom && !!numberOfDays && !!appointableHostId,
    initialData: null,
    ...opts,
  });

  return result;
}

export function hostCalculateAvailabilityMultipleDaysSelectFn({
  data,
  tz,
}: {
  data?: AvailabilityCalculationResult[] | null;
  tz: string;
}) {
  if (!data) {
    return data;
  }
  return data.slice(0).map((availabilityLine) => ({
    ...availabilityLine,
    startTime: utcToZonedTime(availabilityLine.startTime, tz),
    endTime: utcToZonedTime(availabilityLine.endTime, tz),
  }));
}
