import { Placeholders } from "@gymflow/common";
import {
  BlockToolbarButton,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_OL,
  ELEMENT_UL,
  getPluginType,
  HeadingToolbar,
  ImageToolbarButton,
  LinkToolbarButton,
  ListToolbarButton,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  MarkToolbarButton,
  Plate,
  PlateProvider,
  useEventPlateId,
  usePlateEditorRef,
  usePlateSelection,
} from "@udecode/plate";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Bold, Italic, List, Underline } from "react-feather";
import Select from "react-select";
import { Transforms } from "slate";
import { ReactEditor } from "slate-react";

import { getAndUpsertLink } from "./getAndUpsertLink";
import useEditorPlugins from "./useEditorPlugins";

const ToolbarButtonsBasicElements = () => {
  const editor = usePlateEditorRef(useEventPlateId("focus"));

  return (
    <>
      <BlockToolbarButton type={getPluginType(editor, ELEMENT_H1)} icon="H1" />
      <BlockToolbarButton type={getPluginType(editor, ELEMENT_H2)} icon="H2" />
      <BlockToolbarButton type={getPluginType(editor, ELEMENT_H3)} icon="H3" />
    </>
  );
};

const ToolbarButtonsBasicMarks = () => {
  const editor = usePlateEditorRef("email-editor");

  return (
    <>
      <MarkToolbarButton
        type={getPluginType(editor, MARK_BOLD)}
        icon={<Bold />}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_ITALIC)}
        icon={<Italic />}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_UNDERLINE)}
        icon={<Underline />}
      />
      <MarkToolbarButton
        type={getPluginType(editor, MARK_STRIKETHROUGH)}
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-strike"
          >
            <path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3" />
            <line x1="4" y1="8" x2="20" y2="8" />
          </svg>
        }
      />
      <ListToolbarButton
        type={getPluginType(editor, ELEMENT_OL)}
        icon={<List />}
      />
      <ListToolbarButton
        type={getPluginType(editor, ELEMENT_UL)}
        icon={<List />}
      />
      <ImageToolbarButton icon="P" />
    </>
  );
};

const editableProps = {
  placeholder: "Type…",
  style: {
    padding: "15px",
  },
};

const InnerEditor = ({ placeholders, ...props }) => {
  const editor = usePlateEditorRef("email-editor");
  if (props?.editorRef) props.editorRef.current = editor;
  const plateSelection = usePlateSelection("email-editor");
  const [selection, setSelection] = useState({ path: [0, 0], offset: 0 });
  useEffect(() => {
    if (!plateSelection) {
      return;
    }
    setSelection(plateSelection.anchor);
  }, [plateSelection]);
  return (
    <HeadingToolbar
      styles={{
        root: [
          {
            padding: "1px 0",
            margin: "0",
          },
        ],
      }}
    >
      <ToolbarButtonsBasicElements />
      <ToolbarButtonsBasicMarks />
      <LinkToolbarButton
        onClick={(event) => {
          if (!editor) return;
          event.preventDefault();
          getAndUpsertLink(editor);
        }}
        icon="L"
      />
      <div style={{ width: "200px" }}>
        <Select
          options={Object.values(placeholders).map((p) => ({
            label: p,
            value: p,
          }))}
          onChange={(selected) => {
            const text = "${" + selected.value + "}";
            Transforms.insertText(editor, text, { at: selection });
            Transforms.select(editor, {
              ...selection,
              offset: selection.offset + text.length,
            });
            ReactEditor.focus(editor);
          }}
          value={null}
          placeholder="Placeholders"
          isSearchable={false}
        />
      </div>
    </HeadingToolbar>
  );
};

const Editor = ({ onChange, value, placeholders, ...props }) => {
  const { plugins } = useEditorPlugins();
  return (
    <Plate
      id="email-editor"
      plugins={plugins}
      editableProps={editableProps}
      onChange={onChange}
      value={value}
    >
      <div className="invert-parent" />
      <InnerEditor editorRef={props?.editorRef} placeholders={placeholders} />
    </Plate>
  );
};

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array.isRequired,
  placeholders: PropTypes.object.isRequired,
};

const EditorProvider = (props) => {
  return (
    <PlateProvider id="email-editor-provider">
      <Editor {...props} />
    </PlateProvider>
  );
};

export default EditorProvider;
