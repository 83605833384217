import { ClubBean } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { clubQueryKeys } from "./clubQueryKeys";

export function useMutationClubTerms({
  api,
}: {
  api: {
    clubApi: {
      updateTerms: (
        clubId: number,
        //*** FormData should include `file` field */
        payload: FormData,
      ) => Promise<{ data: ClubBean }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      clubId,
      payload,
    }: {
      clubId: number;
      payload: FormData;
    }) => {
      const result = await api.clubApi.updateTerms(clubId, payload);
      return result.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: clubQueryKeys.all() });
    },
  });
}
