import {
  faCreditCard,
  faShoppingCart,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clubStaleTime, useClub } from "@gymflow/api";
import {
  AccordionWizard,
  Checkout,
  DATE_FORMAT,
  MembershipCarousel,
  MembershipProrataChoice,
  PARAMETER_DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  PARAMETER_DATE_ONLY_FORMAT,
  SaleType,
  SERVICE_START_DATE_LIMIT,
  ServiceType,
  useSaleStepTitleBuilder,
} from "@gymflow/common";
import { formatCurrency, utcToZonedTime } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useContext, useEffect, useMemo, useState } from "react";

import { hasActiveMembershipSubscription } from "../../helpers/userMember";
import usePaymentSummary from "../../hooks/usePaymentSummary";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import SalesRoute from "./SalesRoute";
import UserDetails from "./UserDetails";

function SellMembershipAccordion({
  memberships,
  membershipType,
  onUserSubmit,
  purchase,
  searchUsers,
  currency,
  fetchPaymentMethods,
  fetchLeadSources,
  dateFormat,
  summary,
  timezone,
  requiredFields,
}) {
  const { createClubLink } = usePortalRoutes();
  const { api, settingsStore } = useGymflowModels();
  const settings = useClubSettings();
  const { stripeApplicationId } = useStoreState(settingsStore);
  const stripeApiKey = settings.stripe_api_key;
  const defaultNationality = settings.default_nationality;
  const postCodeFormat = settings.postal_code_country;
  const telephoneFormat = useMemo(
    () => JSON.parse(settings.phone_number_country),
    [settings.phone_number_country],
  );
  const { data: club } = useClub(
    { api, clubId: settings.clubId },
    { staleTime: clubStaleTime },
  );

  const { toast } = useContext(ToastContext);
  const [activeStep, setActiveStep] = useState(0);

  const [cart, setCart] = useState([]);
  const [user, setUser] = useState(null);

  const {
    summary: paymentSummary,
    onUpdateMembershipSummary: onUpdateSummary,
  } = usePaymentSummary({
    fetchSummary: summary,
    timezone,
    currency,
  });

  const { step1: step1Title, step2: step2Title } = useSaleStepTitleBuilder({
    saleType: SaleType.Membership,
    activeStep,
    membershipType,
    user,
    cart,
    recurringMembershipLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.RecurringMembership,
    ),
    prepaidMembershipLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.PrepaidMembership,
    ),
    sessionPackLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.SessionsPack,
    ),
    productLink: createClubLink(
      RouteLayout.Staff,
      RouteFeature.Sales,
      SalesRoute.Product,
    ),
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    onUpdateSummary({
      membershipId: cart[0].id,
      userMemberId: user.id,
      membershipName: cart[0].name,
      isRecurring: cart[0].isRecurring,
      billingPeriod: cart[0]?.billingPeriod,
      billingType: cart[0]?.billingType,
      calculateProrata: cart[0].calculateProrata,
      weeklyBillingDay: cart[0].weeklyBillingDay,
      monthlyBillingDay: cart[0].monthlyBillingDay,
    });
  }, [cart, user]);

  const steps = [
    {
      title: step1Title,
      icon: <FontAwesomeIcon icon={faShoppingCart} />,
      component: MembershipCarousel,
      props: {
        memberships,
        onSelectClick: (membershipId) => {
          setCart([memberships.find(({ id }) => id === membershipId)]);
          setActiveStep(1);
        },
      },
    },
    {
      title: step2Title,
      icon: <FontAwesomeIcon icon={faUserPlus} />,
      component: UserDetails,
      contentInsideTitleCard: true,
      props: {
        initialValues: user,
        searchUsers,
        fetchLeadSources,
        onUserSelectClick: async (userSelected) => {
          const updatedUser = await onUserSubmit(userSelected);

          if (!updatedUser) {
            return;
          }
          setUser(updatedUser);
          setActiveStep(2);
        },
        postCodeFormat,
        telephoneFormat,
        defaultNationality,
        dateFormat,
        onUserPrefil: ({ subscriptions }) => {
          if (hasActiveMembershipSubscription(subscriptions)) {
            toast({
              message:
                "This user has a membership. It needs to be cancelled before you can subscribe them to a new one.",
              intent: "error",
            });

            return false;
          }
          return true;
        },
        requiredFields,
        clubWaiverLink: club?.termsConditions,
      },
    },
    {
      title: "3. Checkout",
      icon: <FontAwesomeIcon icon={faCreditCard} />,
      component: Checkout,
      contentInsideTitleCard: true,
      props: {
        stripeApplicationId,
        stripeApiKey,
        userEmail: user?.email,
        cardHoldersName: user ? `${user.firstName} ${user.lastName}` : "",
        lineItems: paymentSummary.lineItems,
        total: paymentSummary.total,
        totalDescription: paymentSummary?.totalDescription,
        discount: paymentSummary.discount,
        fetchPaymentMethods:
          !!user && (() => fetchPaymentMethods({ userMemberId: user.id })),
        dateFormat,
        validateDate: (date) => {
          const todayInGym = moment()
            .tz(settings.timezone)
            .format("YYYY-MM-DD");
          const maxDate = moment(todayInGym, "YYYY-MM-DD")
            .add(SERVICE_START_DATE_LIMIT)
            .format(PARAMETER_DATE_ONLY_FORMAT);
          const minDate = moment(todayInGym, "YYYY-MM-DD")
            .subtract(1, "day")
            .endOf("day")
            .format(PARAMETER_DATE_ONLY_FORMAT);

          return date.isAfter(minDate) && date.isBefore(maxDate);
        },
        onTakePaymentClick: (paymentDetails) => {
          return purchase({ ...user, ...paymentDetails, cart });
        },
        onUpdateSummary: (rest) =>
          onUpdateSummary({
            membershipId: cart[0].id,
            userMemberId: user.id,
            membershipName: cart[0].name,
            isRecurring: cart[0].isRecurring,
            billingPeriod: cart[0]?.billingPeriod,
            billingType: cart[0]?.billingType,
            calculateProrata: cart[0].calculateProrata,
            weeklyBillingDay: cart[0].weeklyBillingDay,
            monthlyBillingDay: cart[0].monthlyBillingDay,
            ...rest,
          }),
        termsAndConditionsUrl: cart[0] && cart[0].termsAndConditionsLink,
        showStartDate: true,
        allowChangingStartDate: !cart?.[0]?.fixedStartDate,
        startDate: cart?.[0]?.fixedStartDate
          ? moment(
              utcToZonedTime(cart[0].fixedStartDate, settings.timezone),
              PARAMETER_DATE_FORMAT_WITHOUT_TZ,
            ).format(DATE_FORMAT)
          : undefined,
        skipPaymentMethod:
          membershipType === ServiceType.Prepaid &&
          paymentSummary.total === formatCurrency(0, currency),
        paymentMethodOptional:
          paymentSummary.total === formatCurrency(0, currency),
        timezone: settings.timezone,
      },
    },
  ];
  return (
    <AccordionWizard
      steps={steps}
      activeStep={activeStep}
      onStepTitleClick={(stepClicked) => setActiveStep(stepClicked)}
    />
  );
}

SellMembershipAccordion.defaultProps = {
  memberships: [],
};

SellMembershipAccordion.propTypes = {
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      price: PropTypes.string.isRequired,
      isRecurring: PropTypes.bool,
      includes: PropTypes.arrayOf(PropTypes.string),
      plus: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          price: PropTypes.string.isRequired,
        }),
      ),
      termsAndConditionsLink: PropTypes.string.isRequired,
      calculateProRata: PropTypes.oneOf(Object.keys(MembershipProrataChoice)),
    }),
  ),
  membershipType: PropTypes.oneOf(Object.values(ServiceType)).isRequired,
  onUserSubmit: PropTypes.func.isRequired,
  purchase: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  summary: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  fetchLeadSources: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default SellMembershipAccordion;
