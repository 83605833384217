import { useRecordForm } from "@gymflow/common";
import { useStoreState } from "easy-peasy";
import { useFormik } from "formik";
import omit from "lodash/omit";
import { useMemo } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Form } from "reactstrap";

import useGymflowModels from "../../../store";
import Wizard from "../../Wizard";
import PromotionFormMapper from "./PromotionFormMapper";
import createSchema, {
  BOTH_DISCOUNT,
  DISCOUNT_KIND,
  EXPIRY,
  IS_DURATION_MULTI_CYCLES,
  PROMOTION_CODE,
  PROMOTION_NAME,
  RECURRING_DISCOUNT,
  RECURRING_DISCOUNT_AMOUNT,
  RECURRING_DISCOUNT_DURATION,
  UPFRONT_DISCOUNT,
  UPFRONT_DISCOUNT_AMOUNT,
} from "./PromotionSchema";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

const mapper = new PromotionFormMapper();

export function PromotionAlert({
  editing,
  onCancel,
  onSubmit,
  specificItemsOptions,
}) {
  const { settingsStore } = useGymflowModels();
  const { defaultCurrency: currency } = useStoreState(settingsStore);
  const schema = useMemo(() => createSchema({ isNew: !editing }), [editing]);

  const { initialValues, getValues } = useRecordForm({
    record: editing,
    fields: schema.default(),
    mapper,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      if (editing) {
        const newFieldsToOmit = [
          "limitProductList",
          "limitMembershipList",
          "limitSessionPackList",
          "limitAppointableList",
          "limitProductIdList",
          "limitMembershipIdList",
          "limitSessionPackIdList",
          "limitAppointableIdList",
          "limitCategories",
        ];

        const nonEditableFieldsAfterFirstUse = [
          "name",
          "code",
          "upfrontDiscount",
          "upfrontDiscountType",
          "upfrontDiscountProrata",
          "upfrontDiscountAmount",
          "recurringDiscount",
          "recurringDiscountType",
          "recurringDiscountAmount",
          "recurringDiscountDuration",
          "limitSessionPackId",
          "limitProductId",
          "limitMembershipId",
          "timesUsed",
          "active",
          ...newFieldsToOmit,
        ];
        let valuesToSave =
          editing.timesUsed === 0
            ? omit(getValues(values), newFieldsToOmit)
            : omit(getValues(values), nonEditableFieldsAfterFirstUse);
        await onSubmit({ id: editing.id, patchedFields: valuesToSave });
      } else {
        await onSubmit(getValues(values));
      }
    },
    validationSchema: schema,
  });

  const { handleSubmit, errors, setFieldTouched, values } = formik;
  const lockSomeFields = editing?.timesUsed;

  const steps = [
    {
      name: "Step1",
      component: Step1,
      innerProps: {
        formikProps: formik,
        lockSomeFields,
      },
      validate: () => {
        setFieldTouched(PROMOTION_NAME);
        return values[PROMOTION_NAME] && !errors[PROMOTION_NAME];
      },
    },
    {
      name: "Step2",
      component: Step2,
      innerProps: {
        formikProps: formik,
        lockSomeFields,
      },
      validate: () => {
        setFieldTouched(PROMOTION_CODE);
        return values[PROMOTION_CODE] && !errors[PROMOTION_CODE];
      },
    },
    {
      name: "Step3",
      component: Step3,
      innerProps: {
        formikProps: formik,
        currency,
        lockSomeFields,
      },
      validate: () => {
        setFieldTouched(UPFRONT_DISCOUNT_AMOUNT);
        setFieldTouched(RECURRING_DISCOUNT_AMOUNT);
        setFieldTouched(RECURRING_DISCOUNT_DURATION);
        let valid = false;
        if (values[DISCOUNT_KIND] === BOTH_DISCOUNT) {
          valid =
            !errors[UPFRONT_DISCOUNT_AMOUNT] &&
            !errors[RECURRING_DISCOUNT_AMOUNT] &&
            (values[IS_DURATION_MULTI_CYCLES]
              ? !errors[RECURRING_DISCOUNT_DURATION]
              : true);
        } else if (values[DISCOUNT_KIND] === UPFRONT_DISCOUNT) {
          valid = !errors[UPFRONT_DISCOUNT_AMOUNT];
        } else if (values[DISCOUNT_KIND] === RECURRING_DISCOUNT) {
          valid =
            !errors[RECURRING_DISCOUNT_AMOUNT] &&
            (values[IS_DURATION_MULTI_CYCLES]
              ? !errors[RECURRING_DISCOUNT_DURATION]
              : true);
        }
        return valid;
      },
    },
    {
      name: "Step4",
      component: Step4,
      innerProps: {
        formikProps: formik,
        specificItemsOptions,
        lockSomeFields,
      },
    },
    {
      name: "Step5",
      component: Step5,
      innerProps: {
        formikProps: formik,
      },
    },
    {
      name: "Step6",
      component: Step6,
      innerProps: {
        formikProps: formik,
      },
      validate: () => !errors[EXPIRY],
    },
  ];

  return (
    <ReactBSAlert
      title={
        <div className="head-title d-flex">
          <h2>{editing ? "Edit" : "Add"} Promotion</h2>
        </div>
      }
      showCancel
      onCancel={onCancel}
      closeOnClickOutside={false}
      onConfirm={handleSubmit}
      customButtons={<div />}
      customClass="alert-wrapper"
      style={{
        width: 600,
      }}
    >
      <Form className="form-horizontal text-left" role="form">
        <Wizard steps={steps} onCancel={onCancel} onSubmit={handleSubmit} />
      </Form>
    </ReactBSAlert>
  );
}
