import { facilityListQueryFn } from "@gymflow/api";

import useGymflowModels from "../../store";
import { eagerSelectAll, PaginatedSelect } from ".";

export interface FacilitySelectOption {
  label: string;
  value: number;
}

interface FacilitySelectProps {
  value: FacilitySelectOption[];
  onChange: (newValue: FacilitySelectOption[]) => void;
  allowMultipleSelection?: boolean;
  placeholder?: string;
}

export function FacilitySelect({
  value,
  onChange,
  placeholder,
  allowMultipleSelection,
}: FacilitySelectProps) {
  const { api } = useGymflowModels();

  return (
    <PaginatedSelect
      isMulti={allowMultipleSelection}
      placeholder={placeholder}
      loadOptions={async (_, __, additional) => {
        const facilityList = await facilityListQueryFn({
          api,
          opts: {
            page: additional.page,
            extraParams: {
              status: "ACTIVE",
            },
          },
        });
        return {
          options: facilityList.content.map((facility) => ({
            label: facility.name,
            value: facility.id,
          })),
          hasMore: !facilityList.last,
          additional: {
            page: additional.page + 1,
          },
        };
      }}
      value={value}
      onChange={(newValue) => {
        onChange(allowMultipleSelection ? newValue : [newValue]);
      }}
      showSelectAll
      selectAllClick={eagerSelectAll}
    />
  );
}
