import {
  useClub,
  useClubFeatureFlags,
  useQueryGrowMetricsApiKeys,
} from "@gymflow/api";
import { KisiStatus } from "@gymflow/types";
import { ReactNode, useContext } from "react";
import { useHistory } from "react-router";

import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useClubSettings } from "../../providers";
import { ToastContext } from "../../providers/ToastProvider/context";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";
import { LinkButton, Spinner, Switch } from "../atoms";
import { SettingsContainer } from "../Settings/SettingsContainer";
import { kisiIntegrationRoutes } from "./KisiIntegration";

export function Integrations() {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const toast = useContext(ToastContext);
  const { api } = useGymflowModels();
  const { data: club } = useClub({ clubId: clubId as number, api });

  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const { data: featureFlags } = useClubFeatureFlags({ clubId, api });
  const { data: growMetricsApiKeys, isLoading: isGrowMetricsApiKeysLoading } =
    useQueryGrowMetricsApiKeys({ api });
  const isKisiProcessing = club?.kisiStatus
    ? (
        [
          "PROCESSING_ENABLE",
          "PROCESSING_DISABLE",
          "PROCESSING_MODIFY",
        ] as KisiStatus[]
      ).includes(club.kisiStatus)
    : false;

  return (
    <SettingsContainer
      title="Integrations and connected apps"
      subTitle="Enable superpowers by connecting Gymflow to partner apps and more."
    >
      <div className="flex flex-row flex-wrap gap-4">
        <IntegrationCard
          logo={
            <div className="bg-kisi-logo h-12 w-12 rounded-sm bg-contain bg-center bg-no-repeat"></div>
          }
          title="Kisi"
          description="Mobile check ins and access control solution. Offer 24 hour access via the Gymflow mobile app."
          switchComponent={
            isKisiProcessing ? (
              <Spinner />
            ) : (
              <Switch
                value={club?.kisiStatus === "ENABLED"}
                label="Kisi integration toggle"
                onChange={() => {
                  if (club?.kisiStatus === "ENABLED") {
                    toast.toast({
                      message:
                        "This operation is not supported yet. If you want to disable Kisi please contact support.",
                      intent: "warning",
                    });
                  }
                  if (club?.kisiStatus === "DISABLED") {
                    history.push(
                      createClubLink(
                        RouteLayout.Staff,
                        RouteFeature.KisiIntegration,
                        kisiIntegrationRoutes.Enable,
                      ),
                    );
                  }
                }}
              />
            )
          }
          footer={
            <>
              {club?.kisiStatus === "ENABLED" && (
                <LinkButton
                  to={createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.KisiIntegration,
                    kisiIntegrationRoutes.Edit,
                  )}
                >
                  Update Integration
                </LinkButton>
              )}
              {club?.kisiStatus === "DISABLED" && (
                <LinkButton
                  to={createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.KisiIntegration,
                    kisiIntegrationRoutes.Enable,
                  )}
                >
                  Enable Integration
                </LinkButton>
              )}
              {isKisiProcessing && "Processing..."}
            </>
          }
        />
        {featureFlags?.featureFlags.GROW_METRICS_INTEGRATION && (
          <IntegrationCard
            logo={
              <div className="bg-grow-metrics-logo h-12 w-12 rounded-sm bg-contain bg-center bg-no-repeat"></div>
            }
            title="GrowMetrics"
            description="Automate Lead creation and track conversion of leads generated by GrowMetrics Automated Advertising platform."
            switchComponent={
              isGrowMetricsApiKeysLoading ? (
                <Spinner />
              ) : (
                <Switch
                  value={growMetricsApiKeys !== null}
                  label="GrowMetrics integration toggle"
                  onChange={() => {
                    history.push(
                      createClubLink(
                        RouteLayout.Staff,
                        RouteFeature.GrowMetricsIntegration,
                      ),
                    );
                  }}
                />
              )
            }
            footer={
              isGrowMetricsApiKeysLoading ? (
                <Spinner />
              ) : growMetricsApiKeys !== undefined ? (
                <LinkButton
                  to={createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.GrowMetricsIntegration,
                  )}
                >
                  {growMetricsApiKeys !== null
                    ? "Update Integration"
                    : "Enable Integration"}
                </LinkButton>
              ) : undefined
            }
          />
        )}
      </div>
    </SettingsContainer>
  );
}

const IntegrationCard = ({
  logo,
  title,
  description,
  switchComponent,
  footer,
}: {
  logo: ReactNode;
  title: string;
  description: string;
  switchComponent: ReactNode;
  footer: ReactNode;
}) => {
  return (
    <div className="flex w-full flex-col justify-around rounded-xl border border-gray-200 bg-white shadow lg:w-[400px]">
      <div className="flex items-center justify-between p-6">
        <div className="flex gap-x-4">
          {logo}
          <div className="flex items-center font-semibold">{title}</div>
        </div>
        {switchComponent}
      </div>
      <div className="flex grow p-6 pt-0">{description}</div>
      <div className="flex flex-row items-center justify-end border-t border-gray-200 p-6">
        {footer}
      </div>
    </div>
  );
};
