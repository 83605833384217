import { AxiosError } from "axios";
import { createContext } from "react";

import { ToastProps } from "./Toast";

export const ToastContext = createContext<{
  /*** This function parses errors in the current format the BE sends them, in the future we'll be moving to a different format */
  notifyDanger: (error: string | AxiosError<any>) => void;
  toast: (options: Omit<ToastProps, "toastId">) => void;
}>(null as any);
