import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PARAMETER_DATE_FORMAT,
  ServiceType,
  SubscriptionStatus,
} from "@gymflow/common";
import { toLowerCaseExceptFirstChar } from "@gymflow/helpers";
import classNames from "classnames";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { Badge, Button, Card, CardBody, Col, Row } from "reactstrap";

import { useClubSettings } from "../../providers";

const styleByStatus = (status) => {
  const colorMap = {
    [SubscriptionStatus.Active]: [0, 126, 79],
    [SubscriptionStatus.Paused]: [240, 85, 25],
    [SubscriptionStatus.Overdue]: [226, 0, 58],
  };
  return {
    color: `rgba(${colorMap[status] || [108, 117, 125]}, 1)`,
    backgroundColor: `rgba(${colorMap[status] || [108, 117, 125]}, 0.2)`,
    textTransform: "none",
  };
};

function MembershipWidget({
  membershipName,
  status,
  price,
  isRecurring,
  priceInterval,
  billOn,
  onPauseClick,
  onCancelClick,
  onResumeClick,
  activeFrom,
  type,
  isCancellable,
  allowPause,
  cancellationDate,
  termsConditions,
  defaultPayment,
  onUpdateCard,
}) {
  const settings = useClubSettings();
  const dateFormat = settings.date_format;

  const renderPrice = () => {
    return (
      <>
        {price} {isRecurring && <span className="ml-1">{priceInterval}</span>}
      </>
    );
  };

  const renderButtons = () => {
    if (type === ServiceType.Prepaid) {
      return false;
    }

    if (status === SubscriptionStatus.Active) {
      const isPausable = type === ServiceType.Recurring && allowPause;
      return (
        <>
          <Button
            color="primary"
            size="sm"
            className={classNames("btn-round", { "d-none": !isCancellable })}
            onClick={onCancelClick}
          >
            Cancel
          </Button>
          <Button
            color="warning"
            className={classNames("btn-round", { "d-none": !isPausable })}
            onClick={onPauseClick}
          >
            Pause
          </Button>
        </>
      );
    }

    return (
      <Button
        color="warning"
        className={classNames("btn-round", { "d-none": !allowPause })}
        onClick={onResumeClick}
      >
        Resume
      </Button>
    );
  };

  const renderStatusText = () => {
    if (cancellationDate) {
      return (
        <span>
          Cancellation effective:{" "}
          {moment(cancellationDate, PARAMETER_DATE_FORMAT).format(dateFormat)}
        </span>
      );
    } else if (status === SubscriptionStatus.Pending) {
      return (
        <span>
          Starts on:{" "}
          {moment(activeFrom, PARAMETER_DATE_FORMAT).format(dateFormat)}
        </span>
      );
    }
    return null;
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="d-flex justify-content-between">
            <h3 className="font-weight-bold mr-2">{membershipName}</h3>
            <Badge pill className="p2" style={styleByStatus(status)}>
              {toLowerCaseExceptFirstChar(status)}
            </Badge>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="mb-0">{renderPrice()}</div>
          </Col>
        </Row>
        <Row>
          <Col>{renderStatusText()}</Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-sm" style={{ textDecoration: "underline" }}>
            <a href={termsConditions} target="_blank" rel="noopener noreferrer">
              Terms & Conditions
            </a>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <div className="d-flex justify-content-between mt-1">
              <div className="font-weight-bold">Payment</div>
              {onUpdateCard && (
                <Button className="btn-link py-0" onClick={onUpdateCard}>
                  Update Card
                </Button>
              )}
            </div>
            <div className="mt-1">{billOn}</div>
            {defaultPayment && (
              <div className="mt-1">
                <FontAwesomeIcon className="mr-1" icon={faCreditCard} />
                <span className="mr-1">
                  Card ending in {defaultPayment?.last4Digits}
                </span>
                <span className="text-muted">
                  Expires: {defaultPayment?.expMonth}/
                  {defaultPayment?.expYear.toString()?.slice(-2)}
                </span>
              </div>
            )}
          </Col>
        </Row>
        <Row
          className={classNames(
            { "d-none": status === SubscriptionStatus.Cancelled },
            "mt-2",
            "mb-2",
          )}
        >
          <Col>{renderButtons()}</Col>
        </Row>
      </CardBody>
    </Card>
  );
}

MembershipWidget.defaultProps = {
  includes: [],
};

MembershipWidget.propTypes = {
  membershipName: PropTypes.string.isRequired,
  status: PropTypes.oneOf(Object.values(SubscriptionStatus)).isRequired,
  price: PropTypes.string.isRequired,
  billOn: PropTypes.string.isRequired,
  priceInterval: PropTypes.string,
  onPauseClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onResumeClick: PropTypes.func.isRequired,
  activeFrom: PropTypes.string.isRequired,
  isCancellable: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(Object.values(ServiceType)).isRequired,
  allowPause: PropTypes.bool.isRequired,
  cancellationDate: PropTypes.string,
  defaultPayment: PropTypes.object,
  onUpdateCard: PropTypes.func.isRequired,
};

export default MembershipWidget;
