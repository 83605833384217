import { thunk } from 'easy-peasy';

import BaseModelBuilder, { getApi, createPayloadFromFindResponse } from './BaseModelBuilder';

class FacilityModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId) {
    super(apiKey);
    this.clubId = clubId;
    this.withCategories('facilityCategoryId');
    this.withActiveInactive();
    this.generators.push(this.facilityGenerator);
  }

  /**
   * @private
   */
  facilityGenerator() {
    return {
      create: thunk((_, { name, description, facilityCategoryId }, { injections, getState }) => {
        return getApi(injections, getState()).create({
          name,
          description,
          facilityCategoryId,
          clubId: this.clubId,
        });
      }),
      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, extraParams }, { getState, injections }) => {
        if (getState().categories === null) {
          await actions.fetchCategories();
        }

        const { data } = await getApi(injections, getState()).find({
          page,
          limit,
          sort,
          extraParams: {
            ...extraParams,
            clubId: this.clubId,
          },
        });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList({ ...payload, filter: { sort, ...extraParams } });
        return payload;
      }),
    };
  }
}

export default FacilityModelBuilder;
