import { z } from "zod";

export const StrongAuthorizationPaymentStatus = {
  AuthorizationNotRequired: "PAYMENT_AUTHORIZATION_NOT_REQUIRED",
  Failed: "FAILED",
  Succeeded: "SUCCEEDED",
  AuthorizationRequired: "PAYMENT_AUTHORIZATION_REQUIRED",
} as const;

export const StrongAuthorizationPaymentStatusZodSchema = z.enum([
  StrongAuthorizationPaymentStatus.AuthorizationNotRequired,
  StrongAuthorizationPaymentStatus.Failed,
  StrongAuthorizationPaymentStatus.Succeeded,
  StrongAuthorizationPaymentStatus.AuthorizationRequired,
]);
