import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export function useMutationMembershipLinkMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      primarySubscriptionId,
      secondaryMemberId,
    }: {
      primarySubscriptionId: number;
      secondaryMemberId: string;
    }) => {
      await api.memberApi.membershipLinkMember(
        primarySubscriptionId,
        secondaryMemberId,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries(memberQueryKeys.all());
    },
  });
}
