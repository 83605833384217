import { PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import moment from "moment";

import { LeadFilters } from "./LeadPipelineFilterSidebar";

export function generateLeadFilters({ filters }: { filters?: LeadFilters }): {
  leadSourceId?: number[];
  leadStatusId?: number[];
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
} {
  return {
    leadSourceId:
      filters?.leadSource && filters?.leadSource.length > 0
        ? filters.leadSource.map((source) => source.value)
        : undefined,
    leadStatusId:
      filters?.leadStatus && filters.leadStatus?.length > 0
        ? filters.leadStatus.map((status) => status.value)
        : undefined,
    createdFrom: filters?.createdFrom
      ? moment(filters.createdFrom, "YYYY-MM-DD")
          .startOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
      : undefined,
    createdTo: filters?.createdTo
      ? moment(filters.createdTo, "YYYY-MM-DD")
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
      : undefined,
    smsCommunication: filters?.smsCommunication,
    emailCommunication: filters?.emailCommunication,
  };
}

export function generateLeadFiltersForEmailAndBulkStatus({
  defaultStatusIds,
  filters,
}: {
  defaultStatusIds?: number[];
  filters?: LeadFilters;
}): {
  leadSourceIdList?: number[];
  leadStatusIdList?: number[];
  createdFrom?: string;
  createdTo?: string;
  smsCommunication?: boolean;
  emailCommunication?: boolean;
} {
  return {
    leadSourceIdList: filters?.leadSource
      ? filters.leadSource.map((source) => source.value)
      : undefined,
    leadStatusIdList:
      filters?.leadStatus && filters.leadStatus?.length > 0
        ? filters.leadStatus.map((status) => status.value)
        : defaultStatusIds,
    createdFrom: filters?.createdFrom
      ? moment(filters.createdFrom, "YYYY-MM-DD")
          .startOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
      : undefined,
    createdTo: filters?.createdTo
      ? moment(filters.createdTo, "YYYY-MM-DD")
          .endOf("day")
          .format(PARAMETER_DATE_FORMAT_WITHOUT_TZ)
      : undefined,
    smsCommunication: filters?.smsCommunication,
    emailCommunication: filters?.emailCommunication,
  };
}
