import { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import ServiceType from '../api/ServiceType';
import LinkWithQuery from '../components/atoms/LinkWithQuery';

const useSaleStepTitleBuilder = ({
  saleType,
  activeStep,
  membershipType,
  event,
  cart,
  user,
  hideLinksStep1 = false,
  cartTotal,
  recurringMembershipLink,
  prepaidMembershipLink,
  sessionPackLink,
  productLink,
  keepQuery = true,
} = {}) => {
  const LinkComponent = useMemo(() => keepQuery ? LinkWithQuery : Link, [keepQuery])
  const step1Title = useMemo(() => {
    if (activeStep === 0 || (event && activeStep === 1)) {
      if (hideLinksStep1) {
        switch (saleType) {
          case SaleType.Membership:
            return '1. Select Membership';
          case SaleType.SessionPack:
            return '1. Select Credit Packs';
          case SaleType.Product:
            return '1. Select Product';
          default:
            throw new Error('Unknown Sale Type.');
        }
      }
      const links = [
        <LinkComponent
          className={classNames({
            active: saleType === SaleType.Membership && membershipType === ServiceType.Recurring,
          })}
          to={recurringMembershipLink}
        >
          Recurring Membership
        </LinkComponent>,
        <LinkComponent
          className={classNames({
            active: saleType === SaleType.Membership && membershipType === ServiceType.Prepaid,
          })}
          to={prepaidMembershipLink}
        >
          Pre-Paid Membership
        </LinkComponent>,
        <LinkComponent className={classNames({ active: saleType === SaleType.SessionPack })} to={sessionPackLink}>
          Credit Packs
        </LinkComponent>,
      ];

      if (productLink) {
        links.push(
          <LinkComponent className={classNames({ active: saleType === SaleType.Product })} to={productLink}>
            Products
          </LinkComponent>
        );
      }

      const middle = links.reduce((acc, step, idx) => {
        if (idx === 0) {
          return step;
        }
        if (idx === links.length - 1) {
          return (
            <>
              {acc} or {step}
            </>
          );
        }
        return (
          <>
            {acc}, {step}
          </>
        );
      });

      return <>1. Select {middle}.</>;
    }
    return saleType === SaleType.Product
      ? `1. You selected: ${cart.reduce((acc, p) => acc + p.quantity, 0)} product(s) - ${cartTotal}`
      : `1. You selected: ${cart[0]?.name} - ${cart[0]?.price}`;
  }, [activeStep, membershipType, event, cartTotal]);

  const step2Title = useMemo(() => {
    if (event) {
      if (activeStep > 2) {
        return `2. ${user?.firstName} ${user?.lastName}`;
      }
    } else if (activeStep > 1) {
      return `2. ${user?.firstName} ${user?.lastName}`;
    }

    return '2. Create Account';
  }, [user, activeStep, event, LinkComponent]);

  return { step1: step1Title, step2: step2Title };
};

export const SaleType = {
  Membership: 'membership',
  SessionPack: 'session-pack',
  Product: 'product',
};

export default useSaleStepTitleBuilder;
