export function ExclamationTriangleIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        d="M11.9998 8.99974V12.9997M11.9998 16.9997H12.0098M10.6151 3.89147L2.39019 18.0981C1.93398 18.8861 1.70588 19.2801 1.73959 19.6034C1.769 19.8855 1.91677 20.1418 2.14613 20.3085C2.40908 20.4997 2.86435 20.4997 3.77487 20.4997H20.2246C21.1352 20.4997 21.5904 20.4997 21.8534 20.3085C22.0827 20.1418 22.2305 19.8855 22.2599 19.6034C22.2936 19.2801 22.0655 18.8861 21.6093 18.0981L13.3844 3.89146C12.9299 3.1063 12.7026 2.71372 12.4061 2.58187C12.1474 2.46685 11.8521 2.46685 11.5935 2.58187C11.2969 2.71372 11.0696 3.1063 10.6151 3.89147Z"
        stroke="#D92D20"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
}
