import PropTypes from "prop-types";

function PaymentMethodOption({ type, last4Digits, expMonth, expYear }) {
  const text = (
    <>
      Card ending in <b>{last4Digits}</b> (expires{" "}
      <b>{`${expMonth}/${expYear}`}</b>)
    </>
  );
  return (
    <div className="flex flex-row items-center align-text-top">
      <i className="far fa-credit-card" />
      <div className="d-inline-block ml-2">{text}</div>
    </div>
  );
}

const PaymentMethodTypes = {
  Card: "card",
  Bacs: "bacs_debit",
};

PaymentMethodOption.propTypes = {
  type: PropTypes.oneOf([PaymentMethodTypes.Card, PaymentMethodTypes.Bacs])
    .isRequired,
  last4Digits: PropTypes.string,
  expMonth: PropTypes.number,
  expYear: PropTypes.number,
};

export default PaymentMethodOption;
