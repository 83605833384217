import {
  AvailabilityOverrideDTO,
  AvailabilityOverridePostDTO,
} from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationHostAvailabilityOverrideCreate({
  api,
}: {
  api: {
    availabilityApi: {
      createHostOverride: (
        staffId: string,
        isUnavailableAllDay: boolean,
        startTime: string,
        endTime: string,
        overrideDate: string,
      ) => Promise<{ data: AvailabilityOverrideDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      newOverride,
    }: {
      staffId: string;
      newOverride: AvailabilityOverridePostDTO;
    }) => {
      const response = await api.availabilityApi.createHostOverride(
        staffId,
        newOverride.isUnavailableAllDay,
        newOverride.startTime,
        newOverride.endTime,
        newOverride.overrideDate,
      );
      return response.data;
    },
    onSuccess: (_, { staffId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allHostOverrides(staffId),
      });
    },
  });

  return mutation;
}
