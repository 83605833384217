export type ShieldIconProps = {
  className?: string;
  pathClassName?: string;
};

export const ShieldIcon = ({ className, pathClassName }: ShieldIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M9.41838 18.0125C9.60287 18.1201 9.69512 18.1739 9.82531 18.2018C9.92634 18.2235 10.0737 18.2235 10.1748 18.2018C10.305 18.1739 10.3972 18.1201 10.5817 18.0125C12.2051 17.0654 16.6667 14.0904 16.6667 10.0001V6.01472C16.6667 5.34846 16.6667 5.01533 16.5577 4.72898C16.4615 4.47601 16.3051 4.25029 16.102 4.07133C15.8721 3.86875 15.5602 3.75178 14.9364 3.51785L10.4682 1.84228C10.295 1.77732 10.2083 1.74483 10.1192 1.73196C10.0402 1.72053 9.9599 1.72053 9.88086 1.73196C9.79175 1.74483 9.70512 1.77732 9.53188 1.84228L5.06371 3.51785C4.43987 3.75178 4.12795 3.86875 3.89809 4.07133C3.69503 4.25029 3.5386 4.47601 3.44234 4.72898C3.33337 5.01533 3.33337 5.34846 3.33337 6.01472V10.0001C3.33337 14.0904 7.79501 17.0654 9.41838 18.0125Z"
        stroke="#DC6803"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};
