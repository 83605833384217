import { useMembershipOverdueReport } from "@gymflow/api";
import { ReportMemberDTO } from "@gymflow/types";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import qs from "qs";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";

import { LinkButton } from "../../components/atoms";
import { ReportPagination } from "../../components/molecules";
import { Table } from "../../components/organisms";
import { Report } from "../../components/organisms/Report";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import useGymflowModels from "../../store";
import { UserMemberPage } from "../UserMember";

export function MembershipOverdue() {
  const { createMemberLink } = usePortalRoutes();
  const history = useHistory();
  const { api } = useGymflowModels();

  const location = useLocation();
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState(25);
  const [currentSort, setCurrentSort] = useState<SortingState>([]);

  const createSort = function () {
    return currentSort?.[0]
      ? { field: currentSort[0]?.id, desc: currentSort[0]?.desc }
      : undefined;
  };

  const { data, isFetching } = useMembershipOverdueReport({
    api,
    sort: createSort(),
    limit: pageSize,
    page: currentPage,
  });

  const columnHelper = createColumnHelper<ReportMemberDTO>();
  const columnsDefinition = [
    columnHelper.accessor(
      (row) => {
        return {
          firstName: row.firstName,
          lastName: row.lastName,
          id: row.userMemberId,
        };
      },
      {
        id: "firstName",
        cell: (info) => {
          const memberName = `${info.getValue().firstName} ${
            info.getValue().lastName
          }`;
          return (
            <div>
              <LinkButton
                to={createMemberLink(
                  info.getValue().id,
                  UserMemberPage.Account,
                )}
              >
                {memberName}
              </LinkButton>
            </div>
          );
        },
        header: () => {
          return <div >Name</div>;
        },
        enableSorting: false,
        footer: () => <div>Total: {data?.totalElements}</div>,
      },
    ),
    columnHelper.accessor("mobileNumber", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Contact No",
      enableSorting: false,
    }),

    columnHelper.accessor("email", {
      cell: (info) => <div className="text-gray-600">{info.getValue()}</div>,
      header: "Email",
      enableSorting: false,
    }),
  ];

  return (
    <Report
      title="Overdue Members"
      description="List of all memberships in an overdue status."
      close={() => {
        history.push({
          pathname: searchParams["backRoute"] as string,
          search: qs.stringify({
            dates: {
              startDate: searchParams["startDate"],
              endDate: searchParams["endDate"],
            },
          }),
        });
      }}
      table={
        <>
          <Table
            data={data?.content ?? []}
            columns={columnsDefinition}
            pageCount={data?.totalPages}
            pageIndex={data?.number}
            onSortingChange={setCurrentSort}
            sort={currentSort}
            isFetching={isFetching}
            pageSize={pageSize}
            rowClassName="h-14"
          />
          <div className="border-t-[0.063rem] border-gray-300">
            <ReportPagination
              pageCount={data?.totalPages as number}
              currentPage={data?.number as number}
              onPageChange={(newPage) => {
                setCurrentPage(newPage);
              }}
              pageSize={pageSize}
              setPageSize={(newValue) => {
                setPageSize(newValue);
                setCurrentPage(0);
              }}
            />
          </div>
        </>
      }
    />
  );
}
