import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";
import {
  generateMemberListFilter,
  UserMemberListFilter,
} from "./useInfiniteQueryMemberList";

export function useQueryMemberListCount({
  api,
  filters,
  tz,
}: {
  api: ApiType;
  filters?: UserMemberListFilter;
  tz: string;
}) {
  return useQuery({
    queryKey: memberQueryKeys.listNewCount(filters),
    queryFn: async () => {
      return (
        await api.memberApi.listNewCount({
          ...generateMemberListFilter(tz, filters),
        })
      ).data;
    },
  });
}
