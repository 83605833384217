import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { usePromotionEdit, usePromotionList } from "@gymflow/api";
import { isMobile } from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { PromotionDTO } from "@gymflow/types";
import { createColumnHelper } from "@tanstack/react-table";
import { ToastContext } from "apps/portal/src/providers/ToastProvider/context";
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useWindowSize } from "usehooks-ts";

import { usePageSize, usePortalRoutes } from "../../../hooks";
import { useClubSettings } from "../../../providers";
import { RouteFeature, RouteLayout } from "../../../routes";
import useGymflowModels from "../../../store";
import { marketingRoute } from "../../../views/Marketing";
import { Badge, Button, EditIcon, Switch } from "../../atoms";
import { PaginatedTable } from "../../organisms";
import { PromotionQuickActionsButton } from "./PromotionsQuickActionsButton";

export function Promotions() {
  const { defaultCurrency } = useClubSettings();
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const pageSize = usePageSize({
    tableContainerRef,
    rowHeight: 56,
  });

  const [showDisabled, setShowDisabled] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { toast } = useContext(ToastContext);

  const { api } = useGymflowModels();
  const { data, isLoading } = usePromotionList({
    api,
    opts: {
      page: currentPage,
      limit: pageSize,
      extraParams: {
        active: showDisabled ? undefined : true,
      },
    },
  });
  const editPromotion = usePromotionEdit({ api });

  const history = useHistory();
  const { createClubLink } = usePortalRoutes();
  const columnHelper = createColumnHelper<PromotionDTO>();
  const upfrontDiscountColumn = columnHelper.accessor(
    (e) => ({
      upfrontDiscount: e.upfrontDiscount,
      upfrontDiscountAmount: e.upfrontDiscountAmount,
      upfrontDiscountType: e.upfrontDiscountType,
    }),
    {
      header: "First Discount",
      cell: (column) => {
        const value = column.getValue();
        if (!value.upfrontDiscount || !value.upfrontDiscountAmount) {
          return "-";
        }
        const text =
          value.upfrontDiscountType === "CURRENCY"
            ? formatCurrency(value.upfrontDiscountAmount, defaultCurrency)
            : value.upfrontDiscountAmount + "%";
        return <Badge>{text}</Badge>;
      },
    },
  );
  const recurringDiscountColumn = columnHelper.accessor(
    (e) => ({
      recurringDiscount: e.recurringDiscount,
      recurringDiscountAmount: e.recurringDiscountAmount,
      recurringDiscountType: e.recurringDiscountType,
    }),
    {
      header: "Subsequent Discount",
      cell: (column) => {
        const value = column.getValue();
        if (!value.recurringDiscount || !value.recurringDiscountAmount) {
          return "-";
        }
        const text =
          value.recurringDiscountType === "CURRENCY"
            ? formatCurrency(value.recurringDiscountAmount, defaultCurrency)
            : value.recurringDiscountAmount + "%";
        return <Badge>{text}</Badge>;
      },
    },
  );

  const desktopColumns = [
    columnHelper.accessor("name", {
      cell: (column) => {
        const value = column.getValue();
        return <div className="truncate">{value}</div>;
      },
    }),
    columnHelper.accessor("code", {}),
    columnHelper.accessor(
      (e) => ({
        active: e.active,
        id: e.id,
      }),
      {
        id: "active",
        header: "Active",
        cell: (column) => {
          const value = column.getValue();
          return (
            <Switch
              value={!!value.active}
              onChange={async (checked) => {
                if (checked) {
                  await editPromotion.mutateAsync({
                    promotionId: value.id,
                    patchedFields: { active: true },
                  });
                  toast({ message: "Promotion enabled successfully." });
                } else {
                  await editPromotion.mutateAsync({
                    promotionId: value.id,
                    patchedFields: { active: false },
                  });
                  toast({ message: "Promotion disabled successfully." });
                }
              }}
            />
          );
        },
      },
    ),
    upfrontDiscountColumn,
    recurringDiscountColumn,
    columnHelper.accessor("timesUsed", {
      header: "Times Used",
    }),
    columnHelper.accessor("id", {
      header: "Actions",
      cell: (column) => {
        const value = column.getValue();
        return (
          <Button
            className="p-2"
            onClick={() => {
              history.push(
                createClubLink(
                  RouteLayout.Staff,
                  RouteFeature.Marketing,
                  marketingRoute.Promotions,
                  `/form/${value}`,
                ),
              );
            }}
          >
            <EditIcon className="h-5 w-5" pathClassName="stroke-gray-950" />
          </Button>
        );
      },
    }),
  ];

  const mobileColumns = [
    columnHelper.accessor(
      (e) => ({
        name: e.name,
        id: e.id,
      }),
      {
        header: "Name",
        cell: (column) => {
          const value = column.getValue();
          return (
            <div>
              <Tooltip
                className="!z-50 flex max-w-sm flex-col items-center rounded-lg !bg-black text-center text-sm font-medium"
                id={`tooltip${value.id}`}
                openOnClick
                place="left-start"
              />
              <div
                data-tooltip-id={`tooltip${value.id}`}
                data-tooltip-content={value.name}
                className="w-14 truncate"
              >
                {value.name}
              </div>
            </div>
          );
        },
      },
    ),
    upfrontDiscountColumn,
    recurringDiscountColumn,
    columnHelper.accessor(
      (e) => ({
        active: e.active,
        id: e.id,
      }),
      {
        header: "Actions",
        cell: (column) => {
          const value = column.getValue();
          return (
            <PromotionQuickActionsButton
              promoCodeId={value.id}
              isPromoCodeActive={value.active}
            />
          );
        },
      },
    ),
  ];

  const windowSize = useWindowSize({ debounceDelay: 100 });
  return (
    <div className="mb-4 flex h-full max-h-full w-full flex-col rounded-xl border border-gray-200 bg-white">
      <div className="flex flex-row flex-wrap items-center justify-between gap-4 px-6 py-5">
        <div className="flex flex-col items-start">
          <div className="flex text-lg font-semibold">Promotions</div>
          <div className="flex text-sm text-gray-600"></div>
        </div>
        <div className="flex w-full flex-row flex-wrap items-center justify-end gap-2 md:w-auto">
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex items-center gap-2">
              <div className="text-sm font-medium text-gray-950">
                Show Disabled
              </div>
              <Switch
                value={showDisabled}
                onChange={(newValue) => {
                  setShowDisabled(newValue);
                }}
              />
            </div>
            <Button
              intent="secondary"
              className="text-white"
              onClick={() => {
                history.push(
                  createClubLink(
                    RouteLayout.Staff,
                    RouteFeature.Marketing,
                    marketingRoute.Promotions,
                    `/form`,
                  ),
                );
              }}
            >
              <div className="flex flex-row items-center gap-x-2">
                <FontAwesomeIcon icon={faPlus} />
                <div className="hidden sm:block">Add Promotion</div>
              </div>
            </Button>
          </div>
        </div>
      </div>

      <PaginatedTable
        className="!rounded-t-none border-x-0 !border-t border-gray-200"
        tableProps={{
          data: data?.content ?? [],
          columns: !isMobile(windowSize.width) ? desktopColumns : mobileColumns,
          pageCount: data?.totalPages,
          pageIndex: data?.number,
          isFetching: isLoading,
          pageSize: pageSize,
          tableContainerRef,
        }}
        hasNextPage={!!data && !data.last}
        hasPreviousPage={!!data && data?.number > 0}
        goToNextPage={() => {
          setCurrentPage(currentPage + 1);
        }}
        goToPreviousPage={() => {
          setCurrentPage(currentPage - 1);
        }}
      />
    </div>
  );
}
