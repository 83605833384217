import { action, thunk } from 'easy-peasy';
import moment from 'moment-timezone';
import { tzDateTimeStringToUtc, utcDateTimeStringToTz } from '../helpers/date';
import { PARAMETER_TIME_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from '../api/eventApi';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';

class TaskModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId, settingsKey = 'settings') {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;
    this.generators.push(this.taskGenerator);
  }

  /**
   * @private
   */
  taskGenerator() {
    return {
      last: false,
      create: thunk((_, fields, { injections, getState }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        return getApi(injections, getState()).create({
          ...fields,
          ...convertDeadlineDatesToUtc(fields, tz),
        });
      }),

      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        const { data } = await getApi(injections, getState()).findById(recordId);
        const payload = {
          ...data,
        };
        actions.fetchedRecord(convertDeadlineDatesToTz(payload, tz));
        return payload;
      }),

      fetchList: thunk(
        async (actions, { dateFrom, dateTo, page = 0, limit = 1000, sort, ...rest }, { injections, getState }) => {
          const tz = injections.globalStore.getState()[this.settingsKey].timezone;
          const dateFromUtc = dateFrom ? tzDateTimeStringToUtc(dateFrom, tz) : undefined;
          const dateToUtc = dateTo ? tzDateTimeStringToUtc(dateTo, tz) : undefined;

          const { data } = await getApi(injections, getState()).find({
            page,
            limit,
            sort,
            extraParams: {
              dateFrom: dateFromUtc,
              dateTo: dateToUtc,
              ...rest,
            },
          });
          const payload = createPayloadFromFindResponse(data);

          payload.rows = payload.rows.map((r) => convertDeadlineDatesToTz(r, tz));

          actions.fetchedList({ ...payload, filter: { sort, dateFrom: dateFromUtc, dateTo: dateToUtc, ...rest } });
          return payload;
        }
      ),

      addTaskToStoreInGymTz: thunk((actions, { task, runSort = false }, { injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        const utcTask = convertDeadlineDatesToTz({ ...task }, tz);
        actions.addTaskToStoreInUtc({ task: utcTask, runSort });
      }),

      addTaskToStoreInUtc: action((state, { task, runSort = false }) => {
        state.rows.push(task);
        if (runSort) {
          sortTasks(state);
        }
        state.totalRows += 1;
      }),

      updateTaskToStoreInGymTz: thunk((actions, { task, runSort = false }, { injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        const utcTask = convertDeadlineDatesToTz({ ...task }, tz);
        actions.updateTaskToStoreInUtc({ task: utcTask, runSort });
      }),

      updateTaskToStoreInUtc: action((state, { task, runSort = false }) => {
        const taskIdx = state.rows.findIndex((t) => t.id === task.id);
        if (taskIdx >= 0) {
          state.rows[taskIdx] = task;
        }
        if (runSort) {
          sortTasks(state);
        }
      }),

      removeTaskFromStore: action((state, { task }) => {
        state.rows = state.rows.filter((t) => t.id !== task.id);
      }),

      refreshList: thunk((actions, _, { getState }) => {
        const { page, rowsLimit, filter } = getState();
        return actions.fetchList({ page, limit: rowsLimit, ...filter });
      }),

      update: thunk((_, { id, patchedFields }, { injections, getState }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        const { editing } = getState();
        const deadline = {
          deadlineDate: patchedFields.deadlineDate || editing.deadlineDate,
          deadlineTime: patchedFields.deadlineTime || editing.deadlineTime,
        };
        const fields =
          patchedFields.deadlineDate || patchedFields.deadlineTime
            ? { ...patchedFields, ...convertDeadlineDatesToUtc(deadline, tz) }
            : { ...patchedFields };
        return getApi(injections, getState()).update(id, { ...fields });
      }),

      remove: thunk((_, id, { injections, getState }) => getApi(injections, getState()).remove(id)),
    };
  }
}

const convertDeadlineDatesToTz = ({ deadlineDate, deadlineTime, ...rest }, tz) => {
  const deadline = moment(
    utcDateTimeStringToTz(
      `${deadlineDate} ${deadlineTime}`,
      tz,
      `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`
    )
  );
  const result = {
    ...rest,
    deadlineDate: deadline.format(PARAMETER_DATE_ONLY_FORMAT),
    deadlineTime: deadline.format(PARAMETER_TIME_FORMAT),
  };

  return result;
};

const convertDeadlineDatesToUtc = ({ deadlineDate, deadlineTime }, tz) => {
  const deadlineDateUtc =
    deadlineDate &&
    deadlineTime &&
    moment(
      tzDateTimeStringToUtc(
        `${deadlineDate} ${deadlineTime}`,
        tz,
        `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`
      )
    );

  const result = {
    deadlineDate: deadlineDateUtc && deadlineDateUtc.format(PARAMETER_DATE_ONLY_FORMAT),
    deadlineTime: deadlineDateUtc && deadlineDateUtc.format(PARAMETER_TIME_FORMAT),
  };

  return result;
};

const sortTasks = (state) => {
  if (state.filter.sort.desc) {
    state.rows.sort((left, right) =>
      moment(`${right.deadlineDate} ${right.deadlineTime}`).diff(moment(`${left.deadlineDate} ${left.deadlineTime}`))
    );
  } else {
    state.rows.sort((left, right) =>
      moment(`${left.deadlineDate} ${left.deadlineTime}`).diff(moment(`${right.deadlineDate} ${right.deadlineTime}`))
    );
  }
};

export default TaskModelBuilder;
