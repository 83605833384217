/**
 * @deprecated use another solution based on Promise.all
 */
export class RequestAggregator {
  constructor(apiCall) {
    this.apiCall = apiCall;
    this.queuedParams = [];
  }

  queue(params) {
    this.queuedParams.push(params);
  }

  async run() {
    let results = [];
    for (const params of this.queuedParams) {
      // TODO: rewrite with Promise.all https://stackoverflow.com/a/52152930
      const { rows } = await this.apiCall(params);
      results = results.concat(rows);
    }
    const ids = results.map(({ id }) => id);
    const uniques = [...new Set(ids)];
    return uniques.map((id) => results.find(({ id: resultId }) => id === resultId));
  }
}
