import { AnyAbility } from "@casl/ability";
import cloneDeep from "lodash/cloneDeep";
import { Mutable } from "utility-types";

import { Verb } from "../permissions";
import {
  ParentRouteDefinition,
  RouteCollection,
  RouteDefinition,
} from "../routes";

export function getVisibleRoutes(
  routes: RouteCollection,
  abilityContext: AnyAbility,
): RouteCollection {
  return routes.reduce((acc, r) => {
    const route = cloneDeep(r);
    if (
      !route.invisible &&
      (typeof route.permission === "undefined" ||
        abilityContext.can(Verb.View, route.permission))
    ) {
      const routeAsParent = route as Mutable<ParentRouteDefinition>;
      if (routeAsParent?.children) {
        routeAsParent.children = getVisibleRoutes(
          routeAsParent.children,
          abilityContext,
        ) as RouteDefinition[];
        acc.push(routeAsParent);
      } else {
        acc.push(route);
      }
    }
    return acc;
  }, [] as RouteCollection);
}
