import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button, Spinner } from "reactstrap";

/**
 * @deprecated use portal/atoms/Button instead
 */
function AsyncButton({ children, onClick, ...restProps }) {
  const [processing, setProcessing] = useState(false);
  const onClickWrapper = useCallback(
    async (e) => {
      setProcessing(true);
      await onClick(e);
      setProcessing(false);
    },
    [onClick],
  );
  return (
    <Button {...restProps} onClick={onClickWrapper} disabled={restProps.disabled || processing}>
      <div className={classNames({ invisible: processing })}>{children}</div>
      <div
        className={classNames(
          { "d-none": !processing, "d-flex": processing },
          "h-100",
          "w-100",
          "align-items-center",
          "justify-content-center",
        )}
        style={{ zIndex: 2, position: "absolute", top: 0, left: 0 }}
      >
        <Spinner size="sm" />
      </div>
    </Button>
  );
}

AsyncButton.defaultProps = {
  children: null,
};

AsyncButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
};

export default AsyncButton;
