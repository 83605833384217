import {ReactElement} from 'react';

export const toLowerCaseExceptFirstChar = (value: string) =>
  value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const generateId = () =>
  Date.now().toString(36) + Math.random().toString(36).substr(2);

export const removeWhiteSpaces = (value: string) => value.replace(/\s+/g, '');

export const isValidHexColor = (value: string) =>
  /^#([0-9A-F]{3}){1,2}$/i.test(value);

export const pluralize = (singular: string, plural: string, count: number) =>
  count === 1 ? singular : plural;

export const getTextFromReactElement = (element: ReactElement): string => {
  if (!element) {
    return '';
  }
  if (typeof element === 'string') {
    return element;
  }
  const children = element?.props?.children;
  if (children instanceof Array) {
    return children.map(getTextFromReactElement).join('');
  }
  return getTextFromReactElement(children);
};
