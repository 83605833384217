import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  useRecordForm,
} from "@gymflow/common";
import {
  AppointableDtoAvailabilityType,
  AppointmentDTO,
  FacilityDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import moment from "moment-timezone";
import { useEffect } from "react";

import { ModalWrapper, useClubSettings } from "../../../providers";
import { Button, DangerIcon, DateSelect, PaginatedSelect } from "../../atoms";
import { TimeLimitSelect } from "../../organisms";
import {
  APPOINTABLE_ID,
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
  createRescheduleSchema,
  START_DATE,
} from "./AppointmentSchema";
import { RescheduleFormMapper } from "./RescheduleFormMapper";

export function AppointmentRescheduleModal({
  appointment,
  onConfirm,
  onCancel,
  newDate,
}: {
  appointment: AppointmentDTO;
  onConfirm: (newValues: {
    startDate: string;
    appointmentHostId: string;
  }) => Promise<void>;
  onCancel: () => void;
  newDate?: string;
}) {
  const { timezone } = useClubSettings();
  const schema = createRescheduleSchema(appointment.startDate, timezone);
  const { initialValues, getValues } = useRecordForm({
    fields: schema.getDefault(),
    record: appointment,
    mapper: new RescheduleFormMapper(),
  });

  return (
    <ModalWrapper onCancel={onCancel}>
      <div className="bg-primary-100 mb-3 flex h-11 w-11 self-center rounded-full p-3">
        <DangerIcon className="h-5 w-5" pathClassName="stroke-primary-600" />
      </div>
      <div>
        <div className="font-semibold">Reschedule Appointment</div>
        <div className="text-sm text-gray-600">
          Confirm details to reschedule appointment.
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          onConfirm(getValues(values));
        }}
        validationSchema={schema}
      >
        <RescheduleForm
          onCancel={onCancel}
          newDate={newDate}
          possibleHosts={appointment.appointable.appointableHosts}
          possibleFacilities={appointment.appointable.appointableFacilities}
          availabilityType={appointment.appointable.availabilityType}
        />
      </Formik>
    </ModalWrapper>
  );
}

function RescheduleForm({
  onCancel,
  newDate,
  possibleHosts,
  possibleFacilities,
  availabilityType,
}: {
  onCancel: () => void;
  newDate?: string;
  possibleHosts: UserStaffPublicDTO[];
  possibleFacilities: FacilityDTO[];
  availabilityType: AppointableDtoAvailabilityType;
}) {
  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const values = formikProps.values as Record<string, any>;
  const errors = formikProps.errors as Record<string, any>;
  const initialValues = formikProps.initialValues as Record<string, any>;

  useEffect(() => {
    if (newDate) {
      setFieldValue(START_DATE, newDate);
    }
  }, [newDate, setFieldValue]);

  return (
    <>
      <div className="mt-5 flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <label
            htmlFor={APPOINTMENT_HOST_ID}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            {availabilityType === "STAFF" ? "Select Host" : "Select Facility"}
          </label>
          <div className={classNames({ hidden: availabilityType !== "STAFF" })}>
            <PaginatedSelect
              value={
                values[APPOINTMENT_HOST_ID]?.value
                  ? values[APPOINTMENT_HOST_ID]
                  : null
              }
              onChange={function (newValue) {
                setFieldValue(APPOINTMENT_HOST_ID, newValue);
              }}
              loadOptions={function () {
                return Promise.resolve({
                  options: possibleHosts.map(({ id, firstName, lastName }) => ({
                    label: `${firstName} ${lastName}`,
                    value: id,
                  })),
                });
              }}
              cacheUniqs={[values[APPOINTABLE_ID]?.value]}
            />
          </div>
          <div
            className={classNames("text-error-700 text-sm", {
              hidden:
                availabilityType !== "STAFF" || !errors[APPOINTMENT_HOST_ID],
            })}
          >
            {errors[APPOINTMENT_HOST_ID]}
          </div>
          <div
            className={classNames({ hidden: availabilityType !== "FACILITY" })}
          >
            <PaginatedSelect
              value={
                values[APPOINTMENT_FACILITY_ID]?.value
                  ? values[APPOINTMENT_FACILITY_ID]
                  : null
              }
              onChange={function (newValue) {
                setFieldValue(APPOINTMENT_FACILITY_ID, newValue);
              }}
              loadOptions={function () {
                return Promise.resolve({
                  options: possibleFacilities.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  })),
                });
              }}
              cacheUniqs={[values[APPOINTABLE_ID]?.value]}
            />
          </div>
          <div
            className={classNames("text-error-700 text-sm", {
              hidden:
                availabilityType !== "FACILITY" ||
                !errors[APPOINTMENT_FACILITY_ID],
            })}
          >
            {errors[APPOINTMENT_FACILITY_ID]}
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label
            htmlFor={START_DATE}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            Date
          </label>
          <div>
            <DateSelect
              value={
                values[START_DATE] &&
                moment(
                  values[START_DATE],
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                ).format(DATE_FORMAT)
              }
              onChange={(newDate) => {
                const existingMoment = moment(
                  values[START_DATE],
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                );
                const newMoment = moment(
                  `${newDate} ${existingMoment.format("HH:mm")}`,
                  `${DATE_FORMAT} HH:mm`,
                );
                setFieldValue(
                  START_DATE,
                  newMoment.format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
                );
              }}
              dateFormat={DATE_FORMAT}
              dateToStartFrom={moment(
                initialValues[START_DATE],
                PARAMETER_DATE_FORMAT_WITHOUT_TZ,
              ).format(DATE_FORMAT)}
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <label
            htmlFor={START_DATE}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            Start Time
          </label>
          <div className="flex">
            <TimeLimitSelect
              className="w-100"
              value={
                values[START_DATE]
                  ? moment(
                      values[START_DATE],
                      PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                    ).format("HH:mm")
                  : "00:00"
              }
              minTime={"00:00"}
              onChange={(newTime) => {
                const existingMoment = moment(
                  values[START_DATE],
                  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
                );
                const newMoment = moment(
                  `${existingMoment.format("YYYY-MM-DD")}T${newTime}`,
                  `YYYY-MM-DDTHH:mm`,
                );
                setFieldValue(
                  START_DATE,
                  newMoment.format(PARAMETER_DATE_FORMAT_WITHOUT_TZ),
                );
              }}
            />
          </div>
        </div>
        <div
          className={classNames("text-error-700 text-sm", {
            hidden: !errors[START_DATE],
          })}
        >
          {errors[START_DATE]}
        </div>
      </div>

      <div className="mt-5 flex space-x-2">
        <Button onClick={onCancel} className="flex-1">
          Cancel
        </Button>
        <Button
          intent="primary"
          onClick={() => {
            formikProps.submitForm();
          }}
          className="flex-1"
        >
          Reschedule
        </Button>
      </div>
    </>
  );
}
