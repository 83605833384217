export function HelpCircleIcon({
  className,
  pathClassName,
}: {
  className?: string;
  pathClassName?: string;
}) {
  return (
    <svg
      className={className}
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className={pathClassName}
          d="M7.90833 7.49996C8.10425 6.94302 8.49096 6.47338 8.99996 6.17424C9.50896 5.87509 10.1074 5.76574 10.6893 5.86555C11.2712 5.96536 11.799 6.2679 12.1792 6.71957C12.5594 7.17124 12.7675 7.74289 12.7667 8.33329C12.7667 9.99996 10.2667 10.8333 10.2667 10.8333M10.3333 14.1666H10.3417M18.6667 9.99996C18.6667 14.6023 14.9357 18.3333 10.3333 18.3333C5.73096 18.3333 2 14.6023 2 9.99996C2 5.39759 5.73096 1.66663 10.3333 1.66663C14.9357 1.66663 18.6667 5.39759 18.6667 9.99996Z"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
