import { cn } from "@gymflow/helpers";
import { CheckIcon } from "@heroicons/react/24/solid";
import noop from "lodash/noop";
import omit from "lodash/omit";
import { createContext, Dispatch, useCallback, useRef, useState } from "react";

import { Button, CheckCircleIcon } from "../atoms";

export function StepWizard({
  steps,
  submitButtonText,
  onClickNext,
  onSubmit,
  initialState,
  hideStepDetails,
}: {
  steps: Step[];
  submitButtonText: string;
  onClickNext?: (params: {
    prevStepIdx: number;
    currentStepIdx: number;
    wizardState: WizardState;
  }) => Promise<void>;
  onSubmit?: (params: {
    lastStepIdx: number;
    wizardState: WizardState;
  }) => Promise<boolean>;
  initialState?: { [k: string]: any };
  hideStepDetails?: boolean;
}) {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const [wizardState, setWizardState] = useState<WizardState>({
    isWizardComplete: false,
    ...initialState,
  });

  const [isCompleted, setIsCompleted] = useState(false);

  const renderStepIcon = ({
    stepIdx,
    title,
  }: {
    stepIdx: number;
    title: string;
  }) => {
    if (stepIdx < currentStepIdx) {
      return <CompletedStepLink title={title} />;
    }
    if (stepIdx > currentStepIdx) {
      return <UncompletedStepLink title={title} id={stepIdx + 1} />;
    }
    return <CurrentStepLink title={title} id={stepIdx + 1} />;
  };
  const currentStep = steps[currentStepIdx];

  const onSubmitWrapper = useCallback(async () => {
    if (currentStep.onValidate && !currentStep.onValidate({ wizardState })) {
      setWizardState({
        ...wizardState,
        isWizardStepValid: false,
      });
      return;
    }
    const submitResult =
      onSubmit &&
      (await onSubmit({
        wizardState,
        lastStepIdx: currentStepIdx,
      }));
    if (submitResult) {
      setIsCompleted(true);
      setWizardState({
        ...wizardState,
        isWizardComplete: true,
        isWizardStepValid: true,
      });
    }
  }, [currentStep, currentStepIdx, onSubmit, wizardState]);

  const StepComponent = currentStep.component;

  const cleanStateBetweenSteps = () => {
    setWizardState(omit(wizardState, "isWizardStepValid"));
  };

  const onValidateRef = useRef<(wizardState: WizardState) => Promise<boolean>>(
    () => Promise.resolve(true),
  );

  const setOnValidate = useCallback(
    (onValidate: (wizardState: WizardState) => Promise<boolean>) => {
      onValidateRef.current = onValidate;
    },
    [],
  );

  return (
    <div className="flex h-full flex-col gap-6 overflow-y-auto">
      <nav aria-label="Progress">
        <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
          {steps.map((step, stepIdx) => (
            <li key={step.title} className="relative md:flex md:flex-1">
              {renderStepIcon({ title: step.title, stepIdx })}
              {stepIdx !== steps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="absolute right-0 top-0 hidden h-full w-5 md:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          ))}
        </ol>
      </nav>
      <div className={cn({ hidden: hideStepDetails })}>
        <div className="font-semibold">Step {currentStepIdx + 1}</div>
        <div className="text-gray-600">
          {currentStep.explanation || currentStep.title}
        </div>
      </div>
      <div className="flex flex-1 flex-col justify-between rounded border border-gray-200">
        <WizardContext.Provider
          value={{
            wizardState,
            setWizardState,
            setOnValidate,
          }}
        >
          <div className="p-5">
            <StepComponent {...currentStep.props} />
          </div>
          <div className="flex justify-end border-t border-gray-200 px-5 py-3">
            <Button
              className={cn({ invisible: currentStepIdx === 0 })}
              onClick={() => {
                setCurrentStepIdx(currentStepIdx - 1);
                cleanStateBetweenSteps();
              }}
              disabled={isCompleted}
            >
              Previous
            </Button>
            <Button
              intent="primary"
              className={cn("ml-2 mt-0 min-w-[6.25rem]", {
                hidden: currentStepIdx === steps.length - 1,
              })}
              onClick={async () => {
                if (
                  currentStep.onValidate &&
                  !currentStep.onValidate({ wizardState })
                ) {
                  setWizardState({
                    ...wizardState,
                    isWizardStepValid: false,
                  });
                  return;
                }
                const passedValidate = await onValidateRef.current(wizardState);
                if (!passedValidate) {
                  setWizardState({
                    ...wizardState,
                    isWizardStepValid: false,
                  });
                  return;
                }
                onClickNext &&
                  (await onClickNext({
                    wizardState,
                    currentStepIdx: currentStepIdx + 1,
                    prevStepIdx: currentStepIdx,
                  }));
                setCurrentStepIdx(currentStepIdx + 1);
                cleanStateBetweenSteps();
              }}
              disabled={isCompleted}
            >
              Next
            </Button>
            <Button
              intent="primary"
              className={cn("ml-2 mt-0 min-w-[6.25rem]", {
                hidden: currentStepIdx < steps.length - 1,
              })}
              onClick={onSubmitWrapper}
              disabled={isCompleted}
            >
              {isCompleted ? (
                <div className="flex items-center">
                  <CheckCircleIcon pathClassName="stroke-white" />
                  <div className="ml-2">Completed</div>
                </div>
              ) : (
                submitButtonText
              )}
            </Button>
          </div>
        </WizardContext.Provider>
      </div>
    </div>
  );
}

StepWizard.defaultProps = {
  submitButtonText: "Submit",
};

function CompletedStepLink({ title }: Omit<StepLinkProps, "id">) {
  return (
    <div className="group flex w-full items-center">
      <span className="flex items-center px-6 py-4 text-sm font-medium">
        <span className="bg-primary-600 group-hover:bg-primary-800 flex h-10 w-10 shrink-0 items-center justify-center rounded-full">
          <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </span>
        <span className="ml-4 text-sm font-medium text-gray-900">{title}</span>
      </span>
    </div>
  );
}

function CurrentStepLink({ title, id }: StepLinkProps) {
  return (
    <div
      className="flex items-center px-6 py-4 text-sm font-medium"
      aria-current="step"
    >
      <span className="border-primary-600 flex h-10 w-10 shrink-0 items-center justify-center rounded-full border-2">
        <span className="text-primary-600">{id}</span>
      </span>
      <span className="text-primary-600 ml-4 text-sm font-medium">{title}</span>
    </div>
  );
}

function UncompletedStepLink({ title, id }: StepLinkProps) {
  return (
    <div className="group flex items-center">
      <span className="flex items-center px-6 py-4 text-sm font-medium">
        <span className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
          <span className="text-gray-500 group-hover:text-gray-900">{id}</span>
        </span>
        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
          {title}
        </span>
      </span>
    </div>
  );
}

export interface WizardState {
  readonly isWizardComplete?: boolean;
  readonly isWizardStepValid?: boolean;
  readonly [k: string]: any;
}

export const WizardContext = createContext<{
  wizardState: WizardState;
  setWizardState: Dispatch<WizardState>;
  setOnValidate: (
    onValidate: (wizardState: WizardState) => Promise<boolean>,
  ) => void;
}>({
  wizardState: {},
  setWizardState: noop,
  setOnValidate: () => {},
});

interface StepLinkProps {
  title: string;
  id: number;
}

interface Step {
  readonly title: string;
  readonly explanation?: string;
  readonly component: (props?: any) => JSX.Element | null;
  readonly props?: { readonly [k: string]: any };
  readonly onValidate?: (params: { wizardState: WizardState }) => boolean;
}
