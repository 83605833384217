import { FormMapper } from "@gymflow/common";
import { AppointmentDTO } from "@gymflow/types";
import pick from "lodash/pick";

import {
  APPOINTMENT_FACILITY_ID,
  APPOINTMENT_HOST_ID,
} from "./AppointmentSchema";

export class RescheduleFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: "host",
          transform: (v: {
            id: string;
            firstName: string;
            lastName: string;
          }) => ({
            value: v.id,
            label: `${v.firstName} ${v.lastName}`,
          }),
        },
      ],
      outValue: [
        {
          key: APPOINTMENT_HOST_ID,
          transform: (value: any) => value?.value,
        },
        {
          key: APPOINTMENT_FACILITY_ID,
          transform: (value: any) => value?.value,
        },
      ],
    });
  }

  override to(values: AppointmentDTO) {
    const parsed: Record<string, any> = super.to(values);

    const host = parsed["appointment-host"];
    if (host) {
      parsed["appointment-host-id"] = {
        value: host.id,
        label: `${host.firstName} ${host.lastName}`,
      };
    }

    const facility = parsed["appointment-facility"];
    if (facility) {
      parsed["appointment-facility-id"] = {
        value: facility.id,
        label: facility.name,
      };
    }

    parsed["availability-type"] = values.appointable.availabilityType;

    return pick(parsed, [
      "appointment-host-id",
      "appointment-facility-id",
      "start-date",
      "availability-type",
    ]);
  }

  override from(values: Record<string, any>) {
    const parsed: any = super.from(values);
    if (parsed.availabilityType === "STAFF") {
      delete parsed["appointmentFacilityId"];
    } else if (parsed.availabilityType === "FACILITY") {
      delete parsed["appointmentHostId"];
    }
    delete parsed["availabilityType"];
    return parsed;
  }
}
