import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { kisiQueryKeys } from "./kisiQueryKeys";

export function useMutationKisiUpdate(
  {
    api,
  }: {
    api: {
      kisiApi: {
        modify: (args: {
          clubId: number;
          membershipId?: number;
          facilityId?: number;
          kisiGroupId: string | null;
        }) => Promise<any>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    void,
    unknown,
    {
      clubId: number;
      membershipId?: number;
      kisiGroupId: string | null;
      facilityId?: number;
    }
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      clubId,
      membershipId,
      facilityId,
      kisiGroupId,
    }: {
      clubId: number;
      membershipId?: number;
      facilityId?: number;
      // Sending a null kisiGroupId removes the membership mapping
      kisiGroupId: string | null;
    }) => {
      await api.kisiApi.modify({
        clubId,
        membershipId,
        kisiGroupId,
        facilityId,
      });
    },
    onSuccess: (_, { clubId }) => {
      queryClient.invalidateQueries({ queryKey: kisiQueryKeys.details() });
      queryClient.invalidateQueries({
        queryKey: kisiQueryKeys.groupMappings(clubId),
      });
    },
    ...mutationOpts,
  });
  return mutation;
}
