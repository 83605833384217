import {
  getAboveNode,
  getPluginType,
  isCollapsed,
  unwrapNodes,
} from "@udecode/plate";

import { upsertLinkAtSelection } from "./upsertLinkAtSelection";

const ELEMENT_LINK = "a";
export const getAndUpsertLink: any = async (editor: any, getLinkUrl?: any) => {
  const type = getPluginType(editor, ELEMENT_LINK);
  let prevUrl = "";

  const linkNode = getAboveNode(editor, {
    match: { type },
  });
  if (linkNode) {
    prevUrl = (linkNode[0] as any).url as string;
  }

  let url;
  if (getLinkUrl) {
    url = await getLinkUrl(prevUrl);
  } else {
    url = window.prompt(`Enter the URL of the link:`, prevUrl);
  }

  if (!url) {
    linkNode &&
      editor.selection &&
      unwrapNodes(editor, {
        at: editor.selection,
        match: { type: getPluginType(editor, ELEMENT_LINK) },
      });

    return;
  }

  // If our cursor is in middle of a link, then we don't want to insert it inline
  const shouldWrap: boolean =
    linkNode !== undefined && isCollapsed(editor.selection);
  upsertLinkAtSelection(editor, { url, wrap: shouldWrap });
};
