import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useQuery } from "@tanstack/react-query";

import { memberQueryKeys } from "./memberQueryKeys";

export async function memberSearchByFullNameQueryFn({
  api,
  searchTerm,
  page,
  limit,
}: {
  api: ApiType;
  searchTerm: string;
  page: number;
  limit: number;
}) {
  const result = await api.memberApi.findByFullName(searchTerm, {
    page,
    limit,
  });
  return result.data;
}

export function useMemberSearchByFullName({
  api,
  name,
  page,
  limit,
}: {
  api: ApiType;
  name: string;
  page: number;
  limit: number;
}) {
  const result = useQuery({
    queryKey: memberQueryKeys.searchByFullName(name, page, limit),
    queryFn: () => {
      return memberSearchByFullNameQueryFn({
        api,
        searchTerm: name,
        page,
        limit,
      });
    },
    initialData: [],
    enabled: !!name,
  });
  return result;
}
