import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime } from "@gymflow/helpers";
import { MembershipReportDTO, ReportMemberDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";
import merge from "lodash/merge";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export function useMembershipPendingReport({
  api,
  page,
  limit,
  sort,
  tz,
}: {
  api: ApiType;
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.membershipPending({
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const result = await api.memberApi.membershipPending({
        page,
        limit,
        sort,
      });
      return result.data as MembershipReportDTO;
    },
    placeholderData: () => {
      return defaultPage<ReportMemberDTO>();
    },
    select: (data) => {
      const dto = merge({}, data, {
        content: data.content.map(({ startDate, ...rest }) => {
          return {
            startDate: startDate && utcToZonedTime(startDate, tz),
            ...rest,
          };
        }),
      });
      return dto;
    },
    enabled: !!tz,
  });
  return result;
}
