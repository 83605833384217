import { DATE_FORMAT, PARAMETER_DATE_FORMAT_WITHOUT_TZ } from "@gymflow/common";
import { getDateTimeInGym } from "apps/portal/src/helpers/date";
import moment from "moment-timezone";
import * as Yup from "yup";

import { YupMixedBuilder } from "../../../helpers/yupBuilders";
import { MonthlyRecurringType } from "./MonthlyRadio";

export const APPOINTMENT_USER_ID = "appointment-user-id";
export const APPOINTABLE_ID = "appointable-id";
export const APPOINTMENT_HOST_ID = "appointment-host-id";
export const APPOINTMENT_FACILITY_ID = "appointment-facility-id";
export const START_DATE = "start-date";

export const createUserSchemaFields = () => {
  return {
    [APPOINTMENT_USER_ID]: Yup.object()
      .shape({
        value: Yup.mixed(),
        label: Yup.string(),
      })
      .required()
      .default(null),
  };
};

const hostDefinition = new YupMixedBuilder()
  .isRequiredIfFieldValueIs(
    Yup.ref("availability-type"),
    "STAFF",
    "Host trainer is required.",
  )
  .build()
  .default(null);

export const createServiceDetailsSchemaFields = () => {
  const schemaFields: {
    "appointable-id": any;
    "appointment-host-id"?: any;
    "appointment-facility-id"?: any;
  } = {
    [APPOINTABLE_ID]: Yup.object()
      .shape({ value: Yup.number(), label: Yup.string() })
      .required()
      .default(null),
    [APPOINTMENT_HOST_ID]: hostDefinition.nullable(),
    [APPOINTMENT_FACILITY_ID]: new YupMixedBuilder()
      .isRequiredIfFieldValueIs(
        Yup.ref("availability-type"),
        "FACILITY",
        "Host facility is required.",
      )
      .build()
      .default(null),
  };

  return schemaFields;
};

export const createDateAndTimeSchemaFields = (
  date: string,
  timezone: string,
) => {
  return {
    [START_DATE]: Yup.string()
      .required()
      .default(date)
      .test(function (value) {
        if (value && this.parent[RECURRING_TYPE]) {
          const nowInGym = getDateTimeInGym(timezone);

          let valueMoment = moment(value, PARAMETER_DATE_FORMAT_WITHOUT_TZ);
          if (this.parent[RECURRING_TIME]) {
            valueMoment = moment(
              `${valueMoment.format(DATE_FORMAT)} ${
                this.parent[RECURRING_TIME]
              }`,
              `${DATE_FORMAT} HH:mm`,
            );
          }
          if (valueMoment.isBefore(nowInGym)) {
            return this.createError({
              message:
                "Recurring appointment start dates cannot be in the past.",
            });
          }
        }
        return true;
      }),
  };
};

export const RECURRING_TYPE = "recurring-type";
export const RECURRING_INTERVAL = "recurring-interval";
export const RECURRING_WEEKLY_DAYS = "weekly-days";
export const RECURRING_MONTHLY_DAY_OF_MONTH = "monthly-day-of-month";
export const RECURRING_MONTHLY_DAY_POSITION = "monthly-day-position";
export const RECURRING_MONTHLY_DAY_OF_WEEK = "monthly-day-of-week";
export const RECURRING_END_DATE = "end-date";
export const RECURRING_TIME = "appointment-time";

export const createRecurrenceFields = () => {
  const dayOfWeekField = Yup.string().oneOf([
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ]);

  return {
    [RECURRING_TYPE]: Yup.string()
      .oneOf(["DAILY", "WEEKLY", "MONTHLY"])
      .default("DAILY"),
    [RECURRING_INTERVAL]: Yup.number().required(),
    [RECURRING_WEEKLY_DAYS]: Yup.array().of(dayOfWeekField),
    [RECURRING_MONTHLY_DAY_OF_MONTH]: Yup.number(),
    [RECURRING_MONTHLY_DAY_POSITION]: Yup.string().oneOf([
      "FIRST",
      "SECOND",
      "THIRD",
      "FOURTH",
      "FIFTH",
      "LAST",
    ]),
    [RECURRING_MONTHLY_DAY_OF_WEEK]: dayOfWeekField,
    [RECURRING_END_DATE]: Yup.string().test(function (value) {
      if (value) {
        const startMoment = moment(
          this.parent[START_DATE],
          PARAMETER_DATE_FORMAT_WITHOUT_TZ,
        );
        const valueMoment = moment(value, DATE_FORMAT).endOf("day");

        if (valueMoment.isBefore(startMoment)) {
          return this.createError({
            message: "End date must be after start date.",
          });
        }
      }
      return true;
    }),
  };
};

export const createAppointmentSchema = ({
  date,
  isRecurring,
  timezone,
}: {
  date: string;
  isRecurring?: boolean;
  timezone: string;
}) => {
  let schema = Yup.object()
    .shape(createUserSchemaFields())
    .shape(createServiceDetailsSchemaFields())
    .shape(createDateAndTimeSchemaFields(date, timezone));
  if (isRecurring) {
    schema = schema.shape(createRecurrenceFields());
  }
  return schema;
};

export const createRescheduleSchema = (date: string, timezone: string) => {
  return Yup.object()
    .shape({
      [APPOINTMENT_HOST_ID]: hostDefinition,
    })
    .shape(createDateAndTimeSchemaFields(date, timezone));
};

export const createRescheduleRecurringSchema = (
  date: string,
  timezone: string,
) => {
  return Yup.object()
    .shape({
      [APPOINTMENT_HOST_ID]: hostDefinition,
      "recurrence-monthly-type": Yup.string().oneOf([
        "DAY_OF_THE_EVENT",
        "SPECIFIC_DAY",
      ] satisfies MonthlyRecurringType[]),
    })
    .shape(createDateAndTimeSchemaFields(date, timezone))
    .shape(createRecurrenceFields());
};

export const recurringOptions = [
  { label: "Day", value: "DAILY" },
  { label: "Week", value: "WEEKLY" },
  { label: "Month", value: "MONTHLY" },
];
