import { createContext } from "react";

export const SidebarToggleContext = createContext<{
  sidebarOpened: boolean;
  toggleSidebar: () => void;
  hideSidebar: () => void;
  showSidebar: () => void;
}>({
  sidebarOpened: false,
  toggleSidebar: () => {},
  hideSidebar: () => {},
  showSidebar: () => {},
});
