import { cn } from "@gymflow/helpers";
import React, { ReactNode } from "react";

import { FormLabel } from "./FormLabel";

export interface LabeledFormProps {
  label: string;
  tooltip?: string;
  children: ReactNode;
  className?: string;
}

export const LabeledForm: React.FC<LabeledFormProps> = ({
  children,
  label,
  tooltip,
  className,
}) => {
  return (
    <div className={cn("flex flex-col gap-y-1", className)}>
      <FormLabel label={label} tooltip={tooltip} />
      {children}
    </div>
  );
};
