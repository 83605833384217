import { useQueryClient, useMutation } from '@tanstack/react-query';

import { leadSourceQueryKeys } from './leadSourceQueryKeys';

export function useLeadSourceDisable({
  api,
}: {
  api: {
    leadSourceApi: {
      deactivate: (arg0: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (leadSourceId: number) => {
      await api.leadSourceApi.deactivate(leadSourceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadSourceQueryKeys.all() });
    },
  });

  return mutation;
}
