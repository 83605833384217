import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { utcToZonedTime, zonedTimeToUtc } from "@gymflow/helpers";
import {
  ApiListResponse,
  MembershipResumingDTO,
  MembershipType,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { reportQueryKeys } from "./reportQueryKeys";

export function useMembershipResumingReport({
  api,
  startDate,
  endDate,
  membershipIncluded,
  membershipExcluded,
  membershipType,
  page,
  limit,
  sort,
  tz,
}: {
  api: ApiType;
  startDate?: string;
  endDate?: string;
  membershipIncluded?: number[];
  membershipExcluded?: number[];
  membershipType?: MembershipType;
  page?: number;
  limit?: number;
  sort?: any;
  tz: string;
}) {
  const result = useQuery({
    queryKey: reportQueryKeys.membershipResuming({
      startDate,
      endDate,
      membershipIncluded,
      membershipExcluded,
      membershipType,
      page,
      limit,
      sort,
    }),
    queryFn: async () => {
      const utcStart = zonedTimeToUtc(startDate as string, tz);
      const utcEnd = zonedTimeToUtc(endDate as string, tz);
      const result = await api.memberApi.membershipResuming({
        dateFrom: utcStart,
        dateTo: utcEnd,
        membershipIncluded,
        membershipExcluded,
        membershipType,
        page,
        limit,
        sort,
      });
      return result.data as ApiListResponse<MembershipResumingDTO>;
    },
    placeholderData: defaultPage<MembershipResumingDTO>,
    select: (data: ApiListResponse<MembershipResumingDTO>) => {
      return {
        ...data,
        content: data.content.map(({ pauseDate, resumeDate, ...rest }) => {
          return {
            pauseDate: pauseDate && utcToZonedTime(pauseDate, tz),
            resumeDate: resumeDate && utcToZonedTime(resumeDate, tz),
            ...rest,
          };
        }),
      } as ApiListResponse<MembershipResumingDTO>;
    },
    enabled: !!startDate && !!endDate && !!tz,
  });
  return result;
}
