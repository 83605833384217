export type BankIconProps = {
  className?: string;
  pathClassName?: string;
};

export const BankIcon = ({ className, pathClassName }: BankIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      className={className}
    >
      <path
        d="M3.33333 8.49986V17.8332M8.58333 8.49986V17.8332M14.4167 8.49986V17.8332M19.6667 8.49986V17.8332M1 19.6999L1 20.6332C1 21.2866 1 21.6133 1.12716 21.8628C1.23901 22.0824 1.41749 22.2608 1.63701 22.3727C1.88657 22.4999 2.21327 22.4999 2.86667 22.4999H20.1333C20.7867 22.4999 21.1134 22.4999 21.363 22.3727C21.5825 22.2608 21.761 22.0824 21.8728 21.8628C22 21.6133 22 21.2866 22 20.6332V19.6999C22 19.0465 22 18.7198 21.8728 18.4702C21.761 18.2507 21.5825 18.0722 21.363 17.9604C21.1134 17.8332 20.7867 17.8332 20.1333 17.8332H2.86667C2.21327 17.8332 1.88657 17.8332 1.63701 17.9604C1.41749 18.0722 1.23901 18.2507 1.12716 18.4702C1 18.7198 1 19.0465 1 19.6999ZM11.0951 1.58985L2.46173 3.50836C1.94016 3.62427 1.67937 3.68222 1.48471 3.82246C1.31301 3.94616 1.17818 4.11424 1.09467 4.30869C1 4.52914 1 4.79629 1 5.33058L1 6.63319C1 7.28659 1 7.61328 1.12716 7.86285C1.23901 8.08237 1.41749 8.26085 1.63701 8.3727C1.88657 8.49986 2.21327 8.49986 2.86667 8.49986H20.1333C20.7867 8.49986 21.1134 8.49986 21.363 8.3727C21.5825 8.26085 21.761 8.08237 21.8728 7.86285C22 7.61329 22 7.28659 22 6.63319V5.33058C22 4.79629 22 4.52914 21.9053 4.30869C21.8218 4.11424 21.687 3.94617 21.5153 3.82247C21.3206 3.68222 21.0598 3.62427 20.5383 3.50837L11.9049 1.58985C11.7538 1.55627 11.6783 1.53948 11.6019 1.53278C11.5341 1.52683 11.4659 1.52683 11.3981 1.53278C11.3217 1.53948 11.2462 1.55627 11.0951 1.58985Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={pathClassName}
      />
    </svg>
  );
};
