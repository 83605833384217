import { AxiosAuthenticationLoader } from "@gymflow/api";
import { ReactNode, useCallback, useEffect, useState } from "react";

import { AuthenticatedUserContext, AuthState } from "./AuthenticatedProvider";

export function AuthenticationSupportedProvider({
  authController,
  children,
}: {
  authController: AxiosAuthenticationLoader;
  children: ReactNode;
}) {
  const [user, setUser] = useState<AuthState>({
    isLoggedIn: false,
    roles: [],
  });

  const loadAuth = useCallback(async () => {
    if (!authController) {
      return;
    }
    const isLoggedIn = await authController.loadAuthentication();
    const id = await authController.getUserId();
    const roles = await authController.getRoles();
    setUser({ isLoggedIn, id, roles });
  }, [authController]);

  useEffect(() => {
    loadAuth();
  }, [loadAuth]);

  return (
    <AuthenticatedUserContext.Provider
      value={{
        id: user.id,
        login: async (username: string, password: string) => {
          if (!authController?.login) {
            return { isLoggedIn: false, roles: [] };
          }
          let userId, roles;
          try {
            await authController?.login(username, password);
            userId = await authController.getUserId();
            roles = await authController.getRoles();
            setUser({ id: userId, isLoggedIn: true, roles });
            loadAuth();
          } catch {
            return { isLoggedIn: false, roles: [] };
          }
          return { isLoggedIn: true, id: userId, roles };
        },
        logout: async () => {
          await authController?.logout();
          setUser({ isLoggedIn: false, roles: [] });
        },
        refreshRoles: authController?.getRoles,
        roles: user.roles ?? [],
      }}
    >
      {children}
    </AuthenticatedUserContext.Provider>
  );
}
