import { action, thunk } from 'easy-peasy';
import moment from 'moment-timezone';
import BaseModelBuilder, { getApi, createPayloadFromFindResponse } from './BaseModelBuilder';
import { DATE_FORMAT } from '../helpers/form';
import { PARAMETER_DATE_ONLY_FORMAT } from '../api/eventApi';

class PromotionModelBuilder extends BaseModelBuilder {
  constructor(apiKey, membershipApiKey, creditPackApiKey, productApiKey, settingsKey = 'settings', clubId) {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;
    this.membershipApiKey = membershipApiKey;
    this.creditPackApiKey = creditPackApiKey;
    this.productApiKey = productApiKey;
    this.withActiveInactive();
    this.generators.push(this.promotionGenerator);
  }

  /**
   * @private
   */
  promotionGenerator() {
    return {
      productionsSpecificItems: [],
      create: thunk((_, fields, { injections, getState }) => {
        return getApi(injections, getState()).create({
          ...fields,
          expiry: fields.expiry && moment(fields.expiry, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
        });
      }),

      update: thunk((_, { id, patchedFields }, { injections, getState }) => {
        return getApi(injections, getState()).update(id, {
          ...patchedFields,
          expiry: patchedFields?.expiry && moment(patchedFields.expiry, DATE_FORMAT).format(PARAMETER_DATE_ONLY_FORMAT),
        });
      }),

      fetchList: thunk(async (actions, { page = 0, limit = 10, sort, extraParams }, { getState, injections }) => {
        const { data } = await getApi(injections, getState()).find({
          page,
          limit,
          sort,
          extraParams: {
            ...extraParams,
            clubId: this.clubId,
          },
        });
        const payload = createPayloadFromFindResponse(data);
        actions.fetchedList(payload);
        return payload;
      }),
      fetchPromotionSpecificItems: thunk(async (actions, payload, { injections }) => {
        const [products, memberships, credits] = await Promise.all(
          [this.productApiKey, this.membershipApiKey, this.creditPackApiKey].map(async (item) => {
            const { data } = await injections.api[item].findByClubId(this.clubId, {
              page: 0,
              limit: 1000,
              extraParams: {
                unpage: true,
              },
            });
            return data?.content?.map(({ id, name }) => ({ id, name, specificItemType: item }));
          })
        );

        const items = products.concat(memberships).concat(credits);
        actions.fetchedPromotionSpecificItems(items);
        return payload;
      }),

      fetchedPromotionSpecificItems: action((state, items) => {
        state.productionsSpecificItems = items;
      }),
    };
  }
}

export default PromotionModelBuilder;
