import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, Spinner } from "reactstrap";
import styled from "styled-components";

/**
 * @deprecated rewrite in tailwind
 */
const OverlayButton = styled.div`
  margin-top: -27px;
  min-width: 50px;
  color: #6186db;
  font-weight: 700;
  padding-left: 8px;
  padding-right: 8px;
  border-left: 1px solid rgba(58, 58, 63, 0.5);
  display: flex;
  cursor: pointer;
  position: absolute;
  right: 5px;

  &:hover {
    color: #31436e;
  }
`;

function PromoCodeInput({ isLoading, onChange, value }) {
  const [inputValue, setInputValue] = useState("");
  const onButtonClick = useCallback(() => {
    if (value) {
      onChange("");
    } else {
      onChange(inputValue);
    }
  }, [onChange, inputValue, value]);

  const renderButtonText = () => {
    if (isLoading) {
      return <Spinner color="primary" size="sm" className="ml-auto mr-auto" />;
    }
    if (value) {
      return "Remove";
    }
    return "Apply";
  };

  useEffect(() => {
    if (value === "") {
      setInputValue("");
    }
  }, [value]);

  return (
    <>
      <Input
        type="text"
        maxLength="50"
        style={{ textTransform: "uppercase" }}
        value={inputValue}
        onChange={({ target: { value } }) => setInputValue(value)}
      />
      <OverlayButton onClick={onButtonClick}>
        {renderButtonText()}
      </OverlayButton>
    </>
  );
}

PromoCodeInput.defaultProps = {
  isLoading: false,
};

PromoCodeInput.propTypes = {
  value: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default PromoCodeInput;
