import { removeWhiteSpaces } from "@gymflow/helpers";
import * as Yup from "yup";

import gender from "../../../constants/gender";

export const Field = {
  EMAIL: "email",
  FIRST_NAME: "first-name",
  LAST_NAME: "last-name",
  MOBILE_NUMBER: "mobile-number",
  DATE_OF_BIRTH: "date-of-birth",
  POST_CODE: "post-code",
  ADDRESS1: "address1",
  ADDRESS2: "address2",
  CITY: "city",
  NATIONALITY: "country",
  GENDER: "gender",
  EMAIL_COMMUNICATION: "email-communication",
  SMS_COMMUNICATION: "sms-communication",
  PUSH_COMMUNICATION: "push-communication",
  EMERGENCY_CONTACT_NAME: "emergency-contact-name",
  EMERGENCY_CONTACT: "emergency-contact",
  SOURCE: "source",
  IS_CLUB_WAIVER_ACCEPTED: "is-club-waiver-accepted",
  PERSONAL_NUMBER: "personal-number",
};

const createSchema = ({
  postCodeFormat,
  defaultNationality,
  dateFormat,
  validEmails = [],
  findByEmail,
  requiredFields,
  mustAgreeToClubWaiver = true,
}) => {
  const email = Yup.string()
    .email()
    .required()
    .test(async function (value) {
      if (validEmails.includes(value)) {
        return true;
      }

      const { createError } = this;

      if (!value) {
        return false;
      }

      try {
        await Yup.reach(
          Yup.object().shape({ email: Yup.string().email() }),
          "email",
        ).validate(value);
      } catch {
        return false;
      }

      const data = await findByEmail(value);
      return data ? createError({ message: data }) : true;
    });

  const schema = {
    [Field.EMAIL]: email,
    [Field.FIRST_NAME]: Yup.string().trim().required().min(2),
    [Field.LAST_NAME]: Yup.string().trim().required().min(2),
    [Field.NATIONALITY]: Yup.string()
      .trim()
      .required()
      .min(3)
      .max(3)
      .default(defaultNationality),
    [Field.SOURCE]: Yup.object()
      .typeError("Field is required.")
      .required()
      .default(null),
    [Field.EMAIL_COMMUNICATION]: Yup.boolean().default(false),
    [Field.SMS_COMMUNICATION]: Yup.boolean().default(false),
    [Field.PUSH_COMMUNICATION]: Yup.boolean().default(false),
  };

  if (mustAgreeToClubWaiver) {
    schema[Field.IS_CLUB_WAIVER_ACCEPTED] = Yup.boolean()
      .required()
      .oneOf([true], "You must accept the club's waiver.");
  } else {
    schema[Field.IS_CLUB_WAIVER_ACCEPTED] = Yup.boolean();
  }

  if (requiredFields.mobileNumber) {
    schema[Field.MOBILE_NUMBER] = Yup.string().required().phoneNumber();
  }

  if (requiredFields.dateOfBirth) {
    schema[Field.DATE_OF_BIRTH] = Yup.date()
      .format(dateFormat, true)
      .required();
  }

  if (requiredFields.addressLine) {
    schema[Field.ADDRESS1] = Yup.string().trim().required().min(3);
    schema[Field.ADDRESS2] = Yup.string().trim();
  }

  if (requiredFields.postCode) {
    schema[Field.POST_CODE] = Yup.string().required().postCode(postCodeFormat);
  }

  if (requiredFields.city) {
    schema[Field.CITY] = Yup.string().trim().required().min(3);
  }

  if (requiredFields.gender) {
    schema[Field.GENDER] = Yup.string().required();
  }

  if (requiredFields.emergencyContact) {
    schema[Field.EMERGENCY_CONTACT_NAME] = Yup.string().required().min(3);
    schema[Field.EMERGENCY_CONTACT] = Yup.string()
      .required()
      .phoneNumber()
      .test(function (value) {
        const { createError } = this;
        const errorMessage =
          "Emergency contact cannot be the same as the Phone Number field.";
        return (
          removeWhiteSpaces(this.parent["mobile-number"] || "") !==
            removeWhiteSpaces(value || "") ||
          createError({ message: errorMessage })
        );
      });
  }

  if (requiredFields.personalNumber) {
    schema[Field.PERSONAL_NUMBER] = Yup.string().trim().required().max(25);
  }

  return Yup.object().shape(schema);
};

export default createSchema;
