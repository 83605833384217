import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "../availability";
import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useAppointmentCreate({
  api,
  tz,
}: {
  api: ApiType;
  tz?: string;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      newAppointment: Parameters<ApiType["appointmentApi"]["create"]>[0],
    ) => {
      const response = await api.appointmentApi.create({
        ...newAppointment,
        startDate: zonedTimeToUtc(newAppointment.startDate, tz!),
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailability(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(),
      });
    },
  });

  return mutation;
}
