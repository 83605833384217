import { useMutation, useQueryClient } from '@tanstack/react-query';
import { staffQueryKeys } from './staffQueryKeys';

export function useStaffCreate({
  api,
}: {
  api: {
    staffApi: {
      create: (arg1: unknown) => Promise<any>;
      updatePicture: (arg0: string, arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newStaff: { [k: string]: any }) => {
      const newFields = { ...newStaff };

      delete newFields['picture'];

      const result = await api.staffApi.create(newFields);

      if (newStaff['picture'] && newStaff['picture'].blob) {
        await api.staffApi.updatePicture(result.data.id, newStaff['picture']);
      }
      return result;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: staffQueryKeys.all() });
    },
  });

  return mutation;
}
