import React from 'react';
import { Button, Container, Col, Row } from 'reactstrap';
import { useStoreActions } from 'easy-peasy';
import useGymflowModels from '../../store';


function Footer() {
  const { authStore } = useGymflowModels();
  const { logout } = useStoreActions(authStore);
  return (
    <footer className="footer footer-default ml-1 mr-1">
      <Container fluid>
        <Row className="gym-footer">
          <Col className="text-left">
            Powered by{' '}
            <a href="https://www.gymflow.io" target="_blank">
              Gymflow.io
            </a>
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Button className="btn-link" onClick={() => logout()}>
              Logout
            </Button>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
