import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationLeadStatusOrder(
  {
    api,
  }: {
    api: {
      leadApi: {
        editStatusColumnOrder: (
          statusId: number,
          statusOrder: number,
        ) => Promise<void>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<
    unknown,
    unknown,
    { statusId: number; statusOrder: number }
  >,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      statusId,
      statusOrder,
    }: {
      statusId: number;
      statusOrder: number;
    }) => api.leadApi.editStatusColumnOrder(statusId, statusOrder),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return mutation;
}
