import {
  AvailabilityOverrideDTO,
  AvailabilityOverridePostDTO,
} from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { availabilityQueryKeys } from "./availabilityQueryKeys";

export function useMutationFacilityAvailabilityOverrideEdit({
  api,
}: {
  api: {
    availabilityApi: {
      updateFacilityOverride: (
        facilityId: number,
        overrideId: number,
        patchedFields: unknown,
      ) => Promise<{ data: AvailabilityOverrideDTO }>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async ({
      patchedFields,
      availabilityOverrideId,
      facilityId,
    }: {
      patchedFields: Partial<AvailabilityOverridePostDTO>;
      availabilityOverrideId: number;
      facilityId: number;
    }) => {
      const response = await api.availabilityApi.updateFacilityOverride(
        facilityId,
        availabilityOverrideId,
        patchedFields,
      );
      return response.data;
    },
    onSuccess: (_, { facilityId }) => {
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.allFacilityOverrides(facilityId),
      });
    },
  });

  return mutation;
}
