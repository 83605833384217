import { useReducer } from 'react';
import { ProductStockType } from '@gymflow/common';

const Action = {
  AddProduct: Symbol('AddProductAction'),
  RemoveProduct: Symbol('RemoveProductAction'),
  ChangeProductQuantity: Symbol('ChangeProductQuantity'),
  SetProducts: Symbol('SetProducts'),
};

const canAddProductToExisting = (product) => {
  if (
    product.stockType === ProductStockType.Limited &&
    (product.stockOnHand === 0 || product.quantity >= product.stockOnHand)
  ) {
    return false;
  }

  return true;
};

const addProductAction = (state, payload) => {
  const existing = state.products.find((p) => p.id === payload.product.id);
  if (existing && canAddProductToExisting(existing)) {
    existing.quantity += payload?.quantity || 1;
  } else if (!existing && canAddProductToExisting(payload.product)) {
    state.products.push({ quantity: payload?.quantity || 1, ...payload.product });
  }
  return { ...state };
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case Action.AddProduct: {
      return addProductAction(state, payload);
    }
    case Action.RemoveProduct: {
      return { ...state, products: state.products.filter((p) => p.id !== payload.id) };
    }
    case Action.ChangeProductQuantity: {
      const existing = state.products.find((p) => p.id === payload.product.id);
      if (existing) {
        existing.quantity = payload.quantity;
        return { ...state };
      }
      return addProductAction(state, payload);
    }
    case Action.SetProducts:
      return { ...state, products: payload };
    default:
      throw Error('Unknown reducer action.');
  }
};

const useCartStore = () => {
  const [state, dispatch] = useReducer(reducer, { products: [] });

  return {
    addProduct: (product) => dispatch({ type: Action.AddProduct, payload: product }),
    removeProduct: (product) => dispatch({ type: Action.RemoveProduct, payload: product }),
    changeProductQuantity: ({ product, quantity }) =>
      dispatch({
        type: Action.ChangeProductQuantity,
        payload: { quantity, product },
      }),
    setProducts: (products) => dispatch({ type: Action.SetProducts, payload: products }),
    ...state,
  };
};

export default useCartStore;
