import { facilityListQueryFn, staffListQueryFn } from "@gymflow/api";
import { FormMapper, useRecordForm } from "@gymflow/common";
import { generateDurationOptions } from "@gymflow/helpers";
import {
  AppointableDtoAvailabilityType,
  FacilityDTO,
  UserStaffPublicDTO,
} from "@gymflow/types";
import { Formik, useFormikContext } from "formik";
import noop from "lodash/noop";
import { useContext, useEffect, useMemo } from "react";
import * as Yup from "yup";

import { cn } from "../../../helpers/cn";
import useGymflowModels from "../../../store";
import {
  Avatar,
  CheckCircleIcon,
  eagerSelectAll,
  PaginatedSelect,
  renderMultiSelectPlaceholder,
  Select,
  Switch,
} from "../../atoms";
import { WizardContext, WizardState } from "../../organisms/StepWizard";

export function AppointableWizardStep3({
  submitError,
}: {
  submitError: string;
}) {
  const { wizardState } = useContext(WizardContext);
  const { initialValues, getValues } = useRecordForm({
    record: wizardState["availability"] || null,
    fields: schema.getDefault(),
    mapper,
  });

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={noop}
        validationSchema={schema}
      >
        <Step3Form getValues={getValues} />
      </Formik>

      {submitError && (
        <div className="border-error-700 bg-error-50 text-error-700 flex rounded border p-5">
          <div className="mt-0.5">
            <CheckCircleIcon pathClassName="stroke-error-700" />
          </div>
          <div className="ml-2">
            <div className="font-semibold">Error</div>
            <div>{submitError}</div>
          </div>
        </div>
      )}
    </div>
  );
}

function Step3Form({ getValues }: { getValues: (formValues: any) => any }) {
  const formikProps = useFormikContext();
  const values = formikProps.values as { [k: string]: any };
  const { setFieldValue, validateForm } = formikProps;
  const availabilityOptions: {
    label: string;
    value: AppointableDtoAvailabilityType;
  }[] = [
    { label: "Staff", value: "STAFF" },
    { label: "Facility", value: "FACILITY" },
  ];
  const { api } = useGymflowModels();

  const blockOptions = useMemo(() => {
    return [
      { value: 0, label: "No" },

      ...generateDurationOptions({
        intervalsInMinutes: 15,
        hours: 4,
      }),
    ];
  }, []);

  const { setWizardState, wizardState } = useContext(WizardContext);
  useEffect(() => {
    async function updateWizardState() {
      const errors = await validateForm(values);
      const isValid = Object.keys(errors).length === 0;
      setWizardState((prev: WizardState) => ({
        ...prev,
        availability: getValues(values),
        isValid,
      }));
    }
    updateWizardState();
  }, [values]);

  useEffect(() => {
    if (wizardState.isWizardStepValid !== undefined) {
      formikProps.submitForm();
    }
  }, [wizardState.isWizardStepValid]);

  return (
    <div className="p-2">
      <div>
        <div>Availability type</div>
        <div className="mt-2">
          <Select
            options={availabilityOptions}
            value={availabilityOptions.find(
              (v) => v.value === values[AVAILABILITY_TYPE],
            )}
            onChange={({
              value,
            }: {
              value: AppointableDtoAvailabilityType;
            }) => {
              formikProps.setFieldValue(AVAILABILITY_TYPE, value);
            }}
          />
        </div>
        <div className="mt-1 text-gray-600">
          Determines if your appointment is booked with staff or at a facility
          e.g. Massage with Susan (Staff) vs Squash on Court 4 (Facility).
        </div>
      </div>

      <div
        className={cn("mt-4", {
          hidden: values[AVAILABILITY_TYPE] !== "STAFF",
        })}
      >
        <div>Appointment Hosts</div>
        <div className="mt-2">
          <PaginatedSelect
            isMulti
            showSelectAll
            selectAllClick={eagerSelectAll}
            value={values[APPOINTABLE_HOSTS]}
            onChange={(newValue) => {
              setFieldValue(APPOINTABLE_HOSTS, newValue);
            }}
            loadOptions={async (_, __, additional) => {
              const staffList = await staffListQueryFn({
                api,
                opts: {
                  extraParams: {
                    activeUser: true,
                    availableForAppointments: true,
                  },
                  page: additional.page,
                },
              });
              return {
                options: staffList.content.map(createStaffOption),
                hasMore: !staffList.last,
                additional: { page: additional.page + 1 },
              };
            }}
            placeholder={renderMultiSelectPlaceholder({
              value: values[APPOINTABLE_HOSTS],
            })}
          />
        </div>
      </div>

      <div
        className={cn("mt-4", {
          hidden: values[AVAILABILITY_TYPE] !== "FACILITY",
        })}
      >
        <div>Appointment Facilities</div>
        <div className="mt-2">
          <PaginatedSelect
            isMulti
            value={values[APPOINTABLE_FACILITIES]}
            onChange={(newValue) => {
              setFieldValue(APPOINTABLE_FACILITIES, newValue);
            }}
            loadOptions={async (_, __, additional) => {
              const facilityList = await facilityListQueryFn({
                api,
                opts: {
                  extraParams: {
                    activeUser: true,
                    availableForAppointments: true,
                  },
                  page: additional.page,
                },
              });
              return {
                options: facilityList.content.map(createFacilityOption),
                hasMore: !facilityList.last,
                additional: { page: additional.page + 1 },
              };
            }}
          />
        </div>
      </div>

      <div className="mt-4">
        <div>Block Extra Time</div>
        <div className="mt-2">
          <Select
            options={blockOptions}
            value={
              values?.[BLOCKED_TIME] &&
              blockOptions.find(
                (duration) => duration.value === values[BLOCKED_TIME],
              )
            }
            onChange={({ value }: { value: number }) => {
              setFieldValue(BLOCKED_TIME, value);
            }}
          />
        </div>
        <div className="mt-1 text-gray-600">
          Blocks additional time after the appointment in the host's schedule to
          prevent back to back bookings where processing time may be required.
        </div>
      </div>

      <div className="mt-4 flex content-stretch">
        <div className="flex-auto">
          <div>Web Bookings</div>
          <div className="mt-1 text-gray-600">
            We&apos; make this appointment bookable on your website pages.{" "}
          </div>
        </div>
        <div className="flex justify-end">
          <Switch
            value={values[ALLOW_ONLINE_BOOKINGS]}
            label="test"
            onChange={(value) => {
              setFieldValue(ALLOW_ONLINE_BOOKINGS, value);
            }}
          />
        </div>
      </div>

      <div className="mt-4 flex content-stretch">
        <div className="flex-auto">
          <div>App Bookings</div>
          <div className="mt-1 text-gray-600">
            We&apos; make this appointment bookable on your members mobile app.{" "}
          </div>
        </div>
        <div className="flex justify-end">
          <Switch
            value={values[ALLOW_APP_BOOKINGS]}
            label="test"
            onChange={(value) => {
              setFieldValue(ALLOW_APP_BOOKINGS, value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const AVAILABILITY_TYPE = "availability-type";
const APPOINTABLE_HOSTS = "appointable-hosts-id-list";
const APPOINTABLE_FACILITIES = "appointable-facilities-id-list";
const BLOCKED_TIME = "blocked-time";
const ALLOW_ONLINE_BOOKINGS = "allow-online-bookings";
const ALLOW_APP_BOOKINGS = "allow-app-bookings";

const schema = Yup.object().shape({
  [AVAILABILITY_TYPE]: Yup.string().required().default("STAFF"),
  [APPOINTABLE_HOSTS]: Yup.array().required().min(0).default([]),
  [APPOINTABLE_FACILITIES]: Yup.array().min(0).default([]),
  [BLOCKED_TIME]: Yup.number().min(0),
  [ALLOW_ONLINE_BOOKINGS]: Yup.boolean().required().default(true),
  [ALLOW_APP_BOOKINGS]: Yup.boolean().required().default(true),
});

export function createStaffOption(staff: UserStaffPublicDTO) {
  return {
    label: (
      <div className="flex items-center">
        <Avatar url={staff.picture} className="mr-2 !h-[18px] !w-[18px]" />
        <div>{`${staff.firstName} ${staff.lastName}`}</div>
      </div>
    ),
    value: staff.id,
  };
}

export function createFacilityOption(facility: FacilityDTO) {
  return {
    label: (
      <div className="flex items-center">
        <Avatar url={facility.picture} className="mr-2 !h-[18px] !w-[18px]" />
        <div>{facility.name}</div>
      </div>
    ),
    value: facility.id,
  };
}

const mapper = new FormMapper();
