import { z } from "zod";

export const PaymentMethodStatus = {
  Active: "Active",
  Removed: "Removed",
  Invalid: "Invalid",
} as const;

export const PaymentMethodStatusZodSchema = z.enum([
  PaymentMethodStatus.Active,
  PaymentMethodStatus.Removed,
  PaymentMethodStatus.Invalid,
]);
