import { useAbility } from "@casl/react";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  matchPath,
  Redirect,
  Route,
  useHistory,
  useRouteMatch,
} from "react-router-dom";

import { AbilityContext, Verb } from "../permissions";
import { PageTitleProviderContext } from "../providers";
import { RouteCollection } from "../routes";

export function usePageLayout({ routes }: { routes: RouteCollection }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  const match = useRouteMatch();

  const ability = useAbility(AbilityContext);
  const getRoutes = useCallback(
    (routes: RouteCollection) => {
      let firstAvailableAsDefault: string | null = null;
      const nodes = routes.map((prop) => {
        const hasAccess =
          !prop.permission || ability.can(Verb.View, prop.permission);
        if (hasAccess) {
          if (!firstAvailableAsDefault && !prop.invisible) {
            firstAvailableAsDefault = match.url + prop.path;
          }
          return (
            <Route path={match.path + prop.path} key={prop.path}>
              {(props) => {
                let navbarVisible = prop.navbarVisible ?? true;
                if (isNavbarVisible !== navbarVisible) {
                  setIsNavbarVisible(navbarVisible);
                }
                return <prop.component {...props} />;
              }}
            </Route>
          );
        }
        return null;
      });
      nodes.push(
        <Route key={"default" + nodes.length}>
          <Redirect to={firstAvailableAsDefault!} />
        </Route>,
      );
      return nodes;
    },
    [ability, isNavbarVisible, match.path, match.url],
  );

  const getActiveRouteTitle = useCallback(
    (routes: RouteCollection) => {
      const activeRoute = { title: "Gymflow", iconUrl: undefined };
      for (let i = 0; i < routes.length; i++) {
        if (matchPath(window.location.pathname, match.url + routes[i].path)) {
          return { title: routes[i].name, iconUrl: routes[i].icon };
        }
      }
      return activeRoute;
    },
    [match.url],
  );

  const history = useHistory();
  const { setPageTitle } = useContext(PageTitleProviderContext);

  useEffect(() => {
    function updatePageTitle() {
      const activeRouteTitle = getActiveRouteTitle(routes);
      if (activeRouteTitle.title !== "") {
        setPageTitle(activeRouteTitle);
      }
    }
    updatePageTitle();
    const unlisten = history.listen(updatePageTitle);
    return unlisten;
  }, [getActiveRouteTitle, history, routes, setPageTitle]);

  const toggleSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  const closeSidebar = useCallback(() => {
    setIsSidebarOpen(false);
  }, []);

  const showSidebar = useCallback(() => {
    setIsSidebarOpen(true);
  }, []);

  return {
    isSidebarOpen,
    setIsSidebarOpen,
    isNavbarVisible,
    setIsNavbarVisible,
    getRoutes,
    getActiveRouteTitle,
    toggleSidebar,
    closeSidebar,
    showSidebar,
  };
}
