import { memberSearchByFullNameQueryFn } from "@gymflow/api";
import { UserMemberSearchByFullNameResult } from "@gymflow/types";
import { ReactNode } from "react";
import { ActionMeta } from "react-select/dist/declarations/src/types";

import useGymflowModels from "../../store";
import { LookingGlassIcon } from "./icons";
import { PaginatedSelect } from "./PaginatedSelect";

export function MemberSelect({
  onChange,
  disabledMemberIds = [],
  value,
  placeholder,
  resultMap = (v) => ({ value: v, label: `${v.firstName} ${v.lastName}` }),
  className,
}: {
  onChange: (
    newValue: { value: UserMemberSearchByFullNameResult; label: ReactNode },
    action: ActionMeta<any>,
  ) => void;
  disabledMemberIds?: string[];
  value:
    | {
        value: UserMemberSearchByFullNameResult;
        label: ReactNode;
      }
    | undefined;
  placeholder?: string;
  resultMap?: (
    results: UserMemberSearchByFullNameResult,
    idx: number,
  ) => {
    label: ReactNode;
    value: UserMemberSearchByFullNameResult;
  };
  className?: string;
}) {
  const { api } = useGymflowModels();
  return (
    <PaginatedSelect
      className={className}
      loadOptions={async (term) => {
        if (!term) {
          return {
            options: [
              {
                label: (
                  <div className="text-gray-600">
                    Type the name of the member to start searching.
                  </div>
                ),
                value: null,
                isDisabled: true,
              },
            ],
          };
        }
        const result = await memberSearchByFullNameQueryFn({
          api,
          searchTerm: term,
          limit: 100,
          page: 0,
        });

        return {
          options: result
            .filter(
              (searchResult) => !disabledMemberIds.includes(searchResult.id),
            )
            .map(resultMap),
          hasMore: false,
        };
      }}
      isSearchable
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      icon={<LookingGlassIcon pathClassName="stroke-grey-600" />}
      cacheUniqs={disabledMemberIds}
    />
  );
}
