import { removeWhiteSpaces } from "@gymflow/helpers";
import { Iso2Country } from "@gymflow/types";
import { validatePhoneNumber } from "@gymflow/validation";
import moment from "moment-timezone";

export const ERROR_MESSAGES: {
  postCode: { [key in Iso2Country]: string };
  phoneNumber: string;
  price: string;
  emailExists: string;
  cssColor: string;
  dateTooBig: ({ max }: { max: Date }) => string;
  dateTooSmall: ({ min }: { min: Date }) => string;
  minAge: string;
  ukBankAccount: string;
  ukBankSortCode: string;
} = {
  postCode: {
    GB: "Not a valid UK postcode.",
    US: "Not a valid zip code.",
    CA: "Not a valid zip code.",
    AU: "Not a valid AU postcode.",
    NO: "Not a valid NO postcode.",
    NZ: "Not a valid NZ postcode.",
    ES: "Not a valid postcode.",
    GI: "Not a valid postcode.",
    IE: "Not a valid postcode.",
    CY: "Not a valid postcode.",
    FR: "Not a valid postcode.",
    NL: "Not a valid postcode.",
  },
  phoneNumber: "Not a valid phone number.",
  price: "Price is invalid.",
  emailExists: "Email already exists.",
  cssColor:
    "Not a valid CSS color (eg accepted values: #8B1F9B, purple, rgb(139, 31, 155)).",
  dateTooBig: ({ max }: { max: Date }) => {
    const parsedMaxDate = `${max.getDate()}-${
      max.getMonth() + 1
    }-${max.getFullYear()}`;
    return `Maximum date: ${parsedMaxDate}.`;
  },
  dateTooSmall: ({ min }: { min: Date }) => {
    const parsedMinDate = `${min.getDate()}-${
      min.getMonth() + 1
    }-${min.getFullYear()}`;
    return `Minimum date: ${parsedMinDate}.`;
  },
  minAge: "Minimum age: 16 years old.",
  ukBankAccount:
    "Bank Account number is invalid. Prefix 7 digit bank account numbers with a leading 0.",
  ukBankSortCode: "Sort code is invalid. Expected format: XX-XX-XX.",
};

export const isValidPrice = (value: string) => {
  const regex = /^\d+\.?\d{0,2}$/;
  return regex.test(value);
};


const postalCodeCountryPattern: { [key in Iso2Country]: RegExp } = {
  GB: /^([A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/i,
  US: /^[0-9]{5}(?:-[0-9]{4})?$/i,
  CA: /^[A-Z0-9]{3}[ -]{0,1}[A-Z0-9]{3}$/i,
  AU: /^\d{4}$/,
  NO: /^\d{4}$/,
  NZ: /^\d{4}$/,
  GI: /^([0-9a-zA-Z\- ]{1,23})$/,
  IE: /^([A-Z][0-9]{2}|D6W)([ -][0-9A-Z]{4})$/i,
  ES: /^(\d{5})$/,
  CY: /^\d{4}$/,
  FR: /^\d{5}$/,
  NL: /^[1-9][0-9]{3}\s?[A-Z]{2}$/,
};

export const isValidPostCode = (country: Iso2Country, value: string) => {
  const pattern = postalCodeCountryPattern[country];
  if (pattern) {
    return value && new RegExp(pattern).test(value.trim());
  }
  throw new Error("Invalid post code country.");
};

export const isValidCssColor = (value: string) => {
  const { style } = new Option();
  style.color = value;
  return style.color !== "";
};

export function yupEmergencyContactTest(value: string) {
  // @ts-ignore
  const { createError } = this;
  const errorMessage =
    "Emergency contact cannot be the same as the Phone Number field.";
  // @ts-ignore
  if (!this.parent["mobile-number"]) {
    return true;
  }
  return (
    // @ts-ignore
    removeWhiteSpaces(this.parent["mobile-number"] || "") !==
      removeWhiteSpaces(value || "") || createError({ message: errorMessage })
  );
}

export const extendYup = (Yup: any, dateFormat: string) => {
  Yup.setLocale({
    mixed: {
      default: "Field value is not valid.",
      required: "Field is required.",
      notType: ({ type }: { type: string }) => {
        switch (type) {
          case "date":
            return "Date is invalid. Expected format: " + dateFormat + ".";
          default:
            return "Field value is not valid.";
        }
      },
    },
    string: {
      email: "Must be a valid email address.",
      // eslint-disable-next-line no-template-curly-in-string
      min: "Must be at least ${min} characters",
      // eslint-disable-next-line no-template-curly-in-string
      max: "Cannot have more than ${max} characters.",
    },
  });

  Yup.addMethod(Yup.string, "price", function (customMessage: string) {
    const message = customMessage || ERROR_MESSAGES.price;
    // @ts-ignore
    return this.test("price", message, function (value) {
      // @ts-ignore
      const { createError } = this;
      return isValidPrice(value) || createError(message);
    });
  });

  Yup.addMethod(Yup.string, "cssColor", function (customMessage: string) {
    const message = customMessage || ERROR_MESSAGES.cssColor;
    // @ts-ignore
    return this.test("cssColor", message, function (value) {
      // @ts-ignore
      const { createError } = this;
      return isValidCssColor(value) || createError(message);
    });
  });

  Yup.addMethod(
    Yup.string,
    "postCode",
    function (country: Iso2Country, customMessage: string) {
      const message = customMessage || ERROR_MESSAGES.postCode[country];
      // @ts-ignore
      return this.test("postCode", message, function (value) {
        // @ts-ignore
        const { createError } = this;
        return (
          value === "" ||
          value === undefined ||
          isValidPostCode(country, value) ||
          createError(message)
        );
      });
    },
  );

  Yup.addMethod(Yup.string, "phoneNumber", function (customMessage: string) {
    const message = customMessage || ERROR_MESSAGES.phoneNumber;
    // @ts-ignore
    return this.test("phoneNumber", message, function (value) {
      // @ts-ignore
      const { createError } = this;
      return !value || validatePhoneNumber(value) || createError(message);
    });
  });

  Yup.addMethod(
    Yup.mixed,
    "requiredIf",
    function (ref: any, msg: string, present = true) {
      // @ts-ignore
      return this.test({
        name: "requiredIf",
        exclusive: false,
        message: msg,
        params: {
          reference: ref.path,
        },
        test(value: string | null | undefined) {
          if (this.resolve(ref) === present) {
            return (
              value !== "" && value !== null && typeof value !== "undefined"
            );
          }
          return true;
        },
      });
    },
  );

  Yup.addMethod(
    Yup.date,
    "format",
    function (formats: string, parseStrict: boolean) {
      // @ts-ignore
      return this.transform((value, originalValue) => {
        value = moment(originalValue, formats, parseStrict);

        return value.isValid() ? value.toDate() : new Date("");
      });
    },
  );
};
