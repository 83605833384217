import {
  DATE_FORMAT,
  PARAMETER_DATE_FORMAT_WITHOUT_TZ,
  TIME_FORMAT,
  useRecordForm,
} from "@gymflow/common";
import { EventOccurrenceDTO, EventOccurrenceListItemDTO } from "@gymflow/types";
import classNames from "classnames";
import { Formik, useFormikContext } from "formik";
import moment from "moment-timezone";
import { useCallback, useEffect } from "react";

import { ModalWrapper } from "../../../providers";
import {
  Button,
  DangerIcon,
  DateSelect,
  PrimaryButton,
  TimeSelect,
} from "../../atoms";
import {
  createRescheduleSchema,
  END_DATE,
  END_TIME,
  START_DATE,
  START_TIME,
} from "./OccurrenceSchema";
import { RescheduleFormMapper } from "./RescheduleFormMapper";

export function EventOccurrenceRescheduleModal({
  eventOccurrence,
  onConfirm,
  onCancel,
  newStartDate,
  newEndDate,
}: {
  eventOccurrence: EventOccurrenceDTO | EventOccurrenceListItemDTO;
  onConfirm: (newValues: Record<string, any>) => Promise<void>;
  onCancel: () => void;
  newStartDate?: string;
  newEndDate?: string;
}) {
  const schema = createRescheduleSchema();
  const { initialValues, getValues } = useRecordForm({
    fields: schema.getDefault(),
    record: eventOccurrence,
    mapper: new RescheduleFormMapper(),
  });

  return (
    <ModalWrapper onCancel={onCancel}>
      <div className="bg-primary-100 mb-3 flex h-11 w-11 self-center rounded-full p-3">
        <DangerIcon className="h-5 w-5" pathClassName="stroke-primary-600" />
      </div>
      <div>
        <div className="font-semibold">Reschedule Class</div>
        <div className="text-sm text-gray-600">
          Confirm details to reschedule class.
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values) => {
          onConfirm(getValues(values));
        }}
        validationSchema={schema}
      >
        <RescheduleForm
          onCancel={onCancel}
          newStartDate={newStartDate}
          newEndDate={newEndDate}
        />
      </Formik>
    </ModalWrapper>
  );
}

function RescheduleForm({
  onCancel,
  newStartDate,
  newEndDate,
}: {
  onCancel: () => void;
  newStartDate?: string;
  newEndDate?: string;
}) {
  const formikProps = useFormikContext();
  const { setFieldValue } = formikProps;
  const values = formikProps.values as Record<string, any>;
  const errors = formikProps.errors as Record<string, any>;

  useEffect(() => {
    if (newStartDate) {
      const startMoment = moment(
        newStartDate,
        PARAMETER_DATE_FORMAT_WITHOUT_TZ,
      );
      setFieldValue(START_DATE, startMoment.format(DATE_FORMAT));
      setFieldValue(START_TIME, startMoment.format("hh:mm a"));
    }
    if (newEndDate) {
      const endMoment = moment(newEndDate, PARAMETER_DATE_FORMAT_WITHOUT_TZ);
      setFieldValue(END_DATE, endMoment.format(DATE_FORMAT));
      setFieldValue(END_TIME, endMoment.format("hh:mm a"));
    }
  }, [newEndDate, newStartDate, setFieldValue]);

  const setStartAndEndValues = useCallback(
    (
      startDate: any,
      startTime: any,
      endTime: any,
      updateEndTimeToHalfAnHourAfterStartTime = false,
    ) => {
      const startMoment = moment(
        `${startDate} ${startTime}`,
        `${DATE_FORMAT} ${TIME_FORMAT} a`,
      );
      let endMoment = moment(
        `${startDate} ${endTime}`,
        `${DATE_FORMAT} ${TIME_FORMAT}`,
      );

      if (startMoment.isValid() && updateEndTimeToHalfAnHourAfterStartTime) {
        endMoment = startMoment.add(30, "minutes");
      }

      setFieldValue(START_DATE, startDate);
      setFieldValue(START_TIME, startTime);
      setFieldValue(END_TIME, endMoment.format("hh:mm a"));
      if (startMoment.isAfter(endMoment)) {
        const nextDay = moment(startDate, DATE_FORMAT)
          .startOf("day")
          .add(1, "day");
        setFieldValue(END_DATE, nextDay.format(DATE_FORMAT));
      } else {
        setFieldValue(END_DATE, endMoment.format(DATE_FORMAT));
      }
    },
    [setFieldValue],
  );

  return (
    <>
      <div className="mt-5 flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <label
            htmlFor={START_DATE}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            Date
          </label>
          <div>
            <DateSelect
              value={values[START_DATE]}
              onChange={(newDate) => {
                setStartAndEndValues(
                  newDate,
                  values[START_TIME],
                  values[END_TIME],
                );
              }}
              dateFormat={DATE_FORMAT}
              dateToStartFrom={moment().subtract(3, "days").format(DATE_FORMAT)}
            />
          </div>
          <div
            className={classNames("text-error-700", {
              hidden: !errors[START_DATE],
            })}
          >
            {errors[START_DATE]}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor={START_TIME}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            Start Time
          </label>
          <div className="flex">
            <TimeSelect
              value={values[START_TIME]}
              onChange={(newTime) => {
                setStartAndEndValues(
                  values[START_DATE],
                  newTime,
                  values[END_TIME],
                  true,
                );
              }}
            />
          </div>
          <div
            className={classNames("text-error-700", {
              hidden: !errors[START_TIME],
            })}
          >
            {errors[START_TIME]}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <label
            htmlFor={END_TIME}
            className="mb-0 flex text-sm font-medium text-gray-700"
          >
            End Time
          </label>
          <div className="flex">
            <TimeSelect
              value={values[END_TIME]}
              onChange={(newTime) => {
                setStartAndEndValues(
                  values[START_DATE],
                  values[START_TIME],
                  newTime,
                );
              }}
            />
          </div>
          <div
            className={classNames("text-error-700", {
              hidden: !errors[END_DATE],
            })}
          >
            {errors[END_DATE]}
          </div>
        </div>
      </div>

      <div className="mt-5 flex space-x-2">
        <Button onClick={onCancel} className="mt-2 flex-1">
          Cancel
        </Button>
        <PrimaryButton
          onClick={() => {
            formikProps.submitForm();
          }}
          className="flex-1"
        >
          Reschedule
        </PrimaryButton>
      </div>
    </>
  );
}
