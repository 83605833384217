import {
  getMembershipPrice,
  PARAMETER_DATE_FORMAT,
  ServiceStatus,
  ServiceType,
  tzDateTimeStringToUtc,
  useParseErrors,
  useUserFormFieldConfiguration,
} from "@gymflow/common";
import { formatCurrency } from "@gymflow/helpers";
import { useStoreState } from "easy-peasy";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";

import SellMembershipAccordion from "../../components/SalesWizard/SellMembershipAccordion";
import useMemberships from "../../hooks/useMemberships";
import useProcessSaleStaff from "../../hooks/useProcessSaleStaff";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";
import useStaffOnUserSubmit from "./useStaffOnUserSubmit";

function SellMembership({ membershipType }) {
  const { api, settingsStore, MemberStore } = useGymflowModels();
  const settings = useClubSettings();
  const dateFormat = settings.date_format;
  const clubId = settings.clubId;
  const { defaultCurrency: currency, timezone } = useStoreState(settingsStore);
  const { fetchList: fetchMemberships, rows: memberships } = useMemberships();
  const { fetchPaymentMethods } = MemberStore.useStoreActions(
    (actions) => actions,
  );
  const { processExistingMemberMembership } = useProcessSaleStaff();
  const onUserSubmit = useStaffOnUserSubmit({
    create: api.memberApi.create,
    update: api.memberApi.update,
    clubId,
  });
  const parseError = useParseErrors();
  const { data: requiredFields } = useUserFormFieldConfiguration({
    api,
    clubId,
  });

  useEffect(() => {
    fetchMemberships({
      status: ServiceStatus.Active,
      type: membershipType,
      extraParams: { unpaged: true },
    });
  }, [membershipType]);

  const membershipsToSell = useMemo(
    () =>
      memberships
        .sort((a, b) => getMembershipPrice(a) - getMembershipPrice(b))
        .map((membership) => ({
          ...membership,
          price: getMembershipPrice(membership),
          plus: membership.membershipAddonList
            ?.filter((a) => !a.recurring)
            ?.map((addon) => ({
              name: addon.name,
              price: formatCurrency(addon.price, currency),
            })),
          includes: membership.membershipAddonList
            ?.filter((a) => a.recurring)
            .map((addon) => addon.name),
        })),
    [memberships],
  );

  return (
    <div className="content h-full overflow-y-auto p-8">
      <SellMembershipAccordion
        memberships={membershipsToSell.map(
          ({
            id,
            name,
            description,
            price,
            type,
            billingPeriod,
            billingType,
            termsConditions,
            calculateProrata,
            weeklyBillingDay,
            monthlyBillingDay,
            ...otherFields
          }) => ({
            id,
            name,
            description,
            price: formatCurrency(price, currency),
            isRecurring: type === ServiceType.Recurring,
            billingPeriod,
            billingType,
            includes: otherFields?.includes,
            plus: otherFields?.plus,
            termsAndConditionsLink: termsConditions,
            calculateProrata,
            weeklyBillingDay,
            monthlyBillingDay,
            fixedStartDate: otherFields?.fixedStartDate,
          }),
        )}
        membershipType={membershipType}
        searchUsers={api.memberApi.globalSearch}
        currency={currency}
        fetchPaymentMethods={({ userMemberId }) =>
          fetchPaymentMethods({ userMemberId, clubId })
        }
        fetchLeadSources={({ page }) =>
          api.leadSourceApi.find({
            page,
            extraParams: { activeSource: true },
          })
        }
        dateFormat={dateFormat}
        summary={api.serviceApi.summary}
        onUserSubmit={onUserSubmit}
        purchase={async (purchaseValues) => {
          try {
            await processExistingMemberMembership(purchaseValues.id, clubId, {
              paymentMethod: purchaseValues.paymentMethod,
              membershipId: purchaseValues.cart[0].id,
              promotionCode: purchaseValues.promoCode,
              startDate: tzDateTimeStringToUtc(
                purchaseValues.startDate,
                timezone,
                PARAMETER_DATE_FORMAT,
              ),
            });
          } catch (e) {
            parseError(e.response);
          }
        }}
        timezone={timezone}
        requiredFields={requiredFields}
      />
    </div>
  );
}

SellMembership.propTypes = {
  membershipType: PropTypes.oneOf(Object.values(ServiceType)).isRequired,
};

function SellMembershipWithProviders(props) {
  const { ServiceStore, MemberStore } = useGymflowModels();
  return (
    <ServiceStore.Provider>
      <MemberStore.Provider>
        <SellMembership {...props} />
      </MemberStore.Provider>
    </ServiceStore.Provider>
  );
}

export default SellMembershipWithProviders;
