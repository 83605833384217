import { useState } from "react";

type OptionImageProps = {
  imageUrl?: string;
  label: string;
  withImageByDefault?: boolean;
};

export const OptionImage = ({
  imageUrl,
  label,
  withImageByDefault = false,
}: OptionImageProps) => {
  const [imageError, setImageError] = useState(false);

  return imageUrl && !imageError ? (
    <img
      src={imageUrl}
      alt=""
      className="h-6 w-6 min-w-[1.5rem] rounded-full border border-gray-200 bg-gray-100 object-cover"
      onError={() => setImageError(true)}
    />
  ) : withImageByDefault || imageError ? (
    <div className="flex h-6 w-6 min-w-[1.5rem] items-center justify-center rounded-full border border-gray-200 bg-gray-100 text-xs font-semibold text-gray-950">
      {label?.charAt(0).toUpperCase()}
    </div>
  ) : null;
};
