import { leadStatusListQueryFn } from "@gymflow/api";
import { useState } from "react";

import useGymflowModels from "../../../../store";
import { PaginatedSelect } from "../../../atoms";
import { ConfirmModal } from "../../../templates";

export function BulkStatusChangeModal({
  hide,
  onChange,
}: {
  hide: () => void;
  onChange: (leadStatusId: number) => Promise<void>;
}) {
  const [selectedValue, setSelectedValue] = useState<{
    value: number;
    label: string;
  }>();
  const { api } = useGymflowModels();
  return (
    <ConfirmModal
      onCancel={hide}
      onConfirm={async () => {
        await onChange(selectedValue?.value!);
        hide();
      }}
      confirmText="Change"
      type="default"
      title="Change Lead Status"
      isConfirmDisabled={!selectedValue}
    >
      <div className="flex flex-col justify-center gap-4">
        Select a new lead status for the selected leads.
        <div className="w-100 flex-1">
          <PaginatedSelect
            value={selectedValue}
            onChange={async (newValue) => {
              setSelectedValue(newValue);
            }}
            loadOptions={async () => {
              const response = await leadStatusListQueryFn({ api });
              return {
                options: response.map((source) => ({
                  value: source.id,
                  label: source.name,
                })),
                hasMore: false,
              };
            }}
          />
        </div>
      </div>
    </ConfirmModal>
  );
}
