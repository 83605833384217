import { useMutation } from "@tanstack/react-query";

export function useMutationKisiInitialize({
  api,
}: {
  api: {
    kisiApi: {
      initialize: (
        clubId: number,
        kisiPlaceId: string,
        membershipToGroupMap: { [k: number]: string },
        facilityToGroupMap: { [k: number]: string },
      ) => Promise<any>;
    };
  };
}) {
  const mutation = useMutation({
    mutationFn: ({
      clubId,
      kisiPlaceId,
      membershipToGroupMap,
      facilityToGroupMap,
    }: {
      clubId: number;
      kisiPlaceId: string;
      membershipToGroupMap: { [k: number]: string };
      facilityToGroupMap: { [k: number]: string };
    }) => {
      return api.kisiApi.initialize(
        clubId,
        kisiPlaceId,
        membershipToGroupMap,
        facilityToGroupMap,
      );
    },
  });
  return mutation;
}
