import {
  DATE_FORMAT,
  FormikDateTime,
  formikHelpers,
  FormikInput,
  renderErrorRowOnTouch,
  TIME_FORMAT,
  useRecordForm,
  UserStatus,
} from "@gymflow/common";
import classNames from "classnames";
import { useFormik } from "formik";
import concat from "lodash/concat";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { useEffect } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Label, Row } from "reactstrap";

import { useClubSettings } from "../../providers";
import MultiUsersSelect from "../MultiUsersSelect";
import StaffSelect from "../StaffSelect";
import TaskFormMapper from "./TaskFormMapper";
import schema, {
  COMPLETE,
  DEADLINE_DATE,
  DEADLINE_TIME,
  NAME,
  NOTES,
  TASK_OWNERS,
  TASK_RELATED_LEADS,
  TASK_RELATED_USERS,
} from "./TaskSchema";

const taskStatusOptions = [
  { label: "Completed", value: true },
  { label: "Incomplete", value: false },
];

const mapper = new TaskFormMapper();

function TaskAlert({
  editing,
  defaultRelatedUsers,
  defaultRelatedLeads,
  onSubmit,
  onCancel,
  onRemove,
}) {
  const settings = useClubSettings();
  const dateFormat = settings.date_format;

  const { initialValues, getValues, getPatchedValues } = useRecordForm({
    record: editing,
    fields: schema.default(),
    mapper,
  });

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (editing) {
        const patchedValues = getPatchedValues(values);
        if (patchedValues.deadlineTime) {
          patchedValues.deadlineDate = getValues(values).deadlineDate;
        }
        await onSubmit({ id: editing.id, patchedFields: patchedValues });
      } else {
        await onSubmit(getValues(values));
      }
    },
  });
  const { handleSubmit, setFieldValue, values, touched, errors } = formikProps;
  const { errorClass } = formikHelpers(formikProps);

  useEffect(() => {
    if (defaultRelatedUsers) {
      setFieldValue(TASK_RELATED_USERS, defaultRelatedUsers);
    }

    if (defaultRelatedLeads) {
      setFieldValue(TASK_RELATED_LEADS, defaultRelatedLeads);
    }
  }, [defaultRelatedUsers, defaultRelatedLeads]);

  const renderButtons = () => (
    <>
      <Button size="sm" color="primary" type="button" onClick={handleSubmit}>
        Save
      </Button>
      <Button
        size="sm"
        outline
        color="primary"
        type="button"
        onClick={onCancel}
        style={{ marginRight: "8px" }}
      >
        Cancel
      </Button>
      {editing && onRemove && (
        <Button
          size="sm"
          outline
          color="danger"
          type="button"
          onClick={() => onRemove(editing)}
        >
          Delete
        </Button>
      )}
    </>
  );

  return (
    <ReactBSAlert
      title={
        <div className="head-title d-flex">
          <h2>{editing ? "Update" : "Create"} Task</h2>
        </div>
      }
      showCancel
      onCancel={onCancel}
      closeOnClickOutside={false}
      onConfirm={handleSubmit}
      customButtons={renderButtons()}
      customClass="alert-wrapper"
      style={{
        width: 500,
      }}
    >
      <Form
        className="form-horizontal"
        role="form"
        onSubmit={(e) => e.preventDefault()}
      >
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={NAME}>
              Name *
            </Label>
          </Col>
        </Row>
        <Row className="mt-2 text-left">
          <Col>
            <FormGroup className={errorClass(NAME)}>
              <FormikInput
                autoComplete="off"
                data-testid={NAME}
                maxLength="50"
                name={NAME}
                type="text"
                formikProps={formikProps}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={NOTES}>
              <span className="mr-2">Notes</span>
            </Label>
          </Col>
        </Row>
        <Row className="mt-2 text-left">
          <Col>
            <FormGroup className={errorClass(NOTES)}>
              <FormikInput
                autoComplete="off"
                data-testid={NOTES}
                maxLength="128"
                name={NOTES}
                id={NOTES}
                formikProps={formikProps}
                type="textarea"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3">Associated Users</Label>
          </Col>
        </Row>
        <Row className="mt-2 text-left">
          <Col>
            <FormGroup>
              <MultiUsersSelect
                isMulti
                onChange={(value) => {
                  setFieldValue(
                    TASK_RELATED_USERS,
                    value
                      .map((selected) => selected.value)
                      .filter((u) => u.profileType !== UserStatus.Lead),
                  );
                  setFieldValue(
                    TASK_RELATED_LEADS,
                    value
                      .map((selected) => selected.value)
                      .filter((u) => u.profileType === UserStatus.Lead),
                  );
                }}
                value={concat(
                  values[TASK_RELATED_USERS],
                  values[TASK_RELATED_LEADS],
                ).map((u) => ({
                  label: `${u.firstName} ${u.lastName}`,
                  value: u,
                }))}
              />
              {renderErrorRowOnTouch(TASK_RELATED_USERS, touched, errors)}
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase mt-2 text-left">
          <Col>
            <Label tag="h3">DeadLine</Label>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <FormGroup
              className={classNames("text-left", errorClass(DEADLINE_DATE))}
            >
              <FormikInput
                name={DEADLINE_DATE}
                component={FormikDateTime}
                inputProps={{
                  className: "form-control",
                  name: DEADLINE_DATE,
                  autoComplete: "off",
                  id: DEADLINE_DATE,
                  placeholder: dateFormat,
                  value: moment(values[DEADLINE_DATE], DATE_FORMAT).isValid()
                    ? moment(values[DEADLINE_DATE], DATE_FORMAT).format(
                        dateFormat,
                      )
                    : undefined,
                }}
                timeFormat={false}
                dateFormat={DATE_FORMAT}
                isValidDate={(date) =>
                  date.isAfter(moment().subtract(1, "day"))
                }
                formikProps={formikProps}
                showErrorLabel
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup
              className={classNames(
                "d-block text-left",
                errorClass(DEADLINE_TIME),
              )}
            >
              <FormikInput
                name={DEADLINE_TIME}
                component={FormikDateTime}
                inputProps={{
                  className: "form-control",
                  name: DEADLINE_TIME,
                  autoComplete: "off",
                  id: DEADLINE_TIME,
                  placeholder: TIME_FORMAT,
                }}
                timeFormat="h:mm a"
                dateFormat={false}
                formikProps={formikProps}
                showErrorLabel
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={TASK_OWNERS}>
              Owner
            </Label>
          </Col>
        </Row>
        <Row className="mt-2 text-left">
          <Col>
            <FormGroup>
              <StaffSelect
                isMulti
                onChange={(value) => {
                  setFieldValue(
                    TASK_OWNERS,
                    value.map((s) => s.value),
                  );
                }}
                value={
                  values[TASK_OWNERS] &&
                  values[TASK_OWNERS].map((u) => ({
                    label: `${u.firstName} ${u.lastName}`,
                    value: u,
                  }))
                }
              />
              {renderErrorRowOnTouch(TASK_OWNERS, touched, errors)}
            </FormGroup>
          </Col>
        </Row>
        <Row className="text-uppercase text-left">
          <Col>
            <Label tag="h3" htmlFor={COMPLETE}>
              Status
            </Label>
          </Col>
        </Row>
        <Row className="mt-2 text-left">
          <Col>
            <FormGroup>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                options={taskStatusOptions}
                value={taskStatusOptions.find(
                  (op) => op.value === values[COMPLETE],
                )}
                onChange={({ value }) => {
                  setFieldValue(COMPLETE, value);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </ReactBSAlert>
  );
}

export default TaskAlert;

TaskAlert.defaultProps = {
  editing: null,
};

TaskAlert.propTypes = {
  editing: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};
