import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMemberPaymentMethodAsMember({ api }: { api: ApiType }) {
  const queryClient = useQueryClient();

  const attachPaymentMethodMutation = useMutation({
    mutationFn: async ({
      clubId,
      paymentMethodId,
      memberId,
    }: {
      clubId: number;
      paymentMethodId: string;
      memberId: string;
    }) => {
      await api.profileApi.attachPaymentMethod(
        memberId,
        clubId,
        paymentMethodId,
      );
    },
    onSuccess: async (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      await queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
  });

  const removePaymentMethodMutation = useMutation({
    mutationFn: async ({
      clubId,
      paymentMethodId,
      memberId,
    }: {
      clubId: number;
      paymentMethodId: string;
      memberId: string;
    }) => {
      await api.profileApi.removePaymentMethod(
        memberId,
        clubId,
        paymentMethodId,
      );
    },
    onSuccess: async (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      await queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
  });

  const assignDefaultPaymentMethodMutation = useMutation({
    mutationFn: async ({
      clubId,
      paymentMethodId,
      memberId,
    }: {
      clubId: number;
      paymentMethodId: string;
      memberId: string;
    }) => {
      await api.profileApi.assignDefaultPaymentMethod(
        memberId,
        clubId,
        paymentMethodId,
      );
    },
    onSuccess: async (
      _,
      { memberId, clubId }: { memberId: string; clubId: number },
    ) => {
      await queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.paymentMethods(clubId, memberId),
      });
    },
  });

  return {
    attachPaymentMethodMutation,
    removePaymentMethodMutation,
    assignDefaultPaymentMethodMutation,
  };
}
