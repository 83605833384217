import { useCallback, useEffect, useState } from 'react';
import { useFont } from '@gymflow/common';

import useGymflowModels from '../store';

const useHostedPagesStyle = ({ clubId, useInjectCss }) => {
  const { PublicHostedClubStore } = useGymflowModels();
  const customStyles = PublicHostedClubStore.useStoreState((state) => state.editing);
  const fetchById = PublicHostedClubStore.useStoreActions((actions) => actions.fetchById);
  const [hostedStylesLoaded, setHostedStylesLoaded] = useState(false);
  const fetchSettings = useCallback(async () => {
    await fetchById(clubId);
  }, [fetchById, clubId]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const [customStyle, setCustomStyle] = useState(null);

  const inject = useInjectCss();

  const injectFont = useFont();

  useEffect(() => {
    if (customStyles) {
      const cleanupCss = inject(customStyles.settings);
      const cleanupFont = injectFont(customStyles.settings.font);
      setCustomStyle(customStyles.settings);
      setHostedStylesLoaded(true);
      return function() {
        cleanupCss();
        cleanupFont();
      }
    }
  }, [customStyles, inject, injectFont, setCustomStyle]);

  return { customStyle, hostedStylesLoaded };
};

export default useHostedPagesStyle;
