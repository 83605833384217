import { computed, thunkOn } from 'easy-peasy';

import MemberModelBuilder from './MemberModelBuilder';

class ProfileModelBuilder extends MemberModelBuilder {
  constructor(apiKey, authKey = 'auth') {
    super(apiKey);
    this.authKey = authKey;
    this.generators.push(this.profileGenerator);
  }

  /**
   * @private
   */
  profileGenerator() {
    return {
      currentMembership: computed(
        (state) =>
          state.editing && state.editing.subscriptions.find((f) => f.membershipBean && f.active && f.mainSubscription)
      ),

      onUpdate: thunkOn(
        (actions) => [actions.update, actions.updatePicture],
        (_, __, { injections: { globalStore } }) => {
          return globalStore.getActions()[this.authKey].updateUserDetails();
        }
      ),
    };
  }
}

export default ProfileModelBuilder;
