import { ApiListResponse, PromotionDTO } from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { promotionQueryKeys } from "./promotionQueryKeys";

export function usePromotionList({
  api,
  opts,
}: {
  api: {
    promotionApi: {
      find: (
        arg0: PromotionListFilter,
      ) => Promise<{ data: ApiListResponse<PromotionDTO> }>;
    };
  };
  opts?: PromotionListFilter;
}) {
  const result = useQuery({
    queryKey: promotionQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.promotionApi.find({ ...opts });
      return result.data;
    },
    initialData: defaultPage<PromotionDTO>,
  });
  return result;
}

interface PromotionListFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly extraParams?: {
    readonly active?: boolean;
    readonly id?: number[];
    readonly code?: string;
    readonly [k: string]: any;
  };
  readonly [k: string]: any;
}
