import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation } from "@tanstack/react-query";

export function useMutationMemberInvoiceAsMember({
  api,
  memberId,
  clubId,
}: {
  api: ApiType;
  memberId: string;
  clubId: number;
}) {
  const result = useMutation({
    mutationFn: async ({ invoiceNumber }: { invoiceNumber: string }) => {
      return await api.profileApi.invoiceFile(clubId, invoiceNumber);
    },
  });

  return result;
}
