import { zonedTimeToUtc } from "@gymflow/helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { reportQueryKeys } from "../report";
import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationLeadBulkStatusUpdateFilter({
  api,
  tz,
}: {
  api: {
    leadApi: {
      bulkStatusUpdateFilter: (args: {
        newLeadStatusId: number;
        leadIdsToExclude?: number[];
        createdFrom?: string;
        createdTo?: string;
        smsCommunication?: boolean;
        emailCommunication?: boolean;
        leadStatusIdList?: number[];
        leadSourceIdList?: number[];
      }) => Promise<{ data: any }>;
    };
  };
  tz: string;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (payload: {
      newLeadStatusId: number;
      leadIdsToExclude?: number[];
      createdFrom?: string;
      createdTo?: string;
      smsCommunication?: boolean;
      emailCommunication?: boolean;
      leadStatusIdList?: number[];
      leadSourceIdList?: number[];
    }) => {
      const parsedOpts = { ...payload };
      if (payload?.createdFrom) {
        parsedOpts.createdFrom = zonedTimeToUtc(payload.createdFrom, tz);
      }
      if (payload?.createdTo) {
        parsedOpts.createdTo = zonedTimeToUtc(payload.createdTo, tz);
      }
      const response = await api.leadApi.bulkStatusUpdateFilter(parsedOpts);
      return response.data;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: reportQueryKeys.lead() });
    },
  });
}
