import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import classNames from 'classnames';
import MediumText from '../atoms/MediumText';

function SessionPackCard({ name, description, price, sessionsIncluded, expiry, onButtonClick }) {
  return (
    <Card className="h-100">
      <CardBody className="d-flex flex-column flex-nowrap">
        <Row>
          <Col className="text-center">
            <MediumText className="font-weight-bold">{name}</MediumText>
          </Col>
        </Row>
        <Row className={classNames('mt-3', { 'd-none': !description })}>
          <Col className="text-center">{description}</Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <MediumText className="font-weight-bold">{price}</MediumText>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col className="text-center text-muted">{`${sessionsIncluded} Included`}</Col>
        </Row>
        <Row className={classNames('mt-3', { invisible: !expiry })}>
          <Col className="text-center text-muted">{expiry}</Col>
        </Row>
        <Row className="mt-3" />
        <Row className="mt-auto">
          <Col className="text-center">
            <Button
              className="font-weight-bold"
              color="primary"
              size="sm"
              style={{ width: '140px' }}
              onClick={onButtonClick}
            >
              Select
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

SessionPackCard.defaultProps = {
  description: '',
  expiry: null,
};

SessionPackCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string.isRequired,
  sessionsIncluded: PropTypes.string.isRequired,
  expiry: PropTypes.string,
  onButtonClick: PropTypes.func.isRequired,
};

export default SessionPackCard;
