import { QueryKey } from "@tanstack/react-query";

export const eventOccurrenceQueryKeys = {
  all: () => ["eventOccurrence"] as QueryKey,
  list: (filters: Readonly<{ [k: string]: any }>) =>
    [...eventOccurrenceQueryKeys.all(), { filters }] as QueryKey,
  details: (activityId: number | null) =>
    [...eventOccurrenceQueryKeys.all(), "activity", activityId] as QueryKey,
  member: (memberId: string, filters: Readonly<{ [k: string]: any }>) =>
    [
      ...eventOccurrenceQueryKeys.all(),
      "member",
      memberId,
      { filters },
    ] as QueryKey,
  memberUpcoming: (
    memberId?: string,
    filters?: Readonly<{ [k: string]: any }>,
  ) =>
    [
      ...eventOccurrenceQueryKeys.all(),
      "member",
      "upcoming",
      memberId,
      { filters },
    ] as QueryKey,

  memberPast: (memberId?: string, filters?: Readonly<{ [k: string]: any }>) =>
    [
      ...eventOccurrenceQueryKeys.all(),
      "member",
      "past",
      memberId,
      { filters },
    ] as QueryKey,
};
