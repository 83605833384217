import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import classNames from "classnames";
import range from "lodash/range";
import { Fragment, ReactNode, useCallback, useEffect, useRef } from "react";

import { Spinner } from "../atoms";

export interface ColumnMeta {
  suspense: () => ReactNode;
}

export type InfiniteTableProps<T> = {
  data: T[];
  columns: ColumnDef<T, any>[];
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetching: boolean;
  hasMoreData: boolean;
  rowHeight?: number;
  className?: string;
  theadClassName?: string;
};

export function InfiniteTable<T>({
  data,
  columns,
  fetchNextPage,
  isFetching,
  hasMoreData,
  className,
  theadClassName,
  isLoading,
  rowHeight = 64,
}: InfiniteTableProps<T>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight === 0 && scrollTop === 0 && clientHeight === 0) return;
        if (
          scrollHeight - scrollTop - clientHeight < 500 &&
          hasMoreData &&
          !isFetching
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, hasMoreData, isFetching],
  );
  useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columns: columns,
  });
  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => rowHeight,
    getScrollElement: () => tableContainerRef.current,
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });
  const renderProgressRows = useCallback(() => {
    return (
      isLoading &&
      range(0, 10).map((i, idx) => (
        <tr
          className="animate-pulse border-y border-gray-200"
          key={`progress-tr-${i}`}
        >
          {table.getHeaderGroups().map((headerGroup) => {
            return headerGroup.headers.map((header) => {
              const suspense = (header.column.columnDef.meta as ColumnMeta)
                ?.suspense;
              if (suspense) {
                return suspense();
              }
              return (
                <td
                  style={{ height: rowHeight }}
                  key={`progress-td-${headerGroup.id + header.id}`}
                  className="relative animate-pulse text-sm font-medium text-gray-900"
                >
                  <div className="h-2 w-1/2 rounded bg-gray-400 pl-4"></div>
                </td>
              );
            });
          })}
        </tr>
      ))
    );
  }, [isLoading, rowHeight, table]);

  const renderEmptySet = useCallback(() => {
    return (
      !isLoading &&
      data.length === 0 && (
        <tr className={classNames("border-y-[1px] border-gray-200")}>
          {table.getHeaderGroups().map((headerGroup) => {
            return headerGroup.headers.map((header, headerIdx) => {
              return (
                <td
                  key={`empty-td-${headerGroup.id + header.id}`}
                  className="relative h-16 text-sm font-medium text-gray-900"
                >
                  <div className="pl-4">{headerIdx === 0 && "No results."}</div>
                </td>
              );
            });
          })}
        </tr>
      )
    );
  }, [isLoading, table, data.length]);

  return (
    <div className="relative flex h-full max-h-full w-full max-w-full overflow-hidden">
      <div
        ref={tableContainerRef}
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        className={classNames("absolute inset-0 overflow-auto", className)}
      >
        <div style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          <table className="top-0 z-[3] w-full text-left">
            <thead
              className={classNames(
                "top-0 z-[3] w-full bg-gray-50",
                theadClassName,
              )}
            >
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <Fragment key={headerGroup.id}>
                    <tr>
                      <th
                        className="h-px bg-gray-200 p-0"
                        colSpan={headerGroup.headers.length}
                      />
                    </tr>
                    <tr
                      className="h-11 w-full items-center"
                      key={headerGroup.id}
                    >
                      {headerGroup.headers.map((header, idx) => {
                        return (
                          <th
                            key={headerGroup.id + header.id}
                            scope="col"
                            className={classNames(
                              "relative isolate text-left text-sm font-semibold text-gray-600",
                              { "pl-3": idx === 0 },
                            )}
                          >
                            <div
                              className="group inline-flex cursor-default hover:!text-gray-600"
                              onClick={(e) => {
                                e.preventDefault();
                                const handler =
                                  header.column.getToggleSortingHandler();
                                if (handler) {
                                  handler(e);
                                }
                              }}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  )}
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                    <tr>
                      <th
                        className="h-px bg-gray-200 p-0"
                        colSpan={headerGroup.headers.length}
                      />
                    </tr>
                  </Fragment>
                );
              })}
            </thead>
            <tbody>
              {renderProgressRows()}
              {renderEmptySet()}
              {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
                const row = rows[virtualRow.index] as Row<T>;
                return (
                  <tr
                    className="w-full items-center border-b border-gray-200"
                    key={row.id}
                    data-index={virtualRow.index}
                    style={{
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${
                        virtualRow.start - index * virtualRow.size
                      }px)`,
                    }}
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td
                          className="relative text-sm font-medium text-gray-900"
                          key={`${row.id}_${cell.id}`}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex py-2">{isFetching && <Spinner />}</div>
        </div>
      </div>
    </div>
  );
}
