import { EventOccurrenceDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { calendarQueryKeys } from "../calendar";

import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { eventOccurrenceToUtc } from "./eventOccurrenceToUtc";

export function useEventOccurrenceCreate(
  {
    api,
    tz,
  }: {
    api: {
      eventApi: {
        create: (arg1: unknown) => Promise<any>;
      };
    };
    tz: string;
  },
  mutationOpts?: UseMutationOptions<
    EventOccurrenceDTO,
    unknown,
    Record<string, any>
  >,
) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (newOccurrence: Record<string, any>) => {
      const response = await api.eventApi.create(
        eventOccurrenceToUtc(newOccurrence, tz),
      );
      return response.data as EventOccurrenceDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
    ...mutationOpts,
  });

  return mutation;
}
