import { cn } from "@gymflow/helpers";

import { CheckIcon } from "../icons/CheckIcon";

type CheckboxInputProps = {
  onChange: (value: boolean) => void;
  checked: boolean;
  name?: string;
  label?: string;
  containerClassName?: string;
};

export const CheckboxInput = ({
  onChange,
  checked,
  label,
  containerClassName,
  ...props
}: CheckboxInputProps) => (
  <div className={cn("flex items-center gap-3", containerClassName)}>
    <div className="relative h-5 w-5">
      <input
        type="checkbox"
        className="h-5 !min-h-0 w-5 !min-w-0"
        onChange={() => onChange(!checked)}
        checked={checked}
        {...props}
      />
      <div
        className={cn(
          "pointer-events-none absolute left-0 top-0 flex h-5 w-5 items-center justify-center rounded-md",
          {
            "bg-secondary-500": checked,
            "bg-[#ffffff] border-gray-300 border": !checked,
          },
        )}
      >
        {checked && (
          <CheckIcon
            pathClassName="stroke-[#ffffff] stroke-2"
            className="h-4 w-4"
          />
        )}
      </div>
    </div>
    {label && (
      <div className="m-0 block text-base font-medium text-gray-950">
        {label}
      </div>
    )}
  </div>
);
