import { useQuery } from '@tanstack/react-query';

import { emailTemplateQueryKeys } from './emailTemplateQueryKeys';
import { defaultPage } from '../defaultPage';
import { ApiListResponse, TemplateDTO } from '@gymflow/types';

export function useEmailTemplateList({
  api,
  opts,
}: {
  api: {
    emailTemplateApi: {
      find: (arg0: { [k: string]: any }) => Promise<any>;
    };
  };
  opts: EmailTemplateListFilter;
}) {
  const result = useQuery({
    queryKey: emailTemplateQueryKeys.list(opts),
    queryFn: async () => {
      const result = await api.emailTemplateApi.find({ ...opts });
      return result.data as ApiListResponse<TemplateDTO>;
    },
    initialData: defaultPage<TemplateDTO>,
  });
  return result;
}

interface EmailTemplateListFilter {
  readonly extraParams?: {
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
