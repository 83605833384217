// @ts-ignore
import { PARAMETER_TIME_FORMAT } from "@gymflow/common";
import {
  DATE_ONLY_FORMAT,
  iso8601WithoutTz,
  utcToZonedTime,
} from "@gymflow/helpers";
import {
  ApiListResponse,
  EmailSendingHistoryDTO,
  EventRsvpHistoryDTO,
  LeadHistoryDTO,
  MembershipSubscriptionHistoryTimelineDTO,
  NoteDashboardDTO,
  TaskDTO,
  TimelineDTO,
} from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import dateFnsParse from "date-fns/parse";
import { format, utcToZonedTime as dateFnsUtcToZonedTime } from "date-fns-tz";

import { defaultPage } from "../defaultPage";
import { memberQueryKeys } from "./memberQueryKeys";

export function useMemberTimeline(
  {
    api,
    opts,
    tz,
  }: {
    api: {
      timelineApi: {
        find: (arg0: { [k: string]: any }) => Promise<any>;
      };
    };
    opts: MemberTimelineFilter;
    tz: string;
  },
  queryOpts: UseQueryOptions<ApiListResponse<TimelineNode>>,
) {
  const result = useQuery({
    queryKey: memberQueryKeys.timeline(opts.extraParams.userMemberId, opts),
    queryFn: async () => {
      const result = await api.timelineApi.find({ ...opts });
      return result.data as ApiListResponse<TimelineNode>;
    },
    initialData: defaultPage<TimelineNode>,
    select: (data: ApiListResponse<TimelineNode>) => {
      return {
        ...data,
        content: data.content.map((r) => convertDatesToZoned(r, tz)),
      } as unknown as ApiListResponse<TimelineNode>;
    },
    ...queryOpts,
  });
  return result;
}

const convertDatesToZoned = (
  {
    sendTimestamp,
    createdDate,
    eventStartDate,
    deadlineDate,
    deadlineTime,
    transactionDate,
    ...rest
  }: {
    sendTimestamp?: string;
    createdDate?: string;
    eventStartDate?: string;
    deadlineDate?: string;
    deadlineTime?: string;
    transactionDate?: string;
  },
  tz: string,
) => {
  const deadline =
    deadlineDate &&
    deadlineTime &&
    dateFnsParse(
      utcToZonedTime(`${deadlineDate}T${deadlineTime}Z`, tz),
      iso8601WithoutTz,
      new Date(),
    );

  return {
    ...rest,
    sendTimestamp,
    sendEmailDate:
      sendTimestamp &&
      dateFnsUtcToZonedTime(Math.trunc(+sendTimestamp) * 1000, tz),
    createdDate: createdDate && utcToZonedTime(createdDate, tz),
    eventStartDate: eventStartDate && utcToZonedTime(eventStartDate, tz),
    deadlineDate: deadline && format(deadline, DATE_ONLY_FORMAT),
    deadlineTime: deadline && format(deadline, PARAMETER_TIME_FORMAT),
    transactionDate: transactionDate && utcToZonedTime(transactionDate, tz),
  };
};

export type TimelineNode =
  | MembershipSubscriptionHistoryTimelineDTO
  | TaskDTO
  | TimelineDTO
  | LeadHistoryDTO
  | EmailSendingHistoryDTO
  | EventRsvpHistoryDTO
  | NoteDashboardDTO;

interface MemberTimelineFilter {
  readonly extraParams: {
    readonly userMemberId: string;
    readonly [k: string]: any;
  };
  readonly page?: number;
  readonly limit?: number;
  readonly sort?: {
    readonly field: string;
    readonly desc?: boolean;
  };
}
