import { ApiType } from "@gymflow/common/lib/api/ApiType";
import {
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";

import { availabilityQueryKeys } from "../availability";
import { calendarQueryKeys } from "../calendar";
import { appointmentQueryKeys } from "./appointmentQueryKeys";

export function useMutationRecurringAppointmentCreate({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (
      newAppointment: Parameters<
        ApiType["appointmentApi"]["createRecurring"]
      >[0],
    ) => {
      const response = await api.appointmentApi.createRecurring(newAppointment);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: appointmentQueryKeys.all() });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.appointments(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailability(),
      });
      queryClient.invalidateQueries({
        queryKey: availabilityQueryKeys.hostCalculateAvailabilityMappedByHost(),
      });
    },
  });

  return mutation;
}
