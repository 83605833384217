import { useAutomationDisable, useAutomationEditBcc, useAutomationEnable, useAutomationList } from "@gymflow/api";
import {
  AlertContext,
  AsyncButton,
  AutomationPurpose,
  CardBodyWithSpinner,
  isMobile,
  TemplateType,
} from "@gymflow/common";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactTable from "react-table";
import { Button, Card } from "reactstrap";

import { ToastContext } from "../../providers/ToastProvider/context";
import useGymflowModels from "../../store";
import Switch from "../Switch";
import AddBCCAlert from "./AddBCCAlert";
import useTemplateAlert from "./useTemplateAlert";

export function Automations() {
  const { api } = useGymflowModels();
  const { data: automations, isFetching: isLoading } = useAutomationList({ api });
  const enableAutomation = useAutomationEnable({ api });
  const disableAutomation = useAutomationDisable({ api });
  const updateBcc = useAutomationEditBcc({ api });
  const alert = useContext(AlertContext);
  const { toast, notifyDanger } = useContext(ToastContext);
  const [checkedMap, setCheckedMap] = useState({});

  const { setEditingTemplateId } = useTemplateAlert({ readOnlyTitle: true });

  useEffect(() => {
    const status = automations.reduce((acc, row) => {
      acc[row.id] = row.isActive;
      return acc;
    }, {});
    setCheckedMap(status);
  }, [automations]);

  const handleAddBCC = useCallback(
    (id, bccList) => {
      return () => {
        alert.setAlert(
          <AddBCCAlert
            bcc={bccList}
            onSubmit={async (values) => {
              try {
                await updateBcc.mutateAsync({ automationId: id, bccList: values.bcc ? values.bcc.split(",") : [] });
                alert.hide();
              } catch (err) {
                notifyDanger(err);
              }
            }}
            onCancel={alert.hide}
          />,
        );
      };
    },
    [alert, notifyDanger, updateBcc],
  );

  const tableDefs = [
    {
      id: "name",
      Header: <h3>Trigger</h3>,
      accessor: (row) => row,
      Cell: ({ value: { name, description } }) => (
        <div>
          <div>{name}</div>
          <div className="text-muted font-size-sm">{description}</div>
        </div>
      ),
    },
    {
      id: "type",
      Header: <h3>Type</h3>,
      accessor: (row) => TemplateType.humanize(row.template?.templateType),
      width: 100,
      show: !isMobile(),
    },
    {
      id: "audience",
      Header: <h3>Audience</h3>,
      accessor: (row) => row,
      Cell: ({ value: { audience } }) => <div style={{ textTransform: "capitalize" }}>{audience?.toLowerCase()}</div>,
      width: 150,
      show: !isMobile(),
    },
    {
      id: "message",
      Header: <h3>Message Type</h3>,
      accessor: ({ purpose }) => AutomationPurpose.humanize(purpose),
      width: 150,
      show: !isMobile(),
    },
    {
      id: "actions",
      Header: (
        <div style={{ textAlign: "center", width: "100%" }}>
          <h3>Action</h3>
        </div>
      ),
      accessor: (row) => row,
      width: isMobile() ? 110 : 150,
      Cell: ({
        value: {
          id,
          bccList,
          template: { id: templateId },
        },
      }) => {
        return (
          <div className="d-flex justify-content-center">
            <AsyncButton className="btn-link" onClick={() => setEditingTemplateId(templateId)}>
              Edit
            </AsyncButton>
            <Button className="btn-link" onClick={handleAddBCC(id, bccList)}>
              BCC
            </Button>
          </div>
        );
      },
    },
    {
      id: "isActive",
      Header: <h3>Status</h3>,
      width: isMobile() ? 60 : 100,
      accessor: (row) => row,
      Cell: ({ value: { id } }) => {
        return (
          <Switch
            size="sm"
            checked={checkedMap[id]}
            onChange={async (checked) => {
              checkedMap[id] = checked;
              setCheckedMap({ ...checkedMap });
              if (checked) {
                await enableAutomation.mutateAsync(id);
                toast({ message: "Automation enabled successfully." });
              } else {
                await disableAutomation.mutateAsync(id);
                toast({ message: "Automation disabled successfully." });
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="relative flex h-full max-h-full w-full flex-col">
      <Card className="absolute inset-0 flex overflow-hidden">
        <CardBodyWithSpinner isLoading={isLoading} className="max-h-full overflow-y-auto">
          <ReactTable
            className="normal-table  overscroll-y-auto"
            columns={tableDefs}
            data={automations}
            showPagination={false}
            pageSize={automations.length}
            sortable={false}
          />
        </CardBodyWithSpinner>
      </Card>
    </div>
  );
}
