import { AxiosAuthenticationLoader } from "@gymflow/api";
import { UserRole } from "@gymflow/types";
import { useStoreActions } from "easy-peasy";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import placeholder from "../../../assets/img/placeholder.jpg";
import { LoadingPortal } from "../../components/pages";
import { usePortalRoutes } from "../../hooks/usePortalRoutes";
import { useRememberMe } from "../../hooks/useRememberMe";
import { RouteFeature } from "../../routes/feature";
import { RouteLayout } from "../../routes/layout";
import useGymflowModels from "../../store";

export interface AuthenticatedUser {
  id?: string;
  login: (username: string, password: string) => Promise<AuthState>;
  logout: () => Promise<void>;
  refreshRoles: () => Promise<UserRole[] | null>;
  roles: UserRole[];
}

export interface AuthState {
  isLoggedIn: boolean;
  id?: string;
  roles: UserRole[] | null;
}

export const AuthenticatedUserContext = createContext<AuthenticatedUser>({
  login: () => {
    throw new Error("Login called outside of context.");
  },
  logout: () => {
    throw new Error("Logout called outside of context.");
  },
  refreshRoles: () => {
    throw new Error("getRoles called outside of context.");
  },
  roles: [],
});

export function AuthenticatedProvider({
  authController,
  children,
}: {
  authController: AxiosAuthenticationLoader;
  children: ReactNode | ((properties: AuthenticatedUser) => ReactNode);
}) {
  const { authStore, settingsStore } = useGymflowModels();

  const history = useHistory();
  const { createLoginWithRedirectHistoryState, createClubLink } =
    usePortalRoutes();
  const { forgetRoute } = useRememberMe();

  const askForLogin = useCallback(() => {
    forgetRoute();
    history.push(createLoginWithRedirectHistoryState());
  }, []);

  const { init } = useStoreActions(authStore);
  const { fetchValues } = useStoreActions(settingsStore);

  const [user, setUser] = useState<{
    isLoggedIn: boolean;
    id?: string;
    roles: UserRole[] | null;
  }>({
    isLoggedIn: false,
    roles: [],
  });

  const [areSettingsLoaded, setAreSettingsLoaded] = useState(false);
  useEffect(() => {
    (async () => {
      if (!authController) {
        return;
      }
      const isAuthenticated = await authController.loadAuthentication();

      if (!isAuthenticated) {
        askForLogin();
        return;
      }

      await init({ placeholder });

      try {
        await fetchValues();
        setAreSettingsLoaded(true);

        authController.eventEmitter.on("logout", () => {
          askForLogin();
        });

        const userId = await authController.getUserId();
        const roles = await authController.getRoles();
        setUser({ id: userId, isLoggedIn: isAuthenticated, roles });
      } catch {
        history.push(
          createClubLink(RouteLayout.Authentication, RouteFeature.Unauthorized),
        );
      }
    })();
    return () => {
      setAreSettingsLoaded(false);
    };
  }, []);

  if (!user.isLoggedIn) {
    return <LoadingPortal message="Processing user credentials..." />;
  }

  if (!areSettingsLoaded) {
    return <LoadingPortal message="Processing user details..." />;
  }

  const contextProperties = {
    id: user.id,
    login: async (username: string, password: string) => {
      if (!authController?.login) {
        return { isLoggedIn: false, roles: [] };
      }
      let userId, roles;
      try {
        await authController?.login(username, password);

        userId = await authController.getUserId();
        roles = await authController.getRoles();
        setUser({ id: userId, isLoggedIn: true, roles });
      } catch {
        return { isLoggedIn: false, roles: [] };
      }
      return {
        isLoggedIn: true,
        roles,
        id: userId,
      };
    },
    logout: () => authController?.logout(),
    refreshRoles: authController?.getRoles,
    roles: user.roles ?? [],
  };
  return (
    <AuthenticatedUserContext.Provider value={contextProperties}>
      {typeof children === "function" ? children(contextProperties) : children}
    </AuthenticatedUserContext.Provider>
  );
}

export function useAuthenticatedUser() {
  return useContext(AuthenticatedUserContext);
}
