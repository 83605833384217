import { createContext } from "react";

import { TaskFormValues } from "./components/TaskForm/types";

export type OpenCreateSidebarOptions = {
  initialValues?: Partial<TaskFormValues>;
};

export type OpenEditSidebarOptions = {
  taskId: number;
  onUpdate?: () => void;
};

interface CreateEditTaskSidebarProviderContextType {
  openCreateTaskSidebar: (options?: OpenCreateSidebarOptions) => void;
  openEditTaskSidebar: (options: OpenEditSidebarOptions) => void;
}

const defaultContext: CreateEditTaskSidebarProviderContextType = {
  openCreateTaskSidebar: () => {
    throw new Error(
      "openCreateTaskSidebar function must be used within CreateEditTaskSidebarProvider",
    );
  },
  openEditTaskSidebar: () => {
    throw new Error(
      "openEditTaskSidebar function must be used within CreateEditTaskSidebarProvider",
    );
  },
};

export const CreateEditTaskSidebarProviderContext =
  createContext<CreateEditTaskSidebarProviderContextType>(defaultContext);
