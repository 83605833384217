import moment from 'moment-timezone';
import { DATE_FORMAT, FormMapper, PARAMETER_DATE_ONLY_FORMAT } from '@gymflow/common';
import {
  DISCOUNT_KIND,
  UPFRONT_DISCOUNT,
  RECURRING_DISCOUNT,
  BOTH_DISCOUNT,
  RECURRING_DISCOUNT_DURATION,
  IS_DURATION_MULTI_CYCLES,
} from './PromotionSchema';

class PromotionFormMapper extends FormMapper {
  constructor() {
    super({
      inValue: [
        {
          key: 'expiry',
          transform: (date) => moment(date).format(DATE_FORMAT),
        },
      ],
    });
  }

  to(values) {
    const transformed = super.to(values);
    if (transformed[UPFRONT_DISCOUNT] && transformed[RECURRING_DISCOUNT]) {
      transformed[DISCOUNT_KIND] = BOTH_DISCOUNT;
      if (transformed[RECURRING_DISCOUNT_DURATION]) {
        transformed[IS_DURATION_MULTI_CYCLES] = true;
      }
    } else if (transformed[UPFRONT_DISCOUNT]) {
      transformed[DISCOUNT_KIND] = UPFRONT_DISCOUNT;
      transformed[IS_DURATION_MULTI_CYCLES] = false;
    } else if (transformed[RECURRING_DISCOUNT]) {
      transformed[DISCOUNT_KIND] = RECURRING_DISCOUNT;
      if (transformed[RECURRING_DISCOUNT_DURATION]) {
        transformed[IS_DURATION_MULTI_CYCLES] = true;
      }
    }

    return transformed;
  }

  from(formValues) {
    const transformed = super.from(formValues);
    if (transformed.discountKind === BOTH_DISCOUNT) {
      transformed.upfrontDiscount = true;
      transformed.recurringDiscount = true;
    } else if (transformed.discountKind === UPFRONT_DISCOUNT) {
      transformed.upfrontDiscount = true;
      transformed.recurringDiscount = false;
      transformed.recurringDiscountAmount = null;
      transformed.recurringDiscountType = null;
      transformed.recurringDiscountDuration = null;
    } else if (transformed.discountKind === RECURRING_DISCOUNT) {
      transformed.recurringDiscount = true;
      transformed.upfrontDiscount = false;
      transformed.upfrontDiscountAmount = null;
      transformed.upfrontDiscountType = null;
      transformed.upfrontDiscountProrata = false;
    }

    if (!transformed.isDurationMultiCycles) {
      transformed.recurringDiscountDuration = null;
    }

    if (transformed.limitUsagesPerMember === '') {
      transformed.limitUsagesPerMember = null;
    }

    if (transformed.limitUsages === '') {
      transformed.limitUsages = null;
    }

    if (transformed.expiry === '') {
      transformed.expiry = null;
    } else {
      transformed.expiry = moment(transformed.expiry, DATE_FORMAT)
        .format(PARAMETER_DATE_ONLY_FORMAT)
    }

    if (transformed.limitSessionPackId === '') {
      transformed.limitSessionPackId = null;
    }

    if (transformed.limitProductId === '') {
      transformed.limitProductId = null;
    }

    if (transformed.limitMembershipId === '') {
      transformed.limitMembershipId = null;
    }

    delete transformed.discountKind;
    delete transformed.isDurationMultiCycles;

    return transformed;
  }
}

export default PromotionFormMapper;
