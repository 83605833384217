/* eslint-disable react/prop-types */
import { useMemberSubscription } from "@gymflow/api";
import { DATE_FORMAT, useParseErrors } from "@gymflow/common";
import { iso8601WithoutTz } from "@gymflow/helpers";
import { UserMemberSubscriptionBeanWithMembership } from "@gymflow/types";
import { ModalContext, useClubSettings } from "apps/portal/src/providers";
import useGymflowModels from "apps/portal/src/store";
import { endOfDay, format, parse } from "date-fns";
import { cn } from "libs/common/src/lib/helpers/cn";
import moment, { Moment } from "moment";
import React, { useCallback, useContext, useState } from "react";
import Datetime from "react-datetime";

import { ConfirmModal } from "../../../templates";
import { NotPrimaryMembershipWarning } from "../NotPrimaryMembershipWarning";

export interface ExpireMembershipModalProps {
  memberId: string;
  membership: UserMemberSubscriptionBeanWithMembership;
}

export const ExpireMembershipModal: React.FC<ExpireMembershipModalProps> = ({
  memberId,
  membership,
}) => {
  const todayInDateFormat = moment().format(DATE_FORMAT);
  const { timezone } = useClubSettings();
  const { api } = useGymflowModels();

  const parseError = useParseErrors();
  const { expireSubscriptionMutation } = useMemberSubscription(
    { api, tz: timezone },
    {
      onError: (error) => {
        parseError((error as any)?.response);
      },
    },
  );
  const { hide } = useContext(ModalContext);
  const [expiryDate, setExpiryDate] = useState<string | null>(null);
  const isValidDate = useCallback(
    (date: Moment) => date.isAfter(moment().add(-1, "day")),
    [],
  );
  return (
    <ConfirmModal
      onConfirm={async () => {
        await expireSubscriptionMutation.mutateAsync({
          subscriptionId: membership.id,
          memberId: memberId,
          ...(expiryDate
            ? {
                expiredDate: format(
                  endOfDay(parse(expiryDate, "dd/MM/yyyy", new Date())),
                  iso8601WithoutTz,
                ),
              }
            : {}),
        });
        hide();
      }}
      title="Update Expiry Date"
      type="default"
      onCancel={() => {
        hide();
      }}
    >
      <div className="flex flex-col gap-4">
        <div>
          Select a new expiry date, if you select today the membership will be
          expired immediately.
        </div>
        <div
          className={cn(
            "relative flex h-11 w-full cursor-pointer flex-row items-center rounded-lg border border-gray-200 bg-white text-center text-gray-600 hover:bg-gray-100",
          )}
        >
          <Datetime
            dateFormat={DATE_FORMAT}
            timeFormat={false}
            onChange={(v: Moment | string) => {
              if (typeof v !== "string") {
                if (v.format(DATE_FORMAT) === todayInDateFormat) {
                  setExpiryDate(null);
                } else {
                  setExpiryDate(v.format(DATE_FORMAT));
                }
              }
            }}
            value={expiryDate ?? undefined}
            closeOnSelect
            isValidDate={isValidDate}
            renderDay={(props, currentDate: Moment, selectedDate: Moment) => {
              return (
                <td
                  {...props}
                  className={cn(props?.className, "relative", {
                    "opacity-20": !isValidDate(currentDate),
                    "!bg-secondary-500 hover:!bg-secondary-600 text-white":
                      currentDate?.format(DATE_FORMAT) ===
                      selectedDate?.format(DATE_FORMAT),
                  })}
                >
                  {currentDate.date()}
                </td>
              );
            }}
            className={cn("h-full w-full px-4")}
            inputProps={{
              readOnly: true,
              placeholder: "Today",
              value: expiryDate ?? "Today",
              className: cn(
                "h-full w-full bg-white text-gray-600 font-medium border-0 focus:outline-none cursor-pointer text-gray-600 placeholder:text-gray-600",
              ),
            }}
          />
        </div>
        <NotPrimaryMembershipWarning membership={membership} />
      </div>
    </ConfirmModal>
  );
};
