import { MembershipBean, MembershipSummaryDTO } from "@gymflow/types";
import { AxiosInstance } from "axios";

export type CustomerMembershipApiType = ReturnType<
  typeof customerMembershipApi
>;

const customerMembershipApi = (
  axiosInstance: AxiosInstance,
  clubId: number,
) => {
  const baseUrl = `club/${clubId}/customer/membership`;

  return {
    getById(id: number) {
      return axiosInstance.get<MembershipBean>(`${baseUrl}/${id}`);
    },
    summary(params: { membershipId: number; promotionCode?: string }) {
      return axiosInstance.post<MembershipSummaryDTO>(
        `${baseUrl}/summary`,
        params,
      );
    },
  };
};

export default customerMembershipApi;
