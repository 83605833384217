import { Iso2Country, Iso3Country, UserFormRule } from "@gymflow/types";
import { validatePhoneNumber, validatePostCode } from "@gymflow/validation";
import isBefore from "date-fns/isBefore";
import moment from "moment-timezone";
import { z, ZodType } from "zod";

function option<T extends ZodType>(isOptional: boolean, source: T) {
  if (isOptional) return source.optional();
  return source;
}

export const generateSchema = ({
  rules,
  defaultNationality,
  postCodeFormat,
  dateFormat,
}: {
  rules?: UserFormRule;
  defaultNationality: Iso3Country;
  postCodeFormat: Iso2Country;
  dateFormat: string;
}) => {
  return z
    .object({
      email: z.string().toLowerCase().email().max(50),
      password: z.string().min(4, "Password must have at least 4 characters."),
      "confirm-password": z
        .string()
        .min(4, "Password must have at least 4 characters."),
      "first-name": z
        .string()
        .trim()
        .min(2, "First Name has to have at least 2 characters.")
        .max(50, "First Name cannot have more than 50 characters."),
      "last-name": z
        .string()
        .trim()
        .min(2, "Last Name has to have at least 2 characters.")
        .max(50, "Last Name cannot have more than 50 characters."),
      "mobile-number": option(
        !rules?.mobileNumber.isRequired,
        z.string().min(1, {
          message: `Mobile number is invalid.`,
        }),
      ).refine(
        (e) => !rules?.mobileNumber.isRequired || validatePhoneNumber(e),
        {
          message: `Mobile number is invalid`,
        },
      ),

      "date-birth": option(!rules?.dateOfBirth.isRequired, z.string()).refine(
        (e) => {
          return (
            e === undefined ||
            isBefore(moment(e, dateFormat).toDate(), new Date(Date.now()))
          );
        },
        {
          message: "Date of birth can't be after today.",
        },
      ),
      "post-code": option(!rules?.postCode.isRequired, z.string()).refine(
        (e) => e === undefined || validatePostCode(e, postCodeFormat),
        {
          message: `Invalid Post code`,
        },
      ),
      "address-line1": option(
        !rules?.addressLine.isRequired,
        z.string().trim().min(3).max(250),
      ),
      "address-line2": z.string().max(250).trim().optional(),
      city: option(!rules?.city.isRequired, z.string().trim().min(3)),
      country: z
        .string()
        .trim()
        .min(3)
        .max(3)
        .optional()
        .default(defaultNationality),
      gender: option(
        !rules?.gender.isRequired,
        z.enum(["MALE", "FEMALE", "PREFER_NOT_TO_SAY"]),
      ),
      "source-id": z.object({ value: z.number().positive() }),
      "email-communication": z.boolean().default(false),
      "sms-communication": z.boolean().default(false),
      "push-communication": z.boolean().default(false),
      "emergency-contact-name": option(
        !rules?.emergencyContact.isRequired,
        z.string().min(3),
      ),
      "emergency-contact": option(
        !rules?.emergencyContact.isRequired,
        z.string().max(100),
      ).refine(
        (e) => !rules?.emergencyContact.isRequired || validatePhoneNumber(e),
        {
          message: `Emergency Contact is invalid.`,
        },
      ),
      "is-club-waiver-accepted": z
        .literal<boolean>(true, {
          errorMap: () => ({
            message: "You must accept the club waiver.",
          }),
        })
        .default(false),
    })
    .refine(
      (user) => {
        if (!rules?.mobileNumber.isRequired) return true;
        if (!rules?.emergencyContact.isRequired) return true;
        if (user["mobile-number"] === user["emergency-contact"]) return false;
        return true;
      },
      {
        path: ["emergency-contact"],
        message:
          "Emergency contact cannot be the same as your regular contact.",
      },
    )
    .refine(
      (user) => {
        if (!rules?.emergencyContact.isRequired) return true;
        if (!user["emergency-contact-name"] && !user["emergency-contact"])
          return true;
        if (!user["emergency-contact-name"] && !!user["emergency-contact"])
          return false;
        return true;
      },
      {
        path: ["emergency-contact-name"],
        message: "Emergency Contact Name is required.",
      },
    )
    .refine(
      (user) => {
        if (!rules?.emergencyContact.isRequired) return true;
        if (!user["emergency-contact-name"] && !user["emergency-contact"])
          return true;
        if (!!user["emergency-contact-name"] && !user["emergency-contact"])
          return false;
        return true;
      },
      {
        path: ["emergency-contact"],
        message: "Emergency contact is required.",
      },
    )
    .refine(
      (values) => {
        return values.password === values["confirm-password"];
      },
      {
        path: ["password"],
        message: "Passwords must match!",
      },
    );
};

export type SignUpSchema = z.infer<ReturnType<typeof generateSchema>>;
export type SignUpSchemaKey = keyof SignUpSchema;
