import { pluralize } from "@gymflow/helpers";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { MembershipBillingType } from "../../constants/MembershipBillingType";
import MediumText from "../atoms/MediumText";

function MembershipCard(props) {
  const {
    name,
    description,
    price,
    isRecurring,
    includes,
    plus,
    selectBtnText,
    selectBtnDisabled,
    selectBtnClassName,
    onButtonClick,
    billingType,
    billingPeriod,
  } = props;

  const renderRecurringCycle = () => {
    switch (billingType) {
      case MembershipBillingType.Monthly:
        return `Per ${billingPeriod !== 1 ? billingPeriod : ""} ${pluralize("month", "months", billingPeriod)}`;
      case MembershipBillingType.Weekly:
        return `Per ${billingPeriod !== 1 ? billingPeriod : ""} ${pluralize("week", "weeks", billingPeriod)}`;
      default:
        throw new Error("Unknown MembershipBillingType.");
    }
  };

  return (
    <Card className="h-100">
      <CardBody className="d-flex flex-column flex-nowrap">
        <Row>
          <Col className="text-center">
            <MediumText className="font-weight-bold">{name}</MediumText>
          </Col>
        </Row>
        <Row className={classNames("mt-3", { "d-none": !description })}>
          <Col className="text-center">{description}</Col>
        </Row>
        <Row className="mt-3">
          <Col className="text-center">
            <MediumText className="font-weight-bold">{price}</MediumText>
          </Col>
        </Row>
        <Row className={classNames({ "d-none": !isRecurring })}>
          <Col className="text-muted text-center">{renderRecurringCycle()}</Col>
        </Row>
        {plus.map((item) => (
          <Row className="text-muted" key={item.name}>
            <Col className="text-center">
              + {item.name} {item.price}
            </Col>
          </Row>
        ))}
        <Row className={classNames("mt-3", { "d-none": !includes.length })}>
          <Col className="text-center">
            <MediumText className="font-weight-bold">Includes</MediumText>
          </Col>
        </Row>
        {includes.map((item) => (
          <Row key={item}>
            <Col className="text-center">{item}</Col>
          </Row>
        ))}
        <Row className="mt-3" />
        <Row className="mt-auto">
          <Col className="text-center">
            <Button
              className={classNames("font-weight-bold", "ml-auto", "mr-auto", selectBtnClassName, {
                "opacity-50": selectBtnDisabled,
              })}
              disabled={selectBtnDisabled}
              color="primary"
              size="sm"
              style={{ width: "140px" }}
              onClick={onButtonClick}
            >
              {selectBtnText}
            </Button>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}

MembershipCard.defaultProps = {
  description: "",
  isRecurring: false,
  includes: [],
  plus: [],
  selectBtnDisabled: false,
  selectBtnText: "Select",
  billingType: MembershipBillingType.Monthly,
  billingPeriod: 1,
};

MembershipCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  price: PropTypes.string.isRequired,
  isRecurring: PropTypes.bool,
  billingType: PropTypes.oneOf(Object.values(MembershipBillingType)),
  billingPeriod: PropTypes.number,
  plus: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      price: PropTypes.string.isRequired,
    }),
  ),
  includes: PropTypes.arrayOf(PropTypes.string),
  onButtonClick: PropTypes.func.isRequired,
};

export default MembershipCard;
