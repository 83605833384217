import {
  ActivityStatus,
  ApiListResponse,
  AppointableCategoryDTO,
  PaginationOptions,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { appointableQueryKeys } from "./appointableQueryKeys";

export const appointableCategoriesQueryFn = async ({
  api,
  filter,
  paginationOption,
}: {
  api: AppointableCategoriesApi;
  paginationOption: PaginationOptions;
  filter?: {
    statusList?: ActivityStatus[];
  };
}) => {
  const result = await api.appointableApi.fetchCategories({
    ...paginationOption,
    ...filter,
  });
  return result.data;
};

export function useAppointableCategories({
  api,
  paginationOption,
  filter,
}: {
  api: AppointableCategoriesApi;
  paginationOption: PaginationOptions;
  filter?: {
    statusList?: ActivityStatus[];
  };
}) {
  const result = useQuery({
    queryKey: appointableQueryKeys.categories({
      ...paginationOption,
      ...filter,
    }),
    initialData: defaultPage<AppointableCategoryDTO>(),
    queryFn: () =>
      appointableCategoriesQueryFn({ api, paginationOption, filter }),
  });

  return result;
}

interface AppointableCategoriesApi {
  appointableApi: {
    fetchCategories: (
      params: any,
    ) => Promise<{ data: ApiListResponse<AppointableCategoryDTO> }>;
  };
}
