const canSignUp = () => ({
  createRequestBody(fields) {
    const body = {
      ...fields,
      subscriptionSignUpBean: {},
    };

    moveFieldIfExists(body, body.subscriptionSignUpBean, "startDate");
    moveFieldIfExists(body, body.subscriptionSignUpBean, "promotionCode");
    moveFieldIfExists(body, body.subscriptionSignUpBean, "membershipId");
    moveFieldIfExists(body, body.subscriptionSignUpBean, "sessionPackId");

    if (Object.keys(body.subscriptionSignUpBean).length === 0) {
      delete body.subscriptionSignUpBean;
    }

    return body;
  },
});

/**
 * Moves field from one object to another. The key from the original
 * object will be removed. "to" will be changed.
 *
 * @param {Object} from - Original object where to move the field from
 * @param {Object} to - Object to move the field value to.
 * @param {string} field - Field name
 * @returns {bool} true if the field was found and moved. False otherwise.
 */
const moveFieldIfExists = (from, to, field) => {
  if (from[field]) {
    to[field] = from[field];
    delete from[field];
    return true;
  }
  return false;
};

export default canSignUp;
