import { useMemberAsMember } from "@gymflow/api";
import { useUserFormFieldConfiguration } from "@gymflow/common";

import { UserMemberProfile } from "../components/UserMember/UserMemberProfile";
import { useAuthenticatedUser, useClubSettings } from "../providers";
import useGymflowModels from "../store";

const Profile = () => {
  const { id } = useAuthenticatedUser();
  const { api } = useGymflowModels();
  const settings = useClubSettings();
  const { data: userMember } = useMemberAsMember({
    api,
    tz: settings.timezone,
    memberId: id,
  });
  const { data: requiredFields } = useUserFormFieldConfiguration({
    clubId: settings.clubId,
    api: {
      ruleApi: api.public.ruleApi,
    },
  });

  return (
    <div className="content h-full overflow-y-auto p-8">
      {!userMember?.isFullUser && (
        <span>
          You cannot edit your profile until you buy a membership or credit pack
          once.
        </span>
      )}

      {userMember && (
        <UserMemberProfile
          user={userMember}
          requiredFields={requiredFields as any}
          asMember
        />
      )}
    </div>
  );
};

export default Profile;
