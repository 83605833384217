import { assert } from "check-types";

import endpoints from "./endpoints";
import {
  canActivateAndDeactivate,
  canFind,
  canFindById,
  canTrimFields,
  canUpdate,
} from "./features/apiResource";

const activityEndpoints = {
  findActive: `${endpoints.activity}/active`,
  /**
   * @deprecated
   */
  categoryCatalog: "catalog/activity/category",
};

const activityApi = (axiosInstance, apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    fieldsToTrim: ["name", "description"],
    baseUrl: `${clubPrefix}${endpoints.activity}`,
    apiUrl,
    http: axiosInstance,
  };

  return Object.assign(
    state,
    canTrimFields(state),
    canFind(state),
    canFindById(state),
    canUpdate(state),
    canActivateAndDeactivate(state),
    {
      create(newResource) {
        const trimmedData = state.trimFields(newResource);

        return state.http.post(state.baseUrl, { ...trimmedData, clubId });
      },

      findActive({ page = 0, limit = 10, extraParams = {}, sort }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          params: {
            page,
            size: limit,
            ...extraParams,
          },
        };
        if (sort) {
          assert.string(
            sort.field,
            "sort.field must be a string if sort is specified.",
          );
          config.params.sort = state.createSortParam(sort.field, sort.desc);
        }

        return state.http.get(
          `${clubPrefix}${activityEndpoints.findActive}`,
          config,
        );
      },

      findCategories({ page = 0, limit = 10, status }) {
        assert.maybe.number(page, "page must be number or undefined");
        assert.maybe.number(limit, "limit must be number or undefined");

        const config = {
          page,
          size: limit,
          statusList: status,
        };

        return state.http.put(`${state.baseUrl}/category/search`, config);
      },

      findCategoryById(id) {
        assert(
          check.any([check.string(id), check.number(id)]),
          "id must be number or string",
        );
        return state.http.get(`${state.baseUrl}/category/${id}`);
      },

      createCategory(newCategory) {
        return state.http.post(`${state.baseUrl}/category`, newCategory);
      },

      updateCategory(id, patchedFields) {
        return state.http.patch(
          `${state.baseUrl}/category/${id}`,
          patchedFields,
        );
      },

      updateCategoryStatus(id, newStatus) {
        return state.http.patch(`${state.baseUrl}/category/${id}/status`, {
          status: newStatus,
        });
      },

      /**
       * @deprecated
       */
      async fetchCategories() {
        const result = await state.http.put(
          state.baseUrl + "/category/search",
          { page: 0, size: 1000 },
        );
        result.data = result.data.content;
        return result;
      },
    },
  );
};

export default activityApi;
