import { useMutation, useQueryClient } from '@tanstack/react-query';
import { staffQueryKeys } from './staffQueryKeys';

export function useStaffEdit({
  api,
}: {
  api: {
    staffApi: {
      update: (arg0: string, arg1: unknown) => Promise<any>;
      updatePicture: (arg0: string, arg1: unknown) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      staffId,
      patchedFields,
    }: {
      staffId: string;
      patchedFields: { [k: string]: any };
    }) => {
      if (patchedFields['picture'] && patchedFields['picture'].blob) {
        await api.staffApi.updatePicture(staffId, patchedFields['picture']);
      }

      delete patchedFields['picture'];
      await api.staffApi.update(staffId, patchedFields);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: staffQueryKeys.all() });
    },
  });
  return mutation;
}
