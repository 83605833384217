import { z } from "zod";

export function getDefaultsFromZodSchema<
  Schema extends z.ZodEffects<any, any, any> | z.ZodType,
>(schema: Schema) {
  let innerSchema = schema as any;
  while (!("shape" in innerSchema)) innerSchema = innerSchema.innerType();

  return Object.fromEntries(
    Object.entries(innerSchema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
}
