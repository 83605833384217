import {
  ActivityCategoryDTO,
  ActivityCategoryStatus,
  ApiListResponse,
} from "@gymflow/types";
import { useQuery } from "@tanstack/react-query";

import { defaultPage } from "../defaultPage";
import { activityQueryKeys } from "./activityQueryKeys";

export async function activityCategoryListQueryFn({
  api,
  filter,
}: {
  api: ActivityCategoryListApi;
  filter: ActivityCategoryListFilter;
}) {
  const result = await api.activityApi.findCategories(filter);
  return result.data;
}

export function useActivityCategoryList({
  api,
  filter,
}: {
  api: ActivityCategoryListApi;
  filter: ActivityCategoryListFilter;
}) {
  const result = useQuery({
    queryKey: activityQueryKeys.categories(filter),
    queryFn: () => activityCategoryListQueryFn({ api, filter }),
    initialData: defaultPage<ActivityCategoryDTO>,
  });
  return result;
}

export interface ActivityCategoryListFilter {
  readonly page?: number;
  readonly limit?: number;
  readonly status?: ActivityCategoryStatus[];
}

interface ActivityCategoryListApi {
  activityApi: {
    findCategories: (filter: {
      page?: number;
      limit?: number;
    }) => Promise<{ data: ApiListResponse<ActivityCategoryDTO> }>;
  };
}
