import { action, thunk, thunkOn } from 'easy-peasy';
import moment from 'moment-timezone';

import BaseModelBuilder, { createPayloadFromFindResponse, getApi } from './BaseModelBuilder';
import { tzDateTimeStringToUtc, utcDateTimeStringToTz } from '../helpers/date';
import { PARAMETER_TIME_FORMAT, PARAMETER_DATE_ONLY_FORMAT } from '../api/eventApi';

class EventModelBuilder extends BaseModelBuilder {
  constructor(apiKey, clubId, settingsKey = 'settings') {
    super(apiKey);
    this.clubId = clubId;
    this.settingsKey = settingsKey;
    this.generators.push(this.eventGenerator);
  }

  /**
   * @private
   */
  eventGenerator() {
    return {
      last: false,
      create: thunk(
        /**
         * @param actions
         * @param fields
         * @returns {Promise<*>}
         */
        (_, fields, { injections, getState }) => {
          const tz = injections.globalStore.getState()[this.settingsKey].timezone;
          const newFields = {
            ...fields,
            ...convertChangedEventDatesToUtc(fields, tz),
          };

          return getApi(injections, getState()).create({
            ...newFields,
            clubId: this.clubId,
          });
        }
      ),
      fetchById: thunk(async (actions, recordId, { getState, injections }) => {
        const tz = injections.globalStore.getState()[this.settingsKey].timezone;
        const { data } = await getApi(injections, getState()).findById(recordId);
        const payload = {
          ...data,
        };
        actions.fetchedRecord(convertEventDatesToTz(payload, tz));
        return payload;
      }),

      fetchList: thunk(
        async (
          actions,
          { dateFrom, dateTo, page = 0, limit = 1000, sort, unpaged = true, ...rest },
          { injections, getState }
        ) => {
          const tz = injections.globalStore.getState()[this.settingsKey].timezone;
          const dateFromUtc = dateFrom ? tzDateTimeStringToUtc(dateFrom, tz) : undefined;
          const dateToUtc = dateTo ? tzDateTimeStringToUtc(dateTo, tz) : undefined;

          const { data } = await getApi(injections, getState()).find({
            page,
            limit,
            sort,
            extraParams: {
              dateFrom: dateFromUtc,
              dateTo: dateToUtc,
              clubId: this.clubId,
              unpaged,
              ...rest,
            },
          });
          const payload = createPayloadFromFindResponse(data);

          payload.rows = payload.rows.map((r) => convertEventDatesToTz(r, tz));

          actions.fetchedList({ ...payload, dateFrom, dateTo });
          return payload;
        }
      ),

      fetchedList: action((state, { rows, pageCount, page, rowsLimit, dateFrom, dateTo, last }) => {
        if (!page || page === 0) {
          state.rows = rows;
        } else {
          rows.forEach((r) => {
            if (!state.rows.some((sr) => sr.id === r.id)) {
              state.rows.push(r);
            }
          });
        }
        state.pageCount = pageCount;
        state.page = page;
        state.rowsLimit = rowsLimit;
        state.dateFrom = dateFrom;
        state.dateTo = dateTo;
        state.last = last;
      }),

      refreshList: thunk((actions, _, { getState }) => {
        const { page, rowsLimit, dateFrom, dateTo } = getState();
        return actions.fetchList({ page, limit: rowsLimit, dateFrom, dateTo });
      }),

      update: thunk(
        /**
         * @param actions
         * @param id
         * @param patchedFields
         * @returns {Promise<*>}
         */
        (_, { id, patchedFields }, { injections, getState }) => {
          const tz = injections.globalStore.getState()[this.settingsKey].timezone;
          const fields = { ...patchedFields, ...convertChangedEventDatesToUtc(patchedFields, tz) };
          const { isUpdateAll } = fields;
          delete fields.isUpdateAll;
          return getApi(injections, getState()).update(id, { ...fields, clubId: this.clubId }, isUpdateAll);
        }
      ),

      cancel: thunk((_, { occurrenceId, deleteAll }, { injections, getState }) => {
        return getApi(injections, getState()).delete(occurrenceId, deleteAll);
      }),
      updateRsvp: thunk((_, { rsvpId, status }, { injections, getState }) => {
        return getApi(injections, getState()).updateRsvp(rsvpId, status);
      }),

      addAttendeeToRsvp: thunk((_, { userMemberId, occurrenceId }, { injections, getState }) => {
        return getApi(injections, getState()).addAttendeeToRsvp(userMemberId, occurrenceId);
      }),

      onRsvpChange: thunkOn(
        (actions) => [actions.addAttendeeToRsvp, actions.updateRsvp, actions.checkInAll],
        async (actions, _, { getState }) => {
          const { editing } = getState();
          if (editing) {
            await actions.fetchById(editing.id);
          } else {
            await actions.refreshList();
          }
        }
      ),

      checkInAll: thunk((_, occurrenceId, { injections, getState }) => {
        return getApi(injections, getState()).checkInAll(occurrenceId);
      }),
    };
  }
}

export const convertEventDatesToTz = ({ startDate, endDate, startRecurringDate, event, ...rest }, tz) => {
  const result = {
    ...rest,
    event,
    startDate: utcDateTimeStringToTz(startDate, tz),
    endDate: utcDateTimeStringToTz(endDate, tz),
  };

  if (startRecurringDate) {
    const startRecurringDateUtc = moment(
      utcDateTimeStringToTz(
        `${startRecurringDate} ${event.startTime}`,
        tz,
        `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`
      )
    );
    result.startRecurringDate = startRecurringDateUtc.format(PARAMETER_DATE_ONLY_FORMAT);
  }

  return result;
};

const convertChangedEventDatesToUtc = ({ startDate, startTime, endDate, endTime, startRecurringDate }, tz) => {
  const startUtc = moment(
    tzDateTimeStringToUtc(`${startDate} ${startTime}`, tz, `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`)
  );
  const endUtc = moment(
    tzDateTimeStringToUtc(`${endDate} ${endTime}`, tz, `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`)
  );

  const result = {
    startDate: startUtc.format(PARAMETER_DATE_ONLY_FORMAT),
    startTime: startUtc.format(PARAMETER_TIME_FORMAT),
    endDate: endUtc.format(PARAMETER_DATE_ONLY_FORMAT),
    endTime: endUtc.format(PARAMETER_TIME_FORMAT),
  };

  if (startRecurringDate) {
    const startRecurringDateUtc = moment(
      tzDateTimeStringToUtc(
        `${startRecurringDate} ${startTime}`,
        tz,
        `${PARAMETER_DATE_ONLY_FORMAT} ${PARAMETER_TIME_FORMAT}`
      )
    );
    result.startRecurringDate = startRecurringDateUtc.format(PARAMETER_DATE_ONLY_FORMAT);
  }

  return result;
};

export default EventModelBuilder;
