import { TaskDTO } from "@gymflow/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { leadQueryKeys } from "../lead";
import { deadlineDatesToUtc } from "./deadlineDatesToUtc";
import { taskQueryKeys } from "./taskQueryKeys";

export function useTaskEdit({
  api,
  tz,
}: {
  api: {
    taskApi: {
      update: (arg0: number, arg1: unknown) => Promise<any>;
    };
  };
  tz: string;
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      taskId,
      patchedFields,
    }: {
      taskId: number;
      patchedFields: { [k: string]: any };
    }) => {
      let fields = { ...patchedFields };
      if (patchedFields["deadlineDate"] && patchedFields["deadlineTime"]) {
        fields = { ...fields, ...deadlineDatesToUtc(fields, tz) };
      }

      const response = await api.taskApi.update(taskId, fields);
      return response.data as TaskDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: taskQueryKeys.all() });
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.list() });
    },
  });
  return mutation;
}
