import { ApiType } from "@gymflow/common/lib/api/ApiType";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { memberAsMemberQueryKeys } from "./memberAsMemberQueryKeys";

export function useMutationMemberChangePictureAsMember({
  api,
}: {
  api: ApiType;
}) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({
      memberId,
      blob,
      name,
    }: {
      memberId: string;
      blob: string;
      name: string;
    }) => {
      return api.profileApi.updatePicture(memberId, { blob, name });
    },
    onSuccess: (_, { memberId }: { memberId: string }) => {
      queryClient.invalidateQueries({
        queryKey: memberAsMemberQueryKeys.details(memberId),
      });
    },
  });
  return mutation;
}
