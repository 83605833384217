import { useMutation, UseMutationOptions } from "@tanstack/react-query";

export function useMutationMigrationNewPassword(
  {
    api,
  }: {
    api: {
      public: {
        hostedClubApi: {
          invitationNewPassword: ({
            invitationToken,
            newPassword,
          }: {
            invitationToken: string;
            newPassword: string;
          }) => Promise<{ data: void }>;
        };
      };
    };
  },

  mutationOpts?: UseMutationOptions<
    void,
    unknown,
    { invitationToken: string; newPassword: string }
  >,
) {
  return useMutation({
    mutationFn: async ({
      invitationToken,
      newPassword,
    }: {
      invitationToken: string;
      newPassword: string;
    }) => {
      await api.public.hostedClubApi.invitationNewPassword({
        invitationToken,
        newPassword,
      });
    },

    ...mutationOpts,
  });
}
