import { useMutation,useQueryClient } from "@tanstack/react-query";

import { membershipQueryKeys } from "./membershipQueryKeys";

export function useMembershipDelete({
  api,
}: {
  api: {
    serviceApi: {
      delete: (membershipId: number) => Promise<any>;
    };
  };
}) {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: async (membershipId: number) => {
      await api.serviceApi.delete(membershipId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all() });
    },
  });

  return mutation;
}
