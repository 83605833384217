import endpoints from "./endpoints";
import { canFind } from "./features/apiResource";
import { createPublicAxiosInstance } from "./publicAxiosInstance";

const publicFacilityApi = (apiUrl, clubId) => {
  const clubPrefix = `club/${clubId}/`;
  const state = {
    baseUrl: `${clubPrefix}${endpoints.publicFacility}`,
    apiUrl,
    http: createPublicAxiosInstance({
      apiUrl,
    }),
  };

  return Object.assign(state, canFind(state));
};

export default publicFacilityApi;
