import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Col, FormGroup, Row } from 'reactstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRecordForm, formikHelpers, renderErrorRowOnTouch, TwoWayObjectMapper } from '@gymflow/common';

import useLeadSourceLoadOptions from '../../../../hooks/useLeadSourceLoadOptions';

function Step2GuestRegistration({ onChange, value }) {
  const { initialValues } = useRecordForm({
    record: null,
    fields: schema.default(),
    mapper: new TwoWayObjectMapper(),
  });

  useEffect(() => {
    setFieldValue(SOURCE_ID, value);
  }, [value]);

  const formikProps = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: () => { },
  });
  const { setFieldValue, touched, errors } = formikProps;
  const { errorClass } = formikHelpers(formikProps);
  const leadSourceLoadOptions = useLeadSourceLoadOptions();

  return (
    <div className="w-100 text-center">
      <b className="h2">Set a default lead source</b>
      <p className="text-muted mt-4">
        Setting a default lead source allows you to create forms for specific events and ensure that all leads captured
        have the same source by hiding the source option on the form.
      </p>
      <Row className="mt-4 text-left d-flex justify-content-center">
        <Col md={8}>
          <p className="text-uppercase text-muted">Default Lead Source</p>
          <FormGroup className={classNames('dropdown-group', errorClass(SOURCE_ID))} data-testid={SOURCE_ID}>
            <AsyncPaginate
              className="react-select info'"
              classNamePrefix="react-select"
              additional={{
                hasAll: false,
                page: 0,
              }}
              placeholder="Select Source"
              loadOptions={leadSourceLoadOptions}
              onChange={(newValue) => {
                setFieldValue(SOURCE_ID, newValue);
                onChange(newValue);
              }}
              value={value}
            />

            {renderErrorRowOnTouch(SOURCE_ID, touched, errors)}
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

const SOURCE_ID = 'source';
const schema = Yup.object().shape({
  [SOURCE_ID]: Yup.number().required(),
});

export default Step2GuestRegistration;
