import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { leadQueryKeys } from "./leadQueryKeys";

export function useMutationCreateLeadStatus(
  {
    api,
  }: {
    api: {
      leadApi: {
        createStatusColumn: (newName: string) => Promise<void>;
      };
    };
  },
  mutationOpts?: UseMutationOptions<unknown, unknown, { newName: string }>,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: ({ newName }: { newName: string }) =>
      api.leadApi.createStatusColumn(newName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: leadQueryKeys.all() });
    },
    ...mutationOpts,
  });

  return mutation;
}
