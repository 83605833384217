import { PublicMembershipApiType } from "@gymflow/common/lib/api/publicMembershipApi";
import { useQuery } from "@tanstack/react-query";

import { membershipAsPublicQueryKeys } from "./membershipAsPublicQueryKeys";

export function useQueryMembershipAsPublic({
  api,
  membershipId,
}: {
  api: {
    public: {
      membershipApi: PublicMembershipApiType;
    };
  };
  membershipId?: Parameters<PublicMembershipApiType["getById"]>[0];
}) {
  const result = useQuery({
    queryKey: membershipAsPublicQueryKeys.details(membershipId),
    queryFn: async () => {
      const result = await api.public.membershipApi.getById(membershipId!);
      return result.data;
    },
    enabled: !!membershipId,
  });

  return result;
}
