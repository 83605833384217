import { memberQueryKeys, memberSearchQueryFn } from "@gymflow/api";
import {
  LeadDTO,
  LeadTaskDTO,
  UserMemberBean,
  UserMemberTaskDTO,
} from "@gymflow/types";
import { InfiniteData, UseInfiniteQueryOptions } from "@tanstack/react-query";
import useGymflowModels from "apps/portal/src/store";
import { useCallback, useState } from "react";

import { OptionsType } from "./components/OptionsList/types";
import {
  SearchInput,
  SearchInputOptionsType,
  SearchInputProps,
} from "./SearchInput";

type UserOrLeadType =
  | UserMemberBean
  | LeadDTO
  | LeadTaskDTO
  | UserMemberTaskDTO;

export type UsersSearchInputOptionsType = OptionsType<UserOrLeadType>;

export type UsersSearchInputProps = Pick<
  SearchInputProps<UserOrLeadType>,
  | "label"
  | "value"
  | "onChange"
  | "dropPosition"
  | "isMulti"
  | "withImageByDefault"
  | "className"
>;

const SEARCH_LIST_SIZE = 10;

export const UsersSearchInput = (props: UsersSearchInputProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { api } = useGymflowModels();

  const dataFetchingQuery = useCallback(
    (
      searchTermLocal: string,
    ): UseInfiniteQueryOptions<
      unknown,
      unknown,
      SearchInputOptionsType<UserOrLeadType>
    > => ({
      queryKey: memberQueryKeys.search(searchTermLocal, SEARCH_LIST_SIZE),
      queryFn: async () => {
        setSearchTerm(searchTermLocal);

        return await memberSearchQueryFn(api, {
          term: searchTermLocal,
          size: SEARCH_LIST_SIZE,
        });
      },
      select: (data) => {
        const { pageParams, pages } = data as InfiniteData<UserOrLeadType[]>;

        const transformedPages = pages.map((page) =>
          page.map((user) => ({
            id: user.id,
            label: `${user.firstName} ${user.lastName}`,
            value: user,
            imageUrl: "picture" in user ? user.picture : undefined,
            subLabel: user.email,
          })),
        );

        return {
          pageParams,
          pages: transformedPages,
        };
      },
      enabled: searchTermLocal.trim().length > 0,
    }),
    [api],
  );

  return (
    <SearchInput
      {...props}
      dataFetchingQuery={dataFetchingQuery}
      notFoundClassName="justify-start"
      notFound={!searchTerm.trim() && "Type the name of the member to select"}
    />
  );
};
