import { useState } from "react";

import { ModalWrapper } from "../../../providers";
import { Button, DangerIcon } from "../../atoms";

export function ChooseIfRecurringRescheduleModal({
  onConfirm,
  onCancel,
}: {
  onConfirm: (selected: "THIS_INSTANCE" | "ALL_INSTANCES") => void;
  onCancel: () => void;
}) {
  const [value, setValue] = useState<"THIS_INSTANCE" | "ALL_INSTANCES">(
    "THIS_INSTANCE",
  );

  return (
    <ModalWrapper onCancel={onCancel}>
      <div className="bg-primary-100 mb-3 flex h-11 w-11 self-center rounded-full p-3">
        <DangerIcon className="h-5 w-5" pathClassName="stroke-primary-600" />
      </div>
      <div>
        <div className="font-semibold">Reschedule Appointment</div>
        <div className="text-sm text-gray-600">
          Do you want to reschedule only this appointment or all appointments in
          this series?
        </div>
        <div className="mt-4 flex flex-col gap-2">
          <div
            onClick={() => {
              setValue("THIS_INSTANCE");
            }}
            className="flex h-9 items-center gap-3"
          >
            <div>
              <input
                className="accent-primary-600"
                type="radio"
                checked={value === "THIS_INSTANCE"}
                onChange={() => {}}
              />
            </div>
            <div className="flex-1 text-base">
              Reschedule only this appointment
            </div>
          </div>

          <div
            onClick={() => {
              setValue("ALL_INSTANCES");
            }}
            className="flex h-9 items-center gap-3"
          >
            <div>
              <input
                className="accent-primary-600"
                type="radio"
                checked={value === "ALL_INSTANCES"}
                onChange={() => {}}
              />
            </div>
            <div className="flex-1 text-base">
              Reschedule all appointments in this series
            </div>
          </div>
        </div>
        <div className="mt-5 flex space-x-2">
          <Button onClick={onCancel} className="flex-1">
            Cancel
          </Button>
          <Button
            intent="primary"
            onClick={() => {
              onConfirm(value);
            }}
            className="flex-1"
          >
            Select
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
}
