import { cva, VariantProps } from "class-variance-authority";
import React from "react";

import { cn } from "../../../../../libs/helpers/src";

const emptyDotVariants = cva(
  "flex h-4 w-4 items-center justify-center rounded-full",
  {
    variants: {
      intent: {
        primary: "bg-primary-600",
        secondary: "bg-secondary-600",
        error: "bg-error-600",
        warning: "bg-warning-600",
        success: "bg-success-600",
        lightblue: "bg-lightblue-600",
        default: "bg-gray-600",
      },
    },
    defaultVariants: {
      intent: "default",
    },
  },
);
export type EmptyDotProps = {
  classNameOverride?: string;
} & VariantProps<typeof emptyDotVariants>;

export const EmptyDot: React.FC<EmptyDotProps> = ({
  intent,
  classNameOverride,
}) => {
  return (
    <div className={cn(emptyDotVariants({ intent }), classNameOverride)}>
      <div className="flex h-2 w-2 items-center justify-center rounded-full bg-white"></div>
    </div>
  );
};
