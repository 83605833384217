import useStyle from "../../hooks/useStyle";
import { useClubSettings } from "../../providers";
import useGymflowModels from "../../store";

const MainStyleLayout = ({ isStaff, children }) => {
  const settings = useClubSettings();
  const clubId = settings.clubId;
  const { isLoadingStyle } = useStyle(clubId, isStaff);

  if (isLoadingStyle) {
    return null;
  }

  return children;
};

const MainLayoutWithProvider = (props) => {
  const { PublicHostedClubStore } = useGymflowModels();
  return (
    <PublicHostedClubStore.Provider>
      <MainStyleLayout {...props} />
    </PublicHostedClubStore.Provider>
  );
};

export default MainLayoutWithProvider;
