import { EventOccurrenceDTO } from "@gymflow/types";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { calendarQueryKeys } from "../calendar";

import { eventOccurrenceQueryKeys } from "./eventOccurrenceQueryKeys";
import { eventOccurrenceToUtc } from "./eventOccurrenceToUtc";

export function useEventOccurrenceEdit(
  {
    api,
    tz,
  }: {
    api: {
      eventApi: {
        update: (arg0: number, arg1: unknown, arg2: boolean) => Promise<any>;
      };
    };
    tz: string;
  },
  mutationOpts?: UseMutationOptions<
    EventOccurrenceDTO,
    unknown,
    { eventId: number; patchedFields: Record<string, any> }
  >,
) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({
      eventId,
      patchedFields,
    }: {
      eventId: number;
      patchedFields: Record<string, any>;
    }) => {
      const { isUpdateAll } = patchedFields;
      delete patchedFields["isUpdateAll"];
      const response = await api.eventApi.update(
        eventId,
        eventOccurrenceToUtc(patchedFields, tz),
        isUpdateAll,
      );
      return response.data as EventOccurrenceDTO;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventOccurrenceQueryKeys.all(),
      });
      queryClient.invalidateQueries({
        queryKey: calendarQueryKeys.eventOccurrences(),
      });
    },
    ...mutationOpts,
  });
  return mutation;
}
