import { RuleClubDTO } from "@gymflow/types";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { ruleQueryKeys } from "./ruleQueryKeys";

export function useQueryUserFormRules(
  {
    api,
    clubId,
  }: {
    api: {
      ruleApi: { getValuesByClubId: (clubId: number) => Promise<any> };
    };
    clubId: number;
  },
  opts?: UseQueryOptions<RuleClubDTO[]>,
) {
  return useQuery({
    queryKey: ruleQueryKeys.values(clubId),
    queryFn: async () => {
      const result = await api.ruleApi.getValuesByClubId(clubId);
      return result.data as RuleClubDTO[];
    },
    enabled: !!clubId,
    initialData: [],
    ...opts,
  });
}
