import { rulesHelper } from "@gymflow/helpers";
import { RuleGroup, RuleGroupPost, WindowTypeValues } from "@gymflow/types";
import { z } from "zod";

const AppointmentRuleSchema = z.object({
  name: z.string(),
  isDefault: z.boolean(),
  advancedBookings: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  bookingNoticePeriod: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  rescheduleNoticePeriod: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  concurrentBookings: z.boolean(),
  bookingLimits: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  cancelation: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.number(),
      type: z.enum(WindowTypeValues),
      cancellationFee: z.discriminatedUnion("enabled", [
        z.object({
          enabled: z.literal(true),
          amount: z.coerce
            .number({
              invalid_type_error: "Include the fee amount",
              required_error: "Include the fee amount",
            })
            .min(0),
        }),
        z.object({ enabled: z.literal(false) }),
      ]),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  noShowFee: z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      amount: z.coerce
        .number({
          invalid_type_error: "Include the fee amount",
          required_error: "Include the fee amount",
        })
        .min(0),
    }),
    z.object({ enabled: z.literal(false) }),
  ]),
  appointableList: z
    .array(z.object({ value: z.number(), label: z.any() }))
    .optional(),
});
export type AppointmentRuleFormType = z.infer<typeof AppointmentRuleSchema>;
export const AppointmentRuleMapper = {
  schema: AppointmentRuleSchema,
  defaultValues: {
    name: "",
    isDefault: false,
    advancedBookings: {
      enabled: false,
    },
    bookingNoticePeriod: {
      enabled: false,
    },
    rescheduleNoticePeriod: {
      enabled: false,
    },
    concurrentBookings: false,
    bookingLimits: {
      enabled: false,
    },
    cancelation: {
      enabled: false,
    },
    noShowFee: {
      enabled: false,
    },
    appointableList: [] as { value: number; label: string }[],
  } as const,
  mapBEtoFE: (ruleGroup: RuleGroup) => {
    const appointmentRules = rulesHelper.mapRuleGroupToAppointmentRules(ruleGroup);

    const result: AppointmentRuleFormType = {
      name: ruleGroup.name,
      isDefault: ruleGroup.isDefault,
      advancedBookings:
        !!appointmentRules.advancedBookingsRule &&
        !!appointmentRules.advancedBookingsRule.windowValue &&
        !!appointmentRules.advancedBookingsRule.windowType
          ? {
              enabled: true,
              amount: Number(appointmentRules.advancedBookingsRule.windowValue),
              type: appointmentRules.advancedBookingsRule.windowType,
            }
          : {
              enabled: false,
            },
      bookingNoticePeriod:
        appointmentRules.bookingNoticeRule &&
        !!Number(appointmentRules.bookingNoticeRule.windowValue)
          ? {
              enabled: true,
              amount: Number(appointmentRules.bookingNoticeRule.windowValue),
              type: appointmentRules.bookingNoticeRule.windowType,
            }
          : {
              enabled: false,
            },
      rescheduleNoticePeriod:
        appointmentRules.rescheduleNoticeRule &&
        !!Number(appointmentRules.rescheduleNoticeRule.windowValue)
          ? {
              enabled: true,
              amount: Number(appointmentRules.rescheduleNoticeRule.windowValue),
              type: appointmentRules.rescheduleNoticeRule.windowType,
            }
          : {
              enabled: false,
            },
      concurrentBookings:
        !!appointmentRules.concurrentBookingRule &&
        !!appointmentRules.concurrentBookingRule.restrictConcurrentBookings,
      bookingLimits:
        appointmentRules.bookingLimitRule && !!appointmentRules.bookingLimitRule.limitValue
          ? {
              enabled: true,
              amount: Number(appointmentRules.bookingLimitRule.limitValue),
            }
          : {
              enabled: false,
            },
      cancelation:
        appointmentRules.bookingCancellationRule &&
        !!appointmentRules.bookingCancellationRule.windowValue &&
        !!appointmentRules.bookingCancellationRule.windowType
          ? {
              enabled: true,
              amount: Number(appointmentRules.bookingCancellationRule.windowValue),
              type: appointmentRules.bookingCancellationRule.windowType,
              cancellationFee:
                appointmentRules.bookingCancellationRule &&
                !!appointmentRules.bookingCancellationRule.cancellationFee
                  ? {
                      enabled: true,
                      amount: Number(
                        appointmentRules.bookingCancellationRule.cancellationFee,
                      ),
                    }
                  : {
                      enabled: false,
                    },
            }
          : {
              enabled: false,
            },
      noShowFee:
        appointmentRules.noShowRule && !!appointmentRules.noShowRule.noShowFeeValue
          ? {
              enabled: true,
              amount: Number(appointmentRules.noShowRule.noShowFeeValue),
            }
          : {
              enabled: false,
            },
      appointableList:
        ruleGroup.appointableList?.map((e) => ({
          label: e.name,
          value: e.id,
        })) ?? [],
    };
    return result;
  },
  mapFEtoBE: (appointmentRule: AppointmentRuleFormType) => {
    const result: RuleGroupPost = {
      name: appointmentRule.name,
      groupType: "APPOINTABLE_GROUP",
      ruleClubList: [
        {
          ruleType: "BOOKING_LIMIT_WINDOW",
          bookingRule: appointmentRule.advancedBookings.enabled
            ? {
                windowType: appointmentRule.advancedBookings.type,
                windowValue: `${appointmentRule.advancedBookings.amount}`,
              }
            : ({
                windowType: "",
                windowValue: "",
              } as any),
        },
        {
          ruleType: "BOOKING_NOTICE_PERIOD",
          bookingNoticePeriodRule: appointmentRule.bookingNoticePeriod.enabled
            ? {
                windowType: appointmentRule.bookingNoticePeriod.type,
                windowValue: `${appointmentRule.bookingNoticePeriod.amount}`,
              }
            : {
                windowType: "DAYS",
                windowValue: "0",
              },
        },
        {
          ruleType: "RESCHEDULE_NOTICE_PERIOD",
          rescheduleNoticePeriodRule: appointmentRule.rescheduleNoticePeriod
            .enabled
            ? {
                windowType: appointmentRule.rescheduleNoticePeriod.type,
                windowValue: `${appointmentRule.rescheduleNoticePeriod.amount}`,
              }
            : {
                windowType: "DAYS",
                windowValue: "0",
              },
        },
        {
          ruleType: "CONCURRENT_BOOKING",
          concurrentBookingRule: {
            restrictConcurrentBookings: appointmentRule.concurrentBookings,
          },
        },
        {
          ruleType: "BOOKING_CANCELLATION_WINDOW",
          bookingCancellationRule: appointmentRule.cancelation.enabled
            ? {
                windowType: appointmentRule.cancelation.type,
                windowValue: `${appointmentRule.cancelation.amount}`,
                cancellationFee: appointmentRule.cancelation.cancellationFee
                  .enabled
                  ? appointmentRule.cancelation.cancellationFee.amount
                  : 0,
              }
            : ({
                windowType: "",
                windowValue: "",
                cancellationFee: 0,
              } as any),
        },
        {
          ruleType: "BOOKING_LIMIT_PER_CUSTOMER",
          bookingLimitRule: appointmentRule.bookingLimits.enabled
            ? {
                limitValue: appointmentRule.bookingLimits.amount,
              }
            : {
                limitValue: 0,
              },
        },
        {
          ruleType: "NO_SHOW_FEE",
          noShowFeeRule: appointmentRule.noShowFee.enabled
            ? {
                noShowFeeValue: appointmentRule.noShowFee.amount,
              }
            : {
                noShowFeeValue: 0,
              },
        },
      ],
      appointableIdList:
        appointmentRule.isDefault 
          ? undefined
          : appointmentRule.appointableList?.map((e) => e.value),
    };
    return result;
  },
};
